import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import { getRewards } from 'store/dashboard/rewardsSlice';
import { featureToggle } from 'constants/index';
import { checkIfChallengeOpen } from 'constants/leaderboard';
import QnText from 'components/Assignment/AssignmentView/QnText';
import LeaderboardChallengeList from 'components/Leaderboard/LeaderboardChallengesList';
import SubjectTabs from 'components/Leaderboard/SubjectTabs';
import { subjectNames } from 'constants/products';
import { sendEventTracking } from 'helpers/UserEventTracking';
import Message from 'components/Leaderboard/Message';
import { LEADERBOARD_LIST } from './v2/constant';

import {
  Container,
  RulesButton,
  Topbar,
  topBarStyles,
} from './LeaderboardList.styles';

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LeaderboardList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['leaderboardlist']);
  const query = useQuery();
  const subjectParam = query.get('subject');
  // Redux States
  const { products, isLoading, currentProduct } = useSelector(
    (state) => state.plan
  );
  // Component States
  const initialSubject = featureToggle.science
    ? null
    : { id: 1, subject: 'Math' };
  const [selectedSubject, setSelectedSubject] = useState(initialSubject);
  const lowercaseSubjectName = selectedSubject?.subject.toLowerCase() ?? '';
  const initialChallenges =
    initialSubject !== null
      ? LEADERBOARD_LIST[initialSubject.subject.toLowerCase()]
      : [];
  const [challenges, setChallenges] = useState(initialChallenges);
  const openChallenges = challenges.filter((data) => {
    if (data.startDateTime && data.endDateTime) {
      const isOpen = checkIfChallengeOpen(data.startDateTime, data.endDateTime);
      const itemStatus = isOpen ? 'open' : 'close';
      return itemStatus === 'open';
    }
    return data.status === 'open';
  });
  const closedChallenges = challenges.filter((data) => {
    if (data.startDateTime && data.endDateTime) {
      const isOpen = checkIfChallengeOpen(data.startDateTime, data.endDateTime);
      const itemStatus = isOpen ? 'open' : 'close';
      return itemStatus === 'close';
    }
    return data.status === 'close';
  });
  useEffect(() => {
    if (lowercaseSubjectName) {
      sendEventTracking('leaderboard', 'leaderboard_intermediate_open', {
        sub: subjectNames[lowercaseSubjectName],
      });
    }
  }, [lowercaseSubjectName]);
  // Set default initial subject
  useEffect(() => {
    if (!isLoading && featureToggle.science && !isNil(products)) {
      // Check if user has science subscription
      const nonExpiredProducts = products.filter(
        (product) => product.nonExpiredSubscriptionProducts.length > 0
      );
      const currentParentProduct = products.find(
        (product) => product.id === currentProduct.productParentId
      );
      let defaultSubject;
      if (isNil(subjectParam)) {
        defaultSubject =
          currentParentProduct ?? nonExpiredProducts[0] ?? products[0];
      } else {
        defaultSubject =
          products.find(
            (product) =>
              subjectParam.toLowerCase().trim() ===
              product.subject.toLowerCase().trim()
          ) ??
          currentParentProduct ??
          nonExpiredProducts[0] ??
          products[0];
      }
      setSelectedSubject(defaultSubject);
      history.replace(`/leaderboard?subject=${defaultSubject.subject}`);
    }
  }, [currentProduct, history, isLoading, products, subjectParam]);
  useEffect(() => {
    if (!isNil(selectedSubject))
      setChallenges(LEADERBOARD_LIST[lowercaseSubjectName]);
  }, [lowercaseSubjectName, selectedSubject]);
  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch]);
  return (
    <>
      <Topbar subject={selectedSubject?.subject.toLowerCase()}>
        <div className="header">
          <img
            src={
              topBarStyles[selectedSubject?.subject.toLowerCase()]?.img ??
              topBarStyles.math.img
            }
            alt="confetti"
          />
          <div className="text">
            <QnText
              text={t('leaderboardlist:topBanner.leaderboards', 'Leaderboards')}
              fontFam="Bree Serif"
              color={
                topBarStyles[selectedSubject?.subject.toLowerCase()]
                  ?.textColour ?? topBarStyles.math.textColour
              }
              borderColor={
                topBarStyles[selectedSubject?.subject.toLowerCase()]
                  ?.textColour ?? topBarStyles.math.textColour
              }
              strokeWidth="0.5rem"
              textShadow="none"
            />
          </div>
        </div>
        <RulesButton
          href={
            selectedSubject?.subject.toLowerCase() === 'math'
              ? 'https://support.koobits.com/hc/en-gb/sections/360003272492-Leaderboards'
              : 'https://support.koobits.com/hc/en-gb/sections/4414656710297-Leaderboards-Science-'
          }
          target="_blank"
          className="rules-btn"
          rel="noopener noreferrer"
          subject={selectedSubject?.subject.toLowerCase()}
        >
          {t('leaderboardlist:topBanner.rules', 'Rules')}
        </RulesButton>
      </Topbar>
      {featureToggle.science && !isNil(products) && (
        <SubjectTabs subjects={products} selectedSubject={selectedSubject} />
      )}
      {!isLoading && !isNil(selectedSubject) && (
        <Container>
          {lowercaseSubjectName === 'math' && <Message />}
          <LeaderboardChallengeList
            status="open"
            challenges={openChallenges}
            subject={lowercaseSubjectName}
          />
          <LeaderboardChallengeList
            status="close"
            challenges={closedChallenges}
            subject={lowercaseSubjectName}
          />
        </Container>
      )}
    </>
  );
};

export default LeaderboardList;
