import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from 'components/Header';
import AssignmentList from 'pages/Assignment/AssignmentList';
import StartAssignment from 'pages/Assignment/StartAssignment';
import AssignmentSolution from 'pages/Assignment/AssignmentSolution';
import ParentAssign from 'pages/Assignment/ParentAssign';
import useGlobalFeature from 'hooks/useGlobalFeature';
import AssignmentIntermediate from './AssignmentIntermediate';
import { Page, Content } from './index.styles';

const Assignment = () => {
  const { featuresHashmap } = useGlobalFeature(null, [
    'assignment',
    'parentassignment',
  ]);
  return (
    <Page>
      <Header />
      <Content>
        <Switch>
          {!featuresHashmap.assignment.isEnabled && (
            <Redirect
              exact
              from={[
                '/assignment/assignment-list',
                '/assignment/start/:id',
                '/assignment/solution/:assignmentID/:assignmentSubmissionID',
              ]}
              to="/maintenance-redirect"
            />
          )}
          {!featuresHashmap.parentassignment.isEnabled && (
            <Redirect
              exact
              from="/assignment/parent-assign"
              to="/maintenance-redirect"
            />
          )}
          <Route exact path="/assignment" component={AssignmentIntermediate} />
          <Route
            exact
            path="/assignment/assignment-list"
            component={AssignmentList}
          />
          <Route
            exact
            path="/assignment/parent-assign"
            component={ParentAssign}
          />
          <Route
            exact
            path="/assignment/start/:id"
            component={StartAssignment}
          />
          <Route
            exact
            path="/assignment/solution/:assignmentID/:assignmentSubmissionID"
            component={AssignmentSolution}
          />
        </Switch>
      </Content>
    </Page>
  );
};

export default Assignment;
