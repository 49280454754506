/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
import { Spinner } from 'react-bootstrap';
// assets
import LightIcon from 'assets/img/kooquiz/videoSolution/light.svg';
import CloseIcon from 'assets/img/kooquiz/videoSolution/close.svg';
import SearchIcon from 'assets/img/kooquiz/videoSolution/search.svg';

import { fetchWrapper } from 'services/login';
import { fetchQuestionVideoCode } from 'services/kooQuiz';

// import { getQuestionVideoCode } from 'store/kooQuiz/kooQuizSlice';

// Styles Component
const TriggerButton = styled.div`
  position: fixed;
  right: 5vw;
  bottom: 5%;
  cursor: pointer;
  z-index: 103;
`;

const Wrapper = styled.div`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 101;
`;

const Container = styled.div`
  width: 312px;
  height: 169px;
  background-color: #ffffff;
  border-radius: 14px;
  position: absolute;
  right: 5vw;
  bottom: 5%;
  transform: translate(-13%, -18%);
  color: #000;
  padding: 28px 30px;
  font-size: 14px;
`;

const Heading = styled.div`
  margin-bottom: 12px;
`;

const SearchBar = styled.form`
  height: 38px;
  width: 224px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
`;

const Delete = styled.div`
  width: 20px;
  height: 100%;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 36px;
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: 22px;
  padding-left: 5px;
  border: 1px solid #b7b7b7;
  border-right: none;

  &::placeholder {
    color: #d3d3d3;
    font-size: 18px;
  }
`;

const SearchButton = styled.button`
  background-color: #fadc5b;
  border: 1px solid #e0ba24;
  height: 38px;
  width: 32px;
`;

const ErrorMessage = styled.span`
  opacity: ${(p) => (p.isShow ? 1 : 0)};
  color: #ff0000;
  font-size: 12px;
  padding-left: 2px;
`;

function isNumeric(str) {
  if (typeof str !== 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

const KooQuizVideoSolution = () => {
  const { t } = useTranslation(['kooQuizVideoSolution']);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [videoCode, setVideoCode] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const modalRef = useRef(null);

  const onSearchVideo = async (e) => {
    e.preventDefault();
    if (inputValue === '' || !isNumeric(inputValue)) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
      setIsLoading(true);
      try {
        const res = await fetchWrapper(fetchQuestionVideoCode, inputValue);
        if (res === null) {
          setShowErrorMessage(true);
        } else {
          setVideoCode(res);
          setShowVideoModal(true);
        }
      } catch (err) {
        throw new Error(err?.message ?? 'Get question video code failed');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onClickModal = (e) => {
    if (e.target === modalRef.current) {
      setIsOpen(false);
    }
  };

  const onInputChange = (e) => {
    if (e.target.value === '') {
      setShowErrorMessage(false);
    }
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
      setShowErrorMessage(false);
    }
  }, [isOpen]);

  return (
    <>
      <ModalVideo
        channel="youtube"
        isOpen={showVideoModal}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
        }}
        videoId={videoCode}
        onClose={() => setShowVideoModal(false)}
      />
      <TriggerButton onClick={() => setIsOpen((prev) => !prev)}>
        <img src={LightIcon} title="solution" alt="" />
      </TriggerButton>
      <Wrapper isOpen={isOpen} onClick={onClickModal} ref={modalRef}>
        <Container>
          <Heading>
            {t(
              'kooQuizVideoSolution:heading1',
              'Need help answering a question?'
            )}
            <br />
            {t(
              'kooQuizVideoSolution:heading2',
              'Key in the Question ID to watch solution.'
            )}
          </Heading>
          <SearchBar onSubmit={onSearchVideo}>
            <Input
              value={inputValue}
              onChange={onInputChange}
              placeholder="e.g. 12345678"
            />
            <Delete onClick={() => setInputValue('')}>
              <img src={CloseIcon} alt="" />
            </Delete>
            <SearchButton type="submit">
              <img src={SearchIcon} alt="" />
            </SearchButton>
          </SearchBar>
          {!showErrorMessage && isLoading && (
            <Spinner animation="border" size="sm" />
          )}
          <ErrorMessage isShow={showErrorMessage}>
            {t(
              'kooQuizVideoSolution:error',
              'Video for this Question ID does not exist.'
            )}
          </ErrorMessage>
        </Container>
      </Wrapper>
    </>
  );
};

export default KooQuizVideoSolution;
