const Categories = {
  1: 'speed',
  2: 'attention',
  3: 'flexibility',
  4: 'adventure',
  5: 'memory',
  6: 'problem-solving',
  201: 'problem-solving2',
  202: 'energy',
  203: 'systems',
  204: 'life-science',
  205: 'interaction',
};

// Maps subject to corresponding island game folder
export const subjectIslands = {
  math: {
    folder: 'mathkooislandv4.0',
    skipFolder: 'mathkooislandv4.0-nosound',
    iframe: true,
  },
  science: {
    folder: 'sciencekooisland',
    skipFolder: 'sciencekooisland-nosound',
    iframe: true,
  },
};
export default Categories;
