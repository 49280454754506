import PremiumIcon from 'assets/img/icon-plan-premium.svg';
import PlusIcon from 'assets/img/icon-plan-plus.svg';
import SchoolIcon from 'assets/img/icon-plan-school.svg';

export const subscriptionPlan = {
  standard: {
    icon: SchoolIcon,
    text: 'KooBits Math - Standard Plan',
  },
  premium: {
    icon: PremiumIcon,
    text: 'KooBits Math - Premium Plan',
  },
  plus: {
    icon: PlusIcon,
    text: 'KooBits Math - Plus Plan',
  },
  school: {
    icon: SchoolIcon,
    text: 'KooBits Math - School Plan',
  },
  newStandard: {
    icon: SchoolIcon,
    text: 'Standard Plan',
  },
};

export const genderId = {
  1: 'Male',
  2: 'Female',
};

export const PROFILE_MENU = [
  {
    id: 1,
    url: '/profile-settings/avatar',
    text: 'Edit Avatar',
    translationKey: 'editAvatar',
  },
  {
    id: 2,
    url: '/profile-settings/profile',
    text: 'Edit Profile',
    translationKey: 'editProfile',
  },
  {
    id: 3,
    url: '/profile-settings/settings',
    text: 'Settings',
    translationKey: 'settings',
  },
  {
    id: 4,
    url: '/profile-settings/my-subscription',
    text: 'My Subscription',
    translationKey: 'mySubs',
  },
];
