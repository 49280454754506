import moment from 'moment-timezone';

export const betweenOpenHour = (now, start, end, timezone) => {
  const currentDateTime = moment.tz(now, timezone);
  const startDateTime = moment.tz(start, timezone);
  const endDateTime = moment.tz(end, timezone);
  const diffWithStartDate = moment
    .duration(startDateTime.diff(currentDateTime))
    .as('milliseconds');
  const diffWithEndDate = moment
    .duration(endDateTime.diff(currentDateTime))
    .as('milliseconds');
  if (diffWithStartDate <= 0 && diffWithEndDate > 0) {
    return true;
  }
  return false;
};

export const upcomingSMCHour = (today, start, timezone) => {
  const currentDateTime = moment.tz(today, timezone);
  const startDateTime = moment.tz(start, timezone);
  const diffWithStartDate = moment
    .duration(startDateTime.diff(currentDateTime))
    .as('hours');
  if (diffWithStartDate <= 24 && diffWithStartDate > 0) {
    return true;
  }
  return false;
};
