import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import {
  fetchBFFFriends,
  fetchBFFLeaderBoard,
} from 'services/leaderboardV2/bff';

export const getBFFLeaderboard = createAsyncThunk(
  'leaderboard/getBFFLeaderboard',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchBFFLeaderBoard, params);
      return handleResponseV2(res);
    } catch (error) {
      throw new Error(error?.message ?? 'Get BFF leaderboard data failed');
    }
  }
);
export const getBFFFriends = createAsyncThunk(
  'leaderboard/getBFFFriends',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchBFFFriends, params);
      return handleResponseV2(res);
    } catch (error) {
      throw new Error(error?.message ?? 'Get BFF friends data failed');
    }
  }
);
const initialState = {
  isLoading: false,
  error: null,
  data: [],
  friendsObj: {},
};

const topBrainLeaderboardSlice = createSlice({
  name: 'bffLeaderboard',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getBFFLeaderboard.pending, (state) => {
        state.data = [];
        state.friendsObj = {};
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBFFLeaderboard.fulfilled, (state, action) => {
        state.data = action.payload;
        state.friendsObj = action.payload.reduce((acc, item) => {
          acc[item.userId] = {
            isLoading: false,
            error: null,
            data: [],
          };
          return acc;
        }, {});
        state.isLoading = false;
      })
      .addCase(getBFFLeaderboard.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getBFFFriends.pending, (state, action) => {
        return {
          ...state,
          friendsObj: {
            ...state.friendsObj,
            [action.meta.arg.userId]: {
              isLoading: true,
              error: null,
              data: [],
            },
          },
        };
      })
      .addCase(getBFFFriends.fulfilled, (state, action) => {
        return {
          ...state,
          friendsObj: {
            ...state.friendsObj,
            [action.meta.arg.userId]: {
              isLoading: false,
              data: action.payload,
              error: null,
            },
          },
        };
      })
      .addCase(getBFFFriends.rejected, (state, action) => {
        return {
          ...state,
          friendsObj: {
            ...state.friendsObj,
            [action.meta.arg.userId]: {
              data: [],
              isLoading: false,
              error: action.error.message,
            },
          },
        };
      }),
});

const { reducer } = topBrainLeaderboardSlice;
export default reducer;
