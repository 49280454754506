import { equals, isEmpty } from 'ramda';

// old answers equals new answers
const answersEqualsChecker = (params, allSubmission) => {
  const newAnswers = params.rawBody.answerkeyValuePairs.map((item) => ({
    value: item.value || item.Value || '',
    key: item.key || item.Key,
  }));
  const oldAnswers = allSubmission[
    params.activeQuestion - 1
  ]?.SubmissionModel?.SubmissionKeyValuePairs.map((item) =>
    item ? { value: item.Value || '', key: item.Key } : null
  );
  return (
    equals(oldAnswers, newAnswers) ||
    isEmpty(params.rawBody.answerkeyValuePairs)
  );
};

export default answersEqualsChecker;
