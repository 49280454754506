import * as cn from 'chinese-numbering';
import { isNil } from 'ramda';

const languageKeys = {
  zh_CN: 'simplified',
  zh_TW: 'traditional',
};

// Formats numbers to chinese character numbers
const formatChineseNumber = ({ value, language }) => {
  const isCurrentLanguageChinese = ['zh_CN', 'zh_TW'].includes(language);
  if (!isCurrentLanguageChinese) return value;
  if (isNil(value)) return '';
  return cn.numberToChinese(value, languageKeys[language]);
};

export default formatChineseNumber;
