import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, isEmpty } from 'ramda';
import {
  getStaticUserNotification,
  getUserNotification,
} from 'store/notification/notificationSlice';

import Header from 'components/Header';
import BackButton from 'components/Shared/BackButton';
import DailyChallengeCard from 'components/Dashboard/DailyChallengeCard';
import DashboardCard from 'components/Dashboard/DashboardCard';
import BannerNotification from 'components/Notifications/BannerNotification';
import { plans, featureToggle, sciencePlans } from 'constants/index';

import LeftBg from 'assets/img/dashboard/play-left-bg.svg';
import RightBg from 'assets/img/dashboard/play-right-bg.svg';

import { PageHeading } from 'pages/Practice/index.styles';
import { CardList } from 'pages/Dashboard/index.styles';
import { Container, SubCards } from './index.styles';

const Play = () => {
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { subject } = useSelector((state) => state.plan);
  const { notificationsDetail, bannerNotif, userNotification } = useSelector(
    (state) => state.notification
  );
  const {
    nonExpiredSubscriptionPlans,
    subscriptionData,
    defaultLevel,
    countryCode,
    studentDetails,
  } = useSelector((state) => state.studentDetails);
  const { cards } =
    subject === 'science' ? sciencePlans.premium : plans.premium;

  useEffect(() => {
    if (
      !isNil(studentDetails) &&
      isNil(userNotification) &&
      !featureToggle.staticNotification
    ) {
      dispatch(
        getUserNotification({
          countryCode,
          defaultLevel,
          nonExpiredSubscriptionPlans,
          subscriptionData,
          allPlans: studentDetails.Subscriptions,
        })
      );
    }
    if (featureToggle.staticNotification && isEmpty(notificationsDetail)) {
      dispatch(getStaticUserNotification());
    }
  }, [
    countryCode,
    defaultLevel,
    dispatch,
    nonExpiredSubscriptionPlans,
    notificationsDetail,
    studentDetails,
    subscriptionData,
    userNotification,
  ]);

  return (
    <>
      <Header />
      <BannerNotification />
      <Container banner={!isNil(bannerNotif)}>
        <img src={LeftBg} className="left-bg" alt="left-bg" />
        <div className="back-btn">
          <BackButton
            backText={t('dashboardCards:back', 'Back')}
            btnBgColor="#00A7C6"
            padding="5px 20px"
            onClick={() => history.push('/dashboard')}
          />
        </div>
        <PageHeading>
          {t(
            'dashboardCards:premiumType.challengesHeader',
            'Challenges and Games'
          )}
        </PageHeading>
        <SubCards>
          <DailyChallengeCard
            isFeatureReady={featureToggle.dailyChallenge}
            isPremium
            subject={subject}
          />
          <CardList>
            {cards.map((card) => (
              <DashboardCard
                key={card.name}
                name={card.name}
                background={card.bg}
                description={card.description}
                link={card.link}
                isFeatureReady={card.isFeatureReady}
                btnColor={card.btnColor}
              />
            ))}
          </CardList>
        </SubCards>
        <img src={RightBg} className="right-bg" alt="right-bg" />
      </Container>
    </>
  );
};

export default Play;
