import { answerTypeIDs } from 'constants/index';
import { isNil } from 'ramda';

// Function returns true if all long answers are marked right else false
const checkIfAllLongAnswersMarkedRight = (submission) => {
  // Finds the first occurance of answer in the submissison value-key pairs that is not marked right
  const notMarkedLongAnswer = submission.find(
    (answer) =>
      answer.ismarkedright === false &&
      answerTypeIDs[answer.AnswerType] === 'FreeText'
  );
  return isNil(notMarkedLongAnswer);
};

export const countNoOfLongAnswersNotMarkedRight = (submissionAnswers) => {
  return submissionAnswers.filter(
    (answer) =>
      answer.ismarkedright === false &&
      answerTypeIDs[answer.AnswerType] === 'FreeText'
  ).length;
};

export default checkIfAllLongAnswersMarkedRight;
