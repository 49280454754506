import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 16%);
  }
  p {
    margin-bottom: 0px;
    margin-left: 0.5rem;
    font-size: 18px;
    font-weight: bold;
  }
`;

export const StatusContainer = styled.div`
  border: 1px solid ${(props) => props.border};
  background: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  font-size: 12px;
  padding: 0.1rem 0.5rem;
  margin-left: 1rem;
  margin-top: 5px;
`;
