import handleResponse from 'helpers/handleAPIResponse';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

const updatePreferences = async (planName) => {
  const rawBody = {
    Id: 2,
    Value: planName,
  };
  return await fetch(`${baseUrl}UserPreferences/UpdatePreferences`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update user preferences failed'); // eslint-disable-line
    });
};

export default updatePreferences;
