/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  setType,
  setHighlighter,
  setPencil,
  setDrawingActivation,
  setClearDraw,
} from 'store/assignment/drawingKitsSlice';

import { hideSelectedTool } from 'store/assignment/assistToolsSlice';

import {
  PencilRed,
  PencilRedHide,
  PencilBlue,
  PencilBlueHide,
  PencilGreen,
  PencilGreenHide,
  HighlightYellow,
  HighlightYellowHide,
  HighlightOrange,
  HighlightOrangeHide,
  Eraser,
  ClearAll,
  ArrowClose,
  EraserHide,
} from 'assets/img/assist-tools/drawing-kits';
import { Container } from './DrawingKits.styles';

const PENCIL_KITS = {
  red: {
    default: PencilRedHide,
    active: PencilRed,
    color: '#FF5A26',
  },
  green: {
    default: PencilGreenHide,
    active: PencilGreen,
    color: '#00D941',
  },
  blue: {
    default: PencilBlueHide,
    active: PencilBlue,
    color: '#00A1F7',
  },
};

const HIGHTLIGHT_KITS = {
  yellow: {
    default: HighlightYellowHide,
    active: HighlightYellow,
    color: 'rgba(254, 235, 0, 0.06)',
  },
  orange: {
    default: HighlightOrangeHide,
    active: HighlightOrange,
    color: 'rgba(255, 156, 72, 0.06)',
  },
};

const DrawingKits = ({ slide, setSlide, setFade }) => {
  const dispatch = useDispatch();
  const { type, pencilColor, highlighterColor } = useSelector(
    (state) => state.drawingKits
  );

  const [selectedPencilColor, setSelectedPencilColor] = useState('red');
  const [selectedHighlightColor, setSelectedHighlightColor] = useState(
    'yellow'
  );

  function findKitByColor(kits, color) {
    const entries = Object.entries(kits);
    // eslint-disable-next-line no-restricted-syntax
    for (const key in kits) {
      if (kits[key].color === color) {
        return key;
      }
    }

    return entries.length > 0 ? entries[0][0] : null;
  }

  useEffect(() => {
    const initialPencilColor = findKitByColor(PENCIL_KITS, pencilColor);
    const initialHighlightColor = findKitByColor(
      HIGHTLIGHT_KITS,
      highlighterColor
    );
    setSelectedHighlightColor(initialHighlightColor);
    setSelectedPencilColor(initialPencilColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickHandler = (variant, selectedType) => {
    const currentColor =
      selectedType === 'pencil' ? selectedPencilColor : selectedHighlightColor;
    const keys = Object.keys(variant);
    const currentIndex = keys.indexOf(currentColor);
    const nextIndex = (currentIndex + 1) % keys.length;

    if (selectedType === type) {
      if (selectedType === 'pencil') {
        dispatch(setPencil(variant[keys[nextIndex]].color));
        setSelectedPencilColor(keys[nextIndex]);
      } else {
        dispatch(setHighlighter(variant[keys[nextIndex]].color));
        setSelectedHighlightColor(keys[nextIndex]);
      }
    }
    dispatch(setType(selectedType));
  };

  return (
    <Container slide={slide}>
      <img
        src={
          PENCIL_KITS[selectedPencilColor][
            type === 'pencil' ? 'active' : 'default'
          ]
        }
        alt="pencil"
        onClick={() => clickHandler(PENCIL_KITS, 'pencil')}
      />
      <img
        src={
          HIGHTLIGHT_KITS[selectedHighlightColor][
            type === 'highlighter' ? 'active' : 'default'
          ]
        }
        alt="highlighter"
        onClick={() => clickHandler(HIGHTLIGHT_KITS, 'highlighter')}
      />
      <img
        src={type === 'eraser' ? Eraser : EraserHide}
        alt="eraser"
        onClick={() => dispatch(setType('eraser'))}
      />
      <img
        src={ClearAll}
        alt="clear-all"
        className="clear"
        onClick={() => dispatch(setClearDraw(true))}
      />
      <img
        src={ArrowClose}
        alt="close"
        className="close-arrow"
        onClick={() => {
          setSlide('slide-in');
          setFade('fade-out');
          setTimeout(() => {
            dispatch(setDrawingActivation(false));
            dispatch(hideSelectedTool('drawing-kits'));
          }, 300);
        }}
      />
    </Container>
  );
};

export default DrawingKits;
