import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  TitleText,
  WrapButton,
  ButtonGroup,
} from './GroupByControl.style';

const GroupByControl = ({ onClick, selected }) => {
  const groups = ['Default', 'School', 'Level'];
  const { t } = useTranslation(['bookClub']);
  return (
    <Container>
      <TitleText>{t('bookClub:words.groupBy', 'Group By')}</TitleText>
      <WrapButton>
        {groups.map((group) => {
          return (
            <ButtonGroup
              key={group}
              onClick={() => onClick(group)}
              className={selected === group ? 'selected' : null}
            >
              {t(`bookClub:level.${group}`, group)}
            </ButtonGroup>
          );
        })}
      </WrapButton>
    </Container>
  );
};

export default React.memo(GroupByControl);
