import React from 'react';
import { useTranslation } from 'react-i18next';
import Timer from 'components/Assignment/Timer';
import { Container } from './EventStatusText.styles';

const EventStatusText = ({ status, endTime }) => {
  const { t } = useTranslation(['eventList']);
  const displayText = () => {
    switch (status) {
      case 'open':
        return (
          <div>
            {t('eventList:eventCard.timeLeft', 'Time Left')}{' '}
            <span>
              <Timer
                type="event-card"
                isCountdown
                isInvisible={false}
                endTime={endTime}
                useTimezone
              />
            </span>
          </div>
        );
      case 'result':
        return t(
          'eventList:eventCard.clickResult',
          'Click the results & claim your CPs before Fri, 10pm'
        );
      default:
        return t(
          'eventList:eventCard.resultRelease',
          'Result will be released on Monday'
        );
    }
  };

  return <Container>{displayText()}</Container>;
};

export default EventStatusText;
