import styled from 'styled-components';

export const Container = styled.div`
  min-width: 214px;
  height: 100%;
  min-height: 304.71px;
  border-radius: 10px;
  background: white;
  text-align: center;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: ${(props) => (props.isLocked ? 'not-allowed' : 'pointer')};
  border: ${(props) => (props.isActive ? 'solid 3px #FF8316' : 'none')};
  .placeholder {
    margin-top: 1rem;
    flex-basis: 0;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    position: relative;
    height: 100%;
    box-sizing: content-box;
    min-width: 0px;
    min-height: 0px;
    flex-direction: row;
    max-width: 600px;
    margin: auto;
    width: 100%;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e0f6ff;
  font-size: 0.9375rem;
  font-weight: 600;
  border-radius: 10px 10px 0 0;
  padding: 1rem 0.25rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    border-radius: 10px 0 0 10px;
    line-height: 1.1;
    text-align: start;
    font-size: 18px;
    flex-grow: 1;
    justify-content: start;
    padding: 1rem;
    img.placeholder {
      height: auto;
      width: min(105px, 20vw);
      margin-top: 0px;
      object-position: center;
      object-fit: contain;
    }
    span:nth-child(1) {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      span {
        width: 10px;
        height: 10px;
      }
    }
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      img {
        height: 24px;
      }
    }
    div {
      height: 100%;
      max-width: 100px;
      justify-content: space-between;
      padding-bottom: 4px;
      margin-left: 8px;
      padding: 0.6rem 0px;
      span {
        width: 0px;
        display: inherit;
        img {
          height: 26px;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: max(14px, 2.5vw);
    padding: max(0.5rem, 2vw);
    div {
      span {
        img {
          height: max(14px, 3.5vw);
        }
      }
    }
  }
`;

export const InfoSection = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .info {
    color: #2b96a0;
    background: #aae9f4;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.25rem;
  }
  .extra {
    color: #03799d;
    font-size: 0.8125rem;
    margin-right: 0.25rem;
  }
  .koko {
    margin-left: 0.25rem;
    font-size: 0.6875rem;
    color: #070707;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    flex-direction: column;

    margin-bottom: 0rem;
    padding: 0px 2rem;
    div {
      display: flex;
      span {
        white-space: nowrap;
      }
    }
    .koko {
      line-height: 21px;
    }
    .extra {
      margin-right: 0rem;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    flex-direction: column;

    margin-bottom: 0rem;
    padding: 0px max(0.25rem, 4vw);
    div {
      align-items: center;
    }
    .info {
      width: max(12px, 2.5vw);
      height: max(12px, 2.5vw);
      font-size: max(0.5rem, 1.5vw);
    }
    .extra {
      font-size: max(0.6rem, 2.5vw);
    }
    .koko {
      font-size: max(0.5rem, 2vw);
    }

    img {
      width: max(16px, 2.5vw);
      height: max(16px, 2.5vw);
    }
  }
`;

export const OverlayContainer = styled.div`
  width: 214px;
  min-height: 304.71px;
  position: absolute;
  background: rgba(28, 28, 28, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: not-allowed;
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    img {
      left: 0;
      bottom: 7px;
      height: 30px;
    }
  }
  span {
    text-align: left;
    margin-left: 0.5rem;
    color: white;
    font-weight: 600;
    font-size: 0.9375rem;
    flex-basis: 9rem;
  }
  img {
    position: relative;
    left: 160px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
    min-height: -webkit-fill-available;
    top: 0px;
    left: 0px;
    flex-direction: row;
    .info {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      max-width: 182px;
      img {
        left: -20px;
      }
    }
    img {
      position: absolute;
      left: auto;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }
`;
