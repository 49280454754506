import React from 'react';
import { Select } from 'components/AvatarCreator/NamePicker.style';

function GradeSelect({ options, selected, onSelect, invisible }) {
  return (
    <Select
      invisible={invisible}
      value={selected}
      onChange={onSelect}
      style={{ backgroundColor: '#fff', width: 'auto', marginLeft: 'auto' }}
    >
      {options.map((o) => (
        <option value={o.ID} key={o.ID}>
          {o.Name}
        </option>
      ))}
    </Select>
  );
}

export default React.memo(GradeSelect);
