import styled from 'styled-components';

export const Top = styled.div`
  background: #bff187;
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    color: #404447;
    font-size: 25px;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    button {
      border-radius: 50%;
      height: 40px;
      padding: 15px;

      img {
        margin-right: 0.2rem;
      }

      .button-text {
        display: none;
      }
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 5% 10%;
  .card-item {
    .card-details {
      margin-top: 1rem;
      width: 360px;
      padding: 0 15px;
      font-size: 16px;
      color: #404447;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;

    .card-details {
      width: 100% !important;
      text-align: center;
    }
  }
`;
