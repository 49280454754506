import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-color: #707070;
  text-align: ${({ error }) => error && 'center'};
  min-width: ${({ minWidth }) => minWidth};
  .header-section {
    text-align: center;
    background-color: #d8e9f5;
    width: 100%;
    padding: 8px;
    border-radius: 10px 10px 0 0;
    color: #467fa8;
    font-size: 18px;
  }
  .body-section {
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    padding: 10px 20px 30px;
    width: 100%;
  }
  .warning-title {
    color: #ff0000;
    text-align: center;
  }
  .modal-title {
    font-size: 18px;
    margin: 5px 0;
  }
  .modal-desc {
    font-size: 15px;
  }
  .modal-buttons {
    text-align: center;
    margin-top: 20px;
    .btns {
      border: none;
      width: 90px;
      padding: 3px 0;
      border-radius: 20px;
      color: #ffffff;
      font-size: 18px;
    }
    .no-btn {
      background-color: #ff6701;
      :hover {
        background-color: #ff5801;
      }
    }
    .yes-btn {
      background-color: #39c435;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 25px;

      :hover {
        background-color: #2ebf2a;
      }
    }
    .ok-btn {
      background-color: ${(props) => (props.okBtn ? props.okBtn : '#39c435')};
      :hover {
        background-color: ${(props) => (props.okBtn ? '#ff5801' : '#2ebf2a')};
      }
    }
    ${({ isV2Options }) =>
      isV2Options &&
      css`
        .btns {
          border: 1px solid #ff6701;
        }
        .no-btn {
          background-color: white;
          color: #ff7121;
          margin-left: 25px;
          :hover {
            background-color: #fffaf7;
            color: #ff7121;
          }
        }
        .yes-btn {
          background-color: #ff6701;
          margin-left: 0;
          :hover {
            background-color: #ff5800;
          }
        }
      `}
  }
  @media (max-width: 600px) {
    width: 100%;
    min-width: 320px;
  }
`;
