import styled from 'styled-components';

export const Hoverbutton = styled.button`
  border: none;
  background: #ffffff;
  color: ${(props) => props.btnColor};
  height: 2.4rem;
  font-size: 22px;
  border-radius: 1.2rem;
  padding: 5px 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  @media (max-width: 1150px) {
    font-size: 0.8rem;
    height: fit-content;
    span {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 900px) {
    span {
      font-size: 1.2rem;
    }
  }
`;
