import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import allBadge from 'constants/allBadge';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ZodiacCategory = styled.div`
  width: 70%;
  display: flex;
  justify-content: flex-end;
  background-color: #c6efff;
  align-self: center;
  align-items: center;
  padding: 10px 40px;
  .curriculum-date {
    margin-left: 10px;
  }
`;

export const RowCurrentUser = styled(Row)`
  width: 70%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  align-self: center;
  align-items: center;
  margin-bottom: 20px;

  .column-border {
    background-color: #def6ff;
    border-radius: 10px;
    margin: 30px;
  }

  .rank-title {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .current-user-bg {
    background-color: #fafeff;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 20px;
  }

  .current-user-zodiac {
    display: flex;
    flex-direction: row;

    .rank {
      color: ${(props) => allBadge[props.currPos].badgeColor} !important;
      background: url(${(props) => allBadge[props.currPos].bgImg});
      position: absolute;
      margin-top: ${(props) =>
        props.currPos === 'default' ? '-5px' : '-10px'};
      left: ${(props) => (props.currPos === 'default' ? '12px' : '0px')};
      height: ${(props) => (props.currPos === 'default' ? '35px' : '45px')};
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      background-repeat: no-repeat;
      background-size: 50px 100%;
      flex: 1;
      color: #0ab6dc;
    }
    .full-name {
      padding-left: 35px;
      flex: 6;
    }
    .cp-points {
      flex: 3;
    }
    .cp-total-point {
      font-weight: bold;
    }
    .cp-icon {
      padding-right: 0px;
    }
    .superhero-icon {
      padding-left: 10px;
    }
    .last-submission-time {
      flex: 1;
    }
  }
`;
