import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  box-sizing: inherit;
  align-items: ${(props) => (props.type === 'top' ? 'flex-end' : 'flex-start')};
`;

export default Container;
