import styled from 'styled-components';
import Bg from 'assets/img/assignment/assignment-view/time-up-bg.svg';

export const Container = styled.div`
  background: url(${Bg});
  background-size: cover;
  border-radius: 8px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  .text-section {
    text-align: center;
    color: #ff791e;
    font-size: 27px;
    font-weight: bold;
    margin-top: 1rem;
    p {
      margin-bottom: 0rem;
    }
    .desc-text {
      font-size: 16px;
      color: #040404;
      font-weight: normal;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    min-width: 320px;
  }
`;

export const ErrorText = styled.p`
  color: #ff791e;
  font-size: 1rem;
`;
