import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Tabs from 'components/Shared/Tabs';
import PaypalPayment from './PaypalPayment';
import CheckoutForm from './CheckoutForm';
import PlanHeader from './PlanHeader';
import { Container, Card } from './Payment.styles';
import { Back } from './PlanDetails.styles';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment = ({ goBack, paymentDone, paymentFailed }) => {
  const paymentTabs = ['Credit Card', 'PayPal'];
  const [activeTab, setActiveTab] = useState('Credit Card');

  return (
    <>
      <Back onClick={goBack}>&lt;</Back>
      <Container>
        <PlanHeader type={'newStandard'} />
        <Card>
          <Tabs
            data={paymentTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {activeTab === 'Credit Card' && (
            <Elements stripe={stripePromise}>
              <CheckoutForm
                activeTab={activeTab}
                paymentDone={paymentDone}
                paymentFailed={paymentFailed}
              />
            </Elements>
          )}
          {activeTab === 'PayPal' && (
            <PaypalPayment
              activeTab={activeTab}
              paymentDone={paymentDone}
              paymentFailed={paymentFailed}
            />
          )}
        </Card>
      </Container>
    </>
  );
};

export default Payment;
