/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import Spinner from 'components/Shared/Spinner';
import Header from 'components/QnView/QnViewHeader';
import BackButton from 'components/Shared/BackButton';
import BgIntermediate from 'assets/img/kooclass/intermediate/kooclass-background.png';
import KooClassMath from 'assets/img/kooclass/intermediate/kooclass-math.png';
import KooClassScience from 'assets/img/kooclass/intermediate/kooclass-science.png';
import BGText from 'assets/img/kooclass/intermediate/kooclass-text.png';
import ListsSVG from 'assets/img/kooclass/intermediate/lists.svg';
import VideosSVG from 'assets/img/kooclass/intermediate/videos.svg';
import ContentsSVG from 'assets/img/kooclass/intermediate/contents.svg';
import Button from 'components/Shared/Button';
import checkProductSubscription from 'helpers/Intermediate/checkProductSubscription';
import getCourseId from 'helpers/Intermediate/getCourseId';
import {
  Container,
  ProductItem,
  ProductListContainer,
  StyledHeader,
} from './IntermediatePage.styles';

const IntermediatePage = () => {
  const { t } = useTranslation(['kooClassIntermediate', 'common']);
  const history = useHistory();
  const { isLoading, subject, products } = useSelector((state) => state.plan);
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const [options, setOptions] = useState([]);

  const items = [
    {
      id: 1,
      subject: 'Math',
      name: t('kooClassIntermediate:math.title', 'KooClass - Math'),
      description: t(
        'kooClassIntermediate:math.description',
        'Where Learning Comes to Life'
      ),
      imageUrl: KooClassMath,
      redirectUrl: '',
      totalTopics: 28,
      lessons: 107,
      activities: 869,
    },
    {
      id: 2,
      subject: 'Science',
      name: t('kooClassIntermediate:science.title', 'KooClass - Science'),
      description: t(
        'kooClassIntermediate:science.description',
        'Where Learning Comes to Life'
      ),
      imageUrl: KooClassScience,
      redirectUrl: '',
      totalTopics: 42,
      lessons: 126,
      activities: 630,
    },
  ];

  useEffect(() => {
    const getList = () => {
      if (isNil(subject) || isNil(products)) return;
      // eslint-disable-next-line prefer-const
      let arrayList = [];
      products.forEach((product) => {
        const obj = checkProductSubscription(product, 'KooClass');
        if (isNil(obj)) return;
        if (obj.parameters?.IsEnabled !== true) return;
        const itemIndex = items.findIndex(
          (item) => item.subject === product.subject
        );
        if (isNil(itemIndex)) return;
        const getObjId = getCourseId(product);
        items[itemIndex].redirectUrl = `/kooClass/${defaultLevel}/${getObjId}`;
        if (items[itemIndex].subject.toLowerCase() === subject) {
          arrayList = [items[itemIndex], ...arrayList];
        } else {
          arrayList = [...arrayList, items[itemIndex]];
        }
      });
      setOptions(arrayList);
    };
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, products, t]);

  const renderDetails = () => {
    if (isLoading) {
      return <Spinner animation="border" style={{ marginTop: '25px' }} />;
    }

    return (
      <ProductListContainer>
        {options.map((option) => (
          <ProductItem key={option.id}>
            <img
              className="left-content"
              src={option.imageUrl}
              alt={option.name}
              width="208px"
              height="204px"
            />
            <div className="mid-content">
              <div className="mid-main-content">
                <h4 className="open-title">{option.name}</h4>
                <p className="open-description">{option.description}</p>
              </div>
              <div className="bottom-content">
                <div>
                  <h6>
                    {t('kooClassIntermediate:totalTopics', 'Total Topics')}
                  </h6>
                  <div className="content">
                    <img className="topic-img" alt="topics" src={ListsSVG} />
                    <span>{option.totalTopics}</span>
                  </div>
                </div>
                <div>
                  <h6>
                    {t(
                      'kooClassIntermediate:Lessons',
                      {
                        subject: t(
                          `common:subjects.${option.subject.toLowerCase()}`,
                          ''
                        ),
                      },
                      `${option.subject} Lessons`
                    )}
                  </h6>
                  <div className="content">
                    <img alt="lessons" src={VideosSVG} />
                    <span>{option.lessons}</span>
                  </div>
                </div>
                <div>
                  <h6>{t('kooClassIntermediate:activities', 'Activities')}</h6>
                  <div className="content">
                    <img alt="activities" src={ContentsSVG} />
                    <span>{option.activities}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-content">
              <Button
                variant="primary"
                width="200px"
                onClick={() => history.push(option.redirectUrl)}
              >
                {t('kooClassIntermediate:startLessons', 'Start The Lesson')}
              </Button>
            </div>
          </ProductItem>
        ))}
      </ProductListContainer>
    );
  };

  return (
    <Container>
      <StyledHeader>
        <Header page bgColor="#000" headerBg={BgIntermediate}>
          <BackButton
            backText={t('topicSelection:backBtn', 'Back')}
            btnBgColor="#DB5D00"
            padding="5px 20px"
            onClick={() => {
              history.replace('/dashboard');
            }}
            styles={{ position: 'absolute', left: '3%' }}
          />
          <img
            className="bg-text"
            src={BGText}
            alt="brain-game"
            width="208px"
          />
        </Header>
      </StyledHeader>
      {renderDetails()}
    </Container>
  );
};

export default IntermediatePage;
