import styled, { css } from 'styled-components';

export const Container = styled.div`
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.5rem 1.875rem;
  margin-top: ${({ order }) => (order === 1 ? '2.5rem' : '0.8rem')};

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 0.8rem;
    flex-direction: column;
    padding: 0.5rem;
  }
`;

export const SkillDetails = styled.div`
  display: flex;
  width: 65%;
  margin-right: 1rem;
  .no {
    color: #000000;
    font-weight: 600;
    font-size: 1.625rem;
  }
  .desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0.5rem 2rem 0.5rem 0.8rem;
    flex-basis: 65%;
    .video-container {
      display: flex;
      align-items: center;
      gap: 40px;
      .video {
        display: flex;
        color: #9d9d9d;
        font-size: 0.75rem;
        text-decoration: underline;
        img {
          height: 20px;
          margin-right: 0.5rem;
        }
        &:hover {
          color: #000000;
          cursor: pointer;
        }
      }
      .hide-desktop {
        display: none;
      }
    }
  }
  ${({ titles, order }) => {
    if (order === 1) {
      return css`
        .title {
          position: relative;
          &::before {
            width: max-content;
            font-family: 'Linotte';
            font-size: 0.75rem;
            color: #959595;
            display: block;
            position: absolute;
            left: 0;
            top: -38px;
            content: '${titles[0]}';
          }
        }
        .hide-mobile {
          position: relative;
          &::before {
            width: max-content;
            font-family: 'Linotte';
            font-size: 0.75rem;
            color: #959595;
            display: block;
            position: absolute;
            left: 0;
            top: -30px;
            content: '${titles[1]}';
          }
        }
      `;
    }
  }}

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
    margin-right: 0;
    .no {
      font-size: 18px;
    }
    .desc {
      font-size: 14px;
      flex-basis: auto;
      margin: 0 1rem 0.5rem 0.8rem;
      .title {
        ::before {
          display: none;
        }
      }
      .video-container {
        margin-top: 25px;
        .hide-desktop {
          display: block;
          .bar-container {
            margin-top: 0;
          }
        }
      }
    }
    .hide-mobile {
      display: none;
    }
  }
`;

export const StageDetails = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    gap: 0.5rem;
  }
`;

export const Divider = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: block;
    height: 1px;
    background-color: #dddddd;
    margin: 5px -8px 8px;
  }
`;
