import React, { useCallback, useRef, useContext } from 'react';
import { Rnd } from 'react-rnd';
import {
  setEditing,
  changeObjectPosition,
  changeObjectExtra,
  changeObjectSize,
} from 'store/assignment/barModelSlice';
import { useSelector, useDispatch } from 'react-redux';
import useOnClickOutside from 'helpers/useOnClickOutside';
import { PositioningResize, PositioningMove } from './Positioning.style';
import ColorPickerContext from '../ColorPickerContext';
import ResizeHandle from '../ResizeHandle';

function Positioning({
  id,
  x,
  y,
  size,
  zIndex,
  vertical,
  height,
  children,
  dragHandleClass,
  componentRef,
  bottomRightOnly,
}) {
  const { activeId } = useSelector((state) => state.barModel);
  const dispatch = useDispatch();
  const objectRef = useRef(null);
  const colorPickerRef = useContext(ColorPickerContext);
  const onClickOutside = useCallback(() => {
    if (activeId !== null && activeId === id) {
      dispatch(setEditing(null));
    }
  }, [activeId, dispatch, id]);
  useOnClickOutside(
    !bottomRightOnly
      ? [objectRef, componentRef, colorPickerRef]
      : [componentRef, colorPickerRef],
    [
      'colorPickerIcon',
      'breakIntoPart',
      'barModelHandle',
      'barModelComponent',
      'deleteButton',
    ],
    onClickOutside
  );
  const isActive = activeId === id;
  const onResize = useCallback(
    (e, direction, ref, delta, position) => {
      dispatch(
        changeObjectSize({
          id,
          size: vertical ? ref.offsetHeight : ref.offsetWidth,
        })
      );
      if (bottomRightOnly) {
        dispatch(
          changeObjectExtra({
            id,
            extra: { height: ref.offsetHeight },
          })
        );
      }
      dispatch(changeObjectPosition({ id, x: position.x, y: position.y }));
    },
    [bottomRightOnly, dispatch, id, vertical]
  );
  const onDragStart = useCallback(() => {
    if (activeId !== id) {
      dispatch(setEditing(id));
    }
  }, [activeId, dispatch, id]);
  const onDragStop = useCallback(
    (e, d) => {
      dispatch(changeObjectPosition({ id, x: d.x, y: d.y }));
    },
    [dispatch, id]
  );
  const resizeHandleComponent = vertical
    ? {
        top: <PositioningResize className="barModelHandle" />,
        bottom: <PositioningResize className="barModelHandle" />,
      }
    : {
        left: <PositioningResize className="barModelHandle" />,
        right: <PositioningResize className="barModelHandle" />,
      };
  return (
    <Rnd
      style={{ zIndex: zIndex + 1 }}
      enableResizing={{
        left: !vertical && isActive && !bottomRightOnly,
        right: !vertical && isActive && !bottomRightOnly,
        top: vertical && isActive && !bottomRightOnly,
        bottom: vertical && isActive && !bottomRightOnly,
        bottomRight: bottomRightOnly === true && isActive,
      }}
      position={{ x, y }}
      size={{
        width: vertical ? height : size,
        height: vertical ? size : height,
      }}
      minWidth={vertical ? undefined : 60}
      minHeight={vertical ? 60 : undefined}
      bounds="parent"
      maxWidth={bottomRightOnly ? 200 : undefined}
      maxHeight={bottomRightOnly ? 200 : undefined}
      onDragStart={onDragStart}
      onResizeStop={onResize}
      onDragStop={onDragStop}
      dragHandleClassName={dragHandleClass}
      resizeHandleComponent={
        bottomRightOnly
          ? { bottomRight: <ResizeHandle small className="barModelHandle" /> }
          : resizeHandleComponent
      }
      resizeHandleStyles={{
        left: { left: -22, width: 20 },
        right: { right: -22, width: 20 },
        bottom: { bottom: -22, height: 20 },
        top: { top: -22, height: 20 },
        bottomRight: { bottom: -2, right: -10 },
      }}
    >
      {isActive && !bottomRightOnly && (
        <PositioningMove className="barModelHandle" ref={objectRef} />
      )}
      {children}
    </Rnd>
  );
}

export default React.memo(Positioning);
