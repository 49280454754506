import styled, { css } from 'styled-components';
import IndicatorArrow from 'assets/img/mission/missionplus/indicator-arrow.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 300;
  margin: 0.5rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin: 0;
    font-size: 12px;
    margin-left: 45px;
  }
`;

export const IndicatorContainer = styled.div`
  background: rgb(255, 255, 255, 0.26);
  border-radius: 1rem;
  display: flex;
  padding: 0.3rem 0.5rem;
  justify-content: space-around;

  ${({ isMissionPlus }) => {
    if (isMissionPlus) {
      return css`
        padding-left: 0.1rem;
        padding-right: 0.7rem;
        background-image: url(${IndicatorArrow});
        background-size: 93% 100%;
        background-repeat: no-repeat;
        background-position-x: 15px;
      `;
    }
  }}

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0.3rem;
  }
`;

export const IndicatorColor = styled.div`
  border-radius: 50%;
  background: ${(props) => props.color};
  width: 12px;
  height: 12px;
  margin: 0.2rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 10px;
    height: 10px;
    margin: 0rem;
  }
`;
