import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { CLASS_ACHIEVEMENTS } from 'pages/Leaderboard/v2/pages/ChampionClass/helper';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';

import ProgressBar from '../ProgressBar';

import { Container, Front, Middle, End } from './ClassAchievement.styles';

const ClassAchievements = ({ currentCP = 0, name }) => {
  const { t } = useTranslation(['leaderboard']);
  const { startCP, nextCP } = CLASS_ACHIEVEMENTS(currentCP);

  return (
    <Container>
      <div className="classic-banner">{name}</div>
      <div className="classic-body">
        <Front className="front-container">
          <div className="star-class">
            {t(
              `leaderboard:championClass.classAchievements.${
                CLASS_ACHIEVEMENTS(currentCP).name
              }`,
              `${CLASS_ACHIEVEMENTS(currentCP).name}`
            )}
          </div>
          <div className="start-cps">
            <Trans i18nKey="leaderboard:championClass.starts" startCP={startCP}>
              <span>Starts from {{ startCP }}</span> CPs
            </Trans>
          </div>
        </Front>
        <Middle className="middle-container">
          <div className="progressing">
            <span className="class-name">{name}</span>
            <img src={CPIcon} alt="cps" />
            <Trans
              i18nKey="leaderboard:championClass.currentCP"
              currentCP={currentCP}
            >
              <span className="total-cp">{{ currentCP }}</span>
              <span className="cps"> CPs</span>
            </Trans>
          </div>
          <ProgressBar points={currentCP} />
        </Middle>
        <End className="end-container">
          <div className="gold-class">
            {t(
              `leaderboard:championClass.classAchievements.${
                CLASS_ACHIEVEMENTS(currentCP).nextName
              }`,
              `${CLASS_ACHIEVEMENTS(currentCP).nextName}`
            )}
          </div>
          <div className="total-cps">
            <Trans i18nKey="leaderboard:championClass.nextCP" nextCP={nextCP}>
              <span>{{ nextCP }}</span> CPs
            </Trans>
          </div>
        </End>
      </div>
    </Container>
  );
};

export default ClassAchievements;
