import React from 'react';
import { Icon } from './DifficultyChillis.styles';

const DifficultyChillis = ({ value, className = '' }) => {
  // Set difficulty to 2 peppers if value = 0
  const filteredValue = value <= 0 ? 3 : value;
  const Chillis = [];
  for (let index = 1; index <= 9; index += 2) {
    Chillis.push(<Icon key={index} isActive={index <= filteredValue} />);
  }
  return <span className={`chillis ${className}`}>{Chillis}</span>;
};

export default DifficultyChillis;
