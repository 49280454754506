import React from 'react';
import { useTranslation } from 'react-i18next';
import StarIcon from 'assets/img/mission/white-star.png';
import InactiveStarIcon from 'assets/img/mission/highscore-star-inactive.png';
import { Container, ToggleItem } from './ViewToggle.styles';

const ViewToggle = ({ viewType, setViewType }) => {
  const { t } = useTranslation(['selfPractice']);
  return (
    <Container viewType={viewType}>
      <ToggleItem
        active={viewType === 1}
        index={0}
        onClick={() => {
          setViewType(1);
        }}
      >
        <p>{t('selfPractice:toggle.proficiency', 'Proficiency')}</p>
        <p>%</p>
      </ToggleItem>
      <ToggleItem
        active={viewType === 2}
        index={1}
        onClick={() => {
          setViewType(2);
        }}
      >
        <p>{t('selfPractice:toggle.highScore', 'High Score')}</p>
        <img
          src={viewType === 2 ? StarIcon : InactiveStarIcon}
          alt=""
          className="star-icon"
        />
        <img
          src={viewType === 2 ? StarIcon : InactiveStarIcon}
          alt=""
          className="star-icon"
        />
        <img
          src={viewType === 2 ? StarIcon : InactiveStarIcon}
          alt=""
          className="star-icon"
        />
      </ToggleItem>
    </Container>
  );
};

export default ViewToggle;
