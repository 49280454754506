const durationDisplay = (durationType, type) => {
  const formattedDurationType = durationType.toLowerCase();
  if (formattedDurationType === 'yearly') {
    switch (type) {
      case 1:
        return 'Annual';
      case 2:
        return formattedDurationType;
      default:
        return formattedDurationType;
    }
  }
  if (formattedDurationType === 'monthly') {
    switch (type) {
      case 1:
        return 'Monthly';
      case 2:
        return formattedDurationType;
      default:
        return formattedDurationType;
    }
  }
  return '';
};

export default durationDisplay;
