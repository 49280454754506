import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import { fetchSupervisionData, postSupervisionScore } from 'services/superhero';
import { superVisionOptions } from 'constants/index';

let timerInterval;

// ASYNC THUNKS
export const getSupervisionData = createAsyncThunk(
  'superVision/getSupervisionData',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchSupervisionData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get supervision data failed');
    }
  }
);

// ASYNC THUNKS
export const submitSupervisionScore = createAsyncThunk(
  'superVision/submitSupervisionScore',
  async (params) => {
    try {
      if (timerInterval !== null) {
        clearInterval(timerInterval);
      }
      const res = await fetchWrapper(postSupervisionScore, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'submit supervision score failed');
    }
  }
);

// 2 minutes
const { totalTime } = superVisionOptions;

const initialState = {
  win: false,
  lose: false,
  lives: 5,
  points: 0,
  found: [],
  imageLoaded: false,
  baseImg: '',
  afterImg: '',
  differences: [],
  userDifferences: [],
  startTime: '',
  timeRemaining: 0,
  isLoading: false,
  submittingScore: false,
  error: null,
  completed: false,
};

const superVisionSlice = createSlice({
  name: 'superVision',
  initialState,
  reducers: {
    setDefault: (state) => {
      state.win = false;
      state.lose = false;
      state.lives = 5;
      state.found = [];
      state.startTime = new Date().toISOString();
      state.timeRemaining = 0;
      state.isLoading = false;
      state.submittingScore = false;
      state.error = null;
    },
    setWin: (state, action) => {
      state.win = action.payload;
    },
    setLose: (state, action) => {
      state.lose = action.payload;
    },
    setPoints: (state, action) => {
      state.points = action.payload;
    },
    setFound: (state, action) => {
      state.found = action.payload;
    },
    setLives: (state, action) => {
      state.lives = action.payload;
    },
    setImageLoaded: (state, action) => {
      state.imageLoaded = action.payload;
    },
    setBaseImage: (state, action) => {
      state.baseImg = action.payload;
    },
    setAfterImage: (state, action) => {
      state.afterImg = action.payload;
    },
    setDifferences: (state, action) => {
      state.differences = action.payload;
    },
    decrementTime: (state) => {
      const difference = +new Date() - +new Date(state.startTime);
      const remainingSeconds = totalTime - Math.floor(difference / 1000);
      state.timeRemaining = remainingSeconds;
    },
  },
  extraReducers: {
    [getSupervisionData.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSupervisionData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.baseImg = action.payload.BaseImg;
      state.afterImg = action.payload.DiffImg;
      state.differences = action.payload.ActualDifferences;
      state.completed = action.payload.Status === 2;
      state.points = action.payload.Score / 10;
      state.userDifferences = action.payload.UserDifferences;
      if (action.payload.Status === 2) {
        state.found = action.payload.UserDifferences.map((d) => {
          const index = action.payload.ActualDifferences.findIndex(
            (a) =>
              a.XCoordinate === d.XCoordinate && a.YCoordinate === d.YCoordinate
          );
          return index;
        });
      }
    },
    [getSupervisionData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [submitSupervisionScore.pending]: (state) => {
      state.submittingScore = true;
      state.error = null;
    },
    [submitSupervisionScore.fulfilled]: (state) => {
      state.submittingScore = false;
    },
    [submitSupervisionScore.rejected]: (state, action) => {
      state.submittingScore = false;
      state.error = action.error.message;
    },
  },
});

const { reducer, actions } = superVisionSlice;
export const {
  decrementTime,
  setAfterImage,
  setBaseImage,
  setDifferences,
  setFound,
  setImageLoaded,
  setLose,
  setPoints,
  setWin,
  setDefault,
  setLives,
} = actions;

// clear all interval
export const dispose = createAsyncThunk(
  'superVision/dispose',
  async (_, { dispatch }) => {
    dispatch(actions.setDefault());
    dispatch(actions.setImageLoaded(false));
    if (timerInterval !== null) {
      clearInterval(timerInterval);
    }
  }
);

export const startGame = createAsyncThunk(
  'superVision/startGame',
  async (_, { dispatch, getState }) => {
    dispatch(actions.setDefault());
    dispatch(actions.setPoints(0));
    timerInterval = setInterval(() => {
      dispatch(actions.decrementTime());
      const { timeRemaining } = getState().superVision;
      if (timeRemaining <= 0) {
        dispatch(actions.setLose(true));
      }
    }, 1000);
  }
);

export default reducer;
