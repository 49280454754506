import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';
import MathCP from 'assets/img/sidepanel/CP.png';
import ScienceCP from 'assets/img/sidepanel/science-cp.png';

export const rewardStyles = {
  math: {
    icon: MathCP,
  },
  science: {
    icon: ScienceCP,
  },
};

export const StyledContainer = styled.div`
  text-align: center;
  background: rgba(255, 255, 255, 0.14);
  color: white;
  border-radius: 1rem;
  padding: 1rem 1rem 1.5rem 1rem;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    flex-direction: row;
    padding: 0px;
    background-color: transparent;
  }
`;

export const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  .reward-icon {
    width: calc(0.03604902667 * 100vw);
    cursor: pointer;
    :hover {
      transform: scale(1.15);
    }
  }
  .reward-value {
    font-size: calc(0.010814708 * 100vw);
    font-weight: 700;
  }
  .reward-name {
    font-size: calc(0.00937274693 * 100vw);
  }
  .reward-info {
    margin-bottom: 0.2rem;
    margin-left: 0.5rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  .img-container {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    flex-direction: row-reverse;
    :first-child {
      margin-right: 30px;
    }
    .reward-icon {
      width: 35px;
    }
    .reward-value {
      font-size: 15px;
    }
    .reward-name {
      font-size: 11px;
    }
    .img-container {
      flex-direction: row;
      margin-left: 10px;
      align-items: center;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    :first-child {
      margin-right: 20px;
    }
    .reward-icon {
      width: 23px;
    }
    .reward-info {
      display: none;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    border-radius: 14px;
    max-width: 230px;
    text-align: start;
    margin-right: 20px;
  }
  span {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 5px;
    display: inline-block;

    a {
      color: #ffcb08;
      text-decoration: underline;
    }
  }
`;
