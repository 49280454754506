import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BackButton from 'components/Shared/BackButton';
import moment from 'moment-timezone';
import HeaderTitle from 'assets/img/leaderboard/top-brain/science/top-brain-title2.png';
import {
  Topbar,
  CenterBar,
  Terms,
  ButtonContainer,
} from './TopBrainHeader.styles';
import ChallengeType from '../../ChallengeType';

const terms = [
  { bgColor: '#A6E500' },
  { bgColor: '#A6E500' },
  { bgColor: '#A6E500' },
  { bgColor: '#A6E500' },
];

const TopBrain = ({ currentTerm, changeCurrentTerm }) => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  const { topBrainTerms, topBrainSeasonsLoading } = useSelector(
    (state) => state.topBrain
  );
  return (
    <Topbar>
      <ButtonContainer>
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(109, 177, 0, 0.77)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <CenterBar>
        <div className="middle-content">
          <img src={HeaderTitle} width="444px" alt="header-title" />
        </div>
        <Terms>
          {topBrainSeasonsLoading ? (
            <div>Loading..</div>
          ) : (
            topBrainTerms.map((item, i) => (
              <div
                data-cy={`term-${i + 1}`}
                className={
                  item.TermId === currentTerm.TermId ? 'active-term' : null
                }
                style={{ background: terms[i].bgColor }}
                onClick={() => changeCurrentTerm(item.TermId)}
                key={item.TermId}
              >
                {t(
                  'leaderboard:topBrainBanner.term',
                  { number: item.TermId },
                  `Term ${item.TermId}`
                )}
              </div>
            ))
          )}
        </Terms>
        <div className="scoring-period">
          <div>
            <span>
              {t('leaderboard:topBrainBanner.scoringPeriod', `Scoring Period`)}
            </span>
            {t(
              'leaderboard:topBrainBanner.term',
              { number: currentTerm.TermId },
              `Term ${currentTerm.TermId}`
            )}
            {t('leaderboard:topBrainBanner.week', `Week`)}{' '}
            {currentTerm.StartWeek} - {currentTerm.EndWeek}{' '}
            {moment(currentTerm.TermStartDate).format('DD-MMM')}{' '}
            {t('leaderboard:topBrainBanner.to', `to`)}{' '}
            {moment(currentTerm.TermEndDate).format('DD-MMM')}
          </div>
        </div>
      </CenterBar>
      <ChallengeType subject={2} styles={{ top: '12%' }} />
    </Topbar>
  );
};

export default TopBrain;
