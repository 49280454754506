import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'components/Header';
import HOTDBanner from 'components/MonsterShowcase/HOTDBanner';
import GradeSelect from 'components/MonsterShowcase/GradeSelect';
import RankBySelector from 'components/MonsterShowcase/RankBySelector';
import AvatarDisplay from 'components/MonsterShowcase/AvatarDisplay';
import { Button, Spinner } from 'react-bootstrap';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';

import {
  setLevel,
  setRankBy,
  getLevels,
  getShowcase,
} from 'store/avatar/avatarShowcaseSlice';
import { typeWithLevel } from 'services/avatarShowcase';
import useAvatarBodyParts from 'helpers/useAvatarBodyParts';
import { AvatarsGrid, AvatarErrorContainer } from './index.style';

const MonsterShowcase = () => {
  const { t } = useTranslation(['kokomonster']);
  useAvatarBodyParts();
  const dispatch = useDispatch();
  const {
    levels,
    rankBy,
    avatars,
    level,
    loadingAvatars,
    errorAvatars,
  } = useSelector((s) => s.avatarShowcase);
  useEffect(() => {
    dispatch(getLevels());
    dispatch(getStudentDetails());
  }, [dispatch]);
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const Subscriptions = studentDetails && studentDetails.Subscriptions;
  useEffect(() => {
    if (level == null && levels.length > 0) {
      if (Subscriptions && Subscriptions.length > 0) {
        dispatch(setLevel(Subscriptions[0].LevelId));
        dispatch(getShowcase());
      }
    }
  }, [Subscriptions, dispatch, level, levels.length]);
  const onChangeLevel = useCallback(
    (e) => {
      if (e.target.value !== level) {
        dispatch(setLevel(e.target.value));
        dispatch(getShowcase());
      }
    },
    [dispatch, level]
  );
  const onChangeRankBy = useCallback(
    (e) => {
      if (e !== rankBy) {
        dispatch(setRankBy(e));
        dispatch(getShowcase());
      }
    },
    [dispatch, rankBy]
  );
  return (
    <div>
      <Header loading={false} />
      <div className="container">
        <HOTDBanner />
        <GradeSelect
          invisible={!typeWithLevel.includes(rankBy)}
          options={levels}
          selected={level}
          onSelect={onChangeLevel}
        />
        <RankBySelector onChange={onChangeRankBy} active={rankBy} />
        {loadingAvatars && (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 11,
              top: '50%',
            }}
          >
            <Spinner
              animation="border"
              style={{ marginTop: '15px', display: 'block' }}
            />
          </div>
        )}
        {errorAvatars && (
          <AvatarErrorContainer>
            <p>{errorAvatars}</p>
            <Button onClick={() => dispatch(getShowcase())}>
              {t('kokomonster:body.reload', 'Reload')}
            </Button>
          </AvatarErrorContainer>
        )}
        {errorAvatars == null && (
          <AvatarsGrid>
            {avatars.map(
              (a, i) =>
                a.Body != null && (
                  <AvatarDisplay
                    key={a.ID}
                    body={a.Body.Img}
                    cps={a.TodayCP}
                    eyes={a.Eye.Img}
                    horn={a.Horn.Img}
                    mouth={a.Mouth.Img}
                    name={a.Name}
                    rank={i}
                    school={a.SchooldName}
                    schoolLogo={a.SchoolLogo}
                    backgroundColor={a.BackGroundColor}
                    time={a.LastCPDate}
                  />
                )
            )}
          </AvatarsGrid>
        )}
      </div>
    </div>
  );
};

export default React.memo(MonsterShowcase);
