import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 25%;
  -webkit-box-pack: ${(props) => (props.type === 'top' ? 'end' : 'start')};
  justify-content: ${(props) =>
    props.type === 'top' ? 'flex-end' : 'flex-start'};
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  ${(props) =>
    props.type === 'top' ? 'margin-bottom: 25px' : 'margin-top: 25px'};
  box-sizing: inherit;

  &.devider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    :after {
      content: '';
      height: 100vh;
      width: 1px;
    }
  }
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: 400;
  text-align: center;
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  -webkit-box-direction: normal;
`;
