import store from 'store/index';
import { featureToggle } from 'constants/index';
import eventTrackingConstants, { applicationIdConstants } from './constant';

const sendEventTracking = (eventPage, eventAction, params = {}) => {
  const url = 'https://tracking.koobits.com/kpx.gif';
  const shouldSend = featureToggle.eventTracking;
  const constants = eventTrackingConstants[eventPage]?.[eventAction];
  const envEventTracking = process.env.REACT_APP_EVENT_TRACKING === 'true';
  if (!shouldSend) return;
  if (!constants) {
    console.error('user tracking event constants not found');
    return;
  }
  // get all params
  const data = {
    ei: constants.event_id,
    app: applicationIdConstants.SP001,
    ui: store.getState().login.userID || params.userID,
    si: store.getState().login.sessionID || params.userID,
    ...params,
  };
  const resultParams = `?${new URLSearchParams(data)}`;
  const urlWithParams = url + resultParams;

  const loadPixelImg = () => {
    const img = new Image();
    img.src = urlWithParams;
    img.style.display = 'none';
    document.body.appendChild(img);
  };

  const sendBeaconData = () => {
    if (envEventTracking) {
      const formData = new FormData();
      formData.append('attributes', data);
      try {
        const result = navigator.sendBeacon(urlWithParams, formData);
        if (!result) {
          console.log('Sending beacon failed.');
          loadPixelImg();
        }
      } catch (e) {
        console.log('Sending beacon error.');
        loadPixelImg();
      }
    }
  };

  return sendBeaconData();
};

export default sendEventTracking;
