import styled from 'styled-components';

export const StyledStepOneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin-left: 3rem;
  margin-right: 3rem;
  min-height: 600px;

  .main-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .paragraph {
    margin-left: 1.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }

  .occupation {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }

  .occupation-img {
    padding: 10px;
    cursor: pointer;
  }
`;

export const StyledStepTwoContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 600px;

  .submit-error {
    padding-top: 0.2rem;
    margin-right: 1rem;
    margin-bottom: -0.3rem;
    color: #ff7020;
  }
`;

export const StyledBackButton = styled.div`
  display: flex;
  align-self: flex-start;
  /* border-bottom: 3px solid #394e53; */
  width: 100%;

  .back-icon {
    all: unset;
    /* border-right: 3px solid #394e53; */
    padding: 0.5rem;
    margin-left: 0.2rem;
    cursor: pointer;
  }

  .back-text {
    color: #fff479;
    margin-top: 0.4rem;
    padding: 0.5rem;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  align-self: center;

  .remain-occupation {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .selected {
    border: 3px solid #000000;
    background-color: transparent;
    border-radius: 50%;
    margin-top: -0.25rem;
  }
`;

export const StyledOccupation = styled.div`
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 1rem;

  h1 {
    font-family: 'Linotte';
    font-weight: 600;
  }

  p {
    font-family: 'Linotte';
    font-weight: 400;
    height: 200px;
  }
  .note {
    margin-left: 2.5rem;
  }
`;

export const StyledJoinedButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .join-button {
    margin-top: 1rem;
    margin-left: 2rem;
  }

  .important-note {
    margin-top: 1rem;
  }
`;
