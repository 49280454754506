import styled from 'styled-components';
import { kooClassImages } from 'constants/kooClass';

export const Container = styled.div`
  height: 200px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('${(props) => kooClassImages[props.imgType]}');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  .title {
    color: #00a37e;
    font-size: 38px;
    font-weight: bold;
    margin-right: ${(props) => (props.margin ? props.margin : '2rem')};
    margin-top: -3rem;
    @media (max-width: 1024px) {
      margin-top: -8rem;
      font-size: 32px;
      margin-right: 5%;
    }
  }
  .completed-sign {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8%;
    margin-top: -1.2rem;
    @media (max-width: 1024px) {
      margin-right: 5%;
    }
    img {
      z-index: 2;
      @media (max-width: 1024px) {
        width: 20%;
      }
    }
    .text {
      background: #5cd908;
      font-size: 19px;
      font-weight: bold;
      border-radius: 22px;
      color: #ffffff;
      padding: 0.5rem 2rem 0.5rem 2.5rem;
      margin-left: -2rem;
      @media (max-width: 1024px) {
        font-size: 15px;
        padding: 0.2rem 1.5rem 0.2rem 2.5rem;
      }
    }
  }
`;
