import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  place-items: center;
  .bg-text {
    margin-top: 0.5rem;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const ProductListContainer = styled.div`
  margin-top: 80px;
`;

export const ProductItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.07);

  .left-content {
    margin: 1rem;
  }

  .mid-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    border-right: 1px solid #c9c9c9;
    min-width: 430px;

    .mid-main-content {
      margin-bottom: 4rem;

      .open-title {
        font-size: 28px;
        font-weight: 600;
        margin: 0px;
      }

      .open-description {
        margin: 0px;
        color: #585858;
      }
    }

    .bottom-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 350px;

      h6 {
        color: rgba(112, 112, 112, 0.9);
        margin-bottom: 0px;
      }

      span {
        margin-left: 0.5rem;
        font-size: 16px;
      }

      .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      .topic-img {
        margin-top: -0.1rem;
      }
    }
  }

  .right-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    width: 250px;
  }
`;
