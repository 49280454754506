import styled from 'styled-components';
import TopBg from 'assets/img/braingame/top.png';

export const TopHeader = styled.div`
  background: url(${TopBg}) no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
  height: 110px;
  display: flex;
  justify-content: space-between;
`;

export const ItemHeader = styled.div`
  flex: 1;
  align-items: flex-start;
`;

export const WrapImage = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
`;

export const LogoTitle = styled.img`
  margin-top: 35px;
`;

export const BackButton = styled.button`
  background: rgba(11, 11, 11, 0.24);
  cursor: pointer;
`;
export const InfoKoko = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: 0.25rem;
  padding-right: 4rem;
  img {
    width: 54px;
    padding: 8px;
  }
  span {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
  }
  @media (max-width: 1175px) {
    padding-right: 3rem;
  }
  @media (max-width: 1111px) {
    padding-right: 2rem;
  }
  @media (max-width: 1047px) {
    padding-right: 1rem;
  }
`;

export const LineDivider = styled.div`
  height: 26px;
  margin: 0 2rem;
  border-right: 1px solid white;
`;

export const Timer = styled.div`
  color: white;
  text-align: center;
  p {
    margin: 0;
  }
  .header {
    font-weight: 600;
    font-size: 0.875rem;
  }
  .time {
    font-size: 1.875rem;
    font-weight: 700;
  }
`;
