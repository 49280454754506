export const proficiencyColors = {
  mastery: {
    color: '#FBDA38',
    borderColor: '#CBAA0A',
  },
  competent: {
    color: '#A9E9A4',
    borderColor: '#58BE51',
  },
  developing: {
    color: '#7DD3FF',
    borderColor: '#299AD4',
  },
  beginning: {
    color: '#FF8282',
    borderColor: '#B93A3A',
  },
  incomplete: {
    color: '#EFEFEF',
    borderColor: '#B3CCD6',
  },
};

// For Proficiency Report
const negativeOne = -1;
export const ProficiencyStatus = {
  1: 'beginning',
  2: 'developing',
  3: 'competent',
  4: 'mastery',
  0: 'incomplete',
};

// Grade for High Score Report
export const MasteryLevel = {
  0: 'D',
  1: 'C',
  2: 'B',
  3: 'A',
  [negativeOne]: 'incomplete',
};
