import styled from 'styled-components';

export const Container = styled.div`
  color: ${(props) => (props.color ? props.color : 'none')};
  background: ${(props) => (props.bgColor ? props.bgColor : 'none')};
  padding: 0.2rem 2rem;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0px'};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  margin: ${(props) => (props.margin ? props.margin : '0rem')};
  margin-bottom: 0rem;
  text-align: center;
  max-width: 700px;
  @media (max-width: 980px) {
    font-size: 12px;
  }
`;
