import styled from 'styled-components';
import ChildDetail from 'assets/img/parent-portal/icon-child-detail.svg';
import Bill from 'assets/img/parent-portal/icon-bill.svg';
import ParentDetail from 'assets/img/parent-portal/icon-parent-detail.svg';
import Nav from 'assets/img/parent-portal/icon-next.svg';

export const Container = styled.div`
  width: 300px;
  display: flex;
  .tab {
    display: flex;
    justify-content: normal;
    align-items: baseline;
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    .tab {
      flex-direction: row;
      justify-content: space-evenly;
      border-bottom: 1px solid #cddfe5;
    }
  }
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid #cddfe5;
    border-bottom: ${(props) => props.lastItem && '1px solid #cddfe5'};
    padding: 1rem;
    margin-right: 20px;
    min-width: 242px;
    @media (max-width: 768px) {
      min-width: 0;
      border none;
      margin: 0;
      border-bottom: ${({ active }) => active && '3px solid #FF5B00'};
    }
    @media (max-width: 610px) {
      .name {
        display: none;
      }
    }
  }
  p {
    margin-bottom: 0;
    font-weight: ${(props) => props.active && 'bold'};
    font-size: 14px;
  }
  .icon {
    height: 30px;
    width: 35px;
    margin-right: 5px;
  }
  .child-detail {
    background: url(${ChildDetail});
    background-repeat: no-repeat;
  }
  .bill-subscription {
    background: url(${Bill});
    background-repeat: no-repeat;
  }
  .parent-detail {
    background: url(${ParentDetail});
    background-repeat: no-repeat;
  }
  .nav-icon {
    background: url(${Nav});
    background-repeat: no-repeat;
    width: 10px;
    height: 16px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
