import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isNil, isEmpty } from 'ramda';
import { isJsonStringValid } from 'helpers/checkIfJsonStringValid';

import QnText from 'components/Assignment/AssignmentView/QnText';
import Button from 'components/Shared/Button';
import PlayButton from 'components/Shared/ButtonPlay';
import VideoPlayer from 'components/KooClass/VideoPlayer';
import Spinner from 'components/Shared/Spinner';

import Mascot from 'assets/img/kooclass/activity/mascot2.svg';

import { Container, BubbleContainer } from './WatchAndLearn.styles';

const WatchAndLearn = ({ showVideo, setShowVideo }) => {
  const { t } = useTranslation(['activities']);
  const {
    currentActivity,
    currentActivityLoading,
    currentActivityErr,
  } = useSelector((state) => state.kooClass);

  const activityPayload =
    currentActivity && isJsonStringValid(currentActivity.payload)
      ? JSON.parse(currentActivity.payload)
      : '';
  const videoUrl = !isEmpty(activityPayload) ? activityPayload.Url : undefined;

  return (
    <>
      <VideoPlayer videoUrl={videoUrl} showVideo={showVideo} />
      <Container showVideo={showVideo}>
        <QnText
          text={t('activities:watchAndLearn.title', 'Watch and Learn')}
          fontFam="Grilled Cheese"
          color="#9A7C00"
          borderColor=" #ffce00"
          fontSize="47px"
          margin="-4rem"
        />
        {currentActivityLoading && (
          <BubbleContainer>
            <Spinner color="#ffffff" />
          </BubbleContainer>
        )}
        {!currentActivityLoading && (
          <>
            <BubbleContainer>
              <div className="desc">{currentActivity?.description ?? ''}</div>
            </BubbleContainer>
            {isNil(currentActivityErr) && (
              <div className="watch-btn">
                <Button
                  variant="primary"
                  width="100%"
                  fontSize="26px"
                  fontWeight="bold"
                  onClick={() => setShowVideo(true)}
                >
                  <PlayButton
                    variant="light"
                    size="42px"
                    styles={{ marginRight: '1rem' }}
                  />
                  {t('activities:shared.watchBtn', 'Watch')}
                </Button>
              </div>
            )}
          </>
        )}
        <img src={Mascot} className="mascot" alt="mascot" />
      </Container>
    </>
  );
};

export default WatchAndLearn;
