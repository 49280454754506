import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  right: 10px;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      button.primary {
        font-size: 16px;
      }
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    right: 0px;
  }
`;

export const Result = styled.div`
  height: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  top: -42px;
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    top: -50px;
    left: 40%;
    min-width: ${(props) => (props.result === 'draw' ? '200px' : '300px')};
    transform: none;
  }
`;

export const QuestionContainer = styled.div`
  padding: 1rem 2rem;
  border-bottom: ${(props) => (props.isLastQn ? null : '2px dashed #cae3e5')};
  .qn-no {
    font-size: 18px;
    font-weight: 600;
    color: #ff8c19;
    padding-left: 1rem;
    display: flex;
    align-items: center;

    .qn-id {
      color: rgb(57, 57, 57, 0.44);
      font-size: 14px;
      font-weight: 300;
      margin-left: 0.5rem;
      margin-bottom: 0.1rem;
      @media (max-width: 947px) {
        font-size: 12px;
      }
      @media (max-width: 502px) {
        margin-top: 0;
        font-size: 10px;
      }
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      padding: 1rem 0px;
    }
  }
`;

export const ContinueSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;
