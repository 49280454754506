import styled from 'styled-components';
import PracKooQuiz from 'assets/img/dashboard/practice-kooquiz.svg';
import PracAssig from 'assets/img/dashboard/practice-assignment.svg';

export const CardContainer = styled.div`
  border-radius: 20px;
  flex: 1;
  margin-top: ${(props) => (props.type === 'kooQuiz' ? null : '1rem')};
  cursor: pointer;
  position: relative;
  .hover-desc-block,
  .desc-block {
    position: absolute;
    top: 9rem;
    left: ${(props) => (props.type === 'kooQuiz' ? '7rem' : '2rem')};
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    .title {
      color: #ffffff;
      font-size: 1.5rem;
      text-shadow: 2px 2px 2px rgba(168, 58, 0, 0.4);
      line-height: 1;
    }
    .sub-title {
      color: #ffffff;
    }
    @media (min-width: 1700px) {
      top: 9.5rem;
      left: ${(props) => (props.type === 'kooQuiz' ? '7rem' : '2rem')};
    }
    @media (max-width: 950px) {
      top: 8rem;
      left: ${(props) => (props.type === 'kooQuiz' ? '6rem' : '1rem')};
      .title {
        font-size: 1.2rem;
      }
    }
    @media (max-width: 800px) {
      top: 7rem;
      left: ${(props) => (props.type === 'kooQuiz' ? '5rem' : '1rem')};
      font-size: 0.8rem;
    }
  }
  .hover-desc-block {
    position: static;
    padding: 0.2rem 4rem;
    .title {
      font-size: 1.8rem;
      margin-bottom: 10px;
    }
  }
`;

export const Block = styled.div`
  border-radius: 20px;
  background: url(${(props) =>
      props.type === 'kooQuiz' ? PracKooQuiz : PracAssig})
    no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: ${(props) => (props.showOverlay ? 'blur(4px)' : null)};
  -webkit-filter: ${(props) => (props.showOverlay ? 'blur(4px)' : null)};
  box-shadow: 0px 7px 0px 0px rgba(82, 75, 97, 0.28);
`;
