import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sendEventTracking } from 'helpers/UserEventTracking';
import StatusBar from 'components/SuperVision/StatusBar';
import MessageLost from 'components/SuperVision/MessageLost';
import MessageWin from 'components/SuperVision/MessageWin';
import FooterBar from 'components/SuperVision/FooterBar';
import DifferentMap from 'components/SuperVision/DifferentMap';
import { OtherChallengeButton } from 'components/SuperVision/MessageWin.style';
import { Shake } from 'reshake';
import { useTranslation } from 'react-i18next';
import useDashboard from 'hooks/useDashboard';
import {
  getSupervisionData,
  setFound,
  setLose,
  dispose,
  setPoints,
  setImageLoaded,
  setLives,
  startGame,
  setWin,
  submitSupervisionScore,
} from 'store/superVision/superVisionSlice';
import {
  Container,
  InstructionText,
  Wrap,
  GameArea,
  GameImageWrap,
  GameImageContainer,
} from './SuperVisionGame.style';

function secondsToMinute(seconds) {
  const minute = `${Math.floor(seconds / 60)}`.padStart(2, '0');
  const second = `${Math.floor(seconds % 60)}`.padStart(2, '0');
  return `${minute}:${second}`;
}

const SuperVisionGame = () => {
  const history = useHistory();
  const { t } = useTranslation(['superVision']);
  const { isDashboardv2 } = useDashboard();
  const [showAnswer, setShowAnswer] = useState(false);
  const [shaking, setShaking] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(dispose());
    };
  }, [dispatch]);
  const {
    win,
    lose,
    lives,
    points,
    found,
    imageLoaded,
    baseImg,
    afterImg,
    differences,
    completed,
    timeRemaining,
  } = useSelector((state) => state.superVision);
  const setWinFunction = useCallback(
    (p) => {
      dispatch(setWin(p));
    },
    [dispatch]
  );
  const setLoseFunction = useCallback(
    (p) => {
      dispatch(setLose(p));
    },
    [dispatch]
  );

  const setLivesFunction = useCallback(
    (p) => {
      dispatch(setLives(p));
    },
    [dispatch]
  );

  const setPointsFunction = useCallback(
    (p) => {
      dispatch(setPoints(p));
    },
    [dispatch]
  );

  const setFoundFunction = useCallback(
    (p) => {
      dispatch(setFound(p));
    },
    [dispatch]
  );

  const setImageLoadedFunction = useCallback(
    (p) => {
      dispatch(setImageLoaded(p));
    },
    [dispatch]
  );

  const baseImageRef = useRef(null);
  const afterImageRef = useRef(null);
  useEffect(() => {
    dispatch(getSupervisionData());
  }, [dispatch]);
  useEffect(() => {
    if (imageLoaded && !completed) {
      dispatch(startGame());
    }
  }, [completed, dispatch, imageLoaded]);
  useEffect(() => {
    if (win || lose) {
      dispatch(
        submitSupervisionScore({
          score: points * 10,
          userDiff: found.map((f) => differences[f]),
        })
      );
      sendEventTracking('super_vision', 'challenge_submit');
    }
  }, [dispatch, win, lose, points, found, differences]);

  const handleLoadImage = useCallback(() => {
    setImageLoadedFunction(true);
  }, [setImageLoadedFunction]);

  const HandleClickSpot = useCallback(
    (index) => {
      if (!win && !lose && !completed) {
        // console.log({ points, index, differences, found });
        const totalPoints = found.length + 1;
        // const newPoints = points + 1;
        // console.log({ totalPoints });
        setPointsFunction(totalPoints);
        setFoundFunction([...found, index]);

        if (totalPoints === differences.length) {
          setWinFunction(true);
        }
      }
    },
    [
      completed,
      differences,
      found,
      lose,
      setFoundFunction,
      setPointsFunction,
      setWinFunction,
      win,
    ]
  );

  const HandleOuterSpot = useCallback(
    (e) => {
      if (!win && !lose && !completed) {
        if (e.target.classList.contains('area') && !win && !lose) {
          setShaking(true);
          setTimeout(() => {
            setShaking(false);
          }, 500);
          const newLives = lives - 1;
          setLivesFunction(newLives);
          if (newLives === 0) {
            setLoseFunction(true);
          }
        }
      }
    },
    [completed, lives, lose, setLivesFunction, setLoseFunction, win]
  );
  const toggleShowAnswer = useCallback(() => {
    setShowAnswer(!showAnswer);
  }, [showAnswer]);
  const onGoToOtherChallenge = useCallback(() => {
    history.replace(isDashboardv2 ? '/superhero' : '/challenges');
  }, [history, isDashboardv2]);
  if (differences.length === 0) {
    return [];
  }
  return (
    <Container>
      <StatusBar
        showAnswer={showAnswer}
        toggleShowAnswer={toggleShowAnswer}
        completed={completed}
        remainingTime={secondsToMinute(timeRemaining)}
        lives={lives}
        score={points * 10}
      />
      <Wrap>
        {win && (
          <MessageWin
            onGoToOtherChallenge={onGoToOtherChallenge}
            score={points * 10}
          />
        )}
        {lose && (
          <MessageLost
            onGoToOtherChallenge={onGoToOtherChallenge}
            score={points * 10}
          />
        )}

        {!win && !lose && !completed && (
          <InstructionText>
            {t(
              'superVision:game.instructionText',
              `Click on the image to point out the differences between the pictures.`
            )}
          </InstructionText>
        )}

        {differences.length > 0 && (
          <GameArea>
            <GameImageWrap>
              <Shake trigger={false} active={shaking} h={10} v={0} r={3}>
                <GameImageContainer>
                  <img
                    src={`data:image/png;base64,${baseImg}`}
                    ref={baseImageRef}
                    alt="Loading"
                    className="sv-gameImage"
                    onLoad={handleLoadImage}
                  />
                  {imageLoaded && (
                    <DifferentMap
                      completed={completed}
                      differences={differences}
                      found={found}
                      showAnswer={showAnswer}
                      refEl={baseImageRef}
                      onClickSpot={HandleClickSpot}
                      onClick={HandleOuterSpot}
                    />
                  )}
                </GameImageContainer>
              </Shake>
            </GameImageWrap>
            <GameImageWrap>
              <Shake trigger={false} active={shaking} h={10} v={0} r={3}>
                <GameImageContainer>
                  <img
                    src={`data:image/png;base64,${afterImg}`}
                    ref={afterImageRef}
                    alt="Loading"
                    className="sv-afterImage sv-gameImage"
                  />
                  {imageLoaded && (
                    <DifferentMap
                      completed={completed}
                      differences={differences}
                      found={found}
                      showAnswer={showAnswer}
                      refEl={baseImageRef}
                      onClickSpot={HandleClickSpot}
                      onClick={HandleOuterSpot}
                    />
                  )}
                </GameImageContainer>
              </Shake>
            </GameImageWrap>
          </GameArea>
        )}
        {completed && (
          <div style={{ marginTop: -20 }}>
            <OtherChallengeButton onClick={onGoToOtherChallenge}>
              {t('game.goOther', 'Go to other challenges')}
            </OtherChallengeButton>
          </div>
        )}
        {!completed && <FooterBar found={points} />}
      </Wrap>
    </Container>
  );
};

export default React.memo(SuperVisionGame);
