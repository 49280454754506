import styled from 'styled-components';

export const StyledModalContainer = styled.div`
  width: 383px;
  min-height: 191px;
  background: white;
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .closed-img {
    position: absolute;
    top: -2rem;
  }
`;

export const StyledButton = styled.button`
  border: none;
  background: #ff6701;
  font-size: 18px;
  font-weight: 700;
  color: white;
  border-radius: 2rem;
  padding: 4px 15px;
  min-width: 96px;
  margin-bottom: 2rem;
`;

export const StyledHeader = styled.div`
  background: #d8e9f5;
  color: #467fa8;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  text-align: center;
  padding: 8px 0;
  font-size: 18px;
  margin-bottom: 42px;
`;
