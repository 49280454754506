import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import {
  getDailyChallengeStatusBySubjectID,
  getDailyChallengeDetailsBySubjectID,
} from 'store/dailyChallenge/dailyChallengeSlice';
import { questionStatuses } from 'constants/dailyChallenge';
import ChallengeText from 'assets/img/dailychallenge/challenge.png';
import AchievementButton from 'assets/img/dailychallenge/achievement-icon.png';
import LeaderboardButton from 'assets/img/dailychallenge/leaderboard-icon.png';
import CPIcon from 'assets/img/icon-challenge-point.svg';
import Mascot from 'assets/img/dailychallenge/supermikowithruler.svg';
import DailyChallengeText from 'assets/img/dailychallenge/daily-challenge-text.svg';
import DifficultySelection from 'components/DailyChallenge/DifficultySelection';
import ChallengeQuestions from 'components/DailyChallenge/ChallengeQuestions';
import HeroTrophy from 'components/DailyChallenge/HeroTrophy';
import EarnMoreCPsModal from 'components/DailyChallenge/EarnMoreCPsModal';
import ErrorModal from 'components/Shared/ErrorModal';
import Spinner from 'components/Shared/Spinner';
import DatePicker from 'components/DailyChallenge/DatePicker';
import PopupModal from 'components/Shared/PopupModal';
import HeroOfTheDayProgress from 'components/DailyChallenge/Banner/HeroOfTheDayProgress';
import { SubmitModal } from 'components/Shared';
import BackButton from 'components/Shared/BackButton';
import { isNil } from 'ramda';
import useDashboard from 'hooks/useDashboard';
import useIsDesktop from 'hooks/responsive/useIsDesktop';
import {
  WelcomeHeader,
  DefaultHeader,
  EarnMoreCPsButton,
  WelcomeText,
  BannerText,
  Content,
  Legend,
  LegendCircle,
  StyledLoaderWrap,
  StyledDifficultyWarningModalTitle,
} from './DailyChallenge.styles';

const subjectId = 1;
const showAchievementIcon = false;
// Hide hero's trophy
const showHeroTrophy = false;
const DailyChallenge = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallenge']);
  const {
    currDailyChallenge,
    currDailyChallengeLoading,
    currDailyChallengeErr,
    dailyChallengeStatus,
    dailyChallengeStatusErr,
  } = useSelector((state) => state.dailyChallenge);
  const { isDashboardv2 } = useDashboard();
  // COMPONENT STATES
  const [initialDailyChallengeStatus] = useState(dailyChallengeStatus);
  const [showSelection, setShowSelection] = useState(false);
  // const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState(null);
  const [showEarnMoreCPsModal, setShowEarnMoreCPsModal] = useState(false);
  const [
    showChangeDifficultyWarningModal,
    setShowChangeDifficultyWarningModal,
  ] = useState(false);
  // responsive
  const isDesktop = useIsDesktop();

  // EVENT HANDLERS
  const handleHideModal = () => setShowEarnMoreCPsModal(false);
  const handleShowDifficultySelection = () => {
    const skippedOrCompletedQns = currDailyChallenge.UserQuestionSubmissions.filter(
      (submission) =>
        submission.SubmissionModel !== null &&
        (submission.SubmissionModel.SubmissionResult === 1 ||
          submission.QuestionStatus === 4)
    );
    if (skippedOrCompletedQns.length > 0) {
      setShowChangeDifficultyWarningModal(true);
    } else {
      setShowSelection(true);
    }
  };

  useEffect(() => {
    if (
      isNil(initialDailyChallengeStatus) ||
      initialDailyChallengeStatus?.subjectID !== 1
    ) {
      dispatch(getDailyChallengeStatusBySubjectID(subjectId)).then(() => {
        dispatch(getDailyChallengeDetailsBySubjectID(subjectId));
      });
    } else {
      dispatch(getDailyChallengeDetailsBySubjectID(subjectId));
    }
  }, [dispatch, initialDailyChallengeStatus]);

  const renderHeader = () => {
    const renderBackButton = (
      <BackButton
        backText={t('common:back', 'Back')}
        btnBgColor="rgba(43,197,255,0.33)"
        padding="0.3rem 1.2rem"
        isResponsive
        onClick={() => {
          if (isDashboardv2) {
            history.push('/dashboard?view=koochallenge');
          } else {
            history.push('/challenges');
          }
        }}
        styles={{
          position: 'absolute',
          left: '4%',
          top: isDesktop ? '1rem' : '1.7rem',
          zIndex: 999,
        }}
      />
    );
    if (
      currDailyChallenge.IsFirstTimeUser &&
      currDailyChallenge.ChallengeLevel === 0
    ) {
      return (
        <WelcomeHeader>
          {renderBackButton}
          <img src={Mascot} alt="" className="mascot" />
          <BannerText>
            <WelcomeText>
              <span className="welcome">
                {t('dailyChallenge:welcomeBanner.welcome', 'Welcome to')}
              </span>
              <img src={DailyChallengeText} alt="Daily Challenge" />
            </WelcomeText>
            <p className="choose-difficulty">
              {t(
                'dailyChallenge:welcomeBanner.chooseLevel',
                'Choose the level of difficulty'
              )}
            </p>
          </BannerText>
        </WelcomeHeader>
      );
    }
    return (
      <DefaultHeader>
        {renderBackButton}
        <div className="left">
          <img
            onClick={() => {
              if (isDashboardv2) return;
              history.push('/challenges');
            }}
            src={ChallengeText}
            alt="Challenge"
            width="238px"
            height="85px"
          />
          <DatePicker />
        </div>
        <div className="center">
          <HeroOfTheDayProgress
            CPsEarnedToday={currDailyChallenge.TodayCpPoint}
            BadgeUrl={
              currDailyChallenge.BadgeInfo &&
              currDailyChallenge.BadgeInfo.BadgeUrl
            }
          />
          <EarnMoreCPsButton onClick={() => setShowEarnMoreCPsModal(true)}>
            {t(
              'dailyChallenge:banner.clickHere',
              'Click here to earn more CPs'
            )}{' '}
            <img src={CPIcon} alt="CPs" width="20px" height="20px" />
          </EarnMoreCPsButton>
        </div>
        <div className="right">
          {showAchievementIcon && (
            <div className="nav-item">
              <img src={AchievementButton} alt="Achievements" />
              <span>
                {t('dailyChallenge:banner.achievements', 'Achievements')}
              </span>
            </div>
          )}
          <div
            onClick={() => history.push('/leaderboard?subject=math')}
            className="nav-item"
          >
            <img src={LeaderboardButton} alt="Leaderboards" />
            <span>
              {t('dailyChallenge:banner.leaderboards', 'Leaderboards')}
            </span>
          </div>
        </div>
      </DefaultHeader>
    );
  };
  const renderContent = () => {
    if (currDailyChallengeLoading) {
      return (
        <StyledLoaderWrap>
          <Spinner />
        </StyledLoaderWrap>
      );
    }
    if (currDailyChallengeErr || dailyChallengeStatusErr) {
      return (
        <ErrorModal
          backPage={
            isDashboardv2 ? '/dashboard?view=koochallenge' : '/challenges'
          }
          errorMessage={currDailyChallengeErr || dailyChallengeStatusErr}
        />
      );
    }
    return (
      <>
        <Content>
          <Modal show={showEarnMoreCPsModal} onHide={handleHideModal} centered>
            <EarnMoreCPsModal onHide={handleHideModal} />
          </Modal>
          <PopupModal show={showChangeDifficultyWarningModal} backdrop="static">
            <SubmitModal
              header={t(
                'dailyChallenge:modal.header',
                'Daily Challenge Settings'
              )}
              title={
                <StyledDifficultyWarningModalTitle>
                  <p>
                    {t(
                      'dailyChallenge:modal.title',
                      'It seems that you have completed a Daily Challenge question for today.'
                    )}
                  </p>
                  <p>
                    {t(
                      'dailyChallenge:modal.desc',
                      'Changes will only take place tomorrow. Do you want to proceed?'
                    )}
                  </p>
                </StyledDifficultyWarningModalTitle>
              }
              noHandle={() => {
                setShowChangeDifficultyWarningModal(false);
              }}
              yesHandle={() => {
                setShowChangeDifficultyWarningModal(false);
                setShowSelection(true);
              }}
            />
          </PopupModal>
          {((currDailyChallenge.IsFirstTimeUser &&
            currDailyChallenge.ChallengeLevel === 0) ||
            showSelection) && (
            <DifficultySelection
              difficultyLevel={currDailyChallenge.LatestChallengeLevel}
              setShowSelection={setShowSelection}
              showSelection={showSelection}
              maxChallengeLvl={currDailyChallenge.MaxChallengeLevel}
            />
          )}
          {!currDailyChallenge.IsFirstTimeUser &&
            !showSelection &&
            !isNil(currDailyChallenge.ChallengeLevel) && (
              <>
                <ChallengeQuestions
                  difficultyLevel={currDailyChallenge.ChallengeLevel}
                  handleShowDifficultySelection={handleShowDifficultySelection}
                  curriculumName={currDailyChallenge.CurriculumName}
                  legend={
                    <Legend>
                      {Object.keys(questionStatuses).map((qnStatus) => (
                        <span key={questionStatuses[qnStatus].name}>
                          <LegendCircle
                            color={questionStatuses[qnStatus].color}
                          />
                          {t(
                            `dailyChallenge:main.${questionStatuses[qnStatus].name}`,
                            `${questionStatuses[qnStatus].name}`
                          )}
                        </span>
                      ))}
                    </Legend>
                  }
                />

                {showHeroTrophy && <HeroTrophy />}
              </>
            )}
        </Content>
      </>
    );
  };
  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};

export default DailyChallenge;
