import { Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div`
  width: 827px;
  background: #f4fdff;
  border-radius: 20px;
  box-shadow: 0px 3px 3px rgba(150, 150, 150, 0.12);
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: calc(100vw - 2rem);
    max-width: 827px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
    border-radius: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: rgba(203, 242, 255, 0.63);
  padding: 0.5rem 1rem;
  border-radius: 20px 20px 0 0;
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      border-radius: 0px;
      justify-content: center;
      span {
        .btn-settings {
          margin-right: 1rem;
        }
      }
    }
  }
`;

export const DifficultySection = styled.span`
  display: flex;
  .difficulty-text {
    margin-left: 0.5rem;
    .difficulty-name {
      color: #037da2;
      font-size: 1.125rem;
    }
    .difficulty-description {
      color: #66aec4;
      font-size: 0.8125rem;
    }
    p {
      margin: 0;
    }
  }
  .btn-settings {
    all: unset;
    cursor: pointer;
    background-color: #a9ecfe;
    padding: 0.2rem 0.5rem 0.2rem 0.7rem;
    border-radius: 25px;
    border: 2px solid #a9dbe9;
    white-space: nowrap;
    &:hover {
      background-color: #cbf4fe;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      align-items: center;
      justify-content: center;
    }
  }
`;
export const StyledCurriculum = styled.div`
  display: flex;
  color: #66aec4;
  align-items: center;
  p {
    line-height: 1;
    margin: 3px 8px 0 0;
    font-size: 12px;
  }
  svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    justify-content: center;
    padding-bottom: 2rem;
  }
`;
export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    border-radius: 14px;
  }
  span {
    font-size: 14px;
    font-weight: bold;
    padding: 18px 10px;
    display: inline-block;
  }
`;
export const QuestionsSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding: 1rem 2rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding: 2rem;
    grid-template-columns: 1fr;
  }
`;

export const QuestionsSubSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    &:nth-child(1) {
      margin-right: 1rem;
      align-items: flex-end;
    }
    &:nth-child(2) {
      margin-left: 1rem;
      align-items: flex-start;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    &:nth-child(1) {
      margin-right: 0px;
      align-items: center;
    }
    &:nth-child(2) {
      margin-left: 0px;
      align-items: center;
    }
  }
`;
