import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  z-index: 1;
  margin-top: 59px;
  .alert {
    z-index: 20 !important;
  }
`;
