import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Category, Info } from './DetailsInfo.styles';

const DetailsInfo = ({
  page,
  direction,
  category,
  info,
  categoryColor,
  infoColor,
  fontSizeCategory,
  fontSizeInfo,
  dataCy,
  minWidth,
  isMobile,
}) => {
  const { t } = useTranslation(['startPage']);
  return (
    <Container page={page} direction={direction} isMobile={isMobile}>
      <Category
        color={categoryColor}
        fontSize={fontSizeCategory}
        minWidth={minWidth}
      >
        {t(`startPage:words.${category}`, `${category}`)}
      </Category>
      <Info color={infoColor} fontSize={fontSizeInfo} data-cy={dataCy}>
        {info}
      </Info>
    </Container>
  );
};

export default DetailsInfo;
