import React, { useRef } from 'react';
import topPart from 'assets/img/assignment/bar-model/hk-v-top.png';
import bottomPart from 'assets/img/assignment/bar-model/hk-v-bottom.png';
import centerPart from 'assets/img/assignment/bar-model/hk-v-center.png';
import linePart from 'assets/img/assignment/bar-model/hk-v-middle.png';
import { barModelOptions } from 'constants/index';
import Positioning from './Positioning';
import { VerticalContainer, VSinglePart, VRepeatPart } from './Component.style';

const { components } = barModelOptions;
const { verticalBraceWidth } = components;

function InvertedBrace({ size, x, y, zIndex, id }) {
  const componentRef = useRef(null);
  return (
    <Positioning
      componentRef={componentRef}
      x={x}
      y={y}
      id={id}
      size={size}
      zIndex={zIndex}
      height={verticalBraceWidth}
      vertical
    >
      <VerticalContainer
        className="vertical barModelComponent"
        ref={componentRef}
      >
        <VSinglePart image={topPart} width={8} />
        <VRepeatPart image={linePart} />
        <VSinglePart image={centerPart} width={11} />
        <VRepeatPart image={linePart} />
        <VSinglePart image={bottomPart} width={8} />
      </VerticalContainer>
    </Positioning>
  );
}

export default React.memo(InvertedBrace);
