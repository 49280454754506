import styled from 'styled-components';

export const StyledOTP = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .otp-button {
    margin-top: 2rem;
  }

  .link {
    margin-top: 1rem;
    background: none !important;
    border: none;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #1975a3;
    }
  }

  .error {
    color: red;
  }
`;

export const StyledOTPError = styled.div`
  color: red;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0rem;
`;
