import React from 'react';
import { InpContainer } from './InputField.styles';

const InputField = ({
  style,
  dataCy,
  type,
  val,
  changeVal,
  disabled,
  placeholder,
}) => {
  return (
    <InpContainer
      style={style}
      data-cy={dataCy}
      type={type}
      val={val}
      onChange={changeVal}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default InputField;
