const compareAnswerKeyValuePairs = (
  questionType,
  savedAnswer,
  submissionAnswer,
  qnID,
  assignmentType
) => {
  let valName = 'Value';
  let keyName = 'Key';
  if (assignmentType === 'KooClass') {
    valName = 'value';
    keyName = 'key';
  }
  // Check if array lengths are the same
  if (Object.keys(savedAnswer).length !== submissionAnswer.length) {
    return false;
  }
  for (const keyValuePair of submissionAnswer) {
    const localKeyName =
      questionType === 'mcq' ? null : `${qnID}-${keyValuePair[keyName]}`;
    // TODO: Separate logic for fill-in-the-blanks/long answers from mcq
    // For mcq, there is no values, so we are just comparing the keys
    if (questionType === 'mcq') {
      // Check if submitted answer key found in saved answers array
      if (savedAnswer.includes(keyValuePair[keyName]) === false) {
        return false;
      }
    } else {
      if (
        savedAnswer.hasOwnProperty(localKeyName) === false &&
        savedAnswer.hasOwnProperty(
          `freetext-${keyValuePair[keyName]}` === false
        )
      ) {
        return false;
      }
      if (
        savedAnswer.hasOwnProperty(localKeyName) === true &&
        savedAnswer[localKeyName] !== keyValuePair[valName]
      ) {
        return false;
      }
      if (
        savedAnswer.hasOwnProperty(`freetext-${keyValuePair[keyName]}`) ===
          true &&
        savedAnswer[`freetext-${keyValuePair[keyName]}`] !==
          keyValuePair[valName]
      ) {
        return false;
      }
    }
  }
  return true;
};

export default compareAnswerKeyValuePairs;

export const compareWorkings = (savedWorking, currentAnswer) => {
  if (savedWorking === currentAnswer) {
    return true;
  } else {
    return false;
  }
};

export const compareBarModel = (savedBarModel, currentAnswer) => {
  const savedBarModelString = JSON.stringify(savedBarModel);
  const currentBarModelString = JSON.stringify(currentAnswer);
  if (savedBarModelString === currentBarModelString) {
    return true;
  } else {
    return false;
  }
};
