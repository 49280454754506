import styled from 'styled-components';
import Arrow from 'assets/img/sidepanel/small-arrow.svg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    flex-direction: row;
    margin-right: 1.5rem;
    .cp {
      width: 30px;
      height: 30px;
    }
  }
  .dropdown-menu {
    border-radius: 12px;
    background-color: #404447;
    padding: 0;
    min-width: 95px;
    top: 0.3rem;
  }
  .dropdown-menu.show::before {
    content: url(${Arrow});
    position: absolute;
    top: -0.8rem;
    right: 1rem;
    z-index: -1;
  }
  .dropdown-item {
    border-bottom: 0.75px solid #2b2b2b;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    cursor: auto;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #404447;
    }
  }
  .dropdown-item:last-child {
    border: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .dropdown-item:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .cp-icon {
    margin-right: 0.5rem;
  }
`;

export const StyledToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  color: #ffffff;
  font-weight: 600;
  background-color: rgba(0, 114, 185, 0.5);
  border-radius: 2rem;
  padding: 0.1rem 0.8rem;
  .arrow {
    margin-left: 0.4rem;
  }
`;
