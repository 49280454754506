import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './AlertText.styles';

const AlertText = ({ type, totalWrongAns, className }) => {
  const { t } = useTranslation(['assignmentFinished']);

  return (
    <Container type={type} className={className}>
      {type === 'all-correct' &&
        t(
          'assignmentFinished:homework.CorrectAlertText',
          'You have answered all questions correctly!'
        )}
      {type === 'wrong' &&
        t(
          'assignmentFinished:homework.WrongAlertText',
          {
            number: totalWrongAns,
          },
          `There is (are) ${totalWrongAns} incorrect answer(s). You can click the question to correct.`
        )}
    </Container>
  );
};

export default AlertText;
