import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  height: 32px;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  ${({ type }) => {
    if (type === 'kooQuiz') {
      return `
        border-radius: 26px;
        height: 46px;
        padding: 0.5rem;
        justify-content: space-between;
        gap: 0.5rem;
        margin-left: 5rem;
        background: none;
        box-shadow: none;

        @media (min-width: 1250px) {
          margin-left: 0rem;
          gap: 3.5rem;
        }
      `;
    }
  }}

  @media (max-width: 768px) {
    transform: scale(0.8);
  }

  @media (max-width: 500px) {
    margin-right: -4rem;
  }

  @media (max-width: 400px) {
    margin-right: -3rem;
    transform: scale(0.6);
  }
`;

export const Tab = styled.div`
  border-radius: 18px;
  height: 23px;
  min-width: 77px;
  color: ${(props) =>
    props.active
      ? 'white'
      : props.notActiveColor
      ? 'rgb(73, 118,177, 0.75)'
      : '#404447'};
  background: ${(props) =>
    props.active
      ? props.activeColor
      : props.notActiveColor
      ? props.notActiveColor
      : null};
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  margin-left: ${(props) => (props.index > 0 ? '2rem' : '0')};
  cursor: pointer;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: ${({ iconWidth }) => iconWidth};
    margin-right: 4px;
  }
  ${({ type, active, fontSize, isChinese }) => {
    if (type === 'kooQuiz') {
      return `
        position:relative;
        min-width: initial;
        height: 34px;
        font-size: ${active ? '18px' : '16px'};
        width: 50%;
        margin-left: 0;
        letter-spacing: ${isChinese ? '3px' : 0};
        gap: 9px;
        white-space: nowrap;
        color: ${active ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.8)'};
        background: none;

        &::before {
          content: '';
          width: 100%;
          height: 5px;
          display: block;
          position: absolute;
          bottom: -25px;
          left: 0;
          background: ${active ? '#044c83' : 'transparent'};
          border-radius: 50px;
        }

        & > span{
          display: none;
        }

        img {
          opacity: ${active ? '1' : '0.8'};
        }

        @media (min-width: 1024px) {
          & > span{
            display: block;
            margin-top: ${isChinese ? '3px' : 0};
          }
        }
      `;
    }
  }}
`;
