import styled from 'styled-components';
import { proficiencyColors } from 'constants/report';

export const SkillDetailsContainer = styled.div`
  background: white;
  width: 360px;
  height: 90%;
  position: fixed;
  top: 7.8rem;
  right: 0;
  overflow-y: scroll;
  border-left: solid 1px rgba(112, 112, 112, 0.29);
  @media (max-width: 1024px) {
    width: 330px;
  }
`;

export const SkillDetailsHeader = styled.div`
  border-bottom: solid 1px rgba(112, 112, 112, 0.29);
  padding: 1rem 0.75rem;
  .close-panel {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    cursor: pointer;
    opacity: 0.2;
    &:hover {
      opacity: 1;
    }
  }
  .skill-name {
    font-size: 1.125rem;
  }
  .skill-number {
    font-size: 0.875rem;
    margin-right: 1rem;
  }
  .skill-description {
    font-size: 0.75rem;
    color: #828282;
  }
  .student-percentage {
    font-size: 1.125rem;
    color: black;
    margin-right: 2rem;
  }
  .class-avg-title {
    font-size: 0.625rem;
    margin-right: 0.5rem;
  }
  .class-avg-percentage {
    font-size: 0.9375rem;
    color: #060606;
  }
  p,
  span {
    font-family: 'Nunito';
    color: #202020;
  }
`;

export const SkillBadge = styled.span`
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  background: ${(props) => proficiencyColors[props.proficiency].color};
  border-radius: 12px;
  border: ${(props) =>
    `solid 1px ${proficiencyColors[props.proficiency].borderColor}`};
`;

export const QuestionContainer = styled.div`
  color: #202020;
  font-family: 'Nunito';
  padding: 1rem 0.75rem;
  .question-title {
    font-size: 0.875rem;
  }
  .question-content {
    font-weight: 600;
    font-size: 1rem;
  }
  .fraction-wrap {
    display: inline-block;
    vertical-align: middle;
  }
  .fraction-whole-part {
    display: inline-block;
    vertical-align: baseline;
  }
  .fraction-numerator-denominator-part {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.25em;
  }
  .fraction-numerator {
    display: block;
    border-bottom: 1px solid black;
    padding: 0.25em;
    text-align: center;
  }
  .fraction-denominator {
    display: block;
    padding: 0.25em;
    text-align: center;
  }
`;

export const SolutionContainer = styled.div`
  margin-bottom: 2rem;
  color: #202020;
  font-weight: 600;
  font-family: 'Nunito';
  padding: 0rem 0.75rem 1rem;
  .solution-title {
    color: #5e925c;
    font-size: 0.875rem;
  }
  .solution-content {
    font-size: 1rem;
  }
  .fraction-wrap {
    display: inline-block;
    vertical-align: middle;
  }
  .fraction-whole-part {
    display: inline-block;
    vertical-align: baseline;
  }
  .fraction-numerator-denominator-part {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.25em;
  }
  .fraction-numerator {
    display: block;
    border-bottom: 1px solid black;
    padding: 0.25em;
    text-align: center;
  }
  .fraction-denominator {
    display: block;
    padding: 0.25em;
    text-align: center;
  }
`;

export const ErrorContainer = styled.div`
  padding: 1rem 0.75rem;
  p {
    font-weight: 600;
  }
`;
