import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fetchAssignmentList from 'services/assignmentList';
import { fetchWrapper } from 'services/login';

// ASYNC THUNKS
export const getAssignmentList = createAsyncThunk(
  'assignmentList/getAssignmentList',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchAssignmentList, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get assignment list failed');
    }
  }
);

export const getMoreAssignments = createAsyncThunk(
  'assignmentList/getMoreAssignments',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchAssignmentList, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get more assignments failed');
    }
  }
);

const initialState = {
  assignmentList: [],
  currentPage: 0,
  totalPages: 1,
  hasNextPage: false,
  isLoading: false,
  isLoadingMoreAssignments: false,
  error: null,
};

const homeworkListSlice = createSlice({
  name: 'homeworkList',
  initialState,
  reducers: {},
  extraReducers: {
    [getAssignmentList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.assignmentList = [];
    },
    [getAssignmentList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.assignmentList = action.payload.List;
      state.currentPage = action.meta.arg.pageIndex;
      state.hasNextPage = action.payload.HasNextPage;
      state.totalPages = action.payload.TotalPages;
    },
    [getAssignmentList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getMoreAssignments.pending]: (state) => {
      state.isLoadingMoreAssignments = true;
      state.error = null;
    },
    [getMoreAssignments.fulfilled]: (state, action) => {
      state.isLoadingMoreAssignments = false;
      const newAssignmentList = [
        ...state.assignmentList,
        ...action.payload.List,
      ];
      state.currentPage = action.meta.arg.pageIndex;
      state.hasNextPage = action.payload.HasNextPage;
      state.assignmentList = newAssignmentList;
      state.totalPages = action.payload.TotalPages;
    },
    [getMoreAssignments.rejected]: (state, action) => {
      state.isLoadingMoreAssignments = false;
      state.error = action.error.message;
    },
  },
});

const { reducer } = homeworkListSlice;
export default reducer;
