import styled from 'styled-components';

export const IVMTitle = styled.div`
  p {
    margin: 0px;
  }
  .title-ivm {
    color: #fdfdfd;
    font-size: 32px;
    font-weight: bold;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .sub-title-ivm {
    color: #d9fff9;
    font-size: 30px;
    font-weight: 600;
    .text-shadow {
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    .orange {
      color: #ff7233;
    }
    .yellow {
      color: #ffe033;
    }
    .blue {
      color: #0090dd;
    }
  }
`;

export const BrainGameTitle = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0px;
    width: max-content;
  }
  img {
    margin-right: 0.5rem;
  }
  .title-bg {
    color: #fffbef;
    font-size: 30px;
    font-weight: bold;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
  }
  .sub-title-bg {
    color: #fffcbe;
    font-size: 28px;
    font-weight: 600;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  .desc {
    width: 70%;
    display: flex;
    justify-content: center;
    margin: 0px;
    background-color: #eff7ff;
    font-size: 18px;
    text-align: left;
    border-radius: 22px;
    padding: 2%;
    margin: 2rem 0;
    p {
      margin: 0px;
    }
    @media (max-width: 1024px) {
      width: 85%;
    }
  }
  .play-btn {
    min-width: 20%;
    display: flex;
    justify-content: center;
  }
`;
