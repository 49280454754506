import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/bff/bff-bg.svg';
export const StyledContainer = styled.div`
  background-image: url(${HeaderBg});
  background-size: cover;
  height: 350px;
  display: flex;
  padding: 0 20px;
  .scoring-period {
    flex-direction: column;
    span,
    .physical-prize {
      color: #374b09;
      font-size: 16px;
    }
  }
`;
export const StyledButtonContainer = styled.div`
  position: absolute;
  left: 4%;
`;
export const StyledContent = styled.div`
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  flex-direction: column;
  img {
    margin-left: 30px;
  }
`;
export const StyledBrainHeading = styled.div`
  width: 40%;
  align-self: flex-end;
  color: #ffffff;
  text-shadow: 4px 5px #0090b2;
  font-family: 'Grilled Cheese';
  font-size: 3.9rem;
  position: relative;
  z-index: 1;
  &::before {
    content: ${({ content }) => content && `"${content}"`};
    position: absolute;
    -webkit-text-stroke: 8px #ffffff;
    left: 0;
    top: 0;
    z-index: -1;
  }
`;
