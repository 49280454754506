import React from 'react';
import moment from 'moment-timezone';

import {
  SUBS_STATUS,
  DURATION_TYPE,
  PAYMENT_METHOD,
  BUNDLE_TYPE_ID,
  TRIAL_TYPE,
} from 'constants/parentPortal';
import durationDisplay from 'helpers/durationDisplay';
import { compareDate, compareValues } from 'helpers/compareValue';
import { otpCampaignCode } from 'constants/trialBanner';

import Avatar from 'assets/img/avatar.png';

import SubscriptionPlanRowV3 from './SubscriptionPlanRowV3';
import AvailableProducts from './AvailableProducts';

import {
  Container,
  ChildContainer,
  UserCard,
  SubsCard,
} from './Subscription.styles';

const Subscription = ({ data }) => {
  const timezone = moment.tz.guess();
  const sortSubscription =
    data.subscriptions
      ?.slice()
      .sort(compareDate('endDate', 'asc'))
      .sort(compareValues('bundleTypeId')) ?? [];
  const isAllExpired = sortSubscription.every(
    (item) => SUBS_STATUS[item.subscriptionStatusCode] === 'expired'
  );

  const promoCode = (subject, isOTPTrial) => {
    if (isOTPTrial) return otpCampaignCode[subject];
    return null;
  };

  return (
    <Container>
      <UserCard>
        <ChildContainer>
          <img
            src={data.avatarUrl ?? Avatar}
            alt="avatar"
            onError={(e) => (e.target.src = Avatar)}
          />
          <p>{data.fullName}</p>
        </ChildContainer>
        <div className="subs">Subscription Plan</div>
      </UserCard>
      {isAllExpired ? (
        <AvailableProducts childId={data.childUserId} />
      ) : (
        sortSubscription.map((item, index) => {
          const startDate = moment
            .tz(item.startDate, timezone)
            .format('DD MMMM, YYYY');
          const endDate = moment
            .tz(item.endDate, timezone)
            .subtract(1, 'days')
            .format('DD MMMM, YYYY');
          const isUnderSmartRetry =
            SUBS_STATUS[item.subscriptionStatusCode] === 'recurring' &&
            moment().isAfter(endDate, 'day');

          return (
            <SubsCard key={item.subscriptionId}>
              <SubscriptionPlanRowV3
                isB2B={data.isB2bUser}
                status={SUBS_STATUS[item.subscriptionStatusCode]}
                desc={`${durationDisplay(
                  DURATION_TYPE[item.subscriptionDurationType],
                  1
                )} plan, Valid period ${startDate} - ${endDate}`}
                trialDesc={`7-Day Free Trial, Expires on ${endDate}`}
                subsTrialType={TRIAL_TYPE[item.subscriptionTrialStatus]}
                expiredDate={endDate}
                paymentMethodText={item.paymentMethodText}
                paymentMethodType={PAYMENT_METHOD[item.paymentMethodType]}
                bundleName={item.bundleName}
                childId={data.childUserId}
                paymentSubsId={item.paymentSubscriptionId}
                campaign={promoCode(
                  BUNDLE_TYPE_ID[item.bundleTypeId],
                  TRIAL_TYPE[item.subscriptionTrialStatus] === 'otpTrial'
                )}
                isBundled={item.isBundled}
                position={index + 1}
                bundleTypeId={item.bundleTypeId}
                discount={item.discount}
                subsLength={sortSubscription.length}
                isSmartRetry={isUnderSmartRetry}
              />
            </SubsCard>
          );
        })
      )}
    </Container>
  );
};

export default Subscription;
