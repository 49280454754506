import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  visibility: ${(props) => (props.showSpinner ? 'visible' : 'hidden')};
  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: ${(props) => (props.small ? '1.5em' : '2.5em')};
    height: ${(props) => (props.small ? '1.5em' : '2.5em')};
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  color: ${(props) => props.color || '#ff5b00'};
  font-size: 10px;
  margin: ${(props) => (props.small ? '10px auto' : '80px auto')};
  position: ${(props) => (props.position ? props.position : 'relative')};
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : null)};
  top: ${(props) => (props.top ? props.top : '0')};
  left: ${(props) => (props.left ? props.left : '0')};
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: ${(props) => (props.small ? '-2em' : '-3.5em')};
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: ${(props) => (props.small ? '2em' : '3.5em')};
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`;
