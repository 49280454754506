import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchTopics,
  fetchSelfPracticeQuestions,
  submitSelfPracticeAnswer,
  claimMyRewards,
  fetchSelfPracticeCurriculum,
  fetchMissionPlusTopics,
  fetchMissionPlusTotalProgress,
  fetchMissionPlusUnlockStage,
  fetchMissionPlusQuestions,
  submitMissionPlusAnswer,
  claimMissionPlusKoKo,
} from 'services/mission';
import { isNil, isEmpty } from 'ramda';
import { fetchWrapper } from 'services/login';
import xmlParser from 'helpers/xmlParser';
import { getRewards } from 'store/dashboard/rewardsSlice';
import { subjectNames } from 'constants/products';
// Fetch list of curriculum
export const getSelfPracticeCurriculum = createAsyncThunk(
  'mission/getSelfPracticeCurriculum',
  async (param, { getState }) => {
    try {
      const { plan, login } = getState();
      const { subject } = plan;
      const params = {
        subjectId: param?.isScience ? 2 : subjectNames[subject],
        userId: login.userID,
      };
      const res = await fetchWrapper(fetchSelfPracticeCurriculum, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get topics failed');
    }
  }
);

export const getB2BSelfPracticeCurriculum = createAsyncThunk(
  'mission/getB2BSelfPracticeCurriculum',
  async (_, { getState }) => {
    const { plan, login } = getState();
    const { products } = plan;

    const availableSubjects = products?.filter(
      (item) => !isEmpty(item.nonExpiredSubscriptionProducts)
    );

    try {
      const promises = availableSubjects.map((data) => {
        const promise = fetchWrapper(fetchSelfPracticeCurriculum, {
          subjectId: data.id,
          userId: login.userID,
        });
        return promise.then((response) => {
          return response;
        });
      });
      const res = await Promise.all(promises);
      return res.flat();
    } catch (error) {
      throw new Error(error?.message ?? 'Get topics failed');
    }
  }
);

// Fetch topic details
export const getTopics = createAsyncThunk(
  'mission/getTopics',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTopics, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get topics failed');
    }
  }
);

// Get question
export const getSelfPracticeQuestion = createAsyncThunk(
  'mission/getQuestion',
  async (skillId) => {
    try {
      const res = await fetchWrapper(fetchSelfPracticeQuestions, skillId);
      return res;
    } catch (err) {
      throw new Error(err?.message ?? 'Save question submission failed');
    }
  }
);

// Submit assignment
export const submitSelfPracticeAns = createAsyncThunk(
  'mission/submitAnswer',
  async (params) => {
    try {
      const res = await fetchWrapper(submitSelfPracticeAnswer, params);
      return res;
    } catch (err) {
      throw new Error(err?.message ?? 'Submit solution failed');
    }
  }
);

// Claim CPs
export const claimRewards = createAsyncThunk(
  'mission/claimRewards',
  async (topicSkillId, { dispatch }) => {
    try {
      const res = await fetchWrapper(claimMyRewards, topicSkillId);
      dispatch(getRewards());
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Claim Koko rewards failed');
    }
  }
);

// ------MISSION PLUS------

export const getMissionPlusTopics = createAsyncThunk(
  'mission/getMissionPlusTopics',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchMissionPlusTopics, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get Mission Plus topics failed');
    }
  }
);
export const getMissionPlusTotalProgress = createAsyncThunk(
  'mission/getMissionPlusTotalProgress',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchMissionPlusTotalProgress, params);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get Mission Plus total progress failed'
      );
    }
  }
);
export const unlockMissionPlusStage = createAsyncThunk(
  'mission/unlockMissionPlusStage',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchMissionPlusUnlockStage, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Unlock Mission Plus stage failed');
    }
  }
);
export const getMissionPlusQuestions = createAsyncThunk(
  'mission/getMissionPlusQuestions',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchMissionPlusQuestions, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get Mission Plus topics failed');
    }
  }
);
export const submitMissionPlusAns = createAsyncThunk(
  'mission/submitMissionPlusAns',
  async (params) => {
    try {
      const res = await fetchWrapper(submitMissionPlusAnswer, params);
      if (res.CanClaim) {
        try {
          const claimRes = await fetchWrapper(claimMissionPlusKoKo, params);
          return {
            submitRes: res,
            claimRes,
          };
        } catch (err) {
          return {
            submitRes: res,
            claimResErr: err?.message ?? 'Claim Mission Plus KoKo failed',
          };
        }
      }
      return {
        submitRes: res,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Submit Mission Plus answer failed');
    }
  }
);
export const claimMissionPlus = createAsyncThunk(
  'mission/claimMissionPlus',
  async (params) => {
    try {
      const res = await fetchWrapper(claimMissionPlusKoKo, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Claim Mission Plus Koko failed');
    }
  }
);
// ------MISSION PLUS END------

const initialState = {
  selfPracticeCurriculum: [],
  topics: [],
  topicsLoading: false,
  topicErr: null,
  hotsTopics: [],
  hotsDisplayOrder: null,
  isLoading: false,
  isErr: null,
  questions: [],
  submitAns: {},
  preSubmitAns: {},
  isErrSubmit: null,
  isClaiming: false,
  claiRewardsResult: null,
  claimRewardErr: null,
  selectedLevel: null,
  selectedTopic: null,
  selfPracticeActiveQn: 1,
  selfPracticeLocalSavedAns: [null],
  selfPracticeLocalSavedWorkings: [null],
  missionQuestionAnswers: [],
  preMissionQuestionAnswers: [],
  missionPlusTotalProgress: {
    isLoading: true,
    isErr: null,
    EarnedBadges: 0,
    TotalBadges: 0,
    totalSkills: 0,
  },
  unlockMissionPlus: {
    isLoading: false,
    isErr: null,
  },
  missionPlusClaimKoKo: {
    LevelBonus: null,
    OneTimeReward: 0,
    RepeatableReward: 0,
    Total: 0,
    isErr: null,
  },
};

const missionSlice = createSlice({
  name: 'mission',
  initialState,
  reducers: {
    setSelectedLevel: (state, action) => {
      state.selectedLevel = action.payload;
    },
    setSelectedTopic: (state, action) => {
      state.selectedTopic = action.payload;
    },
    setHotsTopics: (state, action) => {
      state.hotsTopics = action.payload;
    },
    setHotsDisplayOrder: (state, action) => {
      state.hotsDisplayOrder = action.payload;
    },
    resetRewards: (state) => {
      state.claiRewardsResult = null;
      state.claimRewardErr = null;
    },
    incrementSelfPracticeActiveQn: (state) => {
      state.selfPracticeActiveQn += 1;
    },
    saveSelfPracticeAnsLocally: (state, action) => {
      const newAnswers = [...state.selfPracticeLocalSavedAns];
      newAnswers[action.payload.index] = action.payload.answers;
      state.selfPracticeLocalSavedAns = newAnswers;
    },
    saveSelfPracticeWorkingsLocally: (state, action) => {
      const newWorkings = [...state.selfPracticeLocalSavedWorkings];
      newWorkings[action.payload.index] = action.payload.workings;
      state.selfPracticeLocalSavedWorkings = newWorkings;
    },
    clearSavedSelfPracticeAns: (state) => {
      state.selfPracticeLocalSavedAns = [null];
    },
    resetSaveSelfPracticeAns: (state) => {
      state.selfPracticeLocalSavedAns = [null];
      state.selfPracticeLocalSavedWorkings = [null];
    },
    resetSubmitAns: (state) => {
      state.submitAns = {};
      state.isErrSubmit = null;
    },
    resetSelfPracticeQnView: (state) => {
      return {
        ...initialState,
        selfPracticeCurriculum: state.selfPracticeCurriculum,
        selectedLevel: state.selectedLevel,
        selectedTopic: state.selectedTopic,
      };
    },
    resetSelectedLevel: (state) => {
      state.selectedLevel = null;
    },
    resetTopics: (state) => {
      state.topics = [];
    },
  },
  extraReducers: {
    [getSelfPracticeCurriculum.pending]: (state) => {
      state.isLoading = true;
      state.selfPracticeCurriculum = [];
      state.isErr = null;
    },
    [getSelfPracticeCurriculum.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selfPracticeCurriculum = action.payload;
      state.isErr = null;
    },
    [getSelfPracticeCurriculum.rejected]: (state, action) => {
      state.isLoading = false;
      state.selfPracticeCurriculum = [];
      state.isErr = action.error.message;
    },
    [getB2BSelfPracticeCurriculum.pending]: (state) => {
      state.isLoading = true;
      state.selfPracticeCurriculum = [];
      state.isErr = null;
    },
    [getB2BSelfPracticeCurriculum.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selfPracticeCurriculum = action.payload;
      state.isErr = null;
    },
    [getB2BSelfPracticeCurriculum.rejected]: (state, action) => {
      state.isLoading = false;
      state.selfPracticeCurriculum = [];
      state.isErr = action.error.message;
    },
    [getTopics.pending]: (state) => {
      state.topicsLoading = true;
      state.topicErr = null;
    },
    [getTopics.fulfilled]: (state, action) => {
      state.topicsLoading = false;
      state.topics = action.payload;
      if (isNil(state.selectedTopic)) {
        const findTopicBySearch = action.payload.find(
          (topic) => topic.TopicId === action.meta.arg.searchTopicId
        );
        state.selectedTopic = findTopicBySearch || action.payload[0];
      } else {
        const isSelectedTopicFound = action.payload.find(
          (topic) => topic.TopicId === state.selectedTopic.TopicId
        );
        if (isNil(isSelectedTopicFound)) {
          state.selectedTopic = action.payload[0];
        } else {
          state.selectedTopic = isSelectedTopicFound;
        }
      }
    },
    [getTopics.rejected]: (state, action) => {
      state.topicsLoading = false;
      state.topicErr = action.error.message;
    },
    [getSelfPracticeQuestion.pending]: (state) => {
      state.isErr = null;
      state.isLoading = true;
    },
    [getSelfPracticeQuestion.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.questions = action.payload;
    },
    [getSelfPracticeQuestion.rejected]: (state, action) => {
      state.isLoading = false;
      state.isErr = action.error.message;
    },
    [submitSelfPracticeAns.pending]: (state) => {
      state.isErrSubmit = null;
      state.isLoading = true;
    },
    [submitSelfPracticeAns.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.submitAns = action.payload;
      // Populate missionQuestionAnswers
      const filteredXml = action.payload.QuestionSubmissionModel.Payload.replace(
        /\\/g,
        ''
      );
      const parsed = xmlParser(filteredXml);
      let answerKeys = null;
      if (parsed.questionAnswers.length > 0) {
        answerKeys = parsed.questionAnswers.map((answer) => answer.$);
      }
      state.missionQuestionAnswers = answerKeys;
      state.isErrSubmit = null;
    },
    [submitSelfPracticeAns.rejected]: (state, action) => {
      state.isLoading = false;
      state.isErrSubmit = action.error.message;
    },
    [claimRewards.pending]: (state) => {
      state.isClaiming = true;
      state.claimRewardErr = null;
    },
    [claimRewards.fulfilled]: (state, action) => {
      state.isClaiming = false;
      state.claiRewardsResult = action.payload;
    },
    [claimRewards.rejected]: (state, action) => {
      state.isClaiming = false;
      state.claimRewardErr = action.error.message;
    },
    // ----MISSION PLUS----
    [getMissionPlusTopics.pending]: (state) => {
      state.topicsLoading = true;
      state.topicErr = null;
    },
    [getMissionPlusTopics.fulfilled]: (state, action) => {
      const sortTopic = action.payload
        .map((topic) => ({
          ...topic,
          ViewTopicSkillModels: topic.ViewTopicSkillModels.map(
            (topicSkill) => ({
              ...topicSkill,
              Stages: topicSkill.Stages.sort(
                (a, b) => a.StageTypeId - b.StageTypeId
              ),
            })
          ),
        }))
        .sort((a, b) => a.TopicDisplayOrder - b.TopicDisplayOrder);
      state.topicsLoading = false;
      state.topics = sortTopic;
      if (isNil(state.selectedTopic)) {
        const findTopicBySearch = sortTopic.find(
          (topic) => topic.TopicId === action.meta.arg.searchTopicId
        );
        state.selectedTopic = findTopicBySearch || sortTopic[0];
      } else {
        const isSelectedTopicFound = action.payload.find(
          (topic) => topic.TopicId === state.selectedTopic.TopicId
        );
        if (isNil(isSelectedTopicFound)) {
          state.selectedTopic = sortTopic[0];
        } else {
          state.selectedTopic = isSelectedTopicFound;
        }
      }
    },
    [getMissionPlusTopics.rejected]: (state, action) => {
      state.topicsLoading = false;
      state.topicErr = action.error.message;
    },
    [getMissionPlusTotalProgress.pending]: (state) => {
      return {
        ...state,
        missionPlusTotalProgress: initialState.missionPlusTotalProgress,
      };
    },
    [getMissionPlusTotalProgress.fulfilled]: (state, action) => {
      state.missionPlusTotalProgress.isLoading = false;
      state.missionPlusTotalProgress.EarnedBadges = action.payload.EarnedBadges;
      state.missionPlusTotalProgress.TotalBadges = action.payload.TotalBadges;
      state.missionPlusTotalProgress.totalSkills =
        action.payload.TotalBadges / 3;
    },
    [getMissionPlusTotalProgress.rejected]: (state, action) => {
      state.missionPlusTotalProgress.isLoading = false;
      state.missionPlusTotalProgress.isErr = action.error.message;
    },
    [unlockMissionPlusStage.pending]: (state) => {
      state.unlockMissionPlus.isLoading = true;
      state.unlockMissionPlus.isErr = null;
    },
    [unlockMissionPlusStage.fulfilled]: (state, action) => {
      state.unlockMissionPlus.isLoading = false;
      state.topics = state.topics.map((topic) => {
        if (topic.TopicId === state.selectedTopic.TopicId) {
          return {
            ...topic,
            ViewTopicSkillModels: topic.ViewTopicSkillModels.map(
              (topicSkill) => {
                if (topicSkill.TopicSkillId === action.payload.TopicSkillId) {
                  return {
                    ...topicSkill,
                    Stages: topicSkill.Stages.map((stage) => {
                      if (stage.StageTypeId === action.payload.StageTypeId) {
                        return {
                          ...stage,
                          AttemptedTimes: action.payload.AttemptedTimes,
                          StageStatus: action.payload.StageStatus,
                          GoldMedal: action.payload.GoldMedal,
                          Progress: action.payload.Progress,
                        };
                      }
                      return stage;
                    }),
                  };
                }
                return topicSkill;
              }
            ),
          };
        }
        return topic;
      });
    },
    [unlockMissionPlusStage.rejected]: (state, action) => {
      state.unlockMissionPlus.isLoading = false;
      state.unlockMissionPlus.isErr = action.error.message;
    },
    [getMissionPlusQuestions.pending]: (state) => {
      state.isErr = null;
      state.isLoading = true;
    },
    [getMissionPlusQuestions.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.questions = action.payload;
    },
    [getMissionPlusQuestions.rejected]: (state, action) => {
      state.isLoading = false;
      state.isErr = action.error.message;
    },
    [submitMissionPlusAns.pending]: (state) => {
      return {
        ...state,
        isErrSubmit: null,
        isLoading: true,
        missionPlusClaimKoKo: initialState.missionPlusClaimKoKo,
      };
    },
    [submitMissionPlusAns.fulfilled]: (state, action) => {
      state.isLoading = false;
      // Populate missionQuestionAnswers
      const filteredXml = action.payload.submitRes.QuestionSubmissionModel.Payload.replace(
        /\\/g,
        ''
      );
      const parsed = xmlParser(filteredXml);
      let answerKeys = null;
      if (parsed.questionAnswers.length > 0) {
        answerKeys = parsed.questionAnswers.map((answer) => answer.$);
      }
      state.isErrSubmit = null;
      if (action.payload.claimRes) {
        state.submitAns = action.payload.submitRes;
        state.missionQuestionAnswers = answerKeys;
        state.missionPlusClaimKoKo = {
          ...state.missionPlusClaimKoKo,
          ...action.payload.claimRes,
        };
      } else if (action.payload.claimResErr) {
        state.preSubmitAns = action.payload.submitRes;
        state.preMissionQuestionAnswers = answerKeys;
        state.missionPlusClaimKoKo.isErr = action.payload.claimResErr;
      } else {
        state.submitAns = action.payload.submitRes;
        state.missionQuestionAnswers = answerKeys;
      }
    },
    [submitMissionPlusAns.rejected]: (state, action) => {
      state.isLoading = false;
      state.isErrSubmit = action.error.message;
    },
    [claimMissionPlus.pending]: (state) => {
      return {
        ...state,
        missionPlusClaimKoKo: initialState.missionPlusClaimKoKo,
      };
    },
    [claimMissionPlus.fulfilled]: (state, action) => {
      state.submitAns = state.preSubmitAns;
      state.missionQuestionAnswers = state.preMissionQuestionAnswers;
      state.missionPlusClaimKoKo = {
        ...state.missionPlusClaimKoKo,
        ...action.payload,
      };
    },
    [claimMissionPlus.rejected]: (state, action) => {
      state.missionPlusClaimKoKo.isErr = action.error.message;
    },
  },
});

const { actions, reducer } = missionSlice;
export const {
  setSelectedLevel,
  setSelectedTopic,
  setHotsTopics,
  setHotsDisplayOrder,
  resetRewards,
  incrementSelfPracticeActiveQn,
  saveSelfPracticeAnsLocally,
  saveSelfPracticeWorkingsLocally,
  resetSubmitAns,
  resetSaveSelfPracticeAns,
  resetSelfPracticeQnView,
  clearSavedSelfPracticeAns,
  resetSelectedLevel,
  resetTopics,
} = actions;
export default reducer;
