import React from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import useIsTablet from 'hooks/responsive/useIsTablet';
import NavItem from './NavItem';
import navs from './navs';
import parentNavs from './parentNavs.json';
import premiumNavs from './premiumNavs';
import { NavbarCollapseContainer } from './NavbarCollapse.styles';

const NavbarCollapse = () => {
  const { t } = useTranslation(['navigation']);
  const { isExpired } = useSelector((state) => state.studentDetails);
  const { isParentAccount } = useSelector((state) => state.login);
  const { plan, subject } = useSelector((state) => state.plan);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isTablet = useIsTablet();
  const navsItem = () => {
    if (isParentAccount) {
      return parentNavs;
    }
    if (plan === 'premium' || plan === 'standard') {
      return premiumNavs.filter((navItem) => {
        return subject === 'math' ? true : navItem.name !== 'Achievement';
      });
    }
    if (plan === 'school') {
      return navs.filter((navItem) => {
        if (navItem.name === 'Help') {
          return isDesktopOrLaptop;
        }
        return subject === 'math' ? true : navItem.name !== 'Achievement';
      });
    }
    return navs.filter((navItem) => {
      return subject === 'math' ? true : navItem.name !== 'Achievement';
    });
  };
  return (
    <NavbarCollapseContainer plan={plan}>
      {!isExpired && (
        <ul className="navbar-nav mx-lg-auto" data-cy="nav-items">
          {navsItem().map((nav, index) => {
            // hide help when on tablet view
            if (isTablet && nav.name === 'Help') {
              return <></>;
            }
            return (
              <NavItem
                key={String(index)}
                to={nav.to}
                icon={nav.icon}
                isFeatureReady={nav.isFeatureReady}
                isExternal={nav.isExternalLink}
                mobileOnly={nav.mobileOnly}
              >
                <span data-cy={`top-${nav.name}`}>
                  {t(`navigation:navigation.${nav.name}`, `${nav.name}`)}
                </span>
              </NavItem>
            );
          })}
        </ul>
      )}
    </NavbarCollapseContainer>
  );
};

export default NavbarCollapse;
