/* eslint-disable no-nested-ternary */
import { isNil } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { history } from 'Routes';
import {
  checkDailyBonusClaimed,
  getRewards,
} from 'store/dashboard/rewardsSlice';
import ClaimedTick from 'assets/img/sidepanel/claimed-tick.svg';
import { Container, Icon, Pulse } from './SidePanelItem.styles';

const SidePanelItem = ({
  name,
  img,
  type,
  link,
  value,
  isFeatureReady,
  onClickHandler,
  isClaimable,
  isDailyBonusClaimable,
}) => {
  const { plan, subject } = useSelector((state) => state.plan);
  const { dailyBonusError, error } = useSelector((state) => state.rewards);
  const { t } = useTranslation(['dashboard', 'common']);
  const dispatch = useDispatch();

  const updateSidepanelItem = () => {
    return name === 'Story Math'
      ? t(`dashboard:sidepanel.StoryMath`, 'Story')
      : t(`dashboard:sidepanel.${name}`, name);
  };

  return (
    <Container
      type={type}
      isFeatureReady={isFeatureReady}
      data-cy={name}
      name={name}
      onClick={() => {
        if (link) {
          history.push(link);
        }
        if (!isNil(onClickHandler)) {
          onClickHandler();
        }
      }}
    >
      {name === 'Daily Bonus' && (
        <Pulse active={isDailyBonusClaimable}>
          <Icon type={type} selectedPlan={plan} name={name} subject={subject}>
            <img src={img} alt={name} className="pulse" />
          </Icon>
        </Pulse>
      )}
      {name !== 'Daily Bonus' && (
        <Icon type={type} selectedPlan={plan} name={name} subject={subject}>
          <img src={img} alt={name} className="pulse" />
        </Icon>
      )}
      {type === 'right' && name !== 'KoKo Shop' && name !== 'Daily Bonus' && (
        <span
          className={`value ${!isNil(error) && 'reload'}`}
          onClick={() => {
            if (!isNil(error)) {
              dispatch(getRewards());
            }
          }}
          data-cy="reload-rewards"
        >
          {!isNil(error) ? t('common:reload', 'Reload') : value}
        </span>
      )}
      {name !== 'Daily Bonus' ? (
        <span className="label">{updateSidepanelItem()}</span>
      ) : !isNil(dailyBonusError) ? (
        <div
          className="label reload"
          onClick={() => dispatch(checkDailyBonusClaimed())}
          data-cy="reload-btn"
        >
          {t('common:reload', 'Reload')}
        </div>
      ) : (
        <span className="label">{updateSidepanelItem()}</span>
      )}
      {name === 'Daily Bonus' && isClaimable === false && (
        <span className="claimed">
          <img src={ClaimedTick} alt="" />
          {t(`dashboard:sidepanel.ClaimedStatusText`, 'Claimed')}
        </span>
      )}
    </Container>
  );
};

export default SidePanelItem;
