import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './QnEnd.styles';

const QnEnd = () => {
  const { t } = useTranslation(['assignmentQnView']);
  return (
    <Container>
      {t('assignmentQnView:sidebar.endQn', 'You have reached the end')}
    </Container>
  );
};

export default QnEnd;
