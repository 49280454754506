import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { multiplayerListStyles } from 'constants/multiplayer';
import { getIncomingPeerChallengeHistory } from 'services/multiplayerv2';
import { subjectNames } from 'constants/products';
import { fetchWrapper } from 'services/login';
import RedDotWrapper from 'components/Shared/RedDotWrapper';
import ThreeDotIconButton from 'components/Shared/ThreeDotIconButton';
import useResponsiveValue from 'hooks/responsive/useResponsiveValue';
import { StyledButton, StyledBadge } from './IncomingChallengesButton.styles';

const IncomingChallengesButton = ({
  subjectParam = '',
  userID,
  onOpenMenu,
  isPopup,
}) => {
  const { t } = useTranslation(['newChallenge']);
  const history = useHistory();
  const subjectID = subjectNames[subjectParam];
  const [numOfChallenges, setNumOfChallenges] = useState(0);
  const onClickHandler = () => {
    history.push(
      `/multiplayer/peer-challenge/incoming?subject=${subjectParam}`
    );
  };
  const badgeSize = useResponsiveValue({
    desktop: '28px',
    tablet: '18px',
    mobile: '18px',
  });
  useEffect(() => {
    if (!subjectID || !userID) return;
    fetchWrapper(getIncomingPeerChallengeHistory, {
      userID,
      challengeType: 1,
      subjectID,
      pageSize: 1,
    })
      .then((res) => {
        setNumOfChallenges(res.TotalCount);
      })
      .catch((err) => console.error(err.message));
  }, [subjectID, userID]);
  return (
    <>
      <StyledButton className="incoming" onClick={onClickHandler}>
        <img
          src={
            multiplayerListStyles[subjectParam.toLowerCase().trim()]
              .incomingIcon
          }
          alt="incoming"
        />
        {numOfChallenges > 0 && (
          <StyledBadge size={badgeSize}>{numOfChallenges}</StyledBadge>
        )}
        <p>{t('newChallenge:header.incoming', 'Incoming')}</p>
      </StyledButton>
      {!isPopup && (
        <div className="btn-right-section">
          <RedDotWrapper active={numOfChallenges > 0} size="10px">
            <ThreeDotIconButton
              onClick={onOpenMenu}
              size="32px"
              padding="6px"
            />
          </RedDotWrapper>
        </div>
      )}
    </>
  );
};

export default IncomingChallengesButton;
