import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/Shared/BackButton';
import { ChallengeType } from 'pages/Leaderboard/v2/components';
import {
  Topbar,
  Container,
  BrainHeading,
  ButtonContainer,
} from './WizardSchoolHeader.styles';

const WizardSchoolHeader = () => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  return (
    <Topbar>
      <ButtonContainer className="btn">
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(137, 61, 190)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <Container>
        <BrainHeading
          content={t('leaderboard:wizardSchool.wizard', 'Wizard School')}
        >
          {t('leaderboard:wizardSchool.wizard', 'Wizard School')}
        </BrainHeading>
      </Container>
      <ChallengeType styles={{ top: '12%' }} />
    </Topbar>
  );
};

export default WizardSchoolHeader;
