import styled from 'styled-components';

import HeaderBg from 'assets/img/superhero/daily-challenge-body.png';

export const Content = styled.div`
  background: #eaeded;
  min-height: calc(100vh - 229px);
  align-items: center;
  .modal-content {
    border-radius: 1rem;
  }
  padding-top: 30px;
`;

export const CardContainer = styled.div`
  background: url(${HeaderBg}) repeat-y;
  width: 852px;
  margin: 0 auto;
  position: relative;
  height: 300px;
`;

export const CardWrap = styled.div`
  position: absolute;
  padding: 20px 100px;
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Container = styled.div``;

export const Main = styled.div`
  padding: 96px 10% 0%;
`;

export const Heading = styled.h2`
  font-weight: 600;
  margin: 0 10px 0 0;
`;
