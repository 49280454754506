import styled from 'styled-components';
import { questionStatuses } from 'constants/dailyChallenge';

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }

  .difficulty-icon {
    position: relative;
    left: 2rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    position: relative;
    .difficulty-icon {
      position: absolute;
      left: 0px;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    position: relative;
    .difficulty-icon {
      position: absolute;
      left: 0px;
    }
  }
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 286px;
  border-radius: 38px;
  font-weight: 600;
  font-size: 1.1875rem;
  color: white;
  background: ${(props) => questionStatuses[props.qnStatus].color};
  padding: 0.5rem 0.25rem 0.5rem 2rem;
  span {
    margin: auto;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    min-width: 300px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    min-width: 300px;
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #403f3f;
  position: relative;
  left: 4.5em;
  width: 286px;
  .difficulty {
    font-size: 0.6875rem;
    margin-right: 1rem;
  }
  .cp-reward {
    font-size: 0.75rem;
  }
  .cp-icon {
    margin-right: 0.25rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    left: 2em;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    left: 2em;
  }
`;
