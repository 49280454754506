import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ebf7f9;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  padding: 1rem 2rem 1rem 0rem;
  .btn-blue-light {
    border: 1px solid #8ecfe2;
    background-color: #ffffff;
    color: #1198b2;
    font-size: 14px;
    border-radius: 20px;
    margin-right: 1rem;
    &:hover {
      background-color: #f9f9f9;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .btn-orange {
    background-color: #ff6701;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    border-radius: 30px;
    &:hover {
      background-color: #ff5c00;
    }
    &:focus {
      box-shadow: none;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 10px 16px;
  }
`;
