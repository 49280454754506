import styled from 'styled-components';
import ModalBg from 'assets/img/dailychallenge/smc-modal-bg.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  background-image: url(${ModalBg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 531px;
  min-height: 284px;
  padding: 0.5rem 2rem 2rem;
  border-radius: 0.5rem;
  text-align: center;
  .title-text {
    color: #ff8002;
    font-size: 1.5625rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .main-text {
    width: 324px;
    text-align: left;
    font-size: 1rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 350px;
  }
`;

export const CloseBtn = styled.button`
  background: #ebf6ff;
  color: #73b0e8;
  font-weight: 700;
  width: 34px;
  height: 34px;
  border: none;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: absolute;
  margin-left: 510px;
  margin-top: -0.5rem;
  :hover {
    background: #f8fcff;
    color: #68b1f5;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-left: 0px;
    right: 5px;
  }
`;
