import styled from 'styled-components';
import HallOfFameBanner from 'assets/img/leaderboard/hod/hall-of-fame-bg.png';
import { HALL_OF_FAME_COLORS } from '../../constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0 20px;
  width: 70%;
  align-self: center;
  background: #ffffff;
  .banner {
    margin: 1rem 0;
    .hall-of-fame {
      width: 100%;
      height: 80px;
      background-image: url(${HallOfFameBanner});
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .title {
        margin-bottom: 0px;
        font-family: 'Grilled Cheese';
        font-size: 48px;
        color: white;
        -webkit-text-stroke-width: 2.5px;
        -webkit-text-stroke-color: #c36100;
        text-align: center;
      }
    }
  }
  .calendar {
    background-color: rgb(155, 71, 10, 0.53);
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    position: absolute;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    right: 17.5%;
    margin-top: -4rem;
    cursor: pointer;
    span {
      margin-left: 0.5rem;
    }
  }
`;

export const CardSection = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
`;

export const Card = styled.div`
  border-radius: 14px;
  border: ${({ levelId }) =>
    `1px solid ${HALL_OF_FAME_COLORS[levelId].border}`};
  width: 270px;
  margin-bottom: 2rem;
  p {
    margin-bottom: 0rem;
  }
  .header {
    background-color: ${({ levelId }) =>
      `${HALL_OF_FAME_COLORS[levelId].header}`};
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    color: ${({ levelId }) => `${HALL_OF_FAME_COLORS[levelId].font}`};
    font-size: 18px;
    font-weight: 600;
    padding: 0.3rem 1rem;
  }
  .content {
    min-height: 140px;
    background-color: ${({ levelId }) =>
      `${HALL_OF_FAME_COLORS[levelId].content}`};
    padding: 0.3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .student-name {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 1rem;
      text-align: center;
    }
    .school {
      display: flex;
      align-items: center;
      .school-logo {
        width: 40px;
        height: 40px;
      }
      .school-details {
        margin-left: 1rem;
        .school-name {
          line-height: 1rem;
        }
      }
    }
  }
  .footer {
    background-color: ${({ levelId }) =>
      `${HALL_OF_FAME_COLORS[levelId].footer}`};
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding: 0.3rem 1rem;
    display: flex;
    justify-content: space-between;
    color: #030302;
    align-items: center;
    .cp {
      font-size: 18px;
      font-weight: 600;
      .cp-icon {
        margin-right: 0.5rem;
      }
    }
  }
`;
