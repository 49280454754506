import React from 'react';
import { useTranslation } from 'react-i18next';
import ImgClose from 'assets/img/braingame/close.png';
import ImgBtnPlay from 'assets/img/braingame/button-play.png';
import ImgBtnCancel from 'assets/img/braingame/button-cancel.png';
import ImgLock from 'assets/img/braingame/kokolockmask.png';
import ImgKokoCoin from 'assets/img/braingame/k.png';
import ImgTick from 'assets/img/braingame/tick.png';
import { ButtonGroup } from 'react-bootstrap';
import {
  Wrapper,
  ModalHeader,
  ImgCloseWrapper,
  ImageCenterWrapper,
  ModalBody,
  TextInfo,
  ButtonLink,
} from './UnlockGameModal.style';

const UnlockGameModal = ({ img, onUnlock, onClose, unlock }) => {
  const { t } = useTranslation(['brainGames']);
  return (
    <Wrapper>
      <ModalHeader>
        <ImgCloseWrapper onClick={onClose} src={ImgClose} />
        <ImageCenterWrapper>
          <img src={ImgLock} alt="lock game" />
          <img src={img} alt="selected game" />
        </ImageCenterWrapper>
      </ModalHeader>
      <ModalBody>
        {unlock ? (
          <>
            <img src={ImgTick} alt="Played" />
            <TextInfo>
              {t(
                'brainGames:unlockModal.youHave',
                'You have already unlocked this game for today. Continue to play?'
              )}
            </TextInfo>
          </>
        ) : (
          <>
            <img src={ImgKokoCoin} alt="koko coin" />
            <TextInfo>
              {t(
                'brainGames:unlockModal.itTakes',
                'It takes 1 KoKo credit to play this game for today. Continue?'
              )}
            </TextInfo>
          </>
        )}

        <ButtonGroup>
          <ButtonLink onClick={onUnlock}>
            <img src={ImgBtnPlay} alt="Play Game" />
          </ButtonLink>
          <ButtonLink onClick={onClose}>
            <img src={ImgBtnCancel} alt="Cancel Game" />
          </ButtonLink>
        </ButtonGroup>
      </ModalBody>
    </Wrapper>
  );
};

export default React.memo(UnlockGameModal);
