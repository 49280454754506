import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background: #42d8dd;
  border-radius: 50%;
  z-index: 1;
  img {
    width: 75%;
  }
`;

export const Bar = styled.div`
  min-width: 242px;
  height: 40px;
  background: #94e2e7;
  font-weight: 600;
  color: white;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 2rem;
  cursor: pointer;
  :hover {
    background: #a9e8ec;
  }
`;
