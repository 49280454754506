import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './ResultModal.styles';

const ResultModal = () => {
  const { t } = useTranslation(['parentAssign']);
  return (
    <Container>
      <p className="title">
        {t('parentAssign:resultModal.title', 'Assigned Successfully!')}
      </p>
      <p className="subtitle">
        {t(
          'parentAssign:resultModal.subtitle',
          'This homework has been assigned successfully.'
        )}
      </p>
      <p className="details-text">
        {t(
          'parentAssign:resultModal.desc1',
          'Open this homework from My Assignment Page'
        )}
      </p>
      <p className="orange-text">
        {t('parentAssign:resultModal.desc2', '“Assignment &gt; My assignment”')}
      </p>
    </Container>
  );
};

export default ResultModal;
