import React from 'react';
import LeftArrow from 'assets/img/avatarcreator/arrow-left.png';
import RightArrow from 'assets/img/avatarcreator/arrow-right.png';
import { ButtonContainer } from './AvatarArrowButton.style';

function AvatarArrowButton({ direction, isBig, onClick }) {
  return (
    <ButtonContainer onClick={onClick} direction={direction} isBig={isBig}>
      <img alt="arrow" src={direction === 'left' ? LeftArrow : RightArrow} />
    </ButtonContainer>
  );
}

export default React.memo(AvatarArrowButton);
