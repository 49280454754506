import styled from 'styled-components';
import {
  borderColors,
  headerColors,
  fontColors,
  contentColors,
  footerColors,
} from 'constants/heroOfTheDay';
import MathCPIcon from 'assets/img/dailychallenge/icon-cp-shadow.svg';
import ScienceCPIcon from 'assets/img/science-cp-icon.svg';

export const Container = styled.div`
  border-radius: 14px;
  border: ${(props) => `1px solid ${borderColors[props.levelId]}`};
  width: 270px;
  margin-bottom: 2rem;
  p {
    margin-bottom: 0rem;
  }
  .header {
    background-color: ${(props) => `${headerColors[props.levelId]}`};
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    color: ${(props) => `${fontColors[props.levelId]}`};
    font-size: 18px;
    font-weight: 600;
    padding: 0.3rem 1rem;
  }
  .content {
    min-height: 140px;
    background-color: ${(props) => `${contentColors[props.levelId]}`};
    padding: 0.3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .student-name {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 1rem;
      text-align: center;
    }
    .school {
      display: flex;
      align-items: center;
      .school-logo {
        width: 40px;
        height: 40px;
      }
      .school-details {
        margin-left: 1rem;
        .school-name {
          line-height: 1rem;
        }
      }
    }
  }
  .footer {
    background-color: ${(props) => `${footerColors[props.levelId]}`};
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding: 0.3rem 1rem;
    display: flex;
    justify-content: space-between;
    color: #030302;
    align-items: center;
    .cp {
      font-size: 18px;
      font-weight: 600;
      .cp-icon {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const subjectStyles = {
  1: MathCPIcon,
  2: ScienceCPIcon,
};
