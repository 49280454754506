import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CategoryHeader from 'components/BrainGame/CategoryHeader';
import CardGame from 'components/BrainGame/CardGame';
import { isEmpty, isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Categories from 'constants/brainGame';
import {
  getGames,
  getStatus,
  startGame,
  dispose,
} from 'store/brainGame/brainGameSlice';
import { Spinner } from 'react-bootstrap';
import useQuery from 'helpers/useQuery';
import { Content, TimeSpendWrap } from './Arena.style';

// Custom hook for handling timer countdown display
export const useBrainGameTimer = (dispatch) => {
  const { timeRemaining, isStarted, isEnded, startTime } = useSelector(
    (state) => state.brainGame
  );
  useEffect(() => {
    if (
      !isNil(startTime) &&
      !isEmpty(startTime) &&
      !isStarted &&
      isEnded === false
    ) {
      dispatch(startGame());
    }
  }, [dispatch, isEnded, isStarted, startTime]);
  // Clean up hook to clear running timer interval
  useEffect(() => {
    return () => {
      dispatch(dispose());
    };
  }, [dispatch]);
  return timeRemaining;
};

const Arena = () => {
  const dispatch = useDispatch();
  const { categoryID } = useParams();
  const query = useQuery();
  const subjectParam = query.get('subject') ?? '';
  const category = Categories[categoryID];
  const { games, startTime, isLoading } = useSelector(
    (state) => state.brainGame
  );

  const { t } = useTranslation(['brainGames']);
  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGames(categoryID));
  }, [categoryID, dispatch]);

  const BgImg = require(`assets/img/braingame/Koobits-Games-games-${category}-bg.gif`);
  // const categoryData = find(propEq('category', category))(Categories);
  useEffect(() => {
    document.body.style.backgroundImage = `url(${BgImg})`;
    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [BgImg]);

  const timeRemaining = useBrainGameTimer(dispatch);
  return (
    <Content bg={BgImg}>
      <CategoryHeader
        category={category}
        subject={subjectParam}
        timeRemaining={timeRemaining}
      />
      <div className="container-sm">
        <TimeSpendWrap className="col-md-12 col-sm-12 col-xs-12">
          <p className="time-span">
            {' '}
            {t(
              'brainGames:timeHeader.startedTime',
              'Time You Started Today'
            )}:{' '}
          </p>
          <span>
            {isLoading && (
              <Spinner animation="border" variant="light" size="sm" />
            )}
            {!isLoading && startTime && moment(startTime).format('HH:mm:ss')}
            {!isLoading &&
              !startTime &&
              t(
                'brainGames:timeHeader.notStartedText',
                'You have not started playing'
              )}
          </span>
        </TimeSpendWrap>
        <div className="col-md-12 nopadding">
          <div className="row">
            {!isNil(games) &&
              games.map((val) => {
                return (
                  <div key={val.GameId} className="col-md-3 col-sm-6">
                    <CardGame
                      gameId={val.GameId}
                      name={val.Name}
                      game={val.GameFolder}
                      img={val.ThumbnailUrl}
                      unlock={val.Unlocked}
                      price={val.PriceToUnlock}
                      subject={subjectParam}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Content>
  );
};

export default React.memo(Arena);
