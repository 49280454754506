import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import {
  fetchChampionClass,
  fetchChampionClassStudents,
} from 'services/leaderboardV2/championClass';
import { RANKING_FILTER } from 'pages/Leaderboard/v2/constant';

export const getChampionClass = createAsyncThunk(
  'leaderboard/getChampionClassLeaderboard',
  async ({ id, category, level, periodType, periodId }) => {
    const params = {
      leaderboardId: id,
      category,
      rankingFilter: RANKING_FILTER.regular,
      level,
      periodType,
      periodId,
    };
    const res = await fetchWrapper(fetchChampionClass, params);
    return handleResponseV2(res);
  }
);

export const getChampionClassStudents = createAsyncThunk(
  'leaderboard/getChampionClassStudents',
  async ({ id, classId, periodType, periodId }) => {
    const params = {
      leaderboardId: id,
      classId,
      periodType,
      periodId,
    };
    const res = await fetchWrapper(fetchChampionClassStudents, params);
    return handleResponseV2(res);
  }
);

const initialState = {
  type: 'term',
  students: {
    isLoading: false,
    error: null,
    data: {},
  },
  championClass: {
    isLoading: false,
    error: null,
    data: [],
  },
};

const championClassLeaderboardSlice = createSlice({
  name: 'championClassLeaderboard',
  initialState,
  reducers: {
    setCCType: (state, action) => {
      state.type = action.payload;
    },
    resetStudents: (state) => {
      state.students.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChampionClass.pending, (state) => {
        state.championClass.data = [];
        state.championClass.isLoading = true;
        state.championClass.error = null;
      })
      .addCase(getChampionClass.fulfilled, (state, action) => {
        state.championClass.data = action.payload;
        state.championClass.isLoading = false;
      })
      .addCase(getChampionClass.rejected, (state, action) => {
        state.championClass.isLoading = false;
        state.championClass.error = action.error.message;
      })
      .addCase(getChampionClassStudents.pending, (state) => {
        state.students.isLoading = true;
        state.students.error = null;
      })
      .addCase(getChampionClassStudents.fulfilled, (state, action) => {
        const { classId } = action.meta.arg;
        const parsedData = {
          [classId]: action.payload,
        };
        state.students.data = { ...state.students.data, ...parsedData };
        state.students.isLoading = false;
      })
      .addCase(getChampionClassStudents.rejected, (state, action) => {
        state.students.isLoading = false;
        state.students.error = action.error.message;
      });
  },
});

const { reducer, actions } = championClassLeaderboardSlice;
export const { setCCType, resetStudents } = actions;
export default reducer;
