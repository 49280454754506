import styled from 'styled-components';
import LessonBg from 'assets/img/kooclass/lessons/lesson-bg1.svg';

export const Container = styled.div`
  background-image: url(${LessonBg});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 59px);
  color: #ffffff;
  background-position: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 30px;
  .lesson-header {
    display: flex;
    align-items: baseline;
    color: #5573a6;
    margin-left: 4rem;
    #dropdown-custom {
      border-radius: 20px;
      display: flex;
      justify-content: center;
    }
    .dropdown-toggle::after {
      display: none;
    }
    span {
      margin-right: 10px;
      color: #3e5e9a;
    }
  }

  .total-scroll {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    color: #000000;
    padding: 10px 30px;
    border-radius: 25px;
    text-align: center;
    font-size: 14px;
    position: relative;
    width: 150px;
    .sc-total {
      color: #698ed3;
      .sc-no {
        color: #000000;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .topic-name {
      color: #1751bc;
    }
    img {
      position: absolute;
      top: -20%;
      left: 26%;
      width: 45%;
    }
  }
`;

export const Body = styled.div`
  height: 500px;
  width: 100%;
`;

export const NoLesson = styled.div`
  text-align: center;
  color: slategray;
  font-size: 2rem;
`;
