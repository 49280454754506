import KooClass from 'assets/img/access-menu/kooclass.svg';
import DC from 'assets/img/access-menu/dc.png';
import Multiplayer from 'assets/img/access-menu/multiplayer.svg';
import Mission from 'assets/img/access-menu/mission.svg';
import KooQuiz from 'assets/img/access-menu/kooquiz.svg';
import Assignment from 'assets/img/access-menu/assignment.svg';
import Events from 'assets/img/access-menu/events.svg';
import BrainGames from 'assets/img/access-menu/braingames.svg';
import StoryMath from 'assets/img/access-menu/storymath.svg';
import Help from 'assets/img/navigation/help.svg';
import HelpActive from 'assets/img/navigation/help-active.svg';
import BellIcon from 'assets/img/navigation/bell.svg';
import BellActiveIcon from 'assets/img/navigation/bell-active.svg';
import EditProfile from 'assets/img/access-menu/edit-profile.svg';
import EditProfileActive from 'assets/img/access-menu/edit-profile-active.svg';
import EditAvatar from 'assets/img/access-menu/edit-avatar.svg';
import EditAvatarActive from 'assets/img/access-menu/edit-avatar-active.svg';
import Settings from 'assets/img/access-menu/settings.svg';
import SettingsActive from 'assets/img/access-menu/settings-active.svg';
import Logout from 'assets/img/access-menu/logout.svg';
import ParentRegister from 'assets/img/access-menu/parent-register.svg';
import ParentRegisterActive from 'assets/img/access-menu/parent-register-active.svg';
import Home from 'assets/img/navigation/home.svg';
import HomeActive from 'assets/img/navigation/home-active.svg';
import Friends from 'assets/img/navigation/friends.svg';
import FriendsActive from 'assets/img/navigation/friends-active.svg';
import Leaderboard from 'assets/img/navigation/leaderboard.svg';
import LeaderboardActive from 'assets/img/navigation/leaderboard-active.svg';
import Report from 'assets/img/navigation/report.svg';
import ReportActive from 'assets/img/navigation/report-active.svg';
import { featureToggle } from 'constants/index';

const quickAccessMenu = (defaultLevel, courseID) => {
  const menuList = [
    {
      name: 'KooClass',
      icon: KooClass,
      link: featureToggle.science
        ? '/kooClass'
        : `/dashboard?view=kooclass&courseid=${courseID}`,
    },
    {
      name: 'Multiplayer',
      icon: Multiplayer,
      link: '/multiplayer',
    },
    {
      name: 'Daily Challenge',
      icon: DC,
      link: '/challenges',
    },
    {
      name: 'Events',
      icon: Events,
      link: '/events',
    },
    {
      name: 'KooQuiz',
      icon: KooQuiz,
      link: '/kooQuiz',
    },
    {
      name: 'Assignment',
      icon: Assignment,
      link: '/assignment',
    },
    {
      name: 'Brain Games',
      icon: BrainGames,
      link: '/brain-game',
    },
    {
      name: 'StoryMath',
      icon: StoryMath,
      link: featureToggle.science ? '/story' : '/story/math',
    },
    {
      name: 'Mission',
      icon: Mission,
      link: '/mission/curriculum-list',
    },
  ];
  return menuList;
};

// Features with isStatic = true returns url from link,
// while isStatic = false is validated & returned from helper function accessFeature
export const quickAccessMenuv2 = [
  {
    name: 'KooClass',
    keyName: 'kooclass',
    icon: KooClass,
    isStatic: false,
  },
  {
    name: 'Peer Challenge',
    keyName: 'peerchallenge',
    icon: Multiplayer,
    isStatic: false,
  },
  {
    name: 'Daily Challenge',
    keyName: 'dailychallenge',
    icon: DC,
    isStatic: false,
  },
  {
    name: 'Sunday Mini',
    keyName: 'smc',
    icon: Events,
    isStatic: false,
  },
  {
    name: 'KooQuiz',
    keyName: 'kooquiz',
    icon: KooQuiz,
    isStatic: false,
  },
  {
    name: 'Assignment',
    keyName: 'assignment',
    icon: Assignment,
    isStatic: true,
    link: '/assignment',
  },
  {
    name: 'Brain Games',
    keyName: 'braingames',
    icon: BrainGames,
    isStatic: false,
  },
  {
    name: 'Story',
    keyName: 'story',
    icon: StoryMath,
    isStatic: false,
  },
  {
    name: 'Mission',
    keyName: 'mission',
    icon: Mission,
    isStatic: true,
    link: '/mission/curriculum-list',
  },
];
// Features with isStatic = true returns url from link,
// while isStatic = false is validated & returned from helper function accessFeature
// mobileOnly = false is for desktop
// isExternalLink = true is for external links
export const quickAccessMenuV3 = [
  {
    keyName: 'quickAccess',
    arr: [
      {
        name: 'KooClass',
        keyName: 'kooclass',
        icon: KooClass,
        isStatic: false,
        premiumOnly: true,
      },
      {
        name: 'Peer Challenge',
        keyName: 'peerchallenge',
        icon: Multiplayer,
        isStatic: false,
      },
      {
        name: 'Daily Challenge',
        keyName: 'dailychallenge',
        icon: DC,
        isStatic: false,
        width: 55,
      },
      {
        name: 'Sunday Mini',
        keyName: 'smc',
        icon: Events,
        isStatic: false,
      },
      {
        name: 'KooQuiz',
        keyName: 'kooquiz',
        icon: KooQuiz,
        isStatic: false,
        premiumOnly: true,
      },
      {
        name: 'Assignment',
        keyName: 'assignment',
        icon: Assignment,
        isStatic: true,
        link: '/assignment',
      },
      {
        name: 'Brain Games',
        keyName: 'braingames',
        icon: BrainGames,
        isStatic: false,
      },
      {
        name: 'Story',
        keyName: 'story',
        icon: StoryMath,
        isStatic: false,
      },
      {
        name: 'Mission',
        keyName: 'mission',
        icon: Mission,
        isStatic: true,
        link: '/mission/curriculum-list',
        width: 42,
      },
    ],
  },
  {
    keyName: 'help',
    arr: [
      {
        name: 'Home',
        keyName: 'home',
        icon: Home,
        activeIcon: HomeActive,
        isStatic: true,
        link: '/dashboard',
        mobileOnly: true,
      },
      {
        name: 'Report',
        keyName: 'report',
        icon: Report,
        activeIcon: ReportActive,
        isStatic: true,
        link: '/report/proficiency',
        mobileOnly: true,
      },
      {
        name: 'Leaderboard',
        keyName: 'leaderboard',
        icon: Leaderboard,
        activeIcon: LeaderboardActive,
        isStatic: true,
        link: '/leaderboard',
        mobileOnly: true,
      },
      {
        name: 'Friends',
        keyName: 'friends',
        icon: Friends,
        activeIcon: FriendsActive,
        isStatic: true,
        link: '/friends',
        mobileOnly: true,
      },
      {
        name: 'Help',
        keyName: 'help',
        icon: Help,
        activeIcon: HelpActive,
        isExternalLink: true,
        link: 'https://support.koobits.com/hc',
        width: 30,
      },
      {
        name: 'Notification',
        keyName: 'notification',
        icon: BellIcon,
        activeIcon: BellActiveIcon,
        isStatic: true,
        link: '/notification',
        width: 30,
      },
    ],
  },
  {
    keyName: 'profile',
    arr: [
      {
        name: 'Edit Avatar',
        keyName: 'editAvatar',
        icon: EditAvatar,
        activeIcon: EditAvatarActive,
        isStatic: true,
        link: '/profile-settings/avatar',
        width: 30,
      },
      {
        name: 'Edit Profile',
        keyName: 'editProfile',
        icon: EditProfile,
        activeIcon: EditProfileActive,
        isStatic: true,
        link: '/profile-settings/profile',
        width: 30,
      },
      {
        name: 'Settings',
        keyName: 'settings',
        icon: Settings,
        activeIcon: SettingsActive,
        isStatic: true,
        link: '/profile-settings/settings',
        width: 30,
      },
      {
        name: 'Parent Register',
        keyName: 'parentRegister',
        icon: ParentRegister,
        activeIcon: ParentRegisterActive,
        isStatic: true,
        link: '/profile-settings/my-subscription',
        width: 30,
      },
      {
        name: 'Logout',
        keyName: 'logout',
        icon: Logout,
        width: 30,
      },
    ],
  },
];

export default quickAccessMenu;
