import { createSlice } from '@reduxjs/toolkit';
import { barModelOptions } from 'constants/index';
import { isNil } from 'ramda';

const { barModelColorOptions, workspace } = barModelOptions;
const { minHeight, minWidth } = workspace;

// example object
// {
//     id: "uid",
//     type: "bar",
//     x: 0,
//     y: 0,
//     zIndex: 0,
//     size: 0,
//     extra: {
//         color: '',
//         content: '',
//         unit:0,
//     }
// }
const initialState = {
  activeIndex: null,
  multiBarModelData: [
    {
      objects: [],
      width: minWidth,
      height: minHeight,
    },
  ],
  data: {
    objects: [],
    width: minWidth,
    height: minHeight,
  },
  activeId: '',
  colorPickerActive: false,
  currentColor: barModelColorOptions[0],
};

const barModelSlice = createSlice({
  name: 'barModel',
  initialState,
  reducers: {
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload;
      if (isNil(state.multiBarModelData[action.payload])) {
        const newBarModelData = [...state.multiBarModelData];
        newBarModelData[action.payload] = {
          objects: [],
          width: minWidth,
          height: minHeight,
        };
        state.multiBarModelData = newBarModelData;
      }
    },
    populateBarModel: (state, action) => {
      state.data = action.payload;
    },
    removeSelected: (state) => {
      if (isNil(state.activeIndex)) {
        state.data.objects = state.data.objects.filter(
          (o) => o.id !== state.activeId
        );
      } else {
        const newBarModelData = [...state.multiBarModelData];
        newBarModelData[state.activeIndex].objects = newBarModelData[
          state.activeIndex
        ].objects.filter((o) => o.id !== state.activeId);
        state.multiBarModelData = newBarModelData;
      }
      state.activeId = null;
    },
    clearAll: (state) => {
      if (isNil(state.activeIndex)) {
        state.data.objects = [];
      } else {
        const newBarModelData = [...state.multiBarModelData];
        newBarModelData[state.activeIndex].objects = [];
        state.multiBarModelData = newBarModelData;
      }
    },
    setCurrentColor: (state, action) => {
      state.currentColor = action.payload;
    },
    setColorPickerActive: (state, action) => {
      state.colorPickerActive = action.payload;
    },
    setEditing: (state, action) => {
      const currentSelectedObject = isNil(state.activeIndex)
        ? state.data.objects.find((o) => o.id === state.activeId)
        : state.multiBarModelData[state.activeIndex].objects.find(
            (o) => o.id === state.activeId
          );
      if (currentSelectedObject) {
        if (
          currentSelectedObject.type === 'text' &&
          currentSelectedObject.extra.text.trim().length === 0
        ) {
          if (isNil(state.activeIndex)) {
            state.data.objects = state.data.objects.map((o) =>
              o.id === currentSelectedObject.id
                ? { ...o, extra: { ...o.extra, text: '?' } }
                : o
            );
          } else {
            const newBarModelData = [...state.multiBarModelData];
            newBarModelData[state.activeIndex].objects = newBarModelData[
              state.activeIndex
            ].objects.map((o) =>
              o.id === currentSelectedObject.id
                ? { ...o, extra: { ...o.extra, text: '?' } }
                : o
            );
            state.multiBarModelData = newBarModelData;
          }
        }
      }
      state.activeId = action.payload;
      if (action.payload !== null) {
        const object = isNil(state.activeIndex)
          ? state.data.objects.find((o) => o.id === action.payload)
          : state.multiBarModelData[state.activeIndex].objects.find(
              (o) => o.id === action.payload
            );
        if (object.type === 'bar') {
          state.currentColor = object.extra.color;
        }
      }
    },
    setWorkspaceSize: (state, action) => {
      const { width, height } = action.payload;
      state.data.width = width;
      state.data.height = height;
    },
    addObject: (state, action) => {
      const { width, height } = state.data;
      const isVertical = action.payload.type === 'vBrace';
      if (isNil(state.activeIndex)) {
        state.data.objects = [
          ...state.data.objects,
          {
            ...action.payload,
            x: (width - 64) / 2 - (isVertical ? 10 : 50), // center the created object
            y: (height - 150) / 2 - (isVertical ? 50 : 10), // center the created object
            zIndex: state.data.objects.length + 1,
          },
        ];
      } else {
        const newBarModelData = [...state.multiBarModelData];
        newBarModelData[state.activeIndex].objects = [
          ...newBarModelData[state.activeIndex].objects,
          {
            ...action.payload,
            x: (width - 64) / 2 - (isVertical ? 10 : 50), // center the created object
            y: (height - 150) / 2 - (isVertical ? 50 : 10), // center the created object
            zIndex: newBarModelData[state.activeIndex].objects.length + 1,
          },
        ];
        state.multiBarModelData = newBarModelData;
      }
    },
    changeObjectPosition: (state, action) => {
      const { id, x, y } = action.payload;
      if (isNil(state.activeIndex)) {
        state.data.objects = state.data.objects.map((o) =>
          o.id === id ? { ...o, x, y } : o
        );
      } else {
        const newBarModelData = [...state.multiBarModelData];
        newBarModelData[state.activeIndex].objects = newBarModelData[
          state.activeIndex
        ].objects.map((o) => (o.id === id ? { ...o, x, y } : o));
        state.multiBarModelData = newBarModelData;
      }
    },
    changeObjectSize: (state, action) => {
      const { id, size } = action.payload;
      if (isNil(state.activeIndex)) {
        state.data.objects = state.data.objects.map((o) =>
          o.id === id ? { ...o, size } : o
        );
      } else {
        const newBarModelData = [...state.multiBarModelData];
        newBarModelData[state.activeIndex].objects = newBarModelData[
          state.activeIndex
        ].objects.map((o) => (o.id === id ? { ...o, size } : o));
        state.multiBarModelData = newBarModelData;
      }
    },
    changeObjectExtra: (state, action) => {
      const { id, extra } = action.payload;
      if (isNil(state.activeIndex)) {
        state.data.objects = state.data.objects.map((o) =>
          o.id === id ? { ...o, extra: { ...o.extra, ...extra } } : o
        );
      } else {
        const newBarModelData = [...state.multiBarModelData];
        newBarModelData[state.activeIndex].objects = newBarModelData[
          state.activeIndex
        ].objects.map((o) =>
          o.id === id ? { ...o, extra: { ...o.extra, ...extra } } : o
        );
        state.multiBarModelData = newBarModelData;
      }
    },
  },
});

const { actions, reducer } = barModelSlice;
export const {
  addObject,
  changeObjectExtra,
  changeObjectPosition,
  changeObjectSize,
  setEditing,
  setWorkspaceSize,
  setColorPickerActive,
  clearAll,
  populateBarModel,
  removeSelected,
  setActiveIndex,
} = actions;
export default reducer;
