import styled from 'styled-components';

export const Container = styled.div`
  .score-section {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -4%;

    @media (max-width: 670px) {
      margin-left: -0.5rem;
    }
  }

  .mascot {
    display: ${(props) =>
      props.score === 100 && !props.isMobile ? 'block' : 'none'};
    position: absolute;
    left: 5%;
    width: 200px;
    @media (max-width: 2560px) {
      top: 35%;
    }
    @media (min-width: 2300px) {
      left: 7%;
    }
    @media (max-width: 2000px) {
      top: 35%;
    }
    @media (max-width: 1537px) {
      left: 5%;
    }
    @media (max-width: 1367px) {
      left: 1%;
      height: 50%;
      top: 25%;
    }
    @media (max-width: 1140px) {
      left: 0%;
    }
    @media (max-width: 951px) {
      left: 3%;
      top: 40%;
      width: 120px;
    }
    @media (max-width: 876px) {
      left: 1%;
    }
    @media (max-width: 600px) {
      left: -5%;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }

  .alert-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;

    .answer-status {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
      margin-right: 1rem;
    }
    p {
      margin-bottom: 1rem;
    }
    .time {
      color: #13bdee;
      font-size: 13px;
    }
    .unmark-answer {
      color: #414748;
      background: rgb(143, 231, 254, 0.39);
      padding: 0.2rem 2rem;
      border-radius: 8px;
      font-size: 13px;
      width: fit-content;
    }
  }
  .qn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1.5rem;
    height: 160px;
    overflow-y: scroll;
  }
  .action-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.2rem 5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    @media (max-width: 670px) {
      padding: 0.2rem;
    }
    .alt-btn {
      background: none;
      border: none;
      color: #f56200;
      font-size: 17px;
      text-decoration: underline;
      :hover {
        text-decoration: none;
      }
    }
  }

  .action-btn {
    .submit-container {
      display: flex;
      justify-content: center;
    }
    .submit-button {
      width: 40%;
      max-width: 450px;
    }
  }

  @media (max-width: 846px) {
    .alert-section {
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 0.5rem;
      }
    }

    .answer-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.5rem;
      margin-right: 1rem;
    }

    .time {
      text-align: center;
    }

    .qn {
      max-height: 200px;
    }
  }

  @media (max-width: 502px) {
    .alert-section {
      margin-top: 0rem;

      .answer-status {
        margin-bottom: 0rem;
        margin-right: 0rem;

        .correct-answer {
          width: 105%;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          margin-left: 1.2rem;
        }
      }

      .unmark-answer {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      p,
      .unmark-answer {
        margin-bottom: 0rem;
        margin-left: 0rem !important;
        border-radius: 0px;
      }
    }

    .score-section {
      width: 105%;
      margin-top: 2.5rem;
    }

    .qn {
      height: 30vh;
      width: 100%;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .action-btn {
      display: flex;
      align-items: center;
      background-color: #f6f6f6;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin: 0rem;

      .submit-button {
        width: 100%;
      }
    }
  }
`;
