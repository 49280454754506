import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { isNil } from 'ramda';
import Button from 'components/Shared/Button';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { updateDoB } from 'services/zodiacRace';
import { fetchWrapper } from 'services/login';
import { Spinner } from 'react-bootstrap';
import { zodiacs, zodiacNameIDHashmap } from 'constants/leaderboard';
import { getSign } from 'horoscope';
import { useDispatch } from 'react-redux';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import { getCurrentUserZodiac } from 'store/zodiacRace/zodiacRaceSlice';
import { useTranslation } from 'react-i18next';
import {
  Title,
  Instructions,
  DropdownsContainer,
  ZodiacInfo,
  ZodiacDetails,
  ErrorMessage,
} from './DateOfBirthForm.styles';

// Helper for checking zodiac type based on day & month of birth date
const getZodiacType = (day, month) => {
  const zodiac = getSign({ day, month });
  const zodiacID = zodiacNameIDHashmap[zodiac.toLowerCase()];
  if (isNil(zodiacID)) {
    return {
      error: 'Zodiac ID not found',
    };
  }
  const zodiacDetails = zodiacs[zodiacID];
  return {
    zodiac: zodiacDetails,
  };
};

const DateOfBirthForm = ({ close, openJoinedModal, seasonID }) => {
  const dispatch = useDispatch();
  // Component States
  const { t } = useTranslation(['leaderboard']);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedZodiac, setSelectedZodiac] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const isAllDropdownSelected =
    !isNil(selectedDay) && !isNil(selectedMonth) && !isNil(selectedYear);

  const dayOptions = Array.from({ length: 31 }, (v, k) => k + 1);
  const monthOptions = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };
  const yearOptions = Array.from(
    { length: 20 },
    (v, k) => k + (moment().year() - 19)
  );

  // Event Handlers
  const onSubmit = async () => {
    setSubmitError(null);
    setIsSubmitting(true);
    const dobString = `${selectedYear}-${
      monthOptions[selectedMonth] < 10
        ? `0${monthOptions[selectedMonth]}`
        : monthOptions[selectedMonth]
    }-${selectedDay < 10 ? `0${selectedDay}` : selectedDay}T00:00:00`;
    try {
      await fetchWrapper(updateDoB, dobString);
      dispatch(getStudentDetails());
      dispatch(getCurrentUserZodiac({ seasonID }));
      close();
      openJoinedModal();
    } catch (err) {
      console.log(err);
      setSubmitError(err?.message ?? 'Update date of birth failed');
    }
    setIsSubmitting(false);
  };

  // UseEffects
  useEffect(() => {
    if (isAllDropdownSelected === true) {
      const daysInSelectedMonth = moment(
        `${selectedYear}-${monthOptions[selectedMonth]}`,
        'YYYY-MM'
      ).daysInMonth();
      if (selectedDay > daysInSelectedMonth) {
        setIsInvalidDate(
          `Please select a valid day, there are only ${daysInSelectedMonth} days in ${selectedMonth}`
        );
        setSelectedZodiac(null);
      } else {
        setIsInvalidDate(null);
        // Check Zodiac
        const { error, zodiac } = getZodiacType(
          selectedDay,
          monthOptions[selectedMonth]
        );
        if (isNil(error)) {
          setSelectedZodiac(zodiac);
        }
      }
    }
  }, [
    isAllDropdownSelected,
    monthOptions,
    selectedDay,
    selectedMonth,
    selectedYear,
  ]);
  return (
    <>
      <Title>{t('zodiacRace.welcome', 'Welcome to Zodiac Race')}</Title>
      <Instructions>
        {t('zodiacRace.birthDay', 'Please Choose Your Date of Birth')}
      </Instructions>
      <DropdownsContainer class="dob-form">
        <DropdownMenu
          className="drop-down"
          dataCy="dropdown-day"
          selectedValue={
            isNil(selectedDay) ? t('zodiacRace.day', 'Day') : selectedDay
          }
          values={dayOptions}
          setValue={(val) => {
            setSelectedDay(val);
          }}
          width="87px"
        />
        <DropdownMenu
          className="drop-down"
          dataCy="dropdown-month"
          selectedValue={
            isNil(selectedMonth)
              ? t('zodiacRace.month', 'Month')
              : selectedMonth
          }
          values={Object.keys(monthOptions)}
          setValue={(val) => {
            setSelectedMonth(val);
          }}
          width="87px"
        />
        <DropdownMenu
          className="drop-down"
          dataCy="dropdown-year"
          selectedValue={
            isNil(selectedYear) ? t('zodiacRace.year', 'Year') : selectedYear
          }
          values={yearOptions}
          setValue={(val) => {
            setSelectedYear(val);
          }}
          width="87px"
        />
      </DropdownsContainer>
      {(isInvalidDate !== null || submitError !== null) && (
        <ErrorMessage>
          {submitError}
          {isInvalidDate}
        </ErrorMessage>
      )}
      {isAllDropdownSelected === true && (
        <ZodiacInfo>
          <ZodiacDetails>
            {selectedZodiac !== null && (
              <>
                <img src={selectedZodiac.image} width="56px" alt="" />
                <div className="text">
                  <p>
                    <span className="name">{selectedZodiac.name}</span>
                    <span className="dates">{` (${moment(
                      selectedZodiac.startDate,
                      'DD-MM'
                    ).format('MMM DD')}-${moment(
                      selectedZodiac.endDate,
                      'DD-MM'
                    ).format('MMM DD')})`}</span>
                  </p>
                  <p className="desc">{selectedZodiac.desc}</p>
                </div>
              </>
            )}
          </ZodiacDetails>
          <Button
            dataCy="submit-button"
            variant={isInvalidDate !== null ? 'disabled' : 'primary'}
            padding="10px 30px"
            isDisabled={isInvalidDate !== null || isSubmitting === true}
            onClick={onSubmit}
          >
            {isSubmitting === true && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '0.5rem' }}
              />
            )}
            Join
          </Button>
        </ZodiacInfo>
      )}
    </>
  );
};

export default DateOfBirthForm;
