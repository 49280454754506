import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_KOOCLASS_API_DOMAIN;

export const fetchScroll = () => {
  return fetch(`${baseUrl}Kooclass/GetAllRewards`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get scrolls reward failed'); // eslint-disable-line
    });
};

export const fetchCourses = () => {
  return fetch(`${baseUrl}KooClass/GetCourses`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get courses data failed'); // eslint-disable-line
    });
};

export const fetchTopicsByCourse = ({ courseId, currentLevel }) => {
  if (!courseId) throw new Error('Course Id undefined');
  return fetch(
    `${baseUrl}KooClass/GetTopicsByCourseId/${courseId}/${currentLevel}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get topics data by course failed'); // eslint-disable-line
    });
};

export const fetchTopicById = (topicId) => {
  return fetch(`${baseUrl}KooClass/GetTopicByTopicId/${topicId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get topic data by topicid failed'); // eslint-disable-line
    });
};

export const fetchLessonsByTopic = (topicId) => {
  return fetch(`${baseUrl}KooClass/GetLessonsByTopicId/${topicId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get lessons by topicId failed'); // eslint-disable-line
    });
};

export const unlockLessonRequest = (lessonId) => {
  return fetch(`${baseUrl}Kooclass/UnlockLesson/${lessonId}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        if (result.message.code === 'KC002') throw new Error('KC002');
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Unlock lessons failed'); // eslint-disable-line
    });
};

export const fetchLessonByLessonId = (lessonId) => {
  return fetch(`${baseUrl}Kooclass/GetLessonByLessonId/${lessonId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get lesson by lesson id failed'); // eslint-disable-line
    });
};

export const fetchActivitiesByLessonId = (lessonId) => {
  return fetch(`${baseUrl}Kooclass/GetActivitiesByLessonId/${lessonId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get activities by lesson id failed'); // eslint-disable-line
    });
};

export const fetchActivityByActivityId = (activityId) => {
  return fetch(
    `${baseUrl}Kooclass/GetActivitiesByActivityId?activityId=${activityId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get activity by activity id failed'); // eslint-disable-line
    });
};

export const fetchRetryActivity = (activityId) => {
  return fetch(`${baseUrl}Kooclass/RetryActivity/${activityId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Retry activity failed'); // eslint-disable-line
    });
};

export const postStartActivity = (activityId) => {
  return fetch(`${baseUrl}Kooclass/StartActivity/${activityId}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Start activity failed'); // eslint-disable-line
    });
};

export const saveKooClassSubmission = ({ rawBody, userActivityId }) => {
  return fetch(`${baseUrl}Kooclass/SaveQuestion/${userActivityId}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Save KooClass qn failed'); // eslint-disable-line
    });
};

export const checkKooClassAnswer = ({ rawBody, submissionId }) => {
  return fetch(`${baseUrl}Kooclass/SubmitQuestion/${submissionId}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Check KooClass answer submission failed'
      ); // eslint-disable-line
    });
};

// For Finish button
export const closeKooClass = (userActivityId) => {
  return fetch(`${baseUrl}KooClass/Finish/${userActivityId}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Close KooClass failed'); // eslint-disable-line
    });
};

// End activity
export const postEndActivity = (userActivityId) => {
  return fetch(`${baseUrl}Kooclass/EndActivity/${userActivityId}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'End activity failed'); // eslint-disable-line
    });
};

// export const submitKooClass = (userActivityId) => {
//   return fetch(`${baseUrl}Kooclass/SubmitQuestion/${userActivityId}`, {
//     method: 'POST',
//     headers: {
//       authorization: `Bearer ${localStorage.getItem('access_token')}`,
//       Timezone: store.getState().login.timezone,
//     },
//   })
//     .then(handleResponse)
//     .then((result) => {
//       if (!result.isSuccessful) {
//         throw new Error(result.message.message);
//       } else {
//         return result.result;
//       }
//     })
//     .catch((err) => {
//       throw new Error(err?.message ?? 'Submit KooClass failed');
//     });
// };

export const fetchKooClassSolutions = (activityId) => {
  return fetch(`${baseUrl}Kooclass/ActivityResult/${activityId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get kooClass solutions failed');
    });
};
