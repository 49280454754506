import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import { fetchHodLeaderboard } from 'services/leaderboardV2/hod';
import { RANKING_FILTER } from 'pages/Leaderboard/v2/constant';

export const getHodLeaderboard = createAsyncThunk(
  'leaderboard/getHodLeaderboard',
  async ({ id, category, level }) => {
    const params = {
      leaderboardId: id,
      category,
      rankingFilter: RANKING_FILTER.regular,
      level,
    };
    const res = await fetchWrapper(fetchHodLeaderboard, params);
    return handleResponseV2(res);
  }
);

export const getHodTop50Leaderboard = createAsyncThunk(
  'leaderboard/getHodTop50Leaderboard',
  async ({ id, category, level, date }) => {
    const params = {
      leaderboardId: id,
      category,
      rankingFilter: RANKING_FILTER.top50,
      level,
      date,
    };
    const res = await fetchWrapper(fetchHodLeaderboard, params);
    return handleResponseV2(res);
  }
);

export const getHallOfFameHeros = createAsyncThunk(
  'leaderboard/getHallOfFameHeros',
  async ({ id, level, date }) => {
    const params = {
      leaderboardId: id,
      category: 2, // default to country for hod only
      rankingFilter: RANKING_FILTER.hallOfFame,
      level,
      date,
    };
    const res = await fetchWrapper(fetchHodLeaderboard, params);
    return handleResponseV2(res);
  }
);

export const getHallOfFameLeaderboard = createAsyncThunk(
  'leaderboard/getHallOfFameLeaderboard',
  async ({ id, category, level, date }) => {
    const params = {
      leaderboardId: id,
      category,
      rankingFilter: RANKING_FILTER.top50,
      level,
      date,
    };
    const res = await fetchWrapper(fetchHodLeaderboard, params);
    return handleResponseV2(res);
  }
);

const initialState = {
  hodData: {
    isLoading: false,
    error: null,
    data: [],
  },
  hofHero: {
    isLoading: false,
    error: null,
    heros: [],
  },
  hofData: {
    isLoading: false,
    error: null,
    data: [],
  },
};

const hodLeaderboardSlice = createSlice({
  name: 'hodLeaderboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHodLeaderboard.pending, (state) => {
        state.hodData.data = [];
        state.hodData.isLoading = true;
        state.hodData.error = null;
      })
      .addCase(getHodLeaderboard.fulfilled, (state, action) => {
        state.hodData.data = action.payload;
        state.hodData.isLoading = false;
      })
      .addCase(getHodLeaderboard.rejected, (state, action) => {
        state.hodData.isLoading = false;
        state.hodData.error = action.error.message;
      })
      .addCase(getHodTop50Leaderboard.pending, (state) => {
        state.hodData.data = [];
        state.hodData.isLoading = true;
        state.hodData.error = null;
      })
      .addCase(getHodTop50Leaderboard.fulfilled, (state, action) => {
        state.hodData.data = action.payload;
        state.hodData.isLoading = false;
      })
      .addCase(getHodTop50Leaderboard.rejected, (state, action) => {
        state.hodData.isLoading = false;
        state.hodData.error = action.error.message;
      })
      .addCase(getHallOfFameHeros.pending, (state) => {
        state.hofHero.data = [];
        state.hofHero.isLoading = true;
        state.hofHero.error = null;
      })
      .addCase(getHallOfFameHeros.fulfilled, (state, action) => {
        state.hofHero.data = action.payload;
        state.hofHero.isLoading = false;
      })
      .addCase(getHallOfFameHeros.rejected, (state, action) => {
        state.hofHero.isLoading = false;
        state.hofHero.error = action.error.message;
      })
      .addCase(getHallOfFameLeaderboard.pending, (state) => {
        state.hofData.data = [];
        state.hofData.isLoading = true;
        state.hofData.error = null;
      })
      .addCase(getHallOfFameLeaderboard.fulfilled, (state, action) => {
        state.hofData.data = action.payload.slice(0, 10);
        state.hofData.isLoading = false;
      })
      .addCase(getHallOfFameLeaderboard.rejected, (state, action) => {
        state.hofData.isLoading = false;
        state.hofData.error = action.error.message;
      });
  },
});

const { reducer } = hodLeaderboardSlice;
export default reducer;
