import DrawDiagramIcon from 'assets/img/mission/hotsv2/topics/draw-diagrams.png';
import SystematicListIcon from 'assets/img/mission/hotsv2/topics/systematic-list.png';
import UseEquationsIcon from 'assets/img/mission/hotsv2/topics/use-equations.png';
import GuessCheckIcon from 'assets/img/mission/hotsv2/topics/guess-check.png';
import LookPatternsIcon from 'assets/img/mission/hotsv2/topics/look-patterns.png';
import MakeSuppositionIcon from 'assets/img/mission/hotsv2/topics/supposition.png';
import ActItOutIcon from 'assets/img/mission/hotsv2/topics/act-it-out.png';
import WorkBackwardsIcon from 'assets/img/mission/hotsv2/topics/work-backwards.png';
import BeforeAfterIcon from 'assets/img/mission/hotsv2/topics/before-after.png';
import RestateProblemIcon from 'assets/img/mission/hotsv2/topics/restate-problem.png';
import SolvePartProblemIcon from 'assets/img/mission/hotsv2/topics/solve-part-problem.png';
import SimplifyProblemIcon from 'assets/img/mission/hotsv2/topics/simplify-problem.png';

// Keys to access list of categories and skills are based on id of age group in constants/ageGroups.js
// 4 Categories of Skills

// For Age group 8-11, 10-13
export const CategorizedSkills = [
  {
    category: 'Using A Representation',
    skills: [
      {
        id: 12,
        name: 'Draw Diagrams',
        icon: DrawDiagramIcon,
        position: {
          top: '-6px',
          right: '-10px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 11,
        name: 'Make a Systematic List',
        icon: SystematicListIcon,
        position: {
          top: '77px',
          right: '130px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 10,
        name: 'Use Equations',
        icon: UseEquationsIcon,
        position: {
          top: 'none',
          right: '174px',
          left: 'none',
          bottom: '8px',
        },
      },
    ],
    styles: {
      categoryFontColour: 'rgba(255, 145, 0, 0.84)',
      categoryTextAlign: 'right',
      categoryWidth: '48%',
      categoryTopPosition: '46%',
    },
  },
  {
    category: 'Walking Through the Process',
    skills: [
      {
        id: 1,
        name: 'Act it Out',
        icon: ActItOutIcon,
        position: {
          top: '-6px',
          right: '64px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 2,
        name: 'Work Backwards',
        icon: WorkBackwardsIcon,
        position: {
          top: '77px',
          right: '-130px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 3,
        name: 'Before and After',
        icon: BeforeAfterIcon,
        position: {
          top: 'none',
          right: '-174px',
          left: 'none',
          bottom: '8px',
        },
      },
    ],
    styles: {
      categoryFontColour: 'rgba(138, 196, 26, 0.84)',
      categoryTextAlign: 'left',
      categoryWidth: '36%',
      categoryTopPosition: '46%',
    },
  },
  {
    category: 'Making a Guess',
    skills: [
      {
        id: 9,
        name: 'Guess and Check',
        icon: GuessCheckIcon,
        position: {
          top: '20px',
          right: '173px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 8,
        name: 'Look for Patterns',
        icon: LookPatternsIcon,
        position: {
          top: '115px',
          right: '130px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 7,
        name: 'Make a Supposition',
        icon: MakeSuppositionIcon,
        position: {
          top: 'none',
          right: '-9px',
          left: 'none',
          bottom: '-10px',
        },
      },
    ],
    styles: {
      categoryFontColour: 'rgba(0, 168, 196, 0.74)',
      categoryTextAlign: 'left',
      categoryWidth: '51%',
      categoryTopPosition: '30%',
    },
  },
  {
    category: 'Changing the Problem',
    skills: [
      {
        id: 4,
        name: 'Restate the Problem',
        icon: RestateProblemIcon,
        position: {
          top: '20px',
          right: '-190px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 5,
        name: 'Solve Part of the Problem',
        icon: SolvePartProblemIcon,
        position: {
          top: '115px',
          right: '-178px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 6,
        name: 'Simplify the Problem',
        icon: SimplifyProblemIcon,
        position: {
          top: 'none',
          right: 'none',
          left: '-9px',
          bottom: '-10px',
        },
      },
    ],
    styles: {
      categoryFontColour: 'rgba(226, 64, 64, 0.72)',
      categoryTextAlign: 'left',
      categoryWidth: '38%',
      categoryTopPosition: '30%',
    },
  },
];
// For Age group 6-9
export const Age6To9CategorizedSkills = [
  {
    category: 'Using A Representation',
    skills: [
      {
        id: 9,
        name: 'Draw Diagrams',
        icon: DrawDiagramIcon,
        position: {
          top: '-6px',
          right: '-10px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 8,
        name: 'Make a Systematic List',
        icon: SystematicListIcon,
        position: {
          top: '77px',
          right: '130px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 7,
        name: 'Use Equations',
        icon: UseEquationsIcon,
        position: {
          top: 'none',
          right: '174px',
          left: 'none',
          bottom: '8px',
        },
      },
    ],
    styles: {
      categoryFontColour: 'rgba(255, 145, 0, 0.84)',
      categoryTextAlign: 'right',
      categoryWidth: '48%',
      categoryTopPosition: '46%',
    },
  },
  {
    category: 'Walking Through the Process',
    skills: [
      {
        id: 1,
        name: 'Act it Out',
        icon: ActItOutIcon,
        position: {
          top: '26px',
          right: '-29px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 2,
        name: 'Work Backwards',
        icon: WorkBackwardsIcon,
        position: {
          top: '140px',
          right: '-162px',
          left: 'none',
          bottom: 'none',
        },
      },
    ],
    styles: {
      categoryFontColour: 'rgba(138, 196, 26, 0.84)',
      categoryTextAlign: 'left',
      categoryWidth: '36%',
      categoryTopPosition: '46%',
    },
  },
  {
    category: 'Making a Guess',
    skills: [
      {
        id: 6,
        name: 'Guess and Check',
        icon: GuessCheckIcon,
        position: {
          top: '22px',
          right: '173px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 5,
        name: 'Look for Patterns',
        icon: LookPatternsIcon,
        position: {
          top: '151px',
          right: '82px',
          left: 'none',
          bottom: 'none',
        },
      },
    ],
    styles: {
      categoryFontColour: 'rgba(0, 168, 196, 0.74)',
      categoryTextAlign: 'left',
      categoryWidth: '51%',
      categoryTopPosition: '30%',
    },
  },
  {
    category: 'Changing the Problem',
    skills: [
      {
        id: 3,
        name: 'Restate the Problem',
        icon: RestateProblemIcon,
        position: {
          top: '22px',
          right: '-193px',
          left: 'none',
          bottom: 'none',
        },
      },
      {
        id: 4,
        name: 'Simplify the Problem',
        icon: SimplifyProblemIcon,
        position: {
          top: '151px',
          right: '-82px',
          left: 'none',
          bottom: 'none',
        },
      },
    ],
    styles: {
      categoryFontColour: 'rgba(226, 64, 64, 0.72)',
      categoryTextAlign: 'left',
      categoryWidth: '38%',
      categoryTopPosition: '30%',
    },
  },
];

const HotsTopics = {
  1: Age6To9CategorizedSkills,
  2: CategorizedSkills,
  3: CategorizedSkills,
};

export default HotsTopics;
