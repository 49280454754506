import React from 'react';
import { useTranslation } from 'react-i18next';

const PremiumOnlyIcon = () => {
  const { t } = useTranslation(['dashboardCards']);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="123"
      height="47.838"
      viewBox="0 0 123 52.838"
    >
      <defs>
        <clipPath id="clip4-path">
          <path
            fill="none"
            d="M0 0H123V52.839H0z"
            data-name="Rectangle 2024"
          ></path>
        </clipPath>
        <clipPath id="clip4-path-2">
          <path
            fill="none"
            d="M0 0H123V52.839H0z"
            data-name="Rectangle 2012"
          ></path>
        </clipPath>
        <clipPath id="clip4-path-3">
          <path
            fill="none"
            d="M0 0H123V52H0z"
            data-name="Rectangle 2011"
            transform="translate(0 .839)"
          ></path>
        </clipPath>
        <clipPath id="clip4-path-5">
          <path
            fill="none"
            d="M0 0H5.427V9.547H0z"
            data-name="Rectangle 2014"
            transform="translate(114.212 3.824)"
          ></path>
        </clipPath>
        <linearGradient
          id="linear3-gradient"
          x1="-21.045"
          x2="-20.861"
          y1="5.134"
          y2="5.134"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ffe618"></stop>
          <stop offset="1" stopColor="#ffcc0a"></stop>
        </linearGradient>
        <clipPath id="clip4-path-7">
          <path
            fill="none"
            d="M22.041 7.647a18.945 18.945 0 100 37.891h93.651a3.947 3.947 0 003.948-3.946V7.647z"
            data-name="Path 64548"
          ></path>
        </clipPath>
        <linearGradient
          id="linear3-gradient-2"
          x1="-0.011"
          x2="-0.002"
          y1="1.193"
          y2="1.193"
          xlinkHref="#linear3-gradient"
        ></linearGradient>
        <clipPath id="clip4-path-9">
          <path
            fill="none"
            d="M0 0H33V33H0z"
            data-name="Rectangle 2019"
            transform="translate(12.045 11.883)"
          ></path>
        </clipPath>
        <clipPath id="clip4-path-11">
          <path
            fill="none"
            d="M27.362 31.046a18.563 18.563 0 00-5.588.82v1.36h11.174v-1.36a18.539 18.539 0 00-5.454-.82h-.132"
            data-name="Path 64552"
          ></path>
        </clipPath>
        <linearGradient
          id="linear3-gradient-3"
          x1="-0.792"
          x2="-0.745"
          y1="5.502"
          y2="5.502"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#f5b014"></stop>
          <stop offset="1" stopColor="#ff960d"></stop>
        </linearGradient>
        <clipPath id="clip4-path-12">
          <path
            fill="none"
            d="M26.666 21.737a.7.7 0 000 .258.831.831 0 00.47.785l-2.151 5.538c-.291.29-1.365-1.163-4.248-3.377a.763.763 0 00.224-.552.6.6 0 10-.591.711.507.507 0 00.179-.037l.374 1.609a19.969 19.969 0 01.517 4.518v.024a21.771 21.771 0 0111.841 0v-.024a19.913 19.913 0 01.518-4.518l.374-1.609a.5.5 0 00.179.037.723.723 0 000-1.421.659.659 0 00-.591.711.762.762 0 00.223.552l-3.893 3.377a32.367 32.367 0 01-2.506-5.538.836.836 0 00.47-.785.707.707 0 00-.566-.824.723.723 0 00-.13-.012.707.707 0 00-.694.578"
            data-name="Path 64554"
          ></path>
        </clipPath>
        <linearGradient
          id="linear3-gradient-4"
          x1="-0.399"
          x2="-0.366"
          y1="1.82"
          y2="1.82"
          xlinkHref="#linear3-gradient-3"
        ></linearGradient>
      </defs>
      <g clipPath="url(#clip4-path)" data-name="Group 23564">
        <g data-name="Group 23538">
          <g clipPath="url(#clip4-path-2)" data-name="Group 23537">
            <g
              data-name="Group 23536"
              opacity="0.45"
              style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
            >
              <g data-name="Group 23535">
                <g clipPath="url(#clip4-path-3)" data-name="Group 23534">
                  <g data-name="Group 23533">
                    <g clipPath="url(#clip4-path-3)" data-name="Group 23532">
                      <image
                        width="123"
                        height="52"
                        data-name="Rectangle 2009"
                        transform="translate(0 .839)"
                        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHsAAAA0CAYAAACqyUfGAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAe6ADAAQAAAABAAAANAAAAADls5kYAAAEsklEQVR4Ae2cXW8UZRiGW5AP5TMlAY0CaQwGSDgRDAccEX+FB/wDfw7/wANOPQfjKV9C4MCEBBQqlGg1gsQWa6Fc1zDPMN1uS2latu/s8yR335nd7e6+9zXP+zU7Mzo/Pz+SUbYD3577ZrSuQZTuVmDPf3ehATyasIsHvYkafIC216X7Av4f/WcJ8JeUIwlbFwqNOqO38PX3oUNoDG1Fc+gJeoSm0IzAPSIyynbAjBb0WXQc7UCz6CG6iq6jSQ6M2YSNEwWHfbQMzexj6DTai8zsSWQ8Rn+hhK0bhYd9tE35LiRom/IYlH3G9h7k86OZ2bjQgTDDleCVYd+9DVWgfWDNYC8x/PczMtbPgYAbsHs/ycebWDXsHri+qUeQR1NzJDWfkhvr5YCwbbptwvU9sprNxfHOsGvIvqn/G82EH2TfsB/tRj634KhiP2PtHdDjncjBmYO08D36bB56EyuG3YIsWIf3DgQ+RkJ2/wD6vC49CDLW3wFh25rKwumX07AlY0WwAW0mC9mjKCbwR9g+ioQs7HZm+9qM9+NAtLKxguYBsLrMBvRm/vlDZBM9jr5AJ5CwD6IY2gs4+2xMGEAI2Ijy9V7P32UzuwXa+dqXyEm7/cNhZIab0UL2Q+KDouShjI3kwJKwW6DN3q/QWXQSfYJszoVsE5JwMaGE6Au7D+ivqYzAzfCPUELGhNJiEexlQJvhAbq0eub3xQEztAlAu+9gzAw2kyOjEzRmlB4NbEDb99oPO+p2MGYfLfAEjQldiAY2lXHbgdc4ctTtYKzdR7ObUbIDFexWVjudch7t9MpRd/bRmNCViMy2dM7s/NkFE0uzPJ5nM6N0Bzb/ee9O9NWuc59CZ5Br3MJP2JhQYLhcOoMm0K26nAmYnrgQtmvdLqgnaEzoWgg7MttTk57UsIwlUDYzuuJAZHYAzxMZXSHbpx4BO54SekZHHeiF3dFqZrV0IGEP0XGQsBP2EDkwRFXNzE7YQ+TAEFW1N7NdZsvoqAMBW8hevO2lnpYJHRO6FsIO0P+w/TuyTOCY0LWIzDaj/0B3kcC9PUNmNyZ0KTbVN1gxk70twySaQp4ee4kyOuRAZLZZ/ByZ3Q/q0v0EjgldifgpccB+SMVuIE91+kuVT5FnwvIECSaUHlVmt5pym/Db6Cr6BT1Dmd2Y0IWIZnykvleWffVj9HMtt6dRAseE0iOa8aiHUM1ms/oKigvr87fjmFF6NJltRVrNuQM1++4f0TX0G/oXvUA5JcOEEmMBbCvQas4drAn6B2SW30d/I+fgCR0TCogFibkIthUAuDDtv81oB2sXkVl+E00g5+QJHRM2aNgdu3YiI8sK+rL3Lm1d0dnvrguHeBOvINmB8teomLBBQtAOqn9Fl9D3yBnWs2Vh84KR+spOYTrvFq6Qj6D2rTbi58c8nDFgB8zkGHPZIl9GrozOvhU2LxK4iyo2+UI3k9vQ4yY6XmiQMXgHXPl8hH5CdruOvaYdi60INi+uog/0MZ7wSpKYouVK22urBvXXvnkOPUUCn0Leftox2OruN96C7jzdjDbjEzQmbIAQeDM4q2dX1dd6p8zurUgN3YcTdK85g92vRt+Arsr4Kq8AmRj7OxvGNi4AAAAASUVORK5CYII="
                      ></image>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 23540">
          <g clipPath="url(#clip4-path-5)" data-name="Group 23539">
            <path
              fill="url(#linear3-gradient)"
              d="M0 0H5.427V9.547H0z"
              data-name="Rectangle 2013"
              transform="translate(114.212 3.824)"
            ></path>
          </g>
        </g>
        <g data-name="Group 23542">
          <g clipPath="url(#clip4-path-2)" data-name="Group 23541">
            <path
              fill="#cea809"
              d="M119.639 3.824A3.824 3.824 0 00115.816 0h-1.6v7.648h1.6a3.824 3.824 0 003.823-3.824"
              data-name="Path 64547"
            ></path>
          </g>
        </g>
        <g data-name="Group 23544">
          <g clipPath="url(#clip4-path-7)" data-name="Group 23543">
            <path
              fill="url(#linear3-gradient-2)"
              d="M0 0H118.393V37.89H0z"
              data-name="Rectangle 2016"
              transform="translate(1.247 7.648)"
            ></path>
          </g>
        </g>
        <g data-name="Group 23551">
          <g clipPath="url(#clip4-path-2)" data-name="Group 23550">
            <g
              data-name="Group 23549"
              opacity="0.15"
              style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
            >
              <g data-name="Group 23548">
                <g clipPath="url(#clip4-path-9)" data-name="Group 23547">
                  <g data-name="Group 23546">
                    <g clipPath="url(#clip4-path-9)" data-name="Group 23545">
                      <image
                        width="33"
                        height="33"
                        data-name="Rectangle 2017"
                        transform="translate(12.045 11.883)"
                        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAIaADAAQAAAABAAAAIQAAAAA1iKI6AAAEOElEQVRYCa3XW5MURRAFYBbwghdUUMFAWVQMQEMe0Dd/v6/7oKFCaEAIGAgKLAvKTVjP11vZdu/MMDPOZsSJrO6pyjx1sqq6Zm17e3vfsvbNhc/WZo35duPnpQOuLUqiJZYcDjQ/JCM5PGt+e1FCc0m05PsTWOKDwSvBq8FLQZGp5E/z7lHwOPgnQOj5PDIvJBECkksm6evBG8FbwTut/XI8NZB4EjwI7gb3WvuveKSehsjz+Kk2k0QjIMnh4P3gg+aPxx8LEEEOUQkkQ+Bm8HtwK7jR/Fb8k1lEppJoBMhu1ieDMw0fxb8XHA3eDKhUJJTifnA7+CO4FlxquBpPncfTiEyQGCjwdgatB18GF4KzATUkfy2gEgJVDmooyd8BMlS4GGwE3weIbAYTRCy03kJAQLNTAgqcD75ungreS25BFoE0O7MuqIegfsgeCmrxptmtnc3kGZWmJ9EIGGCgup8LKIAIRSgznH0eR2YCiIkhLgyJIgkUu9eIeO468qxUqDJ8kXefBxTxziwFnWfiIKK/cUwy29XWVS6+tvBO0KaCBFR4N/g4OB2cCCzOUiDNhU28IqKU1tSZwLqy3Q+0vP3MSgW1lPiT4MPANlxUgXSdMERMQFwlRsb2dt4ol7wjEpIdCci/HlCEMgJ1neP/jw2J2N5IUFe+HRK7FqTEFMAaeztlFQIZ3o23Ruwa58vwoOtKgiWTiGxkogYCVYZVSSRUp6b4tq0S8553lEiDeSjZHMU6YL8XBBKmj1/foTppRySGHYvQXhEQmw3jjtZZlUOnOkiG3vu9smFcB5bnzopEdXD2Pwp4h0vfMe1VrOLXfYPviQxJ1H3gTjpsBU61vmPaq5g44vuw+dzznrtJ7m+3HrN+GPwZXA/cBxDBeFU1jBffcX07uBkgQvFn8g+VMHMqXA1+DRBCbFU1SgWTcs9AortbxO8okQbzYNY6/hZcDiiCcZUlzaVtSIC61wIkHgQ+YP+RaCUxoEpyJe1fAoSwXnahCq4EJrAZSH4xuBTcCNw9u1LEjz7lpYZBynEkcLI5WFid90poz8NuEwNMCAETUN7vgo0AiVuB9aBPZ6PrXfuOOC0lPBWcD74K3CtOBO4HTtQ68YpQXvXJlZWiSknJHwMEEDE5kxzdrEYk8uO+EBEYEQlPBucCF5xPA0Sc/b4xhwL99Gclv+1nAVpTSvpD8FNAkW5B7r7sTpBIxyGRw3k8HiBzKlgPjgU+ybvLZd1sBRagGV8OrrR2bfmRAvmts6kk/NIUIbsZK8/RQHJABKiiPEyd7wQUMGulsM3J/zCY+QdoJokMQmQtztcUGZ92dwKlQKrKoiSsTkQHEjJU6ZLH9zsh7Ql7IQm9GxFkitDBtBGqBYoksyZqUdoZ2nbA3D/Gc0kkSG9TCBU5fWprdok9t/OnHz+rsRSJYZBGaPiqay+aeDjwXyW3hxaM9HcHAAAAAElFTkSuQmCC"
                      ></image>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path
              fill="#fff"
              d="M27.33 15.766a11.868 11.868 0 11-11.868 11.868A11.868 11.868 0 0127.33 15.766"
              data-name="Path 64549"
            ></path>
            <path
              fill="none"
              stroke="#fff"
              strokeWidth="1.079"
              d="M27.33 16.305A11.329 11.329 0 1116 27.633a11.328 11.328 0 0111.33-11.328z"
              data-name="Path 64550"
            ></path>
          </g>
        </g>
        <g data-name="Group 23553">
          <g clipPath="url(#clip4-path-11)" data-name="Group 23552">
            <path
              fill="url(#linear3-gradient-3)"
              d="M16.771 31.629l8.044 2.571 13.136-1.567-8.044-2.566z"
              data-name="Path 64551"
            ></path>
          </g>
        </g>
        <g data-name="Group 23555">
          <g clipPath="url(#clip4-path-12)" data-name="Group 23554">
            <path
              fill="url(#linear3-gradient-4)"
              d="M12.146 25.847l14.7 10.419 15.729-9.742-14.7-10.419z"
              data-name="Path 64553"
            ></path>
          </g>
        </g>
        <g data-name="Group 23563">
          <g clipPath="url(#clip4-path-2)" data-name="Group 23562">
            <path
              fill="#ffb507"
              d="M26.611 33.224h-4.837v-1.359a18.687 18.687 0 015.2-.815l-.37 2.175zm-5.171-2.013v-.022a19.924 19.924 0 00-.516-4.518l-.374-1.611a.484.484 0 01-.18.038.723.723 0 11.591-.71.769.769 0 01-.223.548c1.331 1.023 2.262 1.864 2.939 2.482s1.022.93 1.218.93a.125.125 0 00.091-.037l2.151-5.533a.835.835 0 01-.47-.785.706.706 0 111.389 0 .834.834 0 01-.471.785c.182.461.372.932.562 1.4l-1.055 6.215a20.669 20.669 0 00-5.651.819z"
              data-name="Path 64555"
            ></path>
            <g data-name="Group 23561" style={{ isolation: 'isolate' }}>
              <g data-name="Group 23560">
                <g clipPath="url(#clip4-path-14)" data-name="Group 23559">
                  <g data-name="Group 23558" style={{ isolation: 'isolate' }}>
                    <g data-name="Group 23557">
                      <g clipPath="url(#clip4-path-14)" data-name="Group 23556">
                        <text
                          fill="#0d0d0d"
                          data-name="Premium Only"
                          fontFamily="Linotte-SemiBold"
                          fontSize="10.467"
                          fontWeight="600"
                          transform="translate(45.229 30.492)"
                        >
                          <tspan x="0" y="0">
                            {t('kooQuiz.premiumOnly', 'Premium Only')}
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PremiumOnlyIcon;
