import styled from 'styled-components';
import { Link } from 'react-router-dom';

const gradientTypes = {
  orange: 'linear-gradient(to right, #ffaa0b, #ffc117 50%, #ffaa0b 100%)',
  blue: 'linear-gradient(to right, #00AFF7, #00EEF8 60%, #0093FF 100%)',
};

export const Container = styled.div`
  margin-top: 59px;
  height: 73px;
  background: ${(props) => gradientTypes[props.gradientType] ?? 'none'};
  text-align: center;
  position: relative;
`;

export const BackBtn = styled(Link)`
  background: ${(props) => props.bgColor ?? 'rgba(244, 108, 9, 0.61)'};
  color: white;
  padding: 0.4rem 1rem;
  border-radius: 1.5rem;
  position: absolute;
  top: 25%;
  left: 50px;
  span {
    font-size: 0.875rem;
    font-weight: 600;
  }
  img {
    margin-right: 0.5rem;
    position: relative;
    bottom: 1px;
  }
  :hover {
    color: white;
    text-decoration: none;
  }
`;
