import React from 'react';
import { useTranslation } from 'react-i18next';
import ChangeButton from './ChangeButton';
import { StyledItem } from './SettingItem.styles';
const SettingItem = ({
  onClick,
  name,
  content,
  showChange,
  isDisabled,
  tip,
}) => {
  const { t } = useTranslation(['mySettings']);
  const lowerName = name.toLowerCase();
  return (
    <StyledItem showChange={showChange} data-cy={lowerName}>
      <div className="title">{t(`${lowerName}.title`, name)}</div>
      <div className="content">{content}</div>
      {showChange && (
        <ChangeButton
          name={lowerName}
          onClick={onClick}
          isDisabled={isDisabled}
        />
      )}
      {tip && <div className="tip">{tip}</div>}
    </StyledItem>
  );
};
export default SettingItem;
