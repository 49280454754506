import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { LABELS } from './constants';
import { Container, Lists, Headings } from './BreakdownScore.styles';

const BreakdownScore = ({ data }) => {
  const { t } = useTranslation(['leaderboard']);
  const { timezone } = useSelector((state) => state.login);

  return (
    <Container>
      <div className="list-headings">
        {LABELS.map((item, index) => (
          <Headings key={index}>
            {t(`leaderboard:superhero.${item.translateKey}`, item.name)}
          </Headings>
        ))}
      </div>
      <div>
        <Lists>
          <div className="speed-points">{data.superSpeedPoints}</div>
          <div className="vision-points">{data.superVisionPoints}</div>
          <div className="list-achievement">
            {moment.utc(data.lastSubmissionDate).tz(timezone).format('H:mm')}
          </div>
        </Lists>
      </div>
    </Container>
  );
};

export default BreakdownScore;
