import React, { useState, useEffect, useRef, useCallback } from 'react';
import { iconHashMap, WARP_PADDING } from './constants';
import {
  StyledTextArea,
  StyledTextAreaWrap,
  StyledAnswerResultIcon,
} from './FillInTheBlanksInput.styles';

const FillInTheBlanksInput = ({
  type,
  key,
  name,
  defaultValue,
  register,
  onChange,
  disabled,
  answerClassName,
  hasAutoFocus,
  errors = {},
}) => {
  const [textareaWidth, setTextareaWidth] = useState(0);
  const [textareaHeight, setTextareaHeight] = useState(0);
  const formWidth =
    document.querySelector('form')?.offsetWidth - WARP_PADDING || 0;

  const wrapRef = useRef(null);
  const setTextareaSize = useCallback(
    (content) => {
      if (wrapRef.current) {
        const tempElement = document.createElement('div');
        tempElement.textContent = content;
        tempElement.classList.add('template');
        tempElement.style.maxWidth = `${formWidth}px`;
        tempElement.style.whiteSpace = 'pre-wrap';
        wrapRef.current.appendChild(tempElement);
        const width = tempElement.offsetWidth + 25;
        const height = tempElement.scrollHeight;
        setTextareaHeight(height > 36 ? `${height}px` : '36px');
        setTextareaWidth(width);
        wrapRef.current.removeChild(tempElement);
      }
    },
    [formWidth]
  );
  useEffect(() => {
    if (answerClassName === 'wrong-input') {
      setTextareaSize(defaultValue);
    }
  }, [answerClassName, defaultValue, setTextareaSize]);

  return (
    <StyledTextAreaWrap ref={wrapRef}>
      <StyledTextArea
        key={key}
        name={name}
        ref={register}
        onChange={(e) => {
          setTextareaSize(e.target.value);
          onChange(e);
        }}
        disabled={disabled}
        className={answerClassName}
        autoComplete="off"
        data-cy={`${answerClassName}-${name}`}
        autoFocus={hasAutoFocus === true}
        errors={errors}
        defaultValue={defaultValue}
        textareaWidth={textareaWidth}
        textareaHeight={textareaHeight}
        rows="1"
        type={type}
        formWidth={formWidth}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />
      {(answerClassName === 'correct-input' ||
        answerClassName === 'wrong-input') && (
        <StyledAnswerResultIcon
          src={iconHashMap[answerClassName].src}
          alt={iconHashMap[answerClassName].alt}
          data-cy={`${iconHashMap[answerClassName].alt}-icon-${name}`}
          className="result-icon"
        />
      )}
    </StyledTextAreaWrap>
  );
};
export default FillInTheBlanksInput;
