import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const ColZero = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
`;

export const StyledBvGCategory = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #c6efff;
  align-self: center;
  align-items: center;
  padding: 10px 40px;
  .curriculum-date {
    margin-left: 10px;
  }
`;
