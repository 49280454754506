import styled from 'styled-components';
import Icon from 'assets/img/dashboard/switch-portal.svg';

const Container = styled.div`
  z-index: 100;
  position: absolute;
  bottom: 2%;
  left: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon {
    width: 43px;
    height: 43px;
    background: url(${Icon});
    background-repeat: no-repeat;
    z-index: 1;
  }
  .text {
    background-color: #fff;
    text-align: center;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-width: 151px;
    padding-left: 1.5rem;
    padding-right: 1rem;
    margin-left: -1.2rem;
    z-index: 0;
  }
`;

export default Container;
