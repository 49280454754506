import styled from 'styled-components';
import { featureToggle } from 'constants/index';

export const HeaderNav = styled.nav`
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 59px;
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  background-color: #4a4947;
  box-shadow: 0px 1px 4px rgba(#000, 0.16);
  z-index: 100;
  align-items: center;
  font-family: ${(props) => props.plan === 'parent' && 'Muli'};
  @media (max-width: 768px) {
    padding: 0 15px;
  }
  .navbar-brand {
    display: flex;
    align-items: ${featureToggle.science ? 'center' : 'flex-end'};
    .logo {
      cursor: pointer;
      @media (max-width: 768px) {
        width: 63px;
      }
    }
    .plan {
      color: ${(props) => props.theme[props.plan]?.planTextColor};
      font-size: 16px;
      margin-left: 5px;
      width: 120px;
      position: relative;
      display: inline-block;
      .plan-name {
        cursor: pointer;
      }
      span {
        @media (max-width: 680px) {
          display: none;
        }
      }
      img {
        cursor: pointer;
        height: 20px;
        margin-left: 5px;
        margin-bottom: 5px;
        align-self: flex-end;
        @media (max-width: 680px) {
          margin-left: 0px;
        }
        @media (max-width: 610px) {
          display: none;
        }
      }
      @media (max-width: 1300px) {
        width: 100px;
      }
    }
    .plan .tooltiptext {
      visibility: ${(props) =>
        props.hasSeenToggleTooltip === true ? 'hidden' : 'visible'};
      background-color: white;
      color: black;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      top: 150%;
      left: 50%;
      margin-left: -120px;
    }
    .tooltiptext {
      font-size: 12px;
      font-family: Arial;
      width: 206px;
      p {
        padding-left: 1rem;
        text-align: left;
        margin: 0;
      }
    }
    .plan .tooltiptext::after {
      content: ' ';
      position: absolute;
      bottom: 100%; /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }

    .plan:hover .tooltiptext {
      visibility: visible;
    }
    @media (max-width: 768px) {
      margin-right: 0;
    }
  }
  .parent-portal {
    color: #ffab64;
    font-family: 'Linotte';
    font-size: 15px;
    margin-top: 1rem;
    margin-left: 0.4rem;
    @media (max-width: 768px) {
      margin-top: 0.5rem;
    }
  }
`;
