import styled from 'styled-components';

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.padding};
  background: ${(props) => props.bgColor ?? 'rgba(0,0,0,0.1)'};
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  color: #ffffff;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  svg {
    fill: ${(props) => props.color};
    width: 100%;
    height: 100%;
  }
`;

export default Container;
