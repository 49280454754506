import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 20px;
  margin-top: 1rem;
  cursor: pointer;
  position: relative;
  width: 350px;
  height: 250px;
  // will be revised after xd file is ready
  .hover-desc-block {
    position: absolute;
    top: 9rem;
    left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    .title {
      color: #ffffff;
      font-size: 1.5rem;
      text-shadow: 2px 2px 2px rgba(168, 58, 0, 0.4);
      line-height: 1;
    }
    .sub-title {
      color: #ffffff;
    }
  }
  .hover-desc-block {
    position: static;
    padding: 0.2rem 4rem;
    .title {
      font-size: 1.8rem;
      margin-bottom: 10px;
    }
  }

  .planlogo {
    position: absolute;
    margin-top: 1rem;
    right: 0rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;

    .planlogo {
      right: 0.5rem;
      transform: scale(1.3);
    }
  }

  @media (max-width: 400px) {
    height: 150px;
  }
`;

export const OverlayContainer = styled.div`
  border-radius: 20px;
  background: ${(props) =>
    props.isFeatureReady ? 'none' : 'rgba(30, 30, 30, 0.67)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 5px solid white;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  p {
    padding: 0 10px;
    font-size: 1rem;
    color: white;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  h4 {
    font-weight: 700;
    color: white;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
`;

export const Block = styled.div`
  border-radius: 20px;
  background: ${(props) => props.bg && `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
  border: 5px solid white;
  width: 100%;
  height: 100%;
  filter: ${(props) => (props.showOverlay ? 'blur(4px)' : null)};
  -webkit-filter: ${(props) => (props.showOverlay ? 'blur(4px)' : null)};
  box-shadow: 0px 7px 0px 0px rgba(82, 75, 97, 0.28);
  .desc-block {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 23px;
      font-weight: bold;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      line-height: 1;
      padding: ${(props) => (props.icon ? '0 12%' : '0 5%')};
      text-align: ${(props) => (props.icon ? 'left' : 'center')};
      .icon {
        margin-right: 1rem;
      }
    }
    .sub-title {
      color: #ffffff;
    }
  }

  @media (max-width: 768px) {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 200px;

    .desc-block {
      height: 220px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .illustration {
      width: 35vw;
      margin: 0px;
      transform: scale(1.2);
    }

    .title {
      width: 45vw;
      font-size: 3.5vw;
      z-index: 1;
    }
  }

  @media (max-width: 400px) {
    height: 150px;

    .desc-block {
      height: 180px;
    }

    .illustration {
      width: 35vw;
      transform: scale(1.2);
    }

    .title {
      width: 45vw;
      font-size: 5vw !important;
    }
  }
`;
