import styled from 'styled-components';

export const ChallengeTypeStyle = styled.div`
  display: flex;
  position: absolute;
  right: 3%;
  margin-top: ${(props) =>
    props.banner && props.page !== 'top-brain-classic' ? '5rem' : '0rem'};
  #dropdown-custom {
    font-size: 14px;
    padding: 5px;
    .toggle-icon {
      background: #ffce00;
      position: absolute;
      right: 0.4px;
      height: 97%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
    }
  }
  #dropdown-custom::after {
    content: none;
  }
`;
