import React, { useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Overlay, Spinner } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { getSMCStatus } from 'store/event/eventSlice';
import accessFeature from 'helpers/accessFeature';
import { isNil } from 'ramda';
import { getCurrentDateTimeInTimezone } from 'pages/Events/EventList';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';
import SMCImg from 'assets/img/dashboardv2/smc.png';
import SMCTabletImg from 'assets/img/dashboardv2/smc-tablet.png';
import MathCP from 'assets/img/sidepanel/CP.png';
import Button from 'components/Shared/Button';
import smcState from 'helpers/Dashboard/smcState';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  StyledContainer,
  StyledTooltip,
  StyledSpinnerContainer,
  StyledErrorContainer,
} from './SMCCard.styles';

const SMCCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const { timezone } = useSelector((state) => state.login);
  const { subject, currentProduct } = useSelector((state) => state.plan);
  const target = useRef(null);
  const { t } = useTranslation(['dashboardCards', 'common']);
  const [smcStatus, setSMCStatus] = useState(null);
  const [show, setShow] = useState(false);
  const isSunday = getCurrentDateTimeInTimezone(timezone)?.isoWeekday() === 7;
  const isSaturday = getCurrentDateTimeInTimezone(timezone)?.isoWeekday() === 6;
  const { isSMCStatusLoading, smcStatusError } = useSelector(
    (state) => state.event
  );

  const handleGetStatus = useCallback(() => {
    dispatch(getSMCStatus()).then((action) => {
      if (action.type.includes('fulfilled')) {
        const uiState = smcState(action.payload, timezone);
        setSMCStatus(uiState);
      }
    });
  }, [dispatch, timezone]);
  const checkShow = (status) => {
    if (isSunday) {
      if (status === 'submitted') {
        setShow(true);
        return true;
      }
      return null;
    }
    if (isSaturday) {
      setShow(true);
      return true;
    }
    if (status === 'not-submitted' || status === 'expired') {
      setShow(true);
      return true;
    }
    return false;
  };
  const handleClick = async () => {
    if (checkShow(smcStatus)) {
      return null;
    }
    try {
      const response = await dispatch(getSMCStatus());
      const eventData = unwrapResult(response);
      const uiState = smcState(eventData, timezone);
      setSMCStatus(uiState);
      const { url, error } = accessFeature({
        type: 'smc',
        subject,
        eventData,
        smcStatus,
        featuresConfig: currentProduct?.features,
        timezone,
      });
      if (!error) {
        if (checkShow(uiState)) {
          return null;
        }
        return history.push(url);
      }
      return null;
    } catch (error) {
      console.error({ error });
      return null;
    }
  };

  return (
    <StyledContainer
      smcStatus={smcStatus}
      isSunday={isSunday}
      onClick={handleClick}
      onMouseLeave={() => setShow(false)}
      disabled={isSMCStatusLoading || smcStatusError}
    >
      <div>
        {isSMCStatusLoading && (
          <StyledSpinnerContainer>
            <Spinner animation="border" variant="secondary" />
          </StyledSpinnerContainer>
        )}
        {!isSMCStatusLoading && !isNil(smcStatusError) && (
          <StyledErrorContainer>
            <Button variant="primary" onClick={handleGetStatus}>
              {t('common:reload', 'Reload')}
            </Button>
          </StyledErrorContainer>
        )}

        <img src={isTablet ? SMCTabletImg : SMCImg} alt="smc" ref={target} />
        <p>{t('dashboardCards:smc.name', 'Sunday Mini Challenge')}</p>
        <Overlay placement="top" show={show} target={target.current}>
          <StyledTooltip id="tooltip-smc">
            {isSunday ? (
              t(
                'dashboardCards:smc.result',
                'Results will be released on Monday, 6am'
              )
            ) : (
              <Trans i18nKey="dashboardCards:smc.range">
                <p>Open every Sunday.</p>
                <div className="earn">
                  <p>Earn up to 20</p>
                  <img src={MathCP} alt="cp" />
                </div>
              </Trans>
            )}
          </StyledTooltip>
        </Overlay>
      </div>
    </StyledContainer>
  );
};

export default SMCCard;
