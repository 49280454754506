import React from 'react';
import { useParams } from 'react-router-dom';

import TopBrainMath from './TopBrainMath';
import TopBrainScience from './TopBrainScience';

const TopBrainV2 = () => {
  const { subject } = useParams();

  if (subject === 'science') {
    return <TopBrainScience />;
  }

  return <TopBrainMath />;
};

export default TopBrainV2;
