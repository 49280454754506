import styled from 'styled-components';
import { proficiencyColors } from 'constants/report';

export const Header = styled.div`
  background: #ffeba9;
  padding: 1rem 175px;
  display: flex;
  align-items: center;
  z-index: 1;
  position: fixed;
  width: 100%;
  color: #151515;
  .title {
    font-size: 1.5625rem;
    width: 20%;
    font-weight: 600;
  }
  .back-btn {
    position: absolute;
    left: 3.5%;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #313131;
    img {
      margin-right: 5px;
    }
    &:hover {
      text-decoration: none;
    }
  }
`;

export const DropdownsContainer = styled.div`
  display: flex;
  align-items: center;
  color: #545454;
  span {
    margin: 0 1rem;
  }
  .dropdown {
    border: 1px solid #707070;
  }
  .dropdown-item {
    color: #545454;
  }
  .dropdown-item:active {
    color: white;
  }
  .btn {
    color: #545454;
    padding: 3px 12px;
    height: 30px;
  }
`;

export const Content = styled.div`
  background: #fbfbfb;
  width: ${(props) =>
    props.showSkillDetails ? 'calc(100vw - 360px)' : '100vw'};
  height: calc(100vh - 150px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 130px;
  @media (max-width: 1024px) {
    width: ${(props) =>
      props.showSkillDetails ? 'calc(100vw - 330px)' : '100vw'};
  }
  @media (max-width: 639px) {
    width: ${(props) =>
      props.showSkillDetails ? 'calc(100vw - 330px)' : '100vw'};
  }
  .section-container {
    font-size: 0.75rem;
    padding: 1.5rem 175px 1rem;
    padding-right: ${(props) => props.showSkillDetails && '1%'};
    border-bottom: solid 1px #e9e9e9;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    .report-level-dropdown {
      margin-right: 1rem;
      margin-bottom: 0.5rem;
      span {
        width: 120px;
      }
    }
    .report-level-dropdown,
    .syllabus-dropdown {
      display: flex;
    }
    @media screen and (max-width: 895px) {
      padding: 1rem 0 1rem 0.5rem;
    }
  }
  .total-progress-bar {
    width: 290px;
    p {
      font-size: 21px;
      margin-bottom: 0px;
    }
    span {
      font-size: 12px;
      color: #828282;
    }
  }
  .high-score {
    display: flex;
    img {
      margin-right: 5px;
    }
    .inner-container {
      width: 100%;
    }
  }
  .skills-section {
    display: flex;
    align-items: center;
    margin: 10px 0;
    .ratings-category {
      margin-right: 20px;
      display: flex;
      align-items: center;
      span {
        margin: 0 5px 0 10px;
        font-size: 16px;
        font-weight: 600;
      }
      .sub-text {
        margin: 0;
        font-size: 75%;
        font-weight: 500;
        /* position: relative;
        bottom: -0.25em; */
      }
    }
    @media (max-width: 1366px) {
      margin-top: 11px;
    }
  }
  .legend-section {
    display: flex;
    align-items: center;
    font-family: 'Muli', 'sans-serif';
    margin-bottom: 10px;
    .ratings-category {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 12px;
      margin-right: 20px;
      img {
        width: 13px;
        margin-right: 3px;
      }
      .sub-text {
        color: #afacac;
        font-size: 11px;
        margin-left: 3px;
        font-weight: normal;
      }
    }
  }

  .dropdown-section {
    display: flex;
    margin-left: ${(props) =>
      props.showSkillDetails
        ? props.type === 'proficiency'
          ? '0px'
          : '15px'
        : '0px'};
    margin-top: ${(props) => (props.showSkillDetails ? '15px' : '0px')};
    @media (max-width: 1024px) {
      margin-top: 15px;
    }
  }
  .dropdown-item:active {
    color: #212529;
    background-color: transparent;
  }
  .dropdown-label {
    margin-top: 10px;
  }
  .dropdown-select-syllabus {
    width: 300px;
    margin-left: 10px;
    border: 1px solid rgba(112, 112, 112, 0.48);
    background-color: #ffffff;
    font-size: 14px;
    text-align: left;
    @media (max-width: 1024px) {
      font-size: 12px;
      width: 280px;
    }
  }
  .dropdown-custom {
    width: 75px;
    margin-left: 10px;
    border: 1px solid rgba(112, 112, 112, 0.48);
    background-color: #ffffff;
    font-size: 14px;
    text-align: left;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }
  .dropdown-toggle::after {
    position: relative;
    left: 10px;
    @media (max-width: 744px) {
      left: 3px;
      width: 6px;
      vertical-align: 1px;
    }
  }
`;

export const ProficiencySkills = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  .icon {
    width: 7px;
    height: 7px;
    margin-top: 5px;
    background: ${(props) => proficiencyColors[props.proficiency].color};
    border-radius: 50%;
    border: solid 1px
      ${(props) => proficiencyColors[props.proficiency].borderColor};
  }
  span {
    font-size: 12px;
    color: #363636;
    margin-left: 5px;
    font-family: Nunito;
  }
  .prof-name {
    display: flex;
  }
  .prof-descricption {
    font-size: 10px;
    display: flex;
    align-items: flex-end;
    color: #727272;
    margin-right: 15px;
  }
  @media screen and (max-width: 895px) {
    margin-right: 0 !important;
  }
`;
