import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  height: fit-content;
  color: white;
  text-align: center;
  margin-bottom: 13px;
  cursor: pointer;
  :hover {
    > div {
      transform: scale(0.95);
    }
  }
  .bg {
    width: 100%;
    height: auto;
  }
  .incoming-icon {
    position: absolute;
    top: 9.45%;
    right: -0.3rem;
    width: 20.15%;
    height: auto;
  }
  p {
    position: absolute;
    bottom: 10%;
    text-align: center;
    width: 100%;
    font-size: calc(0.015861571737 * 100vw);
    font-weight: 700;
    margin: 0;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    margin-bottom: 0px;
    .bg {
      width: auto;
    }
    p {
      font-size: 22px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .bg {
      width: 100%;
    }
  }
`;
