import styled from 'styled-components';

export const Content = styled.div`
  font-family: 'AGENCYR Bold';
  font-weight: bold;
`;

export const ImgClosed = styled.img`
  width: 100%;
`;
