import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchTermsTopBrain,
  fetchTopBrainByTerm,
  fetchTopBrainTopFiftyByTerm,
  fetchSeasonsTopBrain,
  fetchTopBrainBySeason,
  fetchTopBrainTopFiftyBySeason,
} from 'services/topBrain';
import { fetchWrapper } from 'services/login';

// get top brain terms
export const getTopBrainTerms = createAsyncThunk(
  'topBrain/getTopBrainTerms',
  async () => {
    try {
      const res = await fetchWrapper(fetchTermsTopBrain);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get top brain terms failed');
    }
  }
);

// get top brain by term
export const getTopBrainByTerm = createAsyncThunk(
  'topBrain/getTopBrainByTerm',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTopBrainByTerm, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get top brain by term failed');
    }
  }
);

// get top 50 top brain by term
export const getTopFiftyTopBrainByTerm = createAsyncThunk(
  'topBrain/getTopFiftyTopBrainByTerm',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTopBrainTopFiftyByTerm, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get top 50 top brain by term failed');
    }
  }
);

// get top brain seasons
export const getTopBrainSeasons = createAsyncThunk(
  'topBrain/getTopBrainSeasons',
  async () => {
    try {
      const res = await fetchWrapper(fetchSeasonsTopBrain);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get top brain seasons failed');
    }
  }
);

// get top brain by seasons
export const getTopBrainBySeason = createAsyncThunk(
  'topBrain/getTopBrainBySeason',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTopBrainBySeason, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get top brain by season failed');
    }
  }
);

// get top 50 top brain by season
export const getTopFiftyTopBrainBySeason = createAsyncThunk(
  'topBrain/getTopFiftyTopBrainBySeason',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTopBrainTopFiftyBySeason, params);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get top 50 top brain by season failed'
      );
    }
  }
);

const initialState = {
  topBrainTerms: [],
  topBrainTermsLoading: false,
  topBrainTermsError: null,
  topBrainData: [],
  isLoading: false,
  isError: null,
  topBrainTopFiftyByTerm: [],
  topBrainTopFiftyByTermLoading: false,
  topBrainTopFiftyByTermError: null,
  topBrainSeasons: [],
  topBrainSeasonsLoading: false,
  topBrainSeasonsError: null,
};

const topBrainSlice = createSlice({
  name: 'topBrain',
  initialState,
  reducers: {},
  extraReducers: {
    [getTopBrainTerms.pending]: (state) => {
      state.topBrainSeasonsLoading = true;
      state.topBrainTerms = [];
      state.topBrainSeasonsError = null;
    },
    [getTopBrainTerms.fulfilled]: (state, action) => {
      state.topBrainSeasonsLoading = false;
      state.topBrainTerms = action.payload;
      state.topBrainSeasonsError = null;
    },
    [getTopBrainTerms.rejected]: (state, action) => {
      state.topBrainSeasonsLoading = false;
      state.topBrainTerms = [];
      state.topBrainSeasonsError = action.error.message;
    },
    [getTopBrainByTerm.pending]: (state) => {
      state.isLoading = true;
      state.topBrainData = [];
      state.isError = null;
    },
    [getTopBrainByTerm.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.topBrainData = action.payload;
      state.isError = null;
    },
    [getTopBrainByTerm.rejected]: (state, action) => {
      state.isLoading = false;
      state.topBrainData = [];
      state.isError = action.error.message;
    },
    [getTopFiftyTopBrainByTerm.pending]: (state) => {
      state.isLoading = true;
      state.topBrainData = [];
      state.isError = null;
    },
    [getTopFiftyTopBrainByTerm.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.topBrainData = action.payload;
      state.isError = null;
    },
    [getTopFiftyTopBrainByTerm.rejected]: (state, action) => {
      state.isLoading = false;
      state.topBrainData = [];
      state.isError = action.error.message;
    },
    [getTopBrainSeasons.pending]: (state) => {
      state.topBrainSeasonsLoading = true;
      state.topBrainSeasons = [];
      state.topBrainSeasonsError = null;
    },
    [getTopBrainSeasons.fulfilled]: (state, action) => {
      state.topBrainSeasonsLoading = false;
      state.topBrainSeasons = action.payload;
      state.topBrainSeasonsError = null;
    },
    [getTopBrainSeasons.rejected]: (state, action) => {
      state.topBrainSeasonsLoading = false;
      state.topBrainSeasons = [];
      state.topBrainSeasonsError = action.error.message;
    },
    [getTopBrainBySeason.pending]: (state) => {
      state.isLoading = true;
      state.topBrainData = [];
      state.isError = null;
    },
    [getTopBrainBySeason.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.topBrainData = action.payload;
      state.isError = null;
    },
    [getTopBrainBySeason.rejected]: (state, action) => {
      state.isLoading = false;
      state.topBrainData = [];
      state.isError = action.error.message;
    },
    [getTopFiftyTopBrainBySeason.pending]: (state) => {
      state.isLoading = true;
      state.topBrainData = [];
      state.isError = null;
    },
    [getTopFiftyTopBrainBySeason.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.topBrainData = action.payload;
      state.isError = null;
    },
    [getTopFiftyTopBrainBySeason.rejected]: (state, action) => {
      state.isLoading = false;
      state.topBrainData = [];
      state.isError = action.error.message;
    },
  },
});

const { reducer } = topBrainSlice;
export default reducer;
