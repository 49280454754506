import styled from 'styled-components';

export const LoginButton = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};
  .custom-button,
  .login-button {
    background: #ffae0c;
    color: #fff;
    font-size: 28px;
    font-family: Linotte;
    font-weight: bold;
    border: none;
    width: 100%;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    @media only screen and (max-width: 1024px) {
      font-size: calc(20 / 1024 * 100vw);
    }
  }

  .custom-button:hover,
  .login-button:hover {
    background: #ff9e0c;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.rwd.mobile}px`}) {
    .custom-button,
    .login-button {
      width: 90%;
      font-size: 24px;
      position: relative;
      top: -4rem;
    }
    .login-button {
      position: static;
      align-self: center;
    }
  }
`;
