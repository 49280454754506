import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAssignmentDetails,
  openAssignmentRequest,
  closeAssignmentRequest,
  saveQuestionSubmissionRequest,
  checkAnswerRequest,
  submitAssignmentRequest,
  claimKoKoCreditsRequest,
  fetchAssignmentSolutions,
  fetchQnSubmission,
} from 'services/assignment';
import { compareValues } from 'helpers/compareValue';
import { fetchWrapper } from 'services/login';
import xmlParser from 'helpers/xmlParser';
import { submitFactualFluencyRequest } from 'services/factualFluency';
import { getRewards } from 'store/dashboard/rewardsSlice';
import { isNil } from 'ramda';

// HELPERS
const parseAnswerKeys = (userQnSubmission) => {
  if (userQnSubmission.SubmissionModel !== null) {
    const filteredXml = userQnSubmission.SubmissionModel.Payload.replace(
      /\\/g,
      ''
    );
    const parsed = xmlParser(filteredXml);
    let answerKeys = null;
    if (parsed.questionAnswers.length > 0) {
      answerKeys = parsed.questionAnswers.map((answer) => answer.$);
    }
    return answerKeys;
  }
  if (userQnSubmission.Question.QuestionType === 3) {
    const filteredXml = userQnSubmission.Question.Payload.replace(/\\/g, '');
    const parsed = xmlParser(filteredXml);
    let answerKeys = null;
    if (parsed.questionAnswers.length > 0) {
      answerKeys = parsed.questionAnswers.map((answer) => answer.$);
    }
    return answerKeys;
  }
  return null;
};

// ASYNC THUNKS
export const getAssignmentDetails = createAsyncThunk(
  'assignment/getAssignmentDetails',
  async (homeworkID) => {
    try {
      const res = await fetchWrapper(fetchAssignmentDetails, homeworkID);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get assignment details failed');
    }
  }
);

export const openAssignment = createAsyncThunk(
  'assignment/openAssignment',
  async (assignmentID) => {
    try {
      const res = await fetchWrapper(openAssignmentRequest, assignmentID);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Open assignment failed');
    }
  }
);

export const getHwSubmission = createAsyncThunk(
  'assignment/getHwSubmission',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchQnSubmission, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get Hw submission failed');
    }
  }
);

// Saves & Finish assignment
export const finishAssignment = createAsyncThunk(
  'assignment/finishAssignment',
  async (params) => {
    const { rawBody, activeQuestion, assignmentSubmissionID } = params;
    try {
      const saveAnswerResponse = await fetchWrapper(
        saveQuestionSubmissionRequest,
        rawBody
      );
      const finishResponse = await fetchWrapper(
        closeAssignmentRequest,
        assignmentSubmissionID
      );
      return {
        saveResult: saveAnswerResponse,
        activeQuestion,
        finishResult: finishResponse,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Finish assignment failed');
    }
  }
);

// Finish & submit assignment
export const finishSubmitAssignment = createAsyncThunk(
  'assignment/finishSubmitAssignment',
  async (params) => {
    const { assignmentSubmissionID } = params;
    try {
      const finishResponse = await fetchWrapper(
        closeAssignmentRequest,
        assignmentSubmissionID
      );
      const submitResponse = await fetchWrapper(
        submitAssignmentRequest,
        params
      );
      return {
        finishResult: finishResponse,
        submitResult: submitResponse,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Finish & submit assignment failed');
    }
  }
);

export const getAssignmentSolutions = createAsyncThunk(
  'assignment/getAssignmentSolutions',
  async (assignmentSubmissionID) => {
    try {
      const res = await fetchWrapper(
        fetchAssignmentSolutions,
        assignmentSubmissionID
      );
      return res;
    } catch (err) {
      throw new Error(err?.message ?? 'Get assignment solutions failed');
    }
  }
);

export const saveAnswer = createAsyncThunk(
  'assignment/saveAnswer',
  async (params, { dispatch, getState }) => {
    const {
      rawBody,
      activeQuestion,
      fetchNextQn = false,
      setAssignmentDuration = false,
      elapsedTime = 0,
    } = params;
    try {
      const res = await fetchWrapper(saveQuestionSubmissionRequest, rawBody);
      if (fetchNextQn) {
        const { assignment } = getState();
        const nextQnIndex = assignment.activeQuestion - 1;
        dispatch(
          getHwSubmission({
            assignmentSubmissionID: rawBody?.homeworkSubmissionID,
            questionId: assignment.questions[nextQnIndex]?.Id,
            activeQuestion: assignment.activeQuestion,
          })
        );
      }
      let assignmentDuration = null;
      if (setAssignmentDuration) {
        const { assignment } = getState();
        const { startAssignmentDuration = 0 } = assignment;
        assignmentDuration = elapsedTime + startAssignmentDuration;
      }
      return {
        result: res,
        activeQuestion,
        assignmentDuration,
      };
    } catch (err) {
      throw new Error(err?.message ?? 'Save question submission failed');
    }
  }
);

export const checkAnswer = createAsyncThunk(
  'assignment/checkAnswer',
  async (params, { dispatch, getState }) => {
    const { rawBody, activeQuestion, fetchNextQn = false } = params;
    try {
      const res = await fetchWrapper(checkAnswerRequest, rawBody);
      if (fetchNextQn) {
        const { assignment } = getState();
        const nextQnIndex = assignment.activeQuestion - 1;
        dispatch(
          getHwSubmission({
            assignmentSubmissionID: rawBody?.homeworkSubmissionID,
            questionId: assignment.questions[nextQnIndex]?.Id,
            activeQuestion: assignment.activeQuestion,
          })
        );
      }
      return {
        result: res,
        activeQuestion,
      };
    } catch (err) {
      throw new Error(err?.message ?? 'Check answer submission failed');
    }
  }
);

export const submitAssignment = createAsyncThunk(
  'assignment/submitAssignment',
  async (params) => {
    const { assignmentID } = params;
    try {
      const submitAssignmentRes = await fetchWrapper(
        submitAssignmentRequest,
        params
      );
      const openAssignmentRes = await fetchWrapper(
        openAssignmentRequest,
        assignmentID
      );
      return {
        submit: submitAssignmentRes,
        koko: {
          hwAllocatedCredits: openAssignmentRes.HWAllocatedKokoCredits,
          currentMonthClaimedCredits:
            openAssignmentRes.CurrentMonthClaimedKokoCredits,
          canBeClaimed: openAssignmentRes.CanBeClaimed,
          totalQns: openAssignmentRes.TotalQuestions,
          totalCorrect: openAssignmentRes.CorrectAnswers,
        },
      };
    } catch (err) {
      throw new Error(err?.message ?? 'Submit assignment failed');
    }
  }
);

export const claimKoKoCredits = createAsyncThunk(
  'assignment/claimKoKoCredits',
  async (assignmentSubmissionID, { dispatch }) => {
    try {
      const res = await fetchWrapper(
        claimKoKoCreditsRequest,
        assignmentSubmissionID
      );
      dispatch(getRewards());
      return res;
    } catch (err) {
      throw new Error(err?.message ?? 'Claim KoKo credits failed');
    }
  }
);

export const finishFactualFluency = createAsyncThunk(
  'assignment/finishFactualFluency',
  async (params) => {
    const { assignmentID } = params;

    try {
      const submitAssignmentRes = await fetchWrapper(
        submitFactualFluencyRequest,
        params
      );
      const openAssignmentRes = await fetchWrapper(
        openAssignmentRequest,
        assignmentID
      );
      return {
        submit: submitAssignmentRes,
        koko: {
          hwAllocatedCredits: openAssignmentRes.HWAllocatedKokoCredits,
          currentMonthClaimedCredits:
            openAssignmentRes.CurrentMonthClaimedKokoCredits,
          canBeClaimed: openAssignmentRes.CanBeClaimed,
          totalQns: openAssignmentRes.TotalQuestions,
          totalCorrect: openAssignmentRes.CorrectAnswers,
        },
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Finish assignment failed');
    }
  }
);

const initialState = {
  startAssignmentInfo: null,
  startAssignmentInfoError: null,
  assignmentSubmissionID: null,
  startAssignmentDuration: null,
  assignmentDuration: null,
  activeQuestion: 1,
  questions: [],
  questionAnswers: [],
  submissions: [],
  checkedAnswers: [],
  localSavedAnswers: [],
  savedAnswers: [],
  workings: [],
  isOpening: false,
  isFinishing: false,
  isLoading: false,
  isSaving: false,
  isChecking: [],
  isSubmitting: false,
  isClaiming: false,
  saveError: null,
  checkAnswerError: null,
  checkAnswerParams: null,
  error: null,
  assignmentSubmissionResult: null,
  assignmentSubmissionError: null,
  assignmentKoKoCreditsDetails: null,
  claimKoKoCreditsResult: null,
  claimKoKoCreditsError: null,
  isSolutionsLoading: false,
  isSolutionsError: null,
  solutions: null,
  showTimesUpModal: false,
  openAssignmentError: null,
  finishAssignmentParams: null,
  finishAssignmentError: null,
  finishSubmitAssignmentParams: null,
  finishSubmitAssignmentError: null,
  submitAssignmentError: null,
  submitAssignmentParams: null,
  finishFactualFluencyError: null,
  finishFactualFluencyParams: null,
};

const assignmentSlice = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    setActiveQuestion: (state, action) => {
      state.activeQuestion = action.payload;
    },
    saveAnswerLocally: (state, action) => {
      const newAnswers = [...state.localSavedAnswers];
      newAnswers[action.payload.index] = action.payload.answers;
      state.localSavedAnswers = newAnswers;
    },
    saveWorkingsLocally: (state, action) => {
      const newWorkings = [...state.workings];
      newWorkings[action.payload.index] = action.payload.workings;
      state.workings = newWorkings;
    },
    clearSaveError: (state) => {
      state.saveError = null;
    },
    clearSavedAnswer: (state, action) => {
      const newSavedAnswers = [...state.savedAnswers];
      newSavedAnswers[action.payload] = null;
      state.savedAnswers = newSavedAnswers;
    },
    setShowTimesUpModal: (state, action) => {
      state.showTimesUpModal = action.payload;
    },
    reset: (state) => {
      return {
        ...state,
        showTimesUpModal: false,
      };
    },
    resetAssignmentDetails: (state) => {
      return {
        ...state,
        startAssignmentInfo: null,
      };
    },
    resetSolution: (state) => {
      return {
        ...state,
        claimKoKoCreditsError: null,
        startAssignmentInfoError: null,
        isSolutionsError: null,
      };
    },
    resetAssignmentViewError: (state) => {
      return {
        ...state,
        checkAnswerError: null,
        assignmentEventSubmissionError: null,
        openAssignmentError: null,
        error: null,
      };
    },
    resetStartAssignmentInfoError: (state) => {
      return {
        ...state,
        assignmentEventSubmissionError: null,
      };
    },
  },
  extraReducers: {
    [getAssignmentDetails.pending]: (state) => {
      state.isLoading = true;
      state.startAssignmentInfo = null;
      state.startAssignmentInfoError = null;
    },
    [getAssignmentDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.startAssignmentInfo = action.payload;
    },
    [getAssignmentDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.startAssignmentInfoError = action.error.message;
    },
    [openAssignment.pending]: (state) => {
      state.isOpening = true;
      state.error = null;
      state.openAssignmentError = null;
    },
    [openAssignment.fulfilled]: (state, action) => {
      state.isOpening = false;
      state.assignmentSubmissionID = action.payload.HwSubId;
      state.assignmentDuration = action.payload.SubmissionDuration;
      state.startAssignmentDuration = action.payload.SubmissionDuration;
      state.localSavedAnswers = Array(
        action.payload.UserQuestionSubmissions.length
      ).fill(null);
      state.workings = Array(
        action.payload.UserQuestionSubmissions.length
      ).fill(null);
      const sortedUserQuestionSubmissions = action.payload.UserQuestionSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      state.isChecking = Array(sortedUserQuestionSubmissions.length).fill(
        false
      );
      state.submissions = sortedUserQuestionSubmissions;
      state.savedAnswers = sortedUserQuestionSubmissions.map(
        (userQnSubmission) => userQnSubmission.SubmissionModel
      );
      // Populate question answers
      state.questionAnswers = sortedUserQuestionSubmissions.map(
        (userQnSubmission) => {
          if (userQnSubmission.SubmissionModel !== null) {
            const filteredXml = userQnSubmission.SubmissionModel.Payload.replace(
              /\\/g,
              ''
            );
            const parsed = xmlParser(filteredXml);
            let answerKeys = null;
            if (parsed.questionAnswers.length > 0) {
              answerKeys = parsed.questionAnswers.map((answer) => answer.$);
            }
            return answerKeys;
          }
          if (userQnSubmission.Question?.QuestionType === 3) {
            const filteredXml = userQnSubmission.Question.Payload.replace(
              /\\/g,
              ''
            );
            const parsed = xmlParser(filteredXml);
            let answerKeys = null;
            if (parsed.questionAnswers.length > 0) {
              answerKeys = parsed.questionAnswers.map((answer) => answer.$);
            }
            return answerKeys;
          }
          return null;
        }
      );
      state.questions = sortedUserQuestionSubmissions.map(
        (userQnSubmission) => userQnSubmission.Question
      );
    },
    [openAssignment.rejected]: (state, action) => {
      state.isOpening = false;
      state.error = action.error.message;
      state.openAssignmentError = action.error.message;
    },
    [getHwSubmission.pending]: () => {},
    [getHwSubmission.fulfilled]: (state, action) => {
      const nextQnIndex = action.meta.arg.activeQuestion - 1;
      const newQuestions = [...state.questions];
      newQuestions[nextQnIndex] =
        action.payload.UserQuestionSubmission.Question;
      state.questions = newQuestions;
      const newQuestionAnswers = [...state.questionAnswers];
      newQuestionAnswers[nextQnIndex] = parseAnswerKeys(
        action.payload.UserQuestionSubmission
      );
      state.questionAnswers = newQuestionAnswers;
      const newSubmissions = [...state.submissions];
      newSubmissions[nextQnIndex] = action.payload.UserQuestionSubmission;
      state.submissions = newSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
    },
    [getHwSubmission.rejected]: () => {},
    [finishAssignment.pending]: (state, action) => {
      state.isFinishing = true;
      state.finishAssignmentError = null;
      state.assignmentKoKoCreditsDetails = null;
      state.finishAssignmentParams = action.meta.arg;
    },
    [finishAssignment.fulfilled]: (state, action) => {
      state.isFinishing = false;
      const newSavedAnswers = [...state.savedAnswers];
      newSavedAnswers[action.payload.activeQuestion - 1] =
        action.payload.saveResult;
      state.savedAnswers = newSavedAnswers;
      state.assignmentDuration = action.payload.finishResult.SubmissionDuration;
      state.submissions = action.payload.finishResult.UserQuestionSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      state.assignmentKoKoCreditsDetails = {
        hwAllocatedCredits: action.payload.finishResult.HWAllocatedKokoCredits,
        currentMonthClaimedCredits:
          action.payload.finishResult.CurrentMonthClaimedKokoCredits,
        canBeClaimed: action.payload.finishResult.CanBeClaimed,
        totalQns: action.payload.finishResult.TotalQuestions,
        totalCorrect: action.payload.finishResult.CorrectAnswers,
      };
    },
    [finishAssignment.rejected]: (state, action) => {
      state.isFinishing = false;
      state.finishAssignmentError = action.error.message;
    },
    [finishSubmitAssignment.pending]: (state, action) => {
      state.isSubmitting = true;
      state.assignmentSubmissionError = null;
      state.assignmentSubmissionResult = null;
      state.assignmentKoKoCreditsDetails = null;
      state.finishSubmitAssignmentParams = action.meta.arg;
      state.finishSubmitAssignmentError = null;
    },
    [finishSubmitAssignment.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      state.assignmentSubmissionResult = action.payload.submitResult;
    },
    [finishSubmitAssignment.rejected]: (state, action) => {
      state.isSubmitting = false;
      state.assignmentSubmissionError = action.error.message;
      state.finishSubmitAssignmentError = action.error.message;
    },
    [getAssignmentSolutions.pending]: (state) => {
      state.isSolutionsLoading = true;
      state.error = null;
      state.isSolutionsError = null;
    },
    [getAssignmentSolutions.fulfilled]: (state, action) => {
      state.isSolutionsLoading = false;
      state.solutions = action.payload;
      state.assignmentSubmissionID = action.payload.HwSubId;
      // Populate questionAnswers
      state.questionAnswers = action.payload.UserQuestionSubmissions.map(
        (qn) => {
          const filteredXml = qn.SubmissionModel.Payload.replace(/\\/g, '');
          const parsed = xmlParser(filteredXml);
          let answerKeys = null;
          if (parsed.questionAnswers.length > 0) {
            answerKeys = parsed.questionAnswers.map((answer) => answer.$);
          }
          return answerKeys;
        }
      );
    },
    [getAssignmentSolutions.rejected]: (state, action) => {
      state.isSolutionsLoading = false;
      state.error = action.error.message;
      state.isSolutionsError = action.error.message;
    },
    [saveAnswer.pending]: (state) => {
      state.isSaving = true;
      state.saveError = null;
    },
    [saveAnswer.fulfilled]: (state, action) => {
      state.isSaving = false;
      const newSavedAnswers = [...state.savedAnswers];
      newSavedAnswers[action.payload.activeQuestion - 1] =
        action.payload.result;
      state.savedAnswers = newSavedAnswers;
      const newSubmissions = [...state.submissions];
      newSubmissions[action.payload.activeQuestion - 1] =
        action.payload.result.UserQuestionSubmission;
      state.submissions = newSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      // Update assignment total duration for mock test/test
      if (!isNil(action.payload.assignmentDuration)) {
        state.assignmentDuration = action.payload.assignmentDuration;
      }
    },
    [saveAnswer.rejected]: (state, action) => {
      state.isSaving = false;
      state.saveError = action.error.message;
    },
    [checkAnswer.pending]: (state, action) => {
      const newisChecking = [...state.isChecking];
      newisChecking[action.meta.arg.activeQuestion - 1] = true;
      state.isChecking = newisChecking;
      state.checkAnswerError = null;
      state.checkAnswerParams = action.meta.arg;
    },
    [checkAnswer.fulfilled]: (state, action) => {
      const newisChecking = [...state.isChecking];
      newisChecking[action.meta.arg.activeQuestion - 1] = false;
      state.isChecking = newisChecking;
      const newSavedAnswers = [...state.savedAnswers];
      newSavedAnswers[action.meta.arg.activeQuestion - 1] =
        action.payload.result;
      state.savedAnswers = newSavedAnswers;
      // state.assignmentDuration = action.payload.result.SubmissionDuration;
      const newSubmissions = [...state.submissions];
      newSubmissions[action.meta.arg.activeQuestion - 1] =
        action.payload.result.UserQuestionSubmission;
      state.submissions = newSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      const newQnAnswers = [...state.questionAnswers];
      newQnAnswers[action.meta.arg.activeQuestion - 1] = parseAnswerKeys(
        action.payload.result.UserQuestionSubmission
      );
      state.questionAnswers = newQnAnswers;
    },
    [checkAnswer.rejected]: (state, action) => {
      const newisChecking = [...state.isChecking];
      newisChecking[action.meta.arg.activeQuestion - 1] = false;
      state.isChecking = newisChecking;
      state.checkAnswerError = action.error.message;
    },
    [submitAssignment.pending]: (state, action) => {
      state.isSubmitting = true;
      state.assignmentSubmissionError = null;
      state.assignmentSubmissionResult = null;
      state.submitAssignmentParams = action.meta.arg;
      state.submitAssignmentError = null;
    },
    [submitAssignment.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      state.assignmentSubmissionResult = action.payload.submit;
      state.assignmentKoKoCreditsDetails = action.payload.koko;
    },
    [submitAssignment.rejected]: (state, action) => {
      state.isSubmitting = false;
      state.assignmentSubmissionError = action.error.message;
      state.submitAssignmentError = action.error.message;
    },
    [finishFactualFluency.pending]: (state, action) => {
      state.isSubmitting = true;
      state.assignmentSubmissionError = null;
      state.assignmentSubmissionResult = null;
      state.finishFactualFluencyParams = action.meta.arg;
      state.finishFactualFluencyError = null;
    },
    [finishFactualFluency.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      state.assignmentSubmissionResult = action.payload.submit;
      state.assignmentKoKoCreditsDetails = action.payload.koko;
    },
    [finishFactualFluency.rejected]: (state, action) => {
      state.isSubmitting = false;
      state.assignmentSubmissionError = action.error.message;
      state.finishFactualFluencyError = action.error.message;
    },
    [claimKoKoCredits.pending]: (state) => {
      state.isClaiming = true;
      state.claimKoKoCreditsError = null;
      state.claimKoKoCreditsResult = null;
    },
    [claimKoKoCredits.fulfilled]: (state, action) => {
      state.isClaiming = false;
      state.claimKoKoCreditsResult = action.payload;
    },
    [claimKoKoCredits.rejected]: (state, action) => {
      state.isClaiming = false;
      state.claimKoKoCreditsError = action.error.message;
    },
  },
});

const { actions, reducer } = assignmentSlice;
export const {
  setActiveQuestion,
  saveAnswerLocally,
  saveWorkingsLocally,
  clearSaveError,
  clearSavedAnswer,
  setShowTimesUpModal,
  reset,
  resetAssignmentDetails,
  resetSolution,
  resetAssignmentViewError,
  resetStartAssignmentInfoError,
} = actions;
export default reducer;
