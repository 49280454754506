import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.3rem;
  background: #f5f5ff;
  padding: 6px 10px;
  border-radius: 50px;
  p {
    margin-left: 0.5rem;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #1a186c;
    font-weight: 600;
    font-size: 14px;
  }
  cursor: pointer;
`;
