import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, isEmpty } from 'ramda';
import { getFriendInfo, resetFriendInfo } from 'store/friends/friendsSlice';
import ButtonsGroup from './ButtonsGroup';
import { Container } from './InputBFFCode.styles';

const BFF_CODE_DIGIT = 8;
const InputBFFCode = ({ closeModal, setInputCode, setbffDetails }) => {
  const { t } = useTranslation(['friends']);
  const dispatch = useDispatch();
  const {
    myFriends,
    BFFDetails,
    friendInfoError,
    friendInfo,
    friendInfoLoading,
  } = useSelector((state) => state.friends);
  const [BFFCode, setBFFCode] = useState('');

  useEffect(() => {
    if (BFFCode.length >= BFF_CODE_DIGIT) {
      dispatch(getFriendInfo(BFFCode));
    }
    // eslint-disable-next-line
  }, [BFFCode]);

  const codeValidation = (bffCode) => {
    const isMyFriend = myFriends.findIndex(
      (friend) => friend.BFFCode === bffCode
    );
    const myFriendName = myFriends.find((friend) => friend.BFFCode === BFFCode);

    const myOwnCode = BFFDetails.MyBaseBffModel.BFFCode === bffCode;
    if (bffCode === '') {
      return { text: '', status: false };
    }
    if (isMyFriend !== -1) {
      return {
        text: `${myFriendName.Fullname} ${t(
          'friends:addFriend.alreadyFriend',
          'is already your friend. You can try a different one.'
        )}`,
        status: false,
      };
    }
    if (myOwnCode) {
      return {
        text: `${bffCode} ${t(
          'friends:addFriend.yourCode',
          'is your BFF code.'
        )}`,
        status: false,
      };
    }
    if (friendInfoError) {
      return {
        text: t(
          'friends:addFriend.invalidCode',
          'The BFF code you entered is invalid. Please ensure all the digits are entered correctly.'
        ),
        status: false,
      };
    }
    if (BFFCode.length < BFF_CODE_DIGIT) {
      return {
        text: t(
          'friends:addFriend.minChar',
          'BFF code should be at least 8 characters'
        ),
        status: false,
      };
    }
    return { text: '', status: true };
  };

  const nextHandler = () => {
    if (codeValidation(BFFCode).status === true && !isNil(friendInfo)) {
      setInputCode(false);
      setbffDetails(true);
    }
  };

  return (
    <Container>
      <p className="desc">
        {t('friends:addFriend.friendscode', `Please enter your friend's`)}{' '}
        <strong>{t('friends:addFriend.bffcode', `BFF code`)}</strong>{' '}
        {t('friends:addFriend.friendscode1', `to send the invitation`)}
      </p>
      <input
        className="bff-code"
        name="bff-code"
        value={BFFCode}
        onChange={(e) => {
          setBFFCode(e.target.value);
          if (!isNil(friendInfoError)) {
            dispatch(resetFriendInfo());
          }
        }}
        placeholder={t(
          'friends:addFriend.friendBffcode',
          `Your Friend's BFF Code`
        )}
      />
      <p className="validation-text">{codeValidation(BFFCode).text}</p>
      <ButtonsGroup
        cancelText={t('friends:addFriend.cancel', `Cancel`)}
        nextText={t('friends:addFriend.next', `Next`)}
        cancelFunction={closeModal}
        nextFunction={nextHandler}
        nextIsDisabled={
          isNil(friendInfo) ||
          isEmpty(BFFCode) ||
          !isEmpty(codeValidation(BFFCode).text)
        }
        loading={friendInfoLoading}
      />
    </Container>
  );
};

export default InputBFFCode;
