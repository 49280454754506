import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setType } from 'store/leaderboard/topBrainLeaderboardSlice';

import useGlobalFeature from 'hooks/useGlobalFeature';
import { isLeaderboardFeatureOpen } from 'pages/Leaderboard/v2/helper';

import { Container, Term, Classic } from './TopBrainToggle.styles';

const TopBrainToggle = () => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { type } = useSelector((state) => state.topBrainV2);

  const { featuresHashmap } = useGlobalFeature(null, [
    'topbrain-leaderboard-termly',
    'topbrain-leaderboard-classic',
  ]);
  const isTermlyOpen = isLeaderboardFeatureOpen(
    featuresHashmap['topbrain-leaderboard-termly']
  );
  const isClassicOpen = isLeaderboardFeatureOpen(
    featuresHashmap['topbrain-leaderboard-classic']
  );

  return (
    <Container>
      <Term
        onClick={() => {
          if (!isTermlyOpen) {
            window.open(
              'https://support.koobits.com/hc/en-gb/articles/4430653191449'
            );
            return;
          }
          dispatch(setType('term'));
        }}
        active={type === 'term'}
      >
        {t('leaderboard:topBrainBanner.termly', `Termly`)}
      </Term>
      <Classic
        onClick={() => {
          if (!isClassicOpen) {
            window.open(
              'https://support.koobits.com/hc/en-gb/articles/4430653191449'
            );
            return;
          }
          dispatch(setType('classic'));
        }}
        active={type === 'classic'}
      >
        {t('leaderboard:topBrainBanner.classic', `Classic`)}
      </Classic>
    </Container>
  );
};

export default TopBrainToggle;
