import Button from 'components/Shared/Button';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -0.35rem;
  padding: 2rem 0rem;
  display: flex;
  justify-content: center;
  flex-flow: column;
  @media (max-width: 1366px) {
    margin-top: -1rem;
  }
`;

export const Title = styled.div`
  display: flex;
  margin: 0rem 15rem;
  padding: 0rem 6rem;

  p {
    margin-bottom: 0rem;
    color: #212121;
    font-size: 20px;
    font-weight: 600;
  }

  @media (max-width: 1366px) {
    padding: 0rem 3rem;
  }
  @media (max-width: 925px) {
    margin: 0rem 3rem;
    padding: 0rem 3rem;
  }
  @media (max-width: 550px) {
    padding: 0rem 4rem;
  }
  @media (max-width: 500px) {
    margin: 0rem 3rem;
    padding: 0rem 0rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 7%);
  border-radius: 10px;
  padding: 1.5rem 0rem 1rem 2rem;
  margin: 1rem 15rem;

  .desktop-print {
    display: block;
  }

  .mobile-print {
    display: none;
  }

  @media (max-width: 1366px) {
    margin: 1rem 10rem;
  }
  @media (max-width: 1024px) {
    margin: 1rem 6rem;
  }
  @media (max-width: 925px) {
    margin: 1rem 3rem;
    .desktop-print {
      display: none;
    }

    .mobile-print {
      display: block;
    }
  }
  @media (max-width: 500px) {
    margin: 1rem 0.8rem;
    width: 95%;
  }
  @media (max-width: 350px) {
    min-width: 320px;
    margin-left: -0.1rem;
    overflow-x: scroll !important;
  }
`;

export const KoKoCredits = styled.div`
  display: flex;
  margin-top: -10px;
  p {
    margin-bottom: 0rem;
    align-self: center;
    margin-left: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  @media (max-width: 1300px) {
    width: 90%;
  }
  @media (max-width: 925px) {
    flex-flow: column;
  }
`;

export const Print = styled.a`
  display: flex;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  span {
    margin-right: 10px;
  }
  p {
    margin-bottom: 0rem;
    color: #878787;
    text-decoration: underline;
  }
  @media (max-width: 925px) {
    margin-left: 8rem;
    margin-bottom: 1rem;
  }
`;

export const StyledSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const StyledButton = styled(Button)`
  height: 3rem;
  font-size: 24px;
  font-weight: 700;
  min-width: 450px;

  @media (max-width: 768px) {
    min-width: 350px;
  }

  @media (max-width: 450px) {
    min-width: 300px;
  }
`;
