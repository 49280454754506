import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { featureToggle } from 'constants/index';
import KooClassBg from 'assets/img/dashboard/kooclass-premium2.svg';
import KooHoverBg from 'assets/img/dashboard/kooclass-hover.svg';
import { CardContainer, StartButton, Block } from './KooClassCard.styles';

const KooClassCard = ({
  isFeatureReady,
  img = KooClassBg,
  hoverImg = KooHoverBg,
  subjectText,
  textPosition,
  textShadowColour,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['dashboard', 'dashboardCards']);
  const [showOverlay, setShowOverlay] = useState(false);
  const { courses } = useSelector((state) => state.kooClass);
  const { defaultLevel } = useSelector((state) => state.studentDetails);

  const courseID = courses && courses[0] && courses[0].id;

  return (
    <CardContainer
      data-cy="kooclass"
      onClick={() => {
        if (isFeatureReady) {
          if (featureToggle.science) {
            history.push('/kooClass');
          } else {
            history.push(`/kooClass/${defaultLevel}/${courseID}`);
          }
        }
      }}
      onMouseEnter={() => {
        if (isFeatureReady) setShowOverlay(true);
      }}
      onMouseLeave={() => {
        if (isFeatureReady) setShowOverlay(false);
      }}
      hover={showOverlay}
      hoverImg={hoverImg}
    >
      <Block
        showOverlay={showOverlay}
        img={img}
        hoverImg={hoverImg}
        textPosition={textPosition}
        textShadowColour={textShadowColour}
      >
        <div className="desc-block">
          {subjectText && <p className="subject">{subjectText}</p>}
          <div className="title">
            {t('dashboardCards:kooClass.name', 'KooClass')}
          </div>
          <div className="sub-title">
            {t(
              'dashboardCards:kooClass.description',
              'Interactive Virtual Class'
            )}
          </div>
        </div>
        <StartButton>
          {t('dashboard:dailyChallenge.button', 'Start')}
        </StartButton>
      </Block>
    </CardContainer>
  );
};

export default KooClassCard;
