import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  margin-top: 2.5rem;
  img {
    margin-right: 8px;
    width: 22px;
    height: 22px;
  }
  p {
    margin-bottom: 0px;
  }
`;
