import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useGlobalFeature from 'hooks/useGlobalFeature';
import { isNil } from 'ramda';
import useQuery from 'helpers/useQuery';

import useCheckCurrentChinese from 'helpers/useCheckCurrentChinese';
import { Container, Item, ThumbnailContainer } from './ChallengeItem.styles';
import MaintenanceIcon from './maintenace.png';
// Helpers
const showMaintenanceIcon = (globalFeatures, leaderboardFeatureNames) => {
  const numOfFeatureNames = leaderboardFeatureNames.length;
  const enabledFeatures = leaderboardFeatureNames.filter((feature) => {
    if (isNil(globalFeatures[feature])) return true;
    return globalFeatures[feature].isEnabled;
  });
  return enabledFeatures.length < numOfFeatureNames;
};

const ChallengeItem = ({ status, challenges }) => {
  const { t } = useTranslation(['leaderboardlist']);
  const history = useHistory();
  const query = useQuery();
  const subject = query.get('subject');
  const isChinese = useCheckCurrentChinese();
  const { featuresHashmap } = useGlobalFeature(null, [
    'hod-leaderboard',
    'topbrain-leaderboard-termly',
    'topbrain-leaderboard-classic',
    'science-topbrain-leaderboard',
    'championclass-leaderboard-classic',
    'championclass-leaderboard-termly',
    'bff-leaderboard',
    'superhero-leaderboard',
    'zodiacrace-leaderboard',
    'boysvsgirls-leaderboard',
    'wizardschool-leaderboard',
    'lioncity-leaderboard',
    'factualfluency-leaderboard',
  ]);
  // Event Handlers
  const accessLeaderboard = (item) => {
    return () => {
      if (status === 'close') {
        return;
      }
      if (item.featureReady) {
        if (item.hasSubject) {
          history.push(`${item.link}/${subject?.toLowerCase() ?? 'math'}`);
        } else {
          history.push(`${item.link}`);
        }
      }
    };
  };
  return (
    <Container>
      {challenges.map((item, index) => (
        <Item
          key={index}
          status={status}
          onClick={accessLeaderboard(item)}
          featureReady={item.featureReady}
        >
          <ThumbnailContainer>
            {status === 'open' &&
              showMaintenanceIcon(featuresHashmap, item.configFeatureNames) && (
                <div className="overlay">
                  <img
                    src={MaintenanceIcon}
                    alt="Maintenance"
                    width="31px"
                    height="31px"
                    className="maintenance-icon"
                  />
                  <span>
                    {t('leaderboardlist:banner.maintenance', 'Maintenance')}
                  </span>
                </div>
              )}
            <img
              src={status === 'open' ? item.img : item.imgClose}
              alt={item.name}
              className="challenge-img"
            />
          </ThumbnailContainer>

          <p className="challenge-name">
            <span style={{ marginRight: '0.25rem' }}>
              <img
                src={item.pointsIcon}
                alt=""
                height="15px"
                className={status === 'close' ? 'point-icon' : ''}
              />
            </span>
            {t(`leaderboardlist:challengeList.${item.name}`, `${item.name}`)}
          </p>
          {item.schedule && (
            <p className="schedule">
              {t(
                `leaderboardlist:challengeList.${item.translation}`,
                `${item.schedule}`
              )}
            </p>
          )}
          {item.scheduleOpen && (
            <p className="schedule">
              {t(`leaderboardlist:challengeList.startDate`, 'Start Date')}
              <span>
                {moment(
                  `${moment().format('YYYY')} ${item.scheduleOpen}`
                ).format(isChinese ? 'YYYY/MM/DD' : 'DD MMM YYYY')}
              </span>
            </p>
          )}
          {item.scheduleClose && (
            <p className="schedule">
              {t(`leaderboardlist:challengeList.closeDate`, 'Close Date')}
              <span>
                {moment(
                  `${moment().format('YYYY')} ${item.scheduleClose}`
                ).format(isChinese ? 'YYYY/MM/DD' : 'DD MMM YYYY')}
              </span>
            </p>
          )}
        </Item>
      ))}
    </Container>
  );
};

export default ChallengeItem;
