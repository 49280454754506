import styled from 'styled-components';

export const DescriptionStyle = styled.div`
  font-size: 18px;
  span {
    font-size: 18px;
    color: #fc6100;
    text-decoration: underline;
    cursor: pointer;
  }
  .expired-mascot {
    position: absolute;
    right: 10%;
    bottom: 0%;
  }
  a {
    text-decoration: underline;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0 10px;
`;
