import styled from 'styled-components';
import CongratsBg from 'assets/img/assignment/assignment-view/congrats-bg.svg';

export const Container = styled.div`
  .top-section {
    background: url(${CongratsBg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 12rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 4rem;
    text-align: center;
    p {
      color: #e29e63;
      font-size: 24px;
      font-weight: 600;
    }

    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      padding-top: 3.3rem;
    }
  }
  .bottom-section {
    text-align: center;
    margin-top: 1rem;
    p {
      margin-bottom: 0rem;
    }
    .info-text {
      color: #040404;
      font-size: 18px;
    }
    .release-date {
      color: #ff5801;
      font-size: 28px;
      font-weight: 600;
    }
    .hint-text {
      color: #6b6b6b;
      font-size: 18px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const FindResult = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  background-color: #fff2f0;
  border-radius: 10px;
  margin: 1rem 35% 0;
  padding: 1rem;
  .text {
    text-align: left;
    line-height: 1.2;
    p {
      color: #ff6701;
      font-weight: 600;
      margin-bottom: 0;
    }
    .small-text {
      font-size: 14px;
      color: #000000;
      font-weight: normal;
    }
  }
  .event-icon {
    background-color: #f7826a;
    padding: 0.5rem 0.5rem 0.5rem 0.6rem;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: 1.5rem;
    img {
      width: 27px;
      height: 28px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    margin: 1rem 20% 0;
  }
`;
