import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

// Icon && Image
import CPIcon from 'assets/img/breakdown-cp/cp-icon.svg';
import DefaultCP from 'assets/img/breakdown-cp/default-cp.svg';
import ActiveCP from 'assets/img/breakdown-cp/active-cp.svg';
import Triangle from 'assets/img/breakdown-cp/triangle.svg';

import { getTodayCPDetails } from 'store/dailyChallenge/cpsDetailSlice';

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;
  width: 107px;
  z-index: 4;
`;

const CPInfo = styled.div`
  position: relative;
  width: 70%;
  height: 25px;
  background: ${(p) => p.theme.topInfo};
  border-radius: 50px;
  text-align: right;
  font-size: 10px;
  padding-right: 12px;
  line-height: 28px;

  .spinner-border {
    margin-right: 4px;
  }

  & > span {
    font-size: 16px;
    font-weight: ${(p) => (p.target === 'smc' ? 500 : 700)};
  }

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -12px;
    top: -3px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.topInfo};
    background-image: url(${CPIcon});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const Dropdown = styled.div``;

const DropdownButton = styled.button`
  background: ${(p) => p.theme.dropdown};
  width: 32px;
  height: 25px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border: 0;

  &:hover {
    background: ${(p) => p.theme.dropdownHover};
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 42px;
  right: -20px;
  width: 132px;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0px 3px 11px rgb(0 0 0 / 10%);
`;

export const DropdownHeader = styled.header`
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  text-align: center;
  background-color: #6cd7ff;
  color: #fff;
  border-radius: 14px 14px 0 0;
  position: relative;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #6cd7ff;
    position: absolute;
    top: -9px;
    right: 20%;
  }
`;

export const DropdownContentItem = styled.div`
  background-color: ${(p) => (p.isHighlight ? p.theme.highlighted : '#e9fbff')};
  height: 46px;
  text-align: center;
  border-bottom: 1px solid #afe6ef;
  padding: 6px 0;

  & > p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1;
  }

  .cps {
    width: 25%;
    font-weight: 700;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cp-text {
    font-size: 12px;
    margin-top: 5px;
    opacity: 0.45;
  }

  &:last-of-type {
    height: 50px;
    border-bottom: 0;
  }
`;

export const DropdownFooter = styled.footer`
  background-color: #525252;
  border-radius: 0 0 14px 14px;
  text-align: center;
  color: #ffffff;
  padding: 10px 0;
  position: relative;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #525252;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

  & > p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 700;
  }

  & > div {
    margin: 6px 0;

    & > img {
      margin-right: 8px;
    }

    & > span {
      font-weight: 700;
    }
  }

  & > button {
    border: 0;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    background-color: #36c8ff;
    color: #ffffff;
    height: 26px;
  }
`;

const theme = {
  dc: {
    topInfo: '#ffffff',
    dropdown: 'rgba(255, 255, 255, 0.5)',
    dropdownHover: 'rgba(255, 255, 255, 0.8)',
    highlighted: '#FFC50D',
  },
  pc: {
    topInfo: '#ffffff',
    dropdown: 'rgba(255, 255, 255, 0.5)',
    dropdownHover: 'rgba(255, 255, 255, 0.8)',
    highlighted: '#FFA739',
  },
  smc: {
    topInfo: 'transparent',
    dropdown: '#FFE5E5',
    dropdownHover: '#FFE5E5',
    highlighted: '#FFD1B4',
  },
};

/**
 *  Component for Breakdown of CPs
 *
 * @component
 * @param target {string} 'dc' | 'smc' | 'pc', mount on which page
 * @param style {CSSStyle} custom style
 * @example
 *
 * // Custom style
 * const style = { position: 'absolute', top: '10vh', right: '3vw' }
 * return (
 *   <BreakdownCP target="dc style={style} " />
 * )
 */
const BreakdownCP = ({
  target = 'dc',
  style,
  hasFirstLoad,
  setHasFirstLoad,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['breakdownCP', 'common']);
  const [isOpen, setIsOpen] = useState(false);
  const { subject } = useSelector((state) => state.plan);
  const {
    dcCP,
    pcCP,
    smcCP,
    loading,
    TodaysTotalCP,
    smcClaimedOfDate,
    isDisplaySMCClaimed,
  } = useSelector((state) => state.cpsDetail);

  useEffect(() => {
    if (hasFirstLoad) return;
    (async function mounted() {
      await dispatch(getTodayCPDetails());
      setHasFirstLoad(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasFirstLoad]);

  const parseCP = (cp) => (cp === 0 ? '-' : cp);
  const parseCPIcon = (cp) => (cp === 0 ? DefaultCP : ActiveCP);

  const onHeroOfTheDay = () => {
    setIsOpen(false);
    window.open(`/leaderboard/hero-of-the-day/${subject}`, '_blank');
  };

  const currentCP = (targetName) => {
    if (targetName === 'dc') return dcCP;
    if (targetName === 'smc') return smcCP;
    if (targetName === 'pc') return pcCP;
    return TodaysTotalCP;
  };

  return (
    <ThemeProvider theme={theme[target]}>
      <Container style={style}>
        <CPInfo target={target}>
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <span>{currentCP(target)} </span>
          )}
          CP
        </CPInfo>
        <Dropdown>
          <DropdownButton
            type="button"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <img src={Triangle} alt="" />
          </DropdownButton>
          {isOpen && (
            <DropdownContainer>
              <DropdownHeader>
                {t('breakdownCP:todayCP', "Today's CP")}
              </DropdownHeader>
              <DropdownContentItem isHighlight={target === 'dc'}>
                <p>{t('breakdownCP:dailyChallenge', 'Daily Challenge')}</p>
                <div className="cps">
                  <img src={parseCPIcon(dcCP)} alt="" />
                  <span>{parseCP(dcCP)}</span>
                </div>
              </DropdownContentItem>
              <DropdownContentItem isHighlight={target === 'pc'}>
                <p>{t('breakdownCP:peerChallenge', 'Peer Challenge')}</p>
                <div className="cps">
                  <img src={parseCPIcon(pcCP)} alt="" />
                  <span>{parseCP(pcCP)}</span>
                </div>
              </DropdownContentItem>
              <DropdownContentItem isHighlight={target === 'smc'}>
                <p> {t('breakdownCP:SMCChallenge', 'Sunday Mini Challenge')}</p>
                {isDisplaySMCClaimed ? (
                  <div className="cp-text">
                    {t(
                      `breakdownCP:claimedOfDay`,
                      {
                        day: t(`common:${smcClaimedOfDate}`),
                      },
                      `Claimed on ${smcClaimedOfDate}`
                    )}
                  </div>
                ) : (
                  <div className="cps">
                    <img src={parseCPIcon(smcCP)} alt="" />
                    <span>{parseCP(smcCP)}</span>
                  </div>
                )}
              </DropdownContentItem>

              <DropdownFooter>
                <p>{t('breakdownCP:todayTotalCP', "Today's Total  CP")}</p>
                <div>
                  <img src={CPIcon} alt="" />
                  <span>{TodaysTotalCP}</span>
                </div>
                {TodaysTotalCP > 0 && (
                  <button type="button" onClick={onHeroOfTheDay}>
                    {t('breakdownCP:heroDay', 'Hero of the Day')}
                  </button>
                )}
              </DropdownFooter>
            </DropdownContainer>
          )}
        </Dropdown>
      </Container>
    </ThemeProvider>
  );
};

export default BreakdownCP;
