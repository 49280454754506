import styled from 'styled-components';

export const StyledContainer = styled.div`
  min-height: auto;
  margin-right: 1.5rem;
  padding-right: 1rem;
  .recent-opponents-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 22px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .recent-opponents-title {
      margin-bottom: 8px;
      font-size: 12px;
    }
  }

  /* @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      min-height: auto;
      margin-right: 0px;
      padding-right: 0px;
      .recent-opponents-title {
        margin-left: 8px;
        margin-bottom: 8px;
      }
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      min-height: 288px;
      .recent-opponents-title {
        margin-left: 0px;
      }
    }
  } */
`;

export const OpponentList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      flex-direction: row;
      margin-bottom: 16px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      flex-direction: column;
      margin-bottom: 0px;
    }
  }
`;

export const StyledCard = styled.div`
  border: ${(props) => (props.isSelected ? 'solid 2px #FF8900' : 'none')};
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 5px 8px;
  cursor: pointer;
  background: ${(props) => (props.isSelected ? '#FFFAF4' : 'white')};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  :hover {
    background: ${(props) => (props.isSelected ? '#FFFAF4' : '#f6feff')};
  }
  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 10px;
  }
  p {
    font-size: 0.875rem;
    width: 118px;
  }
  .long-text {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      width: 32%;
      margin: 0px 8px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      width: 204px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 12px;
    }
  }
`;
export const MobileRecentButton = styled.button`
  border: ${(props) =>
    props.isSelected ? 'solid 2px #FF8900' : 'solid 2px transparent'};
  border-left: none;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: white;
  padding-left: 20px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 8px;
  position: fixed;
  left: 0px;
  top: 150px;
  align-items: center;
  justify-content: center;
  outline: none;
  z-index: 99;
  &:active,
  &:focus {
    outline: 3px solid #ff7121;
  }
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      display: none;
    }
  }
`;

export const RecentPopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
  width: calc(100vw - 120px);
  display: flex;
  flex-direction: column;
  pointer-events: none;
  .recent-opponents-title {
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  p {
  }
  ${StyledCard} {
    pointer-events: auto;
    img {
      width: 48px;
      height: 48px;
    }
    p {
      font-size: 1rem;
      width: auto;
      margin: 0;
    }
  }
  button {
    margin: auto;
    margin-top: 100px;
    pointer-events: auto;
  }
  /* @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  } */
`;
