import React from 'react';
import { isNil } from 'ramda';
import { NewChallengeCardStyles } from 'constants/multiplayer';
import AvatarPlaceholder from 'assets/img/avatar.png';
import {
  StyledCard,
  StyledTitle,
  RoundedTriangle,
  IconContainer,
  ContentContainer,
  StyledTitleContainer,
} from './ChallengeCardv2.styles';

const ChallengeCardv2 = ({
  id,
  title,
  avatarImg,
  iconImg,
  styles = NewChallengeCardStyles.math,
  selectedOpponentTypeId,
  onClickHandler,
  children,
  subject,
}) => {
  const isSelected = selectedOpponentTypeId === id;
  const isClickable = !isNil(onClickHandler);
  return (
    <StyledCard
      isClickable={isClickable}
      isSelected={isSelected}
      onClick={() => {
        if (isClickable) onClickHandler();
      }}
      selectedOutlineStyle={styles.selectedOutlineStyle}
      className={subject}
    >
      <StyledTitleContainer>
        <StyledTitle
          bgColour={styles.headerBgColour[id]}
          textShadow={styles.headerTitleTextShadow[id]}
        >
          <span className="card--title">{title}</span>
          <RoundedTriangle fillColour={styles.headerBgColour[id]}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.347"
              height="11.777"
              viewBox="0 0 19.347 11.777"
            >
              <path
                id="Polygon_13"
                data-name="Polygon 13"
                d="M6.583,3.763a4,4,0,0,1,6.182,0l1.212,1.475a4,4,0,0,1-3.091,6.539H8.462A4,4,0,0,1,5.371,5.238Z"
                transform="translate(19.347 11.777) rotate(180)"
              />
            </svg>
          </RoundedTriangle>
        </StyledTitle>
        <IconContainer bgColour={styles.iconThumbnailBgColour[id]}>
          {avatarImg === null ? (
            <img src={iconImg} alt="" />
          ) : (
            <img
              src={avatarImg}
              alt=""
              className="avatar"
              onError={(e) => {
                e.target.src = AvatarPlaceholder;
              }}
            />
          )}
        </IconContainer>
      </StyledTitleContainer>
      <ContentContainer>{children}</ContentContainer>
    </StyledCard>
  );
};

export default ChallengeCardv2;
