const checkProductSubscription = (product, feature) => {
  const lowercaseFeatureName = feature.toLowerCase().trim();
  const currentFeatures =
    product?.nonExpiredSubscriptionProducts[0]?.features || [];
  return currentFeatures.find(
    (item) => item.feature.toLowerCase().trim() === lowercaseFeatureName
  );
};

export default checkProductSubscription;
