import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveWorkingsLocally } from 'store/assignment/assignmentSlice';
import { saveEventWorkingsLocally } from 'store/event/eventSlice';
import { saveSelfPracticeWorkingsLocally } from 'store/mission/missionSlice';
import { savePeerChallengeWorkingsLocally } from 'store/multiplayer/multiplayerSlice';
import { saveDailyChallengeWorkingsLocally } from 'store/dailyChallenge/dailyChallengeSlice';
import { saveKooQuizWorkingsLocally } from 'store/kooQuiz/kooQuizSlice';
import { saveKooClassWorkingsLocally } from 'store/kooClass/kooClassSlice';
import { isNil } from 'ramda';
import parseWorkingsBarModel from 'helpers/parseWorkingsBarModel';
import { Container } from './AddWorkingTool.styles';

const AddWorking = ({ feature }) => {
  let activeQn;
  let localSavWorkings;
  let allSubmissions;
  let saveWorkingsHandler;
  const { workings, submissions, activeQuestion } = useSelector(
    (state) => state.assignment
  );
  const {
    eventSubmissions,
    eventLocalSavedWorkings,
    activeQuestionEvent,
  } = useSelector((state) => state.event);
  const { selfPracticeLocalSavedWorkings, selfPracticeActiveQn } = useSelector(
    (state) => state.mission
  );
  const {
    peerChallengeActiveQn,
    peerChallengeLocalSavedWorkings,
  } = useSelector((state) => state.multiplayer);
  const {
    activeQsDailyChallenge,
    dailyChallengeSubmissions,
    dailyChallengeLocalSavedWorkings,
  } = useSelector((state) => state.dailyChallenge);
  const {
    kooQuizActiveQuestion,
    kooQuizSubmissions,
    kooQuizSavedWorkings,
  } = useSelector((state) => state.kooQuiz);
  const {
    kooClassActiveQuestion,
    kooClassSubmissions,
    kooClassWorkings,
  } = useSelector((state) => state.kooClass);

  switch (feature) {
    case 'assignment':
      activeQn = activeQuestion;
      localSavWorkings = workings;
      allSubmissions = submissions;
      saveWorkingsHandler = (params) => {
        dispatch(saveWorkingsLocally(params));
      };
      break;
    case 'sunday-mini-challenge':
      activeQn = activeQuestionEvent;
      localSavWorkings = eventLocalSavedWorkings;
      allSubmissions = eventSubmissions;
      saveWorkingsHandler = (params) => {
        dispatch(saveEventWorkingsLocally(params));
      };
      break;
    case 'mission':
      activeQn = selfPracticeActiveQn;
      localSavWorkings = selfPracticeLocalSavedWorkings;
      allSubmissions = [null];
      saveWorkingsHandler = (params) => {
        dispatch(saveSelfPracticeWorkingsLocally(params));
      };
      break;
    case 'peer-challenge':
      activeQn = peerChallengeActiveQn;
      localSavWorkings = peerChallengeLocalSavedWorkings;
      allSubmissions = [];
      saveWorkingsHandler = (params) => {
        dispatch(savePeerChallengeWorkingsLocally(params));
      };
      break;
    case 'daily-challenge':
      activeQn = activeQsDailyChallenge;
      localSavWorkings = dailyChallengeLocalSavedWorkings;
      allSubmissions = dailyChallengeSubmissions;
      saveWorkingsHandler = (params) => {
        dispatch(saveDailyChallengeWorkingsLocally(params));
      };
      break;
    case 'kooQuiz':
      activeQn = kooQuizActiveQuestion;
      localSavWorkings = kooQuizSavedWorkings;
      allSubmissions = kooQuizSubmissions;
      saveWorkingsHandler = (params) => {
        dispatch(saveKooQuizWorkingsLocally(params));
      };
      break;
    case 'kooClass':
      activeQn = kooClassActiveQuestion;
      localSavWorkings = kooClassWorkings;
      allSubmissions = kooClassSubmissions;
      saveWorkingsHandler = (params) => {
        dispatch(saveKooClassWorkingsLocally(params));
      };
      break;
    default:
      break;
  }

  const currentWorkings = isNil(localSavWorkings[activeQn - 1])
    ? isNil(allSubmissions[activeQn - 1]?.SubmissionModel)
      ? null
      : parseWorkingsBarModel(
          allSubmissions[activeQn - 1].SubmissionModel.Solution
        ).workings
    : localSavWorkings[activeQn - 1];

  const currentWorkingsKooClass = isNil(localSavWorkings[activeQn - 1])
    ? isNil(allSubmissions[activeQn - 1]?.SubmissionModel)
      ? null
      : parseWorkingsBarModel(
          allSubmissions[activeQn - 1].SubmissionModel.solution
        ).workings
    : localSavWorkings[activeQn - 1];

  const dispatch = useDispatch();
  const mathSymbol = [
    '+',
    '-',
    '\xD7',
    '\xF7',
    '=',
    '\x3E',
    '\x3C',
    '\xB2',
    '\xB3',
    '\u2220',
    '\xB0',
    '\xA2',
    '\u2206',
  ];

  function setCaret(el, start, end) {
    if (el.setSelectionRange) {
      el.focus();
      el.setSelectionRange(start, end);
    } else if (el.createTextRange) {
      const range = el.createTextRange();
      range.collapse(true);
      range.moveEnd('character', start);
      range.moveStart('character', end);
      range.select();
    }
  }

  function addSymbol(myField, myValue) {
    if (document.selection) {
      myField.focus();
      const sel = document.selection.createRange();
      sel.text = myValue;
    } else if (myField.selectionStart || myField.selectionStart === '0') {
      const startPos = myField.selectionStart;
      const endPos = myField.selectionEnd;
      const field = document.getElementById('text-here');

      myField.value =
        myField.value.slice(0, startPos) +
        myValue +
        myField.value.slice(endPos, myField.value.length);
      myField.selectionStart = startPos + myValue.length;
      myField.selectionEnd = startPos + myValue.length;
      setCaret(field, startPos + 1, startPos + 1);
    } else {
      myField.value += myValue;
    }
    return myField.value;
  }

  const onClickHandler = (item) => {
    const field = document.getElementById('text-here');
    const newText = addSymbol(field, item);
    saveWorkingsHandler({
      index: activeQn - 1,
      workings: newText,
    });
  };
  const handleChange = (e) => {
    e.preventDefault();
    saveWorkingsHandler({
      index: activeQn - 1,
      workings: e.target.value,
    });
  };
  return (
    <Container>
      <div className="math-symbols">
        {mathSymbol.map((item, index) => (
          <div
            key={index}
            className="symbol"
            onClick={(e) => {
              e.preventDefault();
              onClickHandler(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="text">
        <textarea
          id="text-here"
          key={activeQn}
          name="textarea"
          defaultValue={
            feature === 'kooClass' ? currentWorkingsKooClass : currentWorkings
          }
          onChange={handleChange}
          autoComplete="off"
        />
      </div>
    </Container>
  );
};

export default AddWorking;
