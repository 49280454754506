export const LABELS = [
  {
    name: 'Name',
    translateKey: 'name',
    flex: 2,
  },
  {
    name: 'School',
    translateKey: 'school',
    flex: 2,
  },
  {
    name: 'Super Point',
    translateKey: 'superPoint',
    flex: 1,
  },
  {
    name: 'Achievement Time',
    translateKey: 'achivementTime',
    flex: 1,
  },
];
