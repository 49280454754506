import React from 'react';
import FilledStar from 'assets/img/mission/filled-star.png';
import HalfFilledStar from 'assets/img/mission/half-filled-star.png';
import UnfilledStar from 'assets/img/mission/incomplete-star.png';
import { StarsContainer } from './HighscoreStars.styles';

const HighscoreStarsMapping = (highscore) => {
  switch (highscore) {
    case 'A':
      return Array(3).fill(FilledStar);
    case 'B':
      return [FilledStar, FilledStar, UnfilledStar];
    case 'C':
      return [FilledStar, UnfilledStar, UnfilledStar];
    case 'D':
      return [HalfFilledStar, UnfilledStar, UnfilledStar];
    case 'Incomplete':
      return Array(3).fill(UnfilledStar);
    default:
      return Array(3).fill(UnfilledStar);
  }
};

const HighscoreStars = ({ highscore }) => {
  const stars = HighscoreStarsMapping(highscore);
  return (
    <StarsContainer>
      {stars.map((star, index) => (
        <img key={index} src={star} alt="" />
      ))}
    </StarsContainer>
  );
};

export default HighscoreStars;
