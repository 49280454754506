import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import xmlParser from 'helpers/xmlParser';
import { isNil, isEmpty } from 'ramda';
import convertTagsToHtml from 'helpers/convertTagsToHtml';
import HTMLReactParser from 'html-react-parser';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import QuestionFeedbackLink from 'components/Shared/QuestionFeedbackLink';
import Voiceover from 'components/Shared/Voiceover';
import {
  saveAnswerLocally,
  checkAnswer,
  saveAnswer,
  clearSavedAnswer,
} from 'store/assignment/assignmentSlice';
import {
  saveKooQuizAnswerLocally,
  kooQuizCheckAnswer,
  saveKooQuizAnswer,
  clearKooQuizSavedAnswer,
} from 'store/kooQuiz/kooQuizSlice';
import {
  saveKooClassAnswerLocally,
  kooClassCheckAnswer,
  saveKooClassAnswer,
  clearKooClassSavedAnswer,
} from 'store/kooClass/kooClassSlice';
import {
  saveEventAnsLocally,
  saveEventAns,
  clearEventSavedAnswer,
} from 'store/event/eventSlice';
import { saveSelfPracticeAnsLocally } from 'store/mission/missionSlice';
import { savePeerChallengeAnsLocally } from 'store/multiplayer/multiplayerSlice';
import { saveDailyChallengeAnsLocally } from 'store/dailyChallenge/dailyChallengeSlice';
import { clearVoiceoverPlaying } from 'store/voiceover/voiceoverSlice';
import { SubmissionResultTypes } from 'constants/index';
import { answerStatus } from 'constants/kooClass';
import {
  createMCQAnswerKeyValuePairs,
  createAnswerKeyValuePairsByQuestionType,
} from 'helpers/createAnswerKeyValuePairs';
import CompareTwoAnswersByLanguage from 'helpers/compareAnswers';
import checkIfAllLongAnswersMarkedRight, {
  countNoOfLongAnswersNotMarkedRight,
} from 'helpers/checkIfAllLongAnswersMarkedRight';
import { getWorkings } from 'pages/Assignment/AssignmentView';
import parseWorkingsBarModel from 'helpers/parseWorkingsBarModel';
import ImageLoader from 'components/Question/ImageLoader';
import displaySubmission from 'helpers/Question/displaySubmission';
import VideoIcon from 'assets/img/icon-play-video.svg';
import useIsMobile from 'hooks/responsive/useIsMobile';
import {
  Container,
  CheckAnswerButton,
  IncorrectAnswerMessage,
  Workings,
  Solution,
  VideoTutorial,
} from './index.styles';
import MCQOptions from './MCQOptions';
import LongAnsTextarea from './LongAnsTextarea';
import FillInTheBlanksInput from './FillInTheBlanksInput';
import QuestionAnswer from './Answer';
import QuestionFallback from './QuestionFallback';

// HELPERS
// to check correct/wrong answer for all other features
const toShowCorrect = (currentSubmission, currentQnAnswer, key) => {
  const subName = 'SubmissionKeyValuePairs';
  const subResName = 'SubmissionResult';
  const valName = 'Value';
  const keyName = 'Key';
  if (SubmissionResultTypes[currentSubmission[subResName]] === 'correct') {
    return 'correct-input';
  }
  if (SubmissionResultTypes[currentSubmission[subResName]] === 'wrong') {
    if (currentSubmission[subName].length === 0) return 'wrong-input';
    const qnAnswerIndex = currentQnAnswer.findIndex(
      (answer) => answer[keyName] === key
    );
    const index = currentSubmission[subName].findIndex(
      (answer) => answer[keyName] === key
    );
    let isCorrect;
    if (index !== -1) {
      isCorrect = CompareTwoAnswersByLanguage(
        currentSubmission[subName][index][valName],
        currentQnAnswer[qnAnswerIndex][valName],
        'english'
      );
    } else {
      isCorrect = false;
    }

    return isCorrect ? 'correct-input' : 'wrong-input';
  }
  return '';
};

// to check correct/wrong answer for Kooclass
const toShowCorrectKooClass = (currentSubmission, key) => {
  const subName = 'submissionKeyValuePairs';
  const subResName = 'submissionResult';
  if (SubmissionResultTypes[currentSubmission[subResName]] === 'correct') {
    return 'correct-input';
  }
  if (SubmissionResultTypes[currentSubmission[subResName]] === 'wrong') {
    if (currentSubmission[subName].length <= 1) return 'wrong-input';
    const val = currentSubmission[subName].find((answer) => {
      return answer.key === key;
    });
    if (isNil(val)) return 'wrong-input';
    if (answerStatus[val.result] === 'Wrong') {
      return 'wrong-input';
    }
    if (answerStatus[val.result] === 'Correct') {
      return 'correct-input';
    }
    return '';
  }
  return '';
};

// reference object to prevent rerendering
const DEFAULT_ERRORS = {};

const Question = ({
  page,
  xml,
  showSubmissions,
  showCheckAnswer,
  showSolution,
  qnID,
  submissionType,
  questionType,
  submissionModel,
  qnIndex,
  assignmentType,
  showWorking,
  solutionString,
  PCActiveQn,
  correctAnswer,
  setShowVideoModal,
  videoUrl,
  skillID,
  showFeedbackButton = false,
  errors = DEFAULT_ERRORS,
}) => {
  const { t } = useTranslation([
    'assignmentSolution',
    'assignmentQnView',
    'dailyChallengeQnView',
  ]);
  const dispatch = useDispatch();
  const { activityType } = useParams();
  const store = useStore();
  const isMobile = useIsMobile();

  // REDUX STATES
  const { userID } = useSelector((state) => state.login);
  const {
    activeQuestion,
    localSavedAnswers,
    submissions,
    assignmentSubmissionID,
    questions,
    questionAnswers,
    isChecking,
    workings,
    isSaving,
  } = useSelector((state) => state.assignment);
  const { data: barModelData } = useSelector((state) => state.barModel);
  const {
    activeQuestionEvent,
    eventLocalSavedAns,
    eventLocalSavedWorkings,
    eventSubmissions,
    eventQuestions,
    eventQsAnswers,
    isEventSaving,
  } = useSelector((state) => state.event);
  const {
    selfPracticeLocalSavedAns,
    questions: selfPracticeQuestions,
    missionQuestionAnswers,
    submitAns,
  } = useSelector((state) => state.mission);
  const {
    peerChallengeLocalSavedAns,
    multiplayerQnAnswers,
    peerChallengeQns,
    // peerChallengeSolution,
  } = useSelector((state) => state.multiplayer);
  const {
    activeQsDailyChallenge,
    dailyChallengeQuestion,
    dailyChallengeLocalSavedAns,
    dailyChallengeQuestionAnswer,
    dailyChallengeSubmissions,
  } = useSelector((state) => state.dailyChallenge);
  const {
    isKooQuizChecking,
    kooQuizActiveQuestion,
    kooQuizQuestions,
    kooQuizQuestionAnswers,
    kooQuizSubmissions,
    kooQuizLocalSavedAnswers,
    kooQuizSavedWorkings,
    kooQuizSubmissionID,
    isKooQuizSaving,
  } = useSelector((state) => state.kooQuiz);
  const {
    isKooClassChecking,
    kooClassActiveQuestion,
    kooClassQuestions,
    kooClassQuestionAnswers,
    kooClassSubmissions,
    kooClassLocalSavedAnswers,
    kooClassWorkings,
    kooClassSubmissionID,
    isKooClassSaving,
  } = useSelector((state) => state.kooClass);
  let activeQn;
  let localSavAns;
  let localSavWorkings;
  let allSubmission;
  let allQs;
  let allQsAns = null;
  let isCheckingAnswer = [];
  let submissionId;
  let saving;
  if (assignmentType === 'sundayMC') {
    activeQn = activeQuestionEvent;
    localSavAns = eventLocalSavedAns;
    localSavWorkings = eventLocalSavedWorkings;
    allSubmission = eventSubmissions;
    allQs = eventQuestions;
    allQsAns = eventQsAnswers;
    submissionId = assignmentSubmissionID;
    saving = isEventSaving;
  } else if (assignmentType === 'self-practice') {
    activeQn = 1;
    localSavAns = selfPracticeLocalSavedAns;
    allQsAns = missionQuestionAnswers;
    submissionId = assignmentSubmissionID;
  } else if (assignmentType === 'peer-challenge') {
    activeQn = PCActiveQn;
    localSavAns = peerChallengeLocalSavedAns;
    allQs = peerChallengeQns;
    allQsAns = multiplayerQnAnswers;
    submissionId = assignmentSubmissionID;
  } else if (assignmentType === 'daily-challenge') {
    activeQn = activeQsDailyChallenge;
    localSavAns = dailyChallengeLocalSavedAns;
    allSubmission = dailyChallengeSubmissions;
    allQs = dailyChallengeQuestion;
    allQsAns = dailyChallengeQuestionAnswer;
    submissionId = assignmentSubmissionID;
  } else if (
    assignmentType === 'KooQuizMaths' ||
    assignmentType === 'KooQuizSci'
  ) {
    activeQn = kooQuizActiveQuestion;
    localSavAns = kooQuizLocalSavedAnswers;
    localSavWorkings = kooQuizSavedWorkings;
    allSubmission = kooQuizSubmissions;
    allQs = kooQuizQuestions;
    allQsAns = kooQuizQuestionAnswers;
    isCheckingAnswer = isKooQuizChecking;
    submissionId = kooQuizSubmissionID;
    saving = isKooQuizSaving;
  } else if (assignmentType === 'KooClass') {
    activeQn = kooClassActiveQuestion;
    localSavAns = kooClassLocalSavedAnswers;
    localSavWorkings = kooClassWorkings;
    allSubmission = kooClassSubmissions;
    allQs = kooClassQuestions;
    allQsAns = kooClassQuestionAnswers;
    isCheckingAnswer = isKooClassChecking;
    submissionId = kooClassSubmissionID;
    saving = isKooClassSaving;
  } else {
    activeQn = activeQuestion;
    localSavAns = localSavedAnswers;
    localSavWorkings = workings;
    allSubmission = submissions;
    allQs = questions;
    allQsAns = questionAnswers;
    isCheckingAnswer = isChecking;
    submissionId = assignmentSubmissionID;
    saving = isSaving;
  }
  const currentSubmission =
    page === 'solution' || page === 'peer-challenge'
      ? submissionModel
      : page === 'self-practice'
      ? submitAns.QuestionSubmissionModel
      : allSubmission[activeQn - 1].SubmissionModel;

  const currentQuestion =
    assignmentType === 'self-practice'
      ? selfPracticeQuestions
      : assignmentType === 'peer-challenge'
      ? peerChallengeQns
      : allQs[activeQn - 1];

  let subName = 'SubmissionKeyValuePairs';
  let subResName = 'SubmissionResult';
  let valName = 'Value';
  if (assignmentType === 'KooClass') {
    subName = 'submissionKeyValuePairs';
    subResName = 'submissionResult';
    valName = 'value';
  }

  // Check Answer Handler only for Assignments, KooQuiz, KooClass
  const checkAnswerHandler = (
    data,
    questionType,
    currentLocalSavedAnswer,
    lastKeyToMark,
    workings,
    barModel,
    type
  ) => {
    let keysToMarkRight;
    let answerkeyValuePairs;
    // Long Answer
    if (questionType === 'longAnswer') {
      const localAnswers =
        currentLocalSavedAnswer !== null ? currentLocalSavedAnswer : null;
      const currentSubmissionAnswer =
        currentSubmission !== null
          ? currentSubmission.SubmissionKeyValuePairs
          : null;
      // Construct keys of textareas to mark as right
      keysToMarkRight =
        currentSubmission !== null
          ? currentSubmission.SubmissionKeyValuePairs.filter(
              (keyValuePair) => keyValuePair.ismarkedright === true
            ).map((keyValuePair) => keyValuePair.Key)
          : [];
      if (lastKeyToMark !== null) {
        keysToMarkRight.push(lastKeyToMark.split('-')[1]);
      }
      if (localAnswers !== null) {
        answerkeyValuePairs = createAnswerKeyValuePairsByQuestionType(
          questionType,
          {
            studentAnswers: localAnswers,
            questionAnswers: allQsAns[activeQn - 1],
            userID,
            qnID,
            keysToMarkRight,
          }
        );
      } else if (currentSubmissionAnswer !== null) {
        answerkeyValuePairs = currentSubmissionAnswer.map((answerKey) => {
          const toMarkKeyAsRight = !isNil(keysToMarkRight)
            ? keysToMarkRight.find((key) => key === answerKey.Key)
            : null;
          return {
            ...answerKey,
            ismarkedright: !isNil(toMarkKeyAsRight),
          };
        });
      } else {
        answerkeyValuePairs = [];
      }
    } // MCQ
    else if (questionType === 'mcq') {
      // Construct answerKeyValuePairs for mcq
      const blankMcqAnswerKeyPairs = [
        {
          key: '-1',
          userid: userID,
        },
      ];
      answerkeyValuePairs =
        currentLocalSavedAnswer !== null
          ? createMCQAnswerKeyValuePairs(currentLocalSavedAnswer, userID)
          : currentSubmission !== null
          ? currentSubmission[subName] ?? blankMcqAnswerKeyPairs
          : blankMcqAnswerKeyPairs;
    } // Fill in the blanks
    else {
      answerkeyValuePairs = createAnswerKeyValuePairsByQuestionType(
        questionType,
        {
          studentAnswers: data,
          questionAnswers: allQsAns[activeQn - 1],
          userID,
          qnID,
          keysToMarkRight,
        }
      );
    }
    const now = Date.now();
    const rawBody = {
      userID,
      questionID: qnID,
      questionType:
        assignmentType === 'KooClass'
          ? currentQuestion.questionType
          : currentQuestion.QuestionType,
      versionId:
        assignmentType === 'KooClass'
          ? currentQuestion.version
          : currentQuestion.Version,
      answerkeyValuePairs,
      isFinalSubmit: false,
    };
    if (assignmentType === 'KooClass') {
      rawBody.activityType = Number(activityType);
      if (currentSubmission && currentSubmission.submissionID !== undefined) {
        rawBody.id = currentSubmission.submissionID;
      }
    }
    if (assignmentType !== 'KooClass') {
      rawBody.type = submissionType;
      rawBody.homeworkSubmissionID = submissionId;
      rawBody.duration = now - store.getState().timer.lastSavedTime;
    }
    // Save workings / bar model data
    if (!isNil(workings) || !isNil(barModel)) {
      // const solutionArray = [workings, null];
      const solutionObject = {
        workings: isNil(workings) ? null : workings,
        barModel: isNil(barModel) ? null : barModel,
      };
      // Stringify solution object as API only accepts value type string for solution
      rawBody.solution = JSON.stringify(solutionObject);
    }
    if (type === 'KooQuizMaths' || type === 'KooQuizSci') {
      dispatch(kooQuizCheckAnswer({ rawBody, activeQuestion: activeQn }));
    } else if (type === 'KooClass') {
      dispatch(
        kooClassCheckAnswer({
          rawBody,
          activeQuestion: activeQn,
          submissionId,
        })
      );
    } else {
      dispatch(checkAnswer({ rawBody, activeQuestion: activeQn }));
    }
  };
  // Mark It Right Handler always calls Save answer API since Check Answer is only called
  // after all long answers have been marked as right
  const markAnswerRightHandler = (
    currentLocalSavedAnswer,
    selectedTextareaKey,
    workings,
    barModel
  ) => {
    // Construct student answers from localSavAns/allSubmission
    const currentQnID = currentSubmission?.Id;
    const answersObject = {};
    if (currentSubmission !== null && currentSubmission[subName].length > 0) {
      currentSubmission[subName].forEach((keyValuePair) => {
        if (keyValuePair.AnswerType === 6) {
          answersObject[`freetext-${keyValuePair.Key}`] = keyValuePair.Value;
        } else {
          answersObject[`${currentQnID}-${keyValuePair.Key}`] =
            keyValuePair.Value;
        }
      });
    } else {
      allQsAns[activeQn - 1].forEach((keyValuePair) => {
        if (keyValuePair.Type === 'FreeText') {
          answersObject[`freetext-${keyValuePair.Key}`] = '';
        } else {
          answersObject[`${currentQnID}-${keyValuePair.Key}`] = '';
        }
      });
    }
    const studentAnswers =
      currentLocalSavedAnswer !== null
        ? currentLocalSavedAnswer
        : currentSubmission !== null
        ? answersObject
        : null;
    // Construct keys of textareas to mark as right
    const keysToMarkRight =
      currentSubmission !== null
        ? currentSubmission.SubmissionKeyValuePairs.filter(
            (keyValuePair) => keyValuePair.ismarkedright === true
          ).map((keyValuePair) => keyValuePair.Key)
        : [];
    keysToMarkRight.push(selectedTextareaKey.split('-')[1]);
    const now = Date.now();
    const rawBody = {
      homeworkSubmissionID: submissionId,
      duration: now - store.getState().timer.lastSavedTime,
      userID,
      questionID: qnID,
      type: submissionType,
      questionType: currentQuestion.QuestionType,
      versionId: currentQuestion.Version,
      answerkeyValuePairs: createAnswerKeyValuePairsByQuestionType(
        'longAnswer',
        {
          studentAnswers,
          questionAnswers: allQsAns[activeQn - 1],
          userID,
          qnID,
          keysToMarkRight,
        }
      ),
      isFinalSubmit: false,
    };
    // Save workings / bar model data
    if (!isNil(workings) || !isNil(barModel)) {
      // const solutionArray = [workings, null];
      const solutionObject = {
        workings: isNil(workings) ? null : workings,
        barModel: isNil(barModel) ? null : barModel,
      };
      // Stringify solution object as API only accepts value type string for solution
      rawBody.solution = JSON.stringify(solutionObject);
    }
    let saveAns = null;
    if (assignmentType === 'sundayMC') {
      saveAns = saveEventAns;
    } else if (
      assignmentType === 'KooQuizMaths' ||
      assignmentType === 'KooQuizSci'
    ) {
      saveAns = saveKooQuizAnswer;
    } else if (assignmentType === 'KooClass') {
      saveAns = saveKooClassAnswer;
    } else {
      saveAns = saveAnswer;
    }

    dispatch(
      saveAns({
        rawBody,
        activeQn,
      })
    );
  };
  // React Hook Form
  const { register, handleSubmit, getValues } = useForm();
  const [selectedTextareaKey, setSelectedTextareaKey] = useState(null);
  // Form submit Handler
  const onSubmit = (data) => {
    if (showCheckAnswer !== true) {
      return;
    }
    // Add check answer logic for Long Ans Qns
    if (questionType === 'longAnswer') {
      const numOfLongAnswersNotMarkedRight =
        currentSubmission !== null
          ? currentSubmission.SubmissionKeyValuePairs.length > 0
            ? countNoOfLongAnswersNotMarkedRight(
                currentSubmission.SubmissionKeyValuePairs
              )
            : allQsAns[activeQn - 1].length
          : null;
      // Check if all text area have been marked right before calling check answer
      if (currentSubmission === null || numOfLongAnswersNotMarkedRight > 1) {
        markAnswerRightHandler(
          localSavAns[activeQn - 1],
          selectedTextareaKey,
          getWorkings(
            allSubmission[activeQn - 1],
            localSavWorkings[activeQn - 1]
          ),
          barModelData.objects.length > 0 ? barModelData : null
        );
      } else {
        const lastKeyToMark =
          numOfLongAnswersNotMarkedRight === 1 ? selectedTextareaKey : null;
        // Check answer for long answer qn
        checkAnswerHandler(
          data,
          questionType,
          localSavAns[activeQn - 1],
          lastKeyToMark,
          getWorkings(
            allSubmission[activeQn - 1],
            localSavWorkings[activeQn - 1]
          ),
          barModelData.objects.length > 0 ? barModelData : null,
          assignmentType
        );
      }
    } else {
      checkAnswerHandler(
        data,
        questionType,
        null,
        null,
        getWorkings(
          allSubmission[activeQn - 1],
          localSavWorkings[activeQn - 1],
          assignmentType
        ),
        barModelData.objects.length > 0 ? barModelData : null,
        assignmentType
      );
    }
  };

  // may be one day will need to use this again, just replace the xml to filteredXml
  // const filteredXml = !isNil(xml) ? xml.replace(/\\/g, '') : null;
  // Component States
  const [isAnswerInputDisabled, setIsAnswerInputDisabled] = useState(false);
  const [revealWorkings, setRevealWorkings] = useState(false);
  const [revealSolution, setRevealSolution] = useState(false);
  const [renderError, setRenderError] = useState(false);
  const [questionContent, setQuestionContent] = useState(null);
  // Long Answer <textarea>
  const [showLongAnswer, setShowLongAnswer] = useState(false);
  // For rendering mcqOptions
  const [mcqOptions, setMcqOptions] = useState(null);
  // For rendering qn solution
  const [questionSolution, setQuestionSolution] = useState(null);

  // EVENT HANDLERS
  const inputOnChange = useCallback(() => {
    let saveAnsLocal = null;
    let clearSavedAns = null;
    if (assignmentType === 'sundayMC') {
      saveAnsLocal = saveEventAnsLocally;
      clearSavedAns = clearEventSavedAnswer;
    } else if (assignmentType === 'self-practice') {
      saveAnsLocal = saveSelfPracticeAnsLocally;
    } else if (assignmentType === 'peer-challenge') {
      saveAnsLocal = savePeerChallengeAnsLocally;
    } else if (assignmentType === 'daily-challenge') {
      saveAnsLocal = saveDailyChallengeAnsLocally;
    } else if (
      assignmentType === 'KooQuizMaths' ||
      assignmentType === 'KooQuizSci'
    ) {
      saveAnsLocal = saveKooQuizAnswerLocally;
      clearSavedAns = clearKooQuizSavedAnswer;
    } else if (assignmentType === 'KooClass') {
      saveAnsLocal = saveKooClassAnswerLocally;
      clearSavedAns = clearKooClassSavedAnswer;
    } else {
      saveAnsLocal = saveAnswerLocally;
      clearSavedAns = clearSavedAnswer;
    }
    if (clearSavedAns !== null) dispatch(clearSavedAns(activeQn - 1));
    dispatch(
      saveAnsLocal({
        index: activeQn - 1,
        answers: getValues(),
      })
    );
  }, [activeQn, assignmentType, dispatch, getValues]);

  // condition to display hw tuutorial video
  const videoTutorialHw =
    assignmentType === 'homework' &&
    page !== 'solution' &&
    currentSubmission &&
    SubmissionResultTypes[currentSubmission[subResName]] === 'wrong' &&
    isAnswerInputDisabled &&
    !isNil(videoUrl) &&
    !isEmpty(videoUrl);
  const handlePlayVideo = () => {
    setShowVideoModal(true);
    dispatch(clearVoiceoverPlaying());
  };
  // USEEFFECTS
  useEffect(() => {
    // Set show long answer state to false when current qn number changes
    if (isNil(currentSubmission)) {
      setShowLongAnswer(false);
    } else if (
      !isNil(currentSubmission) &&
      (isNil(currentSubmission[subName]) ||
        currentSubmission[subName].length <= 0 ||
        isNil(
          currentSubmission[subName].find(
            (keyValuePair) =>
              keyValuePair[valName]?.trim().length > 0 ||
              keyValuePair.ismarkedright === true
          )
        ))
    ) {
      setShowLongAnswer(false);
    }
  }, [activeQn, currentSubmission, subName, valName]);
  useEffect(() => {
    // console.log('currentSubmission', currentSubmission);
    if (
      !isNil(currentSubmission) &&
      (SubmissionResultTypes[currentSubmission[subResName]] === 'wrong' ||
        SubmissionResultTypes[currentSubmission[subResName]] === 'correct')
    ) {
      // console.log('Disable the Inputs');
      if (correctAnswer === 'unknown') {
        setIsAnswerInputDisabled(false);
      } else {
        setIsAnswerInputDisabled(true);
      }
    } else {
      // console.log('Enable the Inputs');
      setIsAnswerInputDisabled(false);
    }
  }, [correctAnswer, currentSubmission, subResName]);

  useEffect(() => {
    if (!isNil(xml)) {
      const parsed = xmlParser(xml);
      // Check if there are student allSubmission
      const hasSubmissions =
        assignmentType === 'sundayMC'
          ? isNil(currentSubmission)
            ? false
            : currentSubmission.SubmissionKeyValuePairs.length > 0
          : parsed.studentSubmissions.length > 0;
      // Set Question Content
      let count = 0;
      try {
        const questionContent = HTMLReactParser(
          convertTagsToHtml(parsed.questionContent),
          {
            replace: (domNode) => {
              // Parse <img> elements
              if (domNode.name === 'img') {
                return (
                  <ImageLoader
                    imgSrc={domNode.attribs.src}
                    id={domNode.attribs.uniqueid}
                  />
                );
              }

              // Parse <input> html elements
              if (domNode.name === 'input') {
                count += 1;
                const type = Object.hasOwnProperty.call(domNode.attribs, 'type')
                  ? domNode.attribs.type
                  : 'text';
                const key = Object.hasOwnProperty.call(domNode.attribs, 'key')
                  ? `${isNil(correctAnswer) ? '' : correctAnswer}${qnID}-${
                      domNode.attribs.key
                    }`
                  : `${
                      isNil(correctAnswer) ? '' : correctAnswer
                    }${qnID}-key${count}`;
                const name = Object.hasOwnProperty.call(domNode.attribs, 'key')
                  ? `${qnID}-${domNode.attribs.key}`
                  : `${qnID}-key${count}`;
                if (showSubmissions && hasSubmissions) {
                  const answerClassName = () => {
                    if (isAnswerInputDisabled && currentSubmission !== null) {
                      if (
                        page === 'solution' &&
                        assignmentType === 'KooClass'
                      ) {
                        return toShowCorrectKooClass(
                          currentSubmission,
                          `key${count}`
                        );
                      }
                      if (page === 'solution') {
                        return toShowCorrect(
                          currentSubmission,
                          allQsAns[qnIndex],
                          `key${count}`
                        );
                      }
                      if (page === 'self-practice') {
                        return toShowCorrect(
                          currentSubmission,
                          missionQuestionAnswers,
                          `key${count}`
                        );
                      }
                      if (page === 'peer-challenge') {
                        return toShowCorrect(
                          currentSubmission,
                          multiplayerQnAnswers[qnIndex],
                          `key${count}`
                        );
                      }
                      if (page === 'KooClass') {
                        return toShowCorrectKooClass(
                          currentSubmission,
                          `key${count}`
                        );
                      }
                      return toShowCorrect(
                        currentSubmission,
                        allQsAns[activeQn - 1],
                        `key${count}`
                      );
                    }
                    return null;
                  };

                  return (
                    <FillInTheBlanksInput
                      type={type}
                      key={key}
                      name={name}
                      defaultValue={displaySubmission(
                        assignmentType === 'sundayMC'
                          ? currentSubmission.SubmissionKeyValuePairs
                          : parsed.studentSubmissions[0].answerkeys,
                        Object.hasOwnProperty.call(domNode.attribs, 'key')
                          ? domNode.attribs.key
                          : `key${count}`
                      )}
                      register={register}
                      answerClassName={answerClassName()}
                      onChange={inputOnChange}
                      disabled={
                        isAnswerInputDisabled ||
                        isChecking[activeQn - 1] === true ||
                        page === 'solution'
                      }
                      errors={errors}
                    />
                  );
                }
                const defaultValue = () => {
                  if (!isNil(localSavAns[activeQn - 1])) {
                    return localSavAns[activeQn - 1][`${qnID}-key${count}`];
                  }
                  if (Object.hasOwnProperty.call(domNode.attribs, 'value')) {
                    return domNode.attribs.value;
                  }
                  return '';
                };
                return (
                  <FillInTheBlanksInput
                    type={type}
                    key={key}
                    name={name}
                    defaultValue={defaultValue()}
                    register={register}
                    onChange={inputOnChange}
                    disabled={isChecking[activeQn - 1] === true}
                    hasAutoFocus={
                      count === 1 && assignmentType === 'self-practice'
                    }
                    errors={errors}
                  />
                );
              }
              // Parse <textarea> html elements
              if (domNode.name === 'textarea') {
                count += 1;
                const key = Object.hasOwnProperty.call(domNode.attribs, 'id')
                  ? `${qnID}-${domNode.attribs.id}`
                  : `${qnID}-key${count}`;
                const name = Object.hasOwnProperty.call(domNode.attribs, 'id')
                  ? `freetext-${domNode.attribs.id}`
                  : `freetext-key${count}`;
                if (showSubmissions && hasSubmissions) {
                  return (
                    <LongAnsTextarea
                      key={key}
                      name={name}
                      studentAnswer={
                        parsed.studentSubmissions[0].answerkeys.length > 0
                          ? parsed.studentSubmissions[0].answerkeys.find(
                              (answer) => answer.key === `key${count}`
                            )?.value
                          : null
                      }
                      refAnswer={
                        // For solutions page, we do not show ref answer
                        page !== 'solution'
                          ? allQsAns[activeQn - 1].find(
                              (answerKeys) => answerKeys.Key === `key${count}`
                            ).Value
                          : null
                      }
                      onChangeHandler={inputOnChange}
                      isMarkedRight={
                        currentSubmission.SubmissionKeyValuePairs.length > 0
                          ? currentSubmission.SubmissionKeyValuePairs.find(
                              (answerKey) => answerKey.Key === `key${count}`
                            )?.ismarkedright === true
                          : false
                      }
                      register={register}
                      showRefAnswer={showLongAnswer}
                      setSelectedTextareaKey={setSelectedTextareaKey}
                    />
                  );
                }
                return (
                  <LongAnsTextarea
                    key={key}
                    name={name}
                    studentAnswer={
                      localSavAns[activeQn - 1] !== null
                        ? localSavAns[activeQn - 1][`${qnID}-key${count}`]
                        : domNode.attribs.hasOwnProperty('value')
                        ? domNode.attribs.value
                        : ''
                    }
                    refAnswer={
                      allQsAns.length > 0
                        ? allQsAns[activeQn - 1].find(
                            (answerKeys) => answerKeys.Key === `key${count}`
                          ).Value
                        : null
                    }
                    onChangeHandler={inputOnChange}
                    isMarkedRight={false}
                    register={register}
                    showRefAnswer={showLongAnswer}
                    setSelectedTextareaKey={setSelectedTextareaKey}
                  />
                );
              }
              return domNode;
            },
          }
        );
        setQuestionContent(questionContent);
      } catch (err) {
        console.log(err);
        setRenderError(true);
        setQuestionContent(null);
      }
      // Set Mcq Options
      if (parsed.mcqOptions !== null) {
        setMcqOptions(parsed.mcqOptions);
      } else {
        setMcqOptions(null);
      }
      // Set Solution
      if (parsed.solution.length > 0) {
        try {
          const content = HTMLReactParser(convertTagsToHtml(parsed.solution));
          setQuestionSolution(content);
        } catch (err) {
          console.log(err);
          setRenderError(true);
          setQuestionSolution(null);
        }
      } else {
        setQuestionSolution(null);
      }
    }
    return () => {
      setRenderError(false);
    };
  }, [
    activeQn,
    allQsAns,
    assignmentType,
    correctAnswer,
    currentSubmission,
    errors,
    xml,
    inputOnChange,
    isAnswerInputDisabled,
    isChecking,
    localSavAns,
    missionQuestionAnswers,
    multiplayerQnAnswers,
    page,
    qnID,
    qnIndex,
    register,
    showLongAnswer,
    showSubmissions,
  ]);
  if (renderError) {
    return <QuestionFallback qnID={qnID} />;
  }
  return (
    <Container data-cy="qn-content">
      <ErrorBoundary
        FallbackComponent={() => {
          return <QuestionFallback qnID={qnID} />;
        }}
      >
        <Voiceover qnID={qnID} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {questionContent}
          {questionType === 'mcq' && mcqOptions !== null && (
            <MCQOptions
              data={mcqOptions}
              isAnswerInputDisabled={isAnswerInputDisabled}
              page={page}
              assignmentType={assignmentType}
              submissionModel={currentSubmission}
              correctAnswer={correctAnswer}
              PCActiveQn={PCActiveQn}
            />
          )}
          {page === 'self-practice' &&
            SubmissionResultTypes[
              submitAns.QuestionSubmissionModel &&
                submitAns.QuestionSubmissionModel.SubmissionResult
            ] === 'wrong' && (
              <QuestionAnswer
                xml={
                  submitAns.QuestionSubmissionModel &&
                  submitAns.QuestionSubmissionModel.Payload
                }
                qnType={questionType}
              />
            )}
          {showSolution &&
            page === 'peer-challenge' &&
            currentSubmission !== null &&
            SubmissionResultTypes[currentSubmission.SubmissionResult] ===
              'wrong' && <QuestionAnswer xml={currentSubmission.Payload} />}
          {showSolution &&
            currentSubmission !== null &&
            SubmissionResultTypes[currentSubmission[subResName]] ===
              'wrong' && (
              <IncorrectAnswerMessage data-cy="alert-text">
                {t(
                  'assignmentSolution:words.Text',
                  'You have incorrect answer. No score for this question'
                )}
              </IncorrectAnswerMessage>
            )}
          <div style={{ textAlign: 'right', margin: '10px 0' }}>
            {showWorking && (
              <span
                className="solution-header"
                onClick={() => {
                  setRevealWorkings(!revealWorkings);
                }}
                data-cy="show-working"
              >
                {revealWorkings
                  ? t('assignmentQnView:main.hideMyWorking', 'Hide My Working')
                  : t('assignmentQnView:main.showWorking', 'Show My Working')}
              </span>
            )}
            {showSolution && questionSolution !== null && (
              <span
                className="solution-header"
                onClick={() => {
                  setRevealSolution(!revealSolution);
                }}
                data-cy="show-solution"
              >
                {revealSolution
                  ? t('assignmentQnView:main.hideSolution', 'Hide Solution')
                  : t('assignmentQnView:main.showSolution', 'Show Solution')}
              </span>
            )}
          </div>
          {showWorking && revealWorkings && (
            <Workings>
              <p className="title">
                {t('assignmentQnView:main.myWorking', 'My Working:')}
              </p>
              <p data-cy="workings">
                {parseWorkingsBarModel(solutionString).workings}
              </p>
            </Workings>
          )}
          {showSolution && revealSolution && (
            <Solution>
              <p className="title">
                {t('assignmentQnView:main.solution', 'Solution:')}
              </p>
              {questionSolution}
              {showFeedbackButton && !isMobile && (
                <div style={{ textAlign: 'right' }}>
                  <QuestionFeedbackLink
                    qnID={qnID}
                    skillID={skillID}
                    margin="2rem 0 0 0"
                  />
                </div>
              )}
            </Solution>
          )}
          <br />
          {/* check answer & video tutorial btn */}
          {videoTutorialHw && (
            <VideoTutorial>
              <img src={VideoIcon} alt="video" onClick={handlePlayVideo} />
              <p onClick={handlePlayVideo}>
                <Trans i18nKey="dailyChallengeQnView:tutorial">
                  <span>Click here</span> to watch a video tutorial.
                </Trans>
              </p>
            </VideoTutorial>
          )}
          {showCheckAnswer &&
            (currentSubmission !== null
              ? SubmissionResultTypes[currentSubmission[subResName]] !==
                'correct'
              : true) && (
              <CheckAnswerButton
                data-cy={activeQn}
                type="submit"
                disabled={isCheckingAnswer[activeQn - 1] === true || saving}
                onClick={(e) => {
                  if (questionType === 'longAnswer') {
                    if (showLongAnswer === false) {
                      // Prevents handle submit event handler from being called
                      e.preventDefault();
                      setShowLongAnswer(true);
                    }
                    // Check if all long answer textareas have been marked as right by student
                    else if (
                      currentSubmission === null ||
                      !checkIfAllLongAnswersMarkedRight(
                        currentSubmission.SubmissionKeyValuePairs
                      )
                    ) {
                      e.preventDefault();
                    } // All long answer textareas have been marked right, call Check Answer API to mark normal fill-in-the-blank/s
                  } else if (questionType === 'mcq') {
                    e.preventDefault();
                    if (
                      currentSubmission !== null &&
                      SubmissionResultTypes[currentSubmission[subResName]] ===
                        'wrong' &&
                      isAnswerInputDisabled
                    ) {
                      setIsAnswerInputDisabled(false);
                    } else {
                      setIsAnswerInputDisabled(true);
                      checkAnswerHandler(
                        null,
                        questionType,
                        localSavAns[activeQn - 1],
                        null,
                        getWorkings(
                          allSubmission[activeQn - 1],
                          localSavWorkings[activeQn - 1]
                        ),
                        barModelData.objects.length > 0 ? barModelData : null,
                        assignmentType
                      );
                    }
                  }
                  // If answer is marked as wrong, student needs to click Try Again before they can change answer
                  // and check answer again
                  if (
                    currentSubmission !== null &&
                    SubmissionResultTypes[currentSubmission[subResName]] ===
                      'wrong' &&
                    isAnswerInputDisabled
                  ) {
                    e.preventDefault();
                    setIsAnswerInputDisabled(false);
                  }
                }}
              >
                {currentSubmission !== null &&
                SubmissionResultTypes[currentSubmission[subResName]] ===
                  'wrong' &&
                isAnswerInputDisabled
                  ? t('assignmentQnView:main.tryAgain', 'Try Again')
                  : t('assignmentQnView:main.check', 'Check Answer')}
              </CheckAnswerButton>
            )}
        </form>
      </ErrorBoundary>
    </Container>
  );
};
export default Question;
