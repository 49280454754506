import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const CardImg = styled.img`
  width: 100%;
  cursor: pointer;
`;

export const WrapUnlock = styled.a`
  width: 100%;
  margin: 10px 0 25px 0;
  text-align: center;
  position: relative;
  cursor: pointer;

  span {
    position: absolute;
    font-family: arial;
    color: #fff;

    &.unlock-text {
      top: 10px;
      left: 40px;
    }

    &.koko-text {
      top: 10px;
      right: 35px;
    }
  }
`;
