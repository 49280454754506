import React from 'react';
import ProgressBar from 'components/Report/ProgressBar';
import GradeAIcon from 'assets/img/report/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/report/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/report/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/report/icon-report-high-score-d.svg';
import IncompleteIcon from 'assets/img/report/icon-report-high-score-incomplete.svg';
import { ProficiencyStatus, MasteryLevel } from 'constants/report';
import { sortSkills } from 'helpers/reportProgressbar';
import { useTranslation } from 'react-i18next';
import { SkillSection, ProficiencyBadge } from './ReportDetails.styles';

const ReportDetails = ({
  setShowSkillDetails,
  type,
  selectedSkill,
  setSelectedSkill,
  setSelectedSkillTopic,
  setSelectedSkillNumber,
  data,
}) => {
  const { t } = useTranslation(['proficiencyReport']);
  return (
    <>
      {data &&
        data.map((topic, index) => (
          <SkillSection key={topic.TopicId} index={index} type={type}>
            <p className="skill-title">
              <strong>{topic.TopicName}</strong>
            </p>
            {type === 'proficiency' && (
              <div className="progressbar">
                <ProgressBar
                  height="11px"
                  visualParts={sortSkills(topic.Skills)}
                />
                <p>
                  <strong>{topic.SkillMastered}</strong>
                  {` / ${topic.TotalNoOfSkill} ${t(
                    'proficiencyReport:skillDetail.skillsMastered',
                    'Skills mastered'
                  )} ( ${t(
                    'proficiencyReport:skillDetail.classAvg',
                    'Class Avg'
                  )} `}
                  <strong>{topic.ClassAverage}</strong>
                  {` / ${topic.TotalNoOfSkill} )`}
                </p>
              </div>
            )}
            <div className="skill-badges">
              {type === 'proficiency' &&
                topic.Skills.map((skill, index) => (
                  <div
                    data-cy={skill.SkillId}
                    className="badge"
                    key={`${topic.TopicId}-${skill.SkillId}`}
                    style={{
                      background:
                        selectedSkill &&
                        selectedSkill.SkillId === skill.SkillId &&
                        'rgba(226, 202, 126, 0.24)',
                    }}
                    onClick={() => {
                      setShowSkillDetails(true);
                      setSelectedSkill(skill);
                      setSelectedSkillTopic(topic.TopicName);
                      setSelectedSkillNumber(index + 1);
                    }}
                  >
                    {ProficiencyStatus[skill.ProficiencyStatus] ===
                      'mastery' && (
                      <ProficiencyBadge proficiency="mastery" data-cy="mastery">
                        <span>
                          {t(
                            'proficiencyReport:studentReportSide.skill',
                            'Skill'
                          )}
                        </span>
                        <span>{index + 1}</span>
                      </ProficiencyBadge>
                    )}
                    {ProficiencyStatus[skill.ProficiencyStatus] ===
                      'competent' && (
                      <ProficiencyBadge
                        proficiency="competent"
                        data-cy="competent"
                      >
                        <span>
                          {t(
                            'proficiencyReport:studentReportSide.skill',
                            'Skill'
                          )}
                        </span>
                        <span>{index + 1}</span>
                      </ProficiencyBadge>
                    )}
                    {ProficiencyStatus[skill.ProficiencyStatus] ===
                      'beginning' && (
                      <ProficiencyBadge
                        proficiency="beginning"
                        data-cy="beginning"
                      >
                        <span>
                          {t(
                            'proficiencyReport:studentReportSide.skill',
                            'Skill'
                          )}
                        </span>
                        <span>{index + 1}</span>
                      </ProficiencyBadge>
                    )}
                    {ProficiencyStatus[skill.ProficiencyStatus] ===
                      'incomplete' && (
                      <ProficiencyBadge
                        proficiency="incomplete"
                        data-cy="incomplete"
                      >
                        <span>
                          {t(
                            'proficiencyReport:studentReportSide.skill',
                            'Skill'
                          )}
                        </span>
                        <span>{index + 1}</span>
                      </ProficiencyBadge>
                    )}
                    <p className="skill-percentage">
                      <strong>{`${Math.round(
                        skill.Proficiency * 100
                      )}%`}</strong>
                    </p>
                  </div>
                ))}
              {type === 'highscore' &&
                topic.Skills.map((skill, index) => (
                  <div
                    className="badge"
                    key={`${topic.TopicId}-${skill.SkillId}`}
                    style={{
                      background:
                        selectedSkill &&
                        selectedSkill.SkillId === skill.SkillId &&
                        'rgba(226, 202, 126, 0.24)',
                    }}
                    onClick={() => {
                      setShowSkillDetails(true);
                      setSelectedSkill(skill);
                      setSelectedSkillTopic(topic.TopicName);
                      setSelectedSkillNumber(index + 1);
                    }}
                  >
                    {MasteryLevel[skill.MasteryLevel] === 'A' && (
                      <img
                        src={GradeAIcon}
                        alt="Grade A"
                        className="grade-icon"
                        data-cy="gradeA"
                      />
                    )}
                    {MasteryLevel[skill.MasteryLevel] === 'B' && (
                      <img
                        src={GradeBIcon}
                        alt="Grade B"
                        className="grade-icon"
                        data-cy="gradeB"
                      />
                    )}
                    {MasteryLevel[skill.MasteryLevel] === 'C' && (
                      <img
                        src={GradeCIcon}
                        alt="Grade C"
                        className="grade-icon"
                        data-cy="gradeC"
                      />
                    )}
                    {MasteryLevel[skill.MasteryLevel] === 'D' && (
                      <img
                        src={GradeDIcon}
                        alt="Grade D"
                        className="grade-icon"
                        data-cy="gradeD"
                      />
                    )}
                    {MasteryLevel[skill.MasteryLevel] === 'incomplete' && (
                      <img
                        src={IncompleteIcon}
                        alt="Incomplete"
                        className="grade-icon"
                        data-cy="gradeIncomplete"
                      />
                    )}
                    <p className="skill-number">
                      <strong>
                        {`${t(
                          'proficiencyReport:studentReportSide.skill',
                          'Skill'
                        )} `}
                        {index + 1}
                      </strong>
                    </p>
                  </div>
                ))}
            </div>
          </SkillSection>
        ))}
    </>
  );
};

export default ReportDetails;
