import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PremBadge from 'assets/img/expired/premium-badge.svg';
import StandBadge from 'assets/img/expired/standard-badge.svg';
import { plansTitle } from 'constants/index';
import moment from 'moment';
import { Header } from './PlanHeader.styles';

const PlanHeader = ({ type }) => {
  const { t } = useTranslation(['expired']);
  const { subscriptionPlans } = useSelector((state) => state.payment);

  return (
    <Header>
      <img src={type === 'premium' ? PremBadge : StandBadge} alt="badge" />
      <div className="plan-details">
        <div className="plan-name">
          {t(
            'expired:planHeader.kooBitsType',
            { type: plansTitle[type] },
            `KooBits Math - ${plansTitle[type]}`
          )}
        </div>
        <div className="plan-date">
          {t('expired:planHeader.annualPlan', 'Annual plan,')}{' '}
          {moment().format('DD MMMM, YYYY')} -{' '}
          {moment().add(1, 'years').format('DD MMMM, YYYY')}
        </div>
      </div>
      <div className="plan-price">
        <span className="sgd">
          {subscriptionPlans[0] && subscriptionPlans[0].Currency}
        </span>
        <span className="price">
          {subscriptionPlans[0] && subscriptionPlans[0].Price}
        </span>
        <span className="year">
          {subscriptionPlans[0] &&
          subscriptionPlans[0].DurationType !== 'Yearly'
            ? t('expired:planHeader.perMonth', 'per month')
            : t('expired:planHeader.perYear', 'per year')}
        </span>
        <br />
        {subscriptionPlans[0] && subscriptionPlans[0].HasTax && (
          <span className="tax">{t('expired:planHeader.gst', '+7% GST')}</span>
        )}
      </div>
    </Header>
  );
};

export default PlanHeader;
