import React, { useState } from 'react';
import ResponsiveHeader from 'components/Assignment/AssignmentSolution/ResponsiveHeader';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';
import SideNav from 'components/Shared/SideNav';
import AssignmentSideNav from 'components/Assignment/AssignmentSolution/AssignmentSideNav';
import HeaderMiddle from './HeaderMiddle';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

const AssignmentHeader = ({
  assignmentTitle = '',
  assignmentId,
  assignmentType = '',
  questions,
  activeQuestion,
  scrollToHandler,
  setActiveQn,
}) => {
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  const [showSideNav, setShowSideNav] = useState(false);
  const printUrl = assignmentId
    ? `${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_SUBDOMAIN}${
        process.env.REACT_APP_SUBDOMAIN === '/' ? '' : '/'
      }assignment/print/${assignmentId}`
    : '';

  const sideNavClickHandler = () => {
    setShowSideNav((prevState) => !prevState);
  };

  return (
    <>
      <SideNav show={showSideNav}>
        <AssignmentSideNav
          setShow={setShowSideNav}
          activeQuestion={activeQuestion}
          setActiveQn={setActiveQn}
          questions={questions}
          printUrl={printUrl}
          assignmentType={assignmentType}
          scrollToHandler={scrollToHandler}
        />
      </SideNav>
      <ResponsiveHeader
        assignmentType={assignmentType}
        left={
          <HeaderLeft
            isMobile={isMobile}
            isTablet={isTablet}
            assignmentType={assignmentType}
          />
        }
        middle={
          <HeaderMiddle
            isMobile={isMobile}
            assignmentType={assignmentType}
            assignmentTitle={assignmentTitle}
          />
        }
        right={
          <HeaderRight
            isTablet={isTablet}
            assignmentType={assignmentType}
            printUrl={printUrl}
            sideNavClickHandler={sideNavClickHandler}
          />
        }
        styles={{
          leftSection: isMobile
            ? {
                minWidth: 'auto',
              }
            : {},
          rightSection: isTablet
            ? {
                borderLeft: 'none',
                justifyContent: 'flex-end',
                minWidth: isMobile ? '55%' : '200px',
              }
            : {},
          top: '59px',
        }}
      />
    </>
  );
};

export default AssignmentHeader;
