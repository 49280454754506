import styled from 'styled-components';
import ChartBg from 'assets/img/mission/hotsv2/chart-bg.svg';

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 35%;
  z-index: 1;
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    bottom: auto;
    img {
      display: none;
    }
  }
`;

export const DropdownButton = styled.button`
  border: none;
  border-radius: 1rem;
  padding: 9px 24px;
  background: white;
  position: relative;
  bottom: 7px;
  z-index: 2;
  p {
    margin: 0;
  }
  .title {
    font-size: 12px;
    font-weight: 300;
    color: #666666;
  }
  .age-text {
    font-size: 18px;
  }
  .icon {
    path {
      fill: #505050;
    }
  }
  svg {
    position: absolute;
    bottom: 18px;
    right: 30px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 120px;
    .title {
      display: none;
    }
    .age-text {
      font-size: 16px;
    }
    svg {
      right: 20px;
    }
  }
`;

export const DropdownButtonContainer = styled.div`
  & > .title {
    display: none;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: flex;
    align-items: center;
    gap: 10px;
    & > .title {
      display: block;
    }
  }
`;

export const DropdownMenu = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  min-width: 142px;
  align-items: center;
  border-radius: 1rem;
  background: white;
  :before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-left: 8px solid transparent;
    border-bottom: ${(props) =>
      `8px solid ${props.isHoverOverFirstItem ? '#def0ff' : 'white'}`};
    border-right: 8px solid transparent;
    right: 20%;
    top: -8px;
  }
`;

export const DropdownItem = styled.span`
  padding: 15px 0;
  font-size: 14px;
  text-align: center;
  width: 100%;
  border-radius: ${(props) =>
    props.index === 0
      ? '1rem 1rem 0 0'
      : props.index === props.totalItems - 1
      ? '0 0 1rem 1rem'
      : '0'};
  border-top: ${(props) => (props.index > 0 ? 'solid 0.75px #e2e2e2' : 'none')};
  cursor: pointer;
  :hover {
    background: #def0ff;
  }
`;

export const StyledChartContainer = styled.div`
  background-image: ${`url(${ChartBg})`};
  background-repeat: no-repeat;
  background-size: 501px 501px;
  background-position: top center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    background-image: none;
  }
`;

export const GridChart = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 60px;
    grid-template-columns: 100%;
    width: 100%;
  }
`;

export const StyledQuadrant = styled.div`
  width: 250px;
  height: 250px;
  position: relative;
  .category {
    position: absolute;
    width: ${(props) => props.categoryStyles.categoryWidth ?? '40%'};
    color: ${(props) => props.categoryStyles.categoryFontColour ?? 'black'};
    text-align: ${(props) => props.categoryStyles.categoryTextAlign ?? 'left'};
    font-size: 18px;
    line-height: 1.2;
    margin: 0;
    left: 30%;
    top: ${(props) => props.categoryStyles.categoryTopPosition ?? '30%'};
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
    height: auto;
    padding: 20px;

    :first-child {
      background-color: #ffefce;
    }

    :nth-child(2) {
      background-color: #effbe4;
    }

    :nth-child(3) {
      grid-row-start: 2;
      background-color: #c1e4f6;
    }

    :last-child {
      background-color: #fbdcdc;
    }

    .category {
      position: relative;
      inset: auto;
      width: 100%;
      text-align: center;
    }
  }
`;
