import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import ModalVideo from 'react-modal-video';
import DifficultyChillis from 'components/Shared/DifficultyChillis';
import Button from 'components/Shared/Button';
import { Button as ReactButton } from 'react-bootstrap';
import QuestionContent from 'components/Question';
import PopupModal from 'components/Shared/PopupModal';
import ChangeQuestionModal from 'components/DailyChallenge/ChangeQuestionModal';
import VideoIcon from 'assets/img/icon-play-video.svg';
import {
  submissionTypeIDs,
  questionTypeIDs,
  featureToggle,
} from 'constants/index';
import QuestionAnswer from 'components/Question/Answer';
import { clearVoiceoverPlaying } from 'store/voiceover/voiceoverSlice';
import { resetSubmitAns } from 'store/dailyChallenge/dailyChallengeSlice';
import QnViewSolution from './QnViewSolution';
import CorrectAnswerSubmitted from './CorrectAnswerSubmitted';
import {
  Container,
  WrongAlert,
  WrongAnswerSubmit,
} from './DailyChallengeNotebookMiddle.styles';

const toShowSubmission = (submissionResult, correctAnswer) => {
  if (submissionResult === 2) {
    if (correctAnswer === 'unknown') {
      return false;
    }
    return true;
  }
  if (submissionResult === 1) {
    return true;
  }
  if (submissionResult === 4) {
    return true;
  }
};

const DailyChallengeNotebookMiddle = ({
  correctAnswer,
  expandQn,
  setExpandQn,
  setCorrectAnswer,
  showSolution,
  submitAnswer,
  isSkipAnswer,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallengeQnView']);
  const {
    activeQsDailyChallenge,
    dailyChallengeQuestion,
    dailyChallengeSubmissions,
    submitAnsDailyChallenge,
    currDailyChallenge,
    isLoading,
  } = useSelector((state) => state.dailyChallenge);
  const { voiceOverFileExist } = useSelector((state) => state.voiceover);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const submissionType = submissionTypeIDs.DailyChallenge;
  const [showChangeQuestionModal, setShowChangeQuestionModal] = useState(false);
  const hideChangeQuestionModal = () => {
    setShowChangeQuestionModal(false);
  };
  const videoUrl = currDailyChallenge.UserQuestionSubmissions.map(
    (item) => item.VideoCode
  )[activeQsDailyChallenge - 1];

  // Event Handlers
  const tryAgainClickHandler = () => {
    dispatch(resetSubmitAns());
    setCorrectAnswer('unknown');
  };
  const handlePlayVideo = () => {
    setShowVideoModal(true);
    dispatch(clearVoiceoverPlaying());
  };
  return (
    <Container voiceOverFileExist={voiceOverFileExist}>
      <PopupModal show={showChangeQuestionModal} hide={() => {}} centered>
        <ChangeQuestionModal onHide={hideChangeQuestionModal} />
      </PopupModal>
      <ModalVideo
        channel="youtube"
        isOpen={showVideoModal}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
        }}
        videoId={videoUrl}
        onClose={() => setShowVideoModal(false)}
      />
      {correctAnswer === 'wrong' && (
        <WrongAlert>
          <p>
            {t(
              'dailyChallengeQnView:incorrect',
              'Sorry, your answer is incorrect.'
            )}
          </p>
        </WrongAlert>
      )}
      {correctAnswer === 'correct' && !isEmpty(submitAnsDailyChallenge) && (
        <CorrectAnswerSubmitted setExpandQn={setExpandQn} expandQn={expandQn} />
      )}
      {(correctAnswer !== 'correct' ||
        (correctAnswer === 'correct' && isEmpty(submitAnsDailyChallenge))) && (
        <>
          <div className="qn-header">
            <div className="qn-section">
              <p className="question-title">
                {t('dailyChallengeQnView:qn', 'Question')}
              </p>
              <p className="qn-id">
                (ID {dailyChallengeQuestion[activeQsDailyChallenge - 1].Id})
              </p>
              <p className="qn-id-mobile">
                (Qn ID {dailyChallengeQuestion[activeQsDailyChallenge - 1].Id})
              </p>
            </div>
            <div className="qn-difficulty">
              <div className="difficulty-chillis">
                <DifficultyChillis
                  value={
                    dailyChallengeSubmissions[activeQsDailyChallenge - 1]
                      .Question.Difficulty.ActualValue
                  }
                />
              </div>
              {correctAnswer === 'unknown' &&
                ((featureToggle.science &&
                  currDailyChallenge.SubjectID === 1) ||
                  !featureToggle.science) && (
                  <Button
                    fontSize="14px"
                    variant="blue-secondary"
                    onClick={() => setShowChangeQuestionModal(true)}
                    dataCy="change-question-button"
                  >
                    {t('dailyChallengeQnView:changeQn', 'Change Question')}
                  </Button>
                )}
            </div>
          </div>
        </>
      )}
      {expandQn && (
        <>
          <div className="qn-content">
            <QuestionContent
              page="daily-challenge"
              submissionType={submissionType}
              questionType={
                questionTypeIDs[
                  dailyChallengeQuestion[activeQsDailyChallenge - 1]
                    .QuestionType
                ]
              }
              qnID={dailyChallengeQuestion[activeQsDailyChallenge - 1].Id}
              xml={
                dailyChallengeSubmissions[activeQsDailyChallenge - 1]
                  .SubmissionModel !== null
                  ? dailyChallengeSubmissions[activeQsDailyChallenge - 1]
                      .SubmissionModel.Payload
                  : dailyChallengeQuestion[activeQsDailyChallenge - 1].Payload
              }
              correctAnswer={correctAnswer}
              showSubmissions={
                dailyChallengeSubmissions[activeQsDailyChallenge - 1]
                  .SubmissionModel !== null &&
                toShowSubmission(
                  dailyChallengeSubmissions[activeQsDailyChallenge - 1]
                    .SubmissionModel.SubmissionResult,
                  correctAnswer
                )
              }
              assignmentType="daily-challenge"
            />
          </div>
        </>
      )}
      {!showSolution && correctAnswer === 'unknown' && (
        <div className="btn-submit">
          <ReactButton
            variant="orange"
            disabled={isLoading}
            data-cy="submit-answer"
            onClick={() => {
              if (isLoading) {
                return;
              }
              submitAnswer(false);
            }}
          >
            {!isSkipAnswer && isLoading
              ? `${t('dailyChallengeQnView:submitting', 'Submitting...')}`
              : `${t('dailyChallengeQnView:submitAnswer', 'Submit Answer')}`}
          </ReactButton>
        </div>
      )}
      {correctAnswer === 'skipped' && (
        <QuestionAnswer
          xml={
            dailyChallengeSubmissions[activeQsDailyChallenge - 1]
              .SubmissionModel?.Payload
          }
        />
      )}
      {correctAnswer === 'wrong' && !isEmpty(submitAnsDailyChallenge) && (
        <>
          <WrongAnswerSubmit>
            {!isNil(videoUrl) && videoUrl !== '' && (
              <div className="video-tutorial">
                <img src={VideoIcon} alt="video" onClick={handlePlayVideo} />
                <button
                  type="button"
                  className="btn-tutorial"
                  onClick={handlePlayVideo}
                >
                  <Trans i18nKey="dailyChallengeQnView:tutorial">
                    <span>Click here</span> to watch a video tutorial.
                  </Trans>
                </button>
              </div>
            )}
            <Button
              variant="primary"
              width="170px"
              onClick={tryAgainClickHandler}
              dataCy="try-again-btn"
            >
              {t('dailyChallengeQnView:tryAgain', 'Try Again')}
            </Button>
          </WrongAnswerSubmit>
        </>
      )}
      {showSolution && <QnViewSolution />}
    </Container>
  );
};

export default DailyChallengeNotebookMiddle;
