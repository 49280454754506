import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f2d428;
  display: flex;
`;

export const Progress = styled.div`
  position: absolute;
  top: 0;
  width: ${(props) => props.width};
  height: 10px;
  background-color: #ffffff;
`;
