/* eslint-disable import/prefer-default-export */
// to consruct url based on params value
function addUrlParams(campaignCode) {
  let filter = '';
  if (campaignCode) {
    filter += `&campaignCode=${campaignCode}`;
  }
  return `${filter}`;
}

export const paymentRenewUrl = ({
  type = 'renew',
  userId,
  bundleTypeId,
  campaign,
}) => {
  return `${
    process.env.REACT_APP_RENEWAL_V2_URL
  }${type}/${bundleTypeId}/?id=${userId}${addUrlParams(campaign)}`;
};
