import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProficiencyStatus } from 'constants/report';
import { proficiencyColors } from 'constants/parentAssignment';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';
import useIsMobile from 'hooks/responsive/useIsMobile';
import {
  Container,
  StyledSkillRow,
  QuestionColumn,
  DescriptionColumn,
  SkillBadge,
  ButtonBox,
  PercentColumn,
} from './SkillRow.styles';

const SkillRow = ({
  id,
  percentage,
  status,
  description,
  questionNumber,
  showSkillDetails,
  setShowSkillDetails,
  topics,
  setTopics,
  selectedSkill,
  setSelectedSkill,
  selectedTopic,
  setSelectedTopic,
  totalQuestions,
  setTotalQuestions,
  order,
}) => {
  const { t } = useTranslation(['parentAssign', 'proficiencyReport']);
  const currentQuestion = questionNumber || 0;
  const isMobile = useIsMobile();

  const updateQuestionNumber = (number) => {
    const newNumber = currentQuestion + number;
    const newTotalQuestions = totalQuestions + number;

    const newSkills = selectedTopic.Skills.map((skill) =>
      skill.SkillId === id
        ? { ...skill, questionNumber: newNumber }
        : { ...skill, questionNumber: skill.questionNumber || 0 }
    );

    const totalQuestionNumber = newSkills.reduce((prev, curr) => {
      return prev + curr.questionNumber;
    }, 0);

    const newTopic = {
      ...selectedTopic,
      Skills: newSkills,
      totalQuestionNumber,
    };
    setSelectedTopic(newTopic);
    const updatedTopics = topics.map((topic) =>
      topic.TopicId === newTopic.TopicId ? newTopic : topic
    );
    setTopics(updatedTopics);
    setTotalQuestions(newTotalQuestions);
  };

  const percentageDecorator = (percentageData) => {
    return `${Math.round(percentageData * 100)}%`;
  };

  const statusDecorator = (statusData) => {
    const res = ProficiencyStatus[statusData];

    return (
      <SkillBadge statusColor={proficiencyColors[res]}>
        {t(`proficiencyReport:legend.${res}`, `${capitalizeFirstLetter(res)}`)}
      </SkillBadge>
    );
  };

  const toggleSkill = () => {
    const selectSkill = selectedTopic.Skills.find(
      (skill) => skill.SkillId === id
    );
    setSelectedSkill(selectSkill);
    setShowSkillDetails(true);
  };

  const statusPercentComponent = (
    <>
      <PercentColumn statusColor={proficiencyColors[ProficiencyStatus[status]]}>
        {percentageDecorator(percentage)}
      </PercentColumn>
      <div className="status-column">{statusDecorator(status)}</div>
    </>
  );

  return (
    <Container
      onClick={toggleSkill}
      active={selectedSkill && selectedSkill.SkillId === id}
    >
      {isMobile && (
        <div className="skill-column">
          {t('parentAssign:content.skill', 'Skill')} {order}
        </div>
      )}
      <StyledSkillRow>
        <DescriptionColumn>
          <div className={`left-component skill-row--${id}`}>
            {!isMobile && (
              <div className="left-side">
                <div className="skill-column">
                  {t('parentAssign:content.skill', 'Skill')} {order}
                </div>
                {statusPercentComponent}
              </div>
            )}
            <div className="desc-column">{description}</div>
          </div>
        </DescriptionColumn>
        <div className="bottom-content">
          {isMobile && (
            <div className="status-percent">{statusPercentComponent}</div>
          )}
          {!showSkillDetails && (
            <QuestionColumn number={currentQuestion > 0}>
              <ButtonBox
                className={`button-down-${id}`}
                disabled={currentQuestion === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  updateQuestionNumber(-1);
                }}
              >
                -
              </ButtonBox>
              <div className="question">{currentQuestion}</div>
              <ButtonBox
                className={`button-up-${id}`}
                disabled={currentQuestion === 10 || totalQuestions === 50}
                onClick={(e) => {
                  e.stopPropagation();
                  updateQuestionNumber(1);
                }}
              >
                +
              </ButtonBox>
              <div className="question-text">
                {t('parentAssign:content.qn', 'Qn(s)')}
              </div>
            </QuestionColumn>
          )}
        </div>
      </StyledSkillRow>
    </Container>
  );
};

export default SkillRow;
