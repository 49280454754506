import styled, { css } from 'styled-components';
import { assignmentTypeColors } from 'constants/index';

export const Type = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  background: ${(props) =>
    props.type && !props.deleted
      ? assignmentTypeColors[props.type]
      : props.deleted && '#C4C4C4'};
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  min-width: 50px;
  height: 20px;
  font-size: 14px;
  padding: 0px 5px;
  line-height: 1.2;

  img {
    width: 35px;
    height: 35px;
  }

  ${({ isEnglish }) => {
    if (!isEnglish) {
      return css`
        font-size: 12px;
        padding: 0;
      `;
    }
  }}
`;
