import handleResponse from 'helpers/handleAPIResponse';
import axios from 'axios';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;
const customerUrl = process.env.REACT_APP_TRIAL_SERVICE_URL;

export const fetchStudentParentDetail = (userID) => {
  return fetch(`${baseUrl}Student/${userID}/Parent`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'get parent detail failed'); // eslint-disable-line
    });
};

export const requestTrialConsentOTP = (rawBody) => {
  return fetch(`${baseUrl}TrialSignup/RequestTrialConsentOTP`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'request trial consent OTP failed'); // eslint-disable-line
    });
};

export const verifyTrialConsentOTP = (rawBody) => {
  return fetch(`${baseUrl}TrialSignup/VerifyTrialConsentOTP`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'verify trial OTP failed'); // eslint-disable-line
    });
};

export const requestCountries = () => {
  return axios
    .get(`${customerUrl}Countries`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response.data;
    });
};
