import styled from 'styled-components';

export const ToolNameContainer = styled.div`
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  cursor: move;
  .close-btn {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f4f4;
  padding: 0px;
  box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
  width: 350px;
  min-height: 400px;
  position: fixed;
  right: 90px;
  top: ${(props) => (props.type === 'four-ops' ? '300px' : '60px')};
  z-index: ${(props) => (props.onTop ? '20' : '11')};
  pointer-events: ${(props) => (props.toShow ? 'auto' : 'none')};
  opacity: ${(props) => (props.toShow ? '1' : '0')};
  .content {
    min-height: 366.4px;
  }
  &.bar-model {
    min-width: 350px;
    width: auto;
    position: initial;
    right: 0px;
    height: 100%;
    & > .content {
      height: 100%;
    }
  }
`;
