import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  padding: 0rem 1rem 0rem 1rem;
  font-family: 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.25rem;
  pointer-events: auto;
  display: flex;
  @media (max-width: 955px) {
    min-height: 25rem;
    font-size: 1rem;
  }
  @media (max-width: 850px) {
    min-height: 20rem;
  }

  /* to adjust screen when there is Table in questionContent */
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    min-height: fit-content;
    flex-direction: column;
    form {
      overflow-x: auto;
    }
  }
  form {
    width: 100%;
  }
  input {
    height: 35px;
    width: 140px;
    padding: 0 0.5rem;
  }
  textarea {
    min-width: 140px;
  }
  tr {
    vertical-align: top;
  }
  .vertical-addition {
    width: 140px !important;
    margin-left: 0 !important;
    textarea {
      min-width: auto !important;
      width: 25px !important;
      min-height: auto !important;
      padding: 0;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
    }
    .result-icon {
      right: 4px;
      bottom: 4px;
    }
    .result-icon {
      transform: translateX(69%);
      right: 50%;
    }
  }

  .correct-input {
    background: #e9ffe8;
    border: solid 1px #8fc78b;
  }

  .wrong-input {
    background: #ffd1d4;
    border: solid 1px #f59999;
  }

  .solution-header {
    color: #43b3c5;
    font-family: 'Linotte';
    font-weight: 300;
    font-size: 0.9375rem;
    padding-right: 1rem;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      color: #368f9e;
    }
  }

  .fraction-wrap {
    display: inline-block;
    vertical-align: middle;
  }
  .fraction-whole-part {
    display: inline-block;
    vertical-align: baseline;
  }
  .fraction-numerator-denominator-part {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.25em;
  }
  .fraction-numerator {
    display: block;
    border-bottom: 1px solid black;
    padding: 0.25em;
    text-align: center;
  }
  .fraction-denominator {
    display: block;
    padding: 0.25em;
    text-align: center;
  }
`;

export const CheckAnswerButton = styled.button`
  border: none;
  border-radius: 22px;
  padding: 0.25rem 0.5rem;
  background: #39bee5;
  color: white;
  font-family: 'Linotte', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  margin-top: 1rem;
  :hover {
    background: #00b4e9;
  }
  &:disabled {
    background: #e5e5e5;
    color: #ffffff;
  }
`;

export const Workings = styled.div`
  .title {
    font-family: 'Linotte';
    color: #6ed1e1;
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const Solution = styled(Workings)``;

export const IncorrectAnswerMessage = styled.div`
  margin-top: 1rem;
  margin-right: 1rem;
  background: #ffe8e8;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #e12e2e;
`;

export const VideoTutorial = styled.div`
  display: flex;
  align-items: center;
  color: #393939;
  margin-bottom: 1rem;
  margin-top: -1.5rem;
  font-family: 'Linotte', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-size: 16px;
  p {
    margin-bottom: 0rem;
    margin-left: 0.5rem;
    font-weight: 300;
    cursor: pointer;
  }
  span {
    font-weight: normal;
    color: #393939;
    &:hover {
      text-decoration: underline;
    }
  }
  img {
    width: 40px;
    cursor: pointer;
  }
`;
