import styled from 'styled-components';

export const Container = styled.div`
  min-height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #d6e7ed;
`;

const activeBottomBorderColours = {
  math: '#00b6ff',
  science: '#21DB9D',
};

export const Tab = styled.div`
  font-size: 1rem;
  border-bottom: ${(props) =>
    props.isActive
      ? `solid 2px ${
          activeBottomBorderColours[props.subject] ??
          activeBottomBorderColours.math
        }`
      : 'none'};
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  margin: 0 2rem;
  :hover {
    font-weight: 600;
  }
`;
