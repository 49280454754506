import React from 'react';
import { ThemeProvider } from 'styled-components';
import Bg from 'assets/img/bg.svg';
import BgPurplePremium from 'assets/img/bg-purple-premium.svg';
import BgPurple from 'assets/img/bg-purple.svg';
import BgScience from 'assets/img/bg-science-premium.svg';

export const selectedPlan = 'plus';

// Theme object storing css values for different student plans
export const planThemes = {
  standard: {
    planTextColor: '#63c0f6',
    bgColor: '#1583CB',
    bgPattern: `url(${Bg})`,
    sidePanelButtonBg: '#1581c7',
    sidePanelButtonHoverBg: '#4cabe9',
    kokoMonsterBorderColor: '#0667a7',
  },
  school: {
    planTextColor: '#63c0f6',
    bgColor: '#1583CB',
    bgPattern: `url(${Bg})`,
    sidePanelButtonBg: '#1581c7',
    sidePanelButtonHoverBg: '#4cabe9',
    kokoMonsterBorderColor: '#0667a7',
  },
  plus: {
    planTextColor: '#A7B0FF',
    bgColor: '#6358C5',
    bgPattern: `url(${BgPurple})`,
    sidePanelButtonBg: 'rgba(108, 97, 197, 0.78)',
    sidePanelButtonHoverBg: 'rgba(168, 159, 245, 0.78)',
    kokoMonsterBorderColor: '#5046A1',
  },
  premium: {
    planTextColor: '#A7B0FF',
    bgColor: '#5D47CD',
    bgPattern: `url(${BgPurplePremium})`,
    sidePanelButtonBg: 'rgba(108, 97, 197, 0.78)',
    sidePanelButtonHoverBg: 'rgba(168, 159, 245, 0.78)',
    kokoMonsterBorderColor: '#5046A1',
  },
  science: {
    planTextColor: '#A7B0FF',
    bgColor: '#079976',
    bgPattern: `url(${BgScience})`,
    bgSize: 'cover',
    sidePanelButtonBg: 'rgba(29, 119, 65, 0.2)',
    sidePanelButtonHoverBg: '#a5c9b3',
    kokoMonsterBorderColor: '#167F40',
  },
  parent: {
    planTextColor: '#FFAB64',
  },
};
export const rwd = {
  mobileS: 374,
  mobileM: 424,
  mobileL: 480,
  mobile: 767,
  tablet: 1023,
  laptop: 1439,
  desktop: 2559,
};

// Theme object storing offset for Avatar(KoKoMonster)
export const avatarThemes = {
  horn: {
    topOffset: -18,
  },
  eyes: {
    topOffset: 28,
  },
  mouth: {
    topOffset: 58,
  },
  avatarCreatorHeight: 400,
};

const Theme = ({ children }) => (
  <ThemeProvider theme={{ ...planThemes, ...avatarThemes, rwd }}>
    {children}
  </ThemeProvider>
);
export default Theme;
