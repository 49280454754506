import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import { fetchSuperspeedData, postSuperspeedScore } from 'services/superhero';
import { superSpeedOptions } from 'constants/index';
import { generateQuestion, generateCriteria } from './questionGenerator';

// ASYNC THUNKS
export const getSuperspeedData = createAsyncThunk(
  'superSpeed/getSuperspeedData',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchSuperspeedData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get superspeed data failed');
    }
  }
);

// ASYNC THUNKS
export const submitSuperspeedScore = createAsyncThunk(
  'superSpeed/submitSuperspeedScore',
  async (params) => {
    try {
      const res = await fetchWrapper(postSuperspeedScore, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'submit superspeed score failed');
    }
  }
);

// 2 minutes
const { totalTime, totalTimeEachQuestion } = superSpeedOptions;

const initialState = {
  score: 0,
  isGoingToStart: false,
  isStarted: false,
  startTime: null,
  timeRemaining: 0,
  pausedTime: 0,
  isPaused: false,
  willPaused: false,
  currentQuestionStartTime: null,
  currentQuestion: null,
  currentQuestionTimeRemaining: 0,
  currentAnswer: '',
  isOver: false,
  difficulty: 1,
  level: 1,
  isLoading: false,
  error: false,
  needSubmitScore: false,
  submittingScore: false,
  submitScoreError: false,
};

const superSpeedSlice = createSlice({
  name: 'superSpeed',
  initialState,
  reducers: {
    setDefault: (state) => {
      state.score = 0;
      state.needSubmitScore = false;
      state.isGoingToStart = false;
      state.isStarted = false;
      state.startTime = null;
      state.timeRemaining = 0;
      state.pausedTime = 0;
      state.isPaused = false;
      state.willPaused = false;
      state.currentQuestionStartTime = null;
      state.currentQuestion = null;
      state.currentQuestionTimeRemaining = 0;
      state.currentAnswer = '';
      state.isOver = false;
      state.submittingScore = false;
    },
    initGame: (state) => {
      state.score = 0;
      state.needSubmitScore = false;
      state.isOver = false;
      state.isStarted = true;
      state.timeRemaining = totalTime;
      state.isPaused = false;
      state.willPaused = false;
      state.pausedTime = 0;
      state.currentAnswer = '';
      state.startTime = new Date().toISOString();
    },
    readyStart: (state) => {
      state.isGoingToStart = true;
      state.isOver = false;
      state.score = 0;
      state.needSubmitScore = false;
    },
    setScore: (state, action) => {
      state.score = action.payload;
    },
    setCurrentAnswer: (state, action) => {
      state.currentAnswer = action.payload;
    },
    decrementTime: (state) => {
      const difference = +new Date() - +new Date(state.startTime);
      const remainingSeconds = totalTime - Math.floor(difference / 1000);
      state.timeRemaining = remainingSeconds + state.pausedTime;
    },
    setNewQuestion: (state, action) => {
      state.currentQuestion = action.payload;
      state.currentQuestionTimeRemaining = totalTimeEachQuestion;
      state.currentQuestionStartTime = new Date().toISOString();
    },
    decrementQuestionTime: (state) => {
      if (state.isPaused) {
        state.currentQuestionStartTime = new Date(
          +new Date(state.currentQuestionStartTime) + 1000
        ).toISOString();
      }
      const difference =
        +new Date() - +new Date(state.currentQuestionStartTime);
      const remainingSeconds =
        totalTimeEachQuestion - Math.floor(difference / 1000);
      state.currentQuestionTimeRemaining = remainingSeconds;
    },
    setWillPaused: (state, action) => {
      state.willPaused = action.payload;
    },
    setIsPaused: (state, action) => {
      state.isPaused = action.payload;
    },
    incrementPausedTime: (state) => {
      if (state.isPaused) {
        state.pausedTime += 1;
      }
    },
    setEndGame: (state) => {
      state.isOver = true;
      state.needSubmitScore = true;
      state.isStarted = false;
      state.isGoingToStart = false;
      state.timeRemaining = 0;
      state.isPaused = false;
      state.willPaused = false;
      state.pausedTime = 0;
      state.currentAnswer = '';
    },
    resetNeedSubmitScore(state) {
      state.needSubmitScore = false;
      state.submittingScore = false;
    },
  },
  extraReducers: {
    [getSuperspeedData.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSuperspeedData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.difficulty = action.payload.DifficultyLevel;
      state.level = action.payload.UserLevel;
    },
    [getSuperspeedData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [submitSuperspeedScore.pending]: (state) => {
      state.submittingScore = true;
      state.submitScoreError = false;
    },
    [submitSuperspeedScore.fulfilled]: (state) => {
      state.submittingScore = false;
      state.needSubmitScore = false;
    },
    [submitSuperspeedScore.rejected]: (state) => {
      state.submittingScore = false;
      state.submitScoreError = true;
    },
  },
});

const { reducer, actions } = superSpeedSlice;
export const {
  setEndGame,
  initGame,
  incrementPausedTime,
  decrementTime,
  readyStart,
  setIsPaused,
  setNewQuestion,
  setScore,
  setWillPaused,
  decrementQuestionTime,
  resetNeedSubmitScore,
} = actions;
let pauseInterval;
let timerInterval;
let questionInterval;

export const endGame = createAsyncThunk(
  'superSpeed/endGame',
  async (_, { dispatch }) => {
    dispatch(actions.setEndGame());
    if (timerInterval !== null) {
      clearInterval(timerInterval);
    }
  }
);

// clear all interval
export const dispose = createAsyncThunk(
  'superSpeed/dispose',
  async (_, { dispatch }) => {
    dispatch(actions.setDefault());
    if (timerInterval !== null) {
      clearInterval(timerInterval);
    }
    if (pauseInterval !== null) {
      clearInterval(pauseInterval);
    }
    if (questionInterval !== null) {
      clearInterval(questionInterval);
    }
  }
);

export const pauseGame = createAsyncThunk(
  'superSpeed/pause',
  async (_, { dispatch }) => {
    dispatch(actions.setIsPaused(true));
    dispatch(actions.setWillPaused(false));
    pauseInterval = setInterval(() => {
      dispatch(actions.incrementPausedTime());
    }, 1000);
  }
);

export const nextQuestion = createAsyncThunk(
  'superSpeed/nextQuestion',
  async (_, { dispatch, getState }) => {
    if (questionInterval !== null) {
      clearInterval(questionInterval);
    }
    const { superSpeed } = getState();
    const { difficulty, level, isOver, willPaused: willBePaused } = superSpeed;
    if (willBePaused) {
      dispatch(pauseGame());
      return;
    }
    if (isOver) {
      return;
    }
    const criteria = generateCriteria(difficulty, level);
    const question = generateQuestion(criteria);
    dispatch(actions.setNewQuestion(question));
    questionInterval = setInterval(() => {
      dispatch(actions.decrementQuestionTime());
      const {
        currentQuestionTimeRemaining,
        willPaused,
      } = getState().superSpeed;
      if (currentQuestionTimeRemaining <= 0) {
        if (!willPaused) {
          dispatch(nextQuestion());
        } else {
          dispatch(pauseGame());
        }
      }
    }, 1000);
  }
);

export const startGame = createAsyncThunk(
  'superSpeed/startGame',
  async (_, { dispatch, getState }) => {
    dispatch(actions.initGame());
    dispatch(nextQuestion());
    timerInterval = setInterval(() => {
      dispatch(actions.decrementTime());
      const { timeRemaining } = getState().superSpeed;
      if (timeRemaining <= 0) {
        dispatch(endGame());
      }
    }, 1000);
  }
);

export const resumeGame = createAsyncThunk(
  'superSpeed/resume',
  async (_, { dispatch }) => {
    dispatch(actions.setIsPaused(false));
    dispatch(nextQuestion());
    if (pauseInterval !== null) {
      clearInterval(pauseInterval);
    }
  }
);

export const checkAnswer = createAsyncThunk(
  'superSpeed/checkAnswer',
  async (_, { dispatch, getState }) => {
    const { superSpeed } = getState();
    const { currentAnswer, currentQuestion, score } = superSpeed;
    if (parseInt(currentAnswer) === currentQuestion.answer) {
      dispatch(nextQuestion());
      dispatch(actions.setScore(score + 1));
      setTimeout(() => {
        dispatch(actions.setCurrentAnswer(''));
      }, 100);
    }
  }
);

export const inputAnswer = createAsyncThunk(
  'superSpeed/inputAnswer',
  async (key, { dispatch, getState }) => {
    const { superSpeed } = getState();
    const { isPaused, isStarted, isOver, currentAnswer } = superSpeed;
    if (!isPaused && isStarted && !isOver) {
      switch (key) {
        case 'Backspace':
        case 'delete':
        case 'Delete':
          if (currentAnswer.length > 0) {
            dispatch(
              actions.setCurrentAnswer(
                currentAnswer.substring(0, currentAnswer.length - 1)
              )
            );
          }
          break;
        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
          dispatch(actions.setCurrentAnswer(`${currentAnswer}${key}`));
          break;
        default:
          break;
      }
      dispatch(checkAnswer());
    }
  }
);

export default reducer;
