import React from 'react';
import { StyledContainer } from './SidePanel.styles';
import UserInfo from './UserInfo';
import SubpageNav from './SubpageNav';

const SidePanel = ({ activeView, setActiveView, subject, styles }) => {
  return (
    <StyledContainer subject={subject} data-cy="sidepanel" styles={styles}>
      <UserInfo subject={subject} isAvatarXpClickable />
      <SubpageNav
        activeView={activeView}
        setActiveView={setActiveView}
        subject={subject}
      />
    </StyledContainer>
  );
};

export default SidePanel;
