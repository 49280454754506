import styled from 'styled-components';

export const StyledTitle = styled.div`
  text-align: center;
  padding: 11px 0;
  background: #ffcc00;
  width: 100%;
  margin-top: 59px;
  font-size: 20px;
`;
