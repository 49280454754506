import styled from 'styled-components';

const leftIconPositionStyles = {
  spanPadding: '12px 24px 12px 40px',
  flexDirection: 'row',
  imgLeftPosition: '30px',
  imgRightPosition: 'none',
};

const rightIconPositionStyles = {
  spanPadding: '12px 40px 12px 24px',
  flexDirection: 'row-reverse',
  imgLeftPosition: 'none',
  imgRightPosition: '30px',
};

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: ${(props) =>
    props.iconPosition === 'left'
      ? leftIconPositionStyles.flexDirection
      : rightIconPositionStyles.flexDirection};
  cursor: pointer;
  position: absolute;
  top: ${(props) => props.positionStyles.top};
  right: ${(props) => props.positionStyles.right};
  left: ${(props) => props.positionStyles.left};
  bottom: ${(props) => props.positionStyles.bottom};
  span {
    color: #666666;
    background: white;
    padding: ${(props) =>
      props.iconPosition === 'left'
        ? leftIconPositionStyles.spanPadding
        : rightIconPositionStyles.spanPadding};
    border: solid 1px #eaeaea;
    border-radius: 28px;
    box-shadow: 0 3px 0 rgba(149, 149, 149, 0.16);
    white-space: nowrap;
  }
  img {
    width: 65px;
    height: 65px;
    position: relative;
    left: ${(props) =>
      props.iconPosition === 'left'
        ? leftIconPositionStyles.imgLeftPosition
        : rightIconPositionStyles.imgLeftPosition};
    right: ${(props) =>
      props.iconPosition === 'left'
        ? leftIconPositionStyles.imgRightPosition
        : rightIconPositionStyles.imgRightPosition};
  }

  :hover {
    span {
      color: black;
    }
    img {
      width: 72px;
      height: 72px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    position: relative;
    inset: auto;
    margin-top: 10px;
    span {
      width: 120%;
      margin-left: -30px;
    }
    img {
      inset: auto
    }
`;

export default StyledButton;
