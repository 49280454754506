import React from 'react';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { isNil } from 'ramda';
import useIsDesktop from 'hooks/responsive/useIsDesktop';

import ErrorResponse from 'components/Shared/ErrorResponse';
import ParsedHtml from 'components/Notifications/ParsedHtml';
import { Container } from './BannerNotification.styles';

const BannerNotification = ({ page }) => {
  const { bannerNotif } = useSelector((state) => state.notification);
  const isDesktop = useIsDesktop();

  return (
    <>
      {!isNil(bannerNotif) && isDesktop && (
        <ErrorBoundary FallbackComponent={ErrorResponse}>
          <Container page={page}>
            <ParsedHtml type="banner" />
          </Container>
        </ErrorBoundary>
      )}
    </>
  );
};

export default BannerNotification;
