import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { StyledSpinner, subjectStyles } from './StudentDetails.styles';

const XpDetails = ({ isLoading, error, data, isClickable, subject }) => {
  const history = useHistory();
  const currentExpLevel = data?.EXPData?.CurrentLevel?.ID;
  const navigateToExpPage = () => {
    if (!isClickable) return;
    history.push('/exp-level');
  };

  if (isLoading)
    return (
      <StyledSpinner>
        <Spinner animation="border" />
      </StyledSpinner>
    );
  if (error !== null) return <span>{error}</span>;
  return (
    <div className="xp-row">
      <div className="xp-score">{data?.TotalEXP}</div>
      <div className="xp-text">
        <img width="40px" src={subjectStyles[subject].xpIcon} alt="xp-icon" />
      </div>
      <div className="badge-icon">
        {currentExpLevel && (
          <button
            type="button"
            className="navigate-exp"
            onClick={navigateToExpPage}
          >
            <img
              src={`https://static.content.koobits.com/coreproduct/EXPLevel/small/${currentExpLevel}.png`}
              alt="badge-icon"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default XpDetails;
