import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import kokoMonsterBg from 'assets/img/dashboardv2/koko-monster.png';
import kokoMonsterTablet from 'assets/img/dashboardv2/monsterTablet.png';
import { AvatarRendererFromImage } from 'components/AvatarCreator/AvatarRenderer';
import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import {
  StyledContainer,
  StyledContent,
  StyledKoKoMonster,
  StyledDescription,
} from './KoKoMonsterCard.styles';

const MonsterImage = () => {
  const imgSrc = useResponsiveImage(kokoMonsterBg, kokoMonsterTablet);
  return <img src={imgSrc} alt="kokoMonster" className="monsterImage" />;
};

const KoKoMonsterCard = () => {
  const { plan } = useSelector((state) => state.plan);
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const navigateToAvatarCreator = () => {
    history.push('/dashboard/KoKoMonster');
  };

  return (
    <StyledContainer onClick={navigateToAvatarCreator} data-cy="monster-card">
      <div>
        <MonsterImage />
        <StyledContent>
          <StyledKoKoMonster plan={plan}>
            <AvatarRendererFromImage
              body={studentDetails && studentDetails.KokoMonster.Body}
              eyes={studentDetails && studentDetails.KokoMonster.Eyes}
              horn={studentDetails && studentDetails.KokoMonster.Horn}
              mouth={studentDetails && studentDetails.KokoMonster.Mouth}
            />
            <div className="change">
              <p>{t('kokoMonster.change', 'Change')}</p>
              <p>{t('kokoMonster.monster', 'Monster')}</p>
            </div>
          </StyledKoKoMonster>
          <StyledDescription>
            <h3>{t('kokoMonster.title', 'My KoKo Monster')}</h3>
            <span>{t('kokoMonster.name', 'MONSTER NAME')}</span>
            <p>{studentDetails && studentDetails.KokoMonster.Name}</p>
          </StyledDescription>
        </StyledContent>
      </div>
    </StyledContainer>
  );
};

export default KoKoMonsterCard;
