import styled from 'styled-components';

export const McqOptionsList = styled.div`
  .options-container {
    display: flex;
    align-items: center;
    .option-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: black;
      background-color: transparent;
      border: 1px solid black;
      margin-right: 10px;
    }
  }
`;

export const Container = styled.div`
  .qs-attr {
    padding: 15px 20px 0px;
  }
  .qs-text {
    font-size: 16px;
    padding: 20px;
    color: #343434;
  }
  .fraction-wrap {
    display: inline-block;
    vertical-align: middle;
  }
  .fraction-whole-part {
    display: inline-block;
    vertical-align: baseline;
  }
  .fraction-numerator-denominator-part {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.25em;
  }
  .fraction-numerator {
    display: block;
    border-bottom: 1px solid black;
    padding: 0.25em;
    text-align: center;
  }
  .fraction-denominator {
    display: block;
    padding: 0.25em;
    text-align: center;
  }
`;
