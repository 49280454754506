import styled from 'styled-components';

export const Back = styled.span`
  color: #bcbcbc;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
`;

export const PlanDetailsStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  width: 70%;
  .note {
    font-size: 14px;
  }
  .btnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 10px;
    span {
      color: #8f8f8f;
    }
  }
`;
