import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/boys-vs-girls/boys-vs-girls-bg.svg';

import {
  BoyCardImage,
  GirlCardImage,
  VersusImage,
  HeaderBlindBg,
  BoyBlindCard,
  GirlBlindCard,
} from 'assets/img/leaderboard/boys-vs-girls';
import { Topbar as ChampionClassTopBar } from '../TopBrain/TopBrainHeader.styles';

export const Topbar = styled(ChampionClassTopBar)`
  .scoring-period {
    flex-direction: column;
    span,
    .physical-prize {
      color: #374b09;
      font-size: 16px;
    }
  }
  background-image: ${(props) =>
    props.blindPeriod ? `url(${HeaderBlindBg})` : `url(${HeaderBg})`};
  background-size: cover;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 4%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .score-section {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: row;

    @media (max-width: 768px) {
      margin-top: 500px;
      flex-direction: column;
      height: 800px;
    }
  }
  .blind-details-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    p {
      margin: 0px;
    }
    span {
      color: #ffd800;
    }
  }
`;

export const BoyCard = styled.div`
  background: ${(props) =>
    props.blindPeriod ? `url(${BoyBlindCard})` : `url(${BoyCardImage})`};
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.blindPeriod ? '330px' : '350px')};
  height: ${(props) => (props.blindPeriod ? '200px' : '300px')};
  box-shadow: 0 2px 4px rgba(#000, 0.2);
  justify-content: center;
  margin-top: ${(props) => props.blindPeriod && '4rem'};
  .team-name {
    margin-top: ${(props) => (props.winning === true ? '25px' : '-15px')};
    font-size: 22px;
    font-weight: bold;
    color: #0599ff;
    text-shadow: -2.5px -2.5px 0 #fff, 2.5px -2.5px 0 #fff, -2.5px 2.5px 0 #fff,
      2.5px 2.5px 0 #fff;
    padding-top: ${(props) => (props.blindPeriod ? '1.5rem' : '4.0rem')};
    margin-left: ${(props) => props.blindPeriod && '-4%'};
    z-index: 2;
    span {
      font-size: 37px;
    }
  }
  .card-description {
    padding-top: 0px;
    margin-left: -30px;
    text-align: center;
    h3 {
      font-weight: bold;
      font-size: 34px;
      line-height: 1;
    }
    .total-challenge {
      color: #88bfe5;
      font-weight: 600;
      font-size: 14px;
      margin: 0;
      line-height: 0.5;
    }
    .current-winner {
      color: #ff0000;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .face {
    position: absolute;
    top: ${(props) => (props.blindPeriod ? '12%' : '9%')};
    width: 140px;
    height: 140px;
    margin-left: ${(props) => props.blindPeriod && '-0.5%'};
    margin-top: ${(props) => props.banner && '5rem'};
  }
  .star {
    width: 280px;
    top: 11%;
    height: 140px;
    position: absolute;
    margin-top: ${(props) => props.banner && '5rem'};
  }
  .medal {
    position: absolute;
    margin-left: 7%;
  }
`;

export const GirlCard = styled.div`
  background: ${(props) =>
    props.blindPeriod ? `url(${GirlBlindCard})` : `url(${GirlCardImage})`};
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: ${(props) => (props.blindPeriod ? '190px' : '270px')};
  margin-left: ${(props) => props.blindPeriod && '1rem'};
  box-shadow: 0 2px 4px rgba(#000, 0.2);
  justify-content: center;
  margin-top: ${(props) => props.blindPeriod && '4rem'};
  .team-name {
    margin-top: ${(props) => (props.winning === true ? '25px' : '-15px')};
    font-size: 22px;
    font-weight: bold;
    color: #fe4c63;
    text-shadow: -2px 2px 0 #fff, 2px 2px 0 #fff, 2px -2px 0 #fff,
      -2px -2px 0 #fff;
    padding-top: ${(props) => (props.blindPeriod ? '1.5rem' : '6.5rem')};
    margin-right: ${(props) => (props.blindPeriod ? '0px' : '-50px')};
    margin-left: ${(props) => props.blindPeriod && '-30px'};
    z-index: 2;
    span {
      font-size: 37px;
    }
  }
  .card-description {
    padding-top: 0px;
    margin-right: -50px;
    text-align: center;
    h3 {
      font-weight: bold;
      font-size: 34px;
      line-height: 1;
    }
    .total-challenge {
      color: #88bfe5;
      font-weight: 600;
      font-size: 14px;
      margin: 0;
      line-height: 0.5;
    }
    .current-winner {
      color: #ff0000;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .face {
    position: absolute;
    top: ${(props) => (props.blindPeriod ? '12%' : '9%')};
    margin-left: ${(props) => !props.blindPeriod && '30px'};
    width: 150px;
    height: 150px;
    margin-top: ${(props) => props.banner && '5rem'};
  }
  .star {
    width: 280px;
    top: 17%;
    position: absolute;
    height: 150px;
    margin-top: ${(props) => props.banner && '5rem'};
  }
  .medal {
    position: absolute;
    margin-left: -7%;
    margin-top: 20px;
  }
`;

export const Versus = styled.div`
  background: url(${VersusImage});
  background-size: cover;
  margin-top: 20px;
  width: 120px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;
