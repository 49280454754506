import styled from 'styled-components';

export const CardContainer = styled.div`
  ${'' /* box-shadow: 0px 7px 0px 0px rgba(82, 75, 97, 0.28); */}
  border-radius: 20px;
  flex-basis: ${({ type }) => (type === 'Play' ? '54%' : '44%')};
  cursor: pointer;
  position: relative;
  top: ${({ type }) => (type === 'Play' ? '0' : '0.5rem')};
  :hover {
    transform: scale(1.08);
  }
  @media (max-width: 1150px) {
    top: ${({ type }) => (type === 'Play' ? '0' : '1rem')};
  }
  @media (max-width: 950px) {
    top: ${({ type }) => (type === 'Play' ? '0' : '1.6rem')};
  }
  .overlay-container {
    background: url(${(props) => props.hoverImg});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    width: 100%;
    height: 100%;
    font-weight: 600;
    .overlay-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: ${({ type }) => (type === 'Practice' ? '95%' : '97%')};
      text-align: left;
      .features-list {
        margin-bottom: 0.5rem;
        text-align: center;
        font-size: 18px;
        .features {
          margin-bottom: 0.2rem;
          padding: 0;
        }
      }
      .play {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 1rem 1rem;
        justify-content: space-evenly;
        margin: 0;
        width: 100%;
      }
      .feature-item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      @media (min-width: 1725px) {
        width: 95%;
        height: ${({ type }) => type === 'Play' && '100%'};
      }
      @media (max-width: 1150px) {
        height: 90%;
        .feature-list {
          .features {
            margin-bottom: 0.1rem;
          }
        }
      }
      @media (max-width: 950px) {
        height: 80%;
        line-height: 1;
        .feature-list {
          margin-bottom: 0.1rem;
        }
        .play {
          padding: 0 0.5rem 0.5rem;
        }
        .features {
          margin-bottom: 0;
          font-size: 12px;
        }
      }
    }
  }
  .hover-category,
  .category {
    font-size: 17px;
    color: #ffffff;
    position: relative;
    top: 17%;
    left: 52%;
    font-weight: 700;
    width: fit-content;
    pointer-events: none;
    span {
      font-size: 35px;
      text-shadow: 2px 2px 2px rgba(101, 53, 18, 0.4);
      font-family: 'Linotte-Heavy';
    }
    @media (max-width: 1700px) {
      font-size: 0.8rem;
      span {
        font-size: 1.5rem;
      }
    }
    @media (max-width: 900px) {
      span {
        font-size: 1.2rem;
      }
    }
  }
  .hover-category {
    position: static;
    padding: 0.2rem 2rem;
  }
`;

export const Block = styled.div`
  border-radius: 20px;
  background: url(${(props) => props.img}) no-repeat;
  background-size: contain;
  background-position: left top;
  transform: scale(1.05);
  width: 100%;
  height: 100%;
  visibility: ${(props) => (props.showOverlay ? 'hidden' : 'visible')};
`;
