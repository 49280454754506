import styled from 'styled-components';
import HeaderScienceBg from 'assets/img/dailychallenge/science/science-dc-bg.png';
import HeaderScienceBgDesktop from 'assets/img/dailychallenge/science/dc-science.png';
import HeaderScienceMiko from 'assets/img/dailychallenge/science/science-dc-miko.png';
import { Container } from 'react-bootstrap';

export const StyledPage = styled.div`
  min-height: calc(100vh - 59px);
  background-color: #e6fff4;
  overflow-y: auto;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 120px;
  overflow: hidden;
  background-image: ${`url(${HeaderScienceBg})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    height: 170px;
    background-image: ${`url(${HeaderScienceBgDesktop})`};
  }
  .miko-image {
    z-index: 1;
    width: 120px;
    padding-bottom: 20%;
    position: absolute;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: ${`url(${HeaderScienceMiko})`};
    display: none;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      height: 130px;
      .miko-image {
        width: 130px;
        display: block;
        left: calc(25% - 33px);
        transform: translateX(-50%);
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      height: 170px;
      .miko-image {
        width: 170px;
        left: 50%;
      }
    }
  }
`;

export const HeaderContent = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 2;
  @media (min-width: 1300px) {
    max-width: 1300px;
  }

  .left {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .center {
    max-width: 1350px;
    img {
      max-width: 280px;
    }
  }

  .right {
    display: none;
    @media (max-width: 1024px) {
      right: 2rem;
    }

    .nav-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.875rem;
      color: white;
      margin-left: 1rem;
    }

    img {
      width: 80px;
      height: 80px;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .center {
      img {
        max-width: 320px;
      }
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      justify-content: space-between;
      .left {
        top: calc(5rem - 59px);
        left: 3rem;
      }
      .center {
        img {
          max-width: 1000px;
        }
      }
      .right {
        display: block;
      }
    }
  }
`;

export const CPSection = styled.div`
  width: 100%;
  height: 32px;
  background-color: #c3fbe4;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  .container {
    margin: 0px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      height: 48px;
      height: 65px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      padding-left: 30px;
      padding-right: 30px;
      .container {
        max-width: 1260px;
        margin: auto;
        padding-right: 0px;
        padding-left: 0px;
      }
    }
  }
`;

export const CPContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px;

  &.container {
    max-width: 480px;
    margin: auto;
    padding: 0px;
    @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      padding: 12px 15px;
    }
  }

  .cp-score-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      display: block;
    }
  }

  .cp-desc {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cp-title {
    color: #40865c;
    font-size: 12px;
  }

  .cp-img {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .cp-score {
    font-weight: bold;
    font-size: 16px;
  }

  .cp-total {
    color: #6c877b;
    font-size: 12px;
  }

  .cp-button {
    color: #00934e;
    border: 0px;
    display: none;
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      .cp-button {
        display: block;
      }
      &.container {
        max-width: 750px;
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      .cp-title {
        font-size: 18px;
      }
      .cp-score {
        font-size: 20px;
      }
      .cp-total {
        font-size: 16px;
      }
      &.container {
        max-width: 1300px;
      }
    }
  }
`;

export const CardSection = styled.div`
  width: 100%;
  height: calc(100% - 235px);
  background-color: #e6fff4;
  display: flex;
  justify-content: center;
  overflow-x: auto;
`;

export const CardContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1.5rem 1rem;
  max-width: 1350px;
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      flex-direction: row;
      padding: 3rem 0;
      .container {
        margin: 0px;
      }
    }
  }
  @media (max-height: 733px) {
    padding: 1.5rem 1rem;
  }
  @media (max-height: 660px) {
    padding: 1rem 0.5rem;
  }
`;

export const StyledArrow = styled.div`
  z-index: 2;
  position: fixed;
`;
