import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { isEmpty, isNil } from 'ramda';

import {
  getHallOfFameHeros,
  getHallOfFameLeaderboard,
} from 'store/leaderboard/hodLeaderboardSlice';
import formatChineseNumber from 'helpers/formatChineseNumber';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import Spinner from 'components/Shared/Spinner';
import DropdownMenu from 'components/Shared/DropdownMenu';
import {
  Category,
  HODList,
  NoParticipants,
} from 'pages/Leaderboard/v2/components';

import CalendarIcon from 'assets/img/icon-calendar.svg';
import MathCPIcon from 'assets/img/dailychallenge/icon-cp-shadow.svg';
import ScienceCPIcon from 'assets/img/science-cp-icon.svg';
import { PRIMARY_LEVELS, LEADERBOARD_ID } from '../../../../constant';
import { Container, CardSection, Card } from './HallOfFame.styles';

const HallOfFame = () => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { subject } = useParams();
  const { timezone } = useSelector((state) => state.login);
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const { hofHero, hofData } = useSelector((state) => state.hodV2);
  const { data, isLoading, error } = hofData;
  const { data: heros } = hofHero;

  const currentSelectedDate = moment()
    .tz(timezone)
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  const [selectedDate, setSelectedDate] = useState(currentSelectedDate);
  const [activeCategory, setActiveCategory] = useState(2);
  const [selectedLevel, setSelectedLevel] = useState(defaultLevel);

  const clickHandler = (filter) => {
    setActiveCategory(filter);
  };

  const showLevels = (level) => {
    return `${t('leaderboard:tabs.primary', 'Primary')} ${formatChineseNumber({
      value: level,
      language: i18n.language,
    })}`;
  };

  const levelHandler = (value) => {
    setSelectedLevel(value);
  };

  const levelList = [
    { levelId: 1 },
    { levelId: 2 },
    { levelId: 3 },
    { levelId: 4 },
    { levelId: 5 },
    { levelId: 6 },
  ];

  const parsedHof = levelList.map((level) => {
    const hero = heros?.find((item) => item.levelId === level.levelId) ?? null;
    return {
      ...level,
      hero,
    };
  });

  useEffect(() => {
    const params = {
      id: LEADERBOARD_ID.hod[subject],
      category: activeCategory,
      level: selectedLevel,
      date: selectedDate,
    };
    dispatch(getHallOfFameHeros(params));
    dispatch(getHallOfFameLeaderboard(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, activeCategory, selectedLevel, dispatch]);

  return (
    <Container>
      <div className="banner">
        <div className="hall-of-fame">
          <p className="title">
            {t('leaderboard:buttons.hallOfFame', 'Hall of Fame')}
          </p>
        </div>
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            showDropdowns: true,
            startDate: moment(selectedDate),
          }}
          onApply={(e, picker) =>
            setSelectedDate(moment(picker.startDate).format('YYYY-MM-DD'))
          }
        >
          <div className="calendar">
            {moment(selectedDate).format('DD MMM YYYY')}
            <span>
              <img src={CalendarIcon} alt="icon" />
            </span>
          </div>
        </DateRangePicker>
      </div>
      <CardSection>
        {parsedHof.map((item) => (
          <Card key={item.levelId} levelId={item.levelId}>
            <div className="header">
              {t(
                'leaderboard:buttons.primaryHero',
                { number: item.levelId },
                `Primary ${item.levelId} Hero`
              )}
            </div>
            <div className="content">
              <p className="student-name">{item?.hero?.userName ?? ''}</p>
              <div className="school">
                {!isNil(item?.hero) && (
                  <img
                    className="school-logo"
                    src={`https://static.koobits.com/eduresources/schlogo/${item?.hero?.schoolLogo}.png`}
                    alt="school logo"
                  />
                )}
                <div className="school-details">
                  <p className="school-name">{item?.hero?.schoolName ?? ''}</p>
                  <p>{item?.hero?.className ?? ''}</p>
                </div>
              </div>
            </div>
            <div className="footer">
              <p className="cp">
                <img
                  className="cp-icon"
                  src={subject === 'science' ? ScienceCPIcon : MathCPIcon}
                  alt="cp"
                  width="30px"
                />
                {item?.hero?.cpPoints ?? ''}
              </p>
              <p className="date">
                {!isNil(item?.hero) &&
                  moment
                    .utc(item?.hero?.lastSubmissionDate)
                    .tz(timezone)
                    .format('DD-MMM-YYYY HH:mm')}
              </p>
            </div>
          </Card>
        ))}
      </CardSection>

      <Category
        availableCategory={['class', 'school', 'national']}
        active={activeCategory}
        categoryHandler={clickHandler}
        width="100%"
      >
        <DropdownMenu
          dataCy="dropdown-level"
          selectedValue={showLevels(selectedLevel)}
          values={PRIMARY_LEVELS}
          setValue={levelHandler}
          valueTemplate={showLevels}
          width="150px"
          className="drop-down"
          widthipad="170px"
        />
      </Category>
      {isLoading && <Spinner />}
      {!isLoading && isNil(error) && !isEmpty(data) && (
        <HODList width="100%" data={hofData.data} category={activeCategory} />
      )}
      {!isLoading && isNil(error) && isEmpty(data) && (
        <NoParticipants width="100%" />
      )}
    </Container>
  );
};

export default HallOfFame;
