import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import { isEmpty, isNil } from 'ramda';

import {
  getTopics,
  setSelectedLevel,
  setSelectedTopic,
  getSelfPracticeCurriculum,
  setHotsTopics,
  resetTopics,
  resetSelectedLevel,
  getB2BSelfPracticeCurriculum,
} from 'store/mission/missionSlice';

import SkillsProgress from 'components/Mission/SkillsProgress';
import ProficiencyLegend from 'components/Mission/ProficiencyLegend';
import DropdownMenu from 'components/Shared/DropdownMenu';
import TopicList from 'components/Mission/TopicList';
import ViewToggle from 'components/Mission/ViewToggle';
import ErrorModal from 'components/Shared/ErrorModal';
import SkillsList from 'components/Mission/SkillsList';
import Spinner from 'components/Shared/Spinner';

import FilledStar from 'assets/img/mission/filled-star.png';

import {
  MasteryLevel,
  HighscoreStars,
  NewHOTsCurriculumID,
} from 'constants/mission';
import { featureToggle } from 'constants/index';
import ageGroups from 'constants/ageGroups';
import { subjectNames } from 'constants/products';

import formatChineseNumber from 'helpers/formatChineseNumber';
import useQuery from 'helpers/useQuery';
import getSubjectByCurrId from 'helpers/Mission/getSubjectByCurrId';
import useDefaultAgeGroup from 'hooks/useDefaultAgeGroup';

import {
  Header,
  BackButton,
  Title,
  SkillsSpan,
  Message,
  Main,
  Sidebar,
  Content,
  ContentHeader,
  StyledDropdownIcon,
} from './SelfPractice.styles';
import 'react-modal-video/scss/modal-video.scss';

// Helper
const getDefaultLevelIndex = (
  levels,
  defaultLevel,
  isv2Hots,
  defaultAgeGroupIndex
) => {
  const fallbackLevel = -1;
  if (isNil(levels)) return fallbackLevel;
  if (isv2Hots) {
    return isNil(levels[defaultAgeGroupIndex]) ? -1 : defaultAgeGroupIndex;
  }
  return levels.findIndex((level) => level.ID === defaultLevel);
};

const viewTypes = {
  1: 'proficiency',
  2: 'highscore',
};

const SelfPractice = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const { curriculumId: curriculumIdParams } = useParams();
  const ageIndexParam = query.get('ageIndex');
  const { subject } = useParams();
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const {
    topics,
    topicsLoading,
    topicErr,
    selectedLevel,
    selectedTopic,
    selfPracticeCurriculum,
    hotsTopics,
    hotsDisplayOrder,
    isLoading,
  } = useSelector((state) => state.mission);
  const { products, plan } = useSelector((state) => state.plan);
  const { ageGroupIndex } = useDefaultAgeGroup();
  const { t, i18n } = useTranslation(['selfPractice', 'common']);
  const currentLanguage = i18n.language;
  const curriculumId = parseInt(curriculumIdParams, 10);
  const { state } = useLocation();
  const subjectId =
    state?.subject ?? subjectNames[subject] ?? getSubjectByCurrId(curriculumId);

  // COMPONENT STATES
  const [viewType, setViewType] = useState(2);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const isHotsCurr = curriculumId === 72;
  const isv2Hots = curriculumId === NewHOTsCurriculumID;
  const totalSkillsForLevel =
    topics.length > 0
      ? topics.reduce((prev, curr) => {
          return prev + curr.TotalSkillsCount;
        }, 0)
      : 0;
  const totalAStarSkillsForLevel =
    topics.length > 0
      ? topics.reduce((prev, curr) => {
          return prev + curr.SkillsMastered;
        }, 0)
      : 0;

  const totalAStarSkillsForTopic = !isNil(selectedTopic)
    ? selectedTopic.ViewTopicSkillModels.reduce((prev, curr) => {
        return prev + HighscoreStars(MasteryLevel(curr.MasteryLevel));
      }, 0)
    : 0;
  const levelBasedOnCurr =
    selfPracticeCurriculum &&
    selfPracticeCurriculum
      .filter((curr) => curr.CurriculumID === curriculumId)
      .map((level) => level.Levels);
  const listOfLevels = !isEmpty(levelBasedOnCurr) ? levelBasedOnCurr[0] : [];
  const findCurrentLevel = getDefaultLevelIndex(
    listOfLevels,
    defaultLevel,
    isv2Hots,
    ageIndexParam ? parseInt(ageIndexParam, 10) : ageGroupIndex
  );
  const firstElement = !isEmpty(listOfLevels) ? listOfLevels[0] : null;

  const levelsTemplate = (value) => {
    if (value < 7) {
      // Format numbers for chinese languages
      const formattedValue = formatChineseNumber({
        value,
        language: currentLanguage,
      });
      return `${t(
        `selfPractice:sidebar.primary`,
        'Primary'
      )} ${formattedValue}`;
    }
    const findLevel = listOfLevels.filter((item) => item.ID === value);
    return findLevel[0].Name;
  };
  const ageGroupTemplate = (levelID, index) => {
    const levelIndex = isNil(index)
      ? listOfLevels.findIndex((level) => level.ID === levelID)
      : index;
    if (levelIndex === -1) return levelsTemplate(levelID);
    const ageGroup = ageGroups[levelIndex];
    if (isNil(ageGroup)) return levelsTemplate(levelID);
    return t(
      ageGroup.i18nKey,
      { minAge: ageGroup.minAge, maxAge: ageGroup.maxAge },
      ageGroup.name
    );
  };
  // USEEFFECTS
  useEffect(() => {
    if (!isLoading && !isEmpty(selfPracticeCurriculum)) {
      if (isHotsCurr) {
        dispatch(setSelectedLevel(-1));
      } else if (selectedLevel === null) {
        if (!isEmpty(listOfLevels)) {
          if (subject) {
            if (ageIndexParam) {
              dispatch(
                setSelectedLevel(
                  listOfLevels[ageIndexParam]?.ID || firstElement.ID
                )
              );
              return;
            }
            const queryPrimaryLevel = Number(query.get('primaryLevel'));
            const findPrimaryLevel = listOfLevels.find(
              (level) => level.ID === queryPrimaryLevel
            );
            dispatch(setSelectedLevel(findPrimaryLevel?.ID || firstElement.ID));
            return;
          }
          if (ageIndexParam) {
            dispatch(setSelectedLevel(listOfLevels[findCurrentLevel].ID));
          } else if (findCurrentLevel !== -1) {
            dispatch(setSelectedLevel(defaultLevel));
          } else {
            dispatch(setSelectedLevel(firstElement.ID));
          }
        } else {
          dispatch(setSelectedLevel(-1));
        }
      }
    }
  }, [
    dispatch,
    selectedLevel,
    listOfLevels,
    defaultLevel,
    findCurrentLevel,
    isHotsCurr,
    firstElement,
    isLoading,
    selfPracticeCurriculum,
    ageIndexParam,
    subject,
    query,
  ]);
  const loadData = useCallback(() => {
    if (plan === 'school') {
      dispatch(getB2BSelfPracticeCurriculum());
    } else {
      const params =
        featureToggle.science === true ? { filterSubject: true } : {};
      dispatch(getSelfPracticeCurriculum(params));
    }
  }, [dispatch, plan]);

  useEffect(() => {
    const params = {
      currId: curriculumId,
      levelId: selectedLevel,
    };
    if (subject) {
      const searchTopicId = Number(query.get('topicId'));
      params.searchTopicId = searchTopicId;
    }
    if (selectedLevel !== null) {
      dispatch(getTopics(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedLevel, curriculumId, subject]);
  useEffect(() => {
    if (featureToggle.science !== true) {
      loadData();
    } else if (products !== null) {
      loadData();
    }
  }, [dispatch, isHotsCurr, loadData, products]);

  useEffect(() => {
    if (isHotsCurr) {
      if (topics.length === 12) {
        dispatch(setHotsTopics(topics));
      } else if (topics.length > 12) {
        const slicedTopics = topics && topics.slice(0, 12);
        dispatch(setHotsTopics(slicedTopics));
      } else if (topics.length < 12) {
        const remainingData =
          topics.length > 0 ? Math.floor(12 / topics.length) : 0;
        const dataToSlice = 12 - remainingData * topics.length;
        const getRemainingData = topics.slice(0, dataToSlice);
        const result = Array(remainingData)
          .fill(topics)
          .flat()
          .concat(getRemainingData);
        dispatch(setHotsTopics(result));
      }
    }
  }, [dispatch, isHotsCurr, topics]);
  useEffect(() => {
    if (isHotsCurr && !isNil(hotsTopics) && !isNil(hotsDisplayOrder)) {
      const hotsTopic = hotsTopics.find(
        (topic, index) => index + 1 === hotsDisplayOrder
      );
      if (!isNil(hotsTopic)) {
        dispatch(setSelectedTopic(hotsTopic));
      } else {
        dispatch(setSelectedTopic(hotsTopics[0]));
      }
    } else if (isv2Hots && !isEmpty(topics) && !isNil(hotsDisplayOrder)) {
      const selectedHotsTopic = topics[hotsDisplayOrder - 1];
      dispatch(
        setSelectedTopic(
          isNil(selectedHotsTopic) ? topics[0] : selectedHotsTopic
        )
      );
    }
  }, [dispatch, hotsTopics, topics, hotsDisplayOrder, isHotsCurr, isv2Hots]);

  useEffect(() => {
    return () => {
      dispatch(resetTopics());
    };
  }, [dispatch]);

  // EVENT HANDLERS
  const changeLevel = (level) => {
    dispatch(setSelectedLevel(level.ID));
  };
  const changeTopic = (id) => {
    // Find topic by ID
    const newTopic = topics.find((topic) => topic.TopicId === id);
    const newHotsTopic = hotsTopics.find((topic) => topic.TopicId === id);
    dispatch(setSelectedTopic(isHotsCurr ? newHotsTopic : newTopic));
  };

  const reloadHandler = () => {
    const params = {
      currId: curriculumId,
      levelId: selectedLevel,
    };
    loadData();
    dispatch(getTopics(params));
  };

  const showContent = isHotsCurr
    ? !topicsLoading && topicErr === null && selectedTopic !== null
    : !topicsLoading &&
      topicErr === null &&
      selectedTopic !== null &&
      !isEmpty(levelBasedOnCurr);

  const showLoading = isHotsCurr
    ? topicsLoading
    : topicsLoading && isEmpty(levelBasedOnCurr);

  const showError = !topicsLoading && topicErr !== null;
  return (
    <>
      <ModalVideo
        channel="youtube"
        isOpen={showVideoModal}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
        }}
        videoId={selectedVideo}
        onClose={() => setShowVideoModal(false)}
      />
      <Header isHotsCurr={isHotsCurr || isv2Hots}>
        {!topicsLoading && (
          <BackButton
            data-cy="back-btn"
            isHotsCurr={isHotsCurr || isv2Hots}
            viewType={viewTypes[viewType]}
            onClick={() => {
              if (isHotsCurr || isv2Hots) {
                history.push({
                  pathname: `/mission/hots/${curriculumId}`,
                  state: { subject: subjectId },
                });
                dispatch(resetSelectedLevel());
              } else {
                history.push('/mission/curriculum-list');
              }
            }}
          >
            {t('selfPractice:header.back', 'Back')}
          </BackButton>
        )}
        {!topicsLoading &&
          topicErr === null &&
          selectedTopic !== null &&
          viewTypes[viewType] === 'highscore' &&
          (totalAStarSkillsForLevel > 0 ? (
            <SkillsProgress
              numOfAStars={totalAStarSkillsForLevel}
              totalSkills={totalSkillsForLevel}
              bgColour={isHotsCurr || isv2Hots ? '#1471c7' : '#8294ff'}
            />
          ) : (
            <Message>
              <Title type="highscore">
                {t(
                  'selfPractice:header.clickPracticeBtn',
                  'Click Practice Button to Start!'
                )}
              </Title>
              <SkillsSpan>
                {t(
                  'selfPractice:header.totalSkill',
                  { number: totalSkillsForLevel },
                  `Total ${totalSkillsForLevel} skills`
                )}
              </SkillsSpan>
            </Message>
          ))}
        {viewTypes[viewType] === 'proficiency' && (
          <div style={{ textAlign: 'center' }}>
            <Title type="proficiency">
              {t(
                'selfPractice:header.mySkillProficiency',
                'My Skill Proficiency'
              )}
            </Title>
            <ProficiencyLegend />
          </div>
        )}
      </Header>
      <Main>
        {showLoading && <Spinner />}
        {showError && (
          <ErrorModal reloadAction={reloadHandler} errorMessage={topicErr} />
        )}
        {showContent && (
          <>
            <Sidebar>
              {selectedLevel !== -1 && (
                <DropdownMenu
                  selectedValue={
                    isv2Hots
                      ? ageGroupTemplate(selectedLevel)
                      : levelsTemplate(selectedLevel)
                  }
                  valueKey="ID"
                  values={listOfLevels}
                  setValue={changeLevel}
                  width="250px"
                  className="drop-down"
                  widthipad="170px"
                  valueTemplate={isv2Hots ? ageGroupTemplate : levelsTemplate}
                  feature="mission"
                  toggleIcon={
                    <StyledDropdownIcon>
                      {t('selfPractice:sidebar.changeLevel', 'Change Level')}
                    </StyledDropdownIcon>
                  }
                />
              )}
              <TopicList
                data={topics}
                selectedTopic={selectedTopic}
                changeTopic={changeTopic}
              />
            </Sidebar>
            <Content>
              {selectedTopic && (
                <>
                  <ContentHeader viewType={viewTypes[viewType]}>
                    {viewTypes[viewType] === 'highscore' && (
                      <div className="num-a-star-skills">
                        <img
                          src={FilledStar}
                          alt="A-Star"
                          className="star-icon"
                        />
                        <span>{totalAStarSkillsForTopic}</span>
                        <span className="total-skills">{` / ${
                          selectedTopic.ViewTopicSkillModels.length * 3
                        }`}</span>
                      </div>
                    )}
                    {selectedTopic !== null && (
                      <span className="topic-title">
                        {selectedTopic.TopicName}
                      </span>
                    )}
                    <ViewToggle viewType={viewType} setViewType={setViewType} />
                  </ContentHeader>
                  <SkillsList
                    data={selectedTopic.ViewTopicSkillModels}
                    type={viewTypes[viewType]}
                    setSelectedVideo={setSelectedVideo}
                    setShowVideoModal={setShowVideoModal}
                    curriculumId={curriculumId}
                    subject={subjectId}
                  />
                </>
              )}
            </Content>
          </>
        )}
      </Main>
    </>
  );
};

export default SelfPractice;
