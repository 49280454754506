import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import moment from 'moment';

import { getTerms, getMonths } from 'store/leaderboard/leaderboardSlice';
import {
  setCCType,
  getChampionClass,
  getChampionClassStudents,
  resetStudents,
} from 'store/leaderboard/championClassLeaderboardSlice';

import useGlobalFeature from 'hooks/useGlobalFeature';

import DropdownMenu from 'components/Shared/DropdownMenu';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import formatChineseNumber from 'helpers/formatChineseNumber';
import { Category, NoParticipants, ChampionClassList } from '../../components';
import { HeaderMathClassicCC, HeaderMathTermCC } from './components';

import { isLeaderboardFeatureOpen } from '../../helper';
import { PRIMARY_LEVELS, LEADERBOARD_ID, PERIOD_TYPE } from '../../constant';

import { Container } from './ChampionClass.styles';

const ChampionClassMath = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation(['leaderboard']);
  const { defaultLevel, country } = useSelector(
    (state) => state.studentDetails
  );
  const { type, championClass } = useSelector((state) => state.championClassV2);
  const { data, isLoading, error } = championClass;
  const { data: months } = useSelector((state) => state.leaderboard.months);

  // states
  const [activeCategory, setActiveCategory] = useState(2);
  const [currentTerm, setCurrentTerm] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(defaultLevel);
  const [showAllStudents, setShowAllStudents] = useState({});
  const findCurrentPeriod = months.find(
    (term) =>
      moment().isSameOrAfter(term.startDate) &&
      moment().isSameOrBefore(term.endDate)
  );

  const { featuresHashmap } = useGlobalFeature(null, [
    'championclass-leaderboard-termly',
    'championclass-leaderboard-classic',
  ]);
  const isTermlyOpen = isLeaderboardFeatureOpen(
    featuresHashmap['championclass-leaderboard-termly']
  );
  const isClassicOpen = isLeaderboardFeatureOpen(
    featuresHashmap['championclass-leaderboard-classic']
  );

  const singaporeSchool = country === 'Singapore';
  const periodType =
    type === 'classic' ? PERIOD_TYPE.monthly : PERIOD_TYPE.term;
  const periodId = type === 'classic' ? currentPeriod?.id : currentTerm?.id;

  const showLevels = (level) => {
    return `${t('leaderboard:tabs.primary', 'Primary')} ${formatChineseNumber({
      value: level,
      language: i18n.language,
    })}`;
  };

  const levelHandler = (value) => {
    setSelectedLevel(value);
    if (value !== defaultLevel) {
      setActiveCategory(2);
    }
  };

  const categoryHandler = (filter) => {
    setActiveCategory(filter);
  };

  const typeToggleHandler = (value) => {
    if (value === 'term') {
      if (!isTermlyOpen) {
        window.open(
          'https://support.koobits.com/hc/en-gb/articles/4430653191449'
        );
        return;
      }
    }
    if (value === 'classic') {
      if (!isClassicOpen) {
        window.open(
          'https://support.koobits.com/hc/en-gb/articles/4430653191449'
        );
        return;
      }
    }
    dispatch(setCCType(value));
    setActiveCategory(2);
    setShowAllStudents({});
  };

  const checkAvailableCategory = () => {
    const categories =
      type === 'term'
        ? ['class', 'school', 'national']
        : ['class', 'school', 'national', 'global'];

    if (selectedLevel !== defaultLevel) {
      return categories.slice(1);
    }

    return categories;
  };

  const getStudents = (classId) => {
    dispatch(
      getChampionClassStudents({
        id: LEADERBOARD_ID.championClass.math,
        classId,
        periodId,
        periodType,
      })
    );
  };

  const toggleHandler = (index, classId) => {
    const tempData = { ...showAllStudents };
    tempData[index] = !tempData[index];
    setShowAllStudents(tempData);
    getStudents(classId);
  };

  const getChampionClassData = () => {
    const params = {
      id: LEADERBOARD_ID.championClass.math,
      category: activeCategory,
      level: selectedLevel,
      periodType,
      periodId,
    };
    dispatch(getChampionClass(params));
    dispatch(resetStudents());
    setShowAllStudents({});
  };

  // switch to classic if non Sing school
  useEffect(() => {
    if (!singaporeSchool) {
      if (!isClassicOpen) {
        history.replace('/maintenance-redirect');
        return;
      }
      dispatch(setCCType('classic'));
      setActiveCategory(3);
    } else if (!isTermlyOpen && isClassicOpen) {
      dispatch(setCCType('classic'));
      setActiveCategory(3);
    } else if (!isClassicOpen && !isTermlyOpen) {
      history.replace('/maintenance-redirect');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isClassicOpen, isTermlyOpen, singaporeSchool]);

  useEffect(() => {
    if (type === 'classic') {
      dispatch(getMonths());
    } else {
      dispatch(getTerms());
    }
  }, [dispatch, type]);

  useEffect(() => {
    setCurrentPeriod(findCurrentPeriod);
  }, [findCurrentPeriod]);

  useEffect(() => {
    if (isNil(selectedLevel)) {
      setSelectedLevel(defaultLevel);
    }
  }, [defaultLevel, selectedLevel]);

  useEffect(() => {
    const periodAvailable =
      type === 'classic' ? !isNil(currentPeriod) : !isNil(currentTerm);
    if (!isNil(selectedLevel) && periodAvailable) {
      getChampionClassData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel, activeCategory, currentTerm, type, currentPeriod]);

  return (
    <Container>
      {type === 'term' ? (
        <HeaderMathTermCC
          currentTerm={currentTerm}
          setCurrentTerm={setCurrentTerm}
          toggleHandler={typeToggleHandler}
        />
      ) : (
        <HeaderMathClassicCC
          activeCategory={activeCategory}
          singaporeSchool={singaporeSchool}
          currentPeriod={currentPeriod}
          setCurrentPeriod={setCurrentPeriod}
          toggleHandler={typeToggleHandler}
        />
      )}
      <Category
        availableCategory={checkAvailableCategory()}
        active={activeCategory}
        categoryHandler={categoryHandler}
      >
        <DropdownMenu
          dataCy="dropdown-level"
          selectedValue={showLevels(selectedLevel)}
          values={PRIMARY_LEVELS}
          setValue={levelHandler}
          valueTemplate={showLevels}
          width="150px"
          className="drop-down"
          widthipad="170px"
        />
      </Category>
      {isLoading && <Spinner />}
      {!isLoading && !isEmpty(data) && isNil(error) && (
        <ChampionClassList
          data={data}
          category={activeCategory}
          toggleHandler={toggleHandler}
          showStudents={showAllStudents}
          currentTerm={currentTerm}
          currentPeriod={currentPeriod}
          getStudents={getStudents}
        />
      )}
      {!isLoading && isEmpty(data) && isNil(error) && <NoParticipants />}
      {!isLoading && !isNil(error) && (
        <ErrorModal errorMessage={error} reloadAction={getChampionClassData} />
      )}
    </Container>
  );
};

export default ChampionClassMath;
