import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import CongratsText from 'components/Assignment/AssignmentFinish/CongratsText';
import SubmitButton from 'components/Assignment/AssignmentFinish/SubmitButton';
import { kooQuizPaperTypeInternal } from 'constants/kooQuiz';

import { Container } from './SubmittedContent.styles';

const ResultRelease = ({
  score,
  correct,
  totalQn,
  subject,
  subId,
  kooQuizId,
  hasPrint,
}) => {
  const { t } = useTranslation(['kooQuizSubmitted']);
  const history = useHistory();
  const { revisionGift, paperById } = useSelector((state) => state.kooQuiz);
  const { rewards } = useSelector((state) => state.rewards);
  const currentKoko = rewards && rewards.TotalKoko;
  const reward = revisionGift && revisionGift.KoKoCredits;
  const paperVal = kooQuizPaperTypeInternal[paperById?.Type];
  const scoreBoardColor = (score) => {
    if (score <= 59) {
      return 'red';
    }
    if (score <= 79) {
      return 'orange';
    }
    if (score <= 100) {
      return 'green';
    }
  };

  return (
    <Container color={scoreBoardColor(score)}>
      <div className="top-section">
        <CongratsText />
        <p>
          {t('kooQuizSubmitted:completed', 'You have completed all questions')}
        </p>
      </div>
      <div className="bottom-section">
        <div className="score-info">
          <p className="title">{t('kooQuizSubmitted:score', 'Your score:')}</p>
          <p className="score">{`${score}%`}</p>
          <p className="score-details">
            (<span>{correct}</span>/{totalQn})
          </p>
        </div>
        <div className="rewards">
          {revisionGift && (
            <>
              <div className="gift-box" />
              <div>
                <p className="awarded-koko">
                  <Trans i18nKey="kooQuizSubmitted:awardedKoko" reward={reward}>
                    <span>You have been awarded with</span> {{ reward }} KoKo
                    Credits
                  </Trans>
                </p>
                <p className="total-koko">
                  {t(
                    'kooQuizSubmitted:totalKoko',
                    {
                      currentKoko,
                      reward: revisionGift.KoKoCredits,
                      total: currentKoko + revisionGift.KoKoCredits,
                    },
                    `Your total KoKo Credits is: ${currentKoko} + ${
                      revisionGift.KoKoCredits
                    } = ${currentKoko + revisionGift.KoKoCredits} KoKo Credits`
                  )}
                </p>
              </div>
              <div className="koko-credit" />
            </>
          )}
          {revisionGift === null && (
            <p className="total-koko">
              {t(
                'kooQuizSubmitted:noReward',
                'You have claimed the KoKo Gift Box for this week. There is no reward for this paper.'
              )}
            </p>
          )}
        </div>
        <div className="btn">
          <SubmitButton
            width="35%"
            fontSize="26px"
            onClick={() =>
              history.push(
                `/kooQuiz/solution/${subject}/${paperVal}/${kooQuizId}/${subId}?print=${hasPrint}`
              )
            }
          >
            {revisionGift === null
              ? t('kooQuizSubmitted:awesome', 'Awesome')
              : t('kooQuizSubmitted:ok', 'OK')}
          </SubmitButton>
        </div>
      </div>
    </Container>
  );
};

export default ResultRelease;
