import MCQCorrectIcon from 'assets/img/question/mcqCorrect.svg';
import MCQWrongIcon from 'assets/img/question/mcqWrong.svg';

export const iconHashMap = {
  'correct-input': {
    src: MCQCorrectIcon,
    alt: 'Correct',
  },
  'wrong-input': {
    src: MCQWrongIcon,
    alt: 'Incorrect',
  },
};

export const WARP_PADDING = 48;
