import handleResponse from 'helpers/handleAPIResponse';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const getUserInfoByIds = ({ userIDs, pageIndex = 0, pageSize = 5 }) => {
  if (!userIDs || userIDs?.length <= 0) throw new Error('User IDs undefined');
  return fetch(
    `${baseUrl}Search/Users/Info/Ids?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(userIDs),
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get user info by ids failed');
    });
};

// Role IDs
// 1 [PS_Student]
// 2 [PS_Teacher]
export const getRandomOpponentsByRoleID = ({
  roleID = 1,
  pageIndex = 0,
  pageSize = 5,
}) => {
  if (!roleID) throw new Error('Role ID undefined');
  return fetch(
    `${baseUrl}Search/Users/Random/Role/${roleID}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get random opponents failed');
    });
};

export const getClassmateSearchResults = ({
  groupID,
  subjectID,
  searchString = '',
  pageIndex = 0,
  pageSize = 10,
}) => {
  if (!groupID) throw new Error('Undefined group ID');
  return fetch(
    `${baseUrl}Search/User/${groupID}/Subject/${subjectID}/Name/${searchString}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get classmate search results failed');
    });
};

export const getFriendSearchResults = ({
  searchString = '',
  pageIndex = 0,
  pageSize = 10,
}) => {
  return fetch(
    `${baseUrl}Search/Users/BFF/name/${searchString}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get friend search results failed');
    });
};

export const getSameLevelClassesSearchResults = ({
  userID,
  searchString = '',
  pageIndex = 0,
  pageSize = 10,
}) => {
  if (!userID) throw new Error('User ID undefined');
  return fetch(
    `${baseUrl}Search/Level/${userID}/Classes/Name/${searchString}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get friend search results failed');
    });
};
