import styled from 'styled-components';

export const Container = styled.div`
  margin: 25px 0px;
  h2 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid rgb(112, 112, 112, 0.16);
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  .container {
    background: #f9f9f9;
    padding: 0 20px;
    color: #aeaeae;
  }

  @media (max-width: 400px) {
    .container {
      padding: 0 0;
    }
  }
`;

export const Text = styled.span`
  font-size: 16px;
`;

export const Month = styled.span`
  font-size: 22px;
`;

export const NoAssignment = styled.span`
  font-weight: 300;
  font-size: 18px;

  @media (max-width: 400px) {
    font-size: 16px;
  }

  @media (max-width: 350px) {
    font-size: 14px;
  }
`;
