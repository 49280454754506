import styled from 'styled-components';

export const OpenedKoKoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    z-index: 1;
  }
  img {
    position: relative;
    bottom: 4rem;
    width: 200px;
    height: 145px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    p {
      font-size: 14px;
    }
    img {
      bottom: 1rem;
      width: 100px;
      height: 73px;
    }
  }
`;
