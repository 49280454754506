import handleResponse from 'helpers/handleAPIResponse';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchBadgesByMonthYear = (params) => {
  const { month, year } = params;
  return fetch(`${baseUrl}Calendar/GetBadges/${year}/${month}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return {
          todayCPEarned: result.Result.TodaysCP,
          badges: result.Result.StickerList,
        };
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get Badges by year and month failed'); // eslint-disable-line
    });
};

export const fetchUnseenBadges = () => {
  return fetch(`${baseUrl}Calendar/GetUnseenBadges`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result.StickerList;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get unseen badges failed'); // eslint-disable-line
    });
};

// date in MM-DD-YYYY
export const postUnlockBadge = (params) => {
  const { year, month, day } = params;
  return fetch(`${baseUrl}Calendar/UnlockBadge/${year}/${month}/${day}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        if (!result.Result) {
          throw new Error('Post unlock badge failed');
        }
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Post unlock badge failed'); // eslint-disable-line
    });
};

// date in MM-DD-YYYY
export const postMarkBadgeAsSeen = (params) => {
  const { year, month, day } = params;
  return fetch(`${baseUrl}Calendar/MarkBadgeAsSeen/${year}/${month}/${day}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        if (!result.Result) {
          throw new Error('Post mark badge as seen failed');
        }
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Post mark badge as seen failed'); // eslint-disable-line
    });
};
