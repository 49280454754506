import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 !important;
    width: 100%;
  }
  label {
    font-size: 14px;
    color: #838383;
    font-weight: bold;
    text-align: left;
  }
  input {
    border-radius: 3px;
  }
  .terms-note {
    display: flex;
    font-size: 12px;
    text-align: left;
    justify-content: center;
    align-items: center;
    /* max-width: 336px; */
    margin-bottom: 0.5rem;
    p {
      margin-left: 8px;
    }
  }
  .card-row {
    display: flex;
    justify-content: center;
  }
  .StripeElement {
    border: 1px solid #dddddd;
  }
  .full {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .half {
    width: 50%;
    border: 1px solid #dddddd;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .left {
    border-bottom-right-radius: 0px;
  }
  .right {
    border-bottom-left-radius: 0px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  input {
    width: 100%;
    border: 1px solid #d5d5d5;
    outline: none;
    height: 34px;
    padding: 10px;
    font-size: 14px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
  }
`;
export default Container;
