import styled from 'styled-components';

export const TabContainer = styled.div`
  background-color: #c1daff;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 59px;
`;

export const TabStyle = styled.div`
  cursor: pointer;
  padding: 28px 0 24px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active ? '#000000' : '#5176AD')};
  border-bottom: 4px solid ${(props) => (props.active ? '#ffba00' : '#c1daff')};
`;
export const TabValueContainer = styled.div`
  padding-top: 120px;
`;
