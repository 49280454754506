import styled from 'styled-components';

export const Container = styled.div`
  p {
    margin-bottom: 0px;
  }

  & .book-section:last-of-type {
    margin-bottom: 0px;
  }
`;

export const StyledSpinnerWarper = styled.div`
  margin-top: 140px;
`;
