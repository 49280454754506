import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  padding-top: 146px;
  img {
    margin-right: 5px;
  }
  span {
    color: #ff8001;
  }
`;
