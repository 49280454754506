import React from 'react';

import { AUTO_RENEW_REASONS, FAQ } from './constants';
import { MikoFaq } from './images';

import {
  Container,
  ReasonContainer,
  FaqContainer,
} from './AutoRenewFAQ.styles';

const AutoRenewFAQ = () => {
  return (
    <Container>
      <ReasonContainer>
        <p className="reason-title">Why turn on Auto-Renew?</p>
        <div className="reason-content">
          <div className="reason-container">
            {AUTO_RENEW_REASONS.map((item) => (
              <div key={item.id} className="reason-item">
                <img
                  src={item.icon}
                  alt={item.id}
                  className={`item-icon ${item.id === 1 && 'first'}`}
                />
                <div className="reason">
                  <span className="bold">{item.title}: </span>
                  {item.desc}
                </div>
              </div>
            ))}
          </div>
          <img src={MikoFaq} alt="miko" className="miko" />
        </div>
      </ReasonContainer>
      <FaqContainer>
        <p className="faq-title">FAQ</p>
        {FAQ.map((faq) => (
          <div key={faq.id} className="faq-item">
            <p className="bold">{faq.qn}</p>
            <p>{faq.answer}</p>
          </div>
        ))}
      </FaqContainer>
    </Container>
  );
};

export default AutoRenewFAQ;
