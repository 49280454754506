import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAvatarShowcase, fetchLevels } from 'services/avatarShowcase';
import orderBy from 'lodash/orderBy';
import { fetchWrapper } from 'services/login';

const initialState = {
  avatars: [],
  levels: [],
  rankBy: 'country',
  level: null,
  loadingAvatars: false,
  errorAvatars: null,
  loadingLevels: false,
  errorLevels: null,
};

export const getShowcase = createAsyncThunk(
  'avatar/getShowcase',
  async (_, { getState }) => {
    try {
      const { avatarShowcase } = getState();
      const { rankBy, level } = avatarShowcase;
      const res = await fetchWrapper(fetchAvatarShowcase, {
        type: rankBy,
        level,
      });
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get avatar showcase failed');
    }
  }
);

export const getLevels = createAsyncThunk('avatar/getLevels', async () => {
  try {
    const res = await fetchWrapper(fetchLevels);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Get level failed');
  }
});

const avatarShowcase = createSlice({
  name: 'avatarCreator',
  initialState,
  reducers: {
    setRankBy: (state, action) => {
      state.rankBy = action.payload;
    },
    setLevel: (state, action) => {
      state.level = action.payload;
    },
  },
  extraReducers: {
    [getShowcase.pending]: (state) => {
      state.loadingAvatars = true;
      state.errorAvatars = null;
    },
    [getShowcase.fulfilled]: (state, action) => {
      if (action.payload != null && action.payload.Result != null) {
        state.avatars = orderBy(
          action.payload.Result,
          ['TodayCP', 'LastCPDate'],
          ['desc', 'asc']
        );
        state.loadingAvatars = false;
      } else {
        state.avatars = [];
      }
    },
    [getShowcase.rejected]: (state, action) => {
      state.loadingAvatars = false;
      state.errorAvatars = action.error.message;
      state.avatars = [];
    },
    [getLevels.pending]: (state) => {
      state.loadingLevels = true;
      state.errorLevels = null;
    },
    [getLevels.fulfilled]: (state, action) => {
      if (action.payload != null) {
        state.levels = action.payload.Result;
        state.loadingLevels = false;
      }
    },
    [getLevels.rejected]: (state, action) => {
      state.loadingLevels = false;
      state.errorLevels = action.error.message;
      if (!alert(action.error.message)) {
        window.location.reload();
      }
    },
  },
});

const { actions, reducer } = avatarShowcase;
export const { setLevel, setRankBy } = actions;
export default reducer;
