import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { lionCityIdHashmap } from 'constants/leaderboard';

import { Row, Col } from 'react-bootstrap';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';

import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';
import showPosition from 'components/Leaderboard/showPosition';

import { Ranking, StyledMain } from './LionCityCurrentUser.styles';

const LionCityCurrentUser = ({ currentUserScore }) => {
  const { t } = useTranslation();

  const currentOccupation = lionCityIdHashmap[currentUserScore.OccupationId];

  return (
    <StyledMain>
      <Row>
        <Col
          style={{
            marginTop: 20,
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          <h4
            className="your-rank"
            style={{
              textAlign: 'center',
              marginTop: 10,
              marginBottom: 10,
              fontWeight: 600,
            }}
          >
            {t(
              'leaderboard:lionCity.yourRank',
              {
                occupation: t(
                  `leaderboard:lionCity.${currentOccupation}.title`,
                  '-'
                ),
              },
              `Your Rank in -`
            )}
          </h4>
          {currentUserScore && (
            <div
              style={{
                backgroundColor: '#FAFEFF',
                marginTop: 10,
                marginBottom: 20,
                padding: 10,
                borderRadius: 20,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1, color: '#0AB6DC' }}>
                  <div
                    className="ranking"
                    style={{ marginTop: 15, marginLeft: -25 }}
                  >
                    <Ranking currPos={showPosition(currentUserScore.Rank)}>
                      {currentUserScore.Rank !== -1
                        ? currentUserScore.Rank
                        : '-'}
                    </Ranking>
                  </div>
                </div>
                <div style={{ flex: 6 }}>{currentUserScore.Name}</div>
                <div style={{ flex: 1 }}>
                  <img src={CPIcon} alt="cp-icon" />
                  {currentUserScore.TotalPoints}
                </div>
                <div style={{ flex: 1 }}>
                  {currentUserScore.IsSuperHero && <SuperheroIconModal />}
                </div>
                <div style={{ flex: 1 }} />
                <div style={{ flex: 3 }}>
                  {moment(currentUserScore.LastSubmissionDate).format(
                    'DD-MMM-YYYY HH:mm'
                  )}
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </StyledMain>
  );
};

export default LionCityCurrentUser;
