/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { paymentRenewUrl } from 'helpers/url/paymentRenewUrl';
import { GAEvent } from 'helpers/googleAnalytics';

import { BUNDLE_TYPE_ID } from 'constants/parentPortal';

import Button from 'components/Shared/Button';
import PopupModal from 'components/Shared/PopupModal';
import ModalContentXButton from 'components/Shared/ModalContentXButton';

import { ReactComponent as CC } from 'assets/img/parent-portal/icon-credit-card.svg';
import { ReactComponent as PP } from 'assets/img/parent-portal/icon-pp.svg';
import { ReactComponent as BestPriceIcon } from 'assets/img/parent-portal/icon-best-price-3.svg';
import { ReactComponent as BestPriceIcon2 } from 'assets/img/parent-portal/icon-best-price-2.svg';

import SubscriptionModal from '../SubscriptionModal';
import AutoRenewCancelModal from '../AutoRenewCancelModal';

import {
  Container,
  StatusBadge,
  TopSection,
  BottomSection,
  BestPriceBadge,
  StyledPopover,
  BundleIcon,
  TurnOnAutoRenewStyled,
} from './SubscriptionPlanRowV3.styles';

const SubscriptionPlanRowV3 = ({
  status,
  desc,
  trialDesc,
  subsTrialType,
  expiredDate,
  paymentMethodText,
  paymentMethodType,
  bundleName,
  childId,
  paymentSubsId,
  campaign,
  isBundled,
  position,
  bundleTypeId,
  discount,
  isB2B,
  subsLength,
  isSmartRetry,
}) => {
  const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });
  const isMobile = useMediaQuery({ query: `(max-width: 610px)` });
  // Component States
  const [showCancelModal, setCancelShowModal] = useState(false);
  const [proceedCancelModal, setProceedCancelModal] = useState(false);
  const [autoRenewCancelModal, setAutoRenewCancelModal] = useState(false);
  const bestPriceRef = useRef(null);

  const renewHandler = (type) => {
    if (type === 'turn-on-renew') {
      GAEvent({
        category:
          process.env.REACT_APP_DEV === 'true'
            ? '[Dev] Parent Portal'
            : 'Parent Portal',
        action: 'Click Turn On Auto Renew Button',
        label: 'turn_on_autorenew',
      });
    }
    window.location.href = paymentRenewUrl({
      type,
      userId: childId,
      bundleTypeId,
      campaign,
    });
  };

  const renewButton = () => {
    if (status === 'expired') {
      return (
        <Button
          borderRadius="2px"
          variant="orange3"
          width={isMobile ? '100%' : '142px'}
          fontSize="16px"
          onClick={() => renewHandler('renew')}
        >
          Buy Now{' '}
          {subsTrialType !== 'otpTrial' && (
            <span className="disc">{discount}% OFF</span>
          )}
        </Button>
      );
    }
    if (
      (status === 'recurring' && subsTrialType !== 'ccTrial') ||
      isSmartRetry
    ) {
      return (
        <Button
          borderRadius="2px"
          variant="orange3"
          width={isMobile ? '100%' : '142px'}
          fontSize="16px"
          onClick={() => renewHandler('early-renew')}
        >
          Renew Now
        </Button>
      );
    }
    if (status === 'auto-renew-off') {
      return (
        <Button
          borderRadius="2px"
          variant="orange3"
          width={isMobile ? '100%' : '142px'}
          fontSize="16px"
          onClick={() => {
            if (subsTrialType === 'ccTrial') {
              renewHandler('renew');
            } else {
              renewHandler('early-renew');
            }
          }}
        >
          {subsTrialType === 'ccTrial' ? 'Buy Now' : 'Renew Now'}
          <span className="disc">
            {isB2B && BUNDLE_TYPE_ID[bundleTypeId] !== 'science' && (
              <span className="b2b-label">Partner School</span>
            )}
            <span className="discount-number">{discount}% OFF</span>
          </span>
        </Button>
      );
    }
    if (status === 'one-time-payment') {
      return (
        <div className="otp-trial">
          {subsTrialType === 'otpTrial' && !isMobile && (
            <p>Under 7-Day Free Trial</p>
          )}
          <Button
            borderRadius="2px"
            variant="orange3"
            width={isMobile ? '100%' : '142px'}
            fontSize="16px"
            onClick={() => {
              if (subsTrialType === 'otpTrial') {
                renewHandler('renew');
              } else {
                renewHandler('early-renew');
              }
            }}
          >
            {subsTrialType === 'otpTrial' ? 'Buy Now' : 'Renew Now'}
          </Button>
        </div>
      );
    }
    return null;
  };

  const detailsStatus = () => {
    if (isSmartRetry) {
      return (
        <>
          <p>Expired on {expiredDate}</p>
          <div
            className="underlined"
            onClick={() => setAutoRenewCancelModal(true)}
            role="button"
          >
            Cancel Auto-Renewal
          </div>
        </>
      );
    }
    if (status === 'expired') {
      return (
        <>
          <p>Expired on {expiredDate}</p>
          <p>
            {subsTrialType !== 'notTrial'
              ? 'Free-Trial Cancelled'
              : 'Auto-Renewal Off'}
          </p>
        </>
      );
    }
    if (status === 'recurring') {
      if (subsTrialType !== 'notTrial') {
        return (
          <>
            <div className="payment-details">
              <p>Annual Plan starts on {expiredDate}.</p>
              {paymentMethodType !== 'others' && (
                <p>
                  Payment Method:{' '}
                  {paymentMethodType === 'stripe' ? <CC /> : <PP />}{' '}
                  {paymentMethodType === 'stripe'
                    ? `************${paymentMethodText}`
                    : paymentMethodText}{' '}
                  (Upcoming payment on {expiredDate})
                </p>
              )}
            </div>
            <div
              className="underlined"
              onClick={() => setCancelShowModal(true)}
              role="button"
            >
              Cancel Free-Trial
            </div>
          </>
        );
      }
      return (
        <>
          <div className="best-price-details">
            <div className="best-price-desc">
              <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose
                overlay={
                  <StyledPopover>
                    <Popover.Title as="h3">
                      <BestPriceIcon className="icon" /> Best Price
                    </Popover.Title>
                    <Popover.Content>
                      Those who enable <strong>Auto-Renewal</strong> Or{' '}
                      <strong>Renew your account</strong> before it expires can
                      enjoy our <strong>highest renewal discount.</strong>
                    </Popover.Content>
                  </StyledPopover>
                }
              >
                <BestPriceBadge ref={bestPriceRef}>
                  <BestPriceIcon className="icon" />
                  Best Price
                  <div className="disc">
                    {isB2B && BUNDLE_TYPE_ID[bundleTypeId] !== 'science' && (
                      <span className="b2b-label">Partner School</span>
                    )}
                    <p className="discount-number">{discount}% OFF</p>
                  </div>
                </BestPriceBadge>
              </OverlayTrigger>
              <p>
                Auto-Renewal on. You now enjoy the highest renewal discount.
              </p>
            </div>
            <div>
              {paymentMethodType !== 'others' && (
                <p>
                  Payment Method:{' '}
                  {paymentMethodType === 'stripe' ? <CC /> : <PP />}{' '}
                  {paymentMethodType === 'stripe'
                    ? `************${paymentMethodText}`
                    : paymentMethodText}{' '}
                  (Upcoming payment on {expiredDate})
                </p>
              )}
            </div>
          </div>
          <div
            className="underlined"
            onClick={() => setAutoRenewCancelModal(true)}
            role="button"
          >
            Cancel Auto-Renewal
          </div>
        </>
      );
    }
    if (status === 'auto-renew-off') {
      return (
        <>
          <p className="active-date">
            This account can still be used until {expiredDate}
          </p>
          <p>
            {subsTrialType === 'ccTrial' || subsTrialType === 'otpTrial'
              ? 'Cancelled Free Trial'
              : 'Auto-Renewal Off'}
          </p>
        </>
      );
    }
    if (status === 'one-time-payment') {
      return (
        <TurnOnAutoRenewStyled>
          <BestPriceIcon2 className="best-icon" />{' '}
          <span className="green">Best Price Guarantee</span> To lock the
          highest renewal discount, please{' '}
          <span
            className="orange"
            role="button"
            onClick={() => renewHandler('turn-on-renew')}
          >
            Turn on Auto-Renewal
          </span>{' '}
          <span className="yellow">{discount}% OFF</span>
        </TurnOnAutoRenewStyled>
      );
    }
    return null;
  };

  return (
    <Container length={subsLength} position={position}>
      {/* modals */}
      <PopupModal show={showCancelModal} hide={() => setCancelShowModal(false)}>
        <SubscriptionModal
          heading="Are you sure you want to cancel your subscription?"
          subHeading="If you cancel now, you’ll lose your access to KooBits after your trial ends."
          showDropDown={false}
          isTrial={subsTrialType === 'ccTrial'}
          closeModal={() => setCancelShowModal(false)}
          primaryOnClick={() => {
            setCancelShowModal(false);
          }}
          secondaryOnClick={() => {
            setCancelShowModal(false);
            setProceedCancelModal(true);
          }}
          primaryBtnText="No, keep my subscription."
          secondaryBtnText="Yes, cancel my subscription."
          renewalDate={expiredDate}
        />
      </PopupModal>
      <PopupModal
        show={proceedCancelModal}
        hide={() => setProceedCancelModal(false)}
      >
        <SubscriptionModal
          heading={
            subsTrialType === 'ccTrial'
              ? 'We’re sad to see you go!'
              : 'Are you sure you want to turn off auto-renew?'
          }
          subHeading={
            subsTrialType === 'ccTrial'
              ? 'Could you share with us your reason for cancelling the trial?'
              : 'Could you share with us your reason for turning off auto-renew？'
          }
          isTrial={subsTrialType === 'ccTrial'}
          paymentSubsId={paymentSubsId}
          showDropDown
          closeModal={() => setProceedCancelModal(false)}
          renewalDate={expiredDate}
        />
      </PopupModal>
      <ModalContentXButton
        showModal={autoRenewCancelModal}
        closeModal={() => setAutoRenewCancelModal(false)}
        containerMinWidth="440px"
      >
        <AutoRenewCancelModal
          expiredDate={expiredDate}
          onProceed={() => {
            setAutoRenewCancelModal(false);
            setProceedCancelModal(true);
          }}
          onCancel={() => setAutoRenewCancelModal(false)}
        />
      </ModalContentXButton>

      <TopSection
        active={status !== 'expired' && !isSmartRetry}
        position={position}
        rounded={subsTrialType === 'otpTrial'}
      >
        {!isTablet && <div className="check-icon" />}
        <div className="plan">
          <div className="plan-name">
            {isBundled && <BundleIcon>Bundle</BundleIcon>}
            <div className="name-section">
              <p className="title" data-cy="plan-name">
                {bundleName}
              </p>
              <StatusBadge
                status={
                  status !== 'expired' && !isSmartRetry
                    ? 'recurring'
                    : 'expired'
                }
                data-cy="status"
              >
                {status !== 'expired' && !isSmartRetry ? 'Active' : 'Expired'}
              </StatusBadge>
            </div>
          </div>
          <p className="desc">
            {subsTrialType !== 'notTrial' ? trialDesc : desc}
          </p>
        </div>
        {renewButton()}
      </TopSection>
      {subsTrialType !== 'otpTrial' && (
        <BottomSection active={status !== 'expired' && !isSmartRetry}>
          {detailsStatus()}
        </BottomSection>
      )}
    </Container>
  );
};

export default SubscriptionPlanRowV3;
