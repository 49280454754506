import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const FoundText = styled.p`
  margin: 0 0.5em 0 0;
`;

export const FoundList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
`;

export const FoundItem = styled.li`
  display: inline-block;
  margin-right: 0.8em;
  .full {
    display: none;
  }
  .empty {
    display: inline-block;
  }
  &.found {
    .full {
      display: inline-block;
    }
    .empty {
      display: none;
    }
  }
`;
