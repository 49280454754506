/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';

import {
  showSelectedTool,
  hideAllTools,
  setToolPosition,
} from 'store/assignment/assistToolsSlice';
import { setDrawingActivation } from 'store/assignment/drawingKitsSlice';
import AssistTools from './AssistTools';
import DrawingKits from './AssistTools/DrawingKits';
import { Container, Bounds } from './HelperSidebar.styles';

const HelperSideBar = ({ bgColor, feature, showSideBar = true, top }) => {
  const { t } = useTranslation(['assignmentQnView']);
  const dispatch = useDispatch();
  const barModel = useSelector((state) => state.barModel);
  const { toolsToShow } = useSelector((state) => state.assistTools);
  const { isActive } = useSelector((state) => state.drawingKits);
  const { width } = barModel.data;
  const defaultBarModelPosition = {
    x: window.innerWidth - width - 98,
    y: 130,
  };
  const tools = ['add-working', 'four-ops', 'bar-model'];
  const isDrawingKitsActive = toolsToShow.includes('drawing-kits') || isActive;
  const [slide, setSlide] = useState(null);
  const [animationName, setAnimationName] = useState(null);

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      dispatch(hideAllTools());
    };
  }, [dispatch]);
  return (
    <>
      <Bounds>
        {tools.map((tool) => (
          <AssistTools key={tool} type={tool} feature={feature} />
        ))}
      </Bounds>
      {showSideBar && (
        <Container bgColor={bgColor} animate={animationName} top={top}>
          {isDrawingKitsActive ? (
            <DrawingKits
              slide={slide}
              setSlide={setSlide}
              setFade={setAnimationName}
            />
          ) : (
            <>
              {!isMobile && !isTablet && (
                <div
                  className="helper drawing-kits"
                  onClick={() => {
                    dispatch(setDrawingActivation(true));
                    dispatch(showSelectedTool('drawing-kits'));
                    setSlide('slide-out');
                  }}
                >
                  <p>
                    {t('assignmentQnView:toolbar.drawingKits', 'Drawing Kits')}
                  </p>
                </div>
              )}
            </>
          )}
          {feature !== 'peer-challenge' && (
            <>
              <div
                className="helper add-steps"
                onClick={() => {
                  dispatch(
                    setToolPosition({
                      tool: 'add-working',
                      position: {
                        x: 0,
                        y: 0,
                      },
                    })
                  );
                  dispatch(showSelectedTool('add-working'));
                }}
              >
                <p>{t('assignmentQnView:toolbar.addSteps', 'Add Steps')}</p>
              </div>
              <div
                className="helper bar-model"
                onClick={() => {
                  dispatch(
                    setToolPosition({
                      tool: 'bar-model',
                      position: defaultBarModelPosition,
                    })
                  );
                  dispatch(showSelectedTool('bar-model'));
                }}
              >
                <p>{t('assignmentQnView:toolbar.barModel', 'Bar Model')}</p>
              </div>
            </>
          )}
          <div
            className="helper four-ops"
            onClick={() => {
              dispatch(
                setToolPosition({
                  tool: 'four-ops',
                  position: {
                    x: 0,
                    y: 0,
                  },
                })
              );
              dispatch(showSelectedTool('four-ops'));
            }}
          >
            <p>{t('assignmentQnView:toolbar.fourOps', 'Four Ops')}</p>
          </div>
          {/* <div className="hint">
        <p>Hint</p>
      </div> */}
        </Container>
      )}
    </>
  );
};

export default HelperSideBar;
