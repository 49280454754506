import styled from 'styled-components';

export const Container = styled.div`
  padding: 1% 20%;
  @media (max-width: 1300px) {
    padding: 1% 15%;
  }
  .qn-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  .btn-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .claim-section {
      width: 85%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgb(255, 228, 222, 0.44);
      border-radius: 25px;
      padding: 1.2rem 0;
      .text {
        color: #953333;
        margin-right: 0.2rem;
        margin-bottom: 0.5rem;
        span {
          color: #212121;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 1% 32px;
    .btn-section {
      .claim-section {
        width: 100%;
      }
    }
  }
`;

export const ClaimedText = styled.div`
  background-color: rgb(183, 235, 255, 0.44);
  text-align: center;
  padding: 0.5rem;
  width: 740px;
`;
