import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 50%;
  height: fit-content;
  position: relative;
  cursor: pointer;
  :hover {
    > div {
      transform: scale(0.95);
    }
  }
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
  }
`;

export const StyledContent = styled.div`
  position: absolute;
  left: 45%;
  bottom: 8%;
  transform: translateX(-46%);
  text-align: center;
  width: 93%;
  color: white;
  h3 {
    // font-size 32px,1387px(UI width)  => 32 / 1387 = 0.02307137707
    font-size: calc(0.02307137707 * 100vw);
    margin-bottom: calc(0.007209805 * 100vw);
    line-height: 1;
    font-weight: 900;
    text-shadow: -2px 0px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    display: inline-block;
  }
  img {
    position: absolute;
    left: 107%;
    width: calc(0.06734992679355783 * 100vw);
    bottom: -63%;
  }
  p {
    // font-size 15px,1387px(UI width) => 15 / 1387 = 0.010814708
    font-size: calc(0.010814708 * 100vw);
    font-weight: 700;
    line-height: 1;
    margin: 0;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    h3 {
      font-size: 30px;
      font-weight: 700;
    }
    p {
      font-size: 15px;
      font-weight: 600;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    h3 {
      font-size: 22px;
    }
    p {
      font-size: 12px;
    }
    img {
      width: 50px;
    }
  }
`;
