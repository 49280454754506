import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  position: relative;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  margin-bottom: 5px;
  padding: 20px 12px 12px 12px;
  background: ${({ backgroundColor }) => backgroundColor};
  & > .rankingBadge {
    position: absolute;
    left: -7px;
    top: 24px;
  }
  & > .cps {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #e3f6ff;
    font-size: 12px;
    color: #6b6b6b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0px 2px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const DateDisplay = styled.span`
  font-size: 11px;
  color: #b1b1b1;
  text-align: center;
`;

export const NameDisplay = styled.h4`
  font-size: 15px;
  color: #113b51;
  font-weight: 700;
  margin: 0px;
  margin-bottom: 5px;
  line-height: 1.1;
`;

export const SchollContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > span {
    font-size: 11px;
    color: #616c72;
  }
  & > img {
    margin-right: 4px;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
  }
`;
