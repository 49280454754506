import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getActivitiesByLessonId,
  getLessonByLessonId,
  getActivityByActivityId,
  setActiveType,
  setActiveId,
  setActivityName,
  resetCurrentActivity,
  resetActivity,
} from 'store/kooClass/kooClassSlice';
import { activityType, lessonStatus } from 'constants/kooClass';
import { isEmpty, isNil } from 'ramda';
import useQuery from 'helpers/useQuery';
import { sendEventTracking } from 'helpers/UserEventTracking';
import ActivityNav from 'components/KooClass/ActivityNav';
import ErrorModal from 'components/Shared/ErrorModal';
import Spinner from 'components/Shared/Spinner';
import BackButton from 'components/Shared/BackButton';
import FunWithMath from './FunWithMath';
import WatchAndLearn from './WatchAndLearn';
import KooClassStartPage from './KooClassStartPage';
import KooClassGame from './KooClassGame';
import SelfCheck from './SelfCheck';
import TalkAbout from './TalkAbout';

import { Container, ActivityScreen } from './Activities.styles';

const Activities = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['topicSelection']);
  const { lessonId, topicId, subject } = useParams();
  const { currentProduct } = useSelector((state) => state.plan);
  const {
    activities,
    isLoading,
    isErr,
    currentLessonLoading,
    activeType,
    activeId,
    activityName,
    activitiesErr,
    currentActivityErr,
  } = useSelector((state) => state.kooClass);

  const [showVideo, setShowVideo] = useState(false);
  const [hasFirstLoad, setFirstLoad] = useState(false);
  const filterActiveName =
    activities && activities.filter((item) => item.id === activeId);
  const activeName =
    filterActiveName && filterActiveName[0] && filterActiveName[0].name;
  const getFirstElement = activities?.length > 0 ? activities[0] : null;

  const query = useQuery();
  const activityTypeParams = query.get('activityType');
  const activityIdParams = query.get('activityId');
  const nameParams = query.get('name');
  const currentProductKooClassConfig = currentProduct?.features.find(
    (item) => item.feature.toLowerCase() === 'kooclass'
  );
  const courseIdParam =
    query.get('courseId') || currentProductKooClassConfig?.parameters?.CourseId;
  const isFirstActivity =
    getFirstElement && getFirstElement.id === activityIdParams;

  useEffect(() => {
    dispatch(getActivitiesByLessonId(lessonId)).then(() => {
      setFirstLoad(true);
    });
    dispatch(getLessonByLessonId(lessonId)).then((result) => {
      if (result.type.endsWith('fulfilled')) {
        const currLessonStatus = lessonStatus[result.payload.lessonStatus];
        if (currLessonStatus === lessonStatus[1]) {
          history.replace(
            `/kooClass/lessons/${topicId}?courseId=${courseIdParam}&selectedLessonId=${lessonId}`
          );
        }
      }
    });
  }, [courseIdParam, dispatch, history, lessonId, topicId]);

  useEffect(() => {
    if (
      isNil(activityTypeParams) &&
      isNil(activityIdParams) &&
      isNil(nameParams)
    ) {
      if (isNil(getFirstElement) || !hasFirstLoad) {
        dispatch(setActiveId(null));
        dispatch(setActiveType(null));
        dispatch(setActivityName(null));
        return;
      }
      history.replace(
        `/kooClass/${topicId}/lesson/${lessonId}/activity?activityType=${getFirstElement.activityType}&activityId=${getFirstElement.id}&name=${getFirstElement.name}&courseId=${courseIdParam}`
      );
    } else {
      dispatch(setActiveId(activityIdParams));
      dispatch(setActiveType(activityTypeParams));
      dispatch(setActivityName(nameParams));
      setFirstLoad(true);
    }
  }, [
    dispatch,
    getFirstElement,
    activityTypeParams,
    activityIdParams,
    nameParams,
    history,
    topicId,
    lessonId,
    activeType,
    activeId,
    activityName,
    courseIdParam,
    hasFirstLoad,
    subject,
  ]);

  useEffect(() => {
    if (hasFirstLoad && !isEmpty(activeId) && !isNil(activeId)) {
      dispatch(getActivityByActivityId(activeId));
    }
  }, [dispatch, activeId, hasFirstLoad]);

  useEffect(() => {
    // EVENT TRACKING: onpage load
    sendEventTracking('kooclass', 'lesson_view', {
      sub: currentProduct?.subjectId,
      li: lessonId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // loading & error handling
  const showLoading = isLoading || currentLessonLoading;
  const showError = !isNil(activitiesErr) || !isNil(currentActivityErr);

  const showErrorText = () => {
    if (!isNil(activitiesErr)) {
      return activitiesErr;
    }
    if (!isNil(currentActivityErr)) {
      return currentActivityErr;
    }
    if (!isNil(isErr)) {
      return isErr;
    }
  };

  const reloadError = () => {
    if (!isNil(activitiesErr)) {
      dispatch(getActivitiesByLessonId(lessonId));
    } else if (!isNil(currentActivityErr)) {
      dispatch(getActivityByActivityId(activeId));
    }
  };

  const clearError = () => {
    if (!isNil(currentActivityErr)) {
      dispatch(resetCurrentActivity());
    }
  };

  return (
    <Container>
      <ActivityScreen>
        {((!showVideo && isFirstActivity) || !isNil(activitiesErr)) && (
          <BackButton
            backText={t('topicSelection:backBtn', 'Back')}
            btnBgColor="#5A5ADE"
            padding="5px 20px"
            onClick={() => {
              dispatch(resetCurrentActivity());
              dispatch(resetActivity());
              history.push(
                `/kooClass/lessons/${topicId}?courseId=${courseIdParam}`
              );
            }}
            styles={{ position: 'absolute', left: '5%', marginTop: '2rem' }}
          />
        )}
        {!showLoading && !showError && (
          <>
            {activityType[activeType] === 'Video' &&
              activityName &&
              ['fun with math', 'fun with science'].includes(
                activityName.toLowerCase().trim()
              ) && (
                <FunWithMath
                  showVideo={showVideo}
                  setShowVideo={setShowVideo}
                  activityName={activityName}
                />
              )}
            {activityType[activeType] === 'Video' &&
              activityName &&
              activityName.toLowerCase().trim() === 'watch and learn' && (
                <WatchAndLearn
                  showVideo={showVideo}
                  setShowVideo={setShowVideo}
                />
              )}
            {(activityType[activeType] === 'SingleQuestion' ||
              activityType[activeType] === 'MultipleQuestion') && (
              <KooClassStartPage title={activeName} courseId={courseIdParam} />
            )}
            {(activityType[activeType] === 'IVM' ||
              activityType[activeType] === 'Game') && (
              <KooClassGame type={activityType[activeType]} />
            )}
            {activityType[activeType] === 'Information' &&
              activityName === 'Self-Check' && (
                <SelfCheck courseId={courseIdParam} />
              )}
            {activityType[activeType] === 'Information' &&
              activityName &&
              (activityName.toLowerCase().trim() === "let's talk about" ||
                activityName.toLowerCase().trim() === 'let’s talk about') && (
                <TalkAbout
                  activityId={activityIdParams}
                  courseId={courseIdParam}
                />
              )}
          </>
        )}
        {showLoading && <Spinner position="relative" top="2rem" />}
        {!showLoading && showError && (
          <ErrorModal
            errorMessage={showErrorText()}
            reloadAction={reloadError}
            closeHandler={clearError}
          />
        )}
      </ActivityScreen>
      <ActivityNav
        data={activities}
        activeId={activityIdParams}
        setActiveId={setActiveId}
        setActiveType={setActiveType}
        setActivityName={setActivityName}
        setShowVideo={setShowVideo}
        courseId={courseIdParam}
        subject={subject}
      />
    </Container>
  );
};

export default Activities;
