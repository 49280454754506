import styled from 'styled-components';
import BannerBg from 'assets/img/friends/banner-bg.svg';
import BackgroundBg from 'assets/img/friends/bg-img1.png';

export const Bg = styled.div`
  background-image: ${`url(${BackgroundBg})`};
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 520px;
`;

export const Banner = styled.div`
  width: 100vw;
  height: 130px;
  background-image: ${`url(${BannerBg})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #34c6e8;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .title {
    font-family: 'Grilled Cheese';
    font-size: 2.4375rem;
    color: white;
    position: relative;
    z-index: 1;
    width: 35%;
    &::before {
      content: ${({ content }) => `"${content}"`};
      position: absolute;
      -webkit-text-stroke: 5px #0090b2;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
  .header-sidebar {
    display: flex;
    width: 25%;
    border: 1px solid transparent;
    justify-content: space-evenly;
    background-color: rgba(216, 255, 255, 0.3);
    border-radius: 10px;
    color: #ffffff;
    font-size: 22px;
    padding: 10px;
    text-align: center;
    img {
      margin-right: 8px;
      width: 30px;
    }
    .side-left {
      border-right: 1px solid #198faa;
      padding-right: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .sidebar-heading {
      color: #3281b2;
      font-size: 14px;
    }
    .sidebar-date {
      color: #3281b2;
      font-size: 12px;
    }
  }
`;
