import * as React from 'react';
import styled from 'styled-components';
import SideNavIcon from 'assets/img/assignment/assignment-view/icon-sidenav.svg';
import SideNav from 'components/Shared/SideNav';
import CloseIcon from 'assets/img/assignment/assignment-view/icon-close.png';
import useClickOutside from 'hooks/useClickOutside';
import { AssignmentSideNavHelpers } from 'components/Assignment/AssignmentView/AssignmentSideNav';

const SideNavButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  right: 3%;
  display: none;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: block;
  }
`;
export const StyledNavBar = styled.div`
  height: 100%;
  background: #f2f2f2;
  width: 291px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 80%;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  margin-right: 25px;
`;

const StyledHeader = styled.div`
  width: 100%;
  height: 60px;
  background-color: #1bbfe6;
  display: flex;
  padding-left: 15px;
`;

const HideOnMobile = styled.div`
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

const SideNavigation = ({ children }) => {
  const [show, setShow] = React.useState(false);
  const sideNavClickHandler = () => setShow((s) => !s);
  const navBarRef = React.useRef();

  const closeSideBar = () => {
    if (show) setShow(false);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setShow, showIcon: false });
    }
    return child;
  });

  useClickOutside(navBarRef, closeSideBar);

  return (
    <>
      <SideNavButton onClick={sideNavClickHandler}>
        <img src={SideNavIcon} alt="Side Nav" />
      </SideNavButton>
      <SideNav show={show}>
        <StyledNavBar ref={navBarRef}>
          <StyledHeader>
            <CloseButton onClick={closeSideBar}>
              <img src={CloseIcon} alt="Close" />
            </CloseButton>
          </StyledHeader>
          <HideOnMobile>
            <AssignmentSideNavHelpers setShow={setShow} />
          </HideOnMobile>
          {childrenWithProps}
        </StyledNavBar>
      </SideNav>
    </>
  );
};

export default SideNavigation;
