import moment from 'moment';

const checkIfAssignmentExpired = (deadline) => {
  const deadlineMoment = moment(deadline);
  const currentMoment = moment();
  const difference = deadlineMoment.diff(currentMoment);
  return difference <= 0;
};

export default checkIfAssignmentExpired;
