import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/Shared/BackButton';
import BffLike from 'assets/img/leaderboard/bff/bff-like.svg';
import { ChallengeType } from 'pages/Leaderboard/v2/components';
import {
  StyledContainer,
  StyledButtonContainer,
  StyledBrainHeading,
  StyledContent,
} from './Header.styles';

const Header = () => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  return (
    <StyledContainer>
      <StyledButtonContainer className="btn">
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(53, 137, 216, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/leaderboard')}
          styles={{ marginTop: '1rem' }}
        />
      </StyledButtonContainer>
      <StyledContent>
        <StyledBrainHeading>
          {t('leaderboard:bff.bestfriend', 'Best Friends Forever')}
          <img src={BffLike} alt="bff-like" />
        </StyledBrainHeading>
      </StyledContent>
      <ChallengeType styles={{ top: '12%' }} />
    </StyledContainer>
  );
};

export default Header;
