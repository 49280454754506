import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/super-hero/super-hero-header.png';

export const Container = styled.div`
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: 100%;
  height: 315px;
  .btn {
    position: absolute;
    left: 4%;
  }
  .middle-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-left: 6rem;
    p {
      margin-bottom: 0rem;
      color: #ffffff;
      font-size: 28px;
      font-weight: bold;
    }
    .desc {
      text-align: center;
      margin-bottom: 1.8rem;
      margin-left: -5rem;
    }
  }
`;
