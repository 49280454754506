import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';
import { Container, Marker } from './ProgressBarWithMarkers.styles';

// HELPERS
const filterMarkers = (markers, totalSkills) => {
  let result = [];
  if (totalSkills <= markers[0]) {
    result.push(totalSkills);
  } else {
    result = markers.filter((marker) => marker < totalSkills);
    result.push(totalSkills);
  }
  return result;
};

const calculateMarkerMargin = (numOfMarkers, isMobile) => {
  // Units in rem
  const totalBarLength = isMobile ? 15.625 : 26.25;
  const markerWidth = isMobile ? 1.3 : 1.5625;
  return totalBarLength / numOfMarkers - markerWidth;
};

const calculateProgressBarPercent = (numOfAStars, markers, totalSkills) => {
  if (numOfAStars >= totalSkills) {
    return 100;
  }
  const totalNumOfMarkers = markers.length;
  const markerBarUnitLength = 100 / totalNumOfMarkers;
  const markersSmallerOrEqual = markers.filter(
    (marker) => marker <= numOfAStars
  );
  let percent = markersSmallerOrEqual.length * markerBarUnitLength;
  // Check if the last
  if (markersSmallerOrEqual.length > 0) {
    if (numOfAStars > markersSmallerOrEqual[markersSmallerOrEqual.length - 1]) {
      const diffBetweenMarkers =
        markers[markersSmallerOrEqual.length] -
        markers[markersSmallerOrEqual.length - 1];
      const diffBetweenNumOfAandMarker =
        numOfAStars - markersSmallerOrEqual[markersSmallerOrEqual.length - 1];
      percent +=
        (diffBetweenNumOfAandMarker / diffBetweenMarkers) * markerBarUnitLength;
    }
  } else {
    percent += (numOfAStars / markers[0]) * markerBarUnitLength;
  }
  return percent;
};

const ProgressBarWithMarkers = ({ numOfAStars, totalSkills, type }) => {
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  const markers = [5, 10, 20, 30, 45, 65, 90, 120];
  const mobileMarkers = [10, 30, 50];
  const markersToShow = filterMarkers(
    isMobile ? mobileMarkers : markers,
    totalSkills
  );
  const markerLeftMargin = calculateMarkerMargin(
    markersToShow.length,
    isMobile
  );
  return (
    <Container type={type}>
      <ProgressBar
        variant="warning"
        now={calculateProgressBarPercent(
          numOfAStars,
          markersToShow,
          totalSkills
        )}
      />
      <div className="markers">
        {markersToShow.map((marker) => (
          <Marker
            active={marker <= numOfAStars}
            key={marker}
            leftMargin={markerLeftMargin}
          >
            {marker}
          </Marker>
        ))}
      </div>
    </Container>
  );
};

export default ProgressBarWithMarkers;
