import React from 'react';
import CalendarIcon from 'assets/img/dailychallenge/calendar-icon.png';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Container, Icon, Bar } from './DatePicker.styles';

const DatePicker = () => {
  const history = useHistory();

  return (
    <Container>
      <Icon>
        <img src={CalendarIcon} alt="" />
      </Icon>
      <Bar
        onClick={() => {
          history.push('/challenges/daily-challenge/hero-badge');
        }}
        data-cy="date"
      >
        {moment(new Date()).format('DD/MMM/YYYY')}
      </Bar>
    </Container>
  );
};

export default DatePicker;
