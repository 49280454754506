import React from 'react';
import { Container, TextContainer } from './Ribbon.styles';

const Ribbon = ({ text }) => {
  const CustomText = (text) => {
    return (
      <TextContainer text={text}>
        <h3>{text}</h3>
      </TextContainer>
    );
  };
  return <Container text={text}>{CustomText(text)}</Container>;
};

export default Ribbon;
