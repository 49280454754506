import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLeaderboardRequest } from 'services/factualFluency';

import { fetchWrapper } from 'services/login';

export const getFactualFluencyLeaderboard = createAsyncThunk(
  'factualFluency/getFactualFluencyLeaderboard',
  async (HomeworkId) => {
    try {
      const res = await fetchWrapper(fetchLeaderboardRequest, HomeworkId);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get factual fluency leaderboard data failed'
      );
    }
  }
);

const initialState = {
  factualFluencyLeaderboard: [],
  factualFluencyLeaderboardLoading: false,
  factualFluencyLeaderboardError: null,
};

const factualFluencyLeaderboardSlice = createSlice({
  name: 'factualFluencyLeaderboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getFactualFluencyLeaderboard.pending]: (state) => {
      state.factualFluencyLeaderboardLoading = true;
      state.factualFluencyLeaderboard = [];
      state.factualFluencyLeaderboardError = null;
    },
    [getFactualFluencyLeaderboard.fulfilled]: (state, action) => {
      state.factualFluencyLeaderboardLoading = false;
      state.factualFluencyLeaderboard = action.payload;
      state.factualFluencyLeaderboardError = null;
    },
    [getFactualFluencyLeaderboard.rejected]: (state, action) => {
      state.factualFluencyLeaderboardLoading = false;
      state.factualFluencyLeaderboard = [];
      state.factualFluencyLeaderboardError = action.error.message;
    },
  },
});

const { reducer } = factualFluencyLeaderboardSlice;
export default reducer;
