import styled from 'styled-components';
import { assignmentTypeColors } from 'constants/index';

export const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const InfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  min-height: 72px;
  border: 1px solid ${(props) => props.borderColors};
  background: ${(props) => props.bgColors};
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;

  .details {
    display: flex;
    align-items: center;
    word-break: break-all;
    h5 {
      font-size: 20px;
      color: #404447;
      margin-bottom: 0px;
    }
    p {
      margin-bottom: 0px;
      color: #c4c4c4;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .status-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .deadline {
      display: flex;
      flex-direction: column;
      align-self: center;
      font-size: 16px;
      width: 7rem;
      color: ${(props) =>
        props.expired === 1
          ? '#CBA8A8'
          : props.status && !props.deleted
          ? '#9ACC9A'
          : '#d8d8d8'};
      .date {
        color: #0d0d0d;
        font-size: 13px;
        text-align: left;
        line-height: 1;
      }
    }
  }
`;

export const Type = styled.div`
  margin-right: 20px;
  background: ${(props) =>
    props.type && !props.deleted
      ? assignmentTypeColors[props.type]
      : props.deleted && '#C4C4C4'};
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  min-width: 50px;
  height: 20px;
  align-self: center;
  font-size: 14px;
`;

export const DeletedSign = styled.div`
  align-self: center;
  background: #dfdfdf;
  padding: 3px 10px;
  border-radius: 15px;
  border: 1px solid #d8d8d8;
  font-size: 16px;
  color: #636363;
  margin-right: 25px;
  @media (max-width: 1290px) {
    font-size: 14px;
    margin-right: 10px;
  }
  @media (max-width: 1040px) {
    text-align: center;
    line-height: 1;
    padding: 3px 5px;
  }
  @media (max-width: 880px) {
    align-self: baseline;
  }
  @media (max-width: 610px) {
    margin-top: 5px;
  }
`;

export const AssignmentStatus = styled.div`
  align-self: center;
  margin-right: 30px;
  color: ${(props) => props.fontColor};
  @media (max-width: 1040px) {
    margin-right: 20px;
  }
  @media (max-width: 880px) {
    align-self: auto;
    margin-right: 10px;
    margin-left: 10px;
  }

  @media (max-width: 600px) {
    margin-right: 0px;
  }
`;

export const ScoreColor = styled.span`
  font-size: 24px;
  color: ${(props) => (props.deleted ? '#AFB2AC' : props.score)};
  font-weight: 500;
  line-height: 1;

  @media (max-width: 768px) {
    padding-left: 0.5rem;
  }
`;

export const ButtonSection = styled.div`
  width: 15%;
  min-height: 72px;
  display: flex;
  justify-content: center;
  border: 1px solid ${(props) => props.borderColors};
  background: ${(props) => props.bgColors};
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  @media (max-width: 880px) {
    padding: 10px 10px;
  }

  .date {
    text-align: center;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  background: ${(props) => (props.status ? '#FFFFFF' : '#ff7121')};
  border: 1px solid ${(props) => (props.status ? '#FFCAAC' : '#ff8001')};
  border-radius: 30px;
  color: ${(props) => (props.status ? '#FF7A2E' : '#FFFFFF')};
  font-size: 18px;
  padding: 6px 9px;
  align-self: center;
  min-width: 90%;
  line-height: 1;
  text-align: center;
  p {
    margin-bottom: 0px;
    align-self: center;
  }
  span {
    margin-left: 5px;
    img {
      vertical-align: sub;
    }
    @media (max-width: 880px) {
      display: none;
    }
  }
  &:hover {
    background: ${(props) => (props.status ? '#fff2eb' : '#FF5C00')};
    border: 1px solid ${(props) => (props.status ? '#FFCAAC' : '#FF5C00')};
  }
  @media (max-width: 1367px) {
    font-size: 16px;
  }
  @media (max-width: 1040px) {
    font-size: 14px;
  }
  @media (max-width: 610px) {
    font-size: 10px;
  }
`;

export const Locked = styled.div`
  display: flex;
  img {
    width: 34px;
    height: 34px;
    align-self: center;
  }
  p {
    margin-bottom: 0px;
    line-height: 1;
    text-align: center;
    margin-left: ${(props) => (props.status ? '0px' : '5px')};
    color: #6d6c6c;
    font-size: 16px;
    align-self: center;
    @media (max-width: 610px) {
      font-size: 10px;
    }
  }
`;

export const StartDate = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  p {
    margin-bottom: 0px;
    line-height: 1.2;
    text-align: center;
    color: ${(props) => (props.type ? '#9ACC9A' : '#d8d8d8')};
    font-size: 16px;
    align-self: center;
    @media (max-width: 1367px) {
      font-size: 14px;
    }
    @media (max-width: 610px) {
      font-size: 10px;
    }
  }
  .date {
    color: ${(props) => (props.type ? '#000000' : '#ff7121')};
  }
`;

export const MobileInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
  min-height: 72px;
  border: 1px solid ${(props) => props.borderColors};
  background: ${(props) => props.bgColors};
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  overflow-x: scroll;

  .details {
    margin-top: 10px;
    margin-bottom: 10px;
    word-break: break-all;
    h5 {
      font-size: 20px;
      color: #404447;
      margin-bottom: 0px;
    }
    p {
      margin-bottom: 0px;
      color: #c4c4c4;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .status-info {
    .deadline {
      font-size: 18px;
      display: flex;
      align-items: center;
      color: ${(props) =>
        props.expired === 1
          ? '#CBA8A8'
          : props.status && !props.deleted
          ? '#9ACC9A'
          : '#d8d8d8'};
      .date {
        padding-left: 0.3rem;
        color: #0d0d0d;
        font-size: 12px;
        text-align: left;
        line-height: 1;
        p {
          padding-left: 7px;
          @media (max-width: 880px) {
            padding-left: 0px;
          }
        }
      }
    }

    @media (max-width: 500px) {
      .deadline {
        font-size: 16px;
      }
    }

    @media (max-width: 350px) {
      max-width: 320px;
      .deadline {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const MobileButtonSection = styled.div`
  width: 30%;
  background: ${(props) => props.bgColors};
  padding: 10px 10px;
  border-radius: 10px;

  button {
    width: 100px;
    font-size: 14px !important;
  }

  p {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    width: 40%;
  }

  @media (max-width: 350px) {
    margin-right: 1rem;
  }
`;

export const MobileTopStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 20px;
`;

export const MobileBottomStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.07);
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 0rem;
  min-height: 50px;

  .date {
    font-size: 12px;
  }
`;
