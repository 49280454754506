import { createAnswerKeyValuePairsByQuestionType } from 'helpers/createAnswerKeyValuePairs';
import { isNil } from 'ramda';

const finishAssignmentHandler = ({
  assignmentSubmissionID,
  questionType,
  submissionType,
  questionAnswer,
  activeQnNo,
  currentQn,
  currentLocalSavedAnswer,
  currentSubmission,
  userID,
  timeElapsed,
  finishAssignmentAction,
  workings,
  barModel,
}) => {
  let keysToMarkRight;
  if (questionType === 'longAnswer') {
    keysToMarkRight =
      currentSubmission !== null
        ? currentSubmission.SubmissionKeyValuePairs.filter(
            (keyValuePair) => keyValuePair.ismarkedright === true
          ).map((keyValuePair) => keyValuePair.Key)
        : [];
  }
  let answerKeyValuePairs;
  if (currentLocalSavedAnswer !== null) {
    answerKeyValuePairs = createAnswerKeyValuePairsByQuestionType(
      questionType,
      {
        studentAnswers: currentLocalSavedAnswer,
        questionAnswers: questionAnswer,
        userID,
        qnID: currentQn.Id,
        keysToMarkRight,
      }
    );
  } else {
    answerKeyValuePairs =
      currentSubmission !== null
        ? currentSubmission.SubmissionKeyValuePairs
        : createAnswerKeyValuePairsByQuestionType(questionType, {
            studentAnswers: null,
            questionAnswers: questionAnswer,
            userID,
            qnID: currentQn.Id,
            keysToMarkRight,
          });
  }
  const rawBody = {
    homeworkSubmissionID: assignmentSubmissionID,
    duration: timeElapsed,
    userID,
    questionID: currentQn.Id,
    type: submissionType,
    questionType: currentQn.QuestionType,
    versionId: currentQn.Version,
    answerkeyValuePairs: answerKeyValuePairs,
    isFinalSubmit: false,
  };
  // Save workings / bar model data
  if (!isNil(workings) || !isNil(barModel)) {
    // const solutionArray = [workings, null];
    const solutionObject = {
      workings: isNil(workings) ? null : workings,
      barModel: isNil(barModel) ? null : barModel,
    };
    // Stringify solution object as API only accepts value type string for solution
    rawBody.solution = JSON.stringify(solutionObject);
  }
  finishAssignmentAction({
    rawBody,
    activeQuestion: activeQnNo,
    assignmentSubmissionID,
  });
};

export default finishAssignmentHandler;
