import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import updatePreferences from 'services/premiumSchoolPreferences';
import { fetchWrapper } from 'services/login';
import ErrorModal from 'components/Shared/ErrorModal';
import { setPlanSubjectProduct } from 'store/plan/planSlice';
import useQuery from 'helpers/useQuery';

const PLAN = {
  B2B: 'school',
  B2C: 'premium',
};
const withPlanCheck = (
  WrappedComponent,
  chosenSubject,
  chosenPlan,
  noNeedChange
) => {
  const HOC = () => {
    const { t } = useTranslation('common');
    const query = useQuery();
    const history = useHistory();
    const dispatch = useDispatch();
    const planParam =
      query.get('plan')?.toUpperCase() || chosenPlan?.toUpperCase();
    const [error, setError] = useState(false);
    const queryPlan = PLAN[planParam];
    const { plan, products, isRedirect, subject } = useSelector(
      (state) => state.plan
    );
    useEffect(() => {
      if (!queryPlan) {
        return history.replace(`/dashboard?subject=${subject}&plan=${plan}`);
      }
      if (products && isRedirect) {
        if (Array.isArray(chosenSubject)) {
          const findCurrentPlan = chosenSubject.filter((subjectName) =>
            products?.find(
              ({ nonExpiredSubscriptionProducts, subject }) =>
                subject?.toLowerCase().trim() ===
                  subjectName?.toLowerCase().trim() &&
                nonExpiredSubscriptionProducts.find(
                  ({ productType }) => productType.toLowerCase() === queryPlan
                )
            )
          );
          if (findCurrentPlan?.length === 0 || !findCurrentPlan) {
            return setError(true);
          }
          // eslint-disable-next-line consistent-return
          return;
        }
        const findSubject = products?.find(
          ({ subject }) => chosenSubject === subject?.toLowerCase().trim()
        );
        if (!findSubject) {
          return setError(true);
        }
        const findPlan = findSubject.nonExpiredSubscriptionProducts?.find(
          ({ productType }) => productType.toLowerCase() === queryPlan
        );
        if (!findPlan) {
          return setError(true);
        }
        if (plan !== queryPlan || subject !== chosenSubject) {
          if (!noNeedChange) {
            fetchWrapper(updatePreferences, chosenSubject.productId);
            dispatch(
              setPlanSubjectProduct({
                plan: queryPlan,
                subject: chosenSubject,
                product: findPlan,
                features: findPlan.features,
              })
            );
          }
        }
      }
    }, [products, dispatch, history, plan, queryPlan, isRedirect, subject]);
    if (error) {
      return (
        <ErrorModal
          errorMessage={t(
            'common:noAccess',
            `You don't have access to this feature`
          )}
          closeHandler={() =>
            history.replace(`/dashboard?subject=${subject}&plan=${plan}`)
          }
        />
      );
    }
    return products ? <WrappedComponent /> : null;
  };
  return HOC;
};

export default withPlanCheck;
