import React from 'react';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { assignmentTypeNames } from 'constants/index';
import iconFactualFluency from 'assets/img/factual-fluency/icon-factual-fluency.png';
import { Type } from './TypeIndicator.styles';

const TypeIndicator = ({ type, deleted }) => {
  const { t } = useTranslation(['assignmentList']);
  const isEnglish = i18n.language.toLowerCase().includes('en');

  return (
    <Type type={type} deleted={deleted} isEnglish={isEnglish}>
      {type === 'factualfluency' ? (
        <img src={iconFactualFluency} alt="Factual Fluency" />
      ) : (
        t(
          `assignmentList:assignmentType.${assignmentTypeNames[type]}`,
          `${assignmentTypeNames[type]}`
        )
      )}
    </Type>
  );
};

export default TypeIndicator;
