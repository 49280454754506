import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchParentInfo,
  fetchProfileAvatars,
  updateProfileAvatar,
  updateParentRegistry,
  postPassword,
  postProfilePicture,
  postRequestParentEmail,
  postResetPasswordEmail,
} from 'services/profileSettings';
import { fetchWrapper } from 'services/login';

// Redux Thunks
export const getProfileAvatars = createAsyncThunk(
  'profileSettings/getProfileAvatars',
  async () => {
    try {
      const res = await fetchWrapper(fetchProfileAvatars);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get profile avatars failed');
    }
  }
);

export const updateAvatar = createAsyncThunk(
  'profileSettings/updateAvatar',
  async (params) => {
    try {
      const res = await fetchWrapper(updateProfileAvatar, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update profile avatar failed');
    }
  }
);

export const updateParentDetails = createAsyncThunk(
  'profileSettings/updateParentDetails',
  async (params) => {
    try {
      const res = await fetchWrapper(updateParentRegistry, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update parent registration failed');
    }
  }
);

export const updatePassword = createAsyncThunk(
  'profileSettings/updatePassword',
  async (params) => {
    try {
      const res = await fetchWrapper(postPassword, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update password failed');
    }
  }
);

export const updateProfilePicture = createAsyncThunk(
  'profileSettings/updateProfilePicture',
  async (params) => {
    try {
      const res = await fetchWrapper(postProfilePicture, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update profile picture failed');
    }
  }
);

// Request Parent Info
export const getParentInfo = createAsyncThunk(
  'profileSettings/getParentInfo',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchParentInfo, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Retrieve parent info failed');
    }
  }
);

export const requestParentEmail = createAsyncThunk(
  'profileSettings/TriggerParentAccountEmail',
  async () => {
    try {
      const res = await fetchWrapper(postRequestParentEmail);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Request parent email failed');
    }
  }
);

export const requestResetPasswordEmail = createAsyncThunk(
  'profileSettings/requestResetPasswordEmail',
  async (email) => {
    const res = await fetchWrapper(postResetPasswordEmail, email);
    if (res.status === 200 && !res.data.isSuccessful) {
      throw new Error('exceeded max try');
    }
    if (res.status < 200 || res.status > 299) {
      const response = res?.data;
      throw new Error(
        response?.detail || response?.title || response?.message || res
      );
    }
    return res.data.isSuccessful;
  }
);

const initialState = {
  isLoading: false,
  requestEmailLoading: false,
  parentInfo: {},
  isErr: null,
  parentInfoErr: null,
  avatars: [],
  avatarUpdateLoad: false,
  avatarUpdateSuccess: false,
  parentDetailsUpdate: false,
  passwordUpdateSuccess: null,
  profilePictureUpdateSuccess: null,
  base64String: '',
  profilePictureDateHash: null,
  emailRequestSuccess: false,
  resetPassword: {
    loading: false,
    status: null,
    err: null,
  },
};

const profileSettingsSlice = createSlice({
  name: 'profileSettings',
  initialState,
  reducers: {
    resetAvatarSuccess: (state) => {
      state.avatarUpdateSuccess = false;
    },
    resetParentRegister: (state) => {
      state.parentDetailsUpdate = false;
    },
    resetError: (state) => {
      state.isErr = null;
    },
    resetPasswordSuccess: (state) => {
      state.passwordUpdateSuccess = null;
    },
    resetProfilePictureSuccess: (state) => {
      state.profilePictureUpdateSuccess = null;
    },
    setBase64String: (state, action) => {
      state.base64String = action.payload;
    },
    clearResetPassword: (state) => {
      state.resetPassword = {
        loading: false,
        status: null,
        err: null,
      };
    },
  },
  extraReducers: {
    [getParentInfo.pending]: (state) => {
      state.isLoading = true;
      state.parentInfo = {};
      state.parentInfoErr = null;
    },
    [getParentInfo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.parentInfo = action.payload;
      state.parentInfoErr = null;
    },
    [getParentInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.parentInfo = {};
      state.parentInfoErr = action.error.message;
    },
    [getProfileAvatars.pending]: (state) => {
      state.isLoading = true;
      state.avatars = [];
      state.isErr = null;
    },
    [getProfileAvatars.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.avatars = action.payload;
      state.isErr = null;
    },
    [getProfileAvatars.rejected]: (state, action) => {
      state.isLoading = false;
      state.avatars = [];
      state.isErr = action.error.message;
    },
    [updateAvatar.pending]: (state) => {
      state.avatarUpdateLoad = true;
      state.avatarUpdateSuccess = false;
      state.isErr = null;
    },
    [updateAvatar.fulfilled]: (state, action) => {
      state.avatarUpdateLoad = false;
      state.avatarUpdateSuccess = true;
      state.isErr = null;
    },
    [updateAvatar.rejected]: (state, action) => {
      state.avatarUpdateLoad = false;
      state.avatarUpdateSuccess = false;
      state.isErr = action.error.message;
    },
    [updateParentDetails.pending]: (state) => {
      state.isLoading = true;
      state.parentDetailsUpdate = false;
      state.isErr = null;
    },
    [updateParentDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.parentDetailsUpdate = true;
      state.isErr = null;
    },
    [updateParentDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.parentDetailsUpdate = false;
      state.isErr = action.error.message;
    },
    [updatePassword.pending]: (state) => {
      state.isLoading = true;
      state.passwordUpdateSuccess = null;
      state.isErr = null;
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.passwordUpdateSuccess = action.payload;
      state.isErr = null;
    },
    [updatePassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.passwordUpdateSuccess = false;
      state.isErr = action.error.message;
    },
    [updateProfilePicture.pending]: (state) => {
      state.isLoading = true;
      state.profilePictureUpdateSuccess = null;
      state.isErr = null;
    },
    [updateProfilePicture.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.profilePictureUpdateSuccess = action.payload;
      state.profilePictureDateHash = Date.now();
      state.isErr = null;
    },
    [updateProfilePicture.rejected]: (state, action) => {
      state.isLoading = false;
      state.profilePictureUpdateSuccess = false;
      state.isErr = action.error.message;
    },
    [requestParentEmail.pending]: (state) => {
      state.requestEmailLoading = true;
      state.emailRequestSuccess = false;
      state.isErr = null;
    },
    [requestParentEmail.fulfilled]: (state, action) => {
      state.requestEmailLoading = false;
      state.emailRequestSuccess = true;
      state.isErr = null;
    },
    [requestParentEmail.rejected]: (state, action) => {
      state.requestEmailLoading = false;
      state.emailRequestSuccess = false;
      state.isErr = action.error.message;
    },
    [requestResetPasswordEmail.pending]: (state) => {
      state.resetPassword.loading = true;
      state.resetPassword.status = false;
      state.resetPassword.err = null;
    },
    [requestResetPasswordEmail.fulfilled]: (state, action) => {
      state.resetPassword.loading = false;
      state.resetPassword.status = action.payload;
      state.resetPassword.err = null;
    },
    [requestResetPasswordEmail.rejected]: (state, action) => {
      state.resetPassword.loading = false;
      state.resetPassword.status = false;
      state.resetPassword.err = action.error.message;
    },
  },
});

const { actions, reducer } = profileSettingsSlice;
export const {
  resetAvatarSuccess,
  resetParentRegister,
  resetError,
  resetPasswordSuccess,
  resetProfilePictureSuccess,
  setBase64String,
  clearResetPassword,
} = actions;
export default reducer;
