import handleResponse from 'helpers/handleAPIResponse';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchKoKoHistory = ({ period }) => {
  return fetch(`${baseUrl}KoKoHistory/${period}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get KoKo History failed'); // eslint-disable-line
    });
};
