import styled from 'styled-components';
import Tooltip from 'react-bootstrap/Tooltip';

export const Topbar = styled.div`
  height: 280px;
  background-repeat: no-repeat;
  background-size: 105% 320px;
  padding: 0 20px;
  background-color: #0092ff;
  background-size: cover;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 5rem;
  left: 4%;
`;

export const Container = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MoreXP = styled.div`
  position: absolute;
  background: #0781cc;
  border-radius: 55px;
  color: #fffefc;
  font-size: 14px;
  right: 4%;
  padding: 0.7rem 1.2rem;
  cursor: pointer;
  text-align: center;
  text-wrap: wrap;
  @media (max-width: 1024px) {
    max-width: ${({ isBaLang }) => isBaLang && '135px'};
  }
  @media (max-width: 769px) {
    top: 225px;
  }
`;

export const MoreXPOverlay = styled(Tooltip)`
  .arrow {
    border-top-color: #404447;
    &:before {
      border-top-color: #404447 !important;
    }
  }
  .tooltip-inner {
    min-width: 250px;
    background: #404447;
    color: #ffffff;
    font-size: 14px;
    border-radius: 12px;
    font-family: 'Linotte';
    padding: 0.8rem;
    .dc-text {
      color: #ffcb0a;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
