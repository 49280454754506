import React, { useState } from 'react';
import GiftBoxImg from 'assets/img/dailychallenge/mystery-koko/giftbox.png';
import KokoImg from 'assets/img/dailychallenge/mystery-koko/koko.png';
import useSound from 'use-sound';
import ClaimKokoSound from 'assets/audio/claim-koko.mp3';
import { StyledContainer } from './MysteryGiftBox.styles';

const MysteryGiftBox = ({
  kokoAmt,
  hasClaimed,
  setHasClaimed,
  isDisabled,
  boxImg = GiftBoxImg,
}) => {
  const [play] = useSound(ClaimKokoSound, {
    volume: 1,
    playbackRate: 1,
    interrupt: true,
  });
  const [showKoko, setShowKoko] = useState(false);
  const showKokoHandler = () => {
    if (hasClaimed || isDisabled) return;
    play();
    setShowKoko(true);
    setHasClaimed(true);
  };
  return (
    <StyledContainer isDisabled={isDisabled || hasClaimed}>
      {!showKoko && (
        <img
          src={boxImg}
          alt=""
          className="giftbox"
          onClick={showKokoHandler}
        />
      )}
      {showKoko && (
        <>
          <img src={KokoImg} alt="KoKo Credits" />
          <p>
            <span className="koko-amt">{`+${kokoAmt} `}</span>
            <span className="koko-text">KoKo</span>
          </p>
        </>
      )}
    </StyledContainer>
  );
};

export default MysteryGiftBox;
