import React from 'react';
import { Header } from './FourOpsTool.styles';
import { operationType } from 'constants/index';

const FourOpsHeader = ({ activeType, setActiveType }) => {
  return (
    <Header>
      {operationType.map((obj) => (
        <img
          src={activeType === obj.type ? obj.activeIcon : obj.inActiveIcon}
          alt={obj.type}
          className="type-img"
          onClick={() => setActiveType(obj.type)}
          key={obj.type}
        />
      ))}
    </Header>
  );
};

export default FourOpsHeader;
