import React from 'react';
import { useTranslation } from 'react-i18next';
import Ribbon from 'components/Mission/Ribbon';
import KokoGold from 'assets/img/dailychallenge/gold-koko.svg';
import { Container, ModalContainer, Button } from './ClaimKokoModal.styles';

const ClaimKokoModal = ({ onHide, kokoNumber = 4, challengesNumber = 10 }) => {
  const { t } = useTranslation(['dailyChallengeQnView']);
  return (
    <ModalContainer>
      <Ribbon
        text={t(
          'dailyChallengeQnView:allCorrectModal.congrats',
          'Congratulations!'
        )}
      />
      <Container>
        <div className="koko-container">
          <img src={KokoGold} alt="koko-logo" />
          <div className="koko-num">{kokoNumber} KoKo</div>
          <div className="koko-desc">
            {t(
              'dailyChallengeQnView:allCorrectModal.youHave',
              'You have completed '
            )}
            <br />
            {challengesNumber}
            {t(
              'dailyChallengeQnView:allCorrectModal.challToday',
              ' challenges today!'
            )}
          </div>
        </div>
        <Button data-cy="claim-koko" onClick={onHide}>
          {t('dailyChallengeQnView:allCorrectModal.claim', 'Claim My KoKo')}
        </Button>
      </Container>
    </ModalContainer>
  );
};

export default ClaimKokoModal;
