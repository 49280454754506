import styled from 'styled-components';
import { LEADERBOARD_COLORS } from '../../constant';
// default subject = math = 1
const DEFAULT_SUBJECT = 1;
export const CategoryStyle = styled.div`
  background: ${({ subject }) =>
    LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].headerColor};
  width: ${({ width }) => width ?? '70%'};
  display: flex;
  align-self: center;
  justify-content: space-between;
  height: 60px;
  padding: 10px 20px;
  .categories-container {
    width: 60%;
    display: flex;
  }
  .right-container {
    display: flex;
    position: relative;
    .top-fifty {
      cursor: pointer;
      display: flex;
      div {
        padding: 0px 10px 0 35px;
        font-size: 15px;
      }
      img {
        position: relative;
        left: 35px;
        bottom: 8px;
        width: 65px;
        height: 50px;
      }
    }
  }
`;

export const CategoryType = styled.div`
  border-radius: 30px;
  padding: 0px 10px;
  margin-right: 15px;
  display: flex;
  font-size: 17px;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
  background: ${({ active, subject }) =>
    active
      ? '#FECE18'
      : LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].btnColor};
  color: ${({ active }) => (active ? '#261E00' : '#ffffff')};
  border: ${({ subject }) =>
    `1px solid ${LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].borderColor}`};
  box-shadow: ${({ active }) =>
    active ? 'inset 1px 3px 0 1px #D9B328' : 'none'};
  &:hover {
    background: ${({ subject }) =>
      LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].hoverColor};
  }
`;
