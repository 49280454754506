import React from 'react';
import PlayIcon from 'assets/img/icon-play-polygon.svg';
import OrangeIcon from 'assets/img/icon-play-polygon-orange.svg';

import { Container } from './ButtonPlay.styles';

const ButtonPlay = ({ variant, size, styles, onClick, tabletSize }) => {
  return (
    <Container
      size={size}
      tablet={tabletSize}
      style={{ ...styles }}
      onClick={onClick}
    >
      <div className={variant}>
        <img src={variant === 'light' ? OrangeIcon : PlayIcon} alt="play" />
      </div>
    </Container>
  );
};

export default ButtonPlay;
