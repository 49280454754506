import React from 'react';
import { useParams } from 'react-router-dom';
import Ribbon from 'components/Mission/Ribbon';
import { useTranslation, Trans } from 'react-i18next';
import MathCP from 'assets/img/sidepanel/CP.png';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'components/Shared/Spinner';
import { getTodayCPDetails } from 'store/dailyChallenge/cpsDetailSlice';
import { getEventSolution } from 'store/event/eventSlice';
import StyledContainer, {
  ModalContainer,
  StyledButton,
} from './B2CClaimedCPModal.styles';
import { ErrorText } from './ClaimedCPModal.styles';

const B2CClaimedCPModal = ({ setShowClaimed }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['sundayMCResult', 'notification', 'common']);
  const { isClaiming, eventSolution, claimCPError } = useSelector(
    (state) => state.event
  );
  const cpAmt = eventSolution?.ClaimedCPPoints ?? '0';
  const { smcId } = useParams();
  const closeModalHandler = async () => {
    await dispatch(getTodayCPDetails());
    await dispatch(getEventSolution(smcId));
    setShowClaimed(false);
  };
  return (
    <ModalContainer>
      <Ribbon
        text={t('sundayMCResult:claimedModal.congrats', 'Congratulations!')}
      />
      <StyledContainer>
        {isClaiming && <Spinner />}
        {!isClaiming && claimCPError === null && (
          <>
            <div className="icon">
              <img src={MathCP} alt="CP" width="70px" />
              <p className="cp-amt">
                {`${cpAmt} `}
                <span>{t('common:cps', 'CPs')}</span>
              </p>
            </div>
            <p className="claim-text">
              <Trans
                i18nKey="sundayMCResult:claimedModal.claimedCP"
                components={[
                  <>You have claimed {{ cpAmt }}</>,
                  // eslint-disable-next-line react/jsx-key
                  <span> CPs.</span>,
                ]}
                defaults={`You have claimed {{cpAmt}} <1> CPs.<1/>`}
              />
            </p>
            <StyledButton onClick={closeModalHandler}>
              {t('notification:btn', 'Got It')}
            </StyledButton>
          </>
        )}
        {!isClaiming && claimCPError && (
          <ErrorText>
            <h3>Error</h3>
            <h4>{claimCPError}</h4>
          </ErrorText>
        )}
      </StyledContainer>
    </ModalContainer>
  );
};

export default B2CClaimedCPModal;
