import styled from 'styled-components';

export const CanvasContent = styled.div`
  position: absolute;
  user-select: none;
  background-color: ${({ bgColor }) => bgColor ?? ''};
`;

export const CanvasEl = styled.canvas`
  position: absolute;
  pointer-events: none;
`;

export const Toolbar = styled.div`
  position: absolute;
  bottom: 3rem;
  user-select: none;
`;

export const Button = styled.button`
  background: ${({ active }) => (active ? '#4aa3b4' : '#f7fdff')};
  color: ${({ active }) => (active ? '#f7fdff' : '#4aa3b4')};
  border: 1px solid #4aa3b4;
  outline: none;
`;

export const CustomCursor = styled.div`
  width: 25px;
  height: 25px;
  z-index: 2000;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  background: black;
  display: none;
`;
