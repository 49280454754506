import React, { useState, useEffect } from 'react';
import { isNil } from 'ramda';
import xmlParser from 'helpers/xmlParser';
import convertTagsToHtml from 'helpers/convertTagsToHtml';
import HTMLReactParser from 'html-react-parser';
import { useSelector } from 'react-redux';
import { Container, Content } from './Answer.styles';

const QuestionAnswer = ({ xml, qnType }) => {
  const filteredXml = !isNil(xml) ? xml.replace(/\\/g, '') : null;
  const { voiceOverFileExist } = useSelector((state) => state.voiceover);
  // Component States
  const [questionAnswers, setQuestionAnswers] = useState(null);
  // UseEffects
  useEffect(() => {
    if (!isNil(filteredXml)) {
      const parsed = xmlParser(filteredXml);
      if (qnType === 'mcq') {
        setQuestionAnswers(
          parsed.mcqOptions.correctOptions.map((answer) => {
            return {
              Key: answer.refId,
              Value: HTMLReactParser(convertTagsToHtml(answer.content)),
            };
          })
        );
      } else {
        setQuestionAnswers(
          parsed.questionAnswers.map((answer) => {
            const regex = /EQN\s?[( ](.*?)[)]/;
            const match = answer.$.Value.match(regex);
            if (match) {
              const value = match[1];
              return {
                ...answer.$,
                Value: value,
              };
            }
            return answer.$;
          })
        );
      }
    }
  }, [filteredXml, qnType]);

  return (
    <Container data-cy="answer" voiceOverFileExist={voiceOverFileExist}>
      <p className="label">Answer:</p>
      <div className="answer-wrapper">
        {questionAnswers !== null &&
          questionAnswers.map((answer) => (
            <Content
              key={answer.Key}
              data-cy={`answer-${answer.Key}`}
              text={answer.Type}
            >
              {answer.Value}
            </Content>
          ))}
      </div>
    </Container>
  );
};

export default QuestionAnswer;
