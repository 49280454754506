import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;
  vertical-align: middle;
  font-size: 0.9em;
  z-index: 3;
`;

export const Control = styled.div`
  position: relative;
  overflow: hidden;
  width: 164px;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 6px 52px 6px 10px;
  transition: all 200ms ease;
`;

export const Placeholder = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: 4px;
  vertical-align: middle;
  box-sizing: inherit;
`;

export const Arrow = styled.span`
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
  ::before {
    content: '';
    background-color: #fff;
    width: 28px;
    height: 28px;
    position: absolute;
    top: -20px;
    right: -16px;
  }
  ::after {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    position: absolute;
    right: -6px;
    top: -8px;
    width: 0;
  }
`;

export const Menu = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  overflow-y: auto;
  padding: 4px;
  position: absolute;
  top: 100%;
  z-index: 1000;
`;

export const Option = styled.div`
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: ${(props) => (props.clickAble ? 'pointer' : 'not-allowed')};
  display: block;
  padding: 8px 10px;
  white-space: nowrap;
  background-color: ${(props) => (props.clickAble ? 'transparent' : '#ccc')};
  :hover {
    ${(props) => props.clickAble && 'background-color: #ffdf32 '}
  }
`;
