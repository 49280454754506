import React from 'react';
import { Container } from './CheckBox.styles';

const CheckBox = (props) => {
  const { checked, disabled, onChange, id, value, label, color } = props;
  return (
    <Container color={color}>
      <div className="container-checkbox">
        <input
          type="checkbox"
          className="check-box"
          onChange={onChange}
          id={id}
          disabled={disabled}
          checked={checked}
          value={value}
        />
        <span className="checkmark" />
        <label htmlFor={id}>{label}</label>
      </div>
    </Container>
  );
};

export default CheckBox;
