import { useEffect } from 'react';

const useOnClickOutside = (refs, excludeClasses, onClickOutside) => {
  useEffect(() => {
    // Bind the event listener
    function handleClickOutside(event) {
      const classList = Array.from(event.target.classList);
      const exclude = excludeClasses.some((r) => classList.indexOf(r) >= 0);
      if (!exclude) {
        const handle = refs.reduce(
          (p, c) =>
            p &&
            (c.current === null ||
              (c.current && !c.current.contains(event.target))),
          true
        );
        if (handle) {
          onClickOutside();
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [excludeClasses, onClickOutside, refs]);
};
export default useOnClickOutside;
