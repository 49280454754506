import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchEventDetails = (ID) => {
  return fetch(`${baseUrl}SMC/GetSMCById/${ID}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get event details failed'); // eslint-disable-line
    });
};

export const openEventRequest = (ID) => {
  return fetch(`${baseUrl}SMC/OpenSMC/${ID}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Open event failed'); // eslint-disable-line
    });
};

export const saveSMCQuestionSubmissionRequest = (rawBody) => {
  return fetch(`${baseUrl}SMC/Save`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Save SMC question submission failed'); // eslint-disable-line
    });
};

export const closeEventRequest = (SMCSubId) => {
  return fetch(`${baseUrl}SMC/CloseSundayMiniChallenge/${SMCSubId}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Close event failed'); // eslint-disable-line
    });
};

export const submitSMCRequest = (SMCSubId) => {
  return fetch(`${baseUrl}SMC/TestSubmitToTeacher/${SMCSubId}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit Event failed');
    });
};

export const claimCPRequest = (SMCSubId) => {
  return fetch(`${baseUrl}SMC/ClaimCPPoints/${SMCSubId}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Claim Challenge Point failed');
    });
};

export const fetchSMCSolutions = (SMCSubId) => {
  return fetch(`${baseUrl}SMC/GetSolutionBySMCSubmissionId/${SMCSubId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get SMC solutions failed');
    });
};

export const fetchSMCStatus = () => {
  return fetch(`${baseUrl}SMC/GetStatus`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get SMC status failed');
    });
};
