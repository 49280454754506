import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchUserCurriculums,
  fetchUserProficiencyAndMasteryReport,
  fetchQuestionById,
} from 'services/report';
import { fetchWrapper } from 'services/login';
import { subjectIDs } from 'constants/products';

// Redux Thunks
export const getUserCurriculums = createAsyncThunk(
  'report/getUserCurriculums',
  async (params, { getState }) => {
    try {
      const res = await fetchWrapper(fetchUserCurriculums, params);
      if (params?.filterSubject === true) {
        // Filter curriculum based on products user has subscription
        const { plan } = getState();
        const { products } = plan;
        const nonExpiredProducts = products
          .filter(
            (product) => product.nonExpiredSubscriptionProducts.length > 0
          )
          .map((product) => product.subject);
        const curriculumsWithSubscription = res.filter((curriculum) =>
          nonExpiredProducts.includes(subjectIDs[curriculum.SubjectID])
        );
        return curriculumsWithSubscription;
      }
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get user curriculums failed');
    }
  }
);

export const getUserReport = createAsyncThunk(
  'report/getUserReport',
  async (params) => {
    try {
      const res = await fetchWrapper(
        fetchUserProficiencyAndMasteryReport,
        params
      );
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get user proficiency and mastery report failed'
      );
    }
  }
);

export const getQuestionById = createAsyncThunk(
  'report/getQuestionById',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchQuestionById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get question by id failed');
    }
  }
);

export const initialState = {
  report: null,
  isLoading: false,
  error: null,
  curriculums: [],
  curriculumsLoading: false,
  curriculumsError: null,
  sampleQn: null,
  sampleQnLoading: false,
  sampleQnError: null,
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
      state.curriculumsError = null;
    },
  },
  extraReducers: {
    [getUserCurriculums.pending]: (state) => {
      state.curriculumsLoading = true;
      state.curriculums = [];
      state.curriculumsError = null;
    },
    [getUserCurriculums.fulfilled]: (state, action) => {
      state.curriculumsLoading = false;
      state.curriculums = action.payload;
      state.curriculumsError = null;
    },
    [getUserCurriculums.rejected]: (state, action) => {
      state.curriculumsLoading = false;
      state.curriculums = [];
      state.curriculumsError = action.error.message;
    },
    [getUserReport.pending]: (state) => {
      state.isLoading = true;
      state.report = null;
      state.error = null;
    },
    [getUserReport.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.report = action.payload;
      state.error = null;
    },
    [getUserReport.rejected]: (state, action) => {
      state.isLoading = false;
      state.report = null;
      state.error = action.error.message;
    },
    [getQuestionById.pending]: (state) => {
      state.sampleQnLoading = true;
      state.sampleQn = null;
      state.sampleQnError = null;
    },
    [getQuestionById.fulfilled]: (state, action) => {
      state.sampleQnLoading = false;
      state.sampleQn = action.payload;
      state.sampleQnError = null;
    },
    [getQuestionById.rejected]: (state, action) => {
      state.sampleQnLoading = false;
      state.sampleQn = null;
      state.sampleQnError = action.error.message;
    },
  },
});

const { actions, reducer } = reportSlice;
export const { resetError } = actions;
export default reducer;
