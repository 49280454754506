import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #f2f2f2;
  height: ${(props) =>
    props.page ? 'calc(100vh - 109px)' : 'calc(100vh - 50px)'};
  margin-top: 68px;
  width: ${(props) =>
    props.collapse ? '5%' : props.page ? '13%' : '184.312px'};
  @media (max-width: 1200px) {
    width: 76.8px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
    margin-top: 0px;
  }
`;
export const StyledSidebarWrap = styled.div`
  overflow-y: auto;
`;
