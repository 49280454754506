import styled from 'styled-components';

export const CardContainer = styled.div`
  box-shadow: 0px 7px 0px 0px rgba(82, 75, 97, 0.28);
  width: 60%;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  background: ${(props) => `url(${props.hoverImg}) no-repeat`};
  background-size: cover;
  :hover {
    transform: scale(1.05);
    border: 5px solid white;
  }
  ${'' /* .overlay-container {
    background: ${(props) => `url(${props.hoverImg}) no-repeat`};
    background-size: cover;
    width: 100%;
    height: 100%;
    font-weight: 600;
  } */}
`;

export const Block = styled.div`
  background: ${(props) =>
    props.showOverlay ? 'none' : `url(${props.img}) no-repeat`};
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
  ${'' /* visibility: ${(props) => (props.showOverlay ? 'hidden' : 'auto')}; */}
  .desc-block {
    position: absolute;
    top: 10%;
    left: 7.7%;
    font-weight: 700;
    z-index: 10;
    width: fit-content;
    pointer-events: none;
  }
  .subject {
    color: white;
    font-size: 23px;
    margin: 0;
    font-weight: 700;
  }
  .title {
    color: #ffffff;
    font-size: 53px;
    text-shadow: ${(props) =>
      `2px 2px 2px ${props.textShadowColour ?? 'rgba(168, 58, 0, 0.4)'}`};
    line-height: 1;
    font-family: 'Linotte-Heavy';
    width: fit-content;
    pointer-events: none;
  }
  .sub-title {
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    width: fit-content;
    pointer-events: none;
  }
  @media (max-width: 1700px) {
    .title {
      font-size: 46px;
    }
  }
  @media (max-width: 1150px) {
    .title {
      font-size: 1.5rem;
    }
  }
`;

export const StartButton = styled.button`
  min-width: 110px;
  height: 39px;
  background: linear-gradient(#ff6c00, #ff4b00);
  border: none;
  border-radius: 50px;
  color: white;
  position: absolute;
  font-size: 26px;
  right: 4rem;
  bottom: 1.5rem;
  font-weight: 700;
  z-index: 10;
  @media (min-width: 1700px) {
    width: 120px;
    height: 45px;
    right: 7rem;
    bottom: 1.5rem;
    font-size: 1.5rem;
  }
  @media (max-width: 1150px) {
    width: 100px;
    height: 35px;
    font-size: 1.2rem;
    right: 2rem;
    bottom: 2rem;
  }
  @media (max-width: 900px) {
    right: 1rem;
  }
`;
