import React from 'react';
import { useTranslation } from 'react-i18next';
import RefreshIcon from 'assets/img/assignment/icon-refresh.svg';
import { Container } from './RefreshButton.styles';

const RefreshButton = ({ onClick }) => {
  const { t } = useTranslation(['assignmentList']);
  return (
    <Container>
      <button onClick={onClick}>
        <span>
          <img src={RefreshIcon} alt="icon" />
        </span>
        <p className="refresh-text">
          {t('assignmentList:list.refreshButton', 'Refresh')}
        </p>
      </button>
    </Container>
  );
};

export default RefreshButton;
