import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  setVoiceFileExist,
  setVoiceIsPlayingQn,
  clearVoiceoverPlaying,
  setVoiceDownloaded,
} from 'store/voiceover/voiceoverSlice';
import { useDispatch, useSelector } from 'react-redux';
import { StyledContainer } from './Voiceover.styles';
import { VoiceoverIcon, VoiceGIF } from './img';
import useSound from 'use-sound';
const S3_BUCKET_URL = process.env.REACT_APP_AWS_S3_URL;
const Voiceover = ({ qnID }) => {
  const dispatch = useDispatch();
  const { voicePlayingQnId, voiceList } = useSelector(
    (state) => state.voiceover
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [play, { stop }] = useSound(voiceList[qnID], {
    volume: 1,
    onend: () => setIsPlaying(false),
  });

  useEffect(() => {
    return () => {
      stop();
      dispatch(setVoiceFileExist(false));
      dispatch(clearVoiceoverPlaying());
    };
  }, [stop, dispatch]);
  useEffect(() => {
    if (isPlaying && voicePlayingQnId !== qnID) {
      setIsPlaying(false);
      stop();
    }
  }, [stop, isPlaying, voicePlayingQnId, qnID]);
  const handlePlaying = () => {
    setIsPlaying((prev) => !prev);
    if (isPlaying) {
      stop();
    } else {
      dispatch(setVoiceIsPlayingQn(qnID));
      play();
    }
  };
  return (
    <StyledContainer isPlaying={isPlaying}>
      <VoiceoverIcon className="voice" onClick={handlePlaying} />
      <img src={VoiceGIF} alt="voice-gif" onClick={handlePlaying} />
    </StyledContainer>
  );
};
const readBlobAsDataURL = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read blob as data URL'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
const VoiceoverRef = ({ qnID }) => {
  const myRef = useRef();
  const dispatch = useDispatch();
  const { voiceList } = useSelector((state) => state.voiceover);
  const checkFiles = useCallback(async () => {
    const response = await fetch(`${S3_BUCKET_URL}voiceover/Qn_${qnID}.mp3`);
    if (!response.ok) {
      dispatch(setVoiceDownloaded({ qnID, file: false }));
      return false;
    }
    const blob = await response.blob();
    if (blob.type === 'audio/mpeg' && blob.size > 0) {
      try {
        const dataURL = await readBlobAsDataURL(blob);
        dispatch(setVoiceDownloaded({ qnID, file: dataURL }));
        dispatch(setVoiceFileExist(true));
        return true;
      } catch (e) {
        console.log('transform blob err', e);
        dispatch(setVoiceDownloaded({ qnID, file: false }));
        return false;
      }
    } else {
      dispatch(setVoiceDownloaded({ qnID, file: false }));
      return false;
    }
  }, [dispatch, qnID]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (voiceList[qnID] !== false && !voiceList[qnID]) {
            checkFiles();
          }
        }
      },
      { threshold: 0.5 } // trigger callback when component is 50% visible
    );
    const currentRef = myRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      observer.unobserve(currentRef);
    };
  }, [checkFiles, voiceList, qnID]);

  return (
    <div ref={myRef} id={qnID}>
      {voiceList[qnID] && <Voiceover qnID={qnID} />}
    </div>
  );
};
export default VoiceoverRef;
