import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { splitEvery, isEmpty, isNil } from 'ramda';
import Header from 'components/Header';
import ChapterSelector from 'components/StoryMath/ChapterSelector';
import Timeline from 'components/StoryMath/Timeline';
import UnlockAlert from 'components/StoryMath/UnlockAlert';
import BackButton from 'components/Shared/BackButton';
import PopupModal from 'components/Shared/PopupModal';
import StoryMathLogo from 'assets/img/storymath/storymath.png';
import BookClubLogo from 'assets/img/storymath/bookclub.png';
import PortalLogo from 'assets/img/storymath/portal.png';
import Teacher from 'assets/img/storymath/teacher.png';
import TeacherHover from 'assets/img/storymath/teacher-hover.png';
import ArrowLeft from 'assets/img/storymath/left.png';
import ArrowLeftWhite from 'assets/img/storymath/left-white.png';
import ArrowRight from 'assets/img/storymath/right.png';
import ArrowRightWhite from 'assets/img/storymath/right-white.png';
import {
  getStoryChapters,
  getStoryWorlds,
  unlockChapterRequest,
  setSelectedChapter,
  setChapterList,
  resetUnlockChapterError,
  setSelectedStory,
} from 'store/storyMath/storyMathSlice';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { featureToggle } from 'constants/index';
import useDashboard from 'hooks/useDashboard';
import { unwrapResult } from '@reduxjs/toolkit';
import NotEnoughKokoModal from 'components/Shared/NotEnoughKokoModal';
import { withSubjectCheck } from 'helpers/hoc';
import { subjectIDs } from 'constants/products';
import {
  Page,
  Container,
  Content,
  StoryMathHeader,
  LeftWrapper,
  LogoWrapper,
  PortalWrapper,
  LevelWrapper,
  Logo,
  BookClub,
  Title,
  FloatButton,
  TeacherText,
  CarouselWrapper,
  Carousel,
  TimelineWrapper,
  ArrowWrapper,
  Arrow,
  SpinnerContainer,
} from './StoryMath.styles';

const RenderArrow = ({ type, onClickArrrow }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <Arrow
      role="button"
      tabIndex={0}
      onClick={onClickArrrow}
      onMouseOver={(e) => {
        e.currentTarget.src =
          type === 'left' ? ArrowLeftWhite : ArrowRightWhite;
      }}
      onFocus={(e) => {
        e.currentTarget.src =
          type === 'left' ? ArrowLeftWhite : ArrowRightWhite;
      }}
      onMouseOut={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeft : ArrowRight;
      }}
      onBlur={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeft : ArrowRight;
      }}
    >
      <img alt="arrow" src={type === 'left' ? ArrowLeft : ArrowRight} />
    </Arrow>
  );
};

const subjectId = 1;
const chosenSubject = subjectIDs[subjectId].toLowerCase().trim();

const StoryMath = () => {
  const { t, i18n } = useTranslation(['storyMath', 'dailyChallenge']);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading: isStudentDetailsLoading, defaultLevel } = useSelector(
    (state) => state.studentDetails
  );
  const {
    storyChapters,
    selectedStory,
    selectedChapter,
    isLoading,
    unlockChapterError,
  } = useSelector((state) => state.storyMath);
  const { isDashboardv2 } = useDashboard();
  const [index, setIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const splitedData = splitEvery(4, storyChapters);
  const isFromShortCut = location.state?.quickAccess;

  const onClickImage = useCallback(
    (data) => {
      dispatch(setSelectedChapter(data));
      if (data.Status === 'Locked') {
        setShowAlert(true);
      } else if (data.Status === 'Unlocked') {
        const redirectUrl = featureToggle.science
          ? `/story-book/subject/${subjectId}/viewer/${data.Id}`
          : `/story-book/viewer/${data.Id}`;
        history.push(redirectUrl);
      }
    },
    [dispatch, history]
  );

  const goToBookClub = useCallback(
    (e) => {
      e.preventDefault();
      history.push(`/story/math/book-club`);
    },
    [history]
  );

  const onAcceptUnlock = useCallback(() => {
    if (selectedChapter.Status === 'Unlocked') {
      const newChapterlist = [...storyChapters];
      const chapterIndex = storyChapters.findIndex(
        (obj) => obj.Id === selectedChapter.Id
      );
      newChapterlist[chapterIndex] = {
        ...newChapterlist[chapterIndex],
        Status: 'Unlocked',
      };
      dispatch(setChapterList(newChapterlist));
      setShowAlert(false);
    } else if (selectedChapter.Status === 'Locked') {
      dispatch(unlockChapterRequest(selectedChapter.Id));
    }
  }, [selectedChapter.Status, selectedChapter.Id, storyChapters, dispatch]);
  const closeUnlockModal = () => {
    setShowAlert(false);
    dispatch(resetUnlockChapterError());
  };

  const backHandler = () => {
    if (isFromShortCut) {
      history.goBack();
      return;
    }
    const destination = isDashboardv2 ? '/dashboard?view=koofun' : '/story';
    history.push(destination);
  };

  const checkInsufficientKoKoError = () => {
    return unlockChapterError === 'SPM042';
  };

  useEffect(() => {
    if (isStudentDetailsLoading) return;
    dispatch(getStoryWorlds())
      .then(unwrapResult)
      .then((promiseResult) => {
        const filteredWorlds = promiseResult.filter(
          (world) => world.SubjectId === subjectId
        );
        if (isEmpty(filteredWorlds)) return;
        // Search for default level world
        const defaultLevelWorld = filteredWorlds.find(
          (world) => world.LevelId === defaultLevel
        );
        if (isNil(defaultLevelWorld))
          dispatch(setSelectedStory(filteredWorlds[0]));
        dispatch(setSelectedStory(defaultLevelWorld));
      });
  }, [defaultLevel, dispatch, isStudentDetailsLoading]);

  useEffect(() => {
    if (!isEmpty(selectedStory) && selectedStory?.SubjectId === subjectId) {
      dispatch(getStoryChapters(selectedStory.Id));
      setIndex(0);
    }
  }, [dispatch, selectedStory]);

  const isIndonesianLang = i18n.language === 'ba';

  return (
    <Page>
      {isLoading && (
        <SpinnerContainer>
          <Spinner animation="border" />
        </SpinnerContainer>
      )}
      <Container>
        {unlockChapterError && checkInsufficientKoKoError && (
          <PopupModal show backdrop="static">
            <NotEnoughKokoModal
              yesHandle={() => {
                dispatch(resetUnlockChapterError());
              }}
            />
          </PopupModal>
        )}
        <PopupModal show={showAlert} hide={closeUnlockModal}>
          <UnlockAlert
            onReject={closeUnlockModal}
            status={selectedChapter.Status}
            error={!checkInsufficientKoKoError && unlockChapterError}
            onAccept={() => onAcceptUnlock()}
            isLoading={isLoading}
          />
        </PopupModal>
        <Header />
        <Content>
          <StoryMathHeader>
            <LogoWrapper>
              <LeftWrapper>
                <BackButton
                  backText={t('storyMath:back', 'Back')}
                  btnBgColor="rgb(244,146,0,0.61)"
                  padding="5px 15px"
                  styles={{ marginRight: '8px', zIndex: '3' }}
                  onClick={backHandler}
                />
                <PortalWrapper>
                  <img src={PortalLogo} alt="Portal Logo" width="245px" />
                  <LevelWrapper lang={isIndonesianLang}>
                    <Title>{t('storyMath:letsGoTo', "Let's go to")}</Title>
                    <ChapterSelector />
                  </LevelWrapper>
                </PortalWrapper>
              </LeftWrapper>
              <Logo src={StoryMathLogo} />
              <BookClub href="#" onClick={goToBookClub}>
                <img src={BookClubLogo} alt="Book Club Logo" />
              </BookClub>
            </LogoWrapper>
          </StoryMathHeader>
          {!isLoading && (
            <CarouselWrapper>
              <ArrowWrapper type="left">
                {index !== 0 && (
                  <RenderArrow
                    type="left"
                    onClickArrrow={() => setIndex(index - 1)}
                  />
                )}
              </ArrowWrapper>
              <Carousel
                selected={index}
                bullets={false}
                infinite={false}
                organicArrows={false}
                button={false}
              >
                {splitedData.map((data, i) => {
                  const last = splitedData.length - 1 === i;
                  return (
                    <TimelineWrapper key={`wrap-${data.Id}`}>
                      <Timeline
                        key={data.Id}
                        last={last}
                        data={data}
                        onClickImage={onClickImage}
                      />
                    </TimelineWrapper>
                  );
                })}
              </Carousel>
              <ArrowWrapper type="right">
                {index !== splitedData.length - 1 && (
                  <RenderArrow
                    type="right"
                    onClickArrrow={() => setIndex(index + 1)}
                  />
                )}
              </ArrowWrapper>
            </CarouselWrapper>
          )}
        </Content>
      </Container>
      {false && (
        <FloatButton src={Teacher} hover={TeacherHover}>
          <TeacherText href="">
            {t('storyMath:switchToTeacher', 'Switch to Teacher')}
          </TeacherText>
        </FloatButton>
      )}
    </Page>
  );
};

export default withSubjectCheck(React.memo(StoryMath), chosenSubject, true);
