import styled from 'styled-components';

export const Content = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  > div {
    padding-top: 20%;
  }
`;
