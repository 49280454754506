import React, { forwardRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import { getB2BCPs } from 'store/dashboard/rewardsSlice';

import { ReactComponent as CPIcon } from 'assets/img/sidepanel/icon-cp.svg';
import { ReactComponent as Arrow } from 'assets/img/sidepanel/white-arrow.svg';
import { ReactComponent as MathCP } from 'assets/img/sidepanel/math-cp.svg';
import { ReactComponent as SciCP } from 'assets/img/sidepanel/sci-cp.svg';

import { Container, StyledToggle } from './CpBreakDown.styles';

const ToggleCP = ({ onClick }, ref) => {
  return (
    <StyledToggle
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      My CPs <Arrow className="arrow" />
    </StyledToggle>
  );
};

const CpBreakDown = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.plan);
  const { b2bCP } = useSelector((state) => state.rewards);

  useEffect(() => {
    dispatch(getB2BCPs());
  }, [dispatch, products]);

  return (
    <Container>
      <CPIcon className="cp" />
      <Dropdown>
        <Dropdown.Toggle as={forwardRef(ToggleCP)} id="custom-dropdown-cp" />
        <Dropdown.Menu>
          {b2bCP?.map((cps) => (
            <Dropdown.Item key={cps.subject}>
              {cps.subject === 'science' ? (
                <SciCP className="cp-icon" />
              ) : (
                <MathCP className="cp-icon" />
              )}
              {cps.TotalCP}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  );
};

export default CpBreakDown;
