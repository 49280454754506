import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  border-bottom: 1px #b7b7b7 dashed;
  padding: 10px 0;
  margin-bottom: 20px;
  width: 85%;
  align-items: center;
  .plan-details {
    flex: 1;
    margin-left: 10px;
    .plan-name {
      font-size: 18px;
      font-weight: 600;
    }
    .plan-date {
      font-size: 12px;
      color: #606060;
    }
  }
  .plan-price {
    align-self: flex-end;
    line-height: 1;
    .sgd {
      font-size: 17px;
      font-weight: 600;
    }
    .price {
      font-size: 31px;
      font-weight: 600;
      margin: 0 1px 0 3px;
    }
    .year,
    .tax {
      font-size: 12px;
      color: #343434;
    }
  }
`;
