import React, { useState } from 'react';
import LogoutIcon from 'assets/img/dashboardv2/logout_icon.png';
import CloseIconImg from 'assets/img/dashboardv2/close_icon.svg';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import avatar from 'assets/img/avatar.png';
import SideNav from 'components/Shared/SideNav';
import logout from 'helpers/logout';
import useDashboard from 'hooks/useDashboard';
import useClickOutside from 'helpers/useClickOutside';
import UserInfo from 'components/Dashboard/Dashboardv2/UserInfo';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';
import Bouns from './Bouns';
import {
  MobileHeaderAvatar,
  MobileHeaderAvatarPhoto,
  NavProfileContainerMobile,
  MenuContainer,
  MenuTopContent,
  CloseIcon,
  MenuBottomContent,
  LogoutButton,
} from './NavProfileMobile.styles';

const NavUserMenuMobile = ({ setMenuOpen }) => {
  const outsideClick = useClickOutside(() => setMenuOpen(false));
  const { subject } = useSelector((state) => state.plan);
  const { t } = useTranslation(['navigation']);
  const isAvatarXpClickable = !useMediaQuery({ maxWidth: rwd.mobileL });
  const handleLogout = () => logout();
  return (
    <MenuContainer ref={outsideClick} className="sidebar">
      <CloseIcon onClick={() => setMenuOpen(false)}>
        <img src={CloseIconImg} alt="close" />
      </CloseIcon>
      <MenuTopContent subject={subject}>
        <UserInfo subject={subject} isAvatarXpClickable={isAvatarXpClickable} />
      </MenuTopContent>
      <MenuBottomContent>
        <LogoutButton onClick={handleLogout}>
          <img src={LogoutIcon} alt="logout" />
          <span>{t('navigation:menu.logout', 'Logout')}</span>
        </LogoutButton>
      </MenuBottomContent>
    </MenuContainer>
  );
};

const NavProfileMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { currentProduct, subject } = useSelector((state) => state.plan);
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const { isDashboardv2 } = useDashboard();
  const profilePicture = () => {
    if (studentDetails && studentDetails.ProfileAvatarImage !== null) {
      return studentDetails && studentDetails.ProfileAvatarImage;
    }
    return avatar;
  };
  return (
    <NavProfileContainerMobile>
      {!isNil(currentProduct) && isDashboardv2 && <Bouns />}
      <MobileHeaderAvatar subject={subject} onClick={() => setMenuOpen(true)}>
        <MobileHeaderAvatarPhoto src={profilePicture()} alt="Profile" />
      </MobileHeaderAvatar>
      <SideNav show={menuOpen}>
        <NavUserMenuMobile setMenuOpen={setMenuOpen} />
      </SideNav>
    </NavProfileContainerMobile>
  );
};

export default NavProfileMobile;
