import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { isNil } from 'ramda';
import convertTagsToHtml from 'helpers/convertTagsToHtml';
import questionParser from 'helpers/questionParser';
import { McqOptionsList, Container } from './QuestionContent.styles';

const QuestionContent = ({ data, qnNumber }) => {
  const { t } = useTranslation(['assignmentQnView']);
  const { Payload } = data;
  // States
  const [questionTitle, setQuestionTitle] = useState(null);

  const [mcqOptionsParsed, setMCQOptionsParsed] = useState([]);
  // Effects
  useEffect(() => {
    if (!isNil(Payload)) {
      const { title, mcqOptions } = questionParser({ Payload });
      const renderOptions = mcqOptions.map((option) =>
        HTMLReactParser(convertTagsToHtml(option, false), {
          replace: (domNode) => {
            if (domNode.name === 'img') {
              const newDomNode = domNode;
              newDomNode.attribs.style = 'width: 68%';
              return newDomNode;
            }
            return domNode;
          },
        })
      );
      setMCQOptionsParsed(renderOptions);
      setQuestionTitle(
        HTMLReactParser(convertTagsToHtml(title), {
          replace: (domNode) => {
            if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
              const newDomNode = domNode;
              return (
                <img
                  src={newDomNode.attribs?.url}
                  alt={newDomNode.attribs?.uniqueid || ''}
                />
              );
            }
            if (domNode.name === 'helptext') {
              return (
                <small id="questionHelp" className="form-text text-muted">
                  {domNode.children[0]?.data ?? ''}
                </small>
              );
            }
            if (domNode.name === 'input') {
              return <input type="text" />;
            }
            if (domNode.name === 'textarea') {
              return <textarea style={{ width: '100%' }} />;
            }
            return domNode;
          },
        })
      );
    }
  }, [Payload]);
  return (
    <Container className="question-item-body" data-cy={`qn-${qnNumber}`}>
      <div className="media">
        <div className="media-body">
          <div className="qs-inner">
            <div className="qs-attr">
              <div className="row">
                <div className="col-md">
                  <div className="d-flex">
                    <span>
                      {t('assignmentQnView:sidebar.qn', 'Question')} {qnNumber}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="qs-text">
              {questionTitle}
              {mcqOptionsParsed.length > 0 && (
                <McqOptionsList data-cy="mcq-options">
                  {mcqOptionsParsed.map((option) => (
                    <div className="options-container" key={option}>
                      <div className="option-dot" />
                      <div className="options" key={option}>
                        {option}
                      </div>
                    </div>
                  ))}
                </McqOptionsList>
              )}
              <hr />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default QuestionContent;
