import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background: #fff;
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & > img:first-child {
      margin-right: 12px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 33px;
  font-family: Arial, sans-serif;
  margin-top: 8px;
  color: #4e4e4e;
`;
