import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getParentDetails,
  getParentSubsDetails,
} from 'store/parent/parentSettingsSlice';

import Sidebar from 'components/ParentPortal/Sidebar';
import ChildAccountDetail from 'components/ParentPortal/ChildAccountDetail';
import BillV2 from 'components/ParentPortal/BillV2';
import ParentAccountDetail from 'components/ParentPortal/ParentAccountDetail';
import MockTimeTool from 'components/Shared/MockTimeTool';
import { Container, Content } from './Settings.styles';

const sidebarList = [
  {
    id: 1,
    name: 'Bill & Subscription',
    icon: 'bill-subscription',
  },
  {
    id: 2,
    name: 'Child Account Details',
    icon: 'child-detail',
  },
  {
    id: 3,
    name: 'Parent Account Details',
    icon: 'parent-detail',
  },
];

const Settings = ({ match }) => {
  const dispatch = useDispatch();
  const activeTab = match.params.tab;
  const { userID } = useSelector((state) => state.login);

  useEffect(() => {
    if (activeTab === 'bill-subscription') {
      dispatch(getParentSubsDetails());
    }
    if (userID && activeTab !== 'bill-subscription') {
      dispatch(getParentDetails(userID));
    }
  }, [dispatch, userID, activeTab]);

  return (
    <Container>
      <MockTimeTool />
      <Sidebar activeTab={activeTab} data={sidebarList} />
      <Content>
        {activeTab === 'child-detail' && <ChildAccountDetail />}
        {activeTab === 'bill-subscription' && <BillV2 />}
        {activeTab === 'parent-detail' && <ParentAccountDetail />}
      </Content>
    </Container>
  );
};

export default Settings;
