import { featureToggle } from 'constants/index';

const premiumNavs = [
  {
    name: 'Home',
    to: '/dashboard',
    icon: 'home',
    featureConfigKey: 'dashboard',
    isFeatureReady: true,
    isExternalLink: false,
  },
  {
    name: 'Report',
    to: '/report/proficiency',
    icon: 'report',
    featureConfigKey: 'report',
    isFeatureReady: true,
    isExternalLink: false,
  },
  {
    name: 'Leaderboard',
    to: '/leaderboard',
    icon: 'leaderboard',
    featureConfigKey: 'leaderboard',
    isFeatureReady: featureToggle.leaderboard,
    isExternalLink: false,
  },
  {
    name: 'Achievement',
    to: '/challenges/daily-challenge/hero-badge',
    icon: 'achivement',
    featureConfigKey: 'achievement',
    isFeatureReady: true,
    isExternalLink: false,
  },
  {
    name: 'Help',
    to: 'https://support.koobits.com/hc',
    icon: 'help',
    featureConfigKey: 'help',
    isFeatureReady: true,
    isExternalLink: true,
  },
];

export default premiumNavs;
