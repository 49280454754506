import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ProgressContainer } from './SkillsProgress.styles';
import ProgressBarWithMarkers from './ProgressBarWithMarkers';

const SkillsProgress = ({ numOfAStars, totalSkills, bgColour }) => {
  const { t, i18n } = useTranslation(['selfPractice']);

  return (
    <Container>
      <p>
        {t('selfPractice:header.youHaveGot', 'You have got ')}
        <span className="highlighted">
          <span className="num-of-stars">{numOfAStars}</span>{' '}
          {t('selfPractice:header.skills', 'A-Star')}
          {numOfAStars > 1 && i18n.language === 'en' && 's'}
        </span>
        {t('selfPractice:header.carryOn', ' Carry on!')}
      </p>
      <ProgressContainer bgColour={bgColour}>
        <ProgressBarWithMarkers
          numOfAStars={numOfAStars}
          totalSkills={totalSkills}
        />
        <span className="totalSkill">
          {t(
            'selfPractice:header.totalSkill',
            {
              number: totalSkills,
            },
            `(Total ${totalSkills} skills)`
          )}
        </span>
      </ProgressContainer>
    </Container>
  );
};

export default SkillsProgress;
