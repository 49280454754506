import styled from 'styled-components';
import StoryScienceBg from 'assets/img/storyscience/story-science-bg.png';
import ShelfSvg from 'assets/img/storyscience/shelf.svg';

const paddingTop = 185;

export const Page = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  box-sizing: inherit;
  padding-top: 72px;
`;

export const LogoWrapper = styled.div`
  position: relative;
  height: 80px;
  margin-bottom: 25px;
  text-align: center;
`;

export const Logo = styled.img`
  height: 77px;
`;

export const Content = styled.div`
  width: 100vw;
  height: 100%;
  background-image: url(${StoryScienceBg});
  background-size: cover;
`;

export const StoryHeader = styled.div`
  position: fixed;
  width: 100vw;
  height: 4.5rem;
  text-align: center;
  background-color: #ffdf32;
  z-index: 5;
`;

export const LeftWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 1em;
  left: 3.2em;
  align-items: center;
`;

export const Wrapper = styled.div`
  margin-left: 10rem;
  padding-bottom: 6rem;
`;

export const Sidebar = styled.div`
  width: 10rem;
  height: 100%;
  position: fixed;
  padding-top: 60px;
  background-color: #4c5d0d;
  z-index: 5;

  .menu {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: -10px;
    margin-right: 10px;
    color: #c0e350;
    font-size: 18px;

    &:hover {
      cursor: pointer;
      background-color: #6b8312;
      border-radius: 0px 20px 20px 0px;
    }
  }

  .selected {
    background-color: #6b8312;
    border-radius: 0px 20px 20px 0px;
  }
`;

export const ShelfContainer = styled.div`
  .line {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 50px;
    padding-top: ${paddingTop + 100}px;

    &::before {
      content: '';
      z-index: 2;
      height: 50px;
      position: absolute;
      background-repeat: repeat-x;
      left: 10rem;
      right: 0;
      background-image: url(${ShelfSvg});
      box-shadow: 0px 10px 7px -2px rgba(62, 86, 0, 1);
    }
  }

  .first-line {
    padding-top: ${paddingTop + 50}px;
  }
`;
