import React from 'react';
import { useTranslation } from 'react-i18next';
import bannerImage from 'assets/img/monstershowcase/hotd-monster-board-banner.png';
import {
  BannerContainer,
  DateDisplay,
  DescriptionDisplay,
  ButtonDisplay,
} from './HOTDBanner.style';

function HOTDBanner() {
  const { t } = useTranslation(['kokomonster']);
  const today = new Date();
  const dateString = today.toLocaleString('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  return (
    <BannerContainer image={bannerImage}>
      <DateDisplay>{dateString}</DateDisplay>
      <DescriptionDisplay>
        {t(
          'kokomonster:banner.monsterShowcase',
          `To showcase your KoKo Monster to your friends, you need to earn at least
          1 Challenge Point to make your monster appear in your class leaderboard.`
        )}
      </DescriptionDisplay>
      <ButtonDisplay>
        {t(
          'kokomonster:banner.earnChallengePoints',
          'Earn Challenge Points now'
        )}
      </ButtonDisplay>
    </BannerContainer>
  );
}

export default React.memo(HOTDBanner);
