import styled from 'styled-components';
import { Main as IncomingMain } from './MyFriends.styles';

export const Main = styled(IncomingMain)`
  background-color: #f7f7f7;
  background-image: none;
  border-top: 1px solid #dddddd;
  min-height: 500px;
`;

export const Incomingbutton = styled.div`
  display: flex;
`;

export const Button = styled.div`
  border: 1px solid ${(props) => (props.accept ? '#ff6701' : '#ff6701')};
  background-color: ${(props) => (props.accept ? '#FF7121' : '#ffffff')};
  color: ${(props) => (props.accept ? '#ffffff' : '#e35b00')};
  margin-right: 20px;
  border-radius: 30px;
  padding: 3px 12px;
  cursor: pointer;
  &:hover {
    color: #ff792e;
    background-color: ${(props) => (props.accept ? '#ffffff' : '#FFFAF7')};
  }
`;

export const ShowDate = styled.div`
  font-size: 12px;
  color: #707070;
  text-align: left;
  width: 61%;
  margin-top: 5px;
`;

export const EmptyList = styled.div`
  margin: auto;
  h4 {
    font-weight: 700;
  }
`;
