import React, { useState, useEffect } from 'react';
import { SpinnerContainer } from './Spinner.styles';

const Spinner = ({ top, position, gridColumn, small, color, left }) => {
  const [showSpinner, setShowSpinner] = useState(false);

  // USEEFFECT
  useEffect(() => {
    // Delay in milliseconds before spinner is shown
    const delay = 2000;
    const timerID = setTimeout(() => {
      setShowSpinner(true);
    }, delay);
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  return (
    <SpinnerContainer
      top={top}
      position={position}
      gridColumn={gridColumn}
      small={small}
      color={color}
      showSpinner={showSpinner}
      left={left}
    >
      Loading...
    </SpinnerContainer>
  );
};

export default Spinner;
