import styled from 'styled-components';

export const Topbar = styled.div`
  background: ${(props) =>
    props.background ?? 'linear-gradient(#ffaa0b, #ff9623)'};
  font-size: 32px;
  font-weight: 600;
  color: white;
  text-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
  text-align: center;
  padding: 0.5rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  padding: 1% 10% 0%;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 3% 10% 0%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 3% 4% 0%;
  }
`;
