const checkDataType = (data, userGender) => {
  const { boysGlobalTop100List, girlsGlobalTop100List } = data;
  return userGender === 'Male'
    ? {
        name: 'boysGlobalTop100List',
        array: boysGlobalTop100List,
      }
    : {
        name: 'girlsGlobalTop100List',
        array: girlsGlobalTop100List,
      };
};

const searchCurrentUser = (students, userId) => {
  return students.find((student) => student.userId === userId);
};

const filterStudents = (students, userId) => {
  return students.filter((student) => student.userId !== userId);
};

const filterGlobalTop100CurrentUser = ({
  data,
  currentUserScore,
  userId,
  userGender,
}) => {
  const dataType = checkDataType(data, userGender);
  let result = dataType.array;

  // if not then return data, if yes then remove the user data
  const currentObject = searchCurrentUser(dataType.array, userId);

  if (currentObject && currentUserScore) {
    // remove the user data and update user
    result = filterStudents(dataType.array, userId);
    let newStudent = {
      ...currentObject,
      cp: currentUserScore.cP,
    };
    if (currentObject.rank !== currentUserScore.rank) {
      result = result.map((student) => {
        if (
          student.rank < currentObject.rank &&
          student.rank >= currentUserScore.rank
        ) {
          return { ...student, rank: student.rank + 1 };
        }
        return student;
      });
      newStudent = {
        ...currentObject,
        rank: currentUserScore.rank,
        cp: currentUserScore.cP,
      };
    }
    result.push(newStudent);
  }

  result = result.sort((a, b) => {
    if (a.cP === b.cP) {
      return new Date(a.submissionDate) - new Date(b.submissionDate);
    }
    return b.cP - a.cP;
  });
  return { ...data, [dataType.name]: result };
};

export default filterGlobalTop100CurrentUser;
