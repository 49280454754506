import styled from 'styled-components';

export const Container = styled.div``;

export const Toggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  color: #fff;
  height: 100%;
  cursor: pointer;
  background: ${(props) => props.show && '#333439'};
  padding: 0 0.5rem;
  &:hover {
    background: #333439;
  }
`;
export const Menu = styled.div`
  position: absolute;
  top: 4.2rem;
  min-width: 90px;
  background: #4a4947;
  color: #fff;
  margin-left: -20px;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-width: 10px 8px;
    border-style: solid;
    border-color: transparent;
    z-index: 1001;
    top: -19px;
    right: 10px;
    border-bottom-color: #343434;
  }
`;

export const Item = styled.div`
  display: flex;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  background: ${(props) => props.active && '#343434'};
  border: ${(props) => !props.active && '1px solid #71706e'};
  &:hover {
    background: #343434;
  }
  .options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .dot {
      border-radius: 50%;
      height: 16.5px;
      width: 16.5px;
      background: ${(props) => (props.active ? '#FACF0F' : '#9b9b9b')};
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      margin-bottom: 0px;
    }
  }
`;
