import React, { useState } from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Container from './PaypalPayment.styles';
import PaypalButton from './PaypalButton';

const PaypalPayment = ({ paymentDone, paymentFailed }) => {
  // Component States
  const [error, setError] = useState('');
  return (
    <>
      <Container>
        <p className="error-msg">{error}</p>
        <div className="paypal-section">
          <PayPalScriptProvider
            options={{
              'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
              vault: true,
              intent: 'subscription',
            }}
          >
            <PaypalButton
              setError={setError}
              paymentDone={paymentDone}
              paymentFailed={paymentFailed}
            />
          </PayPalScriptProvider>
        </div>
      </Container>
    </>
  );
};

export default PaypalPayment;
