import styled from 'styled-components';

export const Container = styled.div`
  .proficiency-legend {
    background-color: #e7f0f7;
    padding-top: 60px;
    height: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .proficiency-content {
    display: flex;
    flex-direction: row;
  }
  .year-top {
    span {
      font-size: 16px;
    }
  }

  .year-bottom {
    display: none;
  }
  @media (max-width: 900px) {
    .year-top {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .year-top {
      display: none;
    }

    .year-bottom {
      position: static;
      display: flex;
      font-size: 16px;
      align-items: center;
      margin-left: 0;
      span {
        margin-right: 0.5rem;
      }
    }
  }
  @media (max-width: 500px) {
    .year-bottom {
      font-size: 14px;
      flex-direction: column;
    }
  }
`;

export const AssignmentsList = styled.div`
  padding: 9rem 10% 0%;

  @media (max-width: 920px) {
    padding: 10rem 5% 0%;
  }
`;

export const TopBar = styled.div`
  position: fixed;
  background: #bff187;
  height: 70px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 150px;
  z-index: 10;
`;

export const EmptySplash = styled.div`
  text-align: center;

  p {
    font-size: 1.5625rem;
    color: #46535e;
  }
`;
