import styled from 'styled-components';

export const Header = styled.div`
  background: #ffffff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #469bb2;
  font-size: 18px;
  font-weight: bold;
  position: fixed;
  width: 100%;
  margin-top: -3px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 106px 20% 0;
  min-height: calc(100vh - 59px);
  .btn-section {
    display: flex;
    justify-content: flex-end;
    width: 79%;
    .btn {
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      outline: none;
      border-radius: 2px;
      &:focus {
        box-shadow: none;
      }
    }
    .orange {
      background: #ff5b00;
      color: #ffffff;
      border: 1px solid #ff5b00;
    }
    .white {
      background: #ffffff;
      color: #ff5b00;
      border: 1px solid #ffdac4;
      margin-right: 0.5rem;
    }
  }
  .check-box-container {
    position: absolute;
    margin-right: -38.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    p {
      margin-bottom: 0px;
      margin-left: -10px;
      color: #0d0d0d;
      font-weight: 600;
    }
  }
`;
