import React, { forwardRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Modal, Tooltip } from 'react-bootstrap';
import { ICON_WIDTH, ICON_WIDTH_MOBILE } from './constants';

const ANIMATION_TIME = '0.6s';
const HIDDEN_TIME = '0.3s';
// animation
const moveAnimation = ({ top, left, endTop, endLeft }) => keyframes`
  0% {
    top: ${top}px;
    left: ${left}px;
  }
  100% {
    top: ${endTop}px;
    left: ${endLeft}px;
  }
`;
const displayNoneAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
`;
const borderAnimation = keyframes`
  0% {
    top: -2%;
    left: -100%;
    right: -100%;
    bottom: -2%;
  }
  100%{       
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
const openAnimation = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
`;
const fadeInFadeoutAnimation = keyframes`
     0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
`;
export const StyledModal = styled((props) => <Modal {...props} />)`
  .modal-content {
    background: transparent;
    border: none;
    z-index: 1;
  }
`;
export const StyledContainer = styled.div`
  background: #0090e2;
  padding: 6.6rem 1.25rem 5.3rem;
  border-radius: 25px;
  box-shadow: 0 15px 0 #005c8d;
  display: flex;
  gap: 12px;
  position: relative;
  height: 450px;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 6.6rem 10px 20px;
  }
`;
StyledContainer.Spotlight = styled.div`
  position: absolute;
  z-index: -1;
  top: -104%;
  left: -38%;
  right: 0;
  opacity: 0;
  visibility: hidden;

  img {
    width: 128%;
    transform: scaleY(0.7);
  }
  ${({ open }) =>
    open &&
    css`
      animation: ${fadeInFadeoutAnimation} 2s linear infinite;
      visibility: visible;
    `}
`;
export const StyledLeftContainer = styled.div`
  background: #0ba1f7;
  padding: 3rem 6px;
  border-radius: 14px;
  flex: 1;
  ${({ open }) => {
    return (
      open &&
      css`
        animation: ${displayNoneAnimation} ${HIDDEN_TIME} linear both;
      `
    );
  }}
`;
export const StyledStage = styled.div`
  border-radius: 18px;
  background: #3cb8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  position: relative;
  &:nth-of-type(2) {
    margin: 1rem 0;
  }
  > div {
    display: flex;
    justify-content: center;
  }
  .first-icon {
    width: 72px;
    img {
      width: ${(props) => `${ICON_WIDTH[props.stageId]}px`};
    }
  }
  .last-icon {
    width: 20%;

    img {
      margin-right: 12px;
    }
    .lock {
      margin-top: 10px;
    }
  }
  .index {
    color: white;
    width: calc(100% - 72px - 20%);
    font-size: 24px;
    font-weight: bold;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    img {
      margin-left: 6px;
    }
  }
  ${({ active }) =>
    active &&
    css`
      &:after {
        border: 4px solid #ffd800;
        content: '';
        position: absolute;
        border-radius: 18px;
        animation: ${borderAnimation} ${ANIMATION_TIME} linear both;
        z-index: 2;
      }
    `}
  ${({ block }) =>
    block &&
    css`
      background: rgba(0, 0, 0, 0.2);
      .index,
      .first-icon {
        opacity: 0.2;
      }
    `}
    ${({ collected }) =>
    collected &&
    css`
      background: #3cb8ff;
      .index,
      .first-icon {
        opacity: 1;
      }
    `}


  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    gap: 10px;
    .first-icon {
      width: 35px;
      margin-left: 3px;
      img {
        width: ${(props) => `${ICON_WIDTH_MOBILE[props.stageId]}px`};
      }
    }
    .index {
      width: fit-content;
      font-size: 16px;
      padding-right: 0;
      img {
        width: 20px;
      }
    }
    .last-icon {
      img {
        margin-right: 3px;
      }
      .lock {
        margin-top: 5px;
        width: 28px;
      }
      .collected {
        width: 22px;
      }
    }
  }
`;

export const StyledRightContainer = styled.div`
  padding: 1.5rem 0.5rem;
  border-radius: 14px;
  background: #0ba1f7;
  flex: 3;
  color: #fff32c;
  font-weight: bold;
  text-align: center;
  ${({ open }) => {
    return (
      open &&
      css`
        animation: ${displayNoneAnimation} ${HIDDEN_TIME} linear both;
      `
    );
  }}

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    flex: 2;
    font-size: 14px;
  }
`;
export const StyledTitleImg = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -2%;
  transition: all ${ANIMATION_TIME};
  z-index: 2;
  img {
    position: absolute;
    transform: translateX(-50%);
  }
  .congratulations {
    visibility: hidden;
    opacity: 0;
    transition: all ${ANIMATION_TIME};
    left: 50%;
  }
  .koko-glitters {
    transition: all ${ANIMATION_TIME};
    left: 46%;
  }
  ${({ open }) => {
    return (
      open &&
      css`
        top: -8%;
        .congratulations {
          visibility: visible;
          opacity: 1;
        }
        .koko-glitters {
          visibility: hidden;
          opacity: 0;
        }
      `
    );
  }}

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    top: -5%;
    .congratulations {
      width: 90%;
    }
  }
`;

export const StyledGiftBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5% 0 0 -5%;
  width: 105%;
`;
StyledGiftBox.Item = styled.div`
  width: 20%;
  margin: 5% 0 0 5%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 1;
  }
  ${({ open }) => {
    return (
      open &&
      css`
        transform: scale(1.1);
        .box {
          opacity: 0;
        }
      `
    );
  }}

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 25%;
    margin-right: 6px;
    .bg {
      width: 55px;
    }
    .box {
      width: 30px;
    }
  }
`;
export const StyledResult = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  .title {
    position: relative;
    padding: 3.5rem 0 1.7rem;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: #009aeb;
    border-radius: 25px 25px 0 0;
    span {
      font-size: 2.25rem;
      color: #ffce00;
      display: inline-block;
      margin-left: 10px;
      margin-bottom: -6px;
    }
    img {
      width: 36px;
      margin: 0 10px 4px;
    }
  }
  ${({ open }) =>
    open &&
    css`
      .title {
        animation: ${openAnimation} ${ANIMATION_TIME} linear both;
      }
    `}

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .title {
      font-size: 20px;
    }
  }
`;
StyledResult.Box = styled.img`
  position: absolute;
  z-index: 2;
  animation: ${({ top, left, endTop, endLeft }) =>
    css`
      ${moveAnimation({
        top,
        left,
        endTop,
        endLeft,
      })} ${ANIMATION_TIME} linear both
    `};
`;
StyledResult.Stage = styled.img`
  position: absolute;
  z-index: 2;
  animation: ${({ top, left, endTop, endLeft }) =>
    css`
      ${moveAnimation({
        top,
        left,
        endTop,
        endLeft,
      })} ${ANIMATION_TIME} linear both
    `};
`;
StyledResult.Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 2.5rem 0;
  gap: 40px;
  margin-right: 42%;

  .item {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 1.5rem;
    margin-left: 4rem;
    color: white;
    font-weight: bold;
  }
  .text {
    display: flex;
    align-items: center;
    img {
      margin-left: 10px;
    }
    .lock {
      position: absolute;
      left: -40px;
      top: -8px;
    }
  }

  .stage,
  .gift-box-open,
  .lel {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .stage {
    left: ${({ stageTypeId }) => (stageTypeId === 1 ? -65 : -76)}px;
    top: 58%;
    visibility: hidden;
  }
  .gift-box-open {
    transform: scale(0.9) translateY(-50%);
    left: -84px;
    top: 24%;
    visibility: hidden;
  }
  .lel {
    left: -90px;
    ${({ lock }) =>
      lock &&
      css`
        left: -105px;
      `}
  }
  ${({ open }) => {
    return (
      open &&
      css`
        .lel,
        .text {
          animation: ${openAnimation} ${ANIMATION_TIME} ${ANIMATION_TIME} linear
            both;
        }
      `
    );
  }}
  ${({ hideOneTimeReward }) => {
    return (
      hideOneTimeReward &&
      css`
        .one-time-reward {
          display: none;
          .text {
            animation: unset;
            display: none;
          }
        }
      `
    );
  }}

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin: auto;
    width: fit-content;
    padding: 2rem 0;
  }
`;
export const StyledJumpLink = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: -20%;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  animation: ${openAnimation} ${ANIMATION_TIME} linear both;
  img {
    display: inline-block;
    width: 43px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .story {
    margin: 0 1.25rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;
export const StyledTooltipContainer = styled(
  forwardRef((innerProps, ref) => <Tooltip {...innerProps} ref={ref} />)
)`
  .tooltip-inner {
    background: #404447;
    border-radius: 14px;
    padding: 14px;
  }
  && {
    .arrow::before {
      border-top-color: #404447;
    }
  }
`;
export const StyledTooltipIcon = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 5px 10px;
  border: 1px solid #66aec4;
  background: #dcf1f8;
  color: #66aec4;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: -2.5rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ open }) => {
    return (
      open &&
      css`
        animation: ${openAnimation} ${ANIMATION_TIME} ${ANIMATION_TIME} linear
          both;
      `
    );
  }}
`;
export const StyledTooltip = styled.div`
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  span {
    color: #ffce00;
  }
  .dc-button {
    background: #555555;
    margin: 1rem 0;
    padding: 4px 12px;
    border-radius: 7px;
    cursor: pointer;
    img {
      margin-right: 4px;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: 12px;
    .dc-button {
      img {
        width: 25px;
      }
    }
  }
`;
export const StyledBlockLel = styled.div`
  position: absolute;
  top: -1rem;
  left: -8rem;
  right: -0.7rem;
  bottom: -1rem;
  background: rgba(39, 39, 39, 0.4);
  border-radius: 2rem;
  z-index: 1;
  animation: ${openAnimation} ${ANIMATION_TIME} ${ANIMATION_TIME} linear both;
`;
StyledBlockLel.Description = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: nowrap;
  img {
    display: inline-block;
    margin: 0 10px;
  }
`;
