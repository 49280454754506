import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  setActive,
  resetSubmitAns,
  getCurrentDailyChallenge,
  resetSubmitDailyChallengeAnswerIsErr,
} from 'store/dailyChallenge/dailyChallengeSlice';
import { clearAll } from 'store/assignment/barModelSlice';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import PopupModal from 'components/Shared/PopupModal';
import ErrorModal from 'components/Shared/ErrorModal';
import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';
import { SubmitModal } from 'components/Shared';
import QuestionFeedbackLink from 'components/Shared/QuestionFeedbackLink';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';
import { Container } from './DailyChallengeNotebookBottom.styles';

const DailyChallengeNotebookBottom = ({
  setCorrectAnswer,
  correctAnswer,
  setExpandQn,
  submitAnswer,
  isSkipAnswer,
  showModal,
  openModal,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallengeQnView', 'common']);
  // Redux States
  const {
    activeQsDailyChallenge,
    dailyChallengeQuestion,
    isLoading,
    submitDailyChallengeAnswerIsErr,
  } = useSelector((state) => state.dailyChallenge);

  // responsive
  const isMobile = useIsMobile();

  const researchAction = () => {
    dispatch(resetSubmitDailyChallengeAnswerIsErr());
    dispatch(getCurrentDailyChallenge());
  };

  const withLeftButton =
    correctAnswer === 'unknown' || correctAnswer === 'wrong';

  const withNextButton = activeQsDailyChallenge < dailyChallengeQuestion.length;

  const renderNextButton = (
    <Button
      variant="blue-light"
      onClick={() => {
        setExpandQn(true);
        dispatch(resetSubmitAns());
        dispatch(setActive(activeQsDailyChallenge + 1));
        setCorrectAnswer('unknown');
        dispatch(hideAllTools());
        dispatch(clearAll());
        clearValues();
        dispatch(setClearDraw(true));
      }}
    >
      {t('dailyChallengeQnView:nextQn', 'Next Question')}
    </Button>
  );

  return (
    <Container
      justifyContent={
        correctAnswer === 'correct' || correctAnswer === 'skipped'
          ? 'flex-end'
          : 'space-between'
      }
    >
      <PopupModal show={showModal} backdrop="static">
        <SubmitModal
          header={t('dailyChallengeQnView:skipQnModal.skipQn', 'Skip Question')}
          title={t(
            'dailyChallengeQnView:skipQnModal.areYouSure',
            'Are you sure want to skip this question?'
          )}
          desc={t(
            'dailyChallengeQnView:skipQnModal.modalText',
            'You will not get any Challenge Points if you view the answer directly. It will be considered as Question Skipped'
          )}
          noHandle={closeModal}
          yesHandle={() => {
            submitAnswer(true);
          }}
        />
      </PopupModal>
      {withLeftButton && (
        <div className="btn-left">
          <Button
            variant="blue-light"
            disabled={isLoading}
            data-cy="show-answer"
            onClick={openModal}
          >
            {isSkipAnswer && isLoading
              ? `${t('dailyChallengeQnView:submitting', 'Submitting...')}`
              : `${t('dailyChallengeQnView:showAnswer', 'Show Answer')}`}
          </Button>
          {withNextButton && !isMobile && renderNextButton}
        </div>
      )}
      <div className="btn-right">
        {isMobile ? (
          withLeftButton && withNextButton && renderNextButton
        ) : (
          <QuestionFeedbackLink
            qnID={dailyChallengeQuestion[activeQsDailyChallenge - 1]?.Id}
            skillID={
              dailyChallengeQuestion[activeQsDailyChallenge - 1]?.SkillId
            }
          />
        )}
      </div>
      {submitDailyChallengeAnswerIsErr && (
        <ErrorModal
          reloadAction={researchAction}
          errorMessage={submitDailyChallengeAnswerIsErr}
        />
      )}
    </Container>
  );
};

export default DailyChallengeNotebookBottom;
