import React, { useState, useEffect } from 'react';
import { getCPHistory } from 'store/cpHistory/cpHistorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { subjectNames } from 'constants/products';
import Header from 'components/Header';
import Spinner from 'components/Shared/Spinner';
import { StyledTitle } from './index.styles';
import { CPHistoryHeader, CPHistoryResult } from 'components/CPHistory';

const periodOptions = [1, 2, 3];
const CPHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cpHistory']);
  const { subject, isLoading: productsIsLoading, products } = useSelector(
    (state) => state.plan
  );
  const [selectPeriod, setSelectPeriod] = useState(1);
  const [loading, setLoading] = useState(true);
  const searchAction = () => {
    setLoading(true);
    dispatch(
      getCPHistory({
        period: selectPeriod,
        subjectId: subjectNames[subject],
      })
    ).then(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    if (!productsIsLoading && products) {
      searchAction();
    }
    //eslint-disable-next-line
  }, [selectPeriod, productsIsLoading, products]);
  return (
    <>
      <Header />
      {productsIsLoading && <Spinner />}
      {!productsIsLoading && products && (
        <>
          <StyledTitle subject={subject}>
            {t('title', 'My Challenge Point')}
          </StyledTitle>
          <CPHistoryHeader
            selectPeriod={selectPeriod}
            setSelectPeriod={setSelectPeriod}
            options={periodOptions}
            searchAction={searchAction}
          />
          <CPHistoryResult
            searchAction={searchAction}
            selectPeriod={selectPeriod}
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export default CPHistory;
