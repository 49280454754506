import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Header from 'components/Header';
import { checkSchoolPlan } from 'pages/Dashboard';
import { useSelector, useDispatch } from 'react-redux';
import { getSubscriptionPlans } from 'store/payment/paymentSlice';
import Standard from './Standard';
import NewStandard from './NewStandard';
import School from './School';
import Premium from './Premium';
import Plus from './Plus';
import { Page, Body } from './index.styles';

const PLAN_TYPES = {
  standard: <Standard />,
  school: <School />,
  premium: <Premium />,
  plus: <Plus />,
  newStandard: <NewStandard />,
};
const ExpireContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { studentDetails, isLoading, isExpired } = useSelector(
    (state) => state.studentDetails
  );
  const { plan, subject, products } = useSelector((state) => state.plan);

  const currentSubjectProduct = products?.find(
    (item) => item.subject.toLowerCase().trim() === subject
  );
  const isExpiredOTP =
    currentSubjectProduct?.otpTrialDetails?.otpStatus === 'otp-trial-expired';

  const isOTPEligible =
    currentSubjectProduct?.otpTrialDetails?.otpStatus === 'otp-not-started';

  useEffect(() => {
    dispatch(getSubscriptionPlans());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (plan === 'premium' && isExpiredOTP) {
      history.push('/expired-otp/math');
      return;
    }
    if (isOTPEligible) {
      history.push('/trial/math');
    }
    // eslint-disable-next-line
  }, [plan, isExpiredOTP]);

  let planToShow;
  if (studentDetails) {
    planToShow =
      process.env.REACT_APP_SCIENCE === 'true'
        ? plan
        : checkSchoolPlan(studentDetails.Subscriptions);
    if (planToShow === 'standard' && studentDetails.Roles.includes(103)) {
      planToShow = 'newStandard';
    }
  }

  const showPlan = (type) => {
    if (PLAN_TYPES[type]) {
      return PLAN_TYPES[type];
    }
  };
  if (!isExpired && !isLoading) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Page isPremium={planToShow === 'premium' || planToShow === 'plus'}>
      <Header />
      <Body>{!isLoading && showPlan(planToShow)}</Body>
    </Page>
  );
};

export default ExpireContainer;
