import styled from 'styled-components';
import {
  AddStepsIcon,
  BarModelIcon,
  FourOpsIcon,
  DrawingIcon,
} from 'assets/img/assist-tools';

export const Bounds = styled.div`
  position: fixed;
  padding-top: 50px;
  width: 100vw;
  height: calc(100vh + 50px);
  pointer-events: none;
`;

export const Container = styled.div`
  position: fixed;
  right: 0rem;
  min-height: 100vh;
  padding: 1rem 0.5rem;
  background: ${(props) => props.bgColor};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 65px;
  z-index: 11;
  margin-top: ${({ top }) => (top ? `${top}px` : '50px')};
  .helper {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 105%;
    p {
      display: none;
      font-size: 13px;
      line-height: 1.2;
      text-align: center;
      align-self: center;
      margin-bottom: 0rem;
    }
  }

  .drawing-kits {
    background: url(${DrawingIcon});
    color: #e66300;
    animation: ${({ animate }) => (animate ? `0.5s ${animate}` : '')};
    @keyframes fade-out {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    &:hover {
      p {
        display: block;
        font-size: 11px;
      }
      background: #f5d93a;
      box-shadow: 0px 2px 2px rgba(109, 106, 103, 0.47);
    }
  }
  .add-steps {
    background: url(${AddStepsIcon});
    color: #df5006;
    &:hover {
      p {
        display: block;
      }
      background: #ffd754;
      box-shadow: 0px 2px 2px rgba(109, 106, 103, 0.47);
    }
  }
  .bar-model {
    background: url(${BarModelIcon});
    color: #3b640b;
    &:hover {
      p {
        display: block;
      }
      background: #b0ef50;
      box-shadow: 0px 2px 2px rgba(20, 18, 13, 0.16);
    }
  }
  .four-ops {
    background: url(${FourOpsIcon});
    color: #773b00;
    &:hover {
      p {
        display: block;
      }
      background: #ff9d1d;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    }
  }
  .hint {
    background: #39bee5;
    color: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    &:hover {
      background: #00b4e9;
    }
    p {
      display: block;
      font-size: 13px;
      line-height: 1.5;
      text-align: center;
      align-self: center;
      margin-bottom: 0rem;
    }
  }
`;
