import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHotsDisplayOrder } from 'store/mission/missionSlice';
import StyledButton from './Hotsv2TopicButton.styles';

const Hotsv2TopicsButton = ({
  iconPosition = 'left',
  topicName = '',
  topicIcon,
  positionStyles = {},
  id,
  ageIndex,
  subject = 1,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clickHandler = (id, ageIndex) => {
    return () => {
      history.push({
        pathname: `/mission/self-practice/92`,
        search: `?ageIndex=${ageIndex}`,
        state: { subject },
      });
      dispatch(setHotsDisplayOrder(id));
    };
  };
  return (
    <StyledButton
      iconPosition={iconPosition}
      positionStyles={positionStyles}
      onClick={clickHandler(id, ageIndex)}
    >
      <img src={topicIcon} alt="" />
      <span>{topicName}</span>
    </StyledButton>
  );
};

export default Hotsv2TopicsButton;
