import styled from 'styled-components';
import Glitters from 'assets/img/mobile-toggle/icon-glitters.svg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
  text-align: center;

  .description {
    margin-top: 1rem;
  }

  .modal-header {
    color: #ffffff;
    background-color: #fd8d06;
    border-radius: 20px 20px 0px 0px;
    background-image: url(${Glitters});
    background-repeat: no-repeat;
    background-position: center;

    .modal-title {
      margin-top: 0.5rem;
      font-size: 1.3rem;
      font-weight: 700;
      text-align: center;
      width: 100%;
    }
  }

  .switch-version {
    margin-top: 1rem;
    font-size: 12px;
    font-weight: 600;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 1rem;
`;

export const VersionItem = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d6eaff;
  box-shadow: 1px 2px 2px #707070;
  border-radius: 10px;
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  font-weight: 600;
  padding-bottom: ${({ otpExpired }) => otpExpired && '1.2rem'};
  cursor: pointer;

  width: 100%;

  span {
    margin-right: 0.5rem;
  }

  &.active {
    background: #fff3ed;
    border: 2px solid #ffd0b5;
  }
`;

export const StyledButton = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

export const StyledDescription = styled.div`
  margin-top: 1rem;
  h4 {
    font-size: 18px;
    font-weight: 600;
    margin-left: 2rem;
    margin-bottom: 0rem;
    text-align: left;
  }

  li {
    font-size: 12px;
    text-align: left;
  }
`;
