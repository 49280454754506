import styled from 'styled-components';

export const Container = styled.div``;

export const QuestionContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: 0rem;
  border-bottom: ${(props) => (props.isLastQn ? null : '2px dashed #cae3e5')};
  .qn-no {
    font-size: 18px;
    font-weight: 600;
    color: #ff8c19;
    padding-left: 1rem;

    .qn-id {
      font-size: 12px;
      color: rgb(57, 57, 57, 0.44);
      font-weight: 300;
      margin-left: 0.5rem;
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      margin-bottom: 0;
    }
  }
  .assist-tools {
    display: none;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    color: #11bdd8;
    p {
      margin-bottom: 0rem;
      margin-right: 1rem;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-right: 2rem;

    .assist-tools {
      display: flex;
    }
  }
`;
