import styled from 'styled-components';

export const InpContainer = styled.input.attrs((props) => ({
  type: props.type,
  value: props.val,
}))`
  width: 70%;
  height: 38px;
  padding: 0px 0.5rem;
  border: 1px solid rgba(112, 112, 112, 0.36);
  background-color: #ffffff;
  color: #000000;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #e5e5e5;
  }
`;
