import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, isEmpty } from 'ramda';

import { getTerms } from 'store/leaderboard/leaderboardSlice';
import { getTopBrainLeaderboard } from 'store/leaderboard/topBrainLeaderboardSlice';

import formatChineseNumber from 'helpers/formatChineseNumber';

import DropdownMenu from 'components/Shared/DropdownMenu';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import { HeaderScienceTB } from './components';
import { Category, TopBrainList, NoParticipants } from '../../components';

import { PRIMARY_LEVELS, LEADERBOARD_ID, PERIOD_TYPE } from '../../constant';

import { Container } from './TopBrain.styles';

const TopBrainScience = () => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();

  const { data, isLoading, error } = useSelector((state) => state.topBrainV2);
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const [activeCategory, setActiveCategory] = useState(2);
  const [selectedLevel, setSelectedLevel] = useState(defaultLevel);
  const [currentTerm, setCurrentTerm] = useState(null);

  const showLevels = (level) => {
    return `${t('leaderboard:tabs.primary', 'Primary')} ${formatChineseNumber({
      value: level,
      language: i18n.language,
    })}`;
  };

  const getTopBrainData = (isTop50) => {
    const params = {
      id: LEADERBOARD_ID.topBrain.science,
      category: activeCategory,
      level: selectedLevel,
      periodId: currentTerm?.id,
      periodType: PERIOD_TYPE.term,
      isTop50,
    };
    dispatch(getTopBrainLeaderboard(params));
  };

  const levelHandler = (value) => {
    setSelectedLevel(value);
  };

  const categoryHandler = (filter) => {
    setActiveCategory(filter);
     if (filter === 4) {
      setSelectedLevel(defaultLevel);
    }
  };

  const termHandler = (item) => {
    setCurrentTerm(item);
  };

  useEffect(() => {
    if (isNil(selectedLevel)) {
      setSelectedLevel(defaultLevel);
    }
  }, [defaultLevel, selectedLevel]);

  useEffect(() => {
    dispatch(getTerms());
  }, [dispatch]);

  useEffect(() => {
    if (!isNil(selectedLevel) && !isNil(currentTerm)) {
      getTopBrainData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel, activeCategory, currentTerm]);

  return (
    <Container>
      <HeaderScienceTB
        term={currentTerm}
        termHandler={termHandler}
        setTerm={setCurrentTerm}
      />
      <Category
        top50Available
        availableCategory={['class', 'school', 'national']}
        active={activeCategory}
        categoryHandler={categoryHandler}
        top50Handler={() => getTopBrainData(true)}
      >
        <DropdownMenu
          dataCy="dropdown-level"
          selectedValue={showLevels(selectedLevel)}
          values={PRIMARY_LEVELS}
          setValue={levelHandler}
          valueTemplate={showLevels}
          width="150px"
          className="drop-down"
          widthipad="170px"
        />
      </Category>
      {isLoading && <Spinner />}
      {!isLoading && !isEmpty(data) && isNil(error) && (
        <TopBrainList data={data} category={activeCategory} />
      )}
      {!isLoading && isEmpty(data) && isNil(error) && <NoParticipants />}
      {!isLoading && !isNil(error) && (
        <ErrorModal errorMessage={error} reloadAction={getTopBrainData} />
      )}
    </Container>
  );
};

export default TopBrainScience;
