import styled from 'styled-components';
import BackgroundSVG from 'assets/img/dailychallenge/science/card-background.svg';

const checkStatusColor = (obj, status) => {
  switch (status) {
    case 'complete':
      return '#00d464';
    case 'skipped':
      return 'rgb(204,204,204)';
    default:
      return obj.colorBg;
  }
};

const checkRadialColor = (obj, status) => {
  switch (status) {
    case 'complete':
      return null;
    case 'skipped':
      return 'radial-gradient(circle, rgba(204,204,204,1) 0%, rgba(153,153,153,1) 100%)';
    default:
      return obj.colorRadial;
  }
};

const currentWidth = {
  ipadWidth: 240,
  desktopWidth: 260,
};

export const StyledCard = styled.div`
  display: flex;
  max-width: 480px;
  flex-direction: row;
  z-index: 5;
  width: 100%;
  position: relative;
  background: #fff;
  margin: auto;
  margin-bottom: 1rem;
  border-radius: 12px;
  box-shadow: 0px 3px #bce5c2;
  button.btn {
    font-size: 18px;
  }
  &:hover {
    transition: 0.5s;
    box-shadow: 0px 0px 24px 8px rgba(135, 255, 249, 1);
  }

  @media (min-width: 1576px) {
    width: ${currentWidth.desktopWidth}px;
  }
  .top-section {
    height: 100%;
    padding: 0px 1rem;
    border-radius: 12px 0px 0px 12px;
    background: ${({ obj, status }) => checkStatusColor(obj, status)};
    background: ${({ obj, status }) => checkRadialColor(obj, status)};
    /* status === 'pending' ? obj.colorRadial : null}; */
    display: flex;
    justify-content: center;
    align-items: center;
    .top-content {
      display: flex;
      flex-direction: row;
      .challenge-img {
        margin-top: 0rem;
        width: 50px;
        align-self: center;
      }
      h4 {
        margin-top: 1rem;
        color: #fff;
        text-align: center;
        display: none;
      }
    }
  }
  .bottom-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    .bottom-content {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      text-align: center;
      width: 100%;
      align-items: center;
      .bottom-number {
        font-size: 2rem;
        flex-grow: 1;
        text-align: left;
        margin-left: 1rem;
        margin-bottom: 0rem;
      }
      .challenge-text {
        margin-bottom: 0rem;
        font-size: 1rem;
      }
      .cp-info {
        display: none;
        margin-bottom: 0.5rem;
      }
    }

    .cp-img {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      width: 25px;
    }

    .btn-complete {
      background-color: #e1ffe1;
      color: #4cce45;
      border: 1px solid #4cce45;

      &:hover {
        pointer: cursor;
      }
    }

    .btn-skipped {
      background-color: #cecece;
      color: #fff;
      border: 0px;
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      max-width: 750px;
      margin: auto;
      margin-bottom: 1rem;
      .bottom-section {
        .bottom-content {
          padding: 1.5rem;
          .cp-info {
            display: block;
            flex-grow: 1;
          }
          .bottom-number {
            flex-grow: 0;
            font-size: 3rem;
          }
          .challenge-text {
            font-size: 1.5rem;
          }
        }
      }
      .top-section {
        width: 35%;
        justify-content: flex-start;
        .top-content {
          width: 100%;
          align-items: center;
          .challenge-img {
            width: 80px;
          }
          h4 {
            margin: 0px;
            display: block;
            flex-grow: 1;
            font-size: 20px;
          }
        }
      }
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      box-shadow: none;
      margin-bottom: 0rem;
      margin-right: 30px;
      width: ${currentWidth.ipadWidth}px;
      flex-direction: column;
      height: 100%;
      min-height: 298px;
      max-height: 480px;
      background: url(${BackgroundSVG});
      background-size: cover;
      background-position: bottom;
      border-radius: 35px;

      button.btn {
        font-size: 18px;
      }
      &:nth-child(1) {
        margin-left: 30px;
      }
      .bottom-section {
        flex-grow: 0;
        height: 50%;
        .bottom-content {
          padding: 2.5rem 0;
          flex-direction: column;
          width: auto;
          align-items: stretch;
          .challenge-text {
            margin-bottom: 0.5rem;
          }
          .bottom-number {
            text-align: center;
            margin-left: 0rem;
            margin-bottom: 0.5rem;
          }
        }
      }
      .top-section {
        border-radius: 25px 25px 0px 0px;
        height: 45%;
        width: auto;
        padding: 0px;
        justify-content: center;
        .top-content {
          flex-direction: column;
          .challenge-img {
            margin-top: 1.5rem;
          }
          h4 {
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            flex-grow: 0;
            font-size: 24px;
          }
        }
      }
    }
  }
  @media (max-height: 733px) and (min-width: ${({ theme }) =>
      `${theme.rwd.tablet}px`}) {
    .top-section {
      .top-content {
        .challenge-img {
          margin-top: calc(100vh * 0.032742155525);
          width: calc(100vh * 0.109140518417);
        }
        h4 {
          font-size: calc(100vh * 0.032742155525);
          margin-top: calc(100vh * 0.021828103683);
        }
      }
    }
    .bottom-section {
      .bottom-content {
        padding: 2rem 1rem;
        .challenge-text {
          font-size: calc(100vh * 0.032742155525 * 0.8);
          margin: 0;
        }
        .bottom-number {
          font-size: calc(100vh * 0.06548431105 * 0.8);
        }
        .cp-info {
          margin-bottom: calc(100vh * 0.010914051841);
          .cp-img {
            width: calc(100vh * 0.034106412005);
          }
          span {
            font-size: calc(100vh * 0.021828103683 * 0.8);
          }
        }
      }
    }
  }
  @media (max-height: 660px) and (min-width: ${({ theme }) =>
      `${theme.rwd.tablet}px`}) {
    .top-section {
      .top-content {
        .challenge-img {
          margin-top: 0px;
          width: 72px;
        }
        h4 {
          font-size: 17.2879px;
          margin: 11.525px 0;
        }
      }
    }
    .bottom-section {
      .bottom-content {
        padding: 1.5rem 0.2rem;
        .challenge-text {
          font-size: 17.2879px;
          margin: 0;
        }
        .bottom-number {
          font-size: 34.5757px;
        }
        .cp-info {
          margin-bottom: 7.20327px;
          .cp-img {
            width: 22.5px;
          }
          span {
            font-size: 11.5252px;
          }
        }
      }
    }
  }
`;

export const StyledCheckmarkContainer = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
  display: flex;
  justify-content: flex-end;
`;

export const StyledCheckmark = styled.div``;

export const StyledArrow = styled.div`
  z-index: 2;
  margin-top: 13rem;
  margin-left: -9rem;
`;
