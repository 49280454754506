import styled from 'styled-components';
import Bg from 'assets/img/loading-page-bg.svg';

export const Container = styled.div`
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 101;
  background: url(${Bg});
  background-color: #7981f8;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: ${(props) => (props.toFadeout ? 'none' : 'auto')};
  animation-name: ${(props) => (props.toFadeout ? 'fadeout' : null)};
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  .welcome {
    font-size: 48px;
    span {
      font-size: 24px;
    }
  }
  .text {
    color: #ffffff;
    font-weight: bold;
  }
  .progress {
    width: 100px;
    height: 0.75rem;
    border-radius: 1rem;
    border: 2px solid #d5e1fc;
    .bg-koobits {
      background-color: #ff612b;
    }
  }
`;
