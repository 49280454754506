import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTotalKokoCredits, fetchBookClubInfo } from 'services/storyMatch';
import { fetchWrapper } from 'services/login';

const initialState = {
  totalKoko: 0,
  bookClubInfo: null,
  isLoading: false,
  error: null,
};

export const getTotalKokoCredit = createAsyncThunk(
  'storyMatch/getTotalKokoCredit',
  async () => {
    try {
      const res = await fetchWrapper(fetchTotalKokoCredits);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get Bookclub Total Koko Credits failed'
      );
    }
  }
);

export const getBookClubInfo = createAsyncThunk(
  'storyMatch/getBookClubInfo',
  async () => {
    try {
      const res = await fetchWrapper(fetchBookClubInfo);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get Bookclub Info failed');
    }
  }
);

const bookClubSlice = createSlice({
  name: 'bookclub',
  initialState,
  reducers: {
    setTotalKoko: (state, action) => {
      state.totalKoko = action.payload;
    },
    setbookClubInfo: (state, action) => {
      state.bookClubInfo = action.payload;
    },
  },
  extraReducers: {
    [getTotalKokoCredit.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getTotalKokoCredit.fulfilled]: (state, action) => {
      state.totalKoko = action.payload.Result;
      state.isLoading = false;
    },
    [getTotalKokoCredit.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getBookClubInfo.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getBookClubInfo.fulfilled]: (state, action) => {
      state.bookClubInfo = action.payload.Result;
      state.isLoading = false;
    },
    [getBookClubInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = bookClubSlice;
export const { setTotalKoko, setbookClubInfo } = actions;
export default reducer;
