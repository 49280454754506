import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40vw;
  background-color: #404447;
  @media (max-width: 1024px) {
    width: 50vw;
  }
  @media (max-width: 768px) {
    width: 100vw;
    overflow-y: auto;
  }
`;
export const MenuTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 19px 64px;
  .close {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    path {
      fill: #8e8e8e;
    }
  }
  div {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    color: white;
    &::before,
    &::after {
      flex: 1;
      background: #777777;
      content: '';
      height: 1px;
    }
  }
  span {
    padding: 0 13px;
  }
  @media (max-width: 768px) {
    padding: 14px 52px;
    .close {
      top: 19px;
    }
  }
`;
export const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-end;
  border-bottom: 1px solid #777777;
  margin-bottom: 10px;
  padding: 0 10px 10px;
  &:last-child {
    border-bottom: none;
  }
`;
export const ItemMenu = styled.div`
  display: ${({ mobileOnly }) => (mobileOnly ? 'none' : 'flex')};
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 33%;
  width: ${({ lang }) => (lang === 'ba' ? '40px' : '30px')};
  height: 97px;
  margin-bottom: 0.2rem;
  padding: 0.2rem;
  background-color: ${(props) => (props.isLoading ? '#292a2a' : 'transparent')};
  border: none;
  color: #ffffff;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  a {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon {
    margin: 0;
    visibility: ${(props) => (props.isLoading ? 'hidden' : 'normal')};
    width: ${({ iconWidth }) => {
      if (iconWidth) {
        return `${iconWidth}px`;
      }
    }};
  }
  .feature-name {
    margin: 0;
    margin-top: 0.5rem;
    cursor: pointer;
  }
  &:hover {
    background-color: #292a2a;
    a {
      text-decoration: none;
    }
  }
  .spinner-border {
    position: absolute;
    top: 2rem;
  }
  ${({ isCurrentPath, containerKeyName, iconWidth }) => {
    if (containerKeyName !== 'quickAccess' && isCurrentPath) {
      return css`
        .icon {
          padding: 10px;
          border-radius: 8px;
          background-color: #292a2a;
          width: ${iconWidth ? `${iconWidth + 20}px` : 'initial'};
        }
        .feature-name {
          margin-top: 0;
        }
      `;
    }
  }}
  @media (max-width: 768px) {
    display: flex;
    ${({ containerKeyName }) => {
      if (containerKeyName !== 'quickAccess') {
        return css`
          max-height: 78px;
        `;
      }
    }}
  }
`;
