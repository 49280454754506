import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CPSubmittedTable from '../CPSubmittedTable/CPSubmittedTable';

const CPSubmittedMaleTable = ({ teamLatestCPSubmittedList }) => {
  const { t } = useTranslation(['leaderboard']);
  const { boysLatestCPSubmitted } = teamLatestCPSubmittedList;
  if (boysLatestCPSubmitted.length === 0) return <></>;
  return (
    <>
      <h6 style={{ textAlign: 'center', color: '#00B3DB', paddingTop: 50 }}>
        {t(
          'leaderboard:boysVsGirls.ranking.boysLatest',
          'Team Boys Latest CP Submitted'
        )}
      </h6>
      <CPSubmittedTable
        color="blue"
        teamLatestCPSubmittedList={boysLatestCPSubmitted.slice(0, 6)}
      />
    </>
  );
};

const CPSubmittedFemaleTable = ({ teamLatestCPSubmittedList }) => {
  const { t } = useTranslation(['leaderboard']);
  const { girlsLatestCPSubmitted } = teamLatestCPSubmittedList;
  if (girlsLatestCPSubmitted.length === 0) return <></>;
  return (
    <>
      <h6 style={{ textAlign: 'center', color: '#FF83A5', paddingTop: 50 }}>
        {t(
          'leaderboard:boysVsGirls.ranking.girlLatest',
          'Team Girls Latest CP Submitted'
        )}
      </h6>
      <CPSubmittedTable
        color="pink"
        teamLatestCPSubmittedList={girlsLatestCPSubmitted.slice(0, 6)}
      />
    </>
  );
};

const MainCPSubmittedTable = ({ teamLatestCPSubmittedList }) => {
  return (
    <Row>
      <Col lg={6}>
        <CPSubmittedMaleTable
          teamLatestCPSubmittedList={teamLatestCPSubmittedList}
        />
      </Col>
      <Col lg={6}>
        <CPSubmittedFemaleTable
          teamLatestCPSubmittedList={teamLatestCPSubmittedList}
        />
      </Col>
    </Row>
  );
};

export default MainCPSubmittedTable;
