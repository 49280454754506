import styled, { css } from 'styled-components';
import MainBg from 'assets/img/assignment/assignment-view/content-bg.svg';
import MainGreenBg from 'assets/img/assignment/assignment-view/content-green-bg.svg';
import Top from 'assets/img/assignment/note-book/note-top.png';
import Middle from 'assets/img/assignment/note-book/note-middle.png';
import Bottom from 'assets/img/assignment/note-book/note-bottom.png';

const assignmentTypeStyles = (assignmentType) => {
  switch (assignmentType) {
    case 'sundayMC':
      return {
        bgImage: MainBg,
        bgColor: null,
      };
    default:
      return {
        bgImage: MainGreenBg,
        bgColor: '#3b6c72',
      };
  }
};

export const Container = styled.div`
  display: flex;
`;

export const QnText = styled.p`
  font-family: Rockwell;
  font-size: 34px;
  font-style: italic;
  font-weight: 700;
  color: #ff6701;
  position: relative;
  z-index: 0;
  margin: 0;
  ::before {
    content: 'Question';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 10px;
  }
  @media (max-width: 947px) {
    font-size: 24px;
  }
  @media (max-width: 502px) {
    font-size: 19px;
  }
`;

export const Main = styled.div`
  background: ${(props) =>
    `url(${assignmentTypeStyles(props.assignmentType).bgImage})`};
  background-color: ${(props) =>
    assignmentTypeStyles(props.assignmentType).bgColor};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

export const NotebookContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto;
  padding: 0 64px 0 184px;
  @media (max-width: 1200px) {
    padding: 0 64px 0 82.8px;
  }
  @media (max-width: 846px) {
    padding: 0;
  }
`;

export const Notebook = styled.div`
  width: 900px;
  max-width: 90%;
  padding-top: 1rem;
  @media (max-width: 846px) {
    max-width: 97%;
  }
  @media (max-width: 502px) {
    max-width: max(100%, 320px);
    padding-top: 0;
    min-width: 320px;
  }
`;

export const NoteBookTop = styled.div`
  width: 100%;
  height: auto;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 1.5rem;
  margin-bottom: -0.1rem;
  background: url(${Top});
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 878px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @media (max-width: 552px) {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  @media (max-width: 502px) {
    background: #ebf7f9;
    padding-left: 16px;
    padding-right: 16px;
    min-width: 320px;
  }
  .text {
    display: flex;
    align-items: flex-end;
    .qn-number {
      font-family: Linotte;
      margin-bottom: 0rem;
      font-size: 34px;
      font-weight: 600;
      color: #ff6701;
      margin-left: 1rem;
      @media (max-width: 947px) {
        font-size: 24px;
      }
      @media (max-width: 502px) {
        font-size: 19px;
      }
    }
    .qn-id {
      color: rgb(57, 57, 57, 0.44);
      margin-bottom: 0rem;
      align-self: center;
      margin-top: 1rem;
      margin-left: 0.5rem;
      @media (max-width: 947px) {
        font-size: 12px;
      }
      @media (max-width: 502px) {
        margin-top: 0;
        font-size: 9px;
      }
    }
  }
`;

export const NotebookMiddle = styled.div`
  min-height: max(calc(100% - 200px), 400px);
  background: url(${Middle});
  background-repeat: repeat-y;
  background-size: 100% auto;
  padding-top: 1rem;
  padding-left: 8%;
  padding-right: 9%;
  position: relative;
  @media (max-width: 878px) {
    min-height: calc(100% - 150px);
  }
  @media (max-width: 502px) {
    background: white;
    padding-left: 0;
    padding-right: 30px;
    padding-bottom: 1rem;
    min-width: 320px;
    min-height: calc(100% - 116px);
    .mcq-text-option,
    .mcq-img-option {
      width: 100%;
      margin: 1rem 0;
    }
  }
`;

export const NotebookBottom = styled.div`
  background: url(${Bottom});
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  justify-content: flex-end;
  padding: 5% 8%;
  margin-top: -0.1rem;
  margin-bottom: 1rem;

  ${({ assignmentType }) => {
    if (assignmentType === 'sundayMC') {
      return css`
        padding-bottom: 5rem;
      `;
    }
  }}

  @media (max-width: 878px) {
    padding: 4% 8%;

    ${({ assignmentType }) => {
      if (assignmentType === 'sundayMC') {
        return css`
          padding-bottom: 5rem;
        `;
      }
    }}
  }
  @media (max-width: 502px) {
    background: #ebf7f9;
    padding: 0 30px;
    margin-bottom: 0;
    height: 60px;
  }
`;

export const QnButton = styled.button`
  background: ${(props) => (props.type === 'next' ? '#ff6701' : '#ffffff')};
  border: 1px solid
    ${(props) => (props.type === 'next' ? '#ffa37a' : '#9DDBE1')};
  border-radius: 22px;
  color: ${(props) => (props.type === 'next' ? '#ffffff' : '#55C5CF')};
  font-size: ${(props) => (props.type === 'next' ? '18px' : '16px')};
  height: ${(props) => (props.type === 'next' ? '38px' : '29px')};
  padding: 0rem 1rem;
  align-self: center;
  margin-right: ${(props) => (props.type === 'prev' ? '14px' : '0')};
  img {
    margin-right: ${(props) => props.type === 'prev' && '5px'};
    transform: ${(props) => props.type === 'next' && 'rotate(180deg)'};
  }
  &:hover {
    background: ${(props) => (props.type === 'next' ? '#FF5801' : '#DCF8FF')};
  }
  &:disabled {
    background: #e5e5e5;
    color: #ffffff;
    border-color: #e5e5e5;
    img {
      filter: brightness(0) invert(1);
    }
  }
  @media (max-width: 878px) {
    font-size: ${(props) => (props.type === 'next' ? '18px' : '16px')};
  }
`;
