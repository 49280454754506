import React from 'react';
import { useTranslation } from 'react-i18next';
import ChallengeItem from './ChallengeItem';
import { Container } from './LeaderboardChallengesList.styles';

const LeaderboardChallengeList = ({ status, challenges = [], subject }) => {
  const { t } = useTranslation(['leaderboardlist']);
  if (challenges.length <= 0) {
    return null;
  }
  return (
    <Container status={status} subject={subject}>
      <div className="header">
        {status === 'open'
          ? t('leaderboardlist:topBanner.open', `Open`)
          : t('leaderboardlist:topBanner.closed', `Closed`)}{' '}
        {t('leaderboardlist:topBanner.challenges', `Challenges.`)}
      </div>
      <div>
        <ChallengeItem status={status} challenges={challenges} />
      </div>
    </Container>
  );
};

export default LeaderboardChallengeList;
