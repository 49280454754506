import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchBookClubInfo = async () => {
  return fetch(`${baseUrl}StoryMath/GetBookClubInfo`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch book club info failed'); // eslint-disable-line
    });
};

export const fetchTotalKokoCredits = async () => {
  return fetch(`${baseUrl}StoryMath/GetBookClubTotalKokoCredits`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Fetch book club total koko credits failed'
      ); // eslint-disable-line
    });
};
