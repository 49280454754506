import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from 'components/Shared/Card/Card';
import SchoolCard from 'assets/img/mission/school-card.svg';
import HotsCard from 'assets/img/mission/hots-card.svg';
import scienceCard from 'assets/img/mission/science-mission-plus-card.svg';
import iconPlus from 'assets/img/mission/icon-plus.svg';
import Button from 'components/Shared/Button';
import Spinner from 'components/Shared/Spinner';
import ErrorResponse from 'components/Shared/ErrorResponse';
import BackButton from 'components/Shared/BackButton';
import ErrorModal from 'components/Shared/ErrorModal';
import { isEmpty, isNil } from 'ramda';
// eslint-disable-next-line import/no-cycle
import {
  getSelfPracticeCurriculum,
  resetSelectedLevel,
  getB2BSelfPracticeCurriculum,
} from 'store/mission/missionSlice';
import formatChineseNumber from 'helpers/formatChineseNumber';
import useDashboard from 'hooks/useDashboard';
import { NewHOTsCurriculumID } from 'constants/mission';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';
import {
  Container,
  Header,
  Content,
  RightContainer,
  LeftContainer,
  CardImg,
  CardInfo,
  Detail,
} from './CurriculumList.styles';

const CurriculumList = () => {
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation(['curriculumList', 'common']);
  const currentLanguage = i18n.language;
  const {
    isLoading: isProductsLoading,
    products,
    subject,
    currentProduct,
    plan,
  } = useSelector((state) => state.plan);
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const {
    selfPracticeCurriculum,
    isLoading: isCurriculumsLoading,
    isErr,
  } = useSelector((state) => state.mission);
  const { isDashboardv2 } = useDashboard();
  const isLoading = isProductsLoading || isCurriculumsLoading;

  const isFromShortCut = location.state?.quickAccess;

  const loadData = useCallback(() => {
    if (plan === 'school') {
      dispatch(getB2BSelfPracticeCurriculum());
    } else {
      dispatch(getSelfPracticeCurriculum());
    }
  }, [dispatch, plan]);

  useEffect(() => {
    if (products !== null) {
      loadData();
    }
    dispatch(resetSelectedLevel());
  }, [dispatch, loadData, products]);

  const errorHandling = () => {
    loadData();
    dispatch(resetSelectedLevel());
  };
  const curriculumDatum = (data) => {
    const hotsCurr = [72, NewHOTsCurriculumID].includes(data.CurriculumID);
    if (hotsCurr) {
      return {
        imgTitle: '',
        imgSrc: HotsCard,
        defaultLevelTitle: '',
        pathname: `/mission/hots/${data.CurriculumID}`,
        buttonTitle: t('curriculumList:go', 'Go'),
        icon: null,
      };
    }
    if (data.IsMissionPlus) {
      return {
        imgTitle: '',
        imgSrc: scienceCard,
        defaultLevelTitle: '',
        pathname: `/mission/self-practice/plus/${data.CurriculumID}`,
        buttonTitle: t('curriculumList:go', 'Go'),
        icon: iconPlus,
      };
    }
    return {
      imgTitle: t('curriculumList:schoolCurr', 'School Curriculum'),
      imgSrc: SchoolCard,
      defaultLevelTitle: `${t(
        'curriculumList:primary',
        'Primary'
      )} ${formatChineseNumber({
        value: defaultLevel,
        language: currentLanguage,
      })}`,
      pathname: `/mission/self-practice/${data.CurriculumID}`,
      buttonTitle: t('curriculumList:go', 'Go'),
      icon: null,
    };
  };

  const DetailComponent = ({ icon, defaultLevelTitle, isMissionPlus }) => {
    return (
      <Detail isMissionPlus={isMissionPlus}>
        <div className="skills-details" />
        {icon ? (
          <img src={icon} alt="icon" data-cy="mission-plus-icon" />
        ) : (
          <p className="default-level">{defaultLevelTitle}</p>
        )}
      </Detail>
    );
  };

  // left section
  const leftSection = (data) => {
    const { imgTitle, imgSrc, defaultLevelTitle, icon } = curriculumDatum(data);
    return (
      <LeftContainer>
        <CardImg className="card-img">
          <img src={imgSrc} alt={imgTitle} />
          {imgTitle && <p>{imgTitle}</p>}
        </CardImg>
        <CardInfo isMissionPlus={data.IsMissionPlus}>
          <div className="curriculum-name">
            <p>
              <span>{data.curriculumName}</span>
            </p>
          </div>
          <div className="detail-container">
            <DetailComponent
              icon={icon}
              defaultLevelTitle={defaultLevelTitle}
              isMissionPlus={data.IsMissionPlus}
            />
          </div>
        </CardInfo>
      </LeftContainer>
    );
  };

  // rightsection
  const rightSection = (data) => {
    const { pathname, buttonTitle, icon, defaultLevelTitle } = curriculumDatum(
      data
    );
    return (
      <RightContainer>
        <div className="detail-container">
          <DetailComponent icon={icon} defaultLevelTitle={defaultLevelTitle} />
        </div>
        <Button
          dataCy={`mission-${data.CurriculumID}`}
          width={isMobile ? '130px' : '205px'}
          variant="primary"
          onClick={() =>
            history.push({
              pathname,
              state: {
                subject: data.SubjectID,
              },
            })
          }
        >
          {buttonTitle}
        </Button>
      </RightContainer>
    );
  };
  return (
    <Container>
      {!isNil(currentProduct) && (
        <Header subject={subject} plan={plan}>
          <div className="decoration">
            <BackButton
              isResponsive={isMobile}
              backText={t('common:back', 'Back')}
              btnBgColor={
                subject === 'science' && plan !== 'school'
                  ? 'rgba(0,181,146,0.8)'
                  : 'rgba(66,49,219,0.6)'
              }
              padding="5px 20px"
              onClick={() => {
                if (isFromShortCut) {
                  history.goBack();
                  return;
                }
                history.push(
                  isDashboardv2 ? '/dashboard?view=kootraining' : '/dashboard'
                );
              }}
              styles={{ position: 'absolute', left: '3%' }}
            />
            {t('curriculumList:mission', 'Mission')}
          </div>
        </Header>
      )}
      <Content>
        {isLoading && <Spinner />}
        {!isLoading && !isEmpty(selfPracticeCurriculum) && (
          <>
            {selfPracticeCurriculum.map((item) => (
              <Card
                key={item.CurriculumID}
                infoSection={leftSection(item)}
                buttonSection={rightSection(item)}
                rightWidth={isMobile ? '100%' : '30%'}
                leftWidth={isMobile ? '100%' : undefined}
                missionPlus={item.IsMissionPlus}
              />
            ))}
          </>
        )}
        {!isLoading && isEmpty(selfPracticeCurriculum) && isNil(isErr) && (
          <ErrorResponse error="Curriculum is not available" />
        )}
        {!isNil(isErr) && (
          <ErrorModal reloadAction={errorHandling} errorMessage={isErr} />
        )}
      </Content>
    </Container>
  );
};

export default CurriculumList;
