import React from 'react';
import { CLASS_ACHIEVEMENTS } from 'pages/Leaderboard/v2/pages/ChampionClass/helper';
import { Container, Bar } from './ProgressBar.styles';

const ProgressBar = ({ points }) => {
  const endPoints = CLASS_ACHIEVEMENTS(points).nextCP;
  let percentage = 0;
  percentage = Math.round((points / endPoints) * 100);
  if (percentage > 100) percentage = 100;

  return (
    <Container>
      <Bar width={percentage} />
    </Container>
  );
};

export default ProgressBar;
