import styled from 'styled-components';
import KooClassBg from 'assets/img/dashboardv2/kooclass-bg.png';

export const Overlay = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const Tablet = styled.div`
  display: none;
  width: 575px;
  right: 0;
  padding: 100px 20px;
  height: 100%;
  position: absolute;
  top: 0;

  img {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: block;
  }
`;

export const Container = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${KooClassBg}) rgb(21, 26, 44);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    background: none;
  }
`;

export const Main = styled.div`
  position: relative;
  max-width: 600px;

  .standardImage {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 80%;
  }
`;

export const Badge = styled.img`
  position: absolute;
  right: -10px;
  top: 53px;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    max-width: none;
    width: 20%;
    top: 35px;
    right: -5px;
  }
`;
