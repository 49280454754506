import styled from 'styled-components';
import MissionBg from 'assets/img/dashboard/practice-mission.svg';

export const CardContainer = styled.div`
  border-radius: 20px;
  box-shadow: 0px 7px 0px 0px rgba(82, 75, 97, 0.28);
  width: 55%;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  color: #ffffff;
  .desc-block {
    position: relative;
    top: 17rem;
    left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    .title {
      font-size: 2.5rem;
      text-shadow: 2px 2px 2px rgba(168, 58, 0, 0.4);
      line-height: 1;
    }
  }
  .hover-desc-block {
    padding: 1rem;
    .title {
      font-weight: 600;
      font-size: 2.5rem;
    }
  }
  @media (min-width: 1700px) {
    .desc-block {
      top: 19rem;
    }
  }
  @media (max-width: 950px) {
    .desc-block {
      top: 14rem;
    }
  }
  @media (max-width: 800px) {
    .desc-block {
      top: 13rem;
    }
    .title {
      font-size: 2rem;
    }
    .sub-title {
      font-size: 0.8rem;
    }
  }
`;

export const Block = styled.div`
  background: url(${MissionBg}) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  filter: ${(props) => (props.showOverlay ? 'blur(4px)' : null)};
  -webkit-filter: ${(props) => (props.showOverlay ? 'blur(4px)' : null)};
`;
