import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-right: 2.5%;
  :hover {
    cursor: pointer;
  }
  .progress-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .scroll-progress {
    font-size: 11px;
    color: #ffffff;
    margin-right: 5px;
    span {
      color: #6f6868;
    }
  }
  .progress-bar-section {
    min-width: 50%;
    .progress {
      height: 4.8px;
      border-radius: 1rem;
      background: #f3f3f3;
      .bg-correct {
        background: #fb7438;
      }
    }
  }
  @media (min-width: 1920px) {
    .scroll-progress {
      font-size: 14px;
    }
  }
  @media (max-width: 1024px) {
    margin-right: 25px;
    .progress-bar-section {
      min-width: 42%;
    }
  }
  @media (max-width: 768px) {
    margin-right: 12px;
  }
`;

export const TopicCard = styled.div`
  width: 190px;
  height: 113.49px;
  border-radius: 10px;
  background: ${(props) => `url(${props.bg})`};
  background-size: cover;
  color: #000000;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  margin-bottom: 0.5rem;
  @media (max-width: 1024px) {
    width: 156.48px;
    height: 93.78px;
  }
  @media (min-width: 1920px) {
    width: 272px;
    height: 162px;
  }
`;
