import React from 'react';
import SideNavIcon from 'assets/img/assignment/assignment-view/icon-sidenav.svg';
import {
  ActionButton,
  PrintButton,
} from 'components/Assignment/AssignmentView/Header.styles';
import PrintIcon from 'assets/img/assignment/print-icon.svg';
import { useTranslation } from 'react-i18next';
import TimerDisplay from './TimerDisplay';
import { SideNavButton } from './index.styles';

const HeaderRight = ({
  isTablet,
  assignmentType,
  printUrl,
  openModal,
  onSave,
  sideNavClickHandler,
  isLoading,
  isError,
  isSaving,
  currentSavedAnswer,
  isChecking = [],
  saveError,
  activeQuestion,
  showButtons,
}) => {
  const { t } = useTranslation(['assignmentQnView']);

  const saveHandler = () => {
    onSave();
  };
  if (isTablet) {
    return (
      <>
        {showButtons && (
          <TimerDisplay
            assignmentType={assignmentType}
            styles={{ fontSize: '12px', marginRight: '16px' }}
          />
        )}
        <SideNavButton onClick={sideNavClickHandler}>
          <img src={SideNavIcon} alt="Side Nav" />
        </SideNavButton>
      </>
    );
  }

  return (
    <>
      {printUrl.length > 0 && (
        <PrintButton
          assignmentType={assignmentType}
          data-cy="print-icon"
          rel="noopener noreferrer"
          target="_blank"
          href={printUrl}
        >
          <img src={PrintIcon} alt="Print" />
        </PrintButton>
      )}
      {showButtons && (
        <ActionButton
          data-cy="submit-btn"
          assignmentType={assignmentType}
          disabled={isLoading && isError !== null}
          onClick={openModal}
        >
          {t('assignmentQnView:header.submit', 'Submit')}
        </ActionButton>
      )}
      {showButtons && (
        <ActionButton
          data-cy="saving-btn"
          assignmentType={assignmentType}
          onClick={saveHandler}
          isSaved={
            !isSaving && saveError === null && currentSavedAnswer !== null
          }
          disabled={
            isSaving ||
            isChecking[activeQuestion - 1] ||
            isLoading ||
            isError !== null ||
            currentSavedAnswer !== null
          }
        >
          {isSaving && t('assignmentQnView:header.saving', 'Saving')}
          {!isSaving &&
            saveError === null &&
            currentSavedAnswer !== null &&
            t('assignmentQnView:header.saved', 'Saved')}
          {!isSaving &&
            !isChecking[activeQuestion - 1] &&
            currentSavedAnswer === null &&
            t('assignmentQnView:header.save', 'Save')}
        </ActionButton>
      )}
    </>
  );
};

export default HeaderRight;
