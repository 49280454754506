import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';
import HwStatusTabs from 'components/Assignment/AssignmentList/HwStatusTabs';
import BackButton from 'components/Shared/BackButton';
import KokoCredits from 'assets/img/icon-koko-credits-shadow.svg';
import EssentialSkillsIcon from 'assets/img/kooquiz/essential-skills.svg';
import TopSchoolPaperIcon from 'assets/img/kooquiz/top-school-paper.svg';
import HOTSPapersIcon from 'assets/img/kooquiz/hots-papers.svg';
import OpenEndedQuestionIcon from 'assets/img/kooquiz/open-ended-questions.svg';
import { getRewards } from 'store/dashboard/rewardsSlice';
import { Container, KokoCredit } from './MainPageTopbar.styles';

const essentialSkills = {
  name: 'Essential Skills',
  icon: EssentialSkillsIcon,
  iconWidth: '32px',
  fontSize: '15px',
};
const topSchoolYearEndPaper = {
  name: 'Top School Year-End Paper',
  icon: TopSchoolPaperIcon,
  iconWidth: '26px',
  fontSize: '14px',
};
const HOTSPapers = {
  name: 'HOTS Papers',
  icon: HOTSPapersIcon,
  iconWidth: '32px',
  fontSize: '14px',
};

const openEndedQuestions = {
  name: 'Open-Ended Questions',
  icon: OpenEndedQuestionIcon,
  iconWidth: '34px',
  fontSize: '14px',
};

const MainPageTopbar = ({
  kooQuizSelectedPaper,
  setSelectedPaper,
  clickHandle,
  selectedSubject,
}) => {
  const { t } = useTranslation(['kooQuizBookShelf', 'common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { rewards, error } = useSelector((state) => state.rewards);
  const paperOptions =
    selectedSubject === 'Maths'
      ? [essentialSkills, topSchoolYearEndPaper, HOTSPapers]
      : [topSchoolYearEndPaper, openEndedQuestions];

  return (
    <Container>
      <BackButton
        backText={t('kooQuizBookShelf:header.back', 'Back')}
        btnBgColor="#346FC0"
        padding="0.2rem 1.2rem"
        styles={{ position: 'absolute', left: '3%' }}
        onClick={() => history.replace('/dashboard?view=kootraining')}
      />
      <p className="kooquiz">
        {t('kooQuizBookShelf:header.kooQuiz', 'KooQuiz')}
      </p>
      <HwStatusTabs
        status={kooQuizSelectedPaper}
        setStatus={setSelectedPaper}
        tabs={paperOptions}
        type="kooQuiz"
        activeColor="#2D80EB"
        notActiveColor="rgb(207, 233, 255, 0.75)"
        customOnClick
        clickHandle={clickHandle}
      />
      <KokoCredit data-cy="koko">
        <img src={KokoCredits} alt="koko-credits" />
        {!isNil(error) ? (
          <BackButton
            noIcon
            backText={t('common:reload', 'Reload')}
            btnBgColor="rgb(94,210,255, 0.54)"
            padding="0.2rem 1.2rem"
            onClick={() => dispatch(getRewards())}
            data-cy="reload-btn"
          />
        ) : (
          rewards?.TotalKoko
        )}
      </KokoCredit>
    </Container>
  );
};

export default MainPageTopbar;
