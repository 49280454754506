import React from 'react';
import { Container, Progress } from './ProgressBar.styles';

const ProgressBar = ({ progress }) => {
  return (
    <Container>
      <Progress width={progress} />
    </Container>
  );
};

export default React.memo(ProgressBar);
