import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: fit-content;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  :hover {
    > div {
      transform: scale(0.95);
    }
  }
  img {
    width: 100%;
    height: auto;
    filter: ${({ disabled }) => (disabled ? 'brightness(0.5)' : 'none')};
  }
  margin-bottom: calc(0.018745493 * 100vw);

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    margin-bottom: 0px;
    flex: 1;
    img {
      width: 97%;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;
export const StyledContent = styled.div`
  position: absolute;
  left: 48%;
  bottom: 10%;
  transform: translateX(-47%);
  text-align: center;
  width: 96%;
  color: white;
  h3 {
    // font-size 22px,1387px(UI width)  => 22 / 1387 = 0.015861571
    font-size: calc(0.015861571 * 100vw);
    margin-bottom: 0;
    line-height: 1;
    font-weight: 900;
    text-shadow: -2px 0px 2px rgba(0, 0, 0, 0.24);
    filter: ${({ disabled }) => (disabled ? 'brightness(0.5)' : 'none')};
  }
  p {
    // font-size 14px,1387px(UI width) => 14 / 1387 = 0.010093727
    font-size: calc(0.010093727 * 100vw);
    font-weight: 700;
    line-height: 1.1;
    filter: ${({ disabled }) => (disabled ? 'brightness(0.5)' : 'none')};
    margin-bottom: 0;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    h3 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
    }
  }
`;
export const StyledPremiumOnly = styled.div`
  position: absolute;
  right: -2%;
  top: 10%;
  width: 30%;
  svg {
    width: 100%;
    height: auto;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 140px;
    top: 7%;
  }
`;
