import styled from 'styled-components';
import BellIcon from 'assets/img/navigation/bell.svg';
import BellActiveIcon from 'assets/img/navigation/bell-active.svg';

export const ProfileContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  a {
    width: 25px;
    height: 25px;
    background: ${(props) => (props.isDashboardv2 ? '#707070' : 'none')};
    border-radius: 50%;
    text-align: center;
    :hover {
      background: ${(props) => (props.isDashboardv2 ? '#5a5a5a' : 'none')};
    }
  }
  img.arrow {
    margin: 0;
  }
`;

export const NavProfileContainer = styled.div`
  display: flex;
  min-height: 59px;
  img {
    margin-left: 0.5rem;
    margin-top: 20px;
    cursor: ${({ isMobile }) => (isMobile ? 'auto' : 'pointer')};
    @media (max-width: 1050px) {
      margin-left: 0.5rem;
    }
  }
  img.profile-picture {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    border-radius: 50%;
    border: solid 2px white;

    @media (max-width: 414px) {
      width: 30px;
      height: 30px;
      margin-top: 15px;
    }
  }
  .drop-down {
    padding: 5px;
    margin-top: 5px;
  }
  .dropdown-menu {
    background-color: #4554a5;
    top: 20px !important;
    padding: 0;
  }
  .dropdown-item {
    background-color: #4554a5;
    color: white;
    height: 100%;
    font-size: 0.9375rem;
    text-align: center;
    padding: 0.5rem 0;
    border-top: solid 1px rgba(155, 187, 230, 0.55);
    &:hover {
      background: #6173d5;
    }
    &:first-child {
      border-top: none;
    }
  }
`;

export const BellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.active && 'rgba(41, 42, 42, 0.51)'};
  width: 45px;
  .dot {
    position: absolute;
    display: flex;
    margin-top: -1rem;
    margin-left: 1rem;
    width: 12px;
    height: 12px;
    color: white;
    border-radius: 50%;
    background-color: #ef2e1f;
    justify-content: center;
    align-items: center;
    font-size: 8.5px;
  }
  .bell-icon {
    background: ${(props) =>
      props.active ? `url(${BellActiveIcon})` : `url(${BellIcon})`};
    width: 20px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: contain;
    &:hover {
      background: url(${BellActiveIcon});
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
`;
