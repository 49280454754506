import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_LEADERBOARD_V2}Periods/`;

export const fetchTerms = () => {
  return axios
    .get(`${BASE_URL}GetTerms`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err?.response?.data || 'Fetch terms failed');
    });
};

export const fetchSeasons = () => {
  return axios
    .get(`${BASE_URL}GetSeasons`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err?.response?.data || 'Fetch season failed');
    });
};

export const fetchWeeks = () => {
  return axios
    .get(`${BASE_URL}GetWeeks`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err?.response?.data || 'Fetch weeks failed');
    });
};

export const fetchMonths = () => {
  return axios
    .get(`${BASE_URL}GetMonths`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err?.response?.data || 'Fetch months failed');
    });
};
