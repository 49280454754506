import styled from 'styled-components';

export const LockCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.39);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .koko-icon {
    width: ${({ isActive }) => (isActive ? '38px' : '30px')};
    height: ${({ isActive }) => (isActive ? '38px' : '30px')};
  }
  .btn-text {
    padding-left: 0.5rem;
    margin: 0.5rem;
    span {
      margin-left: 0px;
      font-size: ${({ isActive }) => (isActive ? '22px' : '16px')};
      font-weight: bold;
    }
  }
`;

export const MoreKoko = styled.div`
  background-color: #9ea0a8;
  color: rgba(0, 0, 0, 0.39);

  font-size: ${({ isActive }) => (isActive ? '15px' : '12px')};
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: ${({ isActive }) => (isActive ? '105px' : '67px')};
  p {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .mission-btn {
    border-radius: 30px;
    background: #93cfff;
    border: #93cfff;
    color: #1c1c1c;
    font-size: ${({ isActive }) => (isActive ? '17px' : '12px')};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 0.3rem 1rem 0.3rem 0.6rem;
    &:hover {
      background: #5db7ff;
      border: #5db7ff;
      color: #1c1c1c;
      text-decoration: none;
    }
    .mission-text {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .mission-icon {
        width: ${({ isActive }) => (isActive ? '29px' : '20px')};
        height: ${({ isActive }) => (isActive ? '28px' : '20px')};
        margin-right: 5px;
      }
    }
  }
  @media (max-width: 1335px) {
    font-size: ${({ isActive }) => (isActive ? '15px' : '10px')};
    .mission-btn {
      font-size: ${({ isActive }) => (isActive ? '17px' : '10px')};
      .mission-icon {
        width: ${({ isActive }) => (isActive ? '29px' : '18px')};
        height: ${({ isActive }) => (isActive ? '28px' : '18px')};
      }
    }
  }
  @media (max-width: 1200px) {
    font-size: ${({ isActive }) => (isActive ? '13px' : '8.5px')};
    height: ${({ isActive }) => (isActive ? '95px' : '67px')};
  }
  @media (max-width: 1100px) {
    font-size: ${({ isActive }) => (isActive ? '12px' : '8.5px')};
  }
`;
