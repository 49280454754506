import styled from 'styled-components';
import Top from 'assets/img/assignment/note-book/note-top.png';
import Middle from 'assets/img/assignment/note-book/note-middle.png';
import Bottom from 'assets/img/assignment/note-book/note-bottom.png';
import MathBg from 'assets/img/kooquiz/kooQuizQn/math-qn-bg.svg';
import SciBg from 'assets/img/kooquiz/kooQuizQn/sci-qn-bg.svg';
import MainGreenBg from 'assets/img/assignment/assignment-view/content-green-bg.svg';

const assignmentTypeStyles = (assignmentType) => {
  switch (assignmentType) {
    case 'KooQuizSci':
      return {
        bgImage: SciBg,
        bgColor: '#176B7D',
      };
    case 'KooQuizMaths':
      return {
        bgImage: MathBg,
        bgColor: '#38437b',
      };
    default:
      return {
        bgImage: MainGreenBg,
        bgColor: '#3b6c72',
      };
  }
};

export const Error = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

export const Container = styled.div``;
export const Main = styled.div`
  display: flex;
  min-height: 100vh;
  background: ${(props) =>
    `url(${assignmentTypeStyles(props.assignmentType).bgImage})`};
  background-color: ${(props) =>
    assignmentTypeStyles(props.assignmentType).bgColor};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  pointer-events: none;

  @media (max-width: 846px) {
    padding-top: 58px;
  }
  @media (max-width: 767px) {
    padding-top: 50px;
  }
`;

export const Content = styled.div``;

export const NotebookContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto;
  padding: 0 64px 0 184px;
  @media (max-width: 1200px) {
    padding: 0 64px 0 82.8px;
  }
  @media (max-width: 846px) {
    padding: 0;
  }
`;

export const Notebook = styled.div`
  width: 900px;
  max-width: 90%;
  padding-top: 1rem;
  @media (max-width: 846px) {
    max-width: 97%;
  }
  @media (max-width: 767px) {
    max-width: max(100%, 320px);
    padding-top: 0;
    min-width: 320px;
  }
`;

export const NoteBookTop = styled.div`
  width: 100%;
  height: auto;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 0.5rem;
  background: url(${Top});
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .top-content {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info-section {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0rem;
      }
      .title {
        color: #30a8bb;
        text-transform: uppercase;
        font-weight: bold;
        margin-right: 1rem;
      }
      .score {
        color: #48b500;
        font-size: 29px;
        display: flex;
        align-items: center;
        margin-right: 1rem;

        .number {
          font-family: Pacifico;
          font-size: 47px;
          margin-top: -1rem;
        }
      }
      .qn-number {
        color: #95c9d2;
        font-size: 22px;
        margin-right: 1rem;
        p {
          font-size: 14px;
          align-self: center;
        }
      }
      .completed-info {
        color: rgb(0, 0, 0, 0.49);
        font-size: 13px;
        font-weight: 300;
      }
    }
  }
  .leaderboard-btn {
    background: #cff0f5;
    border: none;
    border-radius: 24px;
    color: #3c5572;
    font-size: 14px;
    font-weight: 300;
    padding: 0.3rem 0.8rem;
    margin-right: 1rem;
    pointer-events: auto;
    img {
      width: 25px;
      vertical-align: bottom;
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 846px) {
    padding-top: 0;
    padding-bottom: 0;
    .info-section {
      padding-top: 0.5rem;
    }
  }
  @media (max-width: 767px) {
    background: #ebf7f9;
    padding-left: 16px;
    padding-right: 16px;
    min-width: 320px;

    .top-content {
      width: 100%;
      justify-content: center;

      .info-section {
        width: 100%;
      }
    }
  }
  @media (max-width: 552px) {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  @media (max-width: 502px) {
    .info-section {
      width: 300px;
      flex-wrap: wrap;
    }
  }
`;

export const NotebookMiddle = styled.div`
  background: url(${Middle});
  background-repeat: repeat-y;
  background-size: 100% auto;
  padding-top: 1rem;
  padding-right: 9%;

  .content {
    margin-left: 5rem;
    padding-top: 1rem;

    .qn-no {
      font-size: 1.125rem;
      font-weight: 600;
      color: #6ed1e1;
      padding-left: 1rem;
      display: flex;
      align-items: center;
    }
  }
  .claim-points {
    &.factual-fluency {
      background: #fff2bd;
    }
    margin-left: 5rem;
    padding: 0.5rem;
    background: #d4f3ff;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 10px;
    }
    .claim-btn {
      background-color: #ffd500;
      margin: 0 20px;
      border-radius: 20px;
      border: none;
      padding: 3px 15px;
      pointer-events: auto;
    }
  }

  @media (max-width: 846px) {
    min-height: calc(100% - 150px);
    padding-left: 9%;
    .content,
    .claim-points {
      margin-left: 0;
    }
    .claim-btn {
      white-space: nowrap;
    }
  }
  @media (max-width: 767px) {
    background: white;
    padding: 0 0 1rem 0;
    min-width: 320px;
    min-height: calc(100% - 116px);
    .mcq-text-option,
    .mcq-img-option {
      width: 100%;
      margin: 1rem 0;
    }
    .claim-points {
      padding: 0.5rem 1rem;
      img {
        width: 24px;
      }
    }
    .content {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export const NotebookBottom = styled.div`
  background: url(${Bottom});
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  justify-content: flex-end;
  padding: 5% 8%;
  margin-top: -1rem;
  margin-bottom: 1rem;
  height: 100px;

  @media (max-width: 846px) {
    padding: 4% 8%;
  }
  @media (max-width: 767px) {
    background: #ebf7f9;
    padding: 0 30px;
    margin-bottom: 0;
    height: 60px;
  }
`;

export const QuestionContainer = styled.div`
  margin-bottom: 1rem;
  border-bottom: ${(props) => (props.isLastQn ? null : '2px dashed #cae3e5')};

  .qn-id {
    color: rgb(57, 57, 57, 0.44);
    font-size: 14px;
    font-weight: 300;
    margin-left: 0.5rem;
    @media (max-width: 947px) {
      font-size: 12px;
    }
    @media (max-width: 502px) {
      margin-top: 0;
      font-size: 10px;
    }
  }
`;

export const ScoreContainer = styled.div`
  color: ${(props) => props.color};
  font-size: 29px;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  .number {
    font-family: Pacifico;
    font-size: 47px;
    margin-top: -1rem;
  }
  @media (max-width: 502px) {
    margin-right: 0.5rem;
  }
`;

export const CorrectAnswerSpan = styled.span`
  color: ${(props) => props.color};
  font-size: 20px;
  align-self: center;
  margin-right: 0.2rem;
  font-weight: bold;
`;
