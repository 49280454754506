import FirstBadge from 'assets/img/leaderboard/first-pos.svg';
import SecondBadge from 'assets/img/leaderboard/second-pos.svg';
import ThirdBadge from 'assets/img/leaderboard/third-pos.svg';

const allBadge2 = {
  default: {
    bgImg: '',
    badgeColor: {
      Female: '#F27C9C',
      Male: '#31AEE2',
    },
    gradient: null,
    borderCol: null,
  },
  first: {
    bgImg: FirstBadge,
    badgeColor: '#CC7900',
    gradient:
      'linear-gradient(to right, #FFDC5D, #FFFFFF, #FFC903, #FFC903, #FFC903, #FFC903, #FFC903, #FFC903, #FFCA07)',
    borderCol: '#fac500',
  },
  second: {
    bgImg: SecondBadge,
    badgeColor: '#849AB1',
    gradient:
      'linear-gradient(to right, #dfe5eb, #ffffff, #e4e9eb, #e4e9eb, #e4e9eb, #e4e9eb, #e4e9eb, #e4e9eb, #deecf4)',
    borderCol: '#a6bdc6',
  },
  third: {
    bgImg: ThirdBadge,
    badgeColor: '#D17D1F',
    gradient:
      'linear-gradient(to right, #fcd094, #f8f8f8,  #fddfbb, #fddfbb, #fddfbb, #fddfbb, #fddfbb, #fddfbb, #fddfbb, #ffc37b)',
    borderCol: '#e3af70',
  },
};

export default allBadge2;
