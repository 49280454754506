import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import {
  fetchBadgesByMonthYear,
  fetchUnseenBadges,
} from 'services/hodCalendar';
import moment from 'moment';
// Get badges by month and year
export const getBadgesByMonthYear = createAsyncThunk(
  'hodCalendar/getBadgesByMonthYear',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchBadgesByMonthYear, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get badges by month and year failed');
    }
  }
);
// Get unseen badges
export const getUnseenBadges = createAsyncThunk(
  'hodCalendar/getUnseenBadges',
  async () => {
    try {
      const res = await fetchWrapper(fetchUnseenBadges);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get unseen badges failed');
    }
  }
);

const initialState = {
  isLoading: false,
  isUnseenBadgesLoading: false,
  badges: [],
  unseenBadges: [],
  error: null,
  unseenBadgesError: null,
  todayCPEarned: 0,
};

const hodCalendarSlice = createSlice({
  name: 'hodCalendar',
  initialState,
  reducers: {
    resetUnseenBadges: (state) => {
      return {
        ...state,
        unseenBadges: [],
        unseenBadgesError: null,
      };
    },
  },
  extraReducers: {
    [getBadgesByMonthYear.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getBadgesByMonthYear.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.badges = action.payload.badges.map((sticker) => {
        return {
          ...sticker,
          day: moment(sticker.Date, 'YYYY-MM-DDTHH:mm:ss').date(),
        };
      });
      state.todayCPEarned = action.payload.todayCPEarned;
      state.error = null;
    },
    [getBadgesByMonthYear.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getUnseenBadges.pending]: (state) => {
      state.isUnseenBadgesLoading = true;
      state.unseenBadgesError = null;
      state.unseenBadges = [];
    },
    [getUnseenBadges.fulfilled]: (state, action) => {
      state.isUnseenBadgesLoading = false;
      state.unseenBadges = action.payload;
    },
    [getUnseenBadges.rejected]: (state, action) => {
      state.isUnseenBadgesLoading = false;
      state.unseenBadgesError = action.error.message;
    },
  },
});

const { actions, reducer } = hodCalendarSlice;
export const { resetUnseenBadges } = actions;
export default reducer;
