import React, { useRef } from 'react';
import leftPart from 'assets/img/assignment/bar-model/left-r.png';
import rightPart from 'assets/img/assignment/bar-model/right-r.png';
import centerPart from 'assets/img/assignment/bar-model/center-r.png';
import linePart from 'assets/img/assignment/bar-model/middle-r.png';
import { barModelOptions } from 'constants/index';
import Positioning from './Positioning';
import { Container, SinglePart, RepeatPart } from './Component.style';

const { components } = barModelOptions;
const { braceHeight } = components;

function InvertedBrace({ size, x, y, zIndex, id }) {
  const componentRef = useRef(null);
  return (
    <Positioning
      componentRef={componentRef}
      x={x}
      y={y}
      id={id}
      size={size}
      zIndex={zIndex}
      height={braceHeight}
      vertical={false}
    >
      <Container className="invert barModelComponent" ref={componentRef}>
        <SinglePart image={leftPart} width={8} />
        <RepeatPart image={linePart} />
        <SinglePart image={centerPart} width={11} />
        <RepeatPart image={linePart} />
        <SinglePart image={rightPart} width={8} />
      </Container>
    </Positioning>
  );
}

export default React.memo(InvertedBrace);
