import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'ramda';
import { fetchRewards, fetchRewardsBySubject } from 'services/dashboard';
import { fetchWrapper } from 'services/login';
import { getTodayBonusClaimed } from 'services/dailyBonus';
import { initialCurrentLevel, initialUpcomingLevels } from 'constants/expLevel';
import { featureToggle } from 'constants/index';

// Async Thunks
export const checkDailyBonusClaimed = createAsyncThunk(
  'dashboard/checkDailyBonusClaimed',
  async () => {
    try {
      const res = await fetchWrapper(getTodayBonusClaimed);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get daily bonus claimed failed');
    }
  }
);

export const getRewards = createAsyncThunk(
  'dashboard/getRewards',
  async (_, { getState }) => {
    const { plan } = getState();
    const { subject } = plan;
    try {
      const res = featureToggle.science
        ? await fetchWrapper(fetchRewardsBySubject, 1)
        : await fetchWrapper(fetchRewards);
      return {
        result: res,
        isMath: subject?.toLowerCase().trim() === 'math',
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Get rewards failed');
    }
  }
);

export const getB2BCPs = createAsyncThunk(
  'dashboard/getB2BCPs',
  async (_, { getState }) => {
    const { plan } = getState();
    const { products } = plan;
    const availableSubjects = products?.filter(
      (item) => !isEmpty(item.nonExpiredSubscriptionProducts)
    );

    try {
      const promises = availableSubjects.map((data) => {
        const promise = fetchWrapper(fetchRewardsBySubject, data.id);
        return promise.then((response) => {
          return { ...response.Result, subject: data.subject.toLowerCase() };
        });
      });
      const res = await Promise.all(promises);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get total CPs by subject failed');
    }
  }
);

export const getTotalCPsBySubject = createAsyncThunk(
  'dashboard/getTotalCPsBySubject',
  async (subjectID) => {
    try {
      const res = fetchWrapper(fetchRewardsBySubject, subjectID);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get total CPs by subject failed');
    }
  }
);

const initialState = {
  rewards: {
    TotalKoko: 0,
    TotalCP: 0,
    TotalEXP: 0,
    EXPData: {
      CurrentLevel: initialCurrentLevel,
      UpcomingLevels: initialUpcomingLevels,
    },
  },
  isRewardsBySubjectLoading: false,
  currentSubjectTotalCPs: null,
  getRewardsBySubjectErr: null,
  isLoading: false,
  error: null,
  isDailyBonusLoading: false,
  dailyBonusKoKoToBeClaimed: 1,
  isDailyBonusClaimable: null,
  dailyBonusError: null,
  b2bCP: [],
};

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    setRewards: (state, action) => {
      state.rewards = action.payload;
    },
  },
  extraReducers: {
    [getRewards.pending]: (state) => {
      state.isLoading = true;
      state.rewards = null;
      state.error = null;
    },
    [getRewards.fulfilled]: (state, action) => {
      state.rewards = action.payload.result.Result;
      state.isLoading = false;
      if (action.payload.isMath) {
        state.currentSubjectTotalCPs = action.payload.result.Result.TotalCP;
      }
      state.error = null;
    },
    [getRewards.rejected]: (state, action) => {
      state.isLoading = false;
      state.rewards = null;
      state.error = action.error.message;
    },
    [getB2BCPs.pending]: (state) => {
      state.isLoading = true;
      state.b2bCP = [];
      state.error = null;
    },
    [getB2BCPs.fulfilled]: (state, action) => {
      state.b2bCP = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    [getB2BCPs.rejected]: (state, action) => {
      state.isLoading = false;
      state.b2bCP = [];
      state.error = action.error.message;
    },
    [getTotalCPsBySubject.pending]: (state) => {
      state.isRewardsBySubjectLoading = true;
      state.currentSubjectTotalCPs = null;
      state.getRewardsBySubjectErr = null;
    },
    [getTotalCPsBySubject.fulfilled]: (state, action) => {
      state.isRewardsBySubjectLoading = false;
      state.currentSubjectTotalCPs = action.payload.Result.TotalCP;
    },
    [getTotalCPsBySubject.rejected]: (state, action) => {
      state.isRewardsBySubjectLoading = false;
      state.getRewardsBySubjectErr = action.error.message;
    },
    [checkDailyBonusClaimed.pending]: (state) => {
      state.isDailyBonusLoading = true;
      state.dailyBonusError = null;
    },
    [checkDailyBonusClaimed.fulfilled]: (state, action) => {
      state.isDailyBonusLoading = false;
      state.isDailyBonusClaimable = !action.payload.IsDailyBonusClaimed;
      state.dailyBonusKoKoToBeClaimed = action.payload.PointsToBeClaimed;
    },
    [checkDailyBonusClaimed.rejected]: (state, action) => {
      state.isDailyBonusLoading = false;
      state.dailyBonusError = action.error.message;
    },
  },
});

const { actions, reducer } = rewardsSlice;
export const { setRewards } = actions;
export default reducer;
