import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .desc {
    font-size: 14px;
    margin: 1rem 0;
    width: 100%;
    .text {
      margin: 0 8px;
    }
    .comment-field {
      min-height: 200px;
      border: 1px solid #dddddd;
      width: 100%;
      padding: 0.5rem;
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    .error {
      color: #ff5b00;
      font-size: 0.8rem;
    }
    .btn {
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      outline: none;
      border-radius: 2px;
      font-family: Muli;
      &:focus {
        box-shadow: none;
      }
    }
    .orange {
      background: #ff5b00;
      color: #ffffff;
      border: 1px solid #ff5b00;
      margin-bottom: 0.8rem;
    }
    .white {
      background: #ffffff;
      color: #ff5b00;
      border: 1px solid #ffdac4;
    }
  }
  .underline {
    color: #000000;
    text-decoration: underline;
  }
`;
