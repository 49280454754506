import React from 'react';
import IconVersus from 'assets/img/multiplayer/icon-versus.svg';
import AvatarPlaceholder from 'assets/img/avatar.png';
import useResponsiveValue from 'hooks/responsive/useResponsiveValue';
import {
  StyledCountry,
  StyledImage,
  StyledName,
  StyledVersusIcon,
} from './PeerChallengeVersus.styles';

const PeerChallengeVersus = ({ userData = {}, opponentData = {} }) => {
  const flagWidth = useResponsiveValue({
    mobile: '18px',
    desktop: '38px',
    tablet: '38px',
  });
  return (
    <>
      <StyledName data-cy="current-user">{userData.FullName ?? ''}</StyledName>
      {userData?.Country && (
        <StyledCountry
          src={`https://s3.ap-southeast-1.amazonaws.com/static.koobits/countryflags/64/${userData?.Country?.IsoCode2?.toLowerCase()}_64.png`}
          alt=""
          width={flagWidth}
          onError={(e) => {
            e.target.style.display = 'none';
          }}
        />
      )}
      <StyledImage
        src={userData.UserAvatarImage ?? AvatarPlaceholder}
        alt=""
        onError={(e) => {
          e.target.src = AvatarPlaceholder;
        }}
      />
      <StyledVersusIcon>
        <img src={IconVersus} alt="icon" />
      </StyledVersusIcon>
      <StyledImage
        src={opponentData.UserAvatarImage ?? AvatarPlaceholder}
        alt=""
        onError={(e) => {
          e.target.src = AvatarPlaceholder;
        }}
      />
      {opponentData?.Country && (
        <StyledCountry
          src={`https://s3.ap-southeast-1.amazonaws.com/static.koobits/countryflags/64/${opponentData?.Country?.IsoCode2?.toLowerCase()}_64.png`}
          alt=""
          width={flagWidth}
          onError={(e) => {
            e.target.style.display = 'none';
          }}
        />
      )}
      <StyledName data-cy="opponent">{opponentData.FullName ?? ''}</StyledName>
    </>
  );
};

export default PeerChallengeVersus;
