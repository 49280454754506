import React from 'react';
import PopupModal from 'components/Shared/PopupModal';
import Button from 'components/Shared/Button';
import Spinner from 'components/Shared/Spinner';

import IconSuccess from 'assets/img/friends/icon-success.svg';
import IconOps from 'assets/img/friends/icon-ops.svg';

import { Container } from './ModalContentIcon.styles';

const ModalContentIcon = ({
  children,
  showModal,
  backdrop,
  hide,
  size,
  successIcon,
  errorIcon,
  skipSuccessTitle,
  skipErrorTitle,
  successTitle,
  errorTitle,
  error,
  loading,
  data,
  onClick,
  errorBtnText,
  successBtnText,
  containerMinWidth,
}) => {
  return (
    <PopupModal
      show={showModal}
      backdrop={backdrop}
      keyboard={false}
      onHide={hide}
      size={size}
    >
      <Container error={error} minWidth={containerMinWidth}>
        {loading && <Spinner />}
        {!loading && (
          <>
            <img
              className="icon"
              src={data ? successIcon || IconSuccess : errorIcon || IconOps}
              alt="icon"
            />
            <p className="success-text">
              {data
                ? successTitle || (!skipSuccessTitle && 'Success')
                : errorTitle || (!skipErrorTitle && 'Oops!')}
            </p>
            {data && children}
            {error && <p className="error-msg">{error}</p>}
            <Button
              className="okay-button"
              width="220px"
              onClick={onClick}
              variant="primary"
            >
              {!errorBtnText && successBtnText}
              {errorBtnText && data ? successBtnText : errorBtnText}
            </Button>
          </>
        )}
      </Container>
    </PopupModal>
  );
};

export default ModalContentIcon;
