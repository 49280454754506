import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Shared/Button';
import { setPlan } from 'store/plan/planSlice';
import { useDispatch, useSelector } from 'react-redux';
import PremiumPlanIcon from 'assets/img/premiumschooltoggle/premium-plan.png';
import SchoolPlanIcon from 'assets/img/premiumschooltoggle/school-plan.png';
import { fetchWrapper } from 'services/login';
import updatePreferences from 'services/premiumSchoolPreferences';
import { isNil } from 'ramda';
import {
  Container,
  OptionsContainer,
  Option,
} from './FirstTimeSelection.styles';

const PlanOptions = ({ selectedPreference, setSelectedPreference }) => {
  const dispatch = useDispatch();
  const options = [
    {
      id: 1,
      planName: 'Premium',
      img: PremiumPlanIcon,
    },
    {
      id: 2,
      planName: 'School',
      img: SchoolPlanIcon,
    },
  ];

  return (
    <OptionsContainer>
      {options.map((option) => (
        <Option
          key={option.id}
          isSelected={selectedPreference?.id === option.id}
          onClick={() => {
            setSelectedPreference(option);
            dispatch(setPlan(option.planName.toLowerCase()));
          }}
          data-cy={option.planName}
        >
          <img
            src={option.img}
            alt={option.planName}
            width="121px"
            height="35.5px"
          />
        </Option>
      ))}
    </OptionsContainer>
  );
};

const FirstTimeSelection = ({ hideLoader }) => {
  const { t } = useTranslation(['switchPlan']);
  // Redux States
  const { products, currentProduct } = useSelector((state) => state.plan);
  // Component States
  const [selectedPreference, setSelectedPreference] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // Event Handler
  const handleSubmit = () => {
    setIsUpdating(true);
    let newPlan;
    let newSubProduct;
    if (process.env.REACT_APP_SCIENCE === 'true') {
      const currentParentProduct = products.find(
        (product) => product.id === currentProduct.productParentId
      );
      const newProductType = selectedPreference;
      newSubProduct = isNil(currentParentProduct)
        ? null
        : currentParentProduct.nonExpiredSubscriptionProducts.find(
            (subProduct) =>
              subProduct.productType.toLowerCase() === newProductType
          );
      if (!isNil(newSubProduct)) newPlan = newSubProduct.productId;
    } else {
      newPlan = selectedPreference.planName.toLowerCase();
    }
    if (!isNil(newPlan)) {
      try {
        fetchWrapper(updatePreferences, newPlan);
      } catch (err) {
        console.error(err.message);
      }
    }
    hideLoader();
  };
  return (
    <Container data-cy="plan-selection">
      <p className="instructions">
        {t(
          'switchPlan:firstTimeSelection.instruction',
          'Please choose which account you want to login first'
        )}
      </p>
      <PlanOptions
        selectedPreference={selectedPreference}
        setSelectedPreference={setSelectedPreference}
      />
      <Button
        variant="primary"
        isDisabled={selectedPreference === null || isUpdating}
        onClick={handleSubmit}
        dataCy="confirm-btn"
      >
        {t('switchPlan:firstTimeSelection.confirm', 'Confirm')}
      </Button>
    </Container>
  );
};

export default FirstTimeSelection;
