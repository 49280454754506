import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: inherit;
  display: block;
  -webkit-box-direction: normal;
  height: 100%;
`;

export default Container;
