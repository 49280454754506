import React from 'react';
import ProgressArrow from 'assets/img/exp-level/dashboard/progress-arrow.svg';
import XPIcon from 'assets/img/exp-level/dashboard/xp-level.png';
import BarStart from 'assets/img/exp-level/progress-bar/start-bar.png';
import BarMid from 'assets/img/exp-level/progress-bar/mid-bar.png';
import BarEnd from 'assets/img/exp-level/progress-bar/end-bar.png';
import { useSelector } from 'react-redux';
import Spinner from 'components/Shared/Spinner';
import { Trans, useTranslation } from 'react-i18next';
import {
  StyledContainer,
  StyledProgressBar,
  StyledProgressBorder,
} from './ExpLevelHeaderStudent.styles';

const ExpLevelHeaderStudent = ({ currentLevel, totalExp, minExp, nextExp }) => {
  const maxWidth = 19;
  const { t } = useTranslation('expLevel');
  const { error: xpError, isLoading: xpLoading } = useSelector(
    (state) => state.rewards
  );

  const calculateProgress = () => {
    if (currentLevel === 100) return 1;
    return (totalExp - minExp) / (nextExp - minExp);
  };

  const calculateNumOfMiddleBars = () => {
    const currentValue = calculateProgress() * maxWidth;
    return currentValue >= maxWidth ? 19 : Math.floor(calculateProgress() * 19);
  };

  const renderProgressBar = () => {
    const numOfMiddleBars = calculateNumOfMiddleBars();
    const middleBarsArray = Array(numOfMiddleBars).fill(1);

    return (
      <>
        <img
          className="start-bar"
          src={BarStart}
          width="17.5px"
          height="17px"
          alt=""
        />
        {middleBarsArray.map((index) => (
          <img
            key={index}
            className="mid-bar"
            src={BarMid}
            width="17.5px"
            height="17px"
            alt=""
          />
        ))}
        <img
          className="end-bar"
          src={BarEnd}
          width="17.5px"
          height="17px"
          alt=""
        />
      </>
    );
  };

  const renderContent = () => {
    if (xpLoading) return <Spinner animation="border" />;
    if (xpError !== null) return <span>{xpError}</span>;

    return (
      <StyledContainer currentLevel={currentLevel}>
        <div className="badge-icon">
          <img
            width="120px"
            src={`https://static.content.koobits.com/coreproduct/EXPLevel/big/${currentLevel}.png`}
            alt="badge"
          />
        </div>
        <StyledProgressBar currentLevel={currentLevel}>
          <div className="exp-total">
            <Trans
              i18nKey="expLevel:header.text"
              t={t}
              values={{ number: totalExp }}
            >
              You have <span className="exp-total-number">{totalExp}</span>
              <img className="exp-total-xp" src={XPIcon} alt="xp" />
              in total
            </Trans>
          </div>
          <div className="exp-row exp-progress">
            <StyledProgressBorder
              fill={calculateProgress()}
              maxWidth={maxWidth}
            >
              <div className="bar">
                <div className="empty-bar" />
                <div className="fill-bar">
                  {totalExp > minExp && renderProgressBar()}
                </div>
              </div>
            </StyledProgressBorder>
            <div className="exp-current-level">
              <span className="exp-current-text">
                {t('expLevel:header.level', 'Lvl')}
              </span>
              <span className="exp-current-number">
                {currentLevel >= 100
                  ? t('expLevel:header.max', 'Max')
                  : currentLevel + 1}
              </span>
            </div>
          </div>
          {currentLevel < 100 && (
            <div className="exp-row exp-required">
              <div className="exp-small exp-min">
                {minExp}
                <span> XP</span>
              </div>
              <img src={ProgressArrow} alt="progress-arrow" />
              <div className="exp-small exp-max">
                {nextExp}
                <span> XP</span>
              </div>
            </div>
          )}
        </StyledProgressBar>
      </StyledContainer>
    );
  };

  return <>{renderContent()}</>;
};

export default ExpLevelHeaderStudent;
