import styled from 'styled-components';
import { StyledForm, StyledInput } from './BFFSearchBar.styles';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 120px;
  border-radius: 12px;
  background: ${(props) => (props.isSelected ? '#FFFAF4' : 'white')};
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.07);
  margin: 10px 0px;
  outline: ${(props) =>
    props.isSelected ? props.selectedOutlineStyle : 'none'};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'normal')};
  &.science {
    flex-direction: column;
    max-width: 240px;
    margin: 10px auto;
    min-height: 120px;
    height: auto;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      margin: 0 17px;
      height: auto;
      min-height: 327.39px;
      flex-direction: column;
      width: 32%;
      margin: 0 8px;
    }
    &.science {
      min-height: 327.39px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      width: 200px;
      margin: 0 17px;
    }
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 140px;
`;

export const StyledTitle = styled.div`
  background: ${(props) => props.bgColour};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 0 0 0;
  .science & {
    border-radius: 12px 12px 0 0;
  }
  height: 42px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  text-align: center;
  .card--title {
    text-shadow: ${(props) => props.textShadow};
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      font-size: 18px;
      border-radius: 12px 12px 0 0;
    }
    border-radius: 0px;
  }
`;

export const RoundedTriangle = styled.div`
  position: absolute;
  bottom: -10px;
  svg {
    path {
      fill: ${(props) => props.fillColour};
    }
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.bgColour};
  border-bottom-left-radius: 12px;
  .science & {
    min-height: 120px;
    border-bottom-left-radius: 0px;
  }
  .avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: solid 5px white;
  }
  img {
    height: 70%;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    border-bottom-left-radius: 0px;
    .science & {
      min-height: initial;
    }
    & {
      height: 114px;
      border-radius: 0px;
      img {
        height: auto;
      }

      .avatar {
        width: 84px;
        height: 84px;
      }
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 16px 11px;
  display: block;
  /* flex-direction: column;
  align-items: stretch;
  justify-content: center; */
  .science & {
    min-height: 140px;
  }
  ${StyledForm} {
    width: 100%;
  }
  ${StyledInput} {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    position: relative;
    width: 0px;
    flex-grow: 1;
    .science & {
      width: 100%;
    }
  }
`;
