import styled from 'styled-components';
import { isNil } from 'ramda';

/* eslint-disable */

export const Container = styled.button`
  display: flex;
  align-items: center;
  background: ${(props) => props.bgColor};
  color: ${({ color }) => color || '#ffffff'};
  border-radius: 2rem;
  border: none;
  padding: ${(props) => (props.padding ? props.padding : '0.5rem')};
  font-weight: 600;
  cursor: pointer;
  word-break: keep-all;
  top: ${(props) => props.top};
  font-size: ${(props) => (isNil(props.fontSize) ? '1rem' : props.fontSize)};
  svg {
    margin-right: 0.3rem;
    color: ${({ arrowColor }) => arrowColor};
    height: ${(props) => props.iconSize};
  }
  &.responsive {
    width: 32px;
    height: 32px;
    padding: 0px;

    img {
      margin: auto;
    }
    .button-text {
      display: none;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    &.responsive {
      width: auto;
      height: auto;
      padding: ${(props) => (props.padding ? props.padding : '0.5rem')};

      img {
        margin-right: 0.3rem;
      }
      .button-text {
        display: inline;
      }
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: flex;
    justify-content: center;
    svg {
      margin-right: 0;
    }
  }
`;
