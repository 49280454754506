import React from 'react';
import { Container } from './QnResultStatus.styles';

const QnResultStatus = ({ icon, text }) => {
  return (
    <Container>
      <img src={icon} alt="icon" />
      <p>{text}</p>
    </Container>
  );
};

export default QnResultStatus;
