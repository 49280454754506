import React from 'react';
import Emoji from 'assets/img/superhero/supervision-lose.png';
import { useTranslation } from 'react-i18next';
import {
  Container,
  TextInfo,
  WrapButton,
  OtherChallengeButton,
} from './MessageWin.style';

const MessageLost = ({ score, onGoToOtherChallenge }) => {
  const { t } = useTranslation(['superVision']);
  return (
    <Container>
      <TextInfo>
        <img src={Emoji} alt="Smile with sweat droplet" />
        {t('superVision:game.lostText', 'You received ')}
        <strong>{score}</strong>
        {t(
          'superVision:game.lostText2',
          ' score. Please come back tomorrow to improve your score.'
        )}
      </TextInfo>
      <WrapButton>
        <OtherChallengeButton onClick={onGoToOtherChallenge}>
          {t('game.goOther', 'Go to other challenges')}
        </OtherChallengeButton>
      </WrapButton>
    </Container>
  );
};

export default React.memo(MessageLost);
