import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, BannerTop } from './GlobalTop100Table.styles';
import GlobalTop100Row from '../GlobalTop100Row/GlobalTop100Row';

const GlobalTop100Table = ({ color, globalTop100List, title }) => {
  const { t } = useTranslation(['leaderboard']);
  return (
    <Table color={color}>
      <BannerTop color={color}>
        <p>{title}</p>
      </BannerTop>
      <thead className="global-header">
        <tr>
          <th className="text-center">
            {t('leaderboard:boysVsGirls.ranking.rank', 'Rank')}
          </th>
          <th>
            <span>
              {t(
                'leaderboard:boysVsGirls.ranking.nameSchool',
                'Name and School'
              )}
            </span>
          </th>
          <th className="text-center">
            {t('leaderboard:boysVsGirls.ranking.cps', 'CPs')}
          </th>
        </tr>
      </thead>
      <tbody>
        {globalTop100List.map(
          ({ rank, username, schoolName, schoolLogo, cP, submissionDate }) => (
            <GlobalTop100Row
              key={rank}
              rank={rank}
              username={username}
              schoolName={schoolName}
              schoolLogo={schoolLogo}
              cp={cP}
              submissionDate={submissionDate}
            />
          )
        )}
      </tbody>
    </Table>
  );
};

export default GlobalTop100Table;
