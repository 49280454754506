import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  font-size: 1rem;
  .desc {
    margin-bottom: 0;
  }
  .objective {
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 1rem 0;
    span {
      margin-left: 0.5rem;
    }
  }
`;

export const Action = styled.div`
  display: flex;
  margin: 1rem 0.5rem 0.5rem;
  gap: 1.8rem;
  justify-content: center;
`;
