import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchScroll,
  fetchCourses,
  fetchTopicsByCourse,
  fetchLessonsByTopic,
  unlockLessonRequest,
  fetchLessonByLessonId,
  fetchActivitiesByLessonId,
  fetchActivityByActivityId,
  saveKooClassSubmission,
  checkKooClassAnswer,
  postStartActivity,
  closeKooClass,
  postEndActivity,
  fetchKooClassSolutions,
  fetchRetryActivity,
} from 'services/kooClass';
import { fetchWrapper } from 'services/login';
import { sortedData } from 'helpers/compareValue';

// get scrolls
export const getScrolls = createAsyncThunk('kooClass/getScrolls', async () => {
  try {
    const res = await fetchWrapper(fetchScroll);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Get scrolls failed');
  }
});

// get courses available for user
export const getCourses = createAsyncThunk('kooClass/getCourses', async () => {
  try {
    const res = await fetchWrapper(fetchCourses);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Get courses failed');
  }
});

export const getTopicsByCourseId = createAsyncThunk(
  'kooClass/getTopicsByCourseId',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTopicsByCourse, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get topic by course ID failed');
    }
  }
);

// get lessons
export const getLessonsByTopic = createAsyncThunk(
  'kooClass/getLessonsByTopic',
  async (topicId) => {
    try {
      const res = await fetchWrapper(fetchLessonsByTopic, topicId);
      // const res = mockLessons5;
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get lesson by topicId failed');
    }
  }
);

export const unlockLesson = createAsyncThunk(
  'kooClass/unlockLesson',
  async (lessonId) => {
    try {
      const res = await fetchWrapper(unlockLessonRequest, lessonId);
      return {
        res,
        lessonId,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Unlock lesson failed');
    }
  }
);

export const getLessonByLessonId = createAsyncThunk(
  'kooClass/getLessonByLessonId',
  async (lessonId) => {
    try {
      const res = await fetchWrapper(fetchLessonByLessonId, lessonId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get lesson by lesson Id failed');
    }
  }
);

// get activities
export const getActivitiesByLessonId = createAsyncThunk(
  'kooClass/getActivitiesByLessonId',
  async (lessonId) => {
    try {
      const res = await fetchWrapper(fetchActivitiesByLessonId, lessonId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get activities failed');
    }
  }
);

export const getActivityByActivityId = createAsyncThunk(
  'kooClass/getActivityByActivityId',
  async (activityId) => {
    try {
      const res = await fetchWrapper(fetchActivityByActivityId, activityId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get activity failed');
    }
  }
);

export const startActivity = createAsyncThunk(
  'kooClass/startActivity',
  async (activityId) => {
    try {
      const res = await fetchWrapper(postStartActivity, activityId);
      return {
        res,
        UserQuestionSubmissions: [
          ...res.userPayload.userSubmissions.map((item) => {
            return {
              question: item.question,
              SubmissionModel: item.submissionModel,
            };
          }),
        ],
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Start activity failed');
    }
  }
);

// Save kooclass answer
export const saveKooClassAnswer = createAsyncThunk(
  'kooClass/saveKooClassAnswer',
  async (params) => {
    const { activeQuestion } = params;
    try {
      const res = await fetchWrapper(saveKooClassSubmission, params);
      return {
        result: res,
        activeQuestion,
      };
    } catch (err) {
      throw new Error(err?.message ?? 'Save KooClass submission failed');
    }
  }
);

// Check kooclass answer
export const kooClassCheckAnswer = createAsyncThunk(
  'kooClass/kooClassCheckAnswer',
  async (params) => {
    const { activeQuestion } = params;
    try {
      const res = await fetchWrapper(checkKooClassAnswer, params);
      return {
        result: res,
        activeQuestion,
      };
    } catch (err) {
      throw new Error(
        err?.message ?? 'Check KooClass answer submission failed'
      );
    }
  }
);

// Save and Finish kooclass answer
export const finishKooClass = createAsyncThunk(
  'kooClass/finishKooClass',
  async (params) => {
    const { activeQuestion, userActivityId } = params;
    try {
      const saveAnswerResponse = await fetchWrapper(
        saveKooClassSubmission,
        params
      );
      const finishResponse = await fetchWrapper(closeKooClass, userActivityId);
      return {
        saveResult: saveAnswerResponse,
        activeQuestion,
        finishResult: finishResponse,
        UserQuestionSubmissions: [
          ...finishResponse.userPayload.userSubmissions.map((item) => {
            return {
              question: item.question,
              SubmissionModel: item.submissionModel,
            };
          }),
        ],
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Finish KooClass failed');
    }
  }
);

// Endactivity for kooclass
export const endActivityKooClass = createAsyncThunk(
  'kooClass/endActivityKooClass',
  async (userActivityId) => {
    try {
      const res = await fetchWrapper(postEndActivity, userActivityId);
      return {
        res,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'End activity KooClass failed');
    }
  }
);

export const getKooClassSolutions = createAsyncThunk(
  'kooClass/getKooClassSolution',
  async (activityId) => {
    try {
      const res = await fetchWrapper(fetchKooClassSolutions, activityId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get KooClass solution failed');
    }
  }
);

export const getRetryActivity = createAsyncThunk(
  'kooClass/getRetryActivity',
  async (activityId) => {
    try {
      const res = await fetchWrapper(fetchRetryActivity, activityId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Retry activity failed');
    }
  }
);

const initialState = {
  courses: null,
  topics: [],
  topicsLoading: false,
  topicsErr: null,
  lessons: [],
  lessonsLoading: true,
  lessonsErr: null,
  activities: [],
  activitiesLoading: false,
  activitiesErr: null,
  activeType: null,
  activeId: null,
  activityName: null,
  isLoading: false,
  isErr: null,
  isErrFinish: null,
  unlockLoading: false,
  unlock: false,
  unlockErr: null,
  currentLesson: null,
  currentLessonLoading: false,
  currentLessonErr: null,
  currentActivity: null,
  currentActivityLoading: false,
  currentActivityErr: null,
  scrolls: null,
  scrollsLoading: false,
  scrollsErr: null,
  lastVisitedTopic: null,
  startKooClassInfo: null,
  kooClassSubmissionID: null,
  kooClassActiveQuestion: 1,
  kooClassQuestions: [],
  kooClassQuestionAnswers: [],
  kooClassSubmissions: [],
  kooClassCheckedAnswers: [],
  kooClassLocalSavedAnswers: [],
  kooClassSavedAnswers: [],
  kooClassWorkings: [],
  isKooClassChecking: [],
  isKooClassFinishing: false,
  isKooClassLoading: false,
  isKooClassSubmitting: false,
  isKooClassSaving: false,
  kooClassSaveError: null,
  kooClassSubmissionResult: null,
  kooClassSubmissionError: null,
  kooClassCheckAnswerError: null,
  isKooClassSolutionsLoading: false,
  kooClassSolutions: null,
  isKooClassSolutionsError: null,
  error: null,
  endActivity: null,
  isFinish: false,
  retryActivity: null,
  isRetryLoading: false,
  isRetryErr: null,
  videoIsFullScreen: false,
};

const kooClassSlice = createSlice({
  name: 'kooClass',
  initialState,
  reducers: {
    setLastVisitedTopic: (state, action) => {
      state.lastVisitedTopic = action.payload;
    },
    setKooClassActiveQuestion: (state, action) => {
      state.kooClassActiveQuestion = action.payload;
    },
    saveKooClassAnswerLocally: (state, action) => {
      const newAnswers = [...state.kooClassLocalSavedAnswers];
      newAnswers[action.payload.index] = action.payload.answers;
      state.kooClassLocalSavedAnswers = newAnswers;
    },
    saveKooClassWorkingsLocally: (state, action) => {
      const newWorkings = [...state.kooClassWorkings];
      newWorkings[action.payload.index] = action.payload.workings;
      state.kooClassWorkings = newWorkings;
    },
    clearKooClassSaveError: (state) => {
      state.kooClassSaveError = null;
    },
    clearKooClassSavedAnswer: (state, action) => {
      const newSavedAnswers = [...state.kooClassSavedAnswers];
      newSavedAnswers[action.payload] = null;
      state.kooClassSavedAnswers = newSavedAnswers;
    },
    reset: (state) => {
      return {
        ...state,
        kooClassActiveQuestion: 1,
      };
    },
    resetErr: (state) => {
      state.unlockErr = null;
    },
    resetUnlock: (state) => {
      state.unlock = false;
    },
    setActiveType: (state, action) => {
      state.activeType = action.payload;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    setActivityName: (state, action) => {
      state.activityName = action.payload;
    },
    resetActivity: (state) => {
      state.activeType = null;
      state.activeId = null;
      state.activityName = null;
      state.activities = [];
    },
    resetCurrentActivity: (state) => {
      state.currentActivity = null;
      state.currentActivityErr = null;
    },
    resetStartActivity: (state) => {
      state.startKooClassInfo = null;
      state.error = null;
    },
    resetEndActivity: (state) => {
      state.endActivity = null;
      state.isErr = null;
    },
    resetFinish: (state) => {
      state.isFinish = false;
    },
    resetRetry: (state) => {
      state.retryActivity = null;
      state.startKooClassInfo = null;
      state.isRetryErr = null;
    },
    clearFinishError: (state) => {
      state.isErrFinish = null;
    },
    clearSolutionError: (state) => {
      state.isKooClassSolutionsError = null;
    },
    setVideoIsFullScreen: (state, action) => {
      state.videoIsFullScreen = action.payload;
    },
  },
  extraReducers: {
    [getScrolls.pending]: (state) => {
      state.scrollsLoading = true;
      state.scrolls = null;
      state.scrollsErr = null;
    },
    [getScrolls.fulfilled]: (state, action) => {
      state.scrollsLoading = false;
      state.scrolls = action.payload;
      state.scrollsErr = null;
    },
    [getScrolls.rejected]: (state, action) => {
      state.scrollsLoading = false;
      state.scrolls = null;
      state.scrollsErr = action.error.message;
    },
    [getCourses.pending]: (state) => {
      state.isLoading = true;
      state.courses = null;
      state.isErr = null;
    },
    [getCourses.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.courses = sortedData(action.payload, 'displayOrder', 'asc');
      state.isErr = null;
    },
    [getCourses.rejected]: (state, action) => {
      state.isLoading = false;
      state.courses = null;
      state.isErr = action.error.message;
    },
    [getTopicsByCourseId.pending]: (state) => {
      state.topicsLoading = true;
      state.topics = [];
      state.topicsErr = null;
    },
    [getTopicsByCourseId.fulfilled]: (state, action) => {
      state.topicsLoading = false;
      state.topics = sortedData(action.payload, 'displayOrder', 'asc');
      state.topicsErr = null;
    },
    [getTopicsByCourseId.rejected]: (state, action) => {
      state.topicsLoading = false;
      state.topics = [];
      state.topicsErr = action.error.message;
    },
    [getLessonsByTopic.pending]: (state) => {
      state.lessonsLoading = true;
      state.lessonsErr = null;
    },
    [getLessonsByTopic.fulfilled]: (state, action) => {
      state.lessonsLoading = false;
      state.lessons = sortedData(action.payload, 'displayOrder', 'asc');
      state.lessonsErr = null;
    },
    [getLessonsByTopic.rejected]: (state, action) => {
      state.lessonsLoading = false;
      state.lessons = [];
      state.lessonsErr = action.error.message;
    },
    [unlockLesson.pending]: (state) => {
      state.unlockLoading = true;
      state.unlockErr = null;
    },
    [unlockLesson.fulfilled]: (state, action) => {
      state.unlockLoading = false;
      state.unlock = true;
      state.lessons = state.lessons.map((item) => {
        if (item.id === action.payload.lessonId) return action.payload.res;
        return item;
      });
      state.unlockErr = null;
    },
    [unlockLesson.rejected]: (state, action) => {
      state.unlockLoading = false;
      state.unlockErr = action.error.message;
    },
    [getLessonByLessonId.pending]: (state) => {
      state.currentLessonLoading = true;
      state.currentLesson = null;
      state.currentLessonErr = null;
    },
    [getLessonByLessonId.fulfilled]: (state, action) => {
      state.currentLessonLoading = false;
      state.currentLesson = action.payload;
      state.currentLessonErr = null;
    },
    [getLessonByLessonId.rejected]: (state, action) => {
      state.currentLessonLoading = false;
      state.currentLesson = null;
      state.currentLessonErr = action.error.message;
    },
    [getActivitiesByLessonId.pending]: (state) => {
      state.activitiesLoading = true;
      state.activities = [];
      state.activitiesErr = null;
    },
    [getActivitiesByLessonId.fulfilled]: (state, action) => {
      state.activitiesLoading = false;
      state.activities = sortedData(action.payload, 'displayOrder', 'asc');
      state.activitiesErr = null;
    },
    [getActivitiesByLessonId.rejected]: (state, action) => {
      state.activitiesLoading = false;
      state.activities = null;
      state.activitiesErr = action.error.message;
    },
    [getActivityByActivityId.pending]: (state) => {
      state.currentActivityLoading = true;
      state.currentActivity = null;
      state.currentActivityErr = null;
    },
    [getActivityByActivityId.fulfilled]: (state, action) => {
      state.currentActivityLoading = false;
      state.currentActivity = action.payload;
      state.currentActivityErr = null;
    },
    [getActivityByActivityId.rejected]: (state, action) => {
      state.currentActivityLoading = false;
      state.currentActivity = null;
      state.currentActivityErr = action.error.message;
    },
    [startActivity.pending]: (state) => {
      state.isKooClassOpening = true;
      state.error = null;
    },
    [startActivity.fulfilled]: (state, action) => {
      state.isKooClassOpening = false;
      state.error = null;
      state.startKooClassInfo = action.payload.res;
      state.kooClassQnTitle = action.payload.res.userPayload.title;
      state.kooClassSubmissionID = action.payload.res.id;
      state.kooClassLocalSavedAnswers = Array(
        action.payload.UserQuestionSubmissions.length
      ).fill(null);
      state.kooClassWorkings = Array(
        action.payload.UserQuestionSubmissions.length
      ).fill(null);
      const sortedUserQuestionSubmissions =
        action.payload.UserQuestionSubmissions;
      state.isKooClassChecking = Array(
        sortedUserQuestionSubmissions.length
      ).fill(false);
      state.kooClassSubmissions = sortedUserQuestionSubmissions;
      state.kooClassSavedAnswers = sortedUserQuestionSubmissions.map(
        (userQnSubmission) => userQnSubmission.SubmissionModel
      );
      state.kooClassQuestions = sortedUserQuestionSubmissions.map(
        (userQnSubmission) => userQnSubmission.question
      );
    },
    [startActivity.rejected]: (state, action) => {
      state.isKooClassOpening = false;
      state.error = action.error.message;
    },
    [saveKooClassAnswer.pending]: (state) => {
      state.isKooClassSaving = true;
      state.kooClassSaveError = null;
    },
    [saveKooClassAnswer.fulfilled]: (state, action) => {
      state.isKooClassSaving = false;
      const newSavedAnswers = [...state.kooClassSavedAnswers];
      newSavedAnswers[action.payload.activeQuestion - 1] =
        action.payload.result;
      state.kooClassSavedAnswers = newSavedAnswers;
      const userSubmission = action.payload.result;
      state.kooClassSubmissions[
        action.payload.activeQuestion - 1
      ].SubmissionModel = userSubmission;
    },
    [saveKooClassAnswer.rejected]: (state, action) => {
      state.isKooClassSaving = false;
      state.kooClassSaveError = action.error.message;
    },
    [kooClassCheckAnswer.pending]: (state, action) => {
      const newisChecking = [...state.isKooClassChecking];
      newisChecking[action.meta.arg.activeQuestion - 1] = true;
      state.isKooClassChecking = newisChecking;
      state.checkAnswerError = null;
    },
    [kooClassCheckAnswer.fulfilled]: (state, action) => {
      const newisChecking = [...state.isKooClassChecking];
      newisChecking[action.meta.arg.activeQuestion - 1] = false;
      state.isKooClassChecking = newisChecking;
      state.checkAnswerError = null;
      const index = state.kooClassQuestions.findIndex(
        (item) => item.id === action.payload.result.id
      );

      // TODO: Need to save checked answers in redux state?
      const newSavedAnswers = [...state.kooClassSavedAnswers];
      newSavedAnswers[action.meta.arg.activeQuestion - 1] =
        action.payload.result;
      state.kooClassSavedAnswers = newSavedAnswers;
      const userSubmission = action.payload.result;
      state.kooClassSubmissions[index].SubmissionModel = userSubmission;
      // const filteredXml = userSubmission.payload.replace(/\\/g, '');
      // const parsed = xmlParser(filteredXml);
      // let answerKeys = null;
      // if (parsed.questionAnswers.length > 0) {
      //   answerKeys = parsed.questionAnswers.map((answer) => answer.$);
      // }
    },
    [kooClassCheckAnswer.rejected]: (state, action) => {
      const newisChecking = [...state.isKooClassChecking];
      newisChecking[action.meta.arg.activeQuestion - 1] = false;
      state.isKooClassChecking = newisChecking;
      state.checkAnswerError = action.error.message;
    },
    [finishKooClass.pending]: (state) => {
      state.isKooClassFinishing = true;
      state.isErrFinish = null;
    },
    [finishKooClass.fulfilled]: (state, action) => {
      state.isKooClassFinishing = false;
      state.isErrFinish = null;
      const newSavedAnswers = [...state.kooClassSavedAnswers];
      newSavedAnswers[action.payload.activeQuestion - 1] =
        action.payload.saveResult;
      state.kooClassSavedAnswers = newSavedAnswers;
      const userSubmission = action.payload.saveResult;
      state.kooClassSubmissions[
        action.payload.activeQuestion - 1
      ].SubmissionModel = userSubmission;
      // const userAllSubmissions = action.payload.finishResult.userPayload;
      const userAllSubmissions = action.payload.UserQuestionSubmissions;
      state.kooClassSubmissions = userAllSubmissions;
      state.isFinish = true;
    },
    [finishKooClass.rejected]: (state, action) => {
      state.isKooClassFinishing = false;
      state.isErrFinish = action.error.message;
    },
    [getKooClassSolutions.pending]: (state) => {
      state.isKooClassSolutionsLoading = true;
      state.kooClassSolutions = null;
      state.isKooClassSolutionsError = null;
    },
    [getKooClassSolutions.fulfilled]: (state, action) => {
      state.isKooClassSolutionsLoading = false;
      state.kooClassSolutions = action.payload.userPayload;
      state.isKooClassSolutionsError = null;
    },
    [getKooClassSolutions.rejected]: (state, action) => {
      state.isKooClassSolutionsLoading = false;
      state.kooClassSolutions = null;
      state.isKooClassSolutionsError = action.error.message;
    },
    [endActivityKooClass.pending]: (state) => {
      state.isLoading = true;
      state.endActivity = null;
      state.isErr = null;
    },
    [endActivityKooClass.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.endActivity = action.payload.res;
      state.isErr = null;
    },
    [endActivityKooClass.rejected]: (state, action) => {
      state.isLoading = false;
      state.endActivity = null;
      state.isErr = action.error.message;
    },
    [getRetryActivity.pending]: (state) => {
      state.isRetryLoading = true;
      state.retryActivity = null;
      state.isRetryErr = null;
    },
    [getRetryActivity.fulfilled]: (state, action) => {
      state.isRetryLoading = false;
      state.retryActivity = action.payload;
      state.isRetryErr = null;
    },
    [getRetryActivity.rejected]: (state, action) => {
      state.isRetryLoading = false;
      state.retryActivity = null;
      state.isRetryErr = action.error.message;
    },
  },
});

const { actions, reducer } = kooClassSlice;
export const {
  setLastVisitedTopic,
  setKooClassActiveQuestion,
  saveKooClassAnswerLocally,
  saveKooClassWorkingsLocally,
  clearKooClassSaveError,
  clearKooClassSavedAnswer,
  reset,
  resetErr,
  resetUnlock,
  setActiveType,
  setActiveId,
  setActivityName,
  resetActivity,
  resetCurrentActivity,
  resetStartActivity,
  resetFinish,
  resetRetry,
  resetEndActivity,
  clearFinishError,
  clearSolutionError,
  setVideoIsFullScreen,
} = actions;
export default reducer;
