import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { bvsgFaces } from 'constants/leaderboard';
import BackButton from 'components/Shared/BackButton';
import { ChallengeType } from 'pages/Leaderboard/v2/components';
import {
  Medal,
  GirlStar,
  BoyStar,
  BlindBoy,
  BlindGirl,
} from 'assets/img/leaderboard/boys-vs-girls';
import {
  Topbar,
  Container,
  ButtonContainer,
  BoyCard,
  GirlCard,
  Versus,
} from './BoysVsGirlsHeader.styles';

const BvGHeader = ({ totalScores }) => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  const {
    isBlindFoldPeriod,
    isCurrentlyWinning,
    teamBoysDetails,
    teamGirlsDetails,
  } = totalScores;
  const { bannerNotif } = useSelector((state) => state.notification);

  const boyScore = teamBoysDetails.totalCP;
  const girlScore = teamGirlsDetails.totalCP;
  const isBlindPeriod = isBlindFoldPeriod;

  const currentPosition = () => {
    if (boyScore > girlScore) {
      return { face: bvsgFaces.BoyWin, status: 'Boy Win' };
    }
    if (boyScore < girlScore) {
      return { face: bvsgFaces.BoyLose, status: 'Boy Lose' };
    }
    return { face: bvsgFaces.Draw, status: 'Draw' };
  };

  return (
    <Topbar blindPeriod={isBlindPeriod}>
      <ButtonContainer className="btn">
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(53, 137, 216, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <Container blindPeriod={isBlindPeriod}>
        {!isBlindPeriod && (
          <>
            <div className="score-section">
              <BoyCard
                winning={currentPosition().status === 'Boy Win'}
                banner={!isNil(bannerNotif)}
              >
                <img
                  className="face"
                  src={currentPosition().face.boy}
                  alt="boy"
                />
                {currentPosition().status === 'Boy Win' && (
                  <img className="star" src={BoyStar} alt="boy" />
                )}
                {currentPosition().status === 'Boy Win' && (
                  <img className="medal" src={Medal} alt="medal" />
                )}
                <div className="team-name">
                  <Trans i18nKey="leaderboard:boysVsGirls.header.teamBoys">
                    Team <span>Boys</span>
                  </Trans>
                </div>
                <div className="card-description">
                  <h3>{boyScore}</h3>
                  <p className="total-challenge">
                    {t(
                      'leaderboard:boysVsGirls.header.totalCP',
                      'Total Challenge Points'
                    )}
                  </p>
                  {currentPosition().status === 'Boy Win' && (
                    <p className="current-winner">
                      {isCurrentlyWinning
                        ? t(
                            'leaderboard:boysVsGirls.header.currentlyWin',
                            'Currently Winning'
                          )
                        : t(
                            'leaderboard:boysVsGirls.header.winningTeam',
                            'Winning Team'
                          )}
                    </p>
                  )}
                </div>
              </BoyCard>
              <Versus />
              <GirlCard
                winning={currentPosition().status === 'Boy Lose'}
                banner={!isNil(bannerNotif)}
              >
                <img
                  className="face"
                  src={currentPosition().face.girl}
                  alt="girl"
                />
                {currentPosition().status === 'Boy Lose' && (
                  <img className="star" src={GirlStar} alt="girl" />
                )}
                {currentPosition().status === 'Boy Lose' && (
                  <img className="medal" src={Medal} alt="medal" />
                )}
                <div className="team-name">
                  <Trans i18nKey="leaderboard:boysVsGirls.header.teamGirls">
                    Team <span>Girls</span>
                  </Trans>
                </div>
                <div className="card-description">
                  <h3>{girlScore}</h3>
                  <p className="total-challenge">
                    {t(
                      'leaderboard:boysVsGirls.header.totalCP',
                      'Total Challenge Points'
                    )}
                  </p>
                  {currentPosition().status === 'Boy Lose' && (
                    <p className="current-winner">
                      {isCurrentlyWinning
                        ? t(
                            'leaderboard:boysVsGirls.header.currentlyWin',
                            'Currently Winning'
                          )
                        : t(
                            'leaderboard:boysVsGirls.header.winningTeam',
                            'Winning Team'
                          )}
                    </p>
                  )}
                </div>
              </GirlCard>
            </div>
          </>
        )}
        {isBlindPeriod && (
          <>
            <div className="score-section">
              <BoyCard blindPeriod={isBlindPeriod} banner={!isNil(bannerNotif)}>
                <img className="face" src={BlindBoy} alt="boy" />
                <div className="team-name">
                  <Trans i18nKey="leaderboard:boysVsGirls.header.teamBoys">
                    Team <span>Boys</span>
                  </Trans>
                </div>
              </BoyCard>
              <Versus />
              <GirlCard
                blindPeriod={isBlindPeriod}
                banner={!isNil(bannerNotif)}
              >
                <img className="face" src={BlindGirl} alt="girl" />
                <div className="team-name">
                  <Trans i18nKey="leaderboard:boysVsGirls.header.teamGirls">
                    Team <span>Girls</span>
                  </Trans>
                </div>
              </GirlCard>
            </div>
            <div className="blind-details-text">
              <p>
                <Trans i18nKey="leaderboard:boysVsGirls.header.blindFoldText1">
                  <span>
                    Team total score will be hidden during Blackout period from
                  </span>
                  <span>20 July to 27 July.</span>
                </Trans>
              </p>
              <p>
                <Trans i18nKey="leaderboard:boysVsGirls.header.blindFoldText2">
                  <span>
                    Scores will still be counted during Blackout period,{' '}
                  </span>
                  so KEEP SUBMITTING!
                </Trans>
              </p>
            </div>
          </>
        )}
      </Container>
      <ChallengeType styles={{ top: '12%' }} />
    </Topbar>
  );
};

export default BvGHeader;
