import moment from 'moment-timezone';
import { isNil } from 'ramda';
import store from 'store/index';

export const checkIfChallengeOpen = (startDate, endDate) => {
  const currentYear = moment().year();
  const startDateString = `${currentYear}-${startDate}`;
  const endDateString = `${currentYear}-${endDate}`;

  return moment().isBetween(
    startDateString,
    moment(endDateString).add(1, 'days'),
    'day',
    '[)'
  );
};

// helper function for isLeaderboardFeatureOpen
export const checkIsCurrentDateTimeBetweenHours = (startTime, endTime) => {
  const timeFormat = 'HH:mm';
  const startHour = parseInt(startTime.slice(0, 3), 10);
  const endHour = parseInt(endTime.slice(0, 3), 10);
  const hasTwoTimePeriods = endHour < startHour;
  const now = moment();
  if (hasTwoTimePeriods) {
    const firstPeriodStartDateTime = moment(startTime, timeFormat);
    const firstPeriodEndDateTime = moment('23:59', timeFormat);
    const secondPeriodStartDateTime = moment('00:00', timeFormat);
    const secondPeriodEndDateTime = moment(endTime, timeFormat);
    return (
      now.isBetween(
        firstPeriodStartDateTime,
        firstPeriodEndDateTime,
        'minute',
        '[]'
      ) ||
      now.isBetween(
        secondPeriodStartDateTime,
        secondPeriodEndDateTime,
        'minute',
        '[]'
      )
    );
  }
  const startDateTime = moment(startTime, timeFormat);
  const endDateTime = moment(endTime, timeFormat);
  return now.isBetween(startDateTime, endDateTime, 'minute', '[]');
};

// to check if the leaderboard is open to the students
export const isLeaderboardFeatureOpen = (feature) => {
  if (feature.isEnabled) {
    return true;
  }
  const startTime = feature?.feature?.parameters?.startTime;
  const endTime = feature?.feature?.parameters?.endTime;
  const hasStartEndTime =
    !isNil(startTime) &&
    !isNil(endTime) &&
    startTime.length === 5 &&
    endTime.length === 5;
  if (hasStartEndTime) {
    return checkIsCurrentDateTimeBetweenHours(startTime, endTime);
  }
  return false;
};

export const convertToTimezone = (date) => {
  const { timezone } = store.getState().login;
  return moment.utc(date).tz(timezone);
};

export const getCurrentTerm = (arr) => {
  // eslint-disable-next-line array-callback-return
  return arr.find((term, i) => {
    const isBetweenTerm =
      moment().isSameOrAfter(term.startDate) &&
      moment().isSameOrBefore(term.endDate);
    const isInGapPeriod = moment().isBetween(
      term.endDate,
      arr[i + 1]?.startDate
    );

    const termNotStart = !moment().isSameOrAfter(term.startDate);
    const termEnds = moment().isAfter(term.endDate) && !arr[i + 1];

    if (isBetweenTerm || isInGapPeriod) {
      return term;
    }
    if (termNotStart) {
      return arr[0];
    }
    if (termEnds) {
      return arr[3];
    }
  });
};
