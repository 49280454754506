import convertTagsToHtml from 'helpers/convertTagsToHtml';
import xmlParser from 'helpers/xmlParser';
import HTMLReactParser from 'html-react-parser';
import { isNil } from 'ramda';
import React, { useEffect, useRef } from 'react';
import {
  QuestionAnswer,
  QuestionNumber,
  QuestionText,
  QuestionWrap,
} from './FactualFluencyQuestion.style';

const FactualFluencyQuestion = ({
  questionNumber,
  question,
  onChange,
  onSubmitAnswer,
  value,
}) => {
  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);
  let questionContent = '';
  if (!isNil(question)) {
    const parser = xmlParser(question.Payload);
    questionContent = parser.questionContent;
  }

  const renderQuestion = HTMLReactParser(
    convertTagsToHtml(questionContent, false),
    {
      replace: (domNode) => {
        if (domNode.name === 'p') {
          return (
            <QuestionText className="question">
              {domNode.children[0]?.data.replace('__', '') ?? ''}
            </QuestionText>
          );
        }
        if (domNode.name === 'input') {
          return (
            <QuestionAnswer className="question">
              <input
                type="text"
                className="form-control"
                onChange={onChange}
                onKeyPress={onSubmitAnswer}
                value={value}
                ref={inputElement}
              />
            </QuestionAnswer>
          );
        }
        return domNode;
      },
    }
  );
  const renderQuestionCondition = () => {
    if (Array.isArray(renderQuestion)) {
      return renderQuestion.filter((el) => el.type !== 'br');
    }
    return renderQuestion;
  };
  return (
    <QuestionWrap>
      <QuestionNumber className="question">{`Q${questionNumber}:`}</QuestionNumber>
      {renderQuestionCondition()}
    </QuestionWrap>
  );
};

export default React.memo(FactualFluencyQuestion);
