import React, { useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { usePageVisibility } from 'react-page-visibility';
import moment from 'moment';
import { CPContainer, RealTimeContainer } from './CPSection.styles';
import Mascot from './mascot.svg';

// Component
const CPSection = () => {
  const isVisible = usePageVisibility();
  // Component States
  const [hasInitialLoaded, setHasInitialLoaded] = useState(false);
  const [submissionsData, setSubmissionsData] = useState([]);
  // Fetch data handler

  // UseEffects
  // Fetch initial load data
  useEffect(() => {
    const getInitialData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CP_SUBMISSIONS_ENDPOINT
        );
        const data = await response.json();
        // TODO: check isSuccessful flag
        if (!data.IsSuccessful) {
          throw new Error(data.Message.Message);
        }
        setHasInitialLoaded(true);
        setSubmissionsData(data.Result);
        return { data };
      } catch (err) {
        console.log(err.message);
      }
    };
    getInitialData();
  }, []);
  // Update data in batches every 5 seconds
  useEffect(() => {
    const getNewSubmissionsData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CP_SUBMISSIONS_ENDPOINT
        );
        const data = await response.json();
        // TODO: check isSuccessful flag
        if (!data.IsSuccessful) {
          throw new Error(data.Message.Message);
        }
        setSubmissionsData(data.Result);
        return { data };
      } catch (err) {
        console.log(err.message);
      }
    };
    let interval;
    if (isVisible && hasInitialLoaded) {
      interval = setInterval(() => {
        getNewSubmissionsData();
      }, 30000);
    }
    return () => {
      if (!isNil(interval)) clearInterval(interval);
    };
  }, [isVisible, hasInitialLoaded]);
  return (
    <CPContainer>
      <RealTimeContainer>
        <p className="title">Latest CP Submitted</p>
        <table className="table">
          <thead className="table-title">
            <tr>
              <td>Name</td>
              <td>School</td>
              <td>Latest CP</td>
              <td>Submission Time</td>
              <td />
            </tr>
          </thead>
          <tbody className="table-body">
            {submissionsData.slice(0, 4).map((item, index) => (
              <tr className="border_bottom" key={`${item.Name}-${index}`}>
                <td className="student-name">{item.Name}</td>
                <td className="school">
                  <img
                    onError={(e) => {
                      e.target.style.display = 'none';
                    }}
                    src={`https://static.koobits.com/eduresources/schlogo/${item.SchoolCode}.png`}
                    alt="school-logo"
                  />
                  <span>{item.SchoolName}</span>
                </td>
                <td className="latest-CP">{item.CP}</td>
                <td className="time">
                  {moment(item.SubmittedDateTime).format('HH:mm, YYYY-MMM-DD')}
                </td>
                <td className="flag">
                  <img
                    onError={(e) => {
                      e.target.style.display = 'none';
                    }}
                    src={`https://problemsums.koobits.com/Content/images/flag/${item.CountryId}.png`}
                    alt="flag"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </RealTimeContainer>
      <img className="mascot" src={Mascot} alt="Welcome to KooBits" />
    </CPContainer>
  );
};

export default CPSection;
