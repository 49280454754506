import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';

const useResponsiveImage = (
  desktopImage = '',
  tabletImage = '',
  mobileImage = ''
) => {
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });

  let imgSrc = desktopImage;
  if (isTablet) {
    imgSrc = tabletImage;
  }
  if (isMobile) {
    imgSrc = mobileImage;
  }

  return imgSrc;
};

export default useResponsiveImage;
