export const TERMS_COLORS = (subject) => {
  switch (subject) {
    case 'science':
      return {
        container: '#e7ffa3',
        opacity: 1,
        default: {
          bg: {
            1: '#A6E500',
            2: '#A6E500',
            3: '#A6E500',
            4: '#A6E500',
          },
          font: '#567600',
          border: '#84b500',
        },
        active: {
          bg: {
            1: '#fece18',
            2: '#fece18',
            3: '#fece18',
            4: '#fece18',
          },
          font: '#212624',
          boxShadow: '#d9b326',
        },
      };
    default:
      return {
        container: '#707070',
        opacity: 0.7,
        default: {
          bg: {
            1: '#C7851D',
            2: '#979F26',
            3: '#934B27',
            4: '#F7C92E',
          },
          font: '#ffffff',
          border: '#391c03',
        },
        active: {
          bg: {
            1: '#C7851D',
            2: '#979F26',
            3: '#934B27',
            4: '#F7C92E',
          },
          font: '#212624',
          boxShadow: '#adb09f',
        },
      };
  }
};
