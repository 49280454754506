import React from 'react';

import Header from 'components/Header';
import { Page } from 'pages/Dashboard/index.styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Content } from 'pages/Friends/index.styles';
import useGlobalFeature from 'hooks/useGlobalFeature';
import ProfileHeader from './ProfileHeader';

const ProfileSettings = () => {
  const { isEnabled } = useGlobalFeature('profile');
  return (
    <Page>
      <Header />
      <Content>
        <Switch>
          {!isEnabled && (
            <Redirect
              from={[
                '/profile-settings/avatar',
                '/profile-settings/profile',
                '/profile-settings/settings',
                '/profile-settings/my-subscription',
              ]}
              to="/maintenance-redirect"
            />
          )}
          <Route
            path="/profile-settings/avatar"
            render={(props) => <ProfileHeader {...props} activeTab="avatar" />}
          />
          <Route
            path="/profile-settings/profile"
            render={(props) => <ProfileHeader {...props} activeTab="profile" />}
          />
          <Route
            path="/profile-settings/settings"
            render={(props) => (
              <ProfileHeader {...props} activeTab="settings" />
            )}
          />
          <Route
            path="/profile-settings/my-subscription"
            render={(props) => (
              <ProfileHeader {...props} activeTab="my-subscription" />
            )}
          />
        </Switch>
      </Content>
    </Page>
  );
};

export default ProfileSettings;
