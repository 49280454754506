import styled from 'styled-components';

export const Bar = styled.div`
  height: 100%;
  position: absolute;
  border: 1px solid #222222;
  z-index: 1;
  cursor: pointer;
  background: ${({ background }) => background};
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const BarUnit = styled.div`
  border-right: 1px solid #222222;
  pointer-events: none;
  flex-grow: 1;
  &:last-child {
    border-right: none;
  }
`;
