import React from 'react';
import { useTranslation } from 'react-i18next';

import CompletedIcon from 'assets/img/icon-correct-border.svg';

import { Container } from './KooClassPictureType.styles';

const KooClassPictureType = ({ type, title, margin, completed }) => {
  const { t } = useTranslation(['activities']);
  return (
    <Container imgType={type} margin={margin}>
      <p className="title">{title}</p>
      {type === 'kooClass' && completed && (
        <div className="completed-sign">
          <img src={CompletedIcon} alt="completed-icon" />
          <div className="text">
            {t('activities:doAndLearn.startPage.completed', 'Completed')}
          </div>
        </div>
      )}
    </Container>
  );
};

export default KooClassPictureType;
