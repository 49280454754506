import React from 'react';
import { useTranslation } from 'react-i18next';
import MasteryIcon from 'assets/img/mission/mastery.png';
import CompetentIcon from 'assets/img/mission/competent.png';
import BeginningIcon from 'assets/img/mission/beginning.png';
import IncompleteIcon from 'assets/img/mission/incomplete.png';
import { Container, ProficiencyItem } from './ProficiencyLegend.styles';

const ProficiencyLegend = () => {
  const { t } = useTranslation(['selfPractice']);

  const proficiencyLevels = [
    {
      id: 1,
      icon: MasteryIcon,
      name: `${t('selfPractice:header.mastery', 'Mastery')}`,
      description: '80%-100%',
    },
    {
      id: 2,
      icon: CompetentIcon,
      name: `${t('selfPractice:header.competent', 'Competent')}`,
      description: '60%-79%',
    },
    {
      id: 3,
      icon: BeginningIcon,
      name: `${t('selfPractice:header.beginning', 'Beginning')}`,
      description: '0-59%',
    },
    {
      id: 4,
      icon: IncompleteIcon,
      name: `${t('selfPractice:header.incomplete', 'Incomplete')}`,
      description: `${t('selfPractice:header.question<10', 'Question < 10')}`,
    },
  ];
  return (
    <Container>
      {proficiencyLevels.map((level) => (
        <ProficiencyItem key={level.id}>
          <img src={level.icon} alt={level.name} />
          <span>{`${level.name} ${level.description}`}</span>
        </ProficiencyItem>
      ))}
    </Container>
  );
};

export default ProficiencyLegend;
