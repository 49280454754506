import styled from 'styled-components';
import { Lists } from 'pages/Leaderboard/v2/components/List/List.styles';
export const StyledLists = styled(Lists)`
  .bff-friends {
    color: #db5d16;
  }
  .country-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: 1px solid rgb(112, 112, 112);
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
`;
