import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import ChampionClassHeader from 'components/Leaderboard/ChampionClass/ChampionClassHeader';
import Category from 'components/Leaderboard/Category';
import StudentList from 'components/Leaderboard/StudentList';
import { isEmpty } from 'ramda';
import moment from 'moment';
import {
  getClassByMonth,
  getClassByTerm,
  getStudentsByClassAndTerm,
  getStudentByClassAndMonth,
  getStudentsTerm,
  getStudentsClassic,
} from 'store/championClass/championClassSlice';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import { getBFFDetails, getSentBFFRequests } from 'store/friends/friendsSlice';
import { getTopBrainTerms } from 'store/topBrain/topBrainSlice';
import useGlobalFeature from 'hooks/useGlobalFeature';
import { useHistory } from 'react-router-dom';
import { Container } from './ChampionClass.styles';
import { NoParticipant } from './TopBrain.styles';
import { isLeaderboardFeatureOpen } from './TopBrain';
// variable for update invite status immediately
let studentClassId = '';
const defaultActive = 3;

const ChampionClass = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['leaderboard']);
  const { topBrainTerms } = useSelector((state) => state.topBrain);
  const { defaultLevel, groupId, country } = useSelector(
    (state) => state.studentDetails
  );
  const {
    championClassData,
    isLoading,
    months,
    studentsData,
    termClassData,
    termStudents,
    classicStudents,
  } = useSelector((state) => state.championClass);
  const { inviteFriend } = useSelector((state) => state.friends);

  // states
  const [activeTerm, setActiveTerm] = useState('term');
  const [activeCategory, setActiveCategory] = useState(defaultActive);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [currentTerm, setCurrentTerm] = useState({});
  const [selectedLevel, setSelectedlevel] = useState(defaultLevel);
  const [classId, setClassId] = useState(groupId);
  const [toggleStudent, setToggleStudent] = useState(true);
  const [showAllStudents, setShowAllStudents] = useState({});

  const { featuresHashmap } = useGlobalFeature(null, [
    'championclass-leaderboard-termly',
    'championclass-leaderboard-classic',
  ]);
  const isTermlyOpen = isLeaderboardFeatureOpen(
    featuresHashmap['championclass-leaderboard-termly']
  );
  const isClassicOpen = isLeaderboardFeatureOpen(
    featuresHashmap['championclass-leaderboard-classic']
  );

  // get current month
  const currentMonthName = months[0] && months[0].MonthName.substring(0, 3);
  const currentMonth = months[0] && months[0].Month;
  const currentYear = months[0] && months[0].Year;

  const singaporeSchool = country === 'Singapore';

  // switch to classic if non Sing school
  useEffect(() => {
    if (!singaporeSchool) {
      if (!isClassicOpen) {
        history.replace('/maintenance-redirect');
        return;
      }
      setActiveTerm('classic');
      setActiveCategory(2);
    } else if (!isTermlyOpen && isClassicOpen) {
      setActiveTerm('classic');
      setActiveCategory(2);
    } else if (!isClassicOpen && !isTermlyOpen) {
      history.replace('/maintenance-redirect');
    }
  }, [history, isClassicOpen, isTermlyOpen, singaporeSchool]);

  // call initial apis
  useEffect(() => {
    dispatch(getStudentDetails());
    dispatch(getBFFDetails());
    dispatch(getSentBFFRequests());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (defaultLevel === null) {
      setSelectedlevel(1);
    }
  }, [defaultLevel]);

  useEffect(() => {
    if (!isEmpty(groupId)) {
      dispatch(getTopBrainTerms());
    }
    // eslint-disable-next-line
  }, [groupId]);

  // to update invite status immediately
  useEffect(() => {
    if (inviteFriend) {
      dispatch(getSentBFFRequests());
      if (activeTerm === 'term') {
        dispatch(
          getStudentsTerm({
            classId: studentClassId,
            termId: currentTerm.TermId,
          })
        );
      }
      if (activeTerm === 'classic') {
        dispatch(
          getStudentsClassic({
            classId: studentClassId,
            year,
            month,
          })
        );
      }
      studentClassId = '';
    }
    // eslint-disable-next-line
  }, [inviteFriend]);

  const updateInviteStatus = (invitedClassId) => {
    studentClassId = invitedClassId;
  };

  // to get current month and year
  useEffect(() => {
    setYear(currentYear);
    setMonth(currentMonth);
  }, [currentYear, currentMonth]);

  useEffect(() => {
    if (activeTerm === 'term') {
      if (!isEmpty(topBrainTerms)) {
        for (let i = 0; i < topBrainTerms.length; i++) {
          if (
            moment().isSameOrAfter(topBrainTerms[i].TermStartDate) &&
            moment().isSameOrBefore(topBrainTerms[i].TermEndDate)
          ) {
            setCurrentTerm(topBrainTerms[i]);
            getTermsData(topBrainTerms[i].TermId);
            break;
          } else if (
            i < topBrainTerms.length - 1 &&
            moment().isBetween(
              topBrainTerms[i].TermEndDate,
              topBrainTerms[i + 1].TermStartDate
            )
          ) {
            setCurrentTerm(topBrainTerms[i]);
            getTermsData(topBrainTerms[i].TermId);
            break;
          } else if (i === topBrainTerms.length - 1) {
            setCurrentTerm(topBrainTerms[i]);
            getTermsData(topBrainTerms[i].TermId);
            break;
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [topBrainTerms, activeTerm]);

  const changeCurrentTerm = (id) => {
    setShowAllStudents({});
    setSelectedlevel(defaultLevel);
    setCurrentTerm(topBrainTerms.find((e) => e.TermId === id));
    getTermsData(id);
  };

  useEffect(() => {
    if (activeTerm === 'classic') {
      dispatch(
        getClassByMonth({
          enumCCFilter: activeCategory,
          year,
          month,
          level: selectedLevel,
        })
      );
      dispatch(
        getStudentByClassAndMonth({
          classId: classId || groupId,
          year,
          month,
        })
      );
    }
    // eslint-disable-next-line
  }, [activeTerm, year, month]);

  const changeActiveTerm = (val) => {
    setActiveTerm(val);
    setActiveCategory(defaultActive);
    setToggleStudent(true);
    setShowAllStudents({});
  };

  const changeMonth = (value) => {
    setYear(value.Year);
    setMonth(value.Month);
    setShowAllStudents({});
  };

  const getClasses = (active, level) => {
    if (activeTerm === 'term') {
      getTermsData(currentTerm.TermId, level, active);
    }
    if (activeTerm === 'classic') {
      dispatch(
        getClassByMonth({
          enumCCFilter: active,
          year,
          month,
          level,
        })
      );
    }
  };

  const getTermsData = (
    term,
    level = defaultLevel,
    enumCCFilter = activeCategory
  ) => {
    const params = {
      enumCCFilter,
      term,
      level,
    };
    dispatch(getClassByTerm(params));
    setShowAllStudents({});
    if (enumCCFilter === 1) callStudents(null, term);
  };

  const callStudents = (classId, termId = currentTerm.TermId) => {
    // Get students for Termly when MyClass is selected
    if (activeTerm === 'term') {
      setShowAllStudents({});
      classId = classId || groupId;
      dispatch(getStudentsByClassAndTerm({ classId, termId }));
    }
    if (activeTerm === 'classic') {
      dispatch(
        getStudentByClassAndMonth({
          classId,
          year,
          month,
        })
      );
    }
  };

  // Get students when MySchool and <international> school selected
  const toggleAllStudent = (index, classId) => {
    const tempData = { ...showAllStudents };
    tempData[index] = !tempData[index];
    setShowAllStudents(tempData);
    if (activeTerm === 'term' && !termStudents[classId]) {
      dispatch(getStudentsTerm({ classId, termId: currentTerm.TermId }));
    }
    if (activeTerm === 'classic' && !classicStudents[classId]) {
      dispatch(getStudentsClassic({ classId, year, month }));
    }
  };

  return (
    <Container>
      <ChampionClassHeader
        activeCategory={activeCategory}
        activeTerm={activeTerm}
        setActiveTerm={changeActiveTerm}
        year={year}
        month={month}
        currentMonthSelection={
          !currentMonthName && !currentYear
            ? 'Loading...'
            : `${currentYear}-${currentMonthName}`
        }
        changeCurrentTerm={changeCurrentTerm}
        currentTerm={currentTerm}
        singaporeSchool={singaporeSchool}
        isTermlyOpen={isTermlyOpen}
        isClassicOpen={isClassicOpen}
        changeMonth={changeMonth}
      />
      <Category
        challengeMode="championClass"
        showTopFifty={false}
        active={activeCategory}
        setActive={setActiveCategory}
        selectedLevel={selectedLevel}
        setSelectedlevel={setSelectedlevel}
        getLists={getClasses}
        activeTerm={activeTerm}
        setToggle={setToggleStudent}
        callStudents={callStudents}
        setShowAllStudents={setShowAllStudents}
        defaultActive={defaultActive}
      />
      <StudentList
        challengeMode="championClass"
        reducer="championClass"
        activeTerm={activeTerm}
        data={activeTerm === 'term' ? termClassData : championClassData}
        isTopFifty
        active={activeCategory}
        setActive={setActiveCategory}
        isLoading={isLoading}
        toggle={toggleStudent}
        setToggle={setToggleStudent}
        studentsData={studentsData} // students under MyClass
        callStudents={callStudents}
        setClassId={setClassId}
        showAllStudents={showAllStudents}
        toggleAllStudent={toggleAllStudent}
        updateInviteStatus={updateInviteStatus}
      />
      {!isLoading &&
        (activeTerm === 'term'
          ? termClassData.length === 0
          : championClassData.length === 0) && (
          <NoParticipant>
            {t(
              'leaderboard:hodBanner.noParticipant',
              'No participants at the moment.'
            )}
          </NoParticipant>
        )}
    </Container>
  );
};

export default ChampionClass;
