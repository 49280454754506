import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 2.5rem;
  height: fit-content;
  padding: 1.5rem 4rem 1.5rem 1rem;
  background: ${(props) => (props.active ? '#F9FFF2' : '#ffffff')};
  border-bottom-left-radius: ${(props) => props.lastItem && '8px'};
  border-bottom-right-radius: ${(props) => props.lastItem && '8px'};
  border-top-left-radius: ${(props) => props.firstItem && '8px'};
  border-top-right-radius: ${(props) => props.firstItem && '8px'};
  border-top: ${(props) =>
    !props.firstItem && '1px solid rgb(112, 112, 112, 0.14)'};
  margin-bottom: ${(props) => props.lastItem && '1.5rem'};
`;
