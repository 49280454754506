import React from 'react';
import { useSelector } from 'react-redux';
import avatar from 'assets/img/avatar.png';
import { featureToggle } from 'constants/index';
import { useHistory } from 'react-router-dom';
import { mappingLevelBgImage } from 'constants/dashboardv2';
import { Spinner } from 'react-bootstrap';
import {
  StyledContainer,
  Avatar,
  AvatarPhoto,
  UserName,
  Level,
} from './UserInfo.styles';

const UserInfo = ({ subject, isAvatarXpClickable }) => {
  const history = useHistory();
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const { rewards } = useSelector((state) => state.rewards);
  const currentExpLevel = rewards?.EXPData?.CurrentLevel?.ID;
  const navigateToXpPage = () => {
    if (!isAvatarXpClickable) return;
    history.push('/exp-level');
  };
  return (
    <StyledContainer>
      <Avatar subject={subject} data-cy="sidepanel-avatar">
        <AvatarPhoto
          src={studentDetails?.ProfileAvatarImage ?? avatar}
          alt="Profile"
          onClick={() => {
            if (!isAvatarXpClickable) return;
            if (featureToggle.profile) history.push('/profile-settings/avatar');
          }}
          onError={(e) => {
            e.target.src = avatar;
          }}
        />
      </Avatar>

      <UserName>{studentDetails?.FullName ?? ''}</UserName>
      <Level
        data-cy="sidepanel-level"
        onClick={navigateToXpPage}
        subject={subject}
        bg={mappingLevelBgImage(currentExpLevel)}
        level={currentExpLevel || 1}
        isClickable={isAvatarXpClickable}
      >
        {currentExpLevel ? (
          <img
            src={`https://static.content.koobits.com/coreproduct/EXPLevel/small/${currentExpLevel}.png`}
            alt=""
          />
        ) : (
          <Spinner animation="border" size="sm" />
        )}
        <div className="level-badge">
          Lvl&nbsp;
          <span>
            {currentExpLevel || <Spinner animation="border" size="sm" />}
          </span>
        </div>
      </Level>
    </StyledContainer>
  );
};

export default UserInfo;
