import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';
import { featureToggle } from 'constants/index';

const baseUrl = process.env.REACT_APP_LEADERBOARD_API_DOMAIN;
const v2Url = `${baseUrl}Leaderboard/v2/HeroOfTheDay`;
const v1Url = `${baseUrl}Leaderboard/HeroOfTheDay`;

// -------------------------------HOD services-------------------------------------------

export const fetchLeaderBoardData = ({ enumHeroFilter, level, subject }) => {
  const url = featureToggle.science
    ? `${v2Url}/subject/${subject}/GetLeaderBoardData/${enumHeroFilter}/${level}`
    : `${v1Url}/GetLeaderBoardData/${enumHeroFilter}/${level}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get leaderboard data failed'); // eslint-disable-line
    });
};

// get hall of fame data
export const fetchHallOfFame = ({ selectedDate, subject }) => {
  const url = featureToggle.science
    ? `${v2Url}/subject/${subject}/GetHallOfFameData/${selectedDate}`
    : `${v1Url}/GetHallOfFameData/${selectedDate}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get hall of fame data failed'); // eslint-disable-line
    });
};

// get top 50 data by enum filter, level and selected date (for hall of fame section)
export const fetchHallOfFameStudentList = ({
  enumHeroFilter,
  level,
  selectedDate,
  subject,
}) => {
  const url = featureToggle.science
    ? `${v2Url}/subject/${subject}/GetLeaderBoardTopFifty/${enumHeroFilter}/${level}/${selectedDate}`
    : `${v1Url}/GetLeaderBoardTopFifty/${enumHeroFilter}/${level}/${selectedDate}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get hall of fame student list data failed'
      ); // eslint-disable-line
    });
};
