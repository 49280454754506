import React from 'react';
import { Container, InputGroup } from './FormGroup.styles';

const FormGroup = ({
  className,
  label,
  placeholder,
  validationText,
  value,
  onChange,
  type,
  children,
  portal,
  direction,
  width,
  min,
  isIPhoneOrIPad,
}) => {
  return (
    <Container direction={direction} width={width} data-cy={label}>
      <InputGroup
        portal={portal}
        validation={validationText}
        direction={direction}
        width={width}
        data-cy={label}
        isIPhoneOrIPad={isIPhoneOrIPad}
        value={value}
      >
        <label className="form-label">{label}</label>
        <input
          className={`form-input ${className}`}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          min={min}
        />
        {children}
      </InputGroup>
      <p className="validation-text">{validationText}</p>
    </Container>
  );
};

export default FormGroup;
