import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getToken } from 'store/login/loginSlice';
import LoginButton from '../LoginButton/LoginButton';
import { PasswordContainer, LoginFormContainer } from './LoginForm.styles';

// Helper for getting url query params
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Password = ({ password, handleChange }) => {
  const [type, setType] = useState('password');
  const togglePasswordHandler = (e) => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  return (
    <PasswordContainer>
      <input
        type={type}
        name="password"
        id="password"
        placeholder="Enter your password"
        onChange={handleChange}
        value={password}
        required
      />
      <div className="show-password">
        <input
          type="checkbox"
          name="show-password"
          onClick={togglePasswordHandler}
        />
        <span>Show</span>
      </div>
    </PasswordContainer>
  );
};

const LoginErrors = ({ error, username }) => {
  const errorTypes = {
    'Invalid Password.': {
      message: 'Your Password is wrong.',
      url: {
        link: `${process.env.REACT_APP_KOOBITS_DOMAIN}/Recover?uid=${username}`,
        text: 'Forgot password?',
      },
    },
    'Invalid Username.': {
      message: 'Your Username is invalid.',
      url: {
        link:
          'https://support.koobits.com/hc/en-gb/articles/212117966-Forgot-Login-ID-',
        text: 'Check Login ID Format',
      },
    },
    'connection-issue': {
      message: "Sorry, we can't connect to internet or our server.",
    },
    "You don't have permission to access": {
      message: 'Sorry, your account does not have permission to access.',
    },
  };
  return (
    <div
      style={{
        color: '#FF2E35',
        paddingLeft: '1rem',
        marginBottom: '0.5rem',
        textAlign: 'left',
      }}
    >
      {errorTypes.hasOwnProperty(error)
        ? `${errorTypes[error].message} `
        : error}
      {errorTypes.hasOwnProperty(error) &&
        errorTypes[error].hasOwnProperty('url') && (
          <a href={errorTypes[error].url.link} style={{ color: '#FF2E35' }}>
            {errorTypes[error].url.text}
          </a>
        )}
    </div>
  );
};

const LoginForm = ({ isMobile }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { isLoading, error } = useSelector((state) => state.login);
  // STATES
  // Stores user-entered fields
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Event handler for when user changes the value in input fields
  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === 'login-id') {
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  // Event handler for when form is submitted by clicking Login button
  const handleSubmit = (event) => {
    event.preventDefault();
    const loginRedirect = query.get('redirect');
    const entries = query.entries();
    const allSearchParams = Object.fromEntries(entries);
    let redirectString = allSearchParams.redirect;
    Object.entries(allSearchParams).forEach(([key, value]) => {
      if (key !== 'redirect') {
        redirectString += `&${key}=${value}`;
      }
    });
    // Clear any errors from the previous login attempt
    dispatch(
      getToken({
        username,
        password,
        redirect: loginRedirect ? redirectString : null,
      })
    );
  };

  return (
    <LoginFormContainer>
      <form className="login-form" onSubmit={handleSubmit}>
        <PasswordContainer>
          <input
            type="text"
            name="login-id"
            id="login-id"
            placeholder="Enter your Login ID"
            onChange={handleChange}
            value={username}
            required
          />
        </PasswordContainer>
        <Password password={password} handleChange={handleChange} />
        {error !== null && <LoginErrors error={error} username={username} />}
        {!isMobile && (
          <span className="help-container">
            <a href="https://support.koobits.com/hc/en-gb?utm_source=kps&utm_medium=login&utm_campaign=help">
              Need Help?
            </a>
          </span>
        )}
        <LoginButton className="login-button" type="submit">
          {!isLoading ? 'Login' : 'Logging In...'}
        </LoginButton>
      </form>
    </LoginFormContainer>
  );
};

export default LoginForm;
