import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import { inviteFriend } from 'store/friends/friendsSlice';

import { convertToTimezone } from 'pages/Leaderboard/v2/helper';

import { BFF_STATUS } from 'pages/Leaderboard/v2/pages/ChampionClass/constants';

import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';
import PopupModal from 'components/Shared/PopupModal';
import { SubmitModal } from 'components/Shared';
import Spinner from 'components/Shared/Spinner';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';

import { Container, Lists, Top20 } from '../ListInner.styles';

const ChampionClassListInner = ({ data, top20TotalPoints, getStudents }) => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { type } = useSelector((state) => state.championClassV2);
  const { groupId: myClassId, studentDetails } = useSelector(
    (state) => state.studentDetails
  );
  const { inviteFriendError, inviteFriendLoading } = useSelector(
    (state) => state.friends
  );

  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);

  const bffButton = (friend) => {
    if (
      isEmpty(friend.bffCode) ||
      isNil(friend.bffCode) ||
      BFF_STATUS[friend?.bffStatus] === 'friend' ||
      friend.userId === studentDetails?.UserId
    ) {
      return <div className="invited-bff" />;
    }
    if (BFF_STATUS[friend?.bffStatus] === 'invited') {
      // eslint-disable-next-line consistent-return
      return (
        <div className="invited-bff">
          {t('leaderboard:bffModal.invited', 'Invited')}
        </div>
      );
    }

    // eslint-disable-next-line consistent-return
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
      <div
        onClick={() => {
          setShowModal(true);
          setSelectedFriend(friend);
        }}
        className="add-bff"
        role="button"
      >
        {t('leaderboard:championClass.add', 'Add to ')}
        <span>{t('leaderboard:championClass.bff', `BFF`)}</span>
      </div>
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <PopupModal show={showModal} backdrop="static">
        <SubmitModal
          minWidth="428px"
          header={t('leaderboard:bffModal.addBff', `Add as BFF`)}
          desc={t(
            'leaderboard:bffModal.addBffMsg',
            { name: selectedFriend?.userName },
            `Are you sure you want to add ${selectedFriend?.userName} as BFF? Click Yes to confirm`
          )}
          noHandle={closeModal}
          yesHandle={() => {
            dispatch(inviteFriend(selectedFriend?.bffCode));
            setShowModal(false);
            setShowSuccessModal(true);
          }}
        />
      </PopupModal>
      <PopupModal show={showSuccessModal} backdrop="static">
        <SubmitModal
          minWidth="428px"
          header={
            // eslint-disable-next-line no-nested-ternary
            inviteFriendLoading
              ? 'Adding Friend'
              : !isNil(inviteFriendError)
              ? 'Error'
              : 'Success'
          }
          desc={
            // eslint-disable-next-line no-nested-ternary
            inviteFriendLoading ? (
              <Spinner top="-2rem" />
            ) : !isNil(inviteFriendError) ? (
              inviteFriendError
            ) : (
              `Successful! Your invitation has been sent to ${selectedFriend?.userName}. Please ask your friend to accept your request.`
            )
          }
          hideYesButton
          hideNoButton
          showOkButton={!inviteFriendLoading}
          okBtnColor="#ff6701"
          yesHandle={() => {
            if (isNil(inviteFriendError)) {
              getStudents();
            }
            setShowSuccessModal(false);
          }}
        />
      </PopupModal>
      <div className="top-heading">
        {t(
          'leaderboard:championClass.studentSubmission',
          `Students Submissions`
        )}
      </div>
      {data?.map((item, i) => (
        <Fragment key={i}>
          <div data-cy={`data-student-${i}`}>
            <Lists
              pos={i}
              center={
                !isEmpty(item.bffCode) &&
                (BFF_STATUS[item.bffStatus] === 'invited' ||
                  BFF_STATUS[item.bffStatus] === 'not-friend')
              }
            >
              <div className="positions">{i + 1}</div>
              <div className="list-name" data-cy="user-name">
                {item.userName}
              </div>
              <div className="middle">
                {item.classId === myClassId && bffButton(item)}
                <div className="list-points" data-cy="list-points">
                  <img src={CPIcon} alt="cps" />
                  {item.cpPoints}
                  {item.isSuperHero && <SuperheroIconModal />}
                </div>
              </div>
              <div className="list-achievement">
                {convertToTimezone(item.lastSubmissionDate).format(
                  'DD-MMM-YYYY HH:mm'
                )}{' '}
                {type === 'classic' && item.countryCode}
              </div>
            </Lists>
            {i === 19 && (
              <Top20>
                <div className="details">
                  {t(
                    'leaderboard:bffModal.cpsMsg',
                    'We only calculate CPs from top 20 students'
                  )}
                </div>
                <div className="total">
                  {t('leaderboard:bffModal.total', 'Total')}{' '}
                  <img src={CPIcon} alt="cps" /> <span>{top20TotalPoints}</span>
                </div>
              </Top20>
            )}
          </div>
        </Fragment>
      ))}
    </Container>
  );
};

export default ChampionClassListInner;
