import { useSelector } from 'react-redux';
import ageGroups from 'constants/ageGroups';
import { isNil } from 'ramda';

const useDefaultAgeGroup = () => {
  const { defaultLevel, isLoading, error } = useSelector(
    (state) => state.studentDetails
  );
  let defaultAgeGroup = null;
  let ageGroupIndex = null;
  if (!isLoading && isNil(error)) {
    const matchedAgeGroup = ageGroups.findIndex(
      (ageGroup) =>
        defaultLevel >= ageGroup.minLevel && defaultLevel <= ageGroup.maxLevel
    );
    if (isNil(defaultLevel)) {
      // Fallback to first age group
      [defaultAgeGroup] = ageGroups;
      ageGroupIndex = 0;
    } else if (matchedAgeGroup === -1) {
      const [firstAgeGroup] = ageGroups;
      defaultAgeGroup =
        defaultLevel < firstAgeGroup
          ? firstAgeGroup
          : ageGroups[ageGroups.length - 1];
      ageGroupIndex = defaultLevel < firstAgeGroup ? 0 : ageGroups.length - 1;
    } else {
      defaultAgeGroup = ageGroups[matchedAgeGroup];
      ageGroupIndex = matchedAgeGroup;
    }
  }
  return {
    isLoading,
    error,
    defaultAgeGroup,
    ageGroupIndex,
  };
};

export default useDefaultAgeGroup;
