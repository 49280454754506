import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${(props) => props.height ?? '50%'};
  background: #fff;
  z-index: 102;
  padding: ${(props) => props.padding ?? '20px'};

  transform: ${(props) =>
    props.isOpen ? 'translateY(0)' : 'translateY(100%)'};
  transition-duration: 500ms;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 32px;
`;
