import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/Shared/BackButton';
import BffLike from 'assets/img/leaderboard/bff/bff-like.svg';
import ChallengeType from '../ChallengeType';
import {
  Topbar,
  Container,
  BrainHeading,
  ButtonContainer,
} from './BffHeader.styles';

const BffHeader = () => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  return (
    <Topbar>
      <ButtonContainer className="btn">
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(53, 137, 216, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <Container>
        <BrainHeading data-cy="title">
          {t('leaderboard:bff.bestfriend', 'Best Friends Forever')}
          <img src={BffLike} alt="bff-like" />
        </BrainHeading>
      </Container>
      <ChallengeType styles={{ top: '12%' }} />
    </Topbar>
  );
};

export default BffHeader;
