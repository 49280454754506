import React, { useCallback } from 'react';
import AvatarArrowButton from 'components/AvatarCreator/AvatarArrowButton';
import { BodySelectorContainer, ButtonColor } from './AvatarBodySelector.style';

function AvatarBodySelector({
  image,
  colors,
  color,
  onChangeLeft,
  onChangeRight,
  onChangeColor,
}) {
  const changeColor = useCallback(
    (c) => () => {
      onChangeColor(c);
    },
    [onChangeColor]
  );
  return (
    <BodySelectorContainer>
      <div className="buttons">
        <AvatarArrowButton onClick={onChangeLeft} direction="left" />
        <AvatarArrowButton onClick={onChangeRight} direction="right" />
      </div>
      <img alt="nofill" draggable={false} src={image} />
      <ul>
        {colors.map((c) => (
          <li key={c.ID}>
            <ButtonColor
              onClick={changeColor(c.ColorCode)}
              className={
                color.replace('#', '') === c.ColorCode ? 'selected' : ''
              }
              color={c.ColorCode}
            />
          </li>
        ))}
      </ul>
    </BodySelectorContainer>
  );
}

export default React.memo(AvatarBodySelector);
