/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import LionCityHeader from 'components/Leaderboard/LionCity/LionCityHeader';
import StudentList from 'components/Leaderboard/StudentList';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { isEmpty } from 'ramda';
import {
  getSeasons,
  getOccupations,
  getStudentsList,
  getCurrentUser,
} from 'store/lionCity/lionCitySlice';
import getCurrentMonth from 'helpers/getCurrentMonth';
import ModalContentXButton from 'components/Shared/ModalContentXButton';
import LionCityImage from 'assets/img/leaderboard/lion-city/lion-city-1.png';
import LionCitySelectedImage from 'assets/img/leaderboard/lion-city/lion-city-2.png';
import LionCityJoinedModal from 'components/Leaderboard/LionCity/LionCityJoinedModal';
import LionCityOccupationModal from 'components/Leaderboard/LionCity/LionCityOccupationModal';
import LionCityCurrentUser from 'components/Leaderboard/LionCity/LionCityCurrentUser';
import { checkIfChallengeOpen, leaderboardList } from 'constants/leaderboard';
import { Redirect } from 'react-router-dom';
import { Container, ZodiacCategory } from './ZodiacRace.styles';
import { NoParticipant } from './TopBrain.styles';
import { LionCityStyledContainer } from './LionCity.styles';

const LionCity = () => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const { isLoading, seasons, groupsData, currentUserScore } = useSelector(
    (state) => state.lionCity
  );

  const [showAllStudents, setShowAllStudents] = useState({});
  const [dropdownItems, setDropdownItems] = useState(seasons[0]);
  const [competitionActive, setCompetitionActive] = useState(false);
  const [data, setData] = useState({
    step: 1,
    selectedOccupation: null,
  });
  const [showJoinedModal, setShowJoinedModal] = useState(false);
  const [showOccupationModal, setShowOccupationModal] = useState(false);
  const [occupation, setOccupation] = useState(1);

  const isOpen = checkIfChallengeOpen(
    leaderboardList.math[8].startDateTime,
    leaderboardList.math[8].endDateTime
  );

  useEffect(() => {
    // only for december
    const month = getCurrentMonth();
    if (month === 12) {
      setCompetitionActive(true);
    }
  }, []);

  useEffect(() => {
    dispatch(getSeasons());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(seasons)) {
      setDropdownItems(seasons[0]);
      dispatch(getOccupations({ SeasonId: seasons[0].Id }));
      dispatch(getCurrentUser({ SeasonId: seasons[0].Id }));
    }
  }, [dispatch, seasons]);

  useEffect(() => {
    if (studentDetails && studentDetails.Occupation === 0) {
      setShowJoinedModal(true);
    }
  }, [dispatch, studentDetails]);

  const changeSeason = (val) => {
    setDropdownItems(val);
    dispatch(getOccupations({ SeasonId: val.Id }));
    dispatch(getCurrentUser({ SeasonId: val.Id }));
    setShowAllStudents({});
  };

  const closeModal = () => setShowJoinedModal(false);

  const closeOccupationModal = () => setShowOccupationModal(false);

  const openOccupationModal = (occupationId) => {
    setOccupation(occupationId);
    setShowOccupationModal(true);
  };

  const toggleAllStudent = (index, OccupationId) => {
    const tempData = { ...showAllStudents };
    tempData[index] = !tempData[index];
    setShowAllStudents(tempData);
    if (tempData[index])
      dispatch(
        getStudentsList({
          OccupationId,
          SeasonId: dropdownItems.Id,
        })
      );
  };

  if (!isOpen) return <Redirect to="/leaderboard?subject=math" />;
  return (
    <Container>
      <ModalContentXButton
        showModal={showJoinedModal}
        backgroundImage={
          data?.step === 2 ? LionCitySelectedImage : LionCityImage
        }
        containerMinWidth="630px"
        borderRadius="0px"
        closeModal={closeModal}
        backdrop="static"
      >
        <LionCityJoinedModal
          data={data}
          dropdownItems={dropdownItems}
          setData={setData}
          closeModal={closeModal}
        />
      </ModalContentXButton>
      <ModalContentXButton
        showModal={showOccupationModal}
        closeModal={closeOccupationModal}
        backdrop="static"
      >
        <LionCityOccupationModal occupationId={occupation} />
      </ModalContentXButton>
      <LionCityHeader />
      <LionCityStyledContainer>
        <ZodiacCategory>
          <span className="right-container">
            {!competitionActive &&
              t(
                'leaderboard:lionCity.competitionClosed',
                'The Lion City competition has closed. See you next year!'
              )}
          </span>
          <div className="curriculum-date">
            <DropdownMenu
              dataCy="dropdown-season"
              selectedValue={dropdownItems && dropdownItems.Name}
              valueKey="Name"
              values={seasons}
              setValue={changeSeason}
              width="auto"
              className="drop-down"
            />
          </div>
        </ZodiacCategory>
        <StudentList
          challengeMode="lionCity"
          reducer="lionCity"
          data={groupsData}
          isTopFifty
          isLoading={isLoading}
          showAllStudents={showAllStudents}
          toggleAllStudent={toggleAllStudent}
          openOccupationModal={openOccupationModal}
        />
        {!isLoading && groupsData.length === 0 && (
          <NoParticipant>
            {t(
              'leaderboard:hodBanner.noParticipant',
              'No participants at the moment.'
            )}
          </NoParticipant>
        )}
        {!isEmpty(currentUserScore) && currentUserScore.OccupationId !== 0 && (
          <LionCityCurrentUser
            currentUserScore={{
              ...currentUserScore,
              OccupationId: studentDetails.Occupation,
              Name: studentDetails.FullName,
            }}
          />
        )}
      </LionCityStyledContainer>
    </Container>
  );
};

export default LionCity;
