import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  padding-top: 96px;
  padding-bottom: 48px;
  padding-right: calc(0.02307137707 * 100vw);
  padding-left: calc(0.08291276135 * 100vw);
  height: 100%;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 30px 0 0 0;
    flex-direction: column-reverse;
    width: 502px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 90%;
  }
`;
export const StyledCardContainer = styled.div`
  width: 83%;
  display: flex;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
    flex-direction: column;
    margin-top: 10px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    margin-top: 0px;
  }
`;
export const StyledRight = styled.div`
  width: 41.8%;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
    display: flex;
    margin-top: 10px;
  }
`;
export const StyledRewardsSection = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: auto;
    padding-top: auto;
    justify-content: end;
    padding-right: 16px;
    margin-bottom: 10px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-right: -17px;
    margin-bottom: 0px;
  }
`;
