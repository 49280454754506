import KooClass from 'assets/img/kooclass/activity/kooClass-start-page.svg';
import KooClassIVM from 'assets/img/kooclass/activity/bg-ivm.svg';
import KooClassBrainGame from 'assets/img/kooclass/activity/bg-brain-game.svg';

import WatchIcon from 'assets/img/kooclass/activity/icon-watch.svg';
import LearnIcon from 'assets/img/kooclass/activity/icon-do-learn.svg';
import HOAIcon from 'assets/img/kooclass/activity/icon-HOA.svg';
import SelfCheckIcon from 'assets/img/kooclass/activity/icon-self-check.svg';
import TalkAboutIcon from 'assets/img/kooclass/activity/icon-qn-mark-small.svg';
import PlayIcon from 'assets/img/kooclass/activity/icon-play-learn.svg';

export const kooClassImages = {
  kooClass: KooClass,
  kooClassIVM: KooClassIVM,
  kooClassBrainGame: KooClassBrainGame,
};

export const lessonStatus = {
  1: 'Locked',
  2: 'Unlocked',
  3: 'Inprogress',
  4: 'Completed',
  5: 'Retry',
};

export const activityStatus = {
  1: 'Locked',
  2: 'Unlocked',
  3: 'Inprogress',
  4: 'Completed',
  5: 'Retry',
};

export const activityType = {
  1: 'Video',
  2: 'IVM',
  3: 'SingleQuestion',
  4: 'MultipleQuestion',
  5: 'Game',
  6: 'Information',
};

export const activityIcon = (type, name) => {
  if (type === 'Video') {
    return WatchIcon;
  } else if (type === 'SingleQuestion' || type === 'MultipleQuestion') {
    return LearnIcon;
  } else if (type === 'IVM') {
    return HOAIcon;
  } else if (type === 'Game') {
    return PlayIcon;
  } else if (type === 'Information') {
    if (name === 'Self-Check') {
      return SelfCheckIcon;
    } else {
      return TalkAboutIcon;
    }
  } else {
    return TalkAboutIcon;
  }
};

export const answerStatus = {
  0: 'NoEvaluated',
  1: 'Correct',
  2: 'Wrong',
  3: 'Unknown',
  4: 'Skipped',
};
