import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import { LEADERBOARD_ID, PERIOD_TYPE } from 'pages/Leaderboard/v2/constant';

import { getChampionClassStudents } from 'store/leaderboard/championClassLeaderboardSlice';

import Spinner from 'components/Shared/Spinner';
import ChampionClassListInner from 'pages/Leaderboard/v2/components/ListInner/ChampionClassListInner/ChampionClassListInner';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';

import { Container, ClassContainer } from './MyClass.styles';

const MyClass = ({ currentTerm, currentPeriod }) => {
  const dispatch = useDispatch();

  const { type, championClass, students } = useSelector(
    (state) => state.championClassV2
  );

  const [showList, setShowList] = useState(true);
  const isLoading = championClass.isLoading || students.isLoading;
  const currentClass = championClass?.data?.[0] ?? null;
  const studentsList = students?.data?.[currentClass?.classId];
  const participants = studentsList?.length;
  const periodId = type === 'term' ? currentTerm.id : currentPeriod.id;
  const periodType = type === 'term' ? PERIOD_TYPE.term : PERIOD_TYPE.monthly;

  const getStudents = () => {
    dispatch(
      getChampionClassStudents({
        id: LEADERBOARD_ID.championClass.math,
        classId: currentClass?.classId,
        periodId,
        periodType,
      })
    );
  };

  useEffect(() => {
    getStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {isLoading && <Spinner />}
      {!isLoading && !isNil(currentClass) && !isEmpty(studentsList) && (
        <>
          <ClassContainer
            data-cy="myClass-list"
            onClick={() => setShowList(!showList)}
          >
            <div className="school">
              <img
                src={`https://static.koobits.com/eduresources/schlogo/${currentClass?.schoolLogo}.png`}
                style={{ marginRight: '5px' }}
                alt="school-logo"
              />
              {currentClass?.className}
            </div>
            <div className="total-participants">
              <Trans
                i18nKey="leaderboard:championClass.totalParticipant"
                participants={participants}
              >
                Total Participants: <span>{{ participants }}</span>
              </Trans>
            </div>
            <div className="total-details">
              <Trans i18nKey="leaderboard:championClass.totalCp">
                Total CPs of the term
                <br /> from top 20 students
              </Trans>
            </div>
            <div className="total-points">
              <img src={CPIcon} alt="cps" />
              <span>{currentClass?.cpPoints}</span>
            </div>
          </ClassContainer>
          {showList && (
            <ChampionClassListInner
              data={studentsList}
              top20TotalPoints={currentClass?.cpPoints}
              getStudents={getStudents}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default MyClass;
