import styled from 'styled-components';
export const StyledItem = styled.div`
  background: #ffffff;
  border-radius: 5px;
  border: 0.5px solid #e0e0e0;
  padding: 12px 34px;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 18px;
  min-height: 64.2px;
  position: relative;

  .title {
    width: 28.5%;
    color: #bebebe;
    padding-left: 11px;
  }
  .content {
    width: 57.1%;
    font-weight: bold;
  }
  .tip {
    position: absolute;
    color: #ff4545;
    top: 100%;
    left: 30%;
    font-size: 13px;
  }
`;
