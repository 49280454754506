import styled from 'styled-components';

export const Container = styled.div`
  background: #f2fcff;
  width: 966px;
  text-align: center;
  padding-bottom: 1rem;
  .cancel-button {
    color: #070707;
    font-size: 17px;
    text-decoration: underline;
    margin-right: 20px;
    cursor: pointer;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
  }
`;

export const CurrentDifficulty = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #b3dae2;
  .difficulty-level {
    color: #070707;
    font-size: 1.3125rem;
    font-weight: 600;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }
  .difficulty-description {
    font-size: 0.9375rem;
    color: #03799d;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    flex-direction: column;
    .difficulty-level {
      line-height: 26px;
    }
    .difficulty-description {
      padding: 0px 1rem;
      margin-top: 4px;
    }
  }
`;

export const DifficultyOptions = styled.div`
  display: flex;
  padding: 1rem 1rem;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: grid;
    padding: 1rem 1rem;
    grid-column-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-row-gap: 1rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: grid;
    padding: 1rem 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
`;

export const Button = styled.button`
  border: none;
  background: ${(props) => (props.disabled ? 'gray' : '#ff6701')};
  font-weight: 600;
  font-size: 1.3125rem;
  color: white;
  border-radius: 30px;
  padding: 0.25rem 2rem;

  :hover {
    background: ${(props) => (props.disabled ? 'gray' : '#ff5801')};
  }
`;
