import styled from 'styled-components';
import Bg from 'assets/img/kooclass/activity/bg-fun-with-math.svg';
import Bubble from 'assets/img/kooclass/activity/blue-bubble.svg';

export const Container = styled.div`
  height: 100%;
  background: url(${Bg});
  background-size: cover;
  background-repeat: no-repeat;
  display: ${(props) => (props.showVideo ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .watch-btn {
    width: 25%;
  }
  .mascot {
    position: absolute;
    left: 52%;
    top: 50%;
    width: 17%;
    @media (max-width: 1024px) {
      width: 23%;
      left: 50%;
    }
  }
`;

export const BubbleContainer = styled.div`
  background: url(${Bubble});
  background-size: contain;
  background-repeat: no-repeat;
  width: 40%;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  .desc {
    color: #ffffff;
    margin: 8% 5% 5% 8%;
    font-size: 27px;
    font-weight: 600;
    line-height: 1.2;
    max-height: 170px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    width: 55%;
    height: 35%;
    .desc {
      max-height: 135px;
    }
  }
`;
