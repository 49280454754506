import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './QnText.styles';

const QnText = ({
  fontFam,
  color,
  borderColor,
  text,
  strokeWidth,
  textShadow,
  fontSize,
  margin,
}) => {
  const { t } = useTranslation(['assignmentQnView']);
  return (
    <Container
      fontFamily={fontFam}
      color={color}
      borderColor={borderColor}
      strokeWidth={strokeWidth}
      textShadow={textShadow}
      fontSize={fontSize}
      margin={margin}
    >
      <h3 className="stroke">
        {text || t('assignmentQnView:sidebar.qn', 'Question')}
      </h3>
      <h3 className="fill">
        {text || t('assignmentQnView:sidebar.qn', 'Question')}
      </h3>
    </Container>
  );
};

export default QnText;
