const replaceNumOfUnders = (match, numOfUnders) => {
  let currentNumOfUnders = parseInt(numOfUnders, 10);
  if (currentNumOfUnders <= 0) return '';
  currentNumOfUnders -= 1;
  return `&#95;[blank${currentNumOfUnders}]`;
};

const convertTagsToHtml = (htmlText) => {
  let convertedHTML = htmlText.slice();
  // "Content" tag >> to "div" tag
  convertedHTML = convertedHTML.replace(/(<\/?)Content([^>]*>)/gi, '$1div$2');

  // removing "CDATA" opening and closing tags
  convertedHTML = convertedHTML.replace(/<!\[CDATA\[|\]\]>/g, '');

  // converting "/>" tag-ending to a ">" tag-ending in order to avoid detecting "/" as a divide sign
  convertedHTML = convertedHTML.replace(/\/>/g, '>');

  // converting xml-img to html-img
  convertedHTML = convertedHTML.replace(
    /(<img[^<>]*)\surl=([^<>]*>)/g,
    '$1 style="max-width:100%;height:auto;" src=$2'
  );

  // converting "helptext" tags to "div" tags with class "help-text"
  while (convertedHTML.match(/<helptext>(((?!helptext>).)+)<\/helptext>/g))
    convertedHTML = convertedHTML.replace(
      /<helptext>(((?!helptext>).)+)<\/helptext>/g,
      '<span style="color:#ababab" class="help-text">$1</span>'
    );

  // html parts for fractions
  const fractionWrapStart = '<span class="fraction-wrap">';
  const fractionWholeStart = '<span class="fraction-whole-part">';
  const fractionNumeratorDenominatorStart =
    '<span class="fraction-numerator-denominator-part">';
  const fractionNumeratorStart = '<span class="fraction-numerator">';
  const fractionDenominatorStart = '<span class="fraction-denominator">';
  const fractionSpanEnd = '</span>';

  // converting "fraction" tags to html code with horizontal line
  while (
    convertedHTML.match(
      /\[fraction\](((?!fracline\]).)+)\/(((?!fracline\]).)+)\[\/fraction\]/g
    )
  )
    convertedHTML = convertedHTML.replace(
      /\[fraction\](((?!fracline\]).)+)\/(((?!fracline\]).)+)\[\/fraction\]/g,
      '[fraction]$1[fracline]$3[/fraction]'
    );
  while (
    convertedHTML.match(
      // eslint-disable-next-line
      /\[fraction\](((?!fraction\]).)*)\&(((?!fraction\]).)*)\[fracline\](((?!fraction\]).)*)\[\/fraction\]/g
    )
  )
    convertedHTML = convertedHTML.replace(
      // eslint-disable-next-line
      /\[fraction\](((?!fraction\]).)*)\&(((?!fraction\]).)*)\[fracline\](((?!fraction\]).)*)\[\/fraction\]/g,
      `${
        fractionWrapStart + fractionWholeStart
      }$1${fractionSpanEnd}${fractionNumeratorDenominatorStart}${fractionNumeratorStart}$3${fractionSpanEnd}${fractionDenominatorStart}$5${fractionSpanEnd}${fractionSpanEnd}${fractionSpanEnd}`
    );
  while (
    convertedHTML.match(
      /\[fraction\](((?!fraction\]).)*)\[fracline\](((?!fraction\]).)*)\[\/fraction\]/g
    )
  )
    convertedHTML = convertedHTML.replace(
      /\[fraction\](((?!fraction\]).)*)\[fracline\](((?!fraction\]).)*)\[\/fraction\]/g,
      `${
        fractionWrapStart +
        fractionNumeratorDenominatorStart +
        fractionNumeratorStart
      }$1${fractionSpanEnd}${fractionDenominatorStart}$3${fractionSpanEnd}${fractionSpanEnd}${fractionSpanEnd}`
    );
  // removing unused "fraction" tags
  convertedHTML = convertedHTML.replace(
    /\[fraction\]|\[fracline\]|\[\/fraction\]/g,
    ''
  );

  // EQN
  convertedHTML = convertedHTML.replace(/EQN\(/g, '[eqntag]');
  while (convertedHTML.match(/\(([^)(]*)\)/g))
    convertedHTML = convertedHTML.replace(/\(([^)(]*)\)/g, '&#40;$1&#41;');
  convertedHTML = convertedHTML.replace(
    /\[eqntag\](((?!fraction\]).)*)\[fracline\](((?!fraction\]).)*)\)/g,
    `${
      fractionWrapStart +
      fractionNumeratorDenominatorStart +
      fractionNumeratorStart
    }$1${fractionSpanEnd}${fractionDenominatorStart}$3${fractionSpanEnd}${fractionSpanEnd}${fractionSpanEnd}`
  );
  convertedHTML = convertedHTML.replace(
    /\[eqntag\](((?!fraction\]).)*)\/(((?!fraction\]).)*)\)/g,
    `${
      fractionWrapStart +
      fractionNumeratorDenominatorStart +
      fractionNumeratorStart
    }$1${fractionSpanEnd}${fractionDenominatorStart}$3${fractionSpanEnd}${fractionSpanEnd}${fractionSpanEnd}`
  );
  // removing unused "EQN" tags
  convertedHTML = convertedHTML.replace(
    /\[eqntag\](((?!eqntag\]).)*)\)/g,
    '$1'
  );

  // replacing simple tags
  convertedHTML = convertedHTML.replace(/\n/g, '<br/>');
  convertedHTML = convertedHTML.replace(/\[angle\]/g, '&ang;');
  convertedHTML = convertedHTML.replace(/\[div\]/g, '&#247;');
  convertedHTML = convertedHTML.replace(/\[deg\]/g, '&deg;');
  convertedHTML = convertedHTML.replace(/\[quote\]/g, '&#34;');
  convertedHTML = convertedHTML.replace(/\[cent\]/g, '&cent;');
  convertedHTML = convertedHTML.replace(/\[arrow\]/g, '&#8594;');
  convertedHTML = convertedHTML.replace(/\[triangle\]/g, '&utri;');
  convertedHTML = convertedHTML.replace(/\[approx\]/g, '&#8764;');
  convertedHTML = convertedHTML.replace(/\[square\]/g, '<sup>2</sup>');
  convertedHTML = convertedHTML.replace(/\[cube\]/g, '<sup>3</sup>');
  convertedHTML = convertedHTML.replace(/\[power4\]/g, '<sup>4</sup>');
  convertedHTML = convertedHTML.replace(/\[power5\]/g, '<sup>5</sup>');

  // converting "blank#" tag
  convertedHTML = convertedHTML.replace(/\[blank\]/g, '&#95;&#95;');
  convertedHTML = convertedHTML.replace(/\[blank1\]/g, '&#95;&#95;');
  while (convertedHTML.match(/\[blank(\d+)\]/)) {
    convertedHTML = convertedHTML.replace(/\[blank(\d+)\]/, replaceNumOfUnders);
  }

  return convertedHTML;
};

export default convertTagsToHtml;
