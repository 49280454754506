import styled from 'styled-components';
import Top from 'assets/img/assignment/note-book/note-top.png';
import Middle from 'assets/img/assignment/note-book/note-middle.png';
import Bottom from 'assets/img/assignment/note-book/note-bottom.png';

/* eslint-disable */

export const Container = styled.div`
  .note-book-wrap {
    display: flex;
    justify-content: center;
    padding-left: 64px;
    @media (max-width: 1025px) {
      padding-left: ${({ page }) =>
        page === 'self-practice' ? '0px' : '64px'};
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      padding-left: 0px;
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      width: 100%;
      height: ${({ isFinish }) => (isFinish ? 'auto' : '100%')};
    }
    .note-book {
      width: 900px;
      padding-top: 1rem;
      padding-left: calc(12% - 64px);
      @media (min-width: 2000px) {
        padding-left: calc(15% - 64px);
        width: 1500px;
      }
      @media (max-width: 1201px) {
        padding-left: 0px;
      }

      @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
        width: 100%;
      }

      @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
        padding: 0px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: ${({ isFinish }) => (isFinish ? 'auto' : '100%')};
      }

      .notebook-top {
        height: 96px;
        padding-left: 4rem;
        padding-right: 4rem;
        padding-top: 1rem;
        background: url(${Top});
        background-repeat: no-repeat;
        background-size: 841px auto;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: space-between;
        @media (min-width: 2000px) {
          height: 150px;
          background-size: cover;
          padding: 1rem 7.5% 0;
        }
        @media (max-width: 1201px) {
          padding-right: 7rem;
        }

        @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
          padding-right: 2.5rem;
          padding-left: 2.5rem;
          background-size: 100% 100%;
        }

        @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
          background: #ebf7f9;
          padding: 0px;
          height: auto;
        }
      }
      .notebook-middle {
        min-height: 460.5px;
        background: url(${Middle});
        background-repeat: repeat-y;
        background-size: 841px auto;
        padding-left: 4rem;
        padding-right: 4rem;

        @media (min-width: 2000px) {
          background-size: contain;
          min-height: 1000px;
          padding: 0px 7rem;
        }
        @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
          background-size: 100%;
          /* allow table to scroll */
          table {
            display: block !important;
            overflow-x: auto !important;
            white-space: nowrap !important;
            max-width: 100% !important;
            border: none !important;
          }
        }
        @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
          background: none;
          padding: 0px;
          height: ${({ isFinish }) => (isFinish ? 'auto' : '100%')};
        }
      }
      .notebook-bottom {
        height: 100px;
        background: url(${Bottom});
        background-repeat: no-repeat;
        background-size: 841px auto;
        padding-left: 4rem;
        padding-right: 4rem;
        margin-top: ${({ answer }) => answer && '-1.3rem'};

        @media (min-width: 2000px) {
          background-size: cover;
          height: 148px;
        }
        @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
          background-size: 100% 100%;
          padding-left: 3.5rem;
          padding-right: 3.5rem;
          margin-top: -1px;
          position: relative;
        }
        @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
          position: static;
          background: none;
          padding: 0px;
          height: auto;
        }
      }
      .notebook-bottom-pc {
        background: #ebf7f9;
        padding: 1rem;
        @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
          background: url(${Bottom});
          background-repeat: no-repeat;
          background-size: 841px 141px;
          height: 141px;
          padding-top: 4rem;
          padding-left: 4rem;
          padding-right: 4rem;
        }
        @media (min-width: 2000px) {
          background-size: cover;
          height: 148px;
          margin-top: -1px;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0px;
    background-color: white;
    height: ${({ isFinish }) => (isFinish ? 'auto' : '100%')};
  }
`;
