import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

export const ToolbarIcon = styled.a`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: ${({ src }) => `url('${src}')`};
  background-size: cover;
  &:focus {
    outline: 2px solid #ffac4e;
  }
`;

export const ToolbarDelete = styled.a`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 56px;
  background: ${({ src }) => `url('${src}')`};
  background-size: cover;
  &:focus {
    outline: 2px solid #dedede;
  }
`;

export const ToolbarRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const ColorPickerIcon = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  border: 1px solid #848484;
  cursor: pointer;
  background: ${({ color }) => color};
`;

export const ColorPicker = styled.div`
  position: absolute;
  z-index: 99;
  top: 40px;
  left: -10px;
  display: ${({ show }) => (show ? 'initial' : 'none')};
`;

export const ColorPickerContainer = styled.div`
  position: relative;
`;

export const BreakIntoPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  & > span {
    font-size: 12px;
    line-height: 1.2;
    flex-shrink: 0;
  }
  & > select {
    border: 1px solid #a7a7a7;
    margin: 0px 4px;
    width: 50px;
    height: 24px;
    &:focus,
    &:active {
      outline: none;
    }
  }
`;

export const ClearAllButton = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 20px;
  & > span {
    font-size: 12px;
    color: #99a8ae;
    line-height: 1.2;
    flex-shrink: 0;
  }
  & > img {
    height: 18px;
    margin-right: 4px;
    &:active {
      outline: none;
    }
  }
`;
