import React, { useRef } from 'react';
import range from 'lodash/range';
import { barModelOptions } from 'constants/index';
import Positioning from './Positioning';
import { Bar, BarUnit } from './BarField.style';

const { components } = barModelOptions;
const { barHeight } = components;

function BarField({ color, size, x, y, zIndex, id, unit }) {
  const componentRef = useRef(null);
  return (
    <Positioning
      componentRef={componentRef}
      x={x}
      y={y}
      id={id}
      size={size}
      zIndex={zIndex}
      height={barHeight}
      vertical={false}
    >
      <Bar
        className="bar barModelComponent"
        ref={componentRef}
        background={color}
      >
        {range(unit).map((i) => (
          <BarUnit key={i} />
        ))}
      </Bar>
    </Positioning>
  );
}

export default React.memo(BarField);
