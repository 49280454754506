import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isNil } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import PopupModal from 'components/Shared/PopupModal';
import HeroOfTheDayModal from 'components/DailyChallenge/HeroOfTheDayModal';
import { SubmitModal } from 'components/Shared';
import ErrorModal from 'components/Shared/ErrorModal';
import formatI18nDateTime from 'helpers/formatI18nDateTime';
import { postUnlockBadge } from 'services/hodCalendar';
import { fetchWrapper } from 'services/login';
import {
  getBadgesByMonthYear,
  getUnseenBadges,
} from 'store/dailyChallenge/hodCalendarSlice';
import { fetchRewards } from 'services/dashboard';
import NotEnoughKokoModal from 'components/Shared/NotEnoughKokoModal';
import {
  Container,
  CalendarHeader,
  CalendarDays,
  CalendarWeek,
} from './index.styles';
import DateHolder from './DateHolder';

// HELPERS
const isDateEqualToday = (dateTime) => {
  const currentDateTime = moment().format('D-MM-YYYY');
  if (dateTime === currentDateTime) {
    return true;
  }
  return false;
};
const getNumOfDaysInMonth = (monthYear) => {
  return moment(monthYear, 'MM-YYYY').daysInMonth();
};
const isDateInFuture = (dateTime) => {
  const dateTimeToCheck = moment(dateTime, 'D-MM-YYYY');
  const currentDateTime = moment();
  const diff = moment
    .duration(dateTimeToCheck.diff(currentDateTime))
    .as('milliseconds');
  return diff > 0;
};
const createDaysInMonthArray = (numOfDays, monthYear) => {
  const days = {};
  let firstDay = 1;
  for (firstDay; firstDay <= numOfDays; firstDay += 1) {
    // Check week number of date
    let week;
    const isoWeekNum = moment(
      `${firstDay}-${monthYear}`,
      'D-MM-YYYY'
    ).isoWeek();
    if (monthYear.slice(0, 2) === '01') {
      if (isoWeekNum >= 52) {
        week = 0;
      } else {
        week = isoWeekNum;
      }
    } else {
      week = isoWeekNum;
    }
    const dayOfWeek = moment(
      `${firstDay}-${monthYear}`,
      'D-MM-YYYY'
    ).isoWeekday();
    // Check if week number present as key
    if (days.hasOwnProperty(week)) {
      days[week][dayOfWeek - 1] = {
        day: firstDay,
        isToday: isDateEqualToday(`${firstDay}-${monthYear}`),
        isFuture: isDateInFuture(`${firstDay}-${monthYear}`),
      };
    } else {
      days[week] = Array(7).fill(null);
      days[week][dayOfWeek - 1] = {
        day: firstDay,
        isToday: isDateEqualToday(`${firstDay}-${monthYear}`),
        isFuture: isDateInFuture(`${firstDay}-${monthYear}`),
      };
    }
  }
  return days;
};
const checkIfSelectedBadgeIsUnseen = (selectedBadge, unseenBadges) => {
  const findDayInUnseenBadges = unseenBadges.find((badge) => {
    const badgeDateTime = moment(
      badge.uEntity.Date,
      'YYYY-MM-DDTHH:mm:ss'
    ).format('D-MM-YYYY');
    return (
      badgeDateTime ===
      moment(selectedBadge?.uEntity?.Date, 'YYYY-MM-DDTHH:mm:ss').format(
        'D-MM-YYYY'
      )
    );
  });
  return !isNil(findDayInUnseenBadges);
};

const BadgeCalendar = ({ selectedMonthYear, setMarkError }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['dailyChallenge']);
  // Redux States
  const { badges, isLoading, todayCPEarned } = useSelector(
    (state) => state.hodCalendar
  );
  const { unseenBadges } = useSelector((state) => state.hodCalendar);
  // Component States
  const [showEnlargedBadgeModal, setShowEnlargedBadgeModal] = useState(false);
  const [showUnlockBadgeModal, setShowUnlockBadgeModal] = useState(false);
  const [showInsufficientKoKoModal, setShowInsufficientKoKoModal] = useState(
    false
  );
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [unlockError, setUnlockError] = useState(null);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const numOfDaysInMonth = getNumOfDaysInMonth(selectedMonthYear);
  const daysInMonth = createDaysInMonthArray(
    numOfDaysInMonth,
    selectedMonthYear
  );
  const daysInMonthKeys = Object.keys(daysInMonth);
  const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const daysOfWeekHeaders = daysOfWeek.map((day) => (
    <span key={day} className="day-header">
      {day}
    </span>
  ));

  // Event Handlers
  const showEnlargedModalHandler = async (badge, isToday) => {
    setSelectedBadge(badge);
    if (badge.isUnlocked) {
      setShowEnlargedBadgeModal(true);
    } else if (isToday !== true) {
      setShowUnlockBadgeModal(true);
    }
  };
  const unlockBadgeHandler = async () => {
    setIsUnlocking(true);
    const badgeDateTime = moment(selectedBadge.Date, 'YYYY-MM-DDTHH:mm:ss');
    const params = {
      year: badgeDateTime.format('YYYY'),
      month: badgeDateTime.format('MM'),
      day: badgeDateTime.format('DD'),
    };
    try {
      const rewardsResponse = await fetchWrapper(fetchRewards);
      if (rewardsResponse.Result.TotalKoko < 20) {
        setShowInsufficientKoKoModal(true);
        return;
      }
      await fetchWrapper(postUnlockBadge, params);
      dispatch(getBadgesByMonthYear(params));
      dispatch(getUnseenBadges());
    } catch (err) {
      setUnlockError(err.message);
    } finally {
      setIsUnlocking(false);
      setShowUnlockBadgeModal(false);
    }
  };
  return (
    <Container>
      {!isNil(unlockError) && (
        <ErrorModal
          errorMessage={unlockError}
          closeHandler={() => {
            setUnlockError(null);
          }}
        />
      )}
      <CalendarHeader>{daysOfWeekHeaders}</CalendarHeader>
      <CalendarDays data-cy="calendar-days">
        <PopupModal show={showUnlockBadgeModal} backdrop="static">
          <SubmitModal
            header={t(
              'dailyChallenge:unlockStickerModal.header',
              "Unlock Hero's Badge?"
            )}
            title={t(
              'dailyChallenge:unlockStickerModal.title',
              {
                dates: moment(
                  selectedBadge?.Date,
                  'YYYY-MM-DDTHH:mm:ss'
                ).format(
                  formatI18nDateTime({
                    format: 'D MMM YYYY',
                    language: i18n.language,
                  })
                ),
              },
              `Are you sure you want to unlock the Hero's Badge on ${moment(
                selectedBadge?.Date,
                'YYYY-MM-DDTHH:mm:ss'
              ).format(
                formatI18nDateTime({
                  format: 'D MMM YYYY',
                  language: i18n.language,
                })
              )} with 20 KoKo credits?`
            )}
            desc=""
            noHandle={() => {
              setShowUnlockBadgeModal(false);
            }}
            yesHandle={unlockBadgeHandler}
            loading={isUnlocking}
          />
        </PopupModal>
        <PopupModal show={showInsufficientKoKoModal} backdrop="static">
          <NotEnoughKokoModal
            yesHandle={() => setShowInsufficientKoKoModal(false)}
          />
        </PopupModal>
        <PopupModal
          show={showEnlargedBadgeModal}
          backdrop="static"
          hide={() => {
            setShowEnlargedBadgeModal(false);
          }}
        >
          <HeroOfTheDayModal
            hideTopText
            isUnseen={checkIfSelectedBadgeIsUnseen(selectedBadge, unseenBadges)}
            showHoDBanner
            unseenBadges={[selectedBadge]}
            onHide={() => {
              setShowEnlargedBadgeModal(false);
            }}
            setMarkError={setMarkError}
          />
        </PopupModal>
        {!isLoading &&
          daysInMonthKeys.map((week) => (
            <CalendarWeek key={`${selectedMonthYear}-${week}`}>
              {daysInMonth[week].map((day, index) => {
                const findDayInApi = badges.find(
                  (badge) => badge.day === day?.day
                );
                const findDayInUnseenBadges = unseenBadges.find((badge) => {
                  const badgeDateTime = moment(
                    badge.uEntity.Date,
                    'YYYY-MM-DDTHH:mm:ss'
                  ).format('D-MM-YYYY');
                  return badgeDateTime === `${day?.day}-${selectedMonthYear}`;
                });
                if (!isNil(day)) {
                  return (
                    <DateHolder
                      onClickHandler={() => {
                        if (day.isFuture === false) {
                          showEnlargedModalHandler(findDayInApi, day.isToday);
                        }
                      }}
                      key={day.day}
                      isHidden={false}
                      isUnlockedByCP={
                        !isNil(findDayInApi) &&
                        findDayInApi.isUnlocked &&
                        findDayInApi.uEntity.Achievement_id === 1
                      }
                      isToday={day.isToday}
                      todayCPEarned={todayCPEarned}
                      day={day}
                      isUnseen={!isNil(findDayInUnseenBadges)}
                      badgeImg={
                        !isNil(findDayInApi) &&
                        (findDayInApi.isUnlocked ? findDayInApi.Sticker : null)
                      }
                    />
                  );
                }
                return (
                  <DateHolder
                    isHidden
                    key={`${selectedMonthYear}-${week}-hidden-${index}`}
                  />
                );
              })}
            </CalendarWeek>
          ))}
      </CalendarDays>
    </Container>
  );
};

export default BadgeCalendar;
