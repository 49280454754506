import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: normal;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  align-items: baseline;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-basis: 25%;
  cursor: ${(props) => (props.status === 'close' ? 'not-allowed' : 'pointer')};
  p {
    margin-bottom: 0rem;
  }
  .challenge {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    .challenge-img {
      width: 15px;
      height: 15px;
      margin-top: 0.3rem;
      margin-right: 0.3rem;
    }
    .challenge-sp-img {
      width: 25px;
      height: 25px;
      margin-right: 0.3rem;
    }
    .challenge-name {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .schedule {
    color: ${(props) => (props.status === 'close' ? '#A0A0A0' : '#3a9ac4')};
    font-size: 13px;
    text-align: ${(props) => (props.status === 'close' ? 'justify' : 'center')};
    span {
      color: #383838;
    }
  }
  .challenge-img {
    width: 200px;
    filter: ${(props) =>
      props.featureReady === false ? 'grayscale(100%)' : null};
  }
  .point-icon {
    filter: grayscale(100%) brightness(1.3);
  }
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.875rem;
    font-weight: 600;
    color: white;
    width: 200px;
    height: 100%;
    border-radius: 20px;
    background: rgba(54, 54, 54, 0.54);
    display: flex;
    align-items: center;
    justify-content: center;
    .maintenance-icon {
      margin-right: 3px;
    }
    span {
      margin-left: 3px;
    }
  }
`;
