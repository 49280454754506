import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  top: 0.75rem;
  width: 420px;
  margin-right: 0.5rem;
  font-weight: ${({ type }) => type === 'gold-medal' && 'bold'};
  font-size: ${({ type }) => type === 'gold-medal' && '1rem'};
  .progress {
    margin-right: 1rem;
    border-radius: 1rem;
    background: white;
  }
  .markers {
    display: flex;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 250px;
    top: 0.55rem;

    .progress {
      height: 12px;
    }
  }
`;

export const Marker = styled.span`
  font-size: 0.9rem;
  position: relative;
  bottom: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => `${props.leftMargin}rem`};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: ${(props) => (props.active ? 'white' : '#d2d2d2')};
  background: ${(props) => (props.active ? '#ffce00' : 'white')};

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 20px;
    height: 20px;
    font-size: 12px;
    bottom: 1rem;
  }
`;
