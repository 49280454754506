/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { compareValues } from 'helpers/compareValue';
import Spinner from 'components/Shared/Spinner';
import Header from 'components/QnView/QnViewHeader';
import BackButton from 'components/Shared/BackButton';
import BgIntermediate from 'assets/img/braingame/intermediate/brain-game-background.png';
import BrainGameMath from 'assets/img/braingame/intermediate/brain-game-math.png';
import BrainGameScience from 'assets/img/braingame/intermediate/brain-game-science.png';
import BGText from 'assets/img/braingame/intermediate/brain-games-text.png';
import PremiumPlanIcon from 'assets/img/braingame/intermediate/premium-plan-icon.png';
import SchoolPlanIcon from 'assets/img/braingame/intermediate/school-plan-icon.png';
import Button from 'components/Shared/Button';
import checkProductSubscription from 'helpers/Intermediate/checkProductSubscription';
import useDashboard from 'hooks/useDashboard';
import {
  Container,
  ProductItem,
  ProductListContainer,
  StyledHeader,
} from './IntermediatePage.styles';

const IntermediatePage = () => {
  const { t } = useTranslation(['brainGamesIntermediate']);
  const location = useLocation();
  const history = useHistory();
  const { isLoading, subject, products } = useSelector((state) => state.plan);
  const { isDashboardv2 } = useDashboard();
  const isFromShortCut = location.state?.quickAccess;
  const [options, setOptions] = useState([]);

  const items = [
    {
      id: 1,
      subject: 'Math',
      name: t('brainGamesIntermediate:math.title', 'Brain Games - Math'),
      imageUrl: BrainGameMath,
      redirectUrl: '/brain-game/math',
      premiumDuration: t(
        'brainGamesIntermediate:math.premiumDuration',
        '8am to 8pm, Daily'
      ),
      premiumPlanText: t('brainGamesIntermediate:premiumPlan', 'Premium Plan'),
      schoolDuration: t(
        'brainGamesIntermediate:math.schoolDuration',
        '8am to 6pm, Daily'
      ),
    },
    {
      id: 2,
      subject: 'Science',
      name: t('brainGamesIntermediate:science.title', 'Brain Games - Science'),
      imageUrl: BrainGameScience,
      redirectUrl: '/brain-game/science',
      premiumDuration: t(
        'brainGamesIntermediate:science.premiumDuration',
        '8am to 8pm, Daily'
      ),
      premiumPlanText: t(
        'brainGamesIntermediate:sciencePremiumPlan',
        'Premium Plan'
      ),
      schoolDuration: t(
        'brainGamesIntermediate:math.schoolDuration',
        '8am to 6pm, Daily'
      ),
    },
  ];

  useEffect(() => {
    const getList = () => {
      if (isNil(subject) || isNil(products)) return;
      // eslint-disable-next-line prefer-const
      let arrayList = [];
      products.forEach((product) => {
        const obj = checkProductSubscription(product, 'BrainGames');
        if (isNil(obj)) return;
        if (obj.parameters?.IsEnabled !== true) return;
        const itemIndex = items.findIndex(
          (item) => item.subject === product.subject
        );
        if (isNil(itemIndex)) return;
        if (items[itemIndex].subject.toLowerCase() === subject) {
          arrayList = [items[itemIndex], ...arrayList];
        } else {
          arrayList = [...arrayList, items[itemIndex]];
        }
      });
      setOptions(arrayList.sort(compareValues('id', 'asc')));
    };
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, products, t]);

  const renderDetails = () => {
    if (isLoading) {
      return <Spinner animation="border" style={{ marginTop: '25px' }} />;
    }

    return (
      <ProductListContainer>
        {options.map((option) => (
          <ProductItem key={option.id}>
            <img
              className="left-content"
              src={option.imageUrl}
              alt={option.name}
              width="208px"
              height="204px"
            />
            <div className="mid-content">
              <h4 className="open-title">{option.name}</h4>
              <p className="open-hour-label">
                {t('brainGamesIntermediate:openHours', 'Open Hours:')}
              </p>
              <div className="open-hour">
                <p className="open-duration">{option.premiumDuration}</p>
                <span className="open-plan">
                  ( <img src={PremiumPlanIcon} alt="premium-plan" />
                  {` ${option.premiumPlanText} `})
                </span>
              </div>
              {!isNil(option.schoolDuration) && (
                <div className="open-hour">
                  <p className="open-duration">{option.schoolDuration}</p>
                  <span className="open-plan">
                    ( <img src={SchoolPlanIcon} alt="school-plan" />{' '}
                    {t('brainGamesIntermediate:standardPlan', 'School Plan')} )
                  </span>
                </div>
              )}
            </div>
            <div className="right-content">
              <Button
                variant="primary"
                width="200px"
                onClick={() => history.push(option.redirectUrl)}
              >
                {t('brainGamesIntermediate:play', 'Play')}
              </Button>
            </div>
          </ProductItem>
        ))}
      </ProductListContainer>
    );
  };

  return (
    <Container>
      <StyledHeader>
        <Header page bgColor="#000" headerBg={BgIntermediate}>
          <BackButton
            backText={t('topicSelection:backBtn', 'Back')}
            btnBgColor="#0087CE"
            padding="5px 20px"
            onClick={() => {
              if (isFromShortCut) {
                history.goBack();
                return;
              }
              const destination = isDashboardv2
                ? '/dashboard?view=koofun'
                : '/dashboard';
              history.push(destination);
            }}
            styles={{ position: 'absolute', left: '3%' }}
          />
          <img
            className="bg-text"
            src={BGText}
            alt="brain-game"
            width="208px"
          />
        </Header>
      </StyledHeader>
      {renderDetails()}
    </Container>
  );
};

export default IntermediatePage;
