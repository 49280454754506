import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  voiceOverFileExist: false,
  voicePlayingQnId: null,
  voiceList: {},
};

const voiceoverSlice = createSlice({
  name: 'voiceover',
  initialState,
  reducers: {
    setVoiceFileExist(state, action) {
      state.voiceOverFileExist = action.payload;
    },
    setVoiceIsPlayingQn(state, action) {
      state.voicePlayingQnId = action.payload;
    },
    setVoiceDownloaded(state, action) {
      state.voiceList[action.payload.qnID] = action.payload.file;
    },
    clearVoiceoverPlaying(state) {
      state.voicePlayingQnId = null;
    },
  },
  extraReducers: {},
});
const { reducer } = voiceoverSlice;
export const {
  setVoiceFileExist,
  setVoiceIsPlayingQn,
  clearVoiceoverPlaying,
  setVoiceDownloaded,
} = voiceoverSlice.actions;
export default reducer;
