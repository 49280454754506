import React, { useEffect, useState } from 'react';
import AvatarPlaceholder from 'assets/img/avatar.png';
import { useTranslation } from 'react-i18next';
import { getRecentPeerChallengeOpponents } from 'services/multiplayerv2';
import { fetchWrapper } from 'services/login';
import { subjectNames } from 'constants/products';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { getUserInfos } from 'store/lookup/lookupSlice';
import SideNav from 'components/Shared/SideNav';
import useClickOutside from 'helpers/useClickOutside';
import CloseIconButton from 'components/Shared/CloseIconButton';
import {
  StyledContainer,
  OpponentList,
  StyledCard,
  MobileRecentButton,
  RecentPopupContainer,
} from './RecentOpponents.styles';

const OpponentCard = ({ avatar, name, isSelected, onClickHandler }) => {
  return (
    <StyledCard isSelected={isSelected} onClick={onClickHandler}>
      <img
        src={avatar ?? AvatarPlaceholder}
        alt=""
        onError={(e) => {
          e.target.src = AvatarPlaceholder;
        }}
      />
      <p className={name.length > 31 ? 'long-text' : ''}>{name}</p>
    </StyledCard>
  );
};

const getRecentOpponents = async ({
  userID,
  subjectID,
  dispatch,
  userInfoAction,
}) => {
  try {
    const opponents = await fetchWrapper(getRecentPeerChallengeOpponents, {
      userID,
      subjectID,
    });
    if (opponents.length <= 0) return [];
    const opponentUserIds = opponents.map((opponent) => opponent.UserId);
    const uniqueOpponentIds = [...new Set(opponentUserIds)];
    // Fetch user info
    const getUserInfoAction = await dispatch(
      userInfoAction({
        userIDs: uniqueOpponentIds,
        pageIndex: 0,
        pageSize: uniqueOpponentIds.length,
        cache: true,
      })
    );
    const usersInfo = await unwrapResult(getUserInfoAction);
    // key: userid
    const usersInfoHashmap = {};
    usersInfo.List.forEach((userInfo) => {
      usersInfoHashmap[userInfo.Id] = userInfo;
    });
    return opponents
      .filter((opponent) => usersInfoHashmap[opponent.UserId] !== undefined)
      .map((opponent) => ({
        ...usersInfoHashmap[opponent.UserId],
        ...opponent,
      }));
  } catch (err) {
    throw new Error(err.message);
  }
};

const RecentOpponents = ({
  setOpponentType,
  setNewSelectedOpponentId,
  selectedOpponentId,
  opponentType,
  subjectParam,
  userID,
  setRecentOpponentType,
}) => {
  const { t } = useTranslation(['newChallenge']);
  const dispatch = useDispatch();
  const [opponents, setOpponents] = useState([]);
  const [mobilePopup, setMobilePopup] = useState(false);
  const outsideClick = useClickOutside(() => setMobilePopup(false));

  const selectOpponentHandler = (id, isRandom) => {
    return () => {
      setOpponentType('1');
      setRecentOpponentType(isRandom);
      setNewSelectedOpponentId('1', id);
    };
  };
  useEffect(() => {
    if (!userID || !subjectNames[subjectParam]) return;
    getRecentOpponents({
      userID,
      subjectID: subjectNames[subjectParam],
      dispatch,
      userInfoAction: getUserInfos,
    })
      .then((res) => setOpponents(res))
      .catch((err) => console.error(err.message));
  }, [userID, subjectParam, dispatch]);
  if (opponents.length <= 0) return null;
  const renderRecentList = (
    <>
      <p className="recent-opponents-title">
        {t('newChallenge:main.recentOpponent', 'Recent')}
      </p>
      <OpponentList>
        {opponents.map((opponent) => (
          <OpponentCard
            name={opponent.FullName ?? ''}
            avatar={opponent.UserAvatarImage}
            key={opponent.Id}
            isSelected={
              opponentType === '1' && selectedOpponentId === opponent.Id
            }
            onClickHandler={selectOpponentHandler(
              opponent.Id,
              opponent.IsRandom
            )}
          />
        ))}
      </OpponentList>
    </>
  );

  const mobileSelectedOpponent = opponents.filter(
    (itm) => itm.Id === selectedOpponentId
  );

  const getAvatar = () => {
    if (mobileSelectedOpponent[0])
      return mobileSelectedOpponent[0].UserAvatarImage;
    if (opponents[0]) return opponents[0].UserAvatarImage;
    return AvatarPlaceholder;
  };

  return (
    <StyledContainer>
      <MobileRecentButton
        isSelected={mobileSelectedOpponent.length > 0}
        onClick={() => setMobilePopup(true)}
      >
        <p className="recent-opponents-title">
          {t('newChallenge:main.recentOpponent', 'Recent')}
        </p>
        <img
          src={getAvatar()}
          alt=""
          onError={(e) => {
            e.target.src = AvatarPlaceholder;
          }}
        />
      </MobileRecentButton>
      <div className="content">{renderRecentList}</div>
      <SideNav show={mobilePopup}>
        <RecentPopupContainer ref={outsideClick}>
          {renderRecentList}
          <CloseIconButton
            size="32px"
            color="#fff"
            padding="8px"
            onClick={() => setMobilePopup(false)}
            btnBgColor="#b4b4b4"
          />
        </RecentPopupContainer>
      </SideNav>
    </StyledContainer>
  );
};

export default RecentOpponents;
