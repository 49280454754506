import styled from 'styled-components';

export const SelectContainer = styled.button`
  height: 32px;
  display: flex;
  background: #ffffff;
  border: 1px solid #bbd9f3;
  justify-content: space-between;
  align-items: center;
  max-width: ${(props) => (props.isCircle ? '30%' : '70%')};

  p {
    font-family: Nunito;
    font-size: 14px;
    margin-bottom: 0px;
    text-align: left;
    width: calc(90%);
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding-right: 16px;
    white-space: nowrap;
  }

  img {
    width: 10px;
    height: 14px;
    filter: invert(77%) sepia(2%) saturate(9%) hue-rotate(17deg)
      brightness(111%) contrast(87%);
  }
`;

export const ButtonContainer = styled.button`
  background: ${(props) => (props.isSelected ? '#ffffff' : '#fcfcfc')};
  border: 1px solid ${(props) => (props.isSelected ? '#bbd9f3' : '#cdcdcd')};

  p {
    margin-bottom: 0px;
    text-align: ${(props) => (props.isCircle ? 'center' : 'left')};
    font-size: 14px;
    color: ${(props) => (props.isSelected ? '#000' : '#838383')};
  }
`;

export const ModalTitle = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-top: 25px;
  color: #247ac4;
`;

export const ModalContent = styled.div`
  display: ${(props) => (props.isCircle ? 'grid' : 'flex')};
  flex-direction: column;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  overflow-y: auto;
  height: 85%;
  place-items: center;

  .boxContent {
    width: 100%;
    padding: 10px 16px;
  }

  .circleContent {
    width: 100px;
    height: 100px;
    border-radius: 100%;
  }
`;
