import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Button from 'components/Shared/Button';
import Mascot from 'assets/img/expired/expired-mascot.svg';
import { DescriptionStyle, ButtonContainer } from './Description.styles';

const contents = (t, plan) => {
  switch (plan) {
    case 'school':
      return (
        <>
          <p>
            {t(
              'expired:description.thanks',
              `Thanks for using KooBits. We hope you've enjoyed the activities at KooBits.`
            )}
          </p>
          <p>
            {t(
              'expired:description.b2bAccExpired',
              `Unfortunately, your school's account has expired.`
            )}
          </p>
          <p>
            {t(
              'expired:description.desc1',
              `You can check with your school teacher to see whether your school is still subscribing for you.`
            )}
          </p>
          <p>
            {t(
              'expired:description.desc2',
              `If not, you can ask your parent to renew the account for you under a Family plan – Standard or Premium.`
            )}
          </p>
        </>
      );
    case 'standard':
      return (
        <>
          <p>
            {t(
              'expired:description.stdDesc1',
              'Your Standard account has expired.'
            )}
          </p>
          <p>
            <Trans i18nKey="expired:description.stdDesc2" t={t}>
              As we are no longer supporting Standard plans, please ask your
              parent to{' '}
              <a
                href="https://www.koobits.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact our customer support
              </a>{' '}
              to renew and upgrade your account to a Premium plan.
            </Trans>
          </p>
        </>
      );
    case 'plus':
      return (
        <>
          <p>
            {t(
              'expired:description.thanks',
              `Thanks for using KooBits. We hope you've enjoyed the activities at KooBits.`
            )}
          </p>
          <p>
            {t(
              'expired:description.b2cAccExpired',
              `Unfortunately, your account has expired.`
            )}
          </p>
          <p>
            {t(
              'expired:description.desc3',
              `To continue using KooBits, please ask your parent to renew the subscription by clicking the button below.`
            )}
          </p>
        </>
      );
    default:
      return (
        <>
          <p>
            {t(
              'expired:description.genericPremium.text1',
              `Thank you for your interest in KooBits Math.`
            )}
          </p>
          <p>
            {t(
              'expired:description.genericPremium.text2',
              `Unfortunately, you do not have an active Math subscription.`
            )}
          </p>
          <p>
            {t(
              'expired:description.genericPremium.text3',
              `To continue, please ask your parents to activate your Math subscription by clicking the button below.`
            )}
          </p>
        </>
      );
  }
};

const Description = ({ type, name, renew, upgrade }) => {
  const { t } = useTranslation(['expired']);

  return (
    <DescriptionStyle>
      <p>{t('expired:description.hiUser', { name }, `Hi ${name},`)}</p>
      {contents(t, type)}
      {type !== 'standard' && (
        <Buttons type={type} renew={renew} upgrade={upgrade} t={t} />
      )}
      <img src={Mascot} alt="mascot" className="expired-mascot" />
    </DescriptionStyle>
  );
};

export default Description;

const Buttons = ({ type, renew, upgrade, t }) => {
  return (
    <ButtonContainer>
      <Button variant="primary" width="230px" onClick={renew}>
        {type === 'school' &&
          t('expired:description.renewPremium', 'Renew with Math Premium Now')}
        {type !== 'school' &&
          t('expired:description.renew', 'Renew My Account')}
      </Button>
    </ButtonContainer>
  );
};
