import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 2rem 3rem 4rem 0.8rem;
  .qn-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8eced;
    padding-bottom: 0.5rem;
    .qn-section {
      display: flex;
      font-size: 20px;
      font-weight: bold;
      color: #62dcff;
      align-items: baseline;
      .qn-id,
      .qn-id-mobile {
        font-size: 12px;
        color: rgb(57, 57, 57, 0.44);
        font-weight: 300;
        margin-left: 0.5rem;
      }
      p {
        margin-bottom: 0px;
      }
    }
    .qn-difficulty {
      display: flex;
      align-items: center;
      .difficulty-chillis {
        margin-right: 0.8rem;
      }
    }
  }
  .qn-content {
    margin-top: 0.5rem;
    margin-left: -1rem;
  }
  .btn-orange {
    background-color: #ff6701;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    border-radius: 30px;
    border: none;
    &:hover {
      background-color: #ff5c00;
    }
    &:focus {
      box-shadow: none;
    }
    ${({ voiceOverFileExist }) =>
      voiceOverFileExist &&
      css`
        margin-left: 2rem;
        @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
          margin-left: 0;
        }
      `}
  }
  .qn-id-mobile {
    display: none;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 2rem 5vw 4rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 4px 1rem 32px;
    .question-title {
      display: none;
    }
    .qn-id {
      display: none;
    }
    .qn-id-mobile {
      display: block;
    }
    .qn-header .qn-difficulty .difficulty-chillis {
      margin-right: 50px;
    }

    .qn-difficulty button {
      margin-right: 0.5rem;
    }

    .qn-content {
      margin-left: 0px;
      min-height: 0;
      & > div {
        min-height: 0;
        padding: 0;
      }
    }

    .btn-submit {
      padding-left: 16px;
    }
  }
`;

export const WrongAlert = styled.div`
  color: #ff3303;
  font-size: 18px;
  background-color: rgb(255, 241, 241, 0.77);
  text-align: center;
  p {
    margin-bottom: 0px;
  }
`;

export const WrongAnswerSubmit = styled.div`
  margin-top: 0.5rem;

  .video-tutorial {
    display: flex;
    align-items: center;
    color: #393939;
    margin-bottom: 1rem;
    margin-top: -1.5rem;
    span {
      font-weight: normal;
      color: #393939;
      &:hover {
        text-decoration: underline;
      }
    }
    img {
      width: 40px;
      cursor: pointer;
    }
  }
  .btn-tutorial {
    all: unset;
    margin-bottom: 0rem;
    margin-left: 0.5rem;
    font-weight: 300;
    cursor: pointer;
  }
`;
