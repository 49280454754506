import React, { useEffect } from 'react';

import { getUserAvailableCurriculumList } from 'store/curriculum/curriculumSlice';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import Language from 'components/MySettings/Language';
import CurriculumDC from 'components/MySettings/CurriculumDC';
import CurriculumPC from 'components/MySettings/CurriculumPC';
import { StyledBox, StyledContainer } from './index.styles';

const Settings = () => {
  const dispatch = useDispatch();
  const { userAvailableCurriculumList } = useSelector(
    (state) => state.curriculum
  );
  useEffect(() => {
    if (
      isEmpty(userAvailableCurriculumList.curriculumList) &&
      isNil(userAvailableCurriculumList.error)
    ) {
      dispatch(getUserAvailableCurriculumList());
    }
    // eslint-disable-next-line
  }, [
    userAvailableCurriculumList.curriculumList,
    userAvailableCurriculumList.error,
  ]);
  return (
    <StyledContainer>
      <Language />
      <StyledBox>
        <CurriculumDC />
      </StyledBox>
      <StyledBox>
        <CurriculumPC />
      </StyledBox>
    </StyledContainer>
  );
};

export default Settings;
