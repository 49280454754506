import React from 'react';
import ThreeDotIcon from 'assets/img/three-dot-icon.png';
import IconButton from './IconButton';

const ThreeDotIconButton = ({ btnBgColor, onClick, size, dataCy, padding }) => {
  return (
    <IconButton
      data-cy={dataCy}
      padding={padding}
      size={size}
      bgColor={btnBgColor}
      onClick={onClick}
      Icon={ThreeDotIcon}
    />
  );
};

export default ThreeDotIconButton;
