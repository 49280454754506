import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
`;
export const KoKoMonster = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  position: relative;
  img {
    display: block;
    position: absolute;
    max-width: 100%;
    top: 0px;
    left: 0px;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  .body {
    height: 90%;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
  }
  .horn {
    margin: auto;
    left: 50%;
    transform: ${({ theme }) => `translate(-50%,${-theme.horn.topOffset}%)`};
    height: 35%;
    top: ${({ theme }) => `${theme.horn.topOffset}%`};
    width: auto;
  }
  .eyes {
    height: 25%;
    left: 50%;
    top: ${({ theme }) => `${theme.eyes.topOffset}%`};
    transform: ${({ theme }) => `translate(-50%,${-theme.eyes.topOffset}%)`};
    width: auto;
  }
  .mouth {
    height: 20%;
    left: 50%;
    transform: ${({ theme }) => `translate(-50%,${-theme.mouth.topOffset}%)`};
    top: ${({ theme }) => `${theme.mouth.topOffset}%`};
    width: auto;
  }
`;
