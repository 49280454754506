import styled from 'styled-components';

export const StartButton = styled.div`
  border: none;
  background: white;
  width: 55%;
  height: 40px;
  color: ${(props) => (props.btnColor ? props.btnColor : '#6a8ace')};
  font-size: 1.5rem;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    font-size: 1rem;
    height: 30px;
  }
`;

export const OverlayContainer = styled.div`
  border-radius: 15px;
  background: ${(props) =>
    props.isFeatureReady ? 'none' : 'rgba(30, 30, 30, 0.67)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: ${(props) => (props.isFeatureReady ? 'pointer' : 'not-allowed')};
  border: ${(props) => (props.isPremium ? '5px solid white' : 'none')};
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  p {
    padding: 0 10px;
    font-size: 1rem;
    color: white;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  h4 {
    font-weight: 700;
    color: white;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    p {
      margin-bottom: 0.2rem;
      font-size: 10px;
      line-height: 1.1;
    }
  }
`;

export const Border = styled.div`
  border-radius: 20px;
  width: 234px;
  height: 228px;
  box-shadow: 0px 7px 0px 0px rgba(82, 75, 97, 0.28);
  flex-shrink: 0;
  margin-bottom: 1rem;
  position: relative;
  border: ${(props) => (props.isPremium ? 'none' : 'solid 5px white')};
  @media (min-width: 1700px) {
    width: 290px;
    height: 270px;
  }
  @media (max-width: 1150px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 42vw;
    height: 41vw;

    :last-child {
      height: auto;
      background-color: #97cd2c;
      .heading-cover {
        bottom: 6.5rem !important;
      }
    }
  }
`;

export const Container = styled.div`
  border-radius: 15px;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: ${(props) => `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: 105% 105%;
  background-position: center;
  cursor: pointer;
  filter: ${(props) =>
    props.showOverlay && props.isFeatureReady ? 'blur(5px)' : null};
  -webkit-filter: ${(props) =>
    props.showOverlay && props.isFeatureReady ? 'blur(5px)' : null};
  .heading-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 1.7rem;
    text-align: center;
    span {
      font-size: 1.875rem;
      font-weight: 600;
      line-height: 1;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      @media (max-width: 1150px) {
        font-size: 1.4rem;
      }
      @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
        font-size: 5vw;
      }
    }
    .desc {
      text-align: center;
      font-size: 0.8rem;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .heading-cover {
      bottom: 1rem;
    }
  }
`;
