import styled from 'styled-components';
// import ProgressBar from 'assets/img/exp-level/dashboard/progress-bar.svg';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 600;
  width: 40rem;
  height: 120px;
  border-radius: 55px;
  background-color: #0781cc;

  .badge {
    width: 20%;
  }

  .progress {
    width: 70%;
  }

  .exp-total {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-left: -2rem;
    color: #fff;
  }

  .exp-total-number,
  .exp-current-level {
    font-size: 40px;
    font-family: 'Linotte';
    font-weight: 700;
  }

  .exp-total-number {
    margin-bottom: -10px;
    margin-left: 5px;
  }

  .exp-current-level {
    margin-top: -14px;
  }

  .exp-total-xp {
    margin-bottom: -3px;
    margin-left: 3px;
  }
`;

export const StyledProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ currentLevel }) => currentLevel === 100 && '10px'};

  .exp-row {
    display: flex;
    flex-direction: row;
  }

  .exp-small {
    color: #84c2ff;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .exp-progress {
    padding-left: 0.8rem;
    margin-top: -0.5rem;
  }

  .exp-current-level {
    color: #fff;

    .exp-current-text {
      padding-left: 0.5rem;
      font-size: 20px;
    }

    .exp-current-number {
      padding-left: 0.5rem;
      font-size: 31px;
    }
  }
`;

export const StyledProgressBorder = styled.div`
  background-color: #0973b4;
  border-radius: 50px;
  width: 21rem;
  height: 2rem;
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;

  .empty-bar,
  .fill-bar {
    position: absolute;
    margin-left: 1rem;
  }

  .empty-bar {
    background-color: #ffffff;
    width: ${({ maxWidth }) => maxWidth}rem;
    height: 1rem;
    margin-top: 0.5rem;
    border-radius: 50px;
    opacity: 0.9;
  }

  .fill-bar {
    margin-top: 0.26rem;

    .mid-bar,
    .end-bar {
      margin-left: -0.2rem;
    }
  }
`;
