import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: calc(100vh - 99px);
  padding: 120px 15% 0;
  .password {
    padding-left: 7.5rem;
    justify-content: space-between;
  }
  .subscription {
    padding-left: 8rem;
    justify-content: space-between;
  }
`;

export const ProfileCard = styled.div`
  background: #ffffff;
  border-radius: 5px;
  border: 0.5px solid #e0e0e0;
  width: 100%;
  padding: 1rem 4rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  .left-section {
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 0px;
    }
    #file {
      display: none;
    }
    .overlay {
      background: rgb(0, 0, 0, 0.3);
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.3s all;
      position: absolute;
      margin-top: -135px;
      margin-left: 5px;
      height: 130px;
      width: 130px;
      opacity: 0;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
      p {
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 5px;
      }
    }
    .tool-tip {
      color: #bebebe;
      display: flex;
      align-items: center;
    }
    .failed-upload {
      color: #ff0000;
      font-size: 14px;
      width: 200px;
      margin-left: -30px;
      margin-top: 15px;
    }
  }
  a {
    text-decoration: none;
  }
  .profile-picture {
    width: 139px;
    height: 139px;
    border-radius: 50%;
    border: 5px solid #ffffff;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
  }
  .camera-icon {
    position: absolute;
    margin-top: 6.5rem;
    margin-left: -1.8rem;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 85%;
  }
  .no-bottom {
    p {
      margin-bottom: 0px;
    }
  }
`;

export const DetailsInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3rem;
  .title {
    color: #bebebe;
    margin-right: 1.5rem;
    min-width: 50px;
    text-align: end;
  }
  .bold {
    font-weight: 600;
    font-size: 18px;
  }
  .value {
    color: #1f1f1f;
    margin-right: 0.8rem;
  }
  .plan {
    font-weight: 600;
    img {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      position: absolute;
      margin-top: -3px;
      margin-left: 6px;
    }
  }
  .period {
    font-size: 13px;
    color: #949090;
  }

  .period-info {
    font-size: 13px;
    color: #949090;
    margin-top: 10px;
  }
  .subscription {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .warning-text {
    color: #ff0000;
    margin: 0 0.8rem;
  }
`;

export const TooltipIcon = styled.div`
  border: 1px solid #c9c9c9;
  color: #bebebe;
  background: #e6e6e6;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    border-color: #c1daff;
    color: #c1daff;
    background: #e6f0ff;
  }
`;

export const TooltipMsgContainer = styled.div`
  background: #fff5cb;
  border: 1px solid #fae99f;
  padding: 1rem;
  max-width: 335px;
  margin-top: 3rem;
  font-size: 12px;
  margin-left: 5px;
  margin-top: -1px;
  .support-img {
    margin-top: 1rem;
  }
  p {
    margin-bottom: 0px;
  }
`;

export const StyledSubscriptionInfo = styled(DetailsInfo)`
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  .title {
    white-space: nowrap;
    margin-right: 0;
  }
  .value {
    width: calc(100% - 2.5rem);
    margin-left: 2.5rem;
    margin-right: 0;
  }
  .plan {
    margin-bottom: 0.5rem;
  }
`;

export const StyledValueWrap = styled.div`
  border-bottom: 1px dashed #c9c9c9;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &:last-of-type {
    border-bottom: none;
  }
`;

export const StyledSchoolRenewInfo = styled.p`
  max-width: 185px;
  color: #bebebe;
  line-height: 1;
`;

export const StyledRenewButton = styled.a`
  border: 1px solid;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  width: 185px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  margin: 0px;
  background: #ff7121;
  color: #ffffff;
  border-color: #ff7121;
  &:hover {
    color: #ffffff;
    background: #ff5c00;
    border-color: #ff5c00;
  }
  @media (max-width: 1367px) {
    font-size: 16px;
  }

  @media (max-width: 610px) {
    font-size: 10px;
  }
`;
