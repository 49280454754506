import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getQuestionById } from 'store/report/reportSlice';
import GradeAIcon from 'assets/img/report/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/report/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/report/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/report/icon-report-high-score-d.svg';
import IncompleteIcon from 'assets/img/report/icon-report-high-score-incomplete.svg';
import { isNil } from 'ramda';
import HTMLReactParser from 'html-react-parser';
import { ProficiencyStatus, MasteryLevel } from 'constants/report';
import xmlParser from 'helpers/xmlParser';
import convertTagsToHTML from 'helpers/convertTagsToHtml';
import HighScoreStars from './HighScoreStars';
import Button from 'components/Shared/Button';
import {
  SkillDetailsContainer,
  SkillDetailsHeader,
  SkillBadge,
  QuestionContainer,
  SolutionContainer,
  ErrorContainer,
} from './SkillPanel.styles.js';

const SkillDetailsQuestion = ({ data }) => {
  const { t } = useTranslation(['proficiencyReport']);
  // States
  const [questionTitle, setQuestionTitle] = useState(null);
  const [questionSolution, setQuestionSolution] = useState(null);

  // Effects
  useEffect(() => {
    if (!isNil(data.Payload)) {
      const parsed = xmlParser(data.Payload);
      setQuestionTitle(
        HTMLReactParser(convertTagsToHTML(parsed.questionContent), {
          replace: (domNode) => {
            if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
              return <img src={domNode.attribs?.url} alt="unique-id" />;
            }
            if (domNode.name === 'helptext') {
              return (
                <small id="questionHelp" className="form-text text-muted">
                  {domNode.children[0]?.data ?? ''}
                </small>
              );
            }
            if (domNode.name === 'input') {
              return <input type="text" />;
            }
            return domNode;
          },
        })
      );

      setQuestionSolution(HTMLReactParser(convertTagsToHTML(parsed.solution)));
    }
  }, [data.Payload]);
  return (
    <>
      <QuestionContainer data-cy="qn-container">
        <p className="question-title">
          {t(`proficiencyReport:studentReportSide.sampleQs`, 'Sample Question')}{' '}
          ( ID: {data.Id} )
        </p>
        <p className="question-content">{questionTitle}</p>
      </QuestionContainer>
      <SolutionContainer data-cy="solution-container">
        <p className="solution-title">
          {t(`proficiencyReport:studentReportSide.solution`, 'Solution')}
        </p>
        <p className="solution-content">{questionSolution}</p>
      </SolutionContainer>
    </>
  );
};

export default function SkillPanel({
  type,
  selectedSkill,
  selectedSkillTopic,
  selectedSkillNumber,
  setShowSkillDetails,
}) {
  const { t } = useTranslation(['proficiencyReport', 'errorModal']);
  const GradeIconMapping = {
    D: GradeDIcon,
    C: GradeCIcon,
    B: GradeBIcon,
    A: GradeAIcon,
    incomplete: IncompleteIcon,
  };
  const dispatch = useDispatch();
  const { isLoading, sampleQn, sampleQnError } = useSelector(
    (state) => state.report
  );

  useEffect(() => {
    const params = {
      id: selectedSkill.SampleQuestionID,
    };
    dispatch(getQuestionById(params));
  }, [dispatch, selectedSkill.SampleQuestionID]);

  const reloadHandler = () => {
    const params = {
      id: selectedSkill.SampleQuestionID,
    };
    dispatch(getQuestionById(params));
  };

  return (
    <SkillDetailsContainer data-cy="skill-panel">
      <SkillDetailsHeader>
        <p className="skill-name">
          <strong>{selectedSkillTopic}</strong>
          <span
            onClick={() => setShowSkillDetails(false)}
            className="close-panel"
          >
            x
          </span>
        </p>
        <div style={{ marginBottom: '0.5rem' }}>
          <span className="skill-number">
            {t(`proficiencyReport:studentReportSide.skill`, 'Skill')}
            {` ${selectedSkillNumber}`}
          </span>
          {type === 'highscore' && (
            <HighScoreStars numOfStars={selectedSkill.MasteryLevel} />
          )}
          {type === 'proficiency' ? (
            <SkillBadge
              proficiency={ProficiencyStatus[selectedSkill.ProficiencyStatus]}
            >
              {t(
                `proficiencyReport:legend.${
                  ProficiencyStatus[selectedSkill.ProficiencyStatus]
                }`,
                ProficiencyStatus[selectedSkill.ProficiencyStatus]
                  .charAt(0)
                  .toUpperCase() +
                  ProficiencyStatus[selectedSkill.ProficiencyStatus].slice(1)
              )}
            </SkillBadge>
          ) : null}
        </div>
        <p className="skill-description">{selectedSkill.SkillName}</p>
        <div style={{ marginBottom: '0.5rem' }}>
          {type === 'highscore' && (
            <img
              src={GradeIconMapping[MasteryLevel[selectedSkill.MasteryLevel]]}
              alt={MasteryLevel[selectedSkill.MasteryLevel]}
            />
          )}
          {type === 'proficiency' && (
            <>
              <span className="student-percentage">
                <strong>{`${Math.round(
                  selectedSkill.Proficiency * 100
                )}%`}</strong>
              </span>
              <span className="class-avg-title">
                {t(`proficiencyReport:studentReportSide.classAvg`, 'Class Avg')}
              </span>
              <span className="class-avg-percentage">
                <strong>
                  {`${Math.round(selectedSkill.ClassAverage * 100)}%`}
                </strong>
              </span>
            </>
          )}
        </div>
      </SkillDetailsHeader>
      {!isLoading && sampleQn && <SkillDetailsQuestion data={sampleQn} />}
      {!isNil(sampleQnError) && (
        <ErrorContainer data-cy="error-container">
          <p>
            {sampleQnError.toLowerCase() === 'unknown error'
              ? `${t('common:sorry', 'Sorry')}, ${t(
                  'errorModal:someWrong',
                  'Something went wrong'
                )}`
              : sampleQnError}
          </p>
          <Button variant="primary" onClick={reloadHandler} dataCy="reload-btn">
            {t('common:reload', 'Reload')}
          </Button>
        </ErrorContainer>
      )}
    </SkillDetailsContainer>
  );
}
