import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Container, Term, Classic } from './CCToggle.styles';

const CCToggle = ({ clickHandler }) => {
  const { t } = useTranslation(['leaderboard']);

  const { type } = useSelector((state) => state.championClassV2);

  return (
    <Container>
      <Term onClick={() => clickHandler('term')} active={type === 'term'}>
        {t('leaderboard:topBrainBanner.termly', `Termly`)}
      </Term>
      <Classic
        onClick={() => clickHandler('classic')}
        active={type === 'classic'}
      >
        {t('leaderboard:topBrainBanner.classic', `Classic`)}
      </Classic>
    </Container>
  );
};

export default CCToggle;
