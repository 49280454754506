import styled from 'styled-components';
const BUTTON_HEIGHT = '43px';
const BIGGER_BUTTON_HEIGHT = 135;
export const Container = styled.div`
  .swiper-container {
    position: static;
    padding-top: 1%;
    .swiper-button-prev,
    .swiper-button-next {
      top: auto;
      bottom: 7%;
      margin: 0 20px;
      border-radius: 50%;
      outline: none;
      width: ${BUTTON_HEIGHT};
      height: ${BUTTON_HEIGHT};
      background: #ffffff;
      border: none;
      box-shadow: 0px 3px 3px rgb(0 0 0 / 7%);
      color: #7876ee;
      font-weight: 600;
      z-index: 99;
    }
    .swiper-button-prev {
      left: 39%;
    }
    .swiper-button-next {
      right: 36%;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 0.75rem;
      -webkit-text-stroke: 1px #7876ee;
    }
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-pagination {
    bottom: 6%;
    width: 90%;
    left: 6.5%;
    height: ${BUTTON_HEIGHT};
  }
  .swiper-pagination-bullet {
    opacity: 1;
    background-color: #4943e3;
    margin: 0 6px !important;
    @media (max-width: 1320px) {
      margin: 0 3px !important;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: #ffffff;
  }
  .swiper-slide-active {
    margin: 0 11% 0 0;
    @media (max-width: 1250px) {
      margin: 0 9% 0 0;
    }
    .main-container {
      width: 418px;
      @media (max-width: 1320px) {
        width: 400px;
        .swiper-pagination-bullet {
          margin: 0 3px !important;
        }
      }
      @media (max-width: 1250px) {
        width: 370px;
      }
      @media (max-width: 1200px) {
        width: 350px;
        .lesson-img {
          height: 220px;
        }
      }
      @media (max-width: 1100px) {
        width: 330px;
        .lesson-img {
          height: 200px;
        }
      }
      @media (max-width: 1000px) {
        width: 310px;
        .lesson-img {
          height: 180px;
        }
      }
    }
    .lesson-container {
      font-size: 16px;
      width: 100%;
    }
    .lesson-img {
      height: 230px;
    }
    .center {
      height: 110px;
      @media (max-width: 1250px) {
        height: 90px;
      }
    }
    .footer {
      .details {
        margin-top: 10px;
      }
    }
    .complete {
      width: auto;
      right: -4%;
      top: -4%;
    }
  }
`;

export const StyledSwiperButton = styled.div`
  width: ${BIGGER_BUTTON_HEIGHT}px;
  height: ${BIGGER_BUTTON_HEIGHT}px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 7%);
  color: #b0afff;
  font-weight: 600;
  z-index: 99;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  right: ${({ content }) =>
    content === 'next' ? `-${BIGGER_BUTTON_HEIGHT / 1.7}px` : 'auto'};
  left: ${({ content }) =>
    content === 'prev' ? `-${BIGGER_BUTTON_HEIGHT / 1.7}px` : 'auto'};
  &:hover {
    color: #7876ee;
    ::after {
      -webkit-text-stroke: 1px #7876ee;
    }
  }
  ::after {
    -webkit-text-stroke: 1px #b0afff;
    font-family: 'swiper-icons';
    content: '${({ content }) => content}';
    font-size: 1.8rem;
    position: absolute;
    top: 50%;
    right: 70%;
    transform: translateY(-50%);
    ${({ content }) => (content === 'next' ? 'right:70%' : 'left:70%')}
  }
`;
