import React from 'react';
import {
  Container,
  Content,
  Circle,
  TextWrap,
  Text,
} from './ControlPanelInfo.sytle';

const ControlPanelInfo = ({ color, title, name, school, chapter, bbfcode }) => {
  return (
    <Container>
      <Content>
        <Circle color={color}>{title}</Circle>
        <TextWrap>
          <Text>{name}</Text>
          <Text>{school}</Text>
          <Text>{chapter}</Text>
          <Text>{bbfcode ? `BFF code : ${bbfcode}` : ''}</Text>
        </TextWrap>
      </Content>
    </Container>
  );
};

export default React.memo(ControlPanelInfo);
