import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getStudentbyClassIdRequest,
  getClassesBySchoolIdRequest,
  openPeerChallengeRequest,
  acceptChallengeRequest,
  fetchPeerChallengeHistory,
  submitPeerChallengeRequest,
  rejectChallengeRequest,
  fetchPeerChallengeSolution,
  fetchPeerChallengeHistoryV2,
  openPeerChallengeRequestV2,
  fetchIncomingChallenge,
} from 'services/multiplayer';
import {
  postNewChallenge,
  postSubmitPeerChallenge,
  getPeerChallengeResult,
  getIncomingPeerChallengeHistory,
  putAcceptRejectPeerChallenge,
} from 'services/multiplayerv2';
import { fetchWrapper } from 'services/login';
import xmlParser from 'helpers/xmlParser';
import { isNil, isEmpty } from 'ramda';
import { featureToggle } from 'constants/index';

// HELPERS
const parseNullSubmissionmodel = (userQnSubmissions) => {
  const result = userQnSubmissions.map((qnSubmission) => {
    if (
      isNil(qnSubmission.SubmissionModel) ||
      isEmpty(qnSubmission.SubmissionModel)
    ) {
      return {
        ...qnSubmission,
        SubmissionModel: {
          Payload: qnSubmission.Question.Payload,
          Solution: '',
          SubmissionKeyValuePairs: [],
        },
      };
    }
    return qnSubmission;
  });
  return result;
};

// ASYNC THUNKS
export const getClassesBySchoolId = createAsyncThunk(
  'multiplayer/getClassBySchoolId',
  async (params) => {
    try {
      const res = await fetchWrapper(getClassesBySchoolIdRequest, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get Classes by School ID failed');
    }
  }
);
export const getStudentbyClassId = createAsyncThunk(
  'multiplayer/getStudentByClassId',
  async (classId) => {
    try {
      const res = await fetchWrapper(getStudentbyClassIdRequest, classId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get Student by Class ID failed');
    }
  }
);

// Deprecated
export const openPeerChallenge = createAsyncThunk(
  'multiplayer/openPeerChallenge',
  async (params) => {
    try {
      const { opponentId } = params;
      const res = featureToggle.science
        ? await fetchWrapper(openPeerChallengeRequestV2, params)
        : await fetchWrapper(openPeerChallengeRequest, opponentId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Open Peer Challenge failed');
    }
  }
);

export const issuePeerChallenge = createAsyncThunk(
  'multiplayer/openPeerChallenge',
  async (params) => {
    try {
      const res = await fetchWrapper(postNewChallenge, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Open Peer Challenge failed');
    }
  }
);

export const getIncomingChallenge = createAsyncThunk(
  'multiplayer/getIncomingChallenge',
  async (subjectID) => {
    try {
      const res = featureToggle.science
        ? await fetchWrapper(getIncomingPeerChallengeHistory, subjectID)
        : await fetchWrapper(fetchIncomingChallenge);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Fetch Incoming Challenge failed');
    }
  }
);

export const acceptChallenge = createAsyncThunk(
  'multiplayer/acceptChallenge',
  async (params) => {
    try {
      const res = await fetchWrapper(acceptChallengeRequest, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Accept challenge failed');
    }
  }
);

export const acceptChallengeV2 = createAsyncThunk(
  'multiplayer/acceptChallenge',
  async (params) => {
    try {
      const res = await fetchWrapper(putAcceptRejectPeerChallenge, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Accept challenge failed');
    }
  }
);

export const rejectChallenge = createAsyncThunk(
  'multiplayer/rejectChallenge',
  async (params) => {
    try {
      const res = await fetchWrapper(rejectChallengeRequest, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Reject challenge failed');
    }
  }
);

export const getPeerChallenegHistory = createAsyncThunk(
  'multiplayer/getPeerChallenegHistory',
  async (params) => {
    try {
      const res = featureToggle.science
        ? await fetchWrapper(fetchPeerChallengeHistoryV2, params)
        : await fetchWrapper(fetchPeerChallengeHistory, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Fetch Peer Challenge history failed');
    }
  }
);

// Deprecated
export const submitPeerChallenge = createAsyncThunk(
  'multiplayer/submitPeerChallenge',
  async (params) => {
    try {
      const res = await fetchWrapper(submitPeerChallengeRequest, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Submit Peer Challenge failed');
    }
  }
);

export const submitPeerChallengeV2 = createAsyncThunk(
  'multiplayer/submitPeerChallenge',
  async (params) => {
    try {
      const res = await fetchWrapper(postSubmitPeerChallenge, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Submit Peer Challenge failed');
    }
  }
);

export const getPeerChallenegeSolution = createAsyncThunk(
  'multiplayer/getPeerChallenegeSolution',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchPeerChallengeSolution, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Fetch Peer Challenge solution failed');
    }
  }
);

export const getPeerChallengeSolutionV2 = createAsyncThunk(
  'multiplayer/getPeerChallenegeSolution',
  async (params) => {
    if (!params.userID) throw new Error('User ID undefined');
    try {
      const res = await fetchWrapper(getPeerChallengeResult, params);
      const currentUserChallengerData = res.Challengers.find(
        (challenger) => challenger.UserId === params.userID
      );
      if (!currentUserChallengerData)
        throw new Error('Current user data not found');
      return {
        ...res,
        UserQuestionSubmissions: currentUserChallengerData.UserSubmissions.map(
          (userQnSubmission) => ({
            ...userQnSubmission,
            SubmissionModel: {
              ...userQnSubmission.Submission,
            },
          })
        ),
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Fetch Peer Challenge solution failed');
    }
  }
);

export const getHasIncomingPeerChallenges = createAsyncThunk(
  'multiplayer/getHasIncomingPeerChallenges',
  async (params) => {
    try {
      const res = await fetchWrapper(getIncomingPeerChallengeHistory, {
        ...params,
        challengeType: 1,
        pageIndex: 0,
        pageSize: 1,
        period: 30,
      });
      return res.TotalCount > 0;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Fetch has incoming peer challenges failed'
      );
    }
  }
);

const initialState = {
  classes: [],
  classesLoading: false,
  classesError: null,
  students: [],
  studentsLoading: false,
  studentsError: null,
  isOpeningChallenge: false,
  peerChallengeOpen: null,
  errorOpeningChallenge: null,
  isLoading: false,
  isErr: null,
  incomingChallenges: [],
  rejectChallengeResponse: null,
  acceptChallengeResponse: false,
  peerChallengeLocalSavedAns: [null],
  peerChallengeLocalSavedWorkings: [null],
  peerChallengeSubmissions: [null],
  multiplayerQnAnswers: [],
  getPeerChallengeHistory: null,
  submitChallenge: {},
  submitSuccess: false,
  peerChallengeQns: [],
  peerChallengeSolution: null,
  solutionLoading: true,
  peerChallengeActiveQn: 1,
  hasIncomingChallenges: false,
};

const multiplayerSlice = createSlice({
  name: 'multiplayer',
  initialState,
  reducers: {
    setActivePeerChallengeActiveQn: (state, action) => {
      state.peerChallengeActiveQn = action.payload;
    },
    savePeerChallengeAnsLocally: (state, action) => {
      const newAnswers = [...state.peerChallengeLocalSavedAns];
      newAnswers[action.payload.index] = action.payload.answers;
      state.peerChallengeLocalSavedAns = newAnswers;
    },
    savePeerChallengeWorkingsLocally: (state, action) => {
      const newWorkings = [...state.peerChallengeLocalSavedWorkings];
      newWorkings[action.payload.index] = action.payload.workings;
      state.peerChallengeLocalSavedWorkings = newWorkings;
    },
    clearSavedPeerChallengeAnswer: (state, action) => {
      const newSavedAnswers = [...state.peerChallengeLocalSavedAns];
      newSavedAnswers[action.payload] = null;
      state.peerChallengeLocalSavedAns = newSavedAnswers;
    },
    resetSavePeerChallengeAns: (state) => {
      state.peerChallengeLocalSavedAns = [];
    },
    resetIncomingChallenge: (state) => {
      state.rejectChallengeResponse = null;
      state.acceptChallengeResponse = false;
    },
    resetSubmitChallenge: (state) => {
      state.submitChallenge = {};
      state.submitSuccess = false;
    },
    resetChallenge: (state) => {
      state.peerChallengeSolution = null;
      state.peerChallengeLocalSavedAns = [null];
      state.peerChallengeOpen = null;
      state.peerChallengeLocalSavedWorkings = [null];
      state.isErr = null;
    },
  },
  extraReducers: {
    [getClassesBySchoolId.pending]: (state) => {
      state.classesLoading = true;
      state.classes = [];
      state.classesError = null;
    },
    [getClassesBySchoolId.fulfilled]: (state, action) => {
      state.classesLoading = false;
      state.classes = action.payload;
      state.classesError = null;
    },
    [getClassesBySchoolId.rejected]: (state, action) => {
      state.classesLoading = false;
      state.classes = [];
      state.classesError = action.error.message;
    },
    [getStudentbyClassId.pending]: (state) => {
      state.studentsLoading = true;
      state.students = [];
      state.studentsError = null;
    },
    [getStudentbyClassId.fulfilled]: (state, action) => {
      state.studentsLoading = false;
      state.students = action.payload;
      state.studentsError = null;
    },
    [getStudentbyClassId.rejected]: (state, action) => {
      state.studentsLoading = false;
      state.students = [];
      state.studentsError = action.error.message;
    },
    [openPeerChallenge.pending]: (state) => {
      state.isOpeningChallenge = true;
      state.peerChallengeOpen = null;
      state.errorOpeningChallenge = null;
    },
    [openPeerChallenge.fulfilled]: (state, action) => {
      state.isOpeningChallenge = false;
      state.peerChallengeOpen = action.payload;
      state.peerChallengeLocalSavedAns = Array(
        action.payload.Questions.length
      ).fill(null);
      state.peerChallengeLocalSavedWorkings = Array(
        action.payload.Questions.length
      ).fill(null);
      state.peerChallengeLocalSavedBarModel = Array(
        action.payload.Questions.length
      ).fill(null);
      state.peerChallengeSubmissions = Array(
        action.payload.Questions.length
      ).fill(null);
      state.errorOpeningChallenge = null;
    },
    [openPeerChallenge.rejected]: (state, action) => {
      state.isOpeningChallenge = false;
      state.errorOpeningChallenge = action.error.message;
    },
    [acceptChallenge.pending]: (state) => {
      state.isLoading = true;
      state.peerChallengeOpen = null;
      state.acceptChallengeResponse = false;
    },
    [acceptChallenge.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.acceptChallengeResponse = true;
      state.peerChallengeOpen = {
        ...action.payload,
        PeerChallengeId: action.meta.arg.peerChallengeId,
      };
      state.peerChallengeLocalSavedAns = Array(
        action.payload.Questions.length
      ).fill(null);
      state.peerChallengeLocalSavedWorkings = Array(
        action.payload.Questions.length
      ).fill(null);
      state.peerChallengeLocalSavedBarModel = Array(
        action.payload.Questions.length
      ).fill(null);
      state.peerChallengeSubmissions = Array(
        action.payload.Questions.length
      ).fill(null);
    },
    [acceptChallenge.rejected]: (state, action) => {
      state.isLoading = false;
      state.acceptChallengeResponse = false;
    },
    [rejectChallenge.pending]: (state) => {
      state.isLoading = true;
      state.rejectChallengeResponse = null;
      state.isErr = null;
    },
    [rejectChallenge.fulfilled]: (state) => {
      state.isLoading = false;
      state.rejectChallengeResponse = 'fulfilled';
    },
    [rejectChallenge.rejected]: (state, action) => {
      state.isLoading = false;
      state.isErr = action.error.message;
      state.rejectChallengeResponse = null;
    },
    [getPeerChallenegHistory.pending]: (state) => {
      state.isLoading = true;
      state.getPeerChallengeHistory = null;
      state.isErr = null;
    },
    [getPeerChallenegHistory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.getPeerChallengeHistory = action.payload;
      state.isErr = null;
    },
    [getPeerChallenegHistory.rejected]: (state, action) => {
      state.isLoading = false;
      state.getPeerChallengeHistory = null;
      state.isErr = action.error.message;
    },
    [submitPeerChallenge.pending]: (state) => {
      state.isLoading = true;
      state.submitChallenge = null;
      state.isErr = null;
    },
    [submitPeerChallenge.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.submitChallenge = action.payload;
      state.submitSuccess = true;
    },
    [submitPeerChallenge.rejected]: (state, action) => {
      state.isLoading = false;
      state.isErr = action.error.message;
    },
    [getPeerChallenegeSolution.pending]: (state) => {
      state.solutionLoading = true;
      state.peerChallengeSolution = null;
      state.multiplayerQnAnswers = [];
      state.isErr = null;
    },
    [getPeerChallenegeSolution.fulfilled]: (state, action) => {
      state.solutionLoading = false;
      const sanitizedUserQuestionSubmissions = parseNullSubmissionmodel(
        action.payload.UserQuestionSubmissions
      );
      state.peerChallengeSolution = {
        ...action.payload,
        UserQuestionSubmissions: sanitizedUserQuestionSubmissions,
      };
      state.multiplayerQnAnswers = sanitizedUserQuestionSubmissions.map(
        (qn) => {
          const filteredXml = qn.SubmissionModel?.Payload.replace(/\\/g, '');
          const parsed = xmlParser(filteredXml);
          let answerKeys = null;
          if (parsed.questionAnswers.length > 0) {
            answerKeys = parsed.questionAnswers.map((answer) => answer.$);
          }
          return answerKeys;
        }
      );
      state.isErr = null;
    },
    [getPeerChallenegeSolution.rejected]: (state, action) => {
      state.solutionLoading = false;
      state.isErr = action.error.message;
    },
    [getHasIncomingPeerChallenges.pending]: (state) => {
      state.hasIncomingChallenges = false;
    },
    [getHasIncomingPeerChallenges.fulfilled]: (state, action) => {
      state.hasIncomingChallenges = action.payload;
    },
  },
});

const { actions, reducer } = multiplayerSlice;
export const {
  setActivePeerChallengeActiveQn,
  savePeerChallengeAnsLocally,
  clearSavedPeerChallengeAnswer,
  savePeerChallengeWorkingsLocally,
  savePeerChallengeBarModelData,
  resetSavePeerChallengeAns,
  resetIncomingChallenge,
  resetSubmitChallenge,
  resetChallenge,
} = actions;
export default reducer;
