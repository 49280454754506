import React from 'react';
import Header from 'components/Header';
import { Page } from 'pages/Dashboard/index.styles';
import { Content } from 'pages/Friends/index.styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import useGlobalFeature from 'hooks/useGlobalFeature';
import StandardReport from './StandardReport';

const Report = () => {
  const { isEnabled } = useGlobalFeature('report');
  return (
    <Page>
      <Header />
      <Content>
        <Switch>
          {!isEnabled && (
            <Redirect
              exact
              from={['/report/highscore', '/report/proficiency']}
              to="/maintenance-redirect"
            />
          )}
          <Route
            exact
            path="/report/highscore"
            render={() => <StandardReport reportType="highscore" />}
          />
          <Route
            exact
            path="/report/proficiency"
            render={() => <StandardReport reportType="proficiency" />}
          />
        </Switch>
      </Content>
    </Page>
  );
};

export default Report;
