// Helper function to parse the solution string in submission model
// Old solution strings are plain strings containing workings
// New solution strings are JSON string which can be parsed into an object containing workings & bar model data
const parseWorkingsBarModel = (solutionString) => {
  const result = {
    workings: null,
    barModel: null,
  };
  if (typeof solutionString !== 'string') {
    return result;
  }
  try {
    const parsedObj = JSON.parse(solutionString);
    return parsedObj;
  } catch (err) {
    result.workings = solutionString;
    return result;
  }
};

export default parseWorkingsBarModel;
