import listeners from 'helpers/eventListenerPrototype';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import findKey from 'lodash/findKey';

const removedListeners = [
  'DOMContentLoaded',
  'DOMMouseScroll',
  'MSGestureHold',
  'appMobi.device.update.available',
  'backbutton',
  'dblclick',
  'gesturehold',
  'menubutton',
  'mousedown',
  'mousemove',
  'mouseup',
  'mousewheel',
  'offline',
  'online',
  'pointercancel',
  'pointerdown',
  'pointermove',
  'pointerup',
  'resize',
  'searchbutton',
  'tizenhwkey',
  'deviceOrientation',
  'deviceMotion',
  'blur',
  'msvisibilitychange',
  'webkitvisibilitychange',
  'mozvisibilitychange',
  'visibilitychange',
];

const removedListenersTargetMap = {
  DOMContentLoaded: document,
  DOMMouseScroll: document,
  MSGestureHold: document,
  'appMobi.device.update.available': document,
  backbutton: document,
  dblclick: document,
  gesturehold: document,
  menubutton: document,
  mousedown: document,
  mousemove: document,
  mouseup: document,
  mousewheel: document,
  offline: Window,
  online: Window,
  pointercancel: document,
  pointerdown: document,
  pointermove: document,
  pointerup: document,
  resize: Window,
  searchbutton: document,
  tizenhwkey: document,
  deviceOrientation: Window,
  deviceMotion: Window,
  blur: Window,
  msvisibilitychange: document,
  webkitvisibilitychange: document,
  mozvisibilitychange: document,
  visibilitychange: document,
};

const loadScript = (id, link, callback) => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = link;
      script.id = id;
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
        resolve();
      };
      script.onerror = () => {
        reject();
      };
    } else {
      if (existingScript && callback) callback();
      resolve();
    }
  });
};

const removeScript = (id) => {
  const existingScript = document.getElementById(id);
  if (existingScript) {
    existingScript.remove();
  }
};

const startGame = () => {
  if (typeof window.cr_setSuspended === 'function') {
    window.cr_setSuspended(false);
  }
};

const pauseGame = () => {
  if (typeof window.cr_setSuspended === 'function') {
    window.cr_setSuspended(true);
  }
};

const removeListeners = () => {
  const removedListenersObject = listeners.filter((listener) =>
    removedListeners.includes(listener.type)
  );

  removedListenersObject.forEach((item) => {
    const index = findKey(listeners, item);
    const { target, type, listener } = item;
    if (target === removedListenersTargetMap[type]) {
      target.removeEventListener(type, listener, true);
      target.removeEventListener(type, listener, false);
      listeners.splice(index, 1);
    }
  });
};

function GameScene({ folder, iframe, skip, feature, externalUrl = false }) {
  const { t } = useTranslation(['brainGames']);
  useEffect(() => {
    if (!iframe) {
      const loadAll = async () => {
        await loadScript(
          'jquery',
          `/brain_games/${folder}/jquery-2.1.1.min.js`
        );
        if (skip && folder === 'kooislandv1') {
          await loadScript('c2', `/brain_games/${folder}/c2runtime_ns.js`);
        } else {
          await loadScript('c2', `/brain_games/${folder}/c2runtime.js`);
        }
        await loadScript('app', `/brain_games/main.js`);
        startGame();
      };
      loadAll();
      return () => {
        removeScript('jquery');
        removeScript('c2');
        removeScript('app');
        removeListeners();
        pauseGame();
      };
    }
  }, [folder, iframe, skip]);
  if (iframe) {
    return (
      <iframe
        title={folder}
        src={
          externalUrl
            ? `${process.env.REACT_APP_BRAIN_GAMES_URL}${folder}/`
            : `/brain_games/${folder}/index.html`
        }
        allow={`autoplay 'src' ${process.env.REACT_APP_BRAIN_GAMES_URL}`}
        style={{
          border: 0,
          width: '100vw',
          height: feature === 'brain-game' ? '85vh' : 'calc(100vh - 59px)',
        }}
        name="preview-frame"
        frameBorder="0"
        noresize="noresize"
        data-view="fullScreenPreview"
      >
        {t(
          'brainGames:gameScene.notSupportIframe',
          "Your browser doesn't support iFrames."
        )}
      </iframe>
    );
  }
  return (
    <div id="c2canvasdiv">
      <canvas id="c2canvas" width="1920" height="1080">
        <h1>
          {t(
            'brainGames:gameScene.browserNotSupport',
            'Your browser does not appear to support HTML5. Try upgrading your browser to the latest version.'
          )}
          <a href="http://www.whatbrowser.org">
            {t('brainGames:gameScene.whatIsBrowser', 'What is a browser?')}
          </a>
          <br />
          <br />
          <a href="http://www.microsoft.com/windows/internet-explorer/default.aspx">
            Microsoft Internet Explorer
          </a>
          <br />
          <a href="http://www.mozilla.com/firefox/">Mozilla Firefox</a>
          <br />
          <a href="http://www.google.com/chrome/">Google Chrome</a>
          <br />
          <a href="http://www.apple.com/safari/download/">Apple Safari</a>
        </h1>
      </canvas>
    </div>
  );
}

GameScene.defaultProps = {
  iframe: false,
};

export default React.memo(GameScene);
