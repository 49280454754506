import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAvatarParts,
  fetchAvatar,
  submitAvatar,
} from 'services/avatarCreator';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import last from 'lodash/last';
import head from 'lodash/head';
import { fetchWrapper } from 'services/login';

const initialState = {
  avatarParts: {},
  fullName: '',
  firstName: '',
  lastName: '',
  body: null,
  bodyColor: null,
  eyes: null,
  horn: null,
  mouth: null,
  isLoadingParts: false,
  isPartLoaded: false,
  errorParts: null,
  isLoadingCreator: false,
  errorCreators: null,
  isSavingAvatar: false,
  errorSavingAvatar: null,
};

export const getAvatarParts = createAsyncThunk(
  'avatarCreator/getAvatarParts',
  async (_, { getState }) => {
    try {
      const { avatarCreator } = getState();
      const { avatarParts, isPartLoaded } = avatarCreator;
      if (isPartLoaded) {
        return avatarParts;
      }
      const res = await fetchWrapper(fetchAvatarParts);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get avatar parts failed');
    }
  }
);

export const initAvatarCreator = createAsyncThunk(
  'avatarCreator/initAvatarCreator',
  async () => {
    try {
      const res = await fetchWrapper(fetchAvatar);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get current avatar failed');
    }
  }
);

export const saveAvatar = createAsyncThunk(
  'avatarCreator/saveAvatar',
  async ({ userId }, { dispatch, getState }) => {
    try {
      const { avatarCreator } = getState();
      const {
        firstName,
        lastName,
        body,
        bodyColor,
        eyes,
        horn,
        mouth,
      } = avatarCreator;
      const res = await fetchWrapper(submitAvatar, {
        body,
        horn,
        eye: eyes,
        mouth,
        userId,
        color: bodyColor,
        name: `${firstName} ${lastName}`,
      });
      dispatch(getStudentDetails());
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get current avatar failed');
    }
  }
);

const onArrowLeft = (objects, current) => {
  const isFirst = current === head(objects).ID;
  if (isFirst) {
    return last(objects).ID;
  }
  const currentIndex = objects.findIndex((b) => b.ID === current);
  return objects[currentIndex - 1].ID;
};

const onArrowRight = (objects, current) => {
  const isLast = current === last(objects).ID;
  if (isLast) {
    return head(objects).ID;
  }
  const currentIndex = objects.findIndex((b) => b.ID === current);
  return objects[currentIndex + 1].ID;
};

const avatarCreatorSlice = createSlice({
  name: 'avatarCreator',
  initialState,
  reducers: {
    setBodyLeft: (state) => {
      const {
        avatarParts: { Bodys },
        body,
      } = state;
      state.body = onArrowLeft(Bodys, body);
    },
    setBodyRight: (state) => {
      const {
        avatarParts: { Bodys },
        body,
      } = state;
      state.body = onArrowRight(Bodys, body);
    },
    setHornLeft: (state) => {
      const {
        avatarParts: { Horns },
        horn,
      } = state;
      state.horn = onArrowLeft(Horns, horn);
    },
    setHornRight: (state) => {
      const {
        avatarParts: { Horns },
        horn,
      } = state;
      state.horn = onArrowRight(Horns, horn);
    },
    setMouthLeft: (state) => {
      const {
        avatarParts: { Mouths },
        mouth,
      } = state;
      state.mouth = onArrowLeft(Mouths, mouth);
    },
    setMouthRight: (state) => {
      const {
        avatarParts: { Mouths },
        mouth,
      } = state;
      state.mouth = onArrowRight(Mouths, mouth);
    },
    setEyesLeft: (state) => {
      const {
        avatarParts: { Eyes },
        eyes,
      } = state;
      state.eyes = onArrowLeft(Eyes, eyes);
    },
    setEyesRight: (state) => {
      const {
        avatarParts: { Eyes },
        eyes,
      } = state;
      state.eyes = onArrowRight(Eyes, eyes);
    },
    setBodyColor: (state, action) => {
      state.bodyColor = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
  },
  extraReducers: {
    [getAvatarParts.pending]: (state) => {
      state.isLoadingParts = true;
      state.errorParts = null;
    },
    [getAvatarParts.fulfilled]: (state, action) => {
      if (action.payload != null) {
        state.avatarParts = action.payload.Result;
        state.isLoadingParts = false;
        state.isPartLoaded = true;
        if (state.body === null) {
          const avatarParts = action.payload.Result;
          state.firstName = avatarParts.Adjectives[0].Name;
          state.lastName = '';
          state.mouth = avatarParts.Mouths[0].ID;
          state.bodyColor = avatarParts.Colours[0].ColorCode;
          state.eyes = avatarParts.Eyes[0].ID;
          state.body = avatarParts.Bodys[0].ID;
          state.horn = avatarParts.Horns[0].ID;
        }
      }
    },
    [getAvatarParts.rejected]: (state, action) => {
      state.isLoadingParts = false;
      state.errorParts = action.error.message;
      if (!alert(action.error.message)) {
        window.location.reload();
      }
    },
    [initAvatarCreator.pending]: (state) => {
      state.isLoadingCreator = true;
      state.errorCreators = null;
    },
    [initAvatarCreator.fulfilled]: (state, action) => {
      if (action.payload != null && action.payload.Result != null) {
        const { Name, Color, Eye, Horn, Mouth, Body } = action.payload.Result;
        const names = Name.split(' ');
        state.firstName = names[0];
        state.lastName = names[1];
        state.mouth = Mouth.ID;
        state.bodyColor = Color;
        state.eyes = Eye.ID;
        state.body = Body.ID;
        state.horn = Horn.ID;
        state.isLoadingCreator = false;
      }
    },
    [initAvatarCreator.rejected]: (state, action) => {
      state.isLoadingCreator = false;
      state.errorCreators = action.error.message;
      if (action.error.message !== 'No Data Found') {
        if (!alert(action.error.message)) {
          window.location.reload();
        }
      }
    },
    [saveAvatar.pending]: (state) => {
      state.isSavingAvatar = true;
      state.errorParts = null;
    },
    [saveAvatar.fulfilled]: (state, action) => {
      if (action.payload != null && action.payload.Result != null) {
        state.isSavingAvatar = false;
        alert('Avatar saved successfully');
      }
    },
    [saveAvatar.rejected]: (state, action) => {
      state.isSavingAvatar = false;
      state.errorParts = action.error.message;
      alert(action.error.message);
    },
  },
});

const { actions, reducer } = avatarCreatorSlice;
export const {
  setBodyLeft,
  setBodyRight,
  setBodyColor,
  setHornLeft,
  setHornRight,
  setEyesLeft,
  setEyesRight,
  setMouthLeft,
  setMouthRight,
  setFirstName,
  setLastName,
} = actions;
export default reducer;
