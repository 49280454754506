import styled from 'styled-components';

const TOGGLE_COLORS = {
  active: {
    bg: '#FDE900',
    color: '#726800',
  },
  inActive: {
    bg: '#DECB2E',
    color: '#9A8B01',
  },
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  div {
    text-align: center;
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    box-shadow: 0 0 0pt 5pt #ffffff;
  }
`;

export const Term = styled.div`
  border-right: 0px solid #ffffff;
  border-radius: 20px;
  border-top-right-radius: ${({ active }) => (active ? '20px' : '0')};
  border-bottom-right-radius: ${({ active }) => (active ? '20px' : '0')};
  z-index: 1;
  background: ${({ active }) =>
    active ? TOGGLE_COLORS.active.bg : TOGGLE_COLORS.inActive.bg};
  color: ${({ active }) =>
    active ? TOGGLE_COLORS.active.color : TOGGLE_COLORS.inActive.color};
`;

export const Classic = styled.div`
  border-left: 0px solid #ffffff;
  border-radius: 20px;
  border-top-left-radius: ${({ active }) => (active ? '20px' : '0')};
  border-bottom-left-radius: ${({ active }) => (active ? '20px' : '0')};
  z-index: ${({ active }) => (active ? '2' : '0')};
  background: ${({ active }) =>
    active ? TOGGLE_COLORS.active.bg : TOGGLE_COLORS.inActive.bg};
  color: ${({ active }) =>
    active ? TOGGLE_COLORS.active.color : TOGGLE_COLORS.inActive.color};
  position: relative;
  right: 20px;
`;
