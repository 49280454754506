import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { PAGE_COUNT } from './constants';
import { periodOptions } from 'constants/history';
import { ReactComponent as CPIcon } from 'assets/img/icon-challenge-point-current-color.svg';
import moment from 'moment';
import ErrorModal from 'components/Shared/ErrorModal';
import Spinner from 'components/Shared/Spinner';
import Pagination from 'components/Shared/Pagination';
import Button from 'components/Shared/Button';
import noDataMiko from 'assets/img/error/noDataMiko.svg';
import {
  StyledContainer,
  StyledTitle,
  StyledTable,
  StyledNoDataWrap,
} from './CPHistoryResult.styles';

const CPHistoryResult = ({ searchAction, selectPeriod, loading }) => {
  const { t } = useTranslation(['cpHistory']);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [searchPage, setSearchPage] = useState(1);
  const { subject } = useSelector((state) => state.plan);
  const { isError, Transactions, TotalEarned } = useSelector(
    (state) => state.cpHistory
  );
  const maxPage = Math.ceil(Transactions.length / PAGE_COUNT);
  const prevDate = moment()
    .subtract(1, periodOptions[selectPeriod].i18nKey)
    .format('DD-MM-YYYY');
  useEffect(() => {
    setPage(0);
    setSearchPage(1);
  }, [selectPeriod]);
  const periodTitle = {
    1: t('period.options.day', 'Today'),
    2: t('period.options.week', 'Past Week'),
    3: t('period.options.month', 'Past Month'),
  };
  const onPageChange = (newPage) => {
    if (page !== newPage - 1 && /[0-9]/.test(newPage)) {
      if (newPage < maxPage) {
        if (newPage >= 0) {
          setPage(newPage - 1);
          setSearchPage(newPage);
        } else {
          setPage(0);
          setSearchPage(1);
        }
      } else {
        setPage(maxPage - 1);
        setSearchPage(maxPage);
      }
    }
  };
  const onSearchPageChange = (newPage) => {
    const newPageToNumber = Number(newPage);
    setSearchPage(newPage);
    if (newPageToNumber > 0) {
      if (newPageToNumber <= maxPage) {
        setPage(newPageToNumber - 1);
      } else {
        setPage(maxPage - 1);
      }
    }
  };
  return (
    <StyledContainer>
      {loading && <Spinner />}
      {!loading && isNil(isError) && Transactions.length > 0 && (
        <>
          <StyledTitle subject={subject}>
            <div className="left">
              <p>{periodTitle[selectPeriod]}</p>
              <span>
                {t('result.title', 'History Period')}:{' '}
                {selectPeriod !== 1 && prevDate}{' '}
                {selectPeriod !== 1 && t('result.to', 'to')}{' '}
                {moment().format('DD-MM-YYYY')}
              </span>
            </div>
            <div className="right">
              <p className="earned">
                {t('result.totalEarned', 'Total earned of this period')}
              </p>
              <CPIcon />
              <span data-cy="total-earned">{TotalEarned}</span>
            </div>
          </StyledTitle>
          <StyledTable>
            <thead>
              <tr>
                <th>{t('result.date', 'Date')}</th>
                <th>{t('result.time', 'Time')}</th>
                <th>{t('result.type', 'Challenge Type')}</th>
                <th className="earned">{t('result.earned', 'Earned')}</th>
              </tr>
            </thead>
            <tbody>
              {Transactions.filter(
                (_, i) => i >= page * PAGE_COUNT && i < (page + 1) * PAGE_COUNT
              ).map((transaction) => (
                <tr
                  key={transaction.TransactionDateTime}
                  data-cy="transaction-datum"
                >
                  <td>
                    {moment(transaction.TransactionDateTime).format(
                      'DD/MM/YYYY'
                    )}
                  </td>
                  <td>
                    {moment(transaction.TransactionDateTime).format('HH:mm')}
                  </td>
                  <td>{t(`event.${transaction.Event}`, transaction.Event)}</td>
                  <td className="earned">
                    {transaction.PointsEarned > 0 ? '+' : null}
                    {transaction.PointsEarned}
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
          <Pagination
            maxPage={maxPage}
            onPageChange={onPageChange}
            onSearchPageChange={onSearchPageChange}
            page={page + 1}
            searchPage={searchPage}
          />
        </>
      )}
      {!loading && !Transactions.length && (
        <StyledNoDataWrap data-cy="no-data">
          <img src={noDataMiko} alt="no data" />
          <div>
            <p>
              {t(
                'result.noData',
                'No KoKo Credits earned or spent in the selected period.'
              )}
            </p>
            <span>
              ({t('result.title', 'History Period')}:{' '}
              {selectPeriod !== 1 && prevDate}{' '}
              {selectPeriod !== 1 && t('result.to', 'to')}{' '}
              {moment().format('DD-MM-YYYY')})
            </span>
            <Button
              variant="primary"
              width="180px"
              style={{ margin: '25px auto 0' }}
              onClick={() => history.push('/challenges')}
            >
              {t('result.button', 'Earn some CPs')}
            </Button>
            <span className="hint">
              {t(
                'result.buttonHint',
                'Click the button to earn some Challenge Points!'
              )}
            </span>
          </div>
        </StyledNoDataWrap>
      )}
      {!loading && isError && (
        <ErrorModal reloadAction={searchAction} errorMessage={isError} />
      )}
    </StyledContainer>
  );
};

export default CPHistoryResult;
