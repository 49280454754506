import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Label, Select, Spacer } from './NamePicker.style';

function NamePicker({
  adjective,
  lastName,
  onChangeAdjective,
  onChangeLastName,
  adjectives,
  lastNames,
}) {
  const { t } = useTranslation(['kokomonster']);
  useEffect(() => {
    if (lastName === '') {
      if (lastNames.length > 0) {
        onChangeLastName({ target: { value: lastNames[0] } });
      }
    }
    if (adjective === '') {
      if (adjectives.length > 0) {
        onChangeAdjective({ target: { value: adjectives[0].Name } });
      }
    }
  }, [
    adjective,
    adjectives,
    lastName,
    lastNames,
    onChangeAdjective,
    onChangeLastName,
  ]);
  return (
    <Container>
      <Label>{t('kokomonster:main.monsterName', 'MONSTER NAME')}</Label>
      <Select value={adjective} onChange={onChangeAdjective}>
        {adjectives.map((a) => (
          <option key={a.ID} value={a.Name}>
            {a.Name}
          </option>
        ))}
      </Select>
      <Spacer />
      <Select onChange={onChangeLastName}>
        <option key="none" value={lastName} selected disabled hidden>
          {lastName}
        </option>
        {lastNames.map((a) => (
          <option key={a} value={a}>
            {a}
          </option>
        ))}
      </Select>
    </Container>
  );
}

export default React.memo(NamePicker);
