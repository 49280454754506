import styled from 'styled-components';

export const Section = styled.div`
  height: 520px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: bottom;

  .main-text {
    font-family: 'Linotte';
    font-weight: 600;
    font-size: 1.25rem;
    text-align: center;
    padding-top: 4rem;
    margin-bottom: -1rem;
    color: ${(props) => (props.darkText ? '#000000' : '#ffffff')};
  }

  .main-text-90 {
    padding-top: 8rem;
    margin-bottom: -6rem;
  }
`;

export const BadgeSection = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BadgeList = styled.div`
  display: flex;
  flex-direction: row;
`;
