import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Unlockable from 'assets/img/hod-calendar/unlockable-by-koko.png';
import Future from 'assets/img/hod-calendar/future-dates.png';
import { isNil } from 'ramda';
import HoDMiniBanner from 'assets/img/hod-calendar/hod-mini-banner.png';
import { Container, DotIndicator } from './DateHolder.styles';

const DateHolder = ({
  day,
  isHidden,
  isUnlockedByCP,
  badgeImg,
  isToday,
  todayCPEarned,
  onClickHandler,
  isUnseen,
}) => {
  const { t } = useTranslation(['dailyChallenge']);
  return (
    <Container
      isHidden={isHidden}
      isFuture={day?.isFuture}
      isToday={isToday}
      isUnlocked={!isNil(badgeImg)}
      onClick={onClickHandler}
      data-cy="day"
    >
      {isToday && isNil(badgeImg) && (
        <>
          <p className="today-title">
            {t('dailyChallenge:heroBadge.today', 'Today')}
          </p>
          <p className="today-cps">
            <Trans i18nKey="dailyChallenge:heroBadge.cps">
              <span>{{ cps: todayCPEarned }}</span>
              {` CPs`}
            </Trans>
          </p>
        </>
      )}
      {!isToday && isNil(badgeImg) && (
        <img
          data-cy={day?.day}
          src={day?.isFuture ? Future : Unlockable}
          height="111px"
          alt=""
        />
      )}
      {!isNil(badgeImg) && isUnseen && <DotIndicator data-cy="dot" />}
      {!isNil(badgeImg) && (
        <img
          data-cy={day?.day}
          src={`data:image/jpeg;base64,${badgeImg}`}
          width="77px"
          alt=""
        />
      )}
      {!isToday && isNil(badgeImg) && (
        <p className="day-number" data-cy={`day-${day?.day}`}>
          {day?.day}
        </p>
      )}
      {!isNil(badgeImg) && isUnlockedByCP && (
        <img
          src={HoDMiniBanner}
          width="77px"
          alt="Hero of the day"
          className="mini-banner"
          data-cy={`banner-${day?.day}`}
        />
      )}
    </Container>
  );
};

export default DateHolder;
