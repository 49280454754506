import React, { useState, useCallback } from 'react';
import { isEmpty } from 'ramda';
import MatchingAnimation from 'components/Multiplayer/MatchingAnimation/index';
import useSound from 'use-sound';
import { useSelector } from 'react-redux';
import { fetchWrapper } from 'services/login';
import { getRandomOpponentsByRoleID } from 'services/search';
import FoundSound from 'assets/audio/selected.mp3';
import { useTranslation } from 'react-i18next';
import StyledButton from './MatchButtonWithAnimation.styles';

const MatchButtonWithAnimation = ({
  randomBotData = {},
  setRandomBot,
  subject,
  setChooseOpponent,
  setNewSelectedOpponentId,
}) => {
  const { t } = useTranslation(['newChallenge']);
  const hasRandomBotData =
    !isEmpty(randomBotData) && randomBotData?.subject === subject;
  const { userID } = useSelector((state) => state.login);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [randomOpponents, setRandomOpponents] = useState([]);
  const [selectedOpponentIndex, setSelectedOpponentIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const soundOptions = {
    interrupt: true,
    playbackRate: 4,
    volume: 0,
  };
  const [play] = useSound(FoundSound, soundOptions);
  const handleMatch = () => {
    setSearchError(null);
    setIsSearching(true);
    // Preload Sound Effect
    play();
    fetchWrapper(getRandomOpponentsByRoleID, {
      roleID: 101,
      pageIndex: 0,
      pageSize: 3,
    })
      .then((data) => {
        if (data.List?.length <= 0) throw new Error('No results found');
        const randomIndex = Math.floor(Math.random() * data.List?.length);
        setSelectedOpponentIndex(randomIndex);
        setRandomOpponents(data.List);
        setShowAnimation(true);
        setIsSearching(false);
      })
      .catch((err) => {
        console.error(err.message);
        setSearchError(err.message);
        setIsSearching(false);
      });
  };
  const handleOnFinishAnimation = useCallback(() => {
    setChooseOpponent('3');
    setNewSelectedOpponentId('3', randomOpponents[selectedOpponentIndex]?.Id);
    setRandomBot({
      userID,
      name: randomOpponents[selectedOpponentIndex]?.FullName,
      subject,
      data: randomOpponents[selectedOpponentIndex],
    });
    setShowAnimation(false);
  }, [
    randomOpponents,
    selectedOpponentIndex,
    setChooseOpponent,
    setNewSelectedOpponentId,
    setRandomBot,
    subject,
    userID,
  ]);
  return (
    <div style={{ textAlign: 'center', height: '100%' }}>
      {showAnimation && (
        <MatchingAnimation
          randomOpponents={randomOpponents}
          selectedOpponentIndex={selectedOpponentIndex}
          onFinishAnimation={handleOnFinishAnimation}
        />
      )}
      {searchError && (
        <p style={{ color: '#ff7121', marginBottom: '6px' }}>{searchError}</p>
      )}
      {!hasRandomBotData && (
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <StyledButton onClick={handleMatch} disabled={isSearching}>
            {t('newChallenge:random.match', 'Match')}
          </StyledButton>
        </div>
      )}
      {hasRandomBotData && (
        <p style={{ fontSize: '14px' }}>{randomBotData.name ?? ''}</p>
      )}
    </div>
  );
};

export default MatchButtonWithAnimation;
