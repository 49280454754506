import styled, { css } from 'styled-components';
import calendarIcon from 'assets/img/icon-calendar-default.svg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${(props) =>
    props.direction === 'column' ? 'baseline' : 'center'};
  flex-direction: column;
  margin-bottom: 1rem;
  width: ${(props) => props.direction === 'column' && '100%'};
  .validation-text {
    color: #ff2f2f;
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: 0px;
    min-width: ${(props) => (props.width ? props.width : '540px')};
    padding-left: ${(props) =>
      props.direction === 'column' ? '0rem' : '13.5rem'};
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  width: ${(props) => (props.width ? props.width : 'inherit')};
  .form-label {
    width: ${(props) => (props.direction === 'column' ? '100%' : '200px')};
    text-align: ${(props) => (props.direction === 'column' ? 'left' : 'right')};
    margin-right: ${(props) => (props.direction === 'column' ? '0' : '1rem')};
    margin-bottom: ${(props) =>
      props.direction === 'column' ? '0.3rem' : '0px'};
    color: #0d0d0d;
    font-weight: 600;
  }
  .form-input {
    width: ${(props) => (props.width ? props.width : '320px')};
    height: 40px;
    border: ${(props) =>
      props.portal === 'parent' && props.validation !== ''
        ? '1px solid #FF6262'
        : '1px solid #d5d5d5'};
    padding: 12px;
    :focus-visible {
      outline: none;
    }
  }
  input[type='date'],
  input[type='time'] {
    position: relative;
    ${({ isIPhoneOrIPad, value }) => {
      if (isIPhoneOrIPad && !value) {
        return css`
          background: white;
          :after {
            position: absolute;
            color: rgb(169, 169, 169);
            content: attr(placeholder);
            top: 9px;
            left: 12px;
          }
          :before {
            position: absolute;
            content: url(${calendarIcon});
            top: 9px;
            right: 12px;
          }
        `;
      }
    }}
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .form-label {
      font-size: 14px;
      font-weight: 500;
    }
    .form-input,
    .form-input::placeholder {
      font-size: 16px;
    }
    input[type='date'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #ffffff;
    }
  }
`;
