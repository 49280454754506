import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Clock from 'assets/img/assignment/assignment-view/icon-clock.svg';
import { history } from 'Routes';
import { useSelector } from 'react-redux';
import {
  saveSMCQuestionSubmissionRequest,
  submitSMCRequest,
} from 'services/event';
import { submissionTypeIDs, questionTypeIDs } from 'constants/index';
import newSaveAnswerHandler from 'helpers/saveAnswerHandler';
import useDashboard from 'hooks/useDashboard';
import { Container, ErrorText } from './TimeUpEventModalBody.styles';

const TimeUpEventModalBody = () => {
  const { t } = useTranslation(['assignmentQnView']);
  const {
    eventSubmissionID,
    isEventSubmitting,
    assignmentEventSubmissionError,
    eventQsAnswers,
    activeQuestionEvent,
    eventQuestions,
    eventLocalSavedAns,
    eventSubmissions,
  } = useSelector((state) => state.event);
  const { lastSavedTime } = useSelector((state) => state.timer);
  const { userID } = useSelector((state) => state.login);
  const { isDashboardv2 } = useDashboard();
  const [timeLeft, setTimeLeft] = useState(10);
  const assignmentType = 'sundayMC';
  const submissionType = submissionTypeIDs.MiniChallenge;
  useEffect(() => {
    const getRawBody = (params) => {
      const { rawBody } = params;
      return rawBody;
    };
    const saveAndFinish = async () => {
      try {
        const now = Date.now();
        const rawBody = newSaveAnswerHandler({
          assignmentSubmissionID: eventSubmissionID,
          assignmentType,
          questionType:
            questionTypeIDs[
              eventQuestions[activeQuestionEvent - 1].QuestionType
            ],
          submissionType,
          questionAnswer: eventQsAnswers[activeQuestionEvent - 1],
          saveAnswerAction: getRawBody,
          checkAnswerAction: getRawBody,
          activeQnNo: activeQuestionEvent,
          currentQn: eventQuestions[activeQuestionEvent - 1],
          currentLocalSavedAnswer: eventLocalSavedAns[activeQuestionEvent - 1],
          currentSubmission:
            eventSubmissions[activeQuestionEvent - 1].SubmissionModel,
          timeElapsed: now - lastSavedTime,
          userID,
        });
        // Save Answer
        await saveSMCQuestionSubmissionRequest(rawBody);
        // Submit Event Assignment
        await submitSMCRequest(eventSubmissionID);
      } catch (err) {
        console.log(err);
      }
    };
    saveAndFinish();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timerID;
    if (timeLeft > 0) {
      timerID = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else {
      history.replace(
        isDashboardv2 ? '/dashboard?view=koochallenge' : '/events'
      );
    }
    return () => {
      clearInterval(timerID);
    };
  }, [isDashboardv2, timeLeft]);

  return (
    <Container>
      <div className="img-section">
        <img src={Clock} alt="icon" />
      </div>
      <div className="text-section">
        <p>{t('assignmentQnView:timesupModal.text1', "Sorry, time's up!")}</p>
        <p className="desc-text">
          {t(
            'assignmentQnView:timesupModal.text2',
            { time: timeLeft },
            `Your assignment will be automatically submitted in ${timeLeft} seconds.`
          )}
        </p>
        {!isEventSubmitting && assignmentEventSubmissionError !== null && (
          <ErrorText>{assignmentEventSubmissionError}</ErrorText>
        )}
      </div>
    </Container>
  );
};

export default TimeUpEventModalBody;
