import styled, { keyframes } from 'styled-components';

const blink = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;

export const QuestionTimer = styled.p`
  text-align: center;
  font-size: 12px;
  color: #000;
  width: 530px;
  height: 40px;
  position: absolute;
  margin-top: -60px;
`;

export const Question = styled.div`
  & > span {
    font-size: 45px;
    color: #383838;
    font-family: Arial, sans-serif;
    vertical-align: middle;
    &.operand {
      margin: 0 0.25em;
    }
  }
  & > .answerContainer {
    text-align: center;
    display: inline-block;
    width: 345px;
    height: 50px;
    background-color: transparent;
    border: 1px solid #afb3b6;
    box-shadow: none;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    outline: 0;
    & > span {
      font-size: 45px;
      color: #383838;
      font-family: Arial, sans-serif;
      line-height: 1.1;
      vertical-align: middle;
    }
    &:focus > .cursor {
      display: inline-block;
      width: 1px;
      border-right: 1px solid #383838;
      height: 80%;
      animation-name: ${blink};
      animation-duration: 1s;
      margin-left: 8px;
      animation-iteration-count: infinite;
    }
  }
`;
