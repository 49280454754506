import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  removeFriend,
  getBFFDetails,
  inviteFriend as inviteFriendAction,
  resetInviteFriendError,
  resetFriendsPageError,
} from 'store/friends/friendsSlice';

import AddFriendIcon from 'assets/img/friends/icon-add-friend.svg';
import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import Spinner from 'components/Shared/Spinner';
import PopupModal from 'components/Shared/PopupModal';
import AddFriendModal from 'components/Friends/AddFriendModal';
import ResultModal from 'components/Shared/ResultModal';
import FriendCard from 'components/Friends/FriendCard';
import ErrorModal from 'components/Shared/ErrorModal';
import { SubmitModal } from 'components/Shared';
import {
  Main,
  AddFriendBtn,
  CPResult,
  RemoveFriendBtn,
} from './MyFriends.styles';

const CPDetails = ({ todayscp, thisweekcp }) => {
  const { t } = useTranslation(['friends']);
  return (
    <CPResult>
      <img src={CPIcon} alt="CPIcon" />
      <div>
        {t('friends:myFriends.today', 'Today')}
        <p>{todayscp}</p>
      </div>
      <div>
        {t('friends:myFriends.thisWeek', 'This Week')}
        <p>{thisweekcp}</p>
      </div>
    </CPResult>
  );
};

const AddFriendComponent = ({ openModal }) => {
  const { t } = useTranslation(['friends']);
  return (
    <AddFriendBtn data-cy="add-friend" onClick={openModal}>
      <img src={AddFriendIcon} alt="Add friend" />
      {t('friends:addFriend.addFriends', 'Add Friends')}
    </AddFriendBtn>
  );
};

const MyFriends = () => {
  const { t } = useTranslation(['friends']);
  // Redux
  const dispatch = useDispatch();
  const {
    BFFDetails,
    isLoading,
    BFFDetailsError,
    myFriends,
    friendInfo,
    removeFriendError,
    inviteFriendLoading,
    inviteFriend,
    inviteFriendError,
  } = useSelector((state) => state.friends);
  // Component states
  const [showModal, setShowModal] = useState(false);
  const [selectedBFFCode, setSelectedBFFCode] = useState(null);
  const [showRemoveFriendModal, setShowRemoveFriendModal] = useState(false);
  // modal content states
  const [friendInvited, setFriendInvited] = useState(false);
  const [inputCode, setInputCode] = useState(true);
  const [bffDetails, setbffDetails] = useState(false);
  const [inputCaptcha, setinputCaptcha] = useState(false);
  // Clean up
  useEffect(() => {
    return () => {
      dispatch(resetFriendsPageError());
    };
  }, [dispatch]);
  // Event Handlers
  const openModal = () => {
    if (inviteFriendError) {
      dispatch(resetInviteFriendError());
      setFriendInvited(false);
      setInputCode(true);
      setbffDetails(false);
      setinputCaptcha(false);
    }
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setInputCode(true);
    setbffDetails(false);
    setinputCaptcha(false);
    setFriendInvited(false);
  };
  const renderModal = () => {
    if (!inviteFriendError) {
      if (friendInvited) {
        return (
          <ResultModal
            type="invite-friend"
            friendInvited={friendInvited}
            loading={inviteFriendLoading}
            data={inviteFriend}
            error={inviteFriendError}
            name={friendInfo.Fullname}
            desc={t(
              'friends:addFriend.inviteSent',
              'Your invitation has been sent to your friend'
            )}
            btnText={t(
              'friends:addFriend.inviteFriend',
              'Invite another friend'
            )}
            onClick={() => {
              setFriendInvited(false);
              setInputCode(true);
            }}
          />
        );
      }
      return (
        <AddFriendModal
          closeModal={closeModal}
          inputCode={inputCode}
          setInputCode={setInputCode}
          bffDetails={bffDetails}
          setbffDetails={setbffDetails}
          inputCaptcha={inputCaptcha}
          setinputCaptcha={setinputCaptcha}
          friendInvited={friendInvited}
          setFriendInvited={setFriendInvited}
        />
      );
    }
  };
  const reloadAction = () => {
    if (BFFDetailsError) {
      dispatch(getBFFDetails());
    }
    if (removeFriendError) {
      dispatch(removeFriend(selectedBFFCode));
    }
    if (inviteFriendError) {
      dispatch(inviteFriendAction(friendInfo.BFFCode));
    }
  };

  return (
    <>
      <Main>
        {isLoading && <Spinner />}
        {!isLoading && BFFDetailsError === null && BFFDetails !== null && (
          <>
            {myFriends.length > 0 && (
              <AddFriendComponent openModal={openModal} />
            )}
            <FriendCard
              isCurrentUser
              friendDetails={{
                avatar: BFFDetails.MyBaseBffModel.UserAvatarImage,
                name: BFFDetails.MyBaseBffModel.Fullname,
                schoolName: BFFDetails.MyBaseBffModel.SchoolName,
                schoolLogo: BFFDetails.MyBaseBffModel.SchoolLogoImage,
                BFFCode: BFFDetails.MyBaseBffModel.BFFCode,
              }}
              rightSection={
                <CPDetails
                  todayscp={BFFDetails.MyBaseBffModel.todayscp}
                  thisweekcp={BFFDetails.MyBaseBffModel.thisweekcp}
                />
              }
            />
            {myFriends.length === 0 && (
              <>
                <div className="add-bff">
                  <h4>
                    {t(
                      'friends:myFriends.startAdd',
                      'Start by adding your BFF!'
                    )}
                  </h4>
                  <div>
                    {t(
                      'friends:myFriends.sendInvite',
                      'Send the invitation to your friend using his/ her BFF code.'
                    )}
                  </div>
                </div>
                <AddFriendComponent openModal={openModal} />
              </>
            )}
            {myFriends.map((friend) => (
              <FriendCard
                key={friend.Userid}
                friendDetails={{
                  avatar: friend.UserAvatarImage,
                  name: friend.Fullname,
                  schoolName: friend.SchoolName,
                  schoolLogo: friend.SchoolLogoImage,
                  BFFCode: friend.BFFCode,
                }}
                rightSection={
                  <>
                    <CPDetails
                      todayscp={friend.todayscp}
                      thisweekcp={friend.thisweekcp}
                    />
                    <RemoveFriendBtn
                      onClick={() => {
                        setSelectedBFFCode(friend.BFFCode);
                        setShowRemoveFriendModal(true);
                      }}
                    >
                      {t('friends:myFriends.remove', 'Remove')}
                    </RemoveFriendBtn>
                  </>
                }
              />
            ))}
          </>
        )}
        {!isLoading &&
          (BFFDetailsError !== null ||
            removeFriendError !== null ||
            inviteFriendError) && (
            <ErrorModal
              errorMessage={
                BFFDetailsError || removeFriendError || inviteFriendError
              }
              reloadAction={reloadAction}
            />
          )}
      </Main>
      <PopupModal show={showModal && !inviteFriendError} hide={closeModal}>
        {renderModal()}
      </PopupModal>
      <PopupModal show={showRemoveFriendModal} backdrop="static">
        <SubmitModal
          header={t('friends:myFriends.removeFriend', 'Remove Friend')}
          title={t(
            'friends:myFriends.removeConfirmation',
            'Are you sure you want to remove this friend?'
          )}
          desc=""
          noHandle={() => setShowRemoveFriendModal(false)}
          yesHandle={() => {
            dispatch(removeFriend(selectedBFFCode));
            setShowRemoveFriendModal(false);
          }}
        />
      </PopupModal>
    </>
  );
};

export default MyFriends;
