import MathBg from 'assets/img/math/math-bg.svg';
import MathExpired from 'assets/img/math/math-expired.svg';
import ScienceBg from 'assets/img/science/science-trial-background.png';
import ScienceExpired from 'assets/img/science/science-dashboard-expired-ui.svg';

export const trialContentConfig = {
  math: {
    title: 'Save Hours Struggling with Primary School Maths',
    desc:
      'Learn 4x faster with our digital questions and animated tutorials in KooBits Math Premium. Go beyond the school version. No credit card needed.',
    videoUrl: 'https://www.youtube.com/embed/DZzaHWHl9u8',
    btnText: 'Boost Your Math Today with a 7-day FREE Trial',
    bg: MathBg,
    translationKey: 'mathTrial',
  },
  science: {
    title: 'Boost Your Science with KooBits 7-day FREE trial',
    desc:
      'Get unlimited access to over 10,000 P1-6 digital MCQ and OEQ questions that are auto-marked with instant feedback. No credit card needed.',
    videoUrl: 'https://www.youtube.com/embed/2hxOzBuy9ag',
    btnText: 'Boost Your Science Today with a 7-day FREE Trial',
    bg: ScienceBg,
    translationKey: 'scienceTrial',
  },
};

export const trialExpiredConfig = {
  math: {
    title: 'Your Math Premium trial has ended',
    desc:
      'To continue using it, click the button below to upgrade to Math Premium.',
    bg: MathBg,
    img: MathExpired,
    translationKey: 'mathTrial',
  },
  science: {
    title: 'Your KooBits Science Trial period has ended.',
    desc:
      'Subscribe to our Premium KooBits Science account and continue all our fun learning',
    bg: ScienceBg,
    img: ScienceExpired,
    translationKey: 'scienceTrial',
  },
};

export const trialFormConfig = {
  math: {
    title: 'Pass this form to your parents',
    desc: 'Parent’s consent needed to try Math Premium for 7 days',
    term1: 'I agree to activate Math Premium 7-day trial for my child.',
    term2: 'I understand that I can only try Math Premium once.',
    otpDesc: 'Enter the One-Time Password sent to your phone',
    translationKey: 'mathTrial',
    successText:
      'Your Math Premium 7-day trial has been activated successfully!',
  },
  science: {
    title: 'Parents Consent to Activate KooBits Science 7-day Trial',
    desc: 'Please pass this section to your parents',
    term1:
      'I agree to activate the KooBits Science trial for my child.I agree to activate Math Premium 7-day trial for my child.',
    term2: 'I understand that I can only try Science once.',
    otpDesc: 'Enter the OTP sent to your phone',
    translationKey: 'scienceTrial',
    successText: 'Your KooBits Science trial has been activated successfully!',
  },
};
