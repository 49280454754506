import styled from 'styled-components';

export const Container = styled.div`
  background-color: #d9d5a6;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 20px;
  height: 20px;
`;

export const Bar = styled.div`
  width: ${(props) => props.width}%;
  transition: all 200ms ease;
  background: #ff6c00;
  height: 100%;
  border-radius: 20px;
`;
