import React from 'react';
import { Container, TopicItem, TopicRow } from './TopicList.styles';

const TopicList = ({ data, selectedTopic, changeTopic, variant, page }) => {
  return (
    <Container variant={variant} page={page} data-cy="topic-list">
      {data.map((topic) => (
        <TopicItem
          className={`topic--${topic.TopicId}`}
          key={topic.TopicId}
          active={topic.TopicId === selectedTopic?.TopicId}
          onClick={() => changeTopic(topic.TopicId)}
          variant={variant}
          page={page}
        >
          <TopicRow>
            <span>{topic.TopicName}</span>
            {topic && topic.totalQuestionNumber > 0 && (
              <span style={{ color: '#fe3720' }}>
                {topic.totalQuestionNumber}
              </span>
            )}
          </TopicRow>
        </TopicItem>
      ))}
    </Container>
  );
};

export default TopicList;
