/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BackButton from 'components/Shared/BackButton';
import EmptyIncomingChallenge from 'assets/img/multiplayer/empty-incoming-challenge.svg';
import IncomingChallengeCard from 'components/Multiplayer/IncomingChallengeCard';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import {
  resetIncomingChallenge,
  acceptChallengeV2,
} from 'store/multiplayer/multiplayerSlice';
import { isEmpty, isNil } from 'ramda';
import PopupModal from 'components/Shared/PopupModal';
import { SubmitModal } from 'components/Shared';
import { useQuery, getTotalAttempts } from 'pages/Multiplayer/NewChallenge';
import { multiplayerListStyles } from 'constants/multiplayer';
import { subjectNames } from 'constants/products';
import useDashboard from 'hooks/useDashboard';
import Pagination from 'components/Shared/Pagination';
import { fetchWrapper } from 'services/login';
import {
  getIncomingPeerChallengeHistory,
  getPCStatus,
  putAcceptRejectPeerChallenge,
} from 'services/multiplayerv2';
import { getUserInfos } from 'store/lookup/lookupSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import usePeerChallengeAttempts from 'hooks/usePeerChallengeAttempts';
import NoCardLeftModal from 'components/Multiplayer/NoCardLeftModal';
import {
  Topbar,
  EnergyCard,
  Content,
  ChallengesContainer,
  ChallengeList,
  EmptyChallengeContainer,
} from './IncomingChallenges.styles';

const IncomingChallenges = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const subjectParam = query.get('subject');
  const lowercaseSubjectParam = isNil(subjectParam)
    ? 'math'
    : subjectParam.toLowerCase().trim();
  const subjectID = subjectNames[lowercaseSubjectParam];
  const { t } = useTranslation(['incomingChallenge']);
  const { userID } = useSelector((state) => state.login);

  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const { remainingAttempts } = usePeerChallengeAttempts(userID, subjectID);
  const [incomingChallenges, setIncomingChallenges] = useState({
    status: 'idle',
    List: [],
    HasPreviousPage: false,
    HasNextPage: false,
    TotalCount: 0,
    PageIndex: 0,
    PageSize: 0,
    TotalPages: 0,
    error: null,
  });
  const maxPage = incomingChallenges.TotalPages ?? 0;
  const [noDataFound, setNoDataFound] = useState(true);
  const [showNoAttemptsModal, setShowNoAttemptsModal] = useState(false);
  const { isLoading: isProductsLoading, products } = useSelector(
    (state) => state.plan
  );
  const { isDashboardv2 } = useDashboard();
  const isLoading =
    incomingChallenges.status === 'pending' || isProductsLoading;
  const showEmptyComponent = !isLoading && noDataFound;
  // Component States
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [showErrorAccessModal, setShowErrorAccessModal] = useState(false);
  const [selectedPCId, setSelectedPCId] = useState(null);
  const [updatePeerChallenge, setUpdatePeerChallenge] = useState({
    status: 'idle',
    data: null,
    error: null,
  });
  const [isAccepting, setIsAccepting] = useState(false);

  const closeNoAttemptsModal = () => {
    setShowNoAttemptsModal(false);
  };

  const totalEnergyPerDay = getTotalAttempts(products, lowercaseSubjectParam);
  const energyRemaining = remainingAttempts.data ?? 0;
  const energy = Array(totalEnergyPerDay)
    .fill('')
    .map((obj, i) => {
      if (i + 1 <= energyRemaining) return 'full';
      return 'empty';
    });

  const checkPCStatus = useCallback(async () => {
    try {
      const enablePCStatus = await fetchWrapper(getPCStatus, {
        userID,
        subjectID,
      });
      return enablePCStatus;
    } catch (e) {
      return false;
    }
  }, [subjectID, userID]);

  const fetchIncomingChallenges = useCallback(
    async (pageNumber) => {
      if (isNil(userID)) return;
      const pageIndex = pageNumber - 1;
      try {
        setIncomingChallenges((prevState) => ({
          ...prevState,
          status: 'pending',
        }));
        const data = await fetchWrapper(getIncomingPeerChallengeHistory, {
          userID,
          subjectID,
          challengeType: 1,
          pageIndex,
          pageSize: 6,
          period: 0,
        });
        if (isEmpty(data) && pageIndex === 0) {
          setIncomingChallenges({
            status: 'fulfilled',
            List: [],
            HasPreviousPage: false,
            HasNextPage: false,
            TotalCount: 0,
            PageIndex: 0,
            PageSize: 0,
            TotalPages: 0,
            error: null,
          });
          setNoDataFound(true);
          return;
        }
        const userIds = [userID];
        data.List.forEach((challenge) => {
          const opponent = challenge.Challengers.find(
            (challenger) => challenger.UserId !== userID
          );
          if (!opponent) return;
          userIds.push(opponent.UserId);
        });

        const uniqueOpponentIds = [...new Set(userIds)];
        // // Fetch user info
        const usersInfoAction = await dispatch(
          getUserInfos({
            userIDs: uniqueOpponentIds,
            pageIndex: 0,
            pageSize: uniqueOpponentIds.length,
            cache: true,
          })
        );
        unwrapResult(usersInfoAction);
        setIncomingChallenges({
          ...data,
          status: 'fulfilled',
          error: null,
        });
        setNoDataFound(false);
      } catch (err) {
        setIncomingChallenges((prevState) => ({
          ...prevState,
          status: 'rejected',
          List: [],
          HasPreviousPage: pageIndex > 0,
          HasNextPage: pageNumber < prevState.TotalPages,
          PageIndex: pageIndex,
          error: err.message,
        }));
      }
    },
    [dispatch, subjectID, userID]
  );

  // UseEffects
  useEffect(() => {
    fetchIncomingChallenges(page);
  }, [fetchIncomingChallenges, page]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      const intPageNumber = parseInt(searchPage, 10);
      if (!intPageNumber || intPageNumber <= 0) return;
      setPage(intPageNumber);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [searchPage]);

  // Event Handlers
  const acceptChallengerHandler = (
    peerChallengeId,
    userID,
    challengerUserId
  ) => {
    return async () => {
      setIsAccepting(true);
      if (updatePeerChallenge.status === 'pending' || !userID) return;
      if (energyRemaining <= 0) {
        setShowNoAttemptsModal(true);
        return;
      }
      const enablePCStatus = await checkPCStatus();
      if (!enablePCStatus) {
        setShowErrorAccessModal(true);
        return;
      }
      setUpdatePeerChallenge({
        status: 'pending',
        data: null,
        error: null,
      });
      const reqBody = { UserId: userID, PeerChallengeStatus: 3 };
      // Accept Challenge
      try {
        const acceptChallengeAction = await dispatch(
          acceptChallengeV2({ peerChallengeId, reqBody })
        );
        unwrapResult(acceptChallengeAction);
        history.push(
          `/multiplayer/peer-challenge/question-view?id=${peerChallengeId}&type=accept&subject=${lowercaseSubjectParam}`,
          { animation: [{ UserId: userID }, { UserId: challengerUserId }] }
        );
        dispatch(resetIncomingChallenge());
      } catch (err) {
        setIsAccepting(false);
        setUpdatePeerChallenge({
          status: 'rejected',
          data: null,
          error: err.message,
        });
      }
    };
  };
  const rejectChallengerHandler = async () => {
    if (updatePeerChallenge.status === 'pending') return;
    const enablePCStatus = await checkPCStatus();
    if (!enablePCStatus) {
      setShowErrorAccessModal(true);
      return;
    }
    setUpdatePeerChallenge({
      status: 'pending',
      data: null,
      error: null,
    });
    const reqBody = { UserId: userID, PeerChallengeStatus: 4 };
    setConfirmationModal(false);
    // Reject Challenge
    try {
      const res = await fetchWrapper(putAcceptRejectPeerChallenge, {
        peerChallengeId: selectedPCId,
        reqBody,
      });
      const hasPCId = incomingChallenges.List.find(
        (challenge) => challenge.Id === selectedPCId
      );
      if (hasPCId) {
        const updatedChallengeList = incomingChallenges.List.filter(
          (challenge) => challenge.Id !== selectedPCId
        );
        if (updatedChallengeList.length <= 0) {
          if (page === 1) {
            fetchIncomingChallenges(1);
          } else {
            setPage((prevState) => {
              const prevPageNumber = prevState - 1;
              setSearchPage(prevPageNumber);
              return prevPageNumber;
            });
          }
        } else {
          setIncomingChallenges((prevState) => {
            const newTotalCount = prevState.TotalCount - 1;
            const newTotalPages = Math.ceil(newTotalCount / prevState.PageSize);
            return {
              ...prevState,
              List: updatedChallengeList,
              TotalCount: newTotalCount,
              TotalPages: newTotalPages,
            };
          });
        }
        setUpdatePeerChallenge({
          status: 'fulfilled',
          data: res,
          error: null,
        });
      }
    } catch (err) {
      setUpdatePeerChallenge({
        status: 'rejected',
        data: null,
        error: err.message,
      });
    }
  };

  const handleConfirmation = () => {
    setConfirmationModal(true);
  };

  const reloadIncoming = () => {
    fetchIncomingChallenges(page);
  };

  const onPageChange = (number) => {
    setPage(number);
    setSearchPage(number);
  };
  const onSearchPageChange = (pageNumber) => {
    if (pageNumber <= maxPage) {
      setSearchPage(pageNumber);
    } else {
      setSearchPage(page);
    }
  };
  const closeModal = () => {
    setConfirmationModal(false);
  };

  const closeHandler = () => {
    setShowErrorAccessModal(false);
  };

  if (isNil(subjectParam))
    return (
      <Redirect
        to={isDashboardv2 ? '/dashboard?view=koochallenge' : '/multiplayer'}
      />
    );
  if (isNil(subjectNames[lowercaseSubjectParam]))
    return (
      <Redirect
        to={isDashboardv2 ? '/dashboard?view=koochallenge' : '/multiplayer'}
      />
    );
  return (
    <>
      {incomingChallenges.status === 'rejected' && (
        <ErrorModal
          reloadAction={reloadIncoming}
          errorMessage={incomingChallenges.error}
        />
      )}
      {updatePeerChallenge.status === 'rejected' && (
        <ErrorModal errorMessage={updatePeerChallenge.error} />
      )}
      <PopupModal show={showNoAttemptsModal} hide={closeNoAttemptsModal}>
        <NoCardLeftModal
          setShowModal={setShowNoAttemptsModal}
          totalEnergyPerDay={totalEnergyPerDay}
        />
      </PopupModal>
      <Topbar
        background={
          multiplayerListStyles[lowercaseSubjectParam].card.background
        }
      >
        <span className="side left">
          <BackButton
            btnBgColor={
              multiplayerListStyles[lowercaseSubjectParam].backBtnBgColour
            }
            backText={t('incomingChallenge:header.back', 'Back')}
            onClick={() => history.goBack()}
            isResponsive
          />
        </span>

        <span className="middle">
          <img
            src={multiplayerListStyles[lowercaseSubjectParam].incomingV2Icon}
            alt="incoming-challenge-icon"
            className="incoming-challenge-icon"
          />
          <span className="title">
            {t(
              'incomingChallenge:header.incomingChallenge',
              'Incoming Challenge'
            )}
          </span>
        </span>
        <span className="energy-cards side right">
          {energy.map((item, index) => (
            <EnergyCard
              key={`${item}-${index}`}
              index={index}
              src={
                item === 'full'
                  ? multiplayerListStyles[lowercaseSubjectParam].energyIcon
                      .fullShadow
                  : multiplayerListStyles[lowercaseSubjectParam].energyIcon
                      .emptyShadow
              }
              alt="energy"
            />
          ))}
        </span>
      </Topbar>
      <Content>
        <PopupModal show={showConfirmationModal} backdrop="static">
          <SubmitModal
            type={t('incomingChallenge:modal.warning', 'Warning!')}
            header={t(
              'incomingChallenge:modal.rejectChallenge',
              'Reject Challenge'
            )}
            title={t(
              'incomingChallenge:modal.areYouSure',
              'Are you sure you want to reject this challenge?'
            )}
            desc=""
            noHandle={closeModal}
            yesHandle={rejectChallengerHandler}
          />
        </PopupModal>
        {!showEmptyComponent && (
          <ChallengesContainer data-cy="incoming-list">
            {!isLoading && !noDataFound && (
              <div className="data-list">
                <p>
                  {t(
                    'incomingChallenge:main.youHaveNew',
                    'You have new incoming challenge(s) from'
                  )}
                </p>
                <ChallengeList data-cy="incoming-data">
                  {incomingChallenges &&
                    incomingChallenges.List.map((challenge) => (
                      <IncomingChallengeCard
                        key={challenge.Id}
                        challenge={challenge}
                        showConfirmation={handleConfirmation}
                        setSelectedPCId={setSelectedPCId}
                        onAccept={acceptChallengerHandler}
                        isLoading={isAccepting}
                      />
                    ))}
                </ChallengeList>
              </div>
            )}
            {isLoading && (
              <div style={{ position: 'absolute', left: '50%', top: '30%' }}>
                <Spinner animation="border" variant="warning" />
              </div>
            )}
            {maxPage > 0 && (
              <Pagination
                maxPage={incomingChallenges.TotalPages}
                onPageChange={onPageChange}
                onSearchPageChange={onSearchPageChange}
                page={page}
                searchPage={searchPage}
              />
            )}
          </ChallengesContainer>
        )}
        {showErrorAccessModal && (
          <ErrorModal
            closeHandler={closeHandler}
            backPage={
              isDashboardv2 ? '/dashboard?view=koochallenge' : '/dashboard'
            }
            errorMessage={t(
              'newChallenge:modal.notAvailable',
              'Peer Challenge is not available during this time'
            )}
          />
        )}
        {showEmptyComponent && (
          <EmptyChallengeContainer data-cy="empty-container">
            <img
              src={EmptyIncomingChallenge}
              alt="No challenges"
              width="297.86px"
              height="216.5px"
            />
            <h1 style={{ marginTop: '-2rem' }}>
              {t(
                'incomingChallenge:emptyState.noChallenger',
                'You have no challenger yet.'
              )}
            </h1>
          </EmptyChallengeContainer>
        )}
      </Content>
    </>
  );
};

export default IncomingChallenges;
