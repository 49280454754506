import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';
import camelcaseJsonKeys from 'helpers/camelcaseJsonKeys';

const baseUrl = `${process.env.REACT_APP_LEADERBOARD_API_DOMAIN}`;

// get seasons
export const fetchSeasons = () => {
  return fetch(`${baseUrl}Leaderboard/BvGLeaderboard/GetSeasons/-1`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        const parsedResult = result.Result.map((season) =>
          camelcaseJsonKeys(season)
        );
        return parsedResult;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get all seasons failed'); // eslint-disable-line
    });
};

// update gender
export const postGender = (genderId) => {
  return fetch(
    `${baseUrl}Leaderboard/BvGLeaderboard/UpdateGender/${genderId}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update gender failed'); // eslint-disable-line
    });
};

// get total scores
export const fetchTotalScores = ({ id }) => {
  return fetch(`${baseUrl}Leaderboard/BvGLeaderboard/GetTotalScores/${id}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        const parsedResult = camelcaseJsonKeys(result.Result);
        return parsedResult;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get total scores failed'); // eslint-disable-line
    });
};

// get current user score
export const fetchUserCurrentScore = ({ id }) => {
  return fetch(
    `${baseUrl}Leaderboard/BvGLeaderboard/GetCurrentUserScore/${id}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        const parsedResult = camelcaseJsonKeys(result.Result);
        return parsedResult;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get user current score failed'); // eslint-disable-line
    });
};

// get latest submitted score
export const fetchLatestScore = () => {
  return fetch(`${baseUrl}Leaderboard/BvGLeaderboard/GetLatestScore`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        const parsedResult = {
          boysLatestCPSubmitted: result.Result.BoysLatestCPSubmitted.map(
            (student) => camelcaseJsonKeys(student)
          ),
          girlsLatestCPSubmitted: result.Result.GirlsLatestCPSubmitted.map(
            (student) => camelcaseJsonKeys(student)
          ),
        };
        return parsedResult;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get latest score failed'); // eslint-disable-line
    });
};

// get global top 100 score
export const fetchGlobalTop100Score = ({ id }) => {
  return fetch(
    `${baseUrl}Leaderboard/BvGLeaderboard/GetGlobalTop100Score/${id}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        const parsedResult = {
          boysGlobalTop100List: result.Result.BoysGlobalTop100List.map(
            (student) => camelcaseJsonKeys(student)
          ),
          girlsGlobalTop100List: result.Result.GirlsGlobalTop100List.map(
            (student) => camelcaseJsonKeys(student)
          ),
        };
        return parsedResult;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get global top 100 score failed'); // eslint-disable-line
    });
};
