import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './NoParticipants.styles';

const NoParticipants = ({ width }) => {
  const { t } = useTranslation(['leaderboard']);

  return (
    <Container width={width}>
      {t(
        'leaderboard:hodBanner.noParticipant',
        'No participants at the moment.'
      )}
    </Container>
  );
};

export default NoParticipants;
