import React from 'react';
import { useTranslation } from 'react-i18next';
import InputBFFCode from './ModalContents/InputBFFCode';
import BFFDetails from './ModalContents/BFFDetails';
import Captcha from './ModalContents/Captcha';
import IconAdd from 'assets/img/friends/icon-add-friend.svg';
import { Container } from './AddFriendModal.styles';

const AddFriendModal = ({
  closeModal,
  inputCode,
  setInputCode,
  bffDetails,
  setbffDetails,
  inputCaptcha,
  setinputCaptcha,
  setFriendInvited,
}) => {
  const { t } = useTranslation(['friends']);
  // get random number for captcha
  let num1 = Math.floor(Math.random() * 100);
  let num2 = Math.floor(Math.random() * 100);
  return (
    <Container>
      <div className="header">
        <div className="title">
          <img src={IconAdd} alt="add" />
          <p>{t('friends:addFriend.addNewFriend', 'Add New Friend')}</p>
        </div>
        <div className="close-btn" onClick={closeModal}>
          X
        </div>
      </div>
      {inputCode && (
        <InputBFFCode
          closeModal={closeModal}
          setInputCode={setInputCode}
          setbffDetails={setbffDetails}
        />
      )}
      {bffDetails && (
        <BFFDetails
          setInputCode={setInputCode}
          setbffDetails={setbffDetails}
          setinputCaptcha={setinputCaptcha}
        />
      )}
      {inputCaptcha && (
        <Captcha
          setbffDetails={setbffDetails}
          setinputCaptcha={setinputCaptcha}
          setInputCode={setInputCode}
          setFriendInvited={setFriendInvited}
          num1={num1}
          num2={num2}
        />
      )}
    </Container>
  );
};

export default AddFriendModal;
