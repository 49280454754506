import styled from 'styled-components';
import MenuIcon from 'assets/img/navigation/9dots.svg';
import MenuActiveIcon from 'assets/img/navigation/9dots-active.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 42px;
  justify-content: center;
  align-items: center;
  background: ${({ open }) => open && '#343434'};
  cursor: pointer;
  &:hover {
    background-color: #343434;
  }
  .bg {
    background: ${({ hover, open }) =>
      hover || open ? `url(${MenuActiveIcon})` : `url(${MenuIcon})`};
    width: 30px;
    background-repeat: no-repeat;
    height: 28px;
    background-size: contain;
    &:hover {
      background: url(${MenuActiveIcon});
      width: 30px;
      background-repeat: no-repeat;
      height: 28px;
      background-size: contain;
    }
  }
`;

export default Container;
