import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import Header from 'components/Assignment/AssignmentView/Header';
import HomeworkContent from 'components/Assignment/AssignmentFinish/HomeworkContent';
import ErrorModal from 'components/Shared/ErrorModal';
import { Container, Main } from './KooClassFinish.styles';

const KooClassFinish = () => {
  const { activityId } = useParams();
  const {
    kooClassSubmissionID,
    isKooClassSaving,
    isKooClassFinishing,
    kooClassSubmissions,
    kooClassQnTitle,
    isErr,
  } = useSelector((state) => state.kooClass);
  const { currentProduct } = useSelector((state) => state.plan);

  return (
    <Container>
      <Header
        page="finish"
        type="KooClass"
        assignmentTitle={kooClassQnTitle}
        isLoading={isKooClassSaving || isKooClassFinishing}
        duration={null}
        showDuration
      />
      <Main>
        {kooClassSubmissions && (
          <>
            <div className="wrapper">
              <div className="summary-box">
                <HomeworkContent
                  data={kooClassSubmissions}
                  totalQns={kooClassSubmissions.length}
                  duration={null}
                  assignmentId={activityId}
                  submissionId={kooClassSubmissionID}
                  feature="KooClass"
                  subjectId={currentProduct?.subjectId}
                />
              </div>
            </div>
          </>
        )}
        {!isNil(isErr) && <ErrorModal errorMessage={isErr} />}
      </Main>
    </Container>
  );
};

export default KooClassFinish;
