import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_LEADERBOARD_API_DOMAIN;

// get seasons
export const fetchSeasons = () => {
  return fetch(`${baseUrl}Leaderboard/LionCity/GetSeasons`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get all weeks failed'); // eslint-disable-line
    });
};

// get all occupations
export const fetchOccupations = ({ SeasonId }) => {
  return fetch(`${baseUrl}Leaderboard/LionCity/GetOccupations/${SeasonId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get all occupations failed'); // eslint-disable-line
    });
};

// get students
export const fetchStudents = ({ OccupationId, SeasonId }) => {
  return fetch(
    `${baseUrl}Leaderboard/LionCity/GetStudentsList/${OccupationId}/${SeasonId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get students by season and occupation failed'
      );
    });
};

const studentServicesBaseUrl = process.env.REACT_APP_API_DOMAIN;
export const updateOccupation = ({ occupationId }) => {
  return fetch(
    `${studentServicesBaseUrl}Profile/UpdateOccupation/${occupationId}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update occupation failed'); // eslint-disable-line
    });
};

// get current user lion city
export const fetchCurrentUserLionCity = ({ SeasonId }) => {
  return fetch(
    `${baseUrl}Leaderboard/LionCity/GetCurrentUserLionCity/${SeasonId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get current user lion city failed'); // eslint-disable-line
    });
};
