import styled from 'styled-components';

export const PrintAssignmentPage = styled.div`
  padding: 0 1rem;

  .top {
    display: flex;
    width: 100%;
    padding: 0 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: flex-end;
  }

  .question-item-body,
  .media-body {
    border-right: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 1rem;
  }

  .questions {
    margin: 0 1rem 0 1rem;
  }

  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    html,
    body {
      height: initial !important;
      overflow: visible !important;
      -webkit-print-color-adjust: exact;
    }
    .print-btn {
      display: none;
    }
  }

  @media print {
    font-size: 20px !important;
    .page-break {
      margin-bottom: 10mm;
      display: block;
      page-break-inside: auto;
    }
    .question-item-body {
      margin-top: 10mm;
      page-break-inside: avoid;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  margin: 0rem 15rem;
  padding: 0rem 6rem;
  @media (max-width: 925px) {
    margin: 0rem 3rem;
    padding: 0rem 6rem;
  }
  @media (max-width: 550px) {
    padding: 0rem 3rem;
  }
  p {
    margin-bottom: 0rem;
    color: #212121;
    font-size: 20px;
    font-weight: 600;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  border: 1px solid #a8a8a8;
  padding: 1rem 0rem 0rem 2rem;
  margin: 1rem 15rem;
  @media (max-width: 1366px) {
    margin: 1rem 10rem;
  }
  @media (max-width: 1024px) {
    margin: 1rem 6rem;
  }
  @media (max-width: 925px) {
    margin: 1rem 3rem;
  }
`;
