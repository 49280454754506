import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// get preferences
export const fetchUserPreferences = () => {
  return fetch(`${baseUrl}UserPreferences/GetUserPreferences`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get user preferences failed'); // eslint-disable-line
    });
};

// update preferences
export const postUserPreferences = (value) => {
  return fetch(
    `${baseUrl}UserPreferences/UpdateUserPreferences?preferenceId=1&value=${value}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update user preferences failed'); // eslint-disable-line
    });
};
