import styled, { css } from 'styled-components';

export const Container = styled.div`
  font-family: 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  .solution {
    margin-bottom: 0rem;
    color: #11bef0;
    font-weight: 600;
    font-family: Linotte;
  }
  span {
    color: #777777;
    font-size: 13px;
    font-weight: 300;
  }
  ${({ voiceOverFileExist }) =>
    voiceOverFileExist &&
    css`
      margin-left: 2rem;
      @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
        margin-left: 0;
      }
    `}
`;
