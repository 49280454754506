import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Shared/Button';
import ButtonsGroup from './ButtonsGroup';
import { Container } from './Captcha.styles';

import { useDispatch, useSelector } from 'react-redux';
import { inviteFriend } from 'store/friends/friendsSlice';

const Captcha = ({
  setbffDetails,
  setinputCaptcha,
  setInputCode,
  setFriendInvited,
  num1,
  num2,
}) => {
  const { t } = useTranslation(['friends']);
  const dispatch = useDispatch();
  const { friendInfo } = useSelector((state) => state.friends);
  const [captchaAlert, setCaptchaAlert] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const backHandler = () => {
    setinputCaptcha(false);
    setbffDetails(true);
  };

  // captcha
  const captchaSum = num1 + num2;

  const inviteHandler = () => {
    if (Number(captchaInput) === captchaSum) {
      dispatch(inviteFriend(friendInfo.BFFCode));
      setFriendInvited(true);
      setinputCaptcha(false);
    } else {
      setCaptchaAlert(
        t(
          'friends:addFriend.incorrectAns',
          'Incorrect answer, please try again.'
        )
      );
    }
  };

  return (
    <Container>
      <div className="captcha">
        <p data-ans={captchaSum} className="captcha-qn" id="captcha">
          {num1} + {num2} = ?
        </p>
        <div className="captcha-answer">
          <p>
            {t(
              'friends:addFriend.enterAns',
              'Enter the answer to the above question'
            )}
          </p>
          <input
            className="captcha-input"
            value={captchaInput}
            onChange={(e) => setCaptchaInput(e.target.value)}
          />
        </div>
        <p className="validation-text">{captchaAlert}</p>
      </div>
      {captchaAlert === '' ? (
        <ButtonsGroup
          cancelText={t('friends:addFriend.back', 'Back')}
          nextText={t('friends:addFriend.invite', 'Invite')}
          cancelFunction={backHandler}
          nextFunction={inviteHandler}
        />
      ) : (
        <Button
          variant="primary"
          onClick={() => {
            setinputCaptcha(false);
            setInputCode(true);
          }}
        >
          {t('friends:addFriend.inviteFriend', 'Invite another friend')}
        </Button>
      )}
    </Container>
  );
};

export default Captcha;
