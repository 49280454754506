import { isNil } from 'ramda';

const simplifiedChineseLocaleNames = [
  'zh',
  'zh-CN',
  'zh-Hans',
  'zh-Hans-HK',
  'zh-Hans-MO',
  'zh-SG',
];
const traditionalChineseLocaleNames = ['zh-Hant', 'zh-HK', 'zh-MO', 'zh-TW'];

export const defaultLang = (lang, localeName, nativeName) => {
  if (lang === 'en') {
    return { name: 'English', code: 'en' };
  } else if (lang === 'id') {
    return { name: 'Indonesian', code: 'ba' };
  } else if (lang === 'zh') {
    const isSimplifiedChinese = simplifiedChineseLocaleNames.find(
      (name) => name === localeName
    );
    const isTraditionalChinese = traditionalChineseLocaleNames.find(
      (name) => name === localeName
    );
    if (!isNil(isSimplifiedChinese)) {
      return { name: '中文-简', code: 'zh_CN' };
    }
    if (!isNil(isTraditionalChinese)) {
      return { name: '中文', code: 'zh_TW' };
    }
    return 'zh_CN';
  } else {
    return {
      name: nativeName,
      code: localeName,
    };
  }
};
