import React from 'react';
import { Badge } from './BadgeListItem.styles';

const BadgeListItem = ({
  key,
  levelText,
  darkText,
  currentLevel,
  selectedLevel,
  lockedIconLevel,
}) => (
  <Badge key={key} darkText={darkText}>
    <img
      loading="lazy"
      className={`badge-icon-${selectedLevel}`}
      width="100px"
      alt={`badge-${selectedLevel}`}
      src={
        currentLevel >= selectedLevel
          ? `https://static.content.koobits.com/coreproduct/EXPLevel/nolabel/${selectedLevel}.png`
          : `https://static.content.koobits.com/coreproduct/EXPLevel/locked/theme${lockedIconLevel}.png`
      }
    />
    <p className="level-text">
      {levelText} {selectedLevel}
    </p>
  </Badge>
);

export default BadgeListItem;
