import styled from 'styled-components';

export const ButtonContainer = styled.button`
  align-self: center;
  margin-top: 20px;
  background-color: #ff7800;
  font-size: 18px;
  color: #fff;
  line-height: 30px;
  padding: 14px 40px;
  border: 0;
  border-radius: 58px;
  position: relative;
  padding-left: 80px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #ff6600;
    color: #fff;
  }
  & > span {
    background-color: #ff8a24;
    height: 46px;
    width: 46px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 7px;
    text-align: center;
    line-height: 46px;
  }
`;
