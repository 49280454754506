import React from 'react';

import { LoginButton } from './LoginButton.styles';

const CustomButton = ({ children, width, ...otherProps }) => (
  <LoginButton width={width}>
    <button className="custom-button" {...otherProps}>
      {children}
    </button>
  </LoginButton>
);

export default CustomButton;
