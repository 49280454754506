import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Shared/Button';
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import './CardSectionStyles.css';
import {
  createStripeSubscription,
  confirmStripPayment,
} from 'services/payment';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import Container, { InputGroup } from './CheckoutForm.styles';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CheckoutForm = ({ paymentDone, paymentFailed }) => {
  const { t } = useTranslation(['expired']);
  const stripe = useStripe();
  const elements = useElements();
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const { subscriptionPlans } = useSelector((state) => state.payment);
  const stripeSubscriptionPlan =
    !isNil(subscriptionPlans) &&
    subscriptionPlans.find((plan) => plan.PaymentProviderName === 'Stripe');

  // Component States
  const [errorToDisplay, setErrorToDisplay] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardName, setCardName] = useState('');

  // Event Handlers
  const handleCustomerAction = ({ paymentMethodId, clientSecret }) => {
    return stripe
      .confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      })
      .then((result) => {
        if (result.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          paymentFailed(); // redirect to failed page
          throw new Error(result.error.message);
        }
        return result;
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorToDisplay(null);
    // Validations
    const nameOnCard = cardName;
    if (nameOnCard.length <= 0) {
      setErrorToDisplay('Please fill in Name on Card');
      return;
    }
    setIsSubmitting(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement);

    const parentEmail = studentDetails.ParentsDetails
      ? studentDetails.ParentsDetails.Email
      : '';
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: nameOnCard,
        email: parentEmail,
      },
    });
    if (error) {
      console.error('createPaymentMethod error:', error.message);
      setErrorToDisplay(error.message);
      setIsSubmitting(false);
    } else {
      const paymentMethodId = paymentMethod.id;
      try {
        const subResult = await createStripeSubscription({
          paymentMethodId,
          subscriptionPlanId: stripeSubscriptionPlan.Id,
        });
        let confirmCardSetupResult = {};
        // if without authentication and transaction fails
        if (
          subResult.SetupIntent_ClientSecret === null &&
          subResult.PaymentIntentStatus !== 'succeeded'
        ) {
          paymentFailed(); // redirect to failed page
          setIsSubmitting(false);
          return;
        }
        // if with authentication generate pop up
        if (subResult.SetupIntent_ClientSecret != null) {
          confirmCardSetupResult = await handleCustomerAction({
            paymentMethodId,
            clientSecret: subResult.SetupIntent_ClientSecret,
          });
        }
        await confirmStripPayment({
          planID: stripeSubscriptionPlan.Id,
          stripeSubscriptionInputModel: {
            subscriptionID: subResult.SubscriptionId,
            setupIntentId: confirmCardSetupResult.paymentIntent
              ? confirmCardSetupResult.paymentIntent.id
              : subResult.SubscriptionId,
          },
        });
        setIsSubmitting(false);
        paymentDone(); // redirect to success page
      } catch (err) {
        console.log(err);
        setIsSubmitting(false);
        setErrorToDisplay(err.message || err.error.message);
      }
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit} className="payment-form">
        <label>
          {t('expired:checkOut.cardInfo', 'Card information')}
          <CardNumberElement options={CARD_ELEMENT_OPTIONS} className="full" />
          <div className="card-row">
            <CardExpiryElement
              options={CARD_ELEMENT_OPTIONS}
              className="half left"
            />
            <CardCvcElement
              options={CARD_ELEMENT_OPTIONS}
              className="half right"
            />
          </div>
        </label>
        <InputGroup>
          <label>{t('expired:checkOut.name', 'Name on card')}</label>
          <input
            className="form-input"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
            type="text"
          />
        </InputGroup>
        {errorToDisplay !== null && (
          <p style={{ color: '#f36e20', marginBottom: '0.5rem' }}>
            {errorToDisplay}
          </p>
        )}
        <div style={{ margin: '0 auto' }}>
          <Button
            isDisabled={!stripe || isSubmitting}
            width="230px"
            variant="primary"
            type="submit"
          >
            {isSubmitting
              ? t('expired:checkOut.submitting', 'Submitting')
              : t('expired:checkOut.pay', 'Pay')}
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default CheckoutForm;
