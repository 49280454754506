import styled from 'styled-components';
import MainGreenBg from 'assets/img/assignment/assignment-view/content-green-bg.svg';

export const Container = styled.div`
  display: flex;
`;

export const Main = styled.div`
  background: url(${MainGreenBg});
  background-color: #187e86;
  background-repeat: repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  .wrapper {
    display: flex;
    justify-content: center;
  }
  .summary-box {
    height: fit-content;
    background: #ffffff;
    border: 1px solid #fdad0c;
    border-radius: 8px;
    box-shadow: 10px 10px 0px 0px #fdad0c;
    /* min-width: ${(props) => (props.type !== 'homework' ? '70%' : 'none')}; */
    width: 70%;
    margin-top: 5rem;
    padding: 0% 6% 0% 10%;
    @media (max-width: 1367px) {
      width: 75%;
    }
    @media (max-width: 1140px) {
      width: 92%;
      padding-left: 18%;
    }
    @media (max-width: 951px) {
      padding-left: 10%;
    }
    @media (max-width: 835px) {
    }
  }
`;

export const AssignerSection = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-left: 14%;
  @media (max-width: 1141px) {
    margin-left: 4%;
  }
`;
