import styled, { css } from 'styled-components';
import Button from 'components/Shared/Button';
import Spotlight from 'assets/img/mission/missionplus/spotlight.svg';
import Glitters from 'assets/img/mission/missionplus/glitters.svg';
import BgGoodEffort from 'assets/img/mission/missionplus/bg-good-effort.png';
import MikoBadge1 from 'assets/img/mission/missionplus/miko-badge-1.svg';
import MikoBadge2 from 'assets/img/mission/missionplus/miko-badge-2.svg';
import MikoBadge3 from 'assets/img/mission/missionplus/miko-badge-3.svg';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 5rem;

  ${({ progress }) => {
    if (progress > 0) {
      return css`
        margin-top: 1rem;
      `;
    }
  }}

  .layer-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    width: 320px;
    margin-top: 2rem;
    background: rgb(16, 15, 15, 0.43);
    border: 4px solid rgb(112, 112, 112, 0.16);
    border-radius: 13px;

    ${({ progress }) => {
      if (progress > 0) {
        return css`
          background-image: url(${Spotlight});
          background-position-x: center;
          background-position-y: bottom;
        `;
      }
    }}
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-left: 0;
    padding-bottom: 10px;
  }
`;

export const StyledEffortText = styled.div`
  margin-top: -2rem;
  margin-bottom: 0.5rem;
  background-image: url(${Glitters});
  background-position-y: 60%;
  background-position-x: 0%;

  span {
    font-size: ${({ language }) => (language === 'ba' ? '2.0rem' : '2.8rem')};
    font-family: 'Grilled Cheese';

    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;

    background-image: url(${BgGoodEffort});
    background-position-y: 60%;
    position: relative;
    z-index: 0;

    &:before {
      content: attr(title);
      position: absolute;
      -webkit-text-stroke: 0.13em #000000;
      left: 0;
      z-index: -1;
      white-space: nowrap;
    }

    &:after {
      content: attr(title);
      position: absolute;
      -webkit-text-stroke: 0.25em #ffffff;
      left: 0;
      z-index: -2;
      white-space: nowrap;
    }
  }
`;

const renderMikoBadge = (stageTypeId) => {
  if (stageTypeId === 3) return MikoBadge3;
  if (stageTypeId === 2) return MikoBadge2;
  return MikoBadge1;
};

export const StyledSvgMiko = styled.img.attrs(({ stageTypeId }) => ({
  src: renderMikoBadge(stageTypeId),
  alt: 'well-done-badge',
}))`
  margin-top: -2rem;
`;

export const StyledTotalScore = styled.div`
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-weight: bold;

  span {
    color: #f7c835;
    font-size: 27px;
    padding-right: 0.1rem;
  }
`;

export const StyledButton = styled(Button)`
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 20px;
  font-size: 1.1rem;

  img {
    margin-top: 5px;
    height: 50px;
  }
`;
