import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './QnResultStatus.styles';

const QnResultStatus = ({ type, number, isMobile, clickHandler }) => {
  const { t } = useTranslation(['assignmentFinished']);

  const renderResult = () => {
    if (type === 'correct') {
      return isMobile
        ? t('assignmentFinished:homework.Qn', { number }, `Qn ${number}`)
        : t(
            'assignmentFinished:homework.Question',
            { number },
            `Question ${number}`
          );
    }
    if (type === 'wrong') {
      return t('assignmentFinished:homework.Re-try', 'Re-try');
    }
    return t('assignmentFinished:homework.Not-marked', 'Not-marked');
  };

  return (
    <Container type={type} onClick={clickHandler}>
      {type === 'correct' ? (
        <div className="icon" />
      ) : (
        <div className="qn-number">
          {t('assignmentFinished:homework.Q', 'Q')}
          {number}
        </div>
      )}
      {renderResult()}
    </Container>
  );
};

export default QnResultStatus;
