import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'components/Shared/Button';
import { Container } from './ButtonsGroup.styles';

const ButtonsGroup = ({
  cancelText,
  cancelFunction,
  nextText,
  nextFunction,
  nextIsDisabled,
  loading,
}) => {
  return (
    <Container>
      <Button width="130px" variant="secondary" onClick={cancelFunction}>
        {cancelText}
      </Button>
      <Button
        variant="primary"
        width="130px"
        onClick={nextFunction}
        isDisabled={nextIsDisabled}
      >
        {nextText}{' '}
        {loading && (
          <Spinner
            animation="border"
            variant="light"
            size="sm"
            style={{ marginLeft: '0.3rem' }}
          />
        )}
      </Button>
    </Container>
  );
};

export default ButtonsGroup;
