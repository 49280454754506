import React from 'react';
import { Container } from './AlertText.styles';

const AlertText = ({
  text,
  color,
  bgColor,
  fontSize,
  margin,
  borderRadius,
}) => {
  return (
    <Container
      data-cy="alert-text"
      color={color}
      bgColor={bgColor}
      fontSize={fontSize}
      margin={margin}
      borderRadius={borderRadius}
    >
      {text}
    </Container>
  );
};

export default AlertText;
