export const rules = [
  {
    level: 1, // primary 1
    difficulty: 1, // easy
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 9,
        },
        operand2: {
          min: 2,
          max: 9,
        },
        result: {
          min: 0,
          max: 10,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 12,
        },
        operand2: {
          min: 2,
          max: 9,
        },
        result: {
          min: 0,
          max: 10,
        },
      },
      multiplication: false, // no multiplication for this difficulty
      division: false,
    },
  },
  {
    level: 1, // primary 1
    difficulty: 2, // normal
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 18,
        },
        operand2: {
          min: 2,
          max: 18,
        },
        result: {
          min: 0,
          max: 20,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 22,
        },
        operand2: {
          min: 2,
          max: 18,
        },
        result: {
          min: 0,
          max: 20,
        },
      },
      multiplication: false, // no multiplication for this difficulty
      division: false,
    },
  },
  {
    level: 1, // primary 1
    difficulty: 3, // hard
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 98,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 102,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      // addition: false,
      // subtraction: false,
      // multiplication: false,
      multiplication: {
        operand1: {
          min: 2,
          max: 10,
        },
        operand2: {
          min: 2,
          max: 10,
        },
        result: {
          min: 0,
          max: 20,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 10,
        },
        operand2: {
          min: 2,
          max: 10,
        },
        result: {
          min: 0,
          max: 20,
          integersOnly: true,
        },
      },
    },
  },
  {
    level: 2, // primary 2
    difficulty: 1, // easy
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 38,
        },
        operand2: {
          min: 2,
          max: 38,
        },
        result: {
          min: 0,
          max: 40,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 42,
        },
        operand2: {
          min: 2,
          max: 38,
        },
        result: {
          min: 0,
          max: 40,
        },
      },
      multiplication: {
        operand1: {
          min: 2,
          max: 10,
        },
        operand2: {
          min: 2,
          max: 10,
        },
        result: {
          min: 0,
          max: 20,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 10,
        },
        operand2: {
          min: 2,
          max: 10,
        },
        result: {
          min: 0,
          max: 20,
          integersOnly: true,
        },
      },
    },
  },
  {
    level: 2, // primary 2
    difficulty: 2, // normal
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 98,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 102,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      multiplication: {
        operand1: {
          min: 2,
          max: 10,
        },
        operand2: {
          min: 2,
          max: 10,
        },
        result: {
          min: 0,
          max: 20,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 10,
        },
        operand2: {
          min: 2,
          max: 10,
        },
        result: {
          min: 0,
          max: 20,
          integersOnly: true,
        },
      },
    },
  },
  {
    level: 2, // primary 2
    difficulty: 3, // hard
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 98,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 102,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      multiplication: {
        operand1: {
          min: 2,
          max: 50,
        },
        operand2: {
          min: 2,
          max: 50,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 50,
        },
        operand2: {
          min: 2,
          max: 50,
        },
        result: {
          min: 0,
          max: 100,
          integersOnly: true,
        },
      },
    },
  },
  {
    level: 3, // primary 3
    difficulty: 1, // easy
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 98,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 102,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      multiplication: {
        operand1: {
          min: 2,
          max: 10,
        },
        operand2: {
          min: 2,
          max: 10,
        },
        result: {
          min: 0,
          max: 20,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 10,
        },
        operand2: {
          min: 2,
          max: 10,
        },
        result: {
          min: 0,
          max: 20,
          integersOnly: true,
        },
      },
    },
  },
  {
    level: 3, // primary 3
    difficulty: 2, // normal
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 98,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 102,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      multiplication: {
        operand1: {
          min: 2,
          max: 20,
        },
        operand2: {
          min: 2,
          max: 20,
        },
        result: {
          min: 0,
          max: 40,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 20,
        },
        operand2: {
          min: 2,
          max: 20,
        },
        result: {
          min: 0,
          max: 40,
          integersOnly: true,
        },
      },
    },
  },
  {
    level: 3, // primary 3
    difficulty: 3, // hard
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 498,
        },
        operand2: {
          min: 2,
          max: 498,
        },
        result: {
          min: 0,
          max: 500,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 502,
        },
        operand2: {
          min: 2,
          max: 498,
        },
        result: {
          min: 0,
          max: 500,
        },
      },
      multiplication: {
        operand1: {
          min: 2,
          max: 50,
        },
        operand2: {
          min: 2,
          max: 50,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 50,
        },
        operand2: {
          min: 2,
          max: 50,
        },
        result: {
          min: 0,
          max: 100,
          integersOnly: true,
        },
      },
    },
  },
  {
    level: 4, // primary 4
    difficulty: 1, // easy
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 98,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 102,
        },
        operand2: {
          min: 2,
          max: 98,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      multiplication: {
        operand1: {
          min: 2,
          max: 20,
        },
        operand2: {
          min: 2,
          max: 20,
        },
        result: {
          min: 0,
          max: 40,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 20,
        },
        operand2: {
          min: 2,
          max: 20,
        },
        result: {
          min: 0,
          max: 40,
          integersOnly: true,
        },
      },
    },
  },
  {
    level: 4, // primary 4
    difficulty: 2, // normal
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 198,
        },
        operand2: {
          min: 2,
          max: 198,
        },
        result: {
          min: 0,
          max: 200,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 202,
        },
        operand2: {
          min: 2,
          max: 198,
        },
        result: {
          min: 0,
          max: 200,
        },
      },
      multiplication: {
        operand1: {
          min: 2,
          max: 50,
        },
        operand2: {
          min: 2,
          max: 50,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 50,
        },
        operand2: {
          min: 2,
          max: 50,
        },
        result: {
          min: 0,
          max: 100,
          integersOnly: true,
        },
      },
    },
  },
  {
    level: 4, // primary 4
    difficulty: 3, // hard
    constraints: {
      // inclusive unless otherwise stated
      addition: {
        operand1: {
          min: 2,
          max: 498,
        },
        operand2: {
          min: 2,
          max: 498,
        },
        result: {
          min: 0,
          max: 500,
        },
      },
      subtraction: {
        operand1: {
          min: 2,
          max: 502,
        },
        operand2: {
          min: 2,
          max: 498,
        },
        result: {
          min: 0,
          max: 500,
        },
      },
      multiplication: {
        operand1: {
          min: 2,
          max: 50,
        },
        operand2: {
          min: 2,
          max: 50,
        },
        result: {
          min: 0,
          max: 100,
        },
      },
      division: {
        operand1: {
          min: 2,
          max: 50,
        },
        operand2: {
          min: 2,
          max: 50,
        },
        result: {
          min: 0,
          max: 100,
          integersOnly: true,
        },
      },
    },
  },
];
