import styled from 'styled-components';

export const Badge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  .level-text {
    font-family: 'Linotte';
    font-weight: normal;
    font-size: 0.875rem;
    color: ${(props) => (props.darkText ? '#000000' : '#ffffff')};
  }
`;

export default {};
