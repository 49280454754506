import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Header from 'components/Header';
import Button from 'components/Shared/Button';
import BackButton from 'components/Shared/BackButton';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import formatI18nDateTime from 'helpers/formatI18nDateTime';

// Constants
import {
  MAPPING_PARAM_BY_PAPER_NAME,
  kooQuizSubjectStatus,
} from 'constants/kooQuiz';

// Assets
import PencilImg from 'assets/img/kooquiz/kooQuizQn/pencil.svg';
import PrintIcon from 'assets/img/kooquiz/kooQuizQn/print.svg';

// Component
import {
  getRevisionId,
  setCurrentCourseId,
  getKooQuizSolutions,
  setKooQuizActiveQuestion,
  resetSelectedPaper,
} from 'store/kooQuiz/kooQuizSlice';
import {
  Page,
  OnlineOfflineContainer,
  ScoreHeading,
  Score,
  ScoreFooter,
} from './KooQuizSolutionPreview.styles';

import {
  StyledBackground,
  StyledContent,
  StyledPencil,
  StyledPaper,
  HOTSContainer,
  HOTSCard,
} from './KooQuizStart.styles';

const mappingSubject = {
  math: 'Maths',
  science: 'Science',
};

const KooQuizSolutionPreview = ({ match }) => {
  const dispatch = useDispatch();
  const paperVal = parseInt(match.params.paper, 10);
  const currentSubject = match.params.subject;
  const submissionId = match.params.kooQuizSubmissionID;
  const kooQuizPaperId = match.params.kooQuizId;
  const { t, i18n } = useTranslation([
    'kooQuizStartPage',
    'assignmentFinished',
  ]);
  const history = useHistory();
  const {
    isKooQuizOpening,
    isSolutionsLoading,
    paperById,
    kooQuizSelectedPaper,
    errorFinish,
    errorById,
    kooQuizSolutions,
  } = useSelector((state) => state.kooQuiz);

  const { subject, allFeatures } = useSelector((state) => state.plan);
  const isMathSubject = subject === 'math';
  const isKooQuizMaths =
    kooQuizSelectedPaper === 1 && currentSubject === 'KooQuizMaths';
  const [isRevisionDataLoaded, setIsRevisionDataLoaded] = useState(false);

  const getRevisionIdData = (parseKooQuizFeatureData) => {
    if (!parseKooQuizFeatureData) throw Error('Not get features data');
    const featureCourseIds = parseKooQuizFeatureData.parameters.Category;
    const currentPaperName = kooQuizSubjectStatus[paperVal];
    const parsePaperName = MAPPING_PARAM_BY_PAPER_NAME[currentPaperName];

    dispatch(setCurrentCourseId(featureCourseIds[parsePaperName]));

    const getRevisionIdParam = {
      subjectId: subject === 'math' ? 1 : 2,
      paperId: kooQuizPaperId,
      courseIds: featureCourseIds[parsePaperName],
    };
    dispatch(getRevisionId(getRevisionIdParam));
  };
  useEffect(() => {
    if (paperById?.Id === parseInt(kooQuizPaperId, 10)) {
      dispatch(getKooQuizSolutions(submissionId));
    }
    return () => {
      dispatch(setKooQuizActiveQuestion(paperVal));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperById]);

  useEffect(() => {
    if (!isRevisionDataLoaded && allFeatures) {
      const parseKooQuizFeatureData = allFeatures.filter(
        (kooQuizItem) => kooQuizItem.feature === 'KooQuiz'
      )[0];

      getRevisionIdData(parseKooQuizFeatureData);
      setIsRevisionDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFeatures]);

  const rewardEndDate = kooQuizSolutions?.LastCompletedDate
    ? moment(kooQuizSolutions?.LastCompletedDate).format(
        formatI18nDateTime({ format: 'DD MMM YYYY', language: i18n.language })
      )
    : moment().format(
        formatI18nDateTime({ format: 'DD MMM YYYY', language: i18n.language })
      );

  const onSolution = () => {
    let param = '';
    if (paperVal === 3 || paperVal === 4) param = '?print=false';
    else param = '';
    history.push(
      `/kooQuiz/solution/${currentSubject}/${paperVal}/${kooQuizPaperId}/${submissionId}${param}`
    );
  };

  const onPrint = () => {
    if (!paperById?.OfflinePDF) throw new Error('No offline PDF data');
    const offlinePDFData = JSON.parse(paperById.OfflinePDF);

    if (offlinePDFData.PrintedPaper.length > 0) {
      window.open(
        `https://s3.ap-southeast-1.amazonaws.com/static.koobits/${offlinePDFData.PrintedPaper}`,
        '_blank'
      );
    }
  };

  const isOkToRender =
    !isKooQuizOpening && paperById && kooQuizSolutions && !isSolutionsLoading;

  const hasOffinePDF = paperById?.OfflinePDF;

  return (
    <Page>
      <Header />
      <OnlineOfflineContainer>
        <StyledBackground isMathSubject={isMathSubject}>
          <BackButton
            backText={t('kooQuizStartPage:Back', 'Back')}
            btnBgColor={isMathSubject ? '#394EB1' : '#23C5A8'}
            padding="0.3rem 1.2rem"
            onClick={() => {
              dispatch(resetSelectedPaper());
              history.push(`/kooQuiz/${mappingSubject[subject]}/${paperVal}`);
            }}
          />
          {(errorById || errorFinish) && (
            <ErrorModal
              errorMessage={errorById || errorFinish || 'Unexpect Error'}
            />
          )}
          {!isOkToRender && <Spinner />}
          {isOkToRender && (
            <StyledContent style={{ maxWidth: '915px' }}>
              <StyledPaper
                isMathSubject={isMathSubject}
                isKooQuizMaths={isKooQuizMaths}
                onlineOffline={paperVal === 3 || paperVal === 4}
              >
                <p className="hots-questions-text">
                  {t('kooQuizStartPage:TotalQuestions', 'Total Questions')}
                  &nbsp;
                  <strong>{paperById?.TotalQuestions}</strong>
                </p>
                <img src={paperById?.Icon?.IconUrl} alt="paper" />
              </StyledPaper>
              <HOTSContainer>
                <HOTSCard width="350px" isMathSubject={isMathSubject}>
                  <div className="hots-header">
                    {t('kooQuizStartPage:OnlinePractice', 'Online Practice')}
                  </div>
                  <div className="hots-score-content">
                    <ScoreHeading>
                      {t(
                        'assignmentFinished:homework:Total Score',
                        'TOTAL SCORE'
                      )}
                    </ScoreHeading>
                    <Score>
                      <div className="score-per">
                        <span>
                          {Math.floor(
                            (kooQuizSolutions.CorrectAnswers /
                              kooQuizSolutions.TotalQuestions) *
                              100
                          )}
                        </span>
                        <div>%</div>
                      </div>
                      <div>
                        ({' '}
                        <span
                          style={{
                            color: '#48b500',
                            fontWeight: '900',
                            fontSize: '20px',
                          }}
                        >
                          {kooQuizSolutions.CorrectAnswers}
                        </span>
                        &nbsp;
                        {t('assignmentFinished:homework.Out of', 'out of')}
                        &nbsp;
                        <span>{kooQuizSolutions.TotalQuestions}</span> )&nbsp;
                      </div>
                    </Score>
                    <ScoreFooter>
                      {t(
                        'assignmentSolution:words.CompletedOn',
                        ' Completed on '
                      )}
                      {rewardEndDate}
                    </ScoreFooter>
                  </div>
                </HOTSCard>
                {hasOffinePDF && (
                  <HOTSCard width="285px" isMathSubject={isMathSubject}>
                    <div className="hots-header">
                      {t(
                        'kooQuizStartPage:OfflinePractice',
                        'Offline Practice'
                      )}
                    </div>
                    <div className="hots-content">
                      <h6
                        style={{
                          marginBottom: isMathSubject ? '26px' : '44px',
                        }}
                      >
                        {isMathSubject
                          ? t(
                              'kooQuizStartPage:offlineHeading',
                              'Complete this KooQuiz paper by hand, supported with full video solutions.'
                            )
                          : t(
                              'kooQuizStartPage:offlineHeadingNoVideo',
                              'Complete this KooQuiz paper by hand, supported with full solution.'
                            )}
                      </h6>
                      <button
                        className="hots-print"
                        type="button"
                        onClick={onPrint}
                      >
                        <img src={PrintIcon} title="print" alt="" />
                        <span>{t('kooQuizStartPage:print', 'Print')}</span>
                      </button>
                    </div>
                  </HOTSCard>
                )}
              </HOTSContainer>

              {hasOffinePDF && (
                <StyledPencil
                  isMathSubject={isMathSubject}
                  style={{ marginLeft: '1rem' }}
                >
                  <img src={PencilImg} alt="pencil" />
                </StyledPencil>
              )}
            </StyledContent>
          )}
        </StyledBackground>
        {paperById && (
          <Button
            className="primary"
            width="100%"
            padding="17px 0"
            fontSize="28px"
            style={{
              margin: '40px auto',
              display: 'flex',
              justifyContent: 'center',
              maxWidth: '380px',
            }}
            onClick={onSolution}
          >
            {t('assignmentFinished:words.seeSolution', 'See Solution')}
          </Button>
        )}
      </OnlineOfflineContainer>
    </Page>
  );
};

export default KooQuizSolutionPreview;
