import React from 'react';
import { useTranslation } from 'react-i18next';
import AvatarPlaceholder from 'assets/img/avatar.png';
import LogoPlaceholder from 'assets/img/img-placeholder.png';
import { isNil } from 'ramda';
import { Card, DetailsSection } from './FriendCard.styles';

const FriendCard = ({ isCurrentUser, friendDetails, rightSection }) => {
  const { t } = useTranslation(['friends']);
  return (
    <Card data-cy="friends-list" isCurrentUser={isCurrentUser}>
      <div className="avatar">
        <img
          style={{ borderRadius: '50%' }}
          src={
            !isNil(friendDetails.avatar) && friendDetails.avatar !== ''
              ? friendDetails.avatar
              : AvatarPlaceholder
          }
          alt="avatar"
          width="65px"
        />
      </div>
      <DetailsSection>
        <p className="student-name">{friendDetails.name}</p>
        <p className="student-bffcode">
          {isCurrentUser && t('friends:addFriend.my', 'My ')}
          {t('friends:addFriend.bffcode', 'BFF Code')}:{' '}
          <span data-cy="friend-code">{friendDetails.BFFCode}</span>
        </p>
        <img
          src={friendDetails.schoolLogo}
          width="23px"
          height="23px"
          alt="school-logo"
          onError={(e) => (e.target.src = LogoPlaceholder)}
        />
        <span className="school-name">{friendDetails.schoolName}</span>
      </DetailsSection>
      {rightSection}
    </Card>
  );
};

export default FriendCard;
