/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { genderId } from 'constants/profileSettings';
import { isEmpty, isNil } from 'ramda';

import ModalContentXButton from 'components/Shared/ModalContentXButton';
import BoysWithGirlsHeader from 'components/Leaderboard/BoysVsGirls/BoysVsGirlsHeader';
import GenderModal from 'components/Leaderboard/BoysVsGirls/GenderModal';
import {
  getCurrentUserScore,
  getLatestScore,
  getTotalScores,
  getGlobalTop100Score,
  getSeasons,
  resetSeasons,
  resetGenderUpdate,
} from 'store/boysVsGirls/boysVsGirlsSlice';
import BvGCategory from 'components/Leaderboard/BoysVsGirls/BvGCategory';
import CurrentUserScore from 'components/Leaderboard/BoysVsGirls/CurrentUserScore';
import MainCPSubmittedTable from 'components/Leaderboard/BoysVsGirls/MainCPSubmittedTable/MainCPSubmittedTable';
import MainGlobalTop100 from 'components/Leaderboard/BoysVsGirls/MainGlobalTop100/MainGlobalTop100';
import { leaderboardList, checkIfChallengeOpen } from 'constants/leaderboard';
import { Redirect } from 'react-router-dom';
import { Container, StyledMain } from './BoysVsGirls.styles';

const BoysVsGirls = () => {
  const refreshInterval = 5000;
  const dispatch = useDispatch();

  const { studentDetails, gender } = useSelector(
    (state) => state.studentDetails
  );
  const {
    currentUserScore,
    seasons,
    teamLatestCPSubmittedList,
    globalTop100List,
    totalScores,
  } = useSelector((state) => state.boysVsGirls);

  const userGender = genderId[gender];
  const userId = studentDetails?.UserId;
  const isOpen = checkIfChallengeOpen(
    leaderboardList.math[6].startDateTime,
    leaderboardList.math[6].endDateTime
  );
  const { isCompetitionActive, bannerMessage } = totalScores;

  const [dropdownItems, setDropdownItems] = useState(null);
  const [showGenderModal, setShowGenderModal] = useState(false);

  const closeModal = () => {
    setShowGenderModal(false);
  };

  // Cleanup
  useEffect(() => {
    return () => {
      dispatch(resetSeasons());
      dispatch(resetGenderUpdate());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSeasons());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(seasons)) {
      setDropdownItems(seasons[0]);
    }
  }, [dispatch, seasons]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getCurrentUserScore(dropdownItems));
      dispatch(getTotalScores(dropdownItems));
      dispatch(
        getGlobalTop100Score({
          ...dropdownItems,
          currentUserScore,
          userId,
          userGender,
          isCompetitionActive,
        })
      );

      if (isCompetitionActive) {
        dispatch(getLatestScore());
      }
    };

    if (!isNil(dropdownItems)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dropdownItems, isCompetitionActive, userGender, userId]);

  useEffect(() => {
    let intervalId;

    const stopInterval = () => {
      clearInterval(intervalId);
      intervalId = 0;
    };
    const visibilityChangeHandler = () => {
      stopInterval();
      if (!document.hidden) {
        intervalId = setInterval(() => {
          dispatch(getLatestScore({ seasonID: dropdownItems.id }));
        }, refreshInterval);
      }
    };

    if (!isNil(dropdownItems) && isCompetitionActive) {
      intervalId = setInterval(() => {
        dispatch(getLatestScore({ seasonID: dropdownItems.id }));
      }, refreshInterval);
      document.addEventListener('visibilitychange', visibilityChangeHandler);
    }

    return () => {
      stopInterval();
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
    // if it's during the competition
  }, [dispatch, dropdownItems, isCompetitionActive]);

  useEffect(() => {
    if (!isNil(gender) && isNil(userGender)) {
      setShowGenderModal(true);
    }
  }, [gender, userGender]);

  const changeSeason = (value) => {
    setDropdownItems(value);
  };

  if (!isOpen) return <Redirect to="/leaderboard?subject=math" />;
  return (
    <Container>
      <ModalContentXButton
        showModal={showGenderModal}
        closeModal={closeModal}
        backdrop="static"
      >
        <GenderModal closeModal={closeModal} />
      </ModalContentXButton>
      <BoysWithGirlsHeader totalScores={totalScores} />
      <StyledMain>
        <BvGCategory
          dropdownItems={dropdownItems}
          seasons={seasons}
          isCompetitionActive={isCompetitionActive}
          bannerMessage={bannerMessage}
          changeSeason={changeSeason}
        />
        <CurrentUserScore
          userGender={userGender}
          currentUserScore={currentUserScore}
        />
        {isCompetitionActive && (
          <MainCPSubmittedTable
            teamLatestCPSubmittedList={teamLatestCPSubmittedList}
          />
        )}
        <MainGlobalTop100 globalTop100List={globalTop100List} />
      </StyledMain>
    </Container>
  );
};

export default BoysVsGirls;
