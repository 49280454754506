import styled from 'styled-components';
import RibbonYellow from 'assets/img/dailychallenge/mystery-koko/ribbon-bg.png';
import { Container, ModalBg as LevelUpModalBg } from './LevelUpModal.styles';
import { ModalContainer as LevelUpModalContainer } from './HeroOfTheDayModal.styles';

export const ModalContainer = styled(LevelUpModalContainer)`
  .header-img {
    position: relative;
    z-index: 1;
    top: 5rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .header-img {
      width: 90%;
    }
  }
`;

export const HeaderSection = styled.div`
  background: #009aeb;
  min-height: 149px;
  border-radius: 25px 25px 0 0;
  position: relative;
  p {
    z-index: 1;
    position: relative;
    top: 80px;
    margin: 0;
  }
  .main-text {
    font-size: 1.25rem;
    color: white;
    font-weight: 700;
    span {
      color: #ffc938;
    }
  }
  .white-subtext {
    font-size: 1rem;
    color: white;
    font-weight: 600;
    span {
      color: #ffc938;
    }
  }
  .sub-text {
    font-size: 1rem;
    color: #ffc938;
    font-weight: 600;
  }
`;

export const MainSection = styled.div`
  padding: 1.625rem 0rem;
  .giftbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    position: relative;
    visibility: ${(props) => (props.isLoading ? 'hidden' : 'visible')};
  }
`;

export const Ribbon = styled.div`
  background: ${`url(${RibbonYellow})`};
  width: 408px;
  height: 131px;
  background-repeat: no-repeat;
  position: relative;
  top: 3rem;
  z-index: 1;
  h3 {
    text-shadow: ${(props) =>
      props.text === 'Job Done!' ? '2px 4px #3497d8' : '2px 2px #DC7113'};
    font-family: 'Grilled Cheese';
    color: white;
    width: 100%;
    text-align: center;
    font-size: 22px;
    margin-top: 1rem;
    margin-left: 5px;
  }
`;

export const ModalBg = styled(LevelUpModalBg)`
  bottom: 36%;
`;

export const MysteryContainer = styled(Container)`
  width: 618px;
  min-height: 473px;
  background: #0090e2;
  box-shadow: 0px 10px 0px 0px #005c8d;
  padding: 0;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
  }
`;

export const ShortcutsContainer = styled.div`
  font-size: 1.1875rem;
  font-weight: 600;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 1rem;
  padding: 1rem;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  img {
    cursor: pointer;
    :hover {
      transform: scale(1.1);
    }
  }
`;
