import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

// Get user curriculum
export const fetchSelfPracticeCurriculum = ({ subjectId, userId }) => {
  return fetch(
    `${baseUrl}SelfPractice/Curriculums/Subject/${subjectId}/User/${userId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get topics failed'); // eslint-disable-line
    });
};

// Get topics
export const fetchTopics = ({ currId, levelId }) => {
  return fetch(
    `${baseUrl}MultiCurriculumSelfPractice/GetTopics/${currId}/${levelId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get topics failed'); // eslint-disable-line
    });
};

// Get questions
export const fetchSelfPracticeQuestions = (topicSkillId) => {
  return fetch(
    `${baseUrl}MultiCurriculumSelfPractice/PracticeQuestion/${topicSkillId}`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get question id failed'); // eslint-disable-line
    });
};

// Submit the answer
export const submitSelfPracticeAnswer = ({ topicSkillId, rawBody }) => {
  return fetch(`${baseUrl}MultiCurriculumSelfPractice/Submit/${topicSkillId}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit answer failed');
    });
};

// Claim the rewards
export const claimMyRewards = (topicSkillId) => {
  return fetch(
    `${baseUrl}MultiCurriculumSelfPractice/ClaimKoKo/${topicSkillId}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Claim Koko rewards failed');
    });
};

// ----MISSION PLUS----
// Get Topics
export const fetchMissionPlusTopics = ({ levelId, curriculumId }) => {
  return fetch(
    `${baseUrl}SelfPractice/Level/${levelId}/Curriculum/${curriculumId}/User/${
      store.getState().login.userID
    }`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get Mission Plus topics failed'); // eslint-disable-line
    });
};
export const fetchMissionPlusTotalProgress = ({ curriculumId, levelId }) => {
  return fetch(
    `${baseUrl}SelfPractice/User/${
      store.getState().login.userID
    }/Curriculum/${curriculumId}/Level/${levelId}/TotalProgress`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get Mission Plus topics failed'); // eslint-disable-line
    });
};
export const fetchMissionPlusUnlockStage = ({ topicSkillId, stageTypeId }) => {
  return fetch(
    `${baseUrl}SelfPractice/User/${
      store.getState().login.userID
    }/Unlock/TopicSkill/${topicSkillId}/Stage/${stageTypeId}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get Mission Plus topics failed'); // eslint-disable-line
    });
};
// Get Questions
export const fetchMissionPlusQuestions = ({ topicSkillId, stageTypeId }) => {
  return fetch(
    `${baseUrl}SelfPractice/User/${
      store.getState().login.userID
    }/PracticeQuestion/TopicSkill/${topicSkillId}/Stage/${stageTypeId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get Mission Plus topics failed'); // eslint-disable-line
    });
};
// Submit answer
export const submitMissionPlusAnswer = ({
  topicSkillId,
  stageTypeId,
  rawBody,
}) => {
  return fetch(
    `${baseUrl}SelfPractice/User/${
      store.getState().login.userID
    }/Submit/TopicSkill/${topicSkillId}/Stage/${stageTypeId}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
      body: JSON.stringify(rawBody),
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit answer failed');
    });
};
// Claim koko
export const claimMissionPlusKoKo = ({ topicSkillId, stageTypeId }) => {
  return fetch(
    `${baseUrl}SelfPractice/User/${
      store.getState().login.userID
    }/ClaimKoko/TopicSkill/${topicSkillId}/Stage/${stageTypeId}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit answer failed');
    });
};
// ----MISSION PLUS END----
