import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from 'components/Header';
import ParentApp from './ParentApp';
import Settings from './Settings';
import ChangePassword from './ChangePassword';
import { Content } from './index.styles';

const index = () => {
  return (
    <div>
      <Header hidePlan parentPortal />
      <Content>
        <Switch>
          <Route exact path="/parent/parent-app" component={ParentApp} />
          <Route exact path="/parent/settings/:tab" component={Settings} />
          <Route
            exact
            path="/parent/settings/change-password/:account"
            component={ChangePassword}
          />
        </Switch>
      </Content>
    </div>
  );
};

export default index;
