import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.marginBottom && '1rem'};
  width: 100%;
  .info {
    display: flex;
    align-items: center;
    flex-basis: 80%;
    .title {
      color: rgb(0, 0, 0, 0.24);
      font-weight: bold;
      font-size: 13px;
      width: 200px;
      text-align: right;
      margin-right: 1rem;
    }
    .input-field {
      display: flex;
      flex-direction: column;
      width: 60%;
      .error-message {
        color: red;
        font-size: 12px;
        margin-top: 2px;
      }
    }
    .data {
      font-size: ${(props) => (props.bold ? '18px' : '14px')};
      font-weight: ${(props) => props.bold && 'bold'};
      border: ${(props) =>
        props.edit ? '1px solid rgb(112 112 112 / 39%)' : 'none'};
      background: #ffffff;
      height: 36px;
      padding-left: 0.8rem;
      display: flex;
      align-items: center;
    }
  }
  .edit-btn {
    color: #ff6701;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }
  .btn-section {
    .btn {
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      outline: none;
      border-radius: 2px;
      &:focus {
        box-shadow: none;
      }
    }
    .orange {
      background: #ff5b00;
      color: #ffffff;
      border: 1px solid #ff5b00;
    }
    .white {
      background: #ffffff;
      color: #ff5b00;
      border: 1px solid #ffdac4;
      margin-right: 0.5rem;
    }
  }
`;
