import React from 'react';
import { useTranslation } from 'react-i18next';
import checkImage from 'assets/img/avatarcreator/icon-check.png';
import { Spinner } from 'react-bootstrap';
import { ButtonContainer } from './AvatarSubmitButton.style';

function AvatarSubmitButton({ onSubmit, loading }) {
  const { t } = useTranslation(['kokomonster']);
  return (
    <ButtonContainer onClick={onSubmit}>
      <span>
        {loading && (
          <Spinner
            animation="border"
            style={{ width: '100%', height: '100%' }}
          />
        )}
        {!loading && <img alt="submit" src={checkImage} />}
      </span>
      {t('kokomonster:main.saveMonster', 'SAVE MY MONSTER')}
    </ButtonContainer>
  );
}

export default React.memo(AvatarSubmitButton);
