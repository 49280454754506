const trialBannerConfig = [
  {
    subjectId: 1,
    isOtpTrial: true,
    btnText: {
      translationKey: 'buy45off',
      text: 'Buy now at 45% off',
    },
    videoUrl: 'CwL9dcOLOsI',
    videoBtn: {
      outer: 'video-btn-secondary',
      inner: 'primary',
    },
  },
  {
    subjectId: 1,
    isOtpTrial: false,
    btnText: null,
    videoUrl: 'CwL9dcOLOsI',
    videoBtn: {
      outer: 'video-btn-primary',
      inner: 'light',
    },
  },
  {
    subjectId: 2,
    isOtpTrial: true,
    btnText: {
      translationKey: 'buy25off',
      text: 'Buy now at 25% off',
    },
    videoUrl: 'mKidiC2PIuc',
    videoBtn: {
      outer: 'video-btn-secondary',
      inner: 'primary',
    },
  },
  {
    subjectId: 2,
    isOtpTrial: false,
    btnText: null,
    videoUrl: '2hxOzBuy9ag',
    videoBtn: {
      outer: 'video-btn-primary',
      inner: 'light',
    },
  },
];

export const hkPremiumTrialBanner = {
  subjectId: 1,
  isOtpTrial: false,
  btnText: null,
  videoUrl: 'upQJ5w0ss9I',
  videoBtn: {
    outer: 'video-btn-primary',
    inner: 'light',
  },
};

export const otpCampaignCode = {
  math: 'B2BOTPN_M45',
  science: 'OTPN_S25',
};

export default trialBannerConfig;
