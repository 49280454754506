import styled from 'styled-components';

const borderColor = '#b7b7b7';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledForm = styled.form`
  display: flex;
  align-items: center;

  input[type='search'] {
    padding-left: 10px;
  }
`;

export const StyledInput = styled.input`
  border: solid 1px ${borderColor};
  width: 155px;
  height: 29px;
  font-size: 13px;
`;

export const SearchButton = styled.button`
  background: #fadc5b;
  width: 25px;
  height: 29px;
  border: solid 1px #e0ba24;
`;

export const StyledResult = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 180px;
  background: white;
  border-radius: 6px;
  padding: 0.5rem;
  justify-content: space-between;
  border: ${(props) =>
    props.isSelected ? 'solid 2px #FF7121' : 'solid 1px #c3e0eb'};
  cursor: pointer;
  box-shadow: ${(props) =>
    props.isSelected ? '0 3px 6px rgba(0, 0, 0, 0.16)' : 'none'};
  :hover {
    background: ${(props) => (props.isSelected ? 'white' : '#F6FEFF')};
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .result-details {
    width: 115px;
    .long-text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .result-details-name {
      color: #0d0d0d;
      width: 115px;
      font-size: 0.8125rem;
      font-weight: normal;
    }
    .result-details-bffcode {
      color: #727272;
      font-size: 0.625rem;
      font-weight: normal;
      margin: 0.5rem 0;
    }
    .result-details-school {
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        border-radius: 3px;
        margin-right: 0.25rem;
      }
      p {
        color: #727272;
        font-size: 0.5rem;
        font-weight: normal;
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    position: absolute;
    bottom: -10px;
    padding: 4px;
    .result-details {
      .result-details-bffcode {
        margin: 4px 0;
      }
    }
  }
`;
