import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  width: 100%;
  cursor: pointer;
  &.invert {
    transform: rotate(180deg);
  }
  &.vertical {
    flex-direction: column;
    width: 20px;
    height: 100%;
  }
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 20px;
  height: 100%;
`;

export const SinglePart = styled.div`
  background: ${({ image }) => `url(${image}) no-repeat`};
  width: ${({ width }) => `${width}px`};
  pointer-events: none;
`;

export const RepeatPart = styled.div`
  background: ${({ image }) => `url(${image}) repeat-x`};
  flex-grow: 1;
  pointer-events: none;
`;

export const VSinglePart = styled.div`
  background: ${({ image }) => `url(${image}) no-repeat`};
  height: ${({ width }) => `${width}px`};
  width: auto;
  pointer-events: none;
`;

export const VRepeatPart = styled.div`
  background: ${({ image }) => `url(${image}) repeat-y`};
  flex-grow: 1;
  pointer-events: none;
`;
