import styled from 'styled-components';
import HeaderBanner from 'assets/img/kooquiz/header-selection.svg';
import BetaBg from 'assets/img/kooquiz/beta-bg.svg';

export const Banner = styled.div`
  background-image: ${`url(${HeaderBanner})`};
  background-repeat: no-repeat;
  background-size: 100%;
  height: 178px;
  padding: 20px;
  position: relative;
  span {
    color: #3177b4;
    position: absolute;
    left: 34%;
    top: 30%;
    font-size: 16px;
  }
  h2 {
    color: #ffffff;
    text-align: center;
    position: relative;
    left: 6%;
    top: 10%;
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const Sections = styled.div`
  .overlayy {
    width: 302px;
    height: 305px;
    margin-left: 2rem;
    position: absolute;
    border: 4px solid #ffffff;
    border-radius: 15px;
    background: rgb(62, 91, 71, 0.23);
    margin-top: -305px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .button {
      color: ${(props) => (props.type === 'Maths' ? '#3083CB' : '#108033')};
      border-radius: 30px;
      border: 1px solid transparent;
      background: #ffffff;
      padding: 2px 30px;
      font-weight: 600;
      font-size: 28px;
    }
  }
  &:hover {
    .overlayy {
      opacity: 1;
    }
  }
`;

export const Card = styled.div`
  width: 302px;
  height: 305px;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-repeat: no-repeat;
  border: 5px solid #ffffff;
  border-radius: 10px;
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  font-weight: 600;
  font-size: 35px;
  color: ${(props) =>
    props.type === 'Maths' ? 'rgb(255, 255, 255, 0.91)' : '#ffffff'};
  -webkit-filter: ${(props) => props.hover && 'blur(5px)'};
  filter: ${(props) => props.hover && 'blur(5px)'};
  .beta {
    background: url(${BetaBg});
    position: absolute;
    margin-right: -14.5rem;
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    width: 73px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
