import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from 'components/Shared/Button';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import Success from 'assets/img/expired/success-tick.svg';
import Mascot from 'assets/img/expired/success-mascot.svg';
import PlanHeader from './PlanHeader';
import { PaymentContainer, PaymentHeader } from './PaymentSuccess.styles';

const PaymentSuccess = ({ type }) => {
  const { t } = useTranslation(['expired']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { studentDetails, isLoading } = useSelector(
    (state) => state.studentDetails
  );
  const parentEmail = studentDetails.ParentsDetails
    ? studentDetails.ParentsDetails.Email
    : '';
  return (
    <PaymentContainer>
      <img src={Mascot} alt="mascot" className="success-mascot" />
      <PaymentHeader>
        <img src={Success} alt="success" />
        <div className="title-container">
          <h5>{t('expired:paymentSuccess.title', 'Payment Successful')}</h5>
          <div className="confirmation">
            {t(
              'expired:paymentSuccess.confirmEmail',
              'A confirmation email has been sent to'
            )}
          </div>
          <div className="email">{parentEmail}</div>
        </div>
      </PaymentHeader>
      <PlanHeader type={type} />
      <div className="note">
        {t(
          'expired:paymentSuccess.message',
          'Thank you for choosing our product. To continue using KooBits, please click the below button.'
        )}
      </div>
      <div className="btnContainer">
        <Button
          variant="primary"
          width="230px"
          isDisabled={isLoading}
          onClick={() => {
            dispatch(getStudentDetails());
            history.replace('/dashboard');
          }}
        >
          {t('expired:paymentSuccess.continue', 'Continue')}
        </Button>
      </div>
    </PaymentContainer>
  );
};

export default PaymentSuccess;
