import React from 'react';
import PopupBg from 'assets/img/braingame/popup.png';
import { Wrapper, ButtonClose, TextInfo, ButtonOK } from './PopupError.style';

const PopupError = ({ onClose, text }) => {
  return (
    <Wrapper>
      <img src={PopupBg} alt="popup" />
      <ButtonClose onClick={onClose} />
      <TextInfo>{text}</TextInfo>
      <ButtonOK onClick={onClose} href="#">
        OK
      </ButtonOK>
    </Wrapper>
  );
};

export default React.memo(PopupError);
