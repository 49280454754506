import styled from 'styled-components';

export const Page = styled.div`
  overflow: hidden;
`;

export const Main = styled.div`
  padding-top: 58px;
  width: 100%;
`;
