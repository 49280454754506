import styled from 'styled-components';
import {
  Topbar as IncomingChallengeTopBar,
  Content as IncomingChallengeContent,
} from './IncomingChallenges.styles';

export const Topbar = styled(IncomingChallengeTopBar)`
  justify-content: center;
  .main-title {
    display: flex;
    align-items: center;
    margin: auto;
  }

  .peer-challenge-title {
    margin-top: 0.2rem;
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      font-size: 24px;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  .selector {
    display: flex;
    align-items: center;
    span {
      margin-right: 1rem;
    }
  }
  .rule {
    font-size: 1rem;
    color: #010101;
    padding-left: 0.25rem;
    :hover {
      color: #ff7121;
    }
  }
`;

export const Content = styled(IncomingChallengeContent)`
  padding: 1rem 10rem;
  background-color: #f9f9f9;
  display: block;
  @media (max-width: 1024px) {
    padding: 1rem 80px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 1rem;
  }
`;

export const StyledPagination = styled.div`
  margin-top: 2rem;
  margin-left: 5rem;
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-left: 0;
  }
`;
