import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import {
  getRemainingPCAttempts,
  getIncomingPeerChallengeHistory,
} from 'services/multiplayerv2';
import { isNil } from 'ramda';
import { MULTI_PLAYER_LIST } from './constants';

const initialState = {
  multiplayerList: [],
  isLoading: false,
  error: null,
};

export const getMultiplayerList = createAsyncThunk(
  'multiplayerList/getMultiplayerList',
  async ({ userID, products }) => {
    const multiplayerList = MULTI_PLAYER_LIST.filter(
      (challenge) =>
        !isNil(products.find((p) => p.subjectId === challenge.SubjectId))
    ).map((challenge) => {
      const product = products.find((p) => p.subjectId === challenge.SubjectId);
      return {
        ...challenge,
        totalEnergyPerDay: product.totalEnergyPerDay,
      };
    });
    try {
      const resWithUpdatedAttempts = await multiplayerList.reduce(
        async (prevPeerChallenges, peerChallenge) => {
          const collection = await prevPeerChallenges;
          let remainingLimit;
          let incomingChallenges;
          try {
            remainingLimit = await fetchWrapper(getRemainingPCAttempts, {
              userID,
              subjectID: peerChallenge.SubjectId,
            });
          } catch {
            remainingLimit = { Result: 0 };
          }
          try {
            incomingChallenges = await fetchWrapper(
              getIncomingPeerChallengeHistory,
              {
                userID,
                challengeType: 1,
                subjectID: peerChallenge.SubjectId,
                pageSize: 1,
              }
            );
          } catch {
            incomingChallenges = [];
          }
          return [
            ...collection,
            {
              ...peerChallenge,
              PCNumOfRemainingChallenges: remainingLimit.Result ?? 0,
              PCNumOfIncomingChallenges: incomingChallenges.length,
            },
          ];
        },
        Promise.resolve([])
      );
      return resWithUpdatedAttempts;
    } catch (error) {
      throw new Error(error?.message ?? 'Get multiplayer list failed');
    }
  }
);

const multiplayerListSlice = createSlice({
  name: 'multiplayerList',
  initialState,
  reducers: {},
  extraReducers: {
    [getMultiplayerList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.multiplayerList = null;
    },
    [getMultiplayerList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.multiplayerList = action.payload;
      state.error = null;
    },
    [getMultiplayerList.rejected]: (state, action) => {
      state.isLoading = false;
      state.multiplayerList = null;
      state.error = action.error.message;
    },
  },
});

const { reducer } = multiplayerListSlice;
export default reducer;
