import styled from 'styled-components';

export const TopBarFixed = styled.div`
  background-color: #064da4;
  border-bottom: 0;
  padding: 0;
  color: #fff;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const LeftNav = styled.div`
  margin-left: 50px;
  flex-grow: 1;
`;

export const RightNav = styled.div`
  width: 270px;
`;

export const ButtonHeader = styled.button`
  width: 50%;
  float: left;
  border-radius: 0;
  background-color: #2167be;
  height: 100px;
`;
