import ChildAge6to9Img from 'assets/img/mission/hotsv2/child6to9.png';
import ChildAge8to11Img from 'assets/img/mission/hotsv2/child8to11.png';
import ChildAge10to13Img from 'assets/img/mission/hotsv2/child10to13.png';

// Age Group objects are order by lowest primary level to highest
const ageGroups = [
  {
    id: 1,
    i18nKey: 'common:ageGroup',
    name: 'Age Group 6 - 9',
    minAge: 6,
    maxAge: 9,
    minLevel: 1,
    maxLevel: 2,
  },
  {
    id: 2,
    i18nKey: 'common:ageGroup',
    name: 'Age Group 8 - 11',
    minAge: 8,
    maxAge: 11,
    minLevel: 3,
    maxLevel: 4,
  },
  {
    id: 3,
    i18nKey: 'common:ageGroup',
    name: 'Age Group 10 - 13',
    minAge: 10,
    maxAge: 13,
    minLevel: 5,
    maxLevel: 6,
  },
];

export const childBrainImages = {
  1: ChildAge6to9Img,
  2: ChildAge8to11Img,
  3: ChildAge10to13Img,
};

export default ageGroups;
