import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { isNil } from 'ramda';

import ScoreBoard from 'components/Assignment/AssignmentFinish/ScoreBoard';
import AlertText from 'components/Assignment/AssignmentFinish/AlertText';
import QnResultStatus from 'components/Assignment/AssignmentFinish/QnResultStatus';
import SubmitButton from 'components/Assignment/AssignmentFinish/SubmitButton';
import Mascot from 'assets/img/assignment/assignment-view/mascot-finish.svg';
import { submissionResultIDs } from 'constants/index';

import {
  openAssignment,
  setActiveQuestion,
  submitAssignment,
} from 'store/assignment/assignmentSlice';
import {
  openKooQuiz,
  setKooQuizActiveQuestion,
  submitClaimKooQuiz,
} from 'store/kooQuiz/kooQuizSlice';
import {
  startActivity,
  setKooClassActiveQuestion,
  endActivityKooClass,
} from 'store/kooClass/kooClassSlice';

import useQuery from 'helpers/useQuery';
import { sendEventTracking } from 'helpers/UserEventTracking';
import { Container } from './HomeworkContent.styles';

const HomeworkContent = ({
  data,
  totalQns,
  duration,
  assignmentId,
  submissionId,
  feature,
  showPrint = true,
  isMobile,
  renderAssigner,
  subjectId,
}) => {
  const { t } = useTranslation(['assignmentFinished']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { activityId, lessonId, topicId, activityType } = useParams();
  const query = useQuery();
  const courseIdParam = query.get('courseId');
  const paperId = query.get('id');

  let subResName = 'SubmissionResult';
  if (feature === 'KooClass') {
    subResName = 'submissionResult';
  }

  const { isKooQuizSubmitting } = useSelector((state) => state.kooQuiz);
  const { endActivity } = useSelector((state) => state.kooClass);
  // score condition color
  const scoreBoardColor = (score) => {
    if (score <= 59) {
      return 'red';
    }
    if (score <= 79) {
      return 'orange';
    }
    if (score <= 100) {
      return 'green';
    }
  };

  // for submissionResult needs
  const submissionModel = data.map((model) => model.SubmissionModel);
  const totalWrongAns = submissionModel.filter(
    (result) => result[subResName] === 2 || result[subResName] === 0
  ).length;
  const totalCorrectAns = submissionModel.filter(
    (result) => result[subResName] === 1
  ).length;
  const checkUnmarkQn = submissionModel.findIndex(
    (unmark) => unmark[subResName] === 3
  );

  // set the index of incorrect answer
  const indexIncorrect = submissionModel.findIndex(
    (item) => item[subResName] !== 1
  );

  // score calculation
  const score = Math.floor((totalCorrectAns / totalQns) * 100);

  const timeFormat = useCallback(() => {
    if (feature !== 'KooClass') {
      return moment.utc(duration).format('HH:mm:ss');
    }
  }, [feature, duration]);
  useEffect(() => {
    if (feature === 'KooClass') {
      if (!isNil(endActivity)) {
        history.push(
          `/kooClass/${topicId}/submitted/${lessonId}/${activityId}?courseId=${courseIdParam}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endActivity]);

  const renderButtonText = () => {
    if (score !== 100)
      return t('assignmentFinished:homework.Try Again', 'Try Again');
    return t('assignmentFinished:words.Submit', 'Submit');
  };

  const renderTotalTime = () => (
    <p className="time">
      <span className="total-time">
        {t('assignmentFinished:words.Total Time', 'Total Time ')}
      </span>
      <span className="time-format">{timeFormat()}</span>
    </p>
  );

  const renderMobileTotalTime = () => {
    return feature !== 'KooClass' && isMobile && renderTotalTime();
  };

  const kooQuizSubmitTrack = () => {
    // eslint-disable-next-line array-callback-return
    return data.map((item) => {
      sendEventTracking('kooquiz', 'question_submit', {
        kqi: paperId,
        qi: item.Question.Id,
        sub: subjectId,
      });
    });
  };

  const kooClassSubmitTrack = () => {
    // eslint-disable-next-line array-callback-return
    return data.map((item) => {
      sendEventTracking('kooclass', 'question_submit', {
        ai: activityId,
        qi: item.question.id,
        sub: subjectId,
      });
    });
  };

  const homeworkSubmitTrack = () => {
    return data.forEach((item) => {
      sendEventTracking('homework', 'question_submit', {
        hi: assignmentId,
        qi: item.Question.Id,
      });
    });
  };
  return (
    <Container score={score} isMobile={isMobile}>
      <img src={Mascot} alt="mascot" className="mascot" />
      <div className="score-section">
        <ScoreBoard
          correct={totalCorrectAns}
          totalQn={totalQns}
          color={scoreBoardColor(score)}
          score={score}
          isMobile={isMobile}
          renderTotalTime={renderMobileTotalTime}
        />
      </div>
      <div className="alert-section">
        <div className="answer-status">
          <AlertText
            className="correct-answer"
            type={totalWrongAns === 0 ? 'all-correct' : 'wrong'}
            totalWrongAns={totalWrongAns}
          />
          {checkUnmarkQn !== -1 && (
            <p className="unmark-answer">
              {t(
                'assignmentFinished:homework.UnmarkAlertText',
                'Please mark the open-ended questions by comparing against the reference answer before submitting.'
              )}
            </p>
          )}
        </div>
        {feature !== 'KooClass' && !isMobile && renderTotalTime()}
      </div>
      <div className="qn">
        {data.map((result, index) => (
          <QnResultStatus
            key={index}
            type={submissionResultIDs[result.SubmissionModel[subResName]]}
            number={feature === 'KooClass' ? index + 1 : result.DisplayOrder}
            isMobile={isMobile}
            clickHandler={() => {
              if (result.SubmissionModel[subResName] !== 1) {
                if (feature === 'kooQuiz') {
                  dispatch(setKooQuizActiveQuestion(index + 1));
                  dispatch(openKooQuiz(assignmentId));
                  history.push(`/kooQuiz/open?print=${showPrint}`);
                } else if (feature === 'KooClass') {
                  dispatch(setKooClassActiveQuestion(index + 1));
                  dispatch(startActivity(assignmentId));
                  history.push(
                    `/kooClass/open/${activityId}/${topicId}/${lessonId}/${activityType}?courseId=${courseIdParam}`
                  );
                } else {
                  dispatch(setActiveQuestion(index + 1));
                  dispatch(openAssignment(assignmentId));
                  history.push('/assignment/open');
                }
              }
            }}
          />
        ))}
      </div>
      {isMobile && renderAssigner()}
      <div className="action-btn">
        <SubmitButton
          width={isMobile ? '90%' : '100%'}
          className="submit-container"
          onClick={() => {
            if (score !== 100) {
              if (feature === 'kooQuiz') {
                dispatch(setKooQuizActiveQuestion(indexIncorrect + 1));
                dispatch(openKooQuiz(assignmentId));
                history.push(`/kooQuiz/open?print=${showPrint}`);
              } else if (feature === 'KooClass') {
                dispatch(setKooClassActiveQuestion(indexIncorrect + 1));
                dispatch(startActivity(assignmentId));
                history.push(
                  `/kooClass/open/${activityId}/${topicId}/${lessonId}/${activityType}?courseId=${courseIdParam}`
                );
              } else {
                dispatch(setActiveQuestion(indexIncorrect + 1));
                dispatch(openAssignment(assignmentId));
                history.push('/assignment/open');
              }
            } else if (feature === 'kooQuiz') {
              dispatch(submitClaimKooQuiz(submissionId));
              kooQuizSubmitTrack();
              if (!isKooQuizSubmitting) {
                history.push(`/kooQuiz/submitted?print=${showPrint}`);
              }
            } else if (feature === 'KooClass') {
              dispatch(endActivityKooClass(submissionId));
              kooClassSubmitTrack();
            } else {
              dispatch(
                submitAssignment({
                  assignmentID: assignmentId,
                  assignmentSubmissionID: submissionId,
                })
              );
              homeworkSubmitTrack();
              history.push('/assignment/submitted/claimkoko/0');
            }
          }}
        >
          {renderButtonText()}
        </SubmitButton>
        {score !== 100 && (
          <button
            type="button"
            className="alt-btn"
            onClick={() => {
              if (feature === 'kooQuiz') {
                dispatch(submitClaimKooQuiz(submissionId));
                kooQuizSubmitTrack();
                if (!isKooQuizSubmitting) {
                  history.push(`/kooQuiz/submitted?print=${showPrint}`);
                }
              } else if (feature === 'KooClass') {
                dispatch(endActivityKooClass(submissionId));
                kooClassSubmitTrack();
              } else {
                homeworkSubmitTrack();
                dispatch(
                  submitAssignment({
                    assignmentID: assignmentId,
                    assignmentSubmissionID: submissionId,
                  })
                );
                history.push('/assignment/submitted/claimkoko/0');
              }
            }}
          >
            {t('assignmentFinished:homework.Submit Anyway', 'Submit Anyway')}
          </button>
        )}
      </div>
    </Container>
  );
};

export default HomeworkContent;
