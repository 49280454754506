import styled from 'styled-components';

export const Container = styled.div`
  width: 73%;
  align-self: center;
  z-index: 1;
  .content-container {
    display: flex;
    justify-content: center;
    padding-top: 0%;
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 1px solid #8dcf44;
      border-radius: 8px;
      box-shadow: 5px 5px 0px 0px #8dcf44;
      width: 560px;
      min-height: 15rem;
      padding: 0.5rem 2rem 2rem;
      border-radius: 0.5rem;
      text-align: center;
      .plane {
        position: relative;
        margin-top: ${(props) => (props.claimKoko ? '-95px' : '-110px')};
        margin-left: 120%;
        @media (max-width: 751px) {
          margin-top: -90px;
          margin-left: 100%;
        }
      }
      .icon {
        margin-top: -18%;
      }
      .text-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        .success-text {
          color: #7eab00;
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 0rem;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 90%;
    position: absolute;
    top: 18%;
    .plane {
      display: none;
    }
    .text-box {
      .success-text {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    .icon {
      img {
        width: 55px;
      }
    }
  }
`;

export const ClaimKokoContainer = styled.div`
  margin-bottom: 2rem;
  .claim {
    display: flex;
    align-items: center;
    p {
      font-size: 22px;
      margin-bottom: 0rem;
      color: #72885b;
    }
    .amount {
      margin: 0rem 0.3rem;
      color: #141414;
      span {
        font-weight: 600;
      }
    }
    img {
      width: 45px;
      margin: 0rem 0.3rem;
    }
  }

  @media (max-width: 500px) {
    .claim,
    .total-credit {
      p {
        font-size: 16px;
      }

      img {
        width: 30px;
      }
    }
  }
`;

export const PlusKokoContainer = styled.div`
  font-size: 35px;
  font-weight: bold;
  color: #ffdd00;
  p {
    margin-bottom: -2rem;
    margin-left: 2.5rem;
  }
  .fade-in {
    -webkit-animation: fadeinout 5s linear forwards;
    animation: fadeinout 5s linear forwards;
  }
  @-webkit-keyframes fadeinout {
    0% {
      opacity: 0;
      -webkit-transform: translateY(0);
    }
    50% {
      opacity: 1;
      -webkit-transform: translateY(-10px);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-10px);
    }
  }

  @keyframes fadeinout {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-10px);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
`;
