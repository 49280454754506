/* eslint-disable no-nested-ternary */
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import getTitleLabel from 'helpers/getTitleLabel';
import { StyledDropdown } from './DropdownMenu.styles';

const DropdownMenu = ({
  selectedValue,
  valueKey,
  values,
  setValue,
  width,
  borderRadius,
  borderColor,
  valueTemplate,
  children,
  widthipad,
  disabled,
  feature,
  toggleIcon,
  portal,
  dataCy = 'dropdown-custom',
  noXOverflow,
  containerWidth = 'auto',
  maxWidth = '200px', // shared component, 200px is max-width from .dropdown-toggle
  menuWidth = 'auto',
  bgColor,
}) => {
  return (
    <StyledDropdown
      portal={portal}
      feature={feature}
      width={width}
      borderradius={borderRadius}
      bordercolor={borderColor}
      widthipad={widthipad}
      data-cy={dataCy}
      noXOverflow={noXOverflow}
      containerWidth={containerWidth}
      maxWidth={maxWidth}
      menuWidth={menuWidth}
      bgcolor={bgColor}
    >
      <Dropdown.Toggle variant="light" id="dropdown-custom" disabled={disabled}>
        <span>{selectedValue}</span> {toggleIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {children}
        {values.length > 0 &&
          values.map((value, index) => {
            return (
              <Dropdown.Item
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="dropdown-item-custom"
                onClick={(event) => {
                  event.preventDefault();
                  setValue(value);
                }}
                data-cy={`${feature}-item-${index + 1}`}
              >
                {getTitleLabel(value, index, valueTemplate, valueKey)}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </StyledDropdown>
  );
};

export default DropdownMenu;
