import styled from 'styled-components';
import FinishBg from 'assets/img/assignment/assignment-view/finish-bg.svg';

export const Container = styled.div`
  .top-section {
    background: url(${FinishBg});
    background-repeat: no-repeat;
    background-size: contain;
    height: 17rem;
    text-align: center;
    padding-top: 7rem;
    @media (max-width: 1445px) {
      padding-top: 5rem;
      margin-top: 2rem;
    }
    @media (max-width: 835px) {
      padding-top: 3rem;
    }
    @media (max-width: 600px) {
      background-size: 130%;
    }
    @media (max-width: 467px) {
      background-size: 150% 60%;
    }

    .text {
      padding: 0rem 11rem;
      margin-bottom: 1rem;
      @media (max-width: 835px) {
        padding: 0rem 8rem;
      }
      @media (max-width: 400px) {
        padding: 0rem;
      }
    }
    p {
      color: #040404;
      font-size: 20px;
      margin-bottom: 0rem;
      @media (max-width: 955px) {
        font-size: 16px;
      }
      @media (max-width: 835px) {
        font-size: 12px;
      }
    }
    .time {
      margin-top: 2rem;
      color: #ff7700;
      font-size: 21px;
      @media (max-width: 1445px) {
        margin-top: 0.5rem;
      }
      @media (max-width: 955px) {
        font-size: 16px;
      }
      @media (max-width: 835px) {
        font-size: 12px;
      }
    }

    @media (max-width: 500px) {
      display: flex;
      justify-content: flex-end;

      .text {
        width: 200px;
        padding: 0rem;
        margin: 1rem;
        margin-right: 2rem;
        text-align: start;
        margin-top: -2rem;
      }
    }

    @media (max-width: 400px) {
      .text {
        text-align: start;
        margin: 0.5rem;
        margin-top: -2rem;
      }
    }
  }
  .bottom-section {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
    @media (max-width: 1445px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    @media (max-width: 955px) {
      margin-top: -1rem;
    }
    @media (max-width: 835px) {
      margin-top: -6rem;
    }
    @media (max-width: 500px) {
      .submit-button {
        width: 100%;
        font-size: 18px;
      }
    }
  }
`;
