import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 0rem;
  background: #f2f2f2;
  color: #9c9c9c;
  font-size: 13px;
  border-bottom: 1px solid #cecece;
  text-align: center;
`;
