export const productTypeHierarchy = {
  premium: 1,
  plus: 2,
  standard: 3,
  school: 4,
};

export const subjectIDs = {
  1: 'Math',
  2: 'Science',
};

export const subjectNames = {
  math: 1,
  science: 2,
};
