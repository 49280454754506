import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_LEADERBOARD_V2}Leaderboard/`;

export const fetchBFFLeaderBoard = ({ weekId }) => {
  return axios
    .get(`${BASE_URL}BFF/Week/${weekId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};
export const fetchBFFFriends = ({ userId, weekId }) => {
  return axios
    .get(`${BASE_URL}BFF/Friends/User/${userId}/Week/${weekId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};
