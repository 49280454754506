import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    font-size: 47px;
    font-weight: bold;
    color: #ff7700;
  }
  .stroke {
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 1rem;
    text-shadow: 6px 10px 5px rgba(0, 0, 0, 0.16);
    color: #ffffff;
  }
  .fill {
    position: relative;
    margin-top: -4rem;
  }

  @media (max-width: 502px) {
    h3 {
      font-size: 27px;
    }

    .top-section {
      height: 8rem;
      padding-top: 1.5rem;
    }

    p {
      font-size: 18px;
    }

    .fill {
      margin-top: -2.5rem;
    }

    .stroke {
      -webkit-text-stroke-width: 0.2rem;
    }
  }
`;
