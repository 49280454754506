import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';
import Header from 'components/ProfileSettings/Header';
import PopupModal from 'components/Shared/PopupModal';
import ResultModal from 'components/Shared/ResultModal';
import Button from 'components/Shared/Button';
import FormGroup from 'components/Shared/FormGroup';
import CheckBox from 'components/Shared/CheckBox';
import ErrorModal from 'components/Shared/ErrorModal';
import {
  updatePassword,
  resetPasswordSuccess,
} from 'store/profileSettings/profileSettingsSlice';
import { Container } from './ChangePassword.styles';

const ChangePassword = () => {
  const { t } = useTranslation(['myProfile', 'errorModal']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { passwordUpdateSuccess, isLoading, isErr } = useSelector(
    (state) => state.profileSettings
  );

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypeNewPassword, setRetypeNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const closeSuccessModal = () => {
    if (passwordUpdateSuccess) {
      history.push('/profile-settings/profile');
    }
    setSuccessModal(false);
    dispatch(resetPasswordSuccess());
  };

  const changePassword = () => {
    const rawBody = {
      OldPassword: currentPassword,
      NewPassword: newPassword,
      RetypeNewPassword: retypeNewPassword,
    };
    if (
      newPassword.length >= 6 &&
      newPassword === retypeNewPassword &&
      currentPassword !== ''
    ) {
      dispatch(updatePassword(rawBody));
    }
  };

  useEffect(() => {
    if (passwordUpdateSuccess) {
      setCurrentPassword('');
      setNewPassword('');
      setRetypeNewPassword('');
      setSuccessModal(true);
    }
  }, [passwordUpdateSuccess]);

  const retypePasswordValidation = () => {
    if (newPassword !== retypeNewPassword && retypeNewPassword !== '') {
      return `${t(
        'myProfile:password.Verfication',
        'The two password you typed do not match'
      )}`;
    }
    return '';
  };

  const newPasswordValidation = () => {
    if (newPassword !== '' && newPassword.length < 6) {
      return `${t('myProfile:password.Placeholder2', 'Minimum 6 characters')}`;
    }
    return '';
  };

  const isDisabled =
    newPassword.length < 6 ||
    newPassword !== retypeNewPassword ||
    currentPassword === '';

  return (
    <>
      <PopupModal show={successModal} hide={closeSuccessModal}>
        <ResultModal
          data={passwordUpdateSuccess}
          btnText={t('myProfile:password.Confirm', 'Confirm')}
          desc={t(
            'myProfile:password.SuccessModal',
            'Your password has been changed successfully.'
          )}
          onClick={closeSuccessModal}
        />
      </PopupModal>
      <Header
        title={t('myProfile:password.ChangePassword', 'Change Password')}
      />
      <Container>
        <FormGroup
          label={t('myProfile:password.CurrentPass', 'Current Password')}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder={t(
            'myProfile:password.Placeholder1',
            'Please enter your current password'
          )}
          type="password"
        />
        <FormGroup
          label={t('myProfile:password.NewPass', 'New Password')}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder={t(
            'myProfile:password.Placeholder2',
            'Minimum 6 characters'
          )}
          validationText={newPasswordValidation()}
          type={showPassword ? 'text' : 'password'}
        >
          <span className="check-box-container">
            <CheckBox
              color="#6AC8FA"
              id="new-password"
              onChange={(e) => {
                setShowPassword(e.target.checked);
              }}
            />
            <p>{t('myProfile:password.Show', 'Show')}</p>
          </span>
        </FormGroup>
        <FormGroup
          label={t('myProfile:password.RetypePass', 'Re-type New Password')}
          value={retypeNewPassword}
          onChange={(e) => setRetypeNewPassword(e.target.value)}
          placeholder={t(
            'myProfile:password.Placeholder3',
            'Please retype password'
          )}
          validationText={retypePasswordValidation()}
          type={showPassword ? 'text' : 'password'}
        />
        <div className="btn">
          <Button
            width="300px"
            fontSize="23px"
            onClick={changePassword}
            variant={isDisabled ? 'disable' : 'primary'}
            isDisabled={isDisabled}
            dataCy="change-btn"
          >
            {isLoading
              ? t('myProfile:password.Loading', 'Loading...')
              : t('myProfile:password.OK', 'OK')}
          </Button>
        </div>
      </Container>
      {!isNil(isErr) && <ErrorModal errorMessage={isErr} />}
    </>
  );
};

export default ChangePassword;
