import HOD from 'assets/img/leaderboard/leaderboardList/hero-of-the-day.png';
import HODClose from 'assets/img/leaderboard/leaderboardList/hero-of-the-day-close.png';
import TopBrain from 'assets/img/leaderboard/leaderboardList/top-brain.png';
import TopBrainClose from 'assets/img/leaderboard/leaderboardList/top-brain-close.png';
import ChampionClass from 'assets/img/leaderboard/leaderboardList/champion-class.png';
import ChampionClassClose from 'assets/img/leaderboard/leaderboardList/champion-class-close.png';
import BFF from 'assets/img/leaderboard/leaderboardList/best-friend-forever.png';
import BFFClose from 'assets/img/leaderboard/leaderboardList/best-friend-forever-close.png';
import SuperHero from 'assets/img/leaderboard/leaderboardList/super-hero.png';
import SuperHeroClose from 'assets/img/leaderboard/leaderboardList/super-hero-close.png';
import ZodiacRace from 'assets/img/leaderboard/leaderboardList/zodiac-race.png';
import ZodiacRaceClose from 'assets/img/leaderboard/leaderboardList/zodiac-race-close.png';
import BoysVsGirls from 'assets/img/leaderboard/leaderboardList/boys-girls.png';
import BoysVsGirlsClose from 'assets/img/leaderboard/leaderboardList/boys-girls-close.png';
import WizardSchool from 'assets/img/leaderboard/leaderboardList/wizard-school.png';
import WizardSchoolClose from 'assets/img/leaderboard/leaderboardList/wizard-school-close.png';
import LionCity from 'assets/img/leaderboard/leaderboardList/lion-city.png';
import LionCityClose from 'assets/img/leaderboard/leaderboardList/lion-city-close.png';
import ScienceHOD from 'assets/img/leaderboard/leaderboardList/science/science-hero-of-the-day.png';
import ScienceTopBrain from 'assets/img/leaderboard/leaderboardList/science/science-top-brain.png';
import moment from 'moment';

import AquariusIcon from 'assets/img/leaderboard/zodiac-race/Aquarius.svg';
import AriesIcon from 'assets/img/leaderboard/zodiac-race/Aries.svg';
import CancerIcon from 'assets/img/leaderboard/zodiac-race/Cancer.svg';
import CapricornIcon from 'assets/img/leaderboard/zodiac-race/Capricorn.svg';
import LeoIcon from 'assets/img/leaderboard/zodiac-race/Leo.svg';
import LibraIcon from 'assets/img/leaderboard/zodiac-race/Libra.svg';
import PiscesIcon from 'assets/img/leaderboard/zodiac-race/Pisces.svg';
import SagittariusIcon from 'assets/img/leaderboard/zodiac-race/Sagittarius.svg';
import ScorpioIcon from 'assets/img/leaderboard/zodiac-race/Scorpio.svg';
import TaurusIcon from 'assets/img/leaderboard/zodiac-race/Taurus.svg';
import VirgoIcon from 'assets/img/leaderboard/zodiac-race/Virgo.svg';
import GeminiIcon from 'assets/img/leaderboard/zodiac-race/Gemini.svg';

import BoySerious from 'assets/img/leaderboard/boys-vs-girls/boy-serious.svg';
import BoyWin from 'assets/img/leaderboard/boys-vs-girls/boy-win.svg';
import BoyLose from 'assets/img/leaderboard/boys-vs-girls/boy-lose.svg';
import GirlSerious from 'assets/img/leaderboard/boys-vs-girls/girl-serious.svg';
import GirlWin from 'assets/img/leaderboard/boys-vs-girls/girl-win.svg';
import GirlLose from 'assets/img/leaderboard/boys-vs-girls/girl-lose.svg';

import SuperHeroPoint from 'assets/img/leaderboard/super-hero/superhero-point.png';
import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import scienceCPIcon from 'assets/img/science-cp-icon.svg';

// Helper for checking if current client date/time is within start/end date of leaderboard challenge
// startDate & endDate in MM-DD format
export const checkIfChallengeOpen = (startDate, endDate) => {
  const currentYear = moment().year();
  const startDateString = `${currentYear}-${startDate}`;
  const endDateString = `${currentYear}-${endDate}`;

  return moment().isBetween(
    startDateString,
    moment(endDateString).add(1, 'days'),
    'day',
    '[)'
  );
};

export const leaderboardList = {
  math: [
    {
      name: 'Hero of The Day',
      img: HOD,
      imgClose: HODClose,
      schedule: 'Daily Challenge: 6am to 10pm',
      translation: 'Daily Challenge HOD', // for localization
      link: '/leaderboard/hero-of-the-day/math/v1',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['hod-leaderboard'],
    },
    {
      name: 'Top Brain',
      img: TopBrain,
      imgClose: TopBrainClose,
      schedule: 'Quarterly Challenge',
      translation: 'Quarterly Challenge', // for localization
      link: '/leaderboard/top-brain/math/v1',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: [
        'topbrain-leaderboard-termly',
        'topbrain-leaderboard-classic',
      ],
    },
    {
      name: 'Champion Class',
      img: ChampionClass,
      imgClose: ChampionClassClose,
      schedule: 'Monthly Challenge',
      translation: 'Monthly Challenge', // for localization
      link: '/leaderboard/champion-class/math/v1',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: [
        'championclass-leaderboard-classic',
        'championclass-leaderboard-termly',
      ],
    },
    {
      name: 'Best Friends Forever',
      img: BFF,
      imgClose: BFFClose,
      schedule: 'Weekly Challenge: Mon to Sun',
      translation: 'Weekly Challenge BFF', // for localization
      link: '/leaderboard/best-friends-forever/v1',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['bff-leaderboard'],
    },
    {
      name: 'Super Hero',
      img: SuperHero,
      imgClose: SuperHeroClose,
      schedule: 'Weekly Challenge: Mon to Sat',
      translation: 'Weekly Challenge SH', // for localization
      link: '/leaderboard/super-hero/v1',
      status: 'open',
      featureReady: true,
      pointsIcon: SuperHeroPoint,
      configFeatureNames: ['superhero-leaderboard'],
    },
    {
      name: 'Zodiac Race',
      img: ZodiacRace,
      imgClose: ZodiacRaceClose,
      scheduleOpen: ' 01 May',
      scheduleClose: ' 31 May',
      startDateTime: '05-01',
      endDateTime: '05-31',
      link: '/leaderboard/zodiac-race',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['zodiacrace-leaderboard'],
    },
    {
      name: 'Boys vs Girls',
      img: BoysVsGirls,
      imgClose: BoysVsGirlsClose,
      scheduleOpen: ' 01 Jun',
      scheduleClose: ' 31 Jul',
      startDateTime: '05-01',
      endDateTime: '07-31',
      link: '/leaderboard/boys-vs-girls',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['boysvsgirls-leaderboard'],
    },
    {
      name: 'Wizard School',
      img: WizardSchool,
      imgClose: WizardSchoolClose,
      scheduleOpen: ' 01 Sep',
      scheduleClose: ' 30 Sep',
      startDateTime: '08-01',
      endDateTime: '09-30',
      link: '/leaderboard/wizard-school',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['wizardschool-leaderboard'],
    },
    {
      name: 'Lion City',
      img: LionCity,
      imgClose: LionCityClose,
      scheduleOpen: ' 01 Dec',
      scheduleClose: ' 31 Dec',
      startDateTime: '12-01',
      endDateTime: '12-31',
      link: '/leaderboard/lion-city',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['lioncity-leaderboard'],
    },
  ],
  science: [
    {
      name: 'Hero of The Day',
      img: ScienceHOD,
      imgClose: HODClose,
      schedule: 'Daily Challenge: 6am to 10pm',
      translation: 'Daily Challenge HOD', // for localization
      link: '/leaderboard/hero-of-the-day/science/v1',
      status: 'open',
      featureReady: true,
      pointsIcon: scienceCPIcon,
      configFeatureNames: ['hod-leaderboard'],
    },
    {
      name: 'Top Brain',
      img: ScienceTopBrain,
      imgClose: TopBrainClose,
      schedule: 'Quarterly Challenge',
      translation: 'Quarterly Challenge', // for localization
      link: '/leaderboard/top-brain/science/v1',
      status: 'open',
      featureReady: true,
      pointsIcon: scienceCPIcon,
      configFeatureNames: ['science-topbrain-leaderboard'],
    },
  ],
};

export const zodiacNameIDHashmap = {
  taurus: 2,
  gemini: 3,
  virgo: 6,
  scorpio: 8,
  cancer: 4,
  pisces: 12,
  aquarius: 11,
  libra: 7,
  sagittarius: 9,
  aries: 1,
  leo: 5,
  capricorn: 10,
};

export const zodiacs = {
  2: {
    name: 'Taurus',
    // DD-MM
    startDate: '20-04',
    endDate: '20-05',
    image: TaurusIcon,
    desc: 'Good taste, sensual, down-to-earth, pleasure-seeking',
  },
  3: {
    name: 'Gemini',
    startDate: '21-05',
    endDate: '20-06',
    image: GeminiIcon,
    desc: 'Dynamic, many talents, likes games',
  },
  6: {
    name: 'Virgo',
    startDate: '23-08',
    endDate: '22-09',
    image: VirgoIcon,
    desc: 'Successful, creative, clever, can lead others on',
  },
  8: {
    name: 'Scorpio',
    startDate: '23-10',
    endDate: '21-11',
    image: ScorpioIcon,
    desc: 'Self-reliant, powerful, wise, dominant',
  },
  4: {
    name: 'Cancer',
    startDate: '21-06',
    endDate: '22-07',
    image: CancerIcon,
    desc: 'Sensitive, friend-oriented, practical',
  },
  12: {
    name: 'Pisces',
    startDate: '19-02',
    endDate: '20-03',
    image: PiscesIcon,
    desc: 'Alluring, free, sensual, sensitive',
  },
  11: {
    name: 'Aquarius',
    startDate: '20-01',
    endDate: '18-02',
    image: AquariusIcon,
    desc: 'Sets trends, innovative, admired',
  },
  7: {
    name: 'Libra',
    startDate: '23-09',
    endDate: '22-10',
    image: LibraIcon,
    desc: 'Irresistible, adventurous, lavish',
  },
  9: {
    name: 'Sagittarius',
    startDate: '22-11',
    endDate: '21-12',
    image: SagittariusIcon,
    desc: 'Open-minded, achiever, loving',
  },
  1: {
    name: 'Aries',
    startDate: '21-03',
    endDate: '19-04',
    image: AriesIcon,
    desc: 'Brave, independent, assertive',
  },
  5: {
    name: 'Leo',
    startDate: '23-07',
    endDate: '22-08',
    image: LeoIcon,
    desc: 'Creative, popular, faithful, dominating',
  },
  10: {
    name: 'Capricorn',
    startDate: '22-12',
    endDate: '19-01',
    image: CapricornIcon,
    desc: 'Hardworking, straightforward, loyal',
  },
};

export const bvsgFaces = {
  BoyWin: {
    boy: BoyWin,
    girl: GirlLose,
  },
  BoyLose: {
    boy: BoyLose,
    girl: GirlWin,
  },
  Draw: {
    boy: BoySerious,
    girl: GirlSerious,
  },
};

// Lion City Constants

export const lionCityIdHashmap = {
  1: 'accountant',
  2: 'animator',
  3: 'architect',
  4: 'astronaut',
  5: 'chef',
  6: 'doctor',
  7: 'economist',
  8: 'fashion-designer',
  9: 'jet-fighter-pilot',
  10: 'professional-photographer',
  11: 'software-engineer',
  12: 'sports-commentator',
};

export const occupationOptions = [
  {
    id: 1,
    name: 'accountant',
  },
  {
    id: 2,
    name: 'animator',
  },
  {
    id: 3,
    name: 'architect',
  },
  {
    id: 4,
    name: 'astronaut',
  },
  {
    id: 5,
    name: 'chef',
  },
  {
    id: 6,
    name: 'doctor',
  },
  {
    id: 7,
    name: 'economist',
  },
  {
    id: 8,
    name: 'fashion-designer',
  },
  {
    id: 9,
    name: 'jet-fighter-pilot',
  },
  {
    id: 10,
    name: 'professional-photographer',
  },
  {
    id: 11,
    name: 'software-engineer',
  },
  {
    id: 12,
    name: 'sports-commentator',
  },
];

export const leaderboardFeatureNames = {
  'hod-leaderboard': {
    paths: [
      '/leaderboard/hero-of-the-day',
      '/leaderboard/science-hero-of-the-day',
    ],
    leaderboardListIndex: 0,
    subjectType: 'math',
  },
  'topbrain-leaderboard': {
    paths: ['/leaderboard/top-brain'],
    leaderboardListIndex: 1,
    subjectType: 'math',
  },
  'science-topbrain-leaderboard': {
    paths: ['/leaderboard/science-top-brain'],
    leaderboardListIndex: 1,
    subjectType: 'science',
  },
  'championclass-leaderboard': {
    paths: ['/leaderboard/champion-class'],
    leaderboardListIndex: 2,
    subjectType: 'math',
  },
  'bff-leaderboard': {
    paths: ['/leaderboard/best-friends-forever'],
    leaderboardListIndex: 3,
    subjectType: 'math',
  },
  'superhero-leaderboard': {
    paths: ['/leaderboard/super-hero'],
    leaderboardListIndex: 4,
    subjectType: 'math',
  },
  'zodiacrace-leaderboard': {
    paths: ['/leaderboard/zodiac-race'],
    leaderboardListIndex: 5,
    subjectType: 'math',
  },
  'boysvsgirls-leaderboard': {
    paths: ['/leaderboard/boys-vs-girls'],
    leaderboardListIndex: 6,
    subjectType: 'math',
  },
  'wizardschool-leaderboard': {
    paths: ['/leaderboard/wizard-school'],
    leaderboardListIndex: 7,
    subjectType: 'math',
  },
  'lioncity-leaderboard': {
    paths: ['/leaderboard/lion-city'],
    leaderboardListIndex: 8,
    subjectType: 'math',
  },
  'factualfluency-leaderboard': {
    paths: ['/leaderboard/factual-fluency/:HomeworkId'],
  },
};
