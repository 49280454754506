import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Hoverbutton } from 'components/Shared/CardHover.styles';
import { OverlayContainer } from './DashboardCard.styles';
import { CardContainer, Block } from './PlayPracticeCard.styles';

const Overlay = ({ type }) => {
  const { t } = useTranslation(['dashboard']);
  // training features
  const practiceFeatures = [
    {
      feature: t('dashboard:cards.Mission.name', 'Mission'),
      link: '/mission/curriculum-list',
    },
    { feature: t('dashboard:cards.KooQuiz.name', 'KooQuiz'), link: '/kooQuiz' },
    {
      feature: t('dashboard:cards.Assignment.name', 'Assignment'),
      link: '/assignment',
    },
  ];

  // challenge features
  const playFeatures = [
    [
      {
        feature: t('dashboard:dailyChallenge.name', 'Daily Challenge'),
        link: '/challenges',
      },
      {
        feature: t('dashboard:cards.Brain Games.name', 'Brain Games'),
        link: '/brain-game',
      },
    ],
    [
      {
        feature: t('dashboard:cards.Multiplayer.name', 'Multiplayer'),
        link: '/multiplayer',
      },
      {
        feature: t('dashboard:cards.StoryMath.name', 'Story'),
        link: '/story/math',
      },
    ],
  ];

  return (
    <OverlayContainer isFeatureReady>
      <div className="overlay-container">
        <div className="overlay-block">
          {type === 'Practice' ? (
            <div className="features-list">
              {practiceFeatures.map((item) => (
                <p key={item.feature} className="features">
                  {item.feature}
                </p>
              ))}
            </div>
          ) : (
            <div className="features-list play">
              {playFeatures.map((item, index) => (
                <div key={`item ${index + 1}`} className="feature-item">
                  {item.map((feature) => (
                    <p key={feature.feature} className="features">
                      {feature.feature}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          )}
          <Hoverbutton btnColor={type === 'Play' ? '#DE9600' : '#3C9DE3'}>
            {t('dashboard:dailyChallenge.button', 'Start')}
          </Hoverbutton>
        </div>
      </div>
    </OverlayContainer>
  );
};

const PlayPracticeCard = ({ type, url, img, hoverImg }) => {
  const history = useHistory();
  const { t } = useTranslation(['dashboardCards']);
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <CardContainer
      type={type}
      onClick={() => history.push(url)}
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => setShowOverlay(false)}
      hoverImg={hoverImg}
    >
      <Block
        type={type}
        showOverlay={showOverlay}
        data-cy={`${type}-card`}
        img={img}
      >
        {type === 'Practice' && (
          <div className="category">
            <span>{t(`dashboardCards:premiumType.${type}`, type)}</span>
          </div>
        )}
      </Block>
      {showOverlay && <Overlay type={type} />}
    </CardContainer>
  );
};

export default PlayPracticeCard;
