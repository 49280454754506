/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import StyledDropdown from './DropdownWithSearch.styles';

const searchResultInitialState = {
  status: 'idle',
  List: [],
  PageIndex: 0,
  PageSize: 0,
  TotalCount: 0,
  TotalPages: 0,
  LastNoInPage: 0,
  HasPreviousPage: false,
  HasNextPage: false,
  error: null,
};

const CustomMenu = React.forwardRef(
  (
    {
      onSelectDropdownItem,
      fetchSearchResultsFunc,
      fetchInitialUserListFunc,
      loadMoreInitialUserListFunc,
      className,
      'aria-labelledby': labeledBy,
    },
    ref
  ) => {
    const { t } = useTranslation(['common']);
    const [searchValue, setSearchValue] = useState('');
    const [initialUserList, setInitialUserList] = useState(
      searchResultInitialState
    );
    const [searchResults, setSearchResults] = useState(
      searchResultInitialState
    );
    const showSearchResults = searchValue.length > 0;

    // Event Handlers
    const onChangeSearchInputHandler = (e) => {
      setSearchValue(e.target.value.trim());
    };
    const onScrollEndOfInitialListHandler = () => {
      setInitialUserList((prevState) => ({ ...prevState, status: 'pending' }));
      if (typeof loadMoreInitialUserListFunc === 'function') {
        loadMoreInitialUserListFunc(initialUserList.PageIndex + 1)
          .then((result) => {
            setInitialUserList((prevState) => ({
              ...prevState,
              ...result,
              List: [...prevState.List, ...result.List],
              status: 'fulfilled',
            }));
          })
          .catch((err) => {
            setInitialUserList((prevState) => ({
              ...prevState,
              status: 'rejected',
              error: err.message,
            }));
          });
      } else {
        fetchInitialUserListFunc(initialUserList.PageIndex + 1).then(
          (result) => {
            setInitialUserList((prevState) => ({
              ...prevState,
              ...result,
              List: [...prevState.List, ...result.List],
              status: 'fulfilled',
            }));
          }
        );
      }
    };
    const onScrollEndOfResultsHandler = () => {
      setSearchResults((prevState) => ({ ...prevState, status: 'pending' }));
      fetchSearchResultsFunc(searchValue, searchResults.PageIndex + 1)
        .then((res) => {
          setSearchResults((prevState) => ({
            ...prevState,
            ...res,
            List: [
              ...prevState.List,
              ...res.List.map((user) => ({
                ...user,
                id: user.Id,
                name: user.FullName,
              })),
            ],
            status: 'fulfilled',
          }));
        })
        .catch((err) => {
          console.error(err.message);
          setSearchResults((prevState) => ({
            ...prevState,
            status: 'rejected',
            error: err.message,
          }));
        });
    };
    // UseEffects
    useEffect(() => {
      if (typeof fetchInitialUserListFunc !== 'function') return;
      setSearchResults(searchResultInitialState);
      setSearchValue('');
      fetchInitialUserListFunc(0).then((result) => {
        setInitialUserList((prevState) => ({
          ...prevState,
          ...result,
          status: 'fulfilled',
        }));
      });
    }, [fetchInitialUserListFunc]);
    useEffect(() => {
      if (searchValue.length <= 0) {
        setSearchResults(searchResultInitialState);
      }
      const delayDebounceFn = setTimeout(() => {
        if (searchValue.length > 0) {
          setSearchResults((prevState) => ({
            ...prevState,
            status: 'pending',
          }));
          if (typeof fetchSearchResultsFunc !== 'function') return;
          fetchSearchResultsFunc(searchValue, 0)
            .then((res) => {
              setSearchResults((prevState) => ({
                ...prevState,
                ...res,
                List: res.List.map((user) => ({
                  ...user,
                  id: user.Id,
                  name: user.FullName,
                })),
                status: 'fulfilled',
              }));
            })
            .catch((err) => {
              console.error(err.message);
              setSearchResults({
                ...searchResultInitialState,
                status: 'rejected',
                error: err.message,
              });
            });
        }
      }, 1500);
      return () => clearTimeout(delayDebounceFn);
    }, [fetchSearchResultsFunc, searchValue]);
    return (
      <div
        ref={ref}
        style={{ height: '150px', overflowY: 'auto' }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <input
          placeholder={t('common:search', 'Search')}
          type="search"
          onChange={onChangeSearchInputHandler}
          value={searchValue}
          style={{ margin: '0 6px', width: '90%' }}
        />
        <InfiniteScroll
          initialLoad
          threshold={20}
          loadMore={
            showSearchResults
              ? onScrollEndOfResultsHandler
              : onScrollEndOfInitialListHandler
          }
          hasMore={
            showSearchResults
              ? searchResults.HasNextPage === true &&
                searchResults.status === 'fulfilled'
              : initialUserList.HasNextPage === true &&
                initialUserList.status === 'fulfilled'
          }
          useWindow={false}
        >
          <ul className="list-unstyled">
            {showSearchResults &&
              searchResults.List.map((result) => (
                <Dropdown.Item
                  key={`search-${result.id}`}
                  onClick={onSelectDropdownItem(result)}
                >
                  {result.name}
                </Dropdown.Item>
              ))}
            {!showSearchResults &&
              initialUserList.List.map((result) => (
                <Dropdown.Item
                  key={result.id}
                  onClick={onSelectDropdownItem(result)}
                >
                  {result.name}
                </Dropdown.Item>
              ))}
            {(searchResults.status === 'pending' ||
              initialUserList.status === 'pending') && (
              <p key="loader" style={{ width: '100%', textAlign: 'center' }}>
                {`${t('common:loading', 'Loading')}...`}
              </p>
            )}
          </ul>
        </InfiniteScroll>
      </div>
    );
  }
);

const DropdownWithSearch = ({
  placeholder,
  isDisabled = false,
  fetchSearchResultsFunc = null,
  opponentType = null,
  setOpponentTypeFunc = () => {},
  setOpponentId = () => {},
  setNewSelectedOpponentId = () => {},
  fetchInitialUserListFunc,
  loadMoreInitialUserListFunc,
  customSetItemFunc = () => {},
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setSelectedItem((prevState) => {
      if (prevState) return null;
      return prevState;
    });
  }, [placeholder]);
  // Event Handlers
  const selectDropdownItemHandler = (item) => {
    return () => {
      customSetItemFunc(item);
      setSelectedItem(item);
      setOpponentTypeFunc();
      setOpponentId(item.id);
      if (opponentType !== null) {
        setNewSelectedOpponentId(opponentType, item.id);
      }
    };
  };
  return (
    <StyledDropdown>
      <Dropdown.Toggle id="bff-dropdown" variant="light" disabled={isDisabled}>
        {selectedItem?.name ?? placeholder}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CustomMenu}
        flip={false}
        onSelectDropdownItem={selectDropdownItemHandler}
        fetchSearchResultsFunc={fetchSearchResultsFunc}
        fetchInitialUserListFunc={fetchInitialUserListFunc}
        loadMoreInitialUserListFunc={loadMoreInitialUserListFunc}
      />
    </StyledDropdown>
  );
};

export default DropdownWithSearch;
