import React from 'react';
import PopupModal from 'components/Shared/PopupModal';
import HotsModalBg from 'assets/img/mission/hots-modal-bg.png';
import {
  ModalContainer,
  ModalContent,
  StyledButton,
} from './HotsInfoModal.styles';

const HotsInfoModal = ({ show = false, setShowInfoModal }) => {
  const closeModalHander = () => {
    setShowInfoModal(false);
  };
  return (
    <PopupModal show={show}>
      <ModalContainer>
        <img src={HotsModalBg} alt="" />
        <ModalContent>
          <p className="title">Higher-Order Thinking Skills (HOTS)</p>
          <p className="desc">
            Higher-Order Thinking Skills (HOTS) in Mathematics are the ability
            to reason, evaluate, and synthesise to solve a problem. We can
            develop HOTS by learning different heuristics in Mathematics.
          </p>
          <StyledButton onClick={closeModalHander}>Got it</StyledButton>
        </ModalContent>
      </ModalContainer>
    </PopupModal>
  );
};

export default HotsInfoModal;
