import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: ${(props) =>
    props.isCurrentUser === true ? '#FFEF77' : 'white'};
  box-shadow: 0 3px 6px rgba(175, 175, 175, 0.16);
  min-width: 842px;
  width: calc(100vw * 0.616398);
  padding: 0.5rem;
  margin-top: 15px;
  .avatar {
    margin-right: 1rem;
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
`;

export const DetailsSection = styled.div`
  width: 60%;
  text-align: left;
  p {
    margin: 0;
  }
  .student-name {
    font-size: 1.125rem;
    font-weight: 600;
  }
  .student-bffcode {
    font-size: 0.75rem;
    color: rgba(20, 19, 19, 0.58);
  }
  .school-name {
    font-size: 0.75rem;
    color: rgba(20, 19, 19, 0.58);
    margin-left: 0.5rem;
  }
`;
