import React, { useState, useEffect } from 'react';
import xmlParser from 'helpers/xmlParser';
import { isNil } from 'ramda';
import convertTagsToHtml from 'helpers/convertTagsToHtml';
import HTMLReactParser from 'html-react-parser';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import SolutionFallback from './SolutionFallback';

export const Container = styled.div`
  .fraction-wrap {
    display: inline-block;
    vertical-align: middle;
  }
  .fraction-whole-part {
    display: inline-block;
    vertical-align: baseline;
  }
  .fraction-numerator-denominator-part {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.25em;
  }
  .fraction-numerator {
    display: block;
    border-bottom: 1px solid black;
    padding: 0.25em;
    text-align: center;
  }
  .fraction-denominator {
    display: block;
    padding: 0.25em;
    text-align: center;
  }
`;

const Solution = ({ xml }) => {
  const [questionSolution, setQuestionSolution] = useState(null);
  const [renderError, setRenderError] = useState(false);
  useEffect(() => {
    if (!isNil(xml)) {
      const filteredXml = xml.replace(/\\/g, '');
      const parsed = xmlParser(filteredXml);
      if (parsed.solution.length > 0) {
        try {
          const content = HTMLReactParser(convertTagsToHtml(parsed.solution));
          setQuestionSolution(content);
        } catch (err) {
          setRenderError(true);
        }
      } else {
        setQuestionSolution(null);
      }
    }
    return () => {
      setRenderError(false);
    };
  }, [xml]);
  if (renderError) {
    return <SolutionFallback />;
  }
  return (
    <Container data-cy="solution-data">
      <ErrorBoundary
        FallbackComponent={() => {
          return <SolutionFallback />;
        }}
      >
        {!isNil(questionSolution) && questionSolution}
      </ErrorBoundary>
    </Container>
  );
};

export default Solution;
