import styled from 'styled-components';
import allBadge from 'constants/allBadge';
import StyledLeaderboard from 'constants/leaderboard/StyledLeaderboard';

const listName = ({ challengeMode, active }) => {
  if (challengeMode === 'zodiacRace') return 4;
  if (challengeMode === 'wizardSchool' && active === 1) return 1;
  return 2;
};

const listSchool = ({ challengeMode, active }) => {
  if (challengeMode === 'zodiacRace') return 0;
  if (challengeMode === 'wizardSchool' && active === 1) return 0;
  if (challengeMode === 'lionCity') return 0;
  return 2;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #ffffff;
  width: ${({ challengeMode }) =>
    challengeMode === 'hallOfFame' ? '100%' : '70%'};
  height: 100%;
  padding: 20px 30px;
  /* margin-bottom: 20px; */
  .list-headings {
    display: flex;
    width: 100%;
    padding: 0 5px 0 35px;
  }
  .nearby-container {
    background-color: ${({ subject }) =>
      StyledLeaderboard[subject].nearbyBgColor};
    border: 1px solid transparent;
    border-radius: 20px;
    margin: 5px 0 20px;
    display: flex;
    justify-content: center;
    .nearby {
      border: ${({ subject }) =>
        `1px solid ${StyledLeaderboard[subject].nearbyBdColor}`};
      background-color: ${({ subject }) =>
        StyledLeaderboard[subject].nearbyBtnColor};
      border-radius: 20px;
      color: ${({ subject }) => StyledLeaderboard[subject].nearbyTxtColor};
      font-size: 18px;
      padding: 5px 10px;
      width: 30%;
      text-align: center;
      cursor: pointer;
    }
  }
  .current-user {
    background-color: #fece18 !important;
  }
`;

export const Headings = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ subject }) => StyledLeaderboard[subject].headingColor};
  font-weight: 600;
  flex: ${({ flex }) => flex};
`;

export const Lists = styled.div`
  display: flex;
  margin-bottom: 10px;
  border: 1px solid black;
  align-items: center;
  padding: 3px 10px 3px 45px;
  border-radius: 30px;
  position: relative;
  font-size: 14px;
  cursor: ${({ challengeMode }) =>
    ['topBrain', 'heroOfTheDay', 'hallOfFame'].includes(challengeMode)
      ? 'default'
      : 'pointer'};
  :nth-of-type(odd) {
    background-color: ${({ subject }) => StyledLeaderboard[subject].oddBgColor};
    border-color: ${({ subject }) => StyledLeaderboard[subject].oddBdColor};
  }
  :nth-of-type(even) {
    background-color: ${({ subject }) =>
      StyledLeaderboard[subject].evenBgColor};
    border-color: ${({ subject }) => StyledLeaderboard[subject].evenBdColor};
  }
  border-color: ${(props) =>
    props.currPos !== 'default'
      ? allBadge[props.currPos].borderCol
      : 'auto'}!important;
  background-image: ${(props) => allBadge[props.currPos].gradient};
  .positions {
    color: ${(props) => allBadge[props.currPos].badgeColor};
    background: url(${(props) => allBadge[props.currPos].bgImg});
    position: absolute;
    left: ${(props) => (props.currPos === 'default' ? '-8px' : '-12px')};
    height: ${(props) => (props.currPos === 'default' ? '30px' : '40px')};
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    background-repeat: no-repeat;
    background-size: 50px 100%;
  }
  .list-name {
    flex: ${({ challengeMode, active }) => listName({ challengeMode, active })};
  }
  .bff-friends {
    color: #db5d16;
  }
  .zodiac-name {
    font-size: 18px;
  }
  .zodiac-desc {
    margin-left: 10px;
  }
  .country-img-logo {
    width: 40px;
    height: 25px;
    margin-right: 10px;
    border: 1px solid #707070;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .list-school-name {
    flex: ${({ challengeMode, active }) =>
      listSchool({ challengeMode, active })};
    display: flex;
    align-items: center;
    .country-img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border: 1px solid #707070;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .school-img-container {
      width: 30px;
      height: 30px;
      border: 1px solid #d4ecf5;
      border-radius: 5px;
      margin-right: 5px;
      background-color: #ffffff;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .list-school {
    flex: ${({ challengeMode, active }) =>
      listSchool({ challengeMode, active })};
    display: flex;
    align-items: center;
    .country-img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border: 1px solid #707070;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .school-img-container {
      width: 30px;
      height: 30px;
      border: 1px solid #d4ecf5;
      border-radius: 5px;
      margin-right: 5px;
      background-color: #ffffff;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .list-points {
    flex: 1;
    font-size: 17px;
    font-weight: 700;

    padding-left: ${({ challengeMode, subject }) =>
      challengeMode === 'wizardSchool' || challengeMode === 'lionCity'
        ? 2
        : subject === 2
        ? '0%'
        : '5%'};
    img {
      margin: 0 5px;
    }

    .total-score {
      font-weight: normal;
    }
    .country-img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border: 1px solid #707070;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        margin: 0 0px !important;
      }
    }
  }
  .list-achivement {
    flex: 1;
  }
`;

export const ClassList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  border: 1px solid black;
  padding: 3px 0px;
  font-size: 16px;
  border: 1px solid #fac500;
  border-radius: 30px;
  user-select: none;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #ffdc5d,
    #ffffff,
    #ffc903,
    #ffc903,
    #ffc903,
    #ffc903,
    #ffca07
  );
  span {
    font-size: 18px;
  }
  .total-participants,
  .total-details {
    font-size: 14px;
  }
  .total-participants {
    span {
      font-weight: bold;
    }
  }
`;

export const OccupationImg = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border: 1px solid #707070;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .occupation-img {
  }
`;
