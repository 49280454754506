/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  ProficiencyIcons,
  HighScoreIcons,
  MasteryLevel,
  CompetencyLevel,
  ProgressStatus,
} from 'constants/mission';
import DifficultyChillis from 'components/Shared/DifficultyChillis';
import HighscoreStars from 'components/Mission/HighscoreStars';
import PlayVideoIcon from 'assets/img/mission/play-video.png';
import { ProgressBar } from 'react-bootstrap';
import useIsMobile from 'hooks/responsive/useIsMobile';
import {
  Container,
  SkillContainer,
  SkillCard,
  Actions,
  StartBtn,
} from './SkillsList.styles';

// HELPERS
// TODO: Fix logic for displaying practice button text
const PracticeButtonText = (progressStatus, canClaim) => {
  if (progressStatus === 'not-started') {
    return 'practice';
  }
  if (progressStatus === 'in-progress') {
    return 'continue';
  }
  if (canClaim) {
    return 'claimKoKo';
  }
  return 'tryAgain';
};

const showIcon = (viewType, progressStatus, competencyLevel, masteryLevel) => {
  if (viewType === 'proficiency') {
    return ProficiencyIcons[CompetencyLevel[competencyLevel]];
  }
  if (ProgressStatus[progressStatus] === 'not-started') {
    return null;
  }
  return HighScoreIcons[MasteryLevel(masteryLevel)];
};

const SkillsList = ({
  data,
  type,
  setSelectedVideo,
  setShowVideoModal,
  curriculumId,
  subject,
}) => {
  const { t, i18n } = useTranslation(['selfPractice']);
  const isMobile = useIsMobile();
  const history = useHistory();

  return (
    <Container type={type}>
      {data.map((skill, index) => {
        const skillCardContent = () => {
          return (
            <>
              <span className="skill-number">{index + 1}</span>
              {type === 'highscore' && (
                <HighscoreStars
                  highscore={
                    ProgressStatus[skill.ProgressStatus] === 'not-started'
                      ? null
                      : MasteryLevel(skill.MasteryLevel)
                  }
                />
              )}
              <span className="skill-name">{skill.AliasSkillName}</span>
              <DifficultyChillis
                value={skill.Difficulity}
                className="hide-mobile"
              />
              {type === 'proficiency' && (
                <>
                  <span className="national-avg">
                    {Math.round(skill.NationalProficiencyAverage * 100)}%
                  </span>
                  <span className="proficiency">
                    {Math.round(skill.UserProficiency * 100)}%
                  </span>
                  <span className="skills-mastered">{`${
                    skill.CorrectAttempt
                  }  ${t('selfPractice:skillsList.outOf', 'out of')} ${
                    skill.TotalAttempt
                  }`}</span>
                </>
              )}
            </>
          );
        };

        return (
          <SkillContainer
            // eslint-disable-next-line react/no-array-index-key
            key={`${skill.TopicSkillId}-${index}`}
            viewType={type}
            hideIcon={
              showIcon(
                type,
                skill.ProgressStatus,
                skill.CompetencyLevel,
                skill.MasteryLevel
              ) === null
            }
          >
            <img
              className="proficiency-icon"
              src={showIcon(
                type,
                skill.ProgressStatus,
                skill.CompetencyLevel,
                skill.MasteryLevel
              )}
              alt={
                type === 'proficiency'
                  ? CompetencyLevel[skill.CompetencyLevel]
                  : MasteryLevel(skill.MasteryLevel)
              }
            />
            <SkillCard
              proficiencyLevel={
                type === 'proficiency'
                  ? CompetencyLevel[skill.CompetencyLevel]
                  : 'incomplete'
              }
              key={skill.id}
              type={type}
              index={index}
              highScoreTitle={[
                t('selfPractice:toggle.highScore', 'High Score'),
                t('selfPractice:skillsList.skillName', 'Skill Name'),
                t('selfPractice:skillsList.difficulty', 'Difficulty'),
                t('selfPractice:skillsList.tutorial', 'Tutorial'),
              ]}
              proficiencyTitle={[
                t('selfPractice:skillsList.difficulty', 'Difficulty'),
                t('selfPractice:skillsList.nationalAvg', 'National Avg'),
                t(
                  'selfPractice:skillsList.yourProficiency',
                  'Your Proficiency'
                ),
              ]}
              language={i18n.language}
            >
              {isMobile ? (
                <div className="skill-container">{skillCardContent()}</div>
              ) : (
                skillCardContent()
              )}
              {type === 'highscore' && (
                <Actions className="actions">
                  <div className="action-container">
                    <img
                      src={PlayVideoIcon}
                      alt="Play Video"
                      className="play-video"
                      style={{
                        visibility:
                          skill.VideoCode !== null ? 'normal' : 'hidden',
                      }}
                      onClick={() => {
                        setSelectedVideo(skill.VideoCode);
                        setShowVideoModal(true);
                      }}
                    />
                    <DifficultyChillis
                      value={skill.Difficulity}
                      className="hide-desktop"
                    />
                  </div>
                  <StartBtn
                    data-cy={`btn-${skill.TopicSkillId}`}
                    isActive={false}
                    onClick={() => {
                      if (
                        PracticeButtonText(
                          ProgressStatus[skill.ProgressStatus],
                          skill.CanClaim
                        ) === 'claimKoKo'
                      ) {
                        history.push({
                          pathname: `/mystery-koko/${curriculumId}/${skill.TopicSkillId}`,
                          state: {
                            subject,
                          },
                        });
                      } else {
                        history.push({
                          pathname: `/mission/self-practice/${curriculumId}/question-view/${skill.TopicSkillId}`,
                          state: {
                            subject,
                          },
                        });
                      }
                    }}
                    textLength={
                      t(
                        `selfPractice:skillsList.${PracticeButtonText(
                          ProgressStatus[skill.ProgressStatus],
                          skill.CanClaim
                        )}`
                      ).length
                    }
                  >
                    {t(
                      `selfPractice:skillsList.${PracticeButtonText(
                        ProgressStatus[skill.ProgressStatus],
                        skill.CanClaim
                      )}`
                    )}
                    {ProgressStatus[skill.ProgressStatus] === 'in-progress' && (
                      <ProgressBar
                        variant="warning"
                        now={skill.CurrentProgressPercentage}
                      />
                    )}
                  </StartBtn>
                </Actions>
              )}
            </SkillCard>
          </SkillContainer>
        );
      })}
    </Container>
  );
};

export default SkillsList;
