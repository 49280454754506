import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getBFFDetails,
  getIncomingBFFRequests,
} from 'store/friends/friendsSlice';
import moment from 'moment';
import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import Tabs from 'components/Friends/Tabs';
import MyFriends from 'components/Friends/MyFriends';
import Incoming from 'components/Friends/Incoming';
import Sent from 'components/Friends/Sent';
import BackButton from 'components/Shared/BackButton';

import { Banner, Bg } from './FriendsContainer.styles';

const FriendsContainer = ({ activeTab }) => {
  const { t } = useTranslation(['friends', 'common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { BFFDetails, isLoading, myFriends, incomingRequests } = useSelector(
    (state) => state.friends
  );
  useEffect(() => {
    dispatch(getBFFDetails());
    dispatch(getIncomingBFFRequests({ pageIndex: 0, pageSize: 10 }));
  }, [dispatch]);

  const changeTab = (val) => {
    if (val !== 'myFriends') {
      history.push(`/friends/${val}`);
    } else {
      history.push(`/friends`);
      dispatch(getBFFDetails());
    }
  };

  return (
    <>
      <Banner content={t('friends:banner.bff', 'Best Friends Forever')}>
        <BackButton
          backText={t('common:back', 'Back')}
          btnBgColor="rgba(0,163,244,0.6)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/dashboard')}
          styles={{ position: 'absolute', left: '4.3%', top: '4.7rem' }}
        />
        <p className="title">
          {t('friends:banner.bff', 'Best Friends Forever')}
        </p>
        <div className="header-sidebar">
          {!isLoading && BFFDetails !== null && (
            <>
              <div className="side-left">
                <div className="sidebar-heading">
                  {t('friends:banner.myBffcode', 'My BFF Code')}
                </div>
                <div style={{ fontWeight: 700 }}>
                  {BFFDetails.MyBaseBffModel.BFFCode}
                </div>
              </div>
              <div className="side-right">
                <div className="sidebar-heading">
                  {t('friends:banner.teamCp', 'My team’s CPs this week')}
                </div>
                <div>
                  <img src={CPIcon} alt="CPIcon" />
                  {BFFDetails.myteamCppoints}
                </div>
                <div className="sidebar-date">
                  {moment(BFFDetails.WeekStartDate).format('DD MMM YYYY')} -{' '}
                  {moment(BFFDetails.WeekEndDate).format('DD MMM YYYY')}
                </div>
              </div>
            </>
          )}
        </div>
      </Banner>
      <Tabs
        activeTab={activeTab}
        friendVal={myFriends.length}
        incoming={incomingRequests.TotalCount ? incomingRequests.TotalCount : 0}
        setActiveTab={changeTab}
      />
      {activeTab === 'myFriends' && (
        <Bg>
          <MyFriends />
        </Bg>
      )}
      {activeTab === 'incoming' && <Incoming />}
      {activeTab === 'sent' && <Sent />}
    </>
  );
};

export default FriendsContainer;
