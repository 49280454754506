import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetFriendInfo } from 'store/friends/friendsSlice';
import ButtonsGroup from './ButtonsGroup';
import Spinner from 'components/Shared/Spinner';
import AvatarPlaceholder from 'assets/img/avatar.png';
import { Container } from './BFFDetails.styles';

import { useSelector } from 'react-redux';

const BFFDetails = ({ setInputCode, setbffDetails, setinputCaptcha }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['friends']);
  const { friendInfo, friendInfoLoading } = useSelector(
    (state) => state.friends
  );

  const backHandler = () => {
    setbffDetails(false);
    setInputCode(true);
    dispatch(resetFriendInfo());
  };
  const inviteHandler = () => {
    setbffDetails(false);
    setinputCaptcha(true);
  };

  return (
    <Container>
      {friendInfoLoading && <Spinner />}
      {!friendInfoLoading && friendInfo !== null && (
        <>
          <div className="info">
            <img
              src={friendInfo.UserAvatarImage}
              alt="avatar"
              onError={(e) => {
                e.target.src = AvatarPlaceholder;
              }}
            />
            <div className="details">
              <p className="bff-name">{friendInfo.Fullname}</p>
              <p className="bff-school">{friendInfo.SchoolName}</p>
              <p className="bff-code">
                {t('friends:addFriend.bffcode', 'BFF code')}:
                <span>{friendInfo.BFFCode}</span>
              </p>
            </div>
          </div>
          <ButtonsGroup
            cancelText={t('friends:addFriend.back', 'Back')}
            nextText={t('friends:addFriend.invite', 'Invite')}
            cancelFunction={backHandler}
            nextFunction={inviteHandler}
          />
        </>
      )}
    </Container>
  );
};

export default BFFDetails;
