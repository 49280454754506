import styled from 'styled-components';

const Button = styled.button`
  border: none;
  margin: auto;
  border-radius: 24px;
  color: ${({ textColor }) => textColor};
  display: inline-block;
  height: ${({ small }) => (small ? `34px` : `44px`)};
  line-height: ${({ small }) => (small ? `34px` : `44px`)};
  font-size: 22px;
  padding: ${({ small }) => (small ? `0 1em` : `0 2em`)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-family: Arial, sans-serif;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ textColorHover, textColor }) => textColorHover || textColor};
    background-color: ${({ backgroundColorHover, backgroundColor }) =>
      backgroundColorHover || backgroundColor};
  }
  &[disabled] {
    background-color: #c3c3c3;
    cursor: not-allowed;
  }
`;

export default Button;
