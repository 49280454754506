import styled from 'styled-components';

export const StarsContainer = styled.span`
  img {
    margin-right: 0.2rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;
