import styled from 'styled-components';

const TOGGLE_COLORS = {
  active: {
    bg: '#FFCA05',
    color: '#3E3D32',
  },
  inActive: {
    bg: 'rgb(155, 98, 82, 0.16)',
    color: '#BAB689',
  },
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  div {
    text-align: center;
    width: 115px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    box-shadow: 0 0 0pt 1px rgb(255, 255, 255, 0.16);
  }
`;

export const Term = styled.div`
  border: ${({ active }) =>
    active ? '2px solid #ffffff' : '2px solid rgb(255, 255, 255, 0.16)'};
  border-radius: 20px;
  border-top-right-radius: ${({ active }) => (active ? '20px' : '0')};
  border-bottom-right-radius: ${({ active }) => (active ? '20px' : '0')};
  z-index: 1;
  background: ${({ active }) =>
    active ? TOGGLE_COLORS.active.bg : TOGGLE_COLORS.inActive.bg};
  color: ${({ active }) =>
    active ? TOGGLE_COLORS.active.color : TOGGLE_COLORS.inActive.color};
  font-size: ${({ active }) => (active ? '18px' : '14px')};
`;

export const Classic = styled.div`
  width: 80px;
  border: ${({ active }) =>
    active ? '2px solid #ffffff' : '2px solid rgb(255, 255, 255, 0.16)'};
  border-radius: 20px;
  border-top-left-radius: ${({ active }) => (active ? '20px' : '0')};
  border-bottom-left-radius: ${({ active }) => (active ? '20px' : '0')};
  z-index: ${({ active }) => (active ? '2' : '0')};
  background: ${({ active }) =>
    active ? TOGGLE_COLORS.active.bg : TOGGLE_COLORS.inActive.bg};
  color: ${({ active }) =>
    active ? TOGGLE_COLORS.active.color : TOGGLE_COLORS.inActive.color};
  position: relative;
  right: 20px;
  font-size: ${({ active }) => (active ? '18px' : '14px')};
`;
