import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from 'components/Shared/Card/Card';
import DetailsInfo from 'components/Shared/DetailsInfo';
import Button from 'components/Shared/Button';
import Spinner from 'components/Shared/Spinner';
import SuperScoreIcon from 'assets/img/dailychallenge/icon-super-score.svg';
import LockedIcon from 'assets/img/icon-locked.svg';
import { superHeroChallengeStatusIDs } from 'constants/superHero';

import {
  Container,
  Rewards,
  LeftContent,
  RightContent,
  StyledErrMsg,
  StyledReloadBtn,
} from './SuperHeroCard.styles';

const SuperHeroCardLeft = ({
  t,
  challengeStatus,
  challengeType,
  superHeroChallengeWithoutImageData,
  superHeroChallengeWithoutImageIsLoading,
  superHeroChallengeWithoutImageIsError,
  challengeOpenImg,
  challengeCloseImg,
  challengeName,
  challengeDesc,
  onReloadScore,
}) => {
  const scoreType = () => {
    switch (challengeType) {
      case 'super-vision-challenge':
        return (
          superHeroChallengeWithoutImageData &&
          superHeroChallengeWithoutImageData.Supervision.Score
        );
      case 'super-speed-challenge':
        return (
          superHeroChallengeWithoutImageData &&
          superHeroChallengeWithoutImageData.Superspeed.Score
        );
      default:
        return '0';
    }
  };

  const superHeroScore = () => {
    const renderResult = () => {
      if (superHeroChallengeWithoutImageIsLoading) {
        return <Spinner small top="-26px" />;
      }
      if (superHeroChallengeWithoutImageIsError) {
        return (
          <StyledErrMsg data-cy="error-response">
            {superHeroChallengeWithoutImageIsError}
            <StyledReloadBtn onClick={onReloadScore}>
              {t('common:reload', 'Reload')}
            </StyledReloadBtn>
          </StyledErrMsg>
        );
      }
      return <span data-cy="score-value">{scoreType(challengeType)}</span>;
    };
    return (
      <Rewards>
        <img
          src={SuperScoreIcon}
          alt="SuperScore"
          style={{ marginRight: '0.25rem' }}
        />
        {renderResult()}
      </Rewards>
    );
  };
  return (
    <LeftContent
      status={challengeStatus}
      closeImg={challengeCloseImg}
      openImg={challengeOpenImg}
    >
      <div className="subcard" />
      <div className="info-section">
        <div className="challenge-name">
          {challengeName}
          <span>{challengeDesc}</span>
        </div>
        <div className="detail-one">
          <DetailsInfo
            direction="column"
            category="Opening Hours"
            info={t(
              'challengeList:schedule',
              '6am to 10pm, Monday to Saturday'
            )}
          />
        </div>
        <div className="detail-two">
          <DetailsInfo
            direction="column"
            category="Score of the Week"
            info={superHeroScore()}
          />
        </div>
      </div>
    </LeftContent>
  );
};

const SuperHeroCardRight = ({ t, challengeStatus, challengeLink }) => {
  const history = useHistory();

  /**
   * @param {'locked' | 'open' | 'incomplete' | 'completed' | 'not opening'} challengeStatus - 'locked' status should be deprecated.
   * @returns Different states correspond to different buttons
   */
  const rednerContents = () => {
    switch (challengeStatus) {
      case 'locked':
        return {
          button: (
            <Button
              isDisabled
              variant="disable"
              fontSize="22px"
              fontWeight="600"
              dataCy="locked"
            >
              {t('challengeList:startChal', 'Start Challenge')}
            </Button>
          ),
        };
      case 'open':
      case 'incomplete':
        return {
          button: (
            <Button
              variant="primary"
              fontSize="22px"
              fontWeight="600"
              onClick={() => {
                history.push(challengeLink);
              }}
            >
              {t('challengeList:startChal', 'Start Challenge')}
            </Button>
          ),
        };
      case 'completed':
        return {
          button: (
            <Button
              variant="secondary"
              fontSize="22px"
              fontWeight="600"
              onClick={() => {
                history.push(challengeLink);
              }}
            >
              {t('challengeList:viewChal', 'View Challenge')}
            </Button>
          ),
        };
      case 'close':
      default:
        return {
          text: 'Open 6am to 10pm, Mon - Sat',
          button: (
            <Button
              variant="disable"
              fontSize="22px"
              fontWeight="600"
              isDisabled
            >
              {t('challengeList:startChal', 'Start Challenge')}
            </Button>
          ),
        };
    }
  };
  return (
    <RightContent status={challengeStatus}>
      {challengeStatus === 'close' && <div className="closed">Closed</div>}
      <div className="text">{rednerContents().text}</div>
      <div className="btn-group">
        <div className="btn">{rednerContents().button}</div>
        {challengeStatus === 'locked' && <img src={LockedIcon} alt="locked" />}
      </div>
    </RightContent>
  );
};

const SuperHeroCard = ({
  challengeStatus,
  challengeName,
  challengeDesc,
  challengeType,
  challengeOpenImg,
  challengeCloseImg,
  challengeLink,
  onReloadScore,
}) => {
  const { t } = useTranslation(['challengeList', 'common']);

  const {
    superHeroChallengeWithoutImageData,
    superHeroChallengeWithoutImageIsError,
    superHeroChallengeWithoutImageIsLoading,
  } = useSelector((state) => state.dailyChallenge);

  const checkIsDisable =
    challengeType === 'super-vision-challenge'
      ? superHeroChallengeStatusIDs[
          superHeroChallengeWithoutImageData &&
            superHeroChallengeWithoutImageData.Supervision.Status
        ] === 'Locked'
      : challengeType === 'super-speed-challenge' &&
        superHeroChallengeStatusIDs[
          superHeroChallengeWithoutImageData &&
            superHeroChallengeWithoutImageData.Superspeed.Status
        ] === 'Locked';

  return (
    <Container data-cy={challengeType}>
      <Card
        rightWidth="25%"
        infoSection={
          <SuperHeroCardLeft
            t={t}
            challengeName={challengeName}
            challengeDesc={challengeDesc}
            challengeType={challengeType}
            challengeStatus={challengeStatus}
            challengeCloseImg={challengeCloseImg}
            challengeOpenImg={challengeOpenImg}
            superHeroChallengeWithoutImageData={
              superHeroChallengeWithoutImageData
            }
            superHeroChallengeWithoutImageIsLoading={
              superHeroChallengeWithoutImageIsLoading
            }
            superHeroChallengeWithoutImageIsError={
              superHeroChallengeWithoutImageIsError
            }
            onReloadScore={onReloadScore}
          />
        }
        buttonSection={
          <SuperHeroCardRight
            t={t}
            challengeStatus={challengeStatus}
            challengeLink={challengeLink}
          />
        }
        isDisabled={checkIsDisable}
      />
    </Container>
  );
};

export default SuperHeroCard;
