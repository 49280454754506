import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fedc00;
  font-size: 16px;
  padding: 8px;
  font-weight: 600;
  text-align: center;
  p {
    margin-bottom: 0px;
  }
  span {
    color: #db2d20;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
