import styled from 'styled-components';
import React from 'react';
import RobotAvatar1 from 'assets/img/multiplayer/matching/Mini-Robot-PP-03.png';
import RobotAvatar2 from 'assets/img/multiplayer/matching/Mini-Robot-PP-07.png';
import RobotAvatar3 from 'assets/img/multiplayer/matching/Mini-Robot-PP-10.png';
import { useTranslation } from 'react-i18next';
import SearchIcon from './search-icon.png';

const StyledContainer = styled.div`
  @keyframes circle {
    from {
      transform: rotate(360deg) translateX(5px) rotate(-360deg);
    }
    to {
      transform: rotate(0deg) translateX(5px) rotate(0deg);
    }
  }
  @keyframes move {
    0% {
      left: -84px;
    }
    70% {
      left: 100px;
    }
    100% {
      left: -84px;
    }
  }
  @keyframes scale {
    0% {
      opacity: 1;
      z-index: 1;
      transform: scale(1);
    }
    32% {
      z-index: 1;
      transform: scale(0.8);
      animation-timing-function: ease-in;
    }
    70% {
      z-index: 2;
      transform: scale(1);
      animation-timing-function: ease-in;
    }
    75% {
      z-index: 2;
      transform: scale(1.2);
      animation-timing-function: ease-in-out;
    }
    82% {
      z-index: 2;
      transform: scale(1.5);
    }
    90% {
      z-index: 2;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      z-index: 1;
      transform: scale(1);
    }
  }
  @keyframes rotate {
    0% {
      background-position: 100px;
    }
    100% {
      background-position: 0px;
    }
  }
  @keyframes fadeout {
    100% {
      opacity: 0;
    }
  }
  display: flex;
  gap: 20px;
  position: relative;
  width: 200px;
  height: 200px;
  animation-name: ${(props) => (props.toFade === true ? 'fadeout' : null)};
  animation-duration: 10s;
  animation-iteration-count: infinite;
  .avatar {
    position: absolute;
    width: 100px;
    height: 100px;
    margin-left: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: solid 6px white;
  }
  .avatar {
    animation-name: move, scale, rotate;
    animation-duration: 1.12s, 1.12s, 1.12s;
    animation-iteration-count: infinite, infinite, infinite;
    animation-timing-function: ease-in-out, linear, linear;
    opacity: 0;
  }
  img:nth-of-type(2) {
    animation-delay: 0.36s;
  }
  img:nth-of-type(3) {
    animation-delay: 0.72s;
  }
  .bottom {
    width: 100%;
    display: flex;
    position: absolute;
    gap: 20px;
    bottom: 30px;
    .text {
      color: white;
      font-weight: 600;
      font-size: 26px;
    }
    img {
      width: 20px;
      height: 20px;
      animation: 1.6s infinite circle ease-in-out;
    }
  }
`;

const SearchingScene = ({ randomOpponents = [], show }) => {
  const { t } = useTranslation(['newChallenge']);
  const opponentsToDisplay =
    randomOpponents.length < 3
      ? new Array(3).map((_, index) => {
          if (randomOpponents[index]) return randomOpponents[index];
          return {};
        })
      : randomOpponents;
  return (
    <StyledContainer toFade={show}>
      <img
        className="avatar"
        src={opponentsToDisplay[0]?.UserAvatarImage}
        onError={(e) => {
          e.target.src = RobotAvatar1;
        }}
        alt=""
      />
      <img
        className="avatar"
        src={opponentsToDisplay[1]?.UserAvatarImage}
        onError={(e) => {
          e.target.src = RobotAvatar2;
        }}
        alt=""
      />
      <img
        className="avatar"
        src={opponentsToDisplay[2]?.UserAvatarImage}
        onError={(e) => {
          e.target.src = RobotAvatar3;
        }}
        alt=""
      />
      <div className="bottom">
        <img src={SearchIcon} alt="" width="36.15px" />
        <span className="text">
          {t('newChallenge:random.matching', 'Matching...')}
        </span>
      </div>
    </StyledContainer>
  );
};
export default SearchingScene;
