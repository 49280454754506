import React, { useEffect } from 'react';
import Confetti from 'react-confetti';
import useSound from 'use-sound';
import { StyledContainer } from './ConfettiWrapper.styles';
import cheeringSound from 'assets/audio/cheering.mp3';

const ConfettiWrapper = ({ sound = cheeringSound }) => {
  const [play, { stop }] = useSound(sound, {
    interrupt: true,
  });

  useEffect(() => {
    play();
    return () => {
      stop();
    };
  }, [play, stop]);
  return (
    <StyledContainer>
      <Confetti
        numberOfPieces={1000}
        opacity={0.8}
        recycle={false}
        tweenDuration={8000}
      />
    </StyledContainer>
  );
};

export default ConfettiWrapper;
