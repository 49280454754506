import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 10px;
  .math-symbols {
    display: flex;
    justify-content: normal;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .symbol {
      color: #f38a01;
      background-color: #fff9f1;
      border: 0.2px solid #f38a01;
      width: 30px;
      height: 30px;
      text-align: center;
      margin-right: 5px;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 18px;
      font-family: Montserrat;
      outline: none;
      &:hover {
        border: 3px solid #e2e2e2;
        background-color: #ffffff;
        cursor: pointer;
      }
      &:focus,
      &:active {
        border: 3px solid #ffac4e;
        background-color: #ffffff;
      }
    }
  }
  #text-here {
    min-width: 330px;
    min-height: 250px;
    border: 1px solid #d1d1d1;
    padding: 0.5rem;
    outline: none;
  }
`;
