import styled from 'styled-components';
import HeaderBg from 'assets/img/mission/spacebg.png';
import Hotsv2Bg from 'assets/img/mission/hots-v2-bg.png';

export const Header = styled.header`
  background-color: ${(props) =>
    props.isHotsCurr === true ? '#0e99fb' : '#0239d8'};
  background-image: ${(props) =>
    props.isHotsCurr === true ? `url(${Hotsv2Bg})` : `url(${HeaderBg})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  min-height: 130px;
  padding: 0.5rem 1rem;
  font-family: 'VAGRounded BT';
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .skillProficiency {
      margin-top: 10px;
    }
  }
`;

export const BackButton = styled.button`
  position: absolute;
  left: 11%;
  background: ${(props) => (props.isHotsCurr === true ? '#1471c7' : '#8294ff')};
  border: none;
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  min-width: 6rem;
  height: 2rem;
  .iconContainer {
    display: none;
  }

  @media (min-width: 2560px) {
    left: 6%;
  }
  @media (max-width: 1330px) {
    left: 3%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 30px;
    height: 30px;
    min-width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    left: 4%;
    top: 80px;
    svg {
      // svg not vertical aligned, need to add margin to center it
      margin-bottom: 3px;
      width: 75%;
    }
    .iconContainer {
      display: block;
    }
    .textContainer {
      display: none;
    }
  }
`;

export const Message = styled.span`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    flex-direction: column;
    padding-top: 15px;
  }
`;

export const Title = styled.span`
  color: white;
  font-size: ${(props) => (props.type === 'highscore' ? '1.375rem' : '2.3rem')};

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: ${(props) => (props.type === 'highscore' ? '16px' : '20px')};
  }
`;

// export const ProficiencyTitle = styled.p`
//   color: white;
//   font-size: 2.3rem;
// `;

export const SkillsSpan = styled.span`
  color: white;
  margin-left: 1rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: 14px;
  }
`;

export const Main = styled.main`
  width: 100%;
  padding: 1rem 10rem;
  display: flex;
  .drop-down {
    @media (max-width: 1025px) {
      width: 120px;
    }
  }
  @media (max-width: 1330px) {
    padding: 1rem 5rem;
    padding: 1rem 2rem;
  }
  @media (max-width: 1025px) {
    padding: 1rem 2rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 1rem;
  }
`;

export const Sidebar = styled.div`
  width: 300px;
  @media (max-width: 1025px) {
    width: 220px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const Content = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 5px;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.viewType === 'highscore' ? 'space-between' : 'center'};
  align-items: center;
  padding-left: ${(props) =>
    props.viewType === 'highscore' ? '3rem' : '6rem'};
  .num-a-star-skills {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-family: 'VAGRounded BT';
    color: #2e2e2e;
    .star-icon {
      margin-right: 0.5rem;
    }
    .total-skills {
      margin-left: 0.5rem;
      color: #9faac2;
    }
  }
  .topic-title {
    font-size: 1.625rem;
    font-weight: 700;
    margin: ${(props) => (props.viewType === 'proficiency' ? 'auto' : null)};
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0px 50px;
    justify-content: center;
    .num-a-star-skills {
      display: none;
    }
    .topic-title {
      font-size: 22px;
      font-weight: 600;
    }
  }
`;

export const StyledDropdownIcon = styled.div`
  color: #0d328c;
  padding: 4px 5px;
  background: #e0e9ff;
  border: 1px solid #b9cbf5;
  border-radius: 4px;
  line-height: 1;
`;
