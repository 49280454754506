import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0px;
  color: #99c4cc;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  border: 2px dashed #dbdbdb;
  padding: 10px;
`;
