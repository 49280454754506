import React from 'react';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import { isNil } from 'ramda';
import { Container } from './Notebook.styles';

const Notebook = ({
  topContent,
  middleContent,
  bottomContent,
  page,
  isLoading,
  error,
  spinnerColor,
  errorHandling,
  backUrl,
  isFinish,
  answer,
}) => {
  return (
    <Container page={page} isFinish={isFinish} answer={answer}>
      <div className="note-book-wrap">
        <div className="note-book">
          <div className="notebook-top">
            {!isLoading && error === null && topContent}
          </div>
          <div className="notebook-middle">
            {isLoading && error === null && (
              <Spinner
                color={spinnerColor}
                top="20%"
                left="52%"
                position="absolute"
              />
            )}
            {!isLoading && !isNil(error) && (
              <ErrorModal
                reloadAction={errorHandling}
                errorMessage={error}
                backPage={backUrl}
              />
            )}
            {!isLoading && isNil(error) && middleContent}
          </div>
          <div
            className={
              page === 'peer-challenge'
                ? 'notebook-bottom-pc'
                : 'notebook-bottom'
            }
          >
            {!isLoading && isNil(error) && bottomContent}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Notebook;
