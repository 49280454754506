import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
  h1 {
    position: absolute;
    font-family: 'Evil Empire';
    font-size: 72px;
    font-weight: 700;
    color: #ff6a31;
    margin-top: 2rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    h1 {
      white-space: nowrap;
      font-size: 56px;
    }
  }

  .fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileS}px`}) {
    h1 {
      font-size: 52px;
    }
  }
`;
