import styled from 'styled-components';
import AnswerButtonBackground from 'assets/img/superhero/supervision-show-answer-button.png';

export const Container = styled.div`
  margin: auto;
  background-color: #ffc745;
  display: flex;
  align-items: center;
  padding: 0.3em 1em;
  border-radius: 4px 4px 0 0;
  font-family: Verdana, sans-serif;
`;

export const LifeList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const LifeItem = styled.li`
  display: inline-block;
  margin-right: 5px;
  .life {
    display: inline-block;
  }
  .lost {
    display: none;
  }
  &.lost-life {
    .life {
      display: none;
    }
    .lost {
      display: inline-block;
    }
  }
`;

export const ScoreWrapper = styled.div`
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-left: 1px solid #f0b126;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  position: relative;
  margin-left: auto;
  margin-right: 1.5em;

  img {
    vertical-align: middle;
  }
`;

export const ScoreText = styled.span`
  display: inline-block;
  width: 40px;
  font-size: 18px;
  vertical-align: middle;
  color: #fff;
  font-weight: 700;
  text-align: center;
`;

export const TimerWrapper = styled.div`
  font-size: 12px;
  color: #fff;
`;

export const TimerLabel = styled.span`
  vertical-align: middle;
  margin-right: 0.75em;
`;

export const TimerText = styled.span`
  font-size: 22px;
  font-weight: 700;
  vertical-align: middle;
`;

export const ShowAnswerButton = styled.a`
  text-align: center;
  color: #fec02f !important;
  font-family: Verdana;
  font-size: 20px;
  text-decoration: none;
  background: url('${AnswerButtonBackground}') center no-repeat;
  display: block;
  height: 32px;
  width: 172px;
  margin-left: 135px;
  cursor: pointer;
`;

export const CompleteChallengeText = styled.p`
  font-family: Arial;
  font-size: 16px;
  color: black;
  margin-left: 300px;
  margin-bottom: 0px;
`;
