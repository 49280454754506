import styled from 'styled-components';
import { proficiencyColors } from 'constants/report';

export const SkillSection = styled.div`
  width: 100%;
  padding: 1rem 175px;
  border-bottom: solid 1px #e9e9e9;
  background: ${(props) =>
    props.index === 0 || props.index % 2 === 0 ? '#fbfbfb' : 'white'};
  .skill-title {
    font-family: 'Nunito';
    font-size: 1.125rem;
    color: #202020;
  }
  .progressbar {
    max-width: 290px;
    p {
      margin: 10px 0px;
      width: ${(props) =>
        props.showSkillDetails ? 'calc(100vw - 385px)' : 'calc(100vw - 56px)'};
    }
  }
  .skill-badges {
    display: flex;
    flex-wrap: wrap;
    .badge {
      display: flex;
      flex-direction: column;
      justify-content: ${(props) =>
        props.type === 'highscore' ? 'flex-end' : 'center'};
      text-align: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      margin: 0 1rem 1rem 0;
      min-width: ${(props) =>
        props.type === 'highscore' ? '60.88px' : '49px'};
      min-height: 74px;
      .grade-icon {
        align-self: center;
        width: 40px;
        height: 40px;
      }
      &:hover {
        background: #eeeeee;
      }
      .skill-percentage {
        margin-top: 1rem;
        font-size: 0.875rem;
        color: #363636;
      }
      .skill-number {
        margin-top: 1rem;
        font-size: 0.8125rem;
        color: #363636;
      }
    }
  }
`;

const proficiencyFontColors = {
  mastery: '#363636',
  competent: '#1B6316',
  developing: '#155575',
  beginning: 'white',
  incomplete: '#8B989E',
};

export const ProficiencyBadge = styled.div`
  width: 32px;
  height: 31px;
  background: ${(props) => proficiencyColors[props.proficiency].color};
  border: solid 1px
    ${(props) => proficiencyColors[props.proficiency].borderColor};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Nunito';
  font-size: 0.625rem;
  color: ${(props) => proficiencyFontColors[props.proficiency]};
`;
