import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 1rem;
  top: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 0.25rem;
  }
  .progress {
    background: white;
    height: 9px;
    width: 83px;
    margin-bottom: 0.25rem;
  }
  .progress-bar {
    background: #ffa200;
  }

  span {
    background: white;
    border-radius: 20px;
    font-size: 0.75rem;
    font-weight: 700;
    color: #46a7c3;
    padding: 0.5rem 1rem;
  }
`;
