import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/top-brain/header.png';
import Rules from 'assets/img/leaderboard/top-brain/rules-img.svg';

export const Topbar = styled.div`
  height: 350px;
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: 105% 350px;
  display: flex;
  padding: 0 20px;
  .badge-img {
    margin-left: 5%;
  }
  .middle-content {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .scoring-period {
    color: #ffffff;
    font-size: 26px;
    display: flex;
    margin-top: 24px;
    font-weight: 700;
    span {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .rules {
    background-image: url(${Rules});
    width: 135px;
    height: 70px;
    position: absolute;
    right: -3%;
    bottom: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .rules-text {
      color: #121111;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0px;
      margin-left: 3.5rem;
      margin-top: 0.5rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 2%;
`;

export const CenterBar = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.challengeType === 'CC'
      ? props.activeTerm === 'term'
        ? 'flex-end'
        : 'space-between'
      : 'space-evenly'};
  align-items: center;
  position: relative;
  padding-top: ${(props) => (props.challengeType === 'CC' ? '70px' : '20px')};
  padding-bottom: ${(props) => props.challengeType === 'CC' && '20px'};
`;

export const BrainHeading = styled.div`
  color: #ff6c1c;
  font-size: 3.9rem;
  font-family: 'Grilled Cheese';
  width: 70%;
  position: relative;
  z-index: 1;
  &::before {
    content: ${({ content }) => content && `"${content}"`};
    position: absolute;
    -webkit-text-stroke: 8px #ffffff;
    left: 0;
    top: 0;
    z-index: -1;
  }
`;

export const TermClassic = styled.div`
  display: flex;
  justify-content: center;
  div {
    border-radius: 20px;
    text-align: center;
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    box-shadow: 0 0 0pt 5pt #ffffff;
  }
  .term {
    border-right: 0px solid #ffffff;
    border-top-right-radius: ${(props) =>
      props.active === 'term' ? '20px' : '0'};
    border-bottom-right-radius: ${(props) =>
      props.active === 'term' ? '20px' : '0'};
    z-index: 1;
    background: ${(props) =>
      props.active === 'term' ? props.bgActive : props.bgInactive};
    color: ${(props) =>
      props.active === 'term' ? props.colorActive : props.colorInactive};
  }
  .classic {
    border-left: 0px solid #ffffff;
    border-top-left-radius: ${(props) =>
      props.active !== 'term' ? '20px' : '0'};
    border-bottom-left-radius: ${(props) =>
      props.active !== 'term' ? '20px' : '0'};
    z-index: ${(props) => (props.active !== 'term' ? '2' : '0')};
    background: ${(props) =>
      props.active !== 'term' ? props.bgActive : props.bgInactive};
    color: ${(props) =>
      props.active !== 'term' ? props.colorActive : props.colorInactive};
    position: relative;
    right: 20px;
  }
`;

export const Terms = styled.div`
  display: flex;
  background: #707070;
  border-radius: 35px;
  padding: 15px;
  opacity: 0.7;
  width: 75%;
  div {
    border: 2px solid #391c03;
    border-radius: 20px;
    width: 25%;
    text-align: center;
    margin: 0 10px;
    padding: 5px 0;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    &:after {
      position: absolute;
      top: 110%;
      left: 50%;
      margin-left: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: #ffffff transparent transparent transparent;
    }
  }
  .active-term {
    border: none;
    color: #212624;
    box-shadow: inset 1px 1px 0 1px #adb09f;
    &:after {
      content: '';
    }
  }
`;
