import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import QnViewHeader from 'components/QnView/QnViewHeader';
import QnStatusIndicator from 'components/Shared/QnStatusIndicator';
import MissionNoteBookHeader from 'components/Mission/MissionNoteBookHeader';
import MissionNoteBookMiddle from 'components/Mission/MissionNoteBookMiddle';
import MissionNoteBookBottom from 'components/Mission/MissionNoteBookBottom';
import Banner from 'components/Mission/Banner';
import { rwd } from 'Theme';
import { useMediaQuery } from 'react-responsive';
import {
  MissionPlusBanner,
  LegendMissionPlus,
} from 'components/Mission/MissionPlus';
import Notebook from 'components/Shared/Notebook';
import Canvas from 'components/Shared/Canvas';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';
import {
  getSelfPracticeQuestion,
  submitSelfPracticeAns,
  resetSubmitAns,
  resetSelfPracticeQnView,
  getMissionPlusQuestions,
  submitMissionPlusAns,
  claimMissionPlus,
} from 'store/mission/missionSlice';
import { createAnswerKeyValuePairsByQuestionType } from 'helpers/createAnswerKeyValuePairs';
import { submissionTypeIDs, questionTypeIDs } from 'constants/index';
import { isEmpty, isNil } from 'ramda';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import useSound from 'use-sound';
import voiceSound from 'assets/audio/thatsright3.mp3';
import validateInputs from 'helpers/validation/validateInputs';
import IndicatorGift from 'assets/img/mission/missionplus/indicator-gift.svg';
import { sendEventTracking } from 'helpers/UserEventTracking';
import getSubjectByCurrId from 'helpers/Mission/getSubjectByCurrId';
import {
  Container,
  Main,
  StyledIndicatorGift,
  StyledLegendMissionPlus,
} from './SelfPracticeQnView.styles';
import { COLORS } from './constants';

const SelfPracticeQnView = ({ match }) => {
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  const topicSkillId = parseInt(match.params.id, 10);
  const stageTypeId = parseInt(match.params.stageTypeId, 10);
  const curriculumId = parseInt(match.params.curriculumId, 10);
  const isMissionPlus = !isNaN(stageTypeId);
  const { bgColor, logoBgColor, btnBgColor, helpBgColor } = COLORS[
    Number(isMissionPlus)
  ];
  const { userID } = useSelector((state) => state.login);
  const {
    curriculumId: missionPlusCurriculumId,
    subject,
  } = useLocation()?.state;
  const getCurriculumId = isMissionPlus
    ? missionPlusCurriculumId
    : curriculumId;
  const subjectId = subject ?? getSubjectByCurrId(getCurriculumId);
  const [play, { stop }] = useSound(voiceSound, {
    volume: 0,
    playbackRate: 4,
    interrupt: true,
  });
  const { t } = useTranslation(['selfPracticeQnView', 'common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    questions,
    isLoading,
    isErr,
    isErrSubmit,
    submitAns,
    selfPracticeLocalSavedAns,
    selfPracticeLocalSavedWorkings,
    selectedTopic,
    missionPlusClaimKoKo,
  } = useSelector((state) => state.mission);
  const { data: barModelData } = useSelector((state) => state.barModel);

  const [answer, setAnswer] = useState('unknown');
  const [canClaim, setCanClaim] = useState(false);
  const [totalCorrectAns, setTotalCorrectAns] = useState(0);
  const [totalProgress, setTotalProgress] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // EVENT TRACKING: onpage load
    if (!isNil(userID)) {
      sendEventTracking('mission', 'question_view', {
        sub: subjectId,
        ci: getCurriculumId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  // Preload sound
  useEffect(() => {
    play();
    return () => {
      stop();
    };
  }, [play, stop]);
  const handleGetQuestions = useCallback(() => {
    dispatch(resetSubmitAns());
    if (isMissionPlus) {
      if (!selectedTopic) {
        history.goBack();
      } else if (userID) {
        dispatch(getMissionPlusQuestions({ topicSkillId, stageTypeId }));
      }
    } else {
      dispatch(getSelfPracticeQuestion(topicSkillId));
    }
  }, [
    dispatch,
    history,
    isMissionPlus,
    selectedTopic,
    stageTypeId,
    topicSkillId,
    userID,
  ]);
  useEffect(() => {
    handleGetQuestions();
  }, [handleGetQuestions]);
  useEffect(() => {
    if (!isEmpty(submitAns) && questions && questions.Progress) {
      let key = 0;
      questions.Progress.forEach((obj) => {
        if (obj.Result === 1) key += 1;
      });
      if (submitAns.QuestionSubmissionModel.SubmissionResult === 1) {
        key += 1;
        setAnswer('correct');
      } else {
        setAnswer('wrong');
      }
      setTotalCorrectAns(key); // set the banner stars
      const tempArr = [...totalProgress]; // for top status indicator
      tempArr.push({
        isResult: submitAns.QuestionSubmissionModel.SubmissionResult,
      });
      setTotalProgress(tempArr);
      setCanClaim(submitAns.CanClaim); // show claim button/banner
    }
    // eslint-disable-next-line
  }, [submitAns]);
  useEffect(() => {
    if (questions && questions.Progress && questions.Progress.length) {
      let tempArr = [...totalProgress];
      tempArr = questions.Progress.map((item) => {
        return {
          isResult: item.Result,
        };
      });
      setTotalProgress(tempArr);
    }
    // eslint-disable-next-line
  }, [questions]);
  useEffect(() => {
    return () => {
      dispatch(resetSelfPracticeQnView());
    };
    // eslint-disable-next-line
  }, []);
  const errorHandling = () => {
    if (isErrSubmit) {
      submitAnswer();
    } else if (missionPlusClaimKoKo.isErr) {
      dispatch(claimMissionPlus({ topicSkillId, stageTypeId }));
    } else {
      handleGetQuestions();
    }
  };
  const submitAnswer = () => {
    // clear and validate for inputs that are black or with empty spaces
    if (!isEmpty(errors)) setErrors({});
    if (!validateInputs(selfPracticeLocalSavedAns[0])) {
      const questionType = questionTypeIDs[questions.Question.QuestionType];
      switch (questionType) {
        case 'mcq':
          setErrors({
            error: t(
              'assignmentQnView:errors.mcq',
              'Please select an answer before submitting.'
            ),
          });
          return;
        default:
          setErrors({
            error: t(
              'assignmentQnView:errors.blank',
              'Please fill in the answer before submitting.'
            ),
          });
          return;
      }
    }

    const rawBody = {
      userID,
      questionID: questions.Question.Id,
      type: submissionTypeIDs.SkillPracticeMS,
      questionType: questions.Question.QuestionType,
      versionId: questions.Question.Version,
      answerkeyValuePairs: createAnswerKeyValuePairsByQuestionType(
        questionTypeIDs[questions.Question.QuestionType],
        {
          studentAnswers: selfPracticeLocalSavedAns[0],
          questionAnswers: null,
          userID,
          qnID: null,
          keysToMarkRight: null,
        }
      ),
      isFinalSubmit: false,
    };

    // Save workings/bar model data under property "solution"
    const workings = selfPracticeLocalSavedWorkings[0];
    const barModel = barModelData.objects.length > 0 ? barModelData : null;
    if (!isNil(workings) || !isNil(barModel)) {
      const solutionObject = {
        workings: isNil(workings) ? null : workings,
        barModel: isNil(barModel) ? null : barModel,
      };
      // Stringify solution object as API only accepts value type string for solution
      rawBody.solution = JSON.stringify(solutionObject);
    }
    if (isMissionPlus) {
      dispatch(submitMissionPlusAns({ topicSkillId, stageTypeId, rawBody }));
    } else {
      dispatch(submitSelfPracticeAns({ topicSkillId, rawBody }));
    }
    // EVENT TRACKING: submit questions
    sendEventTracking('mission', 'question_submit', {
      sub: subject,
      ci: getCurriculumId,
      qi: questions.Question.Id,
    });
    dispatch(hideAllTools());
    dispatch(setClearDraw(true));
  };
  // progress length
  const inProgress = totalProgress ? totalProgress.length : 0;
  const backText = isMissionPlus
    ? t('common:back', 'Back')
    : t('selfPracticeQnView:header.back', 'Back to Mission');

  const MissionNoteBookBottomData = questions && questions.TopicSkill;

  const scrollRef = useRef();
  useLayoutEffect(() => {
    if (isMobile) {
      scrollRef.current.focus();
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    }
  }, [isMobile, isLoading, answer]);

  return (
    <Canvas
      bgColor={helpBgColor}
      feature="mission"
      showSideBar={!isMobile}
      top={68}
    >
      <Container isMissionPlus={isMissionPlus}>
        <QnViewHeader
          bgColor={bgColor}
          logoBgColor={logoBgColor}
          btnBgColor={btnBgColor}
          backText={backText}
          hasBgImg={!isMissionPlus}
          onClick={() => {
            if (isMissionPlus) {
              return history.push({
                pathname: `/mission/self-practice/plus/${missionPlusCurriculumId}`,
                state: {
                  subject,
                },
              });
            }
            return history.push({
              pathname: `/mission/self-practice/${curriculumId}`,
              state: {
                subject,
              },
            });
          }}
        >
          <QnStatusIndicator
            text={t(
              'selfPracticeQnView:header.completeText',
              'Complete 10 questions to unlock rewards'
            )}
            totalProgress={totalProgress}
            inProgress={10 - inProgress}
            isMissionPlus={isMissionPlus}
            correctColor="#9BFCA7"
            wrongColor="#FFB8B8"
          />
          {isMissionPlus && (
            <>
              <StyledIndicatorGift>
                <img src={IndicatorGift} alt="gift" />
              </StyledIndicatorGift>
              <StyledLegendMissionPlus>
                <LegendMissionPlus color="#FFFFFF" />
              </StyledLegendMissionPlus>
            </>
          )}
        </QnViewHeader>
        <Main ref={scrollRef}>
          <div>
            {(canClaim || inProgress === 10) &&
              (isMissionPlus ? (
                <MissionPlusBanner
                  inProgress={inProgress}
                  topicSkillId={topicSkillId}
                  progress={totalCorrectAns}
                  stageTypeId={stageTypeId}
                />
              ) : (
                <Banner
                  inProgress={inProgress}
                  topicSkillId={topicSkillId}
                  canClaim={canClaim}
                  progress={totalCorrectAns}
                  curriculumId={curriculumId}
                  subjectId={subject}
                />
              ))}

            <Notebook
              page="self-practice"
              isLoading={isLoading}
              error={isErrSubmit || isErr || missionPlusClaimKoKo.isErr}
              errorHandling={errorHandling}
              spinnerColor="#3858d2"
              isFinish={canClaim || inProgress === 10}
              answer={answer}
              topContent={
                <MissionNoteBookHeader
                  question={questions && questions.Question}
                  topicSkill={questions && questions.TopicSkill}
                  qnNo={questions && questions.QuestionNo}
                  topicName={questions && questions.TopicName}
                />
              }
              middleContent={
                <MissionNoteBookMiddle
                  question={questions && questions.Question}
                  answer={answer}
                  setAnswer={setAnswer}
                  setCanClaim={setCanClaim}
                  topicId={
                    questions && questions.TopicSkill && questions.TopicSkill.ID
                  }
                  qnNo={questions && questions.QuestionNo}
                  errors={errors}
                  submitAnswer={submitAnswer}
                  isLoading={isLoading}
                  isMissionPlus={isMissionPlus}
                  topicSkillId={topicSkillId}
                  stageTypeId={stageTypeId}
                  videoCode={questions?.TopicSkill?.SIO?.videoCode}
                />
              }
              bottomContent={
                <MissionNoteBookBottom
                  isMissionPlus={isMissionPlus}
                  data={MissionNoteBookBottomData}
                  answer={answer}
                  curriculumId={getCurriculumId}
                  subject={subject}
                  qnID={questions?.Question?.Id}
                  skillID={questions?.Question?.SkillId}
                />
              }
            />
          </div>
        </Main>
      </Container>
    </Canvas>
  );
};

export default SelfPracticeQnView;
