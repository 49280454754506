import TopicList from 'components/Mission/TopicList';
import { isEmpty, isNil } from 'ramda';
import React, { useState } from 'react';
import MenuIcon from 'assets/img/icon-menu.svg';
import CloseIcon from 'assets/img/icon-close.svg';
import getTitleLabel from 'helpers/getTitleLabel';
import {
  Overlay,
  Sidebar,
  Content,
  MenuIconContainer,
  CloseIconContainer,
  Header,
  SidebarWrapper,
  TabWrapper,
  TabButton,
  TabContent,
} from './MobileSidebar.styles';

const getColor = (color) => {
  if (color === 'soft-blue') {
    return {
      borderColor: '#cce6fe',
      bgColor: '#f0f8ff',
      iconColor:
        'invert(69%) sepia(7%) saturate(2993%) hue-rotate(178deg) brightness(108%) contrast(97%)',
    };
  }
  if (color === 'dark-blue') {
    return {
      borderColor: '#cdd8f4',
      bgColor: '#e2e9fd',
      iconColor:
        'invert(48%) sepia(89%) saturate(340%) hue-rotate(189deg) brightness(80%) contrast(91%)',
    };
  }
  if (color === 'green') {
    return {
      borderColor: '#bbdecd',
      bgColor: '#e9f7f1',
      iconColor:
        'invert(50%) sepia(6%) saturate(3624%) hue-rotate(71deg) brightness(100%) contrast(97%)',
    };
  }

  return {};
};

const getShortcut = (label, isHots) => {
  if (isHots) {
    const shortcut = label.slice(10, label.length).replace(/\s/g, '');
    return shortcut;
  }

  const firstCharacter = label.charAt(0);
  const lastCharacter = label.charAt(label.length - 1);
  const shortcut = `${firstCharacter}${lastCharacter}`;

  return shortcut;
};

const MobileSidebar = ({
  withTab = true,
  color = 'soft-blue' || 'dark-blue' || 'green',
  isHots = false,
  listOfLevels,
  topics,
  selectedTopic,
  changeTopic,
  valueTemplate,
  valueKey,
  changeLevel,
  selectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const componentColor = getColor(color);

  const handleMenu = () => {
    setIsOpen((open) => !open);
  };

  const renderListLevel = () => {
    if (!isNil(listOfLevels) && !isEmpty(listOfLevels)) {
      return listOfLevels.map((list, i) => {
        const label = getTitleLabel(list, i, valueTemplate, valueKey);
        const shortcut = getShortcut(label, isHots);
        const isSelected = selectedValue === label;

        return (
          <TabButton
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            onClick={() => {
              changeLevel(list);
            }}
            style={{
              background: isSelected ? componentColor.bgColor : 'transparent',
            }}
          >
            {shortcut}
          </TabButton>
        );
      });
    }

    return <div />;
  };

  const handleChangeTopic = (topicId) => {
    handleMenu();
    changeTopic(topicId);
  };

  return (
    <>
      {isOpen && <Overlay onClick={handleMenu} />}
      <Sidebar>
        <MenuIconContainer
          onClick={handleMenu}
          style={{
            background: componentColor.bgColor,
            border: `1px solid ${componentColor.borderColor}`,
          }}
        >
          <img
            src={MenuIcon}
            alt=""
            style={{ filter: componentColor.iconColor }}
          />
        </MenuIconContainer>
      </Sidebar>
      <SidebarWrapper isOpen={isOpen}>
        <Content>
          <Header>
            <CloseIconContainer onClick={handleMenu}>
              <img src={CloseIcon} alt="" />
            </CloseIconContainer>
          </Header>

          <TabContent>
            {withTab && <TabWrapper>{renderListLevel()}</TabWrapper>}
            {!isNil(topics) && !isEmpty(topics) ? (
              <TopicList
                data={topics}
                selectedTopic={selectedTopic}
                changeTopic={handleChangeTopic}
              />
            ) : (
              <p>Empty</p>
            )}
          </TabContent>
        </Content>
      </SidebarWrapper>
    </>
  );
};

export default MobileSidebar;
