import React from 'react';
import { useTranslation } from 'react-i18next';
import { difficultyLevels } from 'constants/dailyChallenge';
import { Container } from './DifficultyIndicator.styles';

const DifficultyIndicator = ({ difficultyLevel }) => {
  const { t } = useTranslation(['dailyChallenge']);
  return (
    <Container>
      <div className="difficulty-name">
        {t(
          `dailyChallenge:difficultyQnTitle.${difficultyLevels[difficultyLevel].name}`,
          `${difficultyLevels[difficultyLevel].name}`
        )}
      </div>
    </Container>
  );
};

export default DifficultyIndicator;
