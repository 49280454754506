import styled from 'styled-components';
import HeaderBg from 'assets/img/dailychallenge/header-bg.svg';
import HeaderForestBg from 'assets/img/dailychallenge/header-forest-space.png';

export const StyledLoaderWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;
export const WelcomeHeader = styled.div`
  position: relative;
  width: 100%;
  min-height: 170px;
  background-image: ${`url(${HeaderBg})`};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    align-self: flex-end;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    img.mascot {
      display: none;
    }
    min-height: 120px;
  }
`;

export const DefaultHeader = styled.div`
  width: 100%;
  min-height: 170px;
  &.difficulty-selection {
    min-height: 120px;
  }
  background-image: ${`url(${HeaderForestBg})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  position: relative;
  .left {
    display: flex;
    flex-direction: column;
    img {
      cursor: pointer;
    }
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
    .nav-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.875rem;
      color: white;
      margin-left: 1rem;
    }
    img {
      width: 60px;
      height: 60px;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .left {
      visibility: hidden;
      width: 85px;
    }
    .center {
      flex-grow: 1;
      & > div {
        padding-left: 1.6rem;
      }
    }
    .right {
      .nav-item {
        margin: 0px;
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    max-width: 100vw;
    overflow-x: hidden;
    .right {
      visibility: hidden;
    }
    .center {
      & > div {
        padding-left: 4rem;
      }
    }
  }
`;

export const EarnMoreCPsButton = styled.button`
  border: none;
  color: white;
  background: rgba(13, 166, 180, 0.76);
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  :hover {
    background: rgba(13, 166, 180, 1);
  }
`;

export const WelcomeText = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    img {
      max-width: min(300px, 48vw);
    }
  }
`;

export const BannerText = styled.div`
  text-align: center;
  align-self: flex-end;
  .welcome {
    font-family: 'Rockwell';
    font-weight: 700;
    font-size: 2rem;
    color: #ace0ff;
    text-shadow: 3px 0px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    top: 0.5rem;
  }
  .choose-difficulty {
    margin-bottom: 1rem;
    color: white;
    font-weight: 600;
    font-size: 1.4375rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    align-self: center;
    .welcome {
      top: 0px;
      padding-bottom: 1%;
      margin-right: 0.25rem;
      margin-left: 2rem;
      font-size: 36px;
    }
    .choose-difficulty {
      margin-bottom: 1rem;
      margin-top: -0.5rem;
      color: white;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .welcome {
      font-size: max(22px, 5.5vw);
    }
  }
`;

export const Content = styled.div`
  background: #eaeded;
  min-height: calc(100vh - 229px);
  display: flex;
  flex-direction: column;
  align-items: center;
  .modal-content {
    border-radius: 1rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding-top: 1rem;
  }
`;

export const Legend = styled.div`
  margin-top: 0.5rem;
  font-size: 0.6875rem;
  display: flex;
  span {
    display: flex;
    align-items: center;
    margin-right: 2rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    justify-content: center;
    padding-bottom: 0.5rem;
  }
`;

export const LegendCircle = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${(props) => props.color};
  margin-right: 0.25rem;
`;
export const StyledDifficultyWarningModalTitle = styled.div`
  p {
    margin-bottom: 0;
  }
`;
