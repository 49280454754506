import styled from 'styled-components';
import addSymbol from 'assets/img/assignment/four-ops/addSymbol.svg';
import subSymbol from 'assets/img/assignment/four-ops/subSymbol.svg';
import mulSymbol from 'assets/img/assignment/four-ops/mulSymbol.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .operations-list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 13px;
    padding: 10px;
    #dropdown-custom,
    .dropdown-item-custom {
      font-size: 13px;
    }
  }
  .clearAll {
    margin-left: 5px;
    text-decoration: underline;
    color: #99a8ae;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #d6d6d6;
  padding: 10px 10px;
  justify-content: center;
  .type-img {
    cursor: pointer;
    width: 40px;
    margin-right: 10px;
  }
`;

export const BodyContainer = styled.div`
  margin: 10px 0;
  .four-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 15px;
  }
  .input {
    border: 1px solid #c6d2d8;
    background: #fff;
    height: 40px;
    width: 40px;
    text-align: center;
    margin: 5px;
  }
  .cont-carry {
    width: 40px;
    margin: 5px;
    text-align: center;
  }
  .carry {
    border: 1px solid #c6d2d8;
    background: #fffbe9;
    height: 25px;
    width: 25px;
    text-align: center;
    font-size: 13px;
    padding-top: 4px;
  }
  .empty {
    width: 40px;
    height: 40px;
    margin: 0 5px;
  }
  .dash {
    border-bottom: 1px solid #c6d2d8;
    width: 18%;
    margin: 2px 0;
  }
  .add {
    background: url(${addSymbol});
    background-repeat: no-repeat;
    background-size: 80%;
    width: 40px;
    height: 40px;
    margin: 5px;
  }
  .sub {
    background: url(${subSymbol});
    background-repeat: no-repeat;
    background-size: 80%;
    width: 40px;
    height: 40px;
    background-position: bottom;
    margin: 5px;
  }
  .mul {
    background: url(${mulSymbol});
    background-repeat: no-repeat;
    background-size: 60%;
    width: 40px;
    height: 40px;
    background-position: center;
    margin: 5px;
  }
  .div {
    border: 0px solid #c6d2d8;
    border-right-width: 2px;
    border-radius: 0 0 10px 0;
    border-bottom-width: 2px;
    width: 15px;
    height: 60px;
  }
  .div-dash {
    border-bottom: 2px solid #c6d2d8;
    width: 21.5%;
  }
  .div-dash-small {
    border-bottom: 2px solid #c6d2d8;
    width: 19.5%;
  }
  .empty-div {
    width: 25px;
    margin: 0 5px;
  }
  .h0 {
    height: 0px;
  }
`;
