import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { periodOptions } from 'constants/history';
import { ReactComponent as KokoCredit } from 'assets/img/sidepanel/kokocredit.svg';
import { Spinner } from 'react-bootstrap';
import BackButton from 'components/Shared/BackButton';
import DropdownMenu from 'components/Shared/DropdownMenu';
import RefreshButton from 'components/Shared/RefreshButton';
import {
  StyledContainer,
  StyledBackground,
  StyledDropdownWrapper,
  StyledBalance,
  StyledBalanceValue,
  StyledBackButtonWrap,
  StyledDropdownMenuBox,
} from './KoKoHistoryHeader.styles';
import { useHistory } from 'react-router-dom';
const KoKoHistoryHeader = ({
  selectPeriod,
  setSelectPeriod,
  options,
  searchAction,
}) => {
  const { t } = useTranslation(['kokoHistory']);
  const history = useHistory();
  const { Balance, isLoading } = useSelector((state) => state.kokoHistory);

  return (
    <StyledBackground>
      <StyledContainer>
        <StyledDropdownWrapper>
          <StyledBackButtonWrap>
            <BackButton
              backText={t('backBtn', 'Back')}
              btnBgColor="#FFEB9B"
              padding="5px 20px"
              color="#b49000"
              onClick={() => history.push('/dashboard')}
            />
          </StyledBackButtonWrap>
          <span>{t('period.label', 'Select Period')}</span>
          <StyledDropdownMenuBox>
            <DropdownMenu
              width="184px"
              values={options}
              setValue={setSelectPeriod}
              selectedValue={t(
                `period.options.${periodOptions[selectPeriod].i18nKey}`,
                `${periodOptions[selectPeriod].label}`
              )}
              valueTemplate={(value) =>
                t(
                  `period.options.${periodOptions[value].i18nKey}`,
                  periodOptions[value].label
                )
              }
            />
            <p>{t('period.limit', '*Only up to the past month')} </p>
          </StyledDropdownMenuBox>

          <RefreshButton
            onClick={searchAction}
            style={{ marginLeft: '12px' }}
          />
        </StyledDropdownWrapper>

        <StyledBalance>
          <span>{t('balance', 'KoKo Credits balance')}</span>
          <KokoCredit />
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <StyledBalanceValue data-cy="balance">{Balance}</StyledBalanceValue>
          )}
        </StyledBalance>
      </StyledContainer>
    </StyledBackground>
  );
};

export default KoKoHistoryHeader;
