import styled from 'styled-components';

export const Container = styled.div`
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 59px);
  background: #7272ff;
  color: #ffffff;
  .activity-items {
    overflow-y: auto;
  }
  @media (max-width: 1024px) {
    min-width: 16rem;
  }
`;

export const LessonDetail = styled.div`
  background: #5f5ff2;
  padding: 1rem;
  height: min-content;
  p {
    margin-bottom: 0px;
  }
  .btn-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .error {
    margin-bottom: 1rem;
    font-size: 18px;
  }

  .info-section {
    .info-detail {
      margin: 0.8rem 0rem;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      .lesson-no {
        color: #ffd800;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .scroll-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .scroll-progress {
        display: flex;
        align-items: center;
        width: 100%;
        .scroll-points {
          margin-right: 0.5rem;
          font-size: 15px;
          font-weight: 600;
          width: 17%;
          span {
            color: #9b9bff;
          }
        }
        .scroll-bar {
          display: contents;
          .progress-bar-section {
            width: 75%;
            @media (max-width: 1024px) {
              width: 65%;
            }
            .progress {
              height: 7.5px;
              border-radius: 1rem;
              background: #f3f3f3;
              .bg-correct {
                background: #fcc103;
              }
            }
          }
          .round-bg {
            width: 28.5px;
            height: 28.5px;
            background: ${(props) => (props.completed ? '#3B3BB9' : '#ffffff')};
            border-radius: 50%;
            margin-left: -5px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: 35px;
            }
          }
        }
      }
      .total-scroll {
        font-size: 11px;
      }
    }
  }
`;
