import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'components/Header';
import ExpLevelHeader from 'components/ExpLevel/ExpLevelHeader/ExpLevelHeader';
import ExpLevelSection from 'components/ExpLevel/ExpLevelSection/ExpLevelSection';
import { getRewards } from 'store/dashboard/rewardsSlice';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import { StyledLoaderWrap } from './ExpLevel.styles';

const ExpLevel = () => {
  const dispatch = useDispatch();
  const { rewards, error: xpError, isLoading } = useSelector(
    (state) => state.rewards
  );
  const levelData = rewards?.EXPData;

  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch]);

  const reloadHandler = () => {
    dispatch(getRewards());
  };

  const currentLevel = {
    totalExp: rewards?.TotalEXP,
    level: levelData?.CurrentLevel?.ID,
    minExp: levelData?.CurrentLevel?.MinExp,
    nextExp: levelData?.UpcomingLevels[0]?.MinExp,
  };

  // const currentLevel = {
  //   totalExp: 520,
  //   level: 100,
  //   minExp: 500,
  //   nextExp: 600,
  // };

  const renderContent = () => {
    if (isLoading) {
      return (
        <StyledLoaderWrap>
          <Spinner />
        </StyledLoaderWrap>
      );
    }
    if (xpError) {
      return <ErrorModal reloadAction={reloadHandler} errorMessage={xpError} />;
    }

    return (
      <>
        <ExpLevelHeader
          currentLevel={currentLevel.level}
          totalExp={currentLevel.totalExp}
          minExp={currentLevel.minExp}
          nextExp={currentLevel.nextExp}
        />
        <ExpLevelSection currentLevel={currentLevel.level} />
      </>
    );
  };

  return (
    <>
      <Header />
      {renderContent()}
    </>
  );
};

export default ExpLevel;
