import styled from 'styled-components';

export const Page = styled.div`
  background: ${({ bg }) => `url(${bg})`} no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  padding: 2rem 2rem 0 2rem;
  text-align: center;
  img {
    height: 27px;
  }
  .main-title {
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  .main-desc {
    margin-bottom: 0;
    max-width: 800px;
    margin-top: 0.5rem;
  }
  .children-mascot {
    position: absolute;
    height: 40%;
    left: 3rem;
    margin-top: 7rem;
  }
  @media (max-width: 1441px) {
    .children-mascot {
      height: 40%;
      left: 3rem;
      margin-top: 5rem;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .children-mascot {
      height: 40%;
      left: 1rem;
      margin-top: 5rem;
    }
  }
  @media (max-width: 1175px) {
    .children-mascot {
      height: 30%;
      margin-top: 6rem;
      left: 0.8rem;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .children-mascot {
      height: 25%;
      margin-top: 6.5rem;
    }
  }
  @media (max-width: 955px) {
    .children-mascot {
      display: none;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  position: absolute;
  top: 7rem;
  left: 1.875rem;
`;

export const StepOneModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .modal-title {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
  }
`;

export const StepTwoModal = styled.div`
  .back-button {
    padding-top: 1rem;
    padding-left: 5rem;
  }
`;

export const StepTwoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;

  .otp-title {
  }

  .otp-desc {
    color: #909090;
  }
`;

export const FinishedModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .finished-title {
    margin-top: 1.5rem;
    color: #ff8001;
  }

  .finished-description {
    margin-top: 0.5rem;
    word-break: break-word;
    width: 350px;
    text-align: center;
  }

  .check-mark {
    margin-top: -2.5rem;
  }

  .activate-button {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
