import styled from 'styled-components';
import MathDCBg from 'assets/img/dashboardv2/dc.png';
import MathDCBgMobile from 'assets/img/dashboardv2/mobileDC.png';
import ScienceDCBg from 'assets/img/dashboardv2/DC-science.png';
import ScienceDCBgMobile from 'assets/img/dashboardv2/mobileSC.png';

export const DCSubjectStyles = {
  1: MathDCBg,
  2: ScienceDCBg,
};

export const DCSubjectStylesMobile = {
  1: MathDCBgMobile,
  2: ScienceDCBgMobile,
};

export const StyledContainer = styled.div`
  width: 50%;
  height: fit-content;
  position: relative;
  :hover {
    transform: scale(0.95);
  }
  img {
    width: 100%;
    height: auto;
  }

  border-radius: 1rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
    img {
      width: 93%;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0px 17px;
    img {
      width: 100%;
    }
    .btn-smc {
      display: none;
    }
  }
`;

export const StyledContent = styled.div`
  position: absolute;
  bottom: 8%;
  right: calc(0.02307137707 * 100vw);
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: fit-content;
    margin: calc(0.0086517664 * 100vw) 0 0 0;
    color: #754c24;
    font-size: calc(0.00981254506 * 100vw);
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    right: 7%;
    p {
      font-size: 14px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    p {
      font-size: 10px;
    }
  }
`;

export const PrimaryButton = styled.div`
  border: none;
  background: ${(props) => (props.isDisabled ? '#E5E5E5' : '#ff5801')};
  text-align: center;
  font-size: calc(0.02307137707 * 100vw);
  font-weight: 700;
  color: white;
  width: calc(0.11031002162 * 100vw);
  height: calc(0.03532804614 * 100vw);
  border-radius: calc(0.0173035328 * 100vw);
  cursor: pointer;
  pointer-events: none;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    width: 136px;
    height: 43px;
    border-radius: 20px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: 23px;
  }
`;

export const ClickOverlay = styled.div`
  position: absolute;
  top: 11%;
  left: 0;
  width: 100%;
  height: 88%;
  cursor: pointer;
`;
