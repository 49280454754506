import React, { useEffect, useState } from 'react';
import VsIcon from 'assets/img/multiplayer/versus/versus.png';
import ReadyGoSound from 'assets/audio/ready-go-v2.mp3';
import useSound from 'use-sound';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';
import AvatarPlaceholder from 'assets/img/avatar.png';
import { mappingLevelBgImage } from 'constants/dashboardv2';
import {
  StyledContainer,
  AvatarShadow,
  LevelBadge,
} from './VersusAnimation.styles';

const VersusAnimation = ({ data, styles, clearLocationState = false }) => {
  const currentLevel = data?.current?.CurrentEXPLevel?.CurrentLevel?.ID ?? 1;
  const opponentLevel = data?.opponent?.CurrentEXPLevel?.CurrentLevel?.ID ?? 1;
  const [play, { stop }] = useSound(ReadyGoSound, {
    interrupt: true,
  });
  const [showAnimation, setShowAnimation] = useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(true);

  useEffect(() => {
    if (clearLocationState) {
      setTimeout(() => {
        window.history.replaceState({}, document.title);
      }, 300);
    }
  }, [clearLocationState]);
  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
      setShowAnimation(true);
      play();
    }, 700);
    setTimeout(() => {
      setShowAnimation(false);
    }, 3500);
    setTimeout(() => {
      setShouldDisplay(false);
      document.body.style.overflow = 'initial';
    }, 4000);
    return () => {
      stop();
    };
  }, [play, stop]);
  return (
    <StyledContainer shouldDisplay={shouldDisplay} styles={styles}>
      <div className={`c-left ${showAnimation ? 'show' : ''}`}>
        <div className="c-avatar">
          <img
            src={data?.current?.UserAvatarImage ?? AvatarPlaceholder}
            alt=""
            onError={(e) => {
              e.target.src = AvatarPlaceholder;
            }}
          />
          <AvatarShadow />
        </div>
        <h3 className="c-name">{data?.current?.FullName ?? ''}</h3>
        <LevelBadge level={currentLevel}>
          <img
            src={mappingLevelBgImage(currentLevel)}
            alt=""
            width="101px"
            height="27px"
          />
          <p className="level-badge">
            Lvl&nbsp;
            <span>{currentLevel}</span>
          </p>
        </LevelBadge>
        <div className="c-country">
          <img
            src={`https://s3.ap-southeast-1.amazonaws.com/static.koobits/countryflags/64/${data?.current?.Country?.IsoCode2?.toLowerCase()}_64.png`}
            alt=""
            onError={(e) => {
              e.target.style.display = 'none';
            }}
          />
          <span
            style={{ fontSize: '16px', marginLeft: '18px', color: '#020202' }}
          >
            {data?.current?.Country?.Name
              ? data?.current?.Country?.Name.split(' ')
                  .map((word) => capitalizeFirstLetter(word))
                  .join(' ')
              : ''}
          </span>
        </div>
      </div>
      <div className={`c-icon ${showAnimation ? 'show' : ''}`}>
        <img src={VsIcon} alt="VS" />
      </div>
      <div className={`c-right ${showAnimation ? 'show' : ''}`}>
        <div className="c-avatar">
          <img
            src={data?.opponent?.UserAvatarImage ?? AvatarPlaceholder}
            alt=""
            onError={(e) => {
              e.target.src = AvatarPlaceholder;
            }}
          />
          <AvatarShadow />
        </div>
        <h3 className="c-name">{data?.opponent?.FullName ?? ''}</h3>
        <LevelBadge level={opponentLevel}>
          <img
            src={mappingLevelBgImage(opponentLevel)}
            alt=""
            width="101px"
            height="27px"
          />
          <p className="level-badge">
            Lvl&nbsp;
            <span>{opponentLevel}</span>
          </p>
        </LevelBadge>
        <div className="c-country">
          <img
            src={`https://s3.ap-southeast-1.amazonaws.com/static.koobits/countryflags/64/${data?.opponent?.Country?.IsoCode2?.toLowerCase()}_64.png`}
            alt=""
            width="38px"
            onError={(e) => {
              e.target.style.display = 'none';
            }}
          />
          <span
            style={{
              fontSize: '16px',
              marginLeft: '18px',
              color: '#020202',
            }}
          >
            {data?.opponent?.Country?.Name
              ? data?.opponent?.Country?.Name.split(' ')
                  .map((word) => capitalizeFirstLetter(word))
                  .join(' ')
              : ''}
          </span>
        </div>
      </div>
    </StyledContainer>
  );
};

export default VersusAnimation;
