import styled from 'styled-components';

export const Container = styled.div`
  button {
    display: flex;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    padding: 5px 15px;
    color: #727679;
    align-items: center;
    box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.07);
    svg {
      margin-right: 10px;
      color: #c2c2c2;
    }
    p {
      margin-bottom: 0px;
      margin-right: 0px;
      line-height: 1;
    }
    &:hover {
      color: #404447;
      svg {
        color: #a3d6dd;
      }
    }
  }
  ${({ style }) => style}
`;
