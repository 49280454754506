import styled from 'styled-components';

export const KeyboardPad = styled.div`
  margin-bottom: 80px;
  margin-top: 60px;

  &:after {
    display: block;
    clear: both;
    content: '';
  }
`;

export const KeyPad = styled.button`
  background-color: #cfe4ff;
  color: #064da4;
  border: 0;
  font-size: 48px;
  float: left;
  margin-left: 4px;
  height: 60px;
  width: 85px;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 4px;
  outline: none !important;
  padding: 0;
  align-self: center;
  font-weight: 600;

  &.key-clear,
  &.key-back,
  &.key-enter {
    font-size: 24px;
  }

  &.key-clear,
  &.key-back {
    background-color: #73affb;
  }

  &.key-enter {
    background-color: #faa707;
  }

  &.key-back,
  &.key-enter {
    width: 174px;
  }
`;
