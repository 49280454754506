import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Avatar from 'assets/img/avatar.png';
import { useSelector } from 'react-redux';
import { getChallengerInfo } from 'pages/Multiplayer/PeerChallengeQnView';
import {
  Container,
  ActionButton,
  ExpireText,
} from './IncomingChallengeCard.styles';

const IncomingChallengeCard = ({
  challenge,
  showConfirmation,
  setSelectedPCId,
  onAccept,
  isLoading,
}) => {
  const { t } = useTranslation(['incomingChallenge']);
  const { userID } = useSelector((state) => state.login);
  const { userInfoCache } = useSelector((state) => state.lookup);
  const challengeInfo = getChallengerInfo(
    challenge.Challengers,
    userID,
    userInfoCache
  );
  const challenger = challengeInfo.opponent;

  const displayExpiryDate = (challengeDate) => {
    const expiryDateTime = moment(challengeDate)
      .add(3, 'days')
      .set({ hour: 6, minute: 0, second: 0 });
    return expiryDateTime.format('DD/MM/YYYY');
  };

  return (
    <div>
      <Container>
        <img
          src={challenger?.UserAvatarImage || Avatar}
          width="100px"
          height="100px"
          alt="challenger-pic"
          className="challenger-picture"
          onError={(e) => {
            e.target.src = Avatar;
          }}
        />
        <div style={{ textAlign: 'left' }}>
          <p className="opponent-name">{challenger?.FullName ?? ''}</p>
          <span className="date">{`${t(
            'incomingChallenge:main.sentOn',
            'Sent on'
          )} ${moment(challenge.ChallengeDate).format('D/MM/YYYY')}`}</span>
          <div style={{ marginTop: '2rem' }}>
            <ActionButton
              data-cy={`reject-btn-${challenge.Id}`}
              onClick={() => {
                setSelectedPCId(challenge.Id);
                showConfirmation();
              }}
              isActive={false}
            >
              {t('incomingChallenge:main.reject', 'Reject')}
            </ActionButton>
            <ActionButton
              data-cy={`accept-btn-${challenge.Id}`}
              onClick={onAccept(challenge.Id, userID, challenger?.Id)}
              isActive
              disabled={isLoading}
            >
              {t('incomingChallenge:main.accept', 'Accept')}
            </ActionButton>
          </div>
        </div>
      </Container>
      <ExpireText
        style={{
          fontSize: '0.75rem',
          fontWeight: 'normal',
          color: 'rgba(20, 19, 19, 0.32)',
          margin: '0.25rem 0 0 0.5rem',
        }}
      >
        {`${t('incomingChallenge:main.expired', 'Expired on')} `}
        <span data-cy="expired-date">
          {displayExpiryDate(challenge.ChallengeDate)}
        </span>
      </ExpireText>
    </div>
  );
};

export default IncomingChallengeCard;
