import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import EmptyAssignment from 'assets/img/assignment/empty-assignment.svg';
import HwStatusTabs from 'components/Assignment/AssignmentList/HwStatusTabs';
import YearDropdown from 'components/Assignment/AssignmentList/YearDropdown';
import AssignerCheckBox from 'components/Assignment/AssignmentList/AssignerCheckBox';
import MonthlyBorder from 'components/Assignment/AssignmentList/MonthlyBorder';
import EmptySection from 'components/Assignment/AssignmentList/EmptySection';
import AssignmentCard from 'components/Assignment/AssignmentList/AssignmentCard';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import InfiniteScroll from 'react-infinite-scroller';
import sortAssignment from 'helpers/parseAssignmentList';
import {
  getAssignmentList,
  getMoreAssignments,
} from 'store/assignment/assignmentListSlice';
import AssignmentBackButton from 'components/Assignment/AssignmentList/AssignmentBackButton';

import { resetAssignmentDetails } from 'store/assignment/assignmentSlice';
import { assignmentTypeIDs, FilterSubmissionStatus } from 'constants/index';
import {
  Container,
  AssignmentsList,
  TopBar,
  EmptySplash,
} from './AssignmentList.styles';

// HELPERS
function* range(start, end, step) {
  while (start >= end) {
    yield start;
    start += step;
  }
}

const generateYears = () => {
  const currentYear = moment().year();
  return Array.from(range(currentYear, currentYear - 6, -1));
};

function* monthRange(start, end, step) {
  while (start >= end) {
    yield {
      month: start,
      assignments: [],
    };
    start += step;
  }
}

const generateMonths = (start) => {
  return Array.from(monthRange(start, 0, -1));
};

const AssignmentList = () => {
  const { t } = useTranslation(['assignmentList']);
  const dispatch = useDispatch();
  const {
    assignmentList,
    isLoading,
    isLoadingMoreAssignments,
    error,
    currentPage,
    hasNextPage,
  } = useSelector((state) => state.assignmentList);
  const [assignedBy, setAssignedBy] = useState(0);
  const [subStatus, setSubStatus] = useState(FilterSubmissionStatus.All);
  const currentYear = moment().year();
  const [year, setYear] = useState(currentYear);

  const assigners = [0, 1, 2, 3];
  const years = generateYears();
  const submissionStatusTab = [
    { name: 'All' },
    { name: 'Complete' },
    { name: 'Incomplete' },
  ];

  const currentMonth = moment().month();
  const monthsArray =
    year === currentYear ? generateMonths(currentMonth) : generateMonths(11);
  const sortData = sortAssignment(assignmentList, monthsArray);
  const searchAction = useCallback(() => {
    dispatch(
      getAssignmentList({
        assignedBy,
        submissionStatus: subStatus,
        assignedYear: year,
        pageSize: 30,
        pageIndex: 0,
      })
    );
    // eslint-disable-next-line
  }, [year, assignedBy, subStatus]);
  useEffect(() => {
    searchAction();
  }, [searchAction]);

  useEffect(() => {
    dispatch(resetAssignmentDetails());
  }, [dispatch]);

  const loadMore = () => {
    if (!isLoadingMoreAssignments) {
      dispatch(
        getMoreAssignments({
          assignedBy,
          submissionStatus: subStatus,
          assignedYear: year,
          pageSize: 30,
          pageIndex: currentPage + 1,
        })
      );
    }
  };

  return (
    <Container>
      <TopBar>
        <AssignmentBackButton />
        <HwStatusTabs
          status={subStatus}
          setStatus={setSubStatus}
          tabs={submissionStatusTab}
          type="assignment"
          activeColor="#95DB48"
        />
        <YearDropdown
          className="year-top"
          selectedValue={year}
          values={years}
          setValue={setYear}
        />
      </TopBar>
      <AssignerCheckBox
        assigners={assigners}
        assignedBy={assignedBy}
        setAssignedBy={setAssignedBy}
        subStatus={subStatus}
        year={year}
        years={years}
        setYear={setYear}
      />
      {error && error !== 'No Data Found' ? (
        <ErrorModal backPage="/dashboard" errorMessage={error} />
      ) : (
        <InfiniteScroll
          initialLoad={false}
          threshold={350}
          loadMore={loadMore}
          hasMore={hasNextPage}
        >
          <AssignmentsList className="assignment-list">
            {isLoading && !isLoadingMoreAssignments && <Spinner />}
            {assignmentList.length === 0 &&
              !isLoading &&
              error === 'No Data Found' && (
                <EmptySplash>
                  <img src={EmptyAssignment} alt="" />
                  <p>
                    {t(
                      'assignmentList:noAssignment.nothingToSee',
                      'Nothing to see here - yet.'
                    )}
                  </p>
                </EmptySplash>
              )}
            {assignmentList.length > 0 &&
              sortData &&
              sortData.map((item) => (
                <div key={item.month}>
                  <MonthlyBorder
                    key={item.month}
                    type={
                      item.assignments.length === 0
                        ? year === currentYear && item.month === currentMonth
                          ? 'contain-assignment'
                          : 'no-assignment'
                        : 'contain-assignment'
                    }
                    month={
                      item.assignments.length > 0
                        ? item.assignments[0].AssignDate
                        : `${year}-${
                            item.month + 1 < 10
                              ? `0${item.month + 1}`
                              : item.month + 1
                          }-01T00:00:00.333`
                    }
                  />
                  {year === currentYear &&
                    item.month === currentMonth &&
                    item.assignments.length === 0 && <EmptySection />}
                  {item.assignments.map((assignment) => (
                    <AssignmentCard
                      key={assignment.Id}
                      id={assignment.Id}
                      submissionID={assignment.Submission_Id}
                      title={assignment.Title}
                      type={assignmentTypeIDs[assignment.Type]}
                      assigner={
                        assignment.assignedByEnum === 1
                          ? 'KooBits'
                          : assignment.AssignerName
                      }
                      assignedOn={assignment.AssignDate}
                      deadline={assignment.EndDate}
                      submissionStatus={assignment.Sub_Status}
                      expired={assignment.isExpired}
                      homeworkStatus={assignment.Status}
                      totalQn={assignment.QuestionNo}
                      correct={assignment.TotalCorrect}
                      qnAttempted={assignment.TotalAttempted}
                      startDateString={assignment.StartDate}
                      resultDateString={assignment.ResultDate}
                      startDate={new Date(assignment.StartDate)}
                      resultDate={new Date(assignment.ResultDate)}
                      refresh={() => {
                        dispatch(
                          getAssignmentList({
                            assignedBy,
                            submissionStatus: subStatus,
                            assignedYear: year,
                            pageSize: 30,
                            pageIndex: 0,
                          })
                        );
                      }}
                    />
                  ))}
                </div>
              ))}
            {isLoadingMoreAssignments && <Spinner />}
          </AssignmentsList>
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default AssignmentList;
