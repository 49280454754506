export const ICON_WIDTH = {
  1: 37,
  2: 46,
  3: 58,
};

export const ICON_WIDTH_MOBILE = {
  1: ICON_WIDTH[1] * 0.6,
  2: ICON_WIDTH[2] * 0.6,
  3: ICON_WIDTH[3] * 0.6,
};
