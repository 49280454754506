import { parseString } from 'xml2js';
import { isNil } from 'ramda';
import { checkForImgOption } from 'helpers/checkForImgTag';

export default (question) => {
  let questionContent = '';
  let mcqOptions = null;
  const studentSubmissions = [];
  let solution = '';
  let questionAnswers = [];
  if (!isNil(question)) {
    parseString(question, (_error, result) => {
      // Logs any xml parsing errors
      if (!isNil(_error)) {
        console.log(_error);
      }
      // Stores question content (HTML string) in parsed XML object
      if (result.Question?.Body) {
        questionContent = result.Question?.Body[0]?.Content[0] ?? '';
      }
      // Stores array of MCQ Options
      if (result.Question?.Options) {
        const options = result.Question?.Options[0]?.Option ?? [];
        // Check if options array has items
        if (options.length > 0) {
          mcqOptions = {
            correctOptions: [],
          };
          if ('$' in result.Question?.Options[0]) {
            mcqOptions.isOrdered =
              result.Question.Options[0].$.IsOrdered.toLowerCase() === 'true' ||
              false;
            mcqOptions.isMultiSelect =
              result.Question.Options[0].$.IsMultiSelect.toLowerCase() ===
                'true' || false;
          }
          mcqOptions.options = options.map((option) => {
            return {
              id: option.$.ID,
              isCorrect: option.$.Iscorrect?.toLowerCase() === 'true',
              refId: option.$.RefId,
              content: option.Content[0],
            };
          });
          // Checks if any of the options' content have img tag
          mcqOptions.hasImgOption = checkForImgOption(
            mcqOptions.options.map((option) => option.content)
          );
          // Store options that have attribute Iscorrect == true
          mcqOptions.correctOptions = mcqOptions.options.filter(
            (option) => option.isCorrect
          );
        }
      }
      if (result.Question?.Solution) {
        solution = result.Question?.Solution[0]?.Content[0] ?? '';
      }
      // Stores array of answer key-value pairs
      if (result.Question?.Answers) {
        questionAnswers = result.Question?.Answers[0]?.Answer ?? [];
      }

      // Stores array of submissions
      if (result.Question?.Submissions) {
        const submissions = result.Question?.Submissions[0]?.Submission ?? [];
        if (submissions.length > 0) {
          // Loop through array of <Submission> elements
          for (const submission of submissions) {
            // <Submission> element may not have any child <Answers> element
            let answers = [];
            if (submission.hasOwnProperty('Answers')) {
              answers = submission.Answers[0]?.Answer ?? [];
            }
            const options = submission.hasOwnProperty('Options')
              ? submission.Options[0]?.Option
              : [];
            // Store attributes of each <Submission> element
            const submissionObj = {
              id: submission.$.Id,
              keyId: submission.$.KeyId,
              type: submission.$.Type,
              version: submission.$.Version,
              answers,
              answerkeys: [],
              options,
              optionRefIds: [],
            };
            // Check if there is at least 1 element in array of Answer elements
            if (answers.length > 0) {
              for (const answer of answers) {
                let currentIsMarkedWrite;
                if (answer.$.hasOwnProperty('IsMarkedWrite') !== true) {
                  currentIsMarkedWrite = 'Unknown';
                } else {
                  currentIsMarkedWrite =
                    answer.$.IsMarkedWrite.toLowerCase() === 'true';
                }
                const answerKey = {
                  key: answer.$.Key,
                  value: answer.$.Value,
                  ismarkedwrite: currentIsMarkedWrite,
                };
                submissionObj.answerkeys.push(answerKey);
              }
            }
            // Check if there is at least 1 element in array of Option elements
            if (options.length > 0) {
              for (const option of options) {
                const optionRefId = {
                  refId: option.$.RefId,
                };
                submissionObj.optionRefIds.push(optionRefId);
              }
            }
            studentSubmissions.push(submissionObj);
          }
        }
      }
    });
  }
  return {
    questionContent,
    mcqOptions,
    studentSubmissions,
    solution,
    questionAnswers,
  };
};
