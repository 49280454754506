import styled from 'styled-components';

export const StyledNavBar = styled.div`
  height: 100%;
  background: #f2f2f2;
  width: 291px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 18px;
  background: ${(props) =>
    props.assignmentType === 'sundayMC' ? '#21b4ef' : '#0dccaa'};
`;

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  margin-right: 25px;
`;

export const Spacer = styled.div`
  width: ${(props) => props.width ?? '0'};
`;

export const HelpersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  background: #ebf7f9;
`;

export const ProficiencyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 40px;
  background: #e0e5e4;
  .section {
    display: flex;
    align-items: center;
    .progress {
      width: 155px;
      height: 12px;
      margin-right: 1rem;
      border-radius: 1rem;
      background: #f6fbff;
      .bg-correct {
        background: #58cf67;
      }
      .bg-wrong {
        background: #ffb8b8;
      }
      .bg-skipped {
        background: #bbbbbb;
      }
    }
    .num-correct {
      color: #36ac45;
    }
    span {
      font-size: 1.125rem;
      font-weight: 600;
      color: #989898;
    }
  }
`;

export const QuestionsContainer = styled.div`
  height: calc(100% - 201.5px);
  overflow-y: auto;
`;
