import styled from 'styled-components';
import AchievementsIcon from 'assets/img/achievements.svg';
import BlueBg from 'assets/img/dashboard/blue-bg.svg';
import YellowBg from 'assets/img/dashboard/yellow-bg.svg';
import MathXPIcon from 'assets/img/exp-level/xp.png';
import ScienceXPIcon from 'assets/img/exp-level/sciencexp.png';

export const subjectStyles = {
  math: {
    xpIcon: MathXPIcon,
  },
  science: {
    xpIcon: ScienceXPIcon,
  },
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
  }
`;

export const Bar = styled.div`
  padding: 0.5rem 5rem 0rem 8rem;
  background: ${(props) => props.background ?? '  '};
  width: 800px;
  height: 79.21px;
  border-radius: 5rem;
  color: white;
  .details {
    display: flex;
    flex-direction: column;
    .name {
      font-size: 1.3125rem;
      font-weight: 600;
    }
    .school {
      display: flex;
      .logo {
        margin-right: 0.5rem;
      }
    }
  }
  @media (min-width: 2560px) {
    width: 1500px;
    height: 110px;
    .details {
      font-size: 1.5rem;
      .name {
        font-size: 2rem;
      }
      .school {
        .logo {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
  @media (min-width: 1700px) {
    width: 1050px;
    height: 90px;
    .details {
      font-size: 1.2rem;
      .name {
        font-size: 1.5rem;
      }
      .school {
        .logo {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    border-radius: 0;
    height: 76px;
    padding: 0.5rem 4rem 1rem 5.8rem;
    .details {
      height: 100%;
      justify-content: flex-end;
      .name {
        font-size: 14px;
      }
      .school {
        font-size: 12px;
      }
      .logo {
        width: 15px;
        height: 15px;
      }
    }
  }
`;

export const KoKoMonster = styled.div`
  background: url(${(props) => (props.plan === 'school' ? BlueBg : YellowBg)});
  background-size: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 10;
  position: relative;
  left: 6rem;
  padding-top: 6px;
  box-shadow: ${(props) =>
    `0px 7px 0px 0px ${props.theme[props.plan].kokoMonsterBorderColor}`};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};
  img {
    display: block;
    max-width: 100%;
  }
  @media (min-width: 2560px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    position: absolute;
    left: 0;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    box-shadow: none;
    left: 28px;
    width: 50px;
    height: 50px;
  }
`;

export const AchievementsBadge = styled.img.attrs((props) => ({
  src: AchievementsIcon,
  alt: 'Achievements',
}))`
  visibility: ${(props) => (props.showAchievement ? 'visible' : 'hidden')};
  position: relative;
  top: 0.2rem;
  right: 7.5rem;
  z-index: 10;
  cursor: ${(props) => (props.isFeatureReady ? 'pointer' : 'auto')};
  opacity: ${(props) => (props.isFeatureReady ? '1' : '0.32')};
`;

export const ExpLevel = styled.div`
  position: relative;
  top: 0.2rem;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  margin-left: -10rem;
  right: 5rem;
  margin-right: 3rem;
  .xp-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .xp-score {
    padding-top: 0.2rem;
    padding-right: 0.5rem;
    color: white;
    font-size: 28px;
    font-weight: 600;
    width: 100px;
    text-align: right;
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      display: none;
    }
  }
  .xp-text {
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      display: none;
    }
  }
  .navigate-exp {
    all: unset;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      box-shadow: 2px #000000;
    }
  }
  .badge-icon {
    img {
      height: 75px;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 0;
    margin-left: -1rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .badge-icon {
      img {
        height: 35px;
      }
    }
  }
`;

export const StyledSpinner = styled.div`
  margin-left: 11rem;
  margin-right: 1.5rem;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    margin: 0;
  }
`;
