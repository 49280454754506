import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_HOMEWORK_API_DOMAIN;
export const submitFactualFluencyRequest = (params) => {
  const { HomeworkSubmissionId, rawBody } = params;
  return fetch(
    `${baseUrl}Homework/FinishByAllQuestions/${HomeworkSubmissionId}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
      body: JSON.stringify(rawBody),
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit assignment failed');
    });
};

export const fetchLeaderboardRequest = (HomeworkId) => {
  return fetch(`${baseUrl}Homework/FactualFluency/Leaderboard/${HomeworkId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'get leaderboard failed');
    });
};
