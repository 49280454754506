export function arrayQueryParams(ids, key) {
  let keyName = key ? key : 'ids=';
  let filter = '';
  for (let i = 0; i < ids.length; i++) {
    if (ids.length === 0) {
      filter += keyName + '';
    } else if (i === ids.length - 1) {
      filter += keyName + ids[i];
    } else {
      filter += keyName + ids[i] + '&';
    }
  }
  return `?${filter}`;
}
