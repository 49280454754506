import React, { useMemo } from 'react';
import useQuery from 'helpers/useQuery';
import { withPlanCheck, withSubscriptionCheck } from 'helpers/hoc';
import DCScience from './DCScience';

const ScienceDailyChallenge = () => {
  const query = useQuery();
  const plan = query.get('plan');
  const WrappedComponent = useMemo(() => {
    return ['B2B', 'B2C'].includes(plan)
      ? withPlanCheck(DCScience, 'science', plan)
      : withSubscriptionCheck(DCScience, 'science');
  }, [plan]);
  return <WrappedComponent />;
};

export default ScienceDailyChallenge;
