import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import ModalVideo from 'react-modal-video';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  unlockMissionPlusStage,
  getMissionPlusTopics,
  setSelectedLevel,
  setSelectedTopic,
  getSelfPracticeCurriculum,
  getMissionPlusTotalProgress,
} from 'store/mission/missionSlice';

import formatChineseNumber from 'helpers/formatChineseNumber';
import useQuery from 'helpers/useQuery';
import getSubjectByCurrId from 'helpers/Mission/getSubjectByCurrId';
import { subjectNames } from 'constants/products';

import MobileSidebar from 'components/Sidebar/MobileSidebar';
import BackButton from 'components/Shared/BackButton';
import DropdownMenu from 'components/Shared/DropdownMenu';
import TopicList from 'components/Mission/TopicList';
import ModalContentAlert from 'components/Shared/ModalContentAlert';
import ErrorModal from 'components/Shared/ErrorModal';
import Spinner from 'components/Shared/Spinner';
import {
  SkillCardMissionPlus,
  LegendMissionPlus,
  SkillsProgressScience,
} from 'components/Mission/MissionPlus';

import {
  Header,
  Content,
  TopicSection,
  StyledDropdownIcon,
  SkillSection,
} from './SelfPracticePlus.styles';

const SelfPracticePlus = () => {
  const { t, i18n } = useTranslation(['selfPractice', 'common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const { subject } = useParams();
  const { curriculumId } = useParams();
  const { state } = useLocation();
  const subjectId =
    state?.subject ?? subjectNames[subject] ?? getSubjectByCurrId(curriculumId);
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const {
    topics,
    topicsLoading,
    topicErr,
    selectedLevel,
    selectedTopic,
    selfPracticeCurriculum,
    isLoading,
    isErr,
    missionPlusTotalProgress,
    unlockMissionPlus,
  } = useSelector((state) => state.mission);
  const { products } = useSelector((state) => state.plan);

  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedStage, setSelectedStage] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(true);
  const [topicSkillId, setTopicSkillId] = useState(null);
  const currentLanguage = i18n.language;
  const levelBasedOnCurr =
    selfPracticeCurriculum &&
    selfPracticeCurriculum
      .filter((curr) => curr.CurriculumID === Number(curriculumId))
      .map((level) => level.Levels);
  const listOfLevels = !isEmpty(levelBasedOnCurr) ? levelBasedOnCurr[0] : [];

  const showLoading =
    isLoading || topicsLoading || missionPlusTotalProgress.isLoading;
  const showErr =
    !isNil(isErr) || !isNil(topicErr) || !isNil(missionPlusTotalProgress.isErr);
  const showContent =
    !showLoading &&
    !showErr &&
    !isNil(selectedTopic) &&
    !isEmpty(levelBasedOnCurr);

  const levelsTemplate = (value) => {
    if (value < 7) {
      // Format numbers for chinese languages
      const formattedValue = formatChineseNumber({
        value,
        language: currentLanguage,
      });
      return `${t(
        `selfPractice:sidebar.primary`,
        'Primary'
      )} ${formattedValue}`;
    }
    const findLevel = listOfLevels.filter((item) => item.ID === value);
    return findLevel[0].Name;
  };

  const changeLevel = (level) => {
    dispatch(setSelectedLevel(level.ID));
  };
  const getTopics = useCallback(async () => {
    try {
      const params = {
        levelId: selectedLevel,
        curriculumId,
      };
      if (subject) {
        const searchTopicId = Number(query.get('topicId'));
        params.searchTopicId = searchTopicId;
      }
      const res = await dispatch(getMissionPlusTopics(params));
      unwrapResult(res);
      dispatch(
        getMissionPlusTotalProgress({
          curriculumId: res.payload[0].CurriculumId,
          levelId: res.payload[0].LevelId,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, selectedLevel, curriculumId, subject, query]);
  const changeTopic = (id) => {
    const newTopic = topics.find((topic) => topic.TopicId === id);
    dispatch(setSelectedTopic(newTopic));
  };

  const openUnlockStageModal = (stage, { topicSkillId }) => {
    setShowUnlockModal(true);
    setSelectedStage(stage);
    setTopicSkillId(topicSkillId);
  };
  const unlockStage = async () => {
    try {
      const result = await dispatch(
        unlockMissionPlusStage({
          topicSkillId,
          stageTypeId: selectedStage.StageTypeId,
        })
      );
      unwrapResult(result);
      setShowUnlockModal(false);
      return history.push({
        pathname: `/mission/self-practice/plus/${topicSkillId}/question-view/${selectedStage.StageTypeId}`,
        state: { curriculumId, subject: subjectId },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getTotalProgress = () => {
    dispatch(
      getMissionPlusTotalProgress({
        curriculumId,
        levelId: selectedTopic.LevelId,
      })
    );
  };
  const reloadHandler = () => {
    if (topicErr || isErr) {
      dispatch(getSelfPracticeCurriculum());
      dispatch(getMissionPlusTopics({ levelId: selectedLevel, curriculumId }));
    }
    if (unlockMissionPlus.isErr) {
      unlockStage();
    }
    if (missionPlusTotalProgress.isErr) {
      getTotalProgress();
    }
  };

  useEffect(() => {
    if (isNil(selectedLevel)) {
      if (subject) {
        if (listOfLevels.length > 0) {
          const primaryLevel = Number(query.get('primaryLevel'));
          const findLevel = listOfLevels.find(
            (item) => item.ID === Number(primaryLevel)
          );
          dispatch(setSelectedLevel(findLevel?.ID || defaultLevel));
        }
      } else {
        dispatch(setSelectedLevel(defaultLevel));
      }
    }
  }, [defaultLevel, dispatch, selectedLevel, listOfLevels, subject, query]);

  useEffect(() => {
    if (!isNil(selectedLevel)) {
      getTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedLevel]);
  useEffect(() => {
    if (products !== null && isEmpty(selfPracticeCurriculum)) {
      dispatch(getSelfPracticeCurriculum({ isScience: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, products]);

  return (
    <>
      <ModalContentAlert
        showModal={showUnlockModal}
        header={t('selfPractice:unlockStage.header', 'This stage is locked')}
        title={t(
          'selfPractice:unlockStage.msg',
          'You have not completed the previous stage. Are you sure you want to unlock this stage now?'
        )}
        noHandle={() => setShowUnlockModal(false)}
        yesHandle={unlockStage}
      />
      <ModalVideo
        channel="youtube"
        isOpen={showVideoModal}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
        }}
        videoId={selectedVideo}
        onClose={() => setShowVideoModal(false)}
      />
      <Header>
        <div className="backButton-container">
          <BackButton
            backText={t('common:back', 'Back')}
            btnBgColor="#00B592"
            fontSize="0.875rem"
            isResponsive
            padding="0.4rem 1.2rem"
            styles={{
              fontWeight: 'bold',
            }}
            iconSize="0.7rem"
            onClick={() => {
              history.push('/mission/curriculum-list');
            }}
          />
        </div>

        {showContent && (
          <>
            {missionPlusTotalProgress.EarnedBadges > 0 ? (
              <SkillsProgressScience
                goldMedal={missionPlusTotalProgress.EarnedBadges}
                totalSkills={missionPlusTotalProgress.totalSkills}
                bgColour="rgb(255, 255, 255, 0.5)"
              />
            ) : (
              <p className="header-title">
                <Trans i18nKey="selfPractice:header.clickStartBtn">
                  Click <span>Start Button</span> to Practice!{' '}
                </Trans>
                <br />
                {t(
                  'selfPractice:header.totalSkill',
                  { number: missionPlusTotalProgress.totalSkills },
                  `(Total ${missionPlusTotalProgress.totalSkills} skills)`
                )}
              </p>
            )}
          </>
        )}
      </Header>
      <Content>
        {showLoading && <Spinner />}
        {(showErr || Boolean(unlockMissionPlus.isErr)) && (
          <ErrorModal
            reloadAction={reloadHandler}
            errorMessage={
              isErr ||
              topicErr ||
              unlockMissionPlus.isErr ||
              missionPlusTotalProgress.isErr
            }
          />
        )}
        {showContent && (
          <>
            <MobileSidebar
              topics={topics}
              color="green"
              selectedTopic={selectedTopic}
              changeTopic={changeTopic}
              listOfLevels={listOfLevels}
              valueKey="ID"
              valueTemplate={levelsTemplate}
              changeLevel={changeLevel}
              selectedValue={levelsTemplate(selectedLevel)}
            />
            <TopicSection>
              <DropdownMenu
                selectedValue={levelsTemplate(selectedLevel)}
                valueKey="ID"
                values={listOfLevels}
                setValue={changeLevel}
                width="250px"
                className="drop-down"
                widthipad="170px"
                valueTemplate={levelsTemplate}
                feature="mission"
                toggleIcon={
                  <StyledDropdownIcon>
                    {t('selfPractice:sidebar.changeLevel', 'Change Level')}
                  </StyledDropdownIcon>
                }
              />
              <TopicList
                data={topics}
                selectedTopic={selectedTopic}
                changeTopic={changeTopic}
                variant="green"
                page="missionPlus"
              />
            </TopicSection>
            <SkillSection>
              <LegendMissionPlus position="absolute" />
              <p className="topic-title">{selectedTopic.TopicName}</p>
              {selectedTopic.ViewTopicSkillModels.map((item, index) => {
                return (
                  <SkillCardMissionPlus
                    key={
                      item.TopicSkillId + item.MasteryLevel + item.MasteryLevel
                    }
                    data={item}
                    curriculumId={curriculumId}
                    order={index + 1}
                    unlockHandler={openUnlockStageModal}
                    setSelectedVideo={setSelectedVideo}
                    setShowVideoModal={setShowVideoModal}
                    subjectId={subjectId}
                  />
                );
              })}
            </SkillSection>
          </>
        )}
      </Content>
    </>
  );
};

export default SelfPracticePlus;
