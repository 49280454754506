import styled from 'styled-components';

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  .support {
    color: #ff6f05;
  }
  .expired-mascot {
    position: absolute;
    right: 10%;
    bottom: 0%;
  }
`;

export const PaymentHeader = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  width: 85%;
  .title-container {
    margin-left: 1rem;
    h5 {
      margin-bottom: 0.1rem;
    }
  }
  .msg {
    color: #a3a3a3;
    text-align: center;
  }
`;
