import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import DropdownMenu from 'components/Shared/DropdownMenu';
import ToggleIcon from 'assets/img/icon-toggle.svg';
import {
  leaderboardList as list,
  checkIfChallengeOpen,
} from 'constants/leaderboard';
import { useHistory } from 'react-router-dom';
import { getRewards } from 'store/dashboard/rewardsSlice';
import { ChallengeTypeStyle } from './ChallengeType.styles';

const ChallengeType = ({ styles, page, subject }) => {
  const { t } = useTranslation(['leaderboardlist']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { bannerNotif } = useSelector((state) => state.notification);
  const activeLink = history.location.pathname;
  const leaderboardData = subject === 2 ? list.science : list.math;

  const filterChallenge = leaderboardData.filter((leaderboard) => {
    const open = leaderboard.status === 'open';
    const hasStartEndTime =
      leaderboard.startDateTime && leaderboard.endDateTime;
    if (hasStartEndTime) {
      return (
        checkIfChallengeOpen(
          leaderboard.startDateTime,
          leaderboard.endDateTime
        ) && open
      );
    }
    return open;
  });

  const activeChallenge = filterChallenge.find((e) => e.link === activeLink);
  const currentChallengeName =
    activeChallenge &&
    activeChallenge.hasOwnProperty('name') &&
    activeChallenge.name;
  const [selectedChallenge, setSelectedChallenge] = useState('');

  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch]);

  const changeChallenge = (val) => {
    setSelectedChallenge(val);
    history.push(val.link);
  };

  return (
    <ChallengeTypeStyle
      style={{ ...styles }}
      banner={!isNil(bannerNotif)}
      page={page}
    >
      <div className="border-dropdown">
        <DropdownMenu
          selectedValue={
            selectedChallenge === ''
              ? t(
                  `leaderboardlist:challengeList.${currentChallengeName}`,
                  `${currentChallengeName}`
                )
              : t(
                  `leaderboardlist:challengeList.${selectedChallenge.name}`,
                  `${selectedChallenge.name}`
                )
          }
          valueKey="name"
          values={filterChallenge}
          setValue={changeChallenge}
          width="160px"
          className="challenge-drop-down"
          feature="leaderboard"
          borderRadius="5px"
          borderColor="#05519C"
          valueTemplate={(value) =>
            t(`leaderboardlist:challengeList.${value}`, `${value}`)
          }
          toggleIcon={
            <div className="toggle-icon">
              <img src={ToggleIcon} alt="" />
            </div>
          }
        />
      </div>
    </ChallengeTypeStyle>
  );
};

export default ChallengeType;
