import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchUserCurriculums = ({ signal }) => {
  return fetch(`${baseUrl}MultiCurriculumReport/GetUserCurriculums`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    signal,
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get user curriculums failed'); // eslint-disable-line
    });
};

export const fetchUserProficiencyAndMasteryReport = ({
  userId,
  classId,
  curriculumId,
  levelId,
  signal,
}) => {
  return fetch(
    `${baseUrl}MultiCurriculumReport/GetUserProficiencyAndMasteryReport/${userId}/${classId}/${curriculumId}/${levelId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
      signal,
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get user proficiency and mastery report failed'
      ); // eslint-disable-line
    });
};

export const fetchQuestionById = ({ id }) => {
  return fetch(
    `${baseUrl}MultiCurriculumReport/GetQuestionById/${id}/false/true/false`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get question by id failed'); // eslint-disable-line
    });
};
