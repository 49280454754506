import styled from 'styled-components';
import SparklesImg from 'assets/img/level/sparkles.png';

export const Container = styled.div`
  background: #008dd8;
  border-radius: 25px;
  width: 280px;
  min-height: 416px;
  position: relative;
  bottom: 2.2rem;
  text-align: center;
  padding: 2.5rem 1rem 1rem 1rem;
  font-weight: 600;
  box-shadow: 0px 10px 0px 0px rgba(2, 99, 150, 1);

  .new-level {
    color: white;
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;
  }

  .total-exp {
    color: white;
    margin: 0;
    font-size: 1rem;
  }

  .level-badge {
    background-image: url(${SparklesImg});
    background-repeat: no-repeat;
    background-position-y: 10%;
  }

  .exp-amt {
    color: white;
    margin: 0;
    font-size: 1.75rem;
    font-family: 'Linotte-Heavy';
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ModalBg = styled.img`
  z-index: -1;
  position: absolute;
  bottom: 10%;
  -webkit-animation: fade 2s linear infinite;
  -moz-animation: fade 2s linear infinite;
  animation: fade 2s linear infinite;
  opacity: 0.2;

  @keyframes fade {
    0% {
      opacity: 0.2;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.2;
    }
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const AwesomeButton = styled.button`
  background: #ff6701;
  min-width: 187.38px;
  min-height: 40.93px;
  color: white;
  font-size: 1.5rem;
  border: none;
  border-radius: 1.25rem;
  :hover {
    background: #ff5801;
  }
`;
