import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, isEmpty } from 'ramda';
import moment from 'moment';

import { featureToggle } from 'constants/index';
import {
  leaderboardList,
  leaderboardFeatureNames,
  checkIfChallengeOpen,
} from 'constants/leaderboard';

import {
  getStaticUserNotification,
  getUserNotification,
} from 'store/notification/notificationSlice';

import useGlobalFeature from 'hooks/useGlobalFeature';

import Header from 'components/Header';
import BannerNotification from 'components/Notifications/BannerNotification';

import LeaderboardList from './LeaderboardList';
import HeroOfTheDay from './HeroOfTheDay';
import TopBrain from './TopBrain';
import ChampionClass from './ChampionClass';
import Bff from './Bff';
import Superhero from './Superhero';
import BoysVsGirls from './BoysVsGirls';
import ZodiacRace from './ZodiacRace';
import WizardSchool from './WizardSchool';
import LionCity from './LionCity';
import { Content } from './index.styles';
import FactualFluency from './FactualFluency';
import ScienceHeroOfTheDay from './Science/HeroOfTheDay/HeroOfTheDay';
import ScienceTopBrain from './Science/TopBrain/TopBrain';
import {
  HOD,
  TopBrainV2,
  ChampionClassV2,
  Superhero as SuperheroV2,
  BFF as BFFV2,
} from './v2/pages';

export const checkIsCurrentDateTimeBetweenHours = (startTime, endTime) => {
  const timeFormat = 'HH:mm';
  const startHour = parseInt(startTime.slice(0, 3), 10);
  const endHour = parseInt(endTime.slice(0, 3), 10);
  const hasTwoTimePeriods = endHour < startHour;
  const now = moment();
  if (hasTwoTimePeriods) {
    const firstPeriodStartDateTime = moment(startTime, timeFormat);
    const firstPeriodEndDateTime = moment('23:59', timeFormat);
    const secondPeriodStartDateTime = moment('00:00', timeFormat);
    const secondPeriodEndDateTime = moment(endTime, timeFormat);
    return (
      now.isBetween(
        firstPeriodStartDateTime,
        firstPeriodEndDateTime,
        'minute',
        '[]'
      ) ||
      now.isBetween(
        secondPeriodStartDateTime,
        secondPeriodEndDateTime,
        'minute',
        '[]'
      )
    );
  }
  const startDateTime = moment(startTime, timeFormat);
  const endDateTime = moment(endTime, timeFormat);
  return now.isBetween(startDateTime, endDateTime, 'minute', '[]');
};

const Leaderboard = () => {
  const dispatch = useDispatch();
  const { notificationsDetail, bannerNotif, userNotification } = useSelector(
    (state) => state.notification
  );
  const {
    nonExpiredSubscriptionPlans,
    subscriptionData,
    defaultLevel,
    countryCode,
    studentDetails,
  } = useSelector((state) => state.studentDetails);
  const { featuresHashmap } = useGlobalFeature(null, [
    'hod-leaderboard',
    'topbrain-leaderboard',
    'science-topbrain-leaderboard',
    'championclass-leaderboard',
    'bff-leaderboard',
    'superhero-leaderboard',
    'zodiacrace-leaderboard',
    'boysvsgirls-leaderboard',
    'wizardschool-leaderboard',
    'lioncity-leaderboard',
    'factualfluency-leaderboard',
  ]);
  const disabledLeaderboards = Object.keys(leaderboardFeatureNames).filter(
    (leaderboard) => {
      const featureExists = !isNil(featuresHashmap[leaderboard]);
      const leaderboardFeature = leaderboardFeatureNames[leaderboard];
      const { subjectType, leaderboardListIndex } = leaderboardFeature;
      const leaderboardListItem =
        subjectType && leaderboardListIndex
          ? leaderboardList[subjectType][leaderboardListIndex]
          : null;
      const isSeasonal =
        leaderboardListItem &&
        leaderboardListItem.startDateTime &&
        leaderboardListItem.endDateTime;
      const isEnabled = featureExists
        ? featuresHashmap[leaderboard].isEnabled
        : true;
      const startTime =
        featuresHashmap[leaderboard]?.feature?.parameters?.startTime;
      const endTime =
        featuresHashmap[leaderboard]?.feature?.parameters?.endTime;
      const hasStartEndTime = featureExists
        ? !isNil(startTime) &&
          !isNil(endTime) &&
          startTime.length === 5 &&
          endTime.length === 5
        : false;

      if (isSeasonal) {
        const isOpen = checkIfChallengeOpen(
          leaderboardListItem.startDateTime,
          leaderboardListItem.endDateTime
        );
        if (!isOpen) return false;
      }
      if (isEnabled) {
        return false;
      }
      if (hasStartEndTime) {
        const isOpen = checkIsCurrentDateTimeBetweenHours(startTime, endTime);
        return !isOpen;
      }
      return true;
    }
  );
  const disabledLeaderboardRoutes = disabledLeaderboards.reduce(
    (previous, current) => {
      const currentPaths = leaderboardFeatureNames[current].paths;
      return [...previous, ...currentPaths];
    },
    []
  );
  useEffect(() => {
    if (
      !isNil(studentDetails) &&
      isNil(userNotification) &&
      !featureToggle.staticNotification
    ) {
      dispatch(
        getUserNotification({
          countryCode,
          defaultLevel,
          nonExpiredSubscriptionPlans,
          subscriptionData,
          allPlans: studentDetails.Subscriptions,
        })
      );
    }
    if (featureToggle.staticNotification && isEmpty(notificationsDetail)) {
      dispatch(getStaticUserNotification());
    }
  }, [
    countryCode,
    defaultLevel,
    dispatch,
    nonExpiredSubscriptionPlans,
    notificationsDetail,
    studentDetails,
    subscriptionData,
    userNotification,
  ]);
  return (
    <>
      <Header />
      <BannerNotification />
      <Content banner={!isNil(bannerNotif)}>
        <Switch>
          {disabledLeaderboards.length > 0 && (
            <Redirect
              exact
              from={disabledLeaderboardRoutes}
              to="/maintenance-redirect"
            />
          )}
          <Route exact path="/leaderboard" component={LeaderboardList} />
          <Route
            exact
            path="/leaderboard/hero-of-the-day/math/v1"
            component={HeroOfTheDay}
          />
          <Route
            exact
            path="/leaderboard/hero-of-the-day/:subject"
            component={HOD}
          />
          <Route
            exact
            path="/leaderboard/top-brain/math/v1"
            component={TopBrain}
          />
          <Route
            exact
            path="/leaderboard/top-brain/:subject"
            component={TopBrainV2}
          />
          <Route
            exact
            path="/leaderboard/champion-class/math/v1"
            component={ChampionClass}
          />
          <Route
            exact
            path="/leaderboard/champion-class/:subject"
            component={ChampionClassV2}
          />
          <Route
            exact
            path="/leaderboard/best-friends-forever/v1"
            component={Bff}
          />
          <Route
            exact
            path="/leaderboard/best-friends-forever"
            component={BFFV2}
          />
          <Route
            exact
            path="/leaderboard/super-hero/v1"
            component={Superhero}
          />
          <Route exact path="/leaderboard/super-hero" component={SuperheroV2} />
          <Route
            exact
            path="/leaderboard/boys-vs-girls"
            component={BoysVsGirls}
          />
          <Route
            exact
            path="/leaderboard/factual-fluency/:HomeworkId"
            component={FactualFluency}
          />
          <Route exact path="/leaderboard/zodiac-race" component={ZodiacRace} />
          <Route
            exact
            path="/leaderboard/wizard-school"
            component={WizardSchool}
          />
          <Route exact path="/leaderboard/lion-city" component={LionCity} />
          <Route
            exact
            path="/leaderboard/hero-of-the-day/science/v1"
            component={ScienceHeroOfTheDay}
          />
          <Route
            exact
            path="/leaderboard/top-brain/science/v1"
            component={ScienceTopBrain}
          />
        </Switch>
      </Content>
    </>
  );
};

export default Leaderboard;
