import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;
export const RedDot = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background: #ff3e03;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
`;
