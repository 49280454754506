import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_LEADERBOARD_API_DOMAIN;

// get seasons
export const fetchSeasons = () => {
  return fetch(`${baseUrl}Leaderboard/ZodiacRace/GetSeasons`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get all seasons failed'); // eslint-disable-line
    });
};

// get all groups
export const fetchGroups = ({ seasonID }) => {
  return fetch(`${baseUrl}Leaderboard/ZodiacRace/GetZodiacGroups/${seasonID}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get all groups failed'); // eslint-disable-line
    });
};

// get friends
export const fetchStudents = ({ zodiacID, seasonID }) => {
  return fetch(
    `${baseUrl}Leaderboard/ZodiacRace/GetStudentsList/${zodiacID}/${seasonID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get student list for zodiac race failed'
      ); // eslint-disable-line
    });
};

const studentServicesBaseUrl = process.env.REACT_APP_API_DOMAIN;
export const updateDoB = (doBString) => {
  return fetch(`${studentServicesBaseUrl}Profile/UpdateDOB/${doBString}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update user DoB failed'); // eslint-disable-line
    });
};

// get current user zodiac
export const fetchCurrentUserZodiac = ({ seasonID }) => {
  return fetch(
    `${baseUrl}Leaderboard/ZodiacRace/GetCurrentUserZodiacRace/${seasonID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get current user zodiac failed'); // eslint-disable-line
    });
};
