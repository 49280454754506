import styled from 'styled-components';
import MainBg from 'assets/img/doodle-bg.svg';

export const Container = styled.div`
  background: ${(props) => props.bgColour ?? '#87796d'};
  min-height: 100vh;
  overflow-x: hidden;
`;

export const PeerChallengeHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin-bottom: 0px;
    color: #070707;
    font-size: 18px;
  }
  .user-img {
    margin: 0rem 0.8rem;
    border: 3px solid #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
`;

export const Main = styled.div`
  background: url(${MainBg});
  background-repeat: repeat-y;
  background-size: contain;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  width: 100vw;

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-top: 68px;
  }
`;

export const NotebookHeader = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.location !== 'bottom' ? 'flex-end' : 'space-between'};
  align-items: center;
  width: 97%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  p {
    margin-bottom: 0rem;
    color: #028ba0;
  }
  button {
    font-size: 16px;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 0rem;
    margin-bottom: 1rem;
    button {
      font-size: 18px;
    }
  }
`;
