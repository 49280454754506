import styled from 'styled-components';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

export const Page = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  flex-direction: column;
  display: flex;
  box-sizing: inherit;
`;

export const Content = styled.div`
  flex: 1 0 auto;
  padding: 0 35px 2em;
  background-color: #ffdf32;
`;

export const StoryMathHeader = styled.div`
  margin-top: 59px;
  position: relative;
  height: 80px;
  margin-bottom: 25px;
  text-align: center;
`;

export const LogoWrapper = styled.div`
  position: relative;
  height: 80px;
  margin-bottom: 25px;
  text-align: center;
`;

export const LeftWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 1em;
  left: 3.2em;
  align-items: center;
  @media (max-width: 1024px) {
    margin: 50px 0;
  }
`;

export const PortalWrapper = styled.div``;

export const LevelWrapper = styled.div`
  font-size: 0.9em;
  position: absolute;
  top: 5px;
  left: ${({ lang }) => (lang ? '180px' : '160px')};
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Title = styled.span`
  font-size: 0.9em;
`;

export const Logo = styled.img`
  height: 77px;
`;

export const BookClub = styled.a`
  position: absolute;
  top: 1em;
  z-index: 50;
  right: 0;
  @media (max-width: 1024px) {
    margin: 50px 0;
  }
`;

export const FloatButton = styled.div`
  position: fixed;
  bottom: 35px;
  left: 20px;
  z-index: 3001;
  width: 183px;
  height: 58px;
  cursor: pointer;
  background-image: url(${(props) => props.src});
  padding-top: 19px;
  :hover {
    background-image: url(${(props) => props.hover});
  }
`;

export const TeacherText = styled.a`
  font-family: Arial;
  font-size: 12px;
  margin-left: 69px;
  background-color: transparent;
  :hover {
    text-decoration: none;
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: inherit;
  height: 100%;
  margin-top: -80px;
`;

export const Carousel = styled(AwesomeSlider)`
  flex: 1 1 auto;
  overflow: hidden;
  box-sizing: inherit;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
  height: 100%;
  --slider-height-percentage: 41%;
  .awssld__content {
    background-color: transparent;
    > div {
      flex: 1;
    }
  }
`;

export const ArrowWrapper = styled.a`
  display: block;
  flex: 0 1 auto;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  width: 32px;
`;

export const Arrow = styled.div`
  :focus {
    outline: none;
  }
`;

export const TimelineWrapper = styled.div`
  flex: 1 0 100%;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 99;
`;
