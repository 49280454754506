import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Ribbon from 'components/Mission/Ribbon';
import FilledStar from 'assets/img/mission/filled-star.svg';
import HalfFilledStar from 'assets/img/mission/half-filled-star.svg';
import UnfilledStar from 'assets/img/mission/incomplete-star.png';
import KokoIcon from 'assets/img/icon-koko-credits.svg';
import Button from 'components/Shared/Button';
import { HighScoreIcons } from 'constants/mission';
import { Container } from './Banner.styles';
import ConfettiWrapper from 'components/Shared/ConfettiWrapper';

const HighscoreStarsMapping = (progress, firstTry) => {
  if (firstTry || progress > 8) {
    // Gold star / 3 stars, 9 - 10 / 10 questions correct or consecutive first 5 questions
    return {
      mainStar: HighScoreIcons.A,
      subStar: Array(3).fill(FilledStar),
    };
  }
  if (progress > 5) {
    // Silver star/2 stars, 6 - 8 / 10 questions correct
    return {
      mainStar: HighScoreIcons.B,
      subStar: [FilledStar, FilledStar, UnfilledStar],
    };
  }
  if (progress > 3) {
    // Bronze star/1 star, 4 - 5 / 10 questions correct
    return {
      mainStar: HighScoreIcons.C,
      subStar: [FilledStar, UnfilledStar, UnfilledStar],
    };
  }
  if (progress >= 0) {
    // Copper star/unfilled star/0 star, 0 - 3 / 10 questions correct
    return {
      mainStar: HighScoreIcons.D,
      subStar: [HalfFilledStar, UnfilledStar, UnfilledStar],
    };
  }
};

const Banner = ({
  topicSkillId,
  canClaim,
  progress,
  inProgress,
  curriculumId,
  subjectId,
}) => {
  const { t } = useTranslation(['selfPracticeQnView']);
  const history = useHistory();
  let firstTry = false;
  if (progress === 5 && inProgress === 5 && canClaim === true) {
    firstTry = true;
  }
  const stars = HighscoreStarsMapping(progress, firstTry);
  return (
    <Container>
      <div className="layer-bg">
        {firstTry || progress > 8 ? <ConfettiWrapper /> : null}
        <div className="ribbon">
          <Ribbon
            text={
              firstTry || progress > 8
                ? t('selfPracticeQnView:content.congrats', 'Congratulations!')
                : t('selfPracticeQnView:content.jobDone', 'Job Done!')
            }
          />
        </div>
        <div className="content">
          <img className="main-star" src={stars.mainStar} alt="stars" />
          <div className="sub-star">
            {stars.subStar.map((star, index) => (
              <img key={index} src={star} alt="star" />
            ))}
          </div>
          {canClaim && (
            <Button
              dataCy="claim-btn"
              variant="primary"
              padding="0rem 1rem"
              onClick={() =>
                history.push({
                  pathname: `/mystery-koko/${curriculumId}/${topicSkillId}`,
                  state: { subject: subjectId },
                })
              }
            >
              {t(
                'selfPracticeQnView:reward.claimMyRewards',
                'Claim My Rewards'
              )}
              <span>
                <img src={KokoIcon} alt="koko-icon" />
              </span>
            </Button>
          )}
          {!canClaim && (
            <>
              <span className="claimed-text">
                {t(
                  'selfPracticeQnView:reward.rewardsClaimed',
                  'You have already claimed KoKo credit rewards for this skill'
                )}
              </span>
              <Button
                dataCy="return-button"
                variant="primary"
                margin="1rem 0rem"
                onClick={() => history.goBack()}
              >
                {t('selfPracticeQnView:reward.continue', 'Continue')}
              </Button>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Banner;
