import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AnimatedMiko from 'assets/img/loader/MIKO pump 400.gif';
import { ProgressBar } from 'react-bootstrap';
import {
  setHasSeenLoader,
  setHasScreenLoaded,
} from 'store/dashboard/studentDetailsSlice';
import { useSelector, useDispatch } from 'react-redux';
import FirstTimeSelection from 'components/PremiumSchoolToggle/FirstTimeSelection';
import { isEmpty, isNil } from 'ramda';
import useIsBelowTablet from 'hooks/responsive/useIsBelowTablet';
import { Container } from './Loader.styles';

// Custom Hooks
const useLoaderWithProducts = ({
  loadingPercentage,
  isAPILoading,
  setLoadingPercentage,
  pageNotif,
  isRead,
  directToNotifPage,
  hideLoader,
  userPremiumSchoolPlanPreference,
}) => {
  const { currentProduct, products, getProductsError } = useSelector(
    (state) => state.plan
  );
  const currentParentProduct = !isNil(currentProduct)
    ? products.find((product) => product.id === currentProduct.productParentId)
    : null;
  useEffect(() => {
    if (process.env.REACT_APP_SCIENCE === 'true') {
      if (loadingPercentage < 50) {
        setTimeout(() => {
          setLoadingPercentage(loadingPercentage + 10);
        }, 250);
      } else if (!isAPILoading && loadingPercentage < 100) {
        setTimeout(() => {
          setLoadingPercentage(loadingPercentage + 10);
        }, 250);
      } else if (!isAPILoading && loadingPercentage === 100) {
        if (getProductsError !== null) {
          return;
        }
        if (currentParentProduct?.hasPremiumSchool !== true) {
          if (!isNil(pageNotif) && !isRead && !directToNotifPage) {
            hideLoader(true);
          } else {
            hideLoader(false);
          }
        } else if (!isNil(userPremiumSchoolPlanPreference)) {
          if (!isNil(pageNotif) && !isRead && !directToNotifPage) {
            hideLoader(true);
          } else {
            hideLoader(false);
          }
        }
      }
    }
  }, [
    currentParentProduct,
    directToNotifPage,
    getProductsError,
    hideLoader,
    isAPILoading,
    isRead,
    loadingPercentage,
    pageNotif,
    setLoadingPercentage,
    userPremiumSchoolPlanPreference,
  ]);

  return { currentParentProduct };
};

export const AutoFadeoutLoader = ({
  showLoader,
  setShowLoader,
  setHasSeen,
  redirectUrl,
}) => {
  const { t } = useTranslation(['dashboard']);
  const history = useHistory();
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    if (loadingPercentage < 100) {
      setTimeout(() => {
        setLoadingPercentage(loadingPercentage + 10);
      }, 250);
    } else if (loadingPercentage === 100) {
      setShowLoader(false);
      if (!isNil(redirectUrl)) history.replace(redirectUrl);
      setTimeout(() => {
        setHasSeen(true);
      }, 2000);
    }
  }, [history, loadingPercentage, redirectUrl, setHasSeen, setShowLoader]);
  return (
    <Container toFadeout={!showLoader}>
      <p className="welcome text">
        <Trans i18nKey="dashboard:loader.welcome">
          Welcome <span>to</span> KooBits
        </Trans>
      </p>
      <img src={AnimatedMiko} alt="Miko" height="150px" width="150px" />
      <ProgressBar variant="koobits" now={loadingPercentage} max={100} />
      <p className="loading text">
        {t('dashboard:loader.loading', 'Loading...')}
      </p>
    </Container>
  );
};

const Loader = ({ isAPILoading, showLoader, setShowLoader }) => {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();
  const history = useHistory();
  const isBelowTablet = useIsBelowTablet();
  const {
    hasPremiumSchoolPlan,
    userPremiumSchoolPlanPreference,
    isLoading,
  } = useSelector((state) => state.studentDetails);
  const {
    isRead,
    directToNotifPage,
    pageNotif,
    notificationsDetail,
  } = useSelector((state) => state.notification);
  const { language } = useSelector((state) => state.login);

  // Component States
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const hideLoader = useCallback(
    (redirectToNotif) => {
      setShowLoader(false);
      dispatch(setHasScreenLoaded(true));
      if (redirectToNotif === true) {
        if (!isBelowTablet) {
          history.push({ pathname: '/notification', state: { from: 'login' } });
        }
      } else {
        setTimeout(() => {
          dispatch(setHasSeenLoader(true));
        }, 2000);
      }
    },
    [setShowLoader, dispatch, isBelowTablet, history]
  );

  // UseEffects
  const { currentParentProduct } = useLoaderWithProducts({
    loadingPercentage,
    isAPILoading,
    setLoadingPercentage,
    pageNotif,
    isRead,
    directToNotifPage,
    hideLoader,
    userPremiumSchoolPlanPreference,
  });
  const oldCondition =
    hasPremiumSchoolPlan === true &&
    isNil(userPremiumSchoolPlanPreference) &&
    loadingPercentage >= 100;
  const newCondition =
    currentParentProduct?.hasPremiumSchool === true &&
    isNil(userPremiumSchoolPlanPreference) &&
    loadingPercentage >= 100;
  const showPremiumSchoolToggleSelection =
    process.env.REACT_APP_SCIENCE === 'true' ? newCondition : oldCondition;
  useEffect(() => {
    if (process.env.REACT_APP_SCIENCE !== 'true') {
      if (loadingPercentage < 50) {
        setTimeout(() => {
          setLoadingPercentage(loadingPercentage + 10);
        }, 250);
      } else if (!isAPILoading && loadingPercentage < 100) {
        setTimeout(() => {
          setLoadingPercentage(loadingPercentage + 10);
        }, 250);
      } else if (!isAPILoading && loadingPercentage === 100) {
        if (hasPremiumSchoolPlan !== true) {
          if (!isNil(pageNotif) && !isRead && !directToNotifPage) {
            hideLoader(true);
          } else {
            hideLoader(false);
          }
        } else if (!isNil(userPremiumSchoolPlanPreference)) {
          if (!isNil(pageNotif) && !isRead && !directToNotifPage) {
            hideLoader(true);
          } else {
            hideLoader(false);
          }
        }
      }
    }
  }, [
    dispatch,
    hasPremiumSchoolPlan,
    hideLoader,
    isAPILoading,
    loadingPercentage,
    setShowLoader,
    userPremiumSchoolPlanPreference,
    isRead,
    directToNotifPage,
    notificationsDetail,
    pageNotif,
  ]);

  return (
    <Container toFadeout={!showLoader}>
      {!isEmpty(language) && !isLoading && (
        <p className="welcome text">
          <Trans i18nKey="dashboard:loader.welcome">
            Welcome <span>to</span> KooBits
          </Trans>
        </p>
      )}
      {!showPremiumSchoolToggleSelection && (
        <>
          <img src={AnimatedMiko} alt="Miko" height="150px" width="150px" />
          <ProgressBar variant="koobits" now={loadingPercentage} max={100} />
          {!isEmpty(language) && !isLoading && (
            <p className="loading text">
              {t('dashboard:loader.loading', 'Loading...')}
            </p>
          )}
        </>
      )}
      {showPremiumSchoolToggleSelection && (
        <FirstTimeSelection hideLoader={hideLoader} />
      )}
    </Container>
  );
};

export default Loader;
