import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, isEmpty } from 'ramda';

import {
  getHodLeaderboard,
  getHodTop50Leaderboard,
} from 'store/leaderboard/hodLeaderboardSlice';
import formatChineseNumber from 'helpers/formatChineseNumber';

import DropdownMenu from 'components/Shared/DropdownMenu';
import Button from 'components/Shared/Button';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import { Category, NoParticipants, HODList } from '../../components';
import { HeaderScience, HallOfFame } from './components';
import { PRIMARY_LEVELS, LEADERBOARD_ID } from '../../constant';
import { Container, ButtonContainer } from './HOD.styles';

const HODScience = () => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const { isLoading, error, data } = useSelector(
    (state) => state.hodV2.hodData
  );

  const [selectedLevel, setSelectedLevel] = useState(defaultLevel);
  const [activeCategory, setActiveCategory] = useState(2);
  const [expand, setExpand] = useState(false);

  const showLevels = (level) => {
    return `${t('leaderboard:tabs.primary', 'Primary')} ${formatChineseNumber({
      value: level,
      language: i18n.language,
    })}`;
  };

  const levelHandler = (value) => {
    setSelectedLevel(value);
  };

  const categoryHandler = (filter) => {
    setActiveCategory(filter);
    if (filter === 4) {
      setSelectedLevel(defaultLevel);
    }
  };

  const getScienceHodData = () => {
    dispatch(
      getHodLeaderboard({
        id: LEADERBOARD_ID.hod.science,
        category: activeCategory,
        level: selectedLevel,
      })
    );
  };

  const top50Handler = () => {
    dispatch(
      getHodTop50Leaderboard({
        id: LEADERBOARD_ID.hod.science,
        category: activeCategory,
        level: selectedLevel,
      })
    );
  };

  useEffect(() => {
    if (isNil(selectedLevel)) {
      setSelectedLevel(defaultLevel);
    }
  }, [defaultLevel, selectedLevel]);

  useEffect(() => {
    if (expand) {
      setExpand(false);
    }
    if (!isNil(selectedLevel)) {
      getScienceHodData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, dispatch, selectedLevel]);

  return (
    <Container>
      <HeaderScience />
      <Category
        top50Available
        availableCategory={['class', 'school', 'national']}
        active={activeCategory}
        categoryHandler={categoryHandler}
        top50Handler={top50Handler}
      >
        <DropdownMenu
          dataCy="dropdown-level"
          selectedValue={showLevels(selectedLevel)}
          values={PRIMARY_LEVELS}
          setValue={levelHandler}
          valueTemplate={showLevels}
          width="150px"
          className="drop-down"
          widthipad="170px"
        />
      </Category>
      {isLoading && <Spinner />}
      {!isLoading && !isEmpty(data) && isNil(error) && (
        <HODList data={data} category={activeCategory} />
      )}
      {!isLoading && isEmpty(data) && isNil(error) && <NoParticipants />}
      <ButtonContainer>
        <div className="button-center">
          <Button
            dataCy="hero-button"
            variant="yellow"
            fontWeight="600"
            fontSize="18px"
            width="212px"
            onClick={() => setExpand(!expand)}
          >
            {t('leaderboard:buttons.hallOfFame', `Hall of Fame`)}
          </Button>
        </div>
      </ButtonContainer>
      {expand && <HallOfFame />}
      {!isLoading && !isNil(error) && (
        <ErrorModal errorMessage={error} reloadAction={getScienceHodData} />
      )}
    </Container>
  );
};

export default HODScience;
