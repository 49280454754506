import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Title from 'components/Expired/Title';
import Description from 'components/Expired/Description';
import Payment from 'components/Expired/Payment';
import PaymentSuccess from 'components/Expired/PaymentSuccess';
import PaymentFailed from 'components/Expired/PaymentFailed';
import { Cards } from './index.styles';

const NewStandard = () => {
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const [pageNo, setPageNo] = useState(1);

  const showDesc = () => setPageNo(1);
  const renewAcc = () => setPageNo(2);
  const paymentDone = () => setPageNo(3);
  const paymentFailed = () => setPageNo(4);

  return (
    <>
      {pageNo === 1 && <Title type={'newStandard'} />}
      <Cards>
        {pageNo === 1 && (
          <Description
            type="newStandard"
            name={studentDetails && studentDetails.FullName}
            renew={renewAcc}
          />
        )}
        {pageNo === 2 && (
          <Payment
            type="newStandard"
            goBack={showDesc}
            paymentDone={paymentDone}
            paymentFailed={paymentFailed}
          />
        )}
        {pageNo === 3 && <PaymentSuccess type="newStandard" />}
        {pageNo === 4 && <PaymentFailed payAgain={renewAcc} />}
      </Cards>
    </>
  );
};

export default NewStandard;
