import React from 'react';
import ReactDom from 'react-dom';
import { StyledNavContainer } from './SideNav.styles';

const SideNav = ({ show, children }) => {
  if (!show) return null;
  return ReactDom.createPortal(
    <StyledNavContainer>{children}</StyledNavContainer>,
    document.getElementById('portal')
  );
};

export default SideNav;
