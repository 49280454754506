import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Option to validate if local storage cache belongs to current logged in user
const useLocalStorage = (
  key,
  defaultValue,
  { validateUserId = false } = {}
) => {
  const { userID } = useSelector((state) => state.login);
  const [value, setValue] = useState(() => {
    let currentValue;
    try {
      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue)
      );
    } catch (error) {
      currentValue = defaultValue;
    }
    return currentValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);
  // Optional validation if cached value belongs to current logged in user
  if (validateUserId) {
    const currentValueType = typeof value;
    let validatedValue = value;
    if (currentValueType === 'object') {
      validatedValue = value.userID === userID ? value : {};
    }
    return [validatedValue, setValue];
  }
  return [value, setValue];
};

export default useLocalStorage;
