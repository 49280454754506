import React from 'react';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { Container } from './SubmitModal.styles';

const SubmitModal = ({
  header,
  type,
  title,
  desc,
  noHandle,
  yesHandle,
  hideYesButton,
  hideNoButton,
  showOkButton,
  okBtnColor,
  error,
  loading,
  minWidth,
  isV2Options,
}) => {
  const { t } = useTranslation(['sundayMCQnVIew', 'common', 'errorModal']);
  return (
    <Container
      okBtn={okBtnColor}
      error={error}
      minWidth={minWidth}
      isV2Options={isV2Options}
    >
      <div className="header-section">{header}</div>
      <div className="body-section">
        {type && isNil(error) && <div className="warning-title">{type}</div>}
        <div className="modal-title">
          {error ? t('common:sorry', 'Sorry') : title}
        </div>
        <div className="modal-desc">
          {error
            ? error.toLowerCase() === 'unknown error'
              ? t('errorModal:someWrong', 'Something went wrong')
              : error
            : desc}
        </div>
        <div className="modal-buttons">
          {isV2Options ? (
            <>
              {hideYesButton !== true && isNil(error) && (
                <button
                  className="yes-btn btns"
                  onClick={yesHandle}
                  disabled={loading}
                >
                  {t('sundayMCQnVIew:submitModal.yes', 'Yes')}
                  {loading && (
                    <Spinner animation="border" size="sm" className="ml-2" />
                  )}
                </button>
              )}
              {hideNoButton !== true && isNil(error) && (
                <button className="no-btn btns" onClick={noHandle}>
                  {t('sundayMCQnVIew:submitModal.no', 'No')}
                </button>
              )}
            </>
          ) : (
            <>
              {hideNoButton !== true && isNil(error) && (
                <button className="no-btn btns" onClick={noHandle}>
                  {t('sundayMCQnVIew:submitModal.no', 'No')}
                </button>
              )}
              {hideYesButton !== true && isNil(error) && (
                <button
                  className="yes-btn btns"
                  onClick={yesHandle}
                  disabled={loading}
                >
                  {t('sundayMCQnVIew:submitModal.yes', 'Yes')}
                  {loading && (
                    <Spinner animation="border" size="sm" className="ml-2" />
                  )}
                </button>
              )}
            </>
          )}

          {(showOkButton === true || error) && (
            <button
              className="ok-btn btns"
              onClick={yesHandle}
              disabled={loading}
            >
              {t('common:ok', 'OK')}
            </button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default SubmitModal;
