import styled from 'styled-components';

export const ResultContainer = styled.div`
  width: 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  z-index: 2;
  .name {
    background: ${(props) => props.styling.nameBg};
    height: 32px;
    border-radius: 20px;
    padding-left: ${(props) => props.position === 'right' && '1rem'};
    border-bottom-left-radius: ${(props) =>
      props.position === 'right' && '0px'};
    border-top-left-radius: ${(props) => props.position === 'right' && '0px'};
    border-bottom-right-radius: ${(props) =>
      props.position === 'left' && '0px'};
    border-top-right-radius: ${(props) => props.position === 'left' && '0px'};
    display: flex;
    justify-content: center;
    align-items: center;
    color: #070707;
    font-size: 14px;
    z-index: 2;
    position: relative;
    span {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      width: 100%;
      padding: 0px 18px;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: ${(props) => props.styling.nameBg} transparent transparent
        transparent;
    }
  }
  .result-details {
    background: ${(props) => props.styling.detailsBg};
    min-height: 120px;
    padding-top: 18px;
    padding-bottom: 8px;
    margin-top: -1rem;
    margin-left: ${(props) => props.position === 'left' && '0.8rem'};
    margin-right: ${(props) => props.position === 'right' && '0.8rem'};
    padding-top: 1rem;
    border-radius: 20px;
    border-bottom-left-radius: ${(props) =>
      props.position === 'right' && '0px'};
    border-top-left-radius: ${(props) => props.position === 'right' && '0px'};
    border-bottom-right-radius: ${(props) =>
      props.position === 'left' && '0px'};
    border-top-right-radius: ${(props) => props.position === 'left' && '0px'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cp {
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      span {
        font-size: 12px;
        color: #ff3d3d;
        display: block;
      }
    }
    .pending-score {
      display: flex;
      padding: 0 15px;
      .pending-text {
        font-size: 12px;
        text-align: left;
        line-height: 1.2;
      }
      img {
        width: 40px;
        display: none;
      }
    }
    .score {
      display: flex;
      flex-grow: 1;
      align-items: center;
      p {
        font-family: Pacifico;
        line-height: 1;
        font-size: 28px;
        margin-bottom: 0px;
        position: relative;
        &.expired {
          font-family: 'Linotte', -apple-system, BlinkMacSystemFont, 'Segoe UI',
            'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
          font-size: 1rem;
        }
        span {
          font-size: 14px;
        }
        .correct-text {
          position: absolute;
          font-size: 10px;
          right: -6px;
          top: 62%;
          transform: translate(100%, -50%);
          color: ${(props) => props.styling.textColor};
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      .name {
        height: 40px;
        font-size: 18px;
      }
      .result-details {
        min-height: 150px;
        .cp {
          font-size: 12px;
          text-align: start;
          span {
            display: inline;
          }
        }
        .pending-score {
          font-size: 14px;
          img {
            display: block;
          }
        }

        .score {
          p {
            font-size: 47px;
            span {
              font-size: 29px;
            }
            .correct-text {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 340px;
    flex-grow: 0;
    .result-details {
      padding-top: 1rem;
      padding-bottom: 0;
      .cp {
        font-size: 16px;
        font-weight: 400;
      }
      .score {
        display: flex;
        align-items: center;
        margin-top: -1rem;
        flex-grow: 0;
        p {
          font-family: Pacifico;
          font-size: 47px;
          margin-bottom: 0px;
          span {
            font-size: 29px;
          }
        }
        .correct-text {
          margin-top: 1rem;
          margin-left: 1rem;
          color: ${(props) => props.styling.textColor};
          position: initial;
          transform: none;
        }
      }
    }
  }
`;
export const Container = styled.div`
  max-width: 680px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 6px;
  position: relative;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  .img-left {
    max-width: 250px;
    width: 38%;
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(-7%, -70%);
    &.lose {
      max-width: 120px;
      width: 25%;
      transform: translate(20%, -67%);
    }
  }
  .img-right {
    max-width: 200px;
    width: 50%;
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(15%, -75%);
    &.draw,
    &.win {
      max-width: 120px;
      width: 30%;
      transform: translate(10%, -38%);
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      margin-top: 18px;
      .img-left {
        transform: translate(-10%, -70%);
        &.lose {
          max-width: 150px;
          width: 30%;
          transform: translate(20%, -67%);
        }
      }
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      margin-top: 40px;
      max-width: none;
      .img-left {
        &.lose {
          max-width: none;
          position: absolute;
          bottom: 75%;
          left: 15%;
          top: auto;
          width: auto;
          transform: none;
        }
        &.draw,
        &.win {
          max-width: none;
          transform: none;
          width: auto;
          top: auto;
          bottom: 71%;
          left: -3%;
        }
      }
      .img-right {
        &.lose {
          max-width: none;
          position: absolute;
          bottom: 90%;
          left: auto;
          right: -1%;
          top: auto;
          width: auto;
          transform: none;
        }
        &.draw,
        &.win {
          max-width: none;
          transform: none;
          width: auto;
          top: auto;
          bottom: -1%;
          left: auto;
          right: -3%;
        }
      }
    }
  }
`;

export const Vs = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: #ffaa00;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  font-family: Rockwell;
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  z-index: 3;
  padding-right: 4px;
  box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      width: 58px;
      height: 58px;
      font-size: 32px;
      padding-right: 6px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      align-items: flex-end;
      padding-right: 0px;
    }
  }
`;
