import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  .no-submit-desc {
    min-width: 60%;
    background-color: #fff1f1;
    font-size: 18px;
    border-radius: 16px;
    padding: 50px;
    margin-bottom: 2rem;
  }
`;
