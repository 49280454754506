import { useSelector } from 'react-redux';

const useDashboard = () => {
  const { plan } = useSelector((state) => state.plan);
  const isDashboardv2 = !['school'].includes(plan);
  return {
    isDashboardv2,
  };
};

export default useDashboard;
