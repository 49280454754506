import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ isWrong }) =>
    isWrong ? '3rem 2rem 0rem 0.5rem' : '3rem 2rem 4rem 0.5rem'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1201px) {
    padding-right: 4.8rem;
  }

  .centered-next-qn-btn {
    display: flex;
    justify-content: center;
    padding: 1rem 0.5rem 2rem 0.5rem;
    margin-bottom: 1rem;
    border-bottom: dashed 1px #5bcbed;
    .next-icon {
      transform: rotate(180deg);
    }
  }
  .bottom-section {
    display: flex;
    flex-direction: column;
    background: ${({ isWrong }) => (isWrong ? '#ebf7f9' : 'none')};
    border-radius: 8px;
    padding: 0.75rem 1.125rem;
    margin-bottom: 2.875rem;

    .show-solution {
      .title {
        all: unset;
        color: #11bef0;
        text-decoration: underline;
        font-weight: 600;
        margin-top: 0.5rem;
        cursor: pointer;
      }
    }

    .next-qn-btn {
      display: flex;
      justify-content: ${({ isLast }) =>
        isLast ? 'flex-end' : 'space-between'};
      .next-icon {
        transform: rotate(180deg);
      }
      .secondary-action-btn {
        display: flex;
        gap: 5px;
        .video-btn {
          gap: 5px;
          .play-video {
            width: 23px;
            height: 16px;
          }
        }
      }
    }

    .solution-container {
      margin-top: 1.5rem;
      font-size: 1.125rem;
      .title {
        color: #11bef0;
        font-weight: 600;
        font-size: 1.375rem;
        margin-bottom: 1rem;
      }
    }
  }
  .qn-view-mobile {
    display: none;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 16px;
    width: 100vw;
    .qn-view-mobile {
      display: flex;
      padding: 0 16px;
      font-size: 13px;
      .qn-difficulty {
        display: flex;
      }
      .qn-id {
        margin-left: 10px;
      }
    }
    .alert-container {
      margin-bottom: 10px;
    }
  }
`;

export const ErrorMessage = styled.p`
  margin-left: 20px;
  font-size: 14px;
  color: red;
`;
