import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ebf7f9;
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 0.5rem;
  .title {
    display: flex;
    align-items: center;
    img {
      margin-right: 1rem;
    }
    .qn-no {
      color: #ffffff;
      background-color: #79d02f;
      border-radius: 15px;
      font-size: 24px;
      font-weight: bold;
      width: 53px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;

      @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
        width: 43px;
        height: 25px;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
  .sub-title {
    display: flex;
    align-items: center;
    .desc {
      color: #49a0ad;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0px;
      margin-right: 1rem;
    }
    .info {
      color: #2b96a0;
      background: #aae9f4;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 0.75rem;
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.25rem;
    }

    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 16px 0px 10px;
    margin-top: 0px;
  }
`;
