import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useLocalStorage from 'hooks/useLocalStorage';
import { GAEvent } from 'helpers/googleAnalytics';
import ChangeViewWidthButton from 'components/ChangeViewWidthButton';
import MobileToggleModal from 'components/MobileToggleModal';

const MobileToggleFeature = ({ plan = 'B2C' }) => {
  const [
    seenMobilePopup,
    setSeenMobilePopup,
  ] = useLocalStorage('seenMobilePopup', false, { validateUserId: false });
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const handleB2CTabletToPhone = () => {
    const currentView = query.get('view')?.toLowerCase();
    const tabletArray = ['koofun', 'kooclass'];

    if (tabletArray.includes(currentView)) {
      history.replace('/dashboard?view=koochallenge');
    }
  };

  const handleClick = () => {
    handleB2CTabletToPhone();
    const newSelectedVersion = seenMobilePopup === 2 ? 1 : 2;
    setSeenMobilePopup(newSelectedVersion);
    GAEvent({
      category:
        process.env.REACT_APP_DEV === 'true'
          ? '[Dev] Student Portal'
          : 'Student Portal',
      action: 'Toggle mobile viewport',
      label: `Toggled to ${seenMobilePopup === 2 ? 'forced tablet' : 'mobile'}`,
    });
  };

  return (
    <>
      <MobileToggleModal
        seenMobilePopup={seenMobilePopup}
        setSeenMobilePopup={setSeenMobilePopup}
      />
      <ChangeViewWidthButton
        plan={plan}
        seenMobilePopup={seenMobilePopup}
        handleClick={handleClick}
      />
    </>
  );
};

export default MobileToggleFeature;
