import styled from 'styled-components';

import SuperVisionBg from 'assets/img/superhero/supervision-intro.png';
import SuperVisionCompleteBg from 'assets/img/superhero/supervision-complete-intro.png';
import HistoryImg from 'assets/img/superhero/supervision-scorehist.png';

export const Content = styled.div`
  margin: auto;
  position: relative;
  background: ${({ completed }) =>
    `url(${
      completed ? SuperVisionCompleteBg : SuperVisionBg
    }) center no-repeat`};
  width: 852px;
  height: 570px;
`;

export const HistoryButton = styled.a`
  cursor: pointer;
  background: url(${HistoryImg}) no-repeat;
  display: block;
  float: left;
  padding: 10px 0 10px 50px;
  margin-top: 10px;
  margin-left: 10px;
  font-family: Arial !important;
  font-size: 13px !important;
  color: #fff1cf !important;

  &:hover {
    opacity: 0.8;
  }
`;

export const IntroText = styled.p`
  padding: 130px 0 0 425px;
  font-family: Arial;
  font-size: 16px;
  color: #000;
  line-height: 1;
  padding-right: 80px;
  text-align: left;
`;
export const RuleTitleText = styled.p`
  padding: 70px 0 0 420px;
  font-size: 14px !important;
  font-family: Arial !important;
  color: #000 !important;
  text-align: left;
`;

export const RulesText = styled.ul`
  font-family: Arial !important;
  color: #000 !important;
  text-align: left;
  margin-left: 430px;
  padding: 10px 0 0;
  font-size: 12px !important;
  width: 200px;
`;

export const TodayScore = styled.p`
  font-family: Verdana;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
  display: block;
  position: absolute;
  top: 352px;
  left: 676px;
  width: 80px;
  text-align: center;
`;

export const StartButton = styled.a`
  cursor: pointer;
  margin: ${({ completed }) =>
    completed ? `260px 0 0 440px` : `60px 0 0 440px`};
  background: #ff7121;
  height: 48px;
  width: 321px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 30px;
  :hover {
    background: #ff5c00;
  }
  && {
    color: white;
    &:hover {
      color: white;
    }
  }
`;

export const Thumbnail = styled.img`
  position: absolute;
  top: ${({ completed }) => (completed ? '239' : '266')}px;
  left: ${({ completed }) => (completed ? '417' : '651')}px;
  width: 127px;
  height: 163px;
`;

export const StyledLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  > div {
    padding-top: 20%;
  }
`;
