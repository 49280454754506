import React, { useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { inputAnswer } from 'store/superSpeed/superSpeedSlice';
import { useTranslation } from 'react-i18next';
import { Question, QuestionTimer } from './Question.style';

const operationMap = {
  addition: '+',
  subtraction: '-',
  multiplication: 'x',
  division: '÷',
};

function QuestionComponent() {
  const { t } = useTranslation(['superSpeed']);
  const answerContainerRef = useRef(null);
  const { current } = answerContainerRef;
  useEffect(() => {
    if (current !== null) {
      current.focus();
    }
  }, [current]);
  const dispatch = useDispatch();
  const {
    currentQuestion,
    currentQuestionTimeRemaining,
    currentAnswer,
  } = useSelector((state) => state.superSpeed);
  const onKeyDown = useCallback(
    ({ key }) => {
      dispatch(inputAnswer(key));
    },
    [dispatch]
  );
  if (currentQuestion === null) {
    return [];
  }
  const { operand1, operand2, operation } = currentQuestion;
  return (
    <>
      <QuestionTimer>
        {t('superSpeed:game.questionTimerText', 'Question Countdown ')}
        <strong>{currentQuestionTimeRemaining}</strong>
        {t('superSpeed:game.questionTimerSeconds', ' seconds')}
      </QuestionTimer>
      <Question>
        <span>{operand1}</span>
        <span className="operand">{operationMap[operation]}</span>
        <span>{operand2}</span>
        <span className="operand">=</span>
        <div
          ref={answerContainerRef}
          autoFocus
          className="answerContainer"
          onKeyDown={onKeyDown}
          tabIndex="0"
        >
          <span className="answer">{currentAnswer}</span>
          <span className="cursor" />
        </div>
      </Question>
    </>
  );
}

export default React.memo(QuestionComponent);
