import handleResponse from 'helpers/handleAPIResponse';
import { isNil } from 'ramda';

const baseUrl = process.env.REACT_APP_PEERCHALLENGE_API_DOMAIN;

export const getPCStatus = ({ userID, subjectID }) => {
  return fetch(
    `${baseUrl}PeerChallenge/User/${userID}/Subject/${subjectID}/PCStatus`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get PC Status');
    });
};

export const getIncomingPeerChallengeHistory = ({
  userID,
  challengeType,
  subjectID,
  pageIndex = 0,
  pageSize = 5,
  period = 30,
}) => {
  return fetch(
    `${baseUrl}PeerChallenge/Type/${challengeType}/User/${userID}/Subject/${subjectID}/Period/${period}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.code.toLowerCase().trim() === 'pcm003') return [];
        if (result.Message.code.toLowerCase().trim() === 'pcm016') return [];
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get incoming/peer challenge history failed'
      );
    });
};

export const getRecentPeerChallengeOpponents = ({ userID, subjectID }) => {
  if (!userID || !subjectID) throw new Error('User ID/Subject ID undefined');
  return fetch(
    `${baseUrl}PeerChallenge/User/${userID}/Subject/${subjectID}/RecentOpponents`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get recent peer challenge opponents failed'
      );
    });
};

export const getRemainingPCAttempts = ({ userID, subjectID }) => {
  if (!userID || !subjectID) throw new Error('User ID/Subject ID undefined');
  return fetch(
    `${baseUrl}PeerChallenge/User/${userID}/Subject/${subjectID}/RemainingLimit`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get remaining PC attempts failed');
    });
};

export const postNewChallenge = (params) => {
  if (!params || typeof params !== 'object') {
    throw new Error('Params undefined');
  } else {
    const requiredParams = ['CurrentUserId', 'OpponentId', 'SubjectId'];
    const missingParams = requiredParams.filter((param) =>
      isNil(params[param])
    );
    if (missingParams.length > 0)
      throw new Error(`Missing params: ${missingParams.join(', ')}`);
  }
  return fetch(`${baseUrl}PeerChallenge/Issue`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Issue new Peer Challenge failed');
    });
};

export const postSubmitPeerChallenge = ({ peerChallengeId, reqBody }) => {
  if (!peerChallengeId) throw new Error('Peer Challenge ID undefined');
  if (!reqBody) throw new Error('Request body undefined');
  return fetch(`${baseUrl}PeerChallenge/${peerChallengeId}/Submit`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    body: JSON.stringify(reqBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit Peer Challenge failed');
    });
};

export const getPCCurrentCurriculum = ({ userID }) => {
  if (!userID) throw new Error('User ID undefined');
  return fetch(`${baseUrl}PeerChallenge/User/${userID}/curriculum`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => result)
    .catch((err) => {
      throw new Error(err?.message ?? 'Get current PC curriculum failed');
    });
};

export const getPeerChallengeResult = ({ peerChallengeId, userID }) => {
  if (!peerChallengeId) throw new Error('Peer Challenge Id not defined');
  if (!userID) throw new Error('User Id not defined');
  return fetch(`${baseUrl}PeerChallenge/${peerChallengeId}/user/${userID}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get PC result failed');
    });
};

export const putAcceptRejectPeerChallenge = ({ peerChallengeId, reqBody }) => {
  if (!peerChallengeId) throw new Error('Peer Challenge Id not defined');
  if (!reqBody) throw new Error('Request body undefined');
  return fetch(`${baseUrl}PeerChallenge/${peerChallengeId}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    body: JSON.stringify(reqBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Accept/reject Peer Challenge failed');
    });
};

export const updatePCCurrentCurriculum = ({ userID, curriculumID }) => {
  if (!userID) throw new Error('User ID undefined');
  if (!curriculumID) throw new Error('Curriculum ID undefined');
  return fetch(
    `${baseUrl}PeerChallenge/User/${userID}/curriculum/assignment/${curriculumID}`,
    {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update current PC curriculum failed');
    });
};
