import React from 'react';
import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const StyledDropdown = styled(
  ({
    noXOverflow,
    containerWidth,
    maxWidth,
    portal,
    menuWidth,
    width,
    borderradius,
    feature,
    widthipad,
    bgcolor,
    ...props
  }) => <Dropdown {...props} />
)`
  display: flex;
  justify-content: ${({ portal }) => portal && 'flex-end'};
  width: ${({ containerWidth }) => containerWidth};
  #dropdown-custom {
    background-color: ${({ bgcolor }) => bgcolor};
    max-width: ${({ maxWidth }) => maxWidth};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: solid 1px
      ${(props) => (props.bordercolor ? props.bordercolor : '#d6d6d6')};
    width: ${({ width }) => width || '120px'};
    border-radius: ${({ borderradius }) => borderradius || '1px'};
    color: ${({ portal }) => (portal ? '#707070' : '#404447')};
    overflow: hidden;
    white-space: break-spaces;
    box-shadow: ${({ feature }) =>
      feature === 'leaderboard' && ' 0 0 0 10px rgba(0,0,0, 0.14)'};
    font-size: ${({ portal }) => portal && '13px'};
    @media (max-width: 1025px) {
      width: ${({ widthipad, width }) => widthipad || width};
    }
    @media (max-width: 835px) {
      flex-direction: column;
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      width: ${({ width }) => width || '100px'};
      /* width: 165px; */
    }
  }
  .dropdown-item-custom {
    width: ${({ width }) => (width ? 'fit-content' : '120px')};
    font-size: ${({ portal }) => portal && '13px'};
    &:active {
      background-color: #989898;
      width: 100%;
    }
    &:hover {
      background-color: #cdcdcd;
      width: 100%;
    }
    @media (max-width: 1025px) {
      width: ${({ widthipad, width }) => widthipad || width};
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      width: 100px;
      padding: 0.375rem 0.75rem;
    }
  }
  .dropdown-menu {
    z-index: 99;
    min-width: ${({ width }) => width || '120px'};
    max-width: ${({ width }) => width || '120px'};
    max-height: 200px;
    overflow-y: auto;
    overflow-x: ${({ noXOverflow }) => (noXOverflow ? 'hidden' : 'auto')};
    width: ${({ menuWidth }) => menuWidth};
    @media (max-width: 1025px) {
      min-width: ${({ widthipad, width }) => widthipad || width};
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      min-width: 120px;
    }
  }
  ${({ feature }) => {
    if (feature === 'mission') {
      return css`
        #dropdown-custom {
          &::after {
            display: none;
          }
        }
      `;
    }
  }}
`;
