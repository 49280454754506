import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { quickAccessMenuV3 } from 'constants/quickAccessMenu';
import useClickOutside from 'helpers/useClickOutside';
import { ReactComponent as CloseIcon } from 'assets/img/icon-close.svg';
import accessFeature, { ERROR_TYPES } from 'helpers/accessFeature';
import { fetchWrapper } from 'services/login';
import { fetchSMCStatus } from 'services/event';
import { Spinner } from 'react-bootstrap';
import logout from 'helpers/logout';
import { isNil } from 'ramda';
import useIsSchoolPlan from 'hooks/useIsSchoolPlan';
import {
  Container,
  ItemMenu,
  MenuContainer,
  MenuTitle,
} from './AccessMenuV2.styles';

const replaceKeyName = (keyName, subject) => {
  if (keyName !== 'story' || isNil(subject)) return keyName;
  return `${keyName}${subject}`.toLowerCase().trim();
};

// For Revamped Dashboard v2
const ItemV2 = ({
  feature,
  setShowPopup,
  setPopupErrCode,
  setFeatureName,
  containerKeyName,
  setOpenMenu,
}) => {
  const { t, i18n } = useTranslation(['shortcutMenu']);
  const history = useHistory();
  const { subject, currentProduct } = useSelector((state) => state.plan);
  const { timezone } = useSelector((state) => state.login);
  // Component States
  const [isLoading, setIsLoading] = useState(false);
  const {
    icon,
    name,
    link,
    isStatic,
    isExternalLink,
    keyName,
    width,
    activeIcon,
    mobileOnly,
  } = feature;
  const localisationKeyName = replaceKeyName(keyName, currentProduct?.subject);
  const isCurrentPath = history.location.pathname === link;
  const onClickHandler = async () => {
    if (isLoading) return;
    if (isExternalLink) {
      setOpenMenu(false);
      window.open(link, '_blank');
      return;
    }
    if (isStatic) {
      setOpenMenu(false);
      history.push(link);
      return;
    }
    if (keyName === 'logout') {
      logout();
      return;
    }
    let eventData;
    if (keyName === 'smc') {
      setIsLoading(true);
      try {
        eventData = await fetchWrapper(fetchSMCStatus);
        setIsLoading(false);
      } catch (err) {
        console.log(err.message);
        setIsLoading(false);
        return;
      }
    }
    const { url, error } = accessFeature({
      type: keyName,
      subject,
      featuresConfig: currentProduct?.features,
      timezone,
      eventData,
      withValidation: true,
    });
    if (!error) {
      history.push(url);
    } else if (!isNil(ERROR_TYPES[error])) {
      setPopupErrCode(error);
      const featureName = t(`shortcutMenu:${localisationKeyName}`, name);
      setFeatureName(featureName);
      setShowPopup(true);
    }
  };
  return (
    <ItemMenu
      name={name}
      lang={i18n.language}
      onClick={onClickHandler}
      isLoading={isLoading}
      iconWidth={width}
      mobileOnly={mobileOnly}
      containerKeyName={containerKeyName}
      isCurrentPath={isCurrentPath}
    >
      {isLoading && <Spinner animation="border" variant="light" />}
      <img
        src={isCurrentPath && activeIcon ? activeIcon : icon}
        alt={name}
        className="icon"
      />
      <p className="feature-name">
        {t(`shortcutMenu:${localisationKeyName}`, name)}
      </p>
    </ItemMenu>
  );
};

const AccessMenu = ({
  setShowPopup,
  setPopupErrCode,
  setFeatureName,
  setOpenMenu,
  profileOnly,
}) => {
  const { t } = useTranslation(['shortcutMenu']);
  const outsideClick = useClickOutside(() => setOpenMenu(false));
  const { isSchoolPlan } = useIsSchoolPlan();
  const menuArr = useCallback(() => {
    return quickAccessMenuV3
      .filter((menuItem) => {
        // for non school plan, split into 2 sidebar, profile only, and without profile
        if (!isSchoolPlan) {
          return profileOnly
            ? menuItem.keyName === 'profile'
            : menuItem.keyName !== 'profile';
        }
        return true;
      })
      .map((menuItem) => ({
        ...menuItem,
        arr: menuItem.arr.filter((item) => {
          if (isSchoolPlan) {
            return item.premiumOnly !== true;
          }
          return item;
        }),
      }));
  }, [isSchoolPlan, profileOnly]);
  return (
    <Container data-cy="menu-list" ref={outsideClick}>
      <MenuTitle>
        <CloseIcon className="close" onClick={() => setOpenMenu(false)} />
        <div>
          <span>
            {profileOnly
              ? t(`shortcutMenu:myAccount`, 'My Account')
              : t(`shortcutMenu:allActivities`, 'All Activities')}
          </span>
        </div>
      </MenuTitle>
      {menuArr().map((menuItem) => (
        <MenuContainer key={menuItem.keyName}>
          {menuItem.arr.map((feature) => (
            <ItemV2
              key={feature.keyName}
              feature={feature}
              setShowPopup={setShowPopup}
              setPopupErrCode={setPopupErrCode}
              setFeatureName={setFeatureName}
              containerKeyName={menuItem.keyName}
              setOpenMenu={setOpenMenu}
            />
          ))}
        </MenuContainer>
      ))}
    </Container>
  );
};

export default AccessMenu;
