import styled from 'styled-components';
import { progressBarColor } from 'constants/missionPlus';

export const Container = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

export const StyledBar = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ isActive, index }) =>
    isActive ? progressBarColor[index] : '#ebebeb'};
  margin-right: 2px;
  border-bottom-left-radius: ${({ index }) => index === 1 && '8px'};
  border-top-left-radius: ${({ index }) => index === 1 && '8px'};
  border-bottom-right-radius: ${({ index }) => index === 9 && '8px'};
  border-top-right-radius: ${({ index }) => index === 9 && '8px'};
`;
