import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  background-color: #a7a7a7;
  cursor: move;
  height: 6px;
  .deselect > & {
    background-color: transparent;
  }
`;

export const Input = styled.textarea`
  border: 1px solid #a7a7a7;
  width: 100%;
  height: 30px;
  flex-grow: 1;
  resize: none;
  &:focus,
  &:active {
    outline: none;
  }
  .deselect > & {
    border: none;
  }
`;
