import BannerAccountant from 'assets/img/leaderboard/lion-city/careers/accountant-banner.png';
import BannerAnimator from 'assets/img/leaderboard/lion-city/careers/animator-banner.png';
import BannerArchitect from 'assets/img/leaderboard/lion-city/careers/architect-banner.png';
import BannerAstronaut from 'assets/img/leaderboard/lion-city/careers/astronaut-banner.png';
import BannerChef from 'assets/img/leaderboard/lion-city/careers/chef-banner.png';
import BannerDoctor from 'assets/img/leaderboard/lion-city/careers/doctor-banner.png';
import BannerEconomist from 'assets/img/leaderboard/lion-city/careers/economist-banner.png';
import BannerFashionDesigner from 'assets/img/leaderboard/lion-city/careers/fashion-designer-banner.png';
import BannerJetFighterPilot from 'assets/img/leaderboard/lion-city/careers/jet-fighter-pilot-banner.png';
import BannerProfessionalPhotographer from 'assets/img/leaderboard/lion-city/careers/professional-photographer-banner.png';
import BannerSoftwareEngineer from 'assets/img/leaderboard/lion-city/careers/software-engineer-banner.png';
import BannerSportsCommentator from 'assets/img/leaderboard/lion-city/careers/sports-commentator-banner.png';

const lionCityBanners = {
  1: BannerAccountant,
  2: BannerAnimator,
  3: BannerArchitect,
  4: BannerAstronaut,
  5: BannerChef,
  6: BannerDoctor,
  7: BannerEconomist,
  8: BannerFashionDesigner,
  9: BannerJetFighterPilot,
  10: BannerProfessionalPhotographer,
  11: BannerSoftwareEngineer,
  12: BannerSportsCommentator,
};

export default lionCityBanners;
