import styled from 'styled-components';
import ChooseZodiacBG from 'assets/img/leaderboard/zodiac-race/chooseZodiacBG.svg';
const Container = styled.div`
  border-radius: 8px;
  width: 538px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-color: #707070;
  position: relative;
  font-size: 18px;
  .close-button {
    color: #add5f9;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 32px;
    border: 1px solid transparent;
    border-radius: 50%;
    background: #ebf6ff;
    width: 30px;
    height: 30px;
    text-align: center;
    box-shadow: 0px 2px 5px grey;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .mascot {
    position: absolute;
    left: -28%;
    bottom: -4%;
    width: 185px;
  }
  .body-section {
    width: 100%;
    background-color: #3d39a4;
    background-image: url(${ChooseZodiacBG});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -1rem center;
    border-radius: 20px;
    padding: 40px 25px 87px;
    padding-left: ${(props) => props.mascot && '50px'};
    text-align: center;
  }
  .gifts-section {
    display: flex;
    img {
      width: 45px;
      align-self: start;
    }
  }
  .description {
    font-size: 17px;
    color: #ff6600;
    margin-bottom: 10px;
    font-weight: 300;
  }
  .gift-box {
  }
  .modal-buttons {
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: ${(props) =>
      props.singleButton ? 'center' : 'space-between'};
    margin-right: ${(props) => props.mascot && '40px'};
    .btns {
      border: none;
      width: 126px;
      margin-left: 25px;
      padding: 3px 0;
      border-radius: 20px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
    }
    .yes-btn {
      background-color: #ff6701;
    }
    .no-btn {
      background-color: #ffe6d6;
      color: #ff6600;
    }
  }
`;

export default Container;
