import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import { isEmpty, isNil } from 'ramda';

import ZodiacRaceHeader from 'components/Leaderboard/ZodiacRace/ZodiacRaceHeader';
import StudentList from 'components/Leaderboard/StudentList';
import JoinedZodiacModal from 'components/Leaderboard/ZodiacRace/JoinedZodiacModal';
import DropdownMenu from 'components/Shared/DropdownMenu';

import {
  getSeasons,
  getZodiacGroups,
  getStudentsList,
  getCurrentUserZodiac,
  resetSeasons,
} from 'store/zodiacRace/zodiacRaceSlice';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';
import PopupModal from 'components/Shared/PopupModal';
import RoundedModal from 'components/Shared/RoundedModal';
import DateOfBirthForm from 'components/Leaderboard/ZodiacRace/DateOfBirthForm';
import { leaderboardList, checkIfChallengeOpen } from 'constants/leaderboard';
import showPosition from 'components/Leaderboard/showPosition';
import { Redirect } from 'react-router-dom';
import { Container, ZodiacCategory, RowCurrentUser } from './ZodiacRace.styles';
import { NoParticipant } from './TopBrain.styles';

const ZodiacRace = () => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const {
    isLoading,
    isError,
    seasons,
    groupsData,
    currentUserZodiac,
  } = useSelector((state) => state.zodiacRace);
  const { isLoading: isStudentDetailsLoading, studentDetails } = useSelector(
    (state) => state.studentDetails
  );

  const [showAllStudents, setShowAllStudents] = useState({});
  const [dropdownItems, setDropdownItems] = useState(null);
  const [showDateOfBirthModal, setShowDateOfBirthModal] = useState(false);
  const [showZodiacJoinedModal, setShowZodiacJoinedModal] = useState(false);

  const isOpen = checkIfChallengeOpen(
    leaderboardList.math[5].startDateTime,
    leaderboardList.math[5].endDateTime
  );

  useEffect(() => {
    if (
      !isStudentDetailsLoading &&
      !isNil(studentDetails) &&
      studentDetails.ZodiacId === 0
    ) {
      setShowDateOfBirthModal(true);
    }
  }, [isStudentDetailsLoading, studentDetails]);

  useEffect(() => {
    dispatch(getSeasons());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(seasons)) {
      setDropdownItems(seasons[0]);
    }
  }, [dispatch, seasons]);

  useEffect(() => {
    if (!isNil(dropdownItems)) {
      dispatch(getZodiacGroups({ seasonID: dropdownItems.ID }));
      dispatch(getCurrentUserZodiac({ seasonID: dropdownItems.ID }));
    }
  }, [dispatch, dropdownItems]);

  // Cleanup
  useEffect(() => {
    return () => {
      dispatch(resetSeasons());
    };
  }, [dispatch]);

  const changeSeason = (val) => {
    setDropdownItems(val);
    setShowAllStudents({});
  };

  const toggleAllStudent = (index, ZodiacId) => {
    const tempData = { ...showAllStudents };
    tempData[index] = !tempData[index];
    setShowAllStudents(tempData);
    if (tempData[index])
      dispatch(
        getStudentsList({ zodiacID: ZodiacId, seasonID: dropdownItems.ID })
      );
  };

  if (!isOpen) return <Redirect to="/leaderboard?subject=math" />;
  return (
    <Container>
      <ZodiacRaceHeader />
      <ZodiacCategory>
        <span>
          {!isOpen &&
            t(
              'leaderboard:zodiacRace.competitionClosed',
              'The Zodiac Race competition has closed. See you next year!'
            )}
        </span>
        <div className="curriculum-date">
          <DropdownMenu
            dataCy="dropdown-season"
            className="drop-down"
            selectedValue={dropdownItems && dropdownItems.Name}
            valueKey="Name"
            values={seasons}
            setValue={changeSeason}
            width="auto"
          />
        </div>
      </ZodiacCategory>
      {!isLoading && isError === null && groupsData.length > 0 && (
        <StudentList
          challengeMode="zodiacRace"
          reducer="zodiacRace"
          data={groupsData}
          isTopFifty
          isLoading={isLoading}
          showAllStudents={showAllStudents}
          toggleAllStudent={toggleAllStudent}
        />
      )}
      {!isLoading && isError === null && groupsData.length === 0 && (
        <NoParticipant>
          {t(
            'leaderboard:hodBanner.noParticipant',
            'No participants at the moment.'
          )}
        </NoParticipant>
      )}
      {studentDetails && studentDetails.ZodiacID !== 0 && (
        <RowCurrentUser currPos={showPosition(currentUserZodiac.Rank)}>
          <Col className="column-border">
            <Col className="column-margin">
              <h4 className="rank-title">
                {t(
                  'leaderboard:zodiacRace.yourRank',
                  {
                    zodiac: t(
                      `leaderboard:zodiacRace.${currentUserZodiac.ZodiacName}`,
                      `${currentUserZodiac.ZodiacName}`
                    ),
                  },
                  `Your Rank in ${currentUserZodiac.ZodiacName}`
                )}
              </h4>
              <div className="current-user-bg">
                <div className="current-user-zodiac">
                  <div className="rank">{currentUserZodiac.Rank}</div>
                  <div className="full-name" data-cy="zodiac-full-name">
                    {studentDetails.FullName}
                  </div>
                  <div className="cp-points">
                    <img className="cp-icon" src={CPIcon} alt="cp-icon" />
                    <span className="cp-total-point" data-cy="zodiac-total-cp">
                      {currentUserZodiac.TotalPoints}
                    </span>
                    {currentUserZodiac.IsSuperHero && <SuperheroIconModal />}
                  </div>
                  <div className="last-submission-time">
                    {/* {moment(currentUserZodiac.LastSubmissionDate).format(
                      'DD-MMM-YYYY HH:mm'
                    )} */}
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </RowCurrentUser>
      )}
      <PopupModal show={showDateOfBirthModal} backdrop="static">
        <RoundedModal
          close={() => {
            setShowDateOfBirthModal(false);
          }}
        >
          <DateOfBirthForm
            close={() => {
              setShowDateOfBirthModal(false);
            }}
            openJoinedModal={() => {
              setShowZodiacJoinedModal(true);
            }}
            seasonID={dropdownItems && dropdownItems.ID}
          />
        </RoundedModal>
      </PopupModal>
      <PopupModal show={showZodiacJoinedModal} backdrop="static">
        <JoinedZodiacModal
          onHide={() => {
            setShowZodiacJoinedModal(false);
          }}
        />
      </PopupModal>
    </Container>
  );
};

export default ZodiacRace;
