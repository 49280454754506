import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { isEmpty, isNil } from 'ramda';

import Header from 'components/Header';
import BackButton from 'components/Shared/BackButton';
import ModalContentXButton from 'components/Shared/ModalContentXButton';
import ErrorModal from 'components/Shared/ErrorModal';
import ParentForm from 'components/Science/ParentForm/ParentForm';
import Button from 'components/Shared/Button';
import OTPForm from 'components/Science/OTPForm/OTPForm';
import ScienceCheckMark from 'assets/img/science/science-checkmark.svg';
import PremiumPlanV2 from 'assets/img/plan-toggle/premium-plan.png';
import Children from 'assets/img/math/children.svg';

import { fetchWrapper } from 'services/login';
import { getCountryByIPFromIPApi } from 'services/ipLookup';
import { fetchStudentDetails } from 'services/dashboard';

import updatePreferences from 'services/premiumSchoolPreferences';

import {
  getStudentDetails,
  setIsExpired,
  setStudentDetails,
} from 'store/dashboard/studentDetailsSlice';
import {
  getProducts,
  setPlan,
  setSubject,
  setCurrentProduct,
} from 'store/plan/planSlice';
import {
  getCountries,
  getStudentParentDetail,
} from 'store/trialSignup/trialSignupSlice';
import sendFBEvent from 'helpers/facebookEventTracking';
import { subjectNames, subjectIDs } from 'constants/products';
import fbEvents from 'constants/facebookEvents';
import { trialContentConfig, trialFormConfig } from 'constants/otpTrialContent';

import { refreshToken } from 'store/login/loginSlice';
import {
  Page,
  StyledContainer,
  StyledButtonContainer,
  FinishedModal,
  StepOneModal,
  StepTwoContainer,
  StepTwoModal,
} from './OTPTrial.styles';

const INITIAL_STEP = 1;

const OTPTrial = () => {
  const { t } = useTranslation(['scienceTrial', 'mathTrial']);
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState({
    PhoneNumber: '',
    PhoneCode: '',
  });
  const [errMsg, setErrMsg] = useState('');
  const [otp, setOtp] = useState('');
  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [showModal, setShowModal] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [checkedConsent, setCheckedConsent] = useState(false);
  const [ipErr, setIpErr] = useState(null);
  const {
    products,
    subject,
    prevProduct,
    plan,
    isRedirect,
    isLoading: planIsLoading,
  } = useSelector((state) => state.plan);
  const { countries: reduxStateCountries, otpInfo } = useSelector(
    (state) => state.trialSignup
  );
  const { studentDetails, isLoading } = useSelector(
    (state) => state.studentDetails
  );
  const { subjectName } = useParams();
  const subjectId = subjectNames[subjectName?.toLowerCase()];
  const { userID } = useSelector((state) => state.login);
  const allLoading = isLoading || planIsLoading;
  const handleGetCountries = () => {
    dispatch(getCountries());
  };

  const getUserCountry = useCallback(async () => {
    try {
      setIpErr(null);
      const ipApiResult = await getCountryByIPFromIPApi();
      const { country_code_iso3: countryCodeIso3 } = ipApiResult;
      if (reduxStateCountries.data?.length > 0) {
        const findCountry = reduxStateCountries.data.find(
          (c) => c.isO3 === countryCodeIso3
        );
        if (findCountry) {
          setPhone({
            PhoneNumber: '',
            PhoneCode: findCountry.phoneCode,
          });
        }
      }
    } catch (err) {
      setIpErr(err);
    }
  }, [reduxStateCountries.data]);

  useEffect(() => {
    handleGetCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userID) {
      dispatch(getStudentParentDetail(userID));
    }
  }, [userID, dispatch]);

  useEffect(() => {
    if (otpInfo?.ParentUserId && !phone.PhoneNumber) {
      const { ParentUserName, EmailAddress, RawNumber, CountryCode } = otpInfo;
      setName(ParentUserName || '');
      setEmail(EmailAddress);
      if (CountryCode && RawNumber) {
        setPhone({
          PhoneNumber: RawNumber,
          PhoneCode: CountryCode,
        });
      } else {
        getUserCountry();
      }
    }
  }, [getUserCountry, otpInfo, phone.PhoneNumber]);

  useEffect(() => {
    if (isRedirect) {
      if (subject !== subjectName) {
        dispatch(setSubject(subjectName));
      }
      if (plan !== 'premium') {
        dispatch(setPlan('premium'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, plan, subjectName, subject]);
  useEffect(() => {
    if (!allLoading && !isNil(products)) {
      const lowerCaseSubject = subjectName?.toLowerCase();
      const findCurrentSubject = products?.find(
        (item) => item.subject?.toLowerCase() === lowerCaseSubject
      );

      if (findCurrentSubject) {
        if (
          findCurrentSubject?.nonExpiredSubscriptionProducts?.find(
            (product) => product.productType === 'Premium'
          ) ||
          findCurrentSubject.otpTrialDetails.otpStatus !== 'otp-not-started'
        ) {
          if (isEmpty(findCurrentSubject.nonExpiredSubscriptionProducts)) {
            dispatch(setIsExpired(true));
            history.push(
              lowerCaseSubject === 'math'
                ? '/math/expired'
                : '/expired-otp/science'
            );
            return;
          }
          const findCurrentPlan = findCurrentSubject.nonExpiredSubscriptionProducts.find(
            (item) => item.productType === plan
          );
          const oldPlan = findCurrentSubject.nonExpiredSubscriptionProducts[0].productType
            .toLowerCase()
            .trim();
          history.push(
            `/dashboard?subject=${lowerCaseSubject}&plan=${
              findCurrentPlan ? plan : oldPlan
            }`
          );
        }
      } else if (subjectName.toLowerCase() === 'math') {
        const hasB2B = products?.find(
          (item) => item.hasPremiumSchool || item.hasExpiredSchool
        );
        const checkMath = products?.find(
          (product) => product.subject.toLowerCase() === 'math'
        );
        if (isNil(hasB2B)) {
          if (isNil(checkMath)) {
            dispatch(setIsExpired(true));
            history.push('/math/expired');
            return;
          }
          history.push('/dashboard');
        }
      }
    }
  }, [products, subjectName, subject, allLoading, dispatch, history, plan]);
  const closeModalAndRedirect = () => {
    // Close modal & redirect to dashboard page
    setShowModal(false);
    history.replace('/dashboard');
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentStep(1);
  };
  const handleActivation = async () => {
    setIsActivating(true);
    // Send FB event to track science trial activation
    sendFBEvent(fbEvents.scienceTrialActivation, {
      id: studentDetails.ParentsDetails.CustomerId,
      email,
      phone: phone.PhoneNumber,
    });
    try {
      await refreshToken({});
      const studentDetail = await fetchWrapper(fetchStudentDetails);
      dispatch(setStudentDetails(studentDetail.Result));
    } catch (err) {
      console.log(err.message);
    }
    try {
      const getProductsAction = await dispatch(getProducts());
      const newProducts = await unwrapResult(getProductsAction);
      // Get science product & set plan preference
      const currentProduct = newProducts.parsedProducts.find(
        (product) => product.id === subjectId
      );
      const currentSubscription =
        currentProduct.nonExpiredSubscriptionProducts[0];
      if (isNil(currentSubscription)) {
        throw new Error(`${subjectIDs[subjectId]} subscription not found`);
      } else {
        fetchWrapper(updatePreferences, currentSubscription.productId);
        dispatch(setPlan(currentSubscription.productType.toLowerCase().trim()));
        dispatch(setSubject(currentSubscription.subject.toLowerCase().trim()));
        dispatch(setCurrentProduct(currentSubscription));
        dispatch(setIsExpired(false));
      }
    } catch (err) {
      console.log(err.message);
      closeModalAndRedirect();
    }
    closeModalAndRedirect();
  };

  const setDropdownValue = (value) => {
    setPhone({ ...phone, PhoneCode: value.phonecode });
  };

  const reloadHandler = () => {
    dispatch(getStudentDetails());
  };
  const modalContent = () => {
    return (
      <div>
        <ModalContentXButton
          showModal={showModal}
          closeModal={handleCloseModal}
          backdrop="static"
          showClosedButton={currentStep !== 3}
          borderRadius="5px"
          containerMinWidth="550px"
        >
          {currentStep === 1 && (
            <StepOneModal>
              <h4 className="modal-title">
                {t(
                  `${trialFormConfig[subjectName].translationKey}:stepOne.title`,
                  `${trialFormConfig[subjectName].title}`
                )}
              </h4>
              <p>
                {t(
                  `${trialFormConfig[subjectName].translationKey}:stepOne.desc`,
                  `${trialFormConfig[subjectName].desc}`
                )}
              </p>
              <ParentForm
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                checkedConsent={checkedConsent}
                setCheckedConsent={setCheckedConsent}
                setCurrentStep={setCurrentStep}
                errMsg={errMsg}
                setErrMsg={setErrMsg}
                reloadHandler={reloadHandler}
                setDropdownValue={setDropdownValue}
                subjectId={subjectId}
              />
            </StepOneModal>
          )}
          {currentStep === 2 && (
            <StepTwoModal>
              <BackButton
                grey
                btnBgColor="#fff"
                styles={{ marginLeft: '1rem', paddingTop: '1rem' }}
                onClick={() => setCurrentStep(1)}
              >
                {t('scienceTrial:back', 'Back')}
              </BackButton>
              <StepTwoContainer>
                <h4 className="otp-title">
                  {t(
                    `${trialFormConfig[subjectName].translationKey}:stepTwo.title`,
                    'OTP Verification'
                  )}
                </h4>
                <p className="otp-desc">
                  {t(
                    `${trialFormConfig[subjectName].translationKey}:stepTwo.desc`,
                    `${trialFormConfig[subjectName].otpDesc}`
                  )}
                </p>
                <OTPForm
                  otp={otp}
                  setOtp={setOtp}
                  setCurrentStep={setCurrentStep}
                  subjectId={subjectId}
                />
              </StepTwoContainer>
            </StepTwoModal>
          )}
          {currentStep === 3 && (
            <FinishedModal>
              <img
                className="check-mark"
                src={ScienceCheckMark}
                alt="science-checkmark"
                height="80px"
                width="80px"
              />
              <h4 className="finished-title">
                {t('scienceTrial:finished.title', 'Congratulations!')}
              </h4>
              <p className="finished-description">
                {t(
                  `${trialFormConfig[subjectName].translationKey}:finished.desc`,
                  `${trialFormConfig[subjectName].successText}`
                )}
              </p>

              <Button
                dataCy="activate-button"
                className="activate-button"
                variant="primary"
                width="300px"
                onClick={handleActivation}
                isDisabled={isActivating}
              >
                {t('scienceTrial:finished.activate', 'Activate Now')}
              </Button>
            </FinishedModal>
          )}
        </ModalContentXButton>
      </div>
    );
  };

  const availableProd = products?.find(
    (item) => item.subject.toLowerCase().trim() !== subject
  );

  const availableProdSubject = availableProd?.nonExpiredSubscriptionProducts[0]?.subject
    .toLowerCase()
    .trim();
  const availableProdPlan = availableProd?.nonExpiredSubscriptionProducts[0]?.productType
    .toLowerCase()
    .trim();

  const expiredSubject = availableProd?.subscriptionProducts[0]?.subject
    .toLowerCase()
    .trim();
  const expiredPlan = availableProd?.subscriptionProducts[0]?.productType
    .toLowerCase()
    .trim();

  const backHandler = () => {
    if (!isNil(prevProduct)) {
      dispatch(setSubject(prevProduct.subject.toLowerCase().trim()));
      dispatch(setPlan(prevProduct.productType.toLowerCase().trim()));
      dispatch(setCurrentProduct(prevProduct));
      dispatch(setIsExpired(prevProduct.isExpired));
      history.push(
        `/dashboard?subject=${prevProduct.subject
          .toLowerCase()
          .trim()}&plan=${prevProduct.productType.toLowerCase().trim()}`
      );
      return;
    }
    if (!isNil(availableProd)) {
      if (!isEmpty(availableProd.nonExpiredSubscriptionProducts)) {
        dispatch(setSubject(availableProdSubject));
        dispatch(setPlan(availableProdPlan));
        dispatch(
          setCurrentProduct(availableProd.nonExpiredSubscriptionProducts[0])
        );
        dispatch(
          setIsExpired(
            availableProd.nonExpiredSubscriptionProducts[0].isExpired
          )
        );
        history.push(
          `/dashboard?subject=${availableProdSubject}&plan=${availableProdPlan}`
        );
      } else {
        if (availableProd?.otpTrialDetails?.otpStatus === 'otp-not-started') {
          history.push('/trial/math');
          return;
        }
        dispatch(setSubject(expiredSubject));
        dispatch(setPlan(expiredPlan));
        dispatch(setCurrentProduct(null));
        dispatch(setIsExpired(true));
        history.push('/dashboard');
      }
    }
  };

  return (
    !allLoading && (
      <Page bg={trialContentConfig[subjectName].bg}>
        <Header
          toggleMenu={false}
          toggleProfile={subjectName === 'math'}
          toggleAccessMenu={false}
        />
        {subjectName === 'science' && (
          <StyledButtonContainer>
            <BackButton
              btnBgColor="rgb(111, 204, 7, 1)"
              padding="0.4rem 1rem"
              backText={t('scienceTrial:back', 'Back')}
              onClick={backHandler}
            />
          </StyledButtonContainer>
        )}
        <StyledContainer>
          {subjectName === 'math' && (
            <img src={Children} alt="" className="children-mascot" />
          )}
          {subjectName === 'math' && (
            <img src={PremiumPlanV2} alt="premium-plan" />
          )}
          <h3 className="main-title">
            {t(
              `${trialContentConfig[subjectName].translationKey}:productTitle`,
              `${trialContentConfig[subjectName].title}`
            )}
          </h3>
          <h5 className="main-desc">
            {t(
              `${trialContentConfig[subjectName].translationKey}:productDesc`,
              `${trialContentConfig[subjectName].desc}`
            )}
          </h5>
          <Button
            dataCy="try-button"
            variant="primary"
            fontWeight="700"
            fontSize="1.5rem"
            padding="0.5rem 6.5rem"
            onClick={() => setShowModal(true)}
            margin="1rem 0"
          >
            {t(
              `${trialContentConfig[subjectName].translationKey}:continue`,
              `${trialContentConfig[subjectName].btnText}`
            )}
          </Button>
          <iframe
            width="560"
            height="315"
            style={{ marginTop: '1rem' }}
            src={trialContentConfig[subjectName].videoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </StyledContainer>
        {modalContent()}
        {(!reduxStateCountries.isLoading && reduxStateCountries.isErr) ||
          (ipErr && (
            <ErrorModal
              errorMessage={reduxStateCountries.isErr || ipErr}
              reloadAction={
                reduxStateCountries.isErr ? handleGetCountries : getUserCountry
              }
            />
          ))}
      </Page>
    )
  );
};

export default OTPTrial;
