import React, { useMemo } from 'react';
import useQuery from 'helpers/useQuery';
import useDashboard from 'hooks/useDashboard';
import DailyChallenge from './DailyChallenge';
import DailyChallengev2 from './DailyChallengev2';
import { withPlanCheck, withSubscriptionCheck } from 'helpers/hoc';

const MathDailyChallenge = () => {
    const query = useQuery();
    const { isDashboardv2 } = useDashboard();
    const DC = isDashboardv2 ? DailyChallengev2 : DailyChallenge;
    const plan = query.get('plan');
    const WrappedComponent = useMemo(() => {
        return ['B2B', 'B2C'].includes(plan) ? withPlanCheck(DC, 'math', plan) : withSubscriptionCheck(DC, 'math');
    }, [DC, plan]);
    return <WrappedComponent />;
};

export default MathDailyChallenge;