import styled from 'styled-components';

export const TopMessage = styled.div`
  color: #707070;
`;

export const StatusInfo = styled.div`
  margin: 0 40px 0 auto;
  color: ${(props) => (props.status === 'Accepted' ? '#59D100' : '#83C9D8')};
`;
