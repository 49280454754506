import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0 20px;
  width: 70%;
  align-self: center;
  background: #ffffff;
  .button-center {
    display: flex;
    justify-content: center;
  }
`;
