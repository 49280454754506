import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { isEmpty, isNil } from 'ramda';

import {
  getActivitiesByLessonId,
  getActivityByActivityId,
  getKooClassSolutions,
  resetCurrentActivity,
  reset,
  setActiveType,
  setActiveId,
  setActivityName,
  clearSolutionError,
} from 'store/kooClass/kooClassSlice';

import Header from 'components/Assignment/AssignmentView/Header';
import QnSideBar from 'components/KooClass/QnSideBar';
import Spinner from 'components/Shared/Spinner';
import QuestionContent from 'components/Question';
import Button from 'components/Shared/Button';
import ErrorModal from 'components/Shared/ErrorModal';
import { questionTypeIDs, scoreColorsMap } from 'constants/index';
import useQuery from 'helpers/useQuery';
import {
  Container,
  Main,
  Content,
  QuestionContainer,
  ScoreContainer,
  CorrectAnswerSpan,
} from './KooClassSolution.styles';

const KooClassSolution = () => {
  const { t } = useTranslation(['assignmentSolution']);
  const { activityId, topicId, lessonId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const courseIdParam = query.get('courseId');

  const {
    isKooClassSolutionsLoading,
    isKooClassSolutionsError,
    kooClassSolutions,
    kooClassLocalSavedAnswers,
    kooClassActiveQuestion,
    activities,
    currentActivity,
  } = useSelector((state) => state.kooClass);

  // for onNextActivity onClick func
  const checkCurrentIndex =
    activities && activities.findIndex((el) => el.id === activityId);
  const nextItem =
    checkCurrentIndex + 1 < activities.length
      ? activities.find((el, index) => index === checkCurrentIndex + 1)
      : activities[0];

  const currentItem =
    activities && activities.find((el, index) => index === checkCurrentIndex);

  const onNextActivity = () => {
    dispatch(reset());
    dispatch(resetCurrentActivity());
    dispatch(setActiveId(nextItem.id));
    dispatch(setActiveType(nextItem.activityType));
    dispatch(setActivityName(nextItem.name));
    history.push(
      `/kooClass/${topicId}/lesson/${lessonId}/activity?activityType=${nextItem.activityType}&activityId=${nextItem.id}&name=${nextItem.name}&courseId=${courseIdParam}`
    );
  };

  // get total number of correct qn and total qn
  const totalCorrect =
    kooClassSolutions && kooClassSolutions.userSubmissions.length > 0
      ? kooClassSolutions.userSubmissions.filter(
          (item) =>
            !isNil(item.submissionModel) &&
            item.submissionModel.submissionResult === 1
        ).length
      : 0;

  const totalQuestions = kooClassSolutions
    ? kooClassSolutions.userSubmissions.length
    : 0;

  const score = kooClassSolutions
    ? Math.floor((totalCorrect / totalQuestions) * 100)
    : 0;

  // scroll to clicked qn
  const refs = kooClassSolutions
    ? kooClassSolutions.userSubmissions.reduce((acc, value) => {
        acc[value.question.id] = React.createRef();
        return acc;
      }, {})
    : null;

  const scrollTo = (questionID) => {
    refs[questionID].current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  // get the completed date
  const getDates =
    kooClassSolutions &&
    kooClassSolutions.userSubmissions.map(
      (item) => new Date(item.submissionModel.submissionDate)
    );

  const latestSubmissionDate = new Date(Math.max.apply(null, getDates));

  useEffect(() => {
    if (isEmpty(activities)) {
      dispatch(getActivitiesByLessonId(lessonId));
    }
    // eslint-disable-next-line
  }, [dispatch, lessonId]);

  useEffect(() => {
    if (isNil(currentActivity)) {
      dispatch(getActivityByActivityId(activityId));
    }
    // eslint-disable-next-line
  }, [dispatch, activityId]);

  useEffect(() => {
    dispatch(getKooClassSolutions(activityId));
  }, [dispatch, activityId]);

  return (
    <Container>
      <Header
        page="solution"
        assignmentTitle={kooClassSolutions && kooClassSolutions.title}
        noNavBar
        showLogo
        type="kooClassSolution"
      />
      <Main>
        {!isKooClassSolutionsLoading &&
          kooClassSolutions &&
          isKooClassSolutionsError === null && (
            <QnSideBar
              localSavedAnswers={kooClassLocalSavedAnswers}
              questions={kooClassSolutions.userSubmissions}
              activeQuestion={kooClassActiveQuestion}
              page="solution"
              scrollToHandler={scrollTo}
              assignmentType="KooClass"
            />
          )}
        <Content assignmentType="KooClass">
          {isKooClassSolutionsLoading && <Spinner color="#26B1EF" top="35%" />}
          {!isKooClassSolutionsLoading && isKooClassSolutionsError !== null && (
            <ErrorModal
              errorMessage={isKooClassSolutionsError}
              backText
              closeHandler={() => {
                dispatch(clearSolutionError());
                history.push(
                  `/kooClass/${topicId}/lesson/${lessonId}/activity?activityType=${currentItem.activityType}&activityId=${currentItem.id}&name=${currentItem.name}&courseId=${courseIdParam}`
                );
              }}
              reloadAction={() => dispatch(getKooClassSolutions(activityId))}
            />
          )}
          {!isKooClassSolutionsLoading &&
            kooClassSolutions &&
            isKooClassSolutionsError === null && (
              <>
                <div className="notebook-wrap">
                  <div className="notebook">
                    <div className="top">
                      <div className="top-content">
                        <div className="info-section">
                          <p className="title">
                            {t(
                              'assignmentSolution:words.TotalScore',
                              'Total Score'
                            )}
                          </p>
                          <ScoreContainer color={scoreColorsMap(score)}>
                            <p className="number">{score}</p>
                            <p className="char">%</p>
                          </ScoreContainer>
                          <div className="qn-number">
                            <div style={{ display: 'flex' }}>
                              ({' '}
                              <CorrectAnswerSpan color={scoreColorsMap(score)}>
                                {totalCorrect}
                              </CorrectAnswerSpan>
                              <p>
                                {t(
                                  'assignmentSolution:words.OutOf',
                                  ' out of '
                                )}
                                <strong>{totalQuestions}</strong>
                              </p>
                              )
                            </div>
                          </div>
                          <p className="completed-info">
                            {t(
                              'assignmentSolution:words.CompletedOn',
                              ' Completed on '
                            )}
                            {moment(latestSubmissionDate).format('DD-MMM-YYYY')}
                          </p>
                        </div>
                        <Button
                          variant="primary"
                          onClick={onNextActivity}
                          dataCy="next-btn"
                        >
                          {t(
                            'assignmentSolution:words.NextActivity',
                            'Next Activity'
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="middle">
                      <div className="content">
                        {kooClassSolutions.userSubmissions.map(
                          (question, index) => (
                            <QuestionContainer
                              key={question.question.id}
                              ref={refs[question.question.id]}
                              isLastQn={
                                kooClassSolutions.userSubmissions.length ===
                                index + 1
                              }
                            >
                              <p className="qn-no">
                                {t(
                                  'assignmentSolution:words.Question',
                                  'Question'
                                )}{' '}
                                {index + 1}
                              </p>
                              <QuestionContent
                                page="solution"
                                questionType={
                                  questionTypeIDs[
                                    question.question.questionType
                                  ]
                                }
                                qnID={question.question.id}
                                xml={question.submissionModel.payload}
                                submissionModel={question.submissionModel}
                                showCheckAnswer={false}
                                showWorking
                                solutionString={
                                  question.submissionModel.solution
                                }
                                showSolution
                                showSubmissions
                                qnIndex={index}
                                assignmentType="KooClass"
                                skillID={question.question.skillId}
                                showFeedbackButton
                              />
                            </QuestionContainer>
                          )
                        )}
                      </div>
                    </div>
                    <div className="bottom" />
                  </div>
                </div>
              </>
            )}
        </Content>
      </Main>
    </Container>
  );
};

export default KooClassSolution;
