import React from 'react';
import { Container } from './SubmitButton.styles';

const SubmitButton = ({
  children,
  width,
  fontSize,
  className,
  ...otherProps
}) => {
  return (
    <Container width={width} fontSize={fontSize} className={className}>
      <button className="submit-button" {...otherProps}>
        {children}
      </button>
    </Container>
  );
};

export default SubmitButton;
