import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;
  margin: 15px;
`;

export const TitleText = styled.div`
  font-family: Arial;
  color: #383838;
  font-size: 20px;
  margin: 5px;
`;

export const WrapButton = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonGroup = styled.button`
  width: 80px;
  height: 30px;
  padding: 5px;
  border-radius: 3px 1px 1px 3px;
  cursor: pointer;
  background-color: #c6c3bc;
  border: none;
  border-right: 2px solid #b5b093;
  border-bottom: 2px solid #b5b093;
  color: #ebebeb;
  margin-right: 1px;

  :last-child {
    margin-right: 0px;
  }

  &.selected {
    background-color: #fbdc36;
    border: none;
    border-right: 2px solid #e9c62c;
    border-bottom: 2px solid #e9c62c;
    color: #383838;
  }
`;
