import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchChampionClassMonth,
  fetchClassByTerm,
  fetchStudentByClassAndTerm,
  fetchClassByMonth,
  fetchStudentsByClassAndMonth,
} from 'services/championClass';
import { fetchWrapper } from 'services/login';

// get months
export const getChampionClassMonth = createAsyncThunk(
  'championClass/getChampionClassMonth',
  async () => {
    try {
      const res = await fetchWrapper(fetchChampionClassMonth);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get champion class month failed');
    }
  }
);

// get class by term
export const getClassByTerm = createAsyncThunk(
  'championClass/getClassByTerm',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchClassByTerm, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get class by term failed');
    }
  }
);

// get student by class and term
export const getStudentsByClassAndTerm = createAsyncThunk(
  'championClass/getStudentsByClassAndTerm',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchStudentByClassAndTerm, params);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get students by class and term failed'
      );
    }
  }
);

// get student by class and term
export const getStudentsTerm = createAsyncThunk(
  'championClass/getStudentsTerm',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchStudentByClassAndTerm, params);
      const obj = {
        [params.classId]: res,
      };
      return obj;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get students by class and term failed'
      );
    }
  }
);

// get class by month
export const getClassByMonth = createAsyncThunk(
  'championClass/getClassByMonth',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchClassByMonth, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get class by month failed');
    }
  }
);

// get student by class and month
export const getStudentByClassAndMonth = createAsyncThunk(
  'championClass/getStudentsByMonth',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchStudentsByClassAndMonth, params);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get students by class and month failed'
      );
    }
  }
);

export const getStudentsClassic = createAsyncThunk(
  'championClass/getStudentsClassic',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchStudentsByClassAndMonth, params);
      const obj = {
        [params.classId]: res,
      };
      return obj;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get students by class and term failed'
      );
    }
  }
);

const initialState = {
  months: [],
  monthsLoading: false,
  monthsError: null,
  championClassData: [],
  classicStudents: {},
  termClassData: [],
  termStudents: {},
  isLoading: false,
  isError: null,
  studentsData: [],
  studentsLoading: false,
  studentsError: null,
};

const championClassSlice = createSlice({
  name: 'championClass',
  initialState,
  reducers: {},
  extraReducers: {
    [getChampionClassMonth.pending]: (state) => {
      state.monthsLoading = true;
      state.months = [];
      state.monthsError = null;
    },
    [getChampionClassMonth.fulfilled]: (state, action) => {
      state.monthsLoading = false;
      state.months = action.payload;
      state.monthsError = null;
    },
    [getChampionClassMonth.rejected]: (state, action) => {
      state.monthsLoading = false;
      state.months = [];
      state.monthsError = action.error.message;
    },
    [getClassByMonth.pending]: (state) => {
      state.isLoading = true;
      state.championClassData = [];
      state.isError = null;
    },
    [getClassByMonth.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.championClassData = action.payload;
      state.isError = null;
    },
    [getClassByMonth.rejected]: (state, action) => {
      state.isLoading = false;
      state.championClassData = [];
      state.isError = action.error.message;
    },
    [getClassByTerm.pending]: (state) => {
      state.isLoading = true;
      state.termClassData = [];
      state.isError = null;
    },
    [getClassByTerm.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.termStudents = {};
      state.termClassData = action.payload;
      state.isError = null;
    },
    [getClassByTerm.rejected]: (state, action) => {
      state.isLoading = false;
      state.termClassData = [];
      state.isError = action.error.message;
    },
    [getStudentByClassAndMonth.pending]: (state) => {
      state.studentsLoading = true;
      state.studentsData = [];
      state.studentsError = null;
    },
    [getStudentByClassAndMonth.fulfilled]: (state, action) => {
      state.studentsLoading = false;
      state.studentsData = action.payload;
      state.studentsError = null;
    },
    [getStudentByClassAndMonth.rejected]: (state, action) => {
      state.studentsLoading = false;
      state.studentsData = [];
      state.studentsError = action.error.message;
    },
    [getStudentsByClassAndTerm.pending]: (state) => {
      state.studentsLoading = true;
      state.studentsData = [];
      state.studentsError = null;
    },
    [getStudentsByClassAndTerm.fulfilled]: (state, action) => {
      state.studentsLoading = false;
      state.termStudents = {};
      state.studentsData = action.payload;
      state.studentsError = null;
    },
    [getStudentsByClassAndTerm.rejected]: (state, action) => {
      state.studentsLoading = false;
      state.studentsData = [];
      state.studentsError = action.error.message;
    },
    [getStudentsTerm.pending]: (state) => {
      state.studentsLoading = true;
      state.studentsError = null;
    },
    [getStudentsTerm.fulfilled]: (state, action) => {
      state.studentsLoading = false;
      state.termStudents = { ...state.termStudents, ...action.payload };
      state.studentsError = null;
    },
    [getStudentsTerm.rejected]: (state, action) => {
      state.studentsLoading = false;
      state.studentsError = action.error.message;
    },
    [getStudentsClassic.pending]: (state) => {
      state.studentsLoading = true;
      state.studentsError = null;
    },
    [getStudentsClassic.fulfilled]: (state, action) => {
      state.studentsLoading = false;
      state.classicStudents = { ...state.classicStudents, ...action.payload };
      state.studentsError = null;
    },
    [getStudentsClassic.rejected]: (state, action) => {
      state.studentsLoading = false;
      state.studentsError = action.error.message;
    },
  },
});

const { reducer } = championClassSlice;
export default reducer;
