import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NoParticipant = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #ffffff;
  width: 70%;
  height: 100%;
  padding: 20px 30px;
  text-align: center;
`;
