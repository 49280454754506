import React from 'react';
import {
  Container,
  Top,
  Content,
  ActionBtn,
} from './AutoRenewCancelModal.styles';

const AutoRenewCancelModal = ({ expiredDate, onCancel, onProceed }) => {
  return (
    <Container>
      <Top>Are you sure you want to turn off auto-renew?</Top>
      <Content>
        <div className="expired-info">
          Your subscription is still active and only expires on {expiredDate}.
        </div>
        <p>
          <span>Auto-Renewal option gives the highest renewal discount.</span>{' '}
          This is our way to thank our loyal customers for providing an
          efficient and hassle-free way of renewal.
        </p>
        <p>
          Are you sure you want to cancel the auto-renewal and potentially lose
          the best discount offer?
        </p>
        <ActionBtn>
          <button type="button" className="white" onClick={onProceed}>
            Continue to cancel
          </button>
          <button type="button" className="orange" onClick={onCancel}>
            I will keep auto-renewal
          </button>
        </ActionBtn>
      </Content>
    </Container>
  );
};

export default AutoRenewCancelModal;
