import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const BLUE = '#1992F8';
export const StyledModal = styled(Modal)`
  .modal-content {
    background-color: transparent;
    align-items: center;
    border: none;
  }
`;
export const StyledContainer = styled(Modal.Body)`
  max-width: 647px;
  width: 100%;
  background: white;
  border-radius: 17px;
  padding: 20px;
  color: ${BLUE};
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
export const StyledTitle = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  p {
    line-height: 1;
    font-size: 50px;
    margin-bottom: 13px;
    font-weight: bold;
  }
  span {
    display: block;
    font-size: 18px;
    line-height: 1;
    font-weight: 600;
  }
`;
export const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: ${({ isButtonGroup }) =>
    isButtonGroup ? 'space-between' : 'center'};
  max-width: 530px;
  margin: 35px auto;
`;
export const StyledConcat = styled.div`
  color: ${BLUE};
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 1;
  text-align: center;
  a {
    color: ${BLUE};
    text-decoration: underline;
  }
`;
