const getCourseId = (product) => {
  const currentFeatures =
    product?.nonExpiredSubscriptionProducts[0]?.features || [];
  const kooclassObj = currentFeatures.find(
    (item) => item.feature.toLowerCase().trim() === 'kooclass'
  );
  return kooclassObj?.parameters?.CourseId;
};

export default getCourseId;
