import styled from 'styled-components';
import StyledLeaderboard from 'constants/leaderboard/StyledLeaderboard';

export const CategoryStyle = styled.div`
  background: ${({ subject }) => StyledLeaderboard[subject].headerColor};
  width: ${(props) => (props.page === 'hallOfFame' ? '100%' : '70%')};
  display: flex;
  align-self: center;
  justify-content: space-between;
  height: 60px;
  padding: 10px 20px;
  .categories-container {
    width: 60%;
    display: flex;
  }
  .right-container {
    display: flex;
    position: relative;
    .top-fifty {
      cursor: pointer;
      display: flex;
      div {
        padding: 0px 10px 0 35px;
        font-size: 15px;
      }
      img {
        position: relative;
        left: 35px;
        bottom: 8px;
        width: 65px;
        height: 50px;
      }
    }
    .select-level {
      visibility: ${(props) => props.classCategory === '1' && 'hidden'};
    }
  }
`;

export const CategoryType = styled.div`
  border-radius: 30px;
  padding: 0px 10px;
  margin-right: 15px;
  display: flex;
  font-size: 17px;
  align-items: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.active ? 'none' : 'auto')};
  background: ${({ active, subject }) =>
    active ? '#FECE18' : StyledLeaderboard[subject].btnColor};
  color: ${({ active }) => (active ? '#261E00' : '#ffffff')};
  border: ${({ subject }) =>
    `1px solid ${StyledLeaderboard[subject].borderColor}`};
  box-shadow: ${({ active }) =>
    active ? 'inset 1px 3px 0 1px #D9B328' : 'none'};
  &:hover {
    background: ${({ subject }) => StyledLeaderboard[subject].hoverColor};
  }
`;
