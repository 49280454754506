import styled from 'styled-components';

const typeColor = {
  blueLight: {
    longLine: '#90C3ED',
    shortline: '#84BBE8',
  },
  green: {
    longLine: '#0B6A21',
    shortline: '#368949',
  },
  default: {
    longLine: '#143ccb',
    shortline: '#143ccb',
  },
};

const typePage = {
  parentAssign: {
    fontFamily: 'Nunito',
    activeColor: '#202020',
    activeWeight: 'bold',
    nonActiveWeight: 600,
  },
  missionPlus: {
    fontFamily: 'Linotte',
    activeColor: '#0C7224',
    activeWeight: 'bold',
    nonActiveWeight: 600,
  },
  default: {
    fontFamily: 'Arial',
    activeColor: '#2537a3',
    activeWeight: 'bold',
    nonActiveWeight: 'bold',
  },
};

export const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  border-left: solid 1px
    ${(props) =>
      props.variant
        ? `${typeColor[props.variant].longLine}`
        : `${typeColor.default.longLine}`};
  font-family: ${(props) =>
    props.page
      ? `${typePage[props.page].fontFamily}`
      : `${typePage.default.fontFamily}`};
  font-size: 0.875rem;
  @media (max-width: 1025px) {
    font-size: 0.8rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
    overflow-y: auto;
  }
`;

export const TopicItem = styled.div`
  padding: 0.5rem 1rem;
  color: ${(props) =>
    props.active
      ? `${
          props.page
            ? `${typePage[props.page].activeColor}`
            : `${typePage.default.activeColor}`
        }`
      : 'black'};
  font-weight: ${(props) =>
    props.active
      ? `${
          props.page
            ? `${typePage[props.page].activeWeight}`
            : `${typePage.default.activeWeight}`
        }`
      : `${
          props.page
            ? `${typePage[props.page].nonActiveWeight}`
            : `${typePage.default.nonActiveWeight}`
        }`};
  border-left: ${(props) =>
    props.active
      ? `2px solid ${
          props.variant
            ? `${typeColor[props.variant].shortline}`
            : `${typeColor.default.shortline}`
        }`
      : null};
  cursor: pointer;
  @media (max-width: 1025px) {
    padding: 0.2rem 1rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 1rem;
  }
`;

export const TopicRow = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
