import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import filterGlobalTop100CurrentUser from 'components/Leaderboard/BoysVsGirls/filterGlobalTop100CurrentUser';
import {
  fetchGlobalTop100Score,
  fetchLatestScore,
  fetchSeasons,
  fetchTotalScores,
  fetchUserCurrentScore,
  postGender,
} from 'services/boysVsGirls';
import { fetchWrapper } from 'services/login';
// import {
//   currentUserScore,
//   globalTop100Scores,
//   totalScores,
//   latestScores,
// } from 'services/boysVsGirlsMock';

// // get seasons
export const getSeasons = createAsyncThunk(
  'boysVsGirls/getSeasons',
  async () => {
    try {
      const res = await fetchWrapper(fetchSeasons);
      // const res = seasonsData;
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get all seasons failed');
    }
  }
);

export const getTotalScores = createAsyncThunk(
  'boysVsGirls/getTotalScores',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchTotalScores, params);
      // if (params.seasonID === 1)
      //   return {
      //     ...totalScores[params.seasonID],
      //     teamBoysDetails: { totalCP: Math.ceil(Math.random() * 20000) },
      //     teamGirlsDetails: { totalCP: Math.ceil(Math.random() * 20000) },
      //   };
      // return totalScores[params.seasonID];
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get total scores failed');
    }
  }
);

// // get current user
export const getCurrentUserScore = createAsyncThunk(
  'boysVsGirls/getCurrentUserScore',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchUserCurrentScore, params);
      // const res = currentUserScore[params.seasonID];
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get current user score failed');
    }
  }
);

export const getLatestScore = createAsyncThunk(
  'boysVsGirls/getLatestScore',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchLatestScore, params);
      // const res =
      //   params.seasonID === 1
      //     ? latestScores[Math.ceil(Math.random() * 2)]
      //     : {
      //         boysTeamLatestCPSubmittedList: [],
      //         girlsTeamLatestCPSubmittedList: [],
      //       };
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get latest score failed');
    }
  }
);

export const getGlobalTop100Score = createAsyncThunk(
  'boysVsGirls/getGlobalTop100Score',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchGlobalTop100Score, params);
      // const res = globalTop100Scores[params.seasonID];

      const {
        isCompetitionActive,
        currentUserScore,
        userId,
        userGender,
      } = params;
      if (isCompetitionActive) {
        const processedData = filterGlobalTop100CurrentUser({
          data: res,
          currentUserScore,
          userId,
          userGender,
        });
        return processedData;
      }
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get global top 100 score failed');
    }
  }
);

export const updateGender = createAsyncThunk(
  'boysVsGirls/updateGender',
  async (genderId) => {
    try {
      const res = await fetchWrapper(postGender, genderId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update gender failed');
    }
  }
);

const initialState = {
  seasons: [],
  seasonsLoading: false,
  seasonsError: null,
  totalScores: {
    isCompetitionActive: false,
    isBlindFoldPeriod: false,
    isCurrentlyWinning: false,
    teamBoysDetails: {
      totalCP: 0,
      isCurrentlyWinning: false,
      winLoseFaceImage: null,
    },
    teamGirlsDetails: {
      totalCP: 0,
      isCurrentlyWinning: false,
      winLoseFaceImage: null,
    },
    bannerMessage: null,
  },
  teamLatestCPSubmittedList: {
    boysLatestCPSubmitted: [],
    girlsLatestCPSubmitted: [],
  },
  globalTop100List: {
    boysGlobalTop100List: [],
    girlsGlobalTop100List: [],
  },
  isLoading: false,
  isError: null,
  currentUserScore: {
    rank: '-',
    name: '-',
    cP: 0,
    isSuperHero: false,
  },
  genderUpdate: null,
  isLoadingGenderUpdate: false,
  isErrGenderUpdate: null,
};

const boysVsGirlsSlice = createSlice({
  name: 'boysVsGirls',
  initialState,
  reducers: {
    resetSeasons: (state) => {
      return {
        ...state,
        seasons: [],
      };
    },
    clearUpdateErr: (state) => {
      state.isErrGenderUpdate = null;
    },
    resetGenderUpdate: (state) => {
      state.genderUpdate = null;
      state.isErrGenderUpdate = null;
    },
  },
  extraReducers: {
    [getSeasons.pending]: (state) => {
      state.seasonsLoading = true;
      state.seasons = [];
      state.seasonsError = null;
    },
    [getSeasons.fulfilled]: (state, action) => {
      state.seasonsLoading = false;
      state.seasons = action.payload;
      state.seasonsError = null;
    },
    [getSeasons.rejected]: (state, action) => {
      state.seasonsLoading = false;
      state.seasons = [];
      state.seasonsError = action.error.message;
    },
    [getTotalScores.pending]: (state) => {
      state.isError = null;
    },
    [getTotalScores.fulfilled]: (state, action) => {
      state.totalScores = action.payload;
      state.isError = null;
    },
    [getTotalScores.rejected]: (state, action) => {
      state.totalScores = initialState.totalScores;
      state.isError = action.error.message;
    },
    [getCurrentUserScore.pending]: (state) => {
      state.isError = null;
    },
    [getCurrentUserScore.fulfilled]: (state, action) => {
      state.currentUserScore = action.payload;
      state.isError = null;
    },
    [getCurrentUserScore.rejected]: (state, action) => {
      state.currentUserScore = initialState.currentUserScore;
      state.isError = action.error.message;
    },
    [getLatestScore.pending]: (state) => {
      state.isError = null;
    },
    [getLatestScore.fulfilled]: (state, action) => {
      state.teamLatestCPSubmittedList = action.payload;
      state.isError = null;
    },
    [getLatestScore.rejected]: (state, action) => {
      state.teamLatestCPSubmittedList = initialState.teamLatestCPSubmittedList;
      state.isError = action.error.message;
    },
    [getGlobalTop100Score.pending]: (state) => {
      state.isError = null;
    },
    [getGlobalTop100Score.fulfilled]: (state, action) => {
      state.globalTop100List = action.payload;
      state.isError = null;
    },
    [getGlobalTop100Score.rejected]: (state, action) => {
      state.globalTop100List = initialState.globalTop100List;
      state.isError = action.error.message;
    },
    [updateGender.pending]: (state) => {
      state.isLoadingGenderUpdate = true;
      state.genderUpdate = null;
      state.isErrGenderUpdate = null;
    },
    [updateGender.fulfilled]: (state, action) => {
      state.isLoadingGenderUpdate = false;
      state.genderUpdate = action.payload;
      state.isErrGenderUpdate = null;
    },
    [updateGender.rejected]: (state, action) => {
      state.isLoadingGenderUpdate = false;
      state.genderUpdate = null;
      state.isErrGenderUpdate = action.error.message;
    },
  },
});

const { reducer, actions } = boysVsGirlsSlice;
export const { resetSeasons, clearUpdateErr, resetGenderUpdate } = actions;
export default reducer;
