import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 852px;
  border-radius: 8px;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background: #86d4fa;
  width: 852px;
  height: 45px;
  justify-content: flex-end;
  & > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > .timerDescription {
      font-size: 12px;
      padding: 0px 20px;
      color: #fff;
    }
    & > .timer {
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      padding-right: 20px;
    }
    & > .score {
      display: inline-block;
      width: 50px;
      font-size: 18px;
      text-align: center;
      color: #fff;
      font-weight: 700;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-left: 1px solid #6cc3f0;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
    & > .point {
      height: 26px;
      width: 38px;
    }
  }
`;

export const DifficultyBadge = styled.div`
  font-size: 12px;
  position: absolute;
  top: 5em;
  left: 2em;
  background-color: #e1eccc;
  color: #a2cb15;
  text-transform: uppercase;
  padding: 0.3em 0.75em;
`;

export const QuestionContainer = styled.div`
  margin: auto;
  height: 209px;
  width: 100%;
  background: #fff;
  max-width: 852px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const KeyboardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  padding: 0.5em 0.8em 0.2em;
  min-height: 97px;
  background-color: #c3cbd9;
`;

export const KeyboardKey = styled.a`
  position: relative;
  cursor: pointer;
  & > img {
    display: inline-block;
    border: none;
    margin-bottom: 6px;
    opacity: 1;
    transition: all 0.05s ease-in;
  }
  & > .hover {
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
  }
  &:hover > .hover {
    opacity: 1;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  flex-direction: row;
  width: 100%;
  border-radius: 0 0 8px 8px;
  background: #86d4fa;
`;

export const PauseButton = styled.button`
  border: none;
  margin-right: 8px;
  border-radius: 24px;
  color: #86d4fa;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  font-size: 22px;
  padding: 0 2em;
  background-color: #fff;
  font-family: Arial, sans-serif;
  cursor: pointer;
  text-decoration: none;
  &:last-child {
    margin-right: 0px;
  }
  &:hover {
    color: #17a0e0;
  }
`;
