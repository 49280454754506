import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import { fetchTopBrain } from 'services/leaderboardV2/topBrain';
import { RANKING_FILTER } from 'pages/Leaderboard/v2/constant';

export const getTopBrainLeaderboard = createAsyncThunk(
  'leaderboard/getTopBrainLeaderboard',
  async ({ id, category, level, periodType, periodId, isTop50 }) => {
    const params = {
      leaderboardId: id,
      category,
      rankingFilter: isTop50 ? RANKING_FILTER.top50 : RANKING_FILTER.regular,
      level,
      periodType,
      periodId,
    };
    const res = await fetchWrapper(fetchTopBrain, params);
    return handleResponseV2(res);
  }
);

const initialState = {
  type: 'term',
  isLoading: false,
  error: null,
  data: [],
};

const topBrainLeaderboardSlice = createSlice({
  name: 'topBrainLeaderboard',
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopBrainLeaderboard.pending, (state) => {
        state.data = [];
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTopBrainLeaderboard.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(getTopBrainLeaderboard.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

const { reducer, actions } = topBrainLeaderboardSlice;
export const { setType } = actions;
export default reducer;
