import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import GlobalTop100Table from '../GlobalTop100Table/GlobalTop100Table';

const GirlRow = styled(Col)`
  @media (max-width: 991px) {
    margin-top: 50px;
  }
`;

const GlobalTop100Boys = ({ globalTop100List }) => {
  const { t } = useTranslation(['leaderboard']);
  const { boysGlobalTop100List } = globalTop100List;
  return (
    <GlobalTop100Table
      color="blue"
      globalTop100List={boysGlobalTop100List}
      title={t('leaderboard:boysVsGirls.ranking.topBoy', 'Global Top 100 Boys')}
    />
  );
};

const GlobalTop100Girls = ({ globalTop100List }) => {
  const { t } = useTranslation(['leaderboard']);
  const { girlsGlobalTop100List } = globalTop100List;
  return (
    <GlobalTop100Table
      color="pink"
      globalTop100List={girlsGlobalTop100List}
      title={t(
        'leaderboard:boysVsGirls.ranking.topGirl',
        'Global Top 100 Girls'
      )}
    />
  );
};

const MainGlobalTop100 = ({ globalTop100List }) => (
  <Row style={{ paddingTop: 100 }}>
    <Col lg={6}>
      <GlobalTop100Boys globalTop100List={globalTop100List} />
    </Col>
    <GirlRow lg={6}>
      <GlobalTop100Girls globalTop100List={globalTop100List} />
    </GirlRow>
  </Row>
);

export default MainGlobalTop100;
