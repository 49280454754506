import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';
import { isNil } from 'ramda';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const getClassesbyCurrentKidLevelRequest = () => {
  return fetch(`${baseUrl}Lookup/ClassesByCurrentKidLevel`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get Classes by Current Kid Level failed'
      ); // eslint-disable-line
    });
};

export const getStudentbyClassIdRequest = (classId) => {
  return fetch(`${baseUrl}Lookup/StudentsByClassId/${classId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.code?.toLowerCase() === 'er001') return [];
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get Student by Class ID failed'); // eslint-disable-line
    });
};

export const getClassesBySchoolIdRequest = ({ schoolId, level }) => {
  return fetch(
    `${baseUrl}Lookup/ClassesBySchoolAndLevel/${schoolId}/${level}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get Classes by School ID and Level failed'
      ); // eslint-disable-line
    });
};

export const openPeerChallengeRequest = (opponentId) => {
  return fetch(`${baseUrl}PeerChallenge/NewPeerChallenge/${opponentId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Open Peer Challenge failed'); // eslint-disable-line
    });
};

export const fetchIncomingChallenge = () => {
  return fetch(`${baseUrl}PeerChallenge/GetIncomingChallenges`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.Message === 'No incoming peer challenges found')
          return {};
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch incoming challenge failed'); // eslint-disable-line
    });
};

export const acceptChallengeRequest = ({ peerChallengeId }) => {
  return fetch(
    `${baseUrl}PeerChallenge/AcceptPeerChallenge/${peerChallengeId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Accept challenge failed'); // eslint-disable-line
    });
};

export const rejectChallengeRequest = ({ peerChallengeId }) => {
  return fetch(
    `${baseUrl}PeerChallenge/RejectPeerChallenge/${peerChallengeId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.Message === 'No incoming peer challenges found')
          return result.Result;
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Reject challenge failed'); // eslint-disable-line
    });
};

export const fetchPeerChallengeHistory = ({
  period,
  challengeInitiater,
  pageIndex,
  pageSize,
}) => {
  return fetch(
    `${baseUrl}PeerChallenge/PeerChallengeHistory/${period}/${challengeInitiater}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.Message === 'No peer challenge history found')
          return [];
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch Peer Challenge history failed'); // eslint-disable-line
    });
};

export const submitPeerChallengeRequest = ({ rawBody }) => {
  return fetch(`${baseUrl}PeerChallenge/SubmitPeerChallenge`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit Peer Challenge failed'); // eslint-disable-line
    });
};

export const fetchPeerChallengeSolution = ({ peerChallengeId }) => {
  return fetch(`${baseUrl}PeerChallenge/GetSolutionByPCId/${peerChallengeId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch Peer Challenge solution failed'); // eslint-disable-line
    });
};

export const fetchIncomingChallengev2 = (subjectID) => {
  if (isNil(subjectID)) throw new Error('Subject ID undefined');
  return fetch(`${baseUrl}PeerChallenge/${subjectID}/GetIncomingChallenges`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.code.toLowerCase().trim() === 'pcm003') return [];
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch incoming challenge failed'); // eslint-disable-line
    });
};

export const fetchPeerChallengeHistoryV2 = ({
  period,
  challengeInitiater,
  pageIndex,
  pageSize,
  subjectID,
}) => {
  if (isNil(subjectID)) throw new Error('Subject ID undefined');
  return fetch(
    `${baseUrl}PeerChallenge/${subjectID}/PeerChallengeHistory/${period}/${challengeInitiater}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.code.toLowerCase().trim() === 'pcm016') return [];
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch Peer Challenge history failed'); // eslint-disable-line
    });
};

export const fetchPeerChallengesV3 = ({
  typeID,
  userID,
  pageIndex,
  pageSize,
  subjectID,
}) => {
  if (isNil(subjectID)) throw new Error('Subject ID undefined');
  return fetch(
    `${baseUrl}PeerChallenge/Type/${typeID}/User/${userID}/Subject/${subjectID}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.code.toLowerCase().trim() === 'pcm016') return [];
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch Peer Challenge history failed'); // eslint-disable-line
    });
};

export const openPeerChallengeRequestV2 = ({ opponentId, subjectID }) => {
  if (isNil(subjectID)) throw new Error('Subject ID undefined');
  return fetch(
    `${baseUrl}PeerChallenge/${subjectID}/NewPeerChallenge/${opponentId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Open Peer Challenge failed'); // eslint-disable-line
    });
};

export const searchBFFByCodeOrId = (searchString) => {
  if (isNil(searchString)) throw new Error('No BFF code or Id entered');
  return fetch(`${baseUrl}Search/User/BFF/${searchString}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Search BFF failed');
    });
};

export const getHasIncomingChallengesBySubject = ({ subjectID }) => {
  if (isNil(subjectID)) throw new Error('Subject ID undefined');
  return fetch(`${baseUrl}PeerChallenge/${subjectID}/HasIncomingChallenges`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get has incoming challenges failed');
    });
};
