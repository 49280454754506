import React, { useRef, useEffect } from 'react';
import useSize from '@react-hook/size';
import useDraw from 'hooks/doodle/useDraw';
import { useDispatch } from 'react-redux';
import { setDrawingActivation } from 'store/assignment/drawingKitsSlice';
import HelperSidebar from '../HelperSidebar';

import { CanvasEl, CustomCursor, CanvasContent } from './Canvas.styles';

const Canvas = ({ children, bgColor, feature, showSideBar, top, qnBg }) => {
  const { canvasRef } = useDraw();
  const contentRef = useRef();
  const dispatch = useDispatch();
  const [width, height] = useSize(contentRef);
  useEffect(() => {
    return () => {
      dispatch(setDrawingActivation(false));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <CanvasContent id="canvas-content" ref={contentRef} bgColor={qnBg}>
        {children}
      </CanvasContent>
      <CanvasEl
        ref={canvasRef}
        id="drawing-board"
        width={width}
        height={height}
      />
      <CustomCursor id="custom-cursor" />
      <HelperSidebar
        bgColor={bgColor}
        feature={feature}
        showSideBar={showSideBar}
        top={top}
      />
    </div>
  );
};

export default Canvas;
