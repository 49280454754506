import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import MyAvatar from './MyAvatar';
import MyProfile from './MyProfile';
import Settings from './Settings';
import MySubscription from './MySubscription';
import BackButton from 'components/Shared/BackButton';

import {
  TabContainer,
  TabStyle,
  TabValueContainer,
} from './ProfileHeader.styles';

const activeTabValues = {
  avatar: <MyAvatar />,
  profile: <MyProfile />,
  settings: <Settings />,
  'my-subscription': <MySubscription />,
};
const ProfileHeader = ({ activeTab }) => {
  const { t } = useTranslation(['myAvatar', 'common']);
  const history = useHistory();
  const setActiveTab = (val) => {
    history.push(`/profile-settings/${val}`);
  };
  const tabs = [
    {
      label: t('myAvatar:tabs.myAvatar', 'My Avatar'),
      value: 'avatar',
    },
    {
      label: t('myAvatar:tabs.myProfile', 'My Profile'),
      value: 'profile',
    },
    {
      label: t('myAvatar:tabs.settings', 'Settings'),
      value: 'settings',
    },
    {
      label: t('myAvatar:tabs.mySubs', 'My Subscription'),
      value: 'my-subscription',
    },
  ];
  return (
    <>
      <TabContainer>
        <BackButton
          backText={t('common:back', 'Back')}
          btnBgColor="rgba(118,173,255,0.6)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/dashboard')}
          styles={{
            position: 'absolute',
            left: '4.5%',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
        {tabs.map(({ label, value }) => (
          <TabStyle
            data-cy={value}
            onClick={() => setActiveTab(value)}
            active={activeTab === value}
            key={value}
          >
            {label}
          </TabStyle>
        ))}
      </TabContainer>
      <TabValueContainer>{activeTabValues[activeTab]}</TabValueContainer>
    </>
  );
};

export default ProfileHeader;
