import React from 'react';
import { Container, InfoSection, ButtonSection } from './Card.styles';

const Card = ({
  infoSection,
  buttonSection,
  rightWidth,
  isDisabled,
  missionPlus,
  leftWidth,
  hideButton = false,
}) => {
  return (
    <Container>
      <InfoSection
        isDisabled={isDisabled}
        missionPlus={missionPlus}
        width={leftWidth}
        hideButton={hideButton}
      >
        {infoSection}
      </InfoSection>
      {!hideButton && (
        <ButtonSection
          isDisabled={isDisabled}
          width={rightWidth}
          missionPlus={missionPlus}
        >
          {buttonSection}
        </ButtonSection>
      )}
    </Container>
  );
};

export default Card;
