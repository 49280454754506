import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(7, 6, 6, 0.27);
  height: 48px;
  border-radius: 45px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 0.8rem;
  width: 210px;
  margin-bottom: 1.5rem;
  img {
    cursor: pointer;
    animation: ${({ slide }) => (slide === 'slide-out' ? `0.3s slide-up` : '')};
  }
  .clear,
  .close-arrow {
    align-self: center;
  }
  animation: ${({ slide }) => (slide ? `0.8s ${slide}` : '')};

  @keyframes slide-out {
    from {
      margin-right: -100%;
      opacity: 0;
    }
    to {
      margin-right: 0%;
      opacity: 1;
    }
  }

  @keyframes slide-in {
    from {
      margin-right: 0%;
      opacity: 1;
    }
    to {
      margin-right: -100%;
      opacity: 0;
    }
  }

  @keyframes slide-up {
    from {
      margin-bottom: -15px;
      opacity: 0;
    }
    to {
      margin-bottom: 0;
      opacity: 1;
    }
  }
`;
