export const LABELS = [
  {
    name: 'Total Speed Score',
    translateKey: 'speedScore',
  },
  {
    name: 'Total Vision Score',
    translateKey: 'visionScore',
  },
  {
    name: 'Achievement Time',
    translateKey: 'time',
  },
];
