import styled from 'styled-components';

export const Container = styled.div`
  background: #effbe3;
  display: flex;
  justify-content: center;
  align-items: baseline;
  color: #404447;
  padding: 10px 150px;
  position: fixed;
  margin-top: 70px;
  width: 100vw;
  z-index: 8;
  p {
    margin-bottom: 0px;
    margin-right: 25px;
  }

  .assigned-by {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .refresh-button {
    display: flex;
    flex-direction: start;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 16px;
    gap: 1rem;
    .assigned-by {
      margin-right: 1rem;
      p {
        font-size: 16px;
        margin-right: 0.5rem;
      }
    }
  }
  @media (max-width: 500px) {
    justify-content: center;
    .assigned-by {
      margin-right: 0;
      flex-direction: column;
      p {
        font-size: 14px;
      }
    }
  }
  /* 
  @media (max-width: 500px) {
    .assigned-by {
      margin-right: -5rem;

      p {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 350px) {
    .year-bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
    }

    .assigned-by {
      display: flex;
      flex-direction: column;
      margin-right: -1rem;
    }
  } */
`;
