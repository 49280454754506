import styled from 'styled-components';

export const Container = styled.div`
  color: #0d0d0d;
`;

export const Title = styled.div`
  background-color: #404447;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.25rem;
`;

export const Content = styled.div`
  background-color: #eaf2ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 4rem 1rem;
  .email {
    font-size: 1.125rem;
    font-weight: 600;
  }
  .check-icon {
    margin-right: 0.5rem;
  }
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 4rem 2.75rem;
  font-size: 1rem;
  .buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2rem;
    margin-top: 0.8rem;
  }
  .err-msg {
    color: #ff0000;
  }
`;
