import styled from 'styled-components';

const StyledContainer = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: baseline;
  span {
    color: #adadad;
    font-size: 12px;
    margin-right: 10px;
  }
`;

export const HideOnMobile = styled.div`
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export default StyledContainer;
