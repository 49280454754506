import styled from 'styled-components';

export const Container = styled.div``;

export const ChildContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 16%);
  }
  p {
    margin-bottom: 0px;
    margin-left: 0.5rem;
    font-size: 18px;
    font-weight: bold;
  }
  @media (max-width: 610px) {
    margin-left: 0;
  }
`;

export const UserCard = styled.div`
  height: fit-content;
  padding: 1.5rem 4rem 0.5rem 1rem;
  background: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid rgb(112, 112, 112, 0.14);

  .subs {
    color: #606060;
    font-size: 13px;
    margin-left: 3rem;
    margin-top: 2rem;
    @media (max-width: 1024px) {
      margin-left: 1.5rem;
    }
    @media (max-width: 610px) {
      margin-left: 0.5rem;
    }
  }
`;

export const SubsCard = styled.div`
  height: fit-content;
  border-bottom-left-radius: ${(props) => props.lastItem && '8px'};
  border-bottom-right-radius: ${(props) => props.lastItem && '8px'};
  border-top-left-radius: ${(props) => props.firstItem && '8px'};
  border-top-right-radius: ${(props) => props.firstItem && '8px'};
`;
