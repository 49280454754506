import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import { TRIAL_TYPE, SUBS_STATUS } from 'constants/parentPortal';

import Spinner from 'components/Shared/Spinner';
import Subscription from './Subscription';
import AutoRenewFAQ from './AutoRenewFAQ/AutoRenewFAQ';
import {
  ContainerV2,
  ContactSupport,
  ContactPage,
  Title,
  Content,
  ChildList,
  ChildName,
} from './BillV2.styles';

const BillV2 = () => {
  const { parentSubscriptions, error, isLoading } = useSelector(
    (state) => state.parentSettings
  );
  const [selectedChild, setSelectedChild] = useState(null);

  const oneTimeSubsExist = parentSubscriptions
    ?.map((item) =>
      item.subscriptions?.findIndex(
        (a) =>
          SUBS_STATUS[a.subscriptionStatusCode] === 'one-time-payment' &&
          TRIAL_TYPE[a.subscriptionTrialStatus] === 'notTrial'
      )
    )
    ?.some((el) => el > -1);

  const nameFormat = (name) => {
    if (name.length > 15) {
      const sliceResult = name.slice(0, 15);
      return `${sliceResult}...`;
    }
    return name;
  };

  useEffect(() => {
    if (!isNil(parentSubscriptions)) {
      if (isNil(selectedChild)) {
        setSelectedChild(parentSubscriptions[0]);
      } else {
        const selectedChildLatestData = parentSubscriptions?.find(
          (subs) => subs.childUserId === selectedChild.childUserId
        );
        setSelectedChild(selectedChildLatestData);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentSubscriptions]);

  return (
    <ContainerV2>
      <Title>Billing & Subscription</Title>
      {!isLoading && !isNil(selectedChild) ? (
        <Content>
          <ChildList>
            {parentSubscriptions?.map((item) => (
              <ChildName
                key={item.childUserId}
                selected={item.childUserId === selectedChild?.childUserId}
                onClick={() => setSelectedChild(item)}
              >
                {nameFormat(item.fullName)}
              </ChildName>
            ))}
          </ChildList>
          <Subscription data={selectedChild} />
        </Content>
      ) : (
        <Spinner />
      )}

      {isNil(error) &&
        !isEmpty(parentSubscriptions) &&
        !isLoading &&
        oneTimeSubsExist && <AutoRenewFAQ />}

      {isNil(error) && !isEmpty(parentSubscriptions) && !isLoading && (
        <ContactPage>
          If you need further assistance regarding your subscription or renewal,
          you can reach out to our support team at our{' '}
          <a
            href="https://www.koobits.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us page
          </a>
          . Thank you.
        </ContactPage>
      )}

      {(!isNil(error) || isEmpty(parentSubscriptions)) && !isLoading && (
        <ContactSupport>
          You are subscribed to a KooBits special offer.
          <br /> For any renewal related inquires, please reach out to our
          support team at{' '}
          <a
            href="https://www.koobits.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us page.
          </a>
        </ContactSupport>
      )}
    </ContainerV2>
  );
};

export default BillV2;
