import styled from 'styled-components';

export const Container = styled.div`
  background: transparent;
  text-align: center;
  border-radius: 13px;
  box-shadow: 0px 1px 6px 0px #ededed;
  font-weight: 600;
  .header {
    background: #fff8ef;
    border-bottom: #ffb44e;
    padding: 2rem 2.5rem;
    border-radius: 13px 13px 0 0;
    position: relative;
    span {
      position: absolute;
      color: #ffb555;
      right: 18px;
      top: 10px;
      font-size: 20px;
      cursor: pointer;
    }
    .header-title {
      font-size: 16px;
    }
    .header-desc {
      font-size: 13px;
      color: #ff6701;
    }
  }
  .body {
    background: #ffffff;
    border-radius: 0 0 13px 13px;
    padding: 1.5rem 2rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    .dropdown-toggle {
      border-radius: 5px !important;
      border-color: #ff8316 !important;
      background: #fff;
      margin-bottom: 25px;
      font-size: 14px;
      max-width: 200px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .body {
      padding: 1rem;
    }
  }
`;

export const TopicSelect = styled.div`
  border: 1px solid ${({ active }) => (active === true ? '#ff8316' : '#dce2e3')};
  border-radius: 10px;
  width: 70%;
  padding: 10px;
  margin-bottom: 20px;
  background: ${({ active }) => (active === true ? '#fdf4eb' : '#ffffff')};
  cursor: pointer;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 85%;
  }
`;

export const Button = styled.button`
  border: none;
  background: #ff6701;
  font-weight: 600;
  font-size: 1.3125rem;
  color: white;
  border-radius: 30px;
  padding: 10px 2rem;
  width: ${({ reloadBtn }) => (reloadBtn ? '60%' : '80%')};
  margin-bottom: ${({ reloadBtn }) => reloadBtn && '1rem'};
  :hover {
    background: #ff5801;
  }
  :disabled {
    background: #e5e5e5;
    color: #ffffff;
    border-color: #e5e5e5;
    &:hover {
      color: #ffffff;
      background: #e5e5e5;
      border-color: #e5e5e5;
    }
  }
`;
