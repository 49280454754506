import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f5f5f5;
`;

export const Header = styled.div`
  background-color: #eaeaeb;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 139px;
  width: 100%;
  div {
    font-size: 20px;
    font-weight: 600;
    color: #1f1f1f;
    position: relative;
    left: 40px;
  }
  .confirm-btn {
    position: relative;
    left: 20%;
  }
`;

export const ConfirmButton = styled.button`
  background: #ff6701;
  color: #fff;
  border: none;
  width: 100%;
  padding: 0.2rem 1.5rem;
  border-radius: 2rem;
  &:focus {
    outline: none;
  }
  &:hover {
    background: #ff5801;
  }
  &:disabled {
    background: #e5e5e5;
    color: #ffffff;
    border-color: #e5e5e5;
    &:hover {
      color: #ffffff;
      background: #e5e5e5;
      border-color: #e5e5e5;
    }
  }
`;

export const Body = styled.div`
  color: #000000;
  width: 60%;
  margin: auto;
  min-height: 600px;
  margin-top: 160px;
  padding: 0 20px 1rem;
  .cat-row {
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  .category {
    font-size: 20px;
    color: #1f1f1f;
    margin: 10px 0 10px 35px;
  }
  .img-container {
    width: 108px;
    height: 108px;
    /* margin-right: 50px;
    margin-bottom: 25px; */
    margin: 0 15px 25px 35px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    &:hover {
      border: 3px solid #ff671b;
      box-shadow: 3px 3px 5px 0px #d3d3d3;
    }
  }
  .active-img {
    border: 3px solid #ff671b;
    box-shadow: 3px 3px 5px 0px #d3d3d3;
  }
`;
