/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  font-family: 'Nunito';
  color: #202020;
  .total-qns {
    display: flex;
    line-height: 1;
    font-weight: 600;
    width: 110px;
    justify-content: space-between;
    align-items: baseline;
    .total-text {
      font-size: 18px;
      margin-right: 0.5rem;
    }
    .total-number {
      font-size: 22px;
      color: #ff5b00;
      margin-right: 0.2rem;
      font-weight: bold;
    }
    .qns {
      font-size: 10px;
      font-weight: normal;
    }
  }
  .btn-group {
    font-family: 'Linotte';
    display: flex;
    justify-content: space-evenly;
    margin: 2rem 0 1rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .btn-group {
      flex-direction: column-reverse;
      gap: 20px;
    }
  }
`;
