import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 59px;
  padding-bottom: 2rem;
  .btn-container {
    display: flex;
    justify-content: center;
  }
`;

export const Top = styled.div`
  background: #c9d1ff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #151515;
  font-size: 22px;
  font-weight: 600;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  color: #141313;
  img {
    width: 162px;
    height: 162px;
    margin: 10px 0px;
  }
  h4 {
    font-weight: 600;
    font-size: 28px;
  }
  p {
    margin-bottom: 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 10% 1rem 10%;
`;
