import React from 'react';
import parseMilliseconds from 'parse-ms';
import { useTranslation } from 'react-i18next';

const TimeDuration = ({ duration }) => {
  const { t } = useTranslation(['assignmentFinished']);
  const parsedTime = parseMilliseconds(duration);
  const parsedHours = parsedTime.days * 24 + parsedTime.hours;
  return (
    <div>
      {t('assignmentFinished:words.Total Time', 'Total Time ')}{' '}
      <span style={{ marginLeft: '0px' }}>
        {parsedHours > 0
          ? `${parsedHours < 10 ? `0${parsedHours}` : parsedHours}:${
              parsedTime.minutes < 10
                ? `0${parsedTime.minutes}`
                : parsedTime.minutes
            }:${
              parsedTime.seconds < 10
                ? `0${parsedTime.seconds}`
                : parsedTime.seconds
            }`
          : parsedTime.minutes < 10
          ? `0${parsedTime.minutes}`
          : parsedTime.minutes}
        :
        {parsedTime.seconds < 10
          ? `0${parsedTime.seconds}`
          : parsedTime.seconds}
      </span>
    </div>
  );
};

export default TimeDuration;
