import styled from 'styled-components';
import iconTimer from 'assets/img/factual-fluency/bg-timer.png';

export const TimerWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  width: 100%;
`;

export const TimerCountdown = styled.span`
  border: 1px solid #fff;
  font-size: 42px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  line-height: 1;
  padding: 7px 20px;
  margin-left: 10px;
  min-width: 145px;
`;

export const ProgressWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const ProgressTime = styled.div`
  width: 440px;
  display: inline-block;
  vertical-align: middle;

  .progress {
    height: 46px;
    background-color: #fff;
    border: 6px solid #082b56;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    font-size: 0.75rem;

    .progress-bar {
      background-color: #ff7201;
      border-radius: 0 4px 4px 0;
      width: 100%;

      &.active {
        width: 0%;
      }
    }
  }
`;

export const CountdownNumber = styled.div`
  background: url(${iconTimer}) no-repeat;
  width: 81px;
  height: 75px;
  color: #fff;
  font-size: 51px;
  text-align: center;
  line-height: 75px;
  margin-left: 30px;
  padding-right: 6px;
`;
