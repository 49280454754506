import {
  proficiencyColors,
  ProficiencyStatus,
  MasteryLevel,
} from 'constants/report';

const calculatePercentageString = (num, total) => {
  const percentage = ((num / total) * 100).toFixed(1);
  return percentage.toString().concat('%');
};

export const sortSkills = (skills) => {
  const progressBarInput = [
    {
      percentage: '',
      color: proficiencyColors.mastery.color,
      borderColor: proficiencyColors.mastery.borderColor,
    },
    {
      percentage: '',
      color: proficiencyColors.competent.color,
      borderColor: proficiencyColors.competent.borderColor,
    },
    {
      percentage: '',
      color: proficiencyColors.beginning.color,
      borderColor: proficiencyColors.beginning.borderColor,
    },
    {
      percentage: '',
      color: proficiencyColors.incomplete.color,
      borderColor: proficiencyColors.incomplete.borderColor,
    },
  ];
  const proficiencyIndex = {
    incomplete: 3,
    beginning: 2,
    competent: 1,
    mastery: 0,
  };
  const sortedSkills = {
    incomplete: 0,
    beginning: 0,
    competent: 0,
    mastery: 0,
  };
  let total = 0;
  // Sort skills by proficiency status
  for (const skill of skills) {
    const proficiency = ProficiencyStatus[skill.ProficiencyStatus];
    sortedSkills[proficiency] += 1;
    total += 1;
  }
  for (const proficiency of Object.keys(sortedSkills)) {
    if (sortedSkills[proficiency] > 0) {
      // Calculate percentage string
      const percentageString = calculatePercentageString(
        sortedSkills[proficiency],
        total
      );
      progressBarInput[
        proficiencyIndex[proficiency]
      ].percentage = percentageString;
    } else {
      progressBarInput[proficiencyIndex[proficiency]].percentage = '0%';
    }
  }
  return progressBarInput.filter(
    (proficiency) => proficiency.percentage !== '0%'
  );
};

export const parseHighScoreProgressBar = (data) => {
  const progressBarInput = [
    {
      percentage: '',
      color: '#FFC219',
      borderColor: '#D2A905',
    },
    {
      percentage: '',
      color: '#FFEAAF',
      borderColor: '#E5D582',
    },
    {
      percentage: '',
      color: '#FFEAAF',
      borderColor: '#E5D582',
    },
    {
      percentage: '',
      color: '#FFEAAF',
      borderColor: '#E5D582',
    },
    {
      percentage: '',
      color: proficiencyColors.incomplete.color,
      borderColor: proficiencyColors.incomplete.borderColor,
    },
  ];
  const masteryIndex = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    incomplete: 4,
  };
  const sortedMasteryLevels = {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    incomplete: 0,
  };
  let total = 0;
  for (const level of data) {
    const masteryLevel = MasteryLevel[level.MasteryLevel];
    sortedMasteryLevels[masteryLevel] += 1;
    total += 1;
  }
  for (const mastery of Object.keys(sortedMasteryLevels)) {
    if (sortedMasteryLevels[mastery] > 0) {
      // Calculate percentage string
      const percentageString = calculatePercentageString(
        sortedMasteryLevels[mastery],
        total
      );
      progressBarInput[masteryIndex[mastery]].percentage = percentageString;
    } else {
      progressBarInput[masteryIndex[mastery]].percentage = '0%';
    }
  }
  return progressBarInput.filter((mastery) => mastery.percentage !== '0%');
};
