import React from 'react';
import { useTranslation } from 'react-i18next';
import CompletedIcon from 'assets/img/dailychallenge/completed-icon.svg';
import CPIcon from 'assets/img/icon-challenge-point.svg';
import InactiveCPIcon from 'assets/img/icon-challenge-point-inactive.svg';

import { Container, CardBody, Info } from './ChallengeQnCard.styles';

const ChallengeQnCard = ({
  difficulty,
  qnStatus,
  qnNo,
  onClick,
  challengePoints,
}) => {
  const { t } = useTranslation(['dailyChallenge']);
  return (
    <>
      <Container onClick={onClick} data-cy="question-card">
        <img src={difficulty.icon} alt="icon" className="difficulty-icon" />
        <CardBody qnStatus={qnStatus}>
          <span>{`${t(
            'dailyChallenge:difficultySelection.question',
            'Question'
          )} ${qnNo}`}</span>
          {qnStatus === 'completed' && (
            <img src={CompletedIcon} alt="completed" />
          )}
        </CardBody>
      </Container>
      <Info>
        <span className="difficulty">
          {t(`dailyChallenge:main.${difficulty.name}`, `${difficulty.name}`)}
        </span>
        <span className="cp-reward">
          <img
            src={qnStatus === 'completed' ? CPIcon : InactiveCPIcon}
            alt="CPs"
            width="13.62px"
            className="cp-icon"
          />
          {challengePoints} CP
        </span>
      </Info>
    </>
  );
};

export default ChallengeQnCard;
