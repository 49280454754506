import styled from 'styled-components';

export const Container = styled.div``;

export const List = styled.div`
  padding: 1% 10% 0%;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 3% 6% 0%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 3% 4% 0%;
  }
`;

export const ErrorText = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;
