import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  .progress-visual-full {
    display: flex;
    height: ${(props) => props.height};
    border-radius: 50px;
  }

  .visualpart:first-of-type {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .visualpart:last-of-type {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
`;

export const ProgressSection = styled.div`
  transition: width 2s;
  width: ${(props) => props.width};
  background: ${(props) => props.item.color};
  border: ${(props) =>
    props.singleWidth
      ? `1px solid ${props.item.borderColor}`
      : (props.index === 0 || props.isLastIndex) &&
        parseFloat(props.width.slice(0, -1)) <= 3.5
      ? `4px solid ${props.item.color}`
      : `1px solid ${props.item.borderColor}`};
`;
