import React, { useState } from 'react';
import questionFeedbackUrl from 'helpers/questionFeedbackUrl';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ModalContentXButton from 'components/Shared/ModalContentXButton';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { isEmpty, isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const StyledButton = styled.button`
  border: solid 1px #cee9f0;
  font-size: 0.875rem !important;
  color: #73c2d1;
  background: white;
  border-radius: 22px;
  padding: 0.25rem 0.5rem;
  margin: ${(props) => props.margin};
  align-self: center;
  :hover {
    color: #56c2d6;
  }
`;

const DropdownContainer = styled.div`
  width: 100%;
  padding-left: 2.5rem;
  padding-top: 1rem;
`;

const StyledIframe = styled.iframe`
  width: 640px;
  max-width: 95%;
  height: 80vh;
  margin: 1rem;
`;

const QuestionFeedbackLink = ({
  qnID,
  skillID,
  margin = '0',
  questionList,
}) => {
  const { t } = useTranslation(['common']);
  const { user } = useSelector((state) => state.login);
  const [showForm, setShowForm] = useState(false);
  const modalBreakpoint = useMediaQuery({ query: '(max-width: 660px)' });
  const filteredQuestionList =
    isNil(questionList) || isEmpty(questionList)
      ? []
      : questionList
          .filter((question) => !isNil(question))
          .map((question, index) => {
            return {
              id: question.Id,
              skillId: question.SkillId,
              index: index + 1,
            };
          });
  const firstQn = isEmpty(filteredQuestionList) ? {} : filteredQuestionList[0];
  const [selectedQn, setSelectedQn] = useState(firstQn);
  const loginID = isNil(user) ? '' : user.user_name ?? '';
  const urlParams = isNil(questionList)
    ? { userID: loginID, qnID, skillID }
    : { userID: loginID, qnID: selectedQn.id, skillID: selectedQn.skillId };
  const url = questionFeedbackUrl(urlParams);
  return (
    <>
      <ModalContentXButton
        showModal={showForm}
        closeModal={() => {
          setShowForm(false);
        }}
        backdrop="static"
        containerMinWidth={modalBreakpoint ? '95vw' : '640px'}
      >
        {filteredQuestionList.length > 0 && (
          <DropdownContainer>
            <DropdownMenu
              width="576px"
              selectedValue={`${t('common:question', 'Question')} ${
                selectedQn.index
              }`}
              valueKey="index"
              values={filteredQuestionList}
              valueTemplate={(value) =>
                `${t('common:question', 'Question')} ${value}`
              }
              setValue={(qn) => {
                setSelectedQn(qn);
              }}
            />
          </DropdownContainer>
        )}
        <StyledIframe
          key={
            isNil(questionList)
              ? `${loginID}-${qnID}-${skillID}`
              : `${loginID}-${selectedQn.id}-${selectedQn.skillId}`
          }
          src={url}
          frameBorder="0"
          marginHeight="0"
        >
          Loading…
        </StyledIframe>
      </ModalContentXButton>
      <StyledButton
        onClick={() => {
          setShowForm(true);
        }}
        margin={margin}
      >
        {t('common:feedback', 'Feedback')}
      </StyledButton>
    </>
  );
};

export default QuestionFeedbackLink;
