import BannerOne from 'assets/img/exp-level/dashboard/level-bg-1.svg';
import BannerTwo from 'assets/img/exp-level/dashboard/level-bg-2.svg';
import BannerThree from 'assets/img/exp-level/dashboard/level-bg-3.svg';
import BannerFour from 'assets/img/exp-level/dashboard/level-bg-4.svg';
import BannerFive from 'assets/img/exp-level/dashboard/level-bg-5.svg';
import BannerSix from 'assets/img/exp-level/dashboard/level-bg-6.svg';
import BannerSeven from 'assets/img/exp-level/dashboard/level-bg-7.svg';
import BannerEight from 'assets/img/exp-level/dashboard/level-bg-8.svg';
import BannerNine from 'assets/img/exp-level/dashboard/level-bg-9.svg';
import BannerTen from 'assets/img/exp-level/dashboard/level-bg-10.svg';
import BannerEleven from 'assets/img/exp-level/dashboard/level-bg-11.svg';

const expLevelBackgrounds = {
  1: BannerOne,
  2: BannerTwo,
  3: BannerThree,
  4: BannerFour,
  5: BannerFive,
  6: BannerSix,
  7: BannerSeven,
  8: BannerEight,
  9: BannerNine,
  10: BannerTen,
  11: BannerEleven,
};

export default expLevelBackgrounds;
