import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #c6efff;
  width: 100%;
  height: 100%;
  padding: 10px 30px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-bottom: 20px;
  .list-headings {
    display: flex;
    width: 100%;
    padding: 0 5px 0 35px;
    margin-bottom: 5px;
  }
`;

export const Headings = styled.div`
  display: flex;
  flex-direction: column;
  color: #002e41;
  flex: ${(props) => props.flex};
  text-align: center;
`;

export const Lists = styled.div`
  display: flex;
  margin-bottom: 20px;
  border: 1px solid black;
  align-items: center;
  padding: 3px 5px 3px 35px;
  border: 1px solid;
  border-radius: 30px;
  position: relative;
  font-size: 14px;
  background-color: #ffffff;
  border-color: #d4ecf5;
  .speed-points,
  .vision-points,
  .list-achievement {
    flex: 1;
    text-align: center;
  }
`;
