import React from 'react';
import { useTranslation } from 'react-i18next';
import TypeIndicator from 'components/Assignment/TypeIndicator';
import DetailsInfo from 'components/Assignment/StartPage/DetailsInfo';
import { history } from 'Routes';
import KoKoIcon from 'assets/img/icon-koko-credits.svg';
import PrintIcon from 'assets/img/icon-print.svg';
import PdfIcon from 'assets/img/assignment/icon-pdf.svg';
import moment from 'moment';
import { isNil } from 'ramda';
import { assignmentTypeIDs } from 'constants/index';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAssignmentDetails,
  openAssignment,
  setActiveQuestion,
} from 'store/assignment/assignmentSlice';
import { fetchAssignmentDetails } from 'services/assignment';
import { fetchWrapper } from 'services/login';
import checkIfAssignmentExpired from 'helpers/checkIfAssignmentExpired';
import {
  Container,
  Title,
  Content,
  KoKoCredits,
  Column,
  Print,
  StyledSection,
  StyledButton,
} from './AssignmentDetails.styles';

const AssignmentDetails = () => {
  const { t } = useTranslation(['startPage', 'assignmentFinished']);
  const dispatch = useDispatch();
  const { startAssignmentInfo } = useSelector((state) => state.assignment);
  const assignmentType =
    assignmentTypeIDs[startAssignmentInfo && startAssignmentInfo.Type];
  const isExpiredTest =
    (assignmentType === 'mocktest' || assignmentType === 'test') &&
    checkIfAssignmentExpired(startAssignmentInfo.EndDate) &&
    startAssignmentInfo.Sub_Status !== 2;

  const isNotRelease =
    assignmentType === 'test' &&
    !checkIfAssignmentExpired(startAssignmentInfo.ResultDate);

  const today = new Date();
  const assignmentStartDate = new Date(startAssignmentInfo.StartDate);
  const dateStart = assignmentStartDate.setDate(assignmentStartDate.getDate());
  const isAssignmentStarted = today >= dateStart;

  const buttonText = () => {
    if (
      !isNil(startAssignmentInfo.ResultDate) &&
      startAssignmentInfo.Sub_Status === 2 &&
      isNotRelease
    ) {
      return t(`startPage:words.submitted`, 'Submitted');
    }
    if (startAssignmentInfo.Sub_Status === 2) {
      return t('assignmentFinished:words.seeSolution', 'See Solution');
    }
    if (isExpiredTest) {
      return t(`startPage:words.expired`, 'Expired');
    }
    return t(`startPage:words.start`, 'Start Now');
  };

  const buttonHandler = async () => {
    const res = await fetchWrapper(
      fetchAssignmentDetails,
      startAssignmentInfo.Id
    );
    if (
      !isNil(startAssignmentInfo.ResultDate) &&
      startAssignmentInfo.Sub_Status === 2 &&
      isNotRelease
    ) {
      return;
    }
    if (startAssignmentInfo.Sub_Status === 2) {
      dispatch(getAssignmentDetails(startAssignmentInfo.Id));
      history.push(`/assignment/solution/${res.Id}/${res.Submission_Id}`);
      return;
    }
    dispatch(setActiveQuestion(1));
    dispatch(openAssignment(startAssignmentInfo.Id));
    history.push('/assignment/open');
  };

  const KoKo = (
    <KoKoCredits>
      <span>
        <img src={KoKoIcon} alt="KoKo" />
      </span>
      <p>{startAssignmentInfo.NumberOfKokoCredits} KoKo</p>
    </KoKoCredits>
  );
  const PrintSection = (
    <Print
      data-cy="print-btn"
      target="_blank"
      href={`${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_SUBDOMAIN}${
        process.env.REACT_APP_SUBDOMAIN === '/' ? '' : '/'
      }assignment/print/${startAssignmentInfo.Id}`}
    >
      <span>
        <img src={PrintIcon} alt="print-icon" />
      </span>
      <p>{t('startPage:words.Print', 'Print')}</p>
    </Print>
  );
  const Attachment = (
    <div>
      {startAssignmentInfo.Files &&
        startAssignmentInfo.Files.map((file) => (
          <a href={file.FileUrlLink} download key={file.FileID}>
            {file.FileExtension.toLowerCase() === 'pdf' && (
              <img src={PdfIcon} alt="pdf" style={{ marginRight: '10px' }} />
            )}
            {`${file.FileName}.${file.FileExtension}`}
          </a>
        ))}
    </div>
  );
  return (
    <Container>
      <Title>
        <span style={{ marginTop: '0.2rem' }}>
          <TypeIndicator type={assignmentType} />
        </span>
        <p>{startAssignmentInfo && startAssignmentInfo.Title}</p>
      </Title>
      <Content>
        <DetailsInfo
          category="Assigned by"
          info={
            startAssignmentInfo && startAssignmentInfo.assignedByEnum === 1
              ? 'KooBits'
              : startAssignmentInfo.AssignerName
          }
        />
        <Column>
          <DetailsInfo
            category="Deadline"
            info={`${moment(
              startAssignmentInfo && startAssignmentInfo.EndDate
            ).format('DD-MMM-YYYY, h:mm a')}`}
          />
          <DetailsInfo
            category="Total Qns"
            info={startAssignmentInfo && startAssignmentInfo.QuestionNo}
          />
        </Column>
        {assignmentType === 'test' && (
          <Column>
            <DetailsInfo
              category="Result Release"
              info={`${moment(
                startAssignmentInfo && startAssignmentInfo.ResultDate
              ).format('DD-MMM-YYYY, h:mm a')}`}
            />
            <span className="desktop-print">{PrintSection}</span>
          </Column>
        )}
        <Column>
          <DetailsInfo category="Rewards" info={KoKo} />
          {assignmentType !== 'test' && (
            <span className="desktop-print">{PrintSection}</span>
          )}
        </Column>
        <span className="mobile-print">{PrintSection}</span>
        {startAssignmentInfo.Files && (
          <DetailsInfo category="Attachment" info={Attachment} />
        )}
      </Content>
      <StyledSection>
        <StyledButton
          variant="orange3"
          isDisabled={!isAssignmentStarted}
          onClick={!isExpiredTest && buttonHandler}
          style={{
            background: !isAssignmentStarted ? '#ff5a00' : null,
          }}
        >
          {buttonText()}
        </StyledButton>
      </StyledSection>
    </Container>
  );
};

export default AssignmentDetails;
