import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import Button from 'components/Shared/Button';
import { Trans, useTranslation } from 'react-i18next';
import {
  requestSubjectTrialOTP,
  verifySubjectTrialOTP,
} from 'store/trialSignup/trialSignupSlice';
import { StyledOTP, StyledOTPError } from './OTPForm.styles';

const styledOtpInput = {
  width: '2rem',
  height: '3rem',
  margin: '0.5rem',
  fontSize: '24px',
};

const RESEND_TIME = 60 * 1000;
const COUNT_BACKWARDS_NUMBER = 60;
const RESEND_NUMBER = 1;
const MAX_TIMES = 3;

const OTPForm = ({ otp, setOtp, setCurrentStep, subjectId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['scienceTrial']);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [interValId, setInterValId] = useState(null);
  const [resendTimes, setResendTimes] = useState(RESEND_NUMBER);
  const [countBackwards, setCountBackwards] = useState(COUNT_BACKWARDS_NUMBER);
  const [disableResend, setDisableResend] = useState(false);
  const [isError, setIsError] = useState(null);
  const { isLoading, otpInfo, otpErr } = useSelector(
    (state) => state.trialSignup
  );

  useEffect(() => {
    setOtp(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countBackwards === 0) {
      clearInterval(interValId);
      setCountBackwards(COUNT_BACKWARDS_NUMBER);
    }
  }, [countBackwards, interValId]);

  const handleOtpChange = (value) => {
    setOtp(value);
    if (value.length === 6) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
  const handleVerifyOtp = async () => {
    setIsSubmitting(true);
    try {
      const res = await dispatch(
        verifySubjectTrialOTP({
          OTP: otp,
          SubjectId: subjectId,
          ParentId: otpInfo?.ParentUserId,
        })
      );
      if (res.payload.IsVerified === true) {
        setCurrentStep(3);
      }
      setIsSubmitting(false);
    } catch (e) {
      console.log('res', e);
      setIsSubmitting(false);
    }
  };

  const handleCountDown = () => {
    clearInterval(interValId);
    const interval = setInterval(() => {
      if (countBackwards > 0) {
        setCountBackwards((prev) => prev - 1);
      }
    }, 1000);
    setInterValId(interval);
    setTimeout(() => {
      setDisableResend(false);
    }, RESEND_TIME);
  };

  useEffect(() => {
    setDisableResend(true);
    handleCountDown();
    return () => {
      clearInterval(interValId);
    };
    // eslint-disable-next-line
  }, []);

  const handleResend = async () => {
    if (!disableResend) {
      setDisableResend(true);
      setIsError('');
      if (resendTimes < MAX_TIMES) {
        try {
          handleCountDown();
          const newResendTime = resendTimes + 1;
          await dispatch(requestSubjectTrialOTP(otpInfo));
          setResendTimes(newResendTime);
        } catch (err) {
          console.log(err);
          setIsError(err);
        }
      }
    }
  };

  const renderResendOTP = () => {
    return (
      <>
        {disableResend ? (
          <p style={{ marginTop: '20px' }}>
            <Trans i18nKey="scienceTrial:stepTwo.notifyResend" t={t}>
              Resend a new OTP in {{ totalSeconds: countBackwards }} second(s)
            </Trans>
          </p>
        ) : (
          <button
            style={{ marginBottom: '20px' }}
            dataCy="resend-button"
            className="link"
            type="button"
            onClick={handleResend}
          >
            {t(
              'scienceTrial:stepTwo.resendCode',
              'Didn’t receive the OTP? Resend code'
            )}
          </button>
        )}
      </>
    );
  };

  return (
    <StyledOTP>
      <OtpInput
        value={otp}
        onChange={(value) => handleOtpChange(value)}
        numInputs={6}
        inputStyle={styledOtpInput}
        isDisabled={isSubmitting}
        isInputNum
      />
      {isError && <p className="error">{isError}</p>}
      {otpErr && <StyledOTPError data-cy="otp-error">{otpErr}</StyledOTPError>}
      <Button
        dataCy="verify-button"
        className="otp-button"
        variant="primary"
        width="250px"
        onClick={handleVerifyOtp}
        isDisabled={isDisabled || isLoading}
      >
        {t('scienceTrial:stepTwo.verifyOtp', 'Submit')}
      </Button>
      {resendTimes >= MAX_TIMES ? (
        <p data-cy="contact-support" style={{ marginTop: '20px' }}>
          <Trans i18nKey="scienceTrial:stepTwo.contactSupport">
            <span>Having trouble?</span>
            <a href="https://support.koobits.com/">Contact Support.</a>
          </Trans>
        </p>
      ) : (
        renderResendOTP()
      )}
    </StyledOTP>
  );
};

export default OTPForm;
