import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
  margin: auto;
  margin-top: 80px;
`;

export const Content = styled.div`
  width: 898px;
  margin-top: 100px;
`;

export const StyledTable = styled.table`
  width: 97%;
  text-align: center;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 15px;
  thead {
    font-family: Linotte;
    font-size: 16px;
    color: #b4b4b4;
    tr {
      line-height: 3;
    }
    th {
      border-bottom: 1px solid #efefef;
      font-weight: 400;
      &:first-child {
        text-align: left;
        padding-left: 4px;
      }
      &:last-child {
        text-align: right;
        padding-right: 4px;
      }
    }
  }
  tbody {
    tr {
      line-height: 3;
      font-family: Linotte;
      font-size: 16px;
      td {
        border-bottom: 1px solid #efefef;
        img {
          display: inline-block;
        }
        span {
          display: inline-block;
          min-width: 45px;
        }
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
`;

export const LastWeekButton = styled.a`
  margin: 80px auto auto;
  height: 46px;
  width: 356px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 25px;
  border: 2px solid #ff7121;
  margin-bottom: 24px;

  span {
    color: #ff7121;
    font-size: 16px;
    font-family: Linotte;
  }

  &:hover {
    opacity: 0.8;
    background-color: #f6f6f6;
    cursor: pointer;
  }
`;
