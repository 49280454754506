import styled from 'styled-components';
import Bg from 'assets/img/mission/curriculum-list-header.svg';
import scienceBg from 'assets/img/mission/curriculum-list-science-header.svg';

export const Container = styled.div``;

export const Header = styled.div`
  background: ${({ subject, plan }) =>
    subject === 'science' && plan !== 'school'
      ? 'linear-gradient(180deg, #08CCAC, #1BDD7E)'
      : 'linear-gradient(180deg, #8456ff, #475df3)'};
  height: 75px;
  .decoration {
    background: url(${({ subject, plan }) =>
    subject === 'science' && plan !== 'school' ? scienceBg : Bg});
    height: 75px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 33px;
    font-weight: 600;
    color: #ffffff;
  }
`;

export const Content = styled.div`
  padding: 1% 10% 0%;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 1% 3% 0%;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const CardImg = styled.div`
  width: 285px;
  height: 159px;
  position: relative;
  img {
    width: 100%;
  }
  p {
    position: absolute;
    top: 1.5rem;
    left: 2rem;
    margin-bottom: 0px;
    color: #ffffff;
    font-weight: bold;
    font-size: 21px;
    text-shadow: 0px 2px 2px rgba(0, 199, 155, 0.35);
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 130px;
    height: auto;

    p {
      top: 1rem;
      left: 1rem;
      font-size: 12px;
    }
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: ${({ isMissionPlus }) =>
    isMissionPlus ? 'flex-start' : 'space-between'};

  img {
    width: 80px;

    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      width: 50px;
    }
  }
  .skills-details {
    display: flex;
    flex-direction: row;
    align-items: center;

    /* found no skill-progress class name */
    /* .skills-progress {
      margin-left: 0.5rem;
      p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #aaaaaa;
        span {
          font-size: 22px;
          font-weight: 600;
          color: #0d0d0d;
        }
      }
      .progress-bar-section {
        min-width: 180px;
        .progress {
          height: 13px;
          border-radius: 1rem;
          background: #f3f3f3;
          .bg-correct {
            background: #fb7438;
          }
        }
      }
    } */
  }
  .default-level {
    color: #b7b7b7;
    font-size: 16px;
    margin-bottom: 0px;
    min-width: 54.5px;

    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      font-size: 12px;
    }
  }
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${({ isMissionPlus }) => (isMissionPlus ? '1.6rem' : '0.8rem')};
  padding-top: ${({ isMissionPlus }) => isMissionPlus && '1rem'};
  width: 100%;
  .curriculum-name {
    color: #0d0d0d;
    font-size: 16px;
    span {
      font-weight: 600;
      font-size: 22px;
    }
  }

  .detail-container {
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-top: 0px;
    .curriculum-name {
      p {
        line-height: normal;
        margin-top: 5px;
      }
      span {
        font-size: 16px;
      }
    }
    .detail-container {
      display: none;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    justify-content: space-between;
    padding: 0px 10px;
  }

  .detail-container {
    display: none;
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      display: block;
    }
  }
`;
