import styled from 'styled-components';
import ActiveChilli from 'assets/img/icon-chilli-active.svg';
import InactiveChilli from 'assets/img/icon-chilli-inactive.svg';

export const Icon = styled.img.attrs((props) => ({
  src: props.isActive ? ActiveChilli : InactiveChilli,
}))`
  width: 10px;
  height: 17px;
  ${'' /* background-image: ${(props) =>
    props.isActive ? `url(${ActiveChilli})` : `url(${InactiveChilli})`}; */}
`;
