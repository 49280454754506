import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/top-brain/science/top-brain-bg.png';
import Rules from 'assets/img/leaderboard/top-brain/rules-img.svg';

export const Topbar = styled.div`
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 315px;
  display: flex;
  padding: 0 20px;
  .badge-img {
    margin-left: 5%;
  }
  .middle-content {
    margin-top: 2rem;
    display: flex;
    align-items: center;
  }
  .scoring-period {
    color: #262626;
    font-size: 22px;
    display: flex;
    margin-top: 24px;
    font-weight: 600;
    span {
      color: #568000;
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .rules {
    background-image: url(${Rules});
    width: 135px;
    height: 70px;
    position: absolute;
    right: -3%;
    bottom: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .rules-text {
      color: #121111;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0px;
      margin-left: 3.5rem;
      margin-top: 0.5rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 2%;
`;

export const CenterBar = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 35%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Terms = styled.div`
  display: flex;
  background: #e7ffa3;
  border-radius: 35px;
  padding: 15px;
  width: 75%;
  div {
    border: 2px solid #84b500;
    border-radius: 20px;
    width: 25%;
    text-align: center;
    margin: 0 10px;
    padding: 5px 0;
    font-weight: 700;
    font-size: 18px;
    color: #567600;
    cursor: pointer;
    position: relative;
  }
  .active-term {
    border: none;
    color: #212624;
    background: #fece18 !important;
    box-shadow: inset 1px 1px 0 1px #d9b326;
    &:after {
      content: '';
    }
  }
`;
