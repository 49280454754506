import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEventsList } from 'services/eventsList';
import { fetchWrapper } from 'services/login';

const initialState = {
  eventsList: [],
  isLoading: false,
  error: null,
};

export const getEventsList = createAsyncThunk(
  'eventsList/getEventsList',
  async () => {
    try {
      const res = await fetchWrapper(fetchEventsList);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get events list failed');
    }
  }
);

const eventsListSlice = createSlice({
  name: 'eventsList',
  initialState,
  reducers: {},
  extraReducers: {
    [getEventsList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.eventsList = null;
    },
    [getEventsList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.eventsList = action.payload.Result;
    },
    [getEventsList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { reducer } = eventsListSlice;
export default reducer;
