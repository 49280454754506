import { useTranslation } from 'react-i18next';
import formatChineseNumber from './formatChineseNumber';
const usePrimaryLevelsI18n = (level) => {
  const { t, i18n } = useTranslation(['common']);
  return `${t('common:primary', 'Primary')}${formatChineseNumber({
    value: level,
    language: i18n.language,
  })}`;
};

export default usePrimaryLevelsI18n;
