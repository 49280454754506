import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import DropdownMenu from 'components/Shared/DropdownMenu';
import ToggleIcon from 'assets/img/icon-toggle.svg';

import Container from './SeasonDropdown.styles';

// Helper
const replaceSeasonString = (seasonName, seasonString) => {
  return seasonName?.replace('Season', seasonString);
};

const SeasonDropdown = ({ currentSeason, setCurrentSeason }) => {
  const { t } = useTranslation(['leaderboard']);
  const { data } = useSelector((state) => state.leaderboard.seasons);

  const isCurrentSeason = (name) => {
    const currentData = data.find(
      (term) =>
        moment().isSameOrAfter(term.startDate) &&
        moment().isSameOrBefore(term.endDate)
    );
    return name === currentData?.name;
  };

  return (
    <Container>
      <DropdownMenu
        dataCy="dropdown-season"
        selectedValue={
          isCurrentSeason(currentSeason?.name)
            ? t('leaderboard:topBrainBanner.currentSeason', `Current Season`)
            : replaceSeasonString(
                currentSeason?.name,
                t('leaderboard:topBrainBanner.season', `Season`)
              )
        }
        indexKey
        values={data}
        valueKey="name"
        valueTemplate={(value) =>
          isCurrentSeason(value)
            ? t('leaderboard:topBrainBanner.currentSeason', `Current Season`)
            : replaceSeasonString(
                value,
                t('leaderboard:topBrainBanner.season', `Season`)
              )
        }
        setValue={setCurrentSeason}
        width="150px"
        borderRadius="3px"
        toggleIcon={
          <div className="toggle-icon">
            <img src={ToggleIcon} alt="" />
          </div>
        }
      />
    </Container>
  );
};

export default SeasonDropdown;
