import styled from 'styled-components';

export const Container = styled.div`
  background: #fff7e2;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem;
  padding: 0.35rem;
  span {
    color: #ff7121;
  }
`;
