import React from 'react';
import Book from './Book';
import { Container } from './BookShelf.styles';

const BookShelf = ({
  selectedLevel,
  selectedPaper,
  paperVal,
  giftObj,
  data,
}) => {
  return (
    data.length > 0 && (
      <Container
        id={selectedLevel}
        isOnePage={selectedPaper === 3}
        className="book-section"
      >
        <div className="books">
          {data.map((item) => (
            <Book
              key={item.Id}
              id={item.Id}
              paperVal={paperVal}
              item={item}
              icon={item.Icon.IconUrl}
              submitStatus={item.SubmissionStatus}
              selectedPaper={selectedPaper}
              giftObj={giftObj}
            />
          ))}
        </div>
      </Container>
    )
  );
};

export default BookShelf;
