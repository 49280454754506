export const proficiencyColors = {
  mastery: {
    textColor: '#202020',
    percentageColor: '#FFAE0C',
    borderColor: '#E2BF15',
    bg: '#FBDA38',
  },
  competent: {
    textColor: '#00BF7E',
    percentageColor: '#0CB173',
    borderColor: '#00BF7E',
    bg: 'transparent',
  },
  developing: {
    textColor: '#00BDE5',
    percentageColor: '#17ACCB',
    borderColor: '#00BDE5',
    bg: 'transparent',
  },
  beginning: {
    textColor: '#FF8282',
    percentageColor: '#F57676',
    borderColor: '#FC7171',
    bg: 'transparent',
  },
  incomplete: {
    textColor: '#919191',
    percentageColor: '#C1C1C1',
    borderColor: '#BCBCBC',
    bg: 'transparent',
  },
};
