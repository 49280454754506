import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.bg});
`;

export const TimeSpendWrap = styled.div`
  margin-bottom: 5px;
  color: #fff;
  .time-span {
    font-size: 14px;
    font-style: normal;
    font-family: Arial;
    font-weight: normal;
    margin-bottom: 3px;
  }
`;
