import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ErrorBoundary } from 'react-error-boundary';
import { rwd } from 'Theme';
import CPSection from 'components/Login/CPSection/CPSection';
import LoginSection from 'components/Login/LoginSection';
import CPSectionFallback from 'components/Login/CPSection/CPSectionFallback';
import { LoginPage } from './Login.styles';

export default function Login() {
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  return (
    <LoginPage>
      {isMobile ? (
        <LoginSection isMobile={isMobile} />
      ) : (
        <>
          <ErrorBoundary FallbackComponent={CPSectionFallback}>
            <CPSection isMobile={isMobile} />
          </ErrorBoundary>
          <LoginSection isMobile={isMobile} />
        </>
      )}
    </LoginPage>
  );
}
