import styled from 'styled-components';
import { BUNDLE_COLOR, BUNDLE_TYPE_ID } from 'constants/parentPortal';

export const Container = styled.div`
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem 1.5rem 1.7rem;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  gap: 0.5rem;
  .title {
    font-size: 0.75rem;
    color: rgb(13, 13, 13, 0.38);
  }
  .error {
    color: red;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  min-width: 220px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
  &:hover {
    box-shadow: 0 0 0 2px #ffd8c3;
  }
  .top {
    background: ${({ bundle }) => BUNDLE_COLOR[bundle] ?? '#e4f5fc'};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0.3rem;
    .disc {
      background-color: #ffce00;
      border-radius: 4px;
      width: 56px;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: ${({ bundle, isB2B }) =>
        ((isB2B && BUNDLE_TYPE_ID[bundle] === 'science') || !isB2B) &&
        '1.5rem'};
      .b2b-label {
        font-weight: 900;
        font-size: 0.4375rem;
        background: #ff5b00;
        color: #ffffff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      .discount-number {
        font-size: 0.56rem;
        font-weight: 900;
        margin-bottom: 0;
        padding: 0 0.2rem;
      }
    }
    .name {
      margin: 0.5rem 1rem 1.2rem;
      font-size: 0.875rem;
      font-weight: 700;
      display: flex;
      span {
        background: #ffeea5;
        border-radius: 4px;
        font-family: 'Helvetica Neue';
        font-size: 8px;
        padding: 0.3rem;
        margin-right: 0.4rem;
        font-weight: 700;
        font-style: italic;
        height: fit-content;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0.5rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #ffffff;
    p {
      margin-bottom: 0;
    }
    .price {
      color: rgb(96, 96, 96, 0.44);
      font-size: 0.75rem;
      font-weight: 700;
      text-decoration: line-through;
      margin-bottom: 0.5rem;
    }
    .disc-price {
      font-size: 1.06rem;
      font-weight: 700;
      span {
        font-weight: 900;
      }
    }
    .tax {
      font-size: 0.6875rem;
      font-weight: 700;
    }
    .orange3 {
      margin-top: 1.5rem;
    }
  }
`;
