import styled from 'styled-components';

export const TopBar = styled.div`
  background-color: #8ee0ff;
  margin-top: 59px;
  padding: 0.3em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackButton = styled.button`
  background: rgb(0, 153, 212, 0.3);
  border: none;
  color: #ffffff;
  padding: 0 1rem;
  border-radius: 1rem;
  font-size: 18px;
`;

export const Title = styled.div`
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 600;
`;

export const Content = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .center-div {
    position: absolute;
    top: 22.5%;
    z-index: 1;
  }
  .parent-div {
    margin-top: 10px;
  }

  .triangle {
    width: 250px;
    height: 250px;
    display: block;
    margin: -1px;
    position: relative;
    margin-right: 8px;
    p {
      margin: auto 5px;
    }
  }
  .representing,
  .process,
  .guess,
  .problem {
    position: absolute;
    display: flex;
    align-items: flex-end;
    z-index: 1;
    width: 100%;
    cursor: pointer;
  }
  .representing:first-child {
    bottom: 30%;
    right: 30%;
  }
  .representing:nth-child(2) {
    left: 0%;
  }
  .process:first-child {
    top: -17%;
    right: 7%;
  }
  .process:nth-child(2) {
    top: 5%;
    left: 44%;
  }
  .process:nth-child(3) {
    bottom: 34%;
    left: 76%;
  }
  .process:nth-child(4) {
    bottom: -10%;
    left: 85%;
  }
  .guess:first-child {
    top: -6%;
    right: 59%;
  }
  .guess:nth-child(2) {
    top: 46%;
    right: 44%;
  }
  .guess:nth-child(3) {
    bottom: -12%;
    left: 12%;
  }
  .problem:first-child {
    top: 20%;
    left: 78%;
  }
  .problem:nth-child(2) {
    top: 58%;
    left: 54%;
    width: 105%;
  }
  .problem:nth-child(3) {
    bottom: -12%;
    left: 10%;
  }
  .part-one {
    border-radius: 100% 0 0 0;
    border-left: 1px dashed #4c8693;
    border-top: 1px dashed #4c8693;
    float: left;
    background-color: rgb(255, 226, 146, 0.3);
  }
  .part-two {
    border-radius: 0 100% 0 0;
    border-top: 1px dashed #4c8693;
    border-right: 1px dashed #4c8693;
    float: right;
    background-color: rgb(215, 255, 184, 0.3);
  }
  .part-three {
    border-radius: 0 0 0 100%;
    float: left;
    background-color: #27aae1;
    border-bottom: 1px dashed #4c8693;
    border-left: 1px dashed #4c8693;
    background-color: rgb(39, 170, 225, 0.3);
  }
  .part-four {
    border-radius: 0 0 100% 0;
    float: right;
    background-color: #f49f9f;
    border-bottom: 1px dashed #4c8693;
    border-right: 1px dashed #4c8693;
    background-color: rgb(244, 159, 159, 0.3);
  }

  .parent-div span {
    position: absolute;
    font-size: 18px;
  }
  .representing-title {
    color: #ff9100;
    left: 50%;
    top: 50%;
  }
  .process-title {
    color: #8ac41a;
    right: 33%;
    top: 50%;
  }
  .guess-title {
    color: #00a8c4;
    left: 40%;
    bottom: 50%;
  }
  .problem-title {
    color: #e24040;
    right: 40%;
    bottom: 50%;
  }
`;
