import styled from 'styled-components';
import Top from 'assets/img/assignment/note-book/note-top.png';
import Middle from 'assets/img/assignment/note-book/note-middle.png';
import Bottom from 'assets/img/assignment/note-book/note-bottom.png';
import MainGreenBg from 'assets/img/assignment/assignment-view/content-green-bg.svg';

export const Container = styled.div``;
export const Main = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Content = styled.div`
  padding-top: 60px;
  width: 100%;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  background: url(${MainGreenBg});
  background-color: #187e86;
  .notebook-wrap {
    display: flex;
    justify-content: center;
    padding-left: 160px;
    @media (max-width: 1201px) {
      padding-left: 100px;
    }
    .notebook {
      width: 850px;
      padding-left: calc(12% - 164px);
      @media (min-width: 2000px) {
        padding-left: calc(15% - 64px);
        width: 1500px;
      }
      @media (max-width: 1201px) {
        padding-left: calc(12% - 100px);
      }
      .top {
        height: 96px;
        padding-left: 4rem;
        padding-top: 1rem;
        background: url(${Top});
        background-repeat: no-repeat;
        background-size: 841px auto;
        display: flex;
        align-items: center;
        @media (min-width: 2000px) {
          height: 150px;
          background-size: cover;
          padding: 1rem 7rem 0;
        }
        .top-content {
          width: 92%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .info-section {
            display: flex;
            align-items: center;
            p {
              margin-bottom: 0rem;
            }
            .title {
              color: #30a8bb;
              text-transform: uppercase;
              font-weight: bold;
              margin-right: 1rem;
            }
            .score {
              color: #48b500;
              font-size: 29px;
              display: flex;
              align-items: center;
              margin-right: 1rem;

              .number {
                font-family: Pacifico;
                font-size: 47px;
                margin-top: -1rem;
              }
            }
            .qn-number {
              color: #95c9d2;
              font-size: 22px;
              margin-right: 1rem;
              p {
                font-size: 14px;
                align-self: center;
              }
            }
            .completed-info {
              color: rgb(0, 0, 0, 0.49);
              font-size: 13px;
              font-weight: 300;
            }
          }
        }
        .leaderboard-btn {
          background: #cff0f5;
          border: none;
          border-radius: 24px;
          color: #3c5572;
          font-size: 14px;
          font-weight: 300;
          padding: 0.3rem 0.8rem;
          margin-right: 1rem;
          pointer-events: auto;
          img {
            width: 25px;
            vertical-align: bottom;
            margin-right: 0.5rem;
          }
        }
      }
      .middle {
        min-height: 435px;
        background: url(${Middle});
        background-repeat: repeat-y;
        background-size: 841px auto;
        @media (min-width: 2000px) {
          background-size: contain;
          min-height: 1000px;
          padding: 0px 7rem;
        }
        .content {
          margin-left: 5rem;
          padding-top: 1rem;
          width: 670px;
          @media (min-width: 2000px) {
            margin-left: 0rem;
            width: 1080px;
          }
          .qn-no {
            font-size: 1.125rem;
            font-weight: 600;
            color: #6ed1e1;
            padding-left: 1rem;
          }
        }
        .claim-points {
          margin-left: 5rem;
          padding: 0.5rem;
          width: 670px;
          background: #d4f3ff;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-left: 10px;
          }
          .claim-btn {
            background-color: #ffd500;
            margin: 0 20px;
            border-radius: 20px;
            border: none;
            padding: 3px 15px;
          }
        }
      }
      .bottom {
        height: 100px;
        background: url(${Bottom});
        background-repeat: no-repeat;
        background-size: 841px auto;
        position: relative;
        bottom: 1rem;
        @media (min-width: 2000px) {
          background-size: cover;
          height: 148px;
        }
      }
    }
  }
`;

export const QuestionContainer = styled.div`
  margin-bottom: 1rem;
  border-bottom: ${(props) => (props.isLastQn ? null : '2px dashed #cae3e5')};
`;

export const ScoreContainer = styled.div`
  color: ${(props) => props.color};
  font-size: 29px;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  .number {
    font-family: Pacifico;
    font-size: 47px;
    margin-top: -1rem;
  }
`;

export const CorrectAnswerSpan = styled.span`
  color: ${(props) => props.color};
  font-size: 20px;
  align-self: center;
  margin-right: 0.2rem;
  font-weight: bold;
`;
