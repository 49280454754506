import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CollapseIcon from 'assets/img/assignment/assignment-view/icon-collapse.svg';
import { Container, Progress } from './ProficiencyProgress.styles';

const ProficiencyProgress = ({
  totalQns,
  proficiency,
  setCollapse,
  collapse,
  showIcon = true,
}) => {
  const { t } = useTranslation(['assignmentQnView']);
  return (
    <Container>
      {showIcon && (
        <Container.Img
          src={CollapseIcon}
          alt="icon"
          onClick={() => setCollapse(!collapse)}
          collapse={collapse}
        />
      )}
      <p>{t('assignmentQnView:sidebar.proficiency', 'Proficiency')}</p>
      <Progress collapse={collapse}>
        <div className="progressbar">
          <ProgressBar>
            <ProgressBar
              variant="correct"
              now={(proficiency.correct / totalQns) * 100}
              key={1}
            />
            <ProgressBar
              variant="wrong"
              now={(proficiency.wrong / totalQns) * 100}
              key={2}
            />
            <ProgressBar
              variant="skipped"
              now={(proficiency.skipped / totalQns) * 100}
              key={3}
            />
          </ProgressBar>
        </div>
        <div className="progress-number">
          <span
            className={proficiency.correct > 0 ? 'num-correct' : ''}
          >{`${proficiency.correct}`}</span>
          <span>{` / ${totalQns}`}</span>
        </div>
      </Progress>
    </Container>
  );
};

export default ProficiencyProgress;
