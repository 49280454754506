import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: baseline;

  .label {
    margin-bottom: 0px;
    color: #626262;
    font-family: Linotte;
    font-size: 16px;
    margin-top: 0.5rem;
    margin-right: 0.4rem;
  }
  .answer-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  ${({ voiceOverFileExist }) => {
    return (
      voiceOverFileExist &&
      css`
        margin-left: 2rem;
        @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
          margin-left: 0;
        }
      `
    );
  }}
`;

export const Content = styled.div`
  border: 1px solid #83cde1;
  background: #e9fbff;
  min-width: 140px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0px 4px;
  margin-right: 5px;
  margin-bottom: 8px;
`;
