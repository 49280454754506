import styled from 'styled-components';

const fontSize = 18;

export const Container = styled.div`
  border-radius: 8px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-color: #707070;
  text-align: ${({ error }) => error && 'center'};
  .header-section {
    text-align: center;
    background-color: #d8e9f5;
    width: 100%;
    padding: 12px;
    border-radius: 10px 10px 0 0;
    color: #467fa8;
    font-size: ${fontSize}px;
  }
  .body-section {
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    padding: 20px 20px 30px;
    min-width: 460px;
    text-align: ${({ error }) => error && 'center'};
  }
  .modal-desc {
    font-size: ${fontSize}px;
  }
  .modal-buttons {
    text-align: center;
    margin-top: 30px;
    .btns {
      border: none;
      width: 90px;
      margin-left: 25px;
      padding: 3px 0;
      border-radius: 20px;
      color: #ffffff;
      font-size: ${fontSize}px;
    }
    .ok-btn {
      background-color: #ff7120;
      &:hover {
        background-color: #ff5801;
      }
    }
  }
`;

export const KokoDescription = styled.div`
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .description {
    margin: 0 5px 0 0;
  }
  .mission-icon {
    width: 24px;
    height: 24px;
  }
  .mission-text {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-right: 5px;
      margin-top: -3px;
      margin-left: 0;
      display: inline;
    }
  }
`;
