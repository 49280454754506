import styled from 'styled-components';
import BalloonConfetti from 'assets/img/mission/balloons-confetti.png';
import ContinueImg from 'assets/img/mission/continue-btn.png';

export const Page = styled.div`
  background: #3f3f3f;
  min-height: 100vh;
  color: white;
  font-size: 2.1875rem;
  font-family: 'VAGRounded BT';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: 20px;
    padding-top 20px;

    p {
      width: 80%;
      text-align: center;
    }
  }
`;

export const CelebrationContainer = styled.div`
  width: 900px;
  background-image: url(${BalloonConfetti});
  background-repeat: no-repeat;
  background-size: 100%;
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 2.5rem;
  p {
    margin-bottom: 0;
    &:nth-of-type(3) {
      font-size: 1.5rem;
    }
  }
  span {
    color: #fcbe03;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1.5rem;
    p {
      font-size: 18px;
      &:nth-of-type(3) {
        font-size: 22px;
      }
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobileS}px`}) {
    p {
      font-size: 16px;
      &:nth-of-type(3) {
        font-size: 18px;
      }
    }
  }
`;

export const GiftBoxesContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 900px;
  .giftbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .giftbox {
      margin: 1rem;
      width: 175px;
      cursor: ${(props) => (props.hasOpened ? 'auto' : 'pointer')};
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      :hover {
        transform: ${(props) => (props.hasOpened ? 'none' : 'scale(1.1)')};
        -webkit-transform: ${(props) =>
          props.hasOpened ? 'none' : 'scale(1.1)'};
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
    grid-template-columns: auto auto auto;
    padding: 0px 18px;
    .giftbox-container {
      .giftbox {
        width: 80px;
      }
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobileS}px`}) {
    .giftbox-container {
      .giftbox {
        width: 60px;
      }
    }
  }
`;

export const ContinueButton = styled.button`
  background: url(${ContinueImg});
  border: none;
  color: #ffffff;
  width: 469px;
  height: 78px;
  font-size: 1.8rem;
  padding-bottom: 20px;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 330px;
    height: 46px;
    padding-bottom: 2px;
    background-size: cover;
    margin-bottom: 1px;
    max-width: 90%;
  }
`;

export const StyledJumpLink = styled.div`
  background: #707070;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px;
  margin-top: 1rem;
  margin-bottom: 40px;
  p {
    font-size: 1.5rem;
    margin-bottom: 0;
    max-width: 222px;
  }
  img {
    cursor: pointer;
    &:first-of-type {
      width: 83px;
      margin: 0 2rem;
    }
    &:last-of-type {
      width: 77px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    p {
      font-size: 12px;
    }
    img {
      &:first-of-type {
      width: 40px;
      margin: 0 1rem;
    }
    &:last-of-type {
      width: 35px;
    }
  
  }
`;
