import styled from 'styled-components';

export const Container = styled.div`
  .dc-text {
    width: 250px;
  }
`;

export const Main = styled.div`
  padding: 80px 10% 0%;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 80px 7.5% 0%;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 80px 5% 0%;
  }
`;
