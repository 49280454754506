import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { isEmpty, isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import SwitchPortal from 'components/Dashboard/SwitchPortal';
import Loader from 'components/Shared/Loader';
import BannerNotification from 'components/Notifications/BannerNotification';
import TrialBanner from 'components/TrialBanner/index';
import ParentPortal from 'pages/ParentPortal';
import { setHasSeenToggleTooltip } from 'store/dashboard/studentDetailsSlice';
import {
  getStaticUserNotification,
  getUserNotification,
  setIsRead,
} from 'store/notification/notificationSlice';
import { featureToggle } from 'constants/index';
import trialBannerConfig, { hkPremiumTrialBanner } from 'constants/trialBanner';
import { resetActivity } from 'store/kooClass/kooClassSlice';
import moment from 'moment-timezone';
import Dashboardv1 from 'components/Dashboard/Dashboardv1';
import Dashboardv2 from 'components/Dashboard/Dashboardv2/Dashboardv2';
import { useMediaQuery } from 'react-responsive';
import { setSubject, setPlan } from 'store/plan/planSlice';
import { useLocation, useHistory } from 'react-router-dom';
import useQuery from 'helpers/useQuery';
import { Page } from './index.styles';

// Helper
export const checkSchoolPlan = (subscriptions) => {
  const schoolPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
  );
  const standardPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 13
  );
  // PlanId === 4 is for Math olympiad
  const premiumPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 3 || plan.PlanId === 4
  );
  const plusPlanIndex = subscriptions.findIndex((plan) => plan.PlanId === 2);
  if (standardPlanIndex !== -1) {
    return 'standard';
  }
  if (premiumPlanIndex !== -1) {
    return 'premium';
  }
  if (plusPlanIndex !== -1) {
    return 'plus';
  }
  if (schoolPlanIndex !== -1) {
    return 'school';
  }
  return 'plus';
};
const checkIfB2BPlanOnly = (subscriptions) => {
  const schoolPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
  );
  if (subscriptions.length === 1 && schoolPlanIndex !== -1) {
    return true;
  }
  return false;
};

const SubjectBanners = ({
  t,
  currentProduct,
  userId,
  countryCode = '',
  countryZone,
}) => {
  const isHKMathPremiumTrial =
    currentProduct.subjectId === 1 &&
    currentProduct.productType.trim().toLowerCase() === 'premium' &&
    countryCode.trim().toLocaleLowerCase() === 'hkg' &&
    !currentProduct.subscriptionDetails.IsOTPTrial;
  const currentTrial = isHKMathPremiumTrial
    ? hkPremiumTrialBanner
    : trialBannerConfig.find(
        (item) =>
          item.subjectId === currentProduct.subjectId &&
          item.isOtpTrial === currentProduct.subscriptionDetails.IsOTPTrial
      );
  const calculateCurrentDay = () => {
    const start = moment
      .tz(currentProduct.startDate, countryZone)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const today = moment
      .tz(countryZone)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const daysLeft = today.diff(start, 'days');

    if (daysLeft >= 7) return 7;
    return daysLeft + 1;
  };

  return !isNil(currentTrial) ? (
    <TrialBanner
      t={t}
      currentDay={calculateCurrentDay()}
      currentTrial={currentTrial}
      userId={userId}
    />
  ) : null;
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const { t } = useTranslation(['trialBanner']);

  const urlSubject = query.get('subject');
  const urlPlan = query.get('plan');
  const urlView = query.get('view');

  // Redux States
  const {
    plan,
    subject,
    isLoading: isProductsLoading,
    currentProduct,
    products,
  } = useSelector((state) => state.plan);
  const {
    studentDetails,
    isLoading,
    hasSeenLoader,
    nonExpiredSubscriptionPlans,
    subscriptionData,
    defaultLevel,
    countryCode,
  } = useSelector((state) => state.studentDetails);
  const { activeType, activeId, activityName, activities } = useSelector(
    (state) => state.kooClass
  );
  const {
    userNotification,
    isLoadingUserNotification,
    notificationsDetail,
    pageNotif,
    bannerNotif,
  } = useSelector((state) => state.notification);

  const allLoading =
    isLoading ||
    isLoadingUserNotification ||
    (process.env.REACT_APP_SCIENCE === 'true' && isProductsLoading);
  const isTeacherStudent = studentDetails && studentDetails.Roles.includes(12);
  const isTablet = useMediaQuery({ minWidth: 768 });
  // Component States
  const [showLoader, setShowLoader] = useState(!hasSeenLoader);

  useEffect(() => {
    if (studentDetails !== null) {
      // For tracking page view of B2B plan only students
      const isB2BPlanOnly = checkIfB2BPlanOnly(studentDetails.Subscriptions);
      if (
        isB2BPlanOnly &&
        process.env.NODE_ENV === 'production' &&
        process.env.REACT_APP_DEV !== 'true'
      ) {
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: process.env.NODE_ENV === 'development', // enable logs
        };
        ReactPixel.init(process.env.REACT_APP_PIXEL_ID, {}, options);
        ReactPixel.pageView(); // For tracking page view
      }
    }
  }, [dispatch, studentDetails]);
  useEffect(() => {
    if (
      !isNil(activeType) ||
      !isNil(activeId) ||
      !isNil(activityName) ||
      !isEmpty(activities)
    ) {
      dispatch(resetActivity());
    }
  }, [activeId, activeType, activities, activityName, dispatch]);
  // Cleanup on page unmount
  useEffect(() => {
    return () => {
      dispatch(setHasSeenToggleTooltip(true));
    };
  }, [dispatch]);

  // <<NOTIFICATIONS>>
  // calling notification endpoints
  useEffect(() => {
    if (
      !isNil(studentDetails) &&
      isNil(userNotification) &&
      !featureToggle.staticNotification
    ) {
      dispatch(
        getUserNotification({
          countryCode,
          defaultLevel,
          nonExpiredSubscriptionPlans,
          subscriptionData,
          allPlans: studentDetails.Subscriptions,
        })
      );
    }
    if (featureToggle.staticNotification && isEmpty(notificationsDetail)) {
      dispatch(getStaticUserNotification());
    }
  }, [
    countryCode,
    defaultLevel,
    dispatch,
    nonExpiredSubscriptionPlans,
    notificationsDetail,
    plan,
    studentDetails,
    subscriptionData,
    userNotification,
  ]);

  // check page notif type isRead flag for redirect to notification page
  useEffect(() => {
    if (!isNil(pageNotif)) {
      const readStatus = pageNotif.isRead;
      dispatch(setIsRead(readStatus));
    }
  }, [dispatch, pageNotif]);

  // to always update app states align with url subject & plan
  useEffect(() => {
    if (!isNil(urlSubject) && subject !== urlSubject) {
      dispatch(setSubject(urlSubject));
    }
    if (!isNil(urlPlan) && plan !== urlPlan) {
      dispatch(setPlan(urlPlan));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSubject, urlPlan, location.pathname]);

  useEffect(() => {
    if (!allLoading) {
      if (urlView) {
        return;
      }
      if (!isNil(currentProduct) && !currentProduct?.isExpired) {
        history.replace(
          `/dashboard?subject=${currentProduct.subject?.toLocaleLowerCase()}&plan=${currentProduct.productType?.toLocaleLowerCase()}`
        );
        return;
      }
      if (isNil(urlSubject) || isNil(urlPlan)) {
        history.replace(`/dashboard?subject=${subject}&plan=${plan}`);
        return;
      }
      history.replace(`/dashboard?subject=${urlSubject}&plan=${urlPlan}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, plan, location.pathname, currentProduct]);

  const renderNotification = () => {
    if (!isNil(bannerNotif) && !showLoader) {
      if (plan !== 'school' || isTablet) {
        return <BannerNotification page="dashboard" />;
      }
    }
  };

  const renderSubjectBanners = () => {
    if (!allLoading && currentProduct && currentProduct.isTrial) {
      if (plan !== 'school' || isTablet) {
        return (
          <SubjectBanners
            t={t}
            currentProduct={currentProduct}
            userId={studentDetails?.UserId}
            countryCode={countryCode}
            product={products.find(
              (item) => item.subject.toLowerCase().trim() === subject
            )}
            countryZone={studentDetails?.ZoneName}
          />
        );
      }
    }
  };
  const renderResult = () => {
    if (plan !== 'parent') {
      const showDashboardv1 = ['school', 'plus'].includes(plan);
      return (
        <Page>
          <Header loading={allLoading} />
          {process.env.REACT_APP_SWITCH_PORTAL_BANNER === 'true' &&
            plan === 'school' && <SwitchPortal />}
          {renderNotification()}
          {showDashboardv1 && (
            <Dashboardv1 isTeacherStudent={isTeacherStudent} />
          )}
          {!showDashboardv1 && <Dashboardv2 />}
          {renderSubjectBanners()}
        </Page>
      );
    }
    return <ParentPortal />;
  };
  return (
    <>
      {!hasSeenLoader && (
        <Loader
          isAPILoading={allLoading}
          showLoader={showLoader}
          setShowLoader={setShowLoader}
        />
      )}
      {renderResult()}
    </>
  );
};

export default Dashboard;
