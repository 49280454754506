import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Number,
  QnStatus,
  QnNumber,
  StatusInfo,
} from './QnNav.styles';

const QnNav = ({
  number,
  correct,
  qnAnswered,
  active,
  collapse,
  clickHandler,
  styles = {},
  disabled,
}) => {
  const { t } = useTranslation(['assignmentQnView']);
  const statusText = () => {
    if (correct) {
      return t('assignmentQnView:sidebar.correct', 'Correct answer');
    }
    if (correct === false) {
      return t('assignmentQnView:sidebar.wrong', 'Wrong answer');
    }
    if (qnAnswered) {
      return t('assignmentQnView:sidebar.answerProvided', 'Answer provided');
    }
    return '';
  };
  return (
    <Container
      data-cy={`qn-sidebar-item-${number}`}
      collapse={collapse}
      active={active}
      onClick={clickHandler}
      disabled={disabled}
    >
      <div className="number" style={styles.number ?? {}}>
        <Number
          correct={correct}
          active={active}
          answered={qnAnswered}
          data-cy="icon-result"
        >
          {correct || correct === false ? '' : number}
        </Number>
      </div>
      <QnStatus collapse={collapse} data-cy="status">
        <QnNumber active={active}>
          {t('assignmentQnView:sidebar.qn', 'Question')} {number}
        </QnNumber>
        <StatusInfo
          status={correct}
          answered={qnAnswered}
          data-cy="status-info"
        >
          {statusText()}
        </StatusInfo>
      </QnStatus>
    </Container>
  );
};

export default QnNav;
