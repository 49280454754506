import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { findIndex, isNil, propEq } from 'ramda';
import {
  fetchGamesByCategory,
  getStatusGame,
  unlockGameById,
} from 'services/brainGame';
import store from 'store/index';
import { fetchWrapper } from 'services/login';

// 25 minutes
const totalTime = 1500;

// ASYNC THUNKS
export const getStatus = createAsyncThunk('brainGame/getStatus', async () => {
  try {
    const res = await fetchWrapper(getStatusGame);
    return {
      result: res,
      timezone: store.getState().login.timezone,
    };
  } catch (error) {
    throw new Error(error?.message ?? 'Get assignment details failed');
  }
});

export const getGames = createAsyncThunk(
  'brainGame/getGames',
  async (categoryId) => {
    try {
      const res = await fetchWrapper(fetchGamesByCategory, categoryId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get games by category failed');
    }
  }
);

export const unlockGame = createAsyncThunk(
  'brainGame/unlockGame',
  async (gameId) => {
    try {
      const res = await fetchWrapper(unlockGameById, gameId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get games by category failed');
    }
  }
);

const initialState = {
  startTime: '',
  endTime: '',
  openTime: '',
  closeTime: '',
  RestrictedByTeacher: false,
  timeRemaining: 0,
  isStarted: false,
  isEnded: false,
  games: [],
  isLoading: false,
  error: null,
};

const brainGameSlice = createSlice({
  name: 'brainGame',
  initialState,
  reducers: {
    setDefault: (state) => {
      state.isStarted = true;
    },
    decrementTime: (state) => {
      const remainingSeconds = state.timeRemaining - 1;
      state.timeRemaining = remainingSeconds;
    },
    setIsEnded: (state) => {
      state.isEnded = true;
    },
    resetIsStarted: (state) => {
      state.isStarted = false;
    },
  },
  extraReducers: {
    [getStatus.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.openTime = action.payload.result.StartDateTime;
      state.closeTime = action.payload.result.EndDateTime;
      state.startTime = action.payload.result.BrainGameStartTime;
      state.endTime = action.payload.result.BrainGameEndTime;
      state.RestrictedByTeacher = action.payload.result.RestrictedByTeacher;

      if (!isNil(action.payload.result.BrainGameEndTime)) {
        const now = moment.tz(action.payload.timezone);
        const end = moment.tz(
          action.payload.result.BrainGameEndTime,
          action.payload.timezone
        );
        const timeRemaining = end.diff(now, 'seconds');

        state.timeRemaining = timeRemaining < 0 ? 0 : timeRemaining;
      } else {
        state.timeRemaining = totalTime;
      }
    },
    [getStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getGames.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getGames.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.games = action.payload;
    },
    [getGames.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [unlockGame.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [unlockGame.fulfilled]: (state, action) => {
      state.isLoading = false;
      const newGames = [...state.games];
      const index = findIndex(propEq('GameId', action.payload.GameId))(
        newGames
      );
      newGames[index] = action.payload;
      state.games = newGames;
    },
    [unlockGame.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { reducer, actions } = brainGameSlice;
export const { decrementTime, setDefault, setIsEnded } = actions;

let timerInterval;

// clear all interval
export const dispose = createAsyncThunk(
  'brainGame/dispose',
  async (_, { dispatch }) => {
    dispatch(actions.resetIsStarted());
    if (timerInterval !== null) {
      clearInterval(timerInterval);
    }
  }
);

export const startGame = createAsyncThunk(
  'brainGame/startGame',
  async (_, { dispatch, getState }) => {
    dispatch(getStatus());
    dispatch(actions.setDefault());
    timerInterval = setInterval(() => {
      dispatch(actions.decrementTime());
      const { timeRemaining } = getState().brainGame;
      if (timeRemaining <= 0) {
        dispatch(setIsEnded(true));
        dispatch(dispose());
      }
    }, 1000);
  }
);

export default reducer;
