import styled from 'styled-components';

export const Container = styled.div`
  display: ${(props) => (props.isHide ? 'none' : 'flex')};
  justify-content: ${(props) =>
    props.answer === 'correct' ? 'flex-end' : 'space-between'};
  min-width: 720px;
  background-color: #ebf7f9;
  padding-top: 0.8rem;
  position: absolute;
  margin-right: 4rem;
  margin-top: ${(props) => (props.answer !== 'unknown' ? '1rem' : '-0.5rem')};
  margin-left: -1px;
  @media (min-width: 2000px) {
    min-width: 1150px;
    margin-left: 2rem;
    padding-top: 1.2rem;
    margin-top: 1rem;
  }

  p {
    margin-bottom: 0;
  }
  .qn-instruction {
    display: flex;
    flex-direction: row;
  }
  .use-helper-text {
    color: #8ac2d3;
    font-size: 12px;
    span {
      margin-left: 0.2rem;
    }
    img {
      width: 18px;
      height: 18px;
    }
  }
  .video-btn {
    background: #ffffff;
    border: 1px solid #b1dce4;
    border-radius: 22px;
    display: flex;
    align-items: center;
    color: #1198b2;
    padding: 0.1rem 0.5rem;
    margin-right: 0.5rem;
    span {
      vertical-align: center;
      margin-right: 3px;
    }
  }
  .different-skill {
    all: unset;
    color: #2db9e2;
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-width: unset;
    left: 3rem;
    right: 3rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin: 0;
    padding: 26px;
    min-width: auto;
    left: 0;
    .use-helper-text {
      display: none;
    }
    .hide-mobile {
      display: none;
    }
  }
`;

export default {};
