import React from 'react';
import { useTranslation } from 'react-i18next';
import { submissionTypeIDs, questionTypeIDs } from 'constants/index';
import QuestionContent from 'components/Question';
import {
  showSelectedTool,
  setToolPosition,
} from 'store/assignment/assistToolsSlice';
import { setActivePeerChallengeActiveQn } from 'store/multiplayer/multiplayerSlice';
import { setActiveIndex } from 'store/assignment/barModelSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  QuestionContainer,
} from './PeerChallengeQnViewMiddle.styles';

const PeerChallengeQnViewMiddle = ({ questions }) => {
  const dispatch = useDispatch();
  const barModel = useSelector((state) => state.barModel);
  const { width } = barModel.data;
  const submissionType = submissionTypeIDs.SkillPracticeMS;
  const defaultBarModelPosition = {
    x: window.innerWidth - width - 98,
    y: 130,
  };
  const { t } = useTranslation(['peerChallengeQnView']);

  return (
    <>
      <Container data-cy="peer-chal-qns">
        {questions &&
          questions.map((qn, index) => (
            <QuestionContainer
              key={qn.Id}
              isLastQn={questions.length === index + 1}
              data-cy={qn.Id}
            >
              <p className="qn-no">
                {t('peerChallengeQnView:main.qn', 'Question')} {index + 1}
                {qn.Id && <span className="qn-id">{`(ID ${qn.Id})`}</span>}
              </p>
              <QuestionContent
                page="peer-challenge"
                submissionType={submissionType}
                questionType={questionTypeIDs[qn.QuestionType]}
                qnID={qn.Id}
                xml={qn.Payload}
                showSolution={false}
                showSubmissions
                assignmentType="peer-challenge"
                PCActiveQn={index + 1}
              />
              <div className="assist-tools">
                <p
                  onClick={() => {
                    dispatch(setActiveIndex(index));
                    dispatch(
                      setToolPosition({
                        tool: 'bar-model',
                        position: defaultBarModelPosition,
                      })
                    );
                    dispatch(showSelectedTool('bar-model'));
                  }}
                >
                  {t('peerChallengeQnView:main.addBarModel', 'Draw Bar Model')}
                </p>
                <p
                  onClick={() => {
                    dispatch(setActivePeerChallengeActiveQn(index + 1));
                    dispatch(
                      setToolPosition({
                        tool: 'add-working',
                        position: {
                          x: 0,
                          y: 0,
                        },
                      })
                    );
                    dispatch(showSelectedTool('add-working'));
                  }}
                >
                  {t('peerChallengeQnView:main.addWorking', 'Add Working')}
                </p>
              </div>
            </QuestionContainer>
          ))}
      </Container>
    </>
  );
};

export default PeerChallengeQnViewMiddle;
