import { answerTypes } from 'constants/index';
import { isNil } from 'ramda';

// Helper function used for constructing key value pairs for saving or checking (fill-in-the blanks/long) answer
const createAnswerKeyValuePairs = (
  studentAnswers,
  questionAnswers,
  userID,
  qnID,
  keysToMarkRight
) => {
  return questionAnswers.map((answerKey) => {
    // Check if current questionAnswer Key is in array of keys to mark as right for long answer
    const toMarkKeyAsRight = !isNil(keysToMarkRight)
      ? keysToMarkRight.find((key) => key === answerKey.Key)
      : null;
    return {
      answerType: answerTypes[answerKey.Type],
      key: answerKey.Key,
      value:
        studentAnswers !== null
          ? studentAnswers.hasOwnProperty(`${qnID}-${answerKey.Key}`)
            ? studentAnswers[`${qnID}-${answerKey.Key}`]
            : ''
          : '',
      userid: userID,
      ismarkedright: !isNil(toMarkKeyAsRight),
    };
  });
};

// Refactored helper function to construct key value pairs for saving/checking answers (Fill-in-the-blanks/Long Ans)
// Without questionAnswers
const newCreateAnswerKeyValuePairs = (
  studentAnswers,
  userID,
  keysToMarkRight
) => {
  // studentAnswers is an object with <qnID>-key1, <qnID>-key2 etc as keys
  if (studentAnswers === null) return [];
  return Object.keys(studentAnswers).map((key) => {
    const splitKey = key.split('-');
    const isFreeText = splitKey[0] === 'freetext';
    const keyNumber = splitKey[1];
    const toMarkKeyAsRight = !isNil(keysToMarkRight)
      ? keysToMarkRight.find((key) => key === keyNumber)
      : null;
    return {
      answerType: isFreeText ? 6 : 0,
      key: keyNumber,
      value: studentAnswers[key],
      userid: userID,
      ismarkedright: !isNil(toMarkKeyAsRight),
    };
  });
};

// Helper function used for constructing keys for saving/checking mcq answer/s
export const createMCQAnswerKeyValuePairs = (studentAnswers, userID) => {
  if (studentAnswers === null) return [];
  return studentAnswers.map((answerKey) => {
    return {
      key: answerKey,
      userid: userID,
    };
  });
};

// Wrapper function to create answer key value pairs based on questionType
export const createAnswerKeyValuePairsByQuestionType = (
  questionType,
  params
) => {
  const {
    studentAnswers,
    // questionAnswers,
    userID,
    // qnID,
    keysToMarkRight,
  } = params;
  switch (questionType) {
    case 'mcq':
      return createMCQAnswerKeyValuePairs(studentAnswers, userID);
    default:
      return newCreateAnswerKeyValuePairs(
        studentAnswers,
        userID,
        keysToMarkRight
      );
    // return createAnswerKeyValuePairs(
    //   studentAnswers,
    //   questionAnswers,
    //   userID,
    //   qnID,
    //   keysToMarkRight
    // );
  }
};
export default createAnswerKeyValuePairs;
