import styled from 'styled-components';

export const Container = styled.div`
  min-width: ${(props) => (props.width ? props.width : '100%')};
  .submit-button {
    background: #ff6701;
    color: #fff;
    font-size: ${(props) =>
      props.fontSize ? props.fontSize : 'calc(20 / 1366 * 100vw)'};
    font-weight: bold;
    border: none;
    width: 100%;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    @media only screen and (max-width: 1024px) {
      font-size: calc(20 / 1024 * 100vw);
    }
  }

  .submit-button:hover {
    background: #ff5801;
  }

  @media only screen and (max-width: 480px) {
    .submit-button {
      width: 90%;
      font-size: 24px;
      position: relative;
      top: -4rem;
      position: static;
      align-self: center;
    }
  }
`;
