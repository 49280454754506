// Ref:https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
// Ref Convversion API: https://developers.facebook.com/docs/marketing-api/conversions-api/using-the-api
// Ref deduplication: https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
// Ref eventId: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event#event-id

import postConversionApi from 'services/fbConversion';
import Cookies from 'js-cookie';
import { sha256 } from 'js-sha256';
const FB_CONFIGURE = {
  fbPixel: true,
  conversionAPI: true,
};
const sendFBEvent = (
  { eventType, eventMetaObject, isStandard },
  { id, email, phone }
) => {
  const timestamp = new Date().getTime();
  const eventId = `${eventType}_${id}_${timestamp}`;
  const hashedEmail = email && sha256(email.trim().toLowerCase());
  const hashedPhoneNumber = phone && sha256(phone);
  const fbp = Cookies.get('_fbp');
  const fbc = Cookies.get('_fbc');

  if (typeof window !== 'undefined') {
    if (window.fbq != null && FB_CONFIGURE.fbPixel) {
      const functionType = isStandard ? 'track' : 'trackCustom';
      window.fbq(functionType, eventType, eventMetaObject, {
        eventID: eventId,
      });
    }

    if (FB_CONFIGURE.conversionAPI) {
      const body = {
        eventName: eventType,
        eventId: eventId,
        customData: eventMetaObject,
        userData: {
          email: hashedEmail,
          phone_number: hashedPhoneNumber,
          clickId: fbc,
          browserId: fbp,
        },
      };
      postConversionApi(body);
    }
  }
};

export default sendFBEvent;
