export const ClassAchievements = (cp) => {
  if (cp <= 150) {
    return {
      name: 'Class',
      startCP: 0,
      nextCP: 150,
      nextName: 'Star Class',
    };
  }
  if (cp <= 500) {
    return {
      name: 'Star Class',
      startCP: 150,
      nextCP: 500,
      nextName: 'Gold Class',
    };
  }
  if (cp <= 1000) {
    return {
      name: 'Gold Class',
      startCP: 500,
      nextCP: 1000,
      nextName: 'Super Class',
    };
  }
  if (cp <= 2000) {
    return {
      name: 'Super Class',
      startCP: 1000,
      nextCP: 2000,
      nextName: 'Master Class',
    };
  }
  if (cp <= 3000) {
    return {
      name: 'Master Class',
      startCP: 2000,
      nextCP: 3000,
      nextName: 'Wizard Class',
    };
  }
  if (cp <= 4000) {
    return {
      name: 'Wizard Class',
      startCP: 3000,
      nextCP: 4000,
      nextName: 'Genius Class',
    };
  }
  if (cp <= 5000) {
    return {
      name: 'Genius Class',
      startCP: 4000,
      nextCP: 5000,
      nextName: 'Champion Class',
    };
  }
  return {
    name: 'Champion Class',
    startCP: 5000,
    nextCP: 5000,
    nextName: 'Champion Class',
  };
};
