import { SubmissionResultTypes } from 'constants/index';
import { answerStatus } from 'constants/kooClass';

export const countCorrectWrongSubmissions = (
  submissions,
  page,
  assignmentType
) => {
  const result = {
    correct: 0,
    wrong: 0,
  };
  const submissionResultKeyName =
    assignmentType === 'KooClass' ? 'submissionResult' : 'SubmissionResult';
  // Filter out submissions  with submission results === 1 (correct) or 2 (wrong)
  submissions.forEach((submission) => {
    if (submission.SubmissionModel !== null) {
      if (
        SubmissionResultTypes[
          submission.SubmissionModel[submissionResultKeyName]
        ] === 'correct'
      ) {
        result.correct += 1;
      } else if (
        SubmissionResultTypes[
          submission.SubmissionModel[submissionResultKeyName]
        ] === 'wrong'
      ) {
        result.wrong += 1;
      } else if (page === 'solution') {
        result.wrong += 1;
      }
    }
  });
  return result;
};

export const countCorrectWrongSubmissionsKooClass = (submissions, page) => {
  const result = {
    correct: 0,
    wrong: 0,
  };
  // Filter out submissions  with submission results === 1 (correct) or 2 (wrong)
  submissions.forEach((submission) => {
    if (submission.submissionModel !== null) {
      if (
        answerStatus[submission.submissionModel.submissionResult] === 'Correct'
      ) {
        result.correct += 1;
      } else if (
        answerStatus[submission.submissionModel.submissionResult] === 'Wrong'
      ) {
        result.wrong += 1;
      } else if (page === 'solution') {
        result.wrong += 1;
      }
    }
  });
  return result;
};
