import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/top-brain/science/top-brain-bg.png';

export const Container = styled.div`
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 315px;
  display: flex;
  padding: 0 20px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 2%;
`;

export const CenterBar = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 35%;
  padding-top: 20px;
  padding-bottom: 20px;
  .middle-content {
    margin-top: 2rem;
    display: flex;
    align-items: center;
  }
`;

export const ScoringPeriod = styled.div`
  color: #262626;
  font-size: 22px;
  display: flex;
  margin-top: 24px;
  font-weight: 600;
  align-items: baseline;
  span {
    color: #568000;
    font-size: 16px;
    margin-right: 5px;
  }
`;
