import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment-timezone';
import Pwin from 'assets/img/multiplayer/stars/pencilWin.svg';
import Plose from 'assets/img/multiplayer/stars/pencilLose.svg';
import StarLose from 'assets/img/multiplayer/stars/star-lose.svg';
import StarDraw from 'assets/img/multiplayer/stars/star-draw-right.svg';
import VsResult from 'assets/img/multiplayer/stars/vs-result.svg';
import { multiplayerListStyles } from 'constants/multiplayer';
import formatI18nDateTime from 'helpers/formatI18nDateTime';
import { useSelector } from 'react-redux';
import { peerChallengeResult, peerChallengeStatus } from 'constants/index';
import useIsDesktop from 'hooks/responsive/useIsDesktop';
import { Container, ResultContainer, Vs } from './ResultBoard.styles';

const Result = ({
  position,
  result,
  name,
  score,
  CPDate,
  CPPoints,
  subjectName,
  opponentStatus,
}) => {
  const { t, i18n } = useTranslation(['peerChallengeResult']);
  const isDesktop = useIsDesktop();
  const resultStyling = () => {
    if (
      (position === 'left' && result === 'win') ||
      (position === 'right' && result === 'lose')
    ) {
      return {
        nameBg: '#ffbb00',
        detailsBg: '#FFF5CE',
        textColor: '#E4BB35',
      };
    }
    if (
      (position === 'left' && result === 'lose') ||
      (position === 'right' && result === 'win')
    ) {
      return {
        nameBg: '#73DBFD',
        detailsBg: '#CEF2FF',
        textColor: '#61A6BF',
      };
    }
    if (position === 'right' && (result === 'draw' || result === 'pending')) {
      return {
        nameBg: '#ABED54',
        detailsBg: '#DAF8BA',
        textColor: '#6D9542',
      };
    }
    if (position === 'left' && (result === 'draw' || result === 'pending')) {
      return {
        nameBg: '#ABED54',
        detailsBg: '#E6FFCB',
        textColor: '#6D9542',
      };
    }
  };

  return (
    <ResultContainer
      position={position}
      styling={resultStyling(position, result)}
    >
      <div className="name" data-cy={`${position}-name`}>
        <span>{name}</span>
      </div>
      <div className="result-details" data-cy={`${position}-result-details`}>
        {result === 'pending' && position === 'right' ? (
          <div className="pending-score">
            <div className="pending-text">
              {t(
                'peerChallengeResult:main.waitMsg',
                `Now wait for your friend to complete
                the challenge. The result will be shown in your`
              )}
              <b>
                {t(
                  'peerChallengeResult:main.challengeHistory',
                  'challenge history.'
                )}
              </b>
            </div>
            <img src={VsResult} alt="pending" />
          </div>
        ) : (
          <>
            <div className="score">
              {position === 'right' &&
              (opponentStatus === 'expired' ||
                opponentStatus === 'rejected') ? (
                <p className="expired">
                  {t('peerChallengeResult:main.expired', 'Expired')}
                </p>
              ) : (
                <>
                  <p>
                    {score}
                    <span>%</span>
                    {!isDesktop && (
                      <div className="correct-text">
                        {t('peerChallengeResult:main.correct', 'Correct')}
                      </div>
                    )}
                  </p>
                  {isDesktop && (
                    <div className="correct-text">
                      {t('peerChallengeResult:main.correct', 'Correct')}
                    </div>
                  )}
                </>
              )}
            </div>
            {position === 'left' && (result === 'win' || result === 'draw') && (
              <div className="cp">
                <Trans
                  i18nKey="peerChallengeResult:main.myCp"
                  values={{
                    number: `${CPPoints} ${CPPoints > 1 ? 'CPs' : 'CP'}`,
                    date: moment(CPDate).format(
                      formatI18nDateTime({
                        language: i18n.language,
                        value: CPDate,
                        format: 'D/MM/YYYY',
                      })
                    ),
                  }}
                >
                  You have earned
                  <img
                    src={multiplayerListStyles[subjectName].cpIcon}
                    width="25px"
                    alt="CPs"
                  />{' '}
                  {CPPoints} {CPPoints > 1 ? 'CPs ' : 'CP '}
                  <span>
                    on{' '}
                    {moment(CPDate).format(
                      formatI18nDateTime({
                        language: i18n.language,
                        value: CPDate,
                        format: 'D/MM/YYYY',
                      })
                    )}
                  </span>
                </Trans>
              </div>
            )}
            {position === 'right' &&
              result === 'draw' &&
              opponentStatus !== 'expired' &&
              opponentStatus !== 'rejected' && (
                <div className="cp">
                  <Trans
                    i18nKey="peerChallengeResult:main.opponentCp"
                    values={{
                      number: `${CPPoints} ${CPPoints > 1 ? 'CPs' : 'CP'}`,
                      date: moment(CPDate).format(
                        formatI18nDateTime({
                          language: i18n.language,
                          value: CPDate,
                          format: 'D/MM/YYYY',
                        })
                      ),
                    }}
                  >
                    Your opponent earned
                    <img
                      src={multiplayerListStyles[subjectName].cpIcon}
                      width="25px"
                      alt="CPs"
                    />{' '}
                    {CPPoints} {CPPoints > 1 ? 'CPs ' : 'CP '}
                    <span>
                      on{' '}
                      {moment(CPDate).format(
                        formatI18nDateTime({
                          language: i18n.language,
                          value: CPDate,
                          format: 'D/MM/YYYY',
                        })
                      )}
                    </span>
                  </Trans>
                </div>
              )}
          </>
        )}
      </div>
    </ResultContainer>
  );
};

const pImgs = {
  win: Pwin,
  lose: Plose,
  draw: Pwin,
};

const starImgs = {
  win: StarDraw,
  lose: StarLose,
  draw: StarDraw,
};

const getChallengerData = (data, currentUserID) => {
  const result = {};
  if (!data?.Challengers) return result;
  data.Challengers.forEach((challenger) => {
    if (challenger.UserId === currentUserID) {
      result.current = {
        ...challenger,
        scorePercentage: (challenger.Score / 5) * 100,
      };
    } else {
      result.opponent = {
        ...challenger,
        scorePercentage: (challenger.Score / 5) * 100,
      };
    }
  });
  return result;
};

const ResultBoard = ({ data, userID, subjectName }) => {
  const { userInfoCache } = useSelector((state) => state.lookup);
  const challengerData = getChallengerData(data, userID);
  const currentUserResult = peerChallengeResult[challengerData.current?.Result];
  const opponentStatus = peerChallengeStatus[challengerData.opponent?.Status];
  return (
    <Container result={currentUserResult}>
      {currentUserResult !== 'pending' && (
        <img
          src={pImgs[currentUserResult]}
          className={`img-left ${currentUserResult}`}
          alt=""
        />
      )}
      <Result
        position="left"
        result={currentUserResult}
        name={userInfoCache[challengerData.current?.UserId]?.FullName ?? ''}
        score={challengerData.current?.scorePercentage}
        CPDate={challengerData.current?.ClaimedDate}
        CPPoints={challengerData.current?.ChallengePoints}
        subjectName={subjectName}
      />
      <Vs>VS</Vs>
      <Result
        position="right"
        result={currentUserResult}
        name={userInfoCache[challengerData.opponent?.UserId]?.FullName ?? ''}
        score={challengerData.opponent?.scorePercentage}
        CPDate={challengerData.opponent?.ClaimedDate}
        CPPoints={challengerData.opponent?.ChallengePoints}
        subjectName={subjectName}
        opponentStatus={opponentStatus}
      />
      {currentUserResult !== 'pending' && (
        <img
          src={starImgs[currentUserResult]}
          className={`img-right ${currentUserResult}`}
          alt=""
        />
      )}
    </Container>
  );
};

export default ResultBoard;
