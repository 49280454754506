import React from 'react';
const IconLevel = ({ className }) => {
  return (
    <svg
      id="Group_33136"
      data-name="Group 33136"
      xmlns="http://www.w3.org/2000/svg"
      width="73.072"
      height="32.411"
      viewBox="0 0 73.072 32.411"
      className={className}
    >
      <rect
        id="Rectangle_8421"
        data-name="Rectangle 8421"
        width="49.388"
        height="32.411"
        transform="translate(11.768)"
        fill="#f8a200"
      />
      <text
        id="Lvl"
        transform="translate(36.463 14.354)"
        fill="#fff"
        fontSize="14"
        fontFamily="Linotte-Bold"
        fontWeight="700"
      >
        <tspan x="-9.009" y="0">
          Lvl
        </tspan>
      </text>
      <text
        id="Bonus"
        transform="translate(36.463 24.977)"
        fill="#fff"
        fontSize="11"
        fontFamily="Linotte-SemiBold"
        fontWeight="600"
      >
        <tspan x="-15.02" y="0">
          Bonus
        </tspan>
      </text>
      <path
        id="Path_65266"
        data-name="Path 65266"
        d="M62.449,1.584l.017,27.022h11.9L70,14.567,74.342,1.582Z"
        transform="translate(-1.293 2.151)"
        fill="#f88f00"
      />
      <path
        id="Path_78091"
        data-name="Path 78091"
        d="M74.218,1.584,74.2,28.606H62.449L66.76,14.567,62.471,1.582Z"
        transform="translate(-62.449 2.151)"
        fill="#f88f00"
      />
    </svg>
  );
};
export default IconLevel;
