import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  place-items: center;
  .bg-text {
    margin-top: 0.5rem;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const ProductListContainer = styled.div`
  margin-top: 80px;
`;

export const ProductItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.07);

  .open-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .left-content {
    margin: 1rem;
  }

  .mid-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    border-right: 1px solid #c9c9c9;
    min-width: 430px;

    .open-hour-label {
      margin: 0px;
      color: rgba(112, 112, 112, 0.9);
    }

    .open-hour {
      display: flex;
      flex-direction: row;
      font-size: 18px;
    }

    .open-duration {
      margin: 0px;
    }

    .open-plan {
      margin-left: 0.2rem;
      color: rgba(112, 112, 112, 0.9);
    }
  }

  .right-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    width: 250px;
  }
`;
