import styled from 'styled-components';
import allBadge from 'constants/allBadge2';

export const StyledRow = styled.tr`
  .ranking {
    width: 10%;
  }

  .schoolname {
    width: 80%;
  }

  .cppoint {
    width: 10%;
  }
`;

export const Ranking = styled.div`
  color: ${({ currPos }) => allBadge[currPos].badgeColor};
  background-image: ${({ currPos }) => `url(${allBadge[currPos].bgImg})`};
  position: absolute;
  margin-top: -25px;
  margin-left: -5px;
  height: ${({ currPos }) => (currPos === 'default' ? '40px' : '40px')};
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  background-repeat: no-repeat;
  background-size: 50px 100%;
`;

export const NameSchoolContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  gap: 10px;

  .student-name {
    margin-bottom: 0px;
  }

  .school-img-container {
    width: 40px;
    height: 40px;
    border: 1px solid #d4ecf5;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #ffffff;
    img {
      width: 100%;
      height: auto;
    }
  }
`;
