import styled from 'styled-components';
import RibbonYellow from 'assets/img/ribbon/ribbon-yellow.svg';
import RibbonBlue from 'assets/img/ribbon/ribbon-blue.svg';

export const Container = styled.div`
  background: ${(props) =>
    props.text === 'Job Done!' ? `url(${RibbonBlue})` : `url(${RibbonYellow})`};
  width: 16rem;
  height: 4.5rem;
  background-repeat: no-repeat;
  z-index: 1;
`;

export const TextContainer = styled.div`
  h3 {
    text-shadow: ${(props) =>
      props.text === 'Job Done!' ? '2px 4px #3497d8' : '2px 2px #DC7113'};
    font-family: 'Grilled Cheese';
    color: white;
    position: absolute;
    text-align: center;
    font-size: ${(props) => (props.text === 'Job Done!' ? '32px' : '22px')};
    width: 15rem;
    margin-top: ${(props) => (props.text === 'Job Done!' ? '3px' : '10px')};
    margin-left: ${(props) => (props.text === 'Job Done!' ? '0px' : '5px')};
  }
`;
