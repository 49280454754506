import styled from 'styled-components';
import MainGreenBg from 'assets/img/assignment/assignment-view/content-green-bg.svg';
import Top from 'assets/img/assignment/note-book/note-top.png';
import Middle from 'assets/img/assignment/note-book/note-middle.png';
import Bottom from 'assets/img/assignment/note-book/note-bottom.png';

export const Container = styled.div`
  display: flex;
`;

export const Main = styled.div`
  background: url(${MainGreenBg});
  background-color: #187e86;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  .note-book-wrap {
    display: flex;
    justify-content: center;
    padding-left: 64px;
    .note-book {
      width: 900px;
      padding-top: 1rem;
      padding-left: calc(12% - 64px);
      @media (max-width: 1201px) {
        padding-left: 0px;
      }
      .notebook-top {
        height: 96px;
        padding-left: 4rem;
        padding-right: 4rem;
        padding-top: 1rem;
        background: url(${Top});
        background-repeat: no-repeat;
        background-size: 841px auto;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1201px) {
          padding-right: 7rem;
        }
        .text {
          display: flex;
          .qn-number {
            font-family: Linotte;
            margin-bottom: 0rem;
            font-size: 34px;
            font-weight: 600;
            color: #ff6701;
            margin-left: 1rem;
            @media (max-width: 955px) {
              font-size: 30px;
            }
            @media (max-width: 850px) {
              font-size: 25px;
            }
          }
          .qn-id {
            color: rgb(57, 57, 57, 0.44);
            margin-bottom: 0rem;
            align-self: center;
            margin-top: 1rem;
            margin-left: 0.5rem;
            @media (max-width: 955px) {
              font-size: 0.8rem;
            }
            @media (max-width: 850px) {
              font-size: 0.5rem;
            }
          }
        }
      }
      .notebook-middle {
        min-height: 485.5px;
        background: url(${Middle});
        background-repeat: repeat-y;
        background-size: 841px auto;
        padding-left: 4rem;
        padding-right: 4rem;
      }
      .notebook-bottom {
        height: 100px;
        background: url(${Bottom});
        background-repeat: no-repeat;
        background-size: 841px auto;
        display: flex;
        justify-content: flex-end;
        padding-right: 5rem;
      }
    }
  }
`;

export const Error = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

export const QnButton = styled.button`
  background: ${(props) => (props.type === 'next' ? '#ff6701' : '#ffffff')};
  border: 1px solid
    ${(props) => (props.type === 'next' ? '#ffa37a' : '#9DDBE1')};
  border-radius: 22px;
  color: ${(props) => (props.type === 'next' ? '#ffffff' : '#55C5CF')};
  font-size: ${(props) => (props.type === 'next' ? '18px' : '16px')};
  height: ${(props) => (props.type === 'next' ? '40px' : '35px')};
  padding: 0rem 1rem;
  align-self: center;
  margin-right: ${(props) => props.type === 'prev' && '1rem'};
  img {
    margin-right: ${(props) => props.type === 'prev' && '5px'};
    transform: ${(props) => props.type === 'next' && 'rotate(180deg)'};
  }
  &:hover {
    background: ${(props) => (props.type === 'next' ? '#FF5801' : '#DCF8FF')};
  }
  &:disabled {
    background: #e5e5e5;
    color: #ffffff;
    border-color: #e5e5e5;
    img {
      filter: brightness(0) invert(1);
    }
  }
  @media (max-width: 955px) {
    font-size: ${(props) => (props.type === 'next' ? '16px' : '14px')};
  }
  @media (max-width: 850px) {
    font-size: ${(props) => (props.type === 'next' ? '12px' : '10px')};
    height: ${(props) => (props.type === 'next' ? '35px' : '30px')};
  }
`;
