import styled from 'styled-components';

export const StyledTitle = styled.div`
  text-align: center;
  padding: 11px 0;
  background: ${({ subject }) => (subject === 'math' ? '#3CF0FF' : '#B5FF5F')};
  width: 100%;
  margin-top: 59px;
  font-size: 20px;
  color: ${({ subject }) => (subject === 'math' ? 'white' : '#2F2F2F')};
`;
