import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #ffffff;
  width: 70%;
  height: 100%;
  padding: 20px 30px;
`;

export const ClassContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  border: 1px solid black;
  padding: 3px 0px;
  font-size: 16px;
  border: 1px solid #fac500;
  border-radius: 30px;
  user-select: none;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #ffdc5d,
    #ffffff,
    #ffc903,
    #ffc903,
    #ffc903,
    #ffc903,
    #ffca07
  );
  span {
    font-size: 18px;
  }
  .total-participants,
  .total-details {
    font-size: 14px;
  }
  .total-participants {
    span {
      font-weight: bold;
    }
  }
`;
