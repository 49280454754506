import styled from 'styled-components';

export const Container = styled.div`
  min-width: 440px;
  min-height: fit-content;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-family: Muli;
  .close-btn {
    width: 36px;
    height: 36px;
    background: #ebf6ff;
    color: #73b0e8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    font-weight: bold;
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 21px;
    cursor: pointer;
  }
  .top {
    background: #f0f4f5;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    color: #5c85aa;
    font-size: 20px;
    display: flex;
    justify-content: center;
    padding: 1rem 2rem;
    align-items: center;
    p {
      margin-bottom: 0px;
      width: 80%;
      line-height: 1.2;
      text-align: center;
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    padding: 2rem;
    .expired-info {
      color: #348018;
      font-family: Linotte;
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
    .question {
      margin-bottom: 0.5rem;
    }
    .btn-section {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      width: 80%;
      .btn {
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        outline: none;
        border-radius: 2px;
        font-family: Muli;
        &:focus {
          box-shadow: none;
        }
      }
      .orange {
        background: #ff5b00;
        color: #ffffff;
        border: 1px solid #ff5b00;
        margin-bottom: 0.8rem;
      }
      .white {
        background: #ffffff;
        color: #ff5b00;
        border: 1px solid #ffdac4;
      }
    }
  }
`;
