import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { quickAccessMenuv2 } from 'constants/quickAccessMenu';
import accessFeature, { ERROR_TYPES } from 'helpers/accessFeature';
import { fetchWrapper } from 'services/login';
import { fetchSMCStatus } from 'services/event';
import { Spinner } from 'react-bootstrap';
import { isNil } from 'ramda';
import { Container, ItemMenu } from './AccessMenu.styles';

// const Item = ({ icon, name, link }) => {
//   const { t, i18n } = useTranslation(['dashboard']);
//   return (
//     <ItemMenu name={name} lang={i18n.language}>
//       <Link
//         to={{
//           pathname: link,
//           state: {
//             quickAccess: true,
//           },
//         }}
//       >
//         <img src={icon} alt={name} className="icon" />
//         <p className="feature-name">
//           {name === 'Story Math'
//             ? t(`dashboard:cards.StoryMath.nameNoSpace`, 'Story')
//             : t(`dashboard:cards.${name}.name`, name)}
//         </p>
//       </Link>
//     </ItemMenu>
//   );
// };
const replaceKeyName = (keyName, subject) => {
  if (keyName !== 'story' || isNil(subject)) return keyName;
  return `${keyName}${subject}`.toLowerCase().trim();
};

// For Revamped Dashboard v2
const Itemv2 = ({
  icon,
  name,
  link,
  isStatic,
  setShowPopup,
  setPopupErrCode,
  setFeatureName,
  keyName,
  setOpenMenu,
}) => {
  const { t, i18n } = useTranslation(['shortcutMenu']);
  const history = useHistory();
  const { subject, currentProduct } = useSelector((state) => state.plan);
  const { timezone } = useSelector((state) => state.login);
  // Component States
  const [isLoading, setIsLoading] = useState(false);
  const localisationKeyName = replaceKeyName(keyName, currentProduct?.subject);
  const onClickHandler = async () => {
    if (isLoading) return;
    if (isStatic) {
      history.push(link);
    } else {
      let eventData;
      if (keyName === 'smc') {
        setIsLoading(true);
        try {
          eventData = await fetchWrapper(fetchSMCStatus);
          setIsLoading(false);
          setOpenMenu(false);
        } catch (err) {
          console.log(err.message);
          setIsLoading(false);
          return;
        }
      }
      const { url, error } = accessFeature({
        type: keyName,
        subject,
        featuresConfig: currentProduct?.features,
        timezone,
        eventData,
        withValidation: true,
      });
      if (!error) {
        history.push(url);
        setOpenMenu(false);
      } else if (!isNil(ERROR_TYPES[error])) {
        setPopupErrCode(error);
        const featureName = t(`shortcutMenu:${localisationKeyName}`, name);
        setFeatureName(featureName);
        setShowPopup(true);
      }
    }
  };
  return (
    <ItemMenu
      name={name}
      lang={i18n.language}
      onClick={onClickHandler}
      isLoading={isLoading}
    >
      {isLoading && <Spinner animation="border" variant="light" />}
      <img src={icon} alt={name} className="icon" />
      <p className="feature-name">
        {t(`shortcutMenu:${localisationKeyName}`, name)}
      </p>
    </ItemMenu>
  );
};

const AccessMenu = ({
  setShowPopup,
  setPopupErrCode,
  setFeatureName,
  setOpenMenu,
}) => {
  return (
    <Container data-cy="menu-list">
      {quickAccessMenuv2.map((feature) => (
        <Itemv2
          key={feature.name}
          icon={feature.icon}
          name={feature.name}
          link={feature.link}
          isStatic={feature.isStatic}
          setShowPopup={setShowPopup}
          setPopupErrCode={setPopupErrCode}
          setFeatureName={setFeatureName}
          keyName={feature.keyName}
          setOpenMenu={setOpenMenu}
        />
      ))}
    </Container>
  );
};

export default AccessMenu;
