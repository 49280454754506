import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #f2f2f2;
  height: calc(100vh - 50px);
  /* height: ${(props) =>
    props.page ? 'calc(100vh - 109px)' : 'calc(100vh - 50px)'}; */
  margin-top: 50px;
  width: ${(props) => (props.collapse ? '5%' : props.page ? '13%' : '12%')};
  @media (max-width: 1200px) {
    width: 76.8px;
  }
`;
