import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from 'components/ParentPortal/Card';
import DetailInfo from 'components/ParentPortal/DetailInfo';
import DropdownMenu from 'components/Shared/DropdownMenu';
import Spinner from 'components/Shared/Spinner';
import {
  updateParentProfile,
  getParentDetails,
  reset,
} from 'store/parent/parentSettingsSlice';
import { isEmpty, isNil } from 'ramda';
import { PhoneNumberEdit } from './ParentAccountDetail.styles';
import { StyledUpdateError } from './ChildAccountDetail.styles';
const roleDrop = [
  { name: 'Mother', id: '1' },
  { name: 'Father', id: '2' },
  { name: 'Guardian', id: '3' },
];

const ParentAccountDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { uuid, isUpdateError, parentProfileUpdate } = useSelector(
    (state) => state.parentSettings
  );
  const { parentDetails, countries, isLoading } = useSelector(
    (state) => state.parentSettings
  );
  const { userID } = useSelector((state) => state.login);
  const { countryList } = useSelector((state) => state.lookup);

  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [roleId, setRoleId] = useState('');
  const [editName, setEditName] = useState(false);

  const [email, setEmail] = useState('');
  const [editEmail, setEditEmail] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');
  // const [countryISO, setCountryISO] = useState('');
  const [editPhoneNumber, setEditPhoneNumber] = useState(false);

  const [password, setPassword] = useState('');
  const [editPassword, setEditPassword] = useState(false);

  const [currentlyUpdate, setCurrentlyUpdate] = useState(null);

  const filterCurrentCountry =
    countryCode &&
    countryList &&
    countryList.filter((item) => item.PhoneCode === countryCode)[0];

  const validation = (data, id) => {
    const nameFormat = /^[0-9a-zA-Z\s]+$/;
    const phoneNumberFormat = /^[0-9\b]+$/;
    // eslint-disable-next-line
    const mailformat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (isEmpty(data) === true) {
      return 'This is a required field';
    }
    if (id === 1) {
      if (name && !name.match(nameFormat)) {
        return 'Your name should not contain special characters';
      }
    }
    if (id === 2) {
      if (email && !email.match(mailformat)) {
        return 'Invalid email address';
      }
    }
    if (id === 3) {
      if (phoneNumber && !phoneNumber.match(phoneNumberFormat)) {
        return 'Your phone number should contain numbers only';
      }
    }
    return '';
  };

  const data = [
    {
      id: 1,
      title: 'Name',
      data: name,
      setData: setName,
      edit: editName,
      setEdit: setEditName,
      validation: validation(name, 1),
    },
    {
      id: 2,
      title: 'Email',
      data: email,
      setData: setEmail,
      edit: editEmail,
      setEdit: setEditEmail,
      type: 'email',
      validation: validation(email, 2),
    },
    {
      id: 3,
      title: 'Phone Number',
      data: isNil(phoneNumber) ? '-' : `+${countryCode} ${phoneNumber}`,
      setData: setPhoneNumber,
      edit: editPhoneNumber,
      setEdit: setEditPhoneNumber,
      validation: validation(phoneNumber, 3),
    },
    {
      id: 4,
      title: 'Current Password',
      data: password,
      setData: setPassword,
      edit: editPassword,
      setEdit: setEditPassword,
      editable: true,
    },
  ];

  useEffect(() => {
    setName(parentDetails?.fullName);
    setRole(parentDetails?.Relationship);
    setRoleId(parentDetails?.RelationshipId);
    setEmail(parentDetails?.emailAddress);
    setPhoneNumber(parentDetails?.rawNumber);
    setCountryCode(parentDetails?.countryPhonecode);
    setPassword('******');
  }, [parentDetails, countries]);

  useEffect(() => {
    if (parentProfileUpdate) {
      dispatch(getParentDetails(userID));
      // eslint-disable-next-line no-unused-expressions
      currentlyUpdate?.setEdit(false);
    }
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line
  }, [dispatch, parentProfileUpdate, userID, currentlyUpdate]);

  const dropdownValue = (value) => {
    setCountry(value.NiceName);
    const str = `+${value.PhoneCode}`;
    setCountryCode(str);
    // setCountryISO(value.ISO3);
  };

  const changeNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    } else {
      setPhoneNumber(e.target.value);
    }
  };

  const updateProfile = () => {
    const rawBody = {
      Id: uuid,
      Name: name,
      Email: email,
      RelationshipId: roleId,
      Relationship: role,
      PhoneCode: countryCode,
      Phone: phoneNumber,
    };
    dispatch(updateParentProfile({ parentId: uuid, rawBody }));
  };

  return (
    <>
      {isLoading && <Spinner />}
      {isUpdateError !== null && (
        <StyledUpdateError>Failed to update. {isUpdateError}</StyledUpdateError>
      )}
      {!isLoading &&
        parentDetails &&
        data.map((item) => (
          <Card
            key={item.id}
            lastItem={item.id === data.length}
            firstItem={item.id === 1}
          >
            <DetailInfo
              id={item.id}
              bold={item.id === 1}
              title={item.title}
              defaultValue={item.data}
              data={item.data}
              type="parent"
              editable={item.editable}
              onChange={(e) => item.setData(e.target.value)}
              edit={item.edit}
              inputType={item.type}
              validation={item.edit && item.validation}
              cancelClick={() => {
                item.setEdit(false);
                if (item.id === 1) {
                  setName(parentDetails.ParentName);
                  setRole(parentDetails.Relationship);
                  setRoleId(parentDetails.RelationshipId);
                }
                if (item.id === 2) {
                  setEmail(parentDetails.Email);
                }
                if (item.id === 3) {
                  setPhoneNumber(parentDetails?.Phone);
                }
              }}
              updateClick={() => {
                updateProfile();
                setCurrentlyUpdate(item);
              }}
              editClick={() => {
                if (item.id === 4) {
                  history.push('/parent/settings/change-password/parent');
                } else {
                  item.setEdit(true);
                }
              }}
            >
              {item.id === 1 && (
                <EditRole role={role} setRole={setRole} setRoleId={setRoleId} />
              )}
              {item.id === 3 && (
                <EditPhoneNumber
                  dropdownValue={dropdownValue}
                  changeNumber={changeNumber}
                  country={country}
                  countryCode={countryCode}
                  phoneNumber={phoneNumber}
                  countryList={countryList}
                  currentCountry={filterCurrentCountry}
                />
              )}
            </DetailInfo>
          </Card>
        ))}
    </>
  );
};

const EditRole = ({ role, setRole, setRoleId }) => {
  const setValue = (value) => {
    setRole(value.name);
    setRoleId(parseInt(value.id));
  };

  return (
    <div>
      <DropdownMenu
        selectedValue={role}
        values={roleDrop}
        setValue={setValue}
        valueKey="name"
        name="roleInput"
        width="50%"
        portal="parent"
        indexKey={role.id}
      />
    </div>
  );
};

const EditPhoneNumber = ({
  dropdownValue,
  changeNumber,
  country,
  countryCode,
  phoneNumber,
  countryList,
  currentCountry,
}) => {
  return (
    <PhoneNumberEdit className="phone-number">
      <DropdownMenu
        selectedValue={country === '' ? currentCountry.NiceName : country}
        values={countryList}
        setValue={dropdownValue}
        valueKey="NiceName"
        indexKey="ISO3"
        name="countryInput"
        width="fit-content"
      />
      <input className="country-code input" value={countryCode} disabled />
      <input
        className="number input"
        value={phoneNumber}
        placeholder="Parent’s Mobile Phone"
        onChange={(e) => changeNumber(e)}
      />
    </PhoneNumberEdit>
  );
};

export default ParentAccountDetail;
