import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SuperheroHeader from 'components/Leaderboard/Superhero/SuperheroHeader';
import Category from 'components/Leaderboard/Category';
import StudentList from 'components/Leaderboard/StudentList';
import Spinner from 'components/Shared/Spinner';
import { isEmpty } from 'ramda';

import {
  getSuperheroLeaderboardData,
  getSuperheroTopFiftyLeaderboardData,
} from 'store/superheroLeaderboard/superheroLeaderboardSlice';
import { Container, NoParticipant } from './Superhero.styles';

const Superhero = () => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { userID } = useSelector((state) => state.login);
  const { superheroLeaderboard, superheroLeaderboardLoading } = useSelector(
    (state) => state.superheroLeaderboard
  );
  const [activeCategory, setActiveCategory] = useState(5);
  const [isTopFifty, setIsTopFifty] = useState(false);
  const [showBreakdownData, setShowBreakdownData] = useState({});
  const [studentData, setStudentData] = useState([]);

  useEffect(() => {
    dispatch(getSuperheroLeaderboardData());
  }, [dispatch]);

  // to check if user is in top 50
  useEffect(() => {
    if (!isEmpty(superheroLeaderboard)) {
      const isUser = superheroLeaderboard.find((stu) => stu.UserId === userID);
      if (isUser) {
        setIsTopFifty(isUser.LeaderBoardRank <= 50);
      } else {
        setIsTopFifty(true);
      }
      setStudentData(superheroLeaderboard);
    } else {
      setStudentData([]);
    }
  }, [superheroLeaderboard, userID]);

  const getTopFifty = () => {
    setIsTopFifty(false);
    dispatch(getSuperheroTopFiftyLeaderboardData());
  };

  const toggleBreakdownData = (index) => {
    const tempData = { ...showBreakdownData };
    tempData[index] = !tempData[index];
    setShowBreakdownData(tempData);
  };

  return (
    <Container>
      <SuperheroHeader />
      <Category
        challengeMode="superhero"
        showTopFifty
        active={activeCategory}
        getTopFiftyLists={getTopFifty}
      />
      {superheroLeaderboardLoading && <Spinner />}
      {studentData.length > 0 && (
        <StudentList
          challengeMode="superhero"
          reducer="superheroLeaderboard"
          data={studentData}
          isTopFifty={isTopFifty}
          active={activeCategory}
          setActive={setActiveCategory}
          showAllStudents={showBreakdownData}
          toggleAllStudent={toggleBreakdownData}
        />
      )}
      {!superheroLeaderboardLoading && studentData.length === 0 && (
        <NoParticipant>
          {t(
            'leaderboard:hodBanner.noParticipant',
            'No participants at the moment.'
          )}
        </NoParticipant>
      )}
    </Container>
  );
};
export default Superhero;
