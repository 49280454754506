import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';

import { activityStatus } from 'constants/kooClass';
import {
  getRetryActivity,
  startActivity,
  resetRetry,
  getKooClassSolutions,
} from 'store/kooClass/kooClassSlice';

import KooClassPictureType from 'components/KooClass/KooClassPictureType';
import DoAndLearnDetails from 'components/KooClass/DoAndLearnDetails';
import ConfirmModal from 'components/KooClass/ConfirmModal';
import PopupModal from 'components/Shared/PopupModal';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';

const KooClassStartPage = ({ title, courseId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['activities']);
  const { lessonId, topicId } = useParams();
  const {
    currentActivity,
    currentActivityLoading,
    retryActivity,
    isRetryLoading,
    isRetryErr,
    startKooClassInfo,
    isKooClassOpening,
    error,
    isKooClassSolutionsError,
  } = useSelector((state) => state.kooClass);
  const [showModal, setShowModal] = useState(false);

  const currentStatus =
    currentActivity && activityStatus[currentActivity.status];

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const activityId = query.get('activityId');
  const activityType = query.get('activityType');

  const hideModal = () => {
    setShowModal(false);
    if (!isNil(retryActivity) || !isNil(isRetryErr)) {
      dispatch(resetRetry());
    }
  };

  const showLoading = isRetryLoading || isKooClassOpening;

  useEffect(() => {
    if (!isNil(retryActivity)) {
      dispatch(startActivity(activityId));
    }
    // eslint-disable-next-line
  }, [dispatch, retryActivity]);

  useEffect(() => {
    if (!isNil(startKooClassInfo) && !isNil(retryActivity)) {
      history.push(
        `/kooClass/open/${activityId}/${topicId}/${lessonId}/${activityType}?courseId=${courseId}`
      );
    }
    // eslint-disable-next-line
  }, [startKooClassInfo, retryActivity]);

  const reloadStartActivity = () => {
    if (!isNil(isKooClassSolutionsError)) {
      dispatch(getKooClassSolutions(activityId));
    }
    if (!isNil(error)) {
      dispatch(startActivity(activityId));
    }
  };

  const errorMsg = () => {
    if (!isNil(isKooClassSolutionsError)) {
      return isKooClassSolutionsError;
    }
    if (!isNil(error)) {
      return error;
    }
    if (!isNil(isRetryErr)) {
      return isRetryErr;
    }
  };

  const clearErr = () => {
    if (!isNil(isRetryErr)) {
      dispatch(resetRetry());
    }
  };

  return (
    <>
      <PopupModal show={showModal} backdrop="static" centered>
        <ConfirmModal
          text={t(
            'activities:doAndLearn.popUpModal.desc',
            'Do you want to retry this activity?'
          )}
          loading={showLoading}
          closeHandler={hideModal}
          yesHandler={() => {
            dispatch(getRetryActivity(activityId));
            setShowModal(false);
          }}
          noHandler={hideModal}
        />
      </PopupModal>
      <KooClassPictureType
        type="kooClass"
        title={t(
          `activities:activityName.${title?.toLowerCase().trim()}`,
          title
        )}
        margin="10%"
        completed={currentStatus === 'Completed'}
      />
      {currentActivityLoading && <Spinner />}
      {!currentActivityLoading && currentActivity && (
        <>
          <DoAndLearnDetails
            status={currentStatus}
            totalScore={70}
            setShowModal={setShowModal}
            courseId={courseId}
          />
        </>
      )}
      {(!isNil(error) ||
        !isNil(isKooClassSolutionsError) ||
        !isNil(isRetryErr)) && (
        <ErrorModal
          errorMessage={errorMsg()}
          reloadAction={
            (!isNil(error) || !isNil(isKooClassSolutionsError)) &&
            reloadStartActivity
          }
          closeHandler={clearErr}
        />
      )}
    </>
  );
};

export default KooClassStartPage;
