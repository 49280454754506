import React from 'react';
import { Container, OuterContainer } from './Button.styles';

const Button = ({
  children,
  className,
  borderRadius,
  onClick,
  fontSize,
  fontWeight,
  textShadow,
  width,
  marginBottom,
  margin,
  padding,
  isDisabled,
  variant,
  disableColor,
  type,
  dataCy,
  style,
}) => {
  return (
    <OuterContainer width={width} disableColor={disableColor} style={style}>
      <Container
        borderRadius={borderRadius}
        onClick={onClick}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textShadow={textShadow}
        width={width}
        marginBottom={marginBottom}
        margin={margin}
        padding={padding}
        disabled={isDisabled}
        className={[className, isDisabled ? 'disable' : variant]}
        type={type}
        data-cy={dataCy}
      >
        {children}
      </Container>
    </OuterContainer>
  );
};

export default Button;
