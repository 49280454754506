import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/top-brain/header2.svg';

export const Container = styled.div`
  height: 350px;
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  position: relative;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 2%;
  z-index: 1;
`;

export const CenterBar = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  position: relative;
  padding-top: 20px;
  height: 100%;
  @media (max-width: 1281px) {
    gap: 1rem;
  }
  .classic-images {
    img {
      margin-right: 20px;
      @media (max-width: 1281px) {
        width: 300px;
      }
    }
  }
  .classic-head {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .title {
      display: flex;
      align-items: center;
      gap: 1rem;
      img {
        @media (max-width: 1281px) {
          width: 310px;
        }
      }
    }
    .scoring-period {
      background: rgb(255, 255, 255, 0.28);
      border-radius: 12px;
      color: #ffffff;
      font-weight: 600;
      padding: 0.8rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.5rem;
      .text {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        img {
          margin-top: 4px;
          width: 40px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const SubTitle = styled.div`
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  align-self: flex-end;
  position: absolute;
  bottom: 1rem;
`;
