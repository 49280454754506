/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isNil, isEmpty } from 'ramda';
import DropdownMenu from 'components/Shared/DropdownMenu';
import BackButton from 'components/Shared/BackButton';
import LessonSwiper from 'components/KooClass/LessonSwiper';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';

import {
  getTopicsByCourseId,
  getLessonsByTopic,
  setLastVisitedTopic,
  resetErr,
} from 'store/kooClass/kooClassSlice';

import Scroll from 'assets/img/kooclass/lessons/scroll.svg';
import ToggleIcon from 'assets/img/kooclass/lessons/dropdown-arrow.svg';
import { featureToggle } from 'constants/index';
import useQuery from 'helpers/useQuery';
import getCourseId from 'helpers/Intermediate/getCourseId';
import PopupModal from 'components/Shared/PopupModal';
import NotEnoughKokoModal from 'components/Shared/NotEnoughKokoModal';
import { Container, Header, Body, NoLesson } from './Lessons.styles';

const Lessons = () => {
  const { t } = useTranslation(['kooClassLessons']);
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const courseIdParam = query.get('courseId');
  const selectedLessonIdParam = query.get('selectedLessonId');
  const { topicId } = useParams();

  const {
    courses,
    topics,
    lessons,
    lessonsLoading,
    lessonsErr,
    unlockErr,
  } = useSelector((state) => state.kooClass);
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const { products } = useSelector((state) => state.plan);

  const [topic, selectTopic] = useState({});
  const [currentScroll, setCurrentScroll] = useState(0);
  const [allScroll, setAllScroll] = useState(0);

  const mathCourseId = courses && courses[0] && courses[0].id;
  const productCourseId = isNil(products)
    ? null
    : getCourseId(
        products.filter(
          (product) =>
            product.nonExpiredSubscriptionProducts.length > 0 &&
            product.nonExpiredSubscriptionProducts[0].features.find(
              (feature) => feature.feature.toLowerCase().trim() === 'kooclass'
            ).parameters.IsEnabled
        )[0] ?? {}
      );
  const courseId = featureToggle.science
    ? courseIdParam ?? productCourseId
    : mathCourseId;

  // call topics when level & courseId is true and topics are not available
  useEffect(() => {
    if (defaultLevel != null && courseId && isEmpty(topics)) {
      const params = {
        courseId,
        currentLevel: parseInt(defaultLevel, 0),
      };
      dispatch(getTopicsByCourseId(params));
    }
    // eslint-disable-next-line
  }, [dispatch, courseId, defaultLevel]);

  // set current topic match with the url topicId
  useEffect(() => {
    if (!isNil(topicId) && topics.length !== 0) {
      const selectedTopic = topics.find((item) => item.id === topicId);
      selectTopic(selectedTopic);
    }
  }, [topicId, topics]);

  // call lessons for selected topic
  useEffect(() => {
    if (!isEmpty(topic)) {
      dispatch(getLessonsByTopic(topicId));
    }
    // eslint-disable-next-line
  }, [topic]);

  // set current & all scroll according to lessons
  useEffect(() => {
    if (lessons && lessons.length > 0) {
      const currentScroll = lessons.reduce(
        (acc, item) => acc + item.currentUserPoint,
        0
      );
      const totalScroll = lessons.reduce(
        (acc, item) => acc + item.maxpoints,
        0
      );
      setAllScroll(totalScroll);
      setCurrentScroll(currentScroll);
    }
  }, [lessons]);

  const onTopicChange = (val) => {
    dispatch(setLastVisitedTopic(val));
    selectTopic(val);
    history.push(`/kooClass/lessons/${val.id}?courseId=${courseId}`);
  };

  const reloadLesson = () => {
    dispatch(getLessonsByTopic(topicId));
  };

  const checkInsufficientKoKoError = () => {
    return unlockErr === 'KC002';
  };

  return (
    <Container>
      <Header>
        <div className="back-btn">
          <BackButton
            backText={t('kooClassLessons:header.back', 'Back')}
            btnBgColor="#5A5ADE"
            padding="5px 20px"
            onClick={() =>
              history.replace(`/dashboard?view=kooclass&courseid=${courseId}`)
            }
          />
        </div>
        <div className="lesson-header">
          <span className="lesson-selection">
            {t('kooClassLessons:header.changeTopic', 'Change Topic')}
          </span>
          {topics.length > 0 && topic && (
            <DropdownMenu
              selectedValue={topic.name}
              valueKey="name"
              values={topics.filter((topic) => topic.totalNumberOfLessons > 0)}
              setValue={onTopicChange}
              width="auto"
              toggleIcon={<img src={ToggleIcon} alt="" />}
              indexKey
            />
          )}
        </div>
        <div className="total-scroll">
          <div className="sc-topic">
            {t('kooClassLessons:header.topicScroll', 'Topic Scroll')}
          </div>
          <div className="topic-name">{topic ? topic.name : ''}</div>
          <div className="sc-total">
            <span className="sc-no">{currentScroll} </span>
            {t('kooClassLessons:header.outOf', 'out of')} {allScroll}
          </div>
          <img src={Scroll} alt="scroll" />
        </div>
      </Header>
      <Body>
        {lessonsLoading ? (
          <Spinner />
        ) : lessons && lessons.length > 0 ? (
          <LessonSwiper
            data={lessons}
            courseId={courseId}
            selectedLessonId={selectedLessonIdParam}
          />
        ) : !isNil(lessonsErr) ? (
          <ErrorModal errorMessage={lessonsErr} reloadAction={reloadLesson} />
        ) : (
          <NoLesson>No Lessons</NoLesson>
        )}
      </Body>
      {!isNil(unlockErr) &&
        (checkInsufficientKoKoError() ? (
          <PopupModal show backdrop="static">
            <NotEnoughKokoModal yesHandle={() => dispatch(resetErr())} />
          </PopupModal>
        ) : (
          <ErrorModal
            errorMessage={unlockErr}
            closeHandler={() => dispatch(resetErr())}
          />
        ))}
    </Container>
  );
};

export default Lessons;
