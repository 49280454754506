import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${(props) =>
    props.isFeatureReady === false ? 'not-allowed' : 'pointer'};
  color: white;
  font-size: ${(props) => (props.type === 'left' ? '1.125rem' : '1rem')};
  margin-bottom: 1rem;
  opacity: ${(props) => (props.isFeatureReady === false ? '0.29' : '1')};
  .value {
    font-size: 1.2rem;
    @media (min-width: 2560px) {
      font-size: 2rem;
    }
  }
  .reload {
    text-decoration: underline;
  }
  span.label {
    margin-top: ${(props) => (props.type === 'left' ? '0.5rem' : '0')};
    font-weight: 600;
    @media (min-width: 2560px) {
      font-size: 35px;
    }
    @media (max-width: 1161px) {
      font-size: 14px;
      width: 100%;
      text-align: center;
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      display: ${({ type }) => type === 'left' && 'none'};
      font-size: 12px;
    }
  }
  span.claimed {
    font-size: 0.5rem;
    img {
      margin-right: 0.25rem;
    }
    @media (min-width: 2560px) {
      font-size: 1.5rem;
      img {
        width: 20px;
      }
    }
  }
  @media (min-width: 2560px) {
    min-width: 80%;
  }

  @media (min-width: 1920px) {
    font-size: 1.375rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    ${({ type, name }) => {
      if (type === 'right') {
        return css`
          display: flex;
          flex-direction: row;
          margin-right: 35px;
          .value {
            order: 3;
            font-weight: bold;
          }
          .label {
            order: 1;
          }
          ${name === 'Daily Bonus' &&
          css`
            margin-right: 0;
            .label {
              display: none;
            }
          `}
        `;
      } else if (type === 'left') {
        return css`
          margin-bottom: 0;
        `;
      }
    }}
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    ${({ type, name }) => {
      if (type === 'right') {
        return css`
          margin-bottom: 0;
          margin-right: ${name === 'Daily Bonus' ? 0 : '30px'};
          .value {
            font-size: 18px;
          }
        `;
      } else if (type === 'left') {
        return css`
          width: 45%;
        `;
      }
    }}
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    ${({ type }) => {
      if (type === 'right') {
        return css`
          margin-right: 0;
          .value {
            font-size: 12px;
          }
        `;
      }
    }}
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.name === 'Daily Bonus' ? '50px' : '58.06%')};
  background: ${(props) =>
    props.type === 'left'
      ? props.theme[props.selectedPlan].sidePanelButtonBg
      : null};
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  :hover {
    transform: scale(1.15);
    background: ${(props) =>
      props.type === 'left'
        ? props.theme[props.selectedPlan].sidePanelButtonHoverBg
        : null};
  }
  img {
    width: ${(props) =>
      props.name === 'Daily Bonus'
        ? '50px'
        : props.type === 'right'
        ? '55px'
        : '90%'};
  }
  @media (min-width: 2560px) {
    height: 100px;
    margin: 15px;
    img {
      width: ${(props) => (props.name === 'Daily Bonus' ? '150px' : '')};
    }
  }
  @media (max-width: 1161px) {
    img {
      width: 50px;
      ${({ name }) => {
        if (name === 'Story Math') {
          return css`
            width: 62px;
          `;
        }
        if (name === 'Brain Games') {
          return css`
            width: 58px;
          `;
        }
      }}
    }
    
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    ${({ type, name }) => {
      if (type === 'right') {
        return css`
          order: 2;
          width: ${name === 'Daily Bonus' ? '30px' : '30%'};
          img {
            width: 30px;
          }
        `;
      } else if (type === 'left') {
        return css`
          background: none;
          &:hover {
            background: none;
          }
        `;
      }
    }}
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    ${({ type }) => {
      if (type === 'left') {
        return css`
          width: 100%;
          img {
            width: 10.5vw;
            ${({ name }) => {
              if (name === 'Story Math') {
                return css`
                  width: 13vw;
                `;
              }
              if (name === 'Brain Games') {
                return css`
                  width: 12vw;
                `;
              }
            }}
          }
        `;
      }
    }}

`;

export const Pulse = styled.div`
  background: rgba(255, 255, 255, 0);
  border-radius: 50%;
  margin: 10px;
  height: 50px;
  width: 50px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  transform: ${(props) => props.active && 'scale(1)'};
  animation: ${(props) => props.active && 'pulse 2s infinite'};
  display: flex;
  align-items: center;
  @media (min-width: 2560px) {
    height: 100px;
    width: 100px;
    transform: ${(props) => props.active && 'scale(1.5)'};
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    height: 30px;
    width: 30px;
    margin: 15px;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
`;
