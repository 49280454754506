import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { fetchWrapper } from 'services/login';
import updatePreferences from 'services/premiumSchoolPreferences';
import { resetNotification } from 'store/notification/notificationSlice';

import { setPlan, setSubject, setCurrentProduct } from 'store/plan/planSlice';
import { setIsExpired } from 'store/dashboard/studentDetailsSlice';

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

import { ReactComponent as School } from 'assets/img/plan-toggle/icon-school.svg';
import { ReactComponent as Premium } from 'assets/img/plan-toggle/icon-premium.svg';

import Button from 'components/Shared/Button';

import { Container, Action } from './SwitchModalV2.styles';

const SwitchModalV2 = ({
  selectedPlanSubject,
  setModal,
  setShowLoader,
  setHasSeenLoader,
  selectedProduct,
}) => {
  const { t } = useTranslation(['common', 'switchPlan']);
  const dispatch = useDispatch();
  const history = useHistory();
  const confirmHandler = () => {
    setModal(false);
    setShowLoader(true);
    setHasSeenLoader(false);
    dispatch(resetNotification());
    dispatch(setIsExpired(false));
    dispatch(setPlan(selectedProduct.productType.toLowerCase()));
    dispatch(setSubject(selectedProduct.subject.toLowerCase()));
    dispatch(setCurrentProduct(selectedProduct));
    fetchWrapper(updatePreferences, selectedProduct.productId);
    history.push(
      `/dashboard?subject=${selectedProduct.subject.toLowerCase()}&plan=${selectedProduct.productType.toLowerCase()}`
    );
  };

  return (
    <Container>
      <p className="desc">
        {t('switchPlan:switchV2.title', 'You are going to switch to')}
      </p>
      <div className="objective">
        {selectedPlanSubject.plan === 'school' ? <School /> : <Premium />}
        {selectedPlanSubject.plan !== 'school' && (
          <span>
            {t(
              `common:subjects.${selectedPlanSubject.subject}`,
              capitalizeFirstLetter(selectedPlanSubject.subject)
            )}
          </span>
        )}
      </div>
      <Action>
        <Button
          variant="secondary"
          padding="0.625rem 2.31rem"
          fontSize="1rem"
          onClick={() => setModal(false)}
        >
          {t('common:cancel', 'Cancel')}
        </Button>
        <Button
          variant="orange4"
          padding="0.625rem 2.31rem"
          fontSize="1rem"
          onClick={confirmHandler}
        >
          {t('common:confirm', 'Confirm')}
        </Button>
      </Action>
    </Container>
  );
};

export default SwitchModalV2;
