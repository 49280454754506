import styled from 'styled-components';

export const TopHeader = styled.div`
  background-color: #222222;
  width: 100%;
  background-size: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemHeader = styled.div`
  flex: 1;
  align-items: center;
  padding: 8px 0 8px;
`;

export const TimerWrap = styled.div`
  line-height: 24px;
  font-size: 30px;
  color: #fff;
  font-weight: normal;
  padding: 8px 0 0;
  display: flex;
  justify-content: space-around;
`;

export const TimerRow = styled.div``;

export const TimerInfo = styled.div`
  font-size: 14px;
  font-style: normal;
  font-family: Arial;
  font-weight: normal;
  margin-bottom: 5px;
  color: #555555;
`;

export const TimerLeft = styled.p`
  color: #d0852d;
`;

export const Time = styled.span``;

export const TimerInfoSecondary = styled.span`
  font-size: 12px;
  margin-left: ${(props) => (props.desc ? '10px' : 0)};
  color: #ffffff;
`;

export const BackButton = styled.a`
  cursor: pointer;
`;
