import styled from 'styled-components';
import KoKoBg from 'assets/img/events/claimed-cp-bg.png';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledButton = styled.button`
  font-size: 22px;
  font-weight: 600;
  color: white;
  border: none;
  background: #ff6701;
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  width: 100%;
  :hover {
    background: #ff5801;
  }
`;

const StyledContainer = styled.div`
  width: 277px;
  min-height: 273px;
  background: #ffd753;
  border: solid 4px #ffc52c;
  border-radius: 18px;
  background-image: url(${KoKoBg});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center -2rem;
  text-align: center;
  position: relative;
  bottom: 2rem;
  padding: 2rem 2.5rem;
  .icon {
    margin-bottom: 1rem;
    .cp-amt {
      font-size: 19px;
      font-weight: 600;
      span {
        font-size: 10px;
      }
    }
  }
  .claim-text {
    color: #745000;
    span {
      font-size: 0.5rem;
    }
  }
`;

export default StyledContainer;
