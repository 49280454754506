import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWeeks, fetchGroups, fetchFriends } from 'services/bff';
import { fetchWrapper } from 'services/login';

// get weeks
export const getWeeks = createAsyncThunk('bff/getWeeks', async () => {
  try {
    const res = await fetchWrapper(fetchWeeks);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Get all weeks failed');
  }
});

// get all groups
export const getGroups = createAsyncThunk('bff/getGroups', async (params) => {
  try {
    const res = await fetchWrapper(fetchGroups, params);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Get all groups failed');
  }
});

// get friends by user
export const getFriendsList = createAsyncThunk(
  'bff/getFriendsList',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchFriends, params);
      const obj = {
        [params.userId]: res,
      };
      return obj;
    } catch (error) {
      throw new Error(error?.message ?? 'Get friends by user failed');
    }
  }
);

const initialState = {
  weeks: [],
  weeksLoading: false,
  weeksError: null,
  groupsData: [],
  isLoading: false,
  isError: null,
  groupStudents: {},
  groupStudentsLoading: false,
  groupStudentsError: null,
};

const bffSlice = createSlice({
  name: 'bff',
  initialState,
  reducers: {},
  extraReducers: {
    [getWeeks.pending]: (state) => {
      state.weeksLoading = true;
      state.weeks = [];
      state.weeksError = null;
    },
    [getWeeks.fulfilled]: (state, action) => {
      state.weeksLoading = false;
      state.weeks = action.payload;
      state.weeksError = null;
    },
    [getWeeks.rejected]: (state, action) => {
      state.weeksLoading = false;
      state.weeks = [];
      state.weeksError = action.error.message;
    },
    [getGroups.pending]: (state) => {
      state.isLoading = true;
      state.groupsData = [];
      state.isError = null;
    },
    [getGroups.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.groupStudents = {};
      state.groupsData = action.payload;
      state.isError = null;
    },
    [getGroups.rejected]: (state, action) => {
      state.isLoading = false;
      state.groupsData = [];
      state.isError = action.error.message;
    },
    [getFriendsList.pending]: (state) => {
      state.groupStudentsLoading = true;
      state.groupStudentsError = null;
    },
    [getFriendsList.fulfilled]: (state, action) => {
      state.groupStudentsLoading = false;
      state.groupStudents = { ...state.groupStudents, ...action.payload };
      state.groupStudentsError = null;
    },
    [getFriendsList.rejected]: (state, action) => {
      state.groupStudentsLoading = false;
      state.groupStudentsError = action.error.message;
    },
  },
});

const { reducer } = bffSlice;
export default reducer;
