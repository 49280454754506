import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorModal from 'components/Shared/ErrorModal';
import Header from 'components/QnView/QnViewHeader';
import ChallengeCard from 'components/DailyChallenge/ChallengeCard';
import Spinner from 'components/Shared/Spinner';
import BackButton from 'components/Shared/BackButton';
import DCText from 'assets/img/dailychallenge/daily-challenge-text.svg';
import {
  getCurrentDailyChallenge,
  getDailyChallengeStatus,
  getSuperHeroChallengeStatus,
  getSuperHeroChallengeDataWithoutImage,
} from 'store/dailyChallenge/dailyChallengeSlice';
import { isNil } from 'ramda';
import { submissionResultIDs } from 'constants/index';
import {
  dailyChallengeList,
  superHeroChallengeStatusIDs,
} from 'constants/dailyChallenge';
import { getCurrentDateTimeInTimezone } from 'pages/Events/EventList';

import { Container, Main } from './ChallengesList.styles';

// HELPERS
export const checkErrMsgIsClosed = (
  getStatusErr,
  getCurrentDailyChallengeErr
) => {
  const closedErrMsgs = [
    'Please come back between 06:00 AM and 10:00 PM',
    'Current Request is restricted in the following days Sunday',
  ];
  const isGetStatusErrClosed = closedErrMsgs.find(
    (err) => err === getStatusErr
  );
  const isGetCurrentDailyChallengeErrClosed = closedErrMsgs.find(
    (err) => err === getCurrentDailyChallengeErr
  );
  if (
    !isNil(isGetStatusErrClosed) ||
    !isNil(isGetCurrentDailyChallengeErrClosed)
  ) {
    return true;
  }
  return false;
};
export const isSundayBetween6amTo10pm = (timezone) => {
  const currentDateTime = getCurrentDateTimeInTimezone(timezone);
  const smcStartTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 6,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const smcEndTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 22,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return (
    currentDateTime.isoWeekday() === 7 &&
    currentDateTime.isBetween(smcStartTime, smcEndTime)
  );
};

export const parseSuperHeroChallengeStatus = (
  challengeID,
  superHeroChallengeStatusData
) => {
  if (challengeID === 2) {
    if (superHeroChallengeStatusData.Supervision.IsClosed) {
      return 'closed';
    }
    return superHeroChallengeStatusIDs[
      superHeroChallengeStatusData.Supervision.Status
    ];
  }
  if (challengeID === 3) {
    if (superHeroChallengeStatusData.Superspeed.IsClosed) {
      return 'closed';
    }
    return superHeroChallengeStatusIDs[
      superHeroChallengeStatusData.Superspeed.Status
    ];
  }
  return null;
};

const ChallengesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(['challengeList', 'common', 'topicSelection']);

  // redux states
  const { plan } = useSelector((state) => state.plan);
  const {
    currDailyChallenge,
    currDailyChallengeLoading,
    isLoading,
    isErr,
    dailyChallengeStatusErr,
    currDailyChallengeErr,
    dailyChallengeStatus,
    superHeroChallengeStatus,
    superHeroChallengeIsLoading,
    superHeroChallengeIsError,
  } = useSelector((state) => state.dailyChallenge);

  const pageLoading =
    isLoading || currDailyChallengeLoading || superHeroChallengeIsLoading;
  const hasDailyChallengeError =
    isErr !== null ||
    currDailyChallengeErr !== null ||
    dailyChallengeStatusErr !== null;

  const searchAction = () => {
    dispatch(getSuperHeroChallengeDataWithoutImage());
    dispatch(getDailyChallengeStatus());
    dispatch(getSuperHeroChallengeStatus());
    dispatch(getCurrentDailyChallenge());
  };
  useEffect(() => {
    searchAction();
    // eslint-disable-next-line
  }, []);
  const searchSuperChallengeAction = () => {
    dispatch(getSuperHeroChallengeStatus());
  };
  const stillOpen = dailyChallengeStatus && !dailyChallengeStatus.IsClosed;
  // challenge status
  const challengeStatus = () => {
    const checkSubmissionModel =
      currDailyChallenge.UserQuestionSubmissions &&
      currDailyChallenge.UserQuestionSubmissions.filter(
        (item) => item.QuestionStatus !== 4
      )
        .map((item) => item.SubmissionModel)
        .findIndex(
          (data) =>
            data === null ||
            submissionResultIDs[data?.SubmissionResult] === 'wrong' ||
            submissionResultIDs[data?.SubmissionResult] === 'unknown'
        );

    const challengeComplete = () => {
      if (checkSubmissionModel === null || isNil(checkSubmissionModel)) {
        return false;
      }
      if (checkSubmissionModel !== null) {
        if (checkSubmissionModel === -1) {
          return true;
        }
      }
      return false;
    };

    if (!stillOpen) {
      return 'close';
    }
    if (stillOpen && challengeComplete()) {
      return 'completed';
    }
    if (stillOpen) {
      return 'open';
    }
  };
  const renderSuperChallenge = () => {
    if (
      !pageLoading &&
      !superHeroChallengeIsError &&
      !hasDailyChallengeError &&
      !isNil(superHeroChallengeStatus)
    ) {
      return dailyChallengeList.challenge
        .filter((chal) => chal.type !== 'daily-challenge')
        .map((item) => (
          <ChallengeCard
            key={item.id}
            challengeStatus={parseSuperHeroChallengeStatus(
              item.id,
              superHeroChallengeStatus
            )}
            challengeName={t(
              `challengeList:${item.nameTranslation}`,
              `${item.name}`
            )}
            challengeDesc={item.desc}
            challengeType={item.type}
            challengeOpenImg={item.imgOpen}
            challengeCloseImg={item.imgClose}
            challengeLink={item.link}
          />
        ));
    }
    if (
      !pageLoading &&
      !isNil(superHeroChallengeIsError) &&
      superHeroChallengeIsError !== 'Unable to get super vision image'
    ) {
      return (
        <ErrorModal
          reloadAction={searchSuperChallengeAction}
          errorMessage={superHeroChallengeIsError}
        />
      );
    }
  };
  const isFromShortCut = location.state?.quickAccess;

  return (
    <Container>
      <Header page bgColor="#FFC70B">
        <BackButton
          backText={t('topicSelection:backBtn', 'Back')}
          btnBgColor="rgb(229,153,0,0.61)"
          padding="5px 20px"
          onClick={() => {
            const destination =
              plan === 'premium'
                ? isFromShortCut
                  ? null
                  : '/dashboard/play'
                : '/dashboard';
            if (destination === null) {
              history.goBack();
              return;
            }
            history.push(destination);
          }}
          styles={{ position: 'absolute', left: '3%' }}
        />
        <img className="dc-text" src={DCText} alt="daily-challenge" />
      </Header>
      <Main>
        {pageLoading && <Spinner />}
        {!pageLoading &&
          !hasDailyChallengeError &&
          dailyChallengeList.challenge
            .filter(
              (chal) => chal.type === 'daily-challenge' && chal.subjectID === 1
            )
            .map((item) => (
              <ChallengeCard
                key={item.id}
                challengeStatus={challengeStatus()}
                challengeName={t(
                  `challengeList:${item.nameTranslation}`,
                  `${item.name}`
                )}
                challengeDesc={t('challengeList:desc', `${item.desc}`)}
                challengeType={item.type}
                challengeOpenImg={item.imgOpen}
                challengeCloseImg={item.imgClose}
                challengeLink={item.link}
                totalQns={item.totalQns ?? ''}
                cpAmt={item.cpAmt}
              />
            ))}
        {!pageLoading &&
          hasDailyChallengeError &&
          (checkErrMsgIsClosed(
            dailyChallengeStatusErr,
            currDailyChallengeErr
          ) ? (
            <>
              {dailyChallengeList.challenge
                .filter(
                  (chal) =>
                    chal.type === 'daily-challenge' && chal.subjectID !== 2
                )
                .map((item) => (
                  <ChallengeCard
                    key={item.id}
                    challengeStatus="close"
                    challengeName={t(
                      `challengeList:${item.nameTranslation}`,
                      `${item.name}`
                    )}
                    challengeDesc={item.desc}
                    challengeType={item.type}
                    challengeOpenImg={item.imgOpen}
                    challengeCloseImg={item.imgClose}
                    challengeLink={item.link}
                    totalQns={item.totalQns ?? ''}
                    cpAmt={item.cpAmt}
                  />
                ))}
            </>
          ) : (
            <ErrorModal
              reloadAction={searchAction}
              errorMessage={isErr || currDailyChallengeErr}
            />
          ))}
        {renderSuperChallenge()}
      </Main>
    </Container>
  );
};

export default ChallengesList;
