import React from 'react';
import KooBits from 'assets/img/koobits-logo.svg';
import BackIcon from 'assets/img/assignment/back-icon.svg';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';
import {
  Container,
  KoobitsLogo,
  HeaderContent,
  BackButton,
} from './QnViewHeader.styles';

const QnViewHeader = ({
  logoBgColor,
  bgColor,
  btnBgColor,
  onClick,
  backText,
  children,
  page,
  headerBg,
  hasBgImg = true,
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  return (
    <Container bgColor={bgColor} data-cy="qn-header">
      {!page && (
        <KoobitsLogo
          bgColor={logoBgColor}
          onClick={() => {
            history.replace('/dashboard');
          }}
        >
          <img src={KooBits} alt="KooBits" />
        </KoobitsLogo>
      )}
      <HeaderContent headerBg={headerBg} hasBgImg={hasBgImg}>
        {!page && (
          <BackButton data-cy="back-btn" bgColor={btnBgColor} onClick={onClick}>
            <img src={BackIcon} alt="Back" /> {isMobile ? '' : backText}
          </BackButton>
        )}
        {children}
      </HeaderContent>
    </Container>
  );
};

export default QnViewHeader;
