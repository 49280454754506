import GameScene from 'components/BrainGame/GameScene';
import TimerHeader from 'components/BrainGame/TimerHeader';
import Button from 'components/Shared/Button';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Content } from './Play.style';

const Play = () => {
  const { game, feature } = useParams();
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = null;
    };
  }, []);
  return (
    <Content>
      {feature === 'brain-game' && <TimerHeader />}
      {feature === 'kooClass' && (
        <div className="close-btn">
          <Button variant="primary" onClick={() => window.close()}>
            Close
          </Button>
        </div>
      )}
      <GameScene folder={game} iframe feature={feature} externalUrl />
    </Content>
  );
};

export default React.memo(Play);
