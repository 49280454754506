import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActivityScreen = styled.div`
  width: 100%;
  width: fill-available;
  height: calc(100vh - 59px);
  overflow-y: auto;
  flex-basis: 100%;
`;
