import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { confirmPayPalPayment } from 'services/payment';

const PaypalButton = ({ setError, paymentDone, paymentFailed }) => {
  const { subscriptionPlans } = useSelector((state) => state.payment);
  const paypalSubscriptionPlan = subscriptionPlans.find(
    (plan) => plan.PaymentProviderName === 'PayPal'
  );
  const [{ isPending }] = usePayPalScriptReducer();
  const { isLoading } = useSelector((state) => state.payment);
  return (
    <>
      {(isPending || isLoading) && (
        <Spinner animation="border" variant="warning" />
      )}
      {!isPending && !isLoading && (
        <PayPalButtons
          createSubscription={(data, actions) => {
            return actions.subscription.create({
              plan_id: paypalSubscriptionPlan.ExternalPlanId,
            });
          }}
          onApprove={async (data) => {
            try {
              const rawBody = {
                PlanId: paypalSubscriptionPlan.Id,
                PaypalSubscriptionId: data.subscriptionID,
              };
              await confirmPayPalPayment(rawBody);
              paymentDone();
            } catch (err) {
              console.log(err);
              setError(err.message);
            }
          }}
          onError={(err) => {
            console.log(err);
            paymentFailed();
          }}
          style={{
            layout: 'horizontal',
            // label: 'subscribe',
            tagline: false,
            shape: 'pill',
            height: 48,
          }}
        />
      )}
    </>
  );
};
export default PaypalButton;
