import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import QnText from 'components/Assignment/AssignmentView/QnText';
import { featureToggle } from 'constants/index';
import { Container } from './DailyChallengeNotebookHeader.styles';

const DailyChallengeNotebookHeader = () => {
  const { t } = useTranslation(['dailyChallengeQnView']);
  const {
    activeQsDailyChallenge,
    dailyChallengeSubmissions,
    currDailyChallenge,
  } = useSelector((state) => state.dailyChallenge);
  return (
    <Container>
      <div className="title">
        <QnText
          text={t('dailyChallengeQnView:question', 'Question')}
          fontFam="Grilled Cheese"
          color="#9A7C00"
          borderColor=" #ffce00"
        />
        <div className="qn-no">{activeQsDailyChallenge}</div>
      </div>
      <div className="sub-title">
        <p className="desc">
          {dailyChallengeSubmissions[activeQsDailyChallenge - 1].TopicName}
        </p>
        {featureToggle.science && currDailyChallenge.SubjectID === 1 && (
          <div>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="top"
              overlay={
                <Popover>
                  <Popover.Content>
                    {t(
                      'dailyChallengeQnView:beAwarded',
                      'Be rewarded with extra KoKos when you answer the 10 challenges correctly.'
                    )}
                  </Popover.Content>
                </Popover>
              }
            >
              <span className="info">?</span>
            </OverlayTrigger>
          </div>
        )}
      </div>
    </Container>
  );
};

export default DailyChallengeNotebookHeader;
