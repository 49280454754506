import styled from 'styled-components';
import PracticeBg from 'assets/img/dashboard/practice-bg1.svg';

export const Container = styled.div`
  background: url(${PracticeBg}) no-repeat;
  background-size: cover;
  margin-top: 52px;
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .back-btn {
    position: absolute;
    top: 4rem;
    left: 3rem;
  }
  .left-bg {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .right-bg {
    position: absolute;
    right: 0;
    width: 20%;
    bottom: 0%;
  }
  @media (max-width: 1150px) {
    .left-bg,
    .right-bg {
      width: 22%;
    }
  }
`;

export const SubCards = styled.div`
  display: flex;
  height: 450px;
  width: 800px;
  /* height: calc(100vh - 300px); */
  /* width: 60%; */
  margin: 1rem 0;
  z-index: 2;
  .right-side {
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: space-between;
  }
  @media (max-width: 950px) {
    height: 400px;
    width: 700px;
  }
  @media (max-width: 800px) {
    height: 350px;
    width: 650px;
  }
`;

export const PageHeading = styled.div`
  font-size: 2rem;
  color: #ffffff;
  text-shadow: 2px 2px 2px rgba(101, 53, 18, 0.4);
  span {
    font-size: 3rem;
  }
`;
