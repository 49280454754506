import moment from 'moment';

export const checkDateTimeInBetween = (currentDate, startDate, endDate) => {
  const startDateString = `${startDate}`;
  const endDateString = `${endDate}`;
  return moment(currentDate).isBetween(
    startDateString,
    endDateString,
    'day',
    '[]'
  );
};
