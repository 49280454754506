import styled from 'styled-components';

const Container = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .normal-price {
    margin: 20px 0;
    font-size: 14px;
  }
  .terms-note {
    display: flex;
    font-size: 12px;
    text-align: left;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    p {
      margin-left: 8px;
    }
    @media (max-width: 780px) {
      padding: 0 1.5rem;
    }
  }
  .error-msg {
    margin-top: 0.5rem;
    color: #f36e20;
  }
  .paypal-section {
    width: 50%;
    margin-top: 1rem;
    text-align: center;
  }
`;

export default Container;
