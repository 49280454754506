import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

const UI_FILE_WIDTH = 1387;
const CARD_WIDTH = 119;

export const StyledContainer = styled.div`
  width: calc(((${CARD_WIDTH} / ${UI_FILE_WIDTH}) * 100vw));
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 185px;
  }

  :hover {
    > div {
      transform: scale(0.95);
    }
  }
  > div {
    position: relative;
    > p {
      @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
        display: none;
      }
    }
  }
  p {
    color: white;
    position: absolute;
    font-size: calc((14 / ${UI_FILE_WIDTH}) * 100vw);
    font-weight: 700;
    bottom: 13%;
    margin-bottom: 0;
    left: 50%;
    line-height: 1.1;
    transform: translateX(-50%);
    width: 100%;
  }
  img {
    width: 100%;
    filter: ${({ isLoading }) => isLoading && 'brightness(0.5)'};
  }
`;
export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    border-radius: 14px;
    background-color: #404447;
    padding: 12px 22px;
  }
  p {
    margin-bottom: 0;
    font-weight: 700;
    text-align: left;
    font-family: 'Linotte';
    font-size: 14px;
  }
  .earn {
    display: flex;
    align-items: center;
  }
  img {
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }
  && .arrow::before {
    border-top-color: #404447;
  }
`;
export const StyledSpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .spinner-border {
    width: calc(0.02307137707 * 100vw);
    height: calc(0.02307137707 * 100vw);
  }
`;
export const StyledErrorContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: auto;
`;
