import styled from 'styled-components';

export const TitleStyle = styled.div`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
  img {
    margin-right: 10px;
    width: 30px;
  }
`;
