import styled from 'styled-components';
import { Container as BootstrapContainer } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledMain = styled(BootstrapContainer)`
  width: 70%;
  background-color: white;
  padding-bottom: 20px;
  margin-bottom: 50px;
  min-width: 780px;
`;
