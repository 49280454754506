import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const ToggleItem = styled.span`
  font-family: 'Arial';
  font-weight: 700;
  font-size: 0.75rem;
  min-height: 35px;
  padding: 0.25rem 0.75rem;
  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;
  border-right: ${(props) =>
    props.active !== true && props.index === 1 ? 'solid 1px #e0e0e0' : null};
  border-left: ${(props) =>
    props.active !== true && props.index === 0 ? 'solid 1px #e0e0e0' : null};
  border-radius: ${(props) =>
    props.index === 0 ? '1.5rem 0 0 1.5rem' : '0 1.5rem 1.5rem 0'};
  color: ${(props) => (props.active ? 'white' : '#a7adba')};
  background: ${(props) => (props.active ? '#427fe2' : '#fcfcfc')};
  text-align: center;
  cursor: pointer;
  p {
    margin: 0;
  }
  .star-icon {
    margin: 0 0.1rem;
  }
`;
