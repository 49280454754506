import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Tab } from './SubjectTabs.styles';

const SubjectTabs = ({ subjects, selectedSubject }) => {
  const history = useHistory();
  const switchTabHandler = (newSubject) => {
    const lowercaseNewSubject = newSubject.toLowerCase().trim();
    history.replace(`/leaderboard?subject=${lowercaseNewSubject}`);
  };
  return (
    <Container>
      {subjects.map((subject) => (
        <Tab
          isActive={selectedSubject?.id === subject.id}
          subject={subject.subject.toLowerCase()}
          onClick={() => {
            switchTabHandler(subject.subject);
          }}
          key={subject.id}
        >
          {subject.subject}
        </Tab>
      ))}
    </Container>
  );
};

export default SubjectTabs;
