import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import postLocalePreferences from 'services/localePreferences';
import { fetchWrapper } from 'services/login';

export const updateLocalePreferences = createAsyncThunk(
  'localePreferences/updateLocalePreferences',
  async (params) => {
    try {
      const res = await fetchWrapper(postLocalePreferences, params);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Update user locale preferences failed'
      );
    }
  }
);

const initialState = {
  updateLocale: null,
  isLocalizing: false,
  localeError: null,
};

const localePreferences = createSlice({
  name: 'localePreferences',
  initialState,
  reducers: {
    resetUpdateLocale: (state) => {
      state.updateLocale = null;
    },
  },
  extraReducers: {
    [updateLocalePreferences.pending]: (state) => {
      state.isLocalizing = true;
      state.updateLocale = null;
      state.localeError = null;
    },
    [updateLocalePreferences.fulfilled]: (state, action) => {
      state.isLocalizing = false;
      state.updateLocale = action.payload;
      state.localeError = null;
    },
    [updateLocalePreferences.rejected]: (state, action) => {
      state.isLocalizing = false;
      state.updateLocale = null;
      state.localeError = action.error.message;
    },
  },
});

const { actions, reducer } = localePreferences;
export const { resetUpdateLocale } = actions;
export default reducer;
