import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import ReportDetails from 'components/Report/ReportDetails';
import SkillPanel from 'components/Report/SkillPanel';
import Tabs from 'components/Report/Tabs';
import ProgressBar from 'components/Report/ProgressBar.js';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import { isNil, isEmpty } from 'ramda';
import { compareValues } from 'helpers/compareValue';
import GradeAIcon from 'assets/img/mission/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/mission/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/mission/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/mission/icon-report-high-score-d.svg';
import BackIcon from 'assets/img/icon-back-black.svg';

import formatChineseNumber from 'helpers/formatChineseNumber';
import {
  sortSkills,
  parseHighScoreProgressBar,
} from 'helpers/reportProgressbar.js';
import {
  getUserCurriculums,
  getUserReport,
  resetError,
} from 'store/report/reportSlice';
import { featureToggle } from 'constants/index';
import ageGroups from 'constants/ageGroups';
import useDefaultAgeGroup from 'hooks/useDefaultAgeGroup';
import { NewHOTsCurriculumID } from 'constants/mission';
import { MISSION_PLUS_CURRICULUM_IDS } from 'constants/missionPlus';
import { TABS_ITEM } from './constants';
import {
  Header,
  DropdownsContainer,
  Content,
  ProficiencySkills,
} from './StandardReport.styles';

// Helper to find default curriculum based on current student's default level
const getDefaultCurriculum = (curriculums, defaultLevel) => {
  if (curriculums.length === 1) {
    return curriculums[0];
  }
  const defaultCurriculum = curriculums.find((curr) => {
    const hasStudentDefaultLevel = curr.Levels.findIndex(
      (level) => level.ID === defaultLevel
    );
    return hasStudentDefaultLevel !== -1;
  });
  if (isNil(defaultCurriculum)) {
    return curriculums[0];
  }
  return defaultCurriculum;
};
const getLevelIndex = (levels, levelID) => {
  return levels.findIndex((level) => level.ID === levelID);
};

const StandardReport = ({ reportType }) => {
  const { t, i18n } = useTranslation(['proficiencyReport', 'common']);
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const history = useHistory();

  const { defaultLevel, groupId, studentDetails } = useSelector(
    (state) => state.studentDetails
  );
  const { ageGroupIndex } = useDefaultAgeGroup();
  const {
    curriculums,
    curriculumsLoading,
    report,
    isLoading,
    error,
    curriculumsError,
  } = useSelector((state) => state.report);
  const { isLoading: isProductsLoading, products } = useSelector(
    (state) => state.plan
  );
  const isPageLoading = isLoading || isProductsLoading;
  const [curriculumId, setCurriculumId] = useState(null);
  const [reportLevel, setReportLevel] = useState({});
  const [showSkillDetails, setShowSkillDetails] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState();
  const [selectedSkillTopic, setSelectedSkillTopic] = useState();
  const [selectedSkillNumber, setSelectedSkillNumber] = useState();
  const [levels, setLevels] = useState([]);

  const currentSyllabus = getDefaultCurriculum(curriculums, defaultLevel);
  const currentSyllabusId = currentSyllabus?.CurriculumID;
  const [selectedSyllabus, setSelectedSyllabus] = useState('');

  const latestSyllabus = selectedSyllabus || currentSyllabus;
  const isv2Hots = curriculumId === NewHOTsCurriculumID;
  const isMissionPlus = Boolean(MISSION_PLUS_CURRICULUM_IDS[curriculumId]);
  const selectedLevelIndex = getLevelIndex(levels, reportLevel.currLevel);

  const loadCurriculumsData = useCallback(() => {
    const params =
      featureToggle.science === true ? { filterSubject: true } : {};
    dispatch(getUserCurriculums(params));
  }, [dispatch]);

  // Fetch student details & curriculums
  useEffect(() => {
    if (featureToggle.science !== true) {
      loadCurriculumsData();
    } else if (products !== null) {
      loadCurriculumsData();
    }
  }, [loadCurriculumsData, products]);

  useEffect(() => {
    setCurriculumId(currentSyllabusId);
  }, [currentSyllabusId]);

  // Update Levels Dropdown options when selected curriculum changes
  useEffect(() => {
    if (!isNil(curriculumId) && !isEmpty(curriculums)) {
      const selectedCurriculum = curriculums.find(
        (curr) => curr.CurriculumID === curriculumId
      );
      // Set list of options available for level dropdown
      if (
        selectedCurriculum.Levels !== null &&
        selectedCurriculum.Levels.length > 0
      ) {
        const sortedLevels = [...selectedCurriculum.Levels].sort(
          compareValues('ID', 'asc')
        );
        setLevels(sortedLevels);
      } else {
        setLevels([]);
      }
      // Set default level
      const tempLevel = {};
      if (isv2Hots) {
        const ageGroupDefaultLevel = selectedCurriculum.Levels[ageGroupIndex];
        if (!isNil(ageGroupDefaultLevel)) {
          tempLevel.currLevel = ageGroupDefaultLevel.ID;
        } else if (selectedCurriculum?.Levels?.length > 0) {
          tempLevel.currLevel =
            selectedCurriculum.Levels[selectedCurriculum.Levels.length - 1].ID;
        } else {
          tempLevel.currLevel = '';
        }
      } else {
        const hasDefaultLevel = selectedCurriculum?.Levels?.find(
          (level) => level.ID === defaultLevel
        );
        if (!isNil(hasDefaultLevel)) {
          tempLevel.currLevel = defaultLevel;
        } else if (selectedCurriculum?.Levels?.length > 0) {
          tempLevel.currLevel = selectedCurriculum.Levels[0].ID;
        } else if (hasDefaultLevel === undefined) {
          tempLevel.currLevel = '';
        } else {
          tempLevel.currLevel = '';
        }
      }
      setReportLevel(tempLevel);
    }
  }, [ageGroupIndex, curriculumId, curriculums, defaultLevel, isv2Hots]);

  useEffect(() => {
    if (!isNil(curriculumId) && curriculums.length > 0) {
      // Check if level id is valid for selected curriculum id
      const selectedCurriculum = curriculums.find(
        (curr) => curr.CurriculumID === curriculumId
      );
      const hasLevels = selectedCurriculum.Levels.length > 0;
      const obj = {
        userId: studentDetails && studentDetails.UserId,
        classId: groupId,
        curriculumId,
        levelId: reportLevel.currLevel ? reportLevel.currLevel : '',
      };
      if (!hasLevels && reportLevel.currLevel === '') {
        dispatch(getUserReport(obj));
      } else if (
        hasLevels &&
        !isNil(
          selectedCurriculum.Levels.find(
            (level) => level.ID === reportLevel.currLevel
          )
        )
      ) {
        dispatch(getUserReport(obj));
      }
    }
  }, [
    curriculumId,
    curriculums,
    dispatch,
    groupId,
    reportLevel.currLevel,
    studentDetails,
  ]);

  // total no of skills
  const getTotalNumOfSkills =
    report &&
    report.Topics.map((item) => item.TotalNoOfSkill).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

  const getTotalNumOfMasteredSkills =
    report &&
    report.Topics.map((item) => item.SkillMastered).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

  const getTotalNumOfALevelSkills =
    report &&
    report.Topics.map((item) => item.TotalSkillsWithALevel).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

  const getAllSkills =
    report && report.Topics.map((item) => item.Skills).flat();

  const reloadHandler = () => {
    if (!isNil(error)) {
      const obj = {
        userId: studentDetails && studentDetails.UserId,
        classId: groupId,
        curriculumId,
        levelId: reportLevel.currLevel ? reportLevel.currLevel : '',
      };
      dispatch(getUserReport(obj));
    }
    if (!isNil(curriculumsError)) {
      loadCurriculumsData();
    }
  };

  return (
    <>
      <Header>
        <Link to="/dashboard" className="back-btn">
          <img src={BackIcon} alt="back" />
          {t(`proficiencyReport:header.back`, 'Back')}
        </Link>
        <span className="title">
          {t(`proficiencyReport:header.title`, 'Report')}
        </span>
        {isNil(curriculumsError) && (
          <DropdownsContainer data-cy="dropdown-container">
            <span data-cy="syll-label">
              {t(`proficiencyReport:header.Syllabus`, 'Syllabus')}
            </span>
            <Dropdown flip={false} data-cy="syll-dropdown">
              <Dropdown.Toggle
                variant="light"
                className="dropdown-select-syllabus"
              >
                {curriculumsLoading
                  ? 'Loading...'
                  : selectedSyllabus === ''
                  ? currentSyllabus?.curriculumName
                  : selectedSyllabus.curriculumName}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {curriculums.map((syl) => (
                  <Dropdown.Item
                    key={syl.CurriculumID}
                    onClick={() => {
                      const isMissionPlusCurr = Boolean(
                        MISSION_PLUS_CURRICULUM_IDS[syl.CurriculumID]
                      );
                      if (isMissionPlusCurr) {
                        history.push('/report/proficiency');
                      }
                      setSelectedSyllabus(syl);
                      setShowSkillDetails(false);
                      setCurriculumId(syl.CurriculumID);
                    }}
                  >
                    {syl.curriculumName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {latestSyllabus?.Levels.length > 0 && (
              <>
                <span data-cy="level-label">
                  {t(`proficiencyReport:header.Level`, 'Level')}
                </span>
                <Dropdown flip={false} data-cy="level-dropdown">
                  <Dropdown.Toggle variant="light" className="dropdown-custom">
                    {isv2Hots &&
                    selectedLevelIndex !== -1 &&
                    selectedLevelIndex <= ageGroups.length - 1
                      ? t(
                          ageGroups[selectedLevelIndex].i18nKey,
                          {
                            minAge: ageGroups[selectedLevelIndex].minAge,
                            maxAge: ageGroups[selectedLevelIndex].maxAge,
                          },
                          ageGroups[selectedLevelIndex].name
                        )
                      : `${t(
                          `proficiencyReport:header.Primary`,
                          'Primary'
                        )} ${formatChineseNumber({
                          value: reportLevel.currLevel,
                          language: currentLanguage,
                        })}`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {levels.map((level, index) => (
                      <Dropdown.Item
                        key={level.ID}
                        onClick={() => {
                          const tempLevel = {};
                          tempLevel.currLevel = level.ID;
                          setReportLevel(tempLevel);
                          setShowSkillDetails(false);
                        }}
                      >
                        {isv2Hots && ageGroups[index]
                          ? t(
                              ageGroups[index].i18nKey,
                              {
                                minAge: ageGroups[index].minAge,
                                maxAge: ageGroups[index].maxAge,
                              },
                              ageGroups[index].name
                            )
                          : `${t(
                              `proficiencyReport:header.Primary`,
                              'Primary'
                            )} ${formatChineseNumber({
                              value: level.ID,
                              language: currentLanguage,
                            })}`}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </DropdownsContainer>
        )}
      </Header>
      <Tabs
        tabItems={TABS_ITEM}
        reportType={reportType}
        setShowSkillDetails={setShowSkillDetails}
        setSelectedSkill={setSelectedSkill}
        showSkillDetails={showSkillDetails}
        isMissionPlus={isMissionPlus}
      />
      <Content
        showSkillDetails={showSkillDetails}
        type={reportType}
        data-cy="content-container"
      >
        {isPageLoading && <Spinner />}
        {!isPageLoading && report && (
          <>
            <div className="section-container">
              {reportType === 'proficiency' && (
                <>
                  <div className="total-progress-bar">
                    {getAllSkills && (
                      <ProgressBar
                        height="12px"
                        visualParts={sortSkills(getAllSkills)}
                      />
                    )}
                    <p>
                      <strong>{getTotalNumOfMasteredSkills}</strong>{' '}
                      <span>
                        {t('proficiencyReport:legend.outOf', 'out of ')}
                        {getTotalNumOfSkills}
                        {t(
                          'proficiencyReport:legend.skillsMastery',
                          ' Skills Mastery'
                        )}
                      </span>
                    </p>
                  </div>
                </>
              )}
              {reportType === 'highscore' && (
                <>
                  <div className="total-progress-bar high-score">
                    <img src={GradeAIcon} alt="icon" />
                    <div className="inner-container">
                      <p>
                        <strong>{getTotalNumOfALevelSkills}</strong>{' '}
                        <span>
                          {`${t('proficiencyReport:legend.skills', 'skills')} `}
                          A star
                          {t('proficiencyReport:legend.outOf', 'out of ')}
                          {getTotalNumOfSkills}
                          {t('proficiencyReport:legend.skills', ' skills')}
                        </span>
                      </p>
                      {getAllSkills && (
                        <ProgressBar
                          height="12px"
                          visualParts={parseHighScoreProgressBar(getAllSkills)}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="skills-section">
                {reportType === 'highscore' ? (
                  <>
                    <span className="ratings-category">
                      <img src={GradeAIcon} alt="Grade A" />
                      <span>9-10</span>
                      <span className="sub-text">{`${t(
                        'proficiencyReport:legend.outOf',
                        'out of '
                      )}10`}</span>
                    </span>
                    <span className="ratings-category">
                      <img src={GradeBIcon} alt="Grade B" />
                      <span>6-8</span>
                      <span className="sub-text">{`${t(
                        'proficiencyReport:legend.outOf',
                        'out of '
                      )}10`}</span>
                    </span>
                    <span className="ratings-category">
                      <img src={GradeCIcon} alt="Grade C" />
                      <span>4-5</span>
                      <span className="sub-text">{`${t(
                        'proficiencyReport:legend.outOf',
                        'out of '
                      )}10`}</span>
                    </span>
                    <span className="ratings-category">
                      <img src={GradeDIcon} alt="Grade D" />
                      <span>1-3</span>
                      <span className="sub-text">{`${t(
                        'proficiencyReport:legend.outOf',
                        'out of '
                      )}10`}</span>
                    </span>
                  </>
                ) : (
                  <>
                    <ProficiencySkills proficiency="mastery">
                      <div className="prof-name">
                        <div className="icon" />
                        <span>
                          {t(`proficiencyReport:legend.mastery`, 'Mastery')}
                        </span>
                      </div>
                      <span className="prof-descricption">
                        {t(
                          `proficiencyReport:legend.proficiency`,
                          'Proficiency'
                        )}{' '}
                        <b>&nbsp;80-100</b>
                      </span>
                    </ProficiencySkills>
                    <ProficiencySkills proficiency="competent">
                      <div className="prof-name">
                        <div className="icon" />
                        <span>
                          {t(`proficiencyReport:legend.competent`, 'Competent')}
                        </span>
                      </div>
                      <span className="prof-descricption">
                        {t(
                          `proficiencyReport:legend.proficiency`,
                          'Proficiency'
                        )}{' '}
                        <b>&nbsp;60-79</b>
                      </span>
                    </ProficiencySkills>
                    <ProficiencySkills proficiency="beginning">
                      <div className="prof-name">
                        <div className="icon" />
                        <span>
                          {t(`proficiencyReport:legend.beginning`, 'Beginning')}
                        </span>
                      </div>
                      <span className="prof-descricption">
                        {t(
                          `proficiencyReport:legend.proficiency`,
                          'Proficiency'
                        )}{' '}
                        <b>&nbsp;0-59</b>
                      </span>
                    </ProficiencySkills>
                    <ProficiencySkills proficiency="incomplete">
                      <div className="prof-name">
                        <div className="icon" />
                        <span>
                          {t(
                            `proficiencyReport:legend.incomplete`,
                            'Incomplete'
                          )}
                        </span>
                      </div>
                      <span className="prof-descricption">
                        {t(`proficiencyReport:legend.question`, 'Question')}{' '}
                        &lt;
                        <b>&nbsp;10</b>
                      </span>
                    </ProficiencySkills>
                  </>
                )}
              </div>
            </div>
            <ReportDetails
              setShowSkillDetails={setShowSkillDetails}
              selectedSkill={selectedSkill}
              setSelectedSkill={setSelectedSkill}
              setSelectedSkillTopic={setSelectedSkillTopic}
              setSelectedSkillNumber={setSelectedSkillNumber}
              type={reportType}
              data={report && report.Topics}
            />
          </>
        )}
        {(!isNil(error) || !isNil(curriculumsError)) && (
          <ErrorModal
            errorMessage={!isNil(error) ? error : curriculumsError}
            reloadAction={reloadHandler}
            closeHandler={() => dispatch(resetError())}
          />
        )}
      </Content>
      {showSkillDetails && (
        <SkillPanel
          type={reportType}
          setShowSkillDetails={setShowSkillDetails}
          selectedSkill={selectedSkill}
          selectedSkillTopic={selectedSkillTopic}
          selectedSkillNumber={selectedSkillNumber}
        />
      )}
    </>
  );
};

export default StandardReport;
