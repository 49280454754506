import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLevels, fetchCountryList } from 'services/lookup';
import { fetchWrapper } from 'services/login';
import { getUserInfoByIds } from 'services/search';
import { isNil } from 'ramda';

// Fetch levels
export const getLevels = createAsyncThunk('lookup/getLevels', async () => {
  try {
    const res = await fetchWrapper(fetchLevels);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Get levels failed');
  }
});

export const getCountryList = createAsyncThunk(
  'lookup/getCountryList',
  async () => {
    try {
      const res = await fetchWrapper(fetchCountryList);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get country list failed');
    }
  }
);

export const getUserInfos = createAsyncThunk(
  'lookup/getUserInfos',
  async (params, { getState }) => {
    const { lookup } = getState();
    const { userInfoCache } = lookup;
    if (!params?.userIDs) throw new Error('User IDs undefined');
    const shouldCache = params?.cache !== false;
    const userIdsNotInCache = [];
    const userInfoFromCache = [];
    params.userIDs.forEach((id) => {
      const userInfo = userInfoCache[id];
      if (shouldCache) {
        if (isNil(userInfo)) {
          userIdsNotInCache.push(id);
          return;
        }
        userInfoFromCache.push(userInfo);
      } else {
        userIdsNotInCache.push(id);
      }
    });
    if (userIdsNotInCache.length <= 0) return { List: userInfoFromCache };
    try {
      const res = await fetchWrapper(getUserInfoByIds, {
        userIDs: userIdsNotInCache,
        pageIndex: 0,
        pageSize: userIdsNotInCache.length,
      });
      const clonedResult = {
        ...res,
      };
      const newResultList = [...clonedResult.List, ...userInfoFromCache];
      clonedResult.List = newResultList;
      return clonedResult;
    } catch (error) {
      throw new Error(error?.message ?? 'Get user info by user ids failed');
    }
  }
);

const initialState = {
  levels: [],
  countryList: [],
  isLoading: false,
  error: null,
  userInfoCache: {},
};

const lookupSlice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {},
  extraReducers: {
    [getLevels.pending]: (state) => {
      state.isLoading = true;
      state.levels = [];
      state.error = null;
    },
    [getLevels.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.levels = action.payload;
      state.error = null;
    },
    [getLevels.rejected]: (state, action) => {
      state.isLoading = false;
      state.levels = [];
      state.error = action.error.message;
    },
    [getCountryList.pending]: (state) => {
      state.isLoading = true;
      state.countryList = [];
      state.error = null;
    },
    [getCountryList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.countryList = action.payload;
      state.error = null;
    },
    [getCountryList.rejected]: (state, action) => {
      state.isLoading = false;
      state.countryList = [];
      state.error = action.error.message;
    },
    [getUserInfos.pending]: () => {},
    [getUserInfos.fulfilled]: (state, action) => {
      const fetchedUserInfoHashmap = {};
      action.payload.List.forEach((user) => {
        fetchedUserInfoHashmap[user.Id] = user;
      });
      const newUserInfo = {
        ...state.userInfoCache,
        ...fetchedUserInfoHashmap,
      };
      state.userInfoCache = newUserInfo;
    },
    [getUserInfos.rejected]: () => {},
  },
});

const { reducer } = lookupSlice;
export default reducer;
