import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  padding: 1rem;
  text-align: center;
`;

export const Button = styled.button`
  border: none;
  background: #ff6701;
  font-weight: 600;
  font-size: 1.3125rem;
  color: white;
  border-radius: 30px;
  padding: 0.25rem 2rem;
  width: 50%;
  margin-top: 20px;
  :hover {
    background: #ff5801;
  }
`;
