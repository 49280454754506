import React from 'react';
import {
  StyledContainer,
  MiddleSection,
  StyledSection,
} from './ResponsiveHeader.styles';

const ResponsiveHeader = ({
  assignmentType,
  showButtons,
  left,
  middle,
  right,
  styles = {},
}) => {
  return (
    <StyledContainer page="" assignmentType={assignmentType} styles={styles}>
      {left && (
        <StyledSection styles={styles.leftSection}>{left}</StyledSection>
      )}
      {middle && <MiddleSection>{middle}</MiddleSection>}
      {right && (
        <StyledSection
          styles={{
            justifyContent: !showButtons ? 'flex-end' : 'space-evenly',
            borderLeft: showButtons && 'solid 1px #82e5ff',
            ...styles.rightSection,
          }}
        >
          {right}
        </StyledSection>
      )}
    </StyledContainer>
  );
};

export default ResponsiveHeader;
