import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: arial;
  font-weight: bold;
  flex: 1;
`;

export const WrapImage = styled.div`
  width: 100%;
`;

export const ImgClosed = styled.img`
  width: 100%;
`;

export const ButtonChallenge = styled.a`
  font-size: 1em;
  text-decoration: none;
  display: block;
  width: 287px;
  height: 45px;
  margin: 0 auto;
  border-right: 3px solid #ff6200;
  border-bottom: 4px solid #ff6200;
  border-radius: 4px;
  background-color: #f99200;
  color: #fff;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  margin-top: 15px;
  text-decoration: none;
`;

export const Copyright = styled.p`
  font-size: 0.6em;
`;
