import styled from 'styled-components';

export const Container = styled.div`
  .info-section {
    display: flex;
    justify-content: center;
    padding: 0rem 4rem;
    margin: 1rem 1rem 0 1rem;
    align-items: baseline;
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      padding: 0rem 2rem;
    }
  }
  .exp-section {
    margin-left: 0.5rem;
  }
  .next-qn {
    text-align: center;
    padding: 1rem;
    border-bottom: 2px dashed #5bcbed;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  .btn-orange {
    background-color: #ff6701;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    border-radius: 30px;
    &:focus {
      box-shadow: none;
    }
  }
  .bottom-section {
    color: #11bef0;
    font-weight: 600;
    p {
      mamrgin-bottom: 0rem;
    }
    .expand-qn {
      color: #090909;
      font-size: 17px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const AddCP = styled.div`
  display: flex;
  align-items: center;
  background-color: #ebf9ff;
  color: #0895d1;
  border-radius: 12px;
  padding: 0.2rem;
  width: 114px;
  span {
    width: 100%;
    text-align: center;
    img {
      margin: 0rem 0.5rem;
    }
    font-size: 22px;
    font-weight: 600;
    color: #38494e;
  }
`;

export const AddExp = styled(AddCP)`
  background-color: #fff0c7;
`;

export const TotalCP = styled.div`
  color: #087d9d;
  font-size: 12px;
  padding: 0.3rem 0.8rem;
  span {
    font-size: 14px;
    color: #38494e;
  }
`;

export const TotalExp = styled(TotalCP)`
  color: #de9a00;
  padding: 0.3rem 0rem;
`;
