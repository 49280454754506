import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import {
  getAllSubjects,
  getPapers,
  getCurrentGiftBySubject,
  setKooQuizType,
  setKooQuizSelectedPaper,
} from 'store/kooQuiz/kooQuizSlice';
import BackButton from 'components/Shared/BackButton.js';
import ErrorModal from 'components/Shared/ErrorModal';
import MathBg from 'assets/img/kooquiz/math-bg.svg';
import ScienceBg from 'assets/img/kooquiz/science-bg.svg';
import useDashboard from 'hooks/useDashboard';
import { Banner, Body, Sections, Card } from './KooQuizSelection.styles';

const KooQuizSelection = () => {
  const { t } = useTranslation(['kooQuizSubSelect']);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { allSubjects, error } = useSelector((state) => state.kooQuiz);
  const { isDashboardv2 } = useDashboard();
  const [showOverlayMath, setShowOverlayMath] = useState(false);
  const [showOverlayScience, setShowOverlayScience] = useState(false);

  useEffect(() => {
    dispatch(setKooQuizSelectedPaper(2));
    dispatch(getAllSubjects());
  }, [dispatch]);
  const fetchAllPapers = (name) => {
    if (name === 'Science') {
      history.push(`/kooQuiz/${name}/2`);
      dispatch(setKooQuizType('KooQuizSci'));
    } else {
      history.push(`/kooQuiz/${name}/1`);
      dispatch(setKooQuizType('KooQuizMaths'));
    }
    const params = {
      type: name === 'Maths' ? 51 : 52,
      subjectId: name === 'Maths' ? 1 : 2,
    };
    dispatch(getPapers(params));
    dispatch(getCurrentGiftBySubject(params.subjectId));
  };

  const reloadHandler = () => {
    dispatch(getAllSubjects());
  };

  const isFromShortCut = location.state?.quickAccess;

  return (
    <>
      <Banner>
        <div className="btn">
          <BackButton
            backText={t('kooQuizSubSelect:header.back', 'Back')}
            btnBgColor="#5ED2FF"
            padding="0.3rem 1.2rem"
            onClick={() => {
              if (isFromShortCut) {
                history.goBack();
                return;
              }
              history.push(
                isDashboardv2 ? '/dashboard?view=kootraining' : '/dashboard'
              );
            }}
          />
        </div>
        <span>
          {t('kooQuizSubSelect:header.welcome', 'Welcome!')}
          <br />
          {t('kooQuizSubSelect:header.completeKooQuiz', 'Complete KooQuiz')}
          <br /> {t('kooQuizSubSelect:header.everyWeek', 'every week to earn')}
          <br />
          {t('kooQuizSubSelect:header.moreCredits', 'more KoKo credits.')}
        </span>
        <h2>{t('kooQuizSubSelect:header.kooQuiz', 'KooQuiz')}</h2>
      </Banner>
      <Body data-cy="subjects-container">
        {allSubjects.map((item, i) => (
          <React.Fragment key={`subject-${i}`}>
            <Sections
              type={item.Name}
              onClick={() => fetchAllPapers(item.Name)}
              onMouseEnter={() => {
                if (item.Name === 'Maths') {
                  setShowOverlayMath(true);
                } else {
                  setShowOverlayScience(true);
                }
              }}
              onMouseLeave={() => {
                if (item.Name === 'Maths') {
                  setShowOverlayMath(false);
                } else {
                  setShowOverlayScience(false);
                }
              }}
              hover={
                item.Name === 'Maths' ? showOverlayMath : showOverlayScience
              }
            >
              <Card
                type={item.Name}
                bg={item.Name === 'Maths' ? MathBg : ScienceBg}
                hover={
                  item.Name === 'Maths' ? showOverlayMath : showOverlayScience
                }
              >
                <p>{t(`kooQuizSubSelect:cards.${item.Name}`, item.Name)}</p>
              </Card>
              <div className="overlayy" data-cy={item.Name}>
                <div className="button">
                  {t('kooQuizSubSelect:cards.start', 'Start')}
                </div>
              </div>
            </Sections>
          </React.Fragment>
        ))}
      </Body>
      {!isNil(error) && (
        <ErrorModal reloadAction={reloadHandler} errorMessage={error} />
      )}
    </>
  );
};

export default KooQuizSelection;
