import styled from 'styled-components';
import { scoreColorsMap } from 'constants/index';

export const Container = styled.div`
  min-width: 250px;
  margin-top: 45px;
  margin-left: -30%;

  .title-score {
    color: #db2d20;
    font-weight: 600;
    font-size: 23px;
  }

  .sub-title {
    color: #4b0000;
    font-size: 27px;
    font-weight: 600;
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .score-board {
    width: 300px;
    height: 130px;
    background-color: rgb(255, 255, 255, 0.64);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;

    p {
      margin-bottom: 0px;
    }

    .label {
      display: flex;
      justify-content: space-around;
      width: 100%;
      color: #d19898;
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 0px;

      & > p:nth-of-type(2) {
        max-width: 110px;
      }
    }

    .score {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: -1rem;
    }

    .percentage {
      color: ${(props) => scoreColorsMap(props.score)};
      font-family: Pacifico;
      font-size: 57px;
      span {
        font-size: 37px;
      }
      @media (max-width: 1024px) {
        font-size: 37px;
        span {
          font-size: 17px;
        }
      }
    }

    .points {
      display: flex;
      align-items: center;
    }

    .points-cp {
      display: flex;
      align-items: center;
      margin-top: 15px;

      img {
        margin-right: 5px;
      }
    }
  }
`;
