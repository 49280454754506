export const applicationIdConstants = {
  SP001: 'SP001', // STUDENT PORTAL
  TP002: 'TP002', // TEACHER PORTAL
};

export const feConstants = {
  event_id: 'ei',
  application_id: 'app',
  user_id: 'ui',
  session_id: 'si',
  subject_id: 'sub',
  question_id: 'qi',
  curriculum_id: 'ci',
  video_id: 'vi',
  lesson_id: 'li',
  activity_id: 'ai',
  kooquiz_id: 'kqi',
  homework_id: 'hi',
  supervision_id: 'svi',
  superspeed_id: 'sui',
  story_id: 'sti',
  brain_game_id: 'bgi',
};

const mandatoryConstants = [
  'timestamp', // unix timestamp
  'application_id', // defined in applicationIdConstants
  'user_id', // retrieve from login redux
  'session_id', // retrieve from login redux
];

const eventTrackingConstants = {
  daily_challenge: {
    question_view: {
      event_name: 'daily_challenge__question_view',
      event_id: 'dcqv_0001',
      mandatory: mandatoryConstants,
      keys: ['subject_id'],
    },
    question_submit: {
      event_name: 'daily_challenge__question_submit',
      event_id: 'dcqv_0002',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'question_id'],
    },
  },
  peer_challenge: {
    challenge_view: {
      event_name: 'peer_challenge__challenge_view',
      event_id: 'pccv_0003',
      mandatory: mandatoryConstants,
      keys: ['subject_id'],
    },
    challenge_submit: {
      event_name: 'peer_challenge__challenge_submit',
      event_id: 'pccs_0004',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'question_id'],
    },
  },
  sunday_mini_challenge: {
    question_view: {
      event_name: 'sunday_mini_challenge__question_view',
      event_id: 'smcqv_0005',
      mandatory: mandatoryConstants,
      keys: ['subject_id'],
    },
    question_submit: {
      event_name: 'sunday_mini_challenge__question_submit',
      event_id: 'smcqs_0006',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'question_id'],
    },
  },
  mission: {
    question_view: {
      event_name: 'mission__question_view',
      event_id: 'mqv_0007',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'curriculum_id'],
    },
    question_submit: {
      event_name: 'mission__question_submit',
      event_id: 'mqs_0008',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'question_id', 'curriculum_id'],
    },
    video_view: {
      event_name: 'mission__video_view',
      event_id: 'mvv_0009',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'curriculum_id', 'video_id'],
    },
  },
  kooclass: {
    lesson_view: {
      event_name: 'kooclass__lesson_view',
      event_id: 'klv_0010',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'lesson_id'],
    },
    video_view: {
      event_name: 'kooclass__video_view',
      event_id: 'kvv_0011',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'video_id', 'activity_id'],
    },
    question_submit: {
      event_name: 'kooclass__question_submit',
      event_id: 'kqs_0012',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'question_id', 'activity_id'],
    },
  },
  kooquiz: {
    question_view: {
      event_name: 'kooquiz__question_view',
      event_id: 'kqv_0013',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'kooquiz_id'],
    },
    question_submit: {
      event_name: 'kooquiz__question_submit',
      event_id: 'kqs_0014',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'question_id', 'kooquiz_id'],
    },
  },
  homework: {
    question_view: {
      event_name: 'homework__question_view',
      event_id: 'hqv_0015',
      mandatory: mandatoryConstants,
      keys: ['homework_id'],
    },
    question_submit: {
      event_name: 'homework__question_submit',
      event_id: 'hqs_0016',
      mandatory: mandatoryConstants,
      keys: ['question_id', 'homework_id'],
    },
  },
  super_vision: {
    challenge_start: {
      event_name: 'super_vision__challenge_start',
      event_id: 'svcs_0017',
      mandatory: mandatoryConstants,
      keys: ['supervision_id'],
    },
    challenge_submit: {
      event_name: 'super_vision__challenge_end',
      event_id: 'svce_0018',
      mandatory: mandatoryConstants,
      keys: ['supervision_id'],
    },
  },
  super_speed: {
    challenge_start: {
      event_name: 'super_vision__challenge_start',
      event_id: 'sscs_0019',
      mandatory: mandatoryConstants,
      keys: ['superspeed_id'],
    },
    challenge_submit: {
      event_name: 'super_vision__challenge_end',
      event_id: 'ssce_0020',
      mandatory: mandatoryConstants,
      keys: ['superspeed_id'],
    },
  },
  story: {
    story_open: {
      event_name: 'story__story_open',
      event_id: 'sso_0021',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'story_id'],
    },
  },
  brain_games: {
    game_unlock: {
      event_name: 'brain_games__game_unlock',
      event_id: 'bggu_0022',
      mandatory: mandatoryConstants,
      keys: ['subject_id', 'brain_game_id'],
    },
  },
  leaderboard: {
    leaderboard_intermediate_open: {
      event_name: 'leaderboard__leaderboard_intermediate_open',
      event_id: 'llio_0023',
      mandatory: mandatoryConstants,
      keys: ['subject_id'],
    },
  },
};

export default eventTrackingConstants;
