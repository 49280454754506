import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const mcqOptionColors = (isCorrect, selected) => {
  if (isCorrect === true) {
    return {
      border: 'solid 3px #63CB58',
      background: '#E2FFDE',
      hoverBorder: null,
      hoverBackground: null,
    };
  }
  if (isCorrect === false) {
    return {
      border: 'solid 3px #FF6C47',
      background: '#FFECE8',
      hoverBorder: null,
    };
  }
  if (selected === true) {
    return {
      border: 'solid 3px #FFA238',
      background: '#FFEED9',
      hoverBorder: null,
      hoverBackground: null,
    };
  }
  return {
    border: 'solid 3px #15DAFF',
    background: '#fff',
    hoverBorder: null,
    hoverBackground: '#EAFCFF',
  };
};

export const TextOption = styled.div`
  background: ${(props) =>
    mcqOptionColors(props.isCorrect, props.selected).background};
  border-radius: 6px;
  width: 39.5%;
  min-height: 62px;
  padding: 1rem 1.625rem;
  box-shadow: -2px 4px 0px 2px rgba(0, 0, 0, 0.16);
  border: ${(props) => mcqOptionColors(props.isCorrect, props.selected).border};
  cursor: pointer;
  margin: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  :hover {
    background: ${(props) =>
      props.isAnswerInputDisabled === false
        ? mcqOptionColors(props.isCorrect, props.selected).hoverBackground
        : null};
    border: ${(props) =>
      props.isQnCorrect === true || props.isAnswerInputDisabled === true
        ? null
        : mcqOptionColors(props.isCorrect, props.selected).hoverBorder};
  }
  p {
    margin: 0;
    width: 90%;
    word-wrap: break-word;
    text-align: center;
    @media (max-width: ${({ theme }) => `${theme.rwd.mobileS}px`}) {
      width: 100%;
    }
  }
  .is-correct-icon {
    position: absolute;
    right: 0.625rem;
    width: ${(props) => (props.isCorrect ? '17px' : '11.5px')};
    height: ${(props) => (props.isCorrect ? '12.32px' : '11.5px')};
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    &.mcq-text-option,
    &.mcq-img-option {
      width: 100%;
    }
  }
`;

export const ImgOption = styled(TextOption)`
  width: 45%;
  margin: 0.8rem 1rem;
  padding: 1rem 1.5rem;
  img {
    align-self: center;
  }
  .is-correct-icon {
    right: 0.375rem;
  }
`;
