import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchDCCurrentCurriculums,
  fetchAssignDCCurriculum,
  fetchGetAvailableCurriculums,
} from 'services/curriculum';
import { fetchWrapper } from 'services/login';
import {
  getPCCurrentCurriculum,
  updatePCCurrentCurriculum,
} from 'services/multiplayerv2';

export const getDCCurrentCurriculum = createAsyncThunk(
  'curriculum/GetDCCurrentCurriculum',
  async (subjectID = 1) => {
    try {
      const res = await fetchWrapper(fetchDCCurrentCurriculums, subjectID);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get DC latest curriculums failed');
    }
  }
);
export const getUserAvailableCurriculumList = createAsyncThunk(
  'curriculum/GetUserAvailableCurriculumList',
  async (subjectId = 1) => {
    try {
      const res = await fetchWrapper(fetchGetAvailableCurriculums, subjectId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get DC curriculum list failed');
    }
  }
);
export const postAssignDCCurriculum = createAsyncThunk(
  'curriculum/PostAssignDCCurriculum',
  async ({ CurriculumID }) => {
    try {
      const res = await fetchWrapper(fetchAssignDCCurriculum, { CurriculumID });
      if (!res.Result) {
        throw new Error('Post assign DC curriculum failed');
      }
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Post assign DC curriculum failed');
    }
  }
);

export const getPCCurriculum = createAsyncThunk(
  'curriculum/GetPCCurrentCurriculum',
  async ({ userID }) => {
    try {
      const res = await fetchWrapper(getPCCurrentCurriculum, { userID });
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get PC current curriculum failed');
    }
  }
);

export const postAssignPCCurriculum = createAsyncThunk(
  'curriculum/postAssignPCCurriculum',
  async ({ userID, curriculumID }) => {
    try {
      const res = await fetchWrapper(updatePCCurrentCurriculum, {
        userID,
        curriculumID,
      });
      if (!res.Result) {
        throw new Error('Post assign PC curriculum failed');
      }
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Post assign PC curriculum failed');
    }
  }
);

const initialState = {
  currentDCIsLoading: true,
  currentDCError: null,
  currentDCCurriculum: null,
  postAssignDCCurriculumIsLoading: false,
  postAssignDCCurriculumError: null,
  currentPCIsLoading: true,
  currentPCError: null,
  currentPCCurriculum: null,
  postAssignPCCurriculumIsLoading: false,
  postAssignPCCurriculumError: null,
  userAvailableCurriculumList: {
    isLoading: false,
    error: null,
    curriculumList: [],
  },
};

const curriculumSlice = createSlice({
  name: 'curriculum',
  initialState,
  extraReducers: {
    [getDCCurrentCurriculum.pending]: (state) => {
      state.currentDCIsLoading = true;
      state.currentDCError = null;
    },
    [getDCCurrentCurriculum.fulfilled]: (state, action) => {
      state.currentDCIsLoading = false;
      state.currentDCCurriculum = action.payload;
      state.currentDCError = null;
    },
    [getDCCurrentCurriculum.rejected]: (state, action) => {
      state.currentDCIsLoading = false;
      state.currentDCError = action.error.message;
    },
    [getUserAvailableCurriculumList.pending]: (state) => {
      state.userAvailableCurriculumList.isLoading = true;
      state.userAvailableCurriculumList.error = null;
    },
    [getUserAvailableCurriculumList.fulfilled]: (state, action) => {
      state.userAvailableCurriculumList.isLoading = false;
      if (action.payload.Result.length > 0) {
        state.userAvailableCurriculumList.curriculumList =
          action.payload.Result;
      }
    },
    [getUserAvailableCurriculumList.rejected]: (state, action) => {
      state.userAvailableCurriculumList.isLoading = false;
      state.userAvailableCurriculumList.error = action.error.message;
    },
    [postAssignDCCurriculum.pending]: (state) => {
      state.postAssignDCCurriculumIsLoading = true;
      state.postAssignDCCurriculumError = null;
    },
    [postAssignDCCurriculum.fulfilled]: (state) => {
      state.postAssignDCCurriculumIsLoading = false;
    },
    [postAssignDCCurriculum.rejected]: (state, action) => {
      state.postAssignDCCurriculumIsLoading = false;
      state.postAssignDCCurriculumError = action.error.message;
    },
    [getPCCurriculum.pending]: (state) => {
      state.currentPCIsLoading = true;
      state.currentPCError = null;
    },
    [getPCCurriculum.fulfilled]: (state, action) => {
      state.currentPCIsLoading = false;
      state.currentPCCurriculum = action.payload;
      state.currentPCError = null;
    },
    [getPCCurriculum.rejected]: (state, action) => {
      state.currentPCIsLoading = false;
      state.currentPCError = action.error.message;
    },
    [postAssignPCCurriculum.pending]: (state) => {
      state.postAssignPCCurriculumIsLoading = true;
      state.postAssignPCCurriculumError = null;
    },
    [postAssignPCCurriculum.fulfilled]: (state) => {
      state.postAssignPCCurriculumIsLoading = false;
    },
    [postAssignPCCurriculum.rejected]: (state, action) => {
      state.postAssignPCCurriculumIsLoading = false;
      state.postAssignPCCurriculumError = action.error.message;
    },
  },
});
const { reducer } = curriculumSlice;
export default reducer;
