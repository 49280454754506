import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from 'components/Shared/Card/Card';
import DetailsInfo from 'components/Shared/DetailsInfo';
import Button from 'components/Shared/Button';
import Spinner from 'components/Shared/Spinner';
import CPIcon from 'assets/img/icon-challenge-point.svg';
import SuperScoreIcon from 'assets/img/dailychallenge/icon-super-score.svg';
import LockedIcon from 'assets/img/icon-locked.svg';
import { Modal } from 'react-bootstrap';
import EarnMoreCPsModal from 'components/DailyChallenge/EarnMoreCPsModal';
import useIsMobile from 'hooks/responsive/useIsMobile';
import useIsTablet from 'hooks/responsive/useIsTablet';

import { getSuperHeroChallengeDataWithoutImage } from 'store/dailyChallenge/dailyChallengeSlice';
import { superHeroChallengeStatusIDs } from 'constants/dailyChallenge';
import {
  Container,
  Rewards,
  LeftContent,
  RightContent,
  StyledErrMsg,
  StyledReloadBtn,
  UnlockContent,
} from './ChallengeCard.styles';

const ChallengeCard = ({
  challengeStatus,
  challengeName,
  challengeDesc,
  challengeType,
  challengeOpenImg,
  challengeCloseImg,
  challengeLink,
  totalQns,
  cpAmt = '',
  cpIcon = CPIcon,
}) => {
  const { t } = useTranslation(['challengeList', 'common']);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isLocked = challengeStatus === 'locked';
  const isDC = challengeType === 'daily-challenge';
  const {
    superHeroChallengeWithoutImageData,
    superHeroChallengeWithoutImageIsError,
    superHeroChallengeWithoutImageIsLoading,
  } = useSelector((state) => state.dailyChallenge);
  const [showEarnMoreCPsModal, setShowEarnMoreCPsModal] = useState(false);

  const searchScoreAction = () => {
    dispatch(getSuperHeroChallengeDataWithoutImage());
  };

  const handleHideModal = () => setShowEarnMoreCPsModal(false);

  const getButtonFontSize = () => {
    if (isTablet) return '18px';
    if (isMobile) return '16px';
    return '22px';
  };

  const getButtonPadding = () => {
    if (isTablet) return '7.5px 15px';
    if (isMobile) return '5px 10px';
    return '10px 20px';
  };
  // card content right
  const rightSide = (propChallengeStatus, hideText = false) => {
    const contents = () => {
      if (isLocked) {
        return {
          text: (
            <button
              type="button"
              className="btn-message"
              onClick={() => setShowEarnMoreCPsModal(true)}
            >
              {t(
                'challengeList:unclockChal',
                '200 CPs to unlock this challenge'
              )}
            </button>
          ),
          button: (
            <Button
              isDisabled
              variant="disable"
              fontSize={getButtonFontSize()}
              padding={getButtonPadding()}
              fontWeight="600"
              dataCy="locked"
              disableColor="#e3e3e3"
            >
              {t('challengeList:startChal', 'Start Challenge')}
            </Button>
          ),
        };
      }
      if (
        propChallengeStatus === 'open' ||
        propChallengeStatus === 'incomplete'
      ) {
        return {
          button: (
            <Button
              variant="primary"
              fontSize={getButtonFontSize()}
              fontWeight="600"
              padding={getButtonPadding()}
              onClick={() => {
                history.push(challengeLink);
              }}
            >
              {t('challengeList:startChal', 'Start Challenge')}
            </Button>
          ),
        };
      }
      if (propChallengeStatus === 'completed') {
        return {
          text:
            challengeType === 'daily-challenge' &&
            `${t(
              'challengeList:wellDone',
              'Well done! You have completed all daily questions.'
            )}`,
          button: (
            <Button
              variant="secondary"
              fontSize={getButtonFontSize()}
              fontWeight="600"
              padding={getButtonPadding()}
              onClick={() => {
                history.push(challengeLink);
              }}
            >
              {t('challengeList:viewChal', 'View Challenge')}
            </Button>
          ),
        };
      }
      return {
        text: `${t('challengeList:open', 'Open ')}
          ${
            isDC
              ? t('challengeList:dcSchedule', '6am to 10pm, Everyday')
              : t('challengeList:scheduleShorten', '6am to 10pm, Mon - Sat')
          }
        `,
        button: (
          <Button
            variant="disable"
            fontSize={getButtonFontSize()}
            fontWeight="600"
            isDisabled
            disableColor="#e3e3e3"
            padding={getButtonPadding()}
          >
            {t('challengeList:startChal', 'Start Challenge')}
          </Button>
        ),
      };
    };
    return (
      <RightContent status={propChallengeStatus}>
        {propChallengeStatus === 'close' && (
          <div className="closed">Closed</div>
        )}
        {!hideText && (
          <div className="text">{contents(propChallengeStatus).text}</div>
        )}
        {propChallengeStatus === 'close' && isMobile ? null : (
          <div className="btn-group">
            <div className="btn">{contents(propChallengeStatus).button}</div>
            {isLocked && <img src={LockedIcon} alt="locked" />}
          </div>
        )}
      </RightContent>
    );
  };

  // card content left
  const leftSide = (propChallengeStatus) => {
    const rewards = () => {
      const rewardText = () => {
        switch (propChallengeStatus) {
          case 'close':
            return `${t(
              'challengeList:fullScore',
              { cpAmt },
              '17 CPs (Full Score)'
            )}`;
          case 'open':
            return `${t(
              'challengeList:fullScore',
              { cpAmt },
              '17 CPs (Full Score)'
            )}`;
          default:
            return `${t(
              'challengeList:fullScore',
              { cpAmt },
              '17 CPs (Full Score)'
            )}`;
        }
      };
      return (
        <Rewards>
          <img src={cpIcon} alt="CPs" style={{ marginRight: '0.25rem' }} />
          {rewardText(propChallengeStatus)}
        </Rewards>
      );
    };
    const superHeroScore = () => {
      const scoreType = () => {
        switch (challengeType) {
          case 'super-vision-challenge':
            return (
              superHeroChallengeWithoutImageData &&
              superHeroChallengeWithoutImageData.Supervision.Score
            );
          case 'super-speed-challenge':
            return (
              superHeroChallengeWithoutImageData &&
              superHeroChallengeWithoutImageData.Superspeed.Score
            );
          default:
            return '0';
        }
      };
      const renderResult = () => {
        if (superHeroChallengeWithoutImageIsLoading) {
          return <Spinner small top="-26px" />;
        }
        if (superHeroChallengeWithoutImageIsError) {
          return (
            <StyledErrMsg data-cy="error-response">
              {superHeroChallengeWithoutImageIsError}
              <StyledReloadBtn onClick={searchScoreAction}>
                {t('common:reload', 'Reload')}
              </StyledReloadBtn>
            </StyledErrMsg>
          );
        }
        return <span data-cy="score-value">{scoreType(challengeType)}</span>;
      };
      return (
        <Rewards>
          <img
            src={SuperScoreIcon}
            alt="SuperScore"
            style={{ marginRight: '0.25rem' }}
          />
          {renderResult()}
        </Rewards>
      );
    };

    const detailComponent = (
      <div className="detail-two">
        {challengeType === 'daily-challenge' && (
          <>
            <DetailsInfo
              direction="column"
              category="Total Qns"
              info={totalQns ?? ''}
            />
            <DetailsInfo
              direction="column"
              category="Rewards"
              info={rewards()}
            />
          </>
        )}
        {challengeType !== 'daily-challenge' && (
          <DetailsInfo
            direction="column"
            category="Score of the Week"
            info={superHeroScore()}
          />
        )}
      </div>
    );
    return (
      <LeftContent
        status={propChallengeStatus}
        closeImg={challengeCloseImg}
        openImg={challengeOpenImg}
      >
        <div className="top-content" isMobile={isMobile}>
          <div className="subcard" />
          <div className="info-section">
            <div className="challenge-name">
              {challengeName}
              <span>{challengeDesc}</span>
            </div>
            <div className="detail-one">
              <DetailsInfo
                direction="column"
                category="Opening Hours"
                info={t(
                  `challengeList:${isDC ? 'dcSchedule' : 'schedule'}`,
                  isDC ? 'Everyday' : '6am to 10pm, Monday to Saturday'
                )}
              />
            </div>
            {!isMobile && detailComponent}
          </div>
        </div>
        {isMobile && (
          <div className="bottom-content">
            {detailComponent}
            {rightSide(propChallengeStatus, true)}
          </div>
        )}
      </LeftContent>
    );
  };

  return (
    <Container data-cy={challengeType}>
      <Modal show={showEarnMoreCPsModal} onHide={handleHideModal} centered>
        <EarnMoreCPsModal onHide={handleHideModal} />
      </Modal>
      {isLocked && isMobile && (
        <UnlockContent>
          <img src={cpIcon} alt="CPs" style={{ marginRight: '0.75rem' }} />
          <p className="text">
            200 CP minimum requirement to unlock the {challengeName} below.
          </p>
        </UnlockContent>
      )}
      <Card
        rightWidth="25%"
        infoSection={leftSide(challengeStatus)}
        buttonSection={rightSide(challengeStatus)}
        hideButton={isMobile}
        leftWidth={isMobile ? '100%' : '85%'}
        isDisabled={
          challengeType === 'super-vision-challenge'
            ? superHeroChallengeStatusIDs[
                superHeroChallengeWithoutImageData &&
                  superHeroChallengeWithoutImageData.Supervision.Status
              ] === 'locked'
            : challengeType === 'super-speed-challenge' &&
              superHeroChallengeStatusIDs[
                superHeroChallengeWithoutImageData &&
                  superHeroChallengeWithoutImageData.Superspeed.Status
              ] === 'locked'
        }
      />
    </Container>
  );
};

export default ChallengeCard;
