import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'components/Assignment/AssignmentView/Header';
import QnSideBar from 'components/Assignment/AssignmentView/QnSideBar';
import Spinner from 'components/Shared/Spinner';
import QuestionContent from 'components/Question';
import ErrorModal from 'components/Shared/ErrorModal';
import {
  getKooQuizSolutions,
  setKooQuizActiveQuestion,
} from 'store/kooQuiz/kooQuizSlice';
import { questionTypeIDs, scoreColorsMap } from 'constants/index';
import moment from 'moment';
import VideoButtonImg from 'assets/img/kooquiz/kooQuizQn/video-button.png';
import { isNil } from 'ramda';
import ModalVideo from 'react-modal-video';
import useKooQuizRevision from 'hooks/useKooQuizRevision';
import { kooQuizPaperTypeInternal } from 'constants/kooQuiz';
import {
  Container,
  Main,
  Content,
  QuestionContainer,
  ScoreContainer,
  CorrectAnswerSpan,
  QnHeader,
  InfoSection,
  SolutionButton,
} from './KooQuizSolution.styles';

export const subjectToIdMapping = {
  kooquizmaths: 1,
  kooquizsci: 2,
};

const parseOfflinePDF = (jsonString) => {
  let parsedObj = {};
  if (isNil(jsonString)) return parsedObj;
  try {
    parsedObj = JSON.parse(jsonString);
  } catch (err) {
    console.log(err.message);
  }
  return parsedObj;
};

const KooQuizSolution = () => {
  const { t } = useTranslation(['assignmentSolution']);
  const dispatch = useDispatch();
  const { subject, kooQuizId, kooQuizSubmissionID, paper } = useParams();
  const paperVal = parseInt(paper, 10);
  const subjectId = subjectToIdMapping[subject?.toLowerCase()] ?? null;
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const hasPrint = query.get('print')?.toLowerCase() !== 'false';
  const {
    isSolutionsLoading,
    error,
    kooQuizSolutions,
    kooQuizLocalSavedAnswers,
    kooQuizActiveQuestion,
    paperById,
  } = useSelector((state) => state.kooQuiz);
  const offlinePDFData = parseOfflinePDF(paperById?.OfflinePDF);
  const hasSolutionPDFLink =
    offlinePDFData?.Solution && offlinePDFData?.Solution.length > 0;
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoId, setVideoId] = useState('');

  const kooQuizTitle = paperById !== null ? paperById.Name : '';
  const score = kooQuizSolutions
    ? Math.floor(
        (kooQuizSolutions.CorrectAnswers / kooQuizSolutions.TotalQuestions) *
          100
      )
    : 0;
  const refs = kooQuizSolutions
    ? kooQuizSolutions.UserQuestionSubmissions.reduce((acc, value) => {
        acc[value.Question.Id] = React.createRef();
        return acc;
      }, {})
    : null;
  // Event Handlers
  const scrollTo = (questionID) => {
    refs[questionID].current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };
  const playVideoHandler = (videoId) => {
    return () => {
      setVideoId(videoId);
      setShowVideoModal(true);
    };
  };
  const openSolutionPDFHandler = (url) => {
    return () => {
      window
        .open(
          `https://s3.ap-southeast-1.amazonaws.com/static.koobits/${url}`,
          '_blank'
        )
        .focus();
    };
  };

  // UseEffects
  useEffect(() => {
    if (kooQuizSubmissionID) dispatch(getKooQuizSolutions(kooQuizSubmissionID));
    return () => {
      dispatch(setKooQuizActiveQuestion(1));
    };
  }, [dispatch, kooQuizSubmissionID]);
  useKooQuizRevision(subjectId, kooQuizId, paperVal, true);

  const reloadHandler = () => {
    dispatch(getKooQuizSolutions(kooQuizSubmissionID));
  };

  return (
    <Container>
      <Header
        type={subject}
        page="solution"
        assignmentTitle={kooQuizTitle}
        noNavBar
        showPrint={hasPrint}
        metaData={{
          subjectid: subjectId,
          papertypeid: kooQuizPaperTypeInternal[paperById?.Type],
        }}
      />
      <Main>
        {!isSolutionsLoading && kooQuizSolutions && error === null && (
          <QnSideBar
            localSavedAnswers={kooQuizLocalSavedAnswers}
            questions={kooQuizSolutions.UserQuestionSubmissions}
            activeQuestion={kooQuizActiveQuestion}
            page="solution"
            scrollToHandler={scrollTo}
            assignmentType={subject}
          />
        )}
        <Content assignmentType={subject}>
          {isSolutionsLoading && <Spinner color="#26B1EF" top="35%" />}
          {!isSolutionsLoading && error !== null && (
            <ErrorModal reloadAction={reloadHandler} errorMessage={error} />
          )}
          {!isSolutionsLoading && kooQuizSolutions && error === null && (
            <>
              <ModalVideo
                channel="youtube"
                isOpen={showVideoModal}
                youtube={{
                  autoplay: 1,
                  mute: 0,
                  rel: 0,
                }}
                videoId={videoId}
                onClose={() => setShowVideoModal(false)}
              />
              <div className="notebook-wrap">
                <div className="notebook">
                  <div className="top">
                    <div className="top-content">
                      <InfoSection>
                        <div className="left">
                          <p className="title">
                            {t(
                              'assignmentSolution:words.TotalScore',
                              'Total Score'
                            )}
                          </p>
                          <ScoreContainer color={scoreColorsMap(score)}>
                            <p className="number">{score}</p>
                            <p className="char">%</p>
                          </ScoreContainer>
                          <div className="qn-number">
                            <div style={{ display: 'flex' }}>
                              ({' '}
                              <CorrectAnswerSpan color={scoreColorsMap(score)}>
                                {kooQuizSolutions.CorrectAnswers}
                              </CorrectAnswerSpan>
                              <p>
                                {t(
                                  'assignmentSolution:words.OutOf',
                                  ' out of '
                                )}
                                <strong>
                                  {kooQuizSolutions.TotalQuestions}
                                </strong>
                              </p>
                              )
                            </div>
                          </div>
                          <p className="completed-info">
                            {t(
                              'assignmentSolution:words.CompletedOn',
                              ' Completed on '
                            )}
                            {moment(kooQuizSolutions.LastCompletedDate).format(
                              'DD-MMM-YYYY'
                            )}
                          </p>
                        </div>
                        {hasSolutionPDFLink && (
                          <SolutionButton
                            onClick={openSolutionPDFHandler(
                              offlinePDFData?.Solution
                            )}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="37.561"
                              height="37.561"
                              viewBox="0 0 37.561 37.561"
                            >
                              <g
                                id="Group_27175"
                                data-name="Group 27175"
                                transform="translate(-858 -317)"
                              >
                                <circle
                                  id="Ellipse_2328"
                                  data-name="Ellipse 2328"
                                  cx="18.781"
                                  cy="18.781"
                                  r="18.781"
                                  transform="translate(858 317)"
                                  fill="#0080d3"
                                />
                                <g
                                  id="Group_26588"
                                  data-name="Group 26588"
                                  transform="translate(865.365 325.387)"
                                >
                                  <path
                                    id="Path_13458"
                                    data-name="Path 13458"
                                    d="M483.779,380.221v4.294h11.168v-7.157H483.779Zm1.4-1.431h8.376v1.431h-8.376Zm0,2.863h8.376v1.431h-8.376Z"
                                    transform="translate(-478.195 -363.045)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_13459"
                                    data-name="Path 13459"
                                    d="M494.947,342.322v-4.294H483.779v7.157h11.168Z"
                                    transform="translate(-478.195 -338.028)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_13460"
                                    data-name="Path 13460"
                                    d="M487.59,349.828h-1.4v4.294h-13.96v-4.294h-1.4a3.041,3.041,0,0,0-2.792,2.862v7.157a3.041,3.041,0,0,0,2.792,2.862h1.4v-4.294h13.96v4.294h1.4a3.041,3.041,0,0,0,2.792-2.862V352.69A3.041,3.041,0,0,0,487.59,349.828Z"
                                    transform="translate(-468.046 -345.533)"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </svg>
                            <span className="solution-button--text">
                              {t(
                                'assignmentSolution:words.detailedSolution',
                                'Detailed Solution'
                              )}
                            </span>
                          </SolutionButton>
                        )}
                      </InfoSection>
                    </div>
                  </div>
                  <div className="middle">
                    <div className="content">
                      {kooQuizSolutions.UserQuestionSubmissions.map(
                        (question, index) => (
                          <QuestionContainer
                            key={question.Question.Id}
                            ref={refs[question.Question.Id]}
                            isLastQn={
                              kooQuizSolutions.UserQuestionSubmissions
                                .length ===
                              index + 1
                            }
                          >
                            <QnHeader>
                              <p className="qn-no">
                                {t(
                                  'assignmentSolution:words.Question',
                                  'Question'
                                )}{' '}
                                {index + 1}
                              </p>
                              <span className="qn-id">{`Qn ID ${
                                question.Question.Id ?? ''
                              }`}</span>
                              {!isNil(question.Question.QuestionVideoCode) &&
                                question.Question.QuestionVideoCode?.length >
                                  0 && (
                                  <img
                                    src={VideoButtonImg}
                                    alt="Video"
                                    className="video-button"
                                    onClick={playVideoHandler(
                                      question.Question.QuestionVideoCode
                                    )}
                                  />
                                )}
                            </QnHeader>
                            <QuestionContent
                              page="solution"
                              questionType={
                                questionTypeIDs[question.Question.QuestionType]
                              }
                              qnID={question.Question.Id}
                              xml={question.SubmissionModel.Payload}
                              submissionModel={question.SubmissionModel}
                              showCheckAnswer={false}
                              showWorking
                              solutionString={question.SubmissionModel.Solution}
                              showSolution
                              showSubmissions
                              qnIndex={index}
                              assignmentType={subject}
                              skillID={question.Question.SkillId}
                              showFeedbackButton
                            />
                          </QuestionContainer>
                        )
                      )}
                    </div>
                  </div>
                  <div className="bottom" />
                </div>
              </div>
            </>
          )}
        </Content>
      </Main>
    </Container>
  );
};

export default KooQuizSolution;
