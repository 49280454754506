import React, { useState } from 'react';
import Placeholder from 'assets/img/question/image-placeholder.png';

const ImageLoader = ({ imgSrc, id }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleImgLoaded = () => {
    setIsLoaded(true);
  };

  return (
    <img
      key={id}
      src={isLoaded ? imgSrc : Placeholder}
      alt=""
      onLoad={handleImgLoaded}
      loading="lazy"
      style={{ maxWidth: isLoaded ? '100%' : '576px' }}
    />
  );
};

export default ImageLoader;
