import styled from 'styled-components';

export const StyledName = styled.p`
  font-weight: 500;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: none;
  }
`;

export const StyledImage = styled.img`
  margin: 0rem 0.4rem;
  border: 3px solid #ffffff;
  width: 36px;
  height: 36px;
  border-radius: 100%;

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin: 0rem 0.8rem;
    height: 68px;
    width: 50px;
    height: 50px;
  }
`;

export const StyledCountry = styled.img`
  margin-top: 5px;
`;

export const StyledVersusIcon = styled.span`
  margin: 2px;
  padding-top: 10px;
  img {
    width: 32px;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      margin: 10px;
      img {
        width: 41px;
      }
    }
  }
`;

export default {};
