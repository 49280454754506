import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchMainSuperheroPageData,
  fetchChallengeHistory,
} from 'services/superhero';
import { fetchWrapper } from 'services/login';

import orderBy from 'lodash/orderBy';

// ASYNC THUNKS
export const getMainSuperheroPageData = createAsyncThunk(
  'superheroChallenge/getMainSuperheroPageData',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchMainSuperheroPageData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get superhero data failed');
    }
  }
);

export const getHistory = createAsyncThunk(
  'superheroChallenge/getHistory',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchChallengeHistory, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get superhero data failed');
    }
  }
);

const initialState = {
  superSpeed: {},
  superVision: {},
  history: [],
  week: 0,
  isLoading: false,
  error: null,
};

const superHeroChallengeList = createSlice({
  name: 'superHeroChallengeList',
  initialState,
  reducers: {
    clearHistory: (state) => {
      state.history = initialState.history;
    },
  },
  extraReducers: {
    [getMainSuperheroPageData.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getMainSuperheroPageData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.superSpeed = action.payload.Superspeed;
      state.superVision = action.payload.Supervision;
    },
    [getMainSuperheroPageData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getHistory.pending]: (state, action) => {
      const { week } = action.meta.arg;
      state.isLoading = true;
      state.week = week;
      state.error = null;
    },
    [getHistory.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { week } = action.meta.arg;
      state.week = week;
      if (week === 0) {
        state.history = orderBy(action.payload, 'Date', 'desc');
      } else {
        state.history = [
          ...state.history,
          ...orderBy(action.payload, 'Date', 'desc'),
        ];
      }
    },
    [getHistory.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { reducer, actions } = superHeroChallengeList;
export const { clearHistory } = actions;
export default reducer;
