import React from 'react';
import { Container, Title } from './ChapterTitle.styles';

const ChapterTitle = ({ data, type, className }) => {
  return (
    <Container type={type} className={className}>
      <Title>{data.Title}</Title>
    </Container>
  );
};

export default React.memo(ChapterTitle);
