import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';

import { activityType, activityIcon } from 'constants/kooClass';
import {
  resetCurrentActivity,
  resetStartActivity,
  resetActivity,
  getLessonByLessonId,
} from 'store/kooClass/kooClassSlice';

import Button from 'components/Shared/Button';
import ScrollIcon from 'assets/img/icon-scroll.svg';
import AllLessonIcon from 'assets/img/kooclass/activity/icon-all-lessons.svg';
import { ProgressBar } from 'react-bootstrap';
import ActivityNavItem from './ActivityNavItem';

import { Container, LessonDetail } from './ActivityNav.styles';

const ActivityNav = ({
  data,
  activeId,
  setActiveId,
  setActiveType,
  setActivityName,
  setShowVideo,
  courseId,
  subject,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['activities', 'common']);
  const { lessonId, topicId } = useParams();
  const { currentLesson, activities, currentLessonErr } = useSelector(
    (state) => state.kooClass
  );
  const completed =
    currentLesson && currentLesson.currentUserPoint === currentLesson.maxpoints;

  // remove when endpoint is fix
  const filterActivitiesWithScroll =
    activities && activities.filter((item) => item.maxPoints > 0);
  const totalScroll =
    filterActivitiesWithScroll && filterActivitiesWithScroll.length > 0
      ? filterActivitiesWithScroll.reduce((prev, curr) => {
          return prev + curr.currentUserPoints;
        }, 0)
      : 0;

  return (
    <Container>
      <LessonDetail completed={completed}>
        {currentLesson && (
          <>
            <div className="btn-section">
              <Button
                variant="purple-secondary"
                fontSize="14px"
                width="150px"
                fontWeight="600"
                onClick={() => {
                  dispatch(resetCurrentActivity());
                  dispatch(resetActivity());
                  history.push(
                    `/kooClass/lessons/${topicId}?courseId=${courseId}`
                  );
                }}
                dataCy="all-lesson"
              >
                {t('activities:activityNav.allLessons', 'All Lessons ')}
                <span>
                  <img src={AllLessonIcon} alt="all-lessons" />
                </span>
              </Button>
            </div>
            <div className="info-section">
              <>
                <div className="info-detail">
                  <p className="lesson-no">{currentLesson.name}</p>
                  <p className="lesson-desc">{currentLesson.description}</p>
                </div>
                <div className="scroll-detail">
                  <div className="scroll-progress">
                    <p className="scroll-points">
                      {totalScroll} <span>/ {currentLesson.maxpoints}</span>
                    </p>
                    <div className="scroll-bar">
                      <div className="progress-bar-section">
                        <ProgressBar
                          variant="correct"
                          now={(totalScroll / currentLesson.maxpoints) * 100}
                          key={1}
                        />
                      </div>
                      <div className="round-bg">
                        <img src={ScrollIcon} alt="scroll" />
                      </div>
                    </div>
                  </div>
                  <p className="total-scroll">
                    {currentLesson.maxpoints}{' '}
                    {t('activities:activityNav.scroll', 'Scroll')}
                  </p>
                </div>
              </>
            </div>
          </>
        )}
        {!isNil(currentLessonErr) && (
          <>
            <p className="error">
              {t('common:sorry', 'Sorry')},{' '}
              {currentLessonErr.toLowerCase() === 'unknown error'
                ? t('errorModal:someWrong', 'Something went wrong')
                : currentLessonErr}
            </p>
            <Button
              variant="purple-secondary"
              fontSize="14px"
              width="150px"
              fontWeight="600"
              onClick={() => dispatch(getLessonByLessonId(lessonId))}
              dataCy="reload-btn"
            >
              {t('common:reload', 'Reload')}
            </Button>
          </>
        )}
      </LessonDetail>
      <div className="activity-items">
        {data &&
          data.map((item) => (
            <ActivityNavItem
              key={item.id}
              active={activeId === item.id}
              id={item.id}
              activityName={item.name}
              icon={
                isNil(item.activityTypeImage)
                  ? activityIcon(activityType[item.activityType], item.name)
                  : item.activityTypeImage
              }
              totalScroll={item.maxPoints}
              scroll={item.currentUserPoints}
              onClick={() => {
                if (activeId !== item.id) {
                  dispatch(setActiveId(item.id));
                  dispatch(setActiveType(item.activityType));
                  dispatch(setActivityName(item.activityName));
                  setShowVideo(false);
                  dispatch(resetCurrentActivity());
                  dispatch(resetStartActivity());
                  history.push(
                    `/kooClass/${topicId}/lesson/${lessonId}/activity?activityType=${item.activityType}&activityId=${item.id}&name=${item.name}&courseId=${courseId}`
                  );
                } else if (activityType[item.activityType] === 'Video')
                  setShowVideo(false);
              }}
            />
          ))}
      </div>
    </Container>
  );
};

export default ActivityNav;
