import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionById } from 'store/assignment/assignmentParentSlice';
import HTMLReactParser from 'html-react-parser';
import convertTagsToHTML from 'helpers/convertTagsToHtml';
import { isNil } from 'ramda';
import xmlParser from 'helpers/xmlParser';
import { ProficiencyStatus } from 'constants/report';
import { proficiencyColors } from 'constants/parentAssignment';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';
import Button from 'components/Shared/Button';
import useIsMobile from 'hooks/responsive/useIsMobile';
import BottomModal from 'components/Shared/BottomModal';
import {
  SkillBadge,
  SkillDetailsContainer,
  SkillDetailsHeader,
  QuestionContainer,
  SolutionContainer,
} from './SkillPanel.styles';
import { ButtonBox, QuestionColumn } from '../SkillRow/SkillRow.styles';

const SkillDetailsQuestion = ({ data, showSolution }) => {
  const { t } = useTranslation(['proficiencyReport']);
  // States
  const [questionTitle, setQuestionTitle] = useState(null);
  const [questionSolution, setQuestionSolution] = useState(null);

  // Effects
  useEffect(() => {
    if (!isNil(data.Payload)) {
      const parsed = xmlParser(data.Payload);
      setQuestionTitle(
        HTMLReactParser(convertTagsToHTML(parsed.questionContent), {
          replace: (domNode) => {
            if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
              return <img src={domNode.attribs?.url} alt="unique-id" />;
            }
            if (domNode.name === 'helptext') {
              return (
                <small id="questionHelp" className="form-text text-muted">
                  {domNode.children[0]?.data ?? ''}
                </small>
              );
            }
            if (domNode.name === 'input') {
              return <input type="text" />;
            }
            return domNode;
          },
        })
      );

      setQuestionSolution(HTMLReactParser(convertTagsToHTML(parsed.solution)));
    }
  }, [data.Payload]);
  return (
    <>
      <QuestionContainer>
        <p className="question-title">
          {t(`proficiencyReport:studentReportSide.sampleQs`, 'Sample Question')}{' '}
          ( ID: {data.Id} )
        </p>
        <p className="question-content">{questionTitle}</p>
      </QuestionContainer>
      {showSolution === true && (
        <SolutionContainer>
          <p className="solution-title">
            {t(`proficiencyReport:studentReportSide.solution`, 'Solution')}
          </p>
          <p className="solution-content">{questionSolution}</p>
        </SolutionContainer>
      )}
    </>
  );
};

const findSkillIndex = (selectedTopic, selectedSkill) => {
  return selectedTopic.Skills.findIndex(
    (skill) => skill.SkillId === selectedSkill.SkillId
  );
};

const SkillPanel = ({
  selectedSkill,
  setSelectedSkill,
  setShowSkillDetails,
  totalQuestions,
  selectedTopic,
  setSelectedTopic,
  topics,
  setTopics,
  setTotalQuestions,
  showSolution,
}) => {
  const { t } = useTranslation(['proficiencyReport', 'parentAssign', 'common']);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isLoading, sampleQn, sampleQnError } = useSelector(
    (state) => state.assignmentParent
  );
  const [skillIndex, setSkillIndex] = useState(0);

  const { questionNumber, ProficiencyStatus: status } = selectedSkill;
  const currentQuestion = questionNumber || 0;
  const res = ProficiencyStatus[status];

  useEffect(() => {
    const currentIndex = findSkillIndex(selectedTopic, selectedSkill);
    setSkillIndex(currentIndex + 1);
  }, [selectedTopic, selectedSkill]);

  const updateQuestionNumber = (number) => {
    const newNumber = currentQuestion + number;
    const newTotalQuestions = totalQuestions + number;

    const newSkills = selectedTopic.Skills.map((skill) =>
      skill.SkillId === selectedSkill.SkillId
        ? { ...skill, questionNumber: newNumber }
        : { ...skill, questionNumber: skill.questionNumber || 0 }
    );
    setSelectedSkill({ ...selectedSkill, questionNumber: newNumber });

    const totalQuestionNumber = newSkills.reduce((prev, curr) => {
      return prev + curr.questionNumber;
    }, 0);

    const newTopic = {
      ...selectedTopic,
      Skills: newSkills,
      totalQuestionNumber,
    };
    setSelectedTopic(newTopic);
    const updatedTopics = topics.map((topic) =>
      topic.TopicId === newTopic.TopicId ? newTopic : topic
    );
    setTopics(updatedTopics);
    setTotalQuestions(newTotalQuestions);
  };

  useEffect(() => {
    const params = {
      id: selectedSkill.SampleQuestionID,
    };
    dispatch(getQuestionById(params));
  }, [dispatch, selectedSkill.SampleQuestionID]);
  const handleReloadSampleQnAction = () => {
    const params = {
      id: selectedSkill.SampleQuestionID,
    };
    dispatch(getQuestionById(params));
  };

  const handleClose = () => {
    setShowSkillDetails(false);
    setSelectedSkill(null);
  };

  const closeButtonComponent = () => {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <span onClick={handleClose} className="close-panel">
        x
      </span>
    );
  };

  const descComponent = (
    <>
      <SkillDetailsHeader
        className="skill-panel"
        statusColor={proficiencyColors[ProficiencyStatus[status]]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <p className="skill-name">
            {t('parentAssign:content.skill', 'Skill')} {skillIndex}
          </p>
          {!isMobile && closeButtonComponent()}
        </div>
        <div style={{ marginBottom: '0.5rem' }}>
          <p className="skill-description">{selectedSkill.SkillName}</p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ marginBottom: '0.5rem' }}>
            <span className="student-percentage">
              <strong>{`${Math.round(
                selectedSkill.Proficiency * 100
              )}%`}</strong>
            </span>
            <SkillBadge statusColor={proficiencyColors[res]}>
              {t(
                `proficiencyReport:legend.${res}`,
                `${capitalizeFirstLetter(res)}`
              )}
            </SkillBadge>
          </div>
          <QuestionColumn skillPanel number={currentQuestion > 0}>
            <ButtonBox
              className={`button-down-${selectedSkill.SkillId}`}
              disabled={currentQuestion === 0}
              onClick={() => updateQuestionNumber(-1)}
            >
              -
            </ButtonBox>
            <div className="question">{currentQuestion}</div>
            <ButtonBox
              className={`button-up-${selectedSkill.SkillId}`}
              disabled={currentQuestion === 10 || totalQuestions === 50}
              onClick={() => updateQuestionNumber(1)}
            >
              +
            </ButtonBox>
            <div className="question-text">
              {t('parentAssign:content.qn', 'Qn(s)')}
            </div>
          </QuestionColumn>
        </div>
      </SkillDetailsHeader>
      {!isLoading && sampleQn && (
        <SkillDetailsQuestion data={sampleQn} showSolution={showSolution} />
      )}
      {!isLoading && sampleQnError && (
        <QuestionContainer>
          <p className="question-title">
            {t(
              `proficiencyReport:studentReportSide.sampleQs`,
              'Sample Question'
            )}
          </p>
          <span className="question-content">
            {sampleQnError.toLowerCase() === 'Unknown error'
              ? `${t('common:sorry', 'Sorry')}, ${t(
                  'errorModal:someWrong',
                  'Something went wrong'
                )}`
              : sampleQnError}
          </span>
          <Button
            dataCy="reload-btn"
            className="primary"
            margin="20px 0"
            onClick={handleReloadSampleQnAction}
          >
            {t('common:reload', 'Reload')}
          </Button>
        </QuestionContainer>
      )}
    </>
  );

  return isMobile ? (
    <BottomModal isOpen handleClose={handleClose} height="85%" padding="0px">
      {descComponent}
    </BottomModal>
  ) : (
    <SkillDetailsContainer>{descComponent}</SkillDetailsContainer>
  );
};

export default SkillPanel;
