import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import {
  getIncomingBFFRequests,
  acceptInvitation,
  rejectInvitation,
  resetInvitation,
  resetIncomingPageError,
} from 'store/friends/friendsSlice';
import moment from 'moment-timezone';
import PopupModal from 'components/Shared/PopupModal';
import { SubmitModal } from 'components/Shared';
import Spinner from 'components/Shared/Spinner';
import FriendCard from 'components/Friends/FriendCard';
import Pagination from 'components/Shared/Pagination';
import ErrorModal from 'components/Shared/ErrorModal';
import {
  Main,
  Incomingbutton,
  Button,
  ShowDate,
  EmptyList,
} from './Incoming.styles';

const ButtonsSection = ({ reject, accept }) => {
  const { t } = useTranslation(['friends']);
  return (
    <Incomingbutton>
      <Button accept={false} onClick={reject}>
        {t('friends:incomingRequests.noThanks', 'No, Thanks.')}
      </Button>
      <Button accept onClick={accept}>
        {t('friends:incomingRequests.accept', 'Accept')}
      </Button>
    </Incomingbutton>
  );
};

const perPage = 10;

const Incoming = () => {
  const { t } = useTranslation(['friends']);
  const dispatch = useDispatch();
  const {
    incomingLoading,
    incomingErr,
    incomingRequests,
    incomingLists,
    invitations,
    invitationErr,
  } = useSelector((state) => state.friends);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [debouncedSearchPage] = useDebounce(searchPage, 500);
  const [showModal, setShowModal] = useState(false);
  const [reqType, setReqType] = useState(null);
  const [code, setCode] = useState(null);
  const openModal = (val, bffCode) => {
    setCode(bffCode);
    setReqType(val);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const yesHandle = () => {
    if (reqType === 'accept') dispatch(acceptInvitation(code));
    else dispatch(rejectInvitation(code));
    setShowModal(false);
  };

  useEffect(() => {
    if (debouncedSearchPage) {
      callIncomingInvitations();
      setPage(parseInt(debouncedSearchPage, 10));
    }
    // eslint-disable-next-line
  }, [debouncedSearchPage]);
  useEffect(() => {
    if (invitations) {
      callIncomingInvitations();
      dispatch(resetInvitation());
    }
    // eslint-disable-next-line
  }, [invitations]);
  // Clean up
  useEffect(() => {
    return () => {
      dispatch(resetIncomingPageError());
    };
  }, [dispatch]);
  const onPageChange = (number) => {
    setPage(number);
    setSearchPage(number);
  };

  let maxPage = 0;
  if (incomingRequests && incomingRequests.TotalPages) {
    maxPage = incomingRequests.TotalPages;
  }
  const onSearchPageChange = (pageNumber) => {
    if (pageNumber <= maxPage) {
      setSearchPage(pageNumber);
    } else {
      setSearchPage(page);
    }
  };

  const callIncomingInvitations = () => {
    const params = {
      pageIndex: debouncedSearchPage - 1,
      pageSize: perPage,
    };
    dispatch(getIncomingBFFRequests(params));
  };
  const reloadHandler = () => {
    if (incomingErr) {
      callIncomingInvitations();
    }
    if (invitationErr) {
      yesHandle();
    }
  };

  const renderError = () => {
    if (!incomingLoading) {
      if (incomingErr !== null || invitationErr !== null) {
        if (incomingErr === 'No Data Found') {
          return (
            <EmptyList>
              <h4>
                {t(
                  'friends:incomingRequests.noIncoming',
                  'There is no incoming BFF request at the moment.'
                )}
              </h4>
              <div>
                {t(
                  'friends:incomingRequests.incomingMsg',
                  'You will see the list of request from your BFFs here.'
                )}
              </div>
            </EmptyList>
          );
        }
        return (
          <ErrorModal
            errorMessage={incomingErr || invitationErr}
            reloadAction={reloadHandler}
          />
        );
      }
    }
  };
  return (
    <>
      <PopupModal show={showModal} backdrop="static">
        <SubmitModal
          header={`${
            reqType === 'accept'
              ? t('friends:incomingRequests.accept', 'Accept')
              : t('friends:incomingRequests.reject', 'Reject')
          } ${t('friends:incomingRequests.invitation', 'Invitation')}`}
          title={t(
            'friends:incomingRequests.confirmation',
            { type: reqType },
            `Are you sure want to ${reqType} this invitation?`
          )}
          desc=""
          noHandle={closeModal}
          yesHandle={yesHandle}
        />
      </PopupModal>
      <Main>
        {incomingLoading && <Spinner />}
        {!incomingLoading &&
          incomingErr === null &&
          incomingLists.map((friend) => (
            <Fragment key={friend.Userid}>
              <FriendCard
                friendDetails={{
                  avatar: friend.UserAvatarImage,
                  name: friend.Fullname,
                  schoolName: friend.SchoolName,
                  schoolLogo: friend.SchoolLogoImage,
                  BFFCode: friend.BFFCode,
                }}
                rightSection={
                  <ButtonsSection
                    reject={() => openModal('reject', friend.BFFCode)}
                    accept={() => openModal('accept', friend.BFFCode)}
                  />
                }
              />
              <ShowDate>
                {moment(friend.requestinfo.ActionTakenDateTime).format(
                  'DD-MMM-YYYY HH:mm'
                )}
              </ShowDate>
            </Fragment>
          ))}
        {!incomingLoading && incomingErr === null && (
          <Pagination
            maxPage={maxPage}
            onPageChange={onPageChange}
            onSearchPageChange={onSearchPageChange}
            page={page}
            searchPage={searchPage}
          />
        )}
        {renderError()}
      </Main>
    </>
  );
};

export default Incoming;
