import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  .desc {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    @media (max-width: 950px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    p {
      margin-left: 0.5rem;
      margin-bottom: 0rem;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    .scroll-rewards {
      display: flex;
      p {
        margin-bottom: 0rem;
      }
    }
    .score {
      display: flex;
      align-items: baseline;
      .percentage {
        font-family: Pacifico;
        color: #334042;
        font-size: 47px;
        margin-bottom: 0;
        line-height: 0;
        span {
          font-size: 29px;
          margin-left: -0.6rem;
        }
      }
      .score-detail {
        color: #95c9d2;
        font-weight: 600;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.5rem;
        span {
          color: #334042;
          font-size: 20px;
          font-weight: bold;
        }
        p {
          margin-bottom: 0px;
          font-size: 14px;
        }
      }
    }
  }
  hr {
    border-top: 1px dashed grey;
    margin: 0.5rem 0rem;
  }
  .details-box {
    width: 80%;
    padding: 1rem 5%;
    border: 1px solid grey;
    margin: 2rem 0px;
  }
  .start-btn {
    min-width: ${(props) => (props.status ? '50%' : '30%')};
    display: flex;
    justify-content: ${(props) =>
      props.status === 'Completed' && props.score > 0
        ? 'space-between'
        : 'center'};
    @media (max-width: 1024px) {
      min-width: ${(props) => (props.status ? '70%' : '30%')};
    }
  }
  .reload {
    p {
      margin-bottom: 0px;
      margin-right: 0px;
      color: #ff5d01;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
