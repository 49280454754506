/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Mission } from 'assets/img/access-menu/mission.svg';
import Button from 'components/Shared/Button';
import { Container, KokoDescription } from './NotEnoughKokoModal.styles';

const NotEnoughKokoModal = ({ yesHandle, error }) => {
  const { t } = useTranslation(['common', 'dailyChallenge', 'storyScience']);

  return (
    <Container error={error}>
      <div className="header-section">
        {t(
          'dailyChallenge:unlockStickerModal.header2',
          'Not Enough KoKo Credits'
        )}
      </div>
      <div className="body-section">
        <div className="modal-desc">
          <KokoDescription>
            <span className="description">
              {t('storyScience:insufficientKoKo', 'You can earn more at')}
            </span>
            <Button
              variant="blue4"
              padding="0.4rem 1rem"
              margin="0rem 0.2rem"
              onClick={() => {
                window.open('/mission/curriculum-list', '_blank');
              }}
              fontSize="14px"
            >
              <p className="mission-text">
                <span>
                  <Mission className="mission-icon" />
                </span>
                {t('storyScience:mission', 'Mission')}
              </p>
            </Button>
          </KokoDescription>
        </div>
        <div className="modal-buttons">
          <button type="button" className="ok-btn btns" onClick={yesHandle}>
            OK
          </button>
        </div>
      </div>
    </Container>
  );
};

export default NotEnoughKokoModal;
