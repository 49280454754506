import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './ScoreBoard.styles';

const ScoreBoard = ({
  color,
  score,
  totalQn,
  correct,
  renderTotalTime,
  isMobile,
}) => {
  const { t } = useTranslation(['assignmentFinished']);

  return (
    <Container color={color}>
      {!isMobile && (
        <div className="title">
          {t('assignmentFinished:homework.Total Score', 'Total Score')}
        </div>
      )}
      <div className="score-value">
        <div className="score-percentage">
          <p>{score}</p>
          <span>%</span>
        </div>
        <div className="status-info">
          {correct}{' '}
          <span>
            {t('assignmentFinished:homework.Out of', 'out of')} {totalQn}
          </span>
        </div>
        {renderTotalTime()}
      </div>
    </Container>
  );
};

export default ScoreBoard;
