import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { history } from 'Routes';
import {
  StartButton,
  Border,
  Container,
  OverlayContainer,
} from './DashboardCard.styles';

const Overlay = ({
  name,
  description,
  isFeatureReady,
  isPremium,
  btnColor,
}) => {
  const { t } = useTranslation(['dashboard']);
  return (
    <OverlayContainer isFeatureReady={isFeatureReady} isPremium={isPremium}>
      {isFeatureReady && (
        <>
          {!isPremium && <p>{description}</p>}
          {isPremium && <h4>{name}</h4>}
          <StartButton btnColor={btnColor}>
            {t('dashboard:dailyChallenge.button', 'Start')}
          </StartButton>
        </>
      )}
    </OverlayContainer>
  );
};

const DashboardCard = ({
  name,
  background,
  description,
  link,
  isFeatureReady,
  btnColor,
}) => {
  const { t } = useTranslation(['dashboard']);
  const { plan } = useSelector((state) => state.plan);
  const [showOverlay, setShowOverlay] = useState(false);

  const updateStoryName = () => {
    if (name === 'Story Science') {
      return t(`dashboard:cards.${name}.name`, 'Story');
    }
    return name === 'Story Math'
      ? t(`dashboard:cards.StoryMath.name`, 'Story')
      : t(`dashboard:cards.${name}.name`, name);
  };

  const updateDescription = () => {
    return name === 'Story Math'
      ? t(`dashboard:cards.StoryMath.description`, description)
      : t(`dashboard:cards.${name}.description`, description);
  };

  return (
    <Border
      className={name}
      isPremium={plan === 'premium'}
      onClick={() => {
        if (link) {
          history.push(link);
        }
      }}
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => setShowOverlay(false)}
    >
      <Container
        background={background}
        showOverlay={showOverlay}
        isFeatureReady={isFeatureReady}
        data-cy={name}
        name={name}
      >
        <div className="heading-cover">
          <span>{updateStoryName()}</span>
          {plan === 'premium' && (
            <div className="desc">{updateDescription()}</div>
          )}
        </div>
      </Container>
      {(showOverlay || isFeatureReady === false) && (
        <Overlay
          isFeatureReady={isFeatureReady}
          name={updateStoryName()}
          description={updateDescription()}
          isPremium={plan === 'premium'}
          btnColor={btnColor}
        />
      )}
    </Border>
  );
};

export default DashboardCard;
