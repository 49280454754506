/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const NavbarCollapseContainer = styled.div`
  display: flex;
  min-height: 59px;
  ${({ plan }) => {
    if (plan === 'school') {
      return css`
        margin-left: -12%;
      `;
    }
    return css`
      position: absolute;
      left: 53%;
      top: 0;
      transform: translateX(-50%);
    `;
  }}
  .navbar-nav {
    flex-direction: row;
    padding: 0px 15px;
    @media (max-width: 1300px) {
      padding: 0px 0px;
    }
    div {
      @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  span {
    @media (max-width: ${({ plan }) =>
        plan === 'school' ? '1023px' : '1300px'}) {
      display: none;
    }
  }
  @media (max-width: 1130px) {
    margin-left: 0;
  }
  @media (max-width: 1023px) {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  @media (max-width: 768px) {
    position: static;
    transform: none;
    ${({ plan }) => {
      if (plan === 'school') {
        return css`
          margin-left: auto;
        `;
      }
    }}
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    position: relative;
    left: auto;
    transform: none;
    margin-left: initial;
  }
`;
