import React from 'react';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Container, TabItem, TooltipIcon } from './Tabs.styles';

const Tabs = ({
  tabItems,
  reportType,
  setShowSkillDetails,
  setSelectedSkill,
  showSkillDetails,
  isMissionPlus,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['proficiencyReport']);

  return (
    <Container showSkillDetails={showSkillDetails}>
      {tabItems
        .filter((item) => (isMissionPlus ? item.id !== 2 : item))
        .map((item) => (
          <TabItem
            data-cy={item.name}
            key={item.id}
            isActive={item.key === reportType}
            onClick={() => {
              history.push(item.path);
              if (item.key !== reportType) {
                setShowSkillDetails(false);
                setSelectedSkill('');
              }
            }}
          >
            <span className="name">
              <img src={item.icon} alt="icon" />
              {t(
                `proficiencyReport:navigation.${item.translationKey}`,
                item.name
              )}
            </span>
            <OverlayTrigger
              overlay={
                <Tooltip key={item.id}>
                  {t(
                    `proficiencyReport:navigation.${item.toolTipTranslationKey}`,
                    item.toolTipText
                  )}
                </Tooltip>
              }
            >
              <TooltipIcon isActive={item.key === reportType}>i</TooltipIcon>
            </OverlayTrigger>
          </TabItem>
        ))}
    </Container>
  );
};

export default Tabs;
