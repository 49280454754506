import styled from 'styled-components';
import Bg from 'assets/img/events/bg.svg';
import Stars from 'assets/img/events/stars.svg';

export const Container = styled.div`
  background: url(${Bg});
  height: 73px;
  .stars {
    background: url(${Stars});
    background-size: cover;
    background-repeat: no-repeat;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      display: flex;
      p {
        margin-bottom: 0px;
        font-size: 32px;
        color: #ffffff;
        font-weight: 600;
        align-self: center;
        margin-left: 1.5rem;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
  }
`;
