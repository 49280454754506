import { useEffect, useRef } from 'react';

// function to close dropdown options when click outside
const useClickOutside = (ref) => {
  const domNode = useRef();
  useEffect(() => {
    const handler = (e) => {
      if (domNode.current && !domNode.current.contains(e.target)) {
        ref();
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
  return domNode;
};

export default useClickOutside;
