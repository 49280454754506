import React from 'react';
import { Container } from './Solution';

const SolutionFallback = () => {
  return (
    <Container data-cy="solution-data">
      <p style={{ color: 'red' }}>
        Solution cannot be rendered, please{' '}
        <a
          href="https://support.koobits.com/hc/en-gb/requests/new"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'red', textDecoration: 'underline' }}
        >
          contact our support
        </a>
        .
      </p>
    </Container>
  );
};

export default SolutionFallback;
