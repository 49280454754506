import React from 'react';
import { useTranslation } from 'react-i18next';

import GoldMedal from 'assets/img/mission/missionplus/gold-medal.svg';
import LockedMedal from 'assets/img/mission/missionplus/locked-medal.svg';

import { Container, Legend, LegendText } from './LegendMissionPlus.styles';

const LegendMissionPlus = ({ position, color = '#959595' }) => {
  const { t } = useTranslation(['selfPractice']);
  const legendList = [
    {
      id: 1,
      icon: LockedMedal,
      text: t('selfPractice:legend.lock', '0-7 correct'),
    },
    {
      id: 2,
      icon: GoldMedal,
      text: t('selfPractice:legend.gold', '8-10 correct'),
    },
  ];
  return (
    <Container position={position}>
      {legendList.map((item) => (
        <Legend key={item.id}>
          <img src={item.icon} alt="medal" />
          <LegendText color={color}>{item.text}</LegendText>
        </Legend>
      ))}
    </Container>
  );
};

export default LegendMissionPlus;
