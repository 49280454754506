import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Spinner from 'components/Shared/Spinner';
import KoKoIcon from 'assets/img/icon-koko-credits.svg';
import CongratsText from 'components/Assignment/AssignmentFinish/CongratsText';
import Button from 'components/Shared/Button';
import Mascot from 'assets/img/events/event-result-mascot.svg';
import {
  Container,
  ClaimKoKoContainer,
  PlusKoKoContainer,
  MascotContainer,
  CloseBtn,
  ErrorText,
} from './ClaimedKokoModal.styles';

const ClaimKoKo = ({ KoKoBalance, isLoading }) => {
  return (
    <ClaimKoKoContainer>
      <div className="claim">
        <p>You have earned</p>
        <img src={KoKoIcon} alt="KoKo" />
        <p className="amount">
          <span>{isLoading ? <Spinner small /> : KoKoBalance} </span>
          KoKo
        </p>
        <p>of the month</p>
      </div>
    </ClaimKoKoContainer>
  );
};

const PlusKoKo = ({ number }) => {
  return (
    <PlusKoKoContainer>
      <p className="fade-in">+{number}</p>
    </PlusKoKoContainer>
  );
};

const ClaimedKoKoModal = ({ setShowClaimed, claimedKoko }) => {
  const history = useHistory();
  const {
    isClaiming,
    claimKoKoCreditsResult,
    claimKoKoCreditsError,
  } = useSelector((state) => state.assignment);

  return (
    <>
      <MascotContainer className="mascot">
        <img src={Mascot} alt="mascot" />
      </MascotContainer>
      <Container>
        {isClaiming && <Spinner />}
        {!isClaiming &&
          claimKoKoCreditsError === null &&
          claimKoKoCreditsResult && (
            <>
              <div className="congrats-text">
                <CongratsText />
                <CloseBtn
                  onClick={() => {
                    setShowClaimed(false);
                  }}
                >
                  X
                </CloseBtn>
              </div>
              <p className="success-text">KoKos claimed successfully!</p>
              <PlusKoKo number={claimedKoko} />
              <ClaimKoKo
                KoKoBalance={
                  claimKoKoCreditsResult.CurrentMonthClaimedKokoCredits
                }
                isLoading={isClaiming}
              />
              <Button
                fontSize="22px"
                variant="primary"
                textShadow="0px 3px 6px rgba(0, 0, 0, 0.16)"
                width="270px"
                onClick={() => {
                  setShowClaimed(false);
                  history.push('/assignment/assignment-list');
                }}
              >
                Go to assignment
              </Button>
            </>
          )}
        {!isClaiming && claimKoKoCreditsError && (
          <ErrorText>
            <h3>Error</h3>
            <h4>{claimKoKoCreditsError}</h4>
          </ErrorText>
        )}
      </Container>
    </>
  );
};

export default ClaimedKoKoModal;
