import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, isEmpty } from 'ramda';
import {
  getStaticUserNotification,
  getUserNotification,
} from 'store/notification/notificationSlice';

import Header from 'components/Header';
import BackButton from 'components/Shared/BackButton';
import MissionCard from 'components/Dashboard/MissionCard';
import PracticeCard from 'components/Dashboard/PracticeCard';
import BannerNotification from 'components/Notifications/BannerNotification';

import { featureToggle } from 'constants/index';
import LeftBg from 'assets/img/dashboard/practice-left-bg.svg';
import RightBg from 'assets/img/dashboard/practice-right-bg1.png';

import { Container, SubCards, PageHeading } from './index.styles';

const Practice = () => {
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { notificationsDetail, bannerNotif, userNotification } = useSelector(
    (state) => state.notification
  );
  const {
    nonExpiredSubscriptionPlans,
    subscriptionData,
    defaultLevel,
    countryCode,
    studentDetails,
  } = useSelector((state) => state.studentDetails);
  useEffect(() => {
    if (
      !isNil(studentDetails) &&
      isNil(userNotification) &&
      !featureToggle.staticNotification
    ) {
      dispatch(
        getUserNotification({
          countryCode,
          defaultLevel,
          nonExpiredSubscriptionPlans,
          subscriptionData,
          allPlans: studentDetails.Subscriptions,
        })
      );
    }
    if (featureToggle.staticNotification && isEmpty(notificationsDetail)) {
      dispatch(getStaticUserNotification());
    }
  }, [
    countryCode,
    defaultLevel,
    dispatch,
    nonExpiredSubscriptionPlans,
    notificationsDetail,
    studentDetails,
    subscriptionData,
    userNotification,
  ]);

  return (
    <>
      <Header />
      <BannerNotification />
      <Container banner={!isNil(bannerNotif)}>
        <img src={LeftBg} className="left-bg" alt="left-bg" />
        <div className="back-btn">
          <BackButton
            backText={t('dashboardCards:back', 'Back')}
            btnBgColor="#0093FF"
            padding="5px 20px"
            onClick={() => history.push('/dashboard')}
          />
        </div>
        <PageHeading>
          {t(
            'dashboardCards:premiumType.practiceHeader',
            'Practice makes perfect'
          )}
        </PageHeading>
        <SubCards>
          <MissionCard isFeatureReady={featureToggle.mission} />
          <div className="right-side">
            <PracticeCard
              type="kooQuiz"
              url="/kooQuiz"
              heading={t('dashboardCards:kooQuiz.name', 'KooQuiz')}
              desc={t(
                'dashboardCards:kooQuiz.description',
                'Revisions made easy'
              )}
            />
            <PracticeCard
              type="assignment"
              url="/assignment"
              heading={t('dashboardCards:assignment.name', 'Assignment')}
              desc={t(
                'dashboardCards:assignment.description',
                'Complete weekly assignment to earn KoKo'
              )}
            />
          </div>
        </SubCards>
        <img src={RightBg} className="right-bg" alt="right-bg" />
      </Container>
    </>
  );
};

export default Practice;
