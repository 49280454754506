import React from 'react';
import { useTranslation } from 'react-i18next';
import QuestionContent from './QuestionContent';
import Container from './AssignmentContent.styles';

const AssignmentContent = ({ title, level, schoolName, questions, type }) => {
  const { t } = useTranslation(['assignmentQnView']);
  return (
    <Container>
      <div className="header">
        <p>{schoolName}</p>
        <p>{title}</p>
        {type !== 'kooQuiz' && (
          <p>
            {t('assignmentQnView:print.primary', 'Primary')} {level}
          </p>
        )}
      </div>
      <div className="info">
        <p>{t('assignmentQnView:print.name', 'Name')}: ________________</p>
        <p>{t('assignmentQnView:print.class', 'Class')}: ________________</p>
      </div>
      <div className="questions">
        {questions.length > 0 &&
          questions.map((question, index) => (
            <div key={`qn-${question.Id}`}>
              <QuestionContent data={question} qnNumber={index + 1} />
              <div className="page-break" />
            </div>
          ))}
      </div>
    </Container>
  );
};

export default AssignmentContent;
