import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { history } from 'Routes';
import Header from 'components/Header';

import { setHotsDisplayOrder } from 'store/mission/missionSlice';

import Brain from 'assets/img/mission/hotspot/brain-center.svg';

import DrawDiagrams from 'assets/img/mission/hotspot/drawdiagrams.svg';
import Makelist from 'assets/img/mission/hotspot/makelist.svg';
import WorkBackwards from 'assets/img/mission/hotspot/work-backwards.svg';
import LogicalReasoning from 'assets/img/mission/hotspot/logical-reasoning.svg';
import BeforeAfter from 'assets/img/mission/hotspot/beforeafter.svg';
import ActItOut from 'assets/img/mission/hotspot/actitout.svg';

import GuessCheck from 'assets/img/mission/hotspot/guesscheck.svg';
import MakeSuppositions from 'assets/img/mission/hotspot/makesuppositions.svg';
import LookForPatterns from 'assets/img/mission/hotspot/lookforpattern.svg';
import RestakeProblem from 'assets/img/mission/hotspot/restakeproblem.svg';
import SolveProblem from 'assets/img/mission/hotspot/solveproblem.svg';
import SimplifyProblem from 'assets/img/mission/hotspot/simplifyproblem.svg';

import { NewHOTsCurriculumID } from 'constants/mission';
import { TopBar, BackButton, Title, Content } from './HotsIntermediate.styles';
import HotsIntermediatev2 from './HotsIntermediatev2';

const HotsIntermediatev1Page = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['hotsIntermediate']);
  const { curriculumId } = useParams();
  const { subject } = useLocation()?.state;

  // top-left section
  const representing = [
    {
      title: 'Make a list',
      img: Makelist,
      displayOrder: 11,
    },
    {
      title: 'Draw diagrams',
      img: DrawDiagrams,
      displayOrder: 12,
    },
  ];

  // top-right section
  const goingThroughTheProcess = [
    {
      title: 'Work Ba​​ckwards',
      img: WorkBackwards,
      displayOrder: 1,
    },
    {
      title: 'Logical Reasoning',
      img: LogicalReasoning,
      displayOrder: 2,
    },
    {
      title: 'Before and After',
      img: BeforeAfter,
      displayOrder: 3,
    },
    {
      title: 'Act it Out',
      img: ActItOut,
      displayOrder: 4,
    },
  ];

  // bottom-right section
  const makingCalculatedGuess = [
    {
      title: 'Guess and Check',
      img: GuessCheck,
      displayOrder: 10,
    },
    {
      title: 'Look for Patterns',
      img: LookForPatterns,
      displayOrder: 9,
    },
    {
      title: 'Make Suppositions',
      img: MakeSuppositions,
      displayOrder: 8,
    },
  ];

  // bottom-left section
  const changingTheProblem = [
    {
      title: 'Restate the Problem',
      img: RestakeProblem,
      displayOrder: 5,
    },
    {
      title: 'Solve Part of the Problem',
      img: SolveProblem,
      displayOrder: 6,
    },
    {
      title: 'Form Equations',
      img: SimplifyProblem,
      displayOrder: 7,
    },
  ];

  const clickHandler = (order) => {
    history.push({
      pathname: `/mission/self-practice/${curriculumId}`,
      state: { subject },
    });
    dispatch(setHotsDisplayOrder(order));
  };

  return (
    <div>
      <Header />
      <TopBar>
        <BackButton onClick={() => history.push('/mission/curriculum-list')}>
          {t('hotsIntermediate:topbar.back', 'Back')}
        </BackButton>
        <Title>
          {t(
            'hotsIntermediate:topbar.title',
            'Problem-Solving Skills and Thinking Strategies'
          )}
        </Title>
        <div />
      </TopBar>
      <>
        <Content>
          <div className="parent-div">
            <div className="triangle part-one">
              {representing.map((item) => (
                <div
                  className="representing"
                  key={item.displayOrder}
                  onClick={() => {
                    clickHandler(item.displayOrder);
                  }}
                  data-cy={item.displayOrder}
                >
                  <p>
                    {t(
                      `hotsIntermediate:topLeft.${item.title}`,
                      `${item.title}`
                    )}
                  </p>
                  <img src={item.img} alt="HOTS" />
                </div>
              ))}
              <span className="representing-title">
                {t('hotsIntermediate:topLeft.Representing', 'Representing')}
              </span>
            </div>
            <div className="triangle part-two">
              {goingThroughTheProcess.map((item) => (
                <div
                  className="process"
                  key={item.displayOrder}
                  onClick={() => {
                    clickHandler(item.displayOrder);
                  }}
                  data-cy={item.displayOrder}
                >
                  <img src={item.img} alt="HOTS" />
                  <p>
                    {t(
                      `hotsIntermediate:topRight.${item.title}`,
                      `${item.title}`
                    )}
                  </p>
                </div>
              ))}
              <span className="process-title">
                <Trans i18nKey="hotsIntermediate:topRight.GoingThroughtheProcess">
                  Going Through <br /> the Process
                </Trans>
              </span>
            </div>
          </div>
          <div className="center-div">
            <img src={Brain} alt="HOTS" />
          </div>
          <div className="parent-div">
            <div className="triangle part-three">
              {makingCalculatedGuess.map((item) => (
                <div
                  className="guess"
                  key={item.displayOrder}
                  onClick={() => {
                    clickHandler(item.displayOrder);
                  }}
                  data-cy={item.displayOrder}
                >
                  <p>
                    {t(
                      `hotsIntermediate:bottomRight.${item.title}`,
                      `${item.title}`
                    )}
                  </p>
                  <img src={item.img} alt="HOTS" />
                </div>
              ))}
              <span className="guess-title">
                <Trans i18nKey="hotsIntermediate:bottomRight.MakingACalculatedGuess">
                  Making a <br /> Calculated Guess
                </Trans>
              </span>
            </div>
            <div className="triangle part-four">
              {changingTheProblem.map((item) => (
                <div
                  className="problem"
                  key={item.displayOrder}
                  onClick={() => {
                    clickHandler(item.displayOrder);
                  }}
                  data-cy={item.displayOrder}
                >
                  <img src={item.img} alt="HOTS" />
                  <p>
                    {t(
                      `hotsIntermediate:bottomLeft.${item.title}`,
                      `${item.title}`
                    )}
                  </p>
                </div>
              ))}
              <span className="problem-title">
                <Trans i18nKey="hotsIntermediate:bottomLeft.ChangingTheProblem">
                  Changing <br /> the Problem
                </Trans>
              </span>
            </div>
          </div>
        </Content>
      </>
    </div>
  );
};

const HotsIntermediate = () => {
  const { curriculumId } = useParams();
  const isv2Hots = curriculumId === NewHOTsCurriculumID.toString(10);

  if (isv2Hots) {
    return <HotsIntermediatev2 />;
  }
  return <HotsIntermediatev1Page />;
};

export default HotsIntermediate;
