import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMainSuperheroPageData } from 'store/superheroChallenge/superheroChallengeSlice';
import { getSuperspeedData } from 'store/superSpeed/superSpeedSlice';
import { getSupervisionData } from 'store/superVision/superVisionSlice';

export default function useSuperHeroChallengeLoader(type) {
  const dispatch = useDispatch();
  const superSpeedLoading = useSelector((state) => state.superSpeed.isLoading);
  const superVisionLoading = useSelector(
    (state) => state.superVision.isLoading
  );
  const mainPageLoading = useSelector(
    (state) => state.superheroChallenge.isLoading
  );
  const superSpeedStatus = useSelector(
    (state) => state.superheroChallenge.superSpeed.Status
  );
  const superVisionStatus = useSelector(
    (state) => state.superheroChallenge.superVision.Status
  );
  useEffect(() => {
    if (type === 'superspeed') {
      dispatch(getSuperspeedData());
    }
    if (type === 'supervision') {
      dispatch(getSupervisionData());
    }
    dispatch(getMainSuperheroPageData());
  }, [dispatch, type]);
  return {
    loading:
      (type === 'superspeed' ? superSpeedLoading : superVisionLoading) ||
      mainPageLoading,
    superSpeedStatus,
    superVisionStatus,
  };
}
