import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  .close-btn {
    width: 36px;
    height: 36px;
    background: #ebf6ff;
    color: #73b0e8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    font-weight: bold;
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 21px;
    cursor: pointer;
  }
  .title {
    font-size: 25px;
    color: #000000;
    font-weight: bold;
  }
  .sub-title {
    font-size: 16px;
    color: #000000;
  }
  .select-gender {
    display: flex;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .round-bg {
    width: 167px;
    height: 167px;
    margin: 1rem;
    background: ${(props) => (props.gender === 'Male' ? '#52B6FF' : '#FF7C8D')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${(props) =>
      props.welcomeMember
        ? 'none'
        : props.selected
        ? 'solid 8px #FF8001'
        : 'solid 8px #efefef'};
  }
  .desc {
    color: ${(props) => (props.selected ? '#000000' : 'rgba(0, 0, 0, 0.49)')};
    font-weight: 600;
    font-size: 18px;
  }
`;
