import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { initGA, PageView } from 'helpers/googleAnalytics';
import { useDispatch } from 'react-redux';
import { getGlobalFeatures } from 'store/plan/planSlice';

const TRACKING_ID =
  process.env.REACT_APP_DEV === 'true' ? 'UA-155874130-5' : 'UA-47054833-1';

const PageViewTracking = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    initGA(TRACKING_ID);
  }, []);
  // Send page view events to Google Analytics
  useEffect(() => {
    dispatch(getGlobalFeatures());
    PageView();
  }, [dispatch, location]);

  return <>{children}</>;
};
export default PageViewTracking;
