import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchEventDetails,
  openEventRequest,
  saveSMCQuestionSubmissionRequest,
  submitSMCRequest,
  fetchSMCSolutions,
  claimCPRequest,
  fetchSMCStatus,
} from 'services/event';
import { fetchWrapper } from 'services/login';
import { compareValues } from 'helpers/compareValue';
import xmlParser from 'helpers/xmlParser';
import { isNil } from 'ramda';

// Get event details
export const getEventDetails = createAsyncThunk(
  'event/getEventDetails',
  async (ID) => {
    try {
      const res = await fetchWrapper(fetchEventDetails, ID);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get event details failed');
    }
  }
);
// Open assignment
export const openEvent = createAsyncThunk('event/openEvent', async (ID) => {
  try {
    const res = await fetchWrapper(openEventRequest, ID);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Open assignment failed');
  }
});
// Save assignment
export const saveEventAns = createAsyncThunk(
  'event/saveEventAns',
  async (params) => {
    const { rawBody, activeQuestion } = params;
    try {
      const res = await fetchWrapper(saveSMCQuestionSubmissionRequest, rawBody);
      return {
        result: res,
        activeQuestionEvent: activeQuestion,
        saveParams: rawBody,
      };
    } catch (err) {
      throw new Error(err?.message ?? 'Save question submission failed');
    }
  }
);

// Save & Submit assignment
export const submitEventAssignment = createAsyncThunk(
  'event/submitEventAssignment',
  async (params) => {
    const { rawBody, activeQuestion } = params;
    try {
      const saveAnsRes = await fetchWrapper(
        saveSMCQuestionSubmissionRequest,
        rawBody
      );
      const res = await fetchWrapper(submitSMCRequest, rawBody.SMCSubmissionID);
      return {
        saveResult: res,
        result: saveAnsRes,
        activeQuestionEvent: activeQuestion,
      };
    } catch (err) {
      throw new Error(err?.message ?? 'Submit event assignment failed');
    }
  }
);

// Get solution
export const getEventSolution = createAsyncThunk(
  'event/getEventSolution',
  async (SMCSubId) => {
    try {
      const res = await fetchWrapper(fetchSMCSolutions, SMCSubId);
      return res;
    } catch (err) {
      throw new Error(err?.message ?? 'Get solution failed');
    }
  }
);

// Claim CPs
export const claimCP = createAsyncThunk('event/claimCP', async (SMCSubId) => {
  try {
    const res = await fetchWrapper(claimCPRequest, SMCSubId);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Claim Challenge Point failed');
  }
});

export const getSMCStatus = createAsyncThunk('event/getSMCStatus', async () => {
  try {
    const res = await fetchWrapper(fetchSMCStatus);
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Get SMC Status failed');
  }
});

const initialState = {
  startEventInfo: null,
  eventIsLoading: false,
  eventError: null,
  activeQuestionEvent: 1,
  eventIsOpening: false,
  eventSubmissionID: null,
  eventQuestions: [],
  eventQsAnswers: [],
  eventLocalSavedAns: [],
  eventLocalSavedWorkings: [],
  eventSubmissions: [],
  eventSavedAns: [],
  isEventSubmitting: false,
  isEventSubmittingLoading: false,
  showEventTimesUpModal: false,
  eventSaveError: null,
  isEventSaving: false,
  isClaiming: false,
  claimCPResult: null,
  claimCPError: null,
  isEventSolutionLoading: false,
  eventSolution: null,
  assignmentEventSubmissionParams: null,
  assignmentEventSubmissionError: null,
  smcStatus: null,
  isSMCStatusLoading: false,
  smcStatusError: null,
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setActiveEventQuestion: (state, action) => {
      state.activeQuestionEvent = action.payload;
    },
    saveEventAnsLocally: (state, action) => {
      const newAnswers = [...state.eventLocalSavedAns];
      newAnswers[action.payload.index] = action.payload.answers;
      state.eventLocalSavedAns = newAnswers;
    },
    saveEventWorkingsLocally: (state, action) => {
      const newWorkings = [...state.eventLocalSavedWorkings];
      newWorkings[action.payload.index] = action.payload.workings;
      state.eventLocalSavedWorkings = newWorkings;
    },
    clearError: (state) => {
      state.eventSaveError = null;
    },
    clearEventSavedAnswer: (state, action) => {
      const newSavedAnswers = [...state.eventSavedAns];
      newSavedAnswers[action.payload] = null;
      state.eventSavedAns = newSavedAnswers;
    },
    setEventShowTimesUpModal: (state, action) => {
      state.showEventTimesUpModal = action.payload;
    },
    resetEventSubmitting: (state) => {
      state.isEventSubmitting = false;
    },
    resetEvent: (state) => {
      return {
        ...state,
        showEventTimesUpModal: false,
      };
    },
    resetClaimCP: (state) => {
      return {
        ...state,
        claimCPResult: null,
        claimCPError: null,
      };
    },
  },
  extraReducers: {
    [getSMCStatus.pending]: (state) => {
      state.isSMCStatusLoading = true;
      state.smcStatusError = null;
    },
    [getSMCStatus.fulfilled]: (state, action) => {
      state.isSMCStatusLoading = false;
      state.smcStatus = action.payload;
    },
    [getSMCStatus.rejected]: (state, action) => {
      state.isSMCStatusLoading = false;
      state.smcStatusError = action.error.message;
    },
    [getEventDetails.pending]: (state) => {
      state.eventIsLoading = true;
      state.eventError = null;
    },
    [getEventDetails.fulfilled]: (state, action) => {
      state.eventIsLoading = false;
      state.startEventInfo = action.payload;
    },
    [getEventDetails.rejected]: (state, action) => {
      state.eventIsLoading = false;
      state.eventError = action.error.message;
    },
    [openEvent.pending]: (state) => {
      state.eventIsOpening = true;
      state.eventError = null;
    },
    [openEvent.fulfilled]: (state, action) => {
      state.eventIsOpening = false;
      state.eventIsLoading = false;
      if (action.payload.UserQuestionSubmissions.length > 0) {
        state.eventSavedAns = Array(
          action.payload.UserQuestionSubmissions.length
        ).fill(null);
        state.eventLocalSavedAns = Array(
          action.payload.UserQuestionSubmissions.length
        ).fill(null);
        state.eventLocalSavedWorkings = Array(
          action.payload.UserQuestionSubmissions.length
        ).fill(null);
        // Populate eventQsAnswers
        state.eventQsAnswers = action.payload.UserQuestionSubmissions.map(
          (qn) => {
            const filteredXml = qn.Question.Payload.replace(/\\/g, '');
            const parsed = xmlParser(filteredXml);
            let answerKeys = null;
            if (parsed.questionAnswers.length > 0) {
              answerKeys = parsed.questionAnswers.map((answer) => answer.$);
            }
            return answerKeys;
          }
        );
      } else {
        state.eventSavedAns = [];
        state.eventLocalSavedAns = [];
        state.eventQsAnswers = [];
      }
      state.eventSubmissionID = action.payload.SMCSubId;
      state.assignmentDuration = action.payload.SubmissionDuration;
      state.startAssignmentDuration = action.payload.SubmissionDuration;
      state.eventQuestions = action.payload.UserQuestionSubmissions.map(
        (elem) => elem.Question
      );
      state.eventSubmissions = action.payload.UserQuestionSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      state.eventSavedAns = action.payload.UserQuestionSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      ).map(
        (UserQuestionSubmissions) => UserQuestionSubmissions.SubmissionModel
      );
    },
    [openEvent.rejected]: (state, action) => {
      state.eventIsOpening = false;
      state.eventError = action.error.message;
    },
    [submitEventAssignment.pending]: (state, action) => {
      state.assignmentEventSubmissionError = null;
      state.isEventSubmitting = false;
      state.isEventSubmittingLoading = true;
      state.assignmentEventSubmissionParams = action.meta.arg;
    },
    [submitEventAssignment.fulfilled]: (state, action) => {
      state.isEventSaving = false;
      state.isEventSubmittingLoading = false;
      const newSavedAnswers = [...state.eventSavedAns];
      newSavedAnswers[action.payload.activeQuestionEvent - 1] =
        action.payload.result;
      state.eventSavedAns = newSavedAnswers;
      // state.assignmentDuration = action.payload.result.SubmissionDuration;
      // state.eventSubmissions = action.payload.result.UserQuestionSubmissions.sort(
      //   compareValues('DisplayOrder', 'asc')
      // );
      state.isEventSubmitting = true;
      state.assignmentEventSubmissionError = null;
    },
    [submitEventAssignment.rejected]: (state, action) => {
      state.isEventSubmittingLoading = false;
      state.isEventSubmitting = false;
      state.assignmentEventSubmissionError = action.error.message;
    },
    [saveEventAns.pending]: (state) => {
      state.isEventSaving = true;
      state.eventSaveError = null;
    },
    [saveEventAns.fulfilled]: (state, action) => {
      state.isEventSaving = false;
      const newSavedAnswers = [...state.eventSavedAns];
      newSavedAnswers[action.payload.activeQuestionEvent - 1] =
        action.payload.result;
      state.eventSavedAns = newSavedAnswers;
      state.assignmentDuration = action.payload.result.SubmissionDuration;
      const newEventSubmissions = [...state.eventSubmissions];
      const newUserQnSubmission = {
        ...newEventSubmissions[action.payload.activeQuestionEvent - 1],
      };
      // Check if current SubmissionModel is null
      if (isNil(newUserQnSubmission.SubmissionModel)) {
        newUserQnSubmission.SubmissionModel = {
          SubmissionKeyValuePairs:
            action.payload.saveParams.answerkeyValuePairs,
          Solution: action.payload.saveParams.solution,
        };
      } else {
        // Update SubmissionKeyValuePairs in SubmissionModel
        newUserQnSubmission.SubmissionModel.SubmissionKeyValuePairs =
          action.payload.saveParams.answerkeyValuePairs;
        newUserQnSubmission.Solution = action.payload.saveParams.solution;
      }
      newEventSubmissions[
        action.payload.activeQuestionEvent - 1
      ] = newUserQnSubmission;
      state.eventSubmissions = newEventSubmissions;
    },
    [saveEventAns.rejected]: (state, action) => {
      state.isEventSaving = false;
      state.eventSaveError = action.error.message;
    },
    [getEventSolution.pending]: (state) => {
      state.isEventSolutionLoading = true;
      state.eventSolution = null;
      state.eventError = null;
    },
    [getEventSolution.fulfilled]: (state, action) => {
      state.isEventSolutionLoading = false;
      state.eventSolution = action.payload;
      state.eventSubmissions = action.payload.UserQuestionSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      state.eventQsAnswers = action.payload.UserQuestionSubmissions.map(
        (qn) => {
          const filteredXml = qn.Question.Payload.replace(/\\/g, '');
          const parsed = xmlParser(filteredXml);
          let answerKeys = null;
          if (parsed.questionAnswers.length > 0) {
            answerKeys = parsed.questionAnswers.map((answer) => answer.$);
          }
          return answerKeys;
        }
      );
    },
    [getEventSolution.rejected]: (state, action) => {
      state.isEventSolutionLoading = false;
      state.eventError = action.error.message;
    },
    [claimCP.pending]: (state) => {
      state.isClaiming = true;
      state.claimCPError = null;
      state.claimCPResult = null;
    },
    [claimCP.fulfilled]: (state, action) => {
      state.isClaiming = false;
      state.claimCPResult = action.payload;
      if (!isNil(state.eventSolution)) {
        state.eventSolution.CanBeClaimed = false;
      }
    },
    [claimCP.rejected]: (state, action) => {
      state.isClaiming = false;
      state.claimCPError = action.error.message;
    },
  },
});

const { actions, reducer } = eventSlice;
export const {
  setActiveEventQuestion,
  saveEventAnsLocally,
  saveEventWorkingsLocally,
  clearError,
  clearEventSavedAnswer,
  setEventShowTimesUpModal,
  resetEvent,
  resetEventSubmitting,
  resetClaimCP,
} = actions;
export default reducer;
