const listeners = [];

EventTarget.prototype.addEventListenerBase =
  EventTarget.prototype.addEventListener;
EventTarget.prototype.addEventListener = function (type, listener) {
  listeners.push({ target: this, type, listener });
  if (this) {
    this.addEventListenerBase(type, listener);
  }
};

export default listeners;
