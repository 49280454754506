import axios from 'axios';
const BASE_URL = process.env.REACT_APP_TRIAL_SERVICE_URL;

const postConversionApi = (body) => {
  return axios
    .post(`${BASE_URL}Conversions`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export default postConversionApi;
