import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'components/Shared/Spinner';
import { isEmpty } from 'ramda';
import ErrorModal from 'components/Shared/ErrorModal';
import { NoParticipants, Category } from '../../components';
import { RANKING_FILTER } from '../../constant';
import { NULL_CATEGORY, OVERALL_CATEGORY } from './constants';
import { Header } from './components';
import SuperheroList from 'pages/Leaderboard/v2/components/List/SuperheroList';
import { getSuperheroLeaderBoard } from 'store/leaderboard/superHeroLeaderBoardSlice';
import { Container } from './Superhero.styles';

const Superhero = () => {
  const dispatch = useDispatch();
  const { userID } = useSelector((state) => state.login);
  const { isLoading, data, error } = useSelector(
    (state) => state.superHeroLeaderBoardV2
  );
  const [activeCategory, setActiveCategory] = useState(OVERALL_CATEGORY);
  const [activeFilter, setActiveFilter] = useState(RANKING_FILTER.regular);
  const [isTopFifty, setIsTopFifty] = useState(false);
  const handleSearch = useCallback(
    (value) => {
      dispatch(getSuperheroLeaderBoard({ rankingFilter: value }));
      setActiveFilter(value);
    },
    [dispatch]
  );
  useEffect(() => {
    handleSearch(RANKING_FILTER.regular);
  }, [handleSearch]);
  // to check if user is in top 50
  useEffect(() => {
    if (!isEmpty(data[activeFilter])) {
      const isRegular = activeFilter === RANKING_FILTER.regular;
      const findUser = data[activeFilter].find((stu) => stu.userId === userID);
      const isTop50 = findUser ? findUser.leaderboardRank <= 50 : true;
      if (isRegular) {
        setIsTopFifty(isTop50);
      }
    }
  }, [data, userID, activeFilter]);

  const handleTopFiftyClick = () => {
    const isRegular = activeFilter === RANKING_FILTER.regular;
    if (isRegular) {
      const findUser = data[activeFilter].find((stu) => stu.userId === userID);
      const isTop50 = findUser ? findUser.leaderboardRank <= 50 : true;
      if (!isTop50) {
        setActiveCategory(NULL_CATEGORY);
      }
    }
    if (isTopFifty) return;
    handleSearch(RANKING_FILTER.top50);
    setIsTopFifty(true);
  };
  const handleCategoryClick = () => {
    setActiveCategory(OVERALL_CATEGORY);
    setActiveFilter(RANKING_FILTER.regular);
    handleSearch(RANKING_FILTER.regular);
  };
  return (
    <Container>
      <Header />
      <Category
        top50Available
        active={activeCategory}
        categoryHandler={handleCategoryClick}
        availableCategory={['overall']}
        top50Handler={handleTopFiftyClick}
      />
      {isLoading && <Spinner />}
      {isLoading && error && (
        <ErrorModal
          errorMessage={error}
          reloadAction={() => handleSearch(activeFilter)}
        />
      )}
      {!isLoading && data[activeFilter].length > 0 && (
        <SuperheroList data={data[activeFilter]} isTopFifty={isTopFifty} />
      )}
      {!isLoading && data[activeFilter].length === 0 && <NoParticipants />}
    </Container>
  );
};
export default Superhero;
