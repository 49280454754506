import React from 'react';
import Header from 'components/Header';
import { Page } from 'pages/Dashboard/index.styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import useGlobalFeature from 'hooks/useGlobalFeature';
import { Content } from './index.styles';
import KooQuizSelection from './KooQuizSelection';
import KooQuizMainPage from './KooQuizMainPage';
import KooQuizStart from './KooQuizStart';

import { withPlanCheck } from 'helpers/hoc';
import { SubjectEnum } from 'constants/index';

const KooQuiz = () => {
  const { isEnabled } = useGlobalFeature('kooquiz');
  return (
    <Page>
      <Header />
      <Content>
        <Switch>
          {!isEnabled && (
            <Redirect
              exact
              from={[
                '/kooQuiz',
                '/kooQuiz/start/:subject/:paper/:id',
                '/kooQuiz/:subject/:paper',
              ]}
              to="/maintenance-redirect"
            />
          )}
          <Route exact path="/kooQuiz" component={KooQuizSelection} />
          <Route
            exact
            path="/kooQuiz/start/:subject/:paper/:id"
            render={({ match }) => {
              const KooQuizWithSubjectCheck = withPlanCheck(
                KooQuizStart,
                SubjectEnum[match.params.subject],
                'B2C'
              );
              return <KooQuizWithSubjectCheck />;
            }}
          />
          <Route
            exact
            path="/kooQuiz/:subject/:paper"
            component={KooQuizMainPage}
          />
        </Switch>
      </Content>
    </Page>
  );
};

export default KooQuiz;
