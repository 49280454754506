import styled from 'styled-components';
import HeroOfTheDayBg from 'assets/img/dailychallenge/hero-of-the-day/hero-of-the-day-bg.png';
// Progress Bar
export const ProgressBarContainer = styled.div`
  position: relative;
  /* bottom: 14.5px; */
`;
export const Base = styled.div`
  background: rgba(52, 96, 114, 0.7);
  width: 400px;
  height: 29.5px;
  border-radius: 1rem;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 320px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 260px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    width: 220px;
  }
`;
export const Bar = styled.div`
  position: absolute;
  /* top: 29.5px; */
`;

export const Progress = styled.div`
  height: 60px;
  background: rgba(7, 100, 129, 0.45);
  padding: 0 1rem;
  border-radius: 1.5rem;
  font-family: 'Bree Serif';
  display: flex;
  align-items: center;
  .cps-earned {
    color: #ffa200;
    font-size: 2.5rem;
  }
  .cps-required {
    color: white;
    margin-right: 0.5rem;
    white-space: nowrap;
  }
  .reload {
    cursor: pointer;
    text-decoration: underline;
    color: white;
    margin-right: 0.5rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .cps-required {
      font-size: 10px;
      line-height: 4;
      align-self: flex-end;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    height: 50px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100px;

  /* @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    transform: scale(0.95);
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    transform: scale(0.8);
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobileS}px`}) {
    transform: scale(0.7);
  } */
`;

export const Badge = styled.span`
  position: relative;
  right: 1.6rem;
  cursor: pointer;
  /* background-image: ${`url(${HeroOfTheDayBg})`};
  background-repeat: no-repeat;
  background-size: contain;
  width: 116.8px;
  height: 116.8px;
  display: flex;
  justify-content: center;
  align-items: flex-start; */
  img {
    /* position: relative;
    bottom: 0.46rem; */
    width: 120px;
    height: 120px;

    &:hover {
      opacity: 0.9;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    right: 3.6rem;
    img {
      width: 100px;
      height: 100px;
    }
  }
`;
