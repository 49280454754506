import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import clsx from 'clsx';
import RankByIcon from 'assets/img/monstershowcase/icon-sort.png';
import {
  MainContainer,
  Label,
  Line,
  ButtonContainer,
  RoundButton,
} from './RankBySelector.style';

function RankBySelector({ active, onChange }) {
  const { t } = useTranslation(['kokomonster']);
  const onClickButton = useCallback(
    (item) => () => {
      onChange(item);
    },
    [onChange]
  );
  const overlayWrapper = (el, text) => (
    <OverlayTrigger placement="top" overlay={<Tooltip>{text}</Tooltip>}>
      {el}
    </OverlayTrigger>
  );
  return (
    <MainContainer>
      <Line />
      <ButtonContainer>
        <Label>{t('kokomonster:body.rankBy', 'Rank By')}</Label>
        {overlayWrapper(
          <RoundButton
            icon={RankByIcon}
            onClick={onClickButton('class')}
            className={clsx({ class: true, selected: active === 'class' })}
          />,
          t('kokomonster:body.myClass', 'My Class')
        )}
        {overlayWrapper(
          <RoundButton
            onClick={onClickButton('school')}
            icon={RankByIcon}
            className={clsx({ school: true, selected: active === 'school' })}
          />,
          t('kokomonster:body.mySchool', 'My School')
        )}
        {overlayWrapper(
          <RoundButton
            onClick={onClickButton('country')}
            icon={RankByIcon}
            className={clsx({ country: true, selected: active === 'country' })}
          />,
          t('kokomonster:body.myCountry', 'My Country')
        )}
      </ButtonContainer>
      <Line />
    </MainContainer>
  );
}

export default React.memo(RankBySelector);
