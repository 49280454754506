import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { subjectIDs } from 'constants/products';
import Button from 'components/Shared/Button';
import ButtonPlay from 'components/Shared/ButtonPlay';
import { otpCampaignCode } from 'constants/trialBanner';
import { paymentRenewUrl } from 'helpers/url/paymentRenewUrl';
import ModalVideo from 'react-modal-video';
import { GAEvent } from 'helpers/googleAnalytics';
import MikoMath from 'assets/img/miko-trial-banner.svg';
import MikoSci from 'assets/img/science/miko-img.svg';
import { Container, CountDown, VideoBtn } from './index.styles';

const TrialBanner = ({ currentDay, currentTrial, userId }) => {
  const { t } = useTranslation(['trialBanner']);
  const [showVideo, setShowVideo] = useState(false);
  const handleOpenVideo = () => {
    setShowVideo(true);
    GAEvent({
      category:
        process.env.REACT_APP_DEV === 'true'
          ? '[Dev] Student Portal'
          : 'Student Portal',
      action: 'Click Bottom Banner Tutorial Video',
      label: `${userId}-${currentTrial.subjectId}-Click Tutorial Video`,
    });
  };

  const handleByNow = () => {
    const subject = subjectIDs[currentTrial.subjectId]?.toLowerCase();
    const campaign = otpCampaignCode[subject];

    return (window.location.href = paymentRenewUrl({
      userId,
      bundleTypeId: currentTrial?.subjectId,
      campaign,
    }));
  };
  return (
    <Container data-cy="trial-banner">
      <ModalVideo
        channel="youtube"
        isOpen={showVideo}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
        }}
        videoId={currentTrial.videoUrl}
        onClose={() => setShowVideo(false)}
      />
      <CountDown isOtp={currentTrial.isOtpTrial}>
        <div className={`count-down ${currentDay >= 7 && 'red'}`}>
          {t('trialBanner:day', { number: currentDay }, `Day ${currentDay}`)}
        </div>
        <p>{t('trialBanner:freeTrial', 'of free trial')}</p>
        {!isNil(currentTrial.btnText) && (
          <Button
            variant="primary"
            margin="0 0.5rem"
            fontWeight="bold"
            onClick={handleByNow}
          >
            {t(
              `trialBanner:${currentTrial.btnText.translationKey}`,
              `${currentTrial.btnText.text}`
            )}
          </Button>
        )}
        {currentTrial.isOtpTrial && (
          <p>{t('trialBanner:unlock', 'to unlock the full version')}</p>
        )}
      </CountDown>
      <VideoBtn>
        <div
          className={currentTrial.videoBtn.outer}
          onClick={handleOpenVideo}
          aria-hidden="true"
        >
          <ButtonPlay
            size="33px"
            variant={currentTrial.videoBtn.inner}
            styles={{ marginRight: '0.5rem' }}
          />
          <p>{t('trialBanner:getStart', "Let's learn how to get started")}</p>
        </div>
      </VideoBtn>
      {subjectIDs[currentTrial.subjectId] === 'Science' ? (
        <img src={MikoSci} className="mascot mascot-science" alt="miko" />
      ) : (
        <img src={MikoMath} className="mascot" alt="miko" />
      )}
    </Container>
  );
};

export default TrialBanner;
