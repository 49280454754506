import styled from 'styled-components';

export const Container = styled.div``;

export const AlertWrapper = styled.div`
  background-color: #ffffff;
`;

export const AlertHeader = styled.div`
  padding: 1em 4em;
  background-color: #d1d1d1;
  box-sizing: inherit;
  display: block;
`;

export const AlertHeaderTitle = styled.div`
  display: block;
  font-size: 1.5em;
  font-weight: bold;
`;

export const AlertContent = styled.div`
  padding: 2em 4em 1em;
  box-sizing: inherit;
  display: block;
`;

export const AlertMessage = styled.p`
  margin: 0;
  padding: 0;
  display: block;
`;

export const AlertAction = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 2em 4em;
  box-sizing: inherit;
`;

export const ActionButton = styled.a`
  @media (min-width: 768px) font-size: 1.4em;
  color: #ffffff;
  background-color: ${(props) =>
    props.color === 'red' ? '#F46036' : '#00D9C0'};
  text-decoration: none;
  display: inline-block;
  padding: 0.6em 2.5em;
  border-radius: 4px;
  box-sizing: inherit;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.color === 'red' ? '#f34d1e' : '#00c0a9'};
  }
`;

export const ActionIcon = styled.span`
  @media (min-width: 480px) display: inline-block;
  color: #ffffff;
`;

export const ActionText = styled.span`
  @media (min-width: 768px) font-size: 1.4em;
  color: #ffffff;
`;
