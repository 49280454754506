import React from 'react';
import PopupModal from 'components/Shared/PopupModal';
import { Container } from './ModalContentXButton.styles';

const ModalContentXButton = ({
  children,
  closeModal,
  showModal,
  backdrop,
  hide,
  size,
  containerMinWidth,
  backgroundImage,
  borderRadius,
  showClosedButton = true,
}) => {
  return (
    <PopupModal
      show={showModal}
      backdrop={backdrop}
      keyboard={false}
      onHide={hide}
      size={size}
    >
      <Container
        minWidth={containerMinWidth}
        backgroundImage={backgroundImage}
        borderRadius={borderRadius}
      >
        {showClosedButton && (
          <div className="close-btn" onClick={closeModal}>
            X
          </div>
        )}
        {children}
      </Container>
    </PopupModal>
  );
};

export default ModalContentXButton;
