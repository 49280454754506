import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Container, Lists, Headings } from './BreakdownScore.styles';

const BreakdownScore = ({ data }) => {
  const { t } = useTranslation(['leaderboard']);
  const labels = [
    {
      name: t('leaderboard:superhero.speedScore', 'Total Speed Score'),
      flex: 1,
    },
    {
      name: t('leaderboard:superhero.visionScore', 'Total Vision Score'),
      flex: 1,
    },
    { name: t('leaderboard:superhero.time', 'Achievement Time'), flex: 1 },
  ];

  return (
    <Container>
      <div className="list-headings">
        {labels.map((item, index) => (
          <Headings key={index} flex={item.flex}>
            {item.name}
          </Headings>
        ))}
      </div>
      <div>
        <Lists>
          <div className="speed-points">{data.WeekSpeedScore}</div>
          <div className="vision-points">{data.WeekVisionScore}</div>
          <div className="list-achievement">
            {moment(data.LastSubmissionDate).format('H:mm')}
          </div>
        </Lists>
      </div>
    </Container>
  );
};

export default BreakdownScore;
