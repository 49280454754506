/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import BackIcon from 'assets/img/leaderboard/lion-city/back-icon.svg';
import { useTranslation } from 'react-i18next';
import Button from 'components/Shared/Button';
import { fetchWrapper } from 'services/login';
import { updateOccupation } from 'services/lionCity';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from 'store/lionCity/lionCitySlice';
import { occupationOptions } from 'constants/leaderboard';
import { isNil } from 'ramda';
import lionCityOccupations from 'constants/leaderboard/lionCityOccupations';
import {
  StyledBackButton,
  StyledJoinedButton,
  StyledOccupation,
  StyledRow,
  StyledStepOneContainer,
  StyledStepTwoContainer,
} from './LionCityJoinedModal.styles';

const RemainingOptions = ({
  selectedOccupation,
  remainingItems,
  data,
  setData,
}) => {
  return (
    <>
      {remainingItems.map((occupation) => (
        <img
          key={occupation.id}
          className={`remain-occupation ${
            occupation.name === selectedOccupation && 'selected'
          }`}
          alt={`occupation-${occupation.name}`}
          src={lionCityOccupations[occupation.id]}
          width={occupation.name === selectedOccupation ? '47px' : '40px'}
          height={occupation.name === selectedOccupation ? '47px' : '40px'}
          onClick={() => {
            setData({
              ...data,
              selectedId: occupation.id,
              selectedOccupation: occupation.name,
            });
          }}
        />
      ))}
    </>
  );
};

const LionCityJoinedModal = ({ data, setData, closeModal, dropdownItems }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['leaderboard']);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [remainingItems, setRemainingItems] = useState([]);

  const resetSubmitError = () => {
    setSubmitError('');
  };

  const updateUserOccupation = async ({ selectedId }) => {
    resetSubmitError();
    try {
      setSubmitLoading(true);
      await fetchWrapper(updateOccupation, { occupationId: selectedId });
      await dispatch(getStudentDetails());
      await dispatch(getCurrentUser({ SeasonId: dropdownItems.Id }));
      setSubmitLoading(false);
      closeModal();
    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
      setSubmitError(err?.message ?? 'Update occupation failed');
    }
  };

  const updateOccupationOnFirstStep = (selectedOccupation) => {
    const index = occupationOptions
      .map((item) => item.name)
      .indexOf(selectedOccupation);
    const tempRemainingItems = [...occupationOptions];
    const firstItem = tempRemainingItems.splice(index, 1);
    setRemainingItems([firstItem[0], ...tempRemainingItems]);
  };

  return (
    <>
      {data.step === 1 && (
        <StyledStepOneContainer>
          <h1 className="main-title">
            {t('leaderboard:lionCity.modal.welcome', 'Welcome to Lion City!')}
          </h1>
          <p className="paragraph">
            {t(
              'leaderboard:lionCity.modal.description',
              'In the city of lions, we are introducing 12 seriously cool careers that need Maths. Choose your dream career and discover how you can apply maths skills in your future job. Click on the icon below to learn more.'
            )}
          </p>
          <div className="occupation">
            {occupationOptions.map((occupation) => (
              <img
                className="occupation-img"
                key={occupation.id}
                alt={`occupation-${occupation.name}`}
                src={lionCityOccupations[occupation.id]}
                onFocus={(e) => e.currentTarget.onfocus}
                onBlur={(e) => e.currentTarget.onblur}
                onMouseOver={(e) =>
                  (e.currentTarget.src = lionCityOccupations[occupation.id])
                }
                onMouseOut={(e) =>
                  (e.currentTarget.src = lionCityOccupations[occupation.id])
                }
                onClick={() => {
                  setData({
                    step: 2,
                    selectedId: occupation.id,
                    selectedOccupation: occupation.name,
                  });
                  updateOccupationOnFirstStep(occupation.name);
                }}
                width="120px"
              />
            ))}
          </div>
        </StyledStepOneContainer>
      )}
      {data.step === 2 && (
        <StyledStepTwoContainer>
          <StyledBackButton>
            <button
              className="back-icon"
              type="button"
              onClick={() => {
                resetSubmitError();
                setData({ ...data, step: 1 });
              }}
            >
              <img src={BackIcon} alt="back-button" width="18px" />
            </button>
            <span className="back-text">
              {t('leaderboard:lionCity.modal.back', 'Back')}
            </span>
          </StyledBackButton>
          <StyledRow>
            <RemainingOptions
              selectedOccupation={data.selectedOccupation}
              remainingItems={remainingItems}
              data={data}
              setData={setData}
            />
          </StyledRow>
          <StyledOccupation>
            <h1 className="selected-occupation-title">
              {t(`leaderboard:lionCity.${data.selectedOccupation}.title`)}
            </h1>
            <p className="selected-occupation-description">
              {t(`leaderboard:lionCity.${data.selectedOccupation}.description`)}
            </p>
            <StyledJoinedButton>
              <Button
                className="join-button"
                variant="primary"
                width="80%"
                fontSize="20px"
                fontWeight="bold"
                isDisabled={submitLoading}
                onClick={() => updateUserOccupation(data)}
              >
                {t('leaderboard:lionCity.modal.joinTeam', 'Join This Team')}
              </Button>
              {!isNil(submitError) && (
                <span className="submit-error">{submitError}</span>
              )}
              <div className="important-note">
                <span>{t('leaderboard:lionCity.modal.note', 'Note')}: </span>
                <span>
                  {t(
                    'leaderboard:lionCity.modal.noteOne',
                    'Think carefully before you choose your team.'
                  )}
                </span>
                <div className="note">
                  {t(
                    'leaderboard:lionCity.modal.noteTwo',
                    'You are not allowed to switch team half-way.'
                  )}
                </div>
                <div className="note">
                  {t(
                    'leaderboard:lionCity.modal.noteThree',
                    'Top 3 from each team will win prizes.'
                  )}
                </div>
                <div className="note">
                  {t(
                    'leaderboard:lionCity.modal.noteFour',
                    'Top 3 from winning team will be given certificates.'
                  )}
                </div>
              </div>
            </StyledJoinedButton>
          </StyledOccupation>
        </StyledStepTwoContainer>
      )}
    </>
  );
};

export default LionCityJoinedModal;
