import styled from 'styled-components';

export const Container = styled.div`
  max-width: 440px;
`;

export const Top = styled.div`
  background: #f0f4f5;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #5c85aa;
  font-size: 20px;
  display: flex;
  justify-content: center;
  padding: 1rem 4rem;
  align-items: center;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
  padding: 1rem 2.75rem 3rem;
  .expired-info {
    color: #348018;
    font-family: Linotte;
    margin-bottom: 1rem;
  }
  p {
    font-family: Muli;
    span {
      font-weight: 700;
      background: #FFE200;
    }
  }
`;

export const ActionBtn = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;
  button {
    font-size: 0.875rem;
    cursor: pointer;
    outline: none;
    border-radius: 2px;
    font-family: Muli;
    &:focus {
      box-shadow: none;
    }
  }
  .orange {
    background: #ff5b00;
    color: #ffffff;
    border: 1px solid #ff5b00;
    font-weight: 600;
    padding: 0.5rem 1rem;
  }
  .white {
    background: #ffffff;
    color: #ff5b00;
    border: 1px solid #ffdac4;
    padding: 0.5rem 0.8rem;
  }
`;
