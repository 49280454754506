import styled from 'styled-components';

export const Container = styled.div`
  background: #c5f5ff;
  padding: 1rem;
  text-align: center;
`;
export const StyledChallengeIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'default')};
`;
export const Button = styled.button`
  border: none;
  background: #ff6701;
  font-weight: 600;
  font-size: 1.3125rem;
  color: white;
  border-radius: 30px;
  padding: 0.25rem 2rem;
  width: 50%;
  :hover {
    background: #ff5801;
  }
`;
