import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parseMilliseconds from 'parse-ms';
import SubmitButton from 'components/Assignment/AssignmentFinish/SubmitButton';
import { finishSubmitAssignment } from 'store/assignment/assignmentSlice';
import { useHistory } from 'react-router-dom';
import { Container } from './TestContent.styles';

const TestContent = ({
  duration,
  type,
  resultDate,
  assignmentId,
  submissionId,
}) => {
  const { t } = useTranslation(['assignmentFinished']);
  const history = useHistory();
  const dispatch = useDispatch();
  const today = new Date();

  // time
  const parsedTime = parseMilliseconds(duration);
  const parsedHours = parsedTime.days * 24 + parsedTime.hours;

  const timeFormat = (
    <span>
      {`${parsedHours < 10 ? `0${parsedHours}` : parsedHours}:${
        parsedTime.minutes < 10 ? `0${parsedTime.minutes}` : parsedTime.minutes
      }:${
        parsedTime.seconds < 10 ? `0${parsedTime.seconds}` : parsedTime.seconds
      }`}
    </span>
  );

  return (
    <Container>
      <div className="top-section">
        <div className="text">
          <p>
            {t(
              'assignmentFinished:test.ThanksText',
              'Thank you for taking the time to complete this test.'
            )}
          </p>
          <p>
            {t(
              'assignmentFinished:test.ClickBtn',
              'Please click on the submission button below to complete the test.'
            )}
          </p>
          <p className="time">
            {t('assignmentFinished:words.Total Time', 'Total Time ')}
            {timeFormat}
          </p>
        </div>
      </div>
      <div className="bottom-section">
        <SubmitButton
          width="60%"
          fontSize="26px"
          onClick={() => {
            if (resultDate > today && type === 'test') {
              dispatch(
                finishSubmitAssignment({
                  assignmentID: assignmentId,
                  assignmentSubmissionID: submissionId,
                })
              );
              history.push('/assignment/submitted/result-release');
            } else {
              dispatch(
                finishSubmitAssignment({
                  assignmentID: assignmentId,
                  assignmentSubmissionID: submissionId,
                })
              );
              history.push('/assignment/submitted/claimkoko/0');
            }
          }}
        >
          {t('assignmentFinished:words.Submit', 'Submit')}
        </SubmitButton>
      </div>
    </Container>
  );
};

export default TestContent;
