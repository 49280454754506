import styled from 'styled-components';

export const ButtonContainer = styled.button`
  padding-top: 9px;
  padding-bottom: 11px;
  padding-right: ${({ direction, isBig }) =>
    !isBig ? '14px' : direction === 'left' ? '60px' : '14px'};
  padding-left: ${({ direction, isBig }) =>
    !isBig ? '14px' : direction === 'right' ? '60px' : '14px'};
  background-color: rgba(222, 248, 251, 0.75);
  border-top-left-radius: ${({ direction, isBig }) =>
    isBig ? '44px' : direction === 'right' ? '0px' : '44px'};
  border-bottom-left-radius: ${({ direction, isBig }) =>
    isBig ? '44px' : direction === 'right' ? '0px' : '44px'};
  border-top-right-radius: ${({ direction, isBig }) =>
    isBig ? '44px' : direction === 'left' ? '0px' : '44px'};
  border-bottom-right-radius: ${({ direction, isBig }) =>
    isBig ? '44px' : direction === 'left' ? '0px' : '44px'};
  line-height: 24px;
  border: 0;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
  z-index: 11;
  &:hover,
  &:focus {
    background-color: rgba(222, 248, 251, 1);
  }
`;
