import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from 'components/ProfileSettings/InputField';
import { isEmpty, isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import Button from 'components/Shared/Button';
import CheckBox from 'components/Shared/CheckBox';
import {
  requestSubjectTrialOTP,
  setOTPInfo,
} from 'store/trialSignup/trialSignupSlice';
import validateEmail from 'helpers/validation/validateEmail';
import { trialFormConfig } from 'constants/otpTrialContent';
import { subjectIDs } from 'constants/products';
import DropdownMenu from 'components/Shared/DropdownMenu';
import {
  StyledButton,
  StyledErrMsg,
  StyledInput,
  StyledForm,
  StyledContainer,
} from './ParentForm.styles';

const ParentForm = ({
  name,
  setName,
  email,
  setEmail,
  phone,
  setDropdownValue,
  setPhone,
  setCurrentStep,
  errMsg,
  setErrMsg,
  checkedConsent,
  setCheckedConsent,
  subjectId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['parentRegister', 'scienceTrial', 'mathTrial']);
  const { otpInfo } = useSelector((state) => state.trialSignup);

  const { isLoading, isErr, countries } = useSelector(
    (state) => state.trialSignup
  );
  useEffect(() => {
    setErrMsg({});
  }, [setErrMsg]);

  const saveDetails = async (e) => {
    e.preventDefault();
    if (!checkedConsent) return;
    const errorDetail = {};
    if (name === '')
      errorDetail.name = t(
        'scienceTrial:stepOne.errorName',
        'Name is required'
      );

    if (isEmpty(phone.PhoneNumber) || isNil(phone.PhoneNumber))
      errorDetail.phoneNumber = t(
        'scienceTrial:stepOne.errorPhoneNumber',
        'Phone number is required'
      );
    if (email === '' || !validateEmail(email)) {
      errorDetail.email = t(
        'scienceTrial:stepOne.errorEmail',
        'Email is required'
      );
    }
    if (isEmpty(errorDetail)) {
      const updatedPhoneCode = phone.PhoneCode;
      const updatedPhoneRaw = phone.PhoneNumber;
      const otpObj = {
        E164FormatPhoneNumber: `+${updatedPhoneCode}${updatedPhoneRaw}`,
        ParentName: name,
        ParentEmail: email,
        SubjectId: subjectId,
      };
      dispatch(setOTPInfo(otpObj));
      try {
        const res = await dispatch(requestSubjectTrialOTP(otpObj));
        if (res.payload?.IsSuccessful === true) {
          setCurrentStep(2);
        }
      } catch (error) {
        setErrMsg(error);
      }
    }
    setErrMsg(errorDetail);
  };

  return (
    <StyledContainer>
      <StyledForm onSubmit={saveDetails}>
        <div style={{ marginBottom: '2rem' }}>
          <StyledInput>
            <span className="inp-title name">
              {t('scienceTrial:stepOne.fullName', 'Full Name')}
            </span>
            <>
              <InputField
                style={{
                  marginLeft: '0.3rem',
                  border: errMsg.name && '1px solid red',
                  color: errMsg.name && '1px solid red',
                }}
                dataCy="name-input"
                type="text"
                val={name}
                placeholder={t(
                  'scienceTrial:stepOne.inputName',
                  'Please enter your name'
                )}
                changeVal={(e) => setName(e.target.value)}
              />
            </>
          </StyledInput>
          {errMsg.name && (
            <p className="error-input" data-cy="error-name">
              {errMsg.name}
            </p>
          )}
        </div>
        <div style={{ marginBottom: '1.5rem' }}>
          <StyledInput>
            <span className="inp-title number">
              {t('scienceTrial:stepOne.phoneNumber', 'Phone Number')}
            </span>
            <div className="phone-container">
              <div className="phone-container-box">
                <DropdownMenu
                  selectedValue={phone.PhoneCode ? `+${phone.PhoneCode}` : null}
                  values={countries.data || []}
                  setValue={setDropdownValue}
                  valueKey="printName"
                  indexKey="phonecode"
                  name="countryInput"
                  maxWidth="none"
                  containerWidth="auto"
                  width="auto"
                />
                <InputField
                  style={{
                    marginLeft: '0.3rem',
                    border: errMsg.phoneNumber && '1px solid red',
                    color: errMsg.phoneNumber && '1px solid red',
                  }}
                  dataCy="phone-input"
                  type="text"
                  val={phone.PhoneNumber}
                  placeholder={t(
                    'scienceTrial:stepOne.inputPhoneNumber',
                    'Mobile number'
                  )}
                  changeVal={(e) =>
                    setPhone({ ...phone, PhoneNumber: e.target.value })
                  }
                />
              </div>
              <p className="verification-msg">
                {t(
                  'scienceTrial:stepOne.verificationMsg',
                  'We will send a verification code to this number'
                )}
              </p>
              {errMsg.phoneNumber && (
                <p className="error-phone-input" data-cy="error-phone-number">
                  {errMsg.phoneNumber}
                </p>
              )}
            </div>
          </StyledInput>
        </div>
        <div style={{ marginBottom: '2rem' }}>
          <StyledInput>
            <span className="inp-title email">
              {t('scienceTrial:stepOne.email', 'Email Address')}
            </span>
            <InputField
              style={{
                marginLeft: '0.3rem',
                border: errMsg.email && '1px solid red',
                color: errMsg.email && '1px solid red',
              }}
              dataCy="email-input"
              type="email"
              val={email}
              disabled={Boolean(otpInfo?.EmailAddress)}
              placeholder={t(
                'scienceTrial:stepOne.inputEmail',
                'Please enter your email'
              )}
              changeVal={(e) => setEmail(e.target.value)}
            />
          </StyledInput>
          {errMsg.email && (
            <p className="error-input" data-cy="error-email">
              {errMsg.email}
            </p>
          )}
        </div>
        <div className="check-box-container">
          <CheckBox
            color="#6AC8FA"
            id="consent-checkbox"
            checked={checkedConsent}
            onChange={(e) => setCheckedConsent(e.target.checked)}
          />
          <div>
            <p>
              {t(
                `${
                  trialFormConfig[subjectIDs[subjectId].toLowerCase()]
                    .translationKey
                }:stepOne.checkbox`,
                `${trialFormConfig[subjectIDs[subjectId].toLowerCase()].term1}`
              )}
            </p>
            <p>
              {t(
                `${
                  trialFormConfig[subjectIDs[subjectId].toLowerCase()]
                    .translationKey
                }:stepOne.checkboxSubtext`,
                `${trialFormConfig[subjectIDs[subjectId].toLowerCase()].term2}`
              )}
            </p>
          </div>
        </div>
        {isErr && (
          <StyledErrMsg>
            {t(`scienceTrial:error.${isErr.replace('.', '')}`, isErr)}
          </StyledErrMsg>
        )}
        <StyledButton>
          <Button
            dataCy="next"
            variant="primary"
            width="200px"
            isDisabled={
              !checkedConsent ||
              isLoading ||
              (countries.isErr && !phone.PhoneCode)
            }
          >
            {t('scienceTrial:stepOne.nextStep', 'Next')}
          </Button>
        </StyledButton>
      </StyledForm>
    </StyledContainer>
  );
};

export default ParentForm;
