import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const Label = styled.label`
  font-size: 21px;
  color: #0e7d84;
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: 10px;
  font-weight: 700;
`;

export const Spacer = styled.label`
  width: 10px;
`;

export const Select = styled.select`
  visibility: ${({ invisible }) => (invisible ? 'hidden' : 'visible')};
  color: #0c2735;
  font-size: 16px;
  width: 180px;
  background-color: #f5f5f5;
  border-color: #c8c8c8;
  height: 38px;
  display: block;
  padding: 6px 12px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  &:focus: {
    outline: none;
  }
  &:hover,
  &:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
