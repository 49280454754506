import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDCCompletionStatusBySubjectId } from 'services/dailyChallenge';
import { fetchWrapper } from 'services/login';

export const getDCCompletionStatusbySubjectID = createAsyncThunk(
  'koochallenge/getDCCompletionStatus',
  async (subjectID) => {
    try {
      const res = await fetchWrapper(
        fetchDCCompletionStatusBySubjectId,
        subjectID
      );
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get DC completion status failed');
    }
  }
);

const initialState = {
  isDCCompletionStatusLoading: false,
  dcCompletionStatus: null,
  dcCompletionStatusError: null,
};

const koochallengeSlice = createSlice({
  name: 'koochallenge',
  initialState,
  reducers: {},
  extraReducers: {
    [getDCCompletionStatusbySubjectID.pending]: (state) => {
      state.isDCCompletionStatusLoading = true;
    },
    [getDCCompletionStatusbySubjectID.fulfilled]: (state, action) => {
      state.isDCCompletionStatusLoading = false;
      state.dcCompletionStatus = action.payload;
    },
  },
});

const { reducer } = koochallengeSlice;
export default reducer;
