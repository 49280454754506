import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 400px;
  min-height: 450px;
  border-radius: 20px;
  background: white;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 90%;
    margin: auto;
    img {
      width: 100%;
    }
  }
`;

export const ModalContent = styled.div`
  padding: 32px 44px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .title,
  .desc {
    text-align: left;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .desc {
    margin-bottom: 2rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 32px 30px 10px;
    .title {
      font-size: 18px;
      text-align: center;
    }
    .desc {
      font-size: 14px;
    }
  }
`;

export const StyledButton = styled.button`
  border: none;
  background: #ff6701;
  border-radius: 22px;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  min-width: 96px;
  :hover {
    background: #ff5801;
  }
`;
