import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import Title from 'components/Expired/Title';
import Description from 'components/Expired/Description';
// import PlanDetails from 'components/Expired/PlanDetails';
// import PaymentSuccess from 'components/Expired/PaymentSuccess';
import { Cards } from './index.styles';
import { useHistory } from 'react-router-dom';
const pageNo = 1;
const School = () => {
  const history = useHistory();
  const { studentDetails, defaultLevel, isExpired } = useSelector(
    (state) => state.studentDetails
  );
  const { plan, products, subject, currentProduct } = useSelector(
    (state) => state.plan
  );
  // const [pageNo, setPageNo] = useState(1);

  const redirectUser = () => {
    const url = `${
      process.env.REACT_APP_OFFICIAL_WEB_URL
    }plans/up?k21up=expired@k21S.koobits&k21up-qty=16.5&k21id=${
      studentDetails?.UserName ?? ''
    }&k21em=${studentDetails?.ParentsDetails?.Email ?? ''}&k21cn=${
      studentDetails?.FullName ?? ''
    }&k21lv=${defaultLevel ?? ''}&k21ph=${
      studentDetails?.ParentsDetails?.PhoneNumber?.PhoneNumberRaw ?? ''
    }&k22uid=${
      studentDetails?.UserId ?? ''
    }&utm_source=expiry&utm_medium=btn&utm_campaign=k21ex&utm_content=resub_sch`;
    window.location.replace(url);
  };
  // const showDesc = () => setPageNo(1);
  // const renewAcc = () => setPageNo(2);
  // const upgradeAcc = () => setPageNo(3);

  useEffect(() => {
    const isMath = products?.find(
      (item) => item.subject.toLowerCase() === 'math'
    );
    const isEligibleTryOTPMath =
      !isMath?.otpTrialDetails.triedOTP && isMath?.hasExpiredSchool;

    const directlyToTrialPage =
      isNil(currentProduct) &&
      subject === 'math' &&
      plan === 'school' &&
      isExpired &&
      isMath?.otpTrialDetails?.otpStatus === 'otp-not-started';
    if (directlyToTrialPage && isEligibleTryOTPMath) {
      history.push('/trial/math');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <>
      {pageNo === 1 && <Title type="school" />}
      <Cards>
        {pageNo === 1 && (
          <Description
            type="school"
            name={studentDetails.FullName}
            renew={redirectUser}
            // upgrade={upgradeAcc}
          />
        )}
        {/* {pageNo === 2 && <PlanDetails type="school" goBack={showDesc} />}
        {pageNo === 3 && <PlanDetails type="school" goBack={renewAcc} />}
        {pageNo === 4 && <PaymentSuccess type="school" />} */}
      </Cards>
    </>
  );
};

export default School;
