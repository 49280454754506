import React from 'react';
import { ReactComponent as CloseIconImg } from 'assets/img/dashboardv2/close_icon.svg';
import IconButton from './IconButton';

const CloseIconButton = ({
  btnBgColor,
  onClick,
  size,
  dataCy,
  padding,
  color,
}) => {
  return (
    <IconButton
      data-cy={dataCy}
      padding={padding}
      size={size}
      color={color}
      btnBgColor={btnBgColor}
      onClick={onClick}
      Icon={CloseIconImg}
    />
  );
};

export default CloseIconButton;
