import store from 'store/index';

export function redirectToOld() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  const state = store.getState();
  const secretKey =
    state.studentDetails && state.studentDetails.studentDetails.MySecret;
  const url = `${process.env.REACT_APP_KOOBITS_DOMAIN}/Client/Redirect?encryptedString=${secretKey}&controllerName=home&actionName=index`;
  window.location.replace(url);
}
