import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isNil } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken } from 'store/login/loginSlice';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import { getProducts } from 'store/plan/planSlice';
import shouldRedirectMapping from 'helpers/redirectionMapping';

// Helper
const checkIfLoggedIn = (env) => {
  const cookies = Cookies.get();
  let isLoggedIn;
  if (env === 'dev') {
    if (!isNil(localStorage.getItem('access_token'))) {
      isLoggedIn = true;
    } else if (isNil(cookies?.sp_access_token)) {
      isLoggedIn = false;
    } else {
      isLoggedIn = true;
      localStorage.setItem('access_token', cookies?.sp_access_token);
      localStorage.setItem('refresh_token', cookies?.sp_refresh_token);
    }
  } // env = prod
  else if (!isNil(cookies?.sp_access_token)) {
    isLoggedIn = true;
    localStorage.setItem('access_token', cookies?.sp_access_token);
    localStorage.setItem('refresh_token', cookies?.sp_refresh_token);
  } else if (!isNil(localStorage.getItem('access_token'))) {
    isLoggedIn = true;
  } else {
    isLoggedIn = false;
  }
  return isLoggedIn;
};

// Custom Hooks
const useProducts = () => {
  const dispatch = useDispatch();
  const { isParentAccount } = useSelector((state) => state.login);
  const { isLoading, studentDetails, error } = useSelector(
    (state) => state.studentDetails
  );
  const hasStudentDetailsLoaded =
    !isLoading && (studentDetails !== null || error !== null);

  useEffect(() => {
    if (
      process.env.REACT_APP_SCIENCE === 'true' &&
      isParentAccount === false &&
      hasStudentDetailsLoaded === true
    ) {
      dispatch(getProducts());
    }
  }, [dispatch, hasStudentDetailsLoaded, isParentAccount]);
};

const AuthenticatedRoute = ({
  component: Component,
  isParentRoute,
  isExpiredRoute = false,
  path,
  ...rest
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isExpired, isLoading } = useSelector((state) => state.studentDetails);
  const { subject, products, currentProduct } = useSelector(
    (state) => state.plan
  );
  const { userID, isParentAccount } = useSelector((state) => state.login);
  const env =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_NETLIFY === 'true'
      ? 'dev'
      : 'prod';
  const isLoggedIn = checkIfLoggedIn(env);
  let loginRoute = env === 'dev' ? '/login' : '/members-login';
  const shouldRedirect = shouldRedirectMapping(
    location.pathname,
    location.search
  );
  if (shouldRedirect) {
    loginRoute += `?redirect=${location.pathname.substring(1)}`;
    if (location.search) {
      loginRoute += location.search;
    }
  }
  const expiredRedirectUrl = () => {
    const currentSubjectProd = products?.find(
      (item) => item.subject.toLowerCase() === subject
    );
    const isMath = products?.find(
      (item) => item.subject.toLowerCase() === 'math'
    );
    const isScience = products?.find(
      (item) => item.subject.toLowerCase() === 'science'
    );

    const isEligibleTryOTPMath =
      !isMath?.otpTrialDetails.triedOTP &&
      isMath?.hasExpiredSchool &&
      !isMath.otpTrialDetails.otpStatus === 'otp-purchased-expired';

    const isEligibleTryOTPScience =
      isScience?.otpTrialDetails?.otpStatus === 'otp-not-started';

    // otp expired
    if (
      (subject === 'math' &&
        currentSubjectProd?.otpTrialDetails?.otpStatus ===
          'otp-trial-expired') ||
      (subject === 'science' &&
        currentSubjectProd?.otpTrialDetails?.otpStatus ===
          'otp-purchased-expired') ||
      currentSubjectProd?.otpTrialDetails?.otpStatus === 'otp-trial-expired'
    ) {
      return `/expired-otp/${subject}`;
    }
    // never try OTP & expired
    if (
      isExpired &&
      ((isEligibleTryOTPMath && subject === 'math') ||
        (isEligibleTryOTPScience && subject === 'science')) &&
      isNil(currentProduct)
    ) {
      return `trial/${subject}`;
    }

    return subject === 'science'
      ? '/expired-otp/science'
      : `/${subject}/expired`;
  };

  useEffect(() => {
    if (userID === null) {
      dispatch(
        refreshToken({
          redirect: shouldRedirect
            ? location.pathname.substring(1) + location.search
            : null,
        })
      );
    }
  }, [dispatch, location.pathname, location.search, userID, shouldRedirect]);
  useEffect(() => {
    if (isParentAccount === false) {
      dispatch(getStudentDetails());
    }
  }, [dispatch, isParentAccount]);
  useProducts();
  const renderComponent = (props) => {
    if (isLoggedIn) {
      if (
        !isLoading &&
        isExpired &&
        isExpiredRoute !== true &&
        (path?.includes('/expired') === false ||
          path !== `/${subject}/expired`) &&
        products
      ) {
        return (
          <Redirect
            to={{
              pathname: expiredRedirectUrl(),
              state: { from: props.location },
            }}
          />
        );
      }
      return <Component {...props} />;
    }
    return <Redirect to={loginRoute} />;
  };
  // Check if this route is supposed to be accessible to parent account
  if (isParentRoute !== true && isParentAccount === true) {
    return (
      <Redirect
        to={{
          pathname: '/parent/settings/bill-subscription',
        }}
      />
    );
  }
  if (isParentRoute === true && isParentAccount === false) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }
  return <Route {...rest} render={renderComponent} />;
};

export default AuthenticatedRoute;
