import SchoolPlan from 'assets/img/plans/school.svg';
import PlusPlan from 'assets/img/plans/plus.svg';
import PremiumPlan from 'assets/img/plans/premium.svg';
import Mission from 'assets/img/dashboard/mission.svg';
import MissionPlus from 'assets/img/dashboard/mission-plus.svg';
// import MissionPremium from 'assets/img/dashboard/mission-premium.svg';
import Multiplayer from 'assets/img/dashboard/multiplayer.svg';
import MultiplayerPremium from 'assets/img/dashboard/multiplayer-premium.svg';
import Assignment from 'assets/img/dashboard/assignment.svg';
// import KooQuizPlus from 'assets/img/dashboard/kooquiz-plus.svg';
import KooQuizPremium from 'assets/img/dashboard/kooquiz-premium.svg';
import PlayBrainGame from 'assets/img/dashboard/play-braingames.svg';
import PlayStoryMath from 'assets/img/dashboard/play-storymath.svg';

import BrainGames from 'assets/img/sidepanel/braingames.svg';
import StoryMath from 'assets/img/sidepanel/storymath.svg';
import Events from 'assets/img/sidepanel/events.png';
import Leaderboard from 'assets/img/sidepanel/leaderboard.svg';
import Friends from 'assets/img/sidepanel/friends.png';

import AssignmentIcon from 'assets/img/sidepanel/assignment.svg';
import BlueGlobe from 'assets/img/globe-blue.svg';
import PurpleGlobe from 'assets/img/globe-purple.svg';

import HW from 'assets/img/assignment/start-page-hw.svg';
import Test from 'assets/img/assignment/start-page-test.svg';
import MT from 'assets/img/assignment/start-page-mt.svg';

import SMC from 'assets/img/events/sunday-MC-no-text.svg';
import SMC2 from 'assets/img/events/sunday-MC-no-text.svg';
import addActive from 'assets/img/assignment/four-ops/addition-active.svg';
import addInactive from 'assets/img/assignment/four-ops/addition-inactive.svg';
import subActive from 'assets/img/assignment/four-ops/subtraction-active.svg';
import subInactive from 'assets/img/assignment/four-ops/subtraction-inactive.svg';
import divActive from 'assets/img/assignment/four-ops/division-active.svg';
import divInactive from 'assets/img/assignment/four-ops/division-inactive.svg';
import mulActive from 'assets/img/assignment/four-ops/multiply-active.svg';
import mulInactive from 'assets/img/assignment/four-ops/multiply-inactive.svg';
import MathCP from 'assets/img/sidepanel/CP.png';
import ScienceCP from 'assets/img/sidepanel/science-cp.png';

export const MAINTENANCE_ROUTE = '/maintenance-redirect';

export const languages = {
  en: 'En',
  ba: 'Ba',
  zh_CN: 'zh_CN',
  zh_TW: 'zh_TW',
};

export const featureToggle = {
  // NavProfile.js
  planDropdown: true,
  languageDropdown: false,
  // Features
  sundayMiniChallenge: true,
  smcDatePicker: process.env.REACT_APP_DEV === 'true',
  mission: true,
  multiplayer: true,
  dailyChallenge: true,
  story: true,
  storyMath: true,
  heroBadgeCalendar: true,
  leaderboard: true,
  friends: true,
  report: true,
  kooQuiz: true,
  brainGame: true,
  profile: true,
  kooClass: true,
  notification: true,
  staticNotification: false,
  expLevel: process.env.REACT_APP_EXPLEVEL === 'true',
  science: process.env.REACT_APP_SCIENCE === 'true',
  eventTracking: process.env.REACT_APP_EVENT_TRACKING === 'true',
};
export const ALL_SUBJECT_WITH_PLANS = {
  math: {
    premium: 'premium',
    school: 'school',
  },
  science: {
    premium: 'premium',
  },
};
// Stores config for different plans
export const subjectToPlanMapping = (subject, plan) => {
  if (subject === 'math' || subject === 'science') {
    return plans[plan];
  }
  return plans.plus;
};

export const subjectToThemeMapping = (subject, plan) => {
  if (subject === 'math') {
    return plan;
  }
  if (subject === 'science') {
    return 'science';
  }
  return plan;
};

const toggleStory = () => (featureToggle.science ? '/story' : '/story/math');

// For Math
export const plans = {
  standard: {
    planName: 'Standard',
    planLogo: SchoolPlan,
    globeImg: BlueGlobe,
    cpIcon: MathCP,
    studentDetailsBg: 'rgba(255, 255, 255, 0.22)',
    leftPanel: [
      {
        name: 'Brain Games',
        src: BrainGames,
        link: featureToggle.brainGame ? '/brain-game' : null,
        isFeatureReady: featureToggle.brainGame,
      },
      {
        name: 'Story Math',
        src: StoryMath,
        link: featureToggle.story ? toggleStory() : null,
        isFeatureReady: featureToggle.story,
      },
      {
        name: 'Events',
        src: Events,
        link: featureToggle.sundayMiniChallenge ? '/events' : null,
        isFeatureReady: featureToggle.sundayMiniChallenge,
      },
    ],
    cardLayoutType: 1,
    cards: [
      {
        name: 'Mission',
        bg: Mission,
        description: 'Practise by topics. Learn at your own pace!',
        isFeatureReady: featureToggle.mission,
        link: featureToggle.mission ? '/mission/curriculum-list' : null,
      },
      {
        name: 'Multiplayer',
        bg: Multiplayer,
        description: 'Challenge friends around the world',
        isFeatureReady: featureToggle.multiplayer,
        link: featureToggle.multiplayer ? '/multiplayer' : null,
      },
      {
        name: 'Assignment',
        bg: Assignment,
        description: 'Complete assignment to earn KoKo credits!',
        link: '/assignment',
        isFeatureReady: true,
      },
    ],
  },
  school: {
    planName: 'School',
    planLogo: SchoolPlan,
    globeImg: BlueGlobe,
    cpIcon: MathCP,
    studentDetailsBg: 'rgba(255, 255, 255, 0.22)',
    leftPanel: [
      {
        name: 'Brain Games',
        src: BrainGames,
        link: featureToggle.brainGame ? '/brain-game' : null,
        isFeatureReady: featureToggle.brainGame,
      },
      {
        name: 'Events',
        src: Events,
        link: featureToggle.sundayMiniChallenge ? '/events' : null,
        isFeatureReady: featureToggle.sundayMiniChallenge,
      },
      {
        name: 'Story Math',
        src: StoryMath,
        link: featureToggle.story ? toggleStory() : null,
        isFeatureReady: featureToggle.story,
      },
    ],
    cardLayoutType: 1,
    cards: [
      {
        name: 'Mission',
        bg: Mission,
        description: 'Practise by topics. Learn at your own pace!',
        isFeatureReady: featureToggle.mission,
        link: featureToggle.mission ? '/mission/curriculum-list' : null,
      },
      {
        name: 'Multiplayer',
        bg: Multiplayer,
        description: 'Challenge friends around the world',
        isFeatureReady: featureToggle.multiplayer,
        link: featureToggle.multiplayer ? '/multiplayer' : null,
      },
      {
        name: 'Assignment',
        bg: Assignment,
        description: 'Complete assignment to earn KoKo credits!',
        link: '/assignment/assignment-list',
        isFeatureReady: true,
      },
    ],
  },
  plus: {
    planName: 'Plus',
    planLogo: PlusPlan,
    globeImg: PurpleGlobe,
    cpIcon: MathCP,
    studentDetailsBg: 'rgba(255, 255, 255, 0.22)',
    leftPanel: [
      {
        name: 'Brain Games',
        src: BrainGames,
        link: featureToggle.brainGame ? '/brain-game' : null,
        isFeatureReady: featureToggle.brainGame,
      },
      {
        name: 'Events',
        src: Events,
        link: featureToggle.sundayMiniChallenge ? '/events' : null,
        isFeatureReady: featureToggle.sundayMiniChallenge,
      },
      {
        name: 'Story Math',
        src: StoryMath,
        link: featureToggle.story ? toggleStory() : null,
        isFeatureReady: featureToggle.story,
      },
      {
        name: 'Assignment',
        src: AssignmentIcon,
        link: '/assignment',
      },
    ],
    cardLayoutType: 1,
    cards: [
      {
        name: 'Mission',
        bg: MissionPlus,
        description: 'Practise by topics. Learn at your own pace!',
        isFeatureReady: featureToggle.mission,
        link: featureToggle.mission ? '/mission/curriculum-list' : null,
      },
      {
        name: 'Multiplayer',
        bg: Multiplayer,
        description: 'Challenge friends around the world',
        isFeatureReady: featureToggle.multiplayer,
        link: featureToggle.multiplayer ? '/multiplayer' : null,
      },
      {
        name: 'KooQuiz',
        bg: KooQuizPremium,
        description: 'Revisions made easy',
        isFeatureReady: featureToggle.kooQuiz,
        link: '/kooQuiz',
        // link: 'kooQuiz/solution/800725/4788814',
      },
    ],
  },
  premium: {
    planName: '',
    planLogo: PremiumPlan,
    globeImg: PurpleGlobe,
    cpIcon: MathCP,
    studentDetailsBg: 'rgba(0, 0, 0, 0.11)',
    leftPanel: [
      {
        name: 'Leaderboard',
        src: Leaderboard,
        link: featureToggle.leaderboard ? '/leaderboard' : null,
        isFeatureReady: featureToggle.leaderboard,
      },
      {
        name: 'Friends',
        src: Friends,
        link: featureToggle.friends ? '/friends' : null,
        isFeatureReady: featureToggle.friends,
      },
      {
        name: 'Events',
        src: Events,
        link: featureToggle.sundayMiniChallenge ? '/events' : null,
        isFeatureReady: featureToggle.sundayMiniChallenge,
      },
      // {
      //   name: 'Assignment',
      //   src: AssignmentIcon,
      //   link: '/assignment',
      // },
    ],
    cardLayoutType: 2,
    cards: [
      {
        name: 'Brain Games',
        bg: PlayBrainGame,
        description: 'Give your brain a workout!',
        isFeatureReady: featureToggle.brainGame,
        link: featureToggle.brainGame ? '/brain-game' : null,
        btnColor: '#068AD3',
      },
      {
        name: 'Multiplayer',
        bg: MultiplayerPremium,
        description: 'Challenge friends around the world',
        isFeatureReady: featureToggle.multiplayer,
        link: featureToggle.multiplayer ? '/multiplayer' : null,
        btnColor: '#D88B33',
      },
      {
        name: 'Story Math',
        bg: PlayStoryMath,
        description: `The book of nature is written 
        in the language of mathematics.`,
        isFeatureReady: featureToggle.story,
        link: featureToggle.story ? toggleStory() : null,
        btnColor: '#70AD13',
      },
    ],
  },
  parent: {
    planName: 'Parent',
    planLogo: '',
    globeImg: '',
    cpIcon: '',
    leftPanel: [],
    cardLayoutType: null,
    cards: [],
  },
};
// For Science
export const sciencePlans = {
  premium: {
    planName: '',
    planLogo: PremiumPlan,
    globeImg: null,
    cpIcon: ScienceCP,
    studentDetailsBg: 'rgba(0, 0, 0, 0.11)',
    leftPanel: [
      {
        name: 'Leaderboard',
        src: Leaderboard,
        link: featureToggle.leaderboard ? '/leaderboard' : null,
        isFeatureReady: featureToggle.leaderboard,
      },
      {
        name: 'Friends',
        src: Friends,
        link: featureToggle.friends ? '/friends' : null,
        isFeatureReady: featureToggle.friends,
      },
      {
        name: 'Events',
        src: Events,
        link: featureToggle.sundayMiniChallenge ? '/events' : null,
        isFeatureReady: featureToggle.sundayMiniChallenge,
      },
    ],
    cardLayoutType: 2,
    cards: [
      {
        name: 'Brain Games',
        bg: PlayBrainGame,
        description: 'Give your brain a workout!',
        isFeatureReady: featureToggle.brainGame,
        link: featureToggle.brainGame ? '/brain-game' : null,
        btnColor: '#068AD3',
      },
      {
        name: 'Multiplayer',
        bg: MultiplayerPremium,
        description: 'Challenge friends around the world',
        isFeatureReady: featureToggle.multiplayer,
        link: featureToggle.multiplayer ? '/multiplayer' : null,
        btnColor: '#D88B33',
      },
      {
        name: 'Story Science',
        bg: PlayStoryMath,
        description: `Immersive stories in the language of science`,
        isFeatureReady: featureToggle.story,
        link: featureToggle.story ? toggleStory() : null,
        btnColor: '#70AD13',
      },
    ],
  },
};

// assignment list page
export const assignmentTypeColors = {
  homework: '#54CBAF',
  mocktest: '#FFD016',
  test: '#FF792D',
};

export const assignmentTypeNames = {
  homework: 'HW',
  mocktest: 'Mock',
  test: 'Test',
  factualfluency: 'FF',
};

// start assignment page
export const assignmentBackButton = {
  homework: 'rgb(0, 185, 143, 0.59)',
  mocktest: 'rgb(255, 200, 19, 0.70)',
  test: 'rgb(255, 153, 99, 0.72)',
  sundayMC: 'rgb(255, 110, 99, 0.72)',
  sundayMC2: 'rgb(255, 110, 99, 0.72)',
};

export const assignmentImages = {
  homework: HW,
  mocktest: MT,
  test: Test,
  sundayMC: SMC,
  sundayMC2: SMC2,
};

export const assignmentText = {
  homework: 'Homework',
  mocktest: 'Mock Test',
  test: 'Test',
};

export const assignmentTextColor = {
  homework: '#46C8AA',
  mocktest: '#FECD0B',
  test: '#FF6210',
  sundayMC: '#EF2E1F',
};

export const assignerEnum = {
  0: 'All',
  1: 'KooBits',
  2: 'Parents',
  3: 'Teachers',
};

export const FilterSubmissionStatus = {
  All: 0,
  Complete: 1,
  Incomplete: 2,
};

export const ChallengeBy = {
  'Challenges from friends': 0,
  'Challenges from me': 1,
};

export const FilterSubjectStatus = {
  Maths: 1,
  Science: 2,
};

export const SubjectEnum = {
  1: 'math',
  2: 'science',
};

export const SubmissionStatus = {
  0: 'Unknown',
  1: 'Saved',
  2: 'Submitted',
  3: 'Withdrawn',
};

export const SubmissionResultTypes = {
  1: 'correct',
  2: 'wrong',
  3: 'unknown',
  4: 'skipped',
};

export const HomeworkStatus = {
  1: 'Assigned',
  2: 'Created',
  3: 'Deleted',
  4: 'Locked',
  6: 'Scheduled',
  7: 'Draft',
};

export const assignmentTypeIDs = {
  1: 'homework',
  2: 'homework',
  3: 'homework',
  11: 'mocktest',
  12: 'test',
  13: 'factualfluency',
};

export const submissionResultIDs = {
  0: 'noevaluated',
  1: 'correct',
  2: 'wrong',
  3: 'unknown',
  4: 'skipped',
};

export const answerTypeIDs = {
  1: 'Image',
  2: 'Text',
  3: 'Number',
  4: 'Equation',
  5: 'Fraction',
  6: 'FreeText',
};

export const answerTypes = {
  Image: 1,
  Text: 2,
  Number: 3,
  Equation: 4,
  Fraction: 5,
  FreeText: 6,
};

export const submissionTypeIDs = {
  SkillPractice: 1,
  Homework: 2,
  DailyChallenge: 3,
  PeerChallenge: 4,
  MiniChallenge: 5,
  SkillPracticeMS: 6,
  Factualfluency: 13,
};

export const friendsStatus = {
  0: 'Unknown',
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected',
  4: 'Withdrawn',
};

export const questionTypeIDs = {
  1: 'mcq',
  2: 'fillInTheBlanks',
  3: 'longAnswer',
};

export const peerChallengeResult = {
  1: 'win',
  2: 'draw',
  3: 'lose',
  4: 'pending',
  5: 'unknown',
  6: 'notStarted',
  7: 'expired',
  8: 'rejected',
};

export const peerChallengeStatus = {
  1: 'created',
  2: 'attempted',
  3: 'accepted',
  4: 'rejected',
  5: 'completed',
  6: 'expired',
  7: 'archived',
};

export const scoreColorsMap = (score) => {
  if (score <= 59) {
    return '#FF3939';
  }
  if (score <= 79) {
    return '#FF6F00';
  }
  if (score <= 100) {
    return '#11A73F';
  }
};

// event Submission Status
export const eventSubmissionStatus = {
  0: 'Not Started',
  1: 'Saved',
  2: 'Submitted',
  3: 'Withdrawn',
  4: 'Claimed',
};

// plans type title
export const plansTitle = {
  standard: 'Standard Plan',
  newStandard: 'Standard Plan',
  premium: 'Premium Plan',
  plus: 'Plus Plan',
  school: 'Standard (School) Plan',
};

// in Four ops tool
export const operationType = [
  { type: 'addition', activeIcon: addActive, inActiveIcon: addInactive },
  { type: 'subtraction', activeIcon: subActive, inActiveIcon: subInactive },
  { type: 'multiplication', activeIcon: mulActive, inActiveIcon: mulInactive },
  { type: 'division', activeIcon: divActive, inActiveIcon: divInactive },
];

// in Four ops addition element
export const addition = [
  [
    { one: 'empty', two: 'c', three: 'c', four: 'c', five: 'empty' },
    { one: 'empty', two: 'i', three: 'i', four: 'i', five: 'i' },
    { one: 'add', two: 'i', three: 'i', four: 'i', five: 'i' },
    { one: 'dash', two: 'dash', three: 'dash', four: 'dash', five: 'dash' },
    { one: 'i', two: 'i', three: 'i', four: 'i', five: 'i' },
  ],
  [
    { one: 'empty', two: 'c', three: 'c', four: 'empty' },
    { one: 'empty', two: 'i', three: 'i', four: 'i' },
    { one: 'add', two: 'i', three: 'i', four: 'i' },
    { one: 'dash', two: 'dash', three: 'dash', four: 'dash' },
    { one: 'i', two: 'i', three: 'i', four: 'i' },
  ],
];

// in Four ops subtraction element
export const subtraction = [
  [
    { one: 'empty', two: 'c', three: 'c', four: 'c', five: 'c' },
    { one: 'empty', two: 'i', three: 'i', four: 'i', five: 'i' },
    { one: 'sub', two: 'i', three: 'i', four: 'i', five: 'i' },
    { one: 'dash', two: 'dash', three: 'dash', four: 'dash', five: 'dash' },
    { one: 'empty', two: 'i', three: 'i', four: 'i', five: 'i' },
  ],
  [
    { one: 'empty', two: 'c', three: 'c', four: 'c' },
    { one: 'empty', two: 'i', three: 'i', four: 'i' },
    { one: 'sub', two: 'i', three: 'i', four: 'i' },
    { one: 'dash', two: 'dash', three: 'dash', four: 'dash' },
    { one: 'empty', two: 'i', three: 'i', four: 'i' },
  ],
];

// in Four ops multiplication element
export const multiplication = [
  [
    { one: 'empty', two: 'empty', three: 'c', four: 'empty' },
    { one: 'empty', two: 'empty', three: 'i', four: 'i' },
    { one: 'empty', two: 'mul', three: 'i', four: 'i' },
    { one: 'dash', two: 'dash', three: 'dash', four: 'dash' },
    { one: 'c', two: 'c', three: 'empty', four: 'empty' },
    { one: 'empty', two: 'i', three: 'i', four: 'i' },
    { one: 'i', two: 'i', three: 'i', four: 'empty' },
    { one: 'dash', two: 'dash', three: 'dash', four: 'dash' },
    { one: 'i', two: 'i', three: 'i', four: 'i' },
  ],
  [
    { one: 'empty', two: 'empty', three: 'c', four: 'c', five: 'empty' },
    { one: 'empty', two: 'empty', three: 'i', four: 'i', five: 'i' },
    { one: 'empty', two: 'mul', three: 'empty', four: 'i', five: 'i' },
    { one: 'dash', two: 'dash', three: 'dash', four: 'dash', five: 'dash' },
    { one: 'c', two: 'c', three: 'c', four: 'empty', five: 'empty' },
    { one: 'empty', two: 'i', three: 'i', four: 'i', five: 'i' },
    { one: 'i', two: 'i', three: 'i', four: 'i', five: 'empty' },
    { one: 'dash', two: 'dash', three: 'dash', four: 'dash', five: 'dash' },
    { one: 'i', two: 'i', three: 'i', four: 'i', five: 'i' },
  ],
];

// in Four ops division element
export const division = [
  [
    { one: 'empty-div', two: 'empty-div', three: 'i', four: 'i', five: 'i' },
    {
      one: 'empty-h',
      two: 'empty-h',
      three: 'div-dash-small',
      four: 'div-dash-small',
      five: 'div-dash-small',
    },
    { one: 'i', two: 'div', three: 'i', four: 'i', five: 'i' },
    {
      one: 'empty-div',
      two: 'empty-div',
      three: 'i',
      four: 'i',
      five: 'empty',
    },
    {
      one: 'empty-div',
      two: 'empty-div',
      three: 'div-dash-small',
      four: 'div-dash-small',
      five: 'div-dash-small',
    },
    { one: 'empty-div', two: 'empty-div', three: 'i', four: 'i', five: 'i' },
    { one: 'empty-div', two: 'empty-div', three: 'i', four: 'i', five: 'i' },
    {
      one: 'empty-div',
      two: 'empty-div',
      three: 'div-dash-small',
      four: 'div-dash-small',
      five: 'div-dash-small',
    },
    {
      one: 'empty-div',
      two: 'empty-div',
      three: 'empty',
      four: 'i',
      five: 'i',
    },
    {
      one: 'empty-div',
      two: 'empty-div',
      three: 'empty',
      four: 'i',
      five: 'i',
    },
    {
      one: 'empty-div',
      two: 'empty-div',
      three: 'div-dash-small',
      four: 'div-dash-small',
      five: 'div-dash-small',
    },
    {
      one: 'empty-div',
      two: 'empty-div',
      three: 'empty',
      four: 'empty',
      five: 'i',
    },
  ],
  [
    { one: 'empty-div', two: 'empty-div', three: 'i', four: 'i' },
    { one: 'empty-h', two: 'empty-h', three: 'div-dash', four: 'div-dash' },
    { one: 'i', two: 'div', three: 'i', four: 'i' },
    { one: 'empty-div', two: 'empty-div', three: 'i', four: 'i' },
    { one: 'empty-div', two: 'empty-div', three: 'div-dash', four: 'div-dash' },
    { one: 'empty-div', two: 'empty-div', three: 'i', four: 'i' },
    { one: 'empty-div', two: 'empty-div', three: 'i', four: 'i' },
    { one: 'empty-div', two: 'empty-div', three: 'div-dash', four: 'div-dash' },
    { one: 'empty-div', two: 'empty-div', three: 'empty', four: 'i' },
  ],
];

export const barModelOptions = {
  barModelColorOptions: [
    '#FF6900',
    '#FCB900',
    '#7BDCB5',
    '#00D084',
    '#8ED1FC',
    '#0693E3',
    '#ABB8C3',
    '#EB144C',
    '#F78DA7',
    '#9900EF',
  ],
  components: {
    defaultSize: 100,
    breakIntoMax: 20,
    barHeight: 30,
    arrowHeight: 20,
    braceHeight: 20,
    verticalBraceWidth: 20,
  },
  workspace: {
    minWidth: 350,
    minHeight: 450,
    maxWidth: 600,
    maxHeight: 600,
  },
};

export const superSpeedOptions = {
  totalTime: 120,
  totalTimeEachQuestion: 10,
};

export const superVisionOptions = {
  totalTime: 300,
};
