import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { getCurrentTerm } from 'pages/Leaderboard/v2/helper';

import DropdownMenu from 'components/Shared/DropdownMenu';
import ToggleIcon from 'assets/img/icon-toggle.svg';

import Container from './TermDropdown.styles';

const TermDropdown = ({ currentTerm, setCurrentTerm }) => {
  const { t } = useTranslation(['leaderboard']);
  const { data } = useSelector((state) => state.leaderboard.terms);

  useEffect(() => {
    if (!isEmpty(data)) {
      setCurrentTerm(getCurrentTerm(data));
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Container>
      <DropdownMenu
        dataCy="dropdown-season"
        selectedValue={t(
          'leaderboard:topBrainBanner.term',
          { number: currentTerm?.termId },
          `Term ${currentTerm?.termId}`
        )}
        indexKey
        values={data}
        valueKey="termId"
        valueTemplate={(value) =>
          t(
            'leaderboard:topBrainBanner.term',
            { number: value },
            `Term ${value}`
          )
        }
        setValue={setCurrentTerm}
        width="150px"
        borderRadius="3px"
        toggleIcon={
          <div className="toggle-icon">
            <img src={ToggleIcon} alt="" />
          </div>
        }
      />
    </Container>
  );
};

export default TermDropdown;
