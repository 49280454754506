// hall of fame card colors
export const borderColors = {
  1: '#E2B91E',
  2: '#F2982F',
  3: '#B7D033',
  4: '#E094D5',
  5: '#A695E2',
  6: '#58B7D3',
};

export const headerColors = {
  1: '#FFDF67',
  2: '#FFB865',
  3: '#D9EB73',
  4: '#F5D7F1',
  5: '#E0D8FF',
  6: '#B7EBFA',
};

export const fontColors = {
  1: '#B28C00',
  2: '#C46900',
  3: '#6F9D07',
  4: '#AD60A3',
  5: '#8C7CC4',
  6: '#3D97B1',
};

export const contentColors = {
  1: '#FFFAE0',
  2: '#FFF6EB',
  3: '#F9FFD9',
  4: '#FFF2FD',
  5: '#F5F2FF',
  6: '#EFFBFF',
};

export const footerColors = {
  1: '#FFF0B9',
  2: '#FFE5C6',
  3: '#F0FF9D',
  4: '#F5DAF2',
  5: '#E8E2FF',
  6: '#DBF7FF',
};
