import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffdf32;
  height: 108px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
`;

export const ButtonWrap = styled.div`
  cursor: pointer;
`;
export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;
export const InfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TextInfo = styled.div`
  font-family: Arial;
  color: #000000;
  font-size: 0.9em;
  line-height: 1em;
  text-align: right;
  margin-right: 10px;
`;

export const TextCount = styled.div`
  font-family: Arial;
  font-size: 2em;
  color: #000000;
`;
