import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 150px;

  button {
    display: flex;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    padding: 5px 15px;
    color: #404447;
    box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.07);
    &:hover {
      background: #efefef;
    }
  }
  span {
    margin-right: 10px;
  }
  img {
    vertical-align: text-top;
  }
  p {
    margin-bottom: 0px;
    margin-right: 0px;
  }
  @media (max-width: 1290px) {
    right: 100px;
  }
  @media (max-width: 1150px) {
    right: 50px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    position: static;
    button {
      border-radius: 50%;
      padding: 4px 8px;
    }
    span {
      margin-right: 0;
    }
    img {
      vertical-align: middle;
    }
    .refresh-text {
      display: none;
    }
  }
`;
