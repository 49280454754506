import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSubscriptionPlans } from 'services/payment';

// Get subscription plans by customer id
export const getSubscriptionPlans = createAsyncThunk(
  'payment/getSubscriptionPlans',
  async () => {
    try {
      const res = await fetchSubscriptionPlans();
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get subscription plans failed');
    }
  }
);

const initialState = {
  isLoading: false,
  isError: null,
  subscriptionPlans: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: {
    [getSubscriptionPlans.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getSubscriptionPlans.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subscriptionPlans = action.payload;
    },
    [getSubscriptionPlans.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
  },
});

const { reducer } = paymentSlice;
export default reducer;
