import React, { useState } from 'react';
import { Container, Block, OverlayContainer } from './OverlayCard.styles';

const Overlay = ({ heading, desc }) => {
  return (
    <OverlayContainer>
      <div className="hover-desc-block">
        <div className="title">{heading}</div>
      </div>
    </OverlayContainer>
  );
};

const OverlayCard = ({
  onClick,
  illustration,
  bg,
  heading,
  desc,
  noOverlay,
  icon,
  plan,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Container
      onClick={onClick}
      hover={showOverlay}
      onMouseEnter={() => !noOverlay && setShowOverlay(true)}
      onMouseLeave={() => !noOverlay && setShowOverlay(false)}
    >
      {plan && (
        <img src={plan} alt="planlogo" className="planlogo" height="22px" />
      )}
      <Block bg={bg} showOverlay={showOverlay} icon={icon}>
        <div className="desc-block">
          {illustration && (
            <img
              className="illustration"
              src={illustration}
              alt="illustration"
            />
          )}
          <div className="title">
            {icon && <img className="icon" src={icon} alt="icon" />}
            {heading}
          </div>
        </div>
      </Block>
      {showOverlay && !noOverlay && <Overlay heading={heading} desc={desc} />}
    </Container>
  );
};

export default OverlayCard;
