import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DailyChallengeIcon from 'assets/img/dailychallenge/earn-more-cps/daily-challenge-icon.png';
import PeerChallengeIcon from 'assets/img/dailychallenge/earn-more-cps/peer-challenge-icon.png';
import SMCIcon from 'assets/img/dailychallenge/earn-more-cps/smc-icon.png';
import useDashboard from 'hooks/useDashboard';
import {
  Container,
  Button,
  StyledChallengeIcon,
} from './EarnMoreCPsModal.styles';

const EarnMoreCPsModal = ({ onHide, subject = 1 }) => {
  const { t } = useTranslation(['dailyChallenge']);
  const history = useHistory();
  const { isDashboardv2 } = useDashboard();
  const challenges =
    subject === 2
      ? [
          {
            id: 1,
            name: 'Daily Challenge',
            description: 'up to 9 CPs (per day)',
            icon: DailyChallengeIcon,
            translation: 'scDailyChallenge',
          },
          {
            id: 2,
            name: 'Peer Challenge',
            description: 'up to 3 CPs (per day)',
            icon: PeerChallengeIcon,
            translation: 'scPeerChallenge',
            link: isDashboardv2 ? null : '/multiplayer',
          },
        ]
      : [
          {
            id: 1,
            name: 'Daily Challenge',
            description: 'up to 17 CPs (per day)',
            icon: DailyChallengeIcon,
            translation: 'dailyChallenge',
          },
          {
            id: 2,
            name: 'Peer Challenge',
            description: 'up to 9 CPs (per day)',
            icon: PeerChallengeIcon,
            translation: 'peerChallenge',
            link: isDashboardv2 ? null : '/multiplayer',
          },
          {
            id: 3,
            name: 'Sunday Mini Challenge',
            description: 'up to 20 CPs (per week)',
            icon: SMCIcon,
            translation: 'sundayMC',
            link: isDashboardv2 ? null : '/events',
          },
        ];
  return (
    <Container>
      <h2>{t('dailyChallenge:howToEarnCP.howTo', 'How to earn more CPs?')}</h2>
      <p>
        {t(
          'dailyChallenge:howToEarnCP.youCanEarn',
          'You can earn Challenge Points (CPs) from the following activities'
        )}
      </p>
      <div style={{ textAlign: 'left' }}>
        {challenges.map((challenge) => (
          <div key={challenge.id} style={{ marginBottom: '1rem' }}>
            <StyledChallengeIcon
              src={challenge.icon}
              alt={challenge.name}
              onClick={() => challenge.link && history.push(challenge.link)}
              isLink={challenge.link}
            />
            {t(
              `dailyChallenge:howToEarnCP.${challenge.translation}`,
              `${challenge.name}: ${challenge.description}`
            )}
          </div>
        ))}
      </div>
      <Button onClick={onHide}>
        {t('dailyChallenge:howToEarnCP.gotIt', 'Got it!')}
      </Button>
    </Container>
  );
};

export default EarnMoreCPsModal;
