import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OverlayContainer } from './DashboardCard.styles';
import { CardContainer, Block } from './PracticeCard.styles';
import { Hoverbutton } from 'components/Shared/CardHover.styles';

const Overlay = ({ type, heading, desc }) => {
  const { t } = useTranslation(['dashboard']);
  return (
    <OverlayContainer isPremium isFeatureReady={true}>
      <div className="hover-desc-block">
        <div className="title">{heading}</div>
      </div>
      <Hoverbutton btnColor={type === 'kooQuiz' ? '#068AD3' : '#77B90E'}>
        {t('dashboard:dailyChallenge.button', 'Start')}
      </Hoverbutton>
    </OverlayContainer>
  );
};

const PracticeCard = ({ type, url, heading, desc }) => {
  const history = useHistory();
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <CardContainer
      type={type}
      hover={showOverlay}
      onClick={() => history.push(url)}
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => setShowOverlay(false)}
    >
      <Block type={type} showOverlay={showOverlay} data-cy={type}>
        <div className="desc-block">
          <div className="title">{heading}</div>
          <div className="sub-title">{desc}</div>
        </div>
      </Block>
      {showOverlay && <Overlay type={type} heading={heading} desc={desc} />}
    </CardContainer>
  );
};

export default PracticeCard;
