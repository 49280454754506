const humanize = (str) => {
  if (str === undefined) return;
  // eslint-disable-next-line consistent-return
  return str
    .replace(/_/g, ' ')
    .replace(/-/g, ' ')
    .trim()
    .replace(/\b[A-Z][a-z]+\b/g, (word) => {
      return word.toLowerCase();
    })
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
};

export default humanize;
