import React from 'react';
import { useTranslation } from 'react-i18next';
import QnText from 'components/Assignment/AssignmentView/QnText';
import DifficultyChillis from 'components/Shared/DifficultyChillis';
import { Container } from './MissionNoteBookHeader.styles';

export const DifficultyChillisInfo = ({ t, question, containerClass = '' }) => {
  return (
    <div className={`qn-info ${containerClass}`}>
      <div className="qn-difficulty">
        <p>{t('selfPracticeQnView:content.difficulty', 'Difficulty')}:</p>
        <DifficultyChillis
          value={question && question.Difficulty.ActualValue}
        />
      </div>
      <div className="qn-id">
        {t('selfPracticeQnView:content.qnId', 'Qn ID')}{' '}
        {question && question.Id}
      </div>
    </div>
  );
};

const MissionNoteBookHeader = ({ question, topicSkill, qnNo, topicName }) => {
  const { t } = useTranslation(['selfPracticeQnView']);

  return (
    <Container>
      <div className="topic">
        <p className="topic-title">
          {topicSkill && topicSkill.SIO.Description !== ''
            ? topicSkill.SIO.Description
            : topicName}
        </p>
      </div>
      <div className="qn">
        <div className="qn-number">
          <QnText
            fontFam="Grilled Cheese"
            color="#9A7C00"
            borderColor=" #ffce00"
          />
          <div className="number">{qnNo}</div>
        </div>
        <DifficultyChillisInfo
          t={t}
          question={question}
          containerClass="qn-info-desktop"
        />
      </div>
    </Container>
  );
};

export default MissionNoteBookHeader;
