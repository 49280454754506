import React from 'react';
import LoginForm from '../LoginForm/LoginForm';
import {
  LoginHeader,
  LoginSectionContainer,
  SignupContainer,
} from './LoginSection.styles';
import {
  HomeIcon,
  KoobitsLogo,
  Stars,
  MobileStars,
  Partners,
  SchoolSignup,
  App,
  ShootingStar,
} from './images';

const LoginSection = ({ isMobile }) => {
  return (
    <LoginSectionContainer>
      <div className="gradient-bg" />
      {!isMobile && (
        <div className="login-header">
          <LoginHeader>
            <div className="links">
              <a
                href={`${process.env.REACT_APP_OFFICIAL_WEB_URL}franchise/centre`}
              >
                <span className="link-text">Partners</span>
                <span className="link-icon">
                  <img src={Partners} alt="partners-icon" />
                </span>
              </a>
              <span>|</span>
              <a href="https://products.koobits.com/problemsums/school/">
                <span className="link-text">School Sign-up</span>
                <span className="link-icon">
                  <img src={SchoolSignup} alt="school-signup-icon" />
                </span>
              </a>
              <span>|</span>
              <a href={`${process.env.REACT_APP_OFFICIAL_WEB_URL}parent/app`}>
                <span className="link-text">Download Parent App</span>
                <span className="link-icon">
                  <img src={App} alt="parents-app-icon" />
                </span>
              </a>
            </div>
            <div className="home-page">
              <HomeIcon className="home-icon" />
              <span className="home-text">Homepage</span>
            </div>
          </LoginHeader>
        </div>
      )}
      <div className="brandname-container">
        <KoobitsLogo className="brandname-logo" alt="KooBits" />
        <span className="brandname-divider">|</span>
        <div className="brandname-tagline">
          <span className="brandname-tagline-text">Love Learning</span>
          <img src={ShootingStar} alt="" className="brandname-tagline-star" />
        </div>
      </div>
      <LoginForm isMobile={isMobile} />
      {!isMobile && (
        <SignupContainer>
          If you don't have account, you can{' '}
          <span>
            <a href="https://products.koobits.com/problemsums/">Sign up</a>
          </span>
        </SignupContainer>
      )}
      {isMobile ? (
        <MobileStars className="mobile-stars" />
      ) : (
        <Stars className="stars" />
      )}
      <footer>KooBits Learning Pte Ltd</footer>
    </LoginSectionContainer>
  );
};

export default LoginSection;
