import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CPGreenIcon from 'assets/img/dailychallenge/science/cp-points.svg';
import CPGreyIcon from 'assets/img/dailychallenge/science/cp-grey-icon.svg';
import CheckmarkSVG from 'assets/img/dailychallenge/science/checkmark.svg';
import CPArrowRight from 'assets/img/dailychallenge/science/cp-arrow-right.svg';
import ChallengeOne from 'assets/img/dailychallenge/science/challenge-1-pending.svg';
import ChallengeTwo from 'assets/img/dailychallenge/science/challenge-2-pending.svg';
import ChallengeThree from 'assets/img/dailychallenge/science/challenge-3-pending.svg';
import ChallengeFour from 'assets/img/dailychallenge/science/challenge-4-pending.svg';
import ChallengeFive from 'assets/img/dailychallenge/science/challenge-5-pending.svg';
import ChallengeOneComplete from 'assets/img/dailychallenge/science/challenge-1-done.svg';
import ChallengeTwoComplete from 'assets/img/dailychallenge/science/challenge-2-done.svg';
import ChallengeThreeComplete from 'assets/img/dailychallenge/science/challenge-3-done.svg';
import ChallengeFourComplete from 'assets/img/dailychallenge/science/challenge-4-done.svg';
import ChallengeFiveComplete from 'assets/img/dailychallenge/science/challenge-5-done.svg';
import ChallengeOneSkipped from 'assets/img/dailychallenge/science/challenge-1-skip.svg';
import ChallengeTwoSkipped from 'assets/img/dailychallenge/science/challenge-2-skip.svg';
import ChallengeThreeSkipped from 'assets/img/dailychallenge/science/challenge-3-skip.svg';
import ChallengeFourSkipped from 'assets/img/dailychallenge/science/challenge-4-skip.svg';
import ChallengeFiveSkipped from 'assets/img/dailychallenge/science/challenge-5-skip.svg';
import Button from 'components/Shared/Button';
import { ScienceQnDifficultyLevels } from 'constants/dailyChallenge';
import { setActive } from 'store/dailyChallenge/dailyChallengeSlice';
import useIsDesktop from 'hooks/responsive/useIsDesktop';
import useResponsiveValue from 'hooks/responsive/useResponsiveValue';
import {
  StyledCard,
  StyledArrow,
  StyledCheckmarkContainer,
  StyledCheckmark,
} from './CardItem.styles';

const levels = {
  1: {
    id: 1,
    title: 'Piece of Cake',
    cpPoints: 2,
    pendingImageUrl: ChallengeOne,
    completeImageUrl: ChallengeOneComplete,
    skippedImageUrl: ChallengeOneSkipped,
    colorBg: `rgb(255, 229, 65)`,
    colorRadial: `radial-gradient(
      circle,
      rgba(255, 229, 65, 1) 0%,
      rgba(255, 188, 2, 1) 35%,
      rgba(255, 229, 65, 1) 100%
    )`,
  },
  2: {
    id: 2,
    title: 'Small Fight',
    cpPoints: 1,
    pendingImageUrl: ChallengeTwo,
    completeImageUrl: ChallengeTwoComplete,
    skippedImageUrl: ChallengeTwoSkipped,
    colorBg: `rgb(252,196,66)`,
    colorRadial: `radial-gradient(circle, rgba(252,196,66,1) 0%, rgba(255,137,0,1) 100%)`,
  },
  3: {
    id: 3,
    title: 'Rough Rumble',
    cpPoints: 2,
    pendingImageUrl: ChallengeThree,
    completeImageUrl: ChallengeThreeComplete,
    skippedImageUrl: ChallengeThreeSkipped,
    colorBg: `rgb(255,146,0)`,
    colorRadial: `radial-gradient(circle, rgba(255,146,0,1) 0%, rgba(240,103,13,1) 100%)`,
  },
  4: {
    id: 4,
    title: 'Hard Time',
    cpPoints: 2,
    pendingImageUrl: ChallengeFour,
    completeImageUrl: ChallengeFourComplete,
    skippedImageUrl: ChallengeFourSkipped,
    colorBg: `rgb(228,108,216)`,
    colorRadial: `radial-gradient(circle, rgba(228,108,216,1) 0%, rgba(212,90,173,1) 100%)`,
  },
  5: {
    id: 5,
    title: 'Brain Drain',
    cpPoints: 3,
    pendingImageUrl: ChallengeFive,
    completeImageUrl: ChallengeFiveComplete,
    skippedImageUrl: ChallengeFiveSkipped,
    colorBg: `rgb(185,94,190)`,
    colorRadial: `radial-gradient(circle, rgba(185,94,190,1) 0%, rgba(143,72,173,1) 100%)`,
  },
};

const checkImageStatus = (status, obj) => {
  switch (status) {
    case 'complete':
      return obj.completeImageUrl;
    case 'skipped':
      return obj.skippedImageUrl;
    default:
      return obj.pendingImageUrl;
  }
};

const CardItem = ({ item, number, status, nextArrow }) => {
  const { t } = useTranslation(['dailyChallenge']);
  const history = useHistory();
  const dispatch = useDispatch();
  const obj = ScienceQnDifficultyLevels(number);
  // responsive
  const isDesktop = useIsDesktop();
  const buttonWidth = useResponsiveValue({
    desktop: '150px',
    mobile: 'auto',
    tablet: 'auto',
  });
  const buttonPadding = useResponsiveValue({
    desktop: '10px 20px',
    mobile: '8px 20px',
    tablet: '10px 24px',
  });

  const renderButton = () => {
    const clickHandler = () => {
      dispatch(setActive(number));
      history.push('/challenges/daily-challenge/question-view');
    };
    switch (status) {
      case 'complete':
        return (
          <Button
            className="btn btn-complete"
            onClick={clickHandler}
            width={buttonWidth}
            padding={buttonPadding}
          >
            {t('dailyChallenge:science.view', 'View')}
          </Button>
        );
      case 'skipped':
        return (
          <Button
            className="btn-skipped"
            onClick={clickHandler}
            width={buttonWidth}
            padding={buttonPadding}
          >
            {t('dailyChallenge:science.skipped', 'Skipped')}
          </Button>
        );
      default:
        return (
          <Button
            className="btn btn-pending"
            variant="primary"
            onClick={clickHandler}
            width={buttonWidth}
            padding={buttonPadding}
          >
            {t('dailyChallenge:science.start', 'Start')}
          </Button>
        );
    }
  };

  return (
    <>
      <StyledCard obj={obj} status={status}>
        {status === 'complete' && isDesktop && (
          <StyledCheckmarkContainer>
            <StyledCheckmark obj={levels[number]}>
              <img src={CheckmarkSVG} alt="checkmark" />
            </StyledCheckmark>
          </StyledCheckmarkContainer>
        )}
        <div className="top-section">
          <div className="top-content">
            <img
              className="challenge-img"
              src={checkImageStatus(status, obj)}
              alt="challenge-one"
            />
            <h4>{t(`dailyChallenge:main.${obj.name}`, obj.name)}</h4>
          </div>
        </div>
        <div className="bottom-section">
          <div className="bottom-content">
            <h4 className="challenge-text">
              {t('dailyChallenge:science.challenge', 'Challenge')}
            </h4>
            <h4 className="bottom-number">{item.DisplayOrder}</h4>
            <div className="cp-info">
              <img
                className="cp-img"
                src={status === 'complete' ? CPGreenIcon : CPGreyIcon}
                alt="cp-points"
              />
              <span>{item.ChallengePoints} CP</span>
            </div>
            {renderButton()}
          </div>
        </div>
      </StyledCard>
      {nextArrow && isDesktop && (
        <StyledArrow>
          <img className="cp-img" src={CPArrowRight} alt="cp-points" />
        </StyledArrow>
      )}
    </>
  );
};

export default CardItem;
