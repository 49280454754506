import styled from 'styled-components';

export const Container = styled.div`
  margin-top: ${({ type }) => (type === 'proficiency' ? '3.3rem' : '2.3rem')};
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 15px;
  }
`;

export const SkillContainer = styled.div`
  display: flex;
  padding-left: 3.4rem;
  position: relative;
  .proficiency-icon {
    position: absolute;
    top: 0;
    left: 2rem;
    visibility: ${(props) => (props.hideIcon ? 'hidden' : 'normal')};
    width: 55px;
    height: 55px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-left: 0;

    .proficiency-icon {
      width: 38px;
      height: 38px;
      left: 10px;
      top: 10px;
      z-index: 1;
    }
  }
`;

const ProficiencyColours = {
  mastery: {
    bgColour: '#fffbc6',
    borderColour: '#f5d193',
    scoreColour: '#f8a300',
  },
  competent: {
    bgColour: '#e7fae6',
    borderColour: '#a4d0a2',
    scoreColour: '#078001',
  },
  beginning: {
    bgColour: '#ffe6e6',
    borderColour: '#f1c1c1',
    scoreColour: '#bf0d0d',
  },
  incomplete: {
    bgColour: 'white',
    borderColour: '#d5e6f4',
    scoreColour: '#d3d3d3',
  },
};

export const SkillCard = styled.div`
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 50px;
  background: ${(props) => ProficiencyColours[props.proficiencyLevel].bgColour};
  border: ${(props) =>
    `solid 1px ${ProficiencyColours[props.proficiencyLevel].borderColour}`};
  margin-bottom: 1rem;
  padding: ${(props) =>
    props.type === 'proficiency'
      ? '0.25rem 1rem 0.25rem 3rem'
      : '0.25rem 1rem 0.25rem 3rem'};
  width: 100%;
  .hide-desktop {
    display: none;
  }
  .skill-number {
    font-family: 'VAGRounded BT';
    color: ${(props) =>
      props.type === 'highscore'
        ? '#b4dcff'
        : ProficiencyColours[props.proficiencyLevel].borderColour};
    font-size: 1.625rem;
    width: 2rem;
  }

  .skill-name {
    font-family: 'VAGRounded Lt';
    font-size: 0.875rem;
    text-align: left;
    width: 50%;
  }
  .national-avg {
    font-family: 'Arial';
    font-size: 0.875rem;
    color: #979797;
    margin: 0 1rem;
    width: 2rem;
  }
  .proficiency {
    font-family: 'Linotte';
    font-weight: 700;
    font-size: 1.125rem;
    color: ${(props) => ProficiencyColours[props.proficiencyLevel].scoreColour};
    margin-right: 1rem;
    width: 1rem;
  }
  .skills-mastered {
    font-family: 'Arial';
    font-size: 0.875rem;
    width: 6rem;
  }
  .skill-container {
    display: flex;
    width: 100%;
    align-items: center;
    padding: ${(props) => (props.type === 'proficiency' ? 0 : '0px 15px')};
    justify-content: space-evenly;
  }

  ${({ index, type, highScoreTitle, proficiencyTitle, language }) => {
    if (index === 0) {
      if (type === 'highscore') {
        return `
        span{
          position: relative;
          &::before {
            font-family: 'Arial';
            font-size: 0.75rem;
            font-weight: 700;
            color: #9faac2;
            display: block;
            position: absolute;
            left: 0;
            top: -33px;
          }
          &:nth-child(2)::before{
            content: '${highScoreTitle[0]}';
          }
          &:nth-child(4)::before{
            content: '${highScoreTitle[2]}';
            top: ${language === 'ba' ? '-32px' : '-33px'};
          }
        }
        .skill-name{
          position: relative;
          &::before {
            font-family: 'Arial';
            font-size: 0.75rem;
            font-weight: 700;
            color: #9faac2;
            content: '${highScoreTitle[1]}';
            display: block;
            position: absolute;
            left: 0;
            top: -34px;
          }
        }
        .actions{
          position: relative;
          &::before {
            font-family: 'Arial';
            font-size: 0.75rem;
            font-weight: 700;
            color: #9faac2;
            content: '${highScoreTitle[3]}';
            display: block;
            position: absolute;
            left: 0.5rem;
            top: -23px;
          }
        }
      `;
      }
      if (type === 'proficiency') {
        return `
        span{
          position: relative;
          &::before {
            font-family: 'Arial';
            font-size: 0.75rem;
            font-weight: 700;
            color: #9faac2;
            display: block;
            position: absolute;
            left: 0;
            top: -33px;
          }
          &:nth-child(3)::before{
            content: '${proficiencyTitle[0]}';
          }
          &:nth-child(4)::before{
            content: '${proficiencyTitle[1]}';
            left: -1px;
            top: ${['en', 'ba'].includes(language) ? '-52px' : '-35px'};
            width:${language === 'en' ? 'auto' : '53px'};
          }
          &:nth-child(5)::before{
            content: '${proficiencyTitle[2]}';
            width: 100px;
            top: -31px
          }
        }
      `;
      }
    }
  }}

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0px 10px;
    flex-direction: column;
    .hide-mobile {
      display: none;
    }
    .hide-desktop {
      display: block;
    }
    .skill-container {
      padding: 10px 15px;
      align-items: center;
      .skill-number {
        font-size: 18px;
        margin-right: 0;
      }
      .skill-name {
        width: 100%;
        margin-left: 0;
        ::before {
          display: none;
        }
      }
    }
    .actions {
      ::before {
        display: none;
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  height: 100%;
  border-left: solid 1px #d5e6f4;
  width: 150px;
  .play-video {
    cursor: pointer;
    :hover {
      filter: brightness(85%);
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    border-left: 0;
    width: 100%;
    padding: 0px 10px 10px 40px;
    justify-content: space-between;

    .action-container {
      display: flex;
      gap: 10px;
      width: fit-content;
    }

    .chillis {
      ::before {
        display: none;
      }
    }
  }
`;

export const StartBtn = styled.button`
  border: ${(props) => (props.isActive ? 'none' : 'solid 1px #ffca84')};
  font-family: 'VAGRounded BT';
  font-size: ${(props) => (props.textLength > 8 ? '0.9rem' : '1rem')};
  color: ${(props) => (props.isActive ? 'white' : '#fb940e')};
  background: ${(props) => (props.isActive ? '#fb940e' : 'white')};
  border-radius: 2rem;
  padding: 0.25rem 1rem;
  margin-left: 0.5rem;
  :hover {
    background: ${(props) => (props.isActive ? '#c9760b' : '#fb940e')};
    border: ${(props) => (props.isActive ? 'none' : 'solid 1px #fb940e')};
    color: white;
  }
  .progress {
    height: 10px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: 14px;
  }
`;
