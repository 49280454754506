import styled from 'styled-components';
import KoKoBg from 'assets/img/dailychallenge/bg-koko-claim.svg';

export const Container = styled.div`
  background: #03c3d3;
  border: solid 4px #f6c12d;
  border-radius: 18px;
  width: 270px;
  min-height: 280px;
  position: relative;
  bottom: 2.2rem;
  text-align: center;
  padding: 2.5rem 1rem 1rem 1rem;
  font-weight: 600;
  height: 180px;
  background-image: url(${KoKoBg});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 20px -20px;
  .koko-container {
    margin-bottom: 30px;
    .koko-num {
      color: #ffffff;
      font-size: 19px;
    }
    .koko-desc {
      font-size: 16px;
    }
  }
`;

export const Button = styled.button`
  border: none;
  background: #ff6701;
  color: white;
  font-size: 1.25rem;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  width: 80%;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
