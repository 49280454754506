export const LABELS = [
  {
    name: 'Name',
    translateKey: 'name',
    flex: 2,
  },
  {
    name: 'City / State',
    translateKey: 'cityState',
    flex: 2,
  },
  {
    name: 'CPs of week',
    translateKey: 'cpWeek',
    flex: 1,
  },
  {
    name: 'Total Time',
    translateKey: 'achivementTime',
    flex: 1,
  },
];
