import React from 'react';

import { Container, StyledBar } from './DifficultyBar.styles';

const DifficultyBar = ({ value }) => {
  const fil = value <= 0 ? 2 : value;
  const Bar = [];

  for (let index = 1; index <= 9; index += 2) {
    Bar.push(<StyledBar key={index} isActive={index <= fil} index={index} />);
  }
  return <Container className="bar-container">{Bar}</Container>;
};

export default DifficultyBar;
