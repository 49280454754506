import styled from 'styled-components';

export const Container = styled.div`
  width: 230px;
  max-height: 370px;
  background: #ffffff;
  color: #000000;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 10px 6px 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .topic-list {
    max-height: 100%;
    overflow-y: scroll;
    padding-right: 6px;
    /* Foreground, Background */
    scrollbar-color: #999 #f0f0f0;
    ::-webkit-scrollbar {
      width: 6px; /* Mostly for vertical scrollbars */
    }
    ::-webkit-scrollbar-thumb {
      /* Foreground */
      background: #999;
      border-radius: 6px;
    }
    ::-webkit-scrollbar-track {
      /* Background */
      background: #f0f0f0;
    }
  }
  @media (max-width: 1024px) {
    width: 180px;
  }
  @media (max-width: 768px) {
    max-height: 240px;
  }
  .title {
    margin-bottom: 0.3rem;
    text-align: center;
    color: #89a5c6;
    font-weight: 600;
  }
`;

export default { Container };
