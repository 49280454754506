// 0 is not mission plus
// 1 is mission plus
const COLORS = {
  0: {
    bgColor: 'linear-gradient(#4860e3, #4a73f9)',
    logoBgColor: '#3858d2',
    btnBgColor: 'rgb(62, 74, 129, 0.42)',
    helpBgColor: 'rgb(36, 46, 91, 0.76)',
  },
  1: {
    bgColor: 'linear-gradient(90deg,#28B19C 0%, #23CD87 100%)',
    logoBgColor: 'rgb(0, 147, 113, 0.7)',
    btnBgColor: 'rgb(0, 123, 108, 0.42)',
    helpBgColor: 'rgb(12, 85, 74, 0.76)',
  },
};

export { COLORS };
