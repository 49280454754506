import styled from 'styled-components';

export const Wrapper = styled.div`
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    /* scale down on tablet so image will not overflow */
    transform: scale(0.8);
  }
`;

export const MascotContainer = styled.div`
  position: absolute;
  margin-left: -270px;
  margin-top: -110px;
`;

export const CloseBtn = styled.button`
  background: #ffaf72;
  color: #b86800;
  width: 34px;
  height: 34px;
  border: none;
  border-radius: 50%;
  position: absolute;
  margin-left: 44%;
  margin-top: -9%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #fdad0c;
  border-radius: 8px;
  box-shadow: 5px 5px 0px 0px #fdad0c;
  width: 555px;
  min-height: 15rem;
  padding: 0.5rem 2rem 2rem;
  border-radius: 0.5rem;
  text-align: center;
  .congrats-text {
    margin-top: -3rem;
  }
  .success-text {
    color: #607f94;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0rem;
  }
`;

export const ErrorText = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

export const ClaimCPContainer = styled.div`
  margin-bottom: 2rem;
  .claim {
    display: flex;
    align-items: center;
    p {
      font-size: 18px;
      margin-bottom: 0rem;
      color: #141414;
    }
    .amount {
      font-size: 20px;
      margin: 0rem 0.3rem;
      margin-left: 0rem;
      color: #141414;
      span {
        font-weight: 600;
      }
    }
    img {
      width: 25px;
      margin: 0rem 0.2rem;
    }
  }
`;

export const PlusCPContainer = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: #2badf9;
  p {
    margin-bottom: -1rem;
    margin-left: 4.5rem;
  }
  .fade-in {
    -webkit-animation: fadeinout 5s linear forwards;
    animation: fadeinout 5s linear forwards;
  }
  @-webkit-keyframes fadeinout {
    0% {
      opacity: 0;
      -webkit-transform: translateY(0);
    }
    50% {
      opacity: 1;
      -webkit-transform: translateY(-10px);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-10px);
    }
  }

  @keyframes fadeinout {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-10px);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
`;
