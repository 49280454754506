import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import formatI18nDateTime from 'helpers/formatI18nDateTime';
import {
  CloseTime,
  InsideWrap,
  OpenTime,
  Wrapper,
  TextInfo,
} from './CloseArenaModal.style';

const CloseArenaModal = ({ openTime, closeTime }) => {
  const { t, i18n } = useTranslation(['brainGames']);

  return (
    <Wrapper>
      <InsideWrap>
        <TextInfo>
          {t('welcome.open', 'KooBits island opening hours')}:
        </TextInfo>
        <TextInfo>
          <OpenTime>
            {moment(openTime).format(
              formatI18nDateTime({
                language: i18n.language,
                format: 'hh:mm a',
                value: openTime,
                time: true,
                timeOnly: true,
              })
            )}
          </OpenTime>
          {t('welcome.to', ' To ')}
          <CloseTime>
            {moment(closeTime).format(
              formatI18nDateTime({
                language: i18n.language,
                format: 'hh:mm a',
                value: closeTime,
                time: true,
                timeOnly: true,
              })
            )}
          </CloseTime>
        </TextInfo>
      </InsideWrap>
    </Wrapper>
  );
};

export default React.memo(CloseArenaModal);
