import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './Countdown.style';

function Countdown({ onAfterCountdown }) {
  const { t } = useTranslation(['superSpeed']);
  const [countdown, setCountdown] = useState(3);
  const onTick = useCallback(() => {
    if (countdown > 1) {
      setCountdown(countdown - 1);
    } else {
      onAfterCountdown();
    }
  }, [countdown, onAfterCountdown]);
  useEffect(() => {
    const i = setInterval(onTick, 1000);
    return () => {
      clearInterval(i);
    };
  }, [onTick]);
  return (
    <Container>
      {t('superSpeed:game.countdownText', 'Game starting in... ')}
      {countdown}
    </Container>
  );
}
export default React.memo(Countdown);
