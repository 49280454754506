import React from 'react';
import NoSubmit from './NoSubmit';
import ResultDetail from './ResultDetail';
import { Container } from './SMCDetails.styles';

const SMCDetails = ({ status }) => {
  const content = () => {
    if (status === 'expired') {
      return <NoSubmit />;
    } else {
      return <ResultDetail />;
    }
  };
  return <Container>{content()}</Container>;
};

export default SMCDetails;
