import styled from 'styled-components';

export const Container = styled.div`
  min-width: 300px;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  border-radius: 13px;
  padding: 1rem;
  display: flex;
  p {
    margin: 0;
  }
  img {
    border-radius: 50%;
    margin-right: 1rem;
  }
  .opponent-name {
    font-size: 1.125rem;
    font-weight: 600;
  }
  .date {
    font-weight: normal;
    font-size: 0.75rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .opponent-name {
      font-size: 16px;
    }
  }
`;

export const ActionButton = styled.button`
  border: ${(props) => (props.isActive ? 'none' : 'solid 1px #FF7121')};
  font-size: 1rem;
  border-radius: 30px;
  padding: 0.25rem 0.5rem;
  min-width: 77px;
  color: ${(props) => (props.isActive ? 'white' : '#FF7121')};
  background: ${(props) => (props.isActive ? '#FF7121' : 'white')};
  :first-child {
    margin-right: 1rem;
  }
  :hover {
    background: ${(props) => (props.isActive ? '#FF5801' : '#ffaa7a')};
    color: ${(props) => (props.isActive ? 'white' : 'white')};
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: 14px;
  }
`;

export const ExpireText = styled.p`
  font-size: 0.75rem;
  font-weight: normal;
  color: rgba(20, 19, 19, 0.32);
  margin: 0.25rem 0 0 0.5rem;
  text-align: left;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    text-align: left;
    margin: 0.25rem 0 0 0 !important;
  }
`;
