import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  openAssignment,
  getAssignmentDetails,
} from 'store/assignment/assignmentSlice';
import { openKooQuiz } from 'store/kooQuiz/kooQuizSlice';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import getSchoolName from 'helpers/getSchoolName';
import Spinner from 'components/Shared/Spinner';
import Button from 'components/Shared/Button';
import ErrorModal from 'components/Shared/ErrorModal';
import AssignmentContent from 'components/Assignment/AssignmentPrint/AssignmentContent';
import useQuery from 'helpers/useQuery';
import useKooQuizRevision from 'hooks/useKooQuizRevision';
import { PrintAssignmentPage } from './PrintAssignment.styles';

const PrintAssignment = () => {
  const { id, type } = useParams();
  const query = useQuery();
  const subjectID = query.get('subjectid');
  const intSubjectID = subjectID ? parseInt(subjectID, 10) : null;
  const kooQuizPaperTypeID = query.get('papertypeid') ?? '';
  const intKooQuizPaperTypeID = kooQuizPaperTypeID
    ? parseInt(kooQuizPaperTypeID, 10)
    : null;
  const { t } = useTranslation(['assignmentQnView']);
  const { plan } = useSelector((state) => state.plan);
  const {
    questions,
    isOpening,
    startAssignmentInfo,
    openAssignmentError,
  } = useSelector((state) => state.assignment);
  const { kooQuizQuestions, paperById, openKooQuizError } = useSelector(
    (state) => state.kooQuiz
  );

  const {
    subscriptionData,
    nonExpiredSubscriptionPlans,
    hasPremiumSchoolPlan,
    defaultLevel,
    isLoading,
  } = useSelector((state) => state.studentDetails);

  const schoolName =
    subscriptionData !== null && nonExpiredSubscriptionPlans !== null
      ? getSchoolName({
          subscriptionData,
          hasPremiumSchoolPlan,
          nonExpiredSubscriptionPlans,
          planToShow: plan,
        })
      : '';

  const dispatch = useDispatch();
  const loading = isOpening || isLoading;
  const error = openKooQuizError || openAssignmentError;
  const { refreshRevisionData } = useKooQuizRevision(
    intSubjectID,
    id,
    intKooQuizPaperTypeID,
    true
  );

  const searchAction = useCallback(() => {
    if (type === 'kooQuiz') {
      dispatch(openKooQuiz(id));
      refreshRevisionData();
    } else {
      dispatch(openAssignment(id));
      dispatch(getAssignmentDetails(id));
    }
    dispatch(getStudentDetails());
  }, [dispatch, id, type, refreshRevisionData]);

  useEffect(() => {
    if (type === 'kooQuiz') {
      dispatch(openKooQuiz(id));
    } else {
      dispatch(openAssignment(id));
      dispatch(getAssignmentDetails(id));
    }
  }, [dispatch, id, type]);

  const printFunction = () => window.print();

  return (
    <PrintAssignmentPage>
      {loading && <Spinner />}
      {!loading && error !== null && (
        <ErrorModal
          reloadAction={searchAction}
          errorMessage={openKooQuizError || openAssignmentError}
        />
      )}
      {!loading && (
        <>
          <div className="top">
            <div className="print-btn">
              <Button
                variant="primary"
                borderRadius="0px"
                onClick={printFunction}
                dataCy="print-btn"
              >
                {t('assignmentQnView:print.print', 'Print')}
              </Button>
            </div>
          </div>
          <AssignmentContent
            title={
              type === 'kooQuiz' ? paperById?.Name : startAssignmentInfo?.Title
            }
            questions={type === 'kooQuiz' ? kooQuizQuestions : questions}
            level={defaultLevel}
            schoolName={schoolName}
            type={type}
          />
        </>
      )}
    </PrintAssignmentPage>
  );
};

export default PrintAssignment;
