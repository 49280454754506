import styled from 'styled-components';
import MathBg from 'assets/img/kooquiz/kooQuizQn/math-qn-bg.svg';
import SciBg from 'assets/img/kooquiz/kooQuizQn/sci-qn-bg.svg';

const assignmentTypeStyles = (assignmentType) => {
  switch (assignmentType) {
    case 'KooQuizSci':
      return {
        bgImage: SciBg,
        bgColor: '#176B7D',
      };
    default:
      return {
        bgImage: MathBg,
        bgColor: '#38437b',
      };
  }
};

export const Container = styled.div`
  display: flex;
`;

export const Main = styled.div`
  background: ${(props) => `url(${assignmentTypeStyles(props.type).bgImage})`};
  background-color: ${(props) => assignmentTypeStyles(props.type).bgColor};
  background-repeat: repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  .wrapper {
    display: flex;
    justify-content: center;
  }
  .summary-box {
    height: fit-content;
    background: #ffffff;
    border: 1px solid #fdad0c;
    border-radius: 8px;
    box-shadow: 10px 10px 0px 0px #fdad0c;
    /* min-width: ${(props) => (props.type !== 'homework' ? '70%' : 'none')}; */
    width: 70%;
    margin-top: 5rem;
    padding: 0% 6% 0% 10%;
    @media (max-width: 1367px) {
      width: 75%;
    }
    @media (max-width: 1140px) {
      width: 92%;
      padding-left: 18%;
    }
    @media (max-width: 951px) {
      padding-left: 10%;
    }
    @media (max-width: 835px) {
    }
  }
`;

export const AssignerSection = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-left: 14%;
  @media (max-width: 1141px) {
    margin-left: 4%;
  }
`;

export const Page = styled.div`
  overflow: hidden;
  visibility: visible;
`;

export const OnlineOfflineContainer = styled.div`
  padding-top: 59px;
  background: #f5f5f5;
  min-height: 100vh;
`;

export const OnlineOfflineContentContainer = styled.div`
  padding-bottom: 74px;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  position: relative;

  .gift-box {
    position: absolute;
    width: 140px;
    left: 0;
    bottom: 15px;
    z-index: 3;
  }
`;
