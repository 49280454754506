import styled from 'styled-components';
/* eslint-disable */
export const Container = styled.div`
  position: absolute;
  right: 3%;
  background-color: rgb(255, 255, 255, 0.21);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 0.3rem 0.7rem;
  .difficulty-name {
    color: #00686f;
    font-size: 15px;
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    right: 15%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    right: 20%;
  }
`;
