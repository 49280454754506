import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/champion-class/champion-header-bg.svg';

export const Container = styled.div`
  height: 350px;
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  display: flex;
  padding: 0 20px;
  .left-kid {
    margin-left: 5%;
  }
  .right-kid {
    position: absolute;
    right: -3%;
    bottom: 40px;
    pointer-events: none;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 2%;
`;

export const CenterBar = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.activeTerm === 'term' ? 'flex-end' : 'space-between'};
  align-items: center;
  position: relative;
  padding-top: 70px;
  padding-bottom: 20px;

  .middle-content {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .curriculum-date {
    align-self: flex-end;
    z-index: 1;
    margin-right: 10%;
  }
`;

export const BrainHeading = styled.div`
  color: #ff6c1c;
  font-size: 3.9rem;
  font-family: 'Grilled Cheese';
  width: 70%;
  position: relative;
  z-index: 1;
  &::before {
    content: ${({ content }) => content && `"${content}"`};
    position: absolute;
    -webkit-text-stroke: 8px #ffffff;
    left: 0;
    top: 0;
    z-index: -1;
  }
`;
