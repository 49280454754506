import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MarkItRightIcon from 'assets/img/question/mark-it-right-icon.svg';
import { useSelector } from 'react-redux';
import {
  Container,
  Label,
  Textarea,
  ButtonGroup,
  Button,
} from './LongAnsTextarea.styles';

// Mark Answer Right action always calls Save answer API since Check Answer is only called
// after all long answers have been marked as right
const LongAnsTextarea = ({
  studentAnswer,
  refAnswer,
  showRefAnswer,
  isMarkedRight,
  onChangeHandler,
  key,
  name,
  register,
  setSelectedTextareaKey,
}) => {
  const { t } = useTranslation(['assignmentQnView']);
  // Redux States
  const { isSaving } = useSelector((state) => state.assignment);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (showRefAnswer) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [showRefAnswer]);
  return (
    <>
      <Container>
        {(isMarkedRight || showRefAnswer) && (
          <Label
            bgColor={isMarkedRight ? '#CCF6CA' : '#b1dbff'}
            width="100px"
            fontColor={isMarkedRight ? '#4BA265' : '#2b6ca2'}
          >
            {t('assignmentQnView:main.myAnswer', 'My Answer')}
          </Label>
        )}
        <Textarea
          data-cy={`textarea-${name}`}
          key={key}
          showAnswer={showRefAnswer}
          isMarkedRight={isMarkedRight}
          bgColor={isMarkedRight ? '#E9FFE8' : '#e9f5ff'}
          borderColor={isMarkedRight ? '#8FC78B' : '#80b5e2'}
          placeholder={t(
            'assignmentQnView:main.typeAnswer',
            'Type in answer here.'
          )}
          defaultValue={studentAnswer}
          // Disable textarea if answer has been marked right
          disabled={isDisabled || isMarkedRight || isSaving}
          name={name}
          ref={register}
          onChange={onChangeHandler}
          autocomplete="off"
        />
        {!isMarkedRight && showRefAnswer && (
          <ButtonGroup>
            <Button
              data-cy={`improve-btn-${name}`}
              bgColor="#FF814A"
              hoverColor="#cc673b"
              onClick={() => {
                setIsDisabled(false);
              }}
              type="button"
            >
              {t('assignmentQnView:main.improve', 'Improve Answer')}
            </Button>
            <Button
              data-cy={`mark-btn-${name}`}
              bgColor="#64CF61"
              hoverColor="#50a64e"
              type="submit"
              onClick={() => {
                setSelectedTextareaKey(name);
              }}
              disabled={isSaving}
            >
              <img src={MarkItRightIcon} alt="Mark it right" />
              {t('assignmentQnView:main.markRight', 'Mark It Right')}
            </Button>
          </ButtonGroup>
        )}
      </Container>
      {!isMarkedRight && showRefAnswer && (
        <Container>
          <Label bgColor="#CCF6CA" width="135px" fontColor="#4BA265">
            {t('assignmentQnView:main.reference', 'Reference Answer')}
          </Label>
          <Textarea
            data-cy={`ref-${name}`}
            showAnswer={showRefAnswer}
            bgColor="#E9FFE8"
            borderColor="#8FC78B"
            defaultValue={refAnswer}
            disabled
          />
        </Container>
      )}
    </>
  );
};

export default LongAnsTextarea;
