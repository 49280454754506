import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_LEADERBOARD_API_DOMAIN;

// get all schools
export const fetchGroups = (params) => {
  const { enumFilter } = params;
  return fetch(
    `${baseUrl}Leaderboard/WizardSchool/GetLeaderboardData/${enumFilter}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get all groups failed'); // eslint-disable-line
    });
};

// get students
export const fetchStudents = ({ schoolId, seasonId }) => {
  return fetch(
    `${baseUrl}Leaderboard/WizardSchool/GetTopFiftyStudents/${seasonId}/${schoolId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get students by season and school failed'
      );
    });
};
