import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2.5rem 20% 0;
  margin-top: 120px;
  .btn {
    display: flex;
    justify-content: flex-end;
    width: 79%;
  }
  .check-box-container {
    position: absolute;
    margin-right: -38.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    p {
      margin-bottom: 0px;
      margin-left: -10px;
      color: #0d0d0d;
      font-weight: 600;
    }
  }
`;
