import styled from 'styled-components';
import { proficiencyColors } from 'constants/report';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

export const ProficiencyItem = styled.span`
  font-family: Nunito;
  font-size: 0.75rem;
  margin-right: 1rem;
  img {
    margin-right: 0.5rem;
  }
  .row-list {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    margin-right: 1rem;
    @media (max-width: 880px) {
      margin: 0.2rem;
    }
  }
  .icon {
    width: 7px;
    height: 7px;
    margin-top: 6px;
    background: ${(props) => proficiencyColors[props.proficiency].color};
    border-radius: 50%;
    border: solid 1px
      ${(props) => proficiencyColors[props.proficiency].borderColor};
    margin-right: 0.3rem;
  }
  .title {
    font-size: 13px;
    color: #363636;
    font-weight: 600;
    margin-right: 0.3rem;
  }
  .detail,
  .description {
    margin-top: 3px;
    font-size: 10px;
    font-weight: 600;
    color: #94999c;
  }
  .description {
    font-weight: 700;
  }
`;
