import React from 'react';
import {
  ModalContainer,
  Container,
  TopBg,
  ModalContent,
} from 'components/Dashboard/ClaimDailyBonus.styles';
import { useSelector } from 'react-redux';
import { zodiacs } from 'constants/leaderboard';
import { ZodiacIconContainer } from './JoinedZodiacModal.styles';

const DoBModal = ({ onHide }) => {
  const { studentDetails, isLoading } = useSelector(
    (state) => state.studentDetails
  );

  const closeModal = () => {
    onHide();
  };
  return (
    <ModalContainer data-cy="daily-bonus-modal">
      <ZodiacIconContainer>
        <img
          src={zodiacs[studentDetails.ZodiacId]?.image || ''}
          alt={studentDetails?.ZodiacName || ''}
          width="111px"
          height="111px"
        />
        <p>{studentDetails?.ZodiacName || ''}</p>
      </ZodiacIconContainer>
      <Container>
        <TopBg customHeight="94.5px" />
        <ModalContent>
          {!isLoading && (
            <>
              <p className="top-text">
                <span className="orange">Yeah!</span>
                {` You have joined ${studentDetails?.ZodiacName || ''} team.`}
              </p>
              <button
                data-cy="confirm-button"
                type="button"
                onClick={closeModal}
              >
                Great!
              </button>
            </>
          )}
        </ModalContent>
      </Container>
    </ModalContainer>
  );
};

export default DoBModal;
