/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import useClickOutside from 'helpers/useClickOutside';
import AccessMenu from 'components/Dashboard/AccessMenu';
import PopupModal from 'components/Shared/PopupModal';
import { useTranslation } from 'react-i18next';
import Container from './QuickAccess.styles';
import RestrictedFeatureModal from './RestrictedFeatureModal';

const QuickAccess = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupErrCode, setPopupErrCode] = useState(null);
  const [featureName, setFeatureName] = useState('');
  const outsideClick = useClickOutside(() => setOpenMenu(false));
  // Preload i18n translation before menu is opened to prevent ui flashing
  useTranslation(['shortcutMenu', 'common']);
  return (
    <>
      <PopupModal show={showPopup} backdrop="static">
        <RestrictedFeatureModal
          setShowPopup={setShowPopup}
          setPopupErrCode={setPopupErrCode}
          errCode={popupErrCode}
          featureName={featureName}
        />
      </PopupModal>
      <Container
        ref={outsideClick}
        hover={isHover}
        open={openMenu}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        data-cy="quick-menu"
      >
        <div
          className="bg"
          data-cy="quick-menu-icon"
          onClick={() => setOpenMenu(!openMenu)}
        />
        {openMenu && (
          <AccessMenu
            setShowPopup={setShowPopup}
            setPopupErrCode={setPopupErrCode}
            setFeatureName={setFeatureName}
            setOpenMenu={setOpenMenu}
          />
        )}
      </Container>
    </>
  );
};

export default QuickAccess;
