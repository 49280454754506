import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

const useGlobalFeature = (featureName, featureNameList) => {
  const { globalFeatureConfig } = useSelector((state) => state.plan);
  if (!isNil(featureNameList)) {
    const featuresHashmap = {};
    featureNameList.forEach((name) => {
      const feature = globalFeatureConfig[name];
      featuresHashmap[name] = {
        name: featureName,
        feature,
        isEnabled: isNil(feature)
          ? true
          : feature.parameters?.IsEnabled === true,
      };
    });
    return { featuresHashmap };
  }
  const feature = globalFeatureConfig[featureName];
  const isEnabled = isNil(feature)
    ? true
    : feature.parameters?.IsEnabled === true;
  return { feature, isEnabled };
};

export default useGlobalFeature;
