import moment from 'moment-timezone';
import { getCurrentDateTimeInTimezone } from 'pages/Events/EventList';

const defaultFallbackPlan = 'plus';

export const getSubscriptionPlanName = (subscription) => {
  const planID = subscription.PlanId;
  if (planID === 8 || planID === 9 || planID === 10) {
    return 'school';
  }
  if (planID === 13) {
    return 'standard';
  }
  if (planID === 3 || planID === 4) {
    return 'premium';
  }
  if (planID === 2) {
    return 'plus';
  }
  return defaultFallbackPlan;
};

export const filterNonExpiredPlans = (subscription, timezone) => {
  const currentDateTimeMoment = getCurrentDateTimeInTimezone(timezone);

  return subscription.filter((plan) =>
    currentDateTimeMoment.isBefore(moment.tz(plan.EndDate, timezone))
  );
};
