import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ProficiencyItem } from './ProficiencyLegend.styles';

const ProficiencyLegend = () => {
  const { t } = useTranslation(['selfPractice', 'proficiencyReport']);

  const proficiencyLevels = [
    {
      id: 1,
      type: 'mastery',
      name: t('selfPractice:header.mastery', 'Mastery'),
      detail: t('proficiencyReport:legend.proficiency', 'Proficiency'),
      description: '80% - 100%',
    },
    {
      id: 2,
      type: 'competent',
      name: t('selfPractice:header.competent', 'Competent'),
      detail: t('proficiencyReport:legend.proficiency', 'Proficiency'),
      description: '60% -  79%',
    },
    {
      id: 3,
      type: 'beginning',
      name: t('selfPractice:header.beginning', 'Beginning'),
      detail: t('proficiencyReport:legend.proficiency', 'Proficiency'),
      description: '0-59%',
    },
    {
      id: 4,
      type: 'incomplete',
      name: t('selfPractice:header.incomplete', 'Incomplete'),
      detail: t('proficiencyReport:legend.question', 'Question'),
      description: '< 10',
    },
  ];
  return (
    <Container>
      {proficiencyLevels.map((level) => (
        <ProficiencyItem key={level.id} proficiency={level.type}>
          <div className="row-list">
            <div className="icon" />
            <span className="title">{level.name}</span>
            <span className="detail">{level.detail}</span>
            <b className="description">&nbsp;{level.description}</b>
          </div>
        </ProficiencyItem>
      ))}
    </Container>
  );
};

export default ProficiencyLegend;
