import styled from 'styled-components';
import Box from 'assets/img/box-trial-banner.svg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-image: linear-gradient(to right, #ffd903, #ffeb00, #ffc541);
  width: 100%;
  z-index: 20;
  padding: 0.5rem 0.8rem;
  p {
    margin: 0;
  }
  .mascot {
    position: absolute;
    bottom: -55%;
    right: 10%;
    @media (max-width: 1400px) {
      right: 5%;
    }
    @media (max-width: 1240px) {
      display: none;
    }
  }
  .mascot-science {
    bottom: 0% !important;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: none;
  }
`;

export const CountDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: ${({ isOtp }) => isOtp && '1px solid #db7c1c'};
  padding-right: ${({ isOtp }) => (!isOtp ? '2.5rem' : '0.5rem')};
  .red {
    color: #ff0000;
  }
  .count-down {
    font-size: 33px;
    font-weight: bold;
    background: url(${Box});
    background-repeat: no-repeat;
    background-size: contain;
    width: 8.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    @media (max-width: 911px) {
      font-size: 28px;
      width: 7rem;
    }
    @media (max-width: 752px) {
      width: 5rem;
    }
    @media (max-width: 750px) {
      font-size: 20px;
    }
  }
  span {
    font-weight: 600;
  }
  @media (max-width: 911px) {
    font-size: 12px;
  }
`;

export const VideoBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .video-btn-secondary {
    display: flex;
    background-color: #ffcf03;
    border: 1px solid #f7a451;
    padding: 5px 12.5px 5px 5px;
    align-items: center;
    border-radius: 50px;
    margin: 0 0.5rem;
  }
  .video-btn-primary {
    display: flex;
    background-color: #ff4b00;
    border: 1px solid #ff4b00;
    padding: 5px 12.5px 5px 5px;
    align-items: center;
    border-radius: 50px;
    margin: 0 0.5rem;
    color: #ffffff;
    font-weight: bold;
  }
  @media (max-width: 911px) {
    font-size: 12px;
  }
  @media (max-width: 750px) {
    width: 12rem;
    align-items: flex-start;
  }
`;
