import styled from 'styled-components';

export const Container = styled.div`
  div {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: ${(props) => props.tablet};
      height: ${(props) => props.tablet};
    }
  }
  img {
    width: calc(${(props) => props.size} - 60%);
    height: calc(${(props) => props.size} - 60%);
    @media (max-width: 768px) {
      width: calc(${(props) => props.tablet} - 60%);
      height: calc(${(props) => props.tablet} - 60%);
    }
  }
  .primary {
    background: #ff6302;
  }
  .secondary {
    background: #ffba6a;
  }
  .light {
    background: #ffffff;
  }
  cursor: pointer;
`;
