import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .books {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-left: 12%;
    margin-right: 5%;
  }
`;
