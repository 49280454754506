import styled from 'styled-components';

export const Container = styled.p`
  color: ${(props) => (props.type === 'all-correct' ? '#6AB517' : '#ff1313')};
  background: ${(props) =>
    props.type === 'all-correct'
      ? 'rgb(164, 251, 67, 0.19)'
      : 'rgb(254, 76, 76, 0.09)'};
  padding: 0.2rem 2rem;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 0rem;
  @media (max-width: 980px) {
    font-size: 12px;
  }
`;
