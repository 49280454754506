import styled from 'styled-components';

export const PaymentContainer = styled.div`
  .success-mascot {
    position: absolute;
    bottom: 0%;
    left: 7%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  .note {
    font-size: 14px;
    width: 80%;
    margin: 20px;
  }
  .btnContainer {
    margin-bottom: 20px;
  }
`;

export const PaymentHeader = styled.div`
  display: flex;
  border-bottom: 1px #b7b7b7 dashed;
  padding-bottom: 20px;
  justify-content: center;
  width: 85%;
  .title-container {
    margin-left: 1rem;
    h5 {
      margin-bottom: 0.1rem;
    }
    .confirmation {
      color: #a3a3a3;
    }
  }
`;
