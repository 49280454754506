/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import Spinner from 'components/Shared/Spinner';
import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import CPGreenIcon from 'assets/img/dailychallenge/enable-cp-green.svg';
import SuperheroIcon from 'assets/img/leaderboard/super-hero/superhero-point.png';
import BreakdownScore from 'components/Leaderboard/Superhero/BreakdownScore';
import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';
import { lionCityIdHashmap, zodiacs } from 'constants/leaderboard';
import { milisecondToString } from 'helpers/timesParser';
import KoobitsLogo from 'assets/img/koobits-school-logo.jpg';
import humanize from 'helpers/humanize';
import lionCityOccupations from 'constants/leaderboard/lionCityOccupations';
import formatI18nDateTime from 'helpers/formatI18nDateTime';
import StudentSubmission from './StudentSubmission';
import { Container, Headings, Lists, ClassList } from './StudentList.styles';

const StudentList = ({
  data,
  isTopFifty,
  challengeMode,
  active,
  isLoading,
  toggle,
  setToggle,
  studentsData,
  callStudents,
  activeTerm,
  setClassId,
  showAllStudents,
  toggleAllStudent,
  updateInviteStatus,
  reducer,
  openOccupationModal,
  subject = 1,
}) => {
  const { t, i18n } = useTranslation(['leaderboard']);

  const { userID } = useSelector((state) => state.login);
  const { termStudents, classicStudents } = useSelector(
    (state) => state.championClass
  );
  const { groupStudents } = useSelector((state) => state[reducer]);
  const [list, setList] = useState(data);
  const [IsNearBy, setIsNearBy] = useState(true);

  const labelOne = () => {
    if (challengeMode === 'championClass')
      return t('leaderboard:leaderboardRanking.class', 'Class');
    if (challengeMode === 'zodiacRace')
      return t('leaderboard:leaderboardRanking.zodiac', 'Zodiac');
    if (challengeMode === 'wizardSchool')
      return t('leaderboard:leaderboardRanking.school', 'School');
    if (challengeMode === 'lionCity')
      return t('leaderboard:leaderboardRanking.career', 'Career');
    return t('leaderboard:leaderboardRanking.name', 'Name');
  };

  const flexOne = () =>
    challengeMode === 'zodiacRace' || challengeMode === 'lionCity' ? 4 : 2;

  const labelTwo = () => {
    if (challengeMode === 'bff')
      return t('leaderboard:leaderboardRanking.cityState', 'City / State');
    if (
      challengeMode === 'zodiacRace' ||
      (challengeMode === 'wizardSchool' && active === 3) ||
      challengeMode === 'lionCity'
    )
      return '';
    if (challengeMode === 'wizardSchool' && active === 4)
      return t('leaderboard:leaderboardRanking.country', 'Country');
    if (
      active === 3 ||
      (challengeMode === 'championClass' && active !== 1) ||
      challengeMode === 'superhero'
    ) {
      return t('leaderboard:leaderboardRanking.school', 'School');
    }
    return t('leaderboard:leaderboardRanking.class', 'Class');
  };

  const flexTwo = () =>
    challengeMode === 'zodiacRace' ||
    (challengeMode === 'wizardSchool' && active === 1) ||
    challengeMode === 'lionCity'
      ? 0
      : 2;

  const labelThree = () => {
    if (challengeMode === 'championClass')
      return t('leaderboard:championClass.cp', 'CPs (Top 20)');
    if (challengeMode === 'bff')
      return t('leaderboard:leaderboardRanking.cpWeek', 'CPs of week');
    if (challengeMode === 'wizardSchool')
      return t('leaderboard:wizardSchool.cp', 'CPs (Top 250)');
    if (challengeMode === 'factualFluency')
      return t('leaderboard:leaderboardRanking.score', 'Score');
    if (challengeMode === 'superhero')
      return t('leaderboard:leaderboardRanking.superPoint', 'Super Point');
    return t(
      'leaderboard:leaderboardRanking.challengePoint',
      'Challenge Point'
    );
  };

  const flexThree = () =>
    (challengeMode === 'wizardSchool' && active === 1) ||
    challengeMode === 'lionCity'
      ? 2
      : 1;

  const labelFour = () => {
    if (challengeMode === 'factualFluency')
      return t('leaderboard:leaderboardRanking.totalTime', 'Total Time');
    return t(
      'leaderboard:leaderboardRanking.achivementTime',
      'Achievement Time'
    );
  };

  const flexFour = () =>
    (challengeMode === 'wizardSchool' && active === 1) ||
    challengeMode === 'lionCity'
      ? 2
      : 1;

  const labels = [
    {
      name: labelOne(),
      flex: flexOne(),
    },
    {
      name: labelTwo(),
      flex: flexTwo(),
    },
    {
      name: labelThree(),
      flex: flexThree(),
    },
    {
      name: labelFour(),
      flex: flexFour(),
    },
  ];

  const showPosition = (pos) => {
    if (isTopFifty && pos < 4) {
      if (pos === 1) return 'first';
      if (pos === 2) return 'second';
      if (pos === 3) return 'third';
    }
    if (subject === 2) {
      return 'scDefault';
    }
    return 'default';
  };

  useEffect(() => {
    setIsNearBy(true);
    if (data && !isTopFifty) {
      let tempList = [...list];
      tempList = data.slice(2);
      setList(tempList);
    } else {
      setList(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const showNerBy = () => {
    setList(data);
    setIsNearBy(false);
  };

  const addDefaultSrc = (ev) => {
    // eslint-disable-next-line no-param-reassign
    ev.target.src = KoobitsLogo;
  };

  const firstName = (item) => {
    if (challengeMode === 'championClass') return item.ClassName;
    if (challengeMode === 'bff') {
      return (
        <>
          {item.Name}
          <span className="bff-friends">
            {t('leaderboard:bff.friends', ' & Friends')}
          </span>
        </>
      );
    }
    if (challengeMode === 'factualFluency') {
      return item.FullName;
    }
    if (challengeMode === 'zodiacRace') {
      return (
        <>
          <span className="zodiac-name">
            {t(`leaderboard:zodiacRace.${item.ZodiacName}`, item.ZodiacName)}
          </span>
          <span className="zodiac-desc">
            {`(${moment(item.ZodiacStartDate).format(
              formatI18nDateTime({
                format: 'MMM DD',
                dateOnly: true,
                language: i18n.language,
              })
            )} - ${moment(item.ZodiacEndDate).format(
              formatI18nDateTime({
                format: 'MMM DD',
                dateOnly: true,
                language: i18n.language,
              })
            )}) ${item.ZodiacDesc}`}
          </span>
        </>
      );
    }
    if (challengeMode === 'wizardSchool') {
      return (
        <div className="list-school-name">
          <span className="school-img-container">
            <img
              src={item.SchoolLogo}
              onError={addDefaultSrc}
              alt="school-logo"
            />
          </span>
          <span>{item.SchoolName}</span>
        </div>
      );
    }
    if (challengeMode === 'lionCity')
      return t(
        `leaderboard:lionCity.${lionCityIdHashmap[item.OccupationId]}.title`,
        '-'
      );
    return item.UserName;
  };

  const secondList = (item) => {
    if (challengeMode === 'zodiacRace') return '';
    if (challengeMode === 'wizardSchool' && active === 3) return '';
    if (challengeMode === 'wizardSchool' && active === 4)
      return (
        <div className="country-img-logo">
          <img src={item.CountryLogo} alt="flag" />
        </div>
      );
    if (challengeMode === 'factualFluency') return '';
    if (challengeMode === 'lionCity') return '';
    if (challengeMode !== 'bff')
      return (
        <div className="school-img-container">
          <img src={item.SchoolLogo} alt="school-logo" />
        </div>
      );

    return (
      <div className="country-img">
        <img src={item.CountryFlag} alt="flag" />
      </div>
    );
  };

  const secondName = (item) => {
    if (challengeMode !== 'championClass' && active === 3) {
      return item.SchoolName;
    }
    if (challengeMode === 'championClass' && active !== 1) {
      return item.SchoolName;
    }
    if (challengeMode === 'bff') {
      return item.CountryName;
    }
    if (challengeMode === 'superhero') {
      return item.SchoolName;
    }
    if (challengeMode === 'zodiacRace') return '';
    if (challengeMode === 'wizardSchool' && active === 3) return '';
    if (challengeMode === 'wizardSchool' && active === 4)
      return humanize(item.CountryName);
    return item.ClassName;
  };

  const cpValue = (item) => {
    if (challengeMode === 'championClass' || challengeMode === 'zodiacRace') {
      return item.TotalCP;
    }
    if (challengeMode === 'wizardSchool') return item.TotalCP;
    if (challengeMode === 'bff') {
      return item.WeekCP;
    }
    if (challengeMode === 'factualFluency') {
      return (
        <>
          {item.CurrentScore}/
          <span className="total-score">{item.TotalScore}</span>
        </>
      );
    }
    if (challengeMode === 'lionCity') return item.TotalCP;
    if (challengeMode === 'superhero') return item.TotalSp;
    return item.CPPoints;
  };

  const toggleStudent = (i, item) => {
    if (challengeMode === 'championClass') toggleAllStudent(i, item.ClassId);
    else if (challengeMode === 'bff') toggleAllStudent(i, item.UserId);
    else if (challengeMode === 'superhero') toggleAllStudent(i);
    else if (challengeMode === 'zodiacRace') toggleAllStudent(i, item.ZodiacId);
    else if (challengeMode === 'wizardSchool')
      toggleAllStudent(i, item.SeasonId, item.SchoolId);
    else if (challengeMode === 'lionCity')
      toggleAllStudent(i, item.OccupationId);
  };

  const renderIcon = () => {
    return subject === 2 ? CPGreenIcon : CPIcon;
  };

  // Champion Class condition
  if (challengeMode === 'championClass' && active === 1) {
    const classList = data[0];
    const participants = classList && classList.TotalParticipants;

    return (
      <Container challengeMode={challengeMode} subject={subject}>
        {classList && (
          <>
            <ClassList
              data-cy="myClass-list"
              onClick={() => {
                if (activeTerm === 'term') {
                  setToggle(!toggle);
                } else if (active === 1 && activeTerm === 'classic') {
                  callStudents(classList.ClassId);
                  setClassId(classList.ClassId);
                  setToggle(!toggle);
                } else {
                  return callStudents;
                }
              }}
            >
              <div className="school">
                <img
                  src={classList.SchoolLogo}
                  style={{ marginRight: '5px' }}
                  alt="school-logo"
                />
                {classList.ClassName}
              </div>
              <div className="total-participants">
                <Trans
                  i18nKey="leaderboard:championClass.totalParticipant"
                  participants={participants}
                >
                  Total Participants: <span>{{ participants }}</span>
                </Trans>
              </div>
              <div className="total-details">
                <Trans i18nKey="leaderboard:championClass.totalCp">
                  Total CPs of the term
                  <br /> from top 20 students
                </Trans>
              </div>
              <div className="total-points">
                <img src={CPIcon} alt="cps" />
                <span>{classList.TotalCP}</span>
              </div>
            </ClassList>
            {toggle && (
              <StudentSubmission
                mode="championClass"
                data={studentsData}
                score={classList.TotalCP}
                updateInviteStatus={updateInviteStatus}
              />
            )}
          </>
        )}
      </Container>
    );
  }
  const renderListAchivement = (item) => {
    if (challengeMode === 'factualFluency') {
      return milisecondToString(item.TimeTaken);
    }
    const lastSubmissionDate = moment(
      item.LastSubmissionDate || item.lastSubmissionDate
    ).format('DD-MMM-YYYY HH:mm');
    if (challengeMode === 'championClass') {
      return `${lastSubmissionDate} ${item.CountryCode}`;
    }
    if (challengeMode === 'lionCity') {
      return `${lastSubmissionDate} SGP`;
    }
    if (challengeMode === 'bff') {
      return moment(item.AchievementTime).format('DD-MMM-YYYY HH:mm');
    }
    return lastSubmissionDate;
  };
  return (
    <Container
      challengeMode={challengeMode}
      subject={subject}
      data-cy="student-list"
    >
      <div className="list-headings">
        {labels.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Headings key={index} flex={item.flex} subject={subject}>
            {item.name}
          </Headings>
        ))}
      </div>
      {isLoading && <Spinner />}
      {!isLoading && !isTopFifty && IsNearBy && (
        <div className="nearby-container">
          <div onClick={showNerBy} className="nearby">
            {t('leaderboard:buttons.nearby', 'See Who is nearby')}
          </div>
        </div>
      )}
      {!isLoading &&
        list.map((item, i) => (
          <React.Fragment key={item.UserId || item.rank || item.ZodiacName}>
            <Lists
              subject={subject}
              data-cy={`data-rank-${i}`}
              active={active}
              className={item.UserId === userID && 'current-user'}
              currPos={showPosition(item.leaderboardRank || i + 1)}
              challengeMode={challengeMode}
              onClick={() => toggleStudent(i, item)}
            >
              {isTopFifty && (
                <div className="positions">{item.leaderboardRank || i + 1}</div>
              )}
              <div className="list-name">{firstName(item)}</div>
              <div className="list-school">
                {secondList(item)}
                {secondName(item)}
              </div>
              <div className="list-points">
                {challengeMode === 'zodiacRace' && (
                  <img
                    style={{ width: '30px', marginLeft: '-25px' }}
                    src={zodiacs[item.ZodiacId].image}
                    alt="zr"
                  />
                )}
                {challengeMode === 'lionCity' && (
                  <img
                    className="country-img"
                    src={lionCityOccupations[item.OccupationId]}
                    alt="occupation"
                    onClick={(e) => {
                      e.stopPropagation();
                      openOccupationModal(item.OccupationId);
                    }}
                  />
                )}
                {challengeMode !== 'factualFluency' && (
                  <img
                    src={
                      challengeMode === 'superhero'
                        ? SuperheroIcon
                        : renderIcon()
                    }
                    alt="cps"
                  />
                )}
                {cpValue(item)}
                {challengeMode !== 'superhero' && item.IsSuperHero && (
                  <SuperheroIconModal />
                )}
              </div>
              <div className="list-achivement">
                {renderListAchivement(item)}
              </div>
            </Lists>
            {challengeMode === 'championClass' && showAllStudents[i] && (
              <StudentSubmission
                mode="championClass"
                data={
                  activeTerm === 'term'
                    ? termStudents[item.ClassId] || []
                    : classicStudents[item.ClassId] || []
                }
                score={item.TotalCP}
                updateInviteStatus={updateInviteStatus}
              />
            )}
            {challengeMode === 'bff' && showAllStudents[i] && (
              <StudentSubmission
                mode="bff"
                data={groupStudents[item.UserId] || []}
              />
            )}
            {challengeMode === 'zodiacRace' && showAllStudents[i] && (
              <StudentSubmission
                mode="zodiacRace"
                data={groupStudents[item.ZodiacId] || []}
              />
            )}
            {challengeMode === 'wizardSchool' && showAllStudents[i] && (
              <StudentSubmission
                mode="wizardSchool"
                data={groupStudents[item.SchoolId] || []}
              />
            )}
            {challengeMode === 'lionCity' && showAllStudents[i] && (
              <StudentSubmission
                mode="lionCity"
                data={groupStudents[item.OccupationId] || []}
              />
            )}
            {challengeMode === 'superhero' && showAllStudents[i] && (
              <BreakdownScore data={data[i] || []} />
            )}
          </React.Fragment>
        ))}
    </Container>
  );
};

export default StudentList;
