import React from 'react';
import { useSelector } from 'react-redux';
import Title from 'components/Expired/Title';
import Description from 'components/Expired/Description';
// import PlanDetails from 'components/Expired/PlanDetails';
// import PaymentSuccess from 'components/Expired/PaymentSuccess';
import { Cards } from './index.styles';
const pageNo = 1;
const Standard = () => {
  const { studentDetails, defaultLevel } = useSelector(
    (state) => state.studentDetails
  );
  // const [pageNo, setPageNo] = useState(1);

  const redirectUser = () => {
    const url = `${
      process.env.REACT_APP_OFFICIAL_WEB_URL
    }plans/up?k21up=expired@k21T.koobits&k21up-qty=14&k21id=${
      studentDetails?.UserName ?? ''
    }&k21em=${studentDetails?.ParentsDetails?.Email ?? ''}&k21cn=${
      studentDetails?.FullName ?? ''
    }&k21lv=${defaultLevel ?? ''}&k21ph=${
      studentDetails?.ParentsDetails?.PhoneNumber?.PhoneNumberRaw ?? ''
    }&k22uid=${
      studentDetails?.UserId ?? ''
    }&utm_source=expiry&utm_medium=btn&utm_campaign=k21ex&utm_content=resub_trial`;
    window.location.replace(url);
  };
  // const showDesc = () => setPageNo(1);
  // const renewAcc = () => setPageNo(2);
  // const upgradeAcc = () => setPageNo(3);

  return (
    <>
      {pageNo === 1 && <Title type="standard" />}
      <Cards>
        {pageNo === 1 && (
          <Description
            type="standard"
            name={studentDetails.FullName}
            renew={redirectUser}
            // upgrade={upgradeAcc}
          />
        )}
        {/* {pageNo === 2 && <PlanDetails type="standard" goBack={showDesc} />}
        {pageNo === 3 && <PaymentSuccess type="standard" />} */}
      </Cards>
    </>
  );
};

export default Standard;
