import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './ErrorResponse.styles';

const ErrorResponse = ({ error, color, paddingTop, children }) => {
  const { t } = useTranslation(['common']);
  return (
    <Container color={color} paddingTop={paddingTop} data-cy="error-response">
      <h3>{t('common:error', 'Error')}</h3>
      <h4>{error}</h4>
      {children}
    </Container>
  );
};

export default ErrorResponse;
