import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: 100px;
  margin-left: 2rem;
  margin-top: 5px;
  display: flex;
  align-items: center;
  color: #404447;
  font-size: 0.875rem;
  @media (max-width: 1150px) {
    left: 15px;
  }
  @media (max-width: 600px) {
    border-radius: 50%;
    padding: 15px;
    left: 10px;
    margin-top: 0px;
    margin-left: 0rem;

    button {
      height: 38px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    img {
      margin-left: 0.2rem;
    }

    .button-text {
      display: none;
    }
    svg {
      position: absolute;
    }
  }

  @media (max-width: 400px) {
    left: 0px;

    button {
      transform: scale(0.9);
    }
  }

  @media (max-width: 350px) {
    left: -10px;
  }
`;
