import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  position: fixed;
  width: 100%;
  top: 144px;
  height: 39px;
  z-index: 10;
`;

export const Level = styled.div`
  border: ${(props) => (props.selected ? '1px solid #dbdbdb' : 'none')};
  border-radius: 20px;
  width: 68px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #191919;
  &:hover {
    border: 1px solid #dbdbdb;
  }
  .dot {
    height: 10px;
    width: 10px;
    background: #b2ddff;
    border-radius: 50%;
    margin-right: 4px;
  }
`;
