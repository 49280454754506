import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';
import HeaderBg from 'assets/img/dailychallengev2/header.png';
import MainSectionBg1 from 'assets/img/dailychallengev2/bg1.svg';
import MainSectionBg2 from 'assets/img/dailychallengev2/bg2.svg';
import MainSectionBg3 from 'assets/img/dailychallengev2/bg3.svg';
import MainSectionBg4 from 'assets/img/dailychallengev2/bg4.svg';

// XL Background assets
import MainSectionXLBg1 from 'assets/img/dailychallengev2/bg1-xl.svg';
import MainSectionXLBg2 from 'assets/img/dailychallengev2/bg2-xl.svg';
import MainSectionXLBg3 from 'assets/img/dailychallengev2/bg3-xl.svg';
import MainSectionXLBg4 from 'assets/img/dailychallengev2/bg4-xl.svg';

// Large Background assets
import MainSectionLargeBg1 from 'assets/img/dailychallengev2/bg1-lg.svg';
import MainSectionLargeBg2 from 'assets/img/dailychallengev2/bg2-lg.svg';
import MainSectionLargeBg3 from 'assets/img/dailychallengev2/bg3-lg.svg';
import MainSectionLargeBg4 from 'assets/img/dailychallengev2/bg4-lg.svg';

// Mobile/Tablet Background assets
import MainSectionResponsiveBg1 from 'assets/img/dailychallengev2/bg1-responsive.png';
import MainSectionResponsiveBg2 from 'assets/img/dailychallengev2/bg2-responsive.png';
import MainSectionResponsiveBg3 from 'assets/img/dailychallengev2/bg3-responsive.png';
import MainSectionResponsiveBg4 from 'assets/img/dailychallengev2/bg4-responsive.png';

// Center Background
import MainSectionCenterBg1 from 'assets/img/dailychallengev2/bg1-center.svg';
import MainSectionCenterBg2 from 'assets/img/dailychallengev2/bg2-center.svg';
import MainSectionCenterBg3 from 'assets/img/dailychallengev2/bg3-center.svg';
import MainSectionCenterBg4 from 'assets/img/dailychallengev2/bg4-center.svg';

// Mobile/Tablet Center Background
import MainSectionCenterResponsiveBg1 from 'assets/img/dailychallengev2/bg1-center-responsive.svg';
import MainSectionCenterResponsiveBg2 from 'assets/img/dailychallengev2/bg2-center-responsive.svg';
import MainSectionCenterResponsiveBg3 from 'assets/img/dailychallengev2/bg3-center-responsive.svg';
import MainSectionCenterResponsiveBg4 from 'assets/img/dailychallengev2/bg4-center-responsive.svg';

import Badge from 'assets/img/dailychallengev2/badge.svg';

const mappingMissionStyle = {
  completed: {
    number: '#ffffff',
    inline: '#4bce2a',
    outline: '#ffffff',
    shadow: '#A8A8A8',
  },
  incompleted: {
    number: '#00a4ff',
    inline: '#ffffff',
    outline: '#CEDDDD',
    shadow: '#A8A8A8',
  },
  skipped: {
    number: '#999999',
    inline: '#CEDDDD',
    outline: '#ffffff',
    shadow: '#6E987C',
  },
};

const mappingMainSectionStyle = {
  Elementary: {
    bgColor: '#C8F774',
    bg: MainSectionBg1,
    XLBg: MainSectionXLBg1,
    largeBg: MainSectionLargeBg1,
    centerBg: MainSectionCenterBg1,
    responsiveBg: MainSectionResponsiveBg1,
    centerResponsiveBg: MainSectionCenterResponsiveBg1,
    center: {
      size: 70,
      position: {
        left: 71,
        bottom: 47,
      },
    },
    runway: {
      shadow: '#05432F',
      bg: '#005A3D',
      line: 'rgba(248, 255, 235, 0.81)',
    },
  },
  Intermediate: {
    bgColor: '#FFF064',
    bg: MainSectionBg2,
    XLBg: MainSectionXLBg2,
    largeBg: MainSectionLargeBg2,
    centerBg: MainSectionCenterBg2,
    responsiveBg: MainSectionResponsiveBg2,
    centerResponsiveBg: MainSectionCenterResponsiveBg2,
    center: {
      size: 71,
      position: {
        left: 73,
        bottom: 42,
      },
    },
    runway: {
      shadow: '#3E2F0A',
      bg: '#836315',
      line: '#F8FFEB ',
    },
  },
  Advanced: {
    bgColor: '#00BDFF',
    bg: MainSectionBg3,
    XLBg: MainSectionXLBg3,
    largeBg: MainSectionLargeBg3,
    centerBg: MainSectionCenterBg3,
    responsiveBg: MainSectionResponsiveBg3,
    centerResponsiveBg: MainSectionCenterResponsiveBg3,
    center: {
      size: 69,
      position: {
        left: 63,
        bottom: 41,
      },
    },
    runway: {
      shadow: '#164164',
      bg: '#1F5D90',
      line: '#F8FFEB ',
    },
  },
  Master: {
    bgColor: '#9280FF',
    bg: MainSectionBg4,
    XLBg: MainSectionXLBg4,
    largeBg: MainSectionLargeBg4,
    centerBg: MainSectionCenterBg4,
    responsiveBg: MainSectionResponsiveBg4,
    centerResponsiveBg: MainSectionCenterResponsiveBg4,
    center: {
      size: 50,
      position: {
        left: 75,
        bottom: 50,
      },
    },
    runway: {
      shadow: '#32298D',
      bg: '#4D3FA3',
      line: '#F8FFEB',
    },
  },
};

const mappingRewardStyle = {
  Elementary: {
    top: '81',
    right: '-0.5',
    width: '5',
  },
  Intermediate: {
    top: '81',
    right: '-1',
    width: '6',
  },
  Advanced: {
    top: '82',
    right: '-2',
    width: '8',
  },
  Master: {
    top: '82',
    right: '-2',
    width: '8',
  },
};

const mappingRewardStyleResponsive = {
  Elementary: {
    top: '85',
    right: '9',
    width: '12',
  },
  Intermediate: {
    top: '85',
    right: '9',
    width: '12',
  },
  Advanced: {
    top: '89',
    right: '9',
    width: '16',
  },
  Master: {
    top: '89',
    right: '9',
    width: '16',
  },
};

const mappingUnderline = {
  Elementary: {
    width: 100,
  },
  Intermediate: {
    width: 114,
  },
  Advanced: {
    width: 90,
  },
  Master: {
    width: 72,
  },
};

export const StyledLoaderWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 59px);
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    overflow: auto;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2rem;
  border-bottom: 2px solid #404447;
  z-index: 2;

  button.responsive {
    position: absolute;
    z-index: 99;
    top: 1.5rem;
    left: 1.5rem;
  }

  .dc-text {
    width: 60%;
    max-width: 280px;
    min-height: 78px;
    margin-bottom: 0.5rem;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      .dc-text {
        margin-bottom: 1rem;
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      height: 18%;
      padding: 0px 2.28vw 0px 2.85vw;
      align-items: center;
      justify-content: space-between;

      button.responsive {
        position: initial;
        margin-top: -3%;
      }
      .dc-text {
        position: initial;
        width: 20%;
        top: 0px;
        transform: translateY(0%);
        right: 0px;
        margin-bottom: 0px;
        max-width: 1000px;
      }
    }
  }
  @media (min-width: 1440px) {
    .dc-text {
      width: 25%;
    }
  }
`;

export const HeaderRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  align-items: center;
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 70%;
    height: 100%;
    gap: 2vw;
  }
`;

export const HeaderLevelContainer = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 0px;
  padding-left: 0px;
  & > span:not(:last-child) {
    display: none;
    width: 50px;
    height: 10px;
    border-radius: 50px;
    background-color: #ffd63c;
    position: relative;
    top: -6%;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      height: 10vw;
      max-height: 100%;
      & > span:not(:last-child) {
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      position: relative;
      width: 80%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: flex-start;
      margin-bottom: 0px;
      padding-left: 0px;
      & > span:not(:last-child) {
        width: 9%;
        margin-bottom: 0px;
      }
    }
  }
`;

export const LevelItem = styled.li`
  position: relative;
  height: 100%;
  aspect-ratio: 1;

  min-width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  user-select: none;
  font-size: 10px;

  transition: 200ms;
  cursor: ${(p) => (p.locked ? 'not-allowed' : 'pointer')};
  transform: scale(${(p) => (p.active ? '1.22' : '1')});
  color: #404447;

  &::before {
    content: '';
    width: ${(p) => mappingUnderline[p.currentLevel].width}%;
    height: 2.5%;
    background: ${(p) => (p.active ? '#404447' : 'transparent')};
    position: absolute;
    bottom: 8.5%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px 50px 10px 10px;
  }

  .dc-dot {
    margin-right: 2px;
  }

  & > p {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .dc-levels {
    position: relative;
    width: 64.1%;
  }

  .dc-level-icon {
    width: 100%;
    border-radius: 50%;
    opacity: ${(p) => (p.active ? '1' : '0.5')};
  }

  .dc-locked {
    position: absolute;
    right: -14px;
    bottom: 0;
  }

  .dc-levels::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 116%;
    height: 110%;
    background: ${(p) => (p.active ? '#f0b62a' : 'transparent')};
    z-index: -1;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
  }

  &:hover .dc-levels::before {
    background: #f0b62a;
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      min-width: 10vw;
      font-size: 12px;
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      aspect-ratio: 1 / 1.7;
      justify-content: center;
      font-size: min(1.2vw, 14px);
      min-width: auto;
      & > p {
        margin-bottom: 0px;
      }
    }
  }
`;

export const MainContainer = styled.div`
  position: relative;
  background-color: ${(p) => mappingMainSectionStyle[p.currentLevel].bgColor};
  background-image: url(${(p) => mappingMainSectionStyle[p.currentLevel].bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 82%;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dc-content {
    margin: 9vh auto 0 auto;
    position: relative;
    top: 2vh;
    width: 70%;
    aspect-ratio: 930 / 336;
    margin-bottom: 10vh;
    background-image: url(${(p) =>
      mappingMainSectionStyle[p.currentLevel].centerBg});
    background-repeat: no-repeat;
    background-size: ${(p) =>
      mappingMainSectionStyle[p.currentLevel].center.size}%;
    background-position: bottom
      ${(p) => mappingMainSectionStyle[p.currentLevel].center.position.bottom}%
      left
      ${(p) => mappingMainSectionStyle[p.currentLevel].center.position.left}%;

    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      & {
        background-image: url(${(p) =>
          mappingMainSectionStyle[p.currentLevel].centerResponsiveBg});
      }
    }
  }

  .dc-runway {
    width: 100%;
    height: auto;
  }

  .runway-shadow {
    fill: ${(p) => mappingMainSectionStyle[p.currentLevel].runway.shadow};
  }

  .runway-bg {
    fill: ${(p) => mappingMainSectionStyle[p.currentLevel].runway.bg};
  }

  .runway-line {
    fill: ${(p) => mappingMainSectionStyle[p.currentLevel].runway.line};
    @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      & {
        fill: transparent;
        stroke: ${(p) => mappingMainSectionStyle[p.currentLevel].runway.line};
      }
    }
  }

  .dc-status-item {
  }

  .dc-info {
    width: 100%;
    display: flex;
    gap: 2vw;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 3vh;

    & > div:nth-child(1) {
      width: 100%;
      display: flex;
      gap: 2vw;
      align-items: center;
      justify-content: center;
      @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
        margin-bottom: 1rem;
      }
    }

    .dc-curriculum {
      color: #66aec4;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-item: center;
      gap: 10px;

      > span {
        color: #403f3f;
      }
    }
  }

  @media (max-width: 1440px) {
    background-image: url(${(p) =>
      mappingMainSectionStyle[p.currentLevel].XLBg});

    .dc-content {
      top: 2vh;
      left: -2vw;
      width: 80%;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    background-image: url(${(p) =>
      mappingMainSectionStyle[p.currentLevel].responsiveBg});
  }

  @media (max-width: 1024px) {
    background-image: url(${(p) =>
      mappingMainSectionStyle[p.currentLevel].largeBg});
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    overflow-x: hidden;
    .dc-info {
      padding-bottom: 0px;
      flex-direction: column;
      margin-top: 4rem;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet - 1}px`}) {
    height: auto;
    flex-grow: 1;
    .dc-content {
      margin: auto;
      margin-top: 4rem;
      width: min(510px, 70%);
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .dc-content {
      width: min(calc(100vw - 1rem), 85%);
    }
  }
`;

export const StatusItem = styled.span`
  font-size: 14px;

  &:first-of-type {
    margin-left: 10vw;
  }
  &::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${(p) => p.color};
    margin-right: 5px;
    margin-bottom: -3px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    &:first-of-type {
      margin-left: 0px;
    }
  }
`;

export const Missionitem = styled.div`
  width: 15%;
  position: absolute;
  top: ${(p) => p.position.top}%;
  left: ${(p) => p.position.left}%;
  cursor: pointer;

  .mission-icon {
    position: relative;
    width: 100%;

    svg {
      width: 100%;
      height: auto;
    }

    img {
      max-width: 100%;
      width: 35%;
      position: absolute;
      right: -5%;
      bottom: 3%;
    }

    .mission-number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: min(3.4vw, 60px);
      color: ${(p) => mappingMissionStyle[p.status]?.number};
      font-weight: 600;
      font-family: Baloo Chettan;
    }
  }

  .mission-cp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #161616;
    font-weight: 600;
    font-size: 12px;

    & > span {
      opacity: ${(p) => (p.status === 'completed' ? 1 : 0.3)};
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    &:nth-child(6) .mission-cp {
      margin-left: 50px;
      span {
        white-space: nowrap;
      }
    }

    .mission-icon {
      width: 120%;
      .mission-number {
        font-size: min(8vw, 60px);
      }
    }
  }

  .mission-bg {
    .d {
      fill: ${(p) => mappingMissionStyle[p.status]?.shadow};
    }

    .e {
      fill: ${(p) => mappingMissionStyle[p.status]?.inline};
    }

    .f {
      stroke: ${(p) => mappingMissionStyle[p.status]?.outline};
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      width: 8%;
      .mission-cp {
        font-size: 14px;
      }
    }
  }
`;

export const RewardIcon = styled.div`
  position: absolute;
  top: ${(p) => mappingRewardStyle[p.currentLevel].top}%;
  right: ${(p) => mappingRewardStyle[p.currentLevel].right}%;
  width: ${(p) => mappingRewardStyle[p.currentLevel].width}%;

  & > img {
    width: 100%;
  }

  & > div > img {
    width: 100%;
    height: auto;
  }

  .dc-reward-container {
    position: relative;
    width: 100%;
  }

  .dc-reward-claimed {
    width: 50%;
    position: relative;

    img {
      width: 100%;
      max-width: 25px;
      position: absolute;
      right: -40px;
      bottom: -5px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    top: ${(p) => mappingRewardStyleResponsive[p.currentLevel].top}%;
    right: ${(p) => mappingRewardStyleResponsive[p.currentLevel].right}%;
    width: ${(p) => mappingRewardStyleResponsive[p.currentLevel].width}%;
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${(p) => (p.opacity ? p.opacity : '0.5')});
  display: flex;
  justify-content: center;

  .dc-alert-modal {
    position: relative;
    width: 470px;
    height: 237px;
    background-color: #ffffff;
    text-align: center;
    border-radius: 20px;
    padding: 20px;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
      content: '!';
      display: inline-block;
      background-color: #ff6100;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      color: #fff;
      font-size: 40px;
      line-height: 62px;
      position: absolute;
      top: -31px;
      left: calc(50% + 2px);
      transform: translateX(-50%);
    }
  }

  p {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  button {
    border: none;
    background-color: #ff6100;
    width: 96px;
    height: 33px;
    line-height: 33px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 600;
  }
`;

export const Loader = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  & {
    margin: 30vh auto;
    font-size: 10px;
    position: relative;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const PointingHand = styled.img`
  position: absolute;
  top: -3%;
  left: -4%;
  width: 15%;
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      top: 0;
      left: 0;
      width: 8%;
    }
  }
`;

const ITooltip = styled(Tooltip)`
  z-index: 3;

  .tooltip.show {
    opacity: 1;
  }

  .arrow::before {
    border-top-color: #404447 !important;
    border-bottom-color: #404447 !important;
  }

  .tooltip-inner {
    border-radius: 14px;
    color: #ffffff;
    background-color: #404447;
    text-align: left;
    padding: 14px 22px;
    white-space: nowrap;
    font-weight: 700;
    font-family: Linotte;
  }
`;

export const LevelTooltip = styled(ITooltip)`
  .dc-tooltip-badge {
    position: relative;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    width: 70px;
    text-align: center;
    background-image: url(${Badge});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
`;

export const InfoTooltip = styled(Tooltip)`
  .tooltip-inner {
    border-radius: 14px;
    padding: 14px 22px;
    font-size: 14px;
    font-weight: 700;
    font-family: Linotte;
    display: inline-block;
  }
`;

export const RewardToolTip = styled(ITooltip)``;

export const MobileBottomBar = styled.div`
  align-items: center;
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  &.dc {
    background: rgba(0, 0, 0, 0.2);
    & > div:nth-child(1) {
      & > div {
        color: #fff;
        background-color: transparent;
        &::before {
          background-color: transparent;
        }
      }
    }
    & > div:nth-child(2) {
      &.dc-curriculum span {
        color: #fff;
      }
      &.dc-curriculum img {
        visibility: hidden;
        display: none;
      }
    }
  }
  button {
    display: none;
  }
  & > div:nth-child(2) {
    width: 100%;
    & > div {
      background: transparent;
      padding-right: 0px;
      &::before {
        background-color: transparent;
      }
    }
  }
  & > div:nth-child(1) {
    position: static;
    margin: 0px;
    span {
      color: #fff;
      flex-grow: 1;
      text-align: center;
      margin-right: 0px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      display: none;
    }
  }
`;
