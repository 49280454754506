import React from 'react';
import {
  Container,
  ImageFrame,
  ImageWrapper,
  Image,
} from './ChapterImage.styles';

const ChapterImage = ({ data, type, onClickImage, className }) => {
  return (
    <Container className={className} onClick={() => onClickImage(data)}>
      <ImageFrame type={type}>
        <ImageWrapper className={data.Status}>
          <Image src={data.Image} />
        </ImageWrapper>
      </ImageFrame>
    </Container>
  );
};

export default React.memo(ChapterImage);
