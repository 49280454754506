import React from 'react';
import { featureToggle } from 'constants/index';
import KooClassBg from 'assets/img/dashboard/kooclass-premium2.svg';
import KooClassHoverBg from 'assets/img/dashboard/kooclass-hover.svg';
import KooClassScienceBg from 'assets/img/dashboard/kooclass-science.svg';
import KooClassScienceHoverBg from 'assets/img/dashboard/kooclass-science-hover.png';
import { useSelector } from 'react-redux';
import PlayBg from 'assets/img/dashboard/challenge-premium.svg';
import PracticeBg from 'assets/img/dashboard/training-premium.svg';
import PlayHoverBg from 'assets/img/dashboard/challenge-premium-hover.png';
import PracticeHoverBg from 'assets/img/dashboard/training-premium-hover.png';
import ChallengeScienceBg from 'assets/img/dashboard/challenge-science.png';
import ChallengeScienceHoverBg from 'assets/img/dashboard/challenge-science-hover.png';
import TrainingScienceBg from 'assets/img/dashboard/training-science.png';
import TrainingScienceHoverBg from 'assets/img/dashboard/training-science-hover.png';
import { useTranslation } from 'react-i18next';
import { Container } from './PremiumCards.styles';
import PlayPracticeCard from './PlayPracticeCard';
import KooClassCard from './KooClassCard';

const PremiumCards = () => {
  const { t } = useTranslation(['dashboardCards']);
  const subjectCardConfig = {
    math: {
      kooClassImg: KooClassBg,
      kooClassHoverImg: KooClassHoverBg,
      kooClassTextPosition: ['10%', '7.7%'],
      kooClassTextShadowColour: 'rgba(168, 58, 0, 0.4)',
      challengeImg: PlayBg,
      challengeHoverImg: PlayHoverBg,
      trainingImg: PracticeBg,
      trainingHoverImg: PracticeHoverBg,
      subjectText: null,
    },
    science: {
      kooClassImg: KooClassScienceBg,
      kooClassHoverImg: KooClassScienceHoverBg,
      kooClassTextPosition: ['10%', '7.7%'],
      kooClassTextShadowColour: 'rgba(82, 142, 83, 0.5)',
      challengeImg: ChallengeScienceBg,
      challengeHoverImg: ChallengeScienceHoverBg,
      trainingImg: TrainingScienceBg,
      trainingHoverImg: TrainingScienceHoverBg,
      subjectText: t('dashboardCards:kooClass.subject', 'Science'),
    },
  };
  // Redux States
  const { subject } = useSelector((state) => state.plan);

  return (
    <Container>
      <KooClassCard
        isFeatureReady={featureToggle.kooClass}
        img={subjectCardConfig[subject].kooClassImg}
        hoverImg={subjectCardConfig[subject].kooClassHoverImg}
        subjectText={subjectCardConfig[subject].subjectText}
        textPosition={subjectCardConfig[subject].kooClassTextPosition}
        textShadowColour={subjectCardConfig[subject].kooClassTextShadowColour}
      />
      <div className="right-side">
        <PlayPracticeCard
          type="Play"
          url="dashboard/play"
          img={subjectCardConfig[subject].challengeImg}
          hoverImg={subjectCardConfig[subject].challengeHoverImg}
        />
        <PlayPracticeCard
          type="Practice"
          url="dashboard/practice"
          img={subjectCardConfig[subject].trainingImg}
          hoverImg={subjectCardConfig[subject].trainingHoverImg}
        />
      </div>
    </Container>
  );
};

export default PremiumCards;
