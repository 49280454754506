import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { isNil } from 'ramda';

import { inviteFriend } from 'store/friends/friendsSlice';

import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';
import PopupModal from 'components/Shared/PopupModal';
import { SubmitModal } from 'components/Shared';
import Spinner from 'components/Shared/Spinner';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import ZodiacRaceStudents from './StudentSubmission/ZodiacRaceStudents';

import { Container, Lists, TopTwentyStyle } from './StudentSubmission.styles';

const StudentSubmission = ({ mode, data, score, updateInviteStatus }) => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const {
    BFFDetails,
    myFriends,
    sentRequests,
    inviteFriendError,
    inviteFriendLoading,
    sentRequestLoading,
  } = useSelector((state) => state.friends);
  const { studentsLoading } = useSelector((state) => state.championClass);
  const { groupId } = useSelector((state) => state.studentDetails);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedStudent, setSelectStudent] = useState({});

  const closeModal = () => {
    setShowModal(false);
  };

  // check if able to add BFF
  const checkBFF = (bffCode, friendClassId) => {
    const myBFFCode =
      BFFDetails && BFFDetails.MyBaseBffModel.BFFCode === bffCode;
    const friendsBFFCode =
      myFriends &&
      myFriends
        .map((item) => item.BFFCode)
        .findIndex((code) => code === bffCode);
    const notMyClassMate = friendClassId !== groupId;
    if (myBFFCode) {
      return true;
    }
    if (notMyClassMate) {
      return true;
    }
    if (friendsBFFCode === -1) {
      return false;
    }
    return true;
  };

  const checkInvitedBFF = (bffCode, friendClassId) => {
    const invitedBFF =
      sentRequests &&
      sentRequests.map((item) => item.BFFCode).includes(bffCode);
    const classMate = friendClassId === groupId;
    if (invitedBFF && classMate) {
      return true;
    }
    if (invitedBFF) {
      return true;
    }
    return false;
  };

  // remove duplicate data
  const nonDuplicateData = data.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => t.UserId === thing.UserId)
  );

  const showHeading = () => {
    switch (mode) {
      case 'championClass':
        return t(
          'leaderboard:championClass.studentSubmission',
          `Students Submissions`
        );
      case 'bff':
        return t('leaderboard:championClass.friendList', `Friends List`);
      default:
        return t('leaderboard:championClass.topFifty', `Top 50 Students`);
    }
  };

  const championClassBFFBtn = (item) => {
    if (
      !studentsLoading &&
      !sentRequestLoading &&
      !checkBFF(item.BFFCode, item.ClassId) &&
      !checkInvitedBFF(item.BFFCode, item.ClassId) &&
      !isNil(item.BFFCode)
    ) {
      return (
        <div
          onClick={() => {
            setShowModal(true);
            setSelectStudent(item);
            updateInviteStatus(item.ClassId);
          }}
          className="add-bff"
        >
          {t('leaderboard:championClass.add', 'Add to ')}
          <span>{t('leaderboard:championClass.bff', `BFF`)}</span>
        </div>
      );
    }
    if (
      !studentsLoading &&
      !sentRequestLoading &&
      !checkBFF(item.BFFCode, item.ClassId) &&
      checkInvitedBFF(item.BFFCode, item.ClassId)
    ) {
      return (
        <div className="invited-bff">
          {t('leaderboard:bffModal.invited', 'Invited')}
        </div>
      );
    }
    if (
      !studentsLoading &&
      !sentRequestLoading &&
      checkBFF(item.BFFCode, item.ClassId)
    ) {
      return (
        <div className="invited-bff" style={{ visibility: 'hidden' }}>
          Friend
        </div>
      );
    }
    return <div className="add-bff" />;
  };

  return (
    <Container>
      <PopupModal show={showModal} backdrop="static">
        <SubmitModal
          header={t('leaderboard:bffModal.addBff', `Add as BFF`)}
          desc={t(
            'leaderboard:bffModal.addBffMsg',
            { name: selectedStudent.UserName },
            `Are you sure you want to add ${selectedStudent.UserName} as BFF? Click Yes to confirm`
          )}
          noHandle={closeModal}
          yesHandle={() => {
            dispatch(inviteFriend(selectedStudent.BFFCode));
            setShowModal(false);
            setShowSuccessModal(true);
          }}
        />
      </PopupModal>
      <PopupModal show={showSuccessModal} backdrop="static">
        <SubmitModal
          header={
            inviteFriendLoading || sentRequestLoading
              ? 'Adding Friend'
              : inviteFriendError !== null
              ? 'Error'
              : 'Success'
          }
          desc={
            inviteFriendLoading || sentRequestLoading ? (
              <Spinner top="-2rem" />
            ) : inviteFriendError !== null ? (
              inviteFriendError
            ) : (
              `Successful! Your invitation has been sent to ${selectedStudent.UserName}. Please ask your friend to accept your request.`
            )
          }
          hideYesButton
          hideNoButton
          showOkButton={!inviteFriendLoading && !sentRequestLoading}
          okBtnColor="#ff6701"
          yesHandle={() => setShowSuccessModal(false)}
        />
      </PopupModal>
      <div className="top-heading">{showHeading()}</div>
      {mode === 'zodiacRace' && (
        <ZodiacRaceStudents nonDuplicateData={nonDuplicateData} />
      )}
      {mode === 'wizardSchool' && (
        <ZodiacRaceStudents nonDuplicateData={nonDuplicateData} />
      )}
      {nonDuplicateData.length !== 0 &&
        nonDuplicateData.map((item, i) => (
          <React.Fragment key={i}>
            <div data-cy={`data-student-${i}`}>
              <Lists
                pos={i}
                center={
                  !checkBFF(item.BFFCode, item.ClassId) ||
                  checkInvitedBFF(item.BFFCode, item.ClassId)
                }
              >
                <div className="positions">{i + 1}</div>
                <div className="list-name" data-cy="user-name">
                  {item.UserName}
                </div>
                <div className="middle">
                  {mode === 'championClass' &&
                    !studentsLoading &&
                    !sentRequestLoading &&
                    championClassBFFBtn(item)}
                  <div className="list-points" data-cy="list-points">
                    {(mode === 'bff' || mode === 'lionCity') && (
                      <img width="30px" src={item.SchoolLogo} alt="logo" />
                    )}
                    <img src={CPIcon} alt="cps" />
                    {item.CPPoints || item.CpPoints}
                    {item.IsSuperHero && <SuperheroIconModal />}
                  </div>
                </div>
                <div className="list-achivement">
                  {`${moment(item.LastSubmissionDate).format(
                    'DD-MMM-YYYY HH:mm'
                  )} ${mode !== 'bff' ? item.CountryCode ?? '' : ''}`}
                </div>
              </Lists>
              {mode === 'championClass' && i === 19 && (
                <TopTwenty score={score} t={t} />
              )}
            </div>
          </React.Fragment>
        ))}
      {/* {!studentsLoading && nonDuplicateData.length === 0 && (
        <NoStd>No students</NoStd>
      )} */}
    </Container>
  );
};

function TopTwenty({ score, t }) {
  return (
    <TopTwentyStyle>
      <div className="details">
        {t(
          'leaderboard:bffModal.cpsMsg',
          'We only calculate CPs from top 20 students'
        )}
      </div>
      <div className="total">
        {t('leaderboard:bffModal.total', 'Total')}{' '}
        <img src={CPIcon} alt="cps" /> <span>{score}</span>
      </div>
    </TopTwentyStyle>
  );
}

export default StudentSubmission;
