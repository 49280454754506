import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGroups, fetchStudents } from 'services/wizardSchool';
import { fetchWrapper } from 'services/login';

// get all schools
export const getSchools = createAsyncThunk(
  'wizardSchool/getSchools',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchGroups, params);
      // const res = {
      //   IsSuccessful: true,
      //   Message: null,
      //   Result: {
      //     seasonId: 1,
      //     seasonName: '2020 Season 3',
      //     leaderboardData: [
      //       {
      //         Rank: 1,
      //         SchoolId: 116,
      //         SchoolName: 'Pei Chun Public School ',
      //         SchoolLogo:
      //           'https://static.koobits.com/eduresources/schlogo/SG0115.png',
      //         TotalCP: 10235,
      //         CountryCode: 'SGP',
      //         CountryLogo:
      //           'https://static.koobits.com/eduresources/schlogo/SG0206.png',
      //         LastSubmissionDate: '2021-05-14T21:27:11.81',
      //       },
      //       {
      //         Rank: 2,
      //         SchoolId: 66,
      //         SchoolName: 'Greenwood Primary School ',
      //         SchoolLogo:
      //           'https://static.koobits.com/eduresources/schlogo/SG0065.png',
      //         TotalCP: 10020,
      //         CountryCode: 'SGP',
      //         CountryLogo:
      //           'https://static.koobits.com/eduresources/schlogo/SG0206.png',
      //         LastSubmissionDate: '2021-05-14T22:00:00',
      //       },
      //       {
      //         Rank: 3,
      //         SchoolId: 167,
      //         SchoolName: 'Xinghua Primary School ',
      //         SchoolLogo:
      //           'https://static.koobits.com/eduresources/schlogo/SG0166.png',
      //         TotalCP: 9769,
      //         CountryCode: 'SGP',
      //         CountryLogo:
      //           'https://static.koobits.com/eduresources/schlogo/SG0206.png',
      //         LastSubmissionDate: '2021-05-14T22:00:00',
      //       },
      //     ],
      //   },
      // };
      return res.Result;
    } catch (error) {
      throw new Error(error?.message ?? 'Get all schools failed');
    }
  }
);

// get students by school
export const getStudentsList = createAsyncThunk(
  'wizardSchool/getStudentsList',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchStudents, params);
      // const array = [
      //   'Zhang Zi Lian',
      //   'Joey Yap',
      //   'Anson Tan',
      //   'Joaquim Chin',
      //   'Zhang Zi Yi',
      //   'Naveen K.',
      //   'Jason Z.',
      //   'Yenni K.',
      //   'Jarrett Y.',
      // ];
      // const res = [
      //   {
      //     UserId: 1,
      //     UserName: array[Math.floor(Math.random() * array.length)],
      //     IsSuperHero: true,
      //     LastSubmissionDate: '2021-04-22T03:16:50.124Z',
      //     CPPoints: 400,
      //   },
      //   {
      //     UserId: 2,
      //     UserName: array[Math.floor(Math.random() * array.length)],
      //     IsSuperHero: true,
      //     LastSubmissionDate: '2021-04-22T03:16:50.124Z',
      //     CPPoints: 300,
      //   },
      //   {
      //     UserId: 3,
      //     UserName: array[Math.floor(Math.random() * array.length)],
      //     IsSuperHero: true,
      //     LastSubmissionDate: '2021-04-22T03:16:50.124Z',
      //     CPPoints: 600,
      //   },
      // ];
      // const res = {
      //   IsSuccessful: true,
      //   Message: null,
      //   Result: [
      //     {
      //       UserId: 343459,
      //       UserName: 'Angel Wee Xiu Yun',
      //       ClassId: 183615,
      //       ClassName: '5/5 Initiative',
      //       LevelId: 5,
      //       LevelName: '',
      //       CountryId: 192,
      //       CountryCode: 'SGP',
      //       SchoolId: 116,
      //       SchoolName: 'Pei Chun Public School ',
      //       SchoolLogo:
      //         'https://static.koobits.com/eduresources/schlogo/SG0115.png',
      //       IsSuperHero: true,
      //       LastSubmissionDate: '2021-05-14T20:17:52.323',
      //       CPPoints: 1373,
      //       LeaderBoardRank: 1,
      //       BFFCode: '37780497',
      //     },
      //     {
      //       UserId: 343509,
      //       UserName: 'Lee Ying En',
      //       ClassId: 183615,
      //       ClassName: '5/5 Initiative',
      //       LevelId: 5,
      //       LevelName: '',
      //       CountryId: 192,
      //       CountryCode: 'SGP',
      //       SchoolId: 116,
      //       SchoolName: 'Pei Chun Public School ',
      //       SchoolLogo:
      //         'https://static.koobits.com/eduresources/schlogo/SG0115.png',
      //       IsSuperHero: true,
      //       LastSubmissionDate: '2021-05-14T19:07:09.833',
      //       CPPoints: 1360,
      //       LeaderBoardRank: 2,
      //       BFFCode: '37785993',
      //     },
      //     {
      //       UserId: 343366,
      //       UserName: 'Kaelyn Tan Jing Shuen',
      //       ClassId: 183615,
      //       ClassName: '5/5 Initiative',
      //       LevelId: 5,
      //       LevelName: '',
      //       CountryId: 192,
      //       CountryCode: 'SGP',
      //       SchoolId: 116,
      //       SchoolName: 'Pei Chun Public School ',
      //       SchoolLogo:
      //         'https://static.koobits.com/eduresources/schlogo/SG0115.png',
      //       IsSuperHero: true,
      //       LastSubmissionDate: '2021-05-14T19:40:58.057',
      //       CPPoints: 1104,
      //       LeaderBoardRank: 3,
      //       BFFCode: '37770268',
      //     },
      //   ],
      // };
      const data = res.Result.map((item) =>
        Object.keys(item).reduce((accumulator, key) => {
          // accumulator is the new object we are creating
          // eslint-disable-next-line no-param-reassign
          accumulator[key[0].toUpperCase() + key.substring(1)] = item[key];
          return accumulator;
        }, {})
      );
      const obj = {
        [params.schoolId]: data,
      };
      return obj;
    } catch (error) {
      throw new Error(error?.message ?? 'Get students by group failed');
    }
  }
);

const initialState = {
  groupsData: [],
  isLoading: false,
  isError: null,
  groupStudents: {},
  groupStudentsLoading: false,
  groupStudentsError: null,
};

const wizardSchoolSlice = createSlice({
  name: 'wizardSchool',
  initialState,
  reducers: {},
  extraReducers: {
    [getSchools.pending]: (state) => {
      state.isLoading = true;
      state.groupsData = [];
      state.isError = null;
    },
    [getSchools.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.groupStudents = {};
      state.groupsData = action.payload;
      state.isError = null;
    },
    [getSchools.rejected]: (state, action) => {
      state.isLoading = false;
      state.groupsData = [];
      state.isError = action.error.message;
    },
    [getStudentsList.pending]: (state) => {
      state.groupStudentsLoading = true;
      state.groupStudentsError = null;
    },
    [getStudentsList.fulfilled]: (state, action) => {
      state.groupStudentsLoading = false;
      state.groupStudents = { ...state.groupStudents, ...action.payload };
      state.groupStudentsError = null;
    },
    [getStudentsList.rejected]: (state, action) => {
      state.groupStudentsLoading = false;
      state.groupStudentsError = action.error.message;
    },
  },
});

const { reducer } = wizardSchoolSlice;
export default reducer;
