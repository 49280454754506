import styled from 'styled-components';

export const Page = styled.div`
  overflow: hidden;
  visibility: visible;
`;

export const OnlineOfflineContainer = styled.div`
  padding-top: 59px;
  background: #f5f5f5;
  min-height: 100vh;
`;

export const OnlineOfflineContentContainer = styled.div`
  padding-bottom: 74px;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  position: relative;

  .gift-box {
    position: absolute;
    width: 140px;
    left: 0;
    bottom: 15px;
    z-index: 3;
  }
`;

export const OnlineOfflineCard = styled.div`
  width: ${(p) => p.width};
  height: 280px;
  font-size: 16px;
  position: relative;
  bottom: -1px;

  &::after {
    content: '';
    position: absolute;
    bottom: -72px;
    left: 0;
    right: 0;
    display: block;
    height: 73px;
    z-index: 1;
    background: linear-gradient(
      180deg,
      ${({ isMathSubject }) =>
        isMathSubject ? 'rgba(31,42,98,1) 0%' : 'rgba(1, 24, 28, 0.38) 0%'},
      rgba(255, 255, 255, 0) 100%
    );
  }

  @media (max-width: 1024px) {
    max-width: 290px;
  }

  .hots-header {
    background-color: rgba(29, 29, 29, 0.7);
    text-align: center;
    color: #ffffff;
    height: 42px;
    line-height: 42px;
    font-weight: bold;
    font-size: 18px;
  }

  .hots-content {
    background-color: rgba(29, 29, 29, 0.35);
    height: calc(100% - 43px);
    padding: 27px 24px;
    color: #ffffff;
  }

  .hots-heightlight {
    color: #ffeb00;
  }

  .hots-print {
    background-color: #1aa5ff;
    color: #fff;
    width: 225px;
    height: 60px;
    text-align: center;
    border-radius: 50px;
    position: relative;
    font-weight: bold;
    font-size: 24px;
    border: 0;

    & > img {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .hots-items-center {
    display: flex;
    align-items: center;
  }
`;

export const ScoreHeading = styled.h2`
  font-size: 1rem;
  font-weight: 700;
  color: #30a8bb;
  text-transform: uppercase;
`;

export const Score = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  & .score-per {
    color: #48b500;
    font-size: 29px;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 7px;
    font-family: Pacifico;

    & > span {
      font-size: 47px;
      margin-bottom: 0px;
      font-weight: 300;
    }

    & > div {
      position: relative;
      top: 13px;
    }
  }

  & > div {
    color: #95c9d2;
    font-size: 14px;
    white-space: nowrap;
  }

  & > div > span {
    font-weight: 700;
  }
`;

export const ScoreFooter = styled.div`
  color: rgba(255, 255, 225, 0.68);
`;
