import React from 'react';
import KooClassStandard from 'assets/img/dashboardv2/kooclass-standard.png';
import PremiumBadge from 'assets/img/dashboardv2/premium-badge.png';
import closeClassTablet from 'assets/img/dashboardv2/closeClassTablet.png';

import {
  Container,
  Main,
  Badge,
  Overlay,
  Tablet,
} from './KooClassLocked.style';

const KooClassLocked = () => {
  return (
    <>
      <Tablet>
        <Overlay />
        <img src={closeClassTablet} alt="tablet close bg" />
      </Tablet>
      <Container data-cy="kooclass-screen">
        <Main>
          <img src={KooClassStandard} alt="" className="standardImage" />
          <Badge src={PremiumBadge} alt="" />
        </Main>
      </Container>
    </>
  );
};

export default KooClassLocked;
