import styled from 'styled-components';
import MathBg from 'assets/img/dashboardv2/math-bg.svg';
import ScienceBg from 'assets/img/dashboardv2/science-bg.svg';

const subjectStyles = {
  math: {
    bg: MathBg,
    bgSize: '98% auto',
    bgPosition: '100px bottom',
    bgPositionResponsive: '50% bottom',
    bgMobileSPositionResponsive: '50% calc(100vh - 180px)',
    bgColour: '#3d3789',
  },
  science: {
    bg: ScienceBg,
    bgSize: '100% auto',
    bgPosition: 'center',
    bgPositionResponsive: '200%',
    bgMobileSPositionResponsive: '200%',
    bgColour: '#00684d',
  },
};

const fallbackStyle = subjectStyles.math;

export const Page = styled.div`
  margin-top: 59px;
  height: ${(props) => props.styles.pageHeight};
  min-height: 661px;
  background-color: ${(props) =>
    subjectStyles[props.subject].bgColour ?? fallbackStyle.bgColour};
  background-image: ${(props) =>
    `url(${subjectStyles[props.subject].bg ?? fallbackStyle.bg})`};
  background-position: ${(props) =>
    subjectStyles[props.subject].bgPosition ?? fallbackStyle.bgPosition};
  background-size: ${(props) =>
    subjectStyles[props.subject].bgSize ?? fallbackStyle.bgSize};
  background-repeat: no-repeat;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-top: 59px;
    margin-top: 0px;
    background-position: right -54px bottom 14%;
    background-size: 128% auto;
    background-position: ${({ subject }) =>
      subjectStyles[subject].bgPositionResponsive ?? fallbackStyle.bgPosition};
    height: ${({ activeView }) => {
      return activeView === 1 ? '100%' : '100vh';
    }};
    padding-bottom: 93px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    height: 100vh;
    background-position: ${({ subject }) =>
      subjectStyles[subject].bgMobileSPositionResponsive ??
      fallbackStyle.bgPosition};
  }
`;

export const Main = styled.div`
  min-height: calc(100vh - 59px);
`;
