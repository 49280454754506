import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_CHAMPIONCLASS_API_DOMAIN;

// get months
export const fetchChampionClassMonth = () => {
  return fetch(`${baseUrl}Leaderboard/ChampionClass/GetMonths`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get champion class month failed'); // eslint-disable-line
    });
};

// get champion class data by term
export const fetchClassByTerm = ({ enumCCFilter, term, level }) => {
  return fetch(
    `${baseUrl}Leaderboard/ChampionClass/GetChampionClassDataByTerm/${enumCCFilter}/${term}/${level}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get class by term failed'); // eslint-disable-line
    });
};

// get students by class and term
export const fetchStudentByClassAndTerm = ({ classId, termId }) => {
  return fetch(
    `${baseUrl}Leaderboard/ChampionClass/GetStudentsByClassAndTerm/${classId}/${termId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get student by class and term failed'); // eslint-disable-line
    });
};

// get class by month
export const fetchClassByMonth = ({ enumCCFilter, year, month, level }) => {
  return fetch(
    `${baseUrl}Leaderboard/ChampionClass/GetChampionClassDataByMonth/${enumCCFilter}/${year}/${month}/${level}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get class by month failed'); // eslint-disable-line
    });
};

// get students by class and month
export const fetchStudentsByClassAndMonth = ({ classId, year, month }) => {
  return fetch(
    `${baseUrl}Leaderboard/ChampionClass/GetStudentsByClassAndDate/${classId}/${year}/${month}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get student by class and month failed'); // eslint-disable-line
    });
};
