import styled from 'styled-components';
import Bg from 'assets/img/loading-page-bg.svg';

export const Content = styled.div`
  margin-top: ${(props) =>
    (props.plan !== 'school' ||
      process.env.REACT_APP_SWITCH_PORTAL_BANNER !== 'true') &&
    '59px'};
  ::before {
    content: '';
    background-repeat: ${(props) =>
      props.plan === 'premium' ? 'repeat' : 'space'};
    background-image: ${(props) => props.theme[props.plan].bgPattern};
    background-size: ${(props) => props.theme[props.plan].bgSize};
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: ${(props) => (props.plan === 'premium' ? 'none' : '0.34')};
    z-index: 0;
  }
  ::after {
    content: '';
    z-index: 1;
    background-image: url(${({ globeImg }) => globeImg});
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-attachment: scroll;
    background-size: calc(100vw * 1.24) calc(100vw * 1.24);
    position: absolute;
    top: 0;
    right: 0;
    bottom: calc(100vw * 1.24 * 0.78306979 * -1);
    left: 0;
  }
  background: ${(props) => props.theme[props.plan].bgColor};
  height: ${(props) =>
    props.plan === 'school' &&
    process.env.REACT_APP_SWITCH_PORTAL_BANNER === 'true'
      ? 'calc(100vh - 99px)'
      : 'calc(100vh - 59px)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-height: 540px) and (min-width: ${({ theme }) =>
      theme.rwd.tablet + 1}px) {
    overflow-y: auto;
    ::after {
      bottom: 0;
      background-position-y: 0px;
      height: 100vh;
      position: fixed;
    }
  }
`;

export const Page = styled.div`
  overflow: hidden;
  background: url(${Bg});
  background-repeat: no-repeat;
  background-size: cover;
  visibility: visible;
`;

export const Main = styled.div`
  padding: 2.5rem 205px 2rem 205px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  @media (min-width: 2560px) {
    padding: 10rem 300px 9.5rem 312px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 2rem 3rem;
    overflow-y: auto;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0;
  }
  @media (max-height: 700px) and (min-width: ${({ theme }) =>
      theme.rwd.tablet + 1}px) {
    scale: 0.8;
    transform-origin: center top;
  }
  @media (min-height: 800px) and (min-width: 1700px) {
    scale: 1.1;
    transform-origin: center top;
  }
`;

export const CardList = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-evenly;
  width: 800px;
  @media (min-width: 1700px) {
    width: 1100px;
  }
  @media (max-width: 1150px) {
    width: 743px;
    justify-content: space-between;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    gap: 1%;
    grid-template-columns: auto auto;

    & :nth-child(2) {
      grid-column-start: 1;
    }

    & :last-child {
      grid-column-start: 2;
      grid-row: 1/3;
    }
  }
`;

export const CardShowWarp = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 94%;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 88%;
  }
`;
