import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import storyMathBg from 'assets/img/dashboardv2/story-math.png';
import storyMathTablet from 'assets/img/dashboardv2/storyMathTablet.png';
import storyScienceBg from 'assets/img/dashboardv2/story-science.png';
import storyScienceTablet from 'assets/img/dashboardv2/storyScienceTablet.png';
import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import { StyledContainer } from './StoryCard.styles';

const BG = {
  math: storyMathBg,
  science: storyScienceBg,
};

const BGTablet = {
  math: storyMathTablet,
  science: storyScienceTablet,
};

const StoryImage = ({ subject }) => {
  const imgSrc = useResponsiveImage(BG[subject], BGTablet[subject]);
  return <img src={imgSrc} alt={subject} />;
};

const StoryCard = () => {
  const { subject } = useSelector((state) => state.plan);
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const navigateToStory = () => {
    history.push(`/story/${subject}`);
  };
  const NAME = {
    math: t('story.math', 'StoryMath'),
    science: t('story.science', 'StoryScience'),
  };
  return (
    <StyledContainer onClick={navigateToStory} data-cy="story-card">
      <div>
        <StoryImage subject={subject} />
        <h3>{NAME[subject]}</h3>
      </div>
    </StyledContainer>
  );
};

export default StoryCard;
