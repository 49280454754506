import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    font-size: ${({ fontSize }) => fontSize || '32px'};
    font-weight: bold;
    color: ${({ color }) => color || '#ff7700'};
    font-family: ${({ fontFamily }) => fontFamily || 'Rockwell'};
    -webkit-text-fill-color: ${({ borderColor }) => borderColor || 'none'};
    -webkit-text-stroke: thin;
  }
  .stroke {
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: ${({ strokeWidth }) => strokeWidth || '1rem'};
    color: #ffffff;
    filter: ${({ textShadow }) =>
      textShadow || 'drop-shadow(3px 2px 2px rgba(0,0,0,0.16))'};
  }
  .fill {
    position: relative;
    margin-top: ${({ margin }) => margin || '-2.8rem'};
  }
`;
