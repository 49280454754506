import React from 'react';
import styled from 'styled-components';
import Button from 'components/Shared/Button';

export const Page = styled.div`
  background: ${({ bg }) => `url(${bg})`} no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  padding: 2rem 2rem 0 2rem;
  text-align: center;
  img {
    height: 27px;
  }
  .title {
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  .desc {
    margin-bottom: 0;
  }
  .children-mascot {
    position: absolute;
    height: 40%;
    left: 3rem;
    margin-top: 7rem;
  }
  @media (max-width: 1441px) {
    .children-mascot {
      height: 40%;
      left: 3rem;
      margin-top: 5rem;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .children-mascot {
      height: 40%;
      left: 1rem;
      margin-top: 5rem;
    }
  }
  @media (max-width: 1175px) {
    .children-mascot {
      height: 30%;
      margin-top: 6rem;
      left: 0.8rem;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .children-mascot {
      height: 25%;
      margin-top: 6.5rem;
    }
  }
  @media (max-width: 955px) {
    .children-mascot {
      display: none;
    }
  }
`;

export const Locked = styled.div`
  text-align: center;
  margin-left: 4rem;
  .lock-icon {
    height: 60px;
  }
  .expired-img {
    width: 675px;
    height: 375px;
    margin: 1rem 0 2rem;
  }
  .message {
    position: absolute;
    margin-top: 9.5rem;
    margin-left: 15.5rem;
    p {
      margin-top: 0.5rem;
      font-weight: 600;
      color: #ffffff;
      font-size: 1.25rem;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  position: absolute;
  top: 7rem;
  left: 1.875rem;
`;

export const StyledBuyNowButton = styled((props) => <Button {...props} />)`
  display: flex;
  flex-direction: column;
  span {
    margin: 0;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
`;
