import styled from 'styled-components';
import MainBg from 'assets/img/assignment/assignment-view/content-bg.svg';

export const Container = styled.div`
  display: flex;
`;

export const Main = styled.div`
  background: url(${MainBg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  .wrapper {
    display: flex;
    justify-content: center;
  }
  .summary-box {
    height: fit-content;
    background: #ffffff;
    border: 1px solid #fdad0c;
    border-radius: 8px;
    box-shadow: 10px 10px 0px 0px #fdad0c;
    width: 70%;
    margin-top: 1.875rem;
    padding: 0.2rem 0rem 0rem 0.3rem;
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  @media (max-width: 602px) {
    .summary-box {
      width: 90%;
    }
  }

  @media (max-width: 502px) {
    .top-section {
      height: 7rem;
      padding-top: 1.5rem;

      p {
        font-size: 18px;
      }
    }

    .bottom-section {
      .info-text {
        font-size: 16px;
      }
      .release-date {
        font-size: 22px;
      }
      .hint-text {
        font-size: 14px;
      }
    }
  }
`;

export const AssignerSection = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-left: 14%;
`;
