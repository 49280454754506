import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertWrapper,
  AlertHeader,
  AlertHeaderTitle,
  AlertContent,
  AlertMessage,
  AlertAction,
  ActionButton,
  ActionIcon,
  ActionText,
} from './ConfirmModal.styles';

const ConfirmModal = ({ header, message, onAccept, onReject }) => {
  const { t } = useTranslation(['common']);
  return (
    <AlertWrapper>
      <AlertHeader>
        <AlertHeaderTitle>{header}</AlertHeaderTitle>
      </AlertHeader>
      <AlertContent>
        <AlertMessage>{message}</AlertMessage>
      </AlertContent>
      <AlertAction>
        <ActionButton color="red" onClick={onReject}>
          <ActionIcon className="d-none d-md-inline d-xl-inline">×</ActionIcon>
          <ActionText> {t('common:no', 'No')} </ActionText>
        </ActionButton>
        <ActionButton onClick={onAccept}>
          <ActionIcon className="d-none d-md-inline d-xl-inline">✓</ActionIcon>
          <ActionText> {t('common:yes', 'Yes')}</ActionText>
        </ActionButton>
      </AlertAction>
    </AlertWrapper>
  );
};

export default React.memo(ConfirmModal);
