import styled from 'styled-components';

const ContainerScience = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  p {
    font-size: 1.375rem;
    color: white;
    margin: 0;
    font-weight: bold;
    text-shadow: 0 2px 2px #10ba92;
    .highlighted {
      color: #ffde00;
    }
  }
  .skills-no {
    font-size: 0.875rem;
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    p {
      font-size: 16px;
      .carry-on {
        display: none;
      }
    }
  }
`;

export default ContainerScience;
