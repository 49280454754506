import styled from 'styled-components';
import MainBg from 'assets/img/doodle-bg.svg';

export const Container = styled.div`
  background: #217a92;
  min-height: 100vh;
`;

export const Main = styled.div`
  background: url(${MainBg});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  width: 100vw;

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-top: 68px;
  }
`;

export const HideOnTabletBelow = styled.div`
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: none;
  }
`;
