import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Shared/Button';
import Failed from 'assets/img/expired/failed-tick.svg';
import Mascot from 'assets/img/expired/expired-mascot.svg';
import { PaymentContainer, PaymentHeader } from './PaymentFailed.styles';

const PaymentFailed = ({ payAgain }) => {
  const { t } = useTranslation(['expired']);
  return (
    <PaymentContainer>
      <PaymentHeader>
        <img src={Failed} alt="failed" />
        <div className="title-container">
          <h5>{t('expired:paymentFailed.title', 'Your Payment Failed')}</h5>
          <div className="msg">
            {t('expired:paymentFailed.tryAgain', 'Please try again')}
          </div>
        </div>
      </PaymentHeader>
      <Button variant="primary" width="230px" onClick={payAgain}>
        {t('expired:paymentFailed.payAgain', 'Pay Again')}
      </Button>
      <div>
        {t('expired:paymentFailed.pleaseEmail', 'Please email to')}{' '}
        <span className="support">support@koobits.com</span>{' '}
        {t('expired:paymentFailed.anyProblem', 'if you have any problems.')}
      </div>
      <img src={Mascot} alt="mascot" className="expired-mascot" />
    </PaymentContainer>
  );
};

export default PaymentFailed;
