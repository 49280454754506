import styled from 'styled-components';

export const Container = styled.div`
  background-color: #3896ff;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 59px;
  width: 100%;
  z-index: 10;
  .kooquiz {
    font-size: 28px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0px;
    position: absolute;
    left: 12%;
    img {
      position: absolute;
      height: 155%;
      left: 83%;
      z-index: -1;
      top: -28%;
    }
  }
  @media (min-width: 1400px) {
    .kooquiz {
      left: 14%;
    }
  }
`;

export const KokoCredit = styled.div`
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  position: absolute;
  right: 5%;
  display: flex;
  align-items: center;
`;
