import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, subjectStyles } from './HallOfFameCard.styles';

const HallOfFameCard = ({
  level,
  nameOfClass,
  studentName,
  schoolName,
  schoolLogo,
  cp,
  submissionDate,
  subjectID,
}) => {
  const { t } = useTranslation(['leaderboard']);
  return (
    <Container levelId={level} data-cy="hof-card">
      <div className="header">
        {t(
          'leaderboard:buttons.primaryHero',
          { number: level },
          `Primary ${level} Hero`
        )}
      </div>
      <div className="content">
        <p className="student-name">{studentName}</p>
        <div className="school">
          {schoolLogo && (
            <img className="school-logo" src={schoolLogo} alt="school logo" />
          )}
          <div className="school-details">
            <p className="school-name">{schoolName}</p>
            <p>{nameOfClass}</p>
          </div>
        </div>
      </div>
      <div className="footer">
        <p className="cp">
          <img
            className="cp-icon"
            src={subjectStyles[subjectID]}
            alt="cp"
            width="30px"
          />
          {cp}
        </p>
        <p className="date">{submissionDate}</p>
      </div>
    </Container>
  );
};

export default HallOfFameCard;
