export const MULTI_PLAYER_LIST = [
  {
    ChallengeId: 1,
    SubjectId: 1,
    Name: 'Peer Challenge',
    Description: '6am to 10pm Daily',
    Type: 1,
    PCNumOfRemainingChallenges: 3,
    PCNumOfIncomingChallenges: 0,
    PCRecentOpponents: [],
  },
  {
    ChallengeId: 2,
    SubjectId: 2,
    Name: 'Peer Challenge',
    Description: '6am to 10pm Daily',
    Type: 1,
    PCNumOfRemainingChallenges: 1,
    PCNumOfIncomingChallenges: 0,
    PCRecentOpponents: [],
  },
];
