import React from 'react';
import SuperVisionBanner from 'assets/img/superhero/supervision-banner.png';
import BackIcon from 'assets/img/assignment/back-icon.svg';
import { useTranslation } from 'react-i18next';
import { Container, BackBtn } from './SuperHeroBanner.styles';

const SuperHeroBanner = ({
  backDestination = '/dashboard',
  gradientType = 'orange',
  banner = SuperVisionBanner,
  bgColor,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Container gradientType={gradientType}>
      <BackBtn to={backDestination} replace bgColor={bgColor}>
        <img src={BackIcon} alt="" />
        <span>{t('common:back', 'Back')}</span>
      </BackBtn>
      <img src={banner} height="73px" alt="Super Hero Challenge" />
    </Container>
  );
};

export default SuperHeroBanner;
