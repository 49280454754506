import ProficiencyTab from 'assets/img/report/tab/icon-proficiency.svg';
import HighScoreTab from 'assets/img/report/tab/icon-highscore.svg';
// Component States
export const TABS_ITEM = [
  {
    id: 1,
    name: 'Proficiency Report',
    icon: ProficiencyTab,
    key: 'proficiency',
    translationKey: 'proficiencyReport',
    path: '/report/proficiency',
    toolTipText: 'Data from all KooBits activities',
    toolTipTranslationKey: 'proficiencyTooltip',
  },
  {
    id: 2,
    name: 'High Score Report',
    icon: HighScoreTab,
    key: 'highscore',
    translationKey: 'highScoreReport',
    path: '/report/highscore',
    toolTipText: 'High score out of 10 Qns',
    toolTipTranslationKey: 'highScoreTooltip',
  },
];
