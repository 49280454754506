import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/champion-class/champion-header-bg.svg';
import ClassicBanner from 'assets/img/leaderboard/champion-class/classic-banner.svg';

import {
  Topbar as ChampionClassTopBar,
  Terms as ChampionClassTerm,
  BrainHeading as ChampionClassBrainHeading,
} from '../TopBrain/TopBrainHeader.styles';

export const Topbar = styled(ChampionClassTopBar)`
  .left-kid {
    margin-left: 5%;
  }
  .right-kid {
    position: absolute;
    right: -3%;
    bottom: 40px;
    pointer-events: none;
  }
  .scoring-period {
    flex-direction: column;
    span,
    .physical-prize {
      color: #374b09;
      font-size: 16px;
    }
  }
  .curriculum-date {
    align-self: flex-end;
    z-index: 1;
    margin-right: 10%;
  }
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position-y: bottom;
`;
export const ButtonContainer = styled.div`
  position: absolute;
  left: 2%;
`;
export const BrainHeading = styled(ChampionClassBrainHeading)`
  white-space: nowrap;
`;
export const Terms = styled(ChampionClassTerm)`
  background: #ffffff;
  z-index: 1;
  div {
    background: #fff47e;
    border-color: #ddc900;
    color: #93891b;
  }
  .active-term {
    border: none;
    background: #76e0af;
    color: #368b64;
    box-shadow: inset 1px 1px 0 1px #31c983;
    &:after {
      content: '';
      border-color: #36a371 transparent transparent transparent;
    }
  }
`;

export const ClassicStyle = styled.div`
  position: relative;
  width: 80%;
  z-index: 1;
  .classic-banner {
    position: absolute;
    background-image: url(${ClassicBanner});
    background-repeat: no-repeat;
    width: 100%;
    text-align: center;
    background-size: 100% 112%;
    color: #ffffff;
    top: -22px;
    font-size: 23px;
    font-weight: bold;
  }
  .classic-body {
    background: #ffffff;
    display: flex;
    padding: 20px;
    border-radius: 15px;
    justify-content: space-between;
    align-items: center;
  }
  .front-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 20%;
  }
  .star-class {
    color: #ff7f00;
    font-size: 17px;
    font-weight: bold;
  }
  .start-cps {
    font-size: 12px;
    span {
      font-weight: bold;
      font-size: 14px;
    }
  }
  .middle-container {
    width: 55%;
    text-align: center;
    .progressing {
      font-size: 16px;
      .class-name {
        font-weight: 600;
        margin-right: 0.5rem;
      }
      .total-cp {
        font-size: 23px;
        font-weight: 600;
      }
      .cps {
        font-size: 12px;
      }
    }
    .progress-bar {
    }
  }
  .end-container {
    width: 15%;
  }
  .gold-class {
    color: #fcbb0a;
    font-size: 17px;
    font-weight: bold;
  }
  .total-cps {
    font-size: 12px;
    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

export const ProgressPercent = styled.div`
  background-color: #d9d5a6;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 20px;
  height: 20px;
  .current-progress {
    width: ${(props) => props.width}%;
    transition: all 200ms ease;
    background: #ff6c00;
    height: 100%;
    border-radius: 20px;
  }
`;
