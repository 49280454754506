import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/wizard-school/wizard-school.svg';

import {
  Topbar as ChampionClassTopBar,
  // BrainHeading as BrainHeadingBff,
} from '../TopBrain/TopBrainHeader.styles';

export const Topbar = styled(ChampionClassTopBar)`
  .scoring-period {
    flex-direction: column;
    span,
    .physical-prize {
      color: #374b09;
      font-size: 16px;
    }
  }
  background-image: url(${HeaderBg});
  background-size: cover;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 4%;
`;

export const Container = styled.div`
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  flex-direction: column;
  img {
    margin-left: 30px;
  }
`;

// export const BrainHeading = styled(BrainHeadingBff)`
//   width: 40%;
//   align-self: flex-end;
//   color: #ffffff;
//   text-shadow: 4px 5px #0090b2;
//   -webkit-text-stroke-width: 0;
// `;

export const BrainHeading = styled.h1`
  width: 30%;
  align-self: flex-end;
  color: #ffce00;
  font-size: 62px;
  font-weight: 500;
  font-family: 'Grilled Cheese';
  position: relative;
  z-index: 1;
  &::before {
    content: ${({ content }) => `"${content}"`};
    position: absolute;
    -webkit-text-stroke: 6px #ffffff;
    left: 0;
    top: 0;
    z-index: -1;
  }
`;
