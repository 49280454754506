import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: 12px;
  align-items: center;
  width: 100%;
  height: 300px;
  background: #fff;
`;

export const Content = styled.p`
  text-align: center;
  line-height: 1.5;
  color: #353535;
  background-color: #ebf9ff;
  padding: 1em 2em;
  margin: 1em 2em;
`;
