import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  padding-top: 102px;
  padding-bottom: 48px;
  padding-right: calc(0.02307137707 * 100vw);
  padding-left: calc(0.096819033886 * 100vw);
  height: 100%;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 30px 0 0 0;
    flex-direction: column-reverse;
    width: 502px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 90%;
  }
`;
export const StyledCardContainer = styled.div`
  width: 77%;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
  }
`;
StyledCardContainer.Bottom = styled.div`
  display: flex;
  margin-top: calc(0.021629416 * 100vw);
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    flex-direction: column;
    margin-top: 0px;
  }
`;
export const StyledRewardsSection = styled.div`
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: auto;
    padding-top: auto;
    justify-content: end;
    margin-bottom: 10px;
  }
`;
