import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Table } from '../GlobalTop100Table/GlobalTop100Table.styles';

const CPSubmittedTable = ({ color, teamLatestCPSubmittedList }) => {
  const { t } = useTranslation(['leaderboard']);
  return (
    <Table color={color}>
      <thead>
        <tr>
          <th>{t('leaderboard:boysVsGirls.ranking.name', 'Name')}</th>
          <th>{t('leaderboard:boysVsGirls.ranking.cp', 'CP')}</th>
          <th>
            {t('leaderboard:boysVsGirls.ranking.submitTime', 'Submitted Time')}
          </th>
        </tr>
      </thead>
      <tbody>
        {teamLatestCPSubmittedList.map(
          ({ name, cP, submittedDateTime }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index}>
              <td width="50%">{name}</td>
              <td width="20%">{cP}</td>
              <td width="30%">
                {moment(submittedDateTime).format('DD-MMM-YYYY HH:mm')}
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};

export default CPSubmittedTable;
