import styled from 'styled-components';

export const Title = styled.p`
  font-size: 1.5625rem;
  font-weight: 700;
  color: white;
`;

export const Instructions = styled.p`
  font-size: 0.875rem;
  color: white;
`;

export const DropdownsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 350px;
  margin: 0 auto;
`;

export const ZodiacInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px dashed rgba(255, 255, 255, 0.22);
  padding-top: 1rem;
  margin-top: 0.5rem;
  button {
    margin-left: 3rem;
  }
  margin-bottom: 1rem;
`;

export const ZodiacDetails = styled.span`
  display: flex;
  color: white;
  .text {
    margin-left: 1rem;
    text-align: left;
    p {
      margin: 0;
    }
  }
  .dates {
    font-size: 0.75rem;
    font-weight: 700;
  }
  .name {
    font-size: 1rem;
    font-weight: 700;
  }
  .desc {
    font-size: 0.75rem;
  }
`;

export const ErrorMessage = styled.p`
  margin: 1rem 0 0 0;
  color: pink;
`;
