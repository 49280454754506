/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import WizardSchoolHeader from 'components/Leaderboard/WizardSchool/WizardSchoolHeader';
import StudentList from 'components/Leaderboard/StudentList';
import {
  getSchools,
  getStudentsList,
} from 'store/wizardSchool/wizardSchoolSlice';
import { isEmpty } from 'ramda';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';
import { checkIfChallengeOpen, leaderboardList } from 'constants/leaderboard';
import { Redirect } from 'react-router-dom';
import { Container } from './ZodiacRace.styles';
import { CategoryType, CategoryStyle } from './WizardSchool.styles';
import { NoParticipant } from './TopBrain.styles';

const WizardSchool = () => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { isLoading, groupsData } = useSelector((state) => state.wizardSchool);

  const categories = [
    {
      name: t('leaderboard:tabs.myCountry', 'My Country'),
      value: 3,
      className: 'my-country',
    },
    {
      name: t('leaderboard:tabs.global', 'Global'),
      value: 4,
      className: 'global',
    },
  ];

  const [showAllStudents, setShowAllStudents] = useState({});
  const [active, setActive] = useState(categories[0].value);

  const isOpen = checkIfChallengeOpen(
    leaderboardList.math[7].startDateTime,
    leaderboardList.math[7].endDateTime
  );

  useEffect(() => {
    dispatch(getSchools({ enumFilter: active }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const toggleAllStudent = (index, seasonId, schoolId) => {
    const tempData = { ...showAllStudents };
    tempData[index] = !tempData[index];
    setShowAllStudents(tempData);
    if (tempData[index]) dispatch(getStudentsList({ seasonId, schoolId }));
  };

  const toggleStudentType = (val) => {
    setActive(val);
    dispatch(getSchools({ enumFilter: val }));
    // const level = val === 1 ? defaultLevel : selectedLevel;
    // if (val === 1) setSelectedlevel(defaultLevel);
    // if (val === 1 && challengeMode === 'championClass') setToggle(true);
    // if (val !== 1 && challengeMode === 'championClass') setToggle(false);
    // getLists(val, level);
    // if (val === 1 && challengeMode === 'championClass') callStudents(groupId);
  };

  if (!isOpen) return <Redirect to="/leaderboard?subject=math" />;
  return (
    <Container>
      <WizardSchoolHeader />
      <CategoryStyle classCategory={active}>
        <div className="categories-container">
          {categories.map((item) => (
            <CategoryType
              className={item.className}
              active={active === item.value}
              key={item.value}
              onClick={() => {
                toggleStudentType(item.value);
                setShowAllStudents({});
                //   if (challengeMode === 'championClass') setShowAllStudents({});
              }}
            >
              {item.name}
            </CategoryType>
          ))}
        </div>
        <div className="right-container">
          {!isOpen && (
            <span className="competition-closed">
              {t(
                'leaderboard:wizardSchool.competitionClosed',
                'The Wizard School competition has closed. See you next year!'
              )}
            </span>
          )}
        </div>
        {!isEmpty(groupsData) && active === 3 && (
          <div className="right-container">
            <img height="20px" alt="sg-flag" src={groupsData[0].CountryLogo} />
            <div className="country-name">
              {capitalizeFirstLetter(groupsData[0].CountryName)}
            </div>
          </div>
        )}
      </CategoryStyle>

      <StudentList
        challengeMode="wizardSchool"
        reducer="wizardSchool"
        active={active}
        data={groupsData}
        isTopFifty
        isLoading={isLoading}
        showAllStudents={showAllStudents}
        toggleAllStudent={toggleAllStudent}
      />
      {!isLoading && groupsData.length === 0 && (
        <NoParticipant>
          {t(
            'leaderboard:hodBanner.noParticipant',
            'No participants at the moment.'
          )}
        </NoParticipant>
      )}
    </Container>
  );
};

export default WizardSchool;
