import styled from 'styled-components';
import Locked from 'assets/img/storymath/locked.png';

export const Container = styled.a`
  width: 25%;
  display: block;
  background-color: transparent;
  box-sizing: inherit;
  cursor: pointer;
  text-decoration: underline;

  &.devider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    :before {
      margin-top: 22px;
      content: '';
      height: 100vh;
      width: 1px;
    }
  }
`;

export const ImageFrame = styled.div`
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: ${(props) => (props.type === 'top' ? 'center' : 'flex-start')};
  ${(props) =>
    props.type === 'top' ? 'margin-bottom:25px' : 'margin-top:25px'};
  -webkit-box-align: start;
  position: relative;
  box-sizing: inherit;
  :before {
    content: '';
    display: block;
    position: absolute;
    ${(props) => (props.type === 'top' ? 'bottom:-31px' : 'top:-31px')};
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 31px;
    background-color: #ffffff;
    z-index: 1;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    ${(props) => (props.type === 'top' ? 'bottom:-31px' : 'top: -31px')};
    left: 50%;
    transform: translateX(-50%)
      translateY(${(props) => (props.type === 'top' ? '50%' : '-50%')});
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    z-index: 1;
  }
`;
export const ImageWrapper = styled.div`
  position: relative;
  max-width: 100%;
  font-size: 0;
  :after {
    border-color: #ffffff;
  }
  &.Locked {
    :after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(${Locked});
      background-position: center;
      border: 10px solid #fff;
      border-radius: 22px;
      background-size: 105%;
    }
  }
`;
export const Image = styled.img`
  width: 100%;
  border: 10px solid #fff;
  border-radius: 22px;
  z-index: 1;
`;
