import styled from 'styled-components';

export const Container = styled.div`
  width: 73%;
  align-self: center;
  .content-container {
    display: flex;
    justify-content: center;
    padding-top: 0%;

    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 1px solid #8dcf44;
      border-radius: 8px;
      box-shadow: 5px 5px 0px 0px #8dcf44;
      width: 560px;
      min-height: 15rem;
      padding: 0.5rem 2rem 2rem;
      border-radius: 0.5rem;
      text-align: center;
      .text-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        .success-text {
          color: #404447;
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }
`;
