import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const ButtonContainer = styled.div`
  display: flex;
  width: 320px;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0px 20px;
  flex-direction: row;
  align-items: center;
`;

export const Line = styled.div`
  height: 2px;
  width: 100px;
  flex-grow: 1;
  background-color: #dff3f3;
`;

export const RoundButton = styled.button`
  background: ${({ icon }) => `url('${icon}') no-repeat `};
  border-radius: 56px;
  height: 56px;
  width: 56px;
  padding: 5px;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #d1e0e8;
  &.selected,
  &:hover {
    border-color: #42c5ca;
    background-color: #03d1e4;
  }
  &.class {
    background-position: -2px 1px;
    &:hover,
    &.selected {
      background-position: -2px -54px;
    }
  }
  &.school {
    background-position: -82px 1px;
    &:hover,
    &.selected {
      background-position: -82px -54px;
    }
  }
  &.country {
    background-position: -163px 1px;
    &:hover,
    &.selected {
      background-position: -163px -54px;
    }
  }
`;

export const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
`;
