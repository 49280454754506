import { AvatarPhoto } from 'components/Dashboard/Dashboardv2/UserInfo.styles';
import { $colors } from 'constants/dashboardv2';
import styled from 'styled-components';

export const NavProfileContainerMobile = styled.div`
  display: flex;
  min-height: 59px;
  align-items: center;
`;

export const MobileHeaderAvatar = styled.div`
  height: 100%;
  border: 3px solid #fff;
  margin-left: 4px;
  border-radius: 100%;
`;

export const MobileHeaderAvatarPhoto = styled(AvatarPhoto)`
  width: 32px;
  height: 32px;
`;

export const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 58vw;
  background-color: #404447;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const MenuTopContent = styled.div`
  background: ${(p) => $colors.dashboard[p.subject]?.tertiary};
  padding-top: 46px;
  height: 45vh;
  width: 100%;
`;

export const MenuBottomContent = styled.div`
  padding-bottom: 46px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
`;

export const CloseIcon = styled.button`
  height: 12px;
  width: 12px;
  position: absolute;
  left: 16px;
  top: 24px;
  border: none;
  background: transparent;
  & img {
    height: 12px;
    width: 12px;
  }
`;

export const LogoutButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  margin: 0px auto;
  width: 110px;
  & span {
    flex-grow: 1;
    text-align: right;
    color: #fff;
  }
`;
