import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  .title {
    font-size: 1.125rem;
    font-weight: 700;
  }
  .subtitle {
    font-size: 0.875rem;
    span {
      font-weight: 600;
      color: ${(props) => (props.plan === 'premium' ? '#41b9ff' : '#FFCC00')};
    }
  }
`;

export const PlansContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  .arrow-icon {
    margin: 0 0.5rem;
    -webkit-transform: ${(props) =>
      props.plan === 'school' ? 'scaleX(-1)' : null};
    transform: ${(props) => (props.plan === 'school' ? 'scaleX(-1)' : null)};
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 153px;
    height: 79px;
    border-radius: 13px;
    img {
      width: 121px;
      height: 36px;
    }
  }
  .selected {
    border: solid 2px #ff6701;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
