import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import TopBrainHeader from 'components/Leaderboard/TopBrain/Science/TopBrainHeader';
import Category from 'components/Leaderboard/Category';
import StudentList from 'components/Leaderboard/StudentList';
import { isEmpty } from 'ramda';
import {
  getTopBrainTerms,
  getTopBrainByTerm,
  getTopFiftyTopBrainByTerm,
} from 'store/topBrain/topBrainSlice';
import { Container, NoParticipant } from './TopBrain.styles';

const subject = 2;

const ScienceTopBrain = () => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const {
    isLoading,
    topBrainTerms,
    topBrainData,
    // topBrainSeasons,
  } = useSelector((state) => state.topBrain);
  const { userID } = useSelector((state) => state.login);
  const { defaultLevel, country } = useSelector(
    (state) => state.studentDetails
  );
  // get current season & year
  // const sortedData = Object.values(topBrainSeasons)
  //   .sort(compareValues('StartDate', 'desc'))
  //   .filter((data) => moment().isSameOrAfter(data.StartDate));
  // const currentSeason = sortedData[0] && sortedData[0].ID;
  // const currentYear = sortedData[0] && sortedData[0].Year;

  const singaporeSchool = country === 'Singapore';

  const [selectedLevel, setSelectedlevel] = useState(defaultLevel);
  const [activeTerm, setActiveTerm] = useState('term');
  const [activeCategory, setActiveCategory] = useState(3);
  // const [activeSeason, setActiveSeason] = useState(currentSeason);
  const [currentTerm, setCurrentTerm] = useState({});
  // const [year, setYear] = useState(currentYear);
  const [studentData, setStudentData] = useState([]);
  const [isTopFifty, setIsTopFifty] = useState(false);

  useEffect(() => {
    if (!singaporeSchool) setActiveTerm('classic');
  }, [singaporeSchool]);

  useEffect(() => {
    if (defaultLevel === null) {
      setSelectedlevel(1);
    }
  }, [defaultLevel]);

  useEffect(() => {
    dispatch(getTopBrainTerms());
  }, [dispatch]);

  // useEffect(() => {
  //   setActiveSeason(currentSeason);
  // }, []);

  // useEffect(() => {
  //   setYear(currentYear);
  // }, []);

  const getTermsData = (
    term,
    level = defaultLevel,
    enumTopBrainFilter = activeCategory
  ) => {
    const params = {
      enumTopBrainFilter,
      term,
      level,
      subject,
    };
    dispatch(getTopBrainByTerm(params));
  };

  useEffect(() => {
    if (activeTerm === 'term') {
      if (!isEmpty(topBrainTerms)) {
        for (let i = 0; i < topBrainTerms.length; i++) {
          if (
            moment().isSameOrAfter(topBrainTerms[i].TermStartDate) &&
            moment().isSameOrBefore(topBrainTerms[i].TermEndDate)
          ) {
            setCurrentTerm(topBrainTerms[i]);
            getTermsData(topBrainTerms[i].TermId);
            break;
          } else if (
            i < topBrainTerms.length - 1 &&
            moment().isBetween(
              topBrainTerms[i].TermEndDate,
              topBrainTerms[i + 1].TermStartDate
            )
          ) {
            setCurrentTerm(topBrainTerms[i]);
            getTermsData(topBrainTerms[i].TermId);
            break;
          } else if (i === topBrainTerms.length - 1) {
            setCurrentTerm(topBrainTerms[i]);
            getTermsData(topBrainTerms[i].TermId);
            break;
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [topBrainTerms, activeTerm]);

  // useEffect(() => {
  //   if (activeTerm === 'classic') {
  //     dispatch(
  //       getTopBrainBySeason({
  //         enumTopBrainFilter: activeCategory,
  //         season: activeSeason,
  //         year,
  //         level: selectedLevel,
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [activeTerm, year, activeSeason]);

  useEffect(() => {
    if (!isEmpty(topBrainData)) {
      const isUser = topBrainData.find((stu) => stu.UserId === userID);
      if (isUser) {
        setIsTopFifty(isUser.LeaderBoardRank <= 50);
      } else {
        setIsTopFifty(true);
      }
      setStudentData(topBrainData);
    } else {
      setStudentData([]);
    }
    // eslint-disable-next-line
  }, [topBrainData]);

  const getStudents = (active, level) => {
    setIsTopFifty(false);
    if (activeTerm === 'term') {
      getTermsData(currentTerm.TermId, level, active);
    }
    // if (activeTerm === 'classic') {
    //   dispatch(
    //     getTopBrainBySeason({
    //       enumTopBrainFilter: active,
    //       season: activeSeason,
    //       year,
    //       level,
    //     })
    //   );
    // }
  };

  const getTopFiftyStudents = () => {
    setIsTopFifty(false);
    if (activeTerm === 'term') {
      dispatch(
        getTopFiftyTopBrainByTerm({
          enumTopBrainFilter: activeCategory,
          term: currentTerm.TermId,
          level: selectedLevel,
          subject,
        })
      );
    }
    // if (activeTerm === 'classic') {
    //   dispatch(
    //     getTopFiftyTopBrainBySeason({
    //       enumTopBrainFilter: activeCategory,
    //       season: activeSeason,
    //       year,
    //       level: selectedLevel,
    //     })
    //   );
    // }
  };

  const changeCurrentTerm = (id) => {
    setSelectedlevel(defaultLevel);
    setCurrentTerm(topBrainTerms.find((e) => e.TermId === id));
    getTermsData(id);
  };

  return (
    <Container>
      <TopBrainHeader
        activeTerm={activeTerm}
        setActiveTerm={setActiveTerm}
        changeCurrentTerm={changeCurrentTerm}
        currentTerm={currentTerm}
      />
      <Category
        showTopFifty
        getLists={getStudents}
        getTopFiftyLists={getTopFiftyStudents}
        active={activeCategory}
        setActive={setActiveCategory}
        selectedLevel={selectedLevel}
        setSelectedlevel={setSelectedlevel}
        subject={2}
      />
      {studentData.length > 0 && (
        <StudentList
          challengeMode="topBrain"
          subject={2}
          reducer="topBrain"
          data={studentData}
          isTopFifty={isTopFifty}
          active={activeCategory}
          setActive={setActiveCategory}
          isLoading={isLoading}
        />
      )}
      {!isLoading && studentData.length === 0 && (
        <NoParticipant>
          {t(
            'leaderboard:hodBanner.noParticipant',
            `No participants at the moment.`
          )}
        </NoParticipant>
      )}
    </Container>
  );
};

export default ScienceTopBrain;
