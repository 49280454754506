import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import kooQuizBg from 'assets/img/dashboardv2/kooquiz.png';
import kooQuizBgTablet from 'assets/img/dashboardv2/quizTablet.png';
import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import {
  StyledContainer,
  StyledContent,
  StyledPremiumOnly,
} from './KooQuizCard.styles';
import PremiumOnlyIcon from './PremiumOnlyIcon';

const QuizImage = () => {
  const imgSrc = useResponsiveImage(kooQuizBg, kooQuizBgTablet, kooQuizBg);
  return <img src={imgSrc} alt="kooQuiz" />;
};

const KooQuizCard = () => {
  const history = useHistory();
  const { t } = useTranslation(['dashboardCards']);
  const { currentProduct, subject } = useSelector((state) => state.plan);
  const findKooQuizFeature = currentProduct?.features.find(
    (item) => item.feature === 'KooQuiz'
  );
  const disabled = !findKooQuizFeature?.parameters.IsEnabled;

  const clickHandler = () => {
    if (!disabled) {
      if (subject === 'math') {
        history.push(`/kooQuiz/Maths/1`);
      } else if (subject === 'science') {
        history.push(`/kooQuiz/Science/4`);
      }
    }
  };
  return (
    <StyledContainer
      onClick={clickHandler}
      disabled={disabled}
      data-cy="kooquiz-card"
    >
      <div>
        <QuizImage />
        {disabled && (
          <StyledPremiumOnly>
            <PremiumOnlyIcon />
          </StyledPremiumOnly>
        )}
        <StyledContent disabled={disabled}>
          <h3>{t('kooQuiz.name', 'KooQuiz')}</h3>
          <p>{t('kooQuiz.description', 'Revisions made easy')}</p>
        </StyledContent>
      </div>
    </StyledContainer>
  );
};
export default KooQuizCard;
