import styled from 'styled-components';
import { Container } from 'components/Assignment/AssignmentView/Header.styles';

export const StyledContainer = styled(Container)`
  top: ${(props) => props.styles.top ?? '0'};
`;

export const MiddleSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
`;

export const StyledSection = styled.div`
  min-width: ${(props) => props.styles?.minWidth ?? '200px'};
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.styles?.justifyContent ?? 'flex-start'};
  border-left: ${(props) => props.styles?.borderLeft ?? 'none'};
  height: 100%;
  margin-right: ${(props) => props.page && '1rem'};
`;
