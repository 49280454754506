import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/top-brain/header.png';

export const Container = styled.div`
  height: 350px;
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: 105% 350px;
  display: flex;
  padding: 0 20px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 2%;
  z-index: 1;
`;

export const CenterBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  gap: 1rem;
  .middle-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    .title {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .scoring-period {
      width: 100%;
      background: rgb(255, 255, 255, 0.28);
      border-radius: 12px;
      color: #ffffff;
      font-weight: 600;
      padding: 0.8rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.5rem;
      .text {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        .cp-icon {
          margin-top: 4px;
          width: 40px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const SubTitle = styled.div`
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  align-self: flex-end;
  position: absolute;
  bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const Rule = styled.div`
  background: #330901;
  cursor: pointer;
  color: #ffffff;
  border-radius: 19px;
  padding: 0.3rem 1.3rem;
`;
