import styled from 'styled-components';
import CongratsBg from 'assets/img/assignment/assignment-view/congrats-bg.svg';
import KoKoGroup from 'assets/img/icon-koko-group.png';
import KokoCredits from 'assets/img/kooquiz/icon-koko-credit.svg';

export const Container = styled.div`
  .top-section {
    background: url(${CongratsBg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 12rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 4rem;
    text-align: center;
    p {
      color: #fd9943;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .bottom-section {
    text-align: center;
    margin-top: 1rem;
    p {
      margin-bottom: 0rem;
    }
    .score-info {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bold;

      .title {
        color: #1c1c1c;
      }
      .score {
        color: ${(props) =>
          props.color === 'green'
            ? '#49b800'
            : props.color === 'orange'
            ? '#FF6701'
            : '#FC4D4D'};
        font-size: 34px;
        margin: 0 0.5rem;
      }
      .score-details {
        color: #a8a8a8;
        span {
          color: ${(props) =>
            props.color === 'green'
              ? '#49b800'
              : props.color === 'orange'
              ? '#FF6701'
              : '#FC4D4D'};
        }
      }
    }
    .rewards {
      color: #6b6b6b;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      .gift-box {
        width: 102px;
        height: 114px;
        background: url(${KoKoGroup});
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 1rem;
      }
      .koko-credit {
        width: 55px;
        height: 55px;
        background: url(${KokoCredits});
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: -0.5rem;
      }
      .awarded-koko {
        font-size: 16px;
        font-weight: 600;
        color: #4a4a4a;
        span {
          color: #f39a00;
        }
      }
      .total-koko {
        color: #575757;
        font-weight: 600;
        font-size: ${(props) => (props.award > 0 ? '13px' : '14px')};
        text-align: center;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;
