import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_LEADERBOARD_API_DOMAIN;

// get superhero leaderboard data
export const fetchSuperHeroLeaderboardData = () => {
  return fetch(`${baseUrl}Leaderboard/SuperHeroLeaderboard/GetSuperHeroData`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get superhero leaderboard data failed'); // eslint-disable-line
    });
};

// get superhero top 50 leaderboard data
export const fetchSuperHeroTopFiftyLeaderboardData = () => {
  return fetch(
    `${baseUrl}Leaderboard/SuperHeroLeaderboard/GetSuperHeroTopFifty`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get superhero top 50 leaderboard data failed'
      ); // eslint-disable-line
    });
};
