import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import { RANKING_FILTER } from 'pages/Leaderboard/v2/constant';
import { fetchSuperheroLeaderBoard } from 'services/leaderboardV2/superHero';

export const getSuperheroLeaderBoard = createAsyncThunk(
  'leaderboard/getSuperheroLeaderBoard',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchSuperheroLeaderBoard, params);
      return handleResponseV2(res);
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get superhero leaderboard data failed'
      );
    }
  }
);
const initialState = {
  isLoading: false,
  error: null,
  data: {
    [RANKING_FILTER.regular]: [],
    [RANKING_FILTER.top50]: [],
  },
};

const superheroLeaderBoardSlice = createSlice({
  name: 'superHeroLeaderBoardV2',
  initialState,
  reducers: {},
  extraReducers: {
    [getSuperheroLeaderBoard.pending]: (state) => {
      state.isLoading = true;
    },
    [getSuperheroLeaderBoard.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data[action.meta.arg.rankingFilter] = action.payload;
    },
    [getSuperheroLeaderBoard.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});
const { reducer } = superheroLeaderBoardSlice;
export default reducer;
