const handleResponse = (response) => {
  if (response.ok) {
    return response.json();
  }
  throw new Error(`${response.status} Error`);
};

export default handleResponse;

export const handleResponseV2 = (res) => {
  if (res.status < 200 || res.status > 299) {
    throw new Error(
      res?.detail || res?.title || res?.message || res?.error || res
    );
  }
  return res.data;
};
