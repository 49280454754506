import styled, { css } from 'styled-components';
import Home from 'assets/img/navigation/home.svg';
import HomeActive from 'assets/img/navigation/home-active.svg';
import Report from 'assets/img/navigation/report.svg';
import ReportActive from 'assets/img/navigation/report-active.svg';
import Leaderboard from 'assets/img/navigation/leaderboard.svg';
import LeaderboardActive from 'assets/img/navigation/leaderboard-active.svg';
import Friends from 'assets/img/navigation/friends.svg';
import FriendsActive from 'assets/img/navigation/friends-active.svg';
import ParentApp from 'assets/img/parent-portal/nav/icon-parent-app.svg';
import ParentAppActive from 'assets/img/parent-portal/nav/icon-parent-app-active.svg';
import Settings from 'assets/img/parent-portal/nav/icon-settings.svg';
import SettingsActive from 'assets/img/parent-portal/nav/icon-settings-active.svg';
import Achivement from 'assets/img/navigation/achivement.svg';
import AchivementActive from 'assets/img/navigation/achivement-active.svg';
import Help from 'assets/img/navigation/help.svg';
import HelpActive from 'assets/img/navigation/help-active.svg';

// eslint-disable-next-line import/prefer-default-export
export const NavItemContainer = styled.div`
  .nav-item {
    padding: 10px 5px;
    min-width: 125px;
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: ${(props) => (props.isFeatureReady ? 'pointer' : 'not-allowed')};
    opacity: ${(props) => (props.isFeatureReady ? '1' : '0.3')};
    @media (max-width: 1350px) {
      margin-right: 0px;
    }
    @media (max-width: 1240px) {
      min-width: 100px;
    }
    @media (max-width: 1130px) {
      min-width: 70px;
    }
    @media (max-width: 965px) {
      min-width: 50px;
    }
    @media (max-width: 414px) {
      min-width: 30px;
    }
    .nav-link {
      font-size: 16px;
      color: white;
      position: relative;
      margin: 0px 25px;
      line-height: 1;
      text-align: center;
      align-items: flex-end;
      display: flex;

      @media (max-width: 1415px) {
        margin: 0px 15px;
      }
      @media (max-width: 1310px) {
        margin: 0px 10px;
      }
      @media (max-width: 1023px) {
        margin: 0px 0px;
        span {
          font-size: 13px;
        }
      }
      @media (max-width: 1000px) {
        span {
          font-size: 10px;
        }
      }
      img {
        margin-right: 10px;
      }
      i {
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: bottom;
        line-height: 1;
        margin-right: 0.5rem;
        @media (max-width: 1023px) {
          margin-right: 0rem;
        }
      }
    }
    &:hover {
      span {
        color: #ffce09;
      }
      background-color: #343434;
    }
    .home {
      background-image: url(${Home});
      width: 20px;
      height: 20px;
    }
    .home-active {
      background-image: url(${HomeActive});
    }
    .report {
      background-image: url(${Report});
      width: 19.5px;
      height: 17.28px;
    }
    .report-active {
      background-image: url(${ReportActive});
    }
    .leaderboard {
      background-image: url(${Leaderboard});
      width: 25px;
      height: 20px;
    }
    .leaderboard-active {
      background-image: url(${LeaderboardActive});
    }
    .friends {
      background-image: url(${Friends});
      width: 34.44px;
      height: 23px;
    }
    .friends-active {
      background-image: url(${FriendsActive});
    }
    .parent-app {
      width: 20px;
      height: 20px;
      background-image: url(${ParentApp});
    }
    .parent-app-active {
      background-image: url(${ParentAppActive});
    }
    .settings {
      width: 20px;
      height: 20px;
      background-image: url(${Settings});
    }
    .settings-active {
      background-image: url(${SettingsActive});
    }
    .achivement {
      background-image: url(${Achivement});
      width: 23px;
      height: 23px;
    }
    .achivement-active {
      background-image: url(${AchivementActive});
    }
    .help {
      background-image: url(${Help});
      width: 20px;
      height: 20px;
    }
    .help-active {
      background-image: url(${HelpActive});
    }

    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      padding: 5px 5px;
      .home,
      .report,
      .parent-app,
      .settings,
      .help {
        width: 24px;
        height: 24px;
      }
      .leaderboard {
        width: 25px;
        height: 24px;
      }
      .friends {
        width: 34.44px;
        height: 23px;
      }
      .achivement {
        width: 30px;
        height: 30px;
      }
    }
  }
  .active {
    span {
      color: #ffce09;
    }
    background-color: #343434;
  }
  .dashboard {
    span {
      color: #ffce09;
    }
  }
  ${({ isSchoolPlan, mobileOnly }) => {
    if (isSchoolPlan && !mobileOnly) {
      return css`
        @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
          display: none;
        }
      `;
    }
  }}
`;
