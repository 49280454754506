import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 59px);
  padding: 3rem 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 0 3rem;
    gap: 1.5rem;
  }
`;
export const Content = styled.div`
  width: 100%;
`;
