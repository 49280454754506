import { IconPrice, IconConvenience, IconExperience } from './images';

export const AUTO_RENEW_REASONS = [
  {
    id: 1,
    title: 'Best Price Guarantee',
    desc:
      'Lock in the lowest renewal discount today. Even if our prices go up during your subscription, you’re getting the best deal!',
    icon: IconPrice,
  },
  {
    id: 2,
    title: 'A seamless KooBits experience',
    desc: 'Your child’s learning won’t skip a beat.',
    icon: IconExperience,
  },
  {
    id: 3,
    title: 'Convenience',
    desc: 'No need to track, remember, and manually renew every year.',
    icon: IconConvenience,
  },
];

export const FAQ = [
  {
    id: 1,
    qn: 'Can I turn off auto-renewal in the future?',
    answer:
      'Yes! You’ll always have the freedom to turn off auto-renewal whenever you want.',
  },
  {
    id: 2,
    qn: 'Does turning on auto-renewal automatically charge my credit card?',
    answer:
      'Turning on auto-renewal doesn’t cost you anything today. You will only be charged when your current subscription ends. It’s a handy feature to ensure your child’s learning stays uninterrupted.',
  },
  {
    id: 3,
    qn: 'Will I receive a notification before my subscription is auto-renewed?',
    answer:
      'We’ll send you a friendly reminder from 30 days onwards before your plan ends, so you will know exactly when your card will be charged. No surprises here!',
  },
  {
    id: 4,
    qn: 'Are there any additional fees associated with auto-renewal?',
    answer:
      'There will be zero additional fees. Auto-renewal is all about giving you convenience. Your only cost is the subscription price itself when it renews, which we guarantee will be our lowest price.',
  },
  {
    id: 5,
    qn: 'When will auto-renewal start?',
    answer:
      'Auto-renewal kicks in on the day your subscription ends. That way, your child won’t miss a moment of their KooBits adventure!',
  },
  {
    id: 6,
    qn: 'Will my subscription price change with auto-renewal?',
    answer:
      'We guarantee the best price at the time of your auto-renewal, so there is no need to worry about any unexpected changes. This is the best way to lock in our lowest prices.',
  },
  {
    id: 7,
    qn: 'Is auto-renewal enabled for other subjects I have with KooBits?',
    answer:
      'Auto-renewal can be turned on for all subjects you have with KooBits. ',
  },
];
