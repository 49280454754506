import styled from 'styled-components';
import PlayBg from 'assets/img/dashboard/play-bg1.svg';

export const Container = styled.div`
  background: url(${PlayBg}) no-repeat;
  background-size: cover;
  margin-top: 52px;
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .back-btn {
    position: absolute;
    top: 4rem;
    left: 3rem;
  }
  .left-bg {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .right-bg {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  @media (max-width: 1150px) {
    .left-bg,
    .right-bg {
      width: 15%;
    }
  }
`;

export const SubCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 450px;
  width: 800px;
  margin: 1rem 0;
  z-index: 2;
`;
