import React from 'react';
import Button from 'components/Shared/Button';
import useResponsiveValue from 'hooks/responsive/useResponsiveValue';
import { NotebookHeader } from '../../pages/Multiplayer/PeerChallengeQnView.styles';

const PeerChallengeSubmitButton = ({
  t,
  openModal,
  isLoading,
  dataCy,
  location,
  children,
}) => {
  const buttonWidth = useResponsiveValue({
    desktop: '135px',
    mobile: undefined,
    tablet: '135px',
  });
  return (
    <NotebookHeader location={location}>
      {children}
      <Button
        dataCy={dataCy}
        width={buttonWidth}
        onClick={openModal}
        variant="primary"
        isDisabled={isLoading}
      >
        {t('peerChallengeQnView:main.submit', 'Submit')}
      </Button>
    </NotebookHeader>
  );
};

export default PeerChallengeSubmitButton;
