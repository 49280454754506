import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BackButton from 'components/Shared/BackButton';
import Q1 from 'assets/img/leaderboard/top-brain/q1.svg';
import Q2 from 'assets/img/leaderboard/top-brain/q2.svg';
import Q3 from 'assets/img/leaderboard/top-brain/q3.svg';
import Q4 from 'assets/img/leaderboard/top-brain/q4.svg';
import { getTopBrainSeasons } from 'store/topBrain/topBrainSlice';
import {
  Topbar,
  CenterBar,
  ButtonContainer,
} from './TopBrainHeaderClassic.styles';
import { TermClassic } from './TopBrainHeader.styles';
import ChallengeType from '../ChallengeType';

import SeasonsDropdown from './SeasonsDropdown';

const TopBrainHeaderClassic = ({
  activeTerm,
  setActiveTerm,
  singaporeSchool,
  season,
  setSeason,
  year,
  setYear,
  currentSeason,
  currentYear,
  // currentSeasonName,
  data,
  isTermlyOpen,
  isClassicOpen,
}) => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTopBrainSeasons());
    // eslint-disable-next-line
  }, []);

  // seasons dropdown
  const [dropdownItems, setDropdownItems] = useState('');

  // get year & season from selected dropdown item
  const dropdownValue = (value) => {
    setDropdownItems(value);
    setSeason(value.ID);
    setYear(value.Year);
  };

  // sort dropdown items
  const selectedSeason =
    dropdownItems !== '' &&
    dropdownItems.ID === currentSeason &&
    dropdownItems.Year === currentYear;

  const currentData = data.filter(
    (item) => item.ID === currentSeason && item.Year === currentYear
  );
  // const dataWithoutCurrentSeason = data.filter(
  //   (item) => item.Name !== currentSeasonName
  // );

  return (
    <Topbar>
      <ButtonContainer>
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(7, 37, 106, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <CenterBar>
        {!singaporeSchool && (
          <div className="classic-banner">
            {t('leaderboard:topBrainBanner.topBrain', `Top Brain`)}
          </div>
        )}
        <div className="classic-images">
          <img src={Q1} alt="Q1" />
          <img src={Q2} alt="Q2" />
          <img src={Q3} alt="Q3" />
          <img src={Q4} alt="Q4" />
        </div>
        {singaporeSchool && (
          <div className="classic-choices">
            <TermClassic
              active={activeTerm}
              bgActive="#FF8C05"
              bgInactive="#DE6600"
              colorActive="#FFFFFF"
              colorInactive="#893F00"
            >
              <div
                className="term"
                onClick={() => {
                  if (!isTermlyOpen) {
                    window.open(
                      'https://support.koobits.com/hc/en-gb/articles/4430653191449'
                    );
                    return;
                  }
                  setActiveTerm('term');
                }}
              >
                {t('leaderboard:topBrainBanner.termly', `Termly`)}
              </div>
              <div
                className="classic"
                onClick={() => {
                  if (!isClassicOpen) {
                    window.open(
                      'https://support.koobits.com/hc/en-gb/articles/4430653191449'
                    );
                    return;
                  }
                  setActiveTerm('classic');
                }}
              >
                {t('leaderboard:topBrainBanner.classic', `Classic`)}
              </div>
            </TermClassic>
            <h3>{t('leaderboard:topBrainBanner.topBrain', `Top Brain`)}</h3>
          </div>
        )}
      </CenterBar>
      <div className="details">
        {t(
          'leaderboard:topBrainBanner.topBrainDesc',
          `Go to Daily Challenge or Peer Challenge to start earning some CPs and badges!`
        )}
      </div>
      <SeasonsDropdown
        currentSeason={selectedSeason}
        dropdownItems={dropdownItems}
        setDropdownItems={dropdownValue}
        season={season}
        setSeason={setSeason}
        data={[]}
        year={year}
        setYear={setYear}
        currentSeasonValue={currentSeason}
        currentYear={currentYear}
        currentData={currentData}
      />
      <ChallengeType styles={{ top: '10%' }} page="top-brain-classic" />
    </Topbar>
  );
};

export default TopBrainHeaderClassic;
