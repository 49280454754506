import styled from 'styled-components';
/* eslint-disable */

export const Container = styled.div`
  width: 100%;
  background-color: #ebf7f9;
  margin-top: 3rem;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 0.5rem;
  margin-left: -5px;
  p {
    margin-bottom: 0rem;
  }
  .topic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .topic-title {
      color: #0a5361;
      font-weight: 600;
    }
    .topic-desc {
      color: #093e56;
      font-weight: 300;
      font-size: 14px;
    }
  }
  .qn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    position: relative;
    .qn-number {
      display: flex;
      align-items: center;
      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        border-radius: 25px;
        min-width: 84px;
        height: 30px;
        background-color: #79d02f;
        margin-left: 15px;
      }
    }
    .qn-info {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      color: #698f9f;
      font-size: 11px;
      font-weight: 300;
      .qn-difficulty {
        display: flex;
        align-items: center;
        margin-right: 20px;
        p {
          margin-right: 3px;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 14px;
    .topic {
      display: none;
    }
    .qn {
      .qn-info-desktop {
        display: none;
      }
    }
  }
`;
