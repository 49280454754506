import styled from 'styled-components';

export const AvatarsGrid = styled.div`
  display: grid;
  margin-top: 20px;
  row-gap: 42px;
  margin-bottom: 120px;
  grid-template-columns: repeat(2, 1fr);

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const AvatarErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  & > .btn.btn-primary {
    background: #03d1e4;
    border-color: #42c5ca;
  }
`;
