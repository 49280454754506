import styled from 'styled-components';

export const QuestionWrap = styled.div`
  text-align: center;
  margin-top: 150px;
  padding-right: 270px;

  .question {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const QuestionNumber = styled.div`
  font-size: 72px;
  margin-right: 80px;
  color: #e06400;
  font-weight: 800;
`;

export const QuestionText = styled.div`
  font-size: 131px;
  margin-right: 20px;
  font-weight: 800;
`;

export const QuestionAnswer = styled.div`
  .form-control {
    background-color: #e4f0ff;
    border: 0;
    height: 137px;
    font-size: 131px;
    width: 200px;
    color: #212529;
    text-align: center;
    border-radius: 10px;
    padding: 0;
  }
`;
