import { fetchWrapper } from 'services/login';
import {
  fetchGetTodayCPDetails,
  fetchGetTodayCPDetailsWithSMCData,
} from 'services/dailyChallenge';
import { fetchSMCStatus } from 'services/event';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const mappingDateOfWeek = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

function isToday(date) {
  const today = new Date();

  if (today.toDateString() === date.toDateString()) {
    return true;
  }

  return false;
}

export const getTodayCPDetails = createAsyncThunk(
  'ChallengePoint/GetTodayCPDetails',
  async () => {
    try {
      const smcStatusData = await fetchWrapper(fetchSMCStatus);

      let res;
      if (smcStatusData?.TestSubmissionId) {
        res = await fetchWrapper(
          fetchGetTodayCPDetailsWithSMCData,
          smcStatusData?.TestSubmissionId
        );
      } else {
        res = await fetchWrapper(fetchGetTodayCPDetails);
      }
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get today cps details failed');
    }
  }
);

const initialState = {
  TodaysTotalCP: 0,
  Breakdown: [
    {
      Type: 1,
      Points: 0,
      LastClaimedDateTime: '',
    },
    {
      Type: 2,
      Points: 0,
      LastClaimedDateTime: '',
    },
    {
      Type: 3,
      Points: 0,
      LastClaimedDateTime: '',
    },
  ],
  dcCP: 0,
  pcCP: 0,
  smcCP: 0,
  isDisplaySMCClaimed: false,
  smcClaimedOfDate: null,
  error: null,
  loading: false,
};

const cpsDetailSlice = createSlice({
  name: 'cpsDetail',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: {
    [getTodayCPDetails.pending]: (state) => {
      state.loading = true;
      state.TodaysTotalCP = initialState.TodaysTotalCP;
      state.Breakdown = initialState.Breakdown;
    },
    [getTodayCPDetails.fulfilled]: (state, action) => {
      state.error = null;
      if (action.payload.Breakdown.length > 0) {
        state.Breakdown = action.payload.Breakdown;

        // ** Breakdown type **
        //    DailyChallenge = 1
        //    PeerChallenge = 2
        //    SMC = 3
        action.payload.Breakdown.forEach((item) => {
          if (item.Type === 1) state.dcCP = item.Points;
          if (item.Type === 2) state.pcCP = item.Points;
          if (item.Type === 3) state.smcCP = item.Points;
        });
        state.TodaysTotalCP = action.payload.Breakdown.reduce(
          (accumulator, item) => accumulator + item.Points,
          0
        );
      }

      // If not ClaimDate will get null
      if (action.payload.ClaimDate) {
        // parse "2022-06-02T02:28:20" to "2022-06-02"
        const smcLastClaimedDateTime = action.payload.ClaimDate.split('T')[0];

        state.isDisplaySMCClaimed = !isToday(new Date(smcLastClaimedDateTime));

        if (state.isDisplaySMCClaimed) {
          const theDay = new Date(smcLastClaimedDateTime).getDay();
          state.smcClaimedOfDate = mappingDateOfWeek[theDay];
        }
      }

      state.loading = false;
    },
    [getTodayCPDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export default cpsDetailSlice.reducer;
