import React from 'react';
import { Container, RedDot } from './RedDotWrapper.styles';

const RedDotWrapper = ({ children, size, active }) => {
  return (
    <Container>
      {children}
      {active && <RedDot size={size} />}
    </Container>
  );
};

export default RedDotWrapper;
