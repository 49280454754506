import moment from 'moment';

const sortAssignment = (assignmentList, monthsArray) => {
  const sortedAssignment = [...monthsArray];
  for (const assignmentObject of assignmentList) {
    const hasMonthIndex = sortedAssignment.findIndex(
      (item) => item.month === moment(assignmentObject.AssignDate).month()
    );
    if (hasMonthIndex !== -1) {
      sortedAssignment[hasMonthIndex].assignments.push(assignmentObject);
    }
  }
  return sortedAssignment;
};

export default sortAssignment;
