import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeSelected } from 'store/assignment/barModelSlice';
import { isNil } from 'ramda';
import { Container, Workspace, WorkspaceContent } from './index.style';
import BarField from './Components/BarField';
import Brace from './Components/Brace';
import InvertedBrace from './Components/InvertedBrace';
import VerticalBrace from './Components/VerticalBrace';
import Arrow from './Components/Arrow';
import Text from './Components/Text';
import Toolbar from './Toolbar';
import ColorPickerContext from './ColorPickerContext';

const componentsMap = {
  bar: BarField,
  brace: Brace,
  iBrace: InvertedBrace,
  vBrace: VerticalBrace,
  arrow: Arrow,
  text: Text,
};

function renderer(object) {
  if (typeof componentsMap[object.type] !== 'undefined') {
    return React.createElement(componentsMap[object.type], {
      key: object.id,
      id: object.id,
      x: object.x,
      y: object.y,
      zIndex: object.zIndex,
      size: object.size,
      ...object.extra,
    });
  }
  return [];
}

function BarModel() {
  const dispatch = useDispatch();
  const { data, activeId, activeIndex, multiBarModelData } = useSelector(
    (state) => state.barModel
  );
  const objectsArray = isNil(activeIndex)
    ? data.objects
    : multiBarModelData[activeIndex].objects;
  const colorPickerRef = useRef(null);
  useEffect(() => {
    function handleBackspace(e) {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        if (activeId !== null) {
          const activeObject = objectsArray.find((o) => o.id === activeId);
          if (!isNil(activeObject) && activeObject.type !== 'text') {
            dispatch(removeSelected());
          }
        }
      }
    }
    document.addEventListener('keydown', handleBackspace);
    return () => {
      document.removeEventListener('keydown', handleBackspace);
    };
  }, [activeId, dispatch, objectsArray]);
  return (
    <Container>
      <ColorPickerContext.Provider value={colorPickerRef}>
        <Toolbar />
        <Workspace>
          <WorkspaceContent>{objectsArray.map(renderer)}</WorkspaceContent>
        </Workspace>
      </ColorPickerContext.Provider>
    </Container>
  );
}

export default React.memo(BarModel);
