import styled from 'styled-components';

export const Container = styled.div`
  background: rgb(255, 225, 106, 0.44);
  color: #000000;
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 1rem;
  margin-right: 1rem;
  .text {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0rem;
      span {
        font-weight: 600;
      }
    }
    img {
      width: 35px;
      margin: 0rem 0.3rem;
    }
  }
  .claim-btn {
    background: #ffd500;
    border-radius: 20px;
    border: none;
    font-size: 16px;
    padding: 0.1rem 0.8rem;
    pointer-events: auto;
    :hover {
      background: #ffcc00;
    }
  }
`;
