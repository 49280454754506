import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  position: fixed;
  right: 10px;
  z-index: 5;

  ${({ plan }) => {
    if (plan === 'B2B') {
      return css`
        bottom: 5px;
      `;
    }
    return css`
      bottom: 110px;
    `;
  }}
`;

export const StyledButton = styled.button`
  all: unset;

  img {
    ${({ seenMobilePopup }) => {
      if (seenMobilePopup === 2) {
        return css`
          width: calc(200px - 2vw);
          margin-right: 3rem;
        `;
      }
      return css`
        transform: scale(1);
      `;
    }}
  }
`;
