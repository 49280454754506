import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { updateGender } from 'store/boysVsGirls/boysVsGirlsSlice';
import { genderId } from 'constants/profileSettings';
import { setGender } from 'store/dashboard/studentDetailsSlice';
import Button from 'components/Shared/Button';
import Spinner from 'components/Shared/Spinner';
import ErrorResponse from 'components/Shared/ErrorResponse';
import BoyIcon from 'assets/img/leaderboard/boys-vs-girls/gender-boy.svg';
import GirlIcon from 'assets/img/leaderboard/boys-vs-girls/gender-girl.svg';
import { unwrapResult } from '@reduxjs/toolkit';
import { Container, IconContainer } from './GenderModal.styles';

const GenderIcon = ({
  gender,
  selectedGender,
  onClick,
  welcomeMember,
  text,
}) => {
  const selected = gender === genderId[selectedGender];

  return (
    <IconContainer
      gender={gender}
      selected={selected}
      onClick={onClick}
      welcomeMember={welcomeMember}
    >
      <div className="round-bg">
        <img src={gender === 'Male' ? BoyIcon : GirlIcon} alt={gender} />
      </div>
      {!welcomeMember && <p className="desc">{text}</p>}
    </IconContainer>
  );
};

const GenderModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['leaderboard']);
  const {
    genderUpdate,
    isLoadingGenderUpdate,
    isErrGenderUpdate,
  } = useSelector((state) => state.boysVsGirls);
  const [selectedGender, setSelectedGender] = useState('');
  const [joinedModal, setJoinedModal] = useState(false);

  const genderList = [
    {
      id: 1,
      gender: 'Male',
    },
    {
      id: 2,
      gender: 'Female',
    },
  ];
  const saveGenderHandler = async () => {
    try {
      const resultAction = await dispatch(updateGender(selectedGender));
      unwrapResult(resultAction);
      dispatch(setGender(selectedGender));
    } catch (err) {
      console.log(err.message);
    }
    setJoinedModal(true);
  };
  return (
    <Container>
      {!joinedModal && (
        <>
          <p className="title">
            {t(
              'leaderboard:boysVsGirls.genderModal.title1',
              'Welcome to Boys Vs Girls'
            )}
          </p>
          <p className="sub-title">
            {t(
              'leaderboard:boysVsGirls.genderModal.subTitle',
              'Are you a boy or girl?'
            )}
          </p>
          <div className="select-gender">
            {genderList.map((item) => (
              <GenderIcon
                key={item.id}
                gender={item.gender}
                onClick={() => {
                  setSelectedGender(item.id);
                }}
                selectedGender={selectedGender}
                text={
                  item.gender === 'Male'
                    ? t(
                        'leaderboard:boysVsGirls.genderModal.boy',
                        'I am a boy.'
                      )
                    : t(
                        'leaderboard:boysVsGirls.genderModal.girl',
                        'I am a girl.'
                      )
                }
              />
            ))}
          </div>
          <Button
            variant={isEmpty(selectedGender) ? 'disable' : 'primary'}
            isDisabled={isEmpty(selectedGender)}
            onClick={saveGenderHandler}
          >
            {t('leaderboard:boysVsGirls.genderModal.confirm', 'Confirm')}
          </Button>
        </>
      )}
      {joinedModal && isLoadingGenderUpdate && <Spinner />}
      {joinedModal && !isNil(genderUpdate) && (
        <>
          <p className="title">
            {t(
              'leaderboard:boysVsGirls.genderModal.title2',
              {
                gender:
                  genderId[selectedGender] === 'Male'
                    ? t('leaderboard:boysVsGirls.genderModal.boys', 'Boys')
                    : t('leaderboard:boysVsGirls.genderModal.girls', 'Girls'),
              },
              `Welcome to join ${
                genderId[selectedGender] === 'Male' ? 'Boys' : 'Girls'
              } team!`
            )}
          </p>
          <GenderIcon
            gender={genderId[selectedGender]}
            selectedGender={genderId[selectedGender]}
            welcomeMember
          />
          <Button variant="primary" onClick={closeModal} padding="0.5rem 2rem">
            {t('leaderboard:boysVsGirls.genderModal.great', 'Great')}
          </Button>
        </>
      )}
      {joinedModal && !isNil(isErrGenderUpdate) && (
        <ErrorResponse error={isErrGenderUpdate} />
      )}
    </Container>
  );
};

export default GenderModal;
