import moment from 'moment';

const isResultsReleased = (resultReleaseDate) => {
  const resultReleaseMoment = moment(resultReleaseDate);
  const currentMoment = moment();
  const difference = resultReleaseMoment.diff(currentMoment);
  return difference <= 0;
};

export default isResultsReleased;
