import styled from 'styled-components';

export const Heading = styled.h2`
  font-weight: 600;
  margin: 0 10px 0 0;
`;

export const StyledTime = styled.img`
  margin-right: 1rem;
  margin-bottom: 0.3rem;
`;

export const Content = styled.div`
  background: #f9f9f9;
`;
