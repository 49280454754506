import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Container, Month, Text, NoAssignment } from './MonthlyBorder.styles';

const MonthlyBorder = ({ month, type }) => {
  const { t, i18n } = useTranslation(['assignmentList']);
  return (
    <Container type={type}>
      <h2>
        <span className="container">
          {type === 'contain-assignment' ? (
            <>
              {i18n.language !== 'zh_CN' && i18n.language !== 'zh_TW' && (
                <Text>
                  {t('assignmentList:list.assignedIn', 'Assigned in')}{' '}
                </Text>
              )}
              <Month>
                {i18n.language === 'zh_CN' || i18n.language === 'zh_TW'
                  ? moment(month).format(
                      `${t('assignmentList:list.in', '于')}YYYY${t(
                        'assignmentList:list.year',
                        '年'
                      )}MM${t('assignmentList:list.month', '月')}`
                    )
                  : moment(month).format(`MMM YYYY`)}
              </Month>
              {(i18n.language === 'zh_CN' || i18n.language === 'zh_TW') && (
                <Text>{t('assignmentList:list.assignedIn', '分配')}</Text>
              )}
            </>
          ) : (
            <>
              {i18n.language !== 'zh_CN' && i18n.language !== 'zh_TW' && (
                <NoAssignment>
                  {t(
                    'assignmentList:noAssignment.previousMonth',
                    'There is no assignment in '
                  )}{' '}
                </NoAssignment>
              )}
              <NoAssignment>
                {i18n.language === 'zh_CN' || i18n.language === 'zh_TW'
                  ? moment(month).format(
                      `YYYY${t('assignmentList:list.year', '年')}MM${t(
                        'assignmentList:list.month',
                        '月'
                      )}`
                    )
                  : moment(month).format('MMMM YYYY')}
                .
              </NoAssignment>
              {(i18n.language === 'zh_CN' || i18n.language === 'zh_TW') && (
                <NoAssignment>
                  {t(
                    'assignmentList:noAssignment.previousMonth',
                    '没有布置作业'
                  )}
                </NoAssignment>
              )}
            </>
          )}
        </span>
      </h2>
    </Container>
  );
};

export default MonthlyBorder;
