import styled from 'styled-components';
import HotsBg from 'assets/img/mission/hots-v2-intermediate-bg.png';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 73px;
  background-image: ${`url(${HotsBg})`};
  background-position: center;
  .title {
    font-weight: 600;
    font-size: 20px;
    color: white;
    margin: 0;
    &.mobile {
      display: none;
    }
  }

  .left-icon,
  .right-icon {
    display: none;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    background-image: none;
    background-color: #3896ff;
    .title {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
    .left-icon {
      display: block;
      position: absolute;
      left: 23px;
      width: 110px;
    }
    .right-icon {
      display: block;
      position: absolute;
      width: 70px;
      right: 55px;
    }
  }
`;

export const StyledContent = styled.div`
  text-align: center;
  padding: 30px 0 2rem 0;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 30px 0 0 0;
  }
`;

export const StyledButton = styled.button`
  border: none;
  color: white;
  background: rgba(52, 111, 192, 0.61);
  padding: 0.5rem 1.5rem 0.5rem 0.8rem;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 62px;
  z-index: 5;
  span {
    margin-left: 8px;
  }
  :hover {
    background: rgba(93, 140, 205, 0.61);
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0;
    width: 30px;
    height: 30px;
    left: 16px;
    span {
      display: none;
    }
  }
`;

export const StyledInfoButton = styled.span`
  width: 27px;
  height: 27px;
  background: #aae9f4;
  font-size: 13px;
  font-weight: 300;
  color: #2b96a0;
  border-radius: 50%;
  text-align: center;
  border: solid 5.5px #3896ff;
  cursor: pointer;
  :hover {
    background: #c7f7ff;
  }
`;
