import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  min-height: calc(100vh - 59px);
  background-color: #000000;
  .close-btn {
    padding: 0.5rem;
  }
`;

export const Main = styled.div``;
