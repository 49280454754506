import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const TextInfo = styled.p`
  margin: 0.5em 0 1em;

  img {
    vertical-align: middle;
    margin-right: 0.5em;
  }
`;

export const WrapButton = styled.div``;
export const OtherChallengeButton = styled.a`
  cursor: pointer;
  width: 266px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 30px;
  background: #ff7121;
  margin: 0 auto;
  :hover {
    background: #ff5c00;
  }
  && {
    color: white;
    &:hover {
      color: white;
    }
  }
`;
