import styled from 'styled-components';

export const MapContainer = styled.div`
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
`;

export const MapPoint = styled.div`
  cursor: pointer;
  display: block;
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  &.scored {
    border: 4px solid #afda0b;
  }
  &.completed {
    border: 4px solid #ff562f;
  }
`;
