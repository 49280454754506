import styled, { css } from 'styled-components';
import Checked from 'assets/img/parent-portal/icon-check.svg';
import { Popover } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ length, position }) =>
    length === 1 || position === length ? '0' : '1.5rem'};
  p {
    margin-bottom: 0;
  }
`;

const badgeColor = {
  recurring: {
    color: '#59A21B',
    bg: '#E6FFC9',
  },
  expired: {
    color: '#FF0000',
    bg: '#FFE5E5',
  },
};

export const StatusBadge = styled.div`
  border-radius: 15px;
  background: ${(props) => badgeColor[props.status].bg};
  color: ${(props) => badgeColor[props.status].color};
  font-size: 12px;
  margin-left: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 22px;
`;

export const TopSection = styled.div`
  width: 100%;
  background: ${({ active }) => (active ? '#F9FFF2' : '#ffffff')};
  padding: 1.5rem 4rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(112, 112, 112, 0.14);
  border-top-left-radius: ${({ position }) => position > 1 && '8px'};
  border-top-right-radius: ${({ position }) => position > 1 && '8px'};
  border-bottom-left-radius: ${({ rounded }) => rounded && '8px'};
  border-bottom-right-radius: ${({ rounded }) => rounded && '8px'};
  .check-icon {
    width: 20px;
    height: 20px;
    background: ${(props) => props.active && `url(${Checked})`};
    background-repeat: no-repeat;
    margin-top: 0.4rem;
    margin-left: -2rem;
    position: absolute;
  }

  .plan {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    p {
      margin-bottom: 0px;
    }
    .plan-name {
      display: flex;
      align-items: center;
      .name-section {
        display: flex;
        .title {
          font-weight: bold;
        }
      }
    }
    .desc {
      color: #606060;
      font-size: 12px;
    }
  }

  .disc {
    background: #ffce00;
    border-radius: 4px;
    color: #000000;
    font-weight: 900;
    font-size: 9px;
    position: absolute;
    margin-bottom: 3rem;
    margin-left: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 22px;
    .b2b-label {
      font-weight: 900;
      font-size: 0.4375rem;
      background: #ff5b00;
      color: #ffffff;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      width: 100%;
      margin-left: 0;
      text-align: center;
      padding: 0.1rem;
    }
    .discount-number {
      padding: 0.2rem 0.5rem;
      margin-left: 0;
    }
  }

  .otp-trial {
    p {
      font-size: 14px;
      margin-bottom: 0.2rem;
      color: #6e904a;
    }
  }

  .mobile-auto-renew {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.8rem;
    p {
      color: #000000;
    }
  }

  @media (max-width: 1024px) {
    padding: 1.5rem 2.5rem;
  }
  @media (max-width: 610px) {
    padding: 1.5rem;
    flex-direction: column;
    gap: 1rem;
    .disc {
      right: 2.5rem;
      margin-left: 0;
    }
    .plan {
      .plan-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }
  }
`;
export const BottomSection = styled.div`
  width: 100%;
  background: ${({ active }) => (active ? '#EFFFDD' : '#ffffff')};
  padding: 1.5rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  color: #000000;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .active-date {
    color: #fc5b00;
  }
  .payment-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .underlined {
    cursor: pointer;
    text-decoration: underline;
  }
  .best-price-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .best-price-desc {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      p {
        color: #000000;
        font-weight: 900;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 1.5rem 2.5rem;
    .best-price-details {
      .best-price-desc {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  @media (max-width: 610px) {
    padding: 1.5rem;
    ${({ active }) =>
      active &&
      css`
        flex-direction: column;
        align-items: flex-start;
        gap: 0.8rem;
        .best-price-details {
          .best-price-desc {
            gap: 0.8rem;
          }
        }
      `}
  }
`;

export const BestPriceBadge = styled.div`
  background: #57bc01;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #ffffff;
  font-weight: 900;
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    position: relative;
    top: 0.1rem;
    left: -0.1rem;
    margin-right: 0.1rem;
    width: 26px;
    height: 26px;
  }
  .disc {
    background: #ffce00;
    color: #000000;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-left: 0.4rem;
    flex-direction: column;
    justify-content: center;
    .b2b-label {
      font-weight: 900;
      font-size: 0.4375rem;
      background: #ff5b00;
      color: #ffffff;
      border-top-right-radius: 4px;
      width: 100%;
      text-align: center;
    }
    .discount-number {
      margin-bottom: 0;
      font-weight: 900;
      padding: 0 0.6rem;
    }
  }
`;

export const StyledPopover = styled(Popover)`
  font-family: 'Muli';
  border: none;
  border-radius: 8px;
  max-width: 279px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 0.75rem;
  .popover-header {
    background: #57b905;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: none;
    color: #ffffff;
    text-align: center;
    font-weight: 900;
  }
  .popover-body {
    padding: 0.5rem 1rem 1rem 1rem;
  }
`;

export const BundleIcon = styled.span`
  background: #ffeea5;
  border-radius: 4px;
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  font-family: 'Helvetica Neue';
  padding: 0.2rem 0.3rem;
  margin-right: 0.3rem;
`;

export const TurnOnAutoRenewStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  flex-wrap: wrap;
  .best-icon {
    margin-top: 5px;
  }
  .green {
    color: #53ad06;
    font-weight: 900;
    margin: 0.3rem;
  }
  .orange {
    color: #ff5b00;
    text-decoration: underline;
    font-weight: 700;
    margin: 0.3rem;
    cursor: pointer;
  }
  .yellow {
    background: #ffce00;
    font-weight: 900;
    font-size: 0.6875rem;
    padding: 0.3rem;
  }
  @media (max-width: 610px) {
    gap: 0.3rem;
  }
`;
