import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FactualFluencyHeader from 'components/Leaderboard/FactualFluency/FactualFluencyHeader';
import StudentList from 'components/Leaderboard/StudentList';
import Spinner from 'components/Shared/Spinner';
import { isEmpty } from 'ramda';

import { getFactualFluencyLeaderboard } from 'store/factualFluencyLeaderboard/factualFluencyLeaderboardSlice';

import { getAssignmentDetails } from 'store/assignment/assignmentSlice';
import { Container, NoParticipant } from './FactualFluency.styles';

const FactualFluency = ({ match }) => {
  const HomeworkId = parseInt(match.params.HomeworkId, 10);
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { userID } = useSelector((state) => state.login);
  const {
    factualFluencyLeaderboard,
    factualFluencyLeaderboardLoading,
  } = useSelector((state) => state.factualFluencyLeaderboard);

  const { startAssignmentInfo } = useSelector((state) => state.assignment);
  // const [isTopFifty, setIsTopFifty] = useState(false);
  const [studentData, setStudentData] = useState([]);

  useEffect(() => {
    dispatch(getFactualFluencyLeaderboard(HomeworkId));
    dispatch(getAssignmentDetails(HomeworkId));
  }, [dispatch, HomeworkId]);

  // to check if user is in top 50
  useEffect(() => {
    if (!isEmpty(factualFluencyLeaderboard)) {
      // const isUser = factualFluencyLeaderboard.find(
      //   (stu) => stu.UserId === userID
      // );
      // // if (isUser) {
      // //   setIsTopFifty(isUser.LeaderBoardRank <= 50);
      // // } else {
      // //   setIsTopFifty(true);
      // // }
      setStudentData(factualFluencyLeaderboard);
    } else {
      setStudentData([]);
    }
  }, [factualFluencyLeaderboard, userID]);
  // const toggleBreakdownData = (index) => {
  //   const tempData = { ...showBreakdownData };
  //   tempData[index] = !tempData[index];
  //   setShowBreakdownData(tempData);
  // };
  return (
    <Container>
      {startAssignmentInfo && (
        <FactualFluencyHeader homework={startAssignmentInfo} />
      )}
      {factualFluencyLeaderboardLoading && <Spinner />}
      {studentData.length > 0 && (
        <StudentList
          challengeMode="factualFluency"
          reducer="factualFluencyLeaderboard"
          data={studentData}
          isTopFifty
        />
      )}
      {!factualFluencyLeaderboardLoading && studentData.length === 0 && (
        <NoParticipant>
          {t(
            'leaderboard:hodBanner.noParticipant',
            'No participants at the moment.'
          )}
        </NoParticipant>
      )}
    </Container>
  );
};
export default React.memo(FactualFluency);
