import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import {
  fetchUserProducts,
  fetchFeaturesBySubscriptionProductID,
  fetchGlobalFeatureConfig,
  fetchUserActiveSubscriptions,
} from 'services/products';
import { isEmpty, isNil } from 'ramda';
import moment from 'moment-timezone';
import { getMockTime } from 'store/timer/timerSlice';
import { productTypeHierarchy, subjectIDs } from 'constants/products';
import { compareValues, compareDate } from 'helpers/compareValue';
import { setIsExpired } from 'store/dashboard/studentDetailsSlice';

const isDev = process.env.REACT_APP_DEV === 'true';
// Helpers
const checkIfPremiumSchool = (products) => {
  const hasPremium = !isNil(
    products.find(
      (product) => product.productType.toLowerCase().trim() === 'premium'
    )
  );
  const hasSchool = !isNil(
    products.find(
      (product) => product.productType.toLowerCase().trim() === 'school'
    )
  );
  return hasPremium && hasSchool;
};

const checkIfSchoolOnly = (products) => {
  const noPremium = isNil(
    products.find(
      (product) => product.productType.toLowerCase().trim() !== 'school'
    )
  );
  const hasSchool = !isNil(
    products.find(
      (product) => product.productType.toLowerCase().trim() === 'school'
    )
  );
  return noPremium && hasSchool;
};

const checkHasOTPBefore = (subs) => {
  const hasMathOTP = !isNil(
    subs?.subscriptionProducts.find(
      (item) =>
        item.subscriptionDetails.IsOTPTrial === true && item.subjectId === 1
    )
  );
  const hasSciOTP = !isNil(
    subs?.subscriptionProducts.find((item) => item.subjectId === 2)
  );

  if (subs.id === 1) {
    return hasMathOTP;
  }
  if (subs.id === 2) {
    return hasSciOTP;
  }
  return false;
};

const checkExpiredSchool = (nonExpired, subs) => {
  const noB2CActive = isNil(
    nonExpired.find(
      (product) => product.productType.toLowerCase().trim() !== 'school'
    )
  );
  const otpB2CActive =
    nonExpired?.find(
      (product) => product.productType.toLowerCase().trim() !== 'school'
    )?.subscriptionDetails.IsOTPTrial ?? false;

  const expiredSchool = !isNil(
    subs.find(
      (product) => product.productType.toLowerCase().trim() === 'school'
    )
  );

  return (noB2CActive && expiredSchool) || (otpB2CActive && expiredSchool);
};

const otpStatus = (prod) => {
  const premium = prod.subscriptionProducts
    .filter((item) => item.productType.toLowerCase().trim() === 'premium')
    .sort(compareDate('startDate', 'asc'));
  const school = prod.subscriptionProducts.filter(
    (item) => item.productType.toLowerCase().trim() === 'school'
  );
  const getTrialProd = premium.find((item) => item.isTrial);
  const getNonTrial = premium.find((item) => !item.isTrial);
  const subject = prod.subject.toLowerCase().trim();
  const isExpiredPremium = isNil(
    prod.nonExpiredSubscriptionProducts.find(
      (item) => item.productType.toLowerCase().trim() === 'premium'
    )
  );
  const isExpiredOTP = !isNil(
    prod?.expiredSubscriptionProducts?.find(
      (item) =>
        item.productType.toLowerCase().trim() === 'premium' &&
        item.subscriptionDetails.IsOTPTrial
    )
  );
  const isUpgradePlan =
    premium.length === 2 &&
    !isNil(getNonTrial) &&
    getNonTrial.isExpired &&
    moment(getNonTrial?.startDate).isBefore(moment(getTrialProd?.startDate));

  if ((!isEmpty(school) && subject === 'math') || subject === 'science') {
    if (
      !isNil(getTrialProd) &&
      premium.length > 1 &&
      isExpiredPremium &&
      !isUpgradePlan
    ) {
      return 'otp-purchased-expired';
    }

    if (
      !isNil(getTrialProd) &&
      (premium.length === 1 || isUpgradePlan) &&
      ((subject === 'math' && isExpiredOTP) ||
        (subject === 'science' && isExpiredPremium))
    ) {
      return 'otp-trial-expired';
    }

    if (!isNil(getTrialProd) && premium.length > 1 && !isExpiredPremium) {
      return 'otp-purchased';
    }

    if (!isNil(getTrialProd) && premium.length === 1 && !isExpiredPremium) {
      return 'otp-trial-ongoing';
    }

    return 'otp-not-started';
  }
  return null;
};

const parseProducts = (
  userProducts,
  countryProducts,
  productFeatures,
  mockTime,
  subscriptionPlans
) => {
  // Parse API Data
  const parsedProducts = [];
  userProducts
    .filter(
      (subscriptionProduct) =>
        !isNil(subscriptionProduct.productParentId) &&
        !isNil(subjectIDs[subscriptionProduct.subjectId])
    )
    .forEach((subscriptionProduct) => {
      const productIndex = parsedProducts.findIndex(
        (product) => product.id === subscriptionProduct.productParentId
      );
      const countryProduct = countryProducts.find(
        (product) => product.id === subscriptionProduct.productParentId
      );
      const isSubscriptionProductExpired =
        mockTime.isBefore(moment(subscriptionProduct.endDate)) === false;
      const subscriptionProductHierarchy =
        productTypeHierarchy[
          subscriptionProduct.productType.toLowerCase().trim()
        ] ?? 99;
      const subscriptionPlanData = subscriptionPlans.find(
        (subscription) =>
          subscription.Contract_Subscription_Id ===
          subscriptionProduct.subscriptionId
      );
      const newSubscriptionProduct = {
        ...subscriptionProduct,
        isExpired: isSubscriptionProductExpired,
        hierarchy: subscriptionProductHierarchy,
        subject: subjectIDs[subscriptionProduct.subjectId],
        features:
          productFeatures.find(
            (subProduct) => subProduct.id === subscriptionProduct.productId
          )?.features ?? [],
        subscriptionDetails: subscriptionPlanData ?? {},
      };
      // Product doesn't exist in array
      if (productIndex === -1) {
        parsedProducts.push({
          id: subscriptionProduct.productParentId,
          name: 'KooBits',
          subject: newSubscriptionProduct.subject,
          subscriptionProducts: [newSubscriptionProduct],
          trialAvailable: countryProduct?.trialAvailable ?? null,
        });
      } else {
        parsedProducts[productIndex].subscriptionProducts.push(
          newSubscriptionProduct
        );
      }
    });
  countryProducts.forEach((product) => {
    const productExists = parsedProducts.find(
      (parsedProduct) => parsedProduct.id === product.id
    );
    if (isNil(productExists) && product.trialAvailable === true) {
      parsedProducts.push({
        id: product.id,
        name: product.name,
        subject: product.subject,
        subscriptionProducts: [],
        trialAvailable: product.trialAvailable,
      });
    }
  });
  for (let i = 0; i < parsedProducts.length; i += 1) {
    // Sort subscription products by end date
    parsedProducts[i].subscriptionProducts = parsedProducts[
      i
    ].subscriptionProducts.sort(compareDate('endDate', 'desc'));
    // Sort all subscription products by plan hierarchy
    parsedProducts[i].subscriptionProducts = parsedProducts[
      i
    ].subscriptionProducts.sort(compareValues('hierarchy', 'asc'));
    parsedProducts[i].expiredSubscriptionProducts = parsedProducts[
      i
    ].subscriptionProducts.filter(
      (subscription) => subscription.isExpired === true
    );
    parsedProducts[i].nonExpiredSubscriptionProducts = parsedProducts[
      i
    ].subscriptionProducts.filter(
      (subscription) => subscription.isExpired !== true
    );
    parsedProducts[i].hasPremiumSchool = checkIfPremiumSchool(
      parsedProducts[i].nonExpiredSubscriptionProducts
    );
    parsedProducts[i].otpTrialDetails = {
      triedOTP: checkHasOTPBefore(parsedProducts[i]),
      otpStatus: otpStatus(parsedProducts[i]),
    };
    parsedProducts[i].hasSchoolOnly = checkIfSchoolOnly(
      parsedProducts[i].nonExpiredSubscriptionProducts
    );
    parsedProducts[i].hasExpiredSchool = checkExpiredSchool(
      parsedProducts[i].nonExpiredSubscriptionProducts,
      parsedProducts[i].subscriptionProducts
    );
  }
  return parsedProducts;
};
const parseFeatureParameters = (parametersJSONString) => {
  let json;
  try {
    json = JSON.parse(parametersJSONString);
  } catch (err) {
    console.log(err.message);
  }
  return json;
};
const currentSubscriptionProduct = (
  savedProductPreference,
  products,
  userProducts
) => {
  // Check if saved product preference exists in non-expired user products
  const userProductPreferenceExists = isNil(savedProductPreference?.Value)
    ? null
    : userProducts.find(
        (product) => product.productId === Number(savedProductPreference.Value)
      );
  if (!isNil(userProductPreferenceExists)) {
    // Get product of that user product belongs to
    const product = products.find(
      (product) => product.id === userProductPreferenceExists.productParentId
    );
    // Check if product has both school & premium non-expired products
    const hasPremiumSchool = product.hasPremiumSchool === true;
    if (hasPremiumSchool) {
      return product.nonExpiredSubscriptionProducts.find(
        (subProduct) =>
          subProduct.productId === userProductPreferenceExists.productId
      );
    }
    // Return highest non-expired plan
    return product.nonExpiredSubscriptionProducts[0];
  }
  // Filter products with at least 1 non-expired plan
  const productsWithNonExpiredSubs = products.filter(
    (product) => product.nonExpiredSubscriptionProducts.length > 0
  );
  if (productsWithNonExpiredSubs.length > 0) {
    return productsWithNonExpiredSubs[0].nonExpiredSubscriptionProducts[0];
  }
};

// Redux Thunks
export const getProducts = createAsyncThunk(
  'plan/getProducts',
  async (_, { dispatch, getState }) => {
    // Get state from store
    const { studentDetails } = getState();

    const subscriptionPlans =
      studentDetails?.studentDetails?.Subscriptions ?? [];
    const userPreferences =
      studentDetails?.studentDetails?.UserPreferences ?? [];
    const currentUserProductPreference = userPreferences.find(
      (preference) =>
        preference.Name.toLowerCase().trim() === 'subscription_default'
    );
    const countryProducts = [];
    // disable cause we are no longer use this to show the available subjects on toggle. Currently being hardcoded. Can activate again when needed
    // const currentUserCountryID =
    //   subscriptionPlans.length > 0 ? subscriptionPlans[0].ISOCode2 : null;
    // try {
    //   countryProducts = await fetchWrapper(
    //     fetchProductsByCountry,
    //     currentUserCountryID
    //   );
    // } catch (error) {
    //   console.log(error);
    //   countryProducts = [];
    // }
    try {
      const userProducts = await fetchWrapper(fetchUserProducts);
      if (isDev) {
        await dispatch(getMockTime());
      }
      const mockTime = moment(getState().timer.mockTime.data);
      const cleanUserProducts = userProducts.filter(
        (subscriptionProduct) =>
          !isNil(subscriptionProduct.productParentId) &&
          !isNil(subjectIDs[subscriptionProduct.subjectId])
      );
      const nonExpiredUserProducts = cleanUserProducts.filter((product) =>
        mockTime.isBefore(moment(product.endDate))
      );
      const productFeatures = await nonExpiredUserProducts.reduce(
        async (previousFeatures, subProduct) => {
          const collection = await previousFeatures;
          const subProductFeatures = await fetchWrapper(
            fetchFeaturesBySubscriptionProductID,
            subProduct.productId
          );
          const parsedSubProductFeatures = subProductFeatures.map(
            (subProductFeature) => {
              const parsedFeatureParameters = parseFeatureParameters(
                subProductFeature.parameters
              );
              return {
                ...subProductFeature,
                parameters: isNil(parsedFeatureParameters)
                  ? {}
                  : parsedFeatureParameters,
              };
            }
          );
          return [
            ...collection,
            {
              id: subProduct.productId,
              features: parsedSubProductFeatures,
            },
          ];
        },
        Promise.resolve([])
      );
      // Parse API Data
      const parsedProducts = parseProducts(
        cleanUserProducts,
        countryProducts,
        productFeatures,
        mockTime,
        subscriptionPlans
      );
      const currentProduct = currentSubscriptionProduct(
        currentUserProductPreference,
        parsedProducts,
        nonExpiredUserProducts
      );
      // TODO: Set expiry state if user does not have any non-expired products
      if (isNil(currentProduct)) {
        dispatch(setIsExpired(true));
      }
      return {
        countryProducts,
        userProducts,
        currentProduct,
        parsedProducts,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Get products failed');
    }
  }
);

export const getGlobalFeatures = createAsyncThunk(
  'plan/getGlobalFeatures',
  async () => {
    try {
      const res = await fetchGlobalFeatureConfig();
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get global features failed');
    }
  }
);

export const getActiveSubscriptions = createAsyncThunk(
  'plan/getActiveSubscriptions',
  async () => {
    try {
      const res = await fetchWrapper(fetchUserActiveSubscriptions);
      return res.data;
    } catch (error) {
      throw new Error(error?.message ?? 'Get active subscriptions failed');
    }
  }
);
const initialState = {
  plan: 'plus',
  subject: 'math',
  currentProduct: null,
  isLoading: false,
  countryProducts: null,
  userProducts: null,
  products: null,
  getProductsError: null,
  // Takes precedence over product level feature config from backend
  globalFeatureConfig: {},
  allFeatures: null,
  prevProduct: null,
  isRedirect: true,
  currentExpiredProduct: null,
  activeSubscriptions: {
    isLoading: false,
    error: null,
    data: null,
  },
};

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setSubject: (state, action) => {
      state.subject = action.payload;
    },
    setCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;
      state.allFeatures = action.payload?.features;
    },
    setPrevProduct: (state, action) => {
      state.prevProduct = action.payload;
    },
    setIsRedirect: (state, action) => {
      state.isRedirect = action.payload;
    },
    setCurrentExpiredProduct: (state, action) => {
      state.currentExpiredProduct = action.payload;
    },
    setPlanSubjectProduct: (state, action) => {
      state.plan = action.payload.plan;
      state.subject = action.payload.subject;
      state.currentProduct = action.payload.product;
      state.allFeatures = action.payload.features;
    },
  },
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.isLoading = true;
      state.countryProducts = null;
      state.userProducts = null;
      state.getProductsError = null;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.countryProducts = action.payload.countryProducts;
      state.userProducts = action.payload.userProducts;
      state.currentProduct = action.payload.currentProduct;
      state.allFeatures = action.payload.currentProduct?.features;
      state.products = action.payload.parsedProducts;
      if (!isNil(action.payload.currentProduct)) {
        state.plan = action.payload.currentProduct.productType
          .toLowerCase()
          .trim();
        state.subject = action.payload.currentProduct.subject
          .toLowerCase()
          .trim();
      } else {
        const mathProducts = action.payload.parsedProducts.filter(
          (product) =>
            product.subject.toLowerCase().trim() === 'math' &&
            product.subscriptionProducts.length > 0
        );
        if (mathProducts.length > 0) {
          state.plan = mathProducts[0].subscriptionProducts[0].productType
            .toLowerCase()
            .trim();
        } else {
          state.plan = 'premium';
        }
        const firstProducts = action.payload.parsedProducts[0];
        if (!isNil(firstProducts)) {
          state.subject = firstProducts.subject.toLowerCase().trim();
        } else {
          state.subject = 'math';
        }
      }
    },
    [getProducts.rejected]: (state, action) => {
      state.isLoading = false;
      state.getProductsError = action.error.message;
    },
    [getGlobalFeatures.pending]: () => {},
    [getGlobalFeatures.fulfilled]: (state, action) => {
      state.globalFeatureConfig = action.payload;
    },
    [getGlobalFeatures.rejected]: () => {},
    [getActiveSubscriptions.pending]: (state) => {
      state.activeSubscriptions.isLoading = true;
      state.activeSubscriptions.error = null;
      state.activeSubscriptions.data = null;
    },
    [getActiveSubscriptions.fulfilled]: (state, action) => {
      state.activeSubscriptions.isLoading = false;
      state.activeSubscriptions.data = action.payload;
    },
    [getActiveSubscriptions.rejected]: (state, action) => {
      state.activeSubscriptions.isLoading = false;
      state.activeSubscriptions.error = action.error.message;
    },
  },
});

const { actions, reducer } = planSlice;
export const {
  setPlan,
  setSubject,
  setCurrentProduct,
  setPrevProduct,
  setIsRedirect,
  setCurrentExpiredProduct,
  setPlanSubjectProduct,
} = actions;
export default reducer;
