import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Solution from 'components/Question/Solution';
import { Container } from './QnViewSolution.styles';

const QnViewSolution = () => {
  const { t } = useTranslation(['dailyChallengeQnView']);
  const {
    activeQsDailyChallenge,
    dailyChallengeQuestion,
    dailyChallengeSubmissions,
  } = useSelector((state) => state.dailyChallenge);
  const { voiceOverFileExist } = useSelector((state) => state.voiceover);
  return (
    <Container voiceOverFileExist={voiceOverFileExist}>
      <p className="solution">
        {t('dailyChallengeQnView:solution', 'Solution ')}
      </p>
      <Solution
        xml={
          dailyChallengeSubmissions[activeQsDailyChallenge - 1]
            .SubmissionModel !== null
            ? dailyChallengeSubmissions[activeQsDailyChallenge - 1]
                .SubmissionModel.Payload
            : dailyChallengeQuestion[activeQsDailyChallenge - 1].Payload
        }
      />
    </Container>
  );
};

export default QnViewSolution;
