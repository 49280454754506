import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f5fdff;
  border-radius: 8px;
  padding: 1rem 0;
  margin-bottom: 0.8rem;
  .bold {
    font-weight: 700;
  }
`;

export const ReasonContainer = styled.div`
  border-bottom: 1px solid #d0ebf2;
  padding: 0 2.625rem;
  .reason-title {
    font-size: 1rem;
    font-weight: 700;
    color: #050505;
  }
  .reason-content {
    display: flex;
    .miko {
      margin-top: -2rem;
    }
    .reason-container {
      width: 80%;
      .reason-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        .reason {
          margin-left: 0.8rem;
        }
      }
    }
  }
  @media (max-width: 1440px) {
    .reason-container {
      .reason-item {
        .first {
          margin-top: 5px;
        }
      }
    }
  }
  @media (max-width: 1246px) {
    .reason-content {
      align-items: flex-end;
    }
  }
  @media (max-width: 610px) {
    padding: 1rem 1.75rem 0;
    .reason-content {
      .miko {
        display: none;
      }
      .reason-container {
        width: 100%;
        .reason-item {
          width: 100%;
          font-size: 0.875rem;
        }
      }
    }
  }
`;

export const FaqContainer = styled.div`
  padding: 1rem 2.625rem 0;
  .faq-title {
    font-size: 0.875rem;
    color: #729ca7;
    font-weight: 700;
  }

  .faq-item {
    margin-bottom: 1rem;
    font-size: 0.75rem;
    p {
      margin: 0;
    }
  }
  @media (max-width: 610px) {
    padding: 1rem 1.75rem 0;
  }
`;
