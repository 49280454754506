const lowercaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

// Converts all key names in json object to have first letter in lowercase (CamelCase) e.g. KeyName => keyName
const camelcaseJsonKeys = (obj) => {
  const output = {};
  const keys = Object.keys(obj);
  keys.forEach((i) => {
    if (Object.prototype.toString.apply(obj[i]) === '[object Object]') {
      output[lowercaseFirstLetter(i)] = camelcaseJsonKeys(obj[i]);
    } else {
      output[lowercaseFirstLetter(i)] = obj[i];
    }
  });
  return output;
};

export default camelcaseJsonKeys;
