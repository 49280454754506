import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: white;
  border-bottom: 0.01rem solid #d5d5d5;
  padding: 0 10%;
  position: fixed;
  /* width: 100%; */
  width: ${(props) =>
    props.showSkillDetails ? 'calc(100vw - 330px)' : '100vw'};
  margin-top: 69px;
  box-shadow: rgba(129, 129, 129, 0.16) 0px 3px 6px;
`;

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: ${(props) => (props.isActive ? '2px solid #ff4e05' : 'none')};
  cursor: ${(props) => (props.isActive === false ? 'pointer' : 'auto')};
  .name {
    color: ${(props) => (props.isActive ? '#363636' : 'rgb(54,54,54,0.41)')};
    img {
      height: 22px;
      width: 22px;
      margin-right: 3px;
      filter: ${(props) => !props.isActive && 'grayscale(1)'};
    }
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    background: #eee;
  }
`;

export const TooltipIcon = styled.span`
  margin-left: 0.5rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 5px 10px;
  background: #e2e2e2;
  cursor: pointer;
`;
