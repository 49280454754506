import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabContainer, TabStyle } from './Tabs.styles';

const Tabs = ({ friendVal, incoming, activeTab, setActiveTab }) => {
  const { t } = useTranslation(['friends']);
  return (
    <TabContainer>
      <TabStyle
        onClick={() => setActiveTab('myFriends')}
        active={activeTab === 'myFriends'}
      >
        {t('friends:tabs.myFriends', 'My Friends')}{' '}
        <span className="count">{friendVal}</span>
      </TabStyle>
      <TabStyle
        onClick={() => setActiveTab('incoming')}
        active={activeTab === 'incoming'}
      >
        {t('friends:tabs.incomingRequest', 'Incoming Requests')}{' '}
        <span className="count">{incoming > 1000 ? '1k+' : incoming}</span>
      </TabStyle>
      <TabStyle
        onClick={() => setActiveTab('sent')}
        active={activeTab === 'sent'}
      >
        {t('friends:tabs.sentRequests', 'Sent Requests')}
      </TabStyle>
    </TabContainer>
  );
};

export default Tabs;
