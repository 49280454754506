import React from 'react';
import { useSelector } from 'react-redux';
import Avatar from 'assets/img/avatar.png';
import { Container, StatusContainer } from './ProfileName.styles';

const ProfileName = ({ status, avatar, displayName }) => {
  const { childrenProfile } = useSelector((state) => state.parentSettings);
  const statusInfo = () => {
    if (status === 'active' || status === 'auto-renew-off') {
      return {
        color: '#59A21B',
        border: 'rgb(73 154 0 / 46%)',
        bg: 'rgb(230 255 201 / 46%)',
        text: 'Active',
      };
    }
    if (status === 'expired') {
      return {
        color: '#FF0000',
        border: '#FF6F6F',
        bg: '#FFF5F5',
        text: 'Expired',
      };
    }
    return {
      color: '#FFFFFF',
      border: '#FFFFFF',
      bg: '#FFFFFF',
      text: '',
    };
  };
  return (
    <Container>
      <img
        src={avatar ?? Avatar}
        alt="avatar"
        onError={(e) => (e.target.src = Avatar)}
      />
      <p>{displayName || childrenProfile[0]?.DisplayName}</p>
      {status && (
        <StatusContainer
          bgColor={statusInfo(status).bg}
          color={statusInfo(status).color}
          border={statusInfo(status).border}
        >
          {statusInfo(status).text}
        </StatusContainer>
      )}
    </Container>
  );
};

export default ProfileName;
