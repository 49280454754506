import styled from 'styled-components';
import FullScoreBg from 'assets/img/kooquiz/icon-score-full.svg';
import NotFullScoreBg from 'assets/img/kooquiz/icon-score-not-full.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  .score-sign {
    background: url(${(props) =>
      props.fullScore ? FullScoreBg : NotFullScoreBg});
    width: 48px;
    height: 56px;
    position: absolute;
    margin-left: 114px;
    margin-top: 24px;
    color: ${(props) => (props.fullScore ? '#d29e10' : '#92591F')};
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    p {
      margin-bottom: 0px;
    }
    .score-text {
      font-size: 15px;
    }
    .score {
      font-size: 13px;
      span {
        color: #1c1107;
      }
    }
  }

  .completed-sign {
    background-color: #47bb6b;
    margin-left: 8px;
    width: 139px;
    color: #ffffff;
    text-align: center;
    align-self: center;
  }
  .book {
    height: 180px;
    width: 177px;
    position: relative;
    .pending-gift {
      background-image: url(${({ giftImg }) => giftImg});
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100%;
      width: 80px;
      height: 80px;
      left: -17px;
      bottom: ${({ isNeedAdjust }) => (isNeedAdjust ? '-9px' : '-6px')};
    }

    .print-section {
      position: absolute;
      background-color: #52bbff;
      width: 28px;
      height: 32px;
      border-radius: 0px 0px 50px 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0px;
      left: 23px;

      & > img {
        width: 15px;
      }
    }

    .overlay-open {
      transition: 0.3s all;
      position: absolute;
      width: 140px;
      height: 190px;
      top: 0;
      left: 13%;
      background: rgba(33, 33, 33, 0.42);
      color: #fff;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .button {
        color: #ffffff;
        border-radius: 30px;
        border: 1px solid rgb(255, 255, 255, 0.37);
        background: rgb(0, 0, 0, 0.37);
        padding: 2px 30px;
      }
      &:hover {
        opacity: 1;
      }
    }
    .paper {
      width: auto;
      height: 190px;
      margin-left: ${({ isNeedAdjust }) => (isNeedAdjust ? '16px' : '0px')};
    }
    .book-label {
      display: flex;
      position: absolute;
      border: 1px solid #ffdb87;
      background: #ffeec7;
      font-size: 13px;
      margin-left: 6px;
      width: 130px;
      align-items: center;
      padding: 4px 4px;
      justify-content: space-around;
      line-height: 1.1;
      margin-top: -40px;
      p {
        margin-bottom: 0px;
      }
      .paper-name {
        font-weight: 600;
      }
      span {
        font-size: 11px;
        color: #e29000;
      }
    }
  }
  .line {
    display: flex;
    justify-content: center;
    text-align: center;
    background: white;
    height: 5vh;
    &::before {
      content: '';
      height: 5vh;
      position: absolute;
      left: 0;
      right: 0;
      background: #ffffff;
      box-shadow: 8px 5px 10px 0px #e2e2e2;
    }
    .koko-credits {
      display: flex;
      align-items: center;
      font-weight: 600;
      color: rgb(19, 19, 19, 0.77);
      font-size: 13px;
      z-index: 8;
      .koko-icon {
        width: 28px;
        height: 28px;
        margin-right: 3px;
      }
      .progress {
        width: 120px;
        height: 12px;
        border-radius: 20px;
        background: #f6fbff;
        border: 1px solid rgb(112, 112, 112, 0.27);
        .bg-green {
          background: #51cb8e;
        }
      }
    }
  }
`;
