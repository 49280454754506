import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #c6efff;
  width: 100%;
  height: 100%;
  padding: 10px 30px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-bottom: 20px;
  .top-heading {
    text-align: center;
    color: #002e41;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
  }
`;

export const Lists = styled.div`
  display: flex;
  margin-bottom: 10px;
  border: 1px solid black;
  align-items: center;
  padding: 3px 25px 3px 2px;
  border: 1px solid;
  border-radius: 30px;
  position: relative;
  font-size: 14px;
  background-color: ${(props) => (props.pos % 2 === 0 ? '#def6ff' : '#ffffff')};
  border-color: ${(props) => (props.pos % 2 === 0 ? '#b9e6f7' : '#d4ecf5')};
  justify-content: space-between;
  .positions {
    color: #ffffff;
    background-color: #34c8ff;
    border: 1.5px solid #28a2d0;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    border-radius: 50rem;
    margin-right: 0.8rem;
  }
  .list-name {
    flex: 1;
  }
  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .list-points {
    flex: 2;
    font-size: 17px;
    font-weight: 700;
    img {
      margin: 0 5px;
    }
  }
  .list-achievement {
    flex: 1 1 0%;
  }
`;
