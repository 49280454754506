import React, { forwardRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, Spinner } from 'react-bootstrap';
import { isNil, isEmpty } from 'ramda';

import {
  setPlan,
  setSubject,
  setCurrentProduct,
  setPrevProduct,
} from 'store/plan/planSlice';
import { setIsExpired } from 'store/dashboard/studentDetailsSlice';

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

import { ReactComponent as School } from 'assets/img/plan-toggle/icon-school.svg';
import { ReactComponent as Premium } from 'assets/img/plan-toggle/icon-premium.svg';

import ModalContentXButton from 'components/Shared/ModalContentXButton';
import SwitchModalV2 from '../SwitchModalV2';

import { StyledToggle, Container, Arrow, RadioBtn } from './ToggleV2.styles';

const AVAILABLE_SUBJECT = [
  {
    id: 1,
    type: 'premium',
    subject: 'math',
  },
  {
    id: 2,
    type: 'premium',
    subject: 'science',
  },
  {
    id: 3,
    type: 'premium',
    subject: 'english',
  },
];

const ToggleBtn = ({ onClick }, ref) => {
  const { t } = useTranslation(['common']);
  const { subject, plan } = useSelector((state) => state.plan);
  return (
    <StyledToggle
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {plan === 'school' ? (
        <School width="76px" height="23px" />
      ) : (
        <Premium width="76px" height="23px" />
      )}
      {plan !== 'school' && (
        <p className="subject-name">
          {t(`common:subjects.${subject}`, capitalizeFirstLetter(subject))}
        </p>
      )}
      <Arrow subject={subject} />
    </StyledToggle>
  );
};

const ToggleV2 = ({ setShowLoader, setHasSeenLoader, setRedirectUrl }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    subject,
    getProductsError,
    products,
    isLoading,
    currentProduct,
    plan,
  } = useSelector((state) => state.plan);

  const [openSwitchModal, setOpenSwitchModal] = useState(false);
  const [selectedPlanSubject, setSelectedPlanSubject] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const schoolProducts =
    products?.filter((item) => item.hasPremiumSchool || item.hasSchoolOnly) ??
    [];
  const schoolSub =
    schoolProducts &&
    schoolProducts[0]?.nonExpiredSubscriptionProducts.find(
      (item) => item.productType.toLowerCase() === 'school'
    );

  const schoolToggleHandler = () => {
    if (plan !== 'school') {
      setRedirectUrl(null);
      setSelectedPlanSubject({ plan: 'school', subject: 'math' });
      setSelectedProduct(schoolSub);
      setOpenSwitchModal(true);
    }
  };

  const premiumToggleHandler = (item) => {
    const subjectProduct = products?.find(
      (data) => data.subject.toLowerCase() === item.subject
    );
    const availableSubs = subjectProduct?.subscriptionProducts ?? [];

    const nonExpiredPremium =
      subjectProduct?.nonExpiredSubscriptionProducts?.find(
        (subs) => subs.productType.toLowerCase() === item.type
      ) ?? null;
    setRedirectUrl(null);

    if (item.subject === 'english') {
      window.open('https://www.koobits.com/english/up', '_blank');
      return;
    }

    if (subject === item.subject && plan === item.type) {
      return;
    }

    if (isEmpty(availableSubs)) {
      dispatch(setSubject(item.subject));
      dispatch(setPlan(item.type));
      if (item.subject === 'science') {
        dispatch(setPrevProduct(currentProduct));
        history.push('/trial/science');
      } else {
        dispatch(setIsExpired(true));
        history.push(`/${item.subject}/expired`);
      }
    } else {
      if (!isNil(nonExpiredPremium)) {
        const activeSubs = subjectProduct.nonExpiredSubscriptionProducts.find(
          (subs) => subs.productType.toLowerCase() === item.type
        );
        setSelectedPlanSubject({
          plan: item.type,
          subject: item.subject,
        });
        setOpenSwitchModal(true);
        setSelectedProduct(activeSubs);
        return;
      }
      if (isNil(nonExpiredPremium)) {
        dispatch(setSubject(item.subject));
        dispatch(setPlan(item.type));
        dispatch(setPrevProduct(currentProduct));
        dispatch(setCurrentProduct(null));
        if (
          subjectProduct?.otpTrialDetails?.otpStatus === 'otp-trial-expired'
        ) {
          dispatch(setIsExpired(true));
          history.push(`/expired-otp/${item.subject}`);
        } else if (
          subjectProduct?.otpTrialDetails?.otpStatus === 'otp-not-started'
        ) {
          history.push(`/trial/${item.subject}`);
        } else {
          dispatch(setIsExpired(true));
          if (item.subject === 'science') {
            history.push('/expired-otp/science');
          } else {
            history.push(`/${item.subject}/expired`);
          }
        }
      }
    }
  };

  return (
    <>
      <ModalContentXButton
        showModal={openSwitchModal}
        closeModal={() => setOpenSwitchModal(false)}
        backdrop="static"
        containerMinWidth="388px"
      >
        <SwitchModalV2
          selectedPlanSubject={selectedPlanSubject}
          setModal={setOpenSwitchModal}
          setShowLoader={setShowLoader}
          setHasSeenLoader={setHasSeenLoader}
          setRedirectUrl={setRedirectUrl}
          selectedProduct={selectedProduct}
        />
      </ModalContentXButton>
      {isLoading && (
        <Spinner animation="border" variant="secondary" size="sm" />
      )}
      {!isLoading && !isNil(products) && isNil(getProductsError) && (
        <Container className="styled-toggle">
          <Dropdown.Toggle as={forwardRef(ToggleBtn)} id="custom-toggle" />
          <Dropdown.Menu>
            {!isEmpty(schoolProducts) && (
              <>
                <Dropdown.Item
                  className="first-item"
                  onClick={schoolToggleHandler}
                >
                  <School width="76px" height="23px" />
                  <RadioBtn active={plan === 'school'} />
                </Dropdown.Item>
                <Dropdown.Item>
                  <Premium width="76px" height="23px" />
                </Dropdown.Item>
              </>
            )}
            {AVAILABLE_SUBJECT.map((item, i) => (
              <Dropdown.Item
                key={item.id}
                className={`subject-item ${
                  i + 1 === AVAILABLE_SUBJECT.length && 'last-item'
                }`}
                onClick={() => premiumToggleHandler(item)}
              >
                {t(
                  `common:subjects.${item.subject}`,
                  capitalizeFirstLetter(item.subject)
                )}
                <RadioBtn
                  active={plan === 'premium' && subject === item.subject}
                />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Container>
      )}
    </>
  );
};

export default ToggleV2;
