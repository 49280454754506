import styled, { css } from 'styled-components';

const Container = styled.div`
  min-height: 363px;
  min-width: ${({ type }) =>
    type === 'left'
      ? 'calc(100vw * 0.11346998535)'
      : 'calc(100vw * 0.08638360175)'};
  background: rgba(255, 255, 255, 0.12);
  position: absolute;
  top: 161px;
  left: ${({ type }) => (type === 'left' ? '0' : null)};
  right: ${({ type }) => (type === 'right' ? '0' : null)};
  border-radius: ${({ type }) =>
    type === 'left' ? '0 30px 30px 0' : '30px 0 0 30px'};
  padding: 1rem 1rem;
  z-index: 10;
  @media (min-width: 2560px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    top: ${(props) => (props.type === 'left' ? '250px' : '275px')};
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    position: static;
    ${({ type }) => {
      if (type === 'right') {
        return css`
          min-height: auto;
          min-width: unset;
          background: transparent;
          display: flex;
          padding: 0;
        `;
      } else if (type === 'left') {
        return css`
          display: flex;
          min-height: unset;
          width: 94%;
          border-radius: 30px;
          justify-content: space-evenly;
          align-items: flex-end;
        `;
      }
    }}
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    ${({ type, overPhoneSize }) => {
      if (type === 'left') {
        if (overPhoneSize) {
          return css`
            display: none;
          `;
        }
        return css`
          width: 42vw;
          height: 42vw;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
        `;
      }
    }}
  }
  @media (max-height: 700px) and (min-width: ${({ theme }) =>
      theme.rwd.tablet + 1}px) {
    scale: ${({ type }) => (type === 'right' ? 0.87 : 0.8)};
    transform-origin: ${({ type }) => type} top;
  }
  @media (min-height: 800px) and (min-width: 1700px) {
    scale: ${({ type }) => (type === 'right' ? 1.3 : 1.1)};
    transform-origin: ${({ type }) => type} top;
  }
`;

export default Container;
