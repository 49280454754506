import React from 'react';
import { useSelector } from 'react-redux';
import { getAvatarBodyUrl } from 'helpers/avatarBody';
import { Spinner } from 'react-bootstrap';
import { KoKoMonster, SpinnerContainer } from './AvatarRenderer.style';

function AvatarRenderer({ body, horn, eyes, mouth, color, loading }) {
  const { avatarParts, isPartLoaded } = useSelector(
    (state) => state.avatarCreator
  );
  if (!isPartLoaded || loading) {
    return (
      <KoKoMonster>
        <SpinnerContainer>
          <Spinner animation="border" style={{ marginTop: '15px' }} />
        </SpinnerContainer>
      </KoKoMonster>
    );
  }
  return (
    <KoKoMonster>
      <img
        className="body"
        draggable={false}
        src={getAvatarBodyUrl(
          avatarParts.Bodys.find((b) => b.ID === body).Img,
          color
        )}
        alt=""
      />
      <img
        className="horn"
        draggable={false}
        src={avatarParts.Horns.find((h) => h.ID === horn).Img}
        alt=""
      />
      <img
        className="eyes"
        draggable={false}
        src={avatarParts.Eyes.find((e) => e.ID === eyes).Img}
        alt=""
      />
      <img
        draggable={false}
        className="mouth"
        src={avatarParts.Mouths.find((m) => m.ID === mouth).Img}
        alt=""
      />
    </KoKoMonster>
  );
}

export function AvatarRendererFromImage({ body, horn, eyes, mouth }) {
  return (
    <KoKoMonster>
      <img className="body" draggable={false} src={body} alt="" />
      <img className="horn" draggable={false} src={horn} alt="" />
      <img className="eyes" draggable={false} src={eyes} alt="" />
      <img className="mouth" draggable={false} src={mouth} alt="" />
    </KoKoMonster>
  );
}

export default React.memo(AvatarRenderer);
