import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Shared/Button';

const ChangeButton = ({ onClick, name, isDisabled }) => {
  const { t } = useTranslation(['mySettings']);
  return (
    <Button
      variant="primary"
      dataCy={`${name}-btn`}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {t('change', 'Change')}
    </Button>
  );
};
export default ChangeButton;
