import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import iconFactualFluency from 'assets/img/leaderboard/factual-fluency/icon-factual-fluency.png';
import moment from 'moment';
import BackButton from 'components/Shared/BackButton';
import {
  Topbar,
  Container,
  TitleHeading,
  TitleMeta,
} from './FactualFluencyHeader.styles';

const LeaderboardHeader = (props) => {
  const { homework } = props;
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  return (
    <Topbar>
      <div className="button-back">
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(0, 74, 137, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </div>
      <Container>
        <TitleHeading>
          <img src={iconFactualFluency} alt="factual-fluency-icon" />
          {homework.Title}
        </TitleHeading>
        <TitleMeta>
          <span>
            {`${t('assignmentList:topBar.AssignedBy', 'Assigned by')}`}:{' '}
            {homework.AssignerName}
          </span>
          <span>
            {`${moment(homework.StartDate).format('DD/MM/YYYY')}-${moment(
              homework.EndDate
            ).format('DD/MM/YYYY')}`}
          </span>
        </TitleMeta>
      </Container>
    </Topbar>
  );
};

export default React.memo(LeaderboardHeader);
