import handleResponse from 'helpers/handleAPIResponse';
import { isNil } from 'ramda';

const baseUrl = process.env.REACT_APP_NOTIFICATION_API_DOMAIN;

// temp service
export const fetchUserStaticNotification = () => {
  return fetch(`${baseUrl}StaticUserNotification`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get static user notification failed'); // eslint-disable-line
    });
};

export const fetchUserNotification = () => {
  return fetch(`${baseUrl}Notification/User`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!isNil(result.Result)) {
        throw new Error(result.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get user notification failed'); // eslint-disable-line
    });
};

export const fetchNotificationDetails = (queryIds) => {
  return fetch(`${baseUrl}Notification${queryIds}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!isNil(result.Result)) {
        throw new Error(result.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get user static notification failed'); // eslint-disable-line
    });
};

export const postMarkNotification = (id) => {
  return fetch(`${baseUrl}Notification/MarkNotification/${id}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!isNil(result.Result)) {
        throw new Error(result.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Mark notification failed'); // eslint-disable-line
    });
};
