import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QnSideBar from 'components/Assignment/AssignmentView/QnSideBar';
import ClaimKokoCreditBar from 'components/Assignment/AssignmentFinish/ClaimKokoCreditBar';
import Spinner from 'components/Shared/Spinner';
import PopupModal from 'components/Shared/PopupModal';
import ErrorModal from 'components/Shared/ErrorModal';
import { SubmitModal } from 'components/Shared';
import ClaimedCPModal from 'components/Events/ResultPage/ClaimedCPModal';
import QuestionContent from 'components/Question';
import LeaderboardIcon from 'assets/img/assignment/icon-leaderboard.svg';
import CPIcon from 'assets/img/events/cps.svg';
import KokoIcon from 'assets/img/icon-koko-credits.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  claimKoKoCredits,
  getAssignmentDetails,
  getAssignmentSolutions,
  setActiveQuestion,
  resetSolution,
} from 'store/assignment/assignmentSlice';
import { resetClaimCP, setActiveEventQuestion } from 'store/event/eventSlice';
import moment from 'moment';
import {
  questionTypeIDs,
  assignmentTypeIDs,
  scoreColorsMap,
} from 'constants/index';
import { history } from 'Routes';
import ClaimedKoKoModal from 'components/Events/ResultPage/ClaimedKokoModal';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';
import AssignmentHeader from 'components/Assignment/AssignmentSolution/AssignmentHeader';
import {
  Container,
  Main,
  QuestionContainer,
  ScoreContainer,
  CorrectAnswerSpan,
  NotebookContainer,
  Notebook,
  NoteBookTop,
  NotebookMiddle,
  NotebookBottom,
} from './AssignmentSolution.styles';

const AssignmentSolution = ({ match }) => {
  const { t } = useTranslation([
    'assignmentQnView',
    'assignmentSolution',
    'eventSolution',
  ]);
  const dispatch = useDispatch();
  const location = useLocation();
  const eventsQs = location.pathname === '/events/solution';
  const isTable = useMediaQuery({ maxWidth: rwd.tablet });
  const assignmentID = parseInt(match.params.assignmentID, 10);
  const assignmentSubmissionID = parseInt(
    match.params.assignmentSubmissionID,
    10
  );
  const {
    isLoading,
    isSolutionsLoading,
    isSolutionsError,
    error,
    startAssignmentInfo,
    solutions,
    localSavedAnswers,
    activeQuestion,
    claimKoKoCreditsError,
  } = useSelector((state) => state.assignment);
  const {
    eventIsLoading,
    isEventSolutionLoading,
    eventError,
    eventSolution,
    eventLocalSavedAns,
    activeQuestionEvent,
    claimCPResult,
  } = useSelector((state) => state.event);

  let loading;
  let solutionLoading;
  let allSolutions;
  let localSavAns;
  let activeQn;
  let assignmentType = null;
  let isErr = null;
  let setActive;
  if (eventsQs) {
    loading = eventIsLoading;
    setActive = setActiveEventQuestion;
    solutionLoading = isEventSolutionLoading;
    isErr = eventError;
    allSolutions = eventSolution;
    localSavAns = eventLocalSavedAns;
    activeQn = activeQuestionEvent;
    assignmentType = 'sundayMC';
    loading = eventIsLoading;
  } else {
    loading = isLoading;
    setActive = setActiveQuestion;
    solutionLoading = isSolutionsLoading;
    isErr = error;
    allSolutions = solutions;
    localSavAns = localSavedAnswers;
    activeQn = activeQuestion;
    assignmentType =
      startAssignmentInfo !== null
        ? assignmentTypeIDs[startAssignmentInfo.Type]
        : null;
    loading = isLoading;
    isErr = error;
  }

  const isPending = loading || solutionLoading;
  const assignmentTitle =
    startAssignmentInfo !== null ? startAssignmentInfo.Title : '';
  const score = allSolutions
    ? Math.floor(
        (allSolutions.CorrectAnswers / allSolutions.TotalQuestions) * 100
      )
    : 0;
  const refs = allSolutions
    ? allSolutions.UserQuestionSubmissions.reduce((acc, value) => {
        acc[value.Question.Id] = React.createRef();
        return acc;
      }, {})
    : null;

  const scrollTo = (questionID) => {
    const dims = refs[questionID].current.getBoundingClientRect();
    window.scrollTo({
      top: dims.top + window.pageYOffset - 130,
      behavior: 'smooth',
    });
  };

  // USEEFFECTS
  // Load solutions from API
  useEffect(() => {
    dispatch(resetClaimCP());
    if (assignmentID) dispatch(getAssignmentDetails(assignmentID));
    if (assignmentSubmissionID)
      dispatch(getAssignmentSolutions(assignmentSubmissionID));
    return () => {
      dispatch(setActiveQuestion(1));
      dispatch(resetSolution());
    };
  }, [assignmentID, assignmentSubmissionID, dispatch]);

  const [showModal, setShowModal] = useState(false);
  const [showClaimed, setShowClaimed] = useState(false);

  const [showModalKoko, setShowModalKoko] = useState(false);
  const [showClaimedKoko, setShowClaimedKoko] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const closeModalKoko = () => {
    setShowModalKoko(false);
  };

  const claimCPs = () => {
    setShowModal(false);
    // Redirect user to Sunday Mini Challenge Results page
    history.push(`/events/view-result/${allSolutions.SMCSubId}`);
    // setShowClaimed(true);
    // dispatch(claimCP(allSolutions.SMCSubId));
  };

  const claimKoko = () => {
    setShowModalKoko(false);
    dispatch(claimKoKoCredits(assignmentSubmissionID)).then((res) => {
      if (!res.error) {
        setShowClaimedKoko(true);
      }
    });
  };
  const reloadHandler = () => {
    if (isSolutionsError) {
      dispatch(getAssignmentSolutions(assignmentSubmissionID));
    }
    if (claimKoKoCreditsError) {
      claimKoko();
    }
  };
  return (
    <Container>
      <PopupModal show={showModal} backdrop="static">
        <SubmitModal
          header={t(
            'eventSolution:claimModal.claimPoints',
            'Claim Challenge Points'
          )}
          title={t(
            'eventSolution:claimModal.sureClaim',
            'Are you sure want to claim now?'
          )}
          desc={t(
            'eventSolution:claimModal.claimDesc',
            'You may claim you CPs between 6 am to 10 pm from Monday to Friday'
          )}
          noHandle={closeModal}
          yesHandle={claimCPs}
        />
      </PopupModal>
      <PopupModal show={showClaimed} backdrop="static">
        <ClaimedCPModal setShowClaimed={setShowClaimed} />
      </PopupModal>

      <PopupModal show={showModalKoko} backdrop="static">
        <SubmitModal
          header="Claim Koko"
          title="Are you sure want to claim now?"
          noHandle={closeModalKoko}
          yesHandle={claimKoko}
        />
      </PopupModal>
      <PopupModal show={showClaimedKoko} backdrop="static">
        <ClaimedKoKoModal
          setShowClaimed={setShowClaimedKoko}
          claimedKoko={allSolutions && allSolutions.HWAllocatedKokoCredits}
        />
      </PopupModal>
      <AssignmentHeader
        assignmentTitle={
          eventsQs
            ? t('assignmentQnView:header.smc', 'Sunday Mini Challenge')
            : assignmentTitle
        }
        assignmentId={eventsQs ? null : startAssignmentInfo?.Id}
        assignmentType={assignmentType}
        localSavedAnswers={localSavAns}
        questions={allSolutions?.UserQuestionSubmissions}
        activeQuestion={activeQn}
        page="solution"
        scrollToHandler={scrollTo}
        setActiveQn={setActive}
      />
      {!isTable && !isPending && allSolutions && isErr === null && (
        <QnSideBar
          localSavedAnswers={localSavAns}
          questions={allSolutions.UserQuestionSubmissions}
          activeQuestion={activeQn}
          page="solution"
          scrollToHandler={scrollTo}
          assignmentType={assignmentType}
        />
      )}
      <Main>
        {isPending && <Spinner color="#26B1EF" top="35%" />}
        {!isPending && (isErr !== null || claimKoKoCreditsError !== null) && (
          <ErrorModal
            reloadAction={reloadHandler}
            errorMessage={isErr || claimKoKoCreditsError}
          />
        )}
        {!isPending && allSolutions && isErr === null && (
          <NotebookContainer>
            <Notebook>
              <NoteBookTop>
                <div className="top-content">
                  <div className="info-section">
                    <p className="title">
                      {t('assignmentSolution:words.TotalScore', 'Total Score')}
                    </p>
                    <ScoreContainer color={scoreColorsMap(score)}>
                      <p className="number">{score}</p>
                      <p className="char">%</p>
                    </ScoreContainer>
                    <div className="qn-number">
                      <div style={{ display: 'flex' }}>
                        ({' '}
                        <CorrectAnswerSpan color={scoreColorsMap(score)}>
                          {allSolutions.CorrectAnswers}
                        </CorrectAnswerSpan>
                        <p>
                          {t('assignmentSolution:words.OutOf', ' out of ')}
                          <strong>{allSolutions.TotalQuestions}</strong>
                        </p>
                        )
                      </div>
                    </div>
                    <p className="completed-info">
                      {t(
                        'assignmentSolution:words.CompletedOn',
                        ' Completed on '
                      )}
                      {moment(allSolutions.LastCompletedDate).format(
                        'DD-MMM-YYYY'
                      )}
                    </p>
                  </div>
                  {assignmentType === 'factualfluency' && (
                    <div className="leaderboard-section">
                      <button
                        type="button"
                        className="leaderboard-btn"
                        onClick={() =>
                          history.push(
                            `/leaderboard/factual-fluency/${assignmentID}`
                          )
                        }
                      >
                        <img src={LeaderboardIcon} alt="Leaderboard" />
                        {t(
                          'assignmentSolution:words.Leaderboard',
                          'Leaderboard'
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </NoteBookTop>
              <NotebookMiddle>
                {eventsQs &&
                  allSolutions.CanBeClaimed &&
                  claimCPResult === null && (
                    <div className="claim-points">
                      <div>
                        {t(
                          'eventSolution:claimBar.forgotMsg1',
                          'You forgot to claim'
                        )}
                        <img src={CPIcon} alt="cp-points" />
                        <b>{allSolutions.ClaimedCPPoints}</b>
                        {t(
                          'eventSolution:claimBar.forgotMsg2',
                          'Cps for this Sunday Mini Challenge.'
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          history.push(
                            `/events/view-result/${allSolutions.SMCSubId}`
                          );
                        }}
                        className="claim-btn"
                      >
                        {t('eventSolution:claimBar.claimNow', 'Claim now!')}
                      </button>
                    </div>
                  )}
                {!eventsQs &&
                  startAssignmentInfo?.Type === 13 &&
                  allSolutions.CanBeClaimed && (
                    <div className="claim-points factual-fluency">
                      <div>
                        {t(
                          'eventSolution:claimBar.forgotMsg1',
                          'You forgot to claim'
                        )}
                        <img src={KokoIcon} alt="cp-points" />{' '}
                        <b>{allSolutions.HWAllocatedKokoCredits}</b> KoKo for
                        this homework.
                      </div>
                      <button
                        type="button"
                        onClick={() => setShowModalKoko(true)}
                        className="claim-btn"
                      >
                        {t('eventSolution:claimBar.claimNow', 'Claim now!')}
                      </button>
                    </div>
                  )}
                <div className="content">
                  {assignmentType === 'homework' &&
                    solutions.CanBeClaimed &&
                    solutions.HWAllocatedKokoCredits > 0 &&
                    solutions.CurrentMonthClaimedKokoCredits < 50 && (
                      <ClaimKokoCreditBar
                        kokoCreditsNumber={solutions.HWAllocatedKokoCredits}
                        claimRoute="/assignment/submitted/claimkoko/1"
                      />
                    )}
                  {allSolutions.UserQuestionSubmissions.map(
                    (question, index) => (
                      <QuestionContainer
                        key={question.Question.Id}
                        data-cy={question.Question.Id}
                        ref={refs[question.Question.Id]}
                        isLastQn={
                          allSolutions.UserQuestionSubmissions.length ===
                          index + 1
                        }
                      >
                        <p className="qn-no">
                          {t('assignmentSolution:words.Question', 'Question')}{' '}
                          {index + 1}{' '}
                          <span className="qn-id">
                            ({t('assignmentQnView:main.qnId', 'Qn ID')}{' '}
                            {question && question.Question.Id})
                          </span>
                        </p>
                        <QuestionContent
                          page="solution"
                          questionType={
                            questionTypeIDs[question.Question.QuestionType]
                          }
                          qnID={question.Question.Id}
                          xml={question.SubmissionModel.Payload}
                          submissionModel={question.SubmissionModel}
                          showCheckAnswer={false}
                          showWorking
                          solutionString={question.SubmissionModel.Solution}
                          showSolution
                          showSubmissions
                          qnIndex={index}
                          assignmentType={assignmentType}
                          skillID={question.Question.SkillId}
                          showFeedbackButton
                        />
                      </QuestionContainer>
                    )
                  )}
                </div>
              </NotebookMiddle>
              <NotebookBottom />
            </Notebook>
          </NotebookContainer>
        )}
      </Main>
    </Container>
  );
};

export default AssignmentSolution;
