import styled from 'styled-components';
import ShelfSvg from 'assets/img/storyscience/shelf.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  .paper-sheet {
    position: relative;
    bottom: 200px;
    width: 200px;
    z-index: -1;
  }
  .locked {
    .overlay-locked {
      position: absolute;
      height: 200px;
      width: 150px;
      top: 0;
      left: 7px;
      background: rgba(33, 33, 33, 0.42);
      cursor: pointer;
    }

    .book-locked {
      position: absolute;
      margin-top: 8.5rem;
      margin-left: 7rem;
    }
  }

  .book {
    height: 186px;
    width: 250px;
    position: relative;
    top: 0.5rem;
    z-index: 4;

    .overlay-open {
      transition: 0.3s all;
      position: absolute;
      height: 200px;
      width: 150px;
      top: 0;
      left: 7px;
      background: rgba(33, 33, 33, 0.42);
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .button {
        color: #ffffff;
        border-radius: 30px;
        border: 1px solid rgb(255, 255, 255, 0.37);
        background: rgb(0, 0, 0, 0.37);
        padding: 2px 30px;
      }
      &:hover {
        opacity: 1;
      }
    }

    .paper {
      width: 150px;
      height: 200px;
      margin-left: 0.4rem;
      cursor: pointer;
      z-index: 3;
      /* &:hover {
        filter: ${(props) =>
        Number(props.submitStatus) === 2 ? 'blur(1.5px)' : 'blur(0)'};
        -webkit-filter: ${(props) =>
        Number(props.submitStatus) === 2 ? 'blur(1.5px)' : 'blur(0)'};
      } */
    }
    .book-label {
      display: flex;
      position: absolute;
      border: 1px solid #ffdb87;
      background: #ffeec7;
      font-size: 13px;
      margin-left: 6px;
      width: 130px;
      align-items: center;
      padding: 4px 4px;
      justify-content: space-around;
      line-height: 1.1;
      margin-top: -40px;
      p {
        margin-bottom: 0px;
      }
      .paper-name {
        font-weight: 600;
      }
      span {
        font-size: 11px;
        color: #e29000;
      }
    }
  }
  .line {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 50px;

    &::before {
      content: '';
      z-index: 2;
      height: 50px;
      position: absolute;
      background-repeat: repeat-x;
      left: 10rem;
      right: 0;
      background-image: url(${ShelfSvg});
      box-shadow: 0px 10px 7px -2px rgba(62, 86, 0, 1);
    }
  }
`;

export default {};
