import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #c6efff;
  width: 100%;
  height: 100%;
  padding: 10px 30px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-bottom: 20px;
  .top-heading {
    text-align: center;
    color: #002e41;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
  }
`;

export const Lists = styled.div`
  display: flex;
  margin-bottom: 10px;
  border: 1px solid black;
  align-items: center;
  padding: 3px 25px 3px 2px;
  border: 1px solid;
  border-radius: 30px;
  position: relative;
  font-size: 14px;
  background-color: ${(props) => (props.pos % 2 === 0 ? '#def6ff' : '#ffffff')};
  border-color: ${(props) => (props.pos % 2 === 0 ? '#b9e6f7' : '#d4ecf5')};
  justify-content: space-between;
  .positions {
    color: #ffffff;
    background-color: #34c8ff;
    border: 1.5px solid #28a2d0;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    border-radius: 50rem;
    margin-right: 0.8rem;
  }
  .list-name {
    flex: 1;
  }
  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .middle-flex {
    flex: 4;
  }
  .list-school {
    width: 30px;
    height: 30px;
    border: 1px solid #d4ecf5;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #ffffff;
    img {
      width: 100%;
      height: auto;
    }
  }
  .add-bff {
    background-color: #00bafd;
    border-radius: 15px;
    color: #ccf3ff;
    font-size: 14px;
    width: 100px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #46d4ff;
    }
    span {
      color: #ffffff;
      font-size: 17px;
    }
  }
  .invited-bff {
    background-color: #ff6701;
    border-radius: 15px;
    color: #ffffff;
    font-size: 14px;
    width: 100px;
    text-align: center;
  }
  .list-points {
    flex: ${(props) => (props.center ? '1' : '2')};
    font-size: 17px;
    font-weight: 700;
    /* padding-left: ${(props) => (props.center ? '5%' : '0')}; */
    img {
      margin: 0 5px;
    }
  }
  .superhero-icon {
    cursor: pointer;
  }
`;

export const TopTwentyStyle = styled.div`
  border-top: 1px dashed #1d91be;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 5px 20px;
  font-size: 19px;
  .details {
    flex: 0.7;
    color: #00729f;
  }
  .total {
    color: #455157;
    span {
      color: #000000;
      font-size: 25px;
    }
  }
`;

export const NoStd = styled.div`
  text-align: center;
  color: red;
`;
