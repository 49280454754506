import styled from 'styled-components';

export const Container = styled.div`
  background: #ffe18d;
  border: solid 4px #f6c12d;
  border-radius: 18px;
  width: 284px;
  min-height: 466px;
  position: relative;
  bottom: 2.2rem;
  text-align: center;
  padding: 2.5rem 0 1rem;
  font-weight: 600;
  .cp-text {
    font-size: 0.9375rem;
    padding: 0 1rem;
  }
  .footer {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .highlighted {
    color: #ff4e00;
  }
  .hod-banner {
    position: relative;
    top: 0.8rem;
  }
  .link-collection {
    all: unset;
    color: #ff4e00;
    text-decoration: underline;
    margin-top: 0.5rem;
    font-size: 14px;
  }
  .hod-footer {
    margin-top: 1.5rem;
  }
`;

export const Button = styled.button`
  border: none;
  background: #ff6701;
  color: white;
  font-size: 1.25rem;
  border-radius: 1rem;
  padding: 0 1rem;
  min-width: 170px;
  margin-top: ${(props) => (props.hasUnseenBadges ? '2rem' : '0')};
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
