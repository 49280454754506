import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem 1rem 0 1rem;
  text-align: center;
  height: 111px;
  width: 77px;
  cursor: ${(props) => (props.isFuture === true ? 'not-allowed' : 'pointer')};
  background: ${(props) =>
    props.isToday && props.isUnlocked !== true ? 'orange' : null};
  border-radius: 0.5rem;
  img {
    visibility: ${(props) => (props.isHidden === true ? 'hidden' : 'normal')};
  }
  .day-number {
    position: relative;
    bottom: 2rem;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .today-title {
    color: white;
    margin: 2rem 0 0 0;
  }
  .today-cps {
    color: white;
    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  .mini-banner {
    position: relative;
    bottom: 1rem;
  }
`;

export const DotIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  margin-left: auto;
  position: relative;
  top: 1rem;
  right: 0.5rem;
  z-index: 1;
`;
