import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 210px;
  justify-content: space-evenly;
  padding: 0rem 2rem;
  .info {
    display: flex;
    width: 100%;
    img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
    }
    .details {
      display: flex;
      flex-direction: column;
      margin-left: 1.2rem;
      p {
        margin-bottom: 0px;
      }
      .bff-name {
        color: #000000;
        font-size: 18px;
        font-weight: 600;
      }
      .bff-school {
        color: rgb(0, 0, 0, 0.71);
        font-size: 14px;
      }
      .bff-code {
        color: #000000;
        font-size: 14px;
        span {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
`;
