import styled from 'styled-components';

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 5rem;
  margin-right: 5rem;

  .related-topics,
  .topics {
    text-align: center;
  }

  .related-topics {
    margin-top: 2rem;
  }

  .topics {
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: #009cff;
  }
`;

export default {};
