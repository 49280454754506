import { isNil } from 'ramda';

const CompareTwoAnswersByLanguage = (value1, value2, language) => {
  let src = value1;
  let dest = value2;
  let result = false;
  if (isNil(src) || isNil(dest)) {
    return result;
  }
  language = language.toLowerCase();
  if (language !== 'english') {
    src = src.replace(' ', '');
    src = src.replace("'", '');
    src = src.replace('.', '');
    src = src.replace(',', '.');
    dest = dest.replace(' ', '');
    dest = dest.replace("'", '');
    dest = dest.replace('.', '');
    dest = dest.replace(',', '.');
  } else {
    src = src.replace(' ', '');
    src = src.replace("'", '');
    src = src.replace(',', '');
    dest = dest.replace(' ', '');
    dest = dest.replace("'", '');
    dest = dest.replace(',', '');
  }
  result = src.toLowerCase() === dest.toLowerCase();
  return result;
};

export default CompareTwoAnswersByLanguage;
