import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import { Rnd } from 'react-rnd';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkspaceSize } from 'store/assignment/barModelSlice';
import { barModelOptions } from 'constants/index';
import {
  hideSelectedTool,
  setToolPosition,
  setSelectedToolOnTop,
} from 'store/assignment/assistToolsSlice';
import { isNil } from 'ramda';
import AddWorking from './AddWorking';
import FourOpsTool from './FourOpsTool';
import BarModel from './BarModel';
import { Container, ToolNameContainer } from './AssistTools.styles';
import ResizeHandle from './BarModel/ResizeHandle';

const { workspace } = barModelOptions;
const { maxHeight, maxWidth, minHeight, minWidth } = workspace;

const AssistTools = ({ type, feature }) => {
  const dispatch = useDispatch();
  const barModel = useSelector((state) => state.barModel);
  const nodeRef = React.useRef(null);
  const { peerChallengeActiveQn } = useSelector((state) => state.multiplayer);
  const {
    toolsToShow,
    toolOnTop,
    barModelToolPosition,
    addWorkingsToolPosition,
    fourOpsToolPosition,
  } = useSelector((state) => state.assistTools);
  const toShow = !isNil(toolsToShow.find((tool) => tool === type));
  const { activeIndex } = barModel;
  const { width, height } = barModel.data;
  const { t } = useTranslation(['assistTools']);
  const toolNameBg = () => {
    if (type === 'bar-model') {
      return '#95D435';
    }
    if (type === 'add-working') {
      return '#ffc43b';
    }
    if (type === 'four-ops') {
      return '#FF9D1D';
    }
  };

  const toolNameColor = () => {
    if (type === 'bar-model') {
      return '#456515';
    }
    if (type === 'add-working') {
      return '#885F00';
    }
    if (type === 'four-ops') {
      return '#714102';
    }
  };

  const toolName = () => {
    if (type === 'bar-model') {
      return `${t('assistTools:barModal.addBar', 'Draw Bar Model')}${
        !isNil(activeIndex) && feature === 'peer-challenge'
          ? ` for Qn ${activeIndex + 1}`
          : ''
      }`;
    }
    if (type === 'add-working') {
      return `${t('assistTools:workingModal.addWorking', 'Add Working')}${
        feature === 'peer-challenge' ? ` for Qn ${peerChallengeActiveQn}` : ''
      }`;
    }
    if (type === 'four-ops') {
      return t('assistTools:fourOpsModal.addFourOps', 'Four Operations Tool');
    }
  };

  const ResizeComponent = () => {
    if (toShow) return <ResizeHandle />;
    return null;
  };

  const wrapper = useCallback(
    (c) => {
      if (type === 'bar-model') {
        return (
          <Rnd
            dragHandleClassName="drag-here"
            minHeight={minHeight}
            minWidth={minWidth}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            enableResizing={{ bottomRight: true }}
            resizeHandleComponent={{
              bottomRight: <ResizeComponent />,
            }}
            style={{
              zIndex: toolOnTop === type ? '20' : '11',
            }}
            resizeHandleStyles={
              toShow
                ? {
                    bottomRight: {
                      bottom: 0,
                      right: -6,
                      pointerEvents: 'auto',
                    },
                  }
                : null
            }
            size={{ width, height }}
            default={{
              x: window.innerWidth - width - 98, // default horizontal positioning
              y: 130,
            }}
            onDragStart={() => {
              dispatch(setSelectedToolOnTop(type));
            }}
            onDragStop={(e, d) => {
              dispatch(
                setToolPosition({
                  tool: 'bar-model',
                  position: { x: d.x, y: d.y },
                })
              );
            }}
            onResizeStop={(e, direction, ref) => {
              dispatch(
                setWorkspaceSize({
                  width: ref.offsetWidth,
                  height: ref.offsetHeight,
                })
              );
            }}
            position={barModelToolPosition}
            bounds="parent"
          >
            {c}
          </Rnd>
        );
      }
      return (
        <Draggable
          nodeRef={nodeRef}
          handle=".drag-here"
          bounds="parent"
          onStart={() => {
            dispatch(setSelectedToolOnTop(type));
          }}
          onStop={(e, d) => {
            dispatch(
              setToolPosition({
                tool: type,
                position: {
                  x: d.x,
                  y: d.y,
                },
              })
            );
          }}
          position={
            type === 'add-working'
              ? addWorkingsToolPosition
              : fourOpsToolPosition
          }
        >
          {c}
        </Draggable>
      );
    },
    [
      addWorkingsToolPosition,
      barModelToolPosition,
      dispatch,
      fourOpsToolPosition,
      height,
      toShow,
      toolOnTop,
      type,
      width,
    ]
  );

  return wrapper(
    <Container
      ref={nodeRef}
      className={type}
      toShow={toShow}
      type={type}
      onTop={type === toolOnTop}
    >
      {toShow && (
        <>
          <ToolNameContainer
            className="drag-here"
            bg={toolNameBg()}
            color={toolNameColor()}
          >
            <div>{toolName()}</div>
            <span
              className="close-btn"
              onClick={() => {
                dispatch(hideSelectedTool(type));
              }}
              onTouchStart={() => {
                dispatch(hideSelectedTool(type));
              }}
            >
              X
            </span>
          </ToolNameContainer>
          <div
            className="content"
            onClick={() => {
              dispatch(setSelectedToolOnTop(type));
            }}
          >
            <>
              {type === 'add-working' && <AddWorking feature={feature} />}
              {type === 'four-ops' && <FourOpsTool />}
              {type === 'bar-model' && <BarModel />}
            </>
          </div>
        </>
      )}
    </Container>
  );
};

export default AssistTools;
