import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ErrorModal from 'components/Shared/ErrorModal';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import { getBFFFriends } from 'store/leaderboard/bffLeaderboardSlice';
import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import { convertToTimezone } from 'pages/Leaderboard/v2/helper';
import { LABELS } from './constants';
import { Container, Headings } from '../List.styles';
import { Submission } from '../../../pages/BFF/components';
import { StyledLists } from './BFFList.styles';

const BFFList = ({ weekId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['leaderboard']);
  const { data, friendsObj } = useSelector((state) => state.bffV2);
  const { userID } = useSelector((state) => state.login);
  const [showFriends, setShowFriends] = useState({});
  const showPosition = (pos) => {
    if (pos < 4) {
      if (pos === 1) return 'first';
      if (pos === 2) return 'second';
      if (pos === 3) return 'third';
    }
    return 'default';
  };
  const handleSearchFriend = (userId) => {
    setShowFriends((prev) => ({ ...prev, [userId]: !prev[userId] }));
    if (!isEmpty(friendsObj[userId].data)) return;
    dispatch(getBFFFriends({ userId, weekId }));
  };
  return (
    <Container>
      <div className="list-headings">
        {LABELS.map((item) => (
          <Headings key={item.name} flex={item.flex}>
            {t(
              `leaderboard:leaderboardRanking.${item.translateKey}`,
              item.name
            )}
          </Headings>
        ))}
      </div>
      {data.map((item, i) => {
        const friends = friendsObj[item.userId];
        return (
          <Fragment key={item.userId}>
            <StyledLists
              data-cy={`data-rank-${i}`}
              className={item.userId === userID && 'current-user'}
              currPos={showPosition(item.leaderboardRank)}
              key={item.userId}
              pointer
              onClick={() => handleSearchFriend(item.userId)}
            >
              <div className="positions">{item.leaderboardRank}</div>
              <div className="list-name">
                {item.userName}
                <span className="bff-friends">
                  {t('leaderboard:bff.friends', ' & Friends')}
                </span>
              </div>
              <div className="list-school">
                <div className="country-img">
                  <img
                    src={`https://static.content.koobits.com/countryflags/${item.countryCode}.png`}
                    alt="flag"
                  />
                </div>
                {item.countryName}
              </div>
              <div className="list-points">
                <img src={CPIcon} alt="cps" />
                {item.cpPoints}
              </div>
              <div className="list-achievement">
                {convertToTimezone(item.lastSubmissionDate).format(
                  'DD-MMM-YYYY HH:mm'
                )}
              </div>
            </StyledLists>
            {!friends.isLoading && !isNil(friends.error) && (
              <ErrorModal
                errorMessage={friends.error}
                reloadAction={() => handleSearchFriend(item.userId)}
              />
            )}
            {showFriends[item.userId] && (
              <Submission data={friends.data} isLoading={friends.isLoading} />
            )}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default BFFList;
