import React from 'react';
import HornIcon from 'assets/img/avatarcreator/icon-horn.png';
import MouthIcon from 'assets/img/avatarcreator/icon-mouth.png';
import EyesIcon from 'assets/img/avatarcreator/icon-eyes.png';
import {
  ButtonPanelContainer,
  ButtonsContainer,
} from './AvatarButtonPanel.style';

const imagesTypeMap = {
  horn: HornIcon,
  mouth: MouthIcon,
  eyes: EyesIcon,
};

function AvatarButtonPanel({ top, children, type }) {
  return (
    <ButtonPanelContainer style={{ top }}>
      <ButtonsContainer>{children}</ButtonsContainer>
      <img alt="icon" src={imagesTypeMap[type]} />
    </ButtonPanelContainer>
  );
}

export default React.memo(AvatarButtonPanel);
