import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(p) => (p.isOnePage ? '0px' : '2rem')};
  .books {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-left: 15%;
    margin-right: 10%;
  }
`;
