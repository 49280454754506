import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withSubjectCheck } from 'helpers/hoc';
import Header from 'components/Header';
import { Content } from 'pages/Assignment/index.styles';
import useGlobalFeature from 'hooks/useGlobalFeature';
import SelfPractice from './SelfPractice';
import HotsIntermediate from './HotsIntermediate';
import CurriculumList from './CurriculumList';
import SelfPracticePlus from './MissionPlus/SelfPracticePlus';
const Mission = () => {
  const { isEnabled } = useGlobalFeature('mission');

  return (
    <div>
      <Header />
      <Content>
        <Switch>
          {!isEnabled && (
            <Redirect from="/mission" to="/maintenance-redirect" />
          )}
          <Route
            path="/mission/hots/:curriculumId"
            component={HotsIntermediate}
          />
          <Route
            exact
            path="/mission/curriculum-list"
            component={CurriculumList}
          />
          <Route
            exact
            path="/mission/self-practice/plus/:curriculumId"
            component={SelfPracticePlus}
          />
          <Route
            exact
            path="/mission/self-practice/plus/:subject/:curriculumId"
            component={({ match }) => {
              const CheckedComponent = () => {
                const subject = match.params.subject;
                const SubjectCheckedComponent = withSubjectCheck(
                  SelfPracticePlus,
                  subject
                );
                return subject ? <SubjectCheckedComponent /> : null;
              };
              return <CheckedComponent />;
            }}
          />
          <Route
            exact
            path="/mission/self-practice/:curriculumId"
            component={SelfPractice}
          />
          <Route
            exact
            path="/mission/self-practice/:subject/:curriculumId"
            component={({ match }) => {
              const CheckedComponent = () => {
                const subject = match.params.subject;
                const SubjectCheckedComponent = withSubjectCheck(
                  SelfPractice,
                  subject
                );
                return subject ? <SubjectCheckedComponent /> : null;
              };
              return <CheckedComponent />;
            }}
          />
        </Switch>
      </Content>
    </div>
  );
};

export default Mission;
