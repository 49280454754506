import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';

const textColor = '#757575';
const borderColor = '#b7b7b7';

const StyledDropdown = styled(Dropdown)`
  #bff-dropdown {
    border: solid 1px ${borderColor};
    background-color: #ffffff;
    color: ${textColor};
    border-radius: 0;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 23px;
    ::after {
      color: ${borderColor};
      position: absolute;
      right: 12px;
      top: 45%;
    }
  }

  .dropdown-item {
    color: ${textColor};
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
  }
`;

export default StyledDropdown;
