import React, { useEffect } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import { setPlan, setSubject, setCurrentProduct } from 'store/plan/planSlice';
import { setIsExpired } from 'store/dashboard/studentDetailsSlice';

import { trialExpiredConfig } from 'constants/otpTrialContent';
import { otpCampaignCode } from 'constants/trialBanner';
import { subjectNames } from 'constants/products';
import { paymentRenewUrl } from 'helpers/url/paymentRenewUrl';
import { compareDate } from 'helpers/compareValue';
import Header from 'components/Header';
import BackButton from 'components/Shared/BackButton';
import CountdownTimer from 'components/Science/CountdownTimer';

import PremiumPlanV2 from 'assets/img/plan-toggle/premium-plan.png';
import Lock from 'assets/img/science/science-locked-ui.svg';
import Children from 'assets/img/math/children.svg';

import {
  Page,
  Content,
  Locked,
  StyledButtonContainer,
  StyledBuyNowButton,
} from './OTPTrialExpired.styles';

const OTPTrialExpired = () => {
  const { t } = useTranslation(['scienceTrial', 'mathTrial']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { subjectName } = useParams();
  const { studentDetails, isExpired, isLoading } = useSelector(
    (state) => state.studentDetails
  );
  const { products, prevProduct, subject, plan, isRedirect } = useSelector(
    (state) => state.plan
  );

  const availableProd = products?.find(
    (item) => item.subject.toLowerCase().trim() !== subject
  );
  const discountNumber = subjectName === 'math' ? 45 : 25;

  const availableProdSubject =
    availableProd?.nonExpiredSubscriptionProducts[0]?.subject
      .toLowerCase()
      .trim() ?? null;
  const availableProdPlan =
    availableProd?.nonExpiredSubscriptionProducts[0]?.productType
      .toLowerCase()
      .trim() ?? null;

  const expiredSubject = availableProd?.subscriptionProducts[0]?.subject
    .toLowerCase()
    .trim();
  const expiredPlan = availableProd?.subscriptionProducts[0]?.productType
    .toLowerCase()
    .trim();

  const currentExpProd = products?.find(
    (item) => item.subject.toLowerCase() === subject
  );

  const sortedCurrentExpProd = currentExpProd
    ? [...currentExpProd.expiredSubscriptionProducts].sort(
        compareDate('startDate', 'desc')
      )
    : [];

  const isCampaignEligible =
    products &&
    !isEmpty(sortedCurrentExpProd) &&
    sortedCurrentExpProd[0]?.subscriptionDetails?.IsOTPTrial;
  const backHandler = () => {
    dispatch(setIsExpired(false));
    if (!isNil(prevProduct)) {
      dispatch(setSubject(prevProduct.subject.toLowerCase().trim()));
      dispatch(setPlan(prevProduct.productType.toLowerCase().trim()));
      dispatch(setCurrentProduct(prevProduct));
      dispatch(setIsExpired(prevProduct.isExpired));
      history.push(
        `/dashboard?subject=${prevProduct.subject
          .toLowerCase()
          .trim()}&plan=${prevProduct.productType.toLowerCase().trim()}`
      );
    }
    if (!isNil(availableProd)) {
      if (!isEmpty(availableProd.nonExpiredSubscriptionProducts)) {
        dispatch(setSubject(availableProdSubject));
        dispatch(setPlan(availableProdPlan));
        dispatch(
          setCurrentProduct(availableProd.nonExpiredSubscriptionProducts[0])
        );
        dispatch(
          setIsExpired(
            availableProd.nonExpiredSubscriptionProducts[0].isExpired
          )
        );
        history.push(
          `/dashboard?subject=${availableProdSubject}&plan=${availableProdPlan}`
        );
      } else {
        if (availableProd?.otpTrialDetails?.otpStatus === 'otp-not-started') {
          history.push('/trial/math');
          return;
        }
        dispatch(setSubject(expiredSubject));
        dispatch(setPlan(expiredPlan));
        dispatch(setCurrentProduct(null));
        dispatch(setIsExpired(true));
        history.push('/dashboard');
      }
    } else {
      dispatch(setIsExpired(true));
      history.push('/dashboard');
    }
  };

  useEffect(() => {
    if (isRedirect) {
      if (subject !== subjectName) {
        dispatch(setSubject(subjectName));
      }
      if (plan !== 'premium') {
        dispatch(setPlan('premium'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, plan, subjectName, subject]);
  const handleBuyNow = () => {
    return window.open(
      paymentRenewUrl({
        userId: studentDetails.UserId,
        bundleTypeId: subjectNames[subject],
        campaign: isCampaignEligible && otpCampaignCode[subject],
      })
    );
  };
  if (!isExpired && !isLoading) {
    return <Redirect to="/dashboard" />;
  }
  return (
    !isLoading && (
      <Page bg={trialExpiredConfig[subjectName].bg}>
        <Header toggleMenu={false} toggleAccesMenu={false} />
        {subjectName === 'science' && (
          <StyledButtonContainer>
            <BackButton
              btnBgColor="rgb(111, 204, 7, 1)"
              padding="0.4rem 1rem"
              backText={t('scienceTrial:back', 'Back')}
              onClick={backHandler}
            />
          </StyledButtonContainer>
        )}
        <Content>
          {subject === 'math' && (
            <img src={Children} alt="" className="children-mascot" />
          )}
          {subjectName === 'math' && (
            <img src={PremiumPlanV2} alt="premium-plan" />
          )}
          <h3 className="title">
            {t(
              `${trialExpiredConfig[subjectName].translationKey}:expired.title`,
              `${trialExpiredConfig[subjectName].title}`
            )}
          </h3>
          <h5 className="desc">
            {t(
              `${trialExpiredConfig[subjectName].translationKey}:expired.desc`,
              `${trialExpiredConfig[subjectName].desc}`
            )}
          </h5>
          <Locked>
            <div className="message">
              <img src={Lock} alt="science-locked" className="lock-icon" />
              <p>
                {t('scienceTrial:expired.message', 'Your trial has expired.')}
              </p>
            </div>
            <img
              src={trialExpiredConfig[subjectName].img}
              alt="expired"
              className="expired-img"
            />
          </Locked>
          <StyledBuyNowButton
            variant="primary"
            width="200px"
            padding="1rem 0.5rem"
            onClick={handleBuyNow}
          >
            <span className="title">
              {t('mathTrial:expired.buy', 'Buy Now')}
            </span>
            <span>
              <Trans
                i18nKey={`${trialExpiredConfig[subjectName].translationKey}:expired.buttonDesc`}
              >
                at {{ discountNumber }}% discount
              </Trans>
            </span>
          </StyledBuyNowButton>
          {subjectName === 'science' && <CountdownTimer />}
        </Content>
      </Page>
    )
  );
};

export default OTPTrialExpired;
