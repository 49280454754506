import styled from 'styled-components';

export const StyledNavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.57);
  z-index: 100;
  opacity: 0;
  animation-name: fadeBackground;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 0, 0.3, 1);
  animation-fill-mode: forwards;
  @keyframes fadeBackground {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

// Sample Navbar positioned right with slide in animation
export const StyledNavBar = styled.div`
  height: 100%;
  background: white;
  width: 400px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
`;
