import styled from 'styled-components';
import allBadge from 'constants/allBadge2';

export const Ranking = styled.div`
  color: ${({ currPos, userGender }) =>
    currPos === 'default'
      ? allBadge[currPos].badgeColor[userGender]
      : allBadge[currPos].badgeColor};
  background-image: ${({ currPos }) => `url(${allBadge[currPos].bgImg})`};
  position: absolute;
  margin-top: -25px;
  margin-left: ${({ currPos }) => (currPos === 'default' ? '15px' : '5px')};
  height: ${({ currPos }) => (currPos === 'default' ? '40px' : '40px')};
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  background-repeat: no-repeat;
  background-size: 50px 100%;
`;

export default {};
