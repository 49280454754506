import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { isNil } from 'ramda';

import HallOfFameCard from 'components/Leaderboard/HallOfFameCard';
import Category from 'components/Leaderboard/Category';
import StudentList from 'components/Leaderboard/StudentList';
import Spinner from 'components/Shared/Spinner';
import CalendarIcon from 'assets/img/icon-calendar.svg';

// date picker imports
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import {
  getHallOfFame,
  getHallOfFameStudentList,
} from 'store/leaderboard/leaderboardSlice';
import { Container, NoParticipant } from './HallOfFameSection.styles';

const HallOfFameSection = ({ subject = 1 }) => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();

  const {
    hallOfFame,
    hallOfFameLoading,
    hallOfFameError,
    hallOfFameStudentList,
    hallOfFameStudentListLoading,
    hallOfFameStudentListError,
  } = useSelector((state) => state.leaderboard);
  const { timezone } = useSelector((state) => state.login);
  const { defaultLevel } = useSelector((state) => state.studentDetails);

  const levelList = [
    { levelId: 1 },
    { levelId: 2 },
    { levelId: 3 },
    { levelId: 4 },
    { levelId: 5 },
    { levelId: 6 },
  ];

  const parsedHof = levelList.map((level) => {
    const hero =
      hallOfFame?.find((item) => item.LevelId === level.levelId) ?? null;
    return {
      ...level,
      hero,
    };
  });

  const [level, setLevel] = useState(defaultLevel);
  const [enumHeroFilter, setEnumHeroFilter] = useState(3);

  const currentSelectedDate = moment()
    .tz(timezone)
    .subtract(1, 'days')
    .format('YYYY-MM-DD');
  const [selectedDate, setSelectedDate] = useState(currentSelectedDate);

  useEffect(() => {
    if (defaultLevel === null) {
      setLevel(1);
    }
  }, [defaultLevel]);

  useEffect(() => {
    dispatch(getHallOfFame({ selectedDate, subject }));
    dispatch(
      getHallOfFameStudentList({
        enumHeroFilter,
        level,
        selectedDate,
        subject,
      })
    );
  }, [dispatch, enumHeroFilter, level, selectedDate, subject]);

  const topTen =
    hallOfFameStudentList === null ? [] : hallOfFameStudentList.slice(0, 10);

  const getStudentsList = (enumHeroFilter, level) => {
    dispatch(
      getHallOfFameStudentList({
        enumHeroFilter,
        level,
        selectedDate,
        subject,
      })
    );
  };

  return (
    <Container>
      <div className="banner">
        <div className="hall-of-fame">
          <p className="title">
            {t('leaderboard:buttons.hallOfFame', 'Hall of Fame')}
          </p>
        </div>
        <DateRangePicker
          initialSettings={{
            singleDatePicker: true,
            showDropdowns: true,
            startDate: moment(selectedDate),
          }}
          onApply={(e, picker) =>
            setSelectedDate(moment(picker.startDate).format('YYYY-MM-DD'))
          }
        >
          <div className="calendar">
            {moment(selectedDate).format('DD MMM YYYY')}
            <span>
              <img src={CalendarIcon} alt="icon" />
            </span>
          </div>
        </DateRangePicker>
      </div>
      {hallOfFameLoading && !hallOfFameError && <Spinner />}
      {!hallOfFameLoading && !hallOfFameError && hallOfFame && (
        <>
          <div className="cards">
            {parsedHof.map((item) => (
              <HallOfFameCard
                key={item.levelId}
                level={item.levelId}
                nameOfClass={item?.hero?.ClassName ?? ''}
                studentName={item?.hero?.UserName ?? ''}
                schoolName={item?.hero?.SchoolName ?? ''}
                schoolLogo={item?.hero?.SchoolLogo ?? ''}
                cp={item?.hero?.CPPoints ?? ''}
                submissionDate={
                  !isNil(item?.hero)
                    ? moment(item?.hero?.LastSubmissionDate)
                        .tz(timezone)
                        .format('DD-MMM-YYYY HH:mm')
                    : ''
                }
                subjectID={subject}
              />
            ))}
          </div>
        </>
      )}
      {hallOfFameError && (
        <>
          <div className="cards">
            {levelList.map((item, index) => (
              <HallOfFameCard
                key={index}
                level={item.levelId}
                nameOfClass=""
                studentName=""
                schoolName=""
                schoolLogo=""
                cp=""
                submissionDate=""
                subjectID={subject}
              />
            ))}
          </div>
        </>
      )}
      <div className="student-list">
        <Category
          challengeMode="hallOfFame"
          subject={subject}
          getLists={getStudentsList}
          getTopFiftyLists={getStudentsList}
          active={enumHeroFilter}
          setActive={setEnumHeroFilter}
          selectedLevel={level}
          setSelectedlevel={setLevel}
        />
        {hallOfFameStudentListLoading && !hallOfFameStudentListError && (
          <Spinner />
        )}
        {!hallOfFameStudentListLoading &&
          !hallOfFameStudentListError &&
          hallOfFameStudentList && (
            <StudentList
              challengeMode="hallOfFame"
              subject={subject}
              reducer="leaderboard"
              data={topTen}
              isTopFifty
              active={enumHeroFilter}
            />
          )}
        {!hallOfFameStudentListLoading && topTen.length === 0 && (
          <NoParticipant>
            {t(
              'leaderboard:hodBanner.noParticipant',
              `No participants at the moment.`
            )}
          </NoParticipant>
        )}
      </div>
    </Container>
  );
};

export default HallOfFameSection;
