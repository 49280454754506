import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { StyledNumber } from './CountdownTimer.styled';

const CountdownTimer = () => {
  const endOfMonth = moment().endOf('month');
  const [timeLeft, setTimeLeft] = useState(null);
  const { t } = useTranslation('common');
  useEffect(() => {
    const timerId = setInterval(() => {
      const now = moment();
      const diff = endOfMonth.diff(now);
      const timeLeftDuration =
        diff > 0 ? moment.duration(diff) : moment.duration(0);
      setTimeLeft(timeLeftDuration);
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [endOfMonth]);
  if (!timeLeft) return null;
  return (
    <p style={{ marginTop: '1rem' }}>
      <StyledNumber>{`${timeLeft.days()} `}</StyledNumber>
      <span>{t('common:date.day', 'Days')}</span>
      <StyledNumber>{`${timeLeft.hours()} `}</StyledNumber>
      <span>{t('common:date.hour', 'Hours')}</span>
      <StyledNumber>{`${timeLeft.minutes()} `}</StyledNumber>
      <span>{t('common:date.minute', 'Minutes')}</span>
      <StyledNumber>{`${timeLeft.seconds()} `}</StyledNumber>
      <span>{t('common:date.second', 'Seconds')}</span>
    </p>
  );
};

export default CountdownTimer;
