import styled from 'styled-components';

export const Timer = styled.div`
  text-align: center;
  position: absolute;
  right: 96px;
  top: 84px;
  z-index: 10;
  color: white;
  p {
    margin: 0;
  }
  .header {
    font-weight: 600;
    font-size: 0.875rem;
  }
  .time {
    font-size: 1.875rem;
    font-weight: 700;
  }
  .subtext {
    font-weight: 600;
    font-size: 0.6875rem;
    opacity: 0.64;
  }
`;
