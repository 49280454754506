import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TRP from 'assets/img/dashboard/daily-challenge/trophy.svg';
import DCHoverMiko from 'assets/img/dashboard/dc-hover-miko.svg';
import {
  Border,
  StartButton,
  OverlayContainer,
  OverlayPremiumContainer,
} from './DailyChallengeCard.styles';

const Overlay = ({ isFeatureReady, qnCount, zhQnCount }) => {
  const { t } = useTranslation(['dashboard']);
  return (
    <OverlayContainer isFeatureReady={isFeatureReady}>
      {isFeatureReady && (
        <>
          <p>
            {t(
              'dashboard:dailyChallenge.caption',
              { qnCount, zhQnCount },
              '10 personalized questions per day'
            )}
          </p>
          <button>{t('dashboard:dailyChallenge.button', 'Start')}</button>
        </>
      )}
    </OverlayContainer>
  );
};

const PremiumOverlay = ({ qnCount, zhQnCount }) => {
  const { t } = useTranslation(['dashboard']);
  return (
    <OverlayPremiumContainer>
      <h4>
        {t(
          'dashboard:dailyChallenge.caption',
          { qnCount, zhQnCount },
          `${qnCount} personalized questions per day`
        )}
      </h4>
      <img className="miko-hover" src={DCHoverMiko} alt="miko" />
      <button className="startbutton">
        {t('dashboard:dailyChallenge.button', 'Start')}
      </button>
    </OverlayPremiumContainer>
  );
};

const DailyChallengeCard = ({
  isFeatureReady,
  isPremium,
  subject = 'math',
}) => {
  const history = useHistory();
  const { t } = useTranslation(['dashboard']);
  const [showOverlay, setShowOverlay] = useState(false);
  const qnCount = subject.toLowerCase().trim() === 'science' ? 5 : 10;
  // For chinese localisation
  const zhQnCount = subject.toLowerCase().trim() === 'science' ? '五' : '十';
  return (
    <Border
      hover={showOverlay}
      isPremium={isPremium}
      onClick={() => {
        if (isFeatureReady) history.push('/challenges');
      }}
      onMouseEnter={() => {
        if (isFeatureReady) setShowOverlay(true);
      }}
      onMouseLeave={() => {
        if (isFeatureReady) setShowOverlay(false);
      }}
    >
      <img src={TRP} className="trophy" alt="trophy" />
      <StartButton hover={showOverlay}>
        {t('dashboard:dailyChallenge.button', 'Start')}
      </StartButton>
      <div className="container" data-cy="DailyChallenge">
        <p className="sub-title">
          {t(
            'dashboard:dailyChallenge.caption',
            { qnCount, zhQnCount },
            `${qnCount} personalized questions per day`
          )}
        </p>
      </div>
      {(showOverlay || isFeatureReady === false) &&
        (isPremium ? (
          <PremiumOverlay qnCount={qnCount} zhQnCount={zhQnCount} />
        ) : (
          <Overlay
            isFeatureReady={isFeatureReady}
            qnCount={qnCount}
            zhQnCount={zhQnCount}
          />
        ))}
    </Border>
  );
};

export default DailyChallengeCard;
