import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { featureToggle, MAINTENANCE_ROUTE } from 'constants/index';
import useQuery from 'helpers/useQuery';
import { useDispatch } from 'react-redux';
import {
  setHasSeenLoader,
  setHasSeenToggleTooltip,
} from 'store/dashboard/studentDetailsSlice';
import useGlobalFeature from 'hooks/useGlobalFeature';
import Header from 'components/Header';
import { withSubjectCheck } from 'helpers/hoc';
import { Page, Main } from './index.styles';
import {
  StoryScience,
  StoryMath,
  IntermediatePage,
  StoryViewer,
  BookClub,
} from './Pages';

const Story = () => {
  const dispatch = useDispatch();
  const { isEnabled } = useGlobalFeature('story');
  const query = useQuery();
  useEffect(() => {
    dispatch(setHasSeenLoader(true));
    dispatch(setHasSeenToggleTooltip(true));
  }, [dispatch]);
  return (
    <Switch>
      {!isEnabled && (
        <Redirect
          exact
          from={['/story', '/story/science']}
          to={MAINTENANCE_ROUTE}
        />
      )}

      {!isEnabled && (
        <Redirect exact from="/story/math/book-club" to={MAINTENANCE_ROUTE} />
      )}
      {!isEnabled && (
        <Redirect
          exact
          from={[
            '/story-book/viewer/:id',
            '/story-book/subject/:subjectId/viewer/:id',
          ]}
          to={MAINTENANCE_ROUTE}
        />
      )}
      {featureToggle.story && featureToggle.science && (
        <Route
          exact
          path="/story-book/subject/:subjectId/viewer/:id"
          component={StoryViewer}
        />
      )}
      {featureToggle.story && (
        <Route exact path="/story-book/viewer/:id" component={StoryViewer} />
      )}
      <Page>
        <Header />
        <Main>
          {featureToggle.science && (
            <Route exact path="/story" component={IntermediatePage} />
          )}
          <Route
            exact
            path="/story/science"
            component={() => {
              const CheckRedirect = () => {
                const isRedirect = query.get('redirect');
                if (isRedirect === '') {
                  const Result = withSubjectCheck(StoryScience, 'science');
                  return <Result />;
                }
                return <StoryScience />;
              };
              return <CheckRedirect />;
            }}
          />
          <Route
            exact
            path="/story/math"
            component={() => {
              const CheckRedirect = () => {
                const isRedirect = query.get('redirect');
                if (isRedirect === '') {
                  const Result = withSubjectCheck(StoryMath, 'math');
                  return <Result />;
                }
                return <StoryMath />;
              };
              return <CheckRedirect />;
            }}
          />
          {featureToggle.story && (
            <Route exact path="/story/math/book-club" component={BookClub} />
          )}
        </Main>
      </Page>
    </Switch>
  );
};

export default React.memo(Story);
