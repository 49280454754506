const events = {
  scienceTrialActivation: {
    eventType: 'StartTrialScience',
    eventMetaObject: {
      value: 75.0,
      currency: 'SGD',
      predicted_ltv: 150.0,
    },
    isStandard: false,
  },
};

export default events;
