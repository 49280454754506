import styled from 'styled-components';

export const StyledTitleText = styled.p`
  font-weight: 600;
  font-size: 1.125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  @media (max-width: 850px) {
    font-size: 1rem;
  }
`;

export const TimerText = styled.span`
  color: #176254;
  margin-right: 6px;
`;

export const SideNavButton = styled.button`
  border: none;
  background: transparent;
`;
