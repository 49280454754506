import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
    margin: auto;
  }
  .modal-content {
    background-color: transparent;
    align-items: center;
    border: none;
  }
  .modal-custom {
    min-width: 30%;
  }

  .modal-custom-large {
    min-width: 60%;
  }
`;
