import styled, { css } from 'styled-components';
import { TERMS_COLORS } from '../../helper';

export const Container = styled.div`
  display: flex;
  background: ${({ subject }) => TERMS_COLORS(subject).container};
  border-radius: 35px;
  padding: 15px;
  opacity: ${({ subject }) => TERMS_COLORS(subject).opacity};
  width: 75%;
  text-align: center;
  .loading {
    color: rgb(147, 137, 27);
    font-size: 1.2rem;
    margin-bottom: 0;
  }
`;

export const Term = styled.div`
  border: ${({ active, subject }) =>
    active ? 'none' : `2px solid ${TERMS_COLORS(subject).default.border}`};
  border-radius: 20px;
  width: 25%;
  text-align: center;
  margin: 0 10px;
  padding: 5px 0;
  font-weight: 700;
  font-size: 18px;
  color: ${({ active, subject }) =>
    active
      ? TERMS_COLORS(subject).active.font
      : TERMS_COLORS(subject).default.font};
  cursor: pointer;
  position: relative;
  box-shadow: ${({ active, subject }) =>
    active && `inset 1px 1px 0 1px ${TERMS_COLORS(subject).active.boxShadow}`};
  background-color: ${({ active, subject }) =>
    active
      ? TERMS_COLORS(subject).active.bg
      : TERMS_COLORS(subject).default.bg};

  &:after {
    ${({ active }) => {
      if (active) {
        return css`
          content: ' ';
          position: absolute;
          top: 2.7rem;
          left: 47%;
          border-width: 8px;
          border-style: solid;
          border-color: rgb(54, 163, 113) transparent transparent transparent;
        `;
      }
    }}
  }
`;
