import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 852px;
  height: 420px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: ${({ background }) => `url('${background}')`};
`;

export const HeaderContainer = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 32px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  color: #89cff0;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 16px;
  font-family: Arial, sans-serif;
  max-width: 500px;
  color: #282828;
  line-height: 1.5;
  margin-bottom: 28px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > button:first-child {
    margin-right: 18px;
  }
`;
