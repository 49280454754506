import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 210px;
  justify-content: space-evenly;
  padding: 2rem 2rem;
  .desc {
    margin-bottom: 1rem;
  }
  .bff-code {
    width: 335px;
    border-radius: 7px;
    border: 1px solid #d5d5d5;
    padding: 0.5rem;
    font-size: 20px;
    font-weight: bold;
    ::placeholder {
      font-size: 16px;
      font-weight: normal;
    }
  }
  .validation-text {
    color: #ff6701;
    width: 75%;
    text-align: left;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
`;
