import styled from 'styled-components';

const StyledButton = styled.button`
  color: #ff7121;
  border: solid 1px #ff6701;
  border-radius: 30px;
  background: white;
  padding: 5px 16px;
  min-width: 130px;
`;

export default StyledButton;
