/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import { useTranslation } from 'react-i18next';
import expLevelBackgrounds from 'constants/exp-level/expLevelBackgrounds';
import BadgeListItem from '../BadgeListItem/BadgeListItem';
import { BadgeList, BadgeSection, Section } from './ExpLevelSection.styles';

const backgroundArray = Array.from(Array(11).keys());
const row = [1, 2, 3, 4, 5];
const row2 = row.map((i) => i + 5);
const darkTextArray = [1, 2, 5, 7, 8];

const ExpLevelSection = ({ currentLevel }) => {
  const { t } = useTranslation('expLevel');
  const levelText = t('expLevel:section.level', 'Level');

  const renderLevelRange = (i) => {
    switch (i) {
      case 0:
        return `${levelText} 1 - 10`;
      case 9:
        return `${levelText} 91 - 95`;
      case 10:
        return `${levelText} 96 - 100`;
      default:
        return `${levelText} ${i * 10 + 1} - ${i * 10 + 10}`;
    }
  };

  const renderBadgeSection = (i) => {
    switch (i) {
      case 9:
        return (
          <BadgeSection>
            <BadgeList>
              {row.map((j) => {
                const selectedLevel = 90 + j;
                return (
                  <BadgeListItem
                    key={selectedLevel}
                    levelText={levelText}
                    darkText={darkTextArray.includes(i)}
                    currentLevel={currentLevel}
                    selectedLevel={selectedLevel}
                    lockedIconLevel={10}
                  />
                );
              })}
            </BadgeList>
          </BadgeSection>
        );
      case 10:
        return (
          <BadgeSection>
            <BadgeList>
              {row2.map((j) => {
                const selectedLevel = 90 + j;
                return (
                  <BadgeListItem
                    key={selectedLevel}
                    levelText={levelText}
                    darkText={darkTextArray.includes(i)}
                    currentLevel={currentLevel}
                    selectedLevel={selectedLevel}
                    lockedIconLevel={11}
                  />
                );
              })}
            </BadgeList>
          </BadgeSection>
        );
      default:
        return (
          <>
            <BadgeSection>
              <BadgeList>
                {row.map((j) => {
                  const selectedLevel = i * 10 + j;
                  return (
                    <BadgeListItem
                      key={selectedLevel}
                      levelText={levelText}
                      darkText={darkTextArray.includes(i)}
                      currentLevel={currentLevel}
                      selectedLevel={selectedLevel}
                      lockedIconLevel={i + 1}
                    />
                  );
                })}
              </BadgeList>
              <BadgeList>
                {row2.map((k) => {
                  const selectedLevel = i * 10 + k;
                  return (
                    <BadgeListItem
                      key={selectedLevel}
                      levelText={levelText}
                      darkText={darkTextArray.includes(i)}
                      currentLevel={currentLevel}
                      selectedLevel={selectedLevel}
                      lockedIconLevel={i + 1}
                    />
                  );
                })}
              </BadgeList>
            </BadgeSection>
          </>
        );
    }
  };

  return (
    <>
      {backgroundArray.map((i) => {
        const currentKey = i + 1;
        return (
          <Section
            loading="lazy"
            key={currentKey}
            url={expLevelBackgrounds[currentKey]}
            darkText={darkTextArray.includes(i)}
          >
            <p className={`main-text ${currentKey >= 10 && 'main-text-90'}`}>
              {renderLevelRange(i)}
            </p>
            {renderBadgeSection(i)}
          </Section>
        );
      })}
    </>
  );
};

export default ExpLevelSection;
