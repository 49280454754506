import React from 'react';
import { AvatarRendererFromImage } from 'components/AvatarCreator/AvatarRenderer';
import badge1 from 'assets/img/monstershowcase/badge-1st.png';
import badge2 from 'assets/img/monstershowcase/badge-2nd.png';
import badge3 from 'assets/img/monstershowcase/badge-3rd.png';
import cpsIcon from 'assets/img/monstershowcase/icon-cps.png';
import {
  MainContainer,
  AvatarContainer,
  DateDisplay,
  NameDisplay,
  SchollContainer,
} from './AvatarDisplay.style';

const rankingBadgeMap = {
  0: badge1,
  1: badge2,
  2: badge3,
};

function AvatarDisplay({
  horn,
  body,
  eyes,
  mouth,
  backgroundColor,
  rank,
  cps,
  time,
  name,
  school,
  schoolLogo,
}) {
  const date = new Date(time);
  const dateString = date.toLocaleString('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
  return (
    <MainContainer>
      <AvatarContainer backgroundColor={`#${backgroundColor}`}>
        <AvatarRendererFromImage
          horn={horn}
          body={body}
          eyes={eyes}
          mouth={mouth}
        />
        {rankingBadgeMap[rank] !== undefined && (
          <img
            className="rankingBadge"
            src={rankingBadgeMap[rank]}
            alt="ranking badge"
          />
        )}
        <div className="cps">
          <img src={cpsIcon} alt="cps point" />
          {cps}
        </div>
      </AvatarContainer>
      <DateDisplay>{dateString}</DateDisplay>
      <NameDisplay>{name}</NameDisplay>
      <SchollContainer>
        <img alt="school logo" src={schoolLogo} />
        <span>{school}</span>
      </SchollContainer>
    </MainContainer>
  );
}

export default React.memo(AvatarDisplay);
