import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';

import { getAvailableProducts } from 'store/parent/parentSettingsSlice';

import { CURRENCY_SYMBOL, BUNDLE_TYPE_ID } from 'constants/parentPortal';
import currencyLocaleFormatter from 'helpers/currencyLocaleFormatter';
import { paymentRenewUrl } from 'helpers/url/paymentRenewUrl';

import Button from 'components/Shared/Button';
import Spinner from 'components/Shared/Spinner';
import { Container, CardContainer, Card } from './AvailableProducts.styles';

const AvailableProducts = ({ childId }) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useSelector(
    (state) => state.parentSettings.availableProducts
  );

  const products = data?.[childId] ?? [];

  const buyNowHandler = (bundleTypeId) => {
    window.location.href = paymentRenewUrl({
      userId: childId,
      bundleTypeId,
    });
  };

  useEffect(() => {
    dispatch(getAvailableProducts(childId));
  }, [childId, dispatch]);

  return (
    <Container>
      <p className="title">Choose a plan that’s best for your child</p>
      {isLoading && <Spinner />}
      {!isLoading && isNil(isError) && (
        <CardContainer>
          {products?.map((product) => (
            <Card
              key={product.bundleId}
              bundle={product.campaignTypeCodeId}
              isB2B={product.isB2bUser}
            >
              <div className="top">
                <div className="disc">
                  {product.isB2bUser &&
                    BUNDLE_TYPE_ID[product.campaignTypeCodeId] !==
                      'science' && (
                      <span className="b2b-label">Partner School</span>
                    )}
                  <p className="discount-number">
                    {product.discountPercentage}% OFF
                  </p>
                </div>
                <p className="name">
                  {product.isBundled && <span>Bundle</span>}
                  {product.bundleName}
                </p>
              </div>
              <div className="bottom">
                <p className="price">
                  {CURRENCY_SYMBOL[product.currency.toLocaleLowerCase()] ?? '$'}
                  {
                    currencyLocaleFormatter(
                      product.currency.toLocaleLowerCase(),
                      product.planAmount
                    ).formattedString
                  }
                </p>
                <p className="disc-price">
                  <span>
                    {CURRENCY_SYMBOL[product.currency.toLocaleLowerCase()] ??
                      '$'}
                    {
                      currencyLocaleFormatter(
                        product.currency.toLocaleLowerCase(),
                        product.totalPaymentAmount
                      ).formattedString
                    }
                  </span>{' '}
                  {product.currency}/yr
                </p>
                <p className="tax">{product.taxRate !== 0 && '(incl GST)'}</p>
                <Button
                  borderRadius="2px"
                  variant="orange3"
                  onClick={() => buyNowHandler(product.campaignTypeCodeId)}
                >
                  Buy Now
                </Button>
              </div>
            </Card>
          ))}
        </CardContainer>
      )}
      {!isLoading && !isNil(isError) && <p className="error">{isError}</p>}
    </Container>
  );
};

export default AvailableProducts;
