import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;
const dailyChallengeBaseUrl = process.env.REACT_APP_DAILYCHALLENGE_API_DOMAIN;

export const fetchGetAvailableCurriculums = async (subjectId) => {
  return fetch(`${baseUrl}Curriculum/Available/List/${subjectId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result?.Message?.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Fetch user available curriculums failed'
      );
    });
};

export const fetchDCCurrentCurriculums = async (subjectId) => {
  const { userID, timezone } = store.getState().login;
  return fetch(
    `${dailyChallengeBaseUrl}DailyChallenge/User/${userID}/curriculum/${subjectId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => result)
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch DC latest curriculums failed'); // eslint-disable-line
    });
};

export const fetchAssignDCCurriculum = async (rawBody) => {
  return fetch(`${dailyChallengeBaseUrl}DailyChallenge/Curriculum/Assignment`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ??
          'Fetch assign daily challenge curriculum individual failed'
      ); // eslint-disable-line
    });
};
