import MasteryIcon from 'assets/img/mission/mastery.png';
import CompetentIcon from 'assets/img/mission/competent.png';
import BeginningIcon from 'assets/img/mission/beginning.png';
import IncompleteIcon from 'assets/img/mission/incomplete.png';
import GradeAIcon from 'assets/img/mission/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/mission/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/mission/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/mission/icon-report-high-score-d.svg';

export const ProficiencyIcons = {
  mastery: MasteryIcon,
  competent: CompetentIcon,
  beginning: BeginningIcon,
  incomplete: IncompleteIcon,
};

export const HighScoreIcons = {
  A: GradeAIcon,
  B: GradeBIcon,
  C: GradeCIcon,
  D: GradeDIcon,
  Incomplete: null,
};

export const MasteryLevel = (level) => {
  if (level >= 3) {
    return 'A';
  }
  if (level === 2) {
    return 'B';
  }
  if (level === 1) {
    return 'C';
  }
  if (level === 0) {
    return 'D';
  }
  return 'Incomplete';
};

export const HighscoreStars = (level) => {
  switch (level) {
    case 'A':
      return 3;
    case 'B':
      return 2;
    case 'C':
      return 1;
    case 'D':
      return 0;
    default:
      return 0;
  }
};

export const CompetencyLevel = {
  0: 'incomplete',
  1: 'beginning',
  2: 'developing',
  3: 'competent',
  4: 'mastery',
};

export const ProgressStatus = {
  0: 'not-started',
  1: 'completed',
  2: 'in-progress',
};

export const NewHOTsCurriculumID = 92;
