import React, { useState, useCallback, createRef, useRef } from 'react';
import { stageIcons } from 'constants/missionPlus';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useSound from 'use-sound';
import { OverlayTrigger } from 'react-bootstrap';
import Button from 'components/Shared/Button';
import { isNil } from 'ramda';
import IconLevel from './IconLevel';
import LelTooltip from './LelTooltip';
import { ICON_WIDTH } from './constants';
import {
  IconKoKo,
  IconCollectedActive,
  IconCollected,
  IconLock,
  IconGiftBoxBg,
  IconGiftBoxOpen,
  IconGiftBox,
  ImgKoKoGlitters,
  ImgSpotlight,
  ImgCongratulations,
  IconLel30,
  BrainGames,
  StoryMath,
  ClaimKokoSound,
} from './images';
import {
  StyledContainer,
  StyledModal,
  StyledLeftContainer,
  StyledRightContainer,
  StyledStage,
  StyledTitleImg,
  StyledGiftBox,
  StyledResult,
  StyledBlockLel,
  StyledTooltipContainer,
  StyledJumpLink,
} from './ClaimKoKoModal.styles';

const ClaimKoKoModal = ({
  topicSkillId,
  stageTypeId,
  open,
  setOpen,
  canGetOneTimeReward,
}) => {
  const { t } = useTranslation(['selfPracticeQnView', 'common']);
  const [openBoxIndex, setOpenBoxIndex] = useState(null);
  const [giftBoxGroup, setGiftBoxGroup] = useState(
    new Array(8).fill('_').map((_, i) => ({ i, open: false }))
  );
  const [play] = useSound(ClaimKokoSound, {
    volume: 1,
    playbackRate: 1,
    interrupt: true,
  });
  const { selectedTopic, missionPlusClaimKoKo } = useSelector(
    (state) => state.mission
  );
  const modalRef = useRef(null);
  const openGiftBoxRef = useRef(null);
  const stageRef = useRef(null);
  const endStageRef = useRef(null);
  const giftBoxRef = useRef(giftBoxGroup.map(() => createRef()));
  const openGiftBox = typeof openBoxIndex === 'number';
  const isLelLocked = isNil(missionPlusClaimKoKo.LevelBonus);
  const history = useHistory();
  const stages = selectedTopic?.ViewTopicSkillModels?.find(
    (datum) => datum.TopicSkillId === topicSkillId
  )?.Stages;
  const currentStage = stages?.find(
    (datum) => datum.StageTypeId === stageTypeId
  );
  const currentStageWithGoldMedal = currentStage?.GoldMedal;

  const handleGiftBoxClick = (i) => {
    setGiftBoxGroup((prev) =>
      prev.map((datum) => (datum.i === i ? { i, open: true } : datum))
    );
    setOpenBoxIndex(i);
    play();
  };

  const resultGiftBox = useCallback(() => {
    if (openGiftBox && openBoxIndex !== null) {
      const originGiftBox = giftBoxRef?.current[
        openBoxIndex
      ]?.current?.getBoundingClientRect();
      const endGiftBox = openGiftBoxRef?.current?.getBoundingClientRect();
      const modalRefResult = modalRef?.current?.getBoundingClientRect();
      const top =
        originGiftBox?.top > 0
          ? originGiftBox.top - modalRefResult.top - 4
          : endGiftBox?.top - modalRefResult.top - 3;
      const left =
        originGiftBox?.left > 0
          ? originGiftBox.left - modalRefResult.left + 29
          : endGiftBox?.left - modalRefResult.left - 4;
      // the number is the offset of the gift box (css scale)
      return (
        <StyledResult.Box
          src={IconGiftBoxOpen}
          alt="gift-box-open"
          top={top}
          left={left}
          endTop={endGiftBox?.top - modalRefResult.top - 3}
          endLeft={endGiftBox?.left - modalRefResult.left - 4}
        />
      );
    }
    return null;
  }, [openGiftBox, openBoxIndex]);
  const resultStage = useCallback(() => {
    if (openGiftBox && !currentStageWithGoldMedal && canGetOneTimeReward) {
      const originStage = stageRef?.current?.getBoundingClientRect();
      const modalRefResult = modalRef?.current?.getBoundingClientRect();
      const endStage = endStageRef?.current?.getBoundingClientRect();
      const top =
        originStage?.top > 0
          ? originStage.top - modalRefResult?.top
          : endStage?.top - modalRefResult.top;
      const left =
        originStage?.left > 0
          ? originStage.left - modalRefResult?.left
          : endStage?.left - modalRefResult.left;
      return (
        <StyledResult.Stage
          src={stageIcons[stageTypeId].iconGold}
          alt="stage"
          width={ICON_WIDTH[stageTypeId]}
          top={top}
          left={left}
          endTop={endStage?.top - modalRefResult?.top}
          endLeft={endStage?.left - modalRefResult?.left}
        />
      );
    }
  }, [
    openGiftBox,
    stageTypeId,
    currentStageWithGoldMedal,
    canGetOneTimeReward,
  ]);
  return (
    <>
      <StyledModal
        show={open}
        keyboard={false}
        onHide={() => setOpen(false)}
        centered
        backdrop="static"
        size="lg"
        data-cy="claim-koko-modal"
      >
        <StyledContainer ref={modalRef}>
          <StyledContainer.Spotlight open={openGiftBox}>
            <img src={ImgSpotlight} alt="spotlight" />
          </StyledContainer.Spotlight>
          <StyledTitleImg open={openGiftBox}>
            <img
              src={ImgKoKoGlitters}
              alt="title-img"
              className="koko-glitters"
            />
            <img
              src={ImgCongratulations}
              alt="title-img"
              className="congratulations"
            />
          </StyledTitleImg>
          <StyledLeftContainer open={openGiftBox}>
            {stages?.map(({ GoldMedal, StageTypeId }) => {
              const active = stageTypeId === StageTypeId;
              const activeStageRef = active ? stageRef : null;
              const imgSrc = () => {
                if (GoldMedal) {
                  return (
                    <img
                      src={IconCollected}
                      alt="icon-collected"
                      data-cy="icon-collected"
                    />
                  );
                }
                if (active && canGetOneTimeReward) {
                  return (
                    <img
                      src={IconCollectedActive}
                      alt="icon-collected-active"
                      data-cy="icon-collected-active"
                      className="collected"
                    />
                  );
                }
                return (
                  <img
                    src={IconLock}
                    alt="icon-lock"
                    data-cy="icon-lock"
                    className="lock"
                  />
                );
              };
              return (
                <StyledStage
                  key={StageTypeId}
                  stageId={StageTypeId}
                  active={!GoldMedal && active && canGetOneTimeReward}
                  data-cy={`stage-${StageTypeId}`}
                  block={
                    (!GoldMedal && !active) || (active && !canGetOneTimeReward)
                  }
                  collected={GoldMedal}
                >
                  <div className="first-icon">
                    <img
                      src={stageIcons[StageTypeId].iconGold}
                      alt={`iconGold-${StageTypeId}`}
                      ref={activeStageRef}
                    />
                  </div>
                  <div className="index">
                    {StageTypeId} <img src={IconKoKo} alt="koko" />
                  </div>
                  <div className="last-icon">{imgSrc()}</div>
                </StyledStage>
              );
            })}
          </StyledLeftContainer>
          <StyledRightContainer open={openGiftBox}>
            <p>
              {t(
                'selfPracticeQnView:missionPlus.claimedTitle',
                'Tap to open and find out what treasure lies inside'
              )}
            </p>
            <StyledGiftBox>
              {giftBoxGroup.map(({ i, open }) => (
                <StyledGiftBox.Item
                  key={i}
                  className="gift-box"
                  open={open}
                  onClick={() => handleGiftBoxClick(i)}
                  ref={giftBoxRef.current[i]}
                >
                  <img src={IconGiftBoxBg} alt="gift-box-bg" className="bg" />
                  <img src={IconGiftBox} alt="gift-box" className="box" />
                </StyledGiftBox.Item>
              ))}
            </StyledGiftBox>
          </StyledRightContainer>
          <StyledResult open={openGiftBox}>
            <div className="title">
              <Trans i18nKey="selfPracticeQnView:missionPlus.rewardTitle">
                You Got <span>{{ reward: missionPlusClaimKoKo.Total }}</span>
                <img src={IconKoKo} alt="koko" />
                reward
              </Trans>
            </div>
            <StyledResult.Description
              open={openGiftBox}
              lock={isLelLocked}
              stageTypeId={stageTypeId}
              hideOneTimeReward={
                !canGetOneTimeReward || currentStageWithGoldMedal
              }
            >
              <div className="item one-time-reward">
                <img
                  src={stageIcons[stageTypeId].iconGold}
                  alt="stage"
                  className="stage"
                  width={ICON_WIDTH[stageTypeId]}
                  ref={endStageRef}
                />
                <div className="text">
                  {missionPlusClaimKoKo.OneTimeReward}{' '}
                  <img src={IconKoKo} alt="koko" />
                </div>
              </div>
              <div className="item">
                <img
                  src={IconGiftBoxOpen}
                  alt="gift-box-open"
                  className="gift-box-open"
                  ref={openGiftBoxRef}
                />
                <div className="text">
                  {missionPlusClaimKoKo.RepeatableReward}{' '}
                  <img src={IconKoKo} alt="koko" />
                </div>
              </div>
              <div className="item">
                {isLelLocked && openGiftBox && (
                  <OverlayTrigger
                    placement="top-start"
                    trigger="click"
                    overlay={
                      <StyledTooltipContainer>
                        <StyledBlockLel.Description>
                          <Trans i18nKey="selfPracticeQnView:missionPlus.lelTooltip.blockLel">
                            Reach
                            <img src={IconLel30} alt="icon-lel-30" /> to unlock
                          </Trans>
                        </StyledBlockLel.Description>
                      </StyledTooltipContainer>
                    }
                  >
                    <StyledBlockLel />
                  </OverlayTrigger>
                )}
                <IconLevel className="lel" />
                <div className="text">
                  {isLelLocked ? (
                    <img src={IconLock} alt="lock" className="lock" />
                  ) : (
                    missionPlusClaimKoKo.LevelBonus
                  )}
                  <img src={IconKoKo} alt="koko" className="last-koko-icon" />
                </div>
                <LelTooltip open={openGiftBox} />
              </div>
              <Button
                variant="primary"
                fontSize="22px"
                fontWeight="700"
                onClick={() => history.goBack()}
              >
                {t('common:continue', 'Continue')}
              </Button>
            </StyledResult.Description>
            {resultGiftBox()}
            {resultStage()}
            {openGiftBox && (
              <StyledJumpLink>
                <span>
                  {t(
                    'selfPracticeQnView:missionPlus.jumpLink',
                    'Jump to Story or Brain Games'
                  )}
                </span>
                <img
                  src={StoryMath}
                  alt="Story"
                  onClick={() => history.push('/story/science')}
                  className="story"
                />
                <img
                  src={BrainGames}
                  alt="Brain Games"
                  onClick={() => history.push('/brain-game/science')}
                />
              </StyledJumpLink>
            )}
          </StyledResult>
        </StyledContainer>
      </StyledModal>
    </>
  );
};

export default ClaimKoKoModal;
