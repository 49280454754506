import React from 'react';
import ChapterImage from 'components/StoryMath/ChapterImage';
import ChapterTitle from 'components/StoryMath/ChapterTitle';
import { Container } from './ChapterList.styles';

const ChapterList = ({ type, data, onClickImage }) => {
  const className = data.length === 1 ? 'devider' : '';
  return (
    <Container type={type}>
      {data.map((chapter, i) => {
        if (type === 'top') {
          if (i % 2) {
            return (
              <ChapterTitle
                key={data.Id}
                className={className}
                data={chapter}
                type={type}
              />
            );
          }
          return (
            <ChapterImage
              key={data.Id}
              className={className}
              data={chapter}
              type={type}
              onClickImage={onClickImage}
            />
          );
        }
        if (i % 2) {
          return (
            <ChapterImage
              key={data.Id}
              className={className}
              data={chapter}
              type={type}
              onClickImage={onClickImage}
            />
          );
        }
        return (
          <ChapterTitle
            key={data.Id}
            className={className}
            data={chapter}
            type={type}
          />
        );
      })}
    </Container>
  );
};

export default React.memo(ChapterList);
