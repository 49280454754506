import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/top-brain/header2.svg';
import ClassicBanner from 'assets/img/leaderboard/champion-class/classic-banner.svg';

import {
  Topbar as ClassicTopBar,
  CenterBar as ClassicCenterBar,
  // Backbutton as ClassicCenterBackbutton,
} from './TopBrainHeader.styles';

export const Topbar = styled(ClassicTopBar)`
  background-image: url(${HeaderBg});
  background-size: cover;
  /* display: flex; */
  flex-direction: column;
  position: relative;
  .back-bar {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .classic-choices {
    h3 {
      color: #ffffff;
      margin-top: 20px;
      font-family: 'Grilled Cheese';
    }
  }
  .details {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 2%;
`;

export const CenterBar = styled(ClassicCenterBar)`
  width: 80%;
  flex-direction: row;
  align-self: center;
  margin-top: 60px;
  .classic-banner {
    position: absolute;
    background-image: url(${ClassicBanner});
    background-repeat: no-repeat;
    width: 100%;
    text-align: center;
    background-size: 100% 100%;
    color: #ffffff;
    top: -44px;
    font-size: 26px;
    font-family: 'Grilled Cheese';
  }
  .classic-images {
    img {
      margin-right: 20px;
    }
  }
`;
