/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { lionCityIdHashmap } from 'constants/leaderboard';
import { Trans, useTranslation } from 'react-i18next';
import lionCityBanners from 'constants/leaderboard/lionCityBanners';
import { StyledDescription } from './LionCityOccupationModal.styles';

const LionCityOccupationModal = ({ occupationId }) => {
  const { t } = useTranslation(['leaderboard']);

  return (
    <div>
      <img
        alt="occupation-img"
        width="635px"
        src={lionCityBanners[occupationId]}
      />
      <StyledDescription>
        <p className="description">
          {t(
            `leaderboard:lionCity.${lionCityIdHashmap[occupationId]}.description`
          )}
        </p>
        <h4 className="related-topics">
          <Trans i18nKey="leaderboard:lionCity.modal.relatedTopics" t={t}>
            ---------------
            <span>Related Topics</span>
            ---------------
          </Trans>
        </h4>
        <h4 className="topics">
          {t(
            `leaderboard:lionCity.${lionCityIdHashmap[occupationId]}.involvedTopics`
          )}
        </h4>
      </StyledDescription>
    </div>
  );
};

export default LionCityOccupationModal;
