import React from 'react';
import { useTranslation } from 'react-i18next';
import SHCBg from 'assets/img/dashboardv2/SHC.png';
import SHCBgTablet from 'assets/img/dashboardv2/superheroTablet.png';
import SHCSmallBg from 'assets/img/dashboardv2/SHC-small.png';
import SHCSmallBgTablet from 'assets/img/dashboardv2/SHC-tablet-small.png';
import { useHistory } from 'react-router-dom';
import { getCurrentDateTimeInTimezone } from 'pages/Events/EventList';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';
import { StyledContainer } from './SuperheroChallengeCard.styles';
import ClosedOnSundayBadge from './ClosedOnSundayBadge';

const CardImage = ({ isSMCEnabled }) => {
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });

  let imgSrc = isSMCEnabled ? SHCSmallBg : SHCBg;
  if (isTablet) {
    imgSrc = isSMCEnabled ? SHCSmallBgTablet : SHCBgTablet;
  }
  if (isMobile) {
    imgSrc = SHCBgTablet;
  }

  return <img src={imgSrc} alt="" className="bg" />;
};

const SuperheroChallengeCard = ({ isSMCEnabled }) => {
  const history = useHistory();
  const { timezone } = useSelector((state) => state.login);
  const { t } = useTranslation(['dashboardCards']);
  const isSunday = getCurrentDateTimeInTimezone(timezone)?.isoWeekday() === 7;
  // Event Handler
  const clickHandler = () => {
    if (isSunday) return;
    history.push('/superhero');
  };
  return (
    <StyledContainer
      data-cy="superhero-challenge"
      onClick={clickHandler}
      isSunday={isSunday}
      isDisabled={isSunday}
      isSMCEnabled={isSMCEnabled}
    >
      <div>
        <CardImage isSMCEnabled={isSMCEnabled} />
        {isSunday && (
          <ClosedOnSundayBadge
            rightPosition={isSMCEnabled ? 0 : '1.3%'}
            bottomPosition="9%"
          />
        )}
        <p>{t('superHeroChallenge.name', 'Super Hero Challenge')}</p>
      </div>
    </StyledContainer>
  );
};

export default SuperheroChallengeCard;
