import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DropdownMenu from 'components/Shared/DropdownMenu';
import formatChineseNumber from 'helpers/formatChineseNumber';
import TopFifty from 'assets/img/leaderboard/top-fifty.svg';
import { CategoryStyle, CategoryType } from './Category.styles';

const Category = ({
  active,
  setActive,
  showTopFifty,
  getLists,
  getTopFiftyLists,
  selectedLevel,
  setSelectedlevel,
  activeTerm,
  setToggle,
  challengeMode,
  callStudents,
  setShowAllStudents,
  setExpand,
  defaultActive = 3,
  subject = 1,
}) => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const { defaultLevel, country, groupId } = useSelector(
    (state) => state.studentDetails
  );
  const primaryLevels = [1, 2, 3, 4, 5, 6];
  const showLevels = (level) => {
    return `${t('leaderboard:tabs.primary', 'Primary')} ${formatChineseNumber({
      value: level,
      language: i18n.language,
    })}`;
  };

  const categories = [
    { name: t('leaderboard:tabs.myClass', 'My Class'), value: 1 },
    { name: t('leaderboard:tabs.mySchool', 'My School'), value: 2 },
    {
      name: t(
        'leaderboard:tabs.schools',
        { name: country },
        `${country} Schools`
      ),
      value: 3,
    },
    { name: t('leaderboard:tabs.globalSchools', 'Global Schools'), value: 4 },
  ];

  const filteredCategories = () => {
    if (challengeMode === 'championClass' && activeTerm === 'classic') {
      return defaultLevel === selectedLevel
        ? categories
        : categories.splice(1, 3);
    }
    if (challengeMode === 'championClass' && activeTerm === 'term') {
      return defaultLevel === selectedLevel
        ? categories.splice(0, 3)
        : categories.splice(1, 2);
    }
    if (challengeMode === 'superhero') {
      return [{ name: t('leaderboard:tabs.overAll', 'Overall'), value: 5 }]; // value will be updated later
    }
    return categories.splice(0, 3);
  };

  const getStudentsList = (val) => {
    setActive(val);
    const level = val === 1 ? defaultLevel : selectedLevel;
    if (val === 1) setSelectedlevel(defaultLevel);
    if (val === 1 && challengeMode === 'championClass') setToggle(true);
    if (val !== 1 && challengeMode === 'championClass') setToggle(false);
    getLists(val, level);
    if (val === 1 && challengeMode === 'championClass') callStudents(groupId);
  };

  const geStudentsListLevel = (val) => {
    // default to active when it's toggling away from 'MyClass'
    if (challengeMode === 'championClass' && defaultLevel === selectedLevel) {
      setActive(defaultActive);
    }
    setSelectedlevel(val);
    getLists(active, val);
  };

  const getTopFiftyStudentsList = () => {
    getTopFiftyLists();
  };

  return (
    <CategoryStyle
      subject={subject}
      page={challengeMode}
      classCategory={active}
      data-cy="categories"
    >
      <div className="categories-container">
        {filteredCategories().map((item, index) => (
          <CategoryType
            key={index}
            onClick={() => {
              getStudentsList(item.value);
              if (challengeMode === 'championClass') setShowAllStudents({});
              if (challengeMode === 'heroOfTheDay') {
                setExpand(false);
              }
            }}
            active={active === item.value}
            subject={subject}
          >
            {item.name}
          </CategoryType>
        ))}
      </div>
      <div className="right-container">
        {showTopFifty && (
          <div className="top-fifty" onClick={getTopFiftyStudentsList}>
            <img src={TopFifty} alt="top-50" />
            <CategoryType data-cy="top-fifty-button" subject={subject}>
              {t('leaderboard:tabs.showTop', 'Show top 50')}
            </CategoryType>
          </div>
        )}
        {challengeMode !== 'superhero' && (
          <div className="select-level">
            <DropdownMenu
              dataCy="dropdown-level"
              selectedValue={showLevels(selectedLevel)}
              values={primaryLevels}
              setValue={geStudentsListLevel}
              valueTemplate={showLevels}
              width="150px"
              className="drop-down"
              widthipad="170px"
            />
          </div>
        )}
      </div>
    </CategoryStyle>
  );
};

export default Category;
