import React from 'react';
import { Container } from './index.styles';

const QuestionFallback = ({ qnID }) => {
  return (
    <Container>
      <p style={{ color: 'red' }}>
        Question ID {qnID} cannot be rendered, please{' '}
        <a
          href="https://support.koobits.com/hc/en-gb/requests/new"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'red', textDecoration: 'underline' }}
        >
          contact our support
        </a>
        .
      </p>
    </Container>
  );
};

export default QuestionFallback;
