import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './DetailsInfo.styles';

const DetailsInfo = ({ page, category, info, ...otherprops }) => {
  const { t } = useTranslation(['startPage']);
  return (
    <Container {...otherprops} page={page} data-cy="details-container">
      <div className="category">
        {t(`startPage:words.${category}`, `${category}`)}
      </div>
      <div className="info">{info}</div>
    </Container>
  );
};

export default DetailsInfo;
