import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  padding-top: 6px;
  padding-right: 8px;
  img {
    width: 26px;
    cursor: pointer;
    ${({ isPlaying }) => {
      return css`
        display: ${isPlaying ? 'block' : 'none'};
      `;
    }}
  }
  .voice {
    path {
      fill: #00b8ff;
    }
    &:hover {
      cursor: pointer;
      path {
        fill: #00a7ff;
      }
    }
    ${({ isPlaying }) => {
      return css`
        display: ${isPlaying ? 'none' : 'block'};
      `;
    }}
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    height: 32px;
  }
`;
