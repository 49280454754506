import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import SearchingScene from './SearchingScene';
import OpponentFoundScene from './OpponentFoundScene';

const StyledOverlayContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MatchingAnimation = ({
  randomOpponents,
  selectedOpponentIndex,
  onFinishAnimation,
}) => {
  const [hasFound, setHasFound] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHasFound(true);
    }, 2000);
  }, []);
  return (
    <StyledOverlayContainer>
      {!hasFound && (
        <SearchingScene randomOpponents={randomOpponents} show={false} />
      )}
      {hasFound && (
        <OpponentFoundScene
          randomOpponents={randomOpponents}
          selectedOpponentIndex={selectedOpponentIndex}
          onFinishAnimation={onFinishAnimation}
        />
      )}
    </StyledOverlayContainer>
  );
};

export default MatchingAnimation;
