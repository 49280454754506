const showPosition = (pos) => {
  if (pos < 4) {
    if (pos === 1) return 'first';
    if (pos === 2) return 'second';
    if (pos === 3) return 'third';
  }
  return 'default';
};

export default showPosition;
