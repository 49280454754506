import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: ${({ position }) => position};
  right: 6rem;
  margin-top: ${({ position }) => position && '0.5rem'};
`;

export const Legend = styled.div`
  display: flex;
  font-size: 0.625rem;
  color: #959595;
  align-items: center;
  img {
    height: 18px;
    width: 18px;
    margin: 0 0.3rem 0 0.6rem;
  }
`;

export const LegendText = styled.p`
  margin-bottom: 0;

  ${({ color }) => {
    if (color === '#FFFFFF') {
      return css`
        color: #ffffff;
        font-weight: bold;
        font-size: 0.725rem;
      `;
    }
  }}
`;
