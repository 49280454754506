import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import HeaderTitle from 'assets/img/leaderboard/top-brain/science/top-brain-title2.png';

import BackButton from 'components/Shared/BackButton';
import { ChallengeType } from '../../../../../../components';
import TermList from '../../../TermsList';

import {
  Container,
  ButtonContainer,
  CenterBar,
  ScoringPeriod,
} from './HeaderScienceTB.styles';

const HeaderScienceTB = ({ term, setTerm, termHandler }) => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();

  return (
    <Container>
      <ButtonContainer>
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(109, 177, 0, 0.77)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/leaderboard?subject=science')}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <CenterBar>
        <div className="middle-content">
          <img src={HeaderTitle} width="444px" alt="header-title" />
        </div>
        <TermList
          setCurrentTerm={setTerm}
          currentTerm={term}
          clickHandler={termHandler}
        />
        <ScoringPeriod>
          <span>
            {t('leaderboard:topBrainBanner.scoringPeriod', `Scoring Period`)}
          </span>
          {t(
            'leaderboard:topBrainBanner.term',
            { number: term?.termId },
            `Term ${term?.termId}`
          )}{' '}
          {t('leaderboard:topBrainBanner.week', `Week`)} {term?.startWeek} -{' '}
          {term?.endWeek} {moment(term?.startDate).format('DD-MMM')}{' '}
          {t('leaderboard:topBrainBanner.to', `to`)}{' '}
          {moment(term?.endDate).format('DD-MMM')}
        </ScoringPeriod>
      </CenterBar>
      <ChallengeType styles={{ top: '12%' }} />
    </Container>
  );
};

export default HeaderScienceTB;
