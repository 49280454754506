/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { OverlayTrigger, Overlay } from 'react-bootstrap';

// Constants
import {
  mappingReward,
  mockLevelsData,
  statusInfoData,
  DCMissionsData,
  mappingMissionBgType,
  mappingQuestionStatus,
  mappingChallengeLevelCodeInToolkit,
} from 'constants/dailyChallengev2';

// Images && Icon
import KoKoIcon from 'assets/img/dailychallengev2/koko.svg';
import CPactive from 'assets/img/dailychallengev2/cp-active.svg';
import CPdefault from 'assets/img/dailychallengev2/cp-default.svg';
import LockedIcon from 'assets/img/dailychallengev2/locked.svg';
import PointingHandeIcon from 'assets/img/dailychallengev2/pointing-hand.svg';
import MissionCheck from 'assets/img/dailychallengev2/mission-check.svg';
import DailyChallengeText from 'assets/img/dailychallengev2/daily-challenge-text.svg';
import { ReactComponent as Runway } from 'assets/img/dailychallengev2/runway.svg';
import { ReactComponent as RunwayResponsive } from 'assets/img/dailychallengev2/runway-responsive.svg';
import WarningIcon from 'assets/img/dailychallengev2/warning.svg';

// Component && Style
import Spinner from 'components/Shared/Spinner';
import BackButton from 'components/Shared/BackButton';
import ErrorModal from 'components/Shared/ErrorModal';
import BreakdownCP from 'components/Shared/BreakdownCP';
import {
  setActive,
  updateDailyChallengeLevelB2C,
  getDailyChallengeStatusBySubjectID,
  getDailyChallengeDetailsBySubjectID,
} from 'store/dailyChallenge/dailyChallengeSlice';
import { getRewards } from 'store/dashboard/rewardsSlice';

import useIsDesktop from 'hooks/responsive/useIsDesktop';
import useIsMobile from 'hooks/responsive/useIsMobile';
import {
  StyledLoaderWrap,
  Container,
  Header as HeaderContainer,
  HeaderRight,
  HeaderLevelContainer,
  LevelItem,
  MainContainer,
  StatusItem,
  Missionitem,
  RewardIcon,
  ModalContainer,
  Loader,
  PointingHand,
  LevelTooltip,
  RewardToolTip,
  InfoTooltip,
  MobileBottomBar,
} from './DailyChallengev2.style';

const IMAGES = {
  KoKoIcon,
  CPactive,
  CPdefault,
  LockedIcon,
  WarningIcon,
  MissionCheck,
  PointingHandeIcon,
  DailyChallengeText,
};

/**
 *  Only for B2C user,
 *  Validation the level is lock
 * @param {string} levelName - level name
 * @param {number} currentUserLevel - max CHallenge level
 * @returns
 */
const checkIslock = (levelName, currentUserLevel) => {
  if (
    (levelName === 'Elementary' && currentUserLevel >= 0) ||
    (levelName === 'Intermediate' && currentUserLevel >= 3) ||
    (levelName === 'Advanced' && currentUserLevel >= 5) ||
    (levelName === 'Master' && currentUserLevel >= 10)
  ) {
    return false;
  }
  return true;
};

const HeaderLevel = ({ currentLevel, onChangeLevel, currentUserLevel }) => {
  const { t } = useTranslation(['dailyChallengev2']);
  return (
    <HeaderLevelContainer>
      {mockLevelsData.map((level, index) => {
        const isLock = checkIslock(level.name, currentUserLevel);
        const isActive = currentLevel === level.name;

        return (
          <React.Fragment key={level.levelCode}>
            {isLock && index > 0 ? (
              <OverlayTrigger
                transition={false}
                placement="bottom"
                overlay={
                  <LevelTooltip id="level-overlay">
                    <div className="dc-tooltip">
                      {t('dailyChallengev2:toolkit.reach', 'Reach')}{' '}
                      <span className="dc-tooltip-badge">
                        Lvl{' '}
                        {
                          mappingChallengeLevelCodeInToolkit[level.levelCode]
                            .unlockLevel
                        }
                      </span>{' '}
                      {t('dailyChallengev2:toolkit.toUnlock', 'to unlock')}
                    </div>
                  </LevelTooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <LevelItem
                    {...triggerHandler}
                    currentLevel={currentLevel}
                    locked={isLock}
                    active={isActive}
                    onClick={() => onChangeLevel(level, currentLevel)}
                  >
                    <div className="dc-levels">
                      <img className="dc-level-icon" src={level.icon} alt="" />
                      {isLock && (
                        <img
                          className="dc-locked"
                          src={IMAGES.LockedIcon}
                          alt=""
                        />
                      )}
                    </div>
                    <p ref={ref}>
                      {isActive && <span className="dc-dot">&#8226;</span>}
                      {t(`dailyChallengev2:header.${level.name}`, level.name)}
                    </p>
                  </LevelItem>
                )}
              </OverlayTrigger>
            ) : (
              <LevelItem
                currentLevel={currentLevel}
                locked={isLock}
                active={isActive}
                onClick={() => onChangeLevel(level, currentLevel)}
              >
                <div className="dc-levels">
                  <img className="dc-level-icon" src={level.icon} alt="" />
                  {isLock && (
                    <img className="dc-locked" src={IMAGES.LockedIcon} alt="" />
                  )}
                </div>
                <p>
                  {isActive && <span className="dc-dot">&#8226;</span>}
                  {t(`dailyChallengev2:header.${level.name}`, level.name)}
                </p>
              </LevelItem>
            )}
            {index !== 3 && <span />}
          </React.Fragment>
        );
      })}
    </HeaderLevelContainer>
  );
};

const Header = ({ currentLevel, onChangeLevel, currentUserLevel }) => {
  const history = useHistory();
  const { t } = useTranslation(['dailyChallengev2']);
  return (
    <HeaderContainer>
      <BackButton
        backText={t('common:back', 'Back')}
        btnBgColor="#FBB32A"
        padding="0.3rem 1.2rem"
        onClick={() => history.push('/dashboard?view=koochallenge')}
        isResponsive
      />
      <HeaderRight>
        <HeaderLevel
          currentLevel={currentLevel}
          onChangeLevel={onChangeLevel}
          currentUserLevel={currentUserLevel}
        />
        <img
          className="dc-text"
          src={IMAGES.DailyChallengeText}
          alt="Daily Challenge"
        />
      </HeaderRight>
    </HeaderContainer>
  );
};

const LoadingModal = () => {
  return (
    <ModalContainer opacity={0.7}>
      <Loader />
    </ModalContainer>
  );
};

const WarningModal = ({ onCheckWarning }) => {
  const { t } = useTranslation(['dailyChallengev2']);
  return (
    <ModalContainer>
      <div className="dc-alert-modal">
        <p>
          {t(
            'dailyChallengev2:warning.content',
            'It seems that you have already completed at least one question. You cannot change the difficulty level for today.'
          )}{' '}
        </p>
        <button type="button" onClick={onCheckWarning}>
          {t('dailyChallengev2:warning.ok', 'OK')}
        </button>
      </div>
    </ModalContainer>
  );
};

const Main = ({
  currentLevel,
  onChangeLevel,
  currentThemeLevel,
  showChangeDifficultyWarningModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallengev2']);
  const rewardIconTarget = useRef(null);
  const [missionsData, setMissionsData] = useState(DCMissionsData);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [hasMobileFirstLoad, setHasMobileFirstLoad] = useState(false);

  const {
    currDailyChallenge,
    dailyChallengeSubmissions,
    isLoading,
  } = useSelector((state) => state.dailyChallenge);

  const kokoClaimed =
    currDailyChallenge?.IsKokoClaimed ||
    currDailyChallenge?.IsKokoClaimable ||
    false;

  // responsive
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();

  const onEnterQuestion = (missionNo) => {
    dispatch(setActive(missionNo));
    history.push('/challenges/daily-challenge/question-view');
  };

  const onCheckWarning = () => {
    const currentChallengeLevelData = mockLevelsData.filter(
      (item) => item.levelCode === currDailyChallenge.ChallengeLevel
    )[0];

    onChangeLevel(currentChallengeLevelData, currentLevel);
  };

  const onImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    if (dailyChallengeSubmissions.length === 10) {
      // The science's questions only 5, math is 10
      setMissionsData((prev) => {
        return prev.map((item, index) => {
          const questionStatusCode =
            dailyChallengeSubmissions[index].QuestionStatus;
          return {
            ...item,
            status: mappingQuestionStatus[questionStatusCode],
            reward: dailyChallengeSubmissions[index]?.ChallengePoints || 0,
          };
        });
      });
    }
  }, [dailyChallengeSubmissions]);

  const renderCurriculum = (
    <div className="dc-curriculum">
      <span>{currDailyChallenge?.CurriculumName}</span>
      <OverlayTrigger
        transition={false}
        overlay={
          <InfoTooltip id="difficultyInfo">
            {t(
              `dailyChallengev2:toolkit.curriculumInfo`,
              'Go to setting page to change curriculum.'
            )}
          </InfoTooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <div {...triggerHandler}>
            <img
              ref={ref}
              width="18"
              height="18"
              src={IMAGES.WarningIcon}
              alt=""
            />
          </div>
        )}
      </OverlayTrigger>
    </div>
  );

  return (
    <MainContainer currentLevel={currentThemeLevel}>
      <div className="dc-content">
        {!kokoClaimed && <PointingHand src={IMAGES.PointingHandeIcon} alt="" />}
        {isDesktop ? (
          <Runway className="dc-runway" />
        ) : (
          <RunwayResponsive className="dc-runway" />
        )}
        {missionsData.map((mission) => {
          const MissionBg = mappingMissionBgType[mission.iconType];
          const isCompleted = mission.status === 'completed';
          return (
            <Missionitem
              key={mission.missionNo}
              status={mission.status}
              position={
                isDesktop ? mission.position : mission.responsivePosition
              }
              onClick={() => onEnterQuestion(mission.missionNo)}
            >
              <div className="mission-icon">
                <MissionBg className="mission-bg" />
                <span className="mission-number">{mission.missionNo}</span>
                {isCompleted && <img src={IMAGES.MissionCheck} alt="" />}
              </div>
              <div className="mission-cp">
                <img
                  src={isCompleted ? IMAGES.CPactive : IMAGES.CPdefault}
                  alt=""
                />
                <span>{mission.reward} CP</span>
              </div>
            </Missionitem>
          );
        })}
        <RewardIcon ref={rewardIconTarget} currentLevel={currentThemeLevel}>
          <img
            onLoad={onImageLoad}
            src={
              mappingReward[currentThemeLevel][
                kokoClaimed ? 'iconClaimed' : 'icon'
              ]
            }
            alt=""
          />

          {currDailyChallenge?.IsKokoClaimed && (
            <div className="dc-reward-claimed">
              <img src={IMAGES.MissionCheck} alt="" />
            </div>
          )}
        </RewardIcon>
        {!isMobile && (
          <Overlay
            show={!kokoClaimed && rewardIconTarget.current && isImageLoaded}
            target={rewardIconTarget.current}
            placement="top"
            styles={{ zIndex: 10 }}
          >
            {(props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <RewardToolTip id="hint-overlay" {...props}>
                {t(
                  `dailyChallengev2:rewardText.complete`,
                  'Complete all questions to'
                )}{' '}
                <br /> {t(`dailyChallengev2:rewardText.earn`, 'earn')}{' '}
                <img src={IMAGES.KoKoIcon} alt="" />{' '}
                {mappingReward[currentThemeLevel].rewards} KoKo
              </RewardToolTip>
            )}
          </Overlay>
        )}
      </div>

      <div className="dc-info">
        <div>
          {statusInfoData.map((item) => (
            <StatusItem key={item.id} color={item.color}>
              {t(`dailyChallengev2:status.${item.title}`, item.title)}
            </StatusItem>
          ))}
          {!isMobile && <>{renderCurriculum}</>}
        </div>
        {isMobile && (
          <MobileBottomBar className="dc">
            <BreakdownCP
              target="dc"
              hasFirstLoad={hasMobileFirstLoad}
              setHasFirstLoad={setHasMobileFirstLoad}
            />
            {renderCurriculum}
          </MobileBottomBar>
        )}
      </div>
      {isLoading && <LoadingModal />}
      {showChangeDifficultyWarningModal && (
        <WarningModal onCheckWarning={onCheckWarning} />
      )}
    </MainContainer>
  );
};

const subjectId = 1;

const DailyChallengev2 = () => {
  const dispatch = useDispatch();
  const [currentLevel, setCurrentLevel] = useState('Elementary');
  const [pageError, setPageError] = useState(false);
  const [currentThemeLevel, serCurrentThemeLevel] = useState(currentLevel);
  const [
    showChangeDifficultyWarningModal,
    setShowChangeDifficultyWarningModal,
  ] = useState(false);
  const { rewards } = useSelector((state) => state.rewards);
  const currentUserLevel = rewards?.EXPData?.CurrentLevel?.ID || 1;

  const [isFetchDataAlready, setIsFetchDataAlready] = useState(false);
  const [hasDesktopFirstLoad, setHasDesktopFirstLoad] = useState(false);

  const {
    isErr,
    isLoading,
    currDailyChallenge,
    dailyChallengeStatus,
    currDailyChallengeErr,
  } = useSelector((state) => state.dailyChallenge);

  // responsive
  const isMobile = useIsMobile();

  /**
   * Handle is show the warning modal
   * @async
   * @function handleShowDifficultySelection
   * @param {number} levelCode go to the level's levelCode
   *
   */
  const handleShowDifficultySelection = (levelCode) => {
    if (
      !currDailyChallenge?.UserQuestionSubmissions ||
      !currDailyChallenge?.ChallengeLevel
    ) {
      setPageError(true);
    }

    const { UserQuestionSubmissions, ChallengeLevel } = currDailyChallenge;

    const skippedOrCompletedQns = UserQuestionSubmissions.filter(
      (submission) =>
        submission.SubmissionModel !== null &&
        (submission.SubmissionModel.SubmissionResult === 1 ||
          submission.QuestionStatus === 4)
    );

    const hasActiveQuestion = skippedOrCompletedQns.length > 0;

    if (hasActiveQuestion) {
      if (ChallengeLevel === levelCode) {
        return setShowChangeDifficultyWarningModal(false);
      }
      return setShowChangeDifficultyWarningModal(true);
    }

    setShowChangeDifficultyWarningModal(false);
    return dispatch(updateDailyChallengeLevelB2C(levelCode));
  };

  /**
   * Chnage level handler
   * @function
   * @param {object} level - go into the level's data
   * @param {string} currentLevel from this level
   */
  const onChangeLevel = (level, currentLev) => {
    if (isLoading || level.name === currentLev) return null;
    if (!checkIslock(level.name, currentUserLevel)) {
      handleShowDifficultySelection(level.levelCode);
      setCurrentLevel(level.name);
    }
    return null;
  };

  /**
   * Fetch daily challenge data
   */
  useEffect(() => {
    setIsFetchDataAlready(false);
    if (!dailyChallengeStatus || dailyChallengeStatus?.subjectID !== 1) {
      (async function mounted() {
        await dispatch(getRewards());
        await dispatch(getDailyChallengeStatusBySubjectID(subjectId));
        await dispatch(getDailyChallengeDetailsBySubjectID(subjectId));
      })();
    } else {
      (async function mounted() {
        await dispatch(getDailyChallengeDetailsBySubjectID(subjectId));
      })();
    }
    setIsFetchDataAlready(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    // Handle change cuurentLevel to the ChallengeLevel
    if (currDailyChallenge?.ChallengeLevel) {
      const latestChallengeLevelData = mockLevelsData.filter(
        (item) => item.levelCode === currDailyChallenge.ChallengeLevel
      )[0];

      setCurrentLevel(latestChallengeLevelData.name);
      serCurrentThemeLevel(latestChallengeLevelData.name);
    }

    // Handle first time user
    if (
      currDailyChallenge?.IsFirstTimeUser &&
      currDailyChallenge?.ChallengeLevel === 0
    ) {
      (async function setUserLevel() {
        await dispatch(updateDailyChallengeLevelB2C(1));
      })();
    }
  }, [currDailyChallenge, dispatch]);

  useEffect(() => {
    if (currDailyChallengeErr || isErr) setPageError(true);
    else setPageError(false);
  }, [currDailyChallengeErr, isErr]);

  /**
   * Loading spinner
   */
  if (!isFetchDataAlready) {
    return (
      <StyledLoaderWrap>
        <Spinner />
      </StyledLoaderWrap>
    );
  }

  /**
   * Error handler
   */
  if (pageError) {
    return (
      <ErrorModal
        backPage="/dashboard?view=koochallenge"
        errorMessage={currDailyChallengeErr || isErr || 'Unexpect Error'}
      />
    );
  }

  return (
    <Container>
      <Header
        currentLevel={currentLevel}
        onChangeLevel={onChangeLevel}
        currentUserLevel={currentUserLevel}
      />
      <Main
        currentLevel={currentLevel}
        onChangeLevel={onChangeLevel}
        currentThemeLevel={currentThemeLevel}
        showChangeDifficultyWarningModal={showChangeDifficultyWarningModal}
      />
      {!isMobile && (
        <BreakdownCP
          target="dc"
          style={{ position: 'absolute', right: '3vw', top: '34%' }}
          hasFirstLoad={hasDesktopFirstLoad}
          setHasFirstLoad={setHasDesktopFirstLoad}
        />
      )}
    </Container>
  );
};

export default DailyChallengev2;
