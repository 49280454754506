/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  font-size: 16px;
  color: #202020;
  .title {
    color: #141414;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }
  .details-text {
    margin: 0px;
  }
  .orange-text {
    color: #ff5b00;
    margin-bottom: 2rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: 14px;

    .subtitle,
    .details-text,
    .orange-text {
      text-align: center;
    }

    .orange-text {
      font-weight: 600;
    }
  }
`;
