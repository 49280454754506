import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isNil, isEmpty } from 'ramda';
import moment from 'moment-timezone';

import { getTerms, getSeasons } from 'store/leaderboard/leaderboardSlice';
import {
  getTopBrainLeaderboard,
  setType,
} from 'store/leaderboard/topBrainLeaderboardSlice';

import formatChineseNumber from 'helpers/formatChineseNumber';
import useGlobalFeature from 'hooks/useGlobalFeature';

import DropdownMenu from 'components/Shared/DropdownMenu';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import { isLeaderboardFeatureOpen } from '../../helper';
import { HeaderMathTB, HeaderMathTBClassic } from './components';
import { Category, TopBrainList, NoParticipants } from '../../components';

import { PRIMARY_LEVELS, LEADERBOARD_ID, PERIOD_TYPE } from '../../constant';

import { Container } from './TopBrain.styles';

const TopBrainMath = () => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const history = useHistory();

  const { type, data, isLoading, error } = useSelector(
    (state) => state.topBrainV2
  );
  const { data: seasons } = useSelector((state) => state.leaderboard.seasons);
  const { defaultLevel, country } = useSelector(
    (state) => state.studentDetails
  );
  const [activeCategory, setActiveCategory] = useState(2);
  const [selectedLevel, setSelectedLevel] = useState(defaultLevel);
  const [currentTerm, setCurrentTerm] = useState(null);
  const [currentSeason, setCurrentSeason] = useState(null);
  const findCurrentSeason = seasons.find(
    (term) =>
      moment().isSameOrAfter(term.startDate) &&
      moment().isSameOrBefore(term.endDate)
  );

  const singaporeSchool = country === 'Singapore';
  const { featuresHashmap } = useGlobalFeature(null, [
    'topbrain-leaderboard-termly',
    'topbrain-leaderboard-classic',
  ]);
  const isTermlyOpen = isLeaderboardFeatureOpen(
    featuresHashmap['topbrain-leaderboard-termly']
  );
  const isClassicOpen = isLeaderboardFeatureOpen(
    featuresHashmap['topbrain-leaderboard-classic']
  );

  const showLevels = (level) => {
    return `${t('leaderboard:tabs.primary', 'Primary')} ${formatChineseNumber({
      value: level,
      language: i18n.language,
    })}`;
  };

  const levelHandler = (value) => {
    setSelectedLevel(value);
  };

  const categoryHandler = (filter) => {
    setActiveCategory(filter);
    if (filter === 4) {
      setSelectedLevel(defaultLevel);
    }
  };

  const getTopBrainData = (isTop50) => {
    const params = {
      id: LEADERBOARD_ID.topBrain.math,
      category: activeCategory,
      level: selectedLevel,
      periodId: type === 'classic' ? currentSeason?.id : currentTerm?.id,
      periodType: type === 'classic' ? PERIOD_TYPE.season : PERIOD_TYPE.term,
      isTop50,
    };
    dispatch(getTopBrainLeaderboard(params));
  };

  const termHandler = (item) => {
    setCurrentTerm(item);
  };

  useEffect(() => {
    if (!singaporeSchool) {
      if (!isClassicOpen) {
        history.replace('/maintenance-redirect');
        return;
      }
      dispatch(setType('classic'));
    } else if (!isTermlyOpen && isClassicOpen) {
      dispatch(setType('classic'));
    } else if (!isClassicOpen && !isTermlyOpen) {
      history.replace('/maintenance-redirect');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isClassicOpen, isTermlyOpen, singaporeSchool]);

  useEffect(() => {
    if (isNil(selectedLevel)) {
      setSelectedLevel(defaultLevel);
    }
  }, [defaultLevel, selectedLevel]);

  useEffect(() => {
    if (type === 'classic') {
      dispatch(getSeasons());
    } else {
      dispatch(getTerms());
    }
  }, [dispatch, type]);

  useEffect(() => {
    setCurrentSeason(findCurrentSeason);
  }, [findCurrentSeason]);

  useEffect(() => {
    if (!isNil(selectedLevel)) {
      if (type === 'classic' && !isNil(currentSeason)) {
        getTopBrainData();
      }
      if (type === 'term' && !isNil(currentTerm)) {
        getTopBrainData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel, activeCategory, currentTerm, type, currentSeason]);

  return (
    <Container>
      {type === 'classic' ? (
        <HeaderMathTBClassic
          data={seasons}
          season={currentSeason}
          setSeason={setCurrentSeason}
          singaporeSchool={singaporeSchool}
        />
      ) : (
        <HeaderMathTB
          term={currentTerm}
          termHandler={termHandler}
          setTerm={setCurrentTerm}
        />
      )}
      <Category
        top50Available
        availableCategory={['class', 'school', 'national']}
        active={activeCategory}
        categoryHandler={categoryHandler}
        top50Handler={() => getTopBrainData(true)}
      >
        <DropdownMenu
          dataCy="dropdown-level"
          selectedValue={showLevels(selectedLevel)}
          values={PRIMARY_LEVELS}
          setValue={levelHandler}
          valueTemplate={showLevels}
          width="150px"
          className="drop-down"
          widthipad="170px"
        />
      </Category>
      {isLoading && <Spinner />}
      {!isLoading && !isEmpty(data) && isNil(error) && (
        <TopBrainList data={data} category={activeCategory} />
      )}
      {!isLoading && isEmpty(data) && isNil(error) && <NoParticipants />}
      {!isLoading && !isNil(error) && (
        <ErrorModal errorMessage={error} reloadAction={getTopBrainData} />
      )}
    </Container>
  );
};

export default TopBrainMath;
