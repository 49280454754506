import styled from 'styled-components';
import MainBg from 'assets/img/doodle-bg.svg';

export const Container = styled.div`
  background: ${(props) => props.bgColour ?? '#87796d'};
  min-height: 100vh;
`;

export const Main = styled.div`
  background: url(${MainBg});
  background-repeat: repeat-y;
  background-size: contain;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-top: 68px;
  }
`;
