import React from 'react';
import resize from 'assets/img/assignment/bar-model/resize.png';

function ResizeHandle({ small, ...otherProps }) {
  return (
    <img
      {...otherProps}
      src={resize}
      alt="resize"
      style={{ width: small ? 8 : 12, height: small ? 8 : 12, zIndex: 99 }}
    />
  );
}

export default React.memo(ResizeHandle);
