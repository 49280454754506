import React from 'react';
import { useTranslation } from 'react-i18next';

import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';
import Spinner from 'components/Shared/Spinner';
import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import { compareValues } from 'helpers/compareValue';
import { convertToTimezone } from 'pages/Leaderboard/v2/helper';
import { Container, Lists } from './Submission.styles';

const Submission = ({ data, isLoading }) => {
  const { t } = useTranslation(['leaderboard']);

  const nonDuplicateData = Object.values(
    data.reduce((acc, current) => {
      if (!acc[current.userId]) {
        return {
          ...acc,
          [current.userId]: current,
        };
      }
      return acc;
    }, {})
  ).sort(compareValues('leaderboardRank'));
  return (
    <Container>
      <div className="top-heading">
        {t('leaderboard:championClass.friendList', `Friends List`)}
      </div>
      {isLoading && <Spinner />}
      {!isLoading &&
        nonDuplicateData.map((item, i) => {
          return (
            <Lists key={i} data-cy={`data-student-${i}`} pos={i}>
              <div className="positions">{i + 1}</div>
              <div className="list-name" data-cy="user-name">
                {item.userName}
              </div>
              <div className="middle">
                <div className="list-points" data-cy="list-points">
                  <img
                    width="30px"
                    src={`https://static.koobits.com/eduresources/schlogo/${item.schoolLogo}.png`}
                    alt="logo"
                  />
                  <img src={CPIcon} alt="cps" />
                  {item.cpPoints}
                  {item.isSuperHero && <SuperheroIconModal />}
                </div>
              </div>
              <div className="list-achievement">
                {convertToTimezone(item.lastSubmissionDate).format(
                  'DD-MMM-YYYY HH:mm'
                )}
              </div>
            </Lists>
          );
        })}
    </Container>
  );
};

export default Submission;
