import styled from 'styled-components';

export const LoginSectionContainer = styled.div`
  grid-column: 7 / span 4;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    grid-column: 6 / span 5;
  }

  .gradient-bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 63vw;
    background-image: radial-gradient(circle, #9adaff, #8077f6);
    z-index: -1;
    @media only screen and (max-width: ${({ theme }) =>
        `${theme.rwd.mobile}px`}) {
      width: 100vw;
    }
  }

  .home-icon {
    width: calc(20 / 1366 * 100vw);
    height: calc(20 / 1366 * 100vw);
  }

  .home-text {
    color: white;
    margin-left: 0.5rem;
    font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    @media only screen and (max-width: 1290px) {
      display: none;
    }
  }

  .brandname-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 6rem 0 1rem;
    @media only screen and (max-width: ${({ theme }) =>
        `${theme.rwd.mobile}px`}) {
      margin: 3rem 0 1rem;
    }
  }

  .brandname-container img {
    position: relative;
    top: 0.4rem;
  }

  .brandname-logo {
    width: calc(147 / 1366 * 100vw);
    height: calc(55 / 1366 * 100vw);
    @media only screen and (max-width: 1024px) {
      width: calc(147 / 1024 * 100vw);
      height: calc(55 / 1024 * 100vw);
    }
  }

  .brandname-divider {
    position: relative;
    top: 0.4rem;
    margin-right: 1rem;
    color: white;
  }

  .brandname-tagline {
    display: flex;
    flex-direction: row;
    height: 40px;
    position: relative;
    top: -0.1rem;
  }

  .brandname-tagline-text {
    font-size: 19px;
    color: #e5ffeb;
    align-self: flex-end;
    text-shadow: 3px 0px 6px rgba(0, 0, 0, 0.14);
  }

  .brandname-tagline-star {
    align-self: flex-start;
    width: 24.3px;
    height: 23.15px;
  }

  .stars {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    @media only screen and (max-width: ${({ theme }) =>
        `${theme.rwd.mobile}px`}) {
      display: none;
    }
  }
  .mascot {
    position: relative;
    top: 2rem;
    pointer-events: none;
  }

  footer {
    color: #4852b5;
    font-size: 12px;
    position: fixed;
    bottom: 1rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    grid-column: 1 / span 10;
    padding: 1.5rem 1rem 1rem;

    .login-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .login-title {
      position: static;
    }

    .brandname-container img {
      position: relative;
      top: 0.4rem;
    }

    .brandname-logo {
      width: 134px;
      height: 49.94px;
    }

    .brandname-divider {
      margin-right: 0.5rem;
    }

    .home-icon {
      width: 23px;
      height: 23px;
    }
    .stars {
      margin-top: 2rem;
      right: -5.5rem;
      width: 140vw;
      height: 73vh;
      flex-shrink: 0;
    }
  }

  @media (min-width: 1920px) {
    .brandname-container {
      margin: 10rem 0 1rem;
    }
    .brandname-tagline {
      height: 50px;
      position: relative;
      top: -0.1rem;
    }
    .brandname-tagline-text {
      font-size: 26px;
    }
    .brandname-tagline-star {
      width: 32.42px;
      height: 30.89px;
    }

    footer {
      font-size: 16px;
      bottom: 2rem;
    }
  }
`;

export const LoginHeader = styled.div`
  position: absolute;
  top: 1rem;
  right: 2.5rem;
  font-size: calc(15 / 1366 * 100vw);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35%;
  justify-content: space-between;
  @media (min-width: 1920px) {
    top: 2rem;
  }
  @media (max-width: 1024px) {
    top: 1.5rem;
    width: 42%;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 85%;
  }
  .links {
    a {
      .link-text {
        background: none;
        border: none;
        color: white;
        font-size: 14px;
        font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont,
          'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
          'Droid Sans', 'Helvetica Neue', sans-serif;
        @media only screen and (min-width: 1920px) {
          font-size: 22px;
        }
        @media only screen and (max-width: ${({ theme }) =>
            `${theme.rwd.mobile}px`}) {
          display: none;
        }
      }
      .link-icon {
        display: none;
        @media only screen and (max-width: ${({ theme }) =>
            `${theme.rwd.mobile}px`}) {
          display: inline-block;
        }
      }
    }
    a:hover {
      color: white;
    }
    span {
      color: white;
      font-size: 18px;
      margin-left: 5px;
      margin-right: 5px;
      @media only screen and (max-width: ${({ theme }) =>
          `${theme.rwd.mobile}px`}) {
        font-size: 15px;
      }
    }
    @media only screen and (min-width: 1920px) {
      span {
        font-size: 20px;
      }
    }
  }
`;

export const SignupContainer = styled.div`
  color: white;
  margin-top: 15px;
  z-index: 100;
  font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  @media only screen and (min-width: 1920px) {
    font-size: 1.2rem;
  }
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin-top: 0px;
  }
  a {
    border: 1px solid white;
    background: none;
    color: white;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    margin-left: 0.25rem;
  }
  a:hover {
    text-decoration: none;
    background: rgba(255, 255, 255, 0.2);
  }
`;
