import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from 'store/index';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';
import LogRocket from 'logrocket';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import 'helpers/eventListenerPrototype';
import { history } from './Routes';

if (process.env.REACT_APP_SENTRY === 'true') {
  Sentry.init({
    dsn:
      'https://f8400c9be94d43e79e31495969ff9e4a@o931734.ingest.sentry.io/5880638',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new SentryRRWeb({
        // ...options
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    normalizeDepth: 10, // Or however deep you want your state context to be.
  });
}

if (process.env.REACT_APP_LOGROCKET === 'true') {
  LogRocket.init('unz1xv/koobits-student-portal');
}
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

if (window.Cypress) {
  window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
