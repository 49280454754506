import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchBFFDetails,
  postRemoveFriend,
  fetchSentBFFRequests,
  fetchIncomingFriendRequests,
  fetchFriendInfoByBFFCode,
  postInviteFriend,
  acceptFriend,
  rejectFriend,
} from 'services/friends';
import { fetchWrapper } from 'services/login';
import { isNil } from 'ramda';
import moment from 'moment';
import { compareValues } from 'helpers/compareValue';
// Get BFF Details
export const getBFFDetails = createAsyncThunk(
  'friends/getBFFDetails',
  async () => {
    try {
      const res = await fetchWrapper(fetchBFFDetails);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get BFF details failed');
    }
  }
);
// Get Sent BFF Requests
export const getSentBFFRequests = createAsyncThunk(
  'friends/getSentBFFRequests',
  async () => {
    try {
      const res = await fetchWrapper(fetchSentBFFRequests);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get sent BFF requests failed');
    }
  }
);
// Get Incoming BFF Requests
export const getIncomingBFFRequests = createAsyncThunk(
  'friends/getIncomingBFFRequests',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchIncomingFriendRequests, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get incoming BFF requests failed');
    }
  }
);
// Remove friend
export const removeFriend = createAsyncThunk(
  'friends/removeFriend',
  async (FriendBFFCode) => {
    try {
      await fetchWrapper(postRemoveFriend, FriendBFFCode);
      const BFFDetails = await fetchWrapper(fetchBFFDetails);
      return BFFDetails;
    } catch (error) {
      throw new Error(error?.message ?? 'Remove friend failed');
    }
  }
);

// Get Friend Info By BFF Code
export const getFriendInfo = createAsyncThunk(
  'friends/getFriendInfo',
  async (BFFCode) => {
    try {
      const res = await fetchWrapper(fetchFriendInfoByBFFCode, BFFCode);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get friend info failed');
    }
  }
);

// Invite Friend
export const inviteFriend = createAsyncThunk(
  'friends/InviteFriend',
  async (BFFCode) => {
    try {
      const res = await fetchWrapper(postInviteFriend, BFFCode);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Invite friend failed');
    }
  }
);

// Accept Friend invitation
export const acceptInvitation = createAsyncThunk(
  'friends/acceptInvitation',
  async (FriendBFFCode) => {
    try {
      await fetchWrapper(acceptFriend, FriendBFFCode);
      const BFFDetails = await fetchWrapper(fetchBFFDetails);
      return BFFDetails;
    } catch (error) {
      throw new Error(error?.message ?? 'Accept friend invitation failed');
    }
  }
);

// Reject Friend invitation
export const rejectInvitation = createAsyncThunk(
  'friends/acceptInvitation',
  async (FriendBFFCode) => {
    try {
      await fetchWrapper(rejectFriend, FriendBFFCode);
    } catch (error) {
      throw new Error(error?.message ?? 'Reject friend invitation failed');
    }
  }
);

export const initialState = {
  isLoading: false,
  error: null,
  BFFDetails: null,
  BFFDetailsError: null,
  incomingLoading: false,
  incomingErr: null,
  incomingRequests: {},
  incomingLists: [],
  sentReqErr: null,
  sentRequestLoading: false,
  sentRequests: [],
  myFriends: [],
  removeFriendError: null,
  friendInfo: null,
  friendInfoLoading: false,
  friendInfoError: null,
  inviteFriend: null,
  inviteFriendLoading: false,
  inviteFriendError: null,
  invitationLoading: false,
  invitation: false,
  invitationErr: null,
};

const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {
    resetInvitation: (state) => {
      state.invitation = false;
    },
    resetInviteFriendError: (state) => {
      state.inviteFriendError = null;
    },
    resetFriendsPageError: (state) => {
      state.BFFDetailsError = null;
      state.removeFriendError = null;
      state.inviteFriendError = null;
    },
    resetIncomingPageError: (state) => {
      state.incomingErr =
        state.incomingErr === 'No Data Found' ? state.incomingErr : null;
      state.invitationErr = null;
    },
    resetSentPageError: (state) => {
      state.sentReqErr =
        state.sentReqErr === 'No Data Found' ? state.sentReqErr : null;
    },
    resetFriendInfo: (state) => {
      state.friendInfo = null;
      state.friendInfoError = null;
    },
  },
  extraReducers: {
    [getBFFDetails.pending]: (state) => {
      state.isLoading = true;
      state.BFFDetailsError = null;
    },
    [getBFFDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.BFFDetails = action.payload;
      // Remove duplicate friends with same BFF Code
      if (!isNil(action.payload.friends)) {
        const newFriendList = [...action.payload.friends]
          .filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t.BFFCode === thing.BFFCode)
          )
          .map((friend) => {
            return {
              ...friend,
              unixTimestamp: moment(
                friend.requestinfo.ActionTakenDateTime
              ).valueOf(),
            };
          })
          .sort(compareValues('unixTimestamp', 'asc'));
        state.myFriends = newFriendList;
      }
    },
    [getBFFDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.BFFDetailsError = action.error.message;
    },
    [getSentBFFRequests.pending]: (state) => {
      state.sentRequestLoading = true;
      state.sentReqErr = null;
    },
    [getSentBFFRequests.fulfilled]: (state, action) => {
      state.sentRequestLoading = false;
      if (!isNil(action.payload.friends)) {
        const newFriendList = [...action.payload.friends]
          .filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t.BFFCode === thing.BFFCode)
          )
          .filter(
            // filter accepted and pending status
            (item) =>
              item.requestinfo.RequestStatus === 2 ||
              item.requestinfo.RequestStatus === 1
          )
          .map((friend) => {
            return {
              ...friend,
              unixTimestamp: moment(
                friend.requestinfo.ActionTakenDateTime
              ).valueOf(),
            };
          })
          .sort(compareValues('unixTimestamp', 'desc'));
        state.sentRequests = newFriendList;
      }
    },
    [getSentBFFRequests.rejected]: (state, action) => {
      state.sentRequestLoading = false;
      state.sentReqErr = action.error.message;
    },
    [getIncomingBFFRequests.pending]: (state) => {
      state.incomingLoading = true;
      state.incomingErr = null;
    },
    [getIncomingBFFRequests.fulfilled]: (state, action) => {
      state.incomingLoading = false;
      state.incomingRequests = action.payload;
      if (!isNil(action.payload.List)) {
        const newFriendList = [...action.payload.List]
          .filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t.BFFCode === thing.BFFCode)
          )
          .map((friend) => {
            return {
              ...friend,
              unixTimestamp: moment(
                friend.requestinfo.ActionTakenDateTime
              ).valueOf(),
            };
          });
        state.incomingLists = newFriendList;
      }
    },
    [getIncomingBFFRequests.rejected]: (state, action) => {
      state.incomingLoading = false;
      state.incomingRequests = {};
      state.incomingLists = [];
      state.incomingErr = action.error.message;
    },
    [removeFriend.pending]: (state) => {
      state.removeFriendError = null;
    },
    [removeFriend.fulfilled]: (state, action) => {
      state.BFFDetails = action.payload;
      // Remove duplicate friends with same BFF Code
      if (!isNil(action.payload.friends)) {
        const newFriendList = [...action.payload.friends]
          .filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t.BFFCode === thing.BFFCode)
          )
          .map((friend) => {
            return {
              ...friend,
              unixTimestamp: moment(
                friend.requestinfo.ActionTakenDateTime
              ).valueOf(),
            };
          })
          .sort(compareValues('unixTimestamp', 'asc'));
        state.myFriends = newFriendList;
      }
    },
    [removeFriend.rejected]: (state, action) => {
      state.removeFriendError = action.error.message;
    },
    [getFriendInfo.pending]: (state) => {
      state.friendInfoLoading = true;
      state.friendInfo = null;
      state.friendInfoError = null;
    },
    [getFriendInfo.fulfilled]: (state, action) => {
      state.friendInfoLoading = false;
      state.friendInfo = action.payload;
      state.friendInfoError = null;
    },
    [getFriendInfo.rejected]: (state, action) => {
      state.friendInfoLoading = false;
      state.friendInfo = null;
      state.friendInfoError = action.error.message;
    },
    [inviteFriend.pending]: (state) => {
      state.inviteFriendLoading = true;
      state.inviteFriend = null;
      state.inviteFriendError = null;
    },
    [inviteFriend.fulfilled]: (state, action) => {
      state.inviteFriendLoading = false;
      state.inviteFriend = action.payload;
      state.inviteFriendError = null;
    },
    [inviteFriend.rejected]: (state, action) => {
      state.inviteFriendLoading = false;
      state.inviteFriend = null;
      state.inviteFriendError = action.error.message;
    },
    [acceptInvitation.pending]: (state) => {
      state.invitationLoading = true;
      state.invitations = false;
      state.invitationErr = null;
    },
    [acceptInvitation.fulfilled]: (state, action) => {
      state.invitationLoading = false;
      state.invitations = true;
      if (!isNil(action.payload)) {
        state.BFFDetails = action.payload;
        // Remove duplicate friends with same BFF Code
        if (!isNil(action.payload.friends)) {
          const newFriendList = [...action.payload.friends]
            .filter(
              (thing, index, self) =>
                index === self.findIndex((t) => t.BFFCode === thing.BFFCode)
            )
            .map((friend) => {
              return {
                ...friend,
                unixTimestamp: moment(
                  friend.requestinfo.ActionTakenDateTime
                ).valueOf(),
              };
            })
            .sort(compareValues('unixTimestamp', 'asc'));
          state.myFriends = newFriendList;
        }
      }
    },
    [acceptInvitation.rejected]: (state, action) => {
      state.invitationLoading = false;
      state.invitations = false;
      state.invitationErr = action.error.message;
    },
  },
});
const { actions, reducer } = friendsSlice;
export const {
  resetInvitation,
  resetInviteFriendError,
  resetFriendsPageError,
  resetIncomingPageError,
  resetSentPageError,
  resetFriendInfo,
} = actions;
export default reducer;
