import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_LEADERBOARD_V2}Leaderboard/`;

export const fetchTopBrain = ({
  leaderboardId,
  category,
  rankingFilter,
  level,
  periodType,
  periodId,
}) => {
  return axios
    .get(
      `${BASE_URL}TopBrain/${leaderboardId}/Category/${category}/RankingFilter/${rankingFilter}/Level/${level}/PeriodType/${periodType}/Period/${periodId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err?.response?.data || 'Fetch top brain failed');
    });
};
