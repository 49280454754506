import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';

/**
 * get value depending on responsive breakpoint for mobile, tablet, and desktop
 * @param {Object} value
 * @param {*} value.mobile - for mobile
 * @param {*} value.tablet - for tablet
 * @param {*} value.desktop - for desktop
 */
export default function useResponsiveValue({
  desktop,
  mobile,
  tablet,
  mobileM,
}) {
  const isMobileM = useMediaQuery({ query: `(max-width: ${rwd.mobileM}px)` });
  const isMobile = useMediaQuery({ query: `(max-width: ${rwd.mobile}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${rwd.tablet}px)` });
  const isDesktop = useMediaQuery({ query: `(max-width: ${rwd.desktop}px)` });
  if (isMobileM && mobileM) {
    return mobileM;
  }
  if (isMobile && mobile) {
    return mobile;
  }
  if (isTablet && tablet) {
    return tablet;
  }
  if (isDesktop && desktop) {
    return desktop;
  }
  return desktop;
}
