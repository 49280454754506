import styled from 'styled-components';
const RWD_SMALL = '1300px';
export const Page = styled.div`
  overflow: hidden;
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

export const Container = styled.div`
  margin-top: 59px;
  display: flex;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 4px;
  flex-direction: column;
  background: rgb(122, 255, 181);
  background: -webkit-linear-gradient(
    rgba(122, 255, 181, 1) 0%,
    rgba(5, 223, 255, 1) 100%
  );
  background: -o-linear-gradient(
    rgba(122, 255, 181, 1) 0%,
    rgba(5, 223, 255, 1) 100%
  );
  background: linear-gradient(
    rgba(122, 255, 181, 1) 0%,
    rgba(5, 223, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7affb5', endColorstr='#05dfff',GradientType=0 );
`;

export const AvatarCreatorMonsterContainer = styled.div`
  height: ${({ theme }) => `${theme.avatarCreatorHeight}px`};
  min-width: ${({ theme }) => `${theme.avatarCreatorHeight * 1.25}px`};
  max-width: ${({ theme }) => `${theme.avatarCreatorHeight * 1.5}px`};
  flex-grow: 1.25;
  flex-shrink: 0;
  position: relative;
  @media (max-width: ${RWD_SMALL}) {
    flex-grow: 1.5;
    margin-right: 60px;
    height: ${({ theme }) => `${theme.avatarCreatorHeight * 0.8}px`};
    min-width: ${({ theme }) => `${theme.avatarCreatorHeight * 1.05}px`};
    max-width: ${({ theme }) => `${theme.avatarCreatorHeight * 1.3}px`};
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

export const AvatarCreatorButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  left: 0px;
`;

export const AvatarCreatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  position: relative;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const AvatarCreatorPanel = styled.div`
  flex-grow: 1;
  width: 100px;
  display: flex;
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
  align-items: flex-start;
  & > img {
    margin-left: 100px;
    margin-top: 24px;
    @media (max-width: ${RWD_SMALL}) {
      margin-left: 0px;
    }
  }
  @media (max-width: 576px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: ${RWD_SMALL}) {
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    position: ${({ start }) => (start ? 'absolute' : 'initial')};
    bottom: 0px;
    right: 8px;
  }
`;

export const ImageTitle = styled.img`
  width: 260px;
  height: 18px;
  margin-top: -32px;
  margin-bottom: 12px;
`;

export const ShowcaseImage = styled.img`
  cursor: pointer;
`;
