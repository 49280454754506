import styled from 'styled-components';

export const CategoryStyle = styled.div`
  background: #2fc7ff;
  width: ${(props) => (props.page === 'HOF' ? '100%' : '70%')};
  display: flex;
  align-self: center;
  justify-content: space-between;
  height: 60px;
  padding: 10px 20px;
  .categories-container {
    width: 30%;
    display: flex;
  }
  .right-container {
    padding-top: 10px;
    display: flex;
    position: relative;
    .select-level {
      visibility: ${(props) => props.classCategory === '1' && 'hidden'};
    }
    .country-name {
      padding-left: 10px;
    }

    .competition-closed {
      padding-right: 10px;
    }
  }
`;

export const CategoryType = styled.div`
  border-radius: 30px;
  padding: 0px 10px;
  margin-right: 15px;
  display: flex;
  font-size: 17px;
  align-items: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.active ? 'none' : 'auto')};
  background: ${(props) => (props.active ? '#FECE18' : '#2fc7ff')};
  color: ${(props) => (props.active ? '#261E00' : '#ffffff')};
  border: 1px solid #009dd9;
  box-shadow: ${(props) =>
    props.active ? 'inset 1px 3px 0 1px #D9B328' : 'none'};
  &:hover {
    background: #46d4ff;
  }
`;
