import React from 'react';
import showPosition from 'components/Leaderboard/showPosition';
import {
  NameSchoolContainer,
  Ranking,
  StyledRow,
} from './GlobalTop100Row.styles';

const GlobalTop100Row = ({
  rank,
  username,
  schoolName,
  schoolLogo,
  cp,
  // submissionDate,
}) => {
  return (
    <StyledRow>
      <td className="text-center ranking">
        <Ranking currPos={showPosition(rank)}>{rank}</Ranking>
      </td>
      <td className={`schoolname schoolname-${rank}`}>
        <NameSchoolContainer>
          <div>
            <h6 className="student-name">{username}</h6>
            <p>{schoolName}</p>
          </div>
          <div>
            <div className="school-img-container">
              <img src={schoolLogo} alt="school-logo" />
            </div>
          </div>
        </NameSchoolContainer>
      </td>
      <td className="cppoint text-center">{cp}</td>
    </StyledRow>
  );
};

export default GlobalTop100Row;
