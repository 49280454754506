import styled from 'styled-components';

export const Container = styled.div`
  padding: 0rem 1rem;
  display: flex;
  align-items: ${(props) => (props.scroll ? 'flex-end' : 'center')};
  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .act-item {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    width: -webkit-fill-available;
    margin-left: 0.5rem;
    min-height: 50px;
    margin-top: ${(props) => props.scroll && '1.5rem'};
  }
  .dot {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: ${(props) => (props.active ? '#ffffff' : '#9b9bff')};
    display: flex;
    justify-content: center;
    align-items: center;
    .inner-dot {
      height: 50%;
      width: 50%;
      background: #9b9bff;
      border-radius: 50%;
    }
  }
  .arrow {
    top: -17px;
    left: 75%;
    width: 0;
    height: 0;
    position: relative;
    :before,
    :after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 8px 5.5px;
      bottom: -15.5px;
      right: -7.1px;
      border-bottom-color: #ffffff;
      transform: rotate(90deg);
    }
  }

  .name {
    display: flex;
    align-items: center;
    margin-left: 0.3rem;
    color: #111150;
    background: ${(props) => (props.active ? '#FFE48E' : '#ffffff')};
    border-radius: 30px;
    padding: 0.5rem;
    width: -webkit-fill-available;
    font-size: 16px;
    font-weight: 600;
    span {
      margin-left: 0.4rem;
      min-width: 3rem;
      @media (max-width: 1440px) {
        min-width: 2.5rem;
      }
      img {
        width: 30px;
      }
    }
    :hover {
      cursor: pointer;
    }
  }

  .scroll {
    font-size: 11px;
    margin-top: 3.2%;
    align-self: flex-end;

    img {
      width: 35px;
    }
  }
`;

export const VerticalLine = styled.div`
  border-right: 6px solid #9b9bff;
  min-height: ${(props) => (props.scroll ? '41px' : '34px')};
`;
