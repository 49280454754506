import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/hod/hod-header.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Topbar = styled.div`
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 315px;
  .btn {
    position: absolute;
    left: 4%;
  }
  .middle-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 6rem;
    .title {
      font-family: 'Grilled Cheese';
      font-size: 48px;
      color: white;
      -webkit-text-stroke-width: 2.5px;
      -webkit-text-stroke-color: #1563aa;
      width: 35%;
      text-align: center;
    }
    p {
      margin-bottom: 0rem;
      color: #ffffff;
      font-weight: bold;
    }
    .desc {
      margin-left: 3rem;
      margin-top: 0.5rem;
      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }
  }
`;

export const NoParticipant = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #ffffff;
  width: 70%;
  height: 100%;
  padding: 20px 30px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0 20px;
  width: 70%;
  align-self: center;
  background: #ffffff;
  .button-center {
    display: flex;
    justify-content: center;
  }
`;
