import styled from 'styled-components';
import {
  assignmentBackButton,
  assignmentImages,
  assignmentTextColor,
} from 'constants/index';

export const Container = styled.div`
  height: 270px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${(props) => assignmentImages[props.imgType]}');
  background-position: center;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  .title {
    color: #db2d20;
    font-size: 38px;
    font-weight: bold;
    margin-right: 2rem;
    margin-top: -3rem;
  }
  .back-btn {
    padding-top: 0.5rem;
  }
  .text {
    color: ${(props) => assignmentTextColor[props.imgType]};
    font-weight: 600;
    font-size: ${(props) => (props.imgType === 'sundayMC' ? '35px' : '62px')};
    margin-left: ${(props) =>
      props.imgType === 'test'
        ? '-25%'
        : props.imgType === 'sundayMC' && '-40%'};
    margin-right: ${(props) => props.imgType === 'test' && '10%'};
    span {
      color: #4b0000;
    }
  }
  &.sundayMC,
  &.sundayMC2 {
    position: relative;
    .text,
    .smc-scoreboard {
      margin: 0px;
      position: absolute;
      left: 57%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media (max-width: 1024px) {
    .text {
      font-size: ${(props) => (props.imgType === 'sundayMC' ? '35px' : '52px')};
    }
  }
  @media (max-width: 768px) {
    padding-top: 1rem;

    .text {
      margin-right: ${(props) => props.imgType === 'test' && '0%'};
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    &.sundayMC,
    &.sundayMC2 {
      position: relative;
      .text {
        left: 95%;
        width: 100%;
      }
    }
    &.sundayMC {
      background-color: #fffefe;
      background-size: 150%;
      background-position: bottom;
    }
  }

  @media (max-width: 750px) {
    &.sundayMC,
    &.sundayMC2 {
      height: 200px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileL}px`}) {
    height: 200px;
    padding-top: 0.5rem;

    .text {
      font-size: 42px;
    }

    &.sundayMC,
    &.sundayMC2 {
      height: 150px;

      .text {
        font-size: 20px;
      }
    }
  }
`;

export const BackButton = styled.button`
  background: ${(props) => assignmentBackButton[props.type]};
  border: transparent;
  border-radius: 21px;
  color: white;
  text-align: center;
  padding: 6px 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .back-icon {
    margin-right: 0.5rem;
  }
  @media (max-width: 610px) {
    padding: 10px;
    width: 40px;
    border-radius: 50%;

    .back-icon {
      margin-left: 0.3rem;
    }

    .button-text {
      display: none;
    }
  }
`;
