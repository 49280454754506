import styled from 'styled-components';

export const ButtonPanelContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 50px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 100px;
  line-height: 24px;
  border: 0;
  box-sizing: content-box;
  & > img {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  flex-direction: row;
`;
