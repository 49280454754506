import React, { useRef } from 'react';
import {
  ActionButton,
  PrintButton,
} from 'components/Assignment/AssignmentView/Header.styles';
import PrintIcon from 'assets/img/assignment/print-icon.svg';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from 'assets/img/assignment/assignment-view/icon-close.png';
import {
  AddStepsIcon,
  BarModelIcon,
  FourOpsIcon,
} from 'assets/img/assist-tools';
import {
  showSelectedTool,
  setToolPosition,
  hideAllTools,
} from 'store/assignment/assistToolsSlice';
import { ProgressBar } from 'react-bootstrap';
import { countCorrectWrongSubmissions } from 'helpers/countCorrectWrongSubmissions';
import QnNav from 'components/Assignment/AssignmentView/QnSideBar/QnNav';
import { clearAll } from 'store/assignment/barModelSlice';
import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';
import { saveAnswer, checkAnswer } from 'store/assignment/assignmentSlice';
import { saveEventAns } from 'store/event/eventSlice';
import {
  checkIfSubmissionIsCorrect,
  checkIsQuestionAnswered,
} from 'components/Assignment/AssignmentView/QnSideBar';
import { questionTypeIDs } from 'constants/index';
import useClickOutside from 'hooks/useClickOutside';
import {
  StyledNavBar,
  ActionsContainer,
  CloseButton,
  Spacer,
  HelpersContainer,
  ProficiencyContainer,
  QuestionsContainer,
} from './AssignmentSideNav.styles';
import QnEnd from './QnSideBar/QnEnd';

const AssignmentProficiency = ({ questions = [], submissions }) => {
  const { t } = useTranslation(['assignmentQnView']);
  const totalQns = questions.length;
  const page = '';
  const assignmentType = 'homework';
  const proficiency = countCorrectWrongSubmissions(
    submissions,
    page,
    assignmentType
  );
  return (
    <ProficiencyContainer>
      <p style={{ margin: '0', fontSize: '11px', fontWeight: '300' }}>
        {t('assignmentQnView:sidebar.proficiency', 'Proficiency')}
      </p>
      <div className="section">
        <div className="progressbar">
          <ProgressBar>
            <ProgressBar
              variant="correct"
              now={(proficiency.correct / totalQns) * 100}
              key={1}
            />
            <ProgressBar
              variant="wrong"
              now={(proficiency.wrong / totalQns) * 100}
              key={2}
            />
            <ProgressBar
              variant="skipped"
              now={(proficiency.skipped / totalQns) * 100}
              key={3}
            />
          </ProgressBar>
        </div>
        <div className="progress-number">
          <span
            className={proficiency.correct > 0 ? 'num-correct' : ''}
          >{`${proficiency.correct}`}</span>
          <span>{` / ${totalQns}`}</span>
        </div>
      </div>
    </ProficiencyContainer>
  );
};

const isQnCorrect = (currentQnSubmissionModel, assignmentType) => {
  if (assignmentType !== 'homework') return null;
  return checkIfSubmissionIsCorrect(currentQnSubmissionModel);
};
const AssignmentQuestions = ({
  questions = [],
  submissions = [],
  localSavedAnswers = [],
  activeQn,
  setActiveQn,
  setShow,
  onSave,
  assignmentType,
}) => {
  const dispatch = useDispatch();

  const dispatchSaveAction = (params) => {
    let saveAns = null;
    const saveParams = { ...params };
    if (assignmentType === 'sundayMC') saveAns = saveEventAns;
    else {
      saveAns = saveAnswer;
      saveParams.fetchNextQn = true;
    }
    dispatch(saveAns(saveParams));
  };
  const dispatchCheckAnswerAction = (params) => {
    const checkAnswerParams = {
      ...params,
      fetchNextQn: true,
    };
    const isAssignment = ['homework', 'mocktest', 'test'].includes(
      assignmentType
    );
    if (!isAssignment) return;
    dispatch(checkAnswer(checkAnswerParams));
  };
  const changeQnSaveAnswerHandler = (index, isActive) => {
    return () => {
      if (isActive) return;
      dispatch(setActiveQn(index + 1));
      // Save Answer
      onSave(dispatchSaveAction, dispatchCheckAnswerAction);
      // Reset assist tools
      dispatch(hideAllTools());
      clearValues();
      dispatch(clearAll());
      // Hide Side Nav
      setShow(false);
    };
  };
  return (
    <QuestionsContainer>
      {questions.map((qn, index) => (
        <QnNav
          key={qn.Id}
          collapse={false}
          number={index + 1}
          correct={isQnCorrect(
            submissions[index]?.SubmissionModel,
            assignmentType
          )}
          qnAnswered={checkIsQuestionAnswered(
            localSavedAnswers[index],
            submissions[index]?.SubmissionModel,
            questionTypeIDs[qn.QuestionType],
            assignmentType
          )}
          active={index + 1 === activeQn}
          clickHandler={changeQnSaveAnswerHandler(
            index,
            index + 1 === activeQn
          )}
          styles={{
            number: {
              marginRight: '25px',
            },
          }}
        />
      ))}
      {questions.length > 0 && <QnEnd />}
    </QuestionsContainer>
  );
};

const AssignmentSideNavActions = ({
  assignmentType,
  setShow,
  printUrl,
  isLoading,
  isError,
  currentSavedAnswer,
  onSubmit,
  onSave,
  showButtons,
}) => {
  const { t } = useTranslation(['assignmentQnView']);
  const { isSaving, isChecking, saveError, activeQuestion } = useSelector(
    (state) => state.assignment
  );
  const closeSideNavHandler = () => {
    setShow(false);
  };
  const saveHandler = () => {
    onSave();
  };
  return (
    <ActionsContainer assignmentType={assignmentType}>
      <CloseButton onClick={closeSideNavHandler}>
        <img src={CloseIcon} alt="Close" />
      </CloseButton>
      {printUrl.length > 0 && (
        <PrintButton
          assignmentType={assignmentType}
          data-cy="print-icon"
          rel="noopener noreferrer"
          target="_blank"
          href={printUrl}
        >
          <img src={PrintIcon} alt="Print" />
        </PrintButton>
      )}
      {showButtons && <Spacer width="16px" />}
      {showButtons && (
        <ActionButton
          data-cy="submit-btn"
          assignmentType={assignmentType}
          disabled={isLoading && isError !== null}
          onClick={onSubmit}
        >
          {t('assignmentQnView:header.submit', 'Submit')}
        </ActionButton>
      )}
      {showButtons && <Spacer width="16px" />}
      {showButtons && (
        <ActionButton
          data-cy="saving-btn"
          assignmentType={assignmentType}
          onClick={saveHandler}
          isSaved={
            !isSaving && saveError === null && currentSavedAnswer !== null
          }
          disabled={
            isSaving ||
            isChecking[activeQuestion - 1] ||
            isLoading ||
            isError !== null ||
            currentSavedAnswer !== null
          }
        >
          {(isSaving || isChecking[activeQuestion - 1]) &&
            t('assignmentQnView:header.saving', 'Saving')}
          {!isSaving &&
            saveError === null &&
            currentSavedAnswer !== null &&
            t('assignmentQnView:header.saved', 'Saved')}
          {!isSaving &&
            !isChecking[activeQuestion - 1] &&
            currentSavedAnswer === null &&
            t('assignmentQnView:header.save', 'Save')}
        </ActionButton>
      )}
    </ActionsContainer>
  );
};

const HELPER_ACTIONS = [
  {
    id: 1,
    name: 'add-working',
    img: AddStepsIcon,
  },
  {
    id: 2,
    name: 'bar-model',
    img: BarModelIcon,
  },
  {
    id: 3,
    name: 'four-ops',
    img: FourOpsIcon,
  },
];
export const AssignmentSideNavHelpers = ({ setShow }) => {
  const dispatch = useDispatch();
  const onClickHandler = (toolName) => {
    return () => {
      dispatch(
        setToolPosition({
          tool: toolName,
          position: {
            x: 0,
            y: 0,
          },
        })
      );
      dispatch(showSelectedTool(toolName));
      setShow(false);
    };
  };
  return (
    <HelpersContainer>
      {HELPER_ACTIONS.map((helper) => (
        <img
          key={helper.id}
          src={helper.img}
          alt=""
          onClick={onClickHandler(helper.name)}
          style={{ cursor: 'pointer' }}
        />
      ))}
    </HelpersContainer>
  );
};

const AssignmentSideNav = ({
  setShow,
  activeQuestion,
  setActiveQn,
  onSubmit,
  onSave,
  printUrl,
  isLoading,
  isError,
  currentSavedAnswer,
  localSavedAnswers,
  questions,
  submissions,
  assignmentType,
  showAssistTools = true,
  showProficiency = true,
  showButtons,
}) => {
  const navBarRef = useRef();
  useClickOutside(navBarRef, () => {
    setShow(false);
  });
  return (
    <StyledNavBar ref={navBarRef}>
      <AssignmentSideNavActions
        assignmentType={assignmentType}
        setShow={setShow}
        printUrl={printUrl}
        isLoading={isLoading}
        isError={isError}
        currentSavedAnswer={currentSavedAnswer}
        onSubmit={onSubmit}
        onSave={onSave}
        showButtons={showButtons}
      />
      {showAssistTools && <AssignmentSideNavHelpers setShow={setShow} />}
      {showProficiency && (
        <AssignmentProficiency
          questions={questions}
          submissions={submissions}
        />
      )}
      <AssignmentQuestions
        setShow={setShow}
        activeQn={activeQuestion}
        setActiveQn={setActiveQn}
        questions={questions}
        submissions={submissions}
        localSavedAnswers={localSavedAnswers}
        onSave={onSave}
        assignmentType={assignmentType}
      />
    </StyledNavBar>
  );
};

export default AssignmentSideNav;
