import React from 'react';
import { MobileOnlyView } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import ToggleMobile from 'assets/img/mobile-toggle/toggle-mobile.svg';
import ToggleDesktop from 'assets/img/mobile-toggle/toggle-desktop.svg';
import { StyledContainer, StyledButton } from './ChangeViewWidthButton.styles';

const ChangeViewWidthButton = ({ plan, seenMobilePopup, handleClick }) => {
  return (
    <StyledContainer plan={plan}>
      <MobileOnlyView>
        <StyledButton
          className="toggle-view-button"
          type="button"
          onClick={handleClick}
          seenMobilePopup={seenMobilePopup}
        >
          {seenMobilePopup === 2 ? (
            <img alt="desktop" src={ToggleDesktop} />
          ) : (
            <img alt="mobile" src={ToggleMobile} />
          )}
        </StyledButton>
      </MobileOnlyView>
      {seenMobilePopup === 2 ? (
        <Helmet>
          <meta name="viewport" content="width=1000" />
        </Helmet>
      ) : (
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
      )}
    </StyledContainer>
  );
};

export default ChangeViewWidthButton;
