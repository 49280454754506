import styled from 'styled-components';

export const Container = styled.div``;

export const Main = styled.div`
  background: #cbf2a0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;

  @media (max-width: 350px) {
    min-height: 130vh;
  }
  .mascot {
    position: absolute;
    left: 0;
    bottom: 2rem;
    pointer-events: none;
    .bg {
      width: 145%;
      position: relative;
    }
    .front {
      width: 160%;
      z-index: 2;
      position: absolute;
      left: -14%;
      bottom: -2rem;
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.laptop}px`}) {
      .bg {
        width: 130%;
      }
      .front {
        width: 142%;
      }
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      .bg {
        width: 98%;
        bottom: -1rem;
      }
      .front {
        width: 114%;
      }
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      .bg {
        width: 88%;
      }
      .front {
        width: 103%;
      }
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      .bg {
        width: 84%;
        bottom: -1rem;
      }
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
      .bg {
        width: 60%;
      }
      .front {
        width: 77%;
      }
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.mobileS}px`}) {
      .success-text {
        font-size: 18px !important;
      }

      .submit-button {
        font-size: 16px;
      }
    }
    @media (max-width: 350px) {
      bottom: -8rem;
    }
  }
`;

export const ErrorMsg = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;
