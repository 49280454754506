import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { getWeeks } from 'store/leaderboard/leaderboardSlice';
import { getBFFLeaderboard } from 'store/leaderboard/bffLeaderboardSlice';
import DropdownMenu from 'components/Shared/DropdownMenu';
import ErrorModal from 'components/Shared/ErrorModal';
import { NoParticipants } from 'pages/Leaderboard/v2/components';
import { LOADING } from 'pages/Leaderboard/v2/constant';
import { Header } from './components';
import BFFList from 'pages/Leaderboard/v2/components/List/BFFList';
import { StyledBffCategory, StyledContainer } from './BFF.styles';
import Spinner from 'components/Shared/Spinner';
const BFF = () => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { weeks } = useSelector((state) => state.leaderboard);
  const { isLoading, error, data } = useSelector((state) => state.bffV2);
  const [dropdownItems, setDropdownItems] = useState(
    weeks.data?.[0] || { name: LOADING }
  );
  const handleSearch = useCallback(
    (weekId) => {
      dispatch(getBFFLeaderboard({ weekId }));
    },
    [dispatch]
  );
  useEffect(() => {
    if (!weeks.data.length && !weeks.isLoading && !weeks.error) {
      dispatch(getWeeks());
    }
  }, [weeks, dispatch]);
  useEffect(() => {
    if (weeks.data.length) {
      setDropdownItems(weeks.data[0]);
      handleSearch(weeks.data[0].id);
    }
  }, [weeks, dispatch, handleSearch]);
  const changeWeek = (val) => {
    setDropdownItems(val);
    handleSearch(val.id);
  };
  return (
    <StyledContainer>
      <Header />
      <StyledBffCategory>
        <span>
          {t('leaderboard:bff.challengePoints', 'Challenge Points of The Week')}
        </span>
        <div className="curriculum-date">
          <DropdownMenu
            dataCy="dropdown-week"
            selectedValue={dropdownItems?.name}
            valueKey="name"
            values={weeks.data}
            setValue={changeWeek}
            width="auto"
            className="drop-down"
          />
        </div>
      </StyledBffCategory>
      {isLoading && <Spinner />}
      {!isLoading && !isEmpty(data) && <BFFList weekId={dropdownItems?.id} />}
      {!isLoading && isEmpty(data) && isNil(error) && <NoParticipants />}
      {!isLoading && !isNil(error) && (
        <ErrorModal
          errorMessage={error}
          reloadAction={() => handleSearch(dropdownItems)}
        />
      )}
    </StyledContainer>
  );
};

export default BFF;
