import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 4rem;
  right: 7rem;
  background-color: #404447;
  padding: 1rem 1rem;
  border-radius: 7px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const ItemMenu = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 33%;
  width: ${({ lang }) => (lang === 'ba' ? '40px' : '30px')};
  height: 97px;
  margin-bottom: 0.2rem;
  padding: 0.2rem;
  background-color: ${(props) => (props.isLoading ? '#292a2a' : 'transparent')};
  border: none;
  color: #ffffff;
  font-size: ${({ name }) => (name.length > 11 ? '12px' : '14px')};
  border-radius: 8px;
  a {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon {
    margin: 0;
    width: ${({ name }) => name === 'Daily Challenge' && '55px'};
    visibility: ${(props) => (props.isLoading ? 'hidden' : 'normal')};
  }
  .feature-name {
    margin: 0;
    margin-top: 0.5rem;
    cursor: pointer;
  }
  &:hover {
    background-color: #292a2a;
    a {
      text-decoration: none;
    }
  }
  .spinner-border {
    position: absolute;
    top: 2rem;
  }
`;
