import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const getStatusGame = async () => {
  return fetch(`${baseUrl}BrainGame/Status`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get Status Brain Game Failed'); // eslint-disable-line
    });
};

export const unlockGameById = (GameId) => {
  return fetch(`${baseUrl}BrainGame/PlayGame/${GameId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.code === 'BGM003') throw new Error('BGM003');
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Unlock Game failed'); // eslint-disable-line
    });
};

export const fetchGamesByCategory = async (categoryGameId) => {
  return fetch(`${baseUrl}BrainGame/GamesListByCategory/${categoryGameId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch Games by category failed'); // eslint-disable-line
    });
};

export const fetchGamesByID = async (gameId) => {
  return fetch(`${baseUrl}BrainGame/Games/${gameId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch Game by ID failed'); // eslint-disable-line
    });
};
