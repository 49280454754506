import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #e0e5e4;
  p {
    font-weight: 300;
    font-size: 0.6875rem;
    margin-bottom: 0;
  }
  padding: 1rem;
  border-bottom: solid 1px #c2c8b9;
  position: relative;
`;
Container.Img = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  transform: ${({ collapse }) => (collapse ? 'rotateY(180deg)' : 'rotateY(0)')};
`;
export const Progress = styled.div`
  .progressbar {
    display: ${(props) => props.collapse && 'none'};

    @media (min-width: ${({ theme }) =>
        `${theme.rwd.tablet}px`}) and (max-width: 1200px) {
      display: none;
    }
  }
  .progress {
    width: 70px;
    height: 10px;
    margin-right: 1rem;
    border-radius: 1rem;
    background: #f6fbff;
    .bg-correct {
      background: #58cf67;
    }
    .bg-wrong {
      background: #ffb8b8;
    }
    .bg-skipped {
      background: #bbbbbb;
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      width: 185px;
    }
  }
  display: flex;
  align-items: center;
  .num-correct {
    color: #36ac45;
  }
  span {
    font-size: 1.125rem;
    font-weight: 600;
    color: #989898;
  }
`;
