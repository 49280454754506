import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import {
//   // getOccupationList,
//   getPastSeasons,
//   // getStudents,
// } from 'constants/leaderboard/lionCityMockData';
import {
  fetchSeasons,
  fetchOccupations,
  fetchStudents,
  fetchCurrentUserLionCity,
} from 'services/lionCity';
import { fetchWrapper } from 'services/login';

// // get seasons
export const getSeasons = createAsyncThunk('lionCity/getSeasons', async () => {
  try {
    const res = await fetchWrapper(fetchSeasons);
    // const res = getPastSeasons;
    return res;
  } catch (error) {
    throw new Error(error?.message ?? 'Get all seasons failed');
  }
});

// get all occupations
export const getOccupations = createAsyncThunk(
  'lionCity/getOccupations',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchOccupations, params);
      // const res = getOccupationList;
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get all occupations failed');
    }
  }
);

// get students by school
export const getStudentsList = createAsyncThunk(
  'lionCity/getStudentsList',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchStudents, params);
      // const res = getStudents;
      const obj = {
        [params.OccupationId]: res,
      };
      return obj;
    } catch (error) {
      throw new Error(error?.message ?? 'Get students by group failed');
    }
  }
);

// // get current user
export const getCurrentUser = createAsyncThunk(
  'lionCity/getCurrentUserLionCity',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchCurrentUserLionCity, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get current user lion city failed');
    }
  }
);

const initialState = {
  seasons: [],
  seasonsLoading: false,
  seasonsError: null,
  groupsData: [],
  isLoading: false,
  isError: null,
  currentUserScore: {},
};

const wizardSchoolSlice = createSlice({
  name: 'wizardSchool',
  initialState,
  reducers: {},
  extraReducers: {
    [getSeasons.pending]: (state) => {
      state.seasonsLoading = true;
      state.seasons = [];
      state.seasonsError = null;
    },
    [getSeasons.fulfilled]: (state, action) => {
      state.seasonsLoading = false;
      state.seasons = action.payload;
      state.seasonsError = null;
    },
    [getSeasons.rejected]: (state, action) => {
      state.seasonsLoading = false;
      state.seasons = [];
      state.seasonsError = action.error.message;
    },
    [getOccupations.pending]: (state) => {
      state.isLoading = true;
      state.groupsData = [];
      state.isError = null;
    },
    [getOccupations.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.groupStudents = {};
      state.groupsData = action.payload;
      state.isError = null;
    },
    [getOccupations.rejected]: (state, action) => {
      state.isLoading = false;
      state.groupsData = [];
      state.isError = action.error.message;
    },
    [getStudentsList.pending]: (state) => {
      state.groupStudentsLoading = true;
      state.groupStudentsError = null;
    },
    [getStudentsList.fulfilled]: (state, action) => {
      state.groupStudentsLoading = false;
      state.groupStudents = { ...state.groupStudents, ...action.payload };
      state.groupStudentsError = null;
    },
    [getStudentsList.rejected]: (state, action) => {
      state.groupStudentsLoading = false;
      state.groupStudentsError = action.error.message;
    },
    [getCurrentUser.pending]: (state) => {
      state.currentUserScore = {};
      state.isError = null;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.currentUserScore = action.payload;
      state.isError = null;
    },
    [getCurrentUser.rejected]: (state, action) => {
      state.currentUserScore = {};
      state.isError = action.error.message;
    },
  },
});

const { reducer } = wizardSchoolSlice;
export default reducer;
