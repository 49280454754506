import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 98px;
  height: 98px;
  margin: 0.8125rem 1rem;
  .giftbox {
    width: 92px;
    height: 92px;
    cursor: pointer;
    :hover {
      width: ${(props) => (props.isDisabled ? '92px' : '98px')};
      height: ${(props) => (props.isDisabled ? '92px' : '98px')};
    }
  }
  .koko-amt {
    font-size: 1.625rem;
    color: white;
    font-family: 'Linotte-Heavy';
  }
  .koko-text {
    font-size: 0.9375rem;
    color: white;
    font-weight: 700;
  }
`;
