import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ active }) => active && '#f4fbff'};
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: flex;
    padding: 20px 10px;
    border-bottom: 2px solid #fafafa;
    cursor: pointer;

    .skill-column {
      white-space: nowrap;
      margin-right: 10px;
      font-size: 14px;
    }
  }
`;

export const StyledSkillRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 2px solid #fafafa;
  font-family: 'Nunito';

  .left-component {
    display: flex;
    flex-direction: row;
    height: 66px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }

  .left-component .active {
    background-color: #f4fbff;
  }

  .bottom-content {
    width: 30%;
    margin-left: auto;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    flex-direction: column;
    border-bottom: none;
    width: 100%;

    .bottom-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-left: 0px;
      margin-top: 20px;
    }

    .status-percent {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-right: 10px;
    }

    .left-component {
      height: auto;
      align-items: flex-start;
    }
  }
`;

export const PercentColumn = styled.div`
  width: 50px;
  font-weight: bold;
  font-size: 15px;
  color: ${(props) => props.statusColor.percentageColor};
`;

export const DescriptionColumn = styled.div`
  /* cursor: pointer; */
  width: 70%;

  .left-side {
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    width: 250px;
  }

  .skill-column {
    width: 60px;
    margin-right: 10px;
    font-size: 14px;
  }

  .status-column {
    width: 100px;
  }

  .desc-column {
    margin-right: auto;
    flex: 5;
    font-size: 13px;
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
  }
`;

export const QuestionColumn = styled.div`
  display: flex;
  flex-direction: row;

  .question {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => (props.number ? '#FF5B00' : '#BCBCBC')};
  }

  .question-text {
    width: 40px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: rgba(93, 85, 85, 0.55);
  }
`;

export const ButtonBox = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 27px;
  font-family: 'Nunito';
  border: 1px solid #d9d9d9;
  background-color: ${(props) => (props.disabled ? '#F8F8F8' : '#ffffff')};
  color: ${(props) => (props.disabled ? '#D8D8D8' : '#aaaaaa')};
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    background-color: ${(props) => (props.disabled ? '#F8F8F8' : '#ffffff')};
    color: ${(props) => (props.disabled ? '#D8D8D8' : '#83d5f4')};
    border-color: ${(props) => (props.disabled ? '#D9D9D9' : '#c8dee3')};
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0;
  }
`;

export const SkillBadge = styled.span`
  font-size: 11px;
  background: ${(props) => props.statusColor.bg};
  border-radius: 12px;
  border: ${(props) => `solid 1px ${props.statusColor.borderColor}`};
  color: ${(props) => props.statusColor.textColor};
  padding: 0.1rem 0.5rem;
`;
