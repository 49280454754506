import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isNil } from 'ramda';

import { getRewards } from 'store/dashboard/rewardsSlice';

import DropdownMenu from 'components/Shared/DropdownMenu';

import ToggleIcon from 'assets/img/icon-toggle.svg';

import { LEADERBOARD_LIST } from '../../constant';
import { checkIfChallengeOpen } from '../../helper';

import { ChallengeTypeStyle } from './ChallengeType.styles';

const ChallengeType = ({ styles, page }) => {
  const { t } = useTranslation(['leaderboardlist']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { subject } = useParams();
  const { bannerNotif } = useSelector((state) => state.notification);
  const activeLink = history.location.pathname;
  const leaderboardList =
    subject === 'science' ? LEADERBOARD_LIST.science : LEADERBOARD_LIST.math;

  const filterChallenge = leaderboardList.filter((leaderboard) => {
    const open = leaderboard.status === 'open';
    const hasStartEndTime =
      leaderboard.startDateTime && leaderboard.endDateTime;
    if (hasStartEndTime) {
      return (
        checkIfChallengeOpen(
          leaderboard.startDateTime,
          leaderboard.endDateTime
        ) && open
      );
    }
    return open;
  });

  const activeChallenge = filterChallenge.find((e) =>
    activeLink.includes(e.link)
  );
  const currentChallengeName =
    activeChallenge &&
    activeChallenge.hasOwnProperty('name') &&
    activeChallenge.name;
  const [selectedChallenge, setSelectedChallenge] = useState('');

  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch]);

  const changeChallenge = (val) => {
    setSelectedChallenge(val);
    if (val.hasSubject) {
      history.push(`${val.link}/${subject ?? 'math'}`);
    } else {
      history.push(`${val.link}`);
    }
  };

  return (
    <ChallengeTypeStyle
      style={{ ...styles }}
      banner={!isNil(bannerNotif)}
      page={page}
    >
      <div className="border-dropdown">
        <DropdownMenu
          selectedValue={
            selectedChallenge === ''
              ? t(
                  `leaderboardlist:challengeList.${currentChallengeName}`,
                  `${currentChallengeName}`
                )
              : t(
                  `leaderboardlist:challengeList.${selectedChallenge.name}`,
                  `${selectedChallenge.name}`
                )
          }
          valueKey="name"
          values={filterChallenge}
          setValue={changeChallenge}
          width="160px"
          className="challenge-drop-down"
          feature="leaderboard"
          borderRadius="5px"
          borderColor="#05519C"
          valueTemplate={(value) =>
            t(`leaderboardlist:challengeList.${value}`, `${value}`)
          }
          toggleIcon={
            <div className="toggle-icon">
              <img src={ToggleIcon} alt="" />
            </div>
          }
        />
      </div>
    </ChallengeTypeStyle>
  );
};

export default ChallengeType;
