import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: fixed;
  top: 4rem;
  right: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem;
  z-index: 9999;
  cursor: grab;
  :active {
    cursor: grabbing;
  }
  .close-btn {
    cursor: pointer;
    display: inline-block;
    margin-left: 1rem;
    font-size: 18px;
    font-weight: bold;
  }
`;
