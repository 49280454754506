import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  p {
    font-size: 1.375rem;
    color: white;
    margin: 0;
    .highlighted {
      color: #ffde00;
    }
    .num-of-stars {
      font-size: 1.875rem;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    p {
      font-size: 16px;
    }
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: white;
  background: ${(props) => props.bgColour || '#6585e2'};
  padding: 0.5rem 1rem;
  border-radius: 2rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 2px 10px;
    .totalSkill,
    .skills-no {
      display: none;
    }
  }
`;
