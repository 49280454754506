import React from 'react';
import { useSelector } from 'react-redux';
import Title from 'components/Expired/Title';
import Description from 'components/Expired/Description';
import { Cards } from './index.styles';

const Premium = () => {
  const { studentDetails, defaultLevel } = useSelector(
    (state) => state.studentDetails
  );

  const redirectUser = () => {
    const url = `${
      process.env.REACT_APP_OFFICIAL_WEB_URL
    }plans/up?k21up=expired@k21P.koobits&k21up-qty=16.5&k21id=${
      studentDetails?.UserName ?? ''
    }&k21em=${encodeURIComponent(
      studentDetails?.ParentsDetails?.Email ?? ''
    )}&k21cn=${studentDetails?.FullName ?? ''}&k21lv=${
      defaultLevel ?? ''
    }&k21ph=${encodeURIComponent(
      studentDetails?.ParentsDetails?.PhoneNumber?.PhoneNumberRaw ?? ''
    )}&k22uid=${
      studentDetails?.UserId ?? ''
    }&utm_source=expiry&utm_medium=btn&utm_campaign=k21ex&utm_content=resub_prem`;
    return window.location.replace(url);
  };

  return (
    <>
      <Title type="premium" />
      <Cards>
        <Description
          type="premium"
          name={studentDetails?.FullName || ''}
          renew={redirectUser}
        />
      </Cards>
    </>
  );
};

export default Premium;
