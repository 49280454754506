/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { isNil, isEmpty } from 'ramda';
import { findCommonElement } from './findCommonElement';
import { checkDateTimeInBetween } from './checkDateTimeInBetween';

// eslint-disable-next-line import/prefer-default-export
export const filteredNotification = ({
  activeData,
  countryData,
  levelData,
  allPlans,
}) => {
  if (isEmpty(activeData) || isNil(activeData)) {
    return [];
  }
  return activeData.filter((item) => {
    if (isNil(item.filter)) {
      return true;
    }
    const checkCountry =
      isEmpty(item.filter.countries) || isNil(item.filter.plans)
        ? true
        : item.filter.countries.includes(countryData);
    const checkLevel =
      isEmpty(item.filter.levels) || isNil(item.filter.plans)
        ? true
        : item.filter.levels.includes(levelData);
    const activePlanId = allPlans ? allPlans.map((item) => item.PlanId) : [];
    const checkPlans =
      isEmpty(item.filter.plans) || isNil(item.filter.plans)
        ? true
        : findCommonElement(item.filter.plans, activePlanId);
    const matchingPlans =
      !isNil(item.filter.plans) &&
      allPlans.filter((subscription) =>
        item.filter.plans.includes(subscription.PlanId)
      );
    const checkPurchaseDate = isNil(item.filter.purchaseDate)
      ? true
      : isEmpty(item.filter.plans) || isNil(item.filter.plans)
      ? allPlans.filter((subscription) =>
          checkDateTimeInBetween(
            subscription.StartDate,
            item.filter.purchaseDate.minDate,
            item.filter.purchaseDate.maxDate
          )
        ).length > 0
      : matchingPlans.filter((subscription) =>
          checkDateTimeInBetween(
            subscription.StartDate,
            item.filter.purchaseDate.minDate,
            item.filter.purchaseDate.maxDate
          )
        ).length > 0;
    const checkExpiryDate = isNil(item.filter.expiryDate)
      ? true
      : isEmpty(item.filter.plans)
      ? allPlans.filter((subscription) =>
          checkDateTimeInBetween(
            subscription.EndDate,
            item.filter.expiryDate.minDate,
            item.filter.expiryDate.maxDate
          )
        ).length > 0
      : matchingPlans.filter((subscription) =>
          checkDateTimeInBetween(
            subscription.EndDate,
            item.filter.expiryDate.minDate,
            item.filter.expiryDate.maxDate
          )
        ).length > 0;
    const customerType = allPlans
      ? allPlans.map((data) => data.CustomerType)
      : [];
    const checkUpgraded =
      item.filter.isUpgraded ===
      (customerType.includes('B2B') && customerType.includes('B2C'));

    const allDataMatch =
      checkCountry &&
      checkLevel &&
      checkPlans &&
      checkPurchaseDate &&
      checkExpiryDate &&
      checkUpgraded;

    return allDataMatch;
  });
};
