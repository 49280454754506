import React from 'react';
import { Trans } from 'react-i18next';
import Button from 'components/Shared/Button';
import { LockCard, MoreKoko } from './Locked.styles';

const Locked = ({ openModal, isActive }) => {
  return (
    <LockCard data-cy="lock-card" isActive={isActive}>
      <Button
        variant="orange2"
        padding={
          isActive
            ? '0.1rem 0.8rem 0.1rem 0.4rem'
            : '0.1rem 0.5rem 0.1rem 0.1rem'
        }
        onClick={openModal}
      >
        <p className="btn-text">
          <span>
            <Trans i18nKey="common:start">Start</Trans>
          </span>
        </p>
      </Button>
      <MoreKoko isActive={isActive} />
    </LockCard>
  );
};

export default Locked;
