import React, { useCallback, useContext } from 'react';
import arrow from 'assets/img/assignment/bar-model/arrow.png';
import bar from 'assets/img/assignment/bar-model/bar.png';
import brace from 'assets/img/assignment/bar-model/brace.png';
import erase from 'assets/img/assignment/bar-model/erase.png';
import iBrace from 'assets/img/assignment/bar-model/i-brace.png';
import text from 'assets/img/assignment/bar-model/text.png';
import vBrace from 'assets/img/assignment/bar-model/v-brace.png';
import trash from 'assets/img/assignment/bar-model/trash.png';
import { v4 } from 'uuid';
import { TwitterPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import {
  addObject,
  setColorPickerActive,
  setEditing,
  changeObjectExtra,
  removeSelected,
  clearAll,
} from 'store/assignment/barModelSlice';
import { barModelOptions } from 'constants/index';
import useOnClickOutside from 'helpers/useOnClickOutside';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import {
  Container,
  ToolbarRow,
  ToolbarIcon,
  ToolbarDelete,
  ColorPickerIcon,
  BreakIntoPart,
  ClearAllButton,
  ColorPicker,
  ColorPickerContainer,
} from './Toolbar.style';
import ColorPickerContext from './ColorPickerContext';

const { barModelColorOptions, components } = barModelOptions;
const { defaultSize, breakIntoMax } = components;
const breakIntoValues = range(breakIntoMax);

function Toolbar() {
  const { t } = useTranslation(['assistTools']);
  const breakIntoText = t('assistTools:barModal.break', 'Break Into');
  const unitText = t('assistTools:barModal.unit', 'unit(s)');
  const clearAllText = t('assistTools:barModal.clearAll', 'Clear All');
  const dispatch = useDispatch();
  const colorPickerRef = useContext(ColorPickerContext);
  const currentObject = useSelector((state) => {
    const { barModel } = state;
    const { data, activeId, activeIndex, multiBarModelData } = barModel;
    const barModelObjects = isNil(activeIndex)
      ? data.objects
      : multiBarModelData[activeIndex].objects;
    const cObject = barModelObjects.find((o) => o.id === activeId);
    if (cObject) {
      return cObject;
    }
    return null;
  });
  const { activeId, data, colorPickerActive, currentColor } = useSelector(
    (state) => state.barModel
  );
  const isBar = currentObject && currentObject.type === 'bar';
  const unit = currentObject == null || !isBar ? 1 : currentObject.extra.unit;
  const changeObjectColor = useCallback(
    (color) => {
      dispatch(
        changeObjectExtra({ id: activeId, extra: { color: color.hex } })
      );
    },
    [activeId, dispatch]
  );
  const changeObjectUnit = useCallback(
    (e) => {
      if (isBar) {
        dispatch(
          changeObjectExtra({ id: activeId, extra: { unit: e.target.value } })
        );
      }
    },
    [activeId, dispatch, isBar]
  );
  const toggleColorPicker = useCallback(() => {
    dispatch(setColorPickerActive(!colorPickerActive));
  }, [colorPickerActive, dispatch]);
  const onClickOutside = useCallback(() => {
    if (colorPickerActive) {
      dispatch(setColorPickerActive(false));
    }
  }, [colorPickerActive, dispatch]);
  const removeSelectedObject = useCallback(
    (e) => {
      e.preventDefault();
      if (activeId !== null) {
        dispatch(removeSelected());
      }
    },
    [activeId, dispatch]
  );
  useOnClickOutside(
    [colorPickerRef],
    ['bar', 'colorPickerIcon'],
    onClickOutside
  );
  const onClickClearAll = useCallback(() => {
    dispatch(clearAll());
  }, [dispatch]);
  const addObjectWithType = useCallback(
    (type, extra) => (e) => {
      e.preventDefault();
      dispatch(
        addObject({
          id: v4(),
          type,
          size: defaultSize,
          extra,
        })
      );
    },
    [dispatch]
  );
  const addBar = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        addObject({
          id: v4(),
          type: 'bar',
          size: defaultSize,
          extra: {
            color:
              barModelColorOptions[
                data.objects.length % barModelColorOptions.length
              ],
            unit: 1,
          },
        })
      );
    },
    [data.objects.length, dispatch]
  );
  const addText = useCallback(
    (e) => {
      const id = v4();
      e.preventDefault();
      dispatch(
        addObject({
          id,
          type: 'text',
          size: defaultSize,
          extra: { text: '?' },
        })
      );
      dispatch(setEditing(id));
    },
    [dispatch]
  );
  return (
    <Container>
      <ToolbarRow>
        <ToolbarIcon href="#" onClick={addBar} src={bar} alt="bar" />
        <ToolbarIcon
          href="#"
          onClick={addObjectWithType('brace')}
          src={brace}
          alt="brace"
        />
        <ToolbarIcon
          href="#"
          onClick={addObjectWithType('iBrace')}
          src={iBrace}
          alt="inverted brace"
        />
        <ToolbarIcon
          onClick={addObjectWithType('vBrace')}
          href="#"
          src={vBrace}
          alt="vertical brace"
        />
        <ToolbarIcon
          onClick={addObjectWithType('arrow')}
          href="#"
          src={arrow}
          alt="arrow"
        />
        <ToolbarIcon onClick={addText} href="#" src={text} alt="text" />
        <ToolbarDelete
          onClick={removeSelectedObject}
          href="#"
          className="deleteButton"
          src={trash}
          alt="delete"
        />
      </ToolbarRow>
      <ToolbarRow>
        <ColorPickerContainer>
          <ColorPickerIcon
            className="colorPickerIcon"
            onClick={toggleColorPicker}
            color={currentColor}
          />
          <ColorPicker ref={colorPickerRef} show={colorPickerActive}>
            <TwitterPicker
              colors={barModelColorOptions}
              onChange={changeObjectColor}
              styles={{
                default: {
                  hash: { display: 'none' },
                  input: { display: 'none' },
                },
              }}
              triangle="top-left"
            />
          </ColorPicker>
        </ColorPickerContainer>
        {/* class are used to prevent `onClickOutside` from trigerring */}
        <BreakIntoPart className="breakIntoPart">
          <span className="breakIntoPart">{breakIntoText}</span>
          <select
            onChange={changeObjectUnit}
            onBlur={changeObjectUnit}
            value={unit}
            disabled={!isBar}
            className="breakIntoPart"
          >
            {breakIntoValues.map((v) => (
              <option key={v + 1} value={v + 1}>
                {v + 1}
              </option>
            ))}
          </select>
          <span className="breakIntoPart">{unitText}</span>
        </BreakIntoPart>
        <ClearAllButton onClick={onClickClearAll}>
          <img src={erase} alt="erase" />
          <span>{clearAllText}</span>
        </ClearAllButton>
      </ToolbarRow>
    </Container>
  );
}

export default React.memo(Toolbar);
