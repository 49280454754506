import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_LEADERBOARD_V2}Leaderboard/`;

export const fetchSuperheroLeaderBoard = ({ rankingFilter }) => {
  return axios
    .get(`${BASE_URL}SuperHero/RankingFilter/${rankingFilter}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(
        err?.response?.data || 'get superhero leaderboard failed'
      );
    });
};
