import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchSeasons,
  fetchGroups,
  fetchStudents,
  fetchCurrentUserZodiac,
} from 'services/zodiacRace';
import { fetchWrapper } from 'services/login';

// // get seasons
export const getSeasons = createAsyncThunk(
  'zodiacRace/getSeasons',
  async () => {
    try {
      const res = await fetchWrapper(fetchSeasons);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get all seasons failed');
    }
  }
);

// get all zodiacs
export const getZodiacGroups = createAsyncThunk(
  'zodiacRace/getZodiacGroups',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchGroups, params);

      // const res = [
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     // CountryFlag: 'https://koobits.blob.core.windows.net/spp/flags/192.png',
      //     // CountryId: 192,
      //     // CountryName: 'SINGAPORE',
      //     // UserName: 'James Tan',
      //     // Rank: 1,
      //     Zodiac: 'Taurus',
      //     ZodiacDetail: 'Good taste, sensual, down-to-earth, pleasure-seeking',
      //     StartDate: 'Apr 20',
      //     EndDate: 'May 20',
      //     UserId: 6340,
      //     CPPoints: 421223,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Gemini',
      //     ZodiacDetail: 'Dynamic, many talents, likes games',
      //     StartDate: 'May 21',
      //     EndDate: 'June 20',
      //     UserId: 6339,
      //     CPPoints: 411222,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Scorpio',
      //     ZodiacDetail: 'Self-reliant, powerful, wise, dominant',
      //     StartDate: 'Oct 23',
      //     EndDate: 'Nov 21',
      //     UserId: 6338,
      //     CPPoints: 410111,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Cancer',
      //     ZodiacDetail: 'Sensitive, friend-oriented, practical',
      //     StartDate: 'Jun 21',
      //     EndDate: 'Jul 22',
      //     UserId: 6337,
      //     CPPoints: 52222,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Sagittarius',
      //     ZodiacDetail: 'Open-minded, achiever, loving',
      //     StartDate: 'Nov 22',
      //     EndDate: 'Dec 21',
      //     UserId: 6336,
      //     CPPoints: 512233,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Pisces',
      //     ZodiacDetail: 'Alluring, free, sensual, sensitive',
      //     StartDate: 'Feb 19',
      //     EndDate: 'Mar 20',
      //     UserId: 6335,
      //     CPPoints: 410111,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Aquarius',
      //     ZodiacDetail: 'Sets trends, innovative, admired',
      //     StartDate: 'Jan 20',
      //     EndDate: 'Feb 18',
      //     UserId: 6334,
      //     CPPoints: 410111,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Libra',
      //     ZodiacDetail: 'Irresistible, adventurous, lavish',
      //     StartDate: 'Sep 23',
      //     EndDate: 'Oct 22',
      //     UserId: 6333,
      //     CPPoints: 410111,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Aries',
      //     ZodiacDetail: 'Brave, independent, assertive',
      //     StartDate: 'Mar 21',
      //     EndDate: 'Apr 19',
      //     UserId: 6332,
      //     CPPoints: 410111,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Leo',
      //     ZodiacDetail: 'Creative, popular, faithful, dominating',
      //     StartDate: 'Jul 23',
      //     EndDate: 'Aug 22',
      //     UserId: 6331,
      //     CPPoints: 410111,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Capricorn',
      //     ZodiacDetail: 'Hardworking, straightforward, loyal',
      //     StartDate: 'Dec 22',
      //     EndDate: 'Jan 19',
      //     UserId: 6330,
      //     CPPoints: 410111,
      //   },
      //   {
      //     AchievementTime: '2021-04-16T12:09:55.247',
      //     Zodiac: 'Virgo',
      //     ZodiacDetail: 'Successful, creative, clever, can lead others on',
      //     StartDate: 'Aug 23',
      //     EndDate: 'Sep 22',
      //     UserId: 6329,
      //     CPPoints: 410111,
      //   },
      // ];
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get all zodiac groups failed');
    }
  }
);

// get students by zodiac group
export const getStudentsList = createAsyncThunk(
  'zodiacRace/getStudentsList',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchStudents, params);
      const obj = {
        [params.zodiacID]: res,
      };
      return obj;
      // const array = [
      //   'Zhang Zi Lian',
      //   'Joey Yap',
      //   'Anson Tan',
      //   'Joaquim Chin',
      //   'Zhang Zi Yi',
      //   'Naveen K.',
      //   'Jason Z.',
      //   'Yenni K.',
      //   'Jarrett Y.',
      // ];
      // const res = [
      //   {
      //     UserId: 1,
      //     UserName: array[Math.floor(Math.random() * array.length)],
      //     CountryId: 0,
      //     CountryCode: 'SG',
      //     SchoolLogo:
      //       'https://static.koobits.com/eduresources/schlogo/SG0004.png',
      //     IsSuperHero: true,
      //     LastSubmissionDate: '2021-04-22T03:16:50.124Z',
      //     CPPoints: 400,
      //   },
      //   {
      //     UserId: 2,
      //     UserName: array[Math.floor(Math.random() * array.length)],
      //     CountryId: 0,
      //     CountryCode: 'SG',
      //     SchoolLogo:
      //       'https://static.koobits.com/eduresources/schlogo/SG0004.png',
      //     IsSuperHero: true,
      //     LastSubmissionDate: '2021-04-22T03:16:50.124Z',
      //     CPPoints: 300,
      //   },
      //   {
      //     UserId: 3,
      //     UserName: array[Math.floor(Math.random() * array.length)],
      //     CountryId: 0,
      //     CountryCode: 'SG',
      //     SchoolLogo:
      //       'https://static.koobits.com/eduresources/schlogo/SG0004.png',
      //     IsSuperHero: true,
      //     LastSubmissionDate: '2021-04-22T03:16:50.124Z',
      //     CPPoints: 600,
      //   },
      // ];
      // const obj = {
      //   [params.UserId]: res,
      // };
      // return obj;
    } catch (error) {
      throw new Error(error?.message ?? 'Get friends by user failed');
    }
  }
);

// // get current user
export const getCurrentUserZodiac = createAsyncThunk(
  'zodiacRace/getCurrentUserZodiac',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchCurrentUserZodiac, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get current user zodiac failed');
    }
  }
);

const initialState = {
  seasons: [],
  seasonsLoading: false,
  seasonsError: null,
  groupsData: [],
  isLoading: false,
  isError: null,
  groupStudents: {},
  groupStudentsLoading: false,
  groupStudentsError: null,
  currentUserZodiac: {},
};

const zodiacRaceSlice = createSlice({
  name: 'zodiacRace',
  initialState,
  reducers: {
    resetSeasons: (state) => {
      return {
        ...state,
        seasons: [],
      };
    },
  },
  extraReducers: {
    [getSeasons.pending]: (state) => {
      state.seasonsLoading = true;
      state.seasons = [];
      state.seasonsError = null;
    },
    [getSeasons.fulfilled]: (state, action) => {
      state.seasonsLoading = false;
      state.seasons = action.payload;
      state.seasonsError = null;
    },
    [getSeasons.rejected]: (state, action) => {
      state.seasonsLoading = false;
      state.seasons = [];
      state.seasonsError = action.error.message;
    },
    [getZodiacGroups.pending]: (state) => {
      state.isLoading = true;
      state.groupsData = [];
      state.isError = null;
    },
    [getZodiacGroups.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.groupStudents = {};
      state.groupsData = action.payload;
      state.isError = null;
    },
    [getZodiacGroups.rejected]: (state, action) => {
      state.isLoading = false;
      state.groupsData = [];
      state.isError = action.error.message;
    },
    [getStudentsList.pending]: (state) => {
      state.groupStudentsLoading = true;
      state.groupStudentsError = null;
    },
    [getStudentsList.fulfilled]: (state, action) => {
      state.groupStudentsLoading = false;
      state.groupStudents = { ...state.groupStudents, ...action.payload };
      state.groupStudentsError = null;
    },
    [getStudentsList.rejected]: (state, action) => {
      state.groupStudentsLoading = false;
      state.groupStudentsError = action.error.message;
    },
    [getCurrentUserZodiac.pending]: (state) => {
      state.currentUserZodiac = {};
      state.isError = null;
    },
    [getCurrentUserZodiac.fulfilled]: (state, action) => {
      state.currentUserZodiac = action.payload;
      state.isError = null;
    },
    [getCurrentUserZodiac.rejected]: (state, action) => {
      state.currentUserZodiac = {};
      state.isError = action.error.message;
    },
  },
});

const { reducer, actions } = zodiacRaceSlice;
export const { resetSeasons } = actions;
export default reducer;
