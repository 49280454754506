import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from './SwitchToTeacher.styles';

const SwitchToTeacher = () => {
  const { t } = useTranslation(['dashboard']);
  const switchHandler = () => {
    // add callback for flutter app switch account
    const hasFlutter = window.flutter_inappwebview;
    if (hasFlutter) hasFlutter.callHandler('switchAccountFunc');
    window.location.replace(process.env.REACT_APP_TEACHER_PORTAL_URL);
  };
  return (
    <Container onClick={() => switchHandler()}>
      <div className="icon" />
      <div className="text">{t('dashboard:switch', 'Switch to Teacher')}</div>
    </Container>
  );
};

export default SwitchToTeacher;
