import styled from 'styled-components';
import { isNil } from 'ramda';

export const Container = styled.div`
  background: url(${(props) => props.backgroundImage});
  background-color: #ffffff;
  background-size: cover;
  background-position-y: bottom;

  border-radius: ${(props) => props.borderRadius ?? `20px`};
  min-width: ${(props) => (isNil(props.minWidth) ? '530px' : props.minWidth)};
  min-height: 200px;
  .close-btn {
    width: 36px;
    height: 36px;
    background: #ebf6ff;
    color: #73b0e8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    font-weight: bold;
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 21px;
    cursor: pointer;
  }
`;

export default {};
