import styled from 'styled-components';

export const Overlay = styled.button`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  border: none;
`;

export const Sidebar = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: block;
  }
`;

export const MenuIconContainer = styled.button`
  position: absolute;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
    height: 24px;
  }
`;

export const SidebarWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 80%;
  background-color: #fff;
  z-index: 102;
  display: flex;
  transform: ${(props) =>
    props.isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition-duration: 500ms;
`;

export const CloseIconContainer = styled.button`
  background: none;
  border: none;
  padding: 0;
  img {
    filter: invert(94%) sepia(100%) saturate(0%) hue-rotate(240deg)
      brightness(106%) contrast(105%);
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 59px;
  background-color: #4a4947;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
`;

export const TabWrapper = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ededed;
`;

export const Content = styled.div`
  height: calc(100% - 59px);
  width: 100%;
`;

export const TabButton = styled.button`
  height: 59px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e3e3e3;
`;

export const TabContent = styled.div`
  display: flex;
  height: 100%;
  background-color: #f7f7f7;
`;
