import React, { useEffect } from 'react';
import {
  addition,
  subtraction,
  multiplication,
  division,
} from 'constants/index';
import { BodyContainer } from './FourOpsTool.styles';

const FourOpsBody = ({ activeType, arrOption }) => {
  let operatorType = addition;
  if (activeType === 'subtraction') operatorType = subtraction;
  else if (activeType === 'multiplication') operatorType = multiplication;
  else if (activeType === 'division') operatorType = division;
  useEffect(() => {
    Array.from(document.querySelectorAll('.unInp')).forEach((input) => {
      input.value = '';
      input.maxLength = '1';
    });
  }, [activeType, arrOption]);
  return (
    <BodyContainer>
      {operatorType[arrOption].map((elem, index) => (
        <div className="four-row" key={index}>
          {typeof elem.one !== 'undefined' && SingleSection(elem.one)}
          {typeof elem.two !== 'undefined' && SingleSection(elem.two)}
          {typeof elem.three !== 'undefined' && SingleSection(elem.three)}
          {typeof elem.four !== 'undefined' && SingleSection(elem.four)}
          {typeof elem.five !== 'undefined' && SingleSection(elem.five)}
        </div>
      ))}
    </BodyContainer>
  );
};

export default FourOpsBody;

const SingleSection = (type) => {
  switch (type) {
    case 'empty': // empty
      return <div className="empty" />;
    case 'i': // input
      return <input className="input unInp" autoComplete="off" />;
    case 'c': // carry
      return (
        <div className="cont-carry">
          <input className="carry unInp" autoComplete="off" />
        </div>
      );
    case 'dash': // dash lines
      return <div className="dash" />;
    case 'add': // add img
      return <div className="add" />;
    case 'sub': // sub img
      return <div className="sub" />;
    case 'mul': // mul img
      return <div className="mul" />;
    case 'div': // div img
      return <div className="div" />;
    // All cases for division
    case 'div-dash': // div dash lines
      return <div className="div-dash" />;
    case 'div-dash-small': // div small dash lines
      return <div className="div-dash-small" />;
    case 'empty-h': // empty with '0' height
      return <div className="empty h0" />;
    case 'empty-div': // empty div
      return <div className="empty-div" />;
    default:
      return <div />;
  }
};
