import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { getCurrentTerm } from 'pages/Leaderboard/v2/helper';
import { LOADING } from 'pages/Leaderboard/v2/constant';

import { Container, Term } from './TermList.styles';

const TermList = ({ clickHandler, currentTerm, setCurrentTerm }) => {
  const { t } = useTranslation(['leaderboard']);
  const { subject } = useParams();

  const { data, isLoading } = useSelector((state) => state.leaderboard.terms);

  useEffect(() => {
    if (!isEmpty(data)) {
      setCurrentTerm(getCurrentTerm(data));
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Container subject={subject}>
      {isLoading && <p className="loading">{LOADING}</p>}
      {!isLoading &&
        data?.map((item, i) => (
          <Term
            data-cy={`term-${i + 1}`}
            onClick={() => clickHandler(item)}
            key={item.termId}
            active={currentTerm?.termId === item.termId}
            term={item.termId}
            subject={subject}
          >
            {t(
              'leaderboard:topBrainBanner.term',
              { number: item.termId },
              `Term ${item.termId}`
            )}
          </Term>
        ))}
    </Container>
  );
};

export default TermList;
