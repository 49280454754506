import styled from 'styled-components';
import CorrectIcon from 'assets/img/icon-correct.svg';

export const Container = styled.div`
  display: flex;
  font-size: 15px;
  background: ${(props) =>
    props.type === 'wrong' || props.type === 'noevaluated'
      ? '#FFD4D4'
      : props.type === 'unmarked'
      ? '#C5F3FF'
      : 'transparent'};
  border: 1px solid
    ${(props) =>
      props.type === 'wrong' || props.type === 'noevaluated'
        ? '#F39191'
        : props.type === 'unmarked'
        ? '#3FC1E4'
        : 'transparent'};
  border-radius: 30px;
  padding: 0.2rem 0.5rem;
  width: 20%;
  height: 35px;
  align-items: center;
  margin-right: 2rem;
  margin-bottom: 1.2rem;
  cursor: ${(props) => props.type !== 'correct' && 'pointer'};

  .icon {
    margin-right: 0.5rem;
    border-radius: 50%;
    background: url(${CorrectIcon});
    background-repeat: no-repeat;
    background-size: 100%;
    color: #ffffff;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
  }

  .qn-number {
    background: ${(props) =>
      props.type === 'wrong' || props.type === 'noevaluated'
        ? '#FF5757'
        : '#3FC1E4'};
    border-radius: 20px;
    color: white;
    padding: 0.1rem 0.6rem;
    margin-right: 0.2rem;
  }

  @media (max-width: 1260px) {
    width: 22%;
    margin-right: 1rem;
  }
  @media (max-width: 980px) {
    font-size: 12px;
    width: 28%;
    margin: 0;
    margin-bottom: 0.5rem;
    &:nth-child(3n-1) {
      margin: 0 8% 0.5rem;
    }
  }

  @media (max-width: 502px) {
    font-size: 10px;
    &:nth-child(3n-1) {
      margin: 0 4% 0.5rem;
    }
  }
`;
