import { Overlay } from 'components/Sidebar/MobileSidebar.styles';
import React from 'react';
import CloseIcon from 'assets/img/icon-close.svg';
import { ModalContainer, CloseButton } from './BottomModal.styles';

const BottomModal = ({ isOpen, handleClose, height, padding, children }) => {
  return (
    <>
      {isOpen && <Overlay onClick={handleClose} />}

      <ModalContainer height={height} padding={padding} isOpen={isOpen}>
        <CloseButton onClick={handleClose}>
          <img src={CloseIcon} alt="" />
        </CloseButton>
        {children}
      </ModalContainer>
    </>
  );
};

export default BottomModal;
