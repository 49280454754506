import styled, { css } from 'styled-components';
import MainGreenBg from 'assets/img/assignment/assignment-view/content-green-bg.svg';

export const Container = styled.div`
  display: flex;
`;

export const Main = styled.div`
  background: url(${MainGreenBg});
  background-color: #3b6c72;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;

  .wrapper {
    display: flex;
    justify-content: center;
  }
  .summary-box {
    height: fit-content;
    background: #ffffff;
    border: 1px solid #fdad0c;
    border-radius: 8px;
    box-shadow: 10px 10px 0px 0px #fdad0c;
    min-width: ${(props) => props.type !== 'homework' && '70%'};
    width: ${(props) => props.type === 'homework' && '70%'};
    padding-left: ${(props) => (props.type === 'homework' ? '5rem' : '0rem')};
    padding-right: ${(props) => (props.type === 'homework' ? '5rem' : '0rem')};
  }

  @media (min-width: 502px) and (max-height: 767px) {
    ${(props) =>
      props.type === 'homework' &&
      css`
        .wrapper {
          margin-top: 8rem;
        }
      `}

    ${(props) =>
      ['mocktest', 'test'].includes(props.type) &&
      css`
        .wrapper {
          margin-top: 5rem;
          margin-left: 1rem;
          margin-right: 1rem;
        }
      `}
  }

  @media (max-width: 876px) {
    ${(props) =>
      ['mocktest', 'test'].includes(props.type) &&
      css`
        justify-content: flex-start;
        padding-top: 10rem;
      `}

    .summary-box {
      padding-left: ${(props) => (props.type === 'homework' ? '2rem' : '0rem')};
      padding-right: ${(props) =>
        props.type === 'homework' ? '2rem' : '0rem'};
      min-width: ${(props) => props.type !== 'homework' && '80%'};
      width: ${(props) => props.type === 'homework' && '80%'};
    }
  }

  @media (max-width: 768px) {
    ${(props) =>
      ['mocktest', 'test'].includes(props.type) &&
      css`
        padding-top: 5rem;
      `}
  }

  @media (max-width: 500px) {
    ${(props) =>
      ['mocktest', 'test'].includes(props.type) &&
      css`
        padding-top: 0rem;
      `}

    .summary-box {
      width: 100%;
    }
  }

  @media (max-width: 502px) {
    display: flex;
    justify-content: flex-start !important;

    ${(props) =>
      ['mocktest', 'test'].includes(props.type) &&
      css`
        .summary-box {
          margin-top: 5rem;
          margin-left: 1rem;
          margin-right: 1rem;
        }
      `}

    ${(props) =>
      props.type === 'homework' &&
      css`
        background: #f6f6f6;

        .wrapper {
          margin-top: 0rem;
        }

        .summary-box {
          padding: 0rem;
          width: 100%;
          background: #ffffff;
          border-radius: 0px;
          border: none;
          box-shadow: none;
        }
      `}
  }
`;

export const AssignerSection = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-left: 14%;
  @media (max-width: 876px) {
    margin-left: 4%;
  }

  @media (max-width: 502px) {
    margin-top: 2rem;
    margin-bottom: 1rem;

    ${(props) =>
      props.type === 'homework' &&
      css`
        .category {
          color: #939393;
        }

        .info {
          color: #000000;
        }
      `}
  }
`;
