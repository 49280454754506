import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Workspace = styled.div`
  min-height: 330px;
  min-width: 350px;
  padding: 32px;
  flex-grow: 1;
  border: none;
  outline: none;
  background: #fff;
  position: relative;
`;

export const WorkspaceContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
