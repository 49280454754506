import styled from 'styled-components';
import MathBg from 'assets/img/kooquiz/kooQuizQn/math-qn-bg.svg';
import SciBg from 'assets/img/kooquiz/kooQuizQn/sci-qn-bg.svg';

const assignmentTypeStyles = (assignmentType) => {
  switch (assignmentType) {
    case 'KooQuizSci':
      return {
        bgImage: SciBg,
        bgColor: '#176B7D',
      };
    default:
      return {
        bgImage: MathBg,
        bgColor: '#38437b',
      };
  }
};

export const Container = styled.div`
  display: flex;
`;

export const Main = styled.div`
  background: ${(props) => `url(${assignmentTypeStyles(props.type).bgImage})`};
  background-color: ${(props) => assignmentTypeStyles(props.type).bgColor};
  background-repeat: repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  .wrapper {
    display: flex;
    justify-content: center;
  }
  .summary-box {
    height: fit-content;
    background: #ffffff;
    border: 1px solid #fdad0c;
    border-radius: 8px;
    box-shadow: 10px 10px 0px 0px #fdad0c;
    width: 70%;
    margin-top: 1.875rem;
    padding: 0.2rem 0rem 0rem 0.3rem;
  }
`;

export const AssignerSection = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-left: 14%;
`;
