import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

const typeIdMap = {
  country: 3,
  school: 2,
  class: 1,
};

export const typeWithLevel = ['school', 'country'];

export const fetchAvatarShowcase = async ({ type, level }) => {
  const levelQuery = typeWithLevel.includes(type) ? level : -1;
  return fetch(
    `${baseUrl}KokoMonster/GetShowcaseAvatars/${levelQuery}/${typeIdMap[type]}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch avatar showcase failed'); // eslint-disable-line
    });
};

export const fetchLevels = async () => {
  return fetch(`${baseUrl}Lookup/Levels`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch levels failed'); // eslint-disable-line
    });
};
