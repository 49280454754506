import React from 'react';
import { SubmissionResultTypes } from 'constants/index';
import {
  Container,
  IndicatorContainer,
  IndicatorColor,
} from './QnStatusIndicator.styles';

const QnStatusIndicator = ({
  totalProgress,
  inProgress,
  correctColor,
  wrongColor,
  text,
  isMissionPlus = false,
}) => {
  const statusColor = (status) => {
    if (status === 'correct') {
      return correctColor;
    }
    if (status === 'wrong') {
      return wrongColor;
    }
    return '#ffffff';
  };
  const emptyArray = [...Array(inProgress)].map((item, index) => (
    <IndicatorColor key={index} color={statusColor(item)} />
  ));

  const progress =
    totalProgress &&
    totalProgress.map((item, index) => (
      <IndicatorColor
        key={item + index}
        color={statusColor(SubmissionResultTypes[item.isResult])}
      />
    ));
  const combine = progress ? progress.concat(emptyArray) : emptyArray;
  return (
    <Container data-cy="qn-header">
      {text}
      <IndicatorContainer isMissionPlus={isMissionPlus}>
        {combine}
      </IndicatorContainer>
    </Container>
  );
};

export default QnStatusIndicator;
