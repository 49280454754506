import { parseString } from 'xml2js';
import { isEmpty, isNil } from 'ramda';

export default (question) => {
  let title = '';
  let mcqOptions = [];
  let solution = '';
  let questionAnswer = '';
  let correctMCQOptions = [];
  if (!isNil(question?.Payload) && !isEmpty(question?.Payload)) {
    parseString(question.Payload, (_error, result) => {
      if (result.Question?.Body) {
        title = result.Question?.Body[0]?.Content[0] ?? '';
      }
      if (result.Question?.Options) {
        const options = result.Question?.Options[0]?.Option ?? '';
        mcqOptions = options.map((option) => option.Content[0]);
        correctMCQOptions = options
          .filter((option) => option.$.Iscorrect === 'True')
          .map((option) => option.$.RefId);
      }
      if (result.Question?.Solution) {
        solution = result.Question?.Solution[0]?.Content[0] ?? '';
      }
      if (result.Question?.Answers) {
        questionAnswer = result.Question?.Answers[0]?.Answer ?? '';
      } else if (result.Question?.Submissions) {
        let optionAnswered;
        if (result.Question?.Submissions[0]?.Submission[0]?.Options) {
          optionAnswered =
            result.Question?.Submissions[0]?.Submission[0]?.Options[0]
              ?.Option[0] ?? '';
        } else {
          optionAnswered = '';
        }
        if (optionAnswered.length > 0) {
          optionAnswered = parseInt(optionAnswered.$.RefId, 10) - 1;
          questionAnswer = mcqOptions[optionAnswered];
        }
      }
    });
  }
  const image = title.match(new RegExp(`url="(.*?)">`)) ?? [];
  return {
    title,
    imageUri: image[1] ?? '',
    mcqOptions,
    solution,
    questionAnswer,
    correctMCQOptions,
  };
};
