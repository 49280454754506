import React from 'react';
import Container from './IconButton.styles';

const IconButton = ({
  btnBgColor,
  onClick,
  size,
  dataCy,
  Icon,
  padding,
  color,
}) => {
  return (
    <Container
      data-cy={dataCy}
      size={size}
      onClick={onClick}
      bgColor={btnBgColor}
      padding={padding}
      color={color}
    >
      {typeof Icon === 'string' ? <img src={Icon} alt="icon" /> : <Icon />}
    </Container>
  );
};

export default IconButton;
