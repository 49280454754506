import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { PAGE_COUNT } from './constants';
import { periodOptions } from 'constants/history';
import moment from 'moment';
import ErrorModal from 'components/Shared/ErrorModal';
import Spinner from 'components/Shared/Spinner';
import Pagination from 'components/Shared/Pagination';
import noDataMiko from 'assets/img/error/noDataMiko.svg';
import {
  StyledContainer,
  StyledTitle,
  StyledTable,
  StyledNoDataWrap,
} from './KoKoHistoryResult.styles';

const KoKoHistoryResult = ({ searchAction, selectPeriod, loading }) => {
  const { t, i18n } = useTranslation(['kokoHistory']);
  const [page, setPage] = useState(0);
  const [searchPage, setSearchPage] = useState(1);
  const { isError, Transactions, TotalEarned, TotalSpent } = useSelector(
    (state) => state.kokoHistory
  );
  const kokoHistoryI18nData = i18n.getResourceBundle(
    i18n.language,
    'kokoHistory'
  );
  const maxPage = Math.ceil(Transactions.length / PAGE_COUNT);
  const prevDate = moment()
    .subtract(1, periodOptions[selectPeriod].i18nKey)
    .format('DD-MM-YYYY');
  const periodTitle = {
    1: t('period.options.day', 'Today'),
    2: t('period.options.week', 'Past Week'),
    3: t('period.options.month', 'Past Month'),
  };
  useEffect(() => {
    setPage(0);
    setSearchPage(1);
  }, [selectPeriod]);
  const onPageChange = (newPage) => {
    if (page !== newPage - 1 && /[0-9]/.test(newPage)) {
      if (newPage < maxPage) {
        if (newPage >= 0) {
          setPage(newPage - 1);
          setSearchPage(newPage);
        } else {
          setPage(0);
          setSearchPage(1);
        }
      } else {
        setPage(maxPage - 1);
        setSearchPage(maxPage);
      }
    }
  };
  const onSearchPageChange = (newPage) => {
    const newPageToNumber = Number(newPage);
    setSearchPage(newPage);
    if (newPageToNumber > 0) {
      if (newPageToNumber <= maxPage) {
        setPage(newPageToNumber - 1);
      } else {
        setPage(maxPage - 1);
      }
    }
  };
  const renderEvent = (event) => {
    const eventType = kokoHistoryI18nData.event;
    if (eventType[event]) {
      return eventType[event];
    } else if (event.includes(':')) {
      const splitEvent = event.split(':');
      const eventName = splitEvent[0].trim();
      if (eventType[eventName]) {
        return `${eventType[eventName]} ${splitEvent[1]}`;
      } else if (event.includes('AchievementSticker')) {
        const achivementDate = event.split('Unlock AchievementSticker');
        return `${t(
          'event.Unlock AchievementSticker',
          'Unlock AchievementSticker'
        )} ${achivementDate[1]}`;
      }
      return event;
    }
    return event;
  };
  return (
    <StyledContainer>
      {loading && <Spinner />}
      {!loading && isNil(isError) && Transactions.length > 0 && (
        <>
          <StyledTitle>
            <div className="left">
              <p>{periodTitle[selectPeriod]}</p>
              <span>
                {t('result.title', 'History Period')}:{' '}
                {selectPeriod !== 1 && prevDate}{' '}
                {selectPeriod !== 1 && t('result.to', 'to')}{' '}
                {moment().format('DD-MM-yyyy')}
              </span>
            </div>
            <div className="right">
              <p className="earned">
                {t('result.totalEarned', 'Total Earned')}
                <span data-cy="total-earned">{TotalEarned}</span>
              </p>
              <p className="spent">
                {t('result.totalSpent', 'Total Spent')}
                <span data-cy="total-spent">{Math.abs(TotalSpent)}</span>
              </p>
            </div>
          </StyledTitle>
          <StyledTable>
            <thead>
              <tr>
                <th>{t('result.date', 'Date')}</th>
                <th>{t('result.time', 'Time')}</th>
                <th>{t('result.earnedOrSpent', 'Earned by / Spent on')}</th>
                <th className="earned">{t('result.earned', 'Earned')}</th>
                <th className="spent">{t('result.spent', 'Spent')}</th>
                <th className="remaining">
                  {t('result.totalCredits', 'Total Credits Remaining')}
                </th>
              </tr>
            </thead>
            <tbody>
              {Transactions.filter(
                (_, i) => i >= page * PAGE_COUNT && i < (page + 1) * PAGE_COUNT
              ).map((transaction) => (
                <tr
                  key={transaction.TransactionDateTime}
                  data-cy="transaction-datum"
                >
                  <td>
                    {moment(transaction.TransactionDateTime).format(
                      'DD/MM/YYYY'
                    )}
                  </td>
                  <td>
                    {moment(transaction.TransactionDateTime).format('HH:mm')}
                  </td>
                  <td>{renderEvent(transaction.Event)}</td>
                  <td className="earned">
                    {transaction.PointsEarned
                      ? `+${transaction.PointsEarned}`
                      : null}
                  </td>
                  <td className="spent">
                    {transaction.PointsSpent ? transaction.PointsSpent : null}
                  </td>
                  <td className="remaining">{transaction.PointsRemaining}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
          <Pagination
            maxPage={maxPage}
            onPageChange={onPageChange}
            onSearchPageChange={onSearchPageChange}
            page={page + 1}
            searchPage={searchPage}
          />
        </>
      )}
      {!loading && !Transactions.length && (
        <StyledNoDataWrap data-cy="no-data">
          <img src={noDataMiko} alt="no data" />
          <div>
            <p>
              {t(
                'result.noData',
                'No KoKo Credits earned or spent in the selected period.'
              )}
            </p>
            <span>
              ({t('result.title', 'History Period')}:{' '}
              {selectPeriod !== 1 && prevDate}{' '}
              {selectPeriod !== 1 && t('result.to', 'to')}{' '}
              {moment().format('DD-MM-yyyy')})
            </span>
          </div>
        </StyledNoDataWrap>
      )}
      {!loading && isError && (
        <ErrorModal reloadAction={searchAction} errorMessage={isError} />
      )}
    </StyledContainer>
  );
};

export default KoKoHistoryResult;
