import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
  color: #000;
  font-weight: bold;
  img {
    width: 100%;
  }
`;

export const ButtonClose = styled.a`
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 15%;
  right: 0;
  cursor: pointer;
`;

export const TextInfo = styled.p`
  position: absolute;
  padding: 10px 20px;
  top: 45%;
`;

export const ButtonOK = styled.a`
  position: absolute;
  background-color: #2996df;
  border-radius: 10%;
  color: #fff;
  bottom: 10%;
  padding: 5px 10px;
  cursor: pointer;
`;
