import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 400px;
  width: 800px;
  /* height: calc(100vh - 300px); */
  /* width: 75%; */
  margin: 1rem 0;
  .right-side {
    display: flex;
    flex-direction: column;
    width: 37%;
    justify-content: space-between;
  }
  @media (min-width: 1700px) {
    height: 500px;
    width: 1050px;
  }
  @media (max-width: 1150px) {
    height: 350px;
    width: 650px;
  }
  @media (max-width: 950px) {
    height: 300px;
    width: 500px;
  }
`;
