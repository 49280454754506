import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchAvatar = async () => {
  return fetch(`${baseUrl}KokoMonster/GetIndividualAvatar`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch individual avatar failed'); // eslint-disable-line
    });
};

export const submitAvatar = async ({
  userId,
  body,
  horn,
  eye,
  mouth,
  color,
  name,
}) => {
  return fetch(`${baseUrl}KokoMonster/SaveAvatar`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify({
      Body: { ID: body },
      Horn: { ID: horn },
      Mouth: { ID: mouth },
      Eye: { ID: eye },
      UserId: userId,
      Color: color,
      Name: name,
    }),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Save avatar failed'); // eslint-disable-line
    });
};

export const fetchAvatarParts = async () => {
  return fetch(`${baseUrl}KokoMonster/GetAvatarPartsInfo`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch avatar parts failed'); // eslint-disable-line
    });
};
