import styled from 'styled-components';
import HallOfFameBanner from 'assets/img/leaderboard/hod/hall-of-fame-bg.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0 20px;
  width: 70%;
  align-self: center;
  background: #ffffff;
  .banner {
    margin: 1rem 0;
    .hall-of-fame {
      width: 100%;
      height: 80px;
      background-image: url(${HallOfFameBanner});
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .title {
        margin-bottom: 0px;
        font-family: 'Grilled Cheese';
        font-size: 48px;
        color: white;
        -webkit-text-stroke-width: 2.5px;
        -webkit-text-stroke-color: #c36100;
        /* width: 35%; */
        text-align: center;
      }
    }
  }
  .calendar {
    background-color: rgb(155, 71, 10, 0.53);
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    position: absolute;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    right: 17.5%;
    margin-top: -4rem;
    cursor: pointer;
    span {
      margin-left: 0.5rem;
    }
  }
  .cards {
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
  }
`;

export const NoParticipant = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #ffffff;
  padding: 20px 30px;
  text-align: center;
`;
