import styled from 'styled-components';

export const Container = styled.div`
  display: ${(props) => (props.showVideo ? 'block' : 'none')};
  animation: ${(props) => props.showVideo && 'fadeIn ease 3s forwards'};
  -webkit-animation: ${(props) => props.showVideo && 'fadeIn ease 3s forwards'};
  -moz-animation: ${(props) => props.showVideo && 'fadeIn ease 3s forwards'};
  -o-animation: ${(props) => props.showVideo && 'fadeIn ease 3s forwards'};
  -ms-animation: ${(props) => props.showVideo && 'fadeIn ease 3s forwards'};
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
