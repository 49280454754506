import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  words: [
    'That’s Right!',
    'Great Job!',
    'Amazing!',
    'Bravo!',
    'Terrific!',
    'Outstanding!',
    'Spectacular!',
    'Phenomenal!',
    'Superb!',
    'Sensational!',
  ],
  pickedWord: null,
};

const generateRandomIndex = (arrayLength) => {
  return Math.floor(Math.random() * arrayLength);
};

const randomWordsSlice = createSlice({
  name: 'randomWords',
  initialState,
  reducers: {
    pickWord: (state) => {
      const newWords = [...state.words];
      const pickedIndex = generateRandomIndex(newWords.length);
      state.pickedWord = newWords[pickedIndex];
      newWords.splice(pickedIndex, 1);
      if (newWords.length > 0) {
        state.words = newWords;
      } else {
        state.words = initialState.words;
      }
    },
    clearPickedWord: (state) => {
      state.pickedWord = null;
    },
  },
});

const { actions, reducer } = randomWordsSlice;
export const { pickWord, clearPickedWord } = actions;
export default reducer;
