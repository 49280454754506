import styled from 'styled-components';
import CorrectIcon from 'assets/img/icon-correct.svg';
import WrongIcon from 'assets/img/icon-wrong.svg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 0rem;
  background: ${(props) => (props.active ? '#FFFFFF' : '#f2f2f2')};
  border-bottom: 1px solid #cecece;
  min-height: 52px;
  cursor: pointer;
  filter: ${({ disabled }) => disabled && 'grayscale(90%)'};
  :hover {
    background: ${(props) => (props.active ? '#FFFFFF' : '#f6f6f6')};
  }
  .number {
    align-self: center;
    margin-right: ${(props) => (!props.collapse ? '0.5rem' : '0')};
  }
`;

export const Number = styled.div`
  border-radius: 50%;
  background: ${(props) =>
    props.correct
      ? `url(${CorrectIcon})`
      : props.correct === false
      ? `url(${WrongIcon})`
      : props.answered
      ? '#67D4E9'
      : '#f8b720'};
  background-repeat: no-repeat;
  background-size: 100%;
  color: #ffffff;
  width: ${(props) => (props.active ? '2rem' : '1.5rem')};
  height: ${(props) => (props.active ? '2rem' : '1.5rem')};
  text-align: center;
  border: ${(props) => (props.active ? '5px solid #fff3b4' : 'none')};
`;

export const QnStatus = styled.div`
  line-height: 1.2;
  align-self: center;
  width: 55%;
  display: ${(props) => (props.collapse ? 'none' : 'block')};
`;

export const QnNumber = styled.p`
  margin-bottom: 0rem;
  color: ${(props) => (props.active ? '#FF7920' : '#343434')};
`;

export const StatusInfo = styled.p`
  margin-bottom: 0rem;
  font-size: 0.8rem;

  color: ${(props) =>
    props.status
      ? 'rgb(15, 129, 18, 0.52)'
      : props.status === false
      ? 'rgb(228, 79, 79, 0.52)'
      : 'rgb(29, 120, 139, 0.52)'};
`;
