import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchStoryWorlds = async () => {
  return fetch(`${baseUrl}StoryMath/GetStoryWorlds`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get story world failed'); // eslint-disable-line
    });
};

export const fetchStoryChapters = async (storyId) => {
  return fetch(`${baseUrl}StoryMath/GetStoryChapters/${storyId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get story chapters failed'); // eslint-disable-line
    });
};

export const fetchStoryChapterTiles = async (chapterId) => {
  return fetch(`${baseUrl}StoryMath/GetStoryChapterTiles/${chapterId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get story chapter tiles failed'); // eslint-disable-line
    });
};

export const unlockStoryChapter = async (chapterId) => {
  return fetch(`${baseUrl}StoryMath/UnlockStoryChapter/${chapterId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        if (result.Message.code === 'SPM042') {
          throw new Error('SPM042');
        }
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Unlock story chapter failed'); // eslint-disable-line
    });
};
