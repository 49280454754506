import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger } from 'react-bootstrap';
import { difficultyLevels } from 'constants/dailyChallenge';
import { setActive } from 'store/dailyChallenge/dailyChallengeSlice';
import { ReactComponent as TipIcon } from 'assets/img/dailychallenge/challenge-difficulty/tip.svg';
import { SubmissionResultTypes } from 'constants/index';
import { isNil } from 'ramda';
import useIsMobile from 'hooks/responsive/useIsMobile';
import DifficultyStarsSetting from './DifficultyStarsSetting';
import {
  Container,
  Header,
  DifficultySection,
  QuestionsSection,
  StyledCurriculum,
  StyledTooltip,
  QuestionsSubSection,
} from './ChallengeQuestions.styles';
import ChallengeQnCard from './ChallengeQnCard';

const ChallengeQuestions = ({
  difficultyLevel,
  handleShowDifficultySelection,
  curriculumName,
  legend,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['dailyChallenge']);
  const diffObject = difficultyLevels[difficultyLevel].qnDifficultyLevels;
  const { dailyChallengeQuestion, dailyChallengeSubmissions } = useSelector(
    (state) => state.dailyChallenge
  );
  const { products } = useSelector((state) => state.plan);
  // responsive
  const isMobile = useIsMobile();
  const isMathB2C = useMemo(() => {
    const mathItem =
      products &&
      products.find(
        ({ subject, nonExpiredSubscriptionProducts }) =>
          subject?.toLowerCase().trim() === 'math' &&
          nonExpiredSubscriptionProducts.find(
            ({ productType }) => productType.toLowerCase().trim() !== 'school'
          )
      );
    return !isNil(mathItem);
  }, [products]);
  const setActiveQuestion = (val) => {
    dispatch(setActive(val));
    history.push('/challenges/daily-challenge/question-view');
  };
  const checkQnStatus = (index) => {
    const submissionModel = dailyChallengeSubmissions[index].SubmissionModel;
    if (submissionModel === null) {
      return 'incomplete';
    }
    if (SubmissionResultTypes[submissionModel.SubmissionResult] === 'correct') {
      return 'completed';
    }
    if (dailyChallengeSubmissions[index].QuestionStatus === 4) {
      return 'skipped';
    }
    return 'incomplete';
  };

  const curriculumContent = (withIcon = true) => {
    return (
      <StyledCurriculum>
        <p>{curriculumName}</p>
        {isMathB2C && withIcon && (
          <OverlayTrigger
            overlay={
              <StyledTooltip key="difficultyInfo">
                <span>
                  {t(
                    `dailyChallenge:difficultySelection.changeInfo`,
                    'Go to setting page to change curriculum.'
                  )}
                </span>
              </StyledTooltip>
            }
          >
            <TipIcon />
          </OverlayTrigger>
        )}
      </StyledCurriculum>
    );
  };

  return (
    <>
      <Container>
        <Header>
          <DifficultySection>
            <button
              className="btn-settings"
              type="button"
              data-cy="difficulty-settings-btn"
              onClick={handleShowDifficultySelection}
            >
              <DifficultyStarsSetting
                numOfStars={difficultyLevels[difficultyLevel].numOfStars}
              />
            </button>
            <div className="difficulty-text">
              <p className="difficulty-name">
                {t(
                  `dailyChallenge:difficultySelection.${difficultyLevels[difficultyLevel].name}`,
                  `${difficultyLevels[difficultyLevel].name} Challenge`
                )}
              </p>
              <p className="difficulty-description">
                {t(
                  `dailyChallenge:difficultySelection.${difficultyLevels[difficultyLevel].translation}`,
                  `${difficultyLevels[difficultyLevel].description}`
                )}
              </p>
            </div>
          </DifficultySection>
          {!isMobile && curriculumContent()}
        </Header>
        {dailyChallengeQuestion.length > 0 && (
          <QuestionsSection>
            <QuestionsSubSection>
              {dailyChallengeQuestion.slice(0, 5).map((question, index) => (
                <ChallengeQnCard
                  key={question.Id}
                  difficulty={diffObject[index]}
                  qnNo={index + 1}
                  qnStatus={checkQnStatus(index)}
                  onClick={() => setActiveQuestion(index + 1)}
                  challengePoints={
                    dailyChallengeSubmissions[index].ChallengePoints
                  }
                />
              ))}
            </QuestionsSubSection>
            <QuestionsSubSection>
              {dailyChallengeQuestion.slice(5, 10).map((question, index) => (
                <ChallengeQnCard
                  key={question.Id}
                  difficulty={diffObject[index + 5]}
                  qnNo={index + 6}
                  qnStatus={checkQnStatus(index + 5)}
                  onClick={() => setActiveQuestion(index + 6)}
                  challengePoints={
                    dailyChallengeSubmissions[index + 5].ChallengePoints
                  }
                />
              ))}
            </QuestionsSubSection>
          </QuestionsSection>
        )}
        {isMobile && legend}
        {isMobile && curriculumContent(false)}
      </Container>
      {!isMobile && legend}
    </>
  );
};

export default ChallengeQuestions;
