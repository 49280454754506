import styled from 'styled-components';
import KoobitsParent from 'assets/img/parent-portal/icon-koobits-parent.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 59px 10%;
  min-height: calc(100vh - 59px);
  .desc {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    .koobits-parent {
      width: 47px;
      height: 47px;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 0.5rem;
      background: url(${KoobitsParent});
    }
    p {
      max-width: 240px;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
  .store {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    .app-store {
      margin-right: 1rem;
    }
  }
`;
