import styled from 'styled-components';

export const Container = styled.div``;

export const Rewards = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledErrMsg = styled.div`
  display: inline-flex;
`;
export const StyledReloadBtn = styled.button`
  color: #ffffff;
  background-color: #ff7121;
  border-radius: 14px;
  font-size: 14px;
  line-height: 1;
  margin-left: 1rem;
  border: 1px solid #ff7121;
`;
export const LeftContent = styled.div`
  display: flex;
  .subcard {
    height: 200px;
    width: 200px;
    background: ${(props) =>
      props.status === 'close' || props.status === 'locked'
        ? `url(${props.closeImg})`
        : `url(${props.openImg})`};
    background-repeat: no-repeat;
    background-size: contain;
  }
  .info-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1.5rem;
    .challenge-name {
      color: #0d0d0d;
      font-size: 24px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      span {
        font-size: 16px;
        font-weight: normal;
      }
    }
    .detail-two {
      display: flex;
    }
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn-group {
    img {
      position: absolute;
      right: 0;
      margin-right: -5%;
      z-index: 1;
      @media (max-width: 1441px) {
        width: 30px;
        margin-top: 2%;
        margin-right: 0%;
      }
    }
  }
  .closed {
    text-transform: uppercase;
    color: #ff7121;
    font-size: 17px;
  }
  .text {
    font-size: ${(props) => (props.status === 'close' ? '12px' : '16px')};
    padding: 0rem 1rem;
    margin-bottom: 0.3rem;
    color: ${(props) => props.status === 'locked' && '#FF7324'};
    font-weight: ${(props) => props.status === 'locked' && '600'};
    text-align: ${(props) => props.status === 'locked' && 'center'};
    line-height: ${(props) => props.status === 'locked' && '1.2rem'};
  }
  .btn-message {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
  }
`;
