import styled from 'styled-components';

export const BannerContainer = styled.div`
  max-width: 1085px;
  overflow: hidden;
  margin-top: 90px;
  position: relative;
  height: 121px;
  margin-bottom: 20px;
  background: ${({ image }) => ` url(${image}) left no-repeat;`};
`;

export const DescriptionDisplay = styled.p`
  font-size: 16px;
  font-family: Arial;
  color: #fff;
  position: absolute;
  width: 400px;
  top: 10px;
  left: 510px;
`;

export const ButtonDisplay = styled.button`
  color: #113b51;
  width: 200px;
  height: 30px;
  border-radius: 100px;
  background: #ffe400;
  border: none;
  font-size: 13px;
  position: absolute;
  top: 82px;
  left: 700px;
`;

export const DateDisplay = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-family: Arial;
  color: #fff;
  position: absolute;
  top: 100px;
  left: 170px;
`;
