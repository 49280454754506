import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { ColZero, StyledBvGCategory } from './BvGCategory.styles';

const BvGCategory = ({
  dropdownItems,
  seasons,
  changeSeason,
  isCompetitionActive,
  bannerMessage,
}) => {
  const { t } = useTranslation();

  return (
    <Row>
      <ColZero>
        <StyledBvGCategory>
          {dropdownItems && !isCompetitionActive && (
            <span className="bvg-closed">
              {bannerMessage &&
                t('leaderboard:boysvsgirls.challengePoints', bannerMessage)}
            </span>
          )}
          <div className="curriculum-date">
            <DropdownMenu
              selectedValue={dropdownItems && dropdownItems.name}
              valueKey="name"
              values={seasons}
              setValue={changeSeason}
              width="auto"
              className="drop-down"
            />
          </div>
        </StyledBvGCategory>
      </ColZero>
    </Row>
  );
};

export default BvGCategory;
