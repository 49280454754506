import styled from 'styled-components';

export const PageNav = styled.nav`
  .pagination {
    justify-content: center;
  }

  .page-item {
    margin: 0 0.5em;
    &.active .page-link,
    &.active .page-link:focus,
    &.active .page-link:hover {
      background-color: #ff6243;
      color: #ffffff;
    }
    .page-link {
      color: #1e1e1e;
      border-color: #d5d5d5;
      &:focus,
      &:hover {
        box-shadow: none;
        color: #ff5b00;
        background-color: #ffffff;
      }
      &:disabled {
        background-color: #909090;
        color: #ffffff;
      }
    }
  }

  .page-nav-search {
    align-self: center;
    margin-left: 1.5em;
    color: #1e1e1e;
    input {
      width: 2em;
      margin: 0 0.25em;
      text-align: center;
    }

    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      display: none;
    }
  }
`;
