import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from 'assets/img/assignment/icon-refresh.svg';
import { Container } from './RefreshButton.styles';

const RefreshButton = ({ onClick, style }) => {
  const { t } = useTranslation(['shared']);
  return (
    <Container style={style}>
      <button onClick={onClick}>
        <RefreshIcon />
        <p>{t('refreshBtn', 'Refresh')}</p>
      </button>
    </Container>
  );
};

export default RefreshButton;
