import styled from 'styled-components';

export const PhoneNumberEdit = styled.div`
  display: flex;
  input {
    border: 1px solid #d0d0d0;
    border-radius: 0px;
    outline: none;
    text-indent: 5px;
  }
  .country-code {
    width: 55px;
    margin-left: 0.5rem;
    border-right: none;
    font-size: 14px;
    color: rgb(47, 47, 47, 0.22);
    text-indent: 0px;
    text-align: center;
  }
  .number {
    width: 100%;
    font-size: 14px;
  }
`;
