import styled from 'styled-components';
import { MAX_WIDTH } from './constants';
export const StyledContainer = styled.div`
  max-width: ${MAX_WIDTH + 32}px;
  width: 100%;
  margin: 27px auto 0;
  padding: 0 16px;
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    span {
      color: #626262;
    }
    p {
      font-size: 26px;
      line-height: 1;
      margin: 0 10px 0 0;
    }
  }
  .right {
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    p {
      width: 50%;
      margin: 0;
      white-space: nowrap;
      padding: 8px 12px;
      font-size: 11px;
      display: flex;
      align-items: center;
      color: #7e7e7e;
      span {
        font-size: 21px;
        display: block;
        margin-left: 12px;
      }
    }
    .earned {
      border-right: 1px solid #e0e0e0;
      span {
        color: #3fb551;
      }
    }
    .spent {
      span {
        color: #f85656;
      }
    }
  }
`;
export const StyledTable = styled.table`
  margin-top: 26px;
  margin-bottom: 60px;
  width: 100%;
  th {
    color: #b4b4b4;
    font-weight: normal;
    padding-bottom: 8px;
  }
  tr {
    border-bottom: 1px solid #efefef;
  }
  td {
    padding: 19px 0;
  }
  td,
  th {
    &:first-child,
    &:nth-child(2) {
      width: 140px;
    }
  }
  .earned,
  .spent,
  .remaining {
    text-align: center;
  }
  td.spent {
    color: #f85656;
  }
  td.earned {
    color: #3fb551;
  }
`;
export const StyledNoDataWrap = styled.div`
  margin-top: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin-left: 40px;
    max-width: 268px;
  }
  p {
    font-size: 18px;
  }
  span {
    font-size: 14px;
    color: #656565;
  }
`;
