import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'react-bootstrap';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';

import showPosition from 'components/Leaderboard/showPosition';

import { Ranking } from './CurrentUserScore.styles';

const CurrentUserScore = ({ userGender, currentUserScore }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col
        style={{
          marginTop: 20,
          marginBottom: 20,
          backgroundColor: userGender === 'Female' ? '#FFF0F5' : '#c6efff',
        }}
      >
        <h4
          style={{
            textAlign: 'center',
            marginTop: 10,
            marginBottom: 10,
            fontWeight: 600,
            color: userGender === 'Female' ? '#FF80A4' : '#0AB6DC',
          }}
        >
          {userGender === 'Female'
            ? t(
                'leaderboard:boysVsGirls.ranking.girlRank',
                'Your Rank in Girls Team'
              )
            : t(
                'leaderboard:boysVsGirls.ranking.boyRank',
                'Your Rank in Boys Team'
              )}
        </h4>
        {currentUserScore && (
          <div
            style={{
              backgroundColor: '#FAFEFF',
              marginTop: 10,
              marginBottom: 20,
              padding: 10,
              borderRadius: 20,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1, color: '#0AB6DC' }}>
                <div
                  className="ranking"
                  style={{ marginTop: 15, marginLeft: -25 }}
                >
                  <Ranking
                    userGender={userGender}
                    currPos={showPosition(currentUserScore.rank)}
                  >
                    {currentUserScore.rank !== -1 ? currentUserScore.rank : '-'}
                  </Ranking>
                </div>
              </div>
              <div style={{ flex: 7 }}>{currentUserScore.name}</div>
              <div style={{ flex: 2 }}>
                <img src={CPIcon} alt="cp-icon" />
                {currentUserScore.cP}
              </div>
              <div style={{ flex: 2 }}>
                {currentUserScore.isSuperHero && <SuperheroIconModal />}
              </div>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default CurrentUserScore;
