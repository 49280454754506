import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import BackButton from 'components/Shared/BackButton';
import Button from 'components/Shared/Button';
import CurrentDate from 'components/Leaderboard/HOD/CurrentDate';
import Category from 'components/Leaderboard/Category';
import HallOfFameSection from 'components/Leaderboard/HOD/HallOfFameSection';
import StudentList from 'components/Leaderboard/StudentList';
import ChallengeType from 'components/Leaderboard/ChallengeType';
import Spinner from 'components/Shared/Spinner';
import {
  getLeaderBoardData,
  getTopFiftyStudentsList,
} from 'store/leaderboard/leaderboardSlice';
import { isEmpty } from 'ramda';
import HODTitle from 'assets/img/leaderboard/hod/science/hero-of-the-day-title.png';
import useDashboard from 'hooks/useDashboard';
import {
  Container,
  ButtonContainer,
  Topbar,
  NoParticipant,
} from './HeroOfTheDay.styles';

const subject = 2;

const ScienceHeroOfTheDay = () => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { userID } = useSelector((state) => state.login);
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const { isLoading, leaderBoardData } = useSelector(
    (state) => state.leaderboard
  );
  const { isDashboardv2 } = useDashboard();
  const [selectedLevel, setSelectedlevel] = useState(defaultLevel);
  const [activeCategory, setActiveCategory] = useState(3);
  const [studentData, setStudentData] = useState([]);
  const [isTopFifty, setIsTopFifty] = useState(false);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (defaultLevel === null) {
      setSelectedlevel(1);
    }
  }, [defaultLevel]);

  useEffect(() => {
    dispatch(
      getLeaderBoardData({
        enumHeroFilter: 3,
        level: defaultLevel,
        subject,
      })
    );
  }, [defaultLevel, dispatch]);

  useEffect(() => {
    if (!isEmpty(leaderBoardData)) {
      const isUser = leaderBoardData.find((stu) => stu.UserId === userID);
      if (isUser) {
        setIsTopFifty(isUser.LeaderBoardRank <= 50);
      } else {
        setIsTopFifty(true);
      }
      setStudentData(leaderBoardData);
    } else {
      setStudentData([]);
    }
  }, [leaderBoardData, userID]);

  const getStudents = (enumHeroFilter, level) => {
    setIsTopFifty(false);
    dispatch(getLeaderBoardData({ enumHeroFilter, level, subject }));
  };

  const getTopFiftyStudents = () => {
    setIsTopFifty(false);
    dispatch(
      getTopFiftyStudentsList({
        enumHeroFilter: activeCategory,
        level: selectedLevel,
        selectedDate: moment().format('YYYY-MM-DD'),
        subject,
      })
    );
  };
  return (
    <Container>
      <Topbar>
        <div className="btn">
          <BackButton
            backText={t('leaderboard:buttons.back', 'Back')}
            btnBgColor="rgb(22, 137, 93, 0.61)"
            padding="0.3rem 1.2rem"
            onClick={() => history.push('/leaderboard?subject=science')}
            styles={{ marginTop: '1rem' }}
          />
        </div>
        <div className="middle-content">
          <img src={HODTitle} width="450px" alt="hod-title" />
          <div className="hod-desc">
            <CurrentDate subject={subject} />
            <div className="desc">
              <p>
                <Trans i18nKey="leaderboard:hodBanner.hodDesc1">
                  <Link
                    to={isDashboardv2 ? '/challenges/science' : '/challenges'}
                  >
                    Daily Challenge
                  </Link>
                  <Link
                    to={
                      isDashboardv2
                        ? '/multiplayer/peer-challenge/new-challenge?subject=Science'
                        : '/multiplayer'
                    }
                  >
                    Peer Challenge
                  </Link>
                </Trans>
              </p>
              <p>
                {t(
                  'leaderboard:hodBanner.hodDesc2',
                  'to start earning some CPs!'
                )}
              </p>
            </div>
          </div>
        </div>
        <ChallengeType
          subject={subject}
          styles={{ top: '12%', right: '13%' }}
        />
      </Topbar>
      <Category
        showTopFifty
        challengeMode="heroOfTheDay"
        subject={subject}
        getLists={getStudents}
        getTopFiftyLists={getTopFiftyStudents}
        active={activeCategory}
        setActive={setActiveCategory}
        selectedLevel={selectedLevel}
        setSelectedlevel={setSelectedlevel}
        setExpand={setExpand}
      />
      {isLoading && <Spinner />}
      {studentData.length > 0 && (
        <StudentList
          challengeMode="heroOfTheDay"
          subject={subject}
          reducer="leaderboard"
          data={studentData}
          isTopFifty={isTopFifty}
          active={activeCategory}
        />
      )}
      {!isLoading && studentData.length === 0 && (
        <NoParticipant>
          {t(
            'leaderboard:hodBanner.noParticipant',
            'No participants at the moment.'
          )}
        </NoParticipant>
      )}
      <ButtonContainer>
        <div className="button-center">
          <Button
            dataCy="hero-button"
            variant="yellow"
            fontWeight="600"
            fontSize="18px"
            width="212px"
            onClick={() => setExpand(!expand)}
          >
            {t('leaderboard:buttons.hallOfFame', `Hall of Fame`)}
          </Button>
        </div>
      </ButtonContainer>
      {expand && <HallOfFameSection subject={subject} />}
    </Container>
  );
};

export default ScienceHeroOfTheDay;
