import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-right: 0.5rem;
`;

export const Circle = styled.span`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${(props) => (props.isActive ? '#f88f06' : '#E6E5E1')};
`;
