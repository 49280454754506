import React, { useState } from 'react';
import ProficiencyProgress from 'components/Shared/ProficiencyProgress';
import QnNav from 'components/Assignment/AssignmentView/QnSideBar/QnNav';
import QnEnd from 'components/Assignment/AssignmentView/QnSideBar/QnEnd';
import {
  saveKooClassAnswer,
  setKooClassActiveQuestion,
} from 'store/kooClass/kooClassSlice';
import { checkAnswer } from 'store/assignment/assignmentSlice';
import { isNil } from 'ramda';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  submissionTypeIDs,
  SubmissionResultTypes,
  questionTypeIDs,
} from 'constants/index';
import {
  // countCorrectWrongSubmissions,
  countCorrectWrongSubmissionsKooClass,
} from 'helpers/countCorrectWrongSubmissions';
import newSaveAnswerHandler from 'helpers/saveAnswerHandler';
import { setLastSavedTime } from 'store/timer/timerSlice';
import { getWorkings } from 'pages/Assignment/AssignmentView';
import { clearAll } from 'store/assignment/barModelSlice';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';
import { Container } from 'components/Assignment/AssignmentView/QnSideBar.styles';

// HELPER
const checkIfSubmissionIsCorrect = (submissionModel) => {
  if (submissionModel === null) {
    return null;
  }
  if (SubmissionResultTypes[submissionModel.submissionResult] === 'correct') {
    return true;
  }
  if (SubmissionResultTypes[submissionModel.submissionResult] === 'wrong') {
    return false;
  }
  return null;
};

const checkIsQuestionAnswered = (
  localSavedAnswer,
  submission,
  questionType,
  assignmentType
) => {
  let joinedValues;
  if (localSavedAnswer !== null) {
    if (questionType !== 'mcq') {
      // Check if answer is blank
      joinedValues = Object.values(localSavedAnswer)
        .map((value) => value.trim())
        .join('');
    } else {
      joinedValues = localSavedAnswer.map((value) => value.trim()).join('');
    }
    return joinedValues.length > 0;
  }
  if (submission !== null) {
    let subName = 'SubmissionKeyValuePairs';
    let valName = 'Value';
    // let keyName = 'Key';
    if (assignmentType === 'KooClass') {
      subName = 'submissionKeyValuePairs';
      valName = 'value';
      // keyName = 'key';
    }
    if (questionType !== 'mcq') {
      // Check if answer is blank
      joinedValues = submission[subName]
        .map((keyValuePair) =>
          isNil(keyValuePair[valName]) ? '' : keyValuePair[valName].trim()
        )
        .join('');
    } else {
      joinedValues = submission[subName]
        .map((keyValuePair) =>
          isNil(keyValuePair[valName]) ? '' : keyValuePair[valName].trim()
        )
        .join('');
    }
    return joinedValues.length > 0;
  }
  return false;
};

const QnSideBar = ({
  assignmentType,
  questions,
  activeQuestion,
  localSavedAnswers,
  page,
  scrollToHandler,
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  // Redux states
  const {
    kooClassSubmissionID,
    kooClassQuestionAnswers,
    kooClassSubmissions,
    kooClassSavedWorkings,
  } = useSelector((state) => state.kooClass);
  let submissionID;
  let qsAnswers;
  let allSubmissions;
  let localSavWorkings;
  let setActive = null;
  if (assignmentType === 'KooClass') {
    submissionID = kooClassSubmissionID;
    qsAnswers = kooClassQuestionAnswers;
    allSubmissions = kooClassSubmissions;
    localSavWorkings = kooClassSavedWorkings;
    setActive = setKooClassActiveQuestion;
  }
  const { userID } = useSelector((state) => state.login);
  const { data: barModelData } = useSelector((state) => state.barModel);
  const submissionType = submissionTypeIDs.Homework;
  // state for collapse sidebar
  const [collapse, setCollapse] = useState(false);

  // EVENT HANDLERS
  const dispatchSaveAction = (params) => {
    let saveAns = null;
    if (assignmentType === 'KooClass') {
      saveAns = saveKooClassAnswer;
    }
    dispatch(saveAns(params));
  };
  const dispatchCheckAnswerAction = (params) => {
    dispatch(checkAnswer(params));
  };

  const isCorrect = (index, item) => {
    if (page !== 'solution') {
      return checkIfSubmissionIsCorrect(allSubmissions[index].submissionModel);
    }
    if (item.submissionModel === null) return false;
    return (
      SubmissionResultTypes[item.submissionModel.submissionResult] === 'correct'
    );
  };

  return (
    <Container collapse={collapse} page={page}>
      {page !== 'solution' && (
        <ProficiencyProgress
          totalQns={questions.length}
          proficiency={countCorrectWrongSubmissionsKooClass(
            allSubmissions,
            page
          )}
          collapse={collapse}
          setCollapse={setCollapse}
        />
      )}
      {page === 'solution' && (
        <ProficiencyProgress
          totalQns={questions.length}
          proficiency={countCorrectWrongSubmissionsKooClass(questions, page)}
          collapse={collapse}
          setCollapse={setCollapse}
        />
      )}
      <div style={{ overflowY: 'auto' }}>
        {questions.map((item, index) => (
          <QnNav
            key={item.question.id}
            collapse={collapse}
            number={index + 1}
            correct={isCorrect(index, item)}
            qnAnswered={
              page === 'solution'
                ? true
                : checkIsQuestionAnswered(
                    localSavedAnswers[index],
                    allSubmissions[index].submissionModel,
                    questionTypeIDs[item.questionType],
                    assignmentType
                  )
            }
            active={index + 1 === activeQuestion}
            clickHandler={() => {
              if (page === 'solution') {
                scrollToHandler(item.question.id);
              } else {
                const now = Date.now();
                newSaveAnswerHandler({
                  assignmentSubmissionID: submissionID,
                  assignmentType,
                  questionType:
                    questionTypeIDs[questions[activeQuestion - 1].QuestionType],
                  submissionType,
                  questionAnswer: qsAnswers[activeQuestion - 1],
                  saveAnswerAction: dispatchSaveAction,
                  checkAnswerAction: dispatchCheckAnswerAction,
                  activeQnNo: activeQuestion,
                  currentQn: questions[activeQuestion - 1],
                  currentLocalSavedAnswer:
                    localSavedAnswers[activeQuestion - 1],
                  currentSubmission:
                    allSubmissions[activeQuestion - 1].SubmissionModel,
                  timeElapsed: now - store.getState().timer.lastSavedTime,
                  userID,
                  workings: getWorkings(
                    allSubmissions[activeQuestion - 1],
                    localSavWorkings[activeQuestion - 1]
                  ),
                  barModel:
                    barModelData.objects.length > 0 ? barModelData : null,
                });
                dispatch(setLastSavedTime(now));
                dispatch(hideAllTools());
                clearValues();
                dispatch(clearAll());
              }
              dispatch(setActive(index + 1));
            }}
          />
        ))}
        <QnEnd />
      </div>
    </Container>
  );
};

export default QnSideBar;
