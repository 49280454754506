import styled from 'styled-components';
import BookClubBg from 'assets/img/bookclub/bookclub_bg.png';

export const Page = styled.div`
  overflow: hidden;
  font-family: Arial;
`;

export const Content = styled.div`
  width: 100%;
  background-image: url(${BookClubBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 108px;
  margin-top: 59px;
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 167px);
`;

export const ControlPanelWrap = styled.div`
  width: ${(props) => `${props.width}px` ?? '25%'};
  height: ${(props) => `${props.height}px` ?? '100%'};
`;
export const MainPanelWrap = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  width: ${(props) => `${props.width}px` ?? '75%'};
  height: ${(props) => `${props.height}px` ?? '100%'};
`;
export const BookClubIntruction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 40px;
  color: #383838;
  font-size: 20px;
`;
export const BookclubD3 = styled.div`
  width: 100%;
  height: ${(props) => `${props.height}px` ?? '100%'};
`;
export const BookClubLegend = styled.div`
  text-align: left;
  height: 50px;
  margin-left: 30px;
`;
