import React, { useState, useRef, useEffect } from 'react';
import ageGroups, { childBrainImages } from 'constants/ageGroups';
import { OverlayTrigger } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import useClickOutside from 'hooks/useClickOutside';
import HotsTopics, { CategorizedSkills } from 'constants/hotsV2Skills';
import useDefaultAgeGroup from 'hooks/useDefaultAgeGroup';
import { ReactComponent as ToggleArrowIcon } from 'assets/img/plan-toggle/arrow.svg';
import { isNil } from 'ramda';
import useIsMobile from 'hooks/responsive/useIsMobile';
import {
  DropdownContainer,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  StyledChartContainer,
  StyledQuadrant,
  DropdownButtonContainer,
  GridChart,
} from './Hotsv2TopicsChart.styles';
import Hotsv2TopicsButton from './Hotsv2TopicButton';

const AgeGroupDropdown = ({
  selectedAgeGroup,
  setSelectedAgeGroup,
  children,
}) => {
  const isMobile = useIsMobile();
  const [showMenu, setShowMenu] = useState(false);
  const [isHoverOverFirstItem, setIsHoverOverFirstItem] = useState(false);
  const clickRef = useRef();
  useClickOutside(clickRef, () => {
    setShowMenu(false);
  });
  const changeAgeGroupHandler = (newAgeGroup) => {
    return () => {
      setShowMenu(false);
      setSelectedAgeGroup(newAgeGroup);
    };
  };
  return (
    <DropdownContainer>
      {children}
      <OverlayTrigger
        trigger="click"
        placement={isMobile ? 'bottom-end' : 'bottom'}
        show={showMenu}
        overlay={
          <DropdownMenu isHoverOverFirstItem={isHoverOverFirstItem}>
            {ageGroups.map((ageGroup, index) => (
              <DropdownItem
                key={ageGroup.id}
                index={index}
                ref={clickRef}
                totalItems={ageGroups.length}
                onClick={changeAgeGroupHandler(ageGroup)}
                onMouseEnter={() => {
                  if (index === 0) setIsHoverOverFirstItem(true);
                }}
                onMouseLeave={() => {
                  if (index === 0) setIsHoverOverFirstItem(false);
                }}
              >{`Age ${ageGroup.minAge} - ${ageGroup.maxAge}`}</DropdownItem>
            ))}
          </DropdownMenu>
        }
      >
        <DropdownButtonContainer>
          <p className="title">Recommended Age</p>
          <DropdownButton
            onClick={() => {
              setShowMenu(true);
            }}
          >
            <p className="title">Recommended Age</p>
            <p className="age-text">{`${selectedAgeGroup?.minAge ?? ''} - ${
              selectedAgeGroup?.maxAge ?? ''
            }`}</p>
            <ToggleArrowIcon width="8px" height="6px" className="icon" />
          </DropdownButton>
        </DropdownButtonContainer>
      </OverlayTrigger>
    </DropdownContainer>
  );
};

const Hotsv2TopicsChart = () => {
  const { ageGroupIndex } = useDefaultAgeGroup();
  const [selectedAgeGroup, setSelectedAgeGroup] = useState(null);
  const isMobile = useIsMobile();
  const { subject } = useLocation()?.state;

  useEffect(() => {
    if (selectedAgeGroup === null && !isNil(ageGroupIndex)) {
      setSelectedAgeGroup(ageGroups[ageGroupIndex]);
    }
  }, [ageGroupIndex, selectedAgeGroup]);
  return (
    <StyledChartContainer>
      <AgeGroupDropdown
        selectedAgeGroup={selectedAgeGroup}
        setSelectedAgeGroup={setSelectedAgeGroup}
      >
        <img src={childBrainImages[selectedAgeGroup?.id]} alt="" />
      </AgeGroupDropdown>
      <GridChart>
        <StyledQuadrant categoryStyles={CategorizedSkills[0].styles}>
          <p className="category">{CategorizedSkills[0].category}</p>
          {selectedAgeGroup &&
            HotsTopics[selectedAgeGroup.id][0].skills.map((topic) => (
              <Hotsv2TopicsButton
                key={topic.id}
                iconPosition={isMobile ? 'left' : 'right'}
                topicName={topic.name}
                topicIcon={topic.icon}
                positionStyles={topic.position}
                id={topic.id}
                ageIndex={selectedAgeGroup?.id - 1}
                subject={subject}
              />
            ))}
        </StyledQuadrant>
        <StyledQuadrant categoryStyles={CategorizedSkills[1].styles}>
          <p className="category">{CategorizedSkills[1].category}</p>
          {selectedAgeGroup &&
            HotsTopics[selectedAgeGroup.id][1].skills.map((topic) => (
              <Hotsv2TopicsButton
                key={topic.id}
                iconPosition="left"
                topicName={topic.name}
                topicIcon={topic.icon}
                positionStyles={topic.position}
                id={topic.id}
                ageIndex={selectedAgeGroup?.id - 1}
                subject={subject}
              />
            ))}
        </StyledQuadrant>
        <StyledQuadrant categoryStyles={CategorizedSkills[2].styles}>
          <p className="category">{CategorizedSkills[2].category}</p>
          {selectedAgeGroup &&
            HotsTopics[selectedAgeGroup.id][2].skills.map((topic) => (
              <Hotsv2TopicsButton
                key={topic.id}
                iconPosition={isMobile ? 'left' : 'right'}
                topicName={topic.name}
                topicIcon={topic.icon}
                positionStyles={topic.position}
                id={topic.id}
                ageIndex={selectedAgeGroup?.id - 1}
                subject={subject}
              />
            ))}
        </StyledQuadrant>
        <StyledQuadrant categoryStyles={CategorizedSkills[3].styles}>
          <p className="category">{CategorizedSkills[3].category}</p>
          {selectedAgeGroup &&
            HotsTopics[selectedAgeGroup.id][3].skills.map((topic) => (
              <Hotsv2TopicsButton
                key={topic.id}
                iconPosition="left"
                topicName={topic.name}
                topicIcon={topic.icon}
                positionStyles={topic.position}
                id={topic.id}
                ageIndex={selectedAgeGroup?.id - 1}
                subject={subject}
              />
            ))}
        </StyledQuadrant>
      </GridChart>
    </StyledChartContainer>
  );
};

export default Hotsv2TopicsChart;
