import React from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { stageIcons, stageStatus } from 'constants/missionPlus';

import { ProgressBar } from 'react-bootstrap';

import Lock from 'assets/img/mission/missionplus/icon-lock.svg';
import StageIcon from 'assets/img/mission/missionplus/icon-stage.svg';

import { Container } from './StageCard.styles';

const StageCard = ({
  details,
  unlockHandler,
  titles,
  order,
  stageIndex,
  topicSkillId,
  curriculumId,
  subject,
}) => {
  const { t, i18n } = useTranslation(['common']);
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: `/mission/self-practice/plus/${topicSkillId}/question-view/${details.StageTypeId}`,
      state: { curriculumId, subject },
    });
  };

  return (
    <Container
      medal={details.GoldMedal}
      status={stageStatus[details.StageStatus]}
      titles={titles}
      order={order}
      stageIndex={stageIndex}
      i18n={i18n?.language}
      data-cy={`mission-plus-${order}-${stageIndex}`}
    >
      {order === 1 && (
        <span className="title">
          {titles[stageIndex]}
          {stageIndex !== 2 && <img src={StageIcon} alt="" />}
        </span>
      )}
      <div className="bg" />
      <div
        className="content"
        role="button"
        tabIndex={details.StageTypeId}
        onKeyDown={handleClick}
        onClick={handleClick}
      >
        <img
          src={
            details.GoldMedal
              ? stageIcons[details.StageTypeId].iconGold
              : stageIcons[details.StageTypeId].icon
          }
          alt=""
          data-cy={`stage-icon-${details.StageTypeId}-${
            details.GoldMedal ? 'gold' : 'lock'
          }`}
        />
        {stageStatus[details.StageStatus].name !== 'not-start' && (
          <div>
            {stageStatus[details.StageStatus].name ===
              'progress-bar-continue' && (
              <ProgressBar now={details.Progress * 10} />
            )}
            <button
              type="button"
              className={stageStatus[details.StageStatus].className}
            >
              {t(
                stageStatus[details.StageStatus].i18nKey,
                stageStatus[details.StageStatus].label
              )}
            </button>
          </div>
        )}
      </div>
      {stageStatus[details.StageStatus].name === 'not-start' && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className="overlay"
          onClick={() => unlockHandler(details, { topicSkillId })}
        >
          <img src={Lock} alt="lock" />
        </div>
      )}
    </Container>
  );
};

export default StageCard;
