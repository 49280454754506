import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

import {
  requestResetPasswordEmail,
  clearResetPassword,
} from 'store/profileSettings/profileSettingsSlice';

import emailMasking from 'helpers/emailMasking';

import Button from 'components/Shared/Button';
import { ReactComponent as Check } from 'assets/img/profile-settings/icon-check.svg';

import { Container, Title, Content, Action } from './ResetPasswordModal.styles';

const ResetPasswordModal = ({ closeModal }) => {
  const { t } = useTranslation(['mySubs', 'common']);
  const dispatch = useDispatch();
  const { loading, status, err } = useSelector(
    (state) => state.profileSettings.resetPassword
  );
  const { parentInfo } = useSelector((state) => state.profileSettings);

  const yesHandler = () => {
    dispatch(requestResetPasswordEmail(parentInfo.EmailAddress));
  };

  useEffect(() => {
    return () => dispatch(clearResetPassword());
  }, [dispatch]);

  return (
    <Container>
      <Title>
        {t('mySubs:resetModal.title', 'Reset Parent Account Password')}
      </Title>
      <Content>
        {status ? (
          <p>
            <Check className="check-icon" />
            {t('mySubs:resetModal.desc1', 'Your reset link was sent to:')}
          </p>
        ) : (
          <p>
            {t(
              'mySubs:resetModal.desc2',
              'We will send a reset link to your registered email.'
            )}
          </p>
        )}
        <p className="email">{emailMasking(parentInfo?.EmailAddress)}</p>
      </Content>
      <Action>
        {!isNil(err) && (
          <p className="err-msg">
            {err === 'exceeded max try'
              ? t(
                  'mySubs:resetModal.err1',
                  'You have exceeded the maximum number of attempts to reset password. Please try again after 2 hours.'
                )
              : err}
          </p>
        )}
        {status ? (
          <p>
            {t(
              'mySubs:resetModal.status1',
              'If you don’t see the email in your inbox, check your spam folder.'
            )}
          </p>
        ) : (
          isNil(err) && (
            <>
              <p> {t('mySubs:resetModal.action', 'Do you wish to proceed?')}</p>
              <div className="buttons">
                <Button
                  variant="secondary3"
                  padding="0.5rem 4.125rem"
                  isDisabled={loading}
                  onClick={closeModal}
                >
                  {t('common:no', 'No')}
                </Button>
                <Button
                  variant="orange4"
                  padding="0.5rem 4.125rem"
                  isDisabled={loading || isNil(parentInfo?.EmailAddress)}
                  onClick={yesHandler}
                >
                  {t('common:yes', 'Yes')}
                </Button>
              </div>
            </>
          )
        )}
      </Action>
    </Container>
  );
};

export default ResetPasswordModal;
