/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import AccessMenuV2 from 'components/Dashboard/AccessMenuV2';
import PopupModal from 'components/Shared/PopupModal';
import SideNav from 'components/Shared/SideNav';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'hooks/responsive/useIsMobile';
import Container from './QuickAccessV2.styles';
import RestrictedFeatureModal from './RestrictedFeatureModal';

const QuickAccess = ({ profileOnly, Toggle }) => {
  const isMobile = useIsMobile();

  const [openMenu, setOpenMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupErrCode, setPopupErrCode] = useState(null);
  const [featureName, setFeatureName] = useState('');

  // Preload i18n translation before menu is opened to prevent ui flashing
  useTranslation(['shortcutMenu', 'common']);

  return (
    <>
      <PopupModal show={showPopup} backdrop="static">
        <RestrictedFeatureModal
          setShowPopup={setShowPopup}
          setPopupErrCode={setPopupErrCode}
          errCode={popupErrCode}
          featureName={featureName}
        />
      </PopupModal>
      {!isMobile && (
        <Container open={openMenu} data-cy="quick-menu">
          {Toggle ? (
            <Toggle onClick={() => setOpenMenu((prev) => !prev)} />
          ) : (
            <div
              className="bg"
              data-cy="quick-menu-icon"
              onClick={() => setOpenMenu((prev) => !prev)}
            />
          )}
          <SideNav show={openMenu}>
            <AccessMenuV2
              setOpenMenu={setOpenMenu}
              setShowPopup={setShowPopup}
              setPopupErrCode={setPopupErrCode}
              setFeatureName={setFeatureName}
              profileOnly={profileOnly}
            />
          </SideNav>
        </Container>
      )}
    </>
  );
};

export default QuickAccess;
