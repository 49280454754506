import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchDailyChallengeStatus,
  fetchSuperHeroChallengeStatus,
  updateDifficultyChallenge,
  fetchTopics,
  updateQuestion,
  submitChallengeAnswer,
  fetchDailyChallengeStatusBySubjectID,
  fetchDailyChallengeDetailsBySubjectID,
  fetchClaimKoko,
  updateDifficultyChallengeForB2C,
} from 'services/dailyChallenge';
import { fetchSuperHeroChallengeDataWithoutImage } from 'services/superhero';
import { fetchWrapper } from 'services/login';
import { fetchVideoBySkillId } from 'services/lookup';
import { compareValues } from 'helpers/compareValue';
import xmlParser from 'helpers/xmlParser';
import { getRewards } from 'store/dashboard/rewardsSlice';
import { isNil } from 'ramda';

// Get daily challenge status
export const getDailyChallengeStatus = createAsyncThunk(
  'dailyChallenge/getDailyChallengeStatus',
  async () => {
    try {
      const res = await fetchWrapper(fetchDailyChallengeStatus);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get daily challenge status failed');
    }
  }
);

// Get super hero challenge status
export const getSuperHeroChallengeStatus = createAsyncThunk(
  'dailyChallenge/getSuperHeroChallengeStatus',
  async () => {
    try {
      const res = await fetchWrapper(fetchSuperHeroChallengeStatus);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get super hero challenge status failed'
      );
    }
  }
);

// Get super hero challenge data without image
export const getSuperHeroChallengeDataWithoutImage = createAsyncThunk(
  'dailyChallenge/getSuperHeroChallengeDataWithoutImage',
  async () => {
    try {
      const res = await fetchWrapper(fetchSuperHeroChallengeDataWithoutImage);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get Super Hero Challenge data without image failed'
      );
    }
  }
);

// Get current daily challenge
export const getCurrentDailyChallenge = createAsyncThunk(
  'dailyChallenge/getCurrentDailyChallenge',
  async () => {
    try {
      const res = await fetchWrapper(fetchDailyChallengeDetailsBySubjectID, 1);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get current daily challenge failed');
    }
  }
);

// Update Daily Challenge difficulty level
export const changeDifficulty = createAsyncThunk(
  'dailyChallenge/changeDifficulty',
  async (diffLevel) => {
    try {
      await fetchWrapper(updateDifficultyChallenge, diffLevel);
      await fetchWrapper(fetchDailyChallengeStatus);
      const res = await fetchWrapper(fetchDailyChallengeDetailsBySubjectID, 1);
      return res;
    } catch (err) {
      throw new Error(err?.message ?? 'Update difficulty challenge failed');
    }
  }
);

export const updateDailyChallengeLevelB2C = createAsyncThunk(
  'dailyChallenge/UpdateDailyChallengeLevelB2C',
  async (diffLevel) => {
    try {
      await fetchWrapper(updateDifficultyChallengeForB2C, diffLevel);
      await fetchWrapper(fetchDailyChallengeStatus);
      const res = await fetchWrapper(fetchDailyChallengeDetailsBySubjectID, 1);
      return res;
    } catch (err) {
      throw new Error(err?.message ?? 'Update difficulty challenge failed');
    }
  }
);

export const firstTimeUpdateDifficulty = createAsyncThunk(
  'dailyChallenge/firstTimeUpdateDifficulty',
  async (diffLevel) => {
    try {
      await fetchWrapper(updateDifficultyChallenge, diffLevel);
      await fetchWrapper(fetchDailyChallengeStatus);
      const res3 = await fetchWrapper(fetchDailyChallengeDetailsBySubjectID, 1);
      return res3;
    } catch (err) {
      throw new Error(err?.message ?? 'First time update difficulty failed');
    }
  }
);

// Get topics based on student level
export const getStudentTopics = createAsyncThunk(
  'dailyChallenge/getStudentTopics',
  async () => {
    try {
      const res = await fetchWrapper(fetchTopics);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get topics based on student failed');
    }
  }
);

// Change question
export const changeQuestion = createAsyncThunk(
  'dailyChallenge/changeQuestion',
  async (params) => {
    try {
      const res = await fetchWrapper(updateQuestion, params);
      return res;
    } catch (err) {
      throw new Error(err?.message ?? 'Change question failed');
    }
  }
);

// Submit answer
export const submitDailyChallengeAnswer = createAsyncThunk(
  'dailyChallenge/submitAnswer',
  async (params, { dispatch, getState }) => {
    const { subjectID } = params.rawBody;
    try {
      await fetchWrapper(submitChallengeAnswer, params);
      const updatedDCDetails = await fetchWrapper(
        fetchDailyChallengeDetailsBySubjectID,
        subjectID
      );
      const { dailyChallenge: dailyChallengeState } = getState();

      // to check if current answer is wrong
      const currentAnsIsWrong =
        updatedDCDetails?.UserQuestionSubmissions?.find(
          (item) =>
            item.DisplayOrder === dailyChallengeState.activeQsDailyChallenge
        ).SubmissionModel.SubmissionResult === 2;

      if (
        dailyChallengeState?.prevIsKokoClaimed !== true &&
        updatedDCDetails?.IsKokoClaimed === true
      ) {
        dispatch(getRewards());
      }

      // to call video endpoint only if it's science subject & wrong answer
      if (subjectID === 2 && currentAnsIsWrong) {
        const currQnSkillId = updatedDCDetails?.UserQuestionSubmissions?.find(
          (item) =>
            item.DisplayOrder === dailyChallengeState.activeQsDailyChallenge
        )?.SubmissionModel?.SkillId;
        const video = await fetchWrapper(fetchVideoBySkillId, currQnSkillId);
        updatedDCDetails.UserQuestionSubmissions[
          dailyChallengeState.activeQsDailyChallenge - 1
        ].VideoCode = video;

        return updatedDCDetails;
      }

      return updatedDCDetails;
    } catch (err) {
      throw new Error(err?.message ?? 'Submit answer failed');
    }
  }
);

export const getDailyChallengeStatusBySubjectID = createAsyncThunk(
  'dailyChallenge/getDailyChallengeStatus',
  async (subjectID) => {
    try {
      const res = await fetchWrapper(
        fetchDailyChallengeStatusBySubjectID,
        subjectID
      );
      return {
        ...res,
        subjectID,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Get daily challenge status failed');
    }
  }
);

export const getDailyChallengeDetailsBySubjectID = createAsyncThunk(
  'dailyChallenge/getCurrentDailyChallenge',
  async (subjectID) => {
    try {
      const res = await fetchWrapper(
        fetchDailyChallengeDetailsBySubjectID,
        subjectID
      );
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get current daily challenge failed');
    }
  }
);

export const getDailyChallengesList = createAsyncThunk(
  'dailyChallenge/getDailyChallengesList',
  async (_, { getState }) => {
    const { plan } = getState();
    const { products } = plan;
    if (isNil(products)) throw new Error('No products available');
    const nonExpiredUserProducts = products
      .filter(
        (product) =>
          product.nonExpiredSubscriptionProducts.length > 0 &&
          product.nonExpiredSubscriptionProducts[0].features.length > 0 &&
          product.nonExpiredSubscriptionProducts[0].features.find(
            (feature) => feature.feature === 'DC'
          )?.parameters?.IsEnabled === true
      )
      .map((product) => product.nonExpiredSubscriptionProducts[0]);
    if (nonExpiredUserProducts.length <= 0)
      throw new Error('All user product/s expired');
    try {
      const dailyChallenges = await nonExpiredUserProducts.reduce(
        async (previousDailyChallenges, subProduct) => {
          const collection = await previousDailyChallenges;
          let challengeStatus;
          let challengeDetails;
          try {
            challengeStatus = await fetchWrapper(
              fetchDailyChallengeStatusBySubjectID,
              subProduct.subjectId
            );
          } catch (err) {
            challengeStatus = { error: err.message };
          }
          try {
            challengeDetails = await fetchWrapper(
              fetchDailyChallengeDetailsBySubjectID,
              subProduct.subjectId
            );
          } catch (err) {
            challengeDetails = { error: err.message };
          }
          return [
            ...collection,
            {
              subjectID: subProduct.subjectId,
              status: challengeStatus,
              details: challengeDetails,
              totalQns: subProduct.features.find(
                (feature) => feature.feature === 'DC'
              )?.parameters?.numberofquestions,
            },
          ];
        },
        Promise.resolve([])
      );
      return dailyChallenges;
    } catch (error) {
      throw new Error(error?.message ?? 'Get daily challenges failed');
    }
  }
);

// Not applicable for Math DC as Koko is auto-credited by backend
export const claimDailyChallengeKoko = createAsyncThunk(
  'dailyChallenge/claimDailyChallengeKoko',
  async (_, { dispatch, getState }) => {
    const { dailyChallenge } = getState();
    const { ChallengeID, SubjectID } = dailyChallenge.currDailyChallenge;
    try {
      const res = await fetchWrapper(fetchClaimKoko, {
        subjectID: SubjectID,
        dcId: ChallengeID,
      });
      dispatch(getRewards());
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Claim Daily Challenge Koko failed');
    }
  }
);

const initialState = {
  currDailyChallenge: {},
  currDailyChallengeLoading: false,
  currDailyChallengeErr: null,
  dailyChallengeStatus: null,
  dailyChallengeStatusErr: null,
  dailyChallenges: [],
  dailyChallengesErr: null,
  isDailyChallengesLoading: false,
  isLoading: false,
  isChangingQn: false,
  isChangingQnThreeTimes: false,
  isClaimingDCKoko: false,
  isChangingQnErr: null,
  isErr: null,
  submitDailyChallengeAnswerIsErr: null,
  superHeroChallengeStatus: null,
  superHeroChallengeIsLoading: null,
  superHeroChallengeIsError: null,
  superHeroChallengeWithoutImageData: null,
  superHeroChallengeWithoutImageIsLoading: null,
  superHeroChallengeWithoutImageIsError: null,
  difficultyLevel: null,
  allTopics: [],
  allTopicsIsLoading: false,
  allTopicsIsErr: null,
  changedQuestion: {},
  submitAnsDailyChallenge: {},
  isClaiming: false,
  claiRewardsResult: null,
  claimKokoErr: null,
  dailyChallengeQuestion: [],
  dailyChallengeLocalSavedAns: [null],
  dailyChallengeLocalSavedWorkings: [],
  dailyChallengeQuestionAnswer: [],
  dailyChallengeSubmissions: [],
  dailyChallengeSavedAnswers: [],
  activeQsDailyChallenge: 1,
  prevTodayCPPoints: 0,
  showHoDModal: false,
  prevIsKokoClaimed: null,
  showClaimKokoModal: false,
  showMysteryKokoModal: false,
  claimedMysteryKokoAmt: null,
  claimMysteryKokoError: null,
  changeQnResult: false,
  totalExp: 0,
};

const dailyChallengeSlice = createSlice({
  name: 'dailyChallenge',
  initialState,
  reducers: {
    saveDailyChallengeAnsLocally: (state, action) => {
      const newAnswers = [...state.dailyChallengeLocalSavedAns];
      newAnswers[action.payload.index] = action.payload.answers;
      state.dailyChallengeLocalSavedAns = newAnswers;
    },
    saveDailyChallengeWorkingsLocally: (state, action) => {
      const newWorkings = [...state.dailyChallengeLocalSavedWorkings];
      newWorkings[action.payload.index] = action.payload.workings;
      state.dailyChallengeLocalSavedWorkings = newWorkings;
    },
    clearSavedDailyChallengeWorkings: (state, action) => {
      const newWorkings = [...state.dailyChallengeLocalSavedWorkings];
      newWorkings[action.payload] = '';
      state.dailyChallengeLocalSavedWorkings = newWorkings;
    },
    clearSavedDailyChallengeAnswer: (state, action) => {
      const newSavedAnswers = [...state.dailyChallengeLocalSavedAns];
      newSavedAnswers[action.payload] = null;
      state.dailyChallengeLocalSavedAns = newSavedAnswers;
    },
    resetSubmitAns: (state) => {
      state.submitAnsDailyChallenge = {};
    },
    resetSaveDailyChallengeAns: (state) => {
      state.dailyChallengeLocalSavedAns = [null];
    },
    setActive: (state, action) => {
      state.activeQsDailyChallenge = action.payload;
    },
    setShowHoDModal: (state, action) => {
      state.showHoDModal = action.payload;
    },
    setShowClaimKokoModal: (state, action) => {
      state.showClaimKokoModal = action.payload;
    },
    setShowMysteryKokoModal: (state, action) => {
      state.showMysteryKokoModal = action.payload;
    },
    resetChangeQnResult: (state) => {
      state.changeQnResult = false;
      state.isChangingQnErr = null;
    },
    resetErr: (state) => {
      state.isErr = null;
      state.isChangingQnThreeTimes = false;
    },
    resetSubmitDailyChallengeAnswerIsErr: (state) => {
      state.submitDailyChallengeAnswerIsErr = null;
    },
  },
  extraReducers: {
    [getDailyChallengeStatus.pending]: (state) => {
      state.isLoading = true;
      state.dailyChallengeStatusErr = null;
    },
    [getDailyChallengeStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.dailyChallengeStatus = action.payload;
      state.dailyChallengeStatusErr = null;
    },
    [getDailyChallengeStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.dailyChallengeStatusErr = action.error.message;
    },
    [getDailyChallengesList.pending]: (state) => {
      state.isDailyChallengesLoading = true;
      state.dailyChallengesErr = null;
    },
    [getDailyChallengesList.fulfilled]: (state, action) => {
      state.isDailyChallengesLoading = false;
      state.dailyChallenges = action.payload;
    },
    [getDailyChallengesList.rejected]: (state, action) => {
      state.isDailyChallengesLoading = false;
      state.dailyChallengesErr = action.error.message;
    },
    [getSuperHeroChallengeStatus.pending]: (state) => {
      state.superHeroChallengeIsLoading = true;
      state.superHeroChallengeIsError = null;
    },
    [getSuperHeroChallengeStatus.fulfilled]: (state, action) => {
      state.superHeroChallengeIsLoading = false;
      state.superHeroChallengeStatus = action.payload;
      state.superHeroChallengeIsError = null;
    },
    [getSuperHeroChallengeStatus.rejected]: (state, action) => {
      state.superHeroChallengeIsLoading = false;
      state.superHeroChallengeIsError = action.error.message;
    },
    [getSuperHeroChallengeDataWithoutImage.pending]: (state) => {
      state.superHeroChallengeWithoutImageIsLoading = true;
      state.superHeroChallengeWithoutImageIsError = null;
    },
    [getSuperHeroChallengeDataWithoutImage.fulfilled]: (state, action) => {
      state.superHeroChallengeWithoutImageIsLoading = false;
      state.superHeroChallengeWithoutImageData = action.payload;
    },
    [getSuperHeroChallengeDataWithoutImage.rejected]: (state, action) => {
      state.superHeroChallengeWithoutImageIsLoading = false;
      state.superHeroChallengeWithoutImageIsError = action.error.message;
    },
    [getCurrentDailyChallenge.pending]: (state) => {
      state.currDailyChallengeLoading = true;
      state.currDailyChallengeErr = null;
    },
    [getCurrentDailyChallenge.fulfilled]: (state, action) => {
      state.currDailyChallengeLoading = false;
      state.currDailyChallenge = action.payload;
      state.prevTodayCPPoints = action.payload.TodayCpPoint;
      state.prevIsKokoClaimed = action.payload.IsKokoClaimed;
      state.showMysteryKokoModal = action.payload.IsKokoClaimable === true;
      if (
        action.payload.UserQuestionSubmissions &&
        action.payload.UserQuestionSubmissions.length > 0
      ) {
        state.dailyChallengeLocalSavedAns = Array(
          action.payload.UserQuestionSubmissions.length
        ).fill(null);
        const sortedUserQuestionSubmissions = action.payload.UserQuestionSubmissions.sort(
          compareValues('DisplayOrder', 'asc')
        );
        state.dailyChallengeQuestion = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.Question
        );
        state.dailyChallengeSubmissions = sortedUserQuestionSubmissions;
        state.dailyChallengeSavedAnswers = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.SubmissionModel
        );
        // Populate dailyChallengeQuestionAnswer
        state.dailyChallengeQuestionAnswer = sortedUserQuestionSubmissions.map(
          (qn) => {
            if (qn.SubmissionModel !== null) {
              const filteredXml = qn.SubmissionModel.Payload.replace(/\\/g, '');
              const parsed = xmlParser(filteredXml);
              let answerKeys = null;
              if (parsed.questionAnswers.length > 0) {
                answerKeys = parsed.questionAnswers.map((answer) => answer.$);
              }
              return answerKeys;
            }
            return null;
          }
        );
      }
    },
    [getCurrentDailyChallenge.rejected]: (state, action) => {
      state.currDailyChallengeLoading = false;
      state.currDailyChallengeErr = action.error.message;
    },
    [firstTimeUpdateDifficulty.pending]: (state) => {
      state.isErr = null;
      state.isLoading = true;
    },
    [firstTimeUpdateDifficulty.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currDailyChallenge = action.payload;
      state.prevTodayCPPoints = action.payload.TodayCpPoint;
      state.prevIsKokoClaimed = action.payload.IsKokoClaimed;
      if (
        action.payload.UserQuestionSubmissions &&
        action.payload.UserQuestionSubmissions.length > 0
      ) {
        state.dailyChallengeLocalSavedAns = Array(
          action.payload.UserQuestionSubmissions.length
        ).fill(null);
        const sortedUserQuestionSubmissions = action.payload.UserQuestionSubmissions.sort(
          compareValues('DisplayOrder', 'asc')
        );
        state.dailyChallengeQuestion = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.Question
        );
        state.dailyChallengeSubmissions = sortedUserQuestionSubmissions;
        state.dailyChallengeSavedAnswers = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.SubmissionModel
        );
        // Populate dailyChallengeQuestionAnswer
        state.dailyChallengeQuestionAnswer = sortedUserQuestionSubmissions.map(
          (qn) => {
            if (qn.SubmissionModel !== null) {
              const filteredXml = qn.SubmissionModel.Payload.replace(/\\/g, '');
              const parsed = xmlParser(filteredXml);
              let answerKeys = null;
              if (parsed.questionAnswers.length > 0) {
                answerKeys = parsed.questionAnswers.map((answer) => answer.$);
              }
              return answerKeys;
            }
            return null;
          }
        );
      }
    },
    [firstTimeUpdateDifficulty.rejected]: (state, action) => {
      state.isLoading = false;
      state.isErr = action.error.message;
    },
    [changeDifficulty.pending]: (state) => {
      state.isErr = null;
      state.isLoading = true;
    },
    [changeDifficulty.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currDailyChallenge = action.payload;
      state.prevTodayCPPoints = action.payload.TodayCpPoint;
      state.prevIsKokoClaimed = action.payload.IsKokoClaimed;
      if (
        action.payload.UserQuestionSubmissions &&
        action.payload.UserQuestionSubmissions.length > 0
      ) {
        state.dailyChallengeLocalSavedAns = Array(
          action.payload.UserQuestionSubmissions.length
        ).fill(null);
        const sortedUserQuestionSubmissions = action.payload.UserQuestionSubmissions.sort(
          compareValues('DisplayOrder', 'asc')
        );
        state.dailyChallengeQuestion = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.Question
        );
        state.dailyChallengeSubmissions = sortedUserQuestionSubmissions;
        state.dailyChallengeSavedAnswers = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.SubmissionModel
        );
        // Populate dailyChallengeQuestionAnswer
        state.dailyChallengeQuestionAnswer = sortedUserQuestionSubmissions.map(
          (qn) => {
            if (qn.SubmissionModel !== null) {
              const filteredXml = qn.SubmissionModel.Payload.replace(/\\/g, '');
              const parsed = xmlParser(filteredXml);
              let answerKeys = null;
              if (parsed.questionAnswers.length > 0) {
                answerKeys = parsed.questionAnswers.map((answer) => answer.$);
              }
              return answerKeys;
            }
            return null;
          }
        );
      }
    },
    [changeDifficulty.rejected]: (state, action) => {
      state.isLoading = false;
      state.isErr = action.error.message;
    },
    [getStudentTopics.pending]: (state) => {
      state.allTopicsIsErr = null;
      state.allTopicsIsLoading = true;
    },
    [getStudentTopics.fulfilled]: (state, action) => {
      state.allTopicsIsLoading = false;
      state.allTopics = action.payload;
    },
    [getStudentTopics.rejected]: (state, action) => {
      state.allTopicsIsLoading = false;
      state.allTopicsIsErr = action.error.message;
    },
    [changeQuestion.pending]: (state) => {
      state.isChangingQn = true;
      state.isChangingQnErr = null;
    },
    [changeQuestion.fulfilled]: (state, action) => {
      state.isChangingQn = false;
      state.currDailyChallenge = action.payload;
      state.changeQnResult = true;
      if (
        action.payload.UserQuestionSubmissions &&
        action.payload.UserQuestionSubmissions.length > 0
      ) {
        state.dailyChallengeLocalSavedAns = Array(
          action.payload.UserQuestionSubmissions.length
        ).fill(null);
        const sortedUserQuestionSubmissions = action.payload.UserQuestionSubmissions.sort(
          compareValues('DisplayOrder', 'asc')
        );
        state.dailyChallengeQuestion = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.Question
        );
        state.dailyChallengeSubmissions = sortedUserQuestionSubmissions;
        state.dailyChallengeSavedAnswers = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.SubmissionModel
        );
        // Populate dailyChallengeQuestionAnswer
        state.dailyChallengeQuestionAnswer = sortedUserQuestionSubmissions.map(
          (qn) => {
            if (qn.SubmissionModel !== null) {
              const filteredXml = qn.SubmissionModel.Payload.replace(/\\/g, '');
              const parsed = xmlParser(filteredXml);
              let answerKeys = null;
              if (parsed.questionAnswers.length > 0) {
                answerKeys = parsed.questionAnswers.map((answer) => answer.$);
              }
              return answerKeys;
            }
            return null;
          }
        );
      }
    },
    [changeQuestion.rejected]: (state, action) => {
      state.isChangingQn = false;
      state.isChangingQnErr =
        action.error.message !== 'Maximum 3 times changes have been reached'
          ? action.error.message
          : null;
      state.changeQnResult = false;
      state.isChangingQnThreeTimes =
        action.error.message === 'Maximum 3 times changes have been reached';
    },
    [submitDailyChallengeAnswer.pending]: (state) => {
      state.isLoading = true;
      state.submitDailyChallengeAnswerIsErr = null;
    },
    [submitDailyChallengeAnswer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currDailyChallenge = action.payload;
      state.totalExp = action.payload.TotalExp;
      if (state.prevTodayCPPoints < 15 && action.payload.TodayCpPoint >= 15) {
        state.showHoDModal = true;
      }
      const hasKokoClaimed =
        state.prevIsKokoClaimed !== true &&
        action.payload.IsKokoClaimed === true;
      const hasMysteryKoko = action.meta.arg.hasMysteryKoko === true;
      if (hasKokoClaimed && !hasMysteryKoko) {
        state.showClaimKokoModal = true;
        state.submitAnsDailyChallenge = action.payload;
      } else if (hasKokoClaimed && hasMysteryKoko) {
        state.submitAnsDailyChallenge = {
          ...action.payload,
          IsKokoClaimable: true,
        };
      } else {
        state.submitAnsDailyChallenge = action.payload;
      }
      state.prevTodayCPPoints = action.payload.TodayCpPoint;
      state.prevIsKokoClaimed = action.payload.IsKokoClaimed;
      state.dailyChallengeQuestion = action.payload.UserQuestionSubmissions.map(
        (userQnSubmission) => userQnSubmission.Question
      );
      if (
        action.payload.UserQuestionSubmissions &&
        action.payload.UserQuestionSubmissions.length > 0
      ) {
        state.dailyChallengeLocalSavedAns = Array(
          action.payload.UserQuestionSubmissions.length
        ).fill(null);
        const sortedUserQuestionSubmissions = action.payload.UserQuestionSubmissions.sort(
          compareValues('DisplayOrder', 'asc')
        );
        state.dailyChallengeQuestion = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.Question
        );
        state.dailyChallengeSubmissions = sortedUserQuestionSubmissions;
        state.dailyChallengeSavedAnswers = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.SubmissionModel
        );
        // Populate dailyChallengeQuestionAnswer
        state.dailyChallengeQuestionAnswer = sortedUserQuestionSubmissions.map(
          (qn) => {
            if (qn.SubmissionModel !== null) {
              const filteredXml = qn.SubmissionModel.Payload.replace(/\\/g, '');
              const parsed = xmlParser(filteredXml);
              let answerKeys = null;
              if (parsed.questionAnswers.length > 0) {
                answerKeys = parsed.questionAnswers.map((answer) => answer.$);
              }
              return answerKeys;
            }
            return null;
          }
        );
      }
    },
    [submitDailyChallengeAnswer.rejected]: (state, action) => {
      state.isLoading = false;
      state.submitDailyChallengeAnswerIsErr = action.error.message;
    },
    [claimDailyChallengeKoko.pending]: (state) => {
      state.isClaimingDCKoko = true;
      state.claimedMysteryKokoAmt = null;
      state.claimMysteryKokoError = null;
    },
    [claimDailyChallengeKoko.fulfilled]: (state, action) => {
      state.isClaimingDCKoko = false;
      state.claimedMysteryKokoAmt = action.payload;
    },
    [claimDailyChallengeKoko.rejected]: (state, action) => {
      state.isClaimingDCKoko = false;
      state.claimMysteryKokoError = action.error.message;
    },
    [updateDailyChallengeLevelB2C.pending]: (state) => {
      state.isErr = null;
      state.isLoading = true;
    },
    [updateDailyChallengeLevelB2C.fulfilled]: (state, action) => {
      state.currDailyChallenge = action.payload;
      state.prevTodayCPPoints = action.payload.TodayCpPoint;
      state.prevIsKokoClaimed = action.payload.IsKokoClaimed;
      if (
        action.payload.UserQuestionSubmissions &&
        action.payload.UserQuestionSubmissions.length > 0
      ) {
        state.dailyChallengeLocalSavedAns = Array(
          action.payload.UserQuestionSubmissions.length
        ).fill(null);
        const sortedUserQuestionSubmissions = action.payload.UserQuestionSubmissions.sort(
          compareValues('DisplayOrder', 'asc')
        );
        state.dailyChallengeQuestion = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.Question
        );
        state.dailyChallengeSubmissions = sortedUserQuestionSubmissions;
        state.dailyChallengeSavedAnswers = sortedUserQuestionSubmissions.map(
          (userQnSubmission) => userQnSubmission.SubmissionModel
        );
        // Populate dailyChallengeQuestionAnswer
        state.dailyChallengeQuestionAnswer = sortedUserQuestionSubmissions.map(
          (qn) => {
            if (qn.SubmissionModel !== null) {
              const filteredXml = qn.SubmissionModel.Payload.replace(/\\/g, '');
              const parsed = xmlParser(filteredXml);
              let answerKeys = null;
              if (parsed.questionAnswers.length > 0) {
                answerKeys = parsed.questionAnswers.map((answer) => answer.$);
              }
              return answerKeys;
            }
            return null;
          }
        );
      }

      state.isLoading = false;
    },
    [updateDailyChallengeLevelB2C.rejected]: (state, action) => {
      state.isLoading = false;
      state.isErr = action.error.message;
    },
  },
});

const { actions, reducer } = dailyChallengeSlice;
export const {
  resetSaveDailyChallengeAns,
  saveDailyChallengeAnsLocally,
  saveDailyChallengeWorkingsLocally,
  setActive,
  clearSavedDailyChallengeAnswer,
  clearSavedDailyChallengeWorkings,
  setShowHoDModal,
  setShowClaimKokoModal,
  resetErr,
  resetSubmitAns,
  resetChangeQnResult,
  resetSubmitDailyChallengeAnswerIsErr,
  setShowMysteryKokoModal,
} = actions;
export default reducer;
