import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import useSound from 'use-sound';
import voiceSound from 'assets/audio/thatsright3.mp3';
import { isNil } from 'ramda';
import { wordToSoundMapping, soundSpriteMap } from 'constants/dailyChallenge';
import useIsMobile from 'hooks/responsive/useIsMobile';
import animationData from './lf30_editor_xakgefzo.json';
import { Page } from './ThatsRight.styles';

const ThatsRight = ({ loop, text, hasSound, soundSprite }) => {
  const isMobile = useIsMobile();
  const soundFile = isNil(soundSprite) ? voiceSound : soundSprite;
  const soundOptions = isNil(soundSprite)
    ? { interrupt: true }
    : { interrupt: true, sprite: soundSpriteMap };
  const [play, { stop }] = useSound(soundFile, soundOptions);

  useEffect(() => {
    if (hasSound === true) {
      const playOptions = isNil(soundSprite)
        ? undefined
        : { id: wordToSoundMapping[text] };
      play(playOptions);
    }
    return () => {
      stop();
    };
  }, [hasSound, play, soundSprite, stop, text]);
  return (
    <Page data-cy="celebration-text">
      <h1 className="fade-in">{text}</h1>
      <Lottie
        options={{
          loop,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        isStopped={false}
        isPaused={false}
        width={isMobile ? 250 : 500}
        height={150}
      />
    </Page>
  );
};

export default ThatsRight;
