import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  setIsExpired,
  setShowPremiumSchoolToggleModal,
} from 'store/dashboard/studentDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import ArrowIcon from 'assets/img/premiumschooltoggle/arrow-left.svg';
import PremiumPlanImg from 'assets/img/premiumschooltoggle/premium-plan.png';
import SchoolPlanImg from 'assets/img/premiumschooltoggle/school-plan.png';
import Button from 'components/Shared/Button';
import {
  setPlan,
  setCurrentProduct,
  setPrevProduct,
} from 'store/plan/planSlice';
import { fetchWrapper } from 'services/login';
import updatePreferences from 'services/premiumSchoolPreferences';
import { resetNotification } from 'store/notification/notificationSlice';
import { isNil } from 'ramda';
import {
  Container,
  PlansContainer,
  ActionButtonsContainer,
} from './TogglePlanModalContent.styles';

const TogglePlanModalContent = ({ setShowLoader, setHasSeen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['switchPlan', 'common']);
  // Redux States
  const { plan, currentProduct, products } = useSelector((state) => state.plan);

  const currentSubjectProduct = products.find(
    (item) => item.subject === currentProduct.subject
  );

  // Event Handlers
  const cancelClickHandler = () => {
    dispatch(setShowPremiumSchoolToggleModal(false));
  };

  const plans =
    plan === 'school'
      ? t('switchPlan:plans.premium', 'Premium')
      : t('switchPlan:plans.school', 'School');

  const confirmClickHandler = async () => {
    let newPlan;
    let newSubProduct;
    const newProductType = plan === 'school' ? 'premium' : 'school';
    if (process.env.REACT_APP_SCIENCE === 'true') {
      const currentParentProduct = products.find(
        (product) => product.id === currentProduct.productParentId
      );
      newSubProduct = isNil(currentParentProduct)
        ? null
        : currentParentProduct.nonExpiredSubscriptionProducts.find(
            (subProduct) =>
              subProduct.productType.toLowerCase() === newProductType
          );
      if (!isNil(newSubProduct)) newPlan = newSubProduct.productId;
    } else {
      newPlan = newProductType;
    }
    if (currentSubjectProduct.hasSchoolOnly) {
      if (
        currentSubjectProduct.otpTrialDetails.otpStatus === 'otp-not-started'
      ) {
        dispatch(setShowPremiumSchoolToggleModal(false));
        if (newProductType === 'premium') {
          dispatch(setPlan(newProductType));
          dispatch(setCurrentProduct(null));
          dispatch(setPrevProduct(currentProduct));
          history.push('/trial/math');
          return;
        }
        if (newProductType === 'school') {
          dispatch(setIsExpired(newSubProduct?.isExpired));
          dispatch(setPlan('school'));
          history.push('/dashboard?subject=math&plan=school');
          return;
        }
      }

      if (
        currentSubjectProduct.otpTrialDetails.otpStatus === 'otp-trial-expired'
      ) {
        if (newProductType === 'premium') {
          dispatch(setShowPremiumSchoolToggleModal(false));
          dispatch(setPlan('premium'));
          dispatch(setIsExpired(true));
          dispatch(setCurrentProduct(null));
          dispatch(setPrevProduct(currentProduct));
          history.push(
            `/expired-otp/${currentSubjectProduct.subject.toLowerCase()}`
          );
          return;
        }
        if (newProductType === 'school') {
          dispatch(setIsExpired(false));
          dispatch(setShowPremiumSchoolToggleModal(false));
          dispatch(setPlan('school'));
          history.push(`/dashboard?subject=math&plan=school`);
          return;
        }
      }

      if (
        currentSubjectProduct.otpTrialDetails.otpStatus ===
        'otp-purchased-expired'
      ) {
        if (newProductType === 'premium') {
          dispatch(setShowPremiumSchoolToggleModal(false));
          dispatch(setPlan('premium'));
          dispatch(setIsExpired(true));
          dispatch(setCurrentProduct(null));
          dispatch(setPrevProduct(currentProduct));
          history.push(`/math/expired`);
          return;
        }
        if (newProductType === 'school') {
          dispatch(setIsExpired(false));
          dispatch(setShowPremiumSchoolToggleModal(false));
          dispatch(setPlan('school'));
          history.push(`/dashboard?subject=math&plan=school`);
          return;
        }
      }
    }
    if (!isNil(newPlan)) {
      try {
        await fetchWrapper(updatePreferences, newPlan);
      } catch (error) {
        console.log(error.message);
      }
    }
    setShowLoader(true);
    setHasSeen(false);
    dispatch(setPlan(newProductType));
    // Set current product
    if (!isNil(newSubProduct)) {
      dispatch(setCurrentProduct(newSubProduct));
    }
    dispatch(resetNotification());
    dispatch(setShowPremiumSchoolToggleModal(false));
    history.replace(`/dashboard?subject=math&plan=${newProductType}`);
  };

  return (
    <Container plan={plan} data-cy="modal-content">
      <p className="title">{t('switchPlan:toggle.title', 'Switch Account')}</p>
      <p className="subtitle">
        <Trans i18nKey="switchPlan:toggle.subtitle" plans={plans}>
          You are going to switch to <span>{{ plans }} Account</span>
        </Trans>
      </p>
      <PlansContainer plan={plan} data-cy="plans">
        <span
          className={plan === 'school' ? '' : 'selected'}
          data-cy="option-school"
        >
          <img src={SchoolPlanImg} alt="School Plan" />
        </span>
        <img src={ArrowIcon} alt="arrow" className="arrow-icon" />
        <span
          className={plan === 'school' ? 'selected' : ''}
          data-cy="option-premium"
        >
          <img src={PremiumPlanImg} alt="Premium Plan" />
        </span>
      </PlansContainer>
      <ActionButtonsContainer>
        <Button
          variant="secondary"
          onClick={cancelClickHandler}
          dataCy="cancel-btn"
        >
          {t('common:cancel', 'Cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={confirmClickHandler}
          dataCy="confirm-btn"
        >
          {t('common:confirm', 'Confirm')}
        </Button>
      </ActionButtonsContainer>
    </Container>
  );
};

export default TogglePlanModalContent;
