import styled from 'styled-components';
import ScoreBoxGreen from 'assets/img/assignment/assignment-view/summary-total-score-bg-green.svg';
import ScoreBoxOrange from 'assets/img/assignment/assignment-view/summary-total-score-bg-orange.svg';
import ScoreBoxRed from 'assets/img/assignment/assignment-view/summary-total-score-bg-red.svg';

export const Container = styled.div`
  background: ${(props) =>
    props.color === 'green'
      ? `url(${ScoreBoxGreen})`
      : props.color === 'orange'
      ? `url(${ScoreBoxOrange})`
      : `url(${ScoreBoxRed})`};
  background-repeat: no-repeat;
  width: 232px;
  height: 100px;
  background-size: contain;
  padding: 0.5rem;
  .title {
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  .score-value {
    display: flex;
    justify-content: center;
    margin-top: 0.1rem;
    .score-percentage {
      display: flex;
      font-family: 'Pacifico', cursive;
      color: #ffffff;
      font-size: 40px;
      max-height: 50px;
      background: ${(props) =>
        props.color === 'green'
          ? '#49b800'
          : props.color === 'orange'
          ? '#FF6701'
          : '#FC4D4D'};
      border-radius: 24px;
      padding: 0rem 1.2rem;
      margin-right: 0.5rem;
      p {
        margin-bottom: 0rem;
        margin-top: -0.8rem;
      }
      span {
        font-size: 25px;
        align-self: center;
      }
    }
    .status-info {
      align-self: flex-end;
      color: #ffffff;
      font-weight: bold;
      font-size: 20px;
      span {
        font-size: 16px;
        color: ${(props) =>
          props.color === 'green'
            ? '#51a11c'
            : props.color === 'orange'
            ? '#FF6701'
            : '#D95454'};
      }
    }
  }

  @media (max-width: 502px) {
    width: 105%;
    background-size: cover;
    padding: 0rem;
    height: 5rem;

    .score-value {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      height: 4rem;
    }

    .score-percentage {
      background: none !important;
      font-size: 48px;
      text-shadow: 0px 3px 6px #9d9d9d;
    }

    .status-info {
      font-size: 12px;
      margin-left: -1rem;
    }

    .time {
      margin-bottom: 0rem;
      font-size: 14px;

      .total-time {
        color: ${(props) =>
          props.color === 'green'
            ? '#51a11c'
            : props.color === 'orange'
            ? '#FF6701'
            : '#D95454'};
      }

      .time-format {
        color: #ffffff;
      }
    }
  }
`;
