import React from 'react';
import { useTranslation } from 'react-i18next';
import { plansTitle } from 'constants/index';
import { TitleStyle } from './Title.styles';
import { subscriptionPlan } from 'constants/profileSettings';

const Title = ({ type }) => {
  const { t } = useTranslation(['expired']);
  return (
    <TitleStyle>
      <img src={subscriptionPlan[type].icon} alt="subscription-plan-icon" />
      {t(`expired:plansType.${plansTitle[type]}`, plansTitle[type])}
    </TitleStyle>
  );
};

export default Title;
