import styled from 'styled-components';

export const Container = styled.div``;

export const Main = styled.div`
  background: #cbf2a0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  .mascot {
    position: absolute;
    left: 0;
    bottom: 0;
    img {
      width: 100%;
      position: relative;
      margin-top: 40%;
      @media (max-width: 1325px) {
        width: 85%;
      }
      @media (max-width: 951px) {
        display: none;
      }
    }
  }
`;

export const ErrorMsg = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;
