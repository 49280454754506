import styled from 'styled-components';

export const Container = styled.div`
  width: 73%;
  align-self: center;
  .content-container {
    display: flex;
    justify-content: center;
    padding-top: 0%;
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 1px solid #8dcf44;
      border-radius: 8px;
      box-shadow: 5px 5px 0px 0px #8dcf44;
      width: 560px;
      min-height: 15rem;
      padding: 0.5rem 2rem 2rem;
      border-radius: 0.5rem;
      text-align: center;
      .plane {
        position: relative;
        margin-top: -95px;
        margin-left: 120%;
        @media (max-width: 751px) {
          margin-top: -90px;
          margin-left: 100%;
        }
      }
      .icon {
        margin-top: -18%;
      }
      .text-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        .success-text {
          color: #404447;
          font-size: 22px;
          font-weight: 600;
        }
        .score {
          display: flex;
          align-items: baseline;
          margin: 0.5rem 0;
          .percentage {
            font-family: Pacifico;
            color: #334042;
            font-size: 47px;
            margin-bottom: 0;
            line-height: 0;
            span {
              font-size: 29px;
              margin-left: -0.6rem;
            }
          }
          .score-detail {
            color: #95c9d2;
            font-weight: 600;
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.5rem;
            span {
              color: #334042;
              font-size: 20px;
              font-weight: bold;
            }
            p {
              margin-bottom: 0px;
              font-size: 14px;
            }
          }
        }
        .scroll-reward {
          font-size: 16px;
          margin: 0.5rem 0;
          span {
            font-size: 18px;
            font-weight: bold;
          }
        }
        .btn-section {
          display: flex;
          align-items: flex-end;
          width: 100%;
          justify-content: space-evenly;
          margin: 0.5rem 0;
          .solution-btn {
            background: none;
            border: none;
            color: #ff6701;
            font-size: 16px;
            font-weight: 600;
            text-decoration: underline;
            :hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
`;
