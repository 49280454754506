import React, { useCallback, useEffect } from 'react';
import useQuery from 'helpers/useQuery';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import Spinner from 'components/Shared/Spinner';
import ScrollIcon from 'assets/img/icon-scroll.svg';
import { useTranslation } from 'react-i18next';
import {
  getTopicsByCourseId,
  getScrolls,
  setLastVisitedTopic,
} from 'store/kooClass/kooClassSlice';
import TopicList from 'components/KooClass/TopicList';
import ButtonPlay from 'components/Shared/ButtonPlay';
import { useHistory } from 'react-router-dom';
import TopicDetails from 'components/KooClass/TopicDetails';
import ErrorModal from 'components/Shared/ErrorModal';
import { kooClassSubjectStyles } from 'constants/dashboardv2';
import {
  Container,
  FillerSideBg,
  TopSection,
  ScrollDisplay,
  FeaturedTopic,
  Card,
  TopicsSection,
} from './KooClass.styles';

const KooClass = ({ subject = '' }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['topicSelection']);
  const courseIdParam = query.get('courseid') ?? '';
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const {
    lastVisitedTopic,
    scrolls,
    topicsLoading,
    topics,
    topicsErr,
  } = useSelector((state) => state.kooClass);
  const hasLastVisitedTopic =
    topics && lastVisitedTopic
      ? !isNil(topics.find((topic) => topic.id === lastVisitedTopic.id))
      : false;
  const featuredTopic = hasLastVisitedTopic
    ? lastVisitedTopic
    : topics?.[0] ?? {};

  const getTopics = useCallback(() => {
    const params = {
      courseId: courseIdParam,
      currentLevel: defaultLevel,
    };
    dispatch(getTopicsByCourseId(params));
  }, [courseIdParam, dispatch, defaultLevel]);

  useEffect(() => {
    getTopics();
  }, [getTopics]);
  useEffect(() => {
    dispatch(getScrolls());
  }, [dispatch]);

  const navigateToKooClassLessonsPage = () => {
    history.push(
      `/kooClass/lessons/${featuredTopic?.id}?courseId=${courseIdParam}`
    );
  };
  return (
    <Container
      styles={{
        bgColour:
          kooClassSubjectStyles[subject.toLowerCase()]?.mainBgColour ??
          kooClassSubjectStyles.math.mainBgColour,
      }}
    >
      <FillerSideBg
        bgColour={
          kooClassSubjectStyles[subject.toLowerCase()]?.sidebarBgColour ??
          kooClassSubjectStyles.math.sidebarBgColour
        }
      />
      {topicsLoading && (
        <Spinner top="20%" position="absolute" left="55%" color="white" />
      )}
      {!topicsLoading && topicsErr && (
        <ErrorModal
          reloadAction={getTopicsByCourseId}
          errorMessage={topicsErr}
        />
      )}
      {!topicsLoading && !topicsErr && (
        <>
          <TopSection
            styles={{
              backgroundImg:
                kooClassSubjectStyles[subject.toLowerCase()]?.bgImage ??
                kooClassSubjectStyles.math.bgImage,
            }}
          >
            <TopicList data={topics ?? []} courseId={courseIdParam} />
            <FeaturedTopic>
              <Card
                data-cy="recent-topic"
                bg={featuredTopic?.thumbnail}
                onClick={navigateToKooClassLessonsPage}
              />
              <div className="play-btn">
                <ButtonPlay
                  variant="primary"
                  size="100px"
                  tabletSize="80px"
                  onClick={navigateToKooClassLessonsPage}
                />
              </div>
            </FeaturedTopic>
            <ScrollDisplay>
              <img className="scroll-icon" src={ScrollIcon} alt="scroll" />
              {scrolls ? (
                <>
                  <p className="no-scroll">{scrolls.totalScrolls}</p>
                  <p>{t('topicSelection:totalScroll', 'Total Scroll')}</p>
                  <p>({t('topicSelection:allTopics', 'All Topics')})</p>
                </>
              ) : (
                <div className="reload" onClick={() => dispatch(getScrolls())}>
                  Reload
                </div>
              )}
            </ScrollDisplay>
          </TopSection>
          <TopicsSection>
            <p className="topics-header">
              {t('topicSelection:browseByTopic', 'Browse by topics')}
            </p>
            <div className="topics-list">
              {topics.map((item) => (
                <TopicDetails
                  key={item.id}
                  data={item}
                  onClick={() => {
                    if (item.totalNumberOfLessons > 0) {
                      dispatch(setLastVisitedTopic(item));
                      history.push(
                        `/kooClass/lessons/${item.id}?courseId=${courseIdParam}`
                      );
                    }
                  }}
                />
              ))}
            </div>
          </TopicsSection>
        </>
      )}
    </Container>
  );
};

export default KooClass;
