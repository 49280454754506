import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { Dropdown } from 'react-bootstrap';
import avatar from 'assets/img/avatar.png';
import ArrowDownIcon from 'assets/img/navigation/arrow-down1.svg';
import { featureToggle } from 'constants/index';
import { PROFILE_MENU } from 'constants/profileSettings';
import logout from 'helpers/logout';
import { setPlan } from 'store/plan/planSlice';
import { updateUserPreferences } from 'store/userPreferences/userPreferencesSlice';
import { redirectToOld } from 'helpers/redirectToOldPortal';
import { useMediaQuery } from 'react-responsive';
import useDashboard from 'hooks/useDashboard';
import useIsTablet from 'hooks/responsive/useIsTablet';
import useIsDesktop from 'hooks/responsive/useIsDesktop';
import useIsMobile from 'hooks/responsive/useIsMobile';
import QuickAccess from './QuickAccess';
import QuickAccessV2 from './QuickAccessV2';
import {
  ProfileContainer,
  NavProfileContainer,
  BellContainer,
} from './NavProfile.styles';
import Bouns from './Bouns';

const ProfileAvatar = ({ onClick }, ref) => {
  const { isDashboardv2 } = useDashboard();
  return (
    <ProfileContainer isDashboardv2={isDashboardv2}>
      <Link
        to="/"
        className="nav-link-avatar"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <img
          data-cy="settings-arrow"
          className="arrow"
          src={ArrowDownIcon}
          alt=""
        />
      </Link>
    </ProfileContainer>
  );
};

// Helper to check if user id is demo account for showing lang/plan dropdowns
export const isDemoAccount = (username) => {
  switch (username) {
    case 'demo_student4':
      return true;
    case 'demo_student3':
      return true;
    case 'kssmaxlee':
      return true;
    default:
      return false;
  }
};

const NavProfile = ({ parentPortal, toggleAccessMenu }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['navigation']);
  const history = useHistory();
  const location = useLocation();
  const { plan, currentProduct } = useSelector((state) => state.plan);
  const { user, isParentAccount } = useSelector((state) => state.login);
  const { updatePreferences } = useSelector((state) => state.userPreferences);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const handleLogout = () => logout();

  const { studentDetails, isExpired } = useSelector(
    (state) => state.studentDetails
  );
  const { isErrorUserNotification } = useSelector(
    (state) => state.notification
  );

  const { isDashboardv2 } = useDashboard();
  // component state
  const profilePicture = () => {
    if (studentDetails && studentDetails.ProfileAvatarImage !== null) {
      return studentDetails && studentDetails.ProfileAvatarImage;
    }
    return avatar;
  };

  // switch to old portal
  const handleSwitchPortal = () => {
    if (plan === 'school') {
      dispatch(updateUserPreferences('PROBLEMSUMS'));
    } else {
      redirectToOld();
    }
  };

  useEffect(() => {
    if (updatePreferences) redirectToOld();
  });
  return (
    <NavProfileContainer isMobile={isMobile}>
      {!isNil(currentProduct) && isDashboardv2 && <Bouns />}
      {featureToggle.planDropdown &&
        user !== null &&
        isDemoAccount(user.user_name) && (
          <Dropdown className="drop-down">
            <Dropdown.Toggle>{plan}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="first"
                onClick={() => {
                  dispatch(setPlan('school'));
                }}
              >
                School
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  dispatch(setPlan('plus'));
                }}
              >
                Plus
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  dispatch(setPlan('premium'));
                }}
              >
                Premium
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      {!window.isKooSchool &&
        featureToggle.notification &&
        !isTablet &&
        !parentPortal &&
        (plan !== 'school' || isDesktopOrLaptop) && (
          <BellContainer
            onClick={() => history.push('/notification')}
            active={location.pathname === '/notification'}
          >
            <div className="bell-icon" />
            {!isNil(isErrorUserNotification) && <div className="dot">!</div>}
          </BellContainer>
        )}
      {!isExpired &&
        !parentPortal &&
        toggleAccessMenu &&
        (plan === 'school'
          ? !isDesktopOrLaptop && <QuickAccessV2 profileOnly={false} />
          : plan !== 'school' && <QuickAccess />)}
      {plan === 'school' && (
        <img
          className="profile-picture"
          src={profilePicture()}
          alt="Profile"
          onClick={() => {
            if (featureToggle.profile && !isMobile)
              history.push('/profile-settings/avatar');
          }}
          onError={(e) => (e.target.src = avatar)}
        />
      )}
      {isTablet && plan !== 'school' && (
        <QuickAccessV2 profileOnly Toggle={forwardRef(ProfileAvatar)} />
      )}
      {(isDesktop || (isMobile && isParentAccount)) && (
        <Dropdown className="drop-down">
          <Dropdown.Toggle
            as={forwardRef(ProfileAvatar)}
            id="dropdown-custom-components"
          />
          <Dropdown.Menu>
            {isParentAccount !== true && !isExpired && (
              <>
                {PROFILE_MENU.map((item) => (
                  <Dropdown.Item
                    key={item.id}
                    onClick={() => history.push(item.url)}
                  >
                    {t(`navigation:menu.${item.translationKey}`, item.text)}
                  </Dropdown.Item>
                ))}
              </>
            )}
            {process.env.REACT_APP_SWITCH_PORTAL_DROPDOWN === 'true' &&
              !isExpired && (
                <Dropdown.Item onClick={handleSwitchPortal}>
                  {t('navigation:menu.switchPortal', 'Switch To Old Portal')}
                </Dropdown.Item>
              )}

            <Dropdown.Item onClick={handleLogout}>
              {t('navigation:menu.logout', 'Logout')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </NavProfileContainer>
  );
};

export default NavProfile;
