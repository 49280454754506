import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchStoryWorlds,
  fetchStoryChapters,
  fetchStoryChapterTiles,
  unlockStoryChapter,
} from 'services/storyMath';
import { fetchWrapper } from 'services/login';
import { isEmpty, isNil } from 'ramda';

export const getStoryWorlds = createAsyncThunk(
  'StoryMath/GetStoryWorlds',
  async (SubjectId) => {
    try {
      const res = await fetchWrapper(fetchStoryWorlds);
      let result = [];
      if (!isNil(SubjectId)) {
        result = res.filter((obj) => obj.SubjectId === SubjectId);
      } else {
        result = res;
      }
      return result;
    } catch (error) {
      throw new Error(error?.message ?? 'Get story worlds failed');
    }
  }
);

export const getStoryChapters = createAsyncThunk(
  'StoryMath/GetStoryChapters',
  async (storyID) => {
    try {
      const res = await fetchWrapper(fetchStoryChapters, storyID);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get story chapters failed');
    }
  }
);

export const getScienceStoryChapters = createAsyncThunk(
  'StoryScience/GetStoryChapters',
  async (storyIds) => {
    try {
      let arrayObj = [];
      if (!isEmpty(storyIds)) {
        const storyChapters = await storyIds.reduce(
          async (previousChapters, storyId) => {
            const collection = await previousChapters;
            const chapters = await fetchWrapper(fetchStoryChapters, storyId);
            const parsedChapters = chapters.map((chapter) => ({
              ...chapter,
              LevelId: storyId,
            }));
            return [...collection, ...parsedChapters];
          },
          Promise.resolve([])
        );
        arrayObj = [...storyChapters];
      }
      return arrayObj;
    } catch (error) {
      throw new Error(error?.message ?? 'Get story chapters failed');
    }
  }
);

export const getStoryChapterTiles = createAsyncThunk(
  'StoryMath/GetStoryChapterTiles',
  async (chapterId) => {
    try {
      const res = await fetchWrapper(fetchStoryChapterTiles, chapterId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get story chapter tiles failed');
    }
  }
);

export const unlockChapterRequest = createAsyncThunk(
  'StoryMath/UnlockStoryChapter',
  async (chapterId) => {
    try {
      const res = await fetchWrapper(unlockStoryChapter, chapterId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Unlock story chapter failed');
    }
  }
);

const initialState = {
  storyWorlds: [],
  storyChapters: [],
  stories: [],
  selectedStory: {},
  selectedChapter: {},
  unlockChapterError: null,
  isLoading: false,
};

const storyMathSlice = createSlice({
  name: 'storyMath',
  initialState,
  reducers: {
    setSelectedStory: (state, action) => {
      state.selectedStory = action.payload;
    },
    setSelectedChapter: (state, action) => {
      state.selectedChapter = action.payload;
    },
    setChapterList: (state, action) => {
      state.storyChapters = action.payload;
    },
    resetStoryWorld: (state) => {
      state.storyWorlds = initialState.storyWorlds;
      state.storyChapters = initialState.storyChapters;
      state.stories = initialState.stories;
      state.selectedStory = initialState.selectedStory;
      state.selectedChapter = initialState.selectedChapter;
    },
    resetUnlockChapterError: (state) => {
      state.unlockChapterError = null;
    },
  },
  extraReducers: {
    [getStoryWorlds.pending]: (state) => {
      state.isLoading = true;
      state.storyWorlds = [];
    },
    [getStoryWorlds.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.storyWorlds = action.payload;
    },
    [getStoryChapters.pending]: (state) => {
      state.isLoading = true;
    },
    [getStoryChapters.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.storyChapters = action.payload;
    },
    [getScienceStoryChapters.pending]: (state) => {
      state.isLoading = true;
    },
    [getScienceStoryChapters.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.storyChapters = action.payload;
    },
    [getStoryChapterTiles.pending]: (state) => {
      state.isLoading = true;
    },
    [getStoryChapterTiles.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.stories = action.payload;
    },
    [unlockChapterRequest.pending]: (state) => {
      state.isLoading = true;
      state.unlockChapterError = null;
    },
    [unlockChapterRequest.fulfilled]: (state) => {
      state.isLoading = false;
      state.selectedChapter.Status = 'Unlocked';
    },
    [unlockChapterRequest.rejected]: (state, action) => {
      state.isLoading = false;
      state.unlockChapterError = action.error.message;
    },
  },
});

const { actions, reducer } = storyMathSlice;
export const {
  setSelectedStory,
  setSelectedChapter,
  setChapterList,
  resetStoryWorld,
  resetUnlockChapterError,
} = actions;
export default reducer;
