import { useSelector } from 'react-redux';

const useIsSchoolPlan = () => {
  const { plan } = useSelector((state) => state.plan);
  const isSchoolPlan = ['school'].includes(plan);
  return {
    isSchoolPlan,
  };
};

export default useIsSchoolPlan;
