import { ReactComponent as ChallangeIcon } from 'assets/img/dashboardv2/challenge-icon.svg';
import { ReactComponent as TraningIcon } from 'assets/img/dashboardv2/training-icon.svg';
import { ReactComponent as FunIcon } from 'assets/img/dashboardv2/fun-icon.svg';
import { ReactComponent as ClassIcon } from 'assets/img/dashboardv2/class-icon.svg';
import { ReactComponent as FriendsIcon } from 'assets/img/dashboardv2/friends-icon.svg';
// import { ReactComponent as ShopIcon } from 'assets/img/dashboardv2/shop-icon.svg';
// import { ReactComponent as CollectionIcon } from 'assets/img/dashboardv2/collection-icon.svg';
import MathKooClassBg from 'assets/img/kooclass/math-space-bg.png';
import ScienceKooClassBg from 'assets/img/kooclass/science-space-bg.png';
// Level
import Lev10 from 'assets/img/dashboardv2/lev10.svg';
import Lev20 from 'assets/img/dashboardv2/lev20.svg';
import Lev30 from 'assets/img/dashboardv2/lev30.svg';
import Lev40 from 'assets/img/dashboardv2/lev40.svg';
import Lev50 from 'assets/img/dashboardv2/lev50.svg';
import Lev60 from 'assets/img/dashboardv2/lev60.svg';
import Lev70 from 'assets/img/dashboardv2/lev70.svg';
import Lev80 from 'assets/img/dashboardv2/lev80.svg';
import Lev99 from 'assets/img/dashboardv2/lev90.svg';
import Lev100 from 'assets/img/dashboardv2/lev100.svg';

export const sidePanelNavigation = [
  {
    id: 1,
    name: 'KooChallenge',
    icon: ChallangeIcon,
  },
  {
    id: 2,
    name: 'KooTraining',
    icon: TraningIcon,
  },
  {
    id: 3,
    name: 'KooClass',
    icon: ClassIcon,
  },
  {
    id: 4,
    name: 'KooFun',
    icon: FunIcon,
  },
];

export const subNavData = [
  {
    id: 1,
    name: 'Friends',
    icon: FriendsIcon,
    link: '/friends',
  },
  // {
  //   id: 2,
  //   name: 'KooShop',
  //   icon: ShopIcon,
  //   link: '/friends',
  // },
  // {
  //   id: 3,
  //   name: 'Collection',
  //   icon: CollectionIcon,
  //   link: '/friends',
  // },
];

export const $colors = {
  dashboard: {
    math: {
      bg: '#3d3789',
      primary: '#6c67df',
      secondary: '#6563EA',
      tertiary: '#4A48B5',
      fourth: '#5754CE',
    },
    science: {
      bg: '#02684E',
      primary: '#00AF78',
      secondary: '#00C184',
      tertiary: '#009B76',
      fourth: '#00846B',
    },
  },
};

/**
 *
 * @param {number} level - current user level
 *
 * @return corresponds to the level background image
 */
export const mappingLevelBgImage = (level) => {
  if (isNaN(level) || Number.isNaN(level)) return Lev10;
  let target = level;
  if (typeof level !== 'number') target = parseInt(level, 10);

  if (target < 11) return Lev10;
  if (target >= 11 && target < 21) return Lev20;
  if (target >= 21 && target < 31) return Lev30;
  if (target >= 31 && target < 41) return Lev40;
  if (target >= 41 && target < 51) return Lev50;
  if (target >= 51 && target < 61) return Lev60;
  if (target >= 61 && target < 71) return Lev70;
  if (target >= 71 && target < 81) return Lev80;
  if (target >= 81 && target < 100) return Lev99;
  return Lev100;
};

export const dailyChallengeCompletionStatusIDs = {
  0: 'not-started',
  1: 'in-progress',
  2: 'completed',
};

export const kooClassSubjectStyles = {
  math: {
    sidebarBgColour: '#4a48b5',
    mainBgColour: '#3d3789',
    bgImage: MathKooClassBg,
  },
  science: {
    sidebarBgColour: '#009E78',
    mainBgColour: '#02664C',
    bgImage: ScienceKooClassBg,
  },
};
