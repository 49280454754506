import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { Container } from './YearDropdown.styles';

const YearDropdown = ({ selectedValue, values, setValue, className }) => {
  const { t } = useTranslation(['assignmentList']);
  return (
    <Container data-cy="assign-year" className={className}>
      <span>{t('assignmentList:topBar.Assigned', 'Assigned')}</span>
      <DropdownMenu
        selectedValue={selectedValue}
        valueKey={null}
        values={values}
        setValue={setValue}
      />
    </Container>
  );
};

export default YearDropdown;
