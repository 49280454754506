import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    gap: 0;
  }
`;

export const ProficiencyItem = styled.span`
  font-family: 'Arial';
  font-size: 0.75rem;
  color: white;
  img {
    margin-right: 0.5rem;
    width: 28px;
    height: 28px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-right: 0px;
      margin-bottom: 5px;
      width: 20px;
      height: 20px;
    }
  }
`;
