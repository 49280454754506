import React from 'react';
import styled from 'styled-components';
import Miko from 'assets/img/leaderboard/miko-leaderboard.png';
import { Trans } from 'react-i18next';

const StyledContainer = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  img {
    position: relative;
    ${'' /* bottom: 1rem; */}
    left: 2rem;
    align-self: flex-end;
  }
`;

const StyledText = styled.div`
  padding: 0.5rem 1rem 0.5rem 40px;
  background: #ffe888;
  border-radius: 20px;
  height: max-content;
  align-self: flex-end;
  p {
    margin: 0;
    font-size: 0.875rem;
    span {
      font-weight: 600;
    }
  }
`;

const Message = () => {
  return (
    <StyledContainer>
      <img src={Miko} alt="Miko" width="59px" height="72px" />
      <StyledText>
        <p>
          <Trans i18nKey="leaderboardlist:banner.message">
            {
              'Miko is adding up your CPs and will update the Leaderboard every '
            }
            <span>5 minutes.</span>
          </Trans>
        </p>
      </StyledText>
    </StyledContainer>
  );
};

export default Message;
