import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';
import useQuery from 'helpers/useQuery';

const withSubscriptionCheck = (WrappedComponent, chosenSubject) => {
  const HOC = () => {
    const query = useQuery();
    const subjectParam = query.get('subject') || chosenSubject;
    const lowercaseSubjectParam = isNil(subjectParam)
      ? 'math'
      : subjectParam.toLowerCase().trim();

    const history = useHistory();
    const { products } = useSelector((state) => state.plan);

    const hasSubject = useMemo(() => {
      const filteredProducts = products?.filter(
        ({ subject, nonExpiredSubscriptionProducts }) =>
          subject?.toLowerCase().trim() === lowercaseSubjectParam &&
          nonExpiredSubscriptionProducts.length > 0
      );
      return filteredProducts && filteredProducts.length > 0;
    }, [lowercaseSubjectParam, products]);

    useEffect(() => {
      if (products && !hasSubject) {
        history.replace('/dashboard');
      }
    }, [hasSubject, history, products]);

    return hasSubject ? <WrappedComponent /> : null;
  };
  return HOC;
};

export default withSubscriptionCheck;
