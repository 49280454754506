import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
`;

export const Label = styled.div`
  background: ${(props) => props.bgColor};
  color: ${(props) => props.fontColor};
  font-size: 0.875rem;
  padding: 0.5rem;
  width: ${(props) => props.width};
  text-align: center;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 120px;
  background: ${(props) =>
    (props.showAnswer && props.disabled) || props.isMarkedRight
      ? props.bgColor
      : 'white'};
  padding: 0.5rem;
  border: ${(props) =>
    props.showAnswer || props.isMarkedRight
      ? `solid 1px ${props.borderColor}`
      : null};
  font-size: 1rem;
`;

export const ButtonGroup = styled.div`
  text-align: right;
  padding-right: 3rem;
`;

export const Button = styled.button`
  background: ${(props) => props.bgColor};
  color: white;
  font-size: 0.8125rem;
  border-radius: 17px;
  border: none;
  padding: 0.5rem;
  margin-left: 1rem;
  img {
    margin-right: 0.3rem;
  }
  :hover {
    background: ${(props) => props.hoverColor};
  }
`;
