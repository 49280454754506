import { isNil } from 'ramda';

const getTitleLabel = (list, index, valueTemplate, valueKey) => {
  if (!isNil(valueTemplate)) {
    if (!isNil(valueKey)) {
      return valueTemplate(list[valueKey], index);
    }
    return valueTemplate(list, index);
  }
  if (!isNil(valueKey)) {
    return list[valueKey];
  }
  return list;
};

export default getTitleLabel;
