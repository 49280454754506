import styled from 'styled-components';

const UI_FILE_WIDTH = 1387;
const CARD_WIDTH = 250;
export const StyledContainer = styled.div`
  cursor: ${({ isSunday }) => (isSunday ? 'not-allowed' : 'pointer')};
  position: relative;
  width: ${({ isSMCEnabled }) =>
    isSMCEnabled ? `calc((${CARD_WIDTH} / ${UI_FILE_WIDTH}) * 100vw)` : 'auto'};

  :hover {
    > div {
      transform: scale(0.95);
    }
  }
  .bg {
    width: 100%;
    height: auto;
    filter: ${({ isDisabled }) => (isDisabled ? 'brightness(0.5)' : 'none')};
  }
  p {
    position: absolute;
    bottom: 13%;
    text-align: center;
    width: 100%;
    font-weight: 700;
    color: white;
    font-size: calc(0.01586157173 * 100vw);
    margin: 0;
    filter: ${({ isDisabled }) => (isDisabled ? 'brightness(0.5)' : 'none')};
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: ${({ isSMCEnabled }) => (isSMCEnabled ? '185px' : 'auto')};
    .bg {
      width: ${({ isSMCEnabled }) => (isSMCEnabled ? '100%' : 'auto')};
    }
    p {
      font-size: 22px;
      bottom: 7%;
      line-height: normal;
    }
  }
`;
