export const status = {
  0: 'Not Started',
  1: 'In progress',
  2: 'Completed',
};
export const kooQuizTypes = {
  1: 'KooQuizMaths',
  2: 'KooQuizSci',
};
export const mathLevels = [1, 2, 3, 4, 5, 6];
export const scienceLevels = [3, 4, 5, 6];

export const MAPPING_PARAM_BY_PAPER_NAME = {
  'Essential Skills': 'Essential Skills',
  'Top School Year-End Paper': 'Top Papers',
  'HOTS Papers': 'HOTS',
  'Open-Ended Questions': 'OED',
};

export const kooQuizSubjectStatus = {
  1: 'Essential Skills',
  2: 'Top School Year-End Paper',
  3: 'HOTS Papers',
  4: 'Open-Ended Questions',
};
export const kooQuizPaperTypeInternal = {
  51: 1, // 'Essential Skills',
  52: 2, // 'Top Papers',
  53: '', // 'Maths Olympiad',
  54: 3, // 'HOTS Papers',
  55: 4, // 'OED',
};

export const KOOQUIZ_SUBJECT_ID = {
  KooQuizMaths: 1,
  KooQuizSci: 2,
};
