import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Header from 'components/Header';
import SuperHeroBanner from 'components/SuperHero/SuperHeroBanner';
import SuperVisionBannerImg from 'assets/img/superhero/supervision-banner.png';
import useDashboard from 'hooks/useDashboard';
import { Content } from './index.style';
import SuperVision from './SuperVision';
import SuperVisionGame from './SuperVisionGame';

const Index = () => {
  const location = useLocation();
  const { isDashboardv2 } = useDashboard();
  return (
    <>
      <Header />
      <SuperHeroBanner
        gradientType="orange"
        banner={SuperVisionBannerImg}
        bgColor="rgba(244, 108, 9, 0.61)"
        backDestination={
          location.pathname === '/superhero/supervision-game'
            ? '/superhero/supervision'
            : isDashboardv2
            ? '/superhero'
            : '/challenges'
        }
      />
      <Content>
        <Switch>
          <Route exact path="/superhero/supervision" component={SuperVision} />
          <Route
            exact
            path="/superhero/supervision-game"
            component={SuperVisionGame}
          />
        </Switch>
      </Content>
    </>
  );
};

export default Index;
