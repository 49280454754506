import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  background-color: #404447;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 1rem;
  font-size: 1rem;
  line-height: normal;
  .logo {
    width: 84.15px;
    height: 30.97px;
    margin-right: 0.3rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 20%;
  justify-content: center;
  align-items: flex-start;
  .forgot-password {
    width: 100%;
    color: #a7a7a7;
    font-size: 0.875rem;
    text-align: center;
    cursor: pointer;
  }
`;

export const ContentItem = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: ${({ isErr }) => !isErr && '1rem'};
  p {
    margin-bottom: 0;
  }
  .label {
    color: #bebebe;
    font-size: 1rem;
    min-width: ${({ isBaLang }) => isBaLang && '92px'};
  }
  .value {
    color: #0d0d0d;
    font-size: 1.125rem;
    font-weight: 600;
  }
  .input-section {
    display: flex;
    flex-direction: column;
    .err-msg {
      color: #ff0000;
      font-size: 12px;
      margin-bottom: 0;
    }
    input {
      outline: none;
      border: 1px solid ${({ isErr }) => (isErr ? '#FF0000' : '#b7b7b7')};
      font-size: 1.125rem;
      height: 41px;
      padding: 0.3rem;
    }
  }
`;
