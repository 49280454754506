import styled from 'styled-components';

export const BodySelectorContainer = styled.div`
  width: 156px;
  background-color: #198f8e;
  border-radius: 18px;
  padding: 20px;
  margin-right: 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > img {
    height: 118px;
    margin-bottom: 20px;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  & > .buttons {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 20px;
    & > button {
      position: absolute;
      top: 20px;
    }
    & > button[direction='left'] {
      left: 0px;
      transform: translateX(-100%);
    }
    & > button[direction='right'] {
      right: 0px;
      transform: translateX(100%);
    }
  }
  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
      display: inline-block;
      margin-bottom: 3px;
    }
  }
  & > .nofill {
    width: 84px;
  }
  @media (min-width: 576px) {
    margin-right: 0px;
    margin-bottom: 12px;
  }
  @media (min-width: 950px) {
    margin-right: 50px;
    margin-left: 12px;
  }
  @media (min-width: 1024px) {
    margin-right: 70px;
  }
  @media (min-width: 1300px) {
    margin-top: -20px;
  }
`;

export const ButtonColor = styled.button`
  height: 36px;
  width: 36px;
  border: 4px solid #198f8e;
  background-color: ${({ color }) => `#${color}`};
  border-radius: 50%;
  &:hover,
  &.selected {
    border-color: #1f705e;
  }
`;
