import styled from 'styled-components';

export const Menu = styled.div`
  position: absolute;
  top: 3.6rem;
  min-width: 90px;
  background: #4a4947;
  color: #fff;
  margin-left: -90px;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-width: 10px 8px;
    border-style: solid;
    border-color: transparent;
    z-index: 1001;
    top: -19px;
    left: 40%;
    border-bottom-color: #343434;
  }
`;

export const Item = styled.a`
  display: flex;
  padding: 0.8rem 2rem;
  width: 280px;
  cursor: pointer;
  text-align: center;
  background: ${(props) => props.active && '#343434'};
  border: ${(props) => !props.active && '1px solid #71706e'};
  color: #ffffff;
  &:hover {
    background: #343434;
    color: #ffce09;
    text-decoration: none;
  }
`;
