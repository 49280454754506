import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_DOMAIN;
const USER_BASE_URL = process.env.REACT_APP_USER_SERVICE_URL;

export const fetchParentInfo = (userID) => {
  return fetch(`${baseUrl}Student/${userID}/Parent`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get parent info failed'); // eslint-disable-line
    });
};

export const fetchProfileAvatars = () => {
  return fetch(`${baseUrl}Profile/GetAllProfileAvatars`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get profile avatars failed'); // eslint-disable-line
    });
};

export const updateProfileAvatar = (ID) => {
  return fetch(`${baseUrl}Profile/UpdateMyProfileAvatar/${ID}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update profile avatar failed'); // eslint-disable-line
    });
};

export const updateParentRegistry = (rawBody) => {
  return fetch(`${baseUrl}Profile/UpdateParentRegistry`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update parent registration failed'); // eslint-disable-line
    });
};

export const postPassword = (rawBody) => {
  return fetch(`${baseUrl}Profile/UpdatePassword`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update password failed'); // eslint-disable-line
    });
};

export const postProfilePicture = (rawBody) => {
  return fetch(`${baseUrl}Profile/UpdateMyProfilePicture`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update profile picture failed'); // eslint-disable-line
    });
};

export const postRequestParentEmail = () => {
  return fetch(`${baseUrl}Profile/TriggerParentAccountEmail`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify({}),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Request parents account failed'); // eslint-disable-line
    });
};

export const postResetPasswordEmail = (email) => {
  return axios
    .post(`${USER_BASE_URL}users/password/email/${email}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response.data || err.message;
    });
};
