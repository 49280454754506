import styled from 'styled-components';

export const Container = styled.div`
  width: 964px;
  height: 100%;
  overflow: hidden;
  min-height: 600px;
  margin: auto;
`;

export const Wrap = styled.div`
  background-color: #fff;
  padding: 1.5em 2.5em;
  border-radius: 0 0 4px 4px;
`;

export const InstructionText = styled.p`
  text-align: center;
`;

export const GameArea = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 2em 0.5em;
  margin-bottom: 2.5em;
`;

export const GameImageWrap = styled.div`
  background-color: #fff;
  padding: 0.75em;
  box-shadow: 0 12px 40px -12px #333, 0 0 23px -10px #333;
  user-select: none;
  margin: auto;
`;

export const GameImageContainer = styled.div`
  position: relative;
  img {
    display: block;
    cursor: pointer;
    width: 345px;
    height: 460px;
  }
`;

export const GameImageLoading = styled.div`
  background-color: rgb(245, 245, 245);
  color: black;
  text-align: center;
  line-height: 280px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
`;
