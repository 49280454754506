import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabContainer, TabStyle } from './Tabs.styles';

const Tabs = ({ data, activeTab, setActiveTab }) => {
  const { t } = useTranslation(['expired']);
  return (
    <TabContainer>
      {data.map((item) => (
        <TabStyle
          key={item}
          onClick={() => setActiveTab(item)}
          active={activeTab === item}
        >
          {t(`expired:planDetail.${item}`, item)}
        </TabStyle>
      ))}
    </TabContainer>
  );
};

export default Tabs;
