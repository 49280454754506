import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchHallOfFame,
  fetchLeaderBoardData,
  fetchHallOfFameStudentList,
} from 'services/leaderboard';
import {
  fetchTerms,
  fetchSeasons,
  fetchMonths,
  fetchWeeks,
} from 'services/leaderboardV2/periods';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import { compareDate } from 'helpers/compareValue';

// -------------------------------HOD Slice-------------------------------------------

// get leaderboard data
export const getLeaderBoardData = createAsyncThunk(
  'leaderboard/getLeaderBoardData',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchLeaderBoardData, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get leaderboard data failed');
    }
  }
);

// get top 50 data by enum filter, level and selected date (for hall of fame section)
export const getTopFiftyStudentsList = createAsyncThunk(
  'leaderboard/getTopFiftyStudentsList',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchHallOfFameStudentList, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get top fifty student list failed');
    }
  }
);

// get hall of fame data
export const getHallOfFame = createAsyncThunk(
  'leaderboard/getHallOfFame',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchHallOfFame, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get hall of fame failed');
    }
  }
);

// get top 50 data by enum filter, level and selected date (for hall of fame section)
export const getHallOfFameStudentList = createAsyncThunk(
  'leaderboard/getHallOfFameStudentList',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchHallOfFameStudentList, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get hall of fame student list failed');
    }
  }
);

export const getTerms = createAsyncThunk('leaderboard/getTerms', async () => {
  const res = await fetchWrapper(fetchTerms);
  return handleResponseV2(res);
});

export const getSeasons = createAsyncThunk(
  'leaderboard/getSeasons',
  async () => {
    const res = await fetchWrapper(fetchSeasons);
    return handleResponseV2(res);
  }
);

export const getMonths = createAsyncThunk('leaderboard/getMonths', async () => {
  const res = await fetchWrapper(fetchMonths);
  return handleResponseV2(res);
});

export const getWeeks = createAsyncThunk('leaderboard/getWeeks', async () => {
  const res = await fetchWrapper(fetchWeeks);
  return handleResponseV2(res);
});

const initialState = {
  groupStudents: [],
  hallOfFame: [],
  hallOfFameLoading: false,
  hallOfFameError: null,
  leaderBoardData: [],
  isLoading: false,
  isErr: null,
  hallOfFameStudentList: [],
  hallOfFameStudentListLoading: false,
  hallOfFameStudentListError: null,
  subject: 1,
  terms: {
    isLoading: false,
    error: null,
    data: [],
  },
  seasons: {
    isLoading: false,
    error: null,
    data: [],
  },
  months: {
    isLoading: false,
    error: null,
    data: [],
  },
  weeks: {
    isLoading: false,
    error: null,
    data: [],
  },
};

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    setSelectedSubject: (state, action) => {
      state.subject = action.payload;
    },
  },
  extraReducers: {
    [getHallOfFame.pending]: (state) => {
      state.hallOfFameLoading = true;
      state.hallOfFameError = null;
    },
    [getHallOfFame.fulfilled]: (state, action) => {
      state.hallOfFameLoading = false;
      state.hallOfFame = action.payload;
      state.hallOfFameError = null;
    },
    [getHallOfFame.rejected]: (state, action) => {
      state.hallOfFameLoading = false;
      state.hallOfFame = [];
      state.hallOfFameError = action.error.message;
    },
    [getLeaderBoardData.pending]: (state) => {
      state.isLoading = true;
      state.isErr = null;
      state.leaderBoardData = [];
    },
    [getLeaderBoardData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.leaderBoardData = action.payload;
      state.isErr = null;
    },
    [getLeaderBoardData.rejected]: (state, action) => {
      state.isLoading = false;
      state.hallOfFame = [];
      state.isErr = action.error.message;
    },
    [getTopFiftyStudentsList.pending]: (state) => {
      state.isLoading = true;
      state.isErr = null;
      state.leaderBoardData = [];
    },
    [getTopFiftyStudentsList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.leaderBoardData = action.payload;
      state.isErr = null;
    },
    [getTopFiftyStudentsList.rejected]: (state, action) => {
      state.isLoading = false;
      state.hallOfFame = [];
      state.isErr = action.error.message;
    },
    [getHallOfFameStudentList.pending]: (state) => {
      state.hallOfFameStudentListLoading = true;
      state.hallOfFameStudentListError = null;
    },
    [getHallOfFameStudentList.fulfilled]: (state, action) => {
      state.hallOfFameStudentListLoading = false;
      state.hallOfFameStudentList = action.payload;
      state.hallOfFameStudentListError = null;
    },
    [getHallOfFameStudentList.rejected]: (state, action) => {
      state.hallOfFameStudentListLoading = false;
      state.hallOfFameStudentList = null;
      state.hallOfFameStudentListError = action.error.message;
    },
    [getTerms.pending]: (state) => {
      state.terms.isLoading = true;
      state.terms.error = null;
      state.terms.data = [];
    },
    [getTerms.fulfilled]: (state, action) => {
      state.terms.isLoading = false;
      const sortedData = action.payload
        .sort(compareDate('startDate'))
        .map((item, index) => {
          return {
            ...item,
            termId: index + 1,
          };
        });
      state.terms.data = sortedData;
    },
    [getTerms.rejected]: (state, action) => {
      state.terms.isLoading = false;
      state.terms.error = action.error.message;
    },
    [getSeasons.pending]: (state) => {
      state.seasons.isLoading = true;
      state.seasons.error = null;
      state.seasons.data = [];
    },
    [getSeasons.fulfilled]: (state, action) => {
      state.seasons.isLoading = false;
      state.seasons.data = action.payload;
    },
    [getSeasons.rejected]: (state, action) => {
      state.seasons.isLoading = false;
      state.seasons.error = action.error.message;
    },
    [getMonths.pending]: (state) => {
      state.months.isLoading = true;
      state.months.error = null;
      state.months.data = [];
    },
    [getMonths.fulfilled]: (state, action) => {
      state.months.isLoading = false;
      state.months.data = action.payload.sort(compareDate('startDate', 'desc'));
    },
    [getMonths.rejected]: (state, action) => {
      state.months.isLoading = false;
      state.months.error = action.error.message;
    },
    [getWeeks.pending]: (state) => {
      state.weeks.isLoading = true;
      state.weeks.error = null;
      state.weeks.data = [];
    },
    [getWeeks.fulfilled]: (state, action) => {
      state.weeks.isLoading = false;
      state.weeks.data = action.payload.sort(compareDate('startDate', 'desc'));
    },
    [getWeeks.rejected]: (state, action) => {
      state.weeks.isLoading = false;
      state.weeks.error = action.error.message;
    },
  },
});

const { actions, reducer } = leaderboardSlice;
export const { setSelectedSubject } = actions;
export default reducer;
