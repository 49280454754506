import handleResponse from 'helpers/handleAPIResponse';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchBFFDetails = () => {
  return fetch(`${baseUrl}BFF/GetMyBFFDetails`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get BFF details failed'); // eslint-disable-line
    });
};

export const fetchSentBFFRequests = () => {
  return fetch(`${baseUrl}BFF/GetSentRequestsHistory`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get sent BFF requests failed'); // eslint-disable-line
    });
};

export const fetchIncomingFriendRequests = ({ pageIndex, pageSize }) => {
  return fetch(
    `${baseUrl}BFF/GetIncomingFriendRequests?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get incoming BFF requests failed'); // eslint-disable-line
    });
};
export const postRemoveFriend = (FriendBFFCode) => {
  return fetch(`${baseUrl}BFF/RemoveFriend/${FriendBFFCode}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        if (result.Result === true) {
          return result.Result;
        }
        throw new Error('Remove friend failed');
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Remove friend failed'); // eslint-disable-line
    });
};

export const fetchFriendInfoByBFFCode = (BFFCode) => {
  return fetch(`${baseUrl}BFF/GetUserInfoByBFFCode/${BFFCode}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get friend info by BFF code failed'); // eslint-disable-line
    });
};

export const postInviteFriend = (BFFCode) => {
  return fetch(`${baseUrl}BFF/SubmitFriendRequest/${BFFCode}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        if (result.Result === true) {
          return result.Result;
        }
        throw new Error('Invite friend failed');
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Invite friend failed'); // eslint-disable-line
    });
};

export const acceptFriend = (FriendBFFCode) => {
  return fetch(`${baseUrl}BFF/AcceptInvitation/${FriendBFFCode}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        if (result.Result === true) {
          return result.Result;
        }
        throw new Error('Accept friend invitation failed');
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Accept friend invitation failed'); // eslint-disable-line
    });
};

export const rejectFriend = (FriendBFFCode) => {
  return fetch(`${baseUrl}BFF/RejectInvitation/${FriendBFFCode}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        if (result.Result === true) {
          return result.Result;
        }
        throw new Error('Reject friend invitation failed');
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Reject friend invitation failed'); // eslint-disable-line
    });
};
