import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  height: 100%;
`;
export const ErrorText = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;
