import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import assignmentBg from 'assets/img/dashboardv2/assignment.png';
import assignmentBgTablet from 'assets/img/dashboardv2/assignmentTablet.png';
import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import { StyledContainer, StyledContent } from './AssignmentCard.styles';

const AssignmentImage = () => {
  const imgSrc = useResponsiveImage(
    assignmentBg,
    assignmentBgTablet,
    assignmentBg
  );
  return <img src={imgSrc} alt="assignment" />;
};

const AssignmentCard = () => {
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const clickHandler = () => {
    history.push('/assignment');
  };
  return (
    <StyledContainer onClick={clickHandler} data-cy="assignment-card">
      <div>
        <AssignmentImage />
        <StyledContent>
          <h3>{t('assignment.name', 'Assignment')}</h3>
        </StyledContent>
      </div>
    </StyledContainer>
  );
};
export default AssignmentCard;
