import React, { useEffect } from 'react';
import Header from 'components/Header';
import Scene from 'components/SuperHeroChallenge/SuperSpeed/Scene';
import { useHistory } from 'react-router-dom';
import useSuperheroChallengeLoader from 'store/superheroChallenge/superheroChallengeLoader';
import SuperHeroBanner from 'components/SuperHero/SuperHeroBanner';
import SuperVisionBanner from 'assets/img/superhero/superspeed-banner.png';
import { Content } from './index.style';

function SuperSpeedGame({ practice }) {
  const history = useHistory();
  const { superSpeedStatus } = useSuperheroChallengeLoader('superspeed');
  useEffect(() => {
    if (!practice && superSpeedStatus !== 3) {
      history.replace('/superhero');
    }
  }, [history, practice, superSpeedStatus]);
  return (
    <>
      <SuperHeroBanner
        banner={SuperVisionBanner}
        gradientType="blue"
        bgColor="rgba(0, 115, 214, 0.61)"
        backDestination="/superhero/superspeed"
      />
      <Header />
      <Content>
        <Scene practice={practice} />
      </Content>
    </>
  );
}

export default React.memo(SuperSpeedGame);
