import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { newRefreshTokenRequest } from 'services/login';
import { isNil } from 'ramda';
import Cookies from 'js-cookie';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const refreshTokenHandler = async (refreshToken, clientID, history) => {
  try {
    localStorage.setItem('refresh_token', refreshToken);
    const res = await newRefreshTokenRequest(clientID);
    if (!isNil(res.access_token)) {
      localStorage.setItem('access_token', res.access_token);
      Cookies.set('sp_access_token', res.access_token, {
        path: '/',
        'max-age': res.expires_in.toString(),
        domain: 'koobits.com',
      });
    }
    if (!isNil(res.refresh_token)) {
      localStorage.setItem('refresh_token', res.refresh_token);
      Cookies.set('sp_refresh_token', res.refresh_token, {
        path: '/',
        'max-age': res.expires_in.toString(),
        domain: 'koobits.com',
      });
    }
    history.push('/dashboard');
  } catch (err) {
    console.log(err);
    window.location.replace(process.env.REACT_APP_MEMBERS_LOGIN_URL);
  }
};
const LoginRedirect = () => {
  const history = useHistory();
  const query = useQuery();
  const refreshToken = query.get('refreshToken');
  const clientID = query.get('clientID');
  useEffect(() => {
    if (!isNil(refreshToken) && !isNil(clientID)) {
      refreshTokenHandler(refreshToken, clientID, history);
    } else {
      window.location.replace(process.env.REACT_APP_MEMBERS_LOGIN_URL);
    }
  }, [clientID, history, refreshToken]);
  return <div />;
};

export default LoginRedirect;
