import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-color: #707070;
  .header-section {
    text-align: center;
    background-color: #d8e9f5;
    width: 100%;
    padding: 8px;
    border-radius: 10px 10px 0 0;
    color: #467fa8;
    font-size: 18px;
  }
  .body-section {
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    padding: 10px 20px 30px;
    min-width: 428px;
  }
  .warning-title {
    color: #ff0000;
    text-align: center;
  }
  .modal-title {
    font-size: 18px;
    margin: 5px 0;
  }
  .modal-desc {
    font-size: 15px;
  }
  .modal-buttons {
    text-align: center;
    margin-top: 20px;
    .btns {
      :first-child {
        margin-right: 25px;
      }
      border: none;
      width: 90px;
      padding: 3px 0;
      border-radius: 20px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
    }
    .no-btn {
      background-color: #ff6701;
      :hover {
        background-color: #ff5801;
      }
    }
    .yes-btn {
      background-color: #39c435;
      :hover {
        background-color: #2ebf2a;
      }
    }
    .ok-btn {
      background-color: ${(props) => (props.okBtn ? props.okBtn : '#39c435')};
      :hover {
        background-color: ${(props) => (props.okBtn ? '#ff5801' : '#2ebf2a')};
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .body-section {
      min-width: 0;
    }
    .modal-title {
      font-size: 16px;
    }
    .modal-buttons {
      .btns {
        font-size: 16px;
      }
    }
  }
`;
