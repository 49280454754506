import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import NavbarCollapse from 'components/Header/Navigations/NavbarCollapse';
import NavProfile from 'components/Header/Navigations/NavProfile';
import NavProfileMobile from 'components/Header/Navigations/NavProfileMobile';
import { plans, featureToggle } from 'constants/index';
import { useSelector, useDispatch } from 'react-redux';
import {
  getStudentDetails,
  setShowPremiumSchoolToggleModal,
  setHasSeenToggleTooltip,
  setIsExpired,
} from 'store/dashboard/studentDetailsSlice';
import { getCourses } from 'store/kooClass/kooClassSlice';
import { isNil } from 'ramda';
import PremiumSchoolIcon from 'assets/img/premiumschooltoggle/premium-school.png';
import SchoolPremiumIcon from 'assets/img/premiumschooltoggle/school-premium.png';
import TogglePlanModalContent from 'components/PremiumSchoolToggle/TogglePlanModalContent';
import ModalContentXButton from 'components/Shared/ModalContentXButton';
import { AutoFadeoutLoader } from 'components/Shared/Loader';
import { setCurrentProduct, setPlan, setSubject } from 'store/plan/planSlice';
import useIsMobile from 'hooks/responsive/useIsMobile';
import useResponsiveValue from 'hooks/responsive/useResponsiveValue';
import ToggleV2 from './ToggleV2';
import { HeaderNav } from './index.styles';

const defaultToggleMenu = true;
const defaultToggleProfile = true;
const defaultAccessMenu = true;

const Header = ({
  loading,
  hidePlan,
  toggleMenu = defaultToggleMenu,
  toggleProfile = defaultToggleProfile,
  parentPortal,
  toggleAccessMenu = defaultAccessMenu,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['switchPlan']);
  const {
    studentDetails,
    hasSeenToggleTooltip,
    hasPremiumSchoolPlan,
    isLoading,
    showPremiumSchoolToggleModal,
    isExpired,
  } = useSelector((state) => state.studentDetails);
  const { isParentAccount } = useSelector((state) => state.login);
  const { courses, isErr } = useSelector((state) => state.kooClass);
  const { plan, currentProduct, products, prevProduct } = useSelector(
    (state) => state.plan
  );
  const isSchool = plan === 'school';
  // COMPONENT STATES
  const [retryCount, setRetryCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [hasSeenLoader, setHasSeenLoader] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState(null);

  // Check the account is registered through localized route
  const IsLocalizedUser =
    (studentDetails && studentDetails.Roles.includes(107)) ?? false;

  // Responsive
  const isMobile = useIsMobile();
  const premiumToggleModalWidth = useResponsiveValue({
    mobile: '200px',
    tablet: '388px',
    desktop: '388px',
  });

  // Event Handlers
  const planClickHandler = () => {
    dispatch(setHasSeenToggleTooltip(true));
    dispatch(setShowPremiumSchoolToggleModal(true));
  };

  const logoClickHandler = () => {
    if (!isNil(prevProduct)) {
      dispatch(setSubject(prevProduct.subject.toLowerCase().trim()));
      dispatch(setPlan(prevProduct.productType.toLowerCase().trim()));
      if (prevProduct.isExpired) {
        dispatch(setIsExpired(true));
        dispatch(setCurrentProduct(null));
      } else {
        dispatch(setIsExpired(false));
        dispatch(setCurrentProduct(prevProduct));
      }
      history.push(
        `/dashboard?subject=${prevProduct.subject
          .toLowerCase()
          .trim()}&plan=${prevProduct.productType.toLowerCase().trim()}`
      );
      return;
    }
    if (isNil(currentProduct)) {
      const findNonExpProd = products.filter(
        (item) => item.nonExpiredSubscriptionProducts.length !== 0
      )[0];
      const findExpProd = products.filter(
        (item) => item.subscriptionProducts.length !== 0
      )[0];

      // set subject
      if (!isNil(findNonExpProd)) {
        const newSubject = findNonExpProd?.subject.toLowerCase().trim();
        const newPlan = findNonExpProd?.nonExpiredSubscriptionProducts[0]?.productType
          .toLowerCase()
          .trim();
        dispatch(setSubject(newSubject));
        dispatch(setPlan(newPlan));
        dispatch(
          setCurrentProduct(findNonExpProd?.nonExpiredSubscriptionProducts[0])
        );
        if (isExpired) {
          dispatch(setIsExpired(false));
        }
        history.push(`/dashboard?subject=${newSubject}&plan=${newPlan}`);
        return;
      }
      if (isNil(findNonExpProd)) {
        const newSubject = findExpProd?.subject.toLowerCase().trim();
        const newPlan = findExpProd?.subscriptionProducts[0]?.productType
          .toLowerCase()
          .trim();
        dispatch(setSubject(newSubject));
        dispatch(setPlan(newPlan));
        dispatch(setCurrentProduct(null));
        dispatch(setIsExpired(true));
        history.push(`/dashboard?subject=${newSubject}&plan=${newPlan}`);
        return;
      }
    }
    history.push(
      `/dashboard?subject=${currentProduct.subject
        .toLowerCase()
        .trim()}&plan=${currentProduct.productType.toLowerCase().trim()}`
    );
  };
  // USEEFFECTS

  useEffect(() => {
    // Retry student details
    if (
      isParentAccount === false &&
      studentDetails === null &&
      retryCount < 3 &&
      !isLoading
    ) {
      setRetryCount((prev) => {
        return prev + 1;
      });
      dispatch(getStudentDetails());
    }
  }, [dispatch, isLoading, isParentAccount, retryCount, studentDetails]);

  useEffect(() => {
    if (isParentAccount === false && isNil(courses) && isErr === null) {
      dispatch(getCourses());
    }
  }, [courses, dispatch, isErr, isParentAccount]);

  return (
    <HeaderNav plan={plan} hasSeenToggleTooltip={hasSeenToggleTooltip}>
      {!hasSeenLoader && (
        <AutoFadeoutLoader
          showLoader={showLoader}
          setHasSeen={setHasSeenLoader}
          setShowLoader={setShowLoader}
          redirectUrl={redirectUrl}
        />
      )}
      <ModalContentXButton
        showModal={showPremiumSchoolToggleModal}
        closeModal={() => {
          dispatch(setShowPremiumSchoolToggleModal(false));
        }}
        backdrop="static"
        containerMinWidth={premiumToggleModalWidth}
      >
        <TogglePlanModalContent
          setShowLoader={setShowLoader}
          setHasSeen={setHasSeenLoader}
        />
      </ModalContentXButton>
      <div className="navbar-brand">
        <Logo className="logo" onClick={logoClickHandler} />
        {featureToggle.science === true && hidePlan !== true && (
          <ToggleV2
            setShowLoader={setShowLoader}
            setHasSeenLoader={setHasSeenLoader}
            setRedirectUrl={setRedirectUrl}
            showLoader={showLoader}
            hasSeenLoader={hasSeenLoader}
          />
        )}
        {parentPortal && <span className="parent-portal">Parent</span>}
        {featureToggle.science !== true && !isLoading && hidePlan !== true && (
          <div className="plan">
            {hasPremiumSchoolPlan === false && !IsLocalizedUser && (
              <>
                <span className="plan-name">{plans[plan].planName}</span>{' '}
                <img src={plans[plan].planLogo} alt="" />
              </>
            )}
            {hasPremiumSchoolPlan === true && (
              <>
                <img
                  src={
                    plan === 'school' ? SchoolPremiumIcon : PremiumSchoolIcon
                  }
                  alt=""
                  onClick={planClickHandler}
                />
                <span className="tooltiptext">
                  <Trans i18nKey="switchPlan:tooltip" t={t}>
                    <p>You can switch the account by </p>
                    <p>clicking the logo.</p>
                  </Trans>
                </span>
              </>
            )}
          </div>
        )}
      </div>
      {((toggleMenu && !isMobile) || parentPortal) && <NavbarCollapse />}
      {isSchool
        ? toggleProfile && (
            <NavProfile
              parentPortal={parentPortal}
              toggleAccessMenu={toggleAccessMenu}
            />
          )
        : ((toggleProfile && !isMobile) || parentPortal) && (
            <NavProfile
              parentPortal={parentPortal}
              toggleAccessMenu={toggleAccessMenu}
            />
          )}
      {!isSchool && toggleProfile && isMobile && !parentPortal && (
        <NavProfileMobile parentPortal={parentPortal} />
      )}
    </HeaderNav>
  );
};

export default Header;
