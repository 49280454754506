import React, { useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import {
  getSentBFFRequests,
  resetSentPageError,
} from 'store/friends/friendsSlice';
import Spinner from 'components/Shared/Spinner';
import FriendCard from 'components/Friends/FriendCard';
import ErrorModal from 'components/Shared/ErrorModal';
import { friendsStatus } from 'constants/index';
import { Main, ShowDate, EmptyList } from './Incoming.styles';
import { TopMessage, StatusInfo } from './Sent.styles';

const StatusSection = ({ statusColor, status }) => {
  return <StatusInfo status={statusColor}>{status}</StatusInfo>;
};

const NoRequest = ({ t }) => {
  return (
    <EmptyList>
      <h4>
        {t(
          'friends:sentRequest.noRequest',
          'Looks like there is no request yet.'
        )}
      </h4>
      <div>
        {t(
          'friends:sentRequest.startSending',
          'Start by sending an invitation to your BFFs!'
        )}
      </div>
    </EmptyList>
  );
};

const Sent = () => {
  const { t } = useTranslation(['friends']);
  const dispatch = useDispatch();
  const { sentRequestLoading, sentReqErr, sentRequests } = useSelector(
    (state) => state.friends
  );
  const searchAction = () => {
    dispatch(getSentBFFRequests());
  };
  useEffect(() => {
    searchAction();
    // eslint-disable-next-line
  }, []);
  // Clean up
  useEffect(() => {
    return () => dispatch(resetSentPageError());
  }, [dispatch]);
  const renderError = () => {
    if (!sentRequestLoading && sentReqErr !== null) {
      if (sentReqErr === 'No Data Found') {
        return <NoRequest t={t} />;
      }
      return (
        <ErrorModal errorMessage={sentReqErr} reloadAction={searchAction} />
      );
    }
  };
  return (
    <>
      <Main>
        {sentRequestLoading && <Spinner />}
        {!sentRequestLoading &&
          sentReqErr === null &&
          (sentRequests.length === 0 ? (
            <NoRequest t={t} />
          ) : (
            <>
              <TopMessage>
                {t(
                  'friends:sentRequest.reqHistory',
                  'Request history (past 3 months)'
                )}
              </TopMessage>
              {sentRequests.map((friend) => (
                <Fragment key={friend.Userid}>
                  <FriendCard
                    friendDetails={{
                      avatar: friend.UserAvatarImage,
                      name: friend.Fullname,
                      schoolName: friend.SchoolName,
                      schoolLogo: friend.SchoolLogoImage,
                      BFFCode: friend.BFFCode,
                    }}
                    rightSection={
                      <StatusSection
                        statusColor={
                          friendsStatus[friend.requestinfo.RequestStatus]
                        }
                        status={t(
                          `friends:sentRequest.${
                            friendsStatus[friend.requestinfo.RequestStatus]
                          }`,
                          `${friendsStatus[friend.requestinfo.RequestStatus]}`
                        )}
                      />
                    }
                  />
                  <ShowDate>
                    {moment(friend.requestinfo.ActionTakenDateTime).format(
                      'DD-MMM-YYYY HH:mm'
                    )}
                  </ShowDate>
                </Fragment>
              ))}
            </>
          ))}
        {renderError()}
      </Main>
    </>
  );
};

export default Sent;
