import jwt_decode from 'jwt-decode';
import { isNil } from 'ramda';

const logUserID = (jwt, asayerTracker, openReplayTracker) => {
  if (isNil(jwt) || (isNil(asayerTracker) && isNil(openReplayTracker))) {
    // no-op
    return;
  }
  try {
    const decoded = jwt_decode(jwt);
    const { user_name: userName, user_id: userID } = decoded;
    if (!isNil(asayerTracker)) {
      asayerTracker.userID(userName);
      asayerTracker.userAnonymousID(userID);
    }
    if (!isNil(openReplayTracker)) {
      openReplayTracker.setUserID(userName);
    }
  } catch (err) {
    console.log(err);
  }
};

export const logNetworkRequestAsEvent = (
  requestName,
  requestBody,
  responseBody,
  asayerTracker,
  openReplayTracker
) => {
  if (
    process.env.REACT_APP_ASAYER !== 'true' ||
    (isNil(asayerTracker) && isNil(openReplayTracker))
  ) {
    // no-op
    return;
  }
  const eventName = requestName ?? '';
  const payload = {
    requestBody: requestBody ?? null,
    responseBody: responseBody ?? null,
  };
  if (!isNil(asayerTracker)) asayerTracker.event(eventName, payload);
  if (!isNil(openReplayTracker)) openReplayTracker.issue(eventName, payload);
};

export const attachOpenReplaySessionToken = (headers, openReplayTracker) => {
  if (process.env.REACT_APP_ASAYER !== 'true' || isNil(openReplayTracker)) {
    // no-op
    return headers;
  }
  const newHeaders = headers;
  const sessionToken = openReplayTracker.getSessionToken();
  if (sessionToken) {
    newHeaders['X-OpenReplay-SessionToken'] = sessionToken;
  }
  return newHeaders;
};

export default logUserID;
