import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Unlock } from 'assets/img/storymath/icon-unlock.svg';
import { ReactComponent as KokoCredit } from 'assets/img/icon-koko-credits.svg';
import { ReactComponent as Mission } from 'assets/img/access-menu/mission.svg';
import Button from 'components/Shared/Button';
import { ErrorMessage } from 'components/StoryMath/UnlockAlert.styles';
import {
  Container,
  AlertWrapper,
  AlertHeader,
  AlertHeaderTitle,
  AlertContent,
  AlertMessage,
  AlertAction,
  AlertFooter,
  CloseButton,
} from './UnlockAlert.styles';

const UnlockAlert = ({
  book,
  status,
  onAccept,
  onReject,
  error,
  isLoading,
}) => {
  const { t, i18n } = useTranslation(['storyScience']);
  const history = useHistory();
  const header =
    status === 'Locked' ? (
      <p className="locked-message">
        <Trans i18nKey="storyScience:modalMessage" t={t}>
          <KokoCredit className="koko-icon" />
          <span className="koko">20</span>KoKo to unlock
        </Trans>
      </p>
    ) : (
      t('storyScience:chapterUnlocked', 'Chapter Unlocked!')
    );
  const message =
    status === 'Locked'
      ? t(`storyScience.storyTitle${book.Id}`, book.Title)
      : t(
          'storyScience:unlockSuccess',
          "You've successfully unlocked the chapter!"
        );

  const isNotChinese = i18n.language === 'en' || i18n.language === 'ba';

  return (
    <Container>
      <AlertWrapper>
        <AlertHeader>
          <CloseButton onClick={onReject}>X</CloseButton>
          <Unlock className="unlock-icon" />
          <AlertHeaderTitle>{header}</AlertHeaderTitle>
        </AlertHeader>
        <AlertContent>
          <AlertMessage lang={isNotChinese}>{message}</AlertMessage>
        </AlertContent>
        <ErrorMessage>{error ?? ''}</ErrorMessage>
        <AlertAction>
          {status === 'Locked' && (
            <Button variant="secondary" onClick={onReject} fontSize="16px">
              <p>{t('storyScience:cancel', 'Cancel')}</p>
            </Button>
          )}
          {status === 'Locked' ? (
            <Button
              variant="primary"
              fontWeight="regular"
              onClick={onAccept}
              padding="5px 20px"
              fontSize="16px"
              isDisabled={isLoading}
            >
              <p className="btn-text">
                <Trans i18nKey="storyScience:unlock" t={t}>
                  <KokoCredit className="koko-icon-btn" />
                  <span className="bold-text">20</span>
                  KoKo<span className="bold-text">to unlock</span>
                </Trans>
              </p>
            </Button>
          ) : (
            <Button onClick={onAccept} variant="primary">
              {t('storyScience:ok', 'OK')}
            </Button>
          )}
        </AlertAction>
        {status === 'Locked' && (
          <AlertFooter>
            <p className="footer-text">
              {t('storyScience:notEnough', 'Not enough KoKo? Earn more at')}
            </p>
            <Button
              variant="blue4"
              padding="0.4rem 1rem"
              onClick={() => {
                history.push('/mission/curriculum-list');
              }}
              fontSize="14px"
            >
              <p className="mission-text">
                <span>
                  <Mission className="mission-icon" />
                </span>
                {t('storyScience:mission', 'Mission')}
              </p>
            </Button>
          </AlertFooter>
        )}
      </AlertWrapper>
    </Container>
  );
};

export default React.memo(UnlockAlert);
