import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './CongratsText.styles';

const CongratsText = () => {
  const { t } = useTranslation(['testSubmitted']);

  return (
    <Container>
      <h3 className="stroke">
        {t('testSubmitted:words.Congrats', 'Congratulations!')}
      </h3>
      <h3 className="fill">
        {t('testSubmitted:words.Congrats', 'Congratulations!')}
      </h3>
    </Container>
  );
};

export default CongratsText;
