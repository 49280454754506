import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchKoKoHistory } from 'services/kokoHistory';
import { fetchWrapper } from 'services/login';

// get KoKo History
export const getKoKoHistory = createAsyncThunk(
  'kokoHistory',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchKoKoHistory, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get KoKo History failed');
    }
  }
);

const initialState = {
  isLoading: true,
  isError: null,
  TotalEarned: 0,
  TotalSpent: 0,
  Balance: 0,
  Transactions: [],
};

const kokoHistorySlice = createSlice({
  name: 'kokoHistory',
  initialState,
  reducers: {},
  extraReducers: {
    [getKoKoHistory.pending]: () => {
      return initialState;
    },
    [getKoKoHistory.fulfilled]: (state, action) => {
      const { TotalEarned, TotalSpent, Balance, Transactions } = action.payload;
      state.isLoading = false;
      state.TotalEarned = TotalEarned;
      state.TotalSpent = TotalSpent;
      state.Balance = Balance;
      state.Transactions = Transactions;
    },
    [getKoKoHistory.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
  },
});

const { reducer } = kokoHistorySlice;
export default reducer;
