import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/bff/bff-bg.svg';

import {
  Topbar as ChampionClassTopBar,
  BrainHeading as BrainHeadingBff,
} from '../TopBrain/TopBrainHeader.styles';

export const Topbar = styled(ChampionClassTopBar)`
  .scoring-period {
    flex-direction: column;
    span,
    .physical-prize {
      color: #374b09;
      font-size: 16px;
    }
  }
  background-image: url(${HeaderBg});
  background-size: cover;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 4%;
`;

export const Container = styled.div`
  width: 90%;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  flex-direction: column;
  img {
    margin-left: 30px;
  }
`;

export const BrainHeading = styled(BrainHeadingBff)`
  width: 40%;
  align-self: flex-end;
  color: #ffffff;
  text-shadow: 4px 5px #0090b2;
  font-family: 'Grilled Cheese';
`;
