import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ScrollIcon from 'assets/img/icon-scroll.svg';
import InactiveScrollIcon from 'assets/img/icon-scroll-inactive.svg';
import LogoPlaceholder from 'assets/img/kooclass/activity/icon-qn-mark-small.svg';

import { isNil } from 'ramda';
import { Container, VerticalLine } from './ActivityNavItem.styles';

const ActivityNavItem = ({
  active,
  activityName,
  icon,
  onClick,
  scroll,
  totalScroll,
  id,
}) => {
  const { t } = useTranslation(['activities']);
  const { videoIsFullScreen } = useSelector((state) => state.kooClass);
  return (
    <Container
      active={active}
      onClick={onClick}
      scroll={totalScroll !== 0}
      data-cy={id}
    >
      <div className="line">
        <VerticalLine scroll={totalScroll !== 0} />
        <div className="dot">{active && <div className="inner-dot" />}</div>
        {active && !videoIsFullScreen && <div className="arrow" />}
        <VerticalLine scroll={totalScroll !== 0} />
      </div>
      <div className="act-item">
        <div className="name">
          <span>
            <img
              src={!isNil(icon) ? icon : LogoPlaceholder}
              onError={(e) => (e.target.src = LogoPlaceholder)}
              alt="activity-icon"
            />
          </span>
          {t(
            `activities:activityName.${activityName.toLowerCase().trim()}`,
            activityName
          )}
        </div>
        {totalScroll !== 0 && (
          <div className="scroll">
            {scroll} / {totalScroll}{' '}
            {t('activities:activityNav.scroll', 'Scroll')}
            <span>
              <img
                src={scroll !== 0 ? ScrollIcon : InactiveScrollIcon}
                alt="scroll-icon"
              />
            </span>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ActivityNavItem;
