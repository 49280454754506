import FullEnergy from 'assets/img/multiplayer/icon-energy-full.svg';
import EmptyEnergy from 'assets/img/multiplayer/icon-energy-empty.svg';
import ScienceFullEnergy from 'assets/img/multiplayer/icon-science-energy-full.svg';
import IncomingV2Icon from 'assets/img/multiplayer/icon-incoming-challenge-v2.svg';
import IncomingIcon from 'assets/img/multiplayer/icon-math-incoming.svg';
import ScienceIncomingIcon from 'assets/img/multiplayer/icon-science-incoming.svg';
import HistoryIcon from 'assets/img/multiplayer/icon-history-challenge.svg';
import ScienceHistoryIcon from 'assets/img/multiplayer/icon-science-history.svg';
import PencilMascot from 'assets/img/multiplayer/icon-pencil.svg';
import RobotMascot from 'assets/img/multiplayer/robot-mascot.svg';
import HeaderBg from 'assets/img/multiplayer/new-challenge-header-bg.svg';
import ScienceHeaderBg from 'assets/img/multiplayer/science-pc-bg.png';
import FullEnergyShadow from 'assets/img/multiplayer/icon-energy-full-shadow.svg';
import ScienceFullEnergyShadow from 'assets/img/multiplayer/icon-science-energy-full-shadow.svg';
import EmptyEnergyShadow from 'assets/img/multiplayer/icon-energy-empty-shadow.svg';
import ScienceEmptyEnergyShadow from 'assets/img/multiplayer/icon-science-energy-empty-shadow.svg';
import CPIcon from 'assets/img/icon-challenge-point.svg';

import PeerChallengeIcon from 'assets/img/multiplayer/icon-peer-challenge-main.svg';
import SciencePeerChallengeIcon from 'assets/img/multiplayer/icon-science-peer-challenge-main.svg';
import ScienceCPIcon from 'assets/img/science-cp-icon.svg';
import MathRulesIcon from 'assets/img/multiplayer/icon-math-rules.svg';
import ScienceRulesIcon from 'assets/img/multiplayer/icon-science-rules.svg';
import MathMyClassmateIcon from 'assets/img/multiplayer/math-my-classmate.svg';
import MathMyFriendIcon from 'assets/img/multiplayer/math-my-friend.svg';
import MathRandomChallengerIcon from 'assets/img/multiplayer/math-random-challenger.svg';

export const peerChallengeResultStatus = {
  win: {
    text: 'You Win',
    color: '#FF9100',
  },
  lose: {
    text: 'You lose',
    color: '#0FA1D1',
  },
  draw: {
    text: 'Draw',
    color: '#FF9100',
  },
  pending: { text: 'Pending', color: '#0FA1D1' },
};

// For Multiplayer List page
export const multiplayerListStyles = {
  math: {
    card: {
      background: 'linear-gradient(#ffad09, #ff9327)',
    },
    energyIcon: {
      full: FullEnergy,
      fullShadow: FullEnergyShadow,
      empty: EmptyEnergy,
      emptyShadow: EmptyEnergyShadow,
    },
    titleIcon: PeerChallengeIcon,
    incomingIcon: IncomingIcon,
    incomingV2Icon: IncomingV2Icon,
    historyIcon: HistoryIcon,
    rulesIcon: MathRulesIcon,
    mascotImg: PencilMascot,
    backBtnBgColour: 'rgb(214, 125, 7, 0.51)',
    headerBgImg: HeaderBg,
    activeTabColour: '#EE9118',
    qnView: {
      bgColour: '#FFA200',
      logoBgColour: '#FF8000',
      btnBgColour: 'rgb(229, 130, 31, 0.62)',
      hasBgImg: true,
      mainBgColour: '#87796d',
      helperSidebarBgColour: 'rgb(80, 68, 57, 0.21)',
    },
    cpIcon: CPIcon,
  },
  science: {
    card: { background: '#65C300' },
    energyIcon: {
      full: ScienceFullEnergy,
      fullShadow: ScienceFullEnergyShadow,
      empty: EmptyEnergy,
      emptyShadow: ScienceEmptyEnergyShadow,
    },
    titleIcon: SciencePeerChallengeIcon,
    incomingIcon: ScienceIncomingIcon,
    incomingV2Icon: IncomingV2Icon,
    historyIcon: ScienceHistoryIcon,
    rulesIcon: ScienceRulesIcon,
    mascotImg: RobotMascot,
    backBtnBgColour: 'rgba(65, 126, 0, 0.57)',
    headerBgImg: ScienceHeaderBg,
    activeTabColour: '#59A507',
    qnView: {
      bgColour: '#6FC316',
      logoBgColour: '#52A000',
      btnBgColour: 'rgba(65, 126, 0, 0.62)',
      hasBgImg: false,
      mainBgColour: '#55655B',
      helperSidebarBgColour: 'rgba(26, 72, 35, 0.21)',
    },
    cpIcon: ScienceCPIcon,
  },
};

// New Challenge Page
// 2: My Classmate
// 3: Random Challenger
// 4: My Friend
const defaultStyles = {
  headerBgColour: {
    2: '#F7AB02',
    3: '#FF9A07',
    4: '#F88526',
  },
  headerTitleTextShadow: {
    2: '0 3px 4px rgba(198, 137, 22, 0.51)',
    3: '0 3px 4px rgba(185, 85, 0, 0.16)',
    4: '0 3px 4px rgba(0, 0, 0, 0.08)',
  },
  iconThumbnails: {
    2: MathMyClassmateIcon,
    3: MathRandomChallengerIcon,
    4: MathMyFriendIcon,
  },
  iconThumbnailBgColour: {
    2: '#FFBA00',
    3: '#FFAD3B',
    4: '#FF9134',
  },
  selectedOutlineStyle: '3px solid #FF7121',
};
export const NewChallengeCardStyles = {
  math: defaultStyles,
  science: {
    headerBgColour: {
      2: '#4CA500',
      3: '#4CA500',
      4: '#4CA500',
    },
    headerTitleTextShadow: {
      2: '0 3px 4px rgba(185, 85, 0, 0.16)',
      3: '0 3px 4px rgba(185, 85, 0, 0.16)',
      4: '0 3px 4px rgba(185, 85, 0, 0.16)',
    },
    iconThumbnails: {
      2: MathMyClassmateIcon,
      3: MathRandomChallengerIcon,
      4: MathMyFriendIcon,
    },
    iconThumbnailBgColour: {
      2: '#76C92E',
      3: '#76C92E',
      4: '#76C92E',
    },
    selectedOutlineStyle: '3px solid #6BBF22',
  },
};
