import styled from 'styled-components';

export const StyledTextAreaWrap = styled.div`
  position: relative;
  display: inline-flex;
  max-width: 100%;
  overflow-y: hidden;
  flex-direction: column;
  .template {
    width: fit-content;
    position: relative;
    word-break: break-word;
    padding: 2px 4px;
    border-width: 1px;
  }
`;
export const StyledTextArea = styled.textarea`
  width: ${({ textareaWidth, className }) =>
    className === 'wrong-input' ? `${textareaWidth}px` : '140px'};
  max-width: ${({ formWidth, className }) =>
    className === 'wrong-input' ? `${formWidth}px` : '140px'};
  resize: none;
  min-height: ${({ textareaHeight, className }) =>
    className === 'wrong-input' ? textareaHeight : '36px'};
  padding: 0px 0.5rem;
  text-wrap: ${({ className }) => className !== 'wrong-input' && 'nowrap'};
  overflow: hidden;
  white-space: ${({ className }) => className === 'wrong-input' && 'pre-wrap'};
`;
export const StyledAnswerResultIcon = styled.img`
  position: absolute;
  right: 6px;
  bottom: 10px;
`;
