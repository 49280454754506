import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { subjectNames } from 'constants/products';
import { getRevisionId } from 'store/kooQuiz/kooQuizSlice';

const paperValToCategoryNameMapping = {
  1: 'Essential Skills',
  2: 'Top Papers',
  3: 'HOTS',
  4: 'OED',
};

const useKooQuizRevision = (
  subjectID,
  paperId,
  paperTypeId,
  initialLoad = false
) => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.plan);
  const [refreshCount, setRefreshCount] = useState(0);
  const categoryName = paperValToCategoryNameMapping[paperTypeId];

  // Refresh Revision Data
  const refreshDataHandler = () => {
    setRefreshCount((prevState) => prevState + 1);
  };
  useEffect(() => {
    const getProductFeatureCategoryCourseId = (productsList, subjectId) => {
      // Find subject product based on current paper's subject ID
      const currentParentProduct = productsList.find(
        (product) =>
          subjectNames[product.subject?.toLowerCase().trim()] === subjectId
      );
      if (isNil(currentParentProduct)) return '';
      if (currentParentProduct.nonExpiredSubscriptionProducts.length <= 0)
        return '';
      const firstSubscriptionProductKooQuizFeature = currentParentProduct.nonExpiredSubscriptionProducts[0].features?.find(
        (feature) => feature.feature?.toLowerCase().trim() === 'kooquiz'
      );
      if (isNil(firstSubscriptionProductKooQuizFeature)) return '';
      return firstSubscriptionProductKooQuizFeature.parameters.Category[
        categoryName
      ];
    };
    if (initialLoad && products && paperId && subjectID && categoryName) {
      const courseIds = getProductFeatureCategoryCourseId(products, subjectID);
      if (courseIds.length <= 0) return;
      dispatch(getRevisionId({ subjectId: subjectID, paperId, courseIds }));
    }
  }, [
    dispatch,
    initialLoad,
    subjectID,
    categoryName,
    products,
    paperId,
    refreshCount,
  ]);
  return {
    refreshRevisionData: refreshDataHandler,
  };
};

export default useKooQuizRevision;
