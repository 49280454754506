import { rules } from 'constants/superSpeed';

function isMatchingLevelAndDifficulty(item, level, difficulty) {
  let currentLevel = level;
  // levels 4 to 6 share the same game rules
  if (level > 4) {
    currentLevel = 4;
  }
  return item.level === currentLevel && item.difficulty === difficulty;
}

function getNumBetween(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

function getOperation() {
  const operations = ['addition', 'subtraction', 'multiplication', 'division'];
  const index = getNumBetween(0, operations.length);
  return operations[index];
}

function setOperationAndRules(criteria, gameRules) {
  const newCriteria = criteria;
  if (!newCriteria.operation || !newCriteria.rules) {
    const operation = getOperation();

    for (let i = 0; i < gameRules.length; i += 1) {
      if (
        isMatchingLevelAndDifficulty(
          gameRules[i],
          newCriteria.level,
          newCriteria.difficulty
        )
      ) {
        const cRules = gameRules[i].constraints[operation];
        if (!cRules) {
          return setOperationAndRules(newCriteria, gameRules);
        }

        newCriteria.operation = operation;
        newCriteria.rules = cRules;
        return newCriteria;
      }
    }
  }
  return newCriteria;
}

function isValidAnswer(answer, criteria) {
  // answer is valid if:
  // generate set of possible operands and choose 1 from it?
  // loop through 1 operand and break if the other operand is ok
  let min;
  let max;
  switch (criteria.operation) {
    case 'addition':
      min = criteria.rules.operand1.min + criteria.rules.operand2.min;
      max = criteria.rules.operand1.max + criteria.rules.operand2.max;
      if (answer >= min && answer <= max) return true;
      break;
    case 'subtraction':
      min = criteria.rules.operand1.min - criteria.rules.operand2.max;
      if (min < 0) min = criteria.rules.result.min;
      max = criteria.rules.operand1.max - criteria.rules.operand2.min;
      if (answer >= min && answer <= max) return true;
      break;
    case 'multiplication':
      for (
        let multiplicand = criteria.rules.operand1.min;
        multiplicand <= criteria.rules.operand1.max;
        multiplicand += 1
      ) {
        // first find a factor of the answer
        if (answer % multiplicand === 0) {
          // then get the other factor
          const multiplier = answer / multiplicand;
          // and check whether it's within range
          if (
            multiplier >= criteria.rules.operand2.min &&
            multiplier <= criteria.rules.operand2.max
          ) {
            return true;
          }
        }
      }
      break;
    case 'division':
      for (
        let dividend = criteria.rules.operand1.max;
        dividend >= criteria.rules.operand1.min;
        dividend -= 1
      ) {
        const divisor = dividend / answer;
        if (
          Number.isInteger(divisor) &&
          divisor >= criteria.rules.operand2.min &&
          divisor <= criteria.rules.operand2.max
        ) {
          return true;
        }
      }
      break;
    default:
      return false;
  }
  return false;
}

function generateAnswer(criteria) {
  const answer = getNumBetween(
    criteria.rules.result.min,
    criteria.rules.result.max + 1
  );
  if (!isValidAnswer(answer, criteria)) {
    return generateAnswer(criteria);
  }
  return answer;
}

function generateOperand(constraints) {
  // adding 1 to max because algorithmn excludes max
  return getNumBetween(constraints.min, constraints.max + 1);
}

function hasValidOperands(question) {
  // operands have to be:
  // between the range
  // integers
  if (
    question.operand1 < question.rules.operand1.min ||
    question.operand1 > question.rules.operand1.max ||
    !Number.isInteger(question.operand1) ||
    question.operand2 < question.rules.operand2.min ||
    question.operand2 > question.rules.operand2.max ||
    !Number.isInteger(question.operand2)
  ) {
    return false;
  }
  return true;
}

function addOperands(question) {
  const newQuestion = question;
  // generate operand 1
  const operand1 = generateOperand(question.rules.operand1);

  // based on operand 1, generate operand 2
  let operand2;
  // check if operand 2 is valid
  switch (newQuestion.operation) {
    case 'addition':
      operand2 = newQuestion.answer - operand1;
      break;

    case 'subtraction':
      operand2 = operand1 - newQuestion.answer;
      break;

    case 'multiplication':
      operand2 = newQuestion.answer / operand1;
      break;

    case 'division':
      operand2 = operand1 / newQuestion.answer;
      break;

    default:
      return false;
  }

  newQuestion.operand1 = operand1;
  newQuestion.operand2 = operand2;

  // if not valid, do this again
  if (!hasValidOperands(newQuestion)) {
    return addOperands(newQuestion);
  }

  return newQuestion;
}

export function generateCriteria(difficulty, level) {
  let criteria = {
    difficulty,
    level,
  };
  criteria = setOperationAndRules(criteria, rules);
  return criteria;
}

export function generateQuestion(criteria) {
  let question = criteria;
  // get an answer first so that there's even chances of
  // every answer

  const answer = generateAnswer(criteria);
  question.answer = answer;
  question = addOperands(question);
  // store the time that the question has started
  question.started = Date.now();
  return question;
}
