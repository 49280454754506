import React from 'react';
import Star from 'assets/img/dailychallenge/difficulty-star.svg';
import EmptyStar from 'assets/img/dailychallenge/difficulty-star-empty.svg';
import DifficultySetting from 'assets/img/dailychallenge/difficulty-settings-icon.svg';

const fullStars = 4;

const DifficultyStarsSetting = ({ numOfStars }) => {
  const stars = Array(numOfStars).fill(Star);
  const remainingStars = Array(fullStars - numOfStars).fill(EmptyStar);

  return (
    <>
      {stars.map((star, index) => (
        <img
          src={star}
          alt="star"
          key={index}
          style={{ marginRight: '0.2rem' }}
          data-cy="star-icon"
        />
      ))}
      {remainingStars.map((emptyStar, index) => (
        <img
          alt="star-empty"
          src={emptyStar}
          key={index}
          data-cy="empty-star-icon"
          style={{ marginTop: '0.03rem', marginRight: '0.2rem' }}
        />
      ))}
      <img
        alt="setting"
        src={DifficultySetting}
        style={{ marginLeft: '0.1rem' }}
      />
    </>
  );
};

export default DifficultyStarsSetting;
