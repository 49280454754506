import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import missionBg from 'assets/img/dashboardv2/mission.png';
import missionBgTablet from 'assets/img/dashboardv2/missionTablet.png';
import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import missionPlusBg from 'assets/img/dashboardv2/mission-plus.png';
import missionPlusMobile from 'assets/img/dashboardv2/mission-plus-mobile.png';
import plusIcon from 'assets/img/dashboardv2/mission-plus-icon.svg';
import { StyledContainer, StyledContent } from './MissionCard.styles';

const MissionImage = ({ isMissionPlus }) => {
  const imgSrc = useResponsiveImage(
    missionBg,
    missionBgTablet,
    missionBgTablet
  );
  const imgPlus = useResponsiveImage(
    missionPlusBg,
    missionPlusMobile,
    missionPlusMobile
  );
  return <img src={isMissionPlus ? imgPlus : imgSrc} alt="mission" />;
};

const MissionCard = () => {
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const { subject } = useSelector((state) => state.plan);
  const isMissionPlus = useCallback(() => {
    if (subject === 'math') {
      return false;
    }
    if (subject === 'science') {
      return true;
    }
  }, [subject]);
  const clickHandler = () => {
    return history.push('/mission/curriculum-list');
  };
  return (
    <StyledContainer onClick={clickHandler} data-cy="mission-card">
      <div>
        <MissionImage isMissionPlus={isMissionPlus()} />
        <StyledContent>
          <h3>
            {t('mission.name', 'Mission')}
            {isMissionPlus() && (
              <img src={plusIcon} alt="plus" data-cy="mission-plus-icon" />
            )}
          </h3>
          <p>
            {t(
              'mission.description',
              'Practice by topics. Learn at your own pace.'
            )}
          </p>
        </StyledContent>
      </div>
    </StyledContainer>
  );
};
export default MissionCard;
