import React, { useEffect, useCallback } from 'react';
import { history } from 'Routes';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { AvatarRendererFromImage } from 'components/AvatarCreator/AvatarRenderer';
import { redirectToOld } from 'helpers/redirectToOldPortal';
import { subjectToPlanMapping } from 'constants/index';
import { rwd } from 'Theme';
import { useMediaQuery } from 'react-responsive';
import { Container, Bar, KoKoMonster, ExpLevel } from './StudentDetails.styles';
import XpDetails from './XpDetails';

// Helper for getting school name for display
const getSchoolName = ({
  subscriptionData,
  hasPremiumSchoolPlan,
  nonExpiredSubscriptionPlans,
  planToShow,
}) => {
  if (hasPremiumSchoolPlan) {
    if (nonExpiredSubscriptionPlans.length <= 0) return '';
    const schoolPlanIndex = nonExpiredSubscriptionPlans.findIndex(
      (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
    );
    // PlanId === 4 is for Math olympiad
    const premiumPlanIndex = nonExpiredSubscriptionPlans.findIndex(
      (plan) => plan.PlanId === 3 || plan.PlanId === 4
    );
    if (planToShow === 'school' && schoolPlanIndex !== -1) {
      return nonExpiredSubscriptionPlans[schoolPlanIndex].Schoolname;
    }
    if (planToShow === 'premium' && premiumPlanIndex !== -1) {
      return nonExpiredSubscriptionPlans[premiumPlanIndex].Schoolname;
    }
  } else {
    return subscriptionData.Schoolname;
  }
};

const StudentDetails = () => {
  // REDUX
  const dispatch = useDispatch();
  const { plan, subject } = useSelector((state) => state.plan);
  const {
    studentDetails,
    subscriptionData,
    nonExpiredSubscriptionPlans,
    hasPremiumSchoolPlan,
    isLoading,
    error,
  } = useSelector((state) => state.studentDetails);
  const { rewards, error: xpError, isLoading: xpLoading } = useSelector(
    (state) => state.rewards
  );
  const isAvatarExpClickable = !useMediaQuery({ maxWidth: rwd.mobileL });

  // USEEFFECT
  useEffect(() => {
    if (
      process.env.REACT_APP_LOGIN_REDIRECT === 'true' &&
      studentDetails &&
      studentDetails.UserPreferences
    ) {
      if (studentDetails.UserPreferences.Value === 'PROBLEMSUMS') {
        redirectToOld();
      }
    }
  }, [dispatch, studentDetails]);

  const schoolName =
    subscriptionData !== null && nonExpiredSubscriptionPlans !== null
      ? getSchoolName({
          subscriptionData,
          hasPremiumSchoolPlan,
          nonExpiredSubscriptionPlans,
          planToShow: plan,
        })
      : '';

  const navigateToAvatarCreator = useCallback(() => {
    if (!isAvatarExpClickable) return;
    history.push('/dashboard/KoKoMonster');
  }, [isAvatarExpClickable]);

  const renderDetails = () => {
    if (isLoading) {
      return <Spinner animation="border" style={{ marginTop: '15px' }} />;
    }
    if (error !== null) {
      return <span>{error}</span>;
    }
    return (
      <>
        <span className="name">
          {studentDetails && studentDetails.FullName}
        </span>
        <span className="school">
          {(studentDetails && studentDetails.SchoolLogo === null) || error ? (
            ' '
          ) : (
            <img
              src={studentDetails && studentDetails.SchoolLogo}
              alt="School Logo"
              width="24px"
              height="24px"
              className="logo"
            />
          )}

          {schoolName}
        </span>
      </>
    );
  };

  return (
    <Container>
      <KoKoMonster
        isClickable={isAvatarExpClickable}
        onClick={navigateToAvatarCreator}
        plan={plan}
      >
        <AvatarRendererFromImage
          body={studentDetails && studentDetails.KokoMonster.Body}
          eyes={studentDetails && studentDetails.KokoMonster.Eyes}
          horn={studentDetails && studentDetails.KokoMonster.Horn}
          mouth={studentDetails && studentDetails.KokoMonster.Mouth}
        />
      </KoKoMonster>
      <Bar background={subjectToPlanMapping(subject, plan).studentDetailsBg}>
        <div className="details">{renderDetails()}</div>
      </Bar>
      <ExpLevel>
        <XpDetails
          isLoading={xpLoading}
          error={xpError}
          data={rewards}
          isClickable={isAvatarExpClickable}
          subject={subject}
        />
      </ExpLevel>
    </Container>
  );
};

export default StudentDetails;
