import React from 'react';
import { Container, Circle } from './RadioButton.styles';

const RadioButton = ({ isActive }) => {
  return (
    <Container>
      <Circle isActive={isActive} />
    </Container>
  );
};

export default RadioButton;
