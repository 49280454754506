import React from 'react';
import { Dropdown } from 'react-bootstrap';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { Container } from './SeasonsDropdown.styles';

// Helper
const replaceSeasonString = (seasonName, seasonString) => {
  return seasonName.replace('Season', seasonString);
};

const SeasonsDropdown = ({
  currentSeason,
  dropdownItems,
  setDropdownItems,
  data,
  setSeason,
  setYear,
  currentSeasonValue,
  currentYear,
  currentData,
}) => {
  const { t } = useTranslation(['leaderboard']);
  return (
    <Container>
      <DropdownMenu
        dataCy="dropdown-season"
        selectedValue={
          dropdownItems === '' || currentSeason
            ? t('leaderboard:topBrainBanner.currentSeason', `Current Season`)
            : replaceSeasonString(
                dropdownItems.Name,
                t('leaderboard:topBrainBanner.season', `Season`)
              )
        }
        indexKey
        values={data}
        valueKey="Name"
        valueTemplate={(value) =>
          replaceSeasonString(
            value,
            t('leaderboard:topBrainBanner.season', `Season`)
          )
        }
        setValue={setDropdownItems}
        width="180px"
      >
        <Dropdown.Item
          onClick={(event) => {
            event.preventDefault();
            setDropdownItems(currentData[0]);
            setYear(currentYear);
            setSeason(currentSeasonValue);
          }}
        >
          {t('leaderboard:topBrainBanner.currentSeason', `Current Season`)}
        </Dropdown.Item>
      </DropdownMenu>
    </Container>
  );
};

export default SeasonsDropdown;
