import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchUserPreferences,
  postUserPreferences,
} from 'services/userPreferences';
import { fetchWrapper } from 'services/login';

export const getUserPreferences = createAsyncThunk(
  'userPreferences/getUserPreferences',
  async () => {
    try {
      const res = await fetchWrapper(fetchUserPreferences);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get user preferences failed');
    }
  }
);

export const updateUserPreferences = createAsyncThunk(
  'userPreferences/updateUserPreferences',
  async (value) => {
    try {
      const res = await fetchWrapper(postUserPreferences, value);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update user preferences failed');
    }
  }
);

const initialState = {
  preferences: null,
  updatePreferences: null,
  isLoading: false,
  error: null,
};

const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {},
  extraReducers: {
    [getUserPreferences.pending]: (state) => {
      state.isLoading = true;
      state.preferences = null;
      state.error = null;
    },
    [getUserPreferences.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.preferences = action.payload;
      state.error = null;
    },
    [getUserPreferences.rejected]: (state, action) => {
      state.isLoading = false;
      state.preferences = null;
      state.error = action.error.message;
    },
    [updateUserPreferences.pending]: (state) => {
      state.isLoading = true;
      state.updatePreferences = null;
      state.error = null;
    },
    [updateUserPreferences.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.updatePreferences = action.payload;
      state.error = null;
    },
    [updateUserPreferences.rejected]: (state, action) => {
      state.isLoading = false;
      state.updatePreferences = null;
      state.error = action.error.message;
    },
  },
});

const { reducer } = userPreferencesSlice;
export default reducer;
