export const timeSettingTypes = {
  intervalTime: {
    name: 'Interval Time',
    value: 5,
  },
  totalTime: {
    name: 'Total Time',
    value: 4,
  },
  noTime: {
    name: 'Do not time me',
    value: 6,
  },
};

export const timeValues = {};
