import styled from 'styled-components';

export const Container = styled.div`
  background: #ededed;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0d0d0d;
  font-size: 22px;
  font-weight: 600;
  position: fixed;
  width: 100%;
  top: 139px;
`;
