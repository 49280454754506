import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: fit-content;
  position: relative;
  cursor: pointer;
  :hover {
    > div {
      transform: scale(0.95);
    }
  }
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    flex: 1;
    img {
      width: 97%;
    }
  }
`;
export const StyledContent = styled.div`
  position: absolute;
  left: 48%;
  bottom: 14.5%;
  transform: translateX(-47%);
  text-align: center;
  width: 96%;
  color: white;
  h3 {
    // font-size 22px,1387px(UI width)  => 22 / 1387 = 0.015861571
    font-size: calc(0.015861571 * 100vw);
    margin-bottom: 0;
    line-height: 1;
    font-weight: 900;
    text-shadow: -2px 0px 2px rgba(0, 0, 0, 0.24);
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    h3 {
      font-size: 22px;
      font-weight: 700;
    }
  }
`;
