import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import { StyledContainer } from './MockTimeTool.styles';
import { getMockTime } from 'store/timer/timerSlice';
const isDev = process.env.REACT_APP_DEV === 'true';
const MockTimeTool = () => {
  const [open, setOpen] = useState(isDev);
  const { mockTime } = useSelector((state) => state.timer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isDev) return;
    dispatch(getMockTime());
  }, [dispatch]);

  return (
    isDev &&
    open && (
      <Draggable>
        <StyledContainer>
          Mock time: {mockTime.data}
          <span className="close-btn" onClick={() => setOpen(false)}>
            X
          </span>
        </StyledContainer>
      </Draggable>
    )
  );
};

export default MockTimeTool;
