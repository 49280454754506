import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  .date {
    background-color: #ff8710;
    margin-right: 0.5rem;
    color: #ffffff;
    font-size: 21px;
    font-weight: bold;
    border-radius: 10px;
    padding: 0.5rem;
  }

  .sc-date {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-right: 0rem;
    padding: 0.2rem;
  }
`;
