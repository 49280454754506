/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import useClickOutside from 'helpers/useClickOutside';
import clsx from 'clsx';
import useIsSchoolPlan from 'hooks/useIsSchoolPlan';
import HelpMenu from './HelpMenu';
import { NavItemContainer } from './NavItem.styles';

const NavItem = ({
  isActive,
  to,
  children,
  icon,
  isFeatureReady,
  isExternal,
  mobileOnly,
}) => {
  const [hover, setHover] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [iconActive, setIconActive] = useState([icon]);
  const { isSchoolPlan } = useIsSchoolPlan();
  useEffect(() => {
    if ((isActive || hover) && iconActive.length === 1) {
      setIconActive((prev) => prev.concat(`${icon}-active`));
    } else if (!isActive && !hover && iconActive.length === 2) {
      setIconActive([icon]);
    }
  }, [hover, isActive, iconActive, icon]);

  useEffect(() => {
    setIconActive([icon]);
  }, [icon]);

  const helpOutsideClickHandler = useClickOutside(() => setShowOptions(false));
  return (
    <NavItemContainer
      isFeatureReady={isFeatureReady}
      mobileOnly={mobileOnly}
      isSchoolPlan={isSchoolPlan}
    >
      <li
        className={clsx({
          'nav-item': true,
          'text-icon': true,
          active: isActive,
          dashboard: isActive && to === '/dashboard',
        })}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        ref={helpOutsideClickHandler}
      >
        {isExternal === true && (
          <div
            className="nav-link"
            onClick={() => {
              window.open(to);
              // setShowOptions(!showOptions)
            }}
          >
            <i className={iconActive.join(' ')} />
            {children}
            {showOptions && <HelpMenu />}
          </div>
        )}
        {isExternal !== true && (
          <Link className="nav-link" to={isFeatureReady ? to : '/'}>
            <i className={iconActive.join(' ')} />
            {children}
          </Link>
        )}
      </li>
    </NavItemContainer>
  );
};

export default withRouter(({ location, match, ...props }) => {
  const isActive = location.pathname.slice(0, props.to.length) === props.to;
  return <NavItem {...props} isActive={isActive} />;
});
