import ImgAccountant from 'assets/img/leaderboard/lion-city/careers/accountant.png';
import ImgAnimator from 'assets/img/leaderboard/lion-city/careers/animator.png';
import ImgArchitect from 'assets/img/leaderboard/lion-city/careers/architect.png';
import ImgAstronaut from 'assets/img/leaderboard/lion-city/careers/astronaut.png';
import ImgChef from 'assets/img/leaderboard/lion-city/careers/chef.png';
import ImgDoctor from 'assets/img/leaderboard/lion-city/careers/doctor.png';
import ImgEconomist from 'assets/img/leaderboard/lion-city/careers/economist.png';
import ImgFashionDesigner from 'assets/img/leaderboard/lion-city/careers/fashion-designer.png';
import ImgJetFighterPilot from 'assets/img/leaderboard/lion-city/careers/jet-fighter-pilot.png';
import ImgProfessionalPhotographer from 'assets/img/leaderboard/lion-city/careers/professional-photographer.png';
import ImgSoftwareEngineer from 'assets/img/leaderboard/lion-city/careers/software-engineer.png';
import ImgSportsCommentator from 'assets/img/leaderboard/lion-city/careers/sports-commentator.png';

const lionCityOccupations = {
  1: ImgAccountant,
  2: ImgAnimator,
  3: ImgArchitect,
  4: ImgAstronaut,
  5: ImgChef,
  6: ImgDoctor,
  7: ImgEconomist,
  8: ImgFashionDesigner,
  9: ImgJetFighterPilot,
  10: ImgProfessionalPhotographer,
  11: ImgSoftwareEngineer,
  12: ImgSportsCommentator,
};

export default lionCityOccupations;
