import styled from 'styled-components';
import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import useSound from 'use-sound';
import RobotAvatar from 'assets/img/multiplayer/matching/Mini-Robot-PP-03.png';
import FoundSound from 'assets/audio/selected.mp3';
import FireworksAnimation from './98350-fireworks.json';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  position: relative;
  .opponent-name {
    color: white;
    text-align: center;
    font-size: 24px;
    width: 100%;
    z-index: 2;
  }

  img {
    animation-name: bounceIn;
    animation-timing-function: ease;
    animation-duration: 1s;
    animation-iteration-count: 1;
    border-radius: 50%;
    border: solid 5px white;
    z-index: 2;
    margin-bottom: 20px;
  }

  .fireworks {
    position: absolute;
    bottom: 20px;
  }

  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
`;

const Fireworks = () => {
  const options = {
    animationData: FireworksAnimation,
    loop: false,
    autoplay: true,
  };
  return (
    <Lottie options={options} isStopped={false} isPaused={false} width={400} />
  );
};

const OpponentFoundScene = ({
  randomOpponents = [],
  selectedOpponentIndex = 0,
  onFinishAnimation,
}) => {
  const foundOpponent = randomOpponents[selectedOpponentIndex] ?? {};
  const soundOptions = {
    interrupt: true,
  };
  const [play, { stop }] = useSound(FoundSound, soundOptions);

  useEffect(() => {
    play();
    setTimeout(() => {
      onFinishAnimation();
    }, 2500);
    return () => {
      stop();
    };
  }, [onFinishAnimation, play, stop]);
  return (
    <StyledContainer>
      <div className="fireworks">
        <Fireworks />
      </div>
      <img
        src={foundOpponent?.UserAvatarImage}
        onError={(e) => {
          e.target.src = RobotAvatar;
        }}
        alt=""
        width="132px"
        height="132px"
      />
      <p className="opponent-name">{foundOpponent.FullName ?? ''}</p>
    </StyledContainer>
  );
};

export default OpponentFoundScene;
