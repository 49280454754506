import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import ClassicBadges from 'assets/img/leaderboard/top-brain/classic-seasons.svg';
import MathCP from 'assets/img/sidepanel/CP.png';
import TopBrainText from 'assets/img/leaderboard/top-brain/text-top-brain-classic.svg';
import CPWhite from 'assets/img/icon-cp-white.svg';

import BackButton from 'components/Shared/BackButton';
import { ChallengeType } from 'pages/Leaderboard/v2/components';
import TopBrainToggle from '../TopBrainToggle';
import SeasonDropdown from '../SeasonDropdown';

import {
  Container,
  ButtonContainer,
  CenterBar,
  SubTitle,
} from './HeaderMathTBClassic.styles';

const HeaderMathTBClassic = ({ singaporeSchool, season, setSeason }) => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();

  return (
    <Container>
      <ButtonContainer>
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(7, 37, 106, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/leaderboard?subject=math')}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <CenterBar>
        <div className="classic-images">
          <img src={ClassicBadges} alt="Q1" />
        </div>
        <div className="classic-head">
          <div className="title">
            <img src={TopBrainText} alt="Top Brain Award" />
            {singaporeSchool && <TopBrainToggle />}
          </div>
          <div className="scoring-period">
            <div className="text">
              <img src={MathCP} alt="cp-icon" />
              <p>
                {t(
                  'leaderboard:topBrainBanner.scoringPeriod',
                  'Scoring Period'
                )}
                :{' '}
                {t(
                  'leaderboard:topBrainBanner.quarterlyChallenge',
                  'Quarterly Challenge'
                )}
              </p>
            </div>
            <SeasonDropdown
              currentSeason={season}
              setCurrentSeason={setSeason}
            />
          </div>
          <SubTitle>
            <Trans i18nKey="leaderboard:topBrainBanner.finalResultSeason">
              <img src={CPWhite} alt="cp" /> and Ranking finalised one day after
              the season ends.
            </Trans>
          </SubTitle>
        </div>
      </CenterBar>

      <ChallengeType styles={{ top: '10%' }} page="top-brain-classic" />
    </Container>
  );
};

export default HeaderMathTBClassic;
