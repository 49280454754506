import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isActive: false,
  pencilColor: '#FF5A26',
  highlighterColor: 'rgba(254, 235, 0, 0.06)',
  type: 'pencil',
  clearDraw: false,
  style: {
    html: '',
    body: '',
  },
};

const drawingKitsSlice = createSlice({
  name: 'drawingKitsSlice',
  initialState,
  reducers: {
    setPencil: (state, action) => {
      state.pencilColor = action.payload;
    },
    setHighlighter: (state, action) => {
      state.highlighterColor = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setClearDraw: (state, action) => {
      state.clearDraw = action.payload;
    },
    setDrawingActivation: (state, action) => {
      const isActive = action.payload;
      document.documentElement.style.overflowX = isActive ? 'hidden' : '';
      document.body.style.overflowX = isActive ? 'hidden' : '';
      state.isActive = isActive;
    },
  },
});

const { actions, reducer } = drawingKitsSlice;
export const {
  setPencil,
  setHighlighter,
  setType,
  setDrawingActivation,
  setClearDraw,
} = actions;
export default reducer;
