import React from 'react';
import { useTranslation } from 'react-i18next';
import KoKoCredit from 'assets/img/icon-koko-credits.svg';
import { history } from 'Routes';
import { Container } from './ClaimKokoCreditBar.styles';

const ClaimKokoCreditBar = ({ kokoCreditsNumber, claimRoute }) => {
  const { t } = useTranslation(['assignmentSolution']);
  return (
    <Container data-cy="claim-koko-bar">
      <div className="text">
        <p>{t('assignmentSolution:koko.Text1', 'You forgot to claim ')}</p>
        <img src={KoKoCredit} alt="KoKo Credit" />
        <p>
          <span>{kokoCreditsNumber}</span>
          {t('assignmentSolution:koko.Text1a', ' KoKo for this homework.')}
        </p>
      </div>
      <div className="btn">
        <button
          className="claim-btn"
          onClick={() => {
            history.push(claimRoute);
          }}
        >
          {t('assignmentSolution:koko.Text2', 'Claim now!')}
        </button>
      </div>
    </Container>
  );
};

export default ClaimKokoCreditBar;
