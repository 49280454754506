import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

import useDashboard from 'hooks/useDashboard';

import BackButton from 'components/Shared/BackButton';

import CurrentDate from '../../CurrentDate';
import { ChallengeType } from '../../../../../components';

import { Container } from './HeaderMath.styles';

const HeaderMath = () => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  const { isDashboardv2 } = useDashboard();

  return (
    <Container>
      <div className="btn">
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(53, 137, 216, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/leaderboard?subject=math')}
          styles={{ marginTop: '1rem' }}
        />
      </div>
      <div className="middle-content">
        <p className="title">
          {t('leaderboard:hodBanner.hod', 'Hero of The Day')}
        </p>
        <CurrentDate />
        <div className="desc">
          <p>
            <Trans i18nKey="leaderboard:hodBanner.hodDesc1">
              <Link
                to={
                  isDashboardv2 ? '/challenges/daily-challenge' : '/challenges'
                }
              >
                Daily Challenge
              </Link>
              <Link
                to={
                  isDashboardv2
                    ? `/multiplayer/peer-challenge/new-challenge?subject=Math`
                    : '/multiplayer'
                }
              >
                Peer Challenge
              </Link>
            </Trans>
          </p>
          <p>
            {t('leaderboard:hodBanner.hodDesc2', 'to start earning some CPs!')}
          </p>
        </div>
      </div>
      <ChallengeType styles={{ top: '12%', right: '13%' }} />
    </Container>
  );
};

export default HeaderMath;
