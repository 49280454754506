// Helper for getting school name for display
const getSchoolName = ({
  subscriptionData,
  hasPremiumSchoolPlan,
  nonExpiredSubscriptionPlans,
  planToShow,
}) => {
  if (hasPremiumSchoolPlan) {
    if (nonExpiredSubscriptionPlans.length <= 0) return '';
    const schoolPlanIndex = nonExpiredSubscriptionPlans.findIndex(
      (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
    );
    // PlanId === 4 is for Math olympiad
    const premiumPlanIndex = nonExpiredSubscriptionPlans.findIndex(
      (plan) => plan.PlanId === 3 || plan.PlanId === 4
    );
    if (planToShow === 'school' && schoolPlanIndex !== -1) {
      return nonExpiredSubscriptionPlans[schoolPlanIndex].Schoolname;
    }
    if (planToShow === 'premium' && premiumPlanIndex !== -1) {
      return nonExpiredSubscriptionPlans[premiumPlanIndex].Schoolname;
    }
  } else {
    return subscriptionData.Schoolname;
  }
};

export default getSchoolName;
