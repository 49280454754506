import styled from 'styled-components';
import ClassicBanner from 'assets/img/leaderboard/champion-class/classic-banner.svg';

export const Container = styled.div`
  position: relative;
  width: 80%;
  z-index: 1;
  .classic-banner {
    position: absolute;
    background-image: url(${ClassicBanner});
    background-repeat: no-repeat;
    width: 100%;
    text-align: center;
    background-size: 100% 112%;
    color: #ffffff;
    top: -22px;
    font-size: 23px;
    font-weight: bold;
  }
  .classic-body {
    background: #ffffff;
    display: flex;
    padding: 20px;
    border-radius: 15px;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Front = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 20%;
  .star-class {
    color: #ff7f00;
    font-size: 17px;
    font-weight: bold;
  }
  .start-cps {
    font-size: 12px;
    span {
      font-weight: bold;
      font-size: 14px;
    }
  }
`;

export const Middle = styled.div`
  width: 55%;
  text-align: center;
  .progressing {
    font-size: 16px;
    .class-name {
      font-weight: 600;
      margin-right: 0.5rem;
    }
    .total-cp {
      font-size: 23px;
      font-weight: 600;
    }
    .cps {
      font-size: 12px;
    }
  }
`;

export const End = styled.div`
  width: 15%;
  .gold-class {
    color: #fcbb0a;
    font-size: 17px;
    font-weight: bold;
  }
  .total-cps {
    font-size: 12px;
    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
`;
