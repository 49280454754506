import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border-radius: 8px;
  color: #0a0a0a;
  padding: 2.5rem 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  .desc {
    text-align: left;
    color: rgb(33, 33, 33, 0.48);
    font-size: 18px;
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 0px;
    }
  }
  img {
    position: relative;
    margin-top: -7.7rem;
    margin-bottom: 0.5rem;
  }
  .btn-section {
    width: 100%;
  }
`;
