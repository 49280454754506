import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import Timer from 'components/Assignment/Timer';
import BackButton from 'components/Shared/BackButton';
import TimeDuration from 'components/Shared/TimeDuration';
import PrintIcon from 'assets/img/assignment/print-icon.svg';
import KooBitsLogo from 'assets/img/koobits-logo.svg';
import { featureToggle } from 'constants/index';
import { kooQuizPaperTypeInternal } from 'constants/kooQuiz';

import TranslationDropdown from 'components/Header/Navigations/TranslationDropdown';
import { isDemoAccount } from 'components/Header/Navigations/NavProfile';
import { resetRetry } from 'store/kooClass/kooClassSlice';
import { setLastSavedTime } from 'store/timer/timerSlice';
import { getRevisionId, openKooQuiz } from 'store/kooQuiz/kooQuizSlice';
import {
  LogoContainer,
  Container,
  Section,
  ActionButton,
  PrintButton,
} from './Header.styles';

const Header = ({
  type,
  assignmentId,
  assignmentTitle,
  isLoading,
  page,
  currentSavedAnswer,
  openModal,
  noNavBar,
  saveHandler,
  duration,
  showDuration,
  showLogo,
  isErr,
  showPrint = true,
  metaData = {},
  isSaving,
  saveErr,
  isChecking,
  activeQuestion,
}) => {
  const { t } = useTranslation(['assignmentQnView', 'common']);
  const dispatch = useDispatch();
  const { assignmentID, kooQuizId } = useParams();
  const { allFeatures } = useSelector((state) => state.plan);
  const {
    startkooQuizDuration,
    kooQuizType,
    kooQuizSelectedPaper,
    paperById,
    errorById,
    errorFinish,
  } = useSelector((state) => state.kooQuiz);
  const { retryActivity } = useSelector((state) => state.kooClass);
  const FilterSubjectStatus = {
    KooQuizMaths: 'Maths',
    KooQuizSci: 'Science',
  };

  const { user } = useSelector((state) => state.login);

  const history = useHistory();

  const title = (type, assignmentTitle) => {
    if (type === 'KooQuizMaths' || type === 'KooQuizSci') {
      if (!isNil(errorById)) {
        return (
          <BackButton
            styles={{
              marginLeft: '75px',
              padding: '5px 10px',
              width: '90px',
              display: 'flex',
              justifyContent: 'center',
              textDecoration: 'underline',
            }}
            btnBgColor="transparent"
            backText={t('common:reload', 'Reload')}
            noIcon
            onClick={() => {
              const featureCourseIds = allFeatures?.find(
                (item) => item.feature === 'KooQuiz'
              )?.parameters.Category;
              const currentPaperType =
                kooQuizPaperTypeInternal[paperById?.Type];
              dispatch(
                getRevisionId({
                  subjectId: type === 'KooQuizMaths' ? 1 : 2,
                  paperId: kooQuizId,
                  courseIds: featureCourseIds[currentPaperType],
                })
              );
            }}
          />
        );
      }
      return assignmentTitle;
    }
    return assignmentTitle;
  };
  const assignmentPrintId = page === 'solution' ? assignmentID : assignmentId;
  const kooQuizPrintId = page === 'solution' ? kooQuizId : paperById?.Id;
  const getPrintUrl = () => {
    const firstUrlFragment = `${process.env.REACT_APP_DOMAIN}${
      process.env.REACT_APP_SUBDOMAIN
    }${process.env.REACT_APP_SUBDOMAIN === '/' ? '' : '/'}${
      type === 'KooQuizMaths' || type === 'KooQuizSci'
        ? 'kooQuiz'
        : 'assignment'
    }/print/${
      type === 'KooQuizMaths' || type === 'KooQuizSci'
        ? kooQuizPrintId
        : assignmentPrintId
    }`;
    // Optional url query params
    const secondUrlFragment =
      type === 'KooQuizMaths' || type === 'KooQuizSci'
        ? `?subjectid=${metaData.subjectid}&papertypeid=${metaData.papertypeid}`
        : '';
    return `${firstUrlFragment}${secondUrlFragment}`;
  };
  return (
    <Container page={page} assignmentType={type} noNavBar={noNavBar}>
      <Section>
        {page === 'solution' && showLogo && (
          <LogoContainer
            assignmentType={type}
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            <img src={KooBitsLogo} alt="KooBits" width="40px" />
          </LogoContainer>
        )}
        {page !== 'solution' && (
          <LogoContainer
            assignmentType={type}
            onClick={() => {
              history.replace('/dashboard');
            }}
          >
            <img src={KooBitsLogo} alt="KooBits" width="40px" />
          </LogoContainer>
        )}
        {type === 'KooClass' && (
          <BackButton
            styles={{
              marginLeft: '15px',
              padding: '5px 10px',
              width: '90px',
              display: 'flex',
              justifyContent: 'center',
            }}
            btnBgColor="rgba(1, 135, 152, 0.5)"
            backText={t('assignmentQnView:header.backKooQuiz', 'Back')}
            onClick={() => {
              history.goBack();
              if (!isNil(saveHandler)) {
                saveHandler(false, true);
              }
              if (!isNil(retryActivity)) {
                dispatch(resetRetry());
              }
            }}
          />
        )}
        {(type === 'KooQuizMaths' || type === 'KooQuizSci') && (
          <BackButton
            styles={{
              marginLeft: '15px',
              padding: '5px 10px',
              width: '90px',
              display: 'flex',
              justifyContent: 'center',
            }}
            btnBgColor={type === 'KooQuizMaths' ? '#394EB1' : '#227CA7'}
            backText={t('assignmentQnView:header.backKooQuiz', 'Back')}
            onClick={() => {
              if (!isNil(isErr)) {
                history.push(
                  `/kooQuiz/${FilterSubjectStatus[kooQuizType]}/${kooQuizSelectedPaper}`
                );
              } else if (page === 'finish') {
                if (!isNil(errorFinish)) {
                  dispatch(openKooQuiz(paperById.Id));
                }
                history.goBack();
              } else if (page === 'solution') {
                history.push(
                  `/kooQuiz/${FilterSubjectStatus[kooQuizType]}/${kooQuizSelectedPaper}`
                );
              } else if (page === 'submitted') {
                history.push(
                  `/kooQuiz/${FilterSubjectStatus[kooQuizType]}/${kooQuizSelectedPaper}`
                );
              } else {
                saveHandler({ needCheckAnswersEqual: true });
                history.push(
                  `/kooQuiz/${FilterSubjectStatus[kooQuizType]}/${kooQuizSelectedPaper}`
                );
              }
            }}
          />
        )}
        {page &&
          showDuration &&
          !isLoading &&
          isErr === null &&
          (type === 'KooQuizMaths' || type === 'KooQuizSci') && (
            <span>
              <TimeDuration duration={duration} />
            </span>
          )}
        {!page && !isLoading && isErr === null && (
          <span>
            {(type === 'KooQuizMaths' || type === 'KooQuizSci') && (
              <Timer
                startOffset={startkooQuizDuration}
                isCountdown={false}
                isInvisible={false}
              />
            )}
          </span>
        )}
      </Section>
      {!isLoading && (
        <span className="hw-title">{title(type, assignmentTitle)}</span>
      )}
      <Section page={page} type="actions" showPrint={showPrint}>
        {featureToggle.languageDropdown &&
          user !== null &&
          isDemoAccount(user.user_name) && <TranslationDropdown />}
        {(type === 'KooQuizMaths' || type === 'KooQuizSci') && showPrint && (
          <a
            assignmentType={type}
            data-cy="print-icon"
            rel="noopener noreferrer"
            target="_blank"
            href={getPrintUrl()}
          >
            <PrintButton assignmentType={type}>
              <img src={PrintIcon} alt="Print" />
            </PrintButton>
          </a>
        )}
        {!page && (
          <>
            <ActionButton
              data-cy="submit-btn"
              assignmentType={type}
              disabled={isLoading && isErr !== null}
              onClick={openModal}
            >
              {t('assignmentQnView:header.submit', 'Submit')}
            </ActionButton>

            <ActionButton
              data-cy="saving-btn"
              assignmentType={type}
              onClick={() => {
                const now = Date.now();
                saveHandler(true);
                dispatch(setLastSavedTime(now));
              }}
              isSaved={
                !isSaving && saveErr === null && currentSavedAnswer !== null
              }
              disabled={
                isSaving ||
                isChecking[activeQuestion - 1] ||
                isLoading ||
                isErr !== null ||
                currentSavedAnswer !== null
              }
            >
              {(isSaving || isChecking[activeQuestion - 1]) &&
                t('assignmentQnView:header.saving', 'Saving')}
              {!isSaving &&
                saveErr === null &&
                currentSavedAnswer !== null &&
                t('assignmentQnView:header.saved', 'Saved')}
              {!isSaving &&
                !isChecking[activeQuestion - 1] &&
                currentSavedAnswer === null &&
                t('assignmentQnView:header.save', 'Save')}
            </ActionButton>
          </>
        )}
      </Section>
    </Container>
  );
};

export default Header;
