import React, { useCallback } from 'react';
import mainBackground from 'assets/img/superherochallenge/superspeed/main-background.png';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, IntroText, HistoryButton, Rules } from './Welcome.style';
import { ButtonContainer } from './BeforeStartChallenge.style';
import Button from './Button.style';

function Welcome({ disabled }) {
  const { t } = useTranslation(['superSpeed']);
  const history = useHistory();
  const startPractice = useCallback(() => {
    history.push('/superhero/superspeed/practice');
  }, [history]);
  const startChallenge = useCallback(() => {
    history.push('/superhero/superspeed/challenge');
  }, [history]);
  const goToHistory = useCallback(() => {
    history.push('/superhero/history');
  }, [history]);
  return (
    <Container background={mainBackground}>
      <HistoryButton onClick={goToHistory}>
        {t('superSpeed:welcome.scoreHistory', 'SCORE HISTORY')}
      </HistoryButton>
      <IntroText>
        {t(
          'superSpeed:welcome.introText',
          'Welcome to Super Speed Challenge! Answer as many questions correctly as possible within the given time of 2 minutes.'
        )}
      </IntroText>
      <Rules>
        <span>{t('superSpeed:welcome.rules', 'Rules')}</span>
        <ul>
          <li>
            {t('superSpeed:welcome.rule1', 'Available from Monday to Saturday')}
          </li>
          <li>
            {t(
              'superSpeed:welcome.rule2',
              '1 Point will be added to the score for every correct answer'
            )}
          </li>
          <li>
            {t(
              'superSpeed:welcome.rule3',
              'Every question will skip automatically after 10 seconds'
            )}
          </li>
          <li>
            {t('superSpeed:welcome.rule4', 'No penalty for skipped questions')}
          </li>
          <li>
            {t(
              'superSpeed:welcome.rule5',
              'Game can be paused, but the pause will only take effect after the current question.'
            )}
          </li>
        </ul>
      </Rules>
      <ButtonContainer>
        <Button
          textColor="#fff"
          small
          backgroundColor="#1ab6ff"
          backgroundColorHover="#1daff3"
          onClick={startPractice}
        >
          {t('superSpeed:welcome.practiceBtnText', 'Practise First')}
        </Button>
        <Button
          disabled={disabled}
          small
          textColor="#fff"
          backgroundColor="#ff7e00"
          backgroundColorHover="#ff7200"
          onClick={startChallenge}
        >
          {t('superSpeed:welcome.challengeBtnText', 'Challenge Now')}
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default React.memo(Welcome);
