import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 190px;
  p {
    font-size: 16px;
  }
  .close-btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: #73b0e8;
    font-weight: bold;
    font-size: 20px;
    background-color: #ebf6ff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    cursor: pointer;
  }
  .err-msg {
    text-align: center;
    .error-title {
      font-weight: 600;
      font-size: 28px;
    }
    p {
      font-size: 20px;
      margin-bottom: 0px;
    }
  }
  .action-btn {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 1rem;
  }
`;
