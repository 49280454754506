import React, { useCallback } from 'react';
import ChapterList from 'components/StoryMath/ChapterList';
import ProgressBar from 'components/StoryMath/ProgressBar';
import { Container } from './Timeline.styles';

const Timeline = ({ last, data, onClickImage }) => {
  const getProgress = useCallback(() => {
    let progress = '';
    if (data.length === 1) {
      progress = '12.5%';
    } else if (data.length === 2) {
      progress = '37.5%';
    } else if (data.length === 3) {
      progress = '62.5%';
    } else if (data.length === 4 && last) {
      progress = '87.5%';
    } else {
      progress = '100%';
    }
    return progress;
  }, [data.length, last]);

  return (
    <Container>
      <ChapterList type="top" data={data} onClickImage={onClickImage} />
      <ProgressBar progress={getProgress} />
      <ChapterList type="bottom" data={data} onClickImage={onClickImage} />
    </Container>
  );
};

export default React.memo(Timeline);
