import { isNil } from 'ramda';

// Helper function that checks an array of options for img tag
export const checkForImgOption = (options) => {
  let result = false;
  for (const option of options) {
    if (checkForImgTag(option) === true) {
      result = true;
      break;
    }
  }
  return result;
};

// Helper function that checks html string for img tags and returns true/false based on whether it contains img tag
export const checkForImgTag = (htmlText) => {
  const matches = htmlText.match(/(<img[^<>]*)\surl=([^<>]*>)/g);
  return !isNil(matches);
};
