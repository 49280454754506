import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Hoverbutton } from 'components/Shared/CardHover.styles';
import { OverlayContainer } from './DashboardCard.styles';
import { CardContainer, Block } from './MissionCard.styles';

const Overlay = ({ isFeatureReady }) => {
  const { t } = useTranslation(['dashboard', 'dashboardCards']);
  return (
    <OverlayContainer isPremium isFeatureReady={isFeatureReady}>
      <div className="hover-desc-block">
        <h4 className="title">{t('dashboardCards:mission.name', 'Mission')}</h4>
      </div>
      <Hoverbutton btnColor="#068AD3">
        {t('dashboard:dailyChallenge.button', 'Start')}
      </Hoverbutton>
    </OverlayContainer>
  );
};

const MissionCard = ({ isFeatureReady }) => {
  const history = useHistory();
  const { t } = useTranslation(['dashboardCards']);
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <CardContainer
      onClick={() => {
        if (isFeatureReady) history.push('/mission/curriculum-list');
      }}
      onMouseEnter={() => {
        if (isFeatureReady) setShowOverlay(true);
      }}
      onMouseLeave={() => {
        if (isFeatureReady) setShowOverlay(false);
      }}
    >
      <Block showOverlay={showOverlay} data-cy="mission">
        <div className="desc-block">
          <div className="title">
            {t('dashboardCards:mission.name', 'Mission')}
          </div>
          <div className="sub-title">
            {t(
              'dashboardCards:mission.description',
              'Practice by topics. Learn at your own pace.'
            )}
          </div>
        </div>
      </Block>
      {showOverlay && <Overlay isFeatureReady={isFeatureReady} />}
    </CardContainer>
  );
};

export default MissionCard;
