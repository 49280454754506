import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'components/Shared/DropdownMenu';
import erase from 'assets/img/assignment/four-ops/erase.svg';
import FourOpsHeader from './FourOpsHeader';
import FourOpsBody from './FourOpsBody';
import { Container } from './FourOpsTool.styles';

export const clearValues = () => {
  // clearing uncontrolled component
  Array.from(document.querySelectorAll('.unInp')).forEach((input) => {
    input.value = '';
  });
};

const FourOpsTool = () => {
  const { t } = useTranslation(['assistTools']);
  const operations = {
    addition: [
      `${t(
        'assistTools:fourOpsModal.addition',
        { number: 4 },
        '4 digits addition'
      )}`,
      `${t(
        'assistTools:fourOpsModal.addition',
        { number: 3 },
        '3 digits addition'
      )}`,
    ],
    subtraction: [
      `${t(
        'assistTools:fourOpsModal.subtraction',
        { number: 4 },
        '4 digits subtraction'
      )}`,
      `${t(
        'assistTools:fourOpsModal.subtraction',
        { number: 3 },
        '3 digits subtraction'
      )}`,
    ],
    multiplication: [
      `${t(
        'assistTools:fourOpsModal.multiplication',
        { mulOne: 2, mulTwo: 2 },
        '2  by 2 multiplication'
      )}`,
      `${t(
        'assistTools:fourOpsModal.multiplication',
        { mulOne: 3, mulTwo: 2 },
        '3 by 2 multiplication'
      )}`,
    ],
    division: [
      `${t(
        'assistTools:fourOpsModal.division',
        { number: 3 },
        '3 digits division'
      )}`,
      `${t(
        'assistTools:fourOpsModal.division',
        { number: 2 },
        '2 digits division'
      )}`,
    ],
  };
  const arrVal = [0, 1];
  const [options, setOptions] = useState(operations.addition);
  const [arrOption, setArrOption] = useState(0);
  const [activeType, setActiveType] = useState('addition');

  const setType = (val) => {
    setActiveType(val);
    setOptions(operations[val]);
  };

  return (
    <Container>
      <FourOpsHeader activeType={activeType} setActiveType={setType} />
      <div className="operations-list">
        <div style={{ color: '#99A8AE' }}>
          {t('assistTools:fourOpsModal.format', 'Format')}
        </div>
        <DropdownMenu
          selectedValue={options[arrOption]}
          valueKey={null}
          values={arrVal}
          setValue={setArrOption}
          width="150px"
          valueTemplate={(val) => options[val]}
        />
        <div>
          <img src={erase} alt="erase" />
          <span className="clearAll" onClick={clearValues}>
            {t('assistTools:barModal.clearAll', 'Clear All')}
          </span>
        </div>
      </div>
      <FourOpsBody activeType={activeType} arrOption={arrOption} />
    </Container>
  );
};

export default FourOpsTool;
