/* eslint-disable no-restricted-syntax */

const validateInputs = (object) => {
  // handle empty null
  if (object === null) return false;
  // handle MCQ
  if (Array.isArray(object)) {
    // for empty array
    if (object.length === 0) return false;
    // if array is not empty
    return true;
  }
  // handle inputs
  if (typeof object === 'object') {
    // loop each keys
    for (const key of Object.keys(object)) {
      const value = object[key];
      // cannot be blank and cannot have empty spaces
      if (value.replace(/\s+/g, '').length === 0) return false;
    }
  }
  // validated
  return true;
};

export default validateInputs;
