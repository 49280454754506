import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import brainGameMathBg from 'assets/img/dashboardv2/brain-game-math.png';
import brainGameMathTablet from 'assets/img/dashboardv2/brainGameTablet.png';
import brainGameScienceBg from 'assets/img/dashboardv2/brain-game-science.png';
import brainGameScienceTablet from 'assets/img/dashboardv2/brainGameSCTablet.png';
import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import { StyledContainer } from './BrainGameCard.styles';

const BG = {
  math: brainGameMathBg,
  science: brainGameScienceBg,
};

const BGTablet = {
  math: brainGameMathTablet,
  science: brainGameScienceTablet,
};

const BrainGameImage = ({ subject }) => {
  const imgSrc = useResponsiveImage(BG[subject], BGTablet[subject]);
  return <img src={imgSrc} alt={subject} />;
};

const BrainGameCard = () => {
  const { subject } = useSelector((state) => state.plan);
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const navigateToBrainGame = () => {
    history.push(`/brain-game/${subject}`);
  };
  return (
    <StyledContainer onClick={navigateToBrainGame} data-cy="brain-game-card">
      <div>
        <BrainGameImage subject={subject} />
        <h3>{t('brainGame.name', 'BrainGame')}</h3>
      </div>
    </StyledContainer>
  );
};

export default BrainGameCard;
