import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDashboard from 'hooks/useDashboard';
import BackIcon from 'assets/img/assignment/back-icon.svg';
import KooBitsLogo from 'assets/img/koobits-logo.svg';
import { LogoContainer, Container, Section, Button } from './index.styles';

const Header = ({ type, assignmentTitle, isLoading, page, isMobile }) => {
  const { t } = useTranslation(['assignmentQnView', 'common']);
  const history = useHistory();
  const { isDashboardv2 } = useDashboard();

  const backOnClickHandler = () => {
    let backLocation = '/assignment/assignment-list';
    if (type === 'sundayMC') {
      backLocation = isDashboardv2 ? '/dashboard?view=koochallenge' : '/events';
    }
    history.replace(backLocation);
  };

  return (
    <Container page={page} assignmentType={type}>
      <Section>
        {!isMobile && (
          <LogoContainer
            assignmentType={type}
            onClick={() => {
              history.replace('/dashboard');
            }}
          >
            <img src={KooBitsLogo} alt="KooBits" width="40px" />
          </LogoContainer>
        )}
        <Button assignmentType={type} onClick={backOnClickHandler}>
          <img src={BackIcon} alt="Back" />
          <span className="button-text">{t('common:back', 'Back')}</span>
        </Button>
      </Section>
      {!isLoading && <span className="hw-title">{assignmentTitle}</span>}
      <div />
    </Container>
  );
};

export default Header;
