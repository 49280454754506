import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 50%;
  height: fit-content;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  :hover {
    > div {
      transform: scale(0.95);
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  h3 {
    position: absolute;
    bottom: 14%;
    text-align: center;
    width: 100%;
    font-weight: 700;
    color: white;
    font-size: calc(0.01586157173 * 100vw);
    margin: 0;
    text-shadow: rgb(0 0 0 / 24%) -2px 0px 2px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
    h3 {
      font-size: 22px;
    }
  }
`;
