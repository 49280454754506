import styled from 'styled-components';

export const PositioningResize = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid #0070ff;
`;

export const PositioningMove = styled.div`
  height: 100%;
  width: 100%;
  border: 2px dashed #0070ff;
  position: absolute;
  z-index: 3;
  flex-grow: 1;
`;
