import styled from 'styled-components';
import { MAX_WIDTH } from './constants';

export const StyledBackground = styled.div`
  background: ${({ subject }) => (subject === 'math' ? '#E8FDFF' : '#F2FFE3')};
  padding: 16px;
  height: 75px;
  display: flex;
`;
export const StyledContainer = styled.div`
  max-width: ${MAX_WIDTH}px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
export const StyledDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > span {
    margin-right: 8px;
  }
`;
export const StyledDropdownMenuBox = styled.div`
  position: relative;
  p {
    position: absolute;
    left: 11px;
    top: 100%;
    color: #7e7e7e;
    font-size: 11px;
  }
`;
export const StyledBalance = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: ${({ subject }) => (subject === 'math' ? '#2BC5FF' : '#96FF1C')};
    margin: 0 4px 4px 8px;
  }
`;

export const StyledBalanceValue = styled.div`
  font-size: 30px;
  margin: 0;
`;
export const StyledBackButtonWrap = styled.div`
  position: absolute;
  left: -157px;
  @media (max-width: 1260px) {
    position: static;
    margin-right: 16px;
  }
`;
