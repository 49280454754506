import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_HOMEWORK_API_DOMAIN;

const fetchAssignmentList = ({
  assignedBy,
  submissionStatus,
  assignedYear,
  pageSize,
  pageIndex,
}) => {
  return fetch(
    `${baseUrl}HomeWork/GetHomeworkDetails?assignedBy=${assignedBy}&submissionStatus=${submissionStatus}&assignedYear=${assignedYear}&pageSize=${pageSize}&pageIndex=${pageIndex}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get assignment list failed'); // eslint-disable-line
    });
};

export default fetchAssignmentList;
