import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GlobalStyle from 'components/GlobalStyle';

import Theme from 'Theme';
import ErrorModal from 'components/Shared/ErrorModal';
import { isEmpty, isNil } from 'ramda';
import { getProducts } from 'store/plan/planSlice';
import useSecret from 'hooks/useSecret';
import Routes from './Routes';

console.log(`App version: ${process.env.REACT_APP_VERSION}`);

function App() {
  const dispatch = useDispatch();

  const { i18n } = useTranslation(['navigation']);
  const { userCurrentLocale } = useSelector((state) => state.studentDetails);
  const { getProductsError } = useSelector((state) => state.plan);
  useSecret();

  const changeLanguage = () => {
    if (!isEmpty(userCurrentLocale) && !isNil(userCurrentLocale)) {
      i18n.changeLanguage(userCurrentLocale);
    } else {
      i18n.changeLanguage('en');
    }
  };

  useEffect(() => {
    changeLanguage();
    // eslint-disable-next-line
  }, [userCurrentLocale]);
  return (
    <>
      <GlobalStyle />
      <Theme>
        {!isNil(getProductsError) && (
          <ErrorModal
            errorMessage={getProductsError}
            reloadAction={() => {
              dispatch(getProducts());
            }}
          />
        )}
        <Routes />
      </Theme>
    </>
  );
}

export default App;
