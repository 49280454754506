import { isNil } from 'ramda';
// answerKeys is an array of submission key value pairs
const displaySubmission = (answerKeys, currentInputKey) => {
  const answer = answerKeys.find(
    (ans) => ans.key === currentInputKey || ans.Key === currentInputKey
  );
  if (isNil(answer)) {
    return null;
  }
  return answer.value ?? answer.Value;
};

export default displaySubmission;
