import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  postMarkNotification,
  fetchUserNotification,
  fetchNotificationDetails,
  fetchUserStaticNotification,
} from 'services/notification';
import { fetchWrapper } from 'services/login';
import { compareDate } from 'helpers/compareValue';
import { notificationType } from 'constants/notification';
import { filteredNotification } from 'helpers/filteredNotification';
import { arrayQueryParams } from 'helpers/arrayQueryParams';
import { isEmpty } from 'ramda';

export const getStaticUserNotification = createAsyncThunk(
  'notification/getStaticUserNotification',
  async () => {
    try {
      const res = await fetchWrapper(fetchUserStaticNotification);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get static user notification failed');
    }
  }
);

export const getUserNotification = createAsyncThunk(
  'notification/getUserNotification',
  async (data, { getState }) => {
    const { plan } = getState();
    const { globalFeatureConfig } = plan;
    const isNotificationEnabled =
      globalFeatureConfig?.notifications?.parameters?.IsEnabled ?? true;
    if (!isNotificationEnabled || window.isKooSchool) {
      return {
        userNotifRes: {
          dynamic: [],
          static: [],
        },
        detailNotifRes: [],
      };
    }

    try {
      const userNotifRes = await fetchWrapper(fetchUserNotification);
      const filteredDynamic = filteredNotification({
        activeData: userNotifRes.dynamic,
        countryData: data.countryCode,
        levelData: data.defaultLevel,
        allPlans: data.allPlans,
      });
      const notifIds = filteredDynamic.map((item) => item.id);
      const detailNotifRes = !isEmpty(filteredDynamic)
        ? await fetchWrapper(
            fetchNotificationDetails,
            arrayQueryParams(notifIds)
          )
        : [];
      return {
        userNotifRes,
        detailNotifRes,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Get user notification failed');
    }
  }
);

export const markNotification = createAsyncThunk(
  'notification/markNotification',
  async (params) => {
    try {
      const res = await fetchWrapper(postMarkNotification, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Mark notification failed');
    }
  }
);

const initialState = {
  userNotification: null,
  isLoadingUserNotification: true,
  isErrorUserNotification: null,
  isRead: null,
  notificationIds: '',
  markedNotification: null,
  isMarkingNotification: false,
  isMarkError: null,
  directToNotifPage: false,
  bannerNotif: null,
  pageNotif: null,
  staticNotif: [],
  dynamicNotif: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setDirectToNotifPage: (state, action) => {
      state.directToNotifPage = action.payload;
    },
    setIsRead: (state, action) => {
      state.isRead = action.payload;
    },
    resetNotification: (state) => {
      state.notificationsDetail = [];
    },
    resetMarkNotification: (state) => {
      state.markedNotification = null;
      state.isMarkError = null;
    },
  },
  extraReducers: {
    [getStaticUserNotification.pending]: (state) => {
      state.isLoadingUserNotification = true;
      state.notificationsDetail = [];
      state.bannerNotif = null;
      state.pageNotif = null;
      state.isErrorUserNotification = null;
    },
    [getStaticUserNotification.fulfilled]: (state, action) => {
      state.isLoadingUserNotification = false;
      state.notificationsDetail = action.payload;
      state.bannerNotif = action.payload.find(
        (item) => notificationType[item.notificationType] === 'banner'
      );
      state.pageNotif = action.payload.find(
        (item) => notificationType[item.notificationType] === 'page'
      );
      state.isErrorUserNotification = null;
    },
    [getStaticUserNotification.rejected]: (state, action) => {
      state.isLoadingUserNotification = false;
      state.notificationsDetail = [];
      state.bannerNotif = null;
      state.pageNotif = null;
      state.isErrorUserNotification = action.error.message;
    },
    [getUserNotification.pending]: (state) => {
      state.isLoadingUserNotification = true;
      state.userNotification = null;
      state.isErrorUserNotification = null;
    },
    [getUserNotification.fulfilled]: (state, action) => {
      state.isLoadingUserNotification = false;
      state.userNotification = action.payload.userNotifRes;
      state.staticNotif = action.payload.userNotifRes.static;
      state.dynamicNotif = action.payload.detailNotifRes;
      const allNotif = action.payload.detailNotifRes.concat(
        action.payload.userNotifRes.static
      );
      const sortedNotification = allNotif.sort(
        compareDate('startDate', 'desc')
      );
      state.bannerNotif = sortedNotification.find(
        (item) => notificationType[item.notificationType] === 'banner'
      );
      state.pageNotif = sortedNotification.find(
        (item) => notificationType[item.notificationType] === 'page'
      );
      state.isErrorUserNotification = null;
    },
    [getUserNotification.rejected]: (state, action) => {
      state.isLoadingUserNotification = false;
      state.userNotification = null;
      state.isErrorUserNotification = action.error.message;
    },
    [markNotification.pending]: (state) => {
      state.isMarkingNotification = true;
      state.markedNotification = null;
      state.isMarkError = null;
    },
    [markNotification.fulfilled]: (state, action) => {
      state.isMarkingNotification = false;
      state.markedNotification = action.payload;
      state.isMarkError = null;
    },
    [markNotification.rejected]: (state, action) => {
      state.isMarkingNotification = false;
      state.markedNotification = null;
      state.isMarkError = action.error.message;
    },
  },
});

const { actions, reducer } = notificationSlice;
export const {
  setDirectToNotifPage,
  setIsRead,
  resetNotification,
  resetMarkNotification,
} = actions;
export default reducer;
