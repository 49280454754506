import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  .category {
    margin-right: 1rem;
    margin-bottom: ${(props) =>
      props.page || props.noSpace ? '0rem' : '1rem'};
    color: ${(props) => (props.page ? 'rgb(255, 255, 255, 0.58)' : '#a4a4a4')};
    text-align: right;
    min-width: 7rem;
  }
  .info {
    color: ${(props) => (props.page ? '#FFFFFF' : '#212121')};
    text-align: left;
    margin-bottom: ${(props) =>
      props.page || props.noSpace ? '0rem' : '1rem'};
  }
`;
