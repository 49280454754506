import styled from 'styled-components';

export const Container = styled.div`
  background: #f9f9f9;
  height: 100%;
`;

export const ErrorMsg = styled.div`
  padding-top: 45px;
  text-align: center;
  color: rgb(112, 112, 112, 0.64);
  h4 {
    font-size: 2.5rem;
  }
  p {
    margin-bottom: 0rem;
    font-size: 1.2rem;
  }
`;
