import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import { isNil } from 'ramda';
import MainPageTopbar from 'components/KooQuiz/MainPageTopbar';
import LevelSelectionBar from 'components/KooQuiz/LevelSelectionBar';
import BookShelf from 'components/KooQuiz/BookShelf';
import GiftBarHeader from 'components/KooQuiz/GiftBarHeader';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import usePreviousValue from 'hooks/usePreviousValue';
import {
  kooQuizTypes,
  mathLevels,
  scienceLevels,
  MAPPING_PARAM_BY_PAPER_NAME,
} from 'constants/kooQuiz';
import { FilterSubjectStatus } from 'constants/index';
import {
  getPapers,
  getCurrentGiftBySubject,
  setKooQuizSelectedPaper,
  setGiftObj,
  setKooQuizType,
  resetSelectedPaper,
} from 'store/kooQuiz/kooQuizSlice';
import { getRewards } from 'store/dashboard/rewardsSlice';
import KooQuizVideoSolution from './KooQuizVideoSolution';
import { Container, StyledSpinnerWarper } from './KooQuizMainPage.styles';

const MATH_PAPER_VALUES = {
  'Essential Skills': 1,
  'Top School Year-End Paper': 2,
  'HOTS Papers': 3,
};

const MATH_PAPER_NAME = {
  1: 'Essential Skills',
  2: 'Top School Year-End Paper',
  3: 'HOTS Papers',
};

const SCIENCE_PAPER_VALUES = {
  'Top School Year-End Paper': 2,
  'Open-Ended Questions': 4,
};

const SCIENCE_PAPER_NAME = {
  2: 'Top School Year-End Paper',
  4: 'Open-Ended Questions',
};

const KooQuizMainPage = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { paper } = useParams();
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const { currentProduct } = useSelector((state) => state.plan);
  const {
    isLoading,
    papers,
    giftsInfo,
    kooQuizSelectedPaper,
    giftObj,
    error,
    errorPapers,
  } = useSelector((state) => state.kooQuiz);
  const paperVal = parseInt(match.params.paper, 10);
  const [selectedPaper, setSelectedPaper] = useState(paperVal);
  const [selectedLevel, setSelectedLevel] = useState(defaultLevel);
  const [currentPaperName, setCurrentPaperName] = useState('Essential Skills');
  const allPapers = (papers && papers.List) || [];
  const kooQuizFeatureData = currentProduct.features.filter(
    (item) => item.feature === 'KooQuiz'
  )[0];
  const selectedSubject = match.params.subject;
  const subject = FilterSubjectStatus[selectedSubject];
  const levels = selectedSubject === 'Maths' ? mathLevels : scienceLevels;
  const prevSubject = usePreviousValue(subject);

  useEffect(() => {
    dispatch(setKooQuizSelectedPaper(paperVal));
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    if (selectedSubject === 'Maths') {
      setSelectedLevel(defaultLevel || mathLevels[0]);
      dispatch(setKooQuizType(kooQuizTypes[1]));
    } else {
      setSelectedLevel(defaultLevel > 2 ? defaultLevel : scienceLevels[0]);
      dispatch(setKooQuizType(kooQuizTypes[2]));
    }
    setSelectedPaper(paperVal);
    // eslint-disable-next-line
  }, [paperVal]);
  useEffect(() => {
    if (prevSubject !== subject) {
      dispatch(getCurrentGiftBySubject(subject));
    }
    // eslint-disable-next-line
  }, [prevSubject, subject]);
  useEffect(() => {
    if (giftsInfo.length > 0) {
      if (subject === 2 && selectedPaper === 1) {
        // science and Top School Year-End Paper
        dispatch(setGiftObj(giftsInfo[0]));
      } else if (subject === 2 && selectedPaper === 4) {
        // science and Open-Ended Questions
        dispatch(setGiftObj(giftsInfo[1]));
      } else if (subject === 1 && selectedPaper === 1) {
        // math and Essential Skills
        dispatch(setGiftObj(giftsInfo[0]));
      } else if (subject === 1 && selectedPaper === 2) {
        // math and Top School Year-End Paper
        dispatch(setGiftObj(giftsInfo[1]));
      } else if (subject === 1 && selectedPaper === 3) {
        // math and Top School Year-End Paper
        dispatch(setGiftObj(giftsInfo[2]));
      } else {
        dispatch(setGiftObj(giftsInfo[0]));
      }
    }
    // eslint-disable-next-line
  }, [giftsInfo, selectedPaper]);

  useEffect(() => {
    dispatch(getRewards());
    const paperName =
      subject === 1 ? MATH_PAPER_NAME[paper] : SCIENCE_PAPER_NAME[paper];

    const params = {
      subjectId: subject,
      rawBody:
        kooQuizFeatureData.parameters.Category[
          MAPPING_PARAM_BY_PAPER_NAME[paperName]
        ],
    };

    dispatch(getPapers(params));
    dispatch(resetSelectedPaper());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // toggle between Essential Skills and Top School in Maths
  const changePaperType = (name) => {
    setCurrentPaperName(name);
    if (selectedSubject === 'Science') {
      const val = SCIENCE_PAPER_VALUES[name];
      history.push(`/kooQuiz/Science/${val}`);
      setSelectedPaper(val);
      dispatch(setKooQuizSelectedPaper(val));
      setSelectedLevel(defaultLevel);
      const params = {
        subjectId: subject,
        rawBody:
          kooQuizFeatureData.parameters.Category[
            MAPPING_PARAM_BY_PAPER_NAME[name]
          ],
      };
      dispatch(getPapers(params));
    }
    if (selectedSubject === 'Maths') {
      const val = MATH_PAPER_VALUES[name];
      history.push(`/kooQuiz/Maths/${val}`);
      setSelectedPaper(val);
      dispatch(setKooQuizSelectedPaper(val));
      setSelectedLevel(defaultLevel);
      const params = {
        subjectId: subject,
        rawBody:
          kooQuizFeatureData.parameters.Category[
            MAPPING_PARAM_BY_PAPER_NAME[name]
          ],
      };
      dispatch(getPapers(params));
    }
  };
  const reloadHandler = () => {
    const params = {
      subjectId: subject,
      rawBody:
        kooQuizFeatureData.parameters.Category[
          MAPPING_PARAM_BY_PAPER_NAME[currentPaperName]
        ],
    };
    if (!isNil(errorPapers) || !isNil(error)) {
      dispatch(getPapers(params));
      dispatch(getCurrentGiftBySubject(params.subjectId));
    }
  };
  return (
    <>
      <Container>
        <MainPageTopbar
          kooQuizSelectedPaper={kooQuizSelectedPaper}
          setSelectedPaper={setSelectedPaper}
          clickHandle={changePaperType}
          selectedSubject={selectedSubject}
        />
        <LevelSelectionBar
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
          defaultLevel={defaultLevel}
          selectedSubject={selectedSubject}
          papers={papers}
          isShowLevel={paper !== '3'}
        />
        {isLoading ? (
          <StyledSpinnerWarper>
            <Spinner />
          </StyledSpinnerWarper>
        ) : (
          <>
            <GiftBarHeader
              selectedPaper={kooQuizSelectedPaper}
              giftObj={giftObj}
            />
            {levels.map((level) => (
              <BookShelf
                key={level}
                selectedLevel={level}
                selectedPaper={kooQuizSelectedPaper}
                paperVal={paperVal}
                giftObj={giftObj}
                data={allPapers.filter((e) => e.LevelId === level)}
              />
            ))}
            {paper === '3' && <KooQuizVideoSolution />}
          </>
        )}
      </Container>
      {(!isNil(errorPapers) || !isNil(error)) && (
        <ErrorModal
          reloadAction={reloadHandler}
          errorMessage={errorPapers || error}
        />
      )}
    </>
  );
};
const checkActivePlan = (Component) => (props) => {
  const { subject, paper } = props.match.params;
  const { products, isLoading, getProductsError } = useSelector(
    (state) => state.plan
  );
  const selectedSubject =
    subject.toLowerCase() === 'maths' ? 'math' : 'science';
  const paperChecker = {
    science: {
      2: true,
      4: true,
    },
    math: {
      1: true,
      2: true,
      3: true,
    },
  };
  if (!paperChecker[selectedSubject][paper]) {
    return <Redirect to="/dashboard?view=kootraining" />;
  }
  if (isLoading) {
    return <Spinner />;
  }
  if (!isLoading && products && !getProductsError) {
    const findProduct = products.find(
      (product) =>
        product.subject.toLowerCase().trim() === selectedSubject &&
        product.nonExpiredSubscriptionProducts.length
    );
    if (
      findProduct &&
      findProduct.nonExpiredSubscriptionProducts.find(
        (plan) =>
          plan?.features.find(
            ({ feature }) => feature.toLowerCase().trim() === 'kooquiz'
          )?.parameters.IsEnabled === true
      )
    ) {
      return <Component {...props} />;
    }
    return <Redirect to="/dashboard?view=kootraining" />;
  }
  return null;
};
export default checkActivePlan(KooQuizMainPage);
