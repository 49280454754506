import React from 'react';
import { Container } from './DetailInfo.styles';

const DetailInfo = ({
  title,
  data,
  onChange,
  editable = false,
  marginBottom,
  bold,
  edit,
  cancelClick,
  updateClick,
  editClick,
  id,
  type,
  children,
  inputType,
  isLoading,
  validation,
  defaultValue,
}) => {
  return (
    <Container marginBottom={marginBottom} bold={bold} edit={edit}>
      <div className="info">
        <div className="title">
          {type === 'parent' && id === 1 && edit ? children : title}
        </div>
        <div className="input-field">
          {type === 'parent' && id === 3 && edit ? (
            children
          ) : (
            <>
              {!edit ? (
                <div className="data">{defaultValue}</div>
              ) : (
                <input
                  className="data"
                  value={data}
                  onChange={onChange}
                  disabled={!edit}
                  type={inputType}
                />
              )}
            </>
          )}
          <div className="error-message">{validation}</div>
        </div>
      </div>
      {editable && !edit && (
        <div className="edit-btn" onClick={editClick}>
          Edit
        </div>
      )}
      {edit && (
        <div className="btn-section">
          <button className="btn white" onClick={cancelClick}>
            Cancel
          </button>
          <button
            type="submit"
            className="btn orange"
            onClick={updateClick}
            disabled={validation !== ''}
          >
            {isLoading ? 'Updating' : 'Update'}
          </button>
        </div>
      )}
    </Container>
  );
};

export default DetailInfo;
