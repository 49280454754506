import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Shared/Button';
import { Spinner } from 'react-bootstrap';
import { Container } from './ConfirmModal.styles';

const ConfirmModal = ({
  text,
  noHandler,
  yesHandler,
  closeHandler,
  errorMsg,
  loading,
}) => {
  const { t } = useTranslation(['activities', 'errorModal', 'common']);
  return (
    <Container data-cy="modal">
      <div className="close-btn" onClick={closeHandler}>
        X
      </div>
      {!loading && !errorMsg && (
        <>
          <p dangerouslySetInnerHTML={{ __html: text }} />
          <div className="action-btn">
            <Button
              isDisabled={loading}
              variant="secondary"
              width="150px"
              onClick={noHandler}
              dataCy="no"
            >
              {t('activities:doAndLearn.popUpModal.no', 'No')}
            </Button>
            <Button
              isDisabled={loading}
              variant="primary"
              width="150px"
              onClick={yesHandler}
              dataCy="yes"
            >
              {t('activities:doAndLearn.popUpModal.yes', 'Yes')}
            </Button>
          </div>
        </>
      )}
      {loading && <Spinner animation="border" size="sm" variant="warning" />}
      {errorMsg && (
        <div className="err-msg">
          <p className="error-title">{t('common:sorry', 'Sorry')}</p>
          <p>
            {errorMsg.toLowerCase() === 'unknown error'
              ? t('errorModal:someWrong', 'Something went wrong')
              : errorMsg}
          </p>
        </div>
      )}
    </Container>
  );
};

export default ConfirmModal;
