import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import BffHeader from 'components/Leaderboard/Bff/BffHeader';
import StudentList from 'components/Leaderboard/StudentList';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { isEmpty } from 'ramda';
import { getWeeks, getGroups, getFriendsList } from 'store/bff/bffSlice';
import { Container, BffCategory } from './Bff.styles';
import { NoParticipant } from './TopBrain.styles';

const Bff = () => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { isLoading, groupsData, weeks } = useSelector((state) => state.bff);

  const [showAllStudents, setShowAllStudents] = useState({});
  const [dropdownItems, setDropdownItems] = useState(weeks[0]);

  useEffect(() => {
    dispatch(getWeeks());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(weeks)) {
      setDropdownItems(weeks[0]);
      dispatch(getGroups({ days: weeks[0].Id }));
    }
  }, [dispatch, weeks]);

  const changeWeek = (val) => {
    setDropdownItems(val);
    dispatch(getGroups({ days: val.Id }));
    setShowAllStudents({});
  };

  const toggleAllStudent = (index, userId) => {
    const tempData = { ...showAllStudents };
    tempData[index] = !tempData[index];
    setShowAllStudents(tempData);
    if (tempData[index])
      dispatch(getFriendsList({ userId, days: dropdownItems.Id }));
  };

  return (
    <Container>
      <BffHeader />
      <BffCategory>
        <span>
          {t('leaderboard:bff.challengePoints', 'Challenge Points of The Week')}
        </span>
        <div className="curriculum-date">
          <DropdownMenu
            dataCy="dropdown-week"
            selectedValue={dropdownItems && dropdownItems.WeekDuration}
            valueKey="WeekDuration"
            values={weeks}
            setValue={changeWeek}
            width="auto"
            className="drop-down"
          />
        </div>
      </BffCategory>
      <StudentList
        challengeMode="bff"
        reducer="bff"
        data={groupsData}
        isTopFifty
        isLoading={isLoading}
        showAllStudents={showAllStudents}
        toggleAllStudent={toggleAllStudent}
      />
      {!isLoading && groupsData.length === 0 && (
        <NoParticipant>
          {t(
            'leaderboard:hodBanner.noParticipant',
            'No participants at the moment.'
          )}
        </NoParticipant>
      )}
    </Container>
  );
};

export default Bff;
