import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BackButton from 'components/Shared/BackButton';

import LeftKid from 'assets/img/leaderboard/champion-class/Kids1.svg';
import RightKid from 'assets/img/leaderboard/champion-class/Kid2.svg';
import Gift from 'assets/img/leaderboard/champion-class/gift.svg';

import formatI18nDateTime from 'helpers/formatI18nDateTime';
import { convertToTimezone } from 'pages/Leaderboard/v2/helper';
import { LOADING } from 'pages/Leaderboard/v2/constant';

import { ChallengeType } from 'pages/Leaderboard/v2/components';
import CCToggle from '../CCToggle';
import TermList from '../../../TermList';

import {
  Container,
  CenterBar,
  BrainHeading,
  ScoringPeriod,
  ButtonContainer,
} from './HeaderMathTermCC.styles';

const HeaderMathTermCC = ({ setCurrentTerm, currentTerm, toggleHandler }) => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const history = useHistory();
  const { type } = useSelector((state) => state.championClassV2);
  const { isLoading } = useSelector((state) => state.leaderboard.terms);

  return (
    <Container>
      <ButtonContainer>
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(209, 194, 20, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/leaderboard?subject=math')}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <img height="100%" src={LeftKid} alt="left-kid" className="left-kid" />
      <CenterBar activeTerm={type}>
        <div className="middle-content">
          <BrainHeading
            content={t(
              'leaderboard:championClass.championClass',
              `Champion Class`
            )}
          >
            {t('leaderboard:championClass.championClass', `Champion Class`)}
          </BrainHeading>
          <CCToggle clickHandler={toggleHandler} />
        </div>
        <TermList
          data-cy="term-container"
          setCurrentTerm={setCurrentTerm}
          currentTerm={currentTerm}
        />
        <ScoringPeriod>
          {isLoading ? (
            LOADING
          ) : (
            <>
              <div className="period">
                <span>
                  {t(
                    'leaderboard:topBrainBanner.scoringPeriod',
                    'Scoring Period'
                  )}
                </span>{' '}
                {t(
                  'leaderboard:topBrainBanner.term',
                  { number: currentTerm?.termId },
                  `Term ${currentTerm?.termId}`
                )}
                ,{' '}
                {t('leaderboard:topBrainBanner.dateRange', {
                  week: `${currentTerm?.startWeek} - ${currentTerm?.endWeek}`,
                  start: convertToTimezone(currentTerm?.startDate).format(
                    formatI18nDateTime({
                      language: i18n.language,
                      format: 'DD-MMM',
                      dateOnly: true,
                    })
                  ),
                  end: convertToTimezone(currentTerm?.endDate).format(
                    formatI18nDateTime({
                      language: i18n.language,
                      format: 'DD-MMM',
                      dateOnly: true,
                    })
                  ),
                })}
              </div>
              <div className="physical-prize">
                <img src={Gift} alt="gift" />
                {t(
                  'leaderboard:championClass.bannerMsg',
                  `Prizes will be sent to your school after the scoring period.`
                )}
              </div>
            </>
          )}
        </ScoringPeriod>
        <img className="right-kid" src={RightKid} alt="right-kid" />
      </CenterBar>
      <ChallengeType styles={{ top: '12%' }} />
    </Container>
  );
};

export default HeaderMathTermCC;
