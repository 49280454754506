import styled from 'styled-components';
import MenuIcon from 'assets/img/navigation/9dots.svg';
import MenuActiveIcon from 'assets/img/navigation/9dots-active.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 42px;
  justify-content: center;
  align-items: center;
  background: ${({ open }) => open && '#343434'};
  cursor: pointer;
  &:hover {
    background-color: #343434;
    .bg {
      background-image: url(${MenuActiveIcon});
    }
  }
  .bg {
    background-image: url(${MenuIcon});
    width: 30px;
    background-repeat: no-repeat;
    height: 28px;
    background-size: contain;
    @media (max-width: 414px) {
      width: 20px;
      height: 20px;
    }
  }
`;

export default Container;
