import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border-radius: 8px;
  min-height: 250px;
  min-width: 515px;
  p {
    margin-bottom: 0px;
  }
  .header {
    background: #e6faff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #20a9c9;
    padding: 0.3rem;
    .title {
      display: flex;
      align-items: center;
      img {
        margin-right: 0.8rem;
      }
    }
    .close-btn {
      position: absolute;
      right: 8%;
      cursor: pointer;
    }
  }
`;
