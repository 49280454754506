import compareAnswerKeyValuePairs from 'helpers/compareAnswerKeyValuePairs';
import {
  compareWorkings,
  compareBarModel,
} from 'helpers/compareAnswerKeyValuePairs';
import { createAnswerKeyValuePairsByQuestionType } from 'helpers/createAnswerKeyValuePairs';
import parseWorkingsBarModel from 'helpers/parseWorkingsBarModel';
import { SubmissionResultTypes } from 'constants/index';
import { isNil } from 'ramda';

const saveAnswerHandler = ({
  assignmentSubmissionID,
  assignmentType,
  questionType,
  submissionType,
  questionAnswer,
  saveAnswerAction,
  checkAnswerAction,
  activeQnNo,
  currentQn,
  currentLocalSavedAnswer,
  currentSubmission,
  timeElapsed,
  userID,
  workings,
  barModel,
}) => {
  let answerKeyValuePairs;
  // Construct JSON body for API request
  const rawBody = {
    homeworkSubmissionID: assignmentSubmissionID,
    duration: timeElapsed,
    userID,
    questionID: currentQn.Id,
    type: submissionType,
    questionType: currentQn.QuestionType,
    versionId: currentQn.Version,
    answerkeyValuePairs: answerKeyValuePairs,
    isFinalSubmit: false,
  };
  if (!isNil(workings) || !isNil(barModel)) {
    // Save workings/bar model data under property "solution"
    const solutionObject = {
      workings: isNil(workings) ? null : workings,
      barModel: isNil(barModel) ? null : barModel,
    };
    // Stringify solution object as API only accepts value type string for solution
    rawBody.solution = JSON.stringify(solutionObject);
  }
  if (assignmentType === 'sundayMC')
    rawBody.SMCSubmissionID = assignmentSubmissionID;
  else rawBody.homeworkSubmissionID = assignmentSubmissionID;
  // Construct keys of textareas to mark as right [For Long Ans Qns]
  let keysToMarkRight;
  if (questionType === 'longAnswer') {
    // Current submission is null when user has not perform any of save/check answer/mark it right actions
    keysToMarkRight =
      currentSubmission !== null
        ? currentSubmission.SubmissionKeyValuePairs.filter(
            (keyValuePair) => keyValuePair.ismarkedright === true
          ).map((keyValuePair) => keyValuePair.Key)
        : [];
  }
  // For assignment type: mocktest/test, only saving since student cannot check answer
  if (assignmentType === 'mocktest' || assignmentType === 'test') {
    // Check if there are local saved answers
    if (currentLocalSavedAnswer !== null) {
      answerKeyValuePairs = createAnswerKeyValuePairsByQuestionType(
        questionType,
        {
          studentAnswers: currentLocalSavedAnswer,
          questionAnswers: questionAnswer,
          userID,
          qnID: currentQn.Id,
          keysToMarkRight,
        }
      );
    } else if (currentSubmission !== null) {
      answerKeyValuePairs = currentSubmission.SubmissionKeyValuePairs;
    } else {
      answerKeyValuePairs = createAnswerKeyValuePairsByQuestionType(
        questionType,
        {
          studentAnswers: null,
          questionAnswers: questionAnswer,
          userID,
          qnID: currentQn.Id,
          keysToMarkRight,
        }
      );
    }
    rawBody.answerkeyValuePairs = answerKeyValuePairs;
    // Dispatch save answer redux action
    saveAnswerAction({
      rawBody,
      activeQuestion: activeQnNo,
    });
  }
  // For assignment type: homework, can be save or check answer
  else if (assignmentType === 'homework') {
    const localAnswers =
      currentLocalSavedAnswer !== null ? currentLocalSavedAnswer : null;
    const submissionResult =
      currentSubmission !== null
        ? SubmissionResultTypes[currentSubmission.SubmissionResult]
        : null;
    // Check if there are answer submissions
    if (currentSubmission !== null) {
      if (submissionResult === 'correct') {
        answerKeyValuePairs = currentSubmission.SubmissionKeyValuePairs;
        rawBody.answerkeyValuePairs = answerKeyValuePairs;
        // Dispatch check answer redux action
        checkAnswerAction({
          rawBody,
          activeQuestion: activeQnNo,
        });
      } else if (submissionResult === 'wrong') {
        // Check if localAnswers is null
        if (localAnswers !== null) {
          // TODO: Update function for mcq qns
          const isAnswersSame = compareAnswerKeyValuePairs(
            questionType,
            localAnswers,
            currentSubmission.SubmissionKeyValuePairs,
            currentQn.Id
          );

          // Check if local answers === submitted answers
          if (isAnswersSame) {
            answerKeyValuePairs = currentSubmission.SubmissionKeyValuePairs;
            rawBody.answerkeyValuePairs = answerKeyValuePairs;
            // Dispatch check answer redux action
            checkAnswerAction({
              rawBody,
              activeQuestion: activeQnNo,
            });
          } else {
            answerKeyValuePairs = createAnswerKeyValuePairsByQuestionType(
              questionType,
              {
                studentAnswers: localAnswers,
                questionAnswers: questionAnswer,
                userID,
                qnID: currentQn.Id,
                keysToMarkRight,
              }
            );
            rawBody.answerkeyValuePairs = answerKeyValuePairs;
            saveAnswerAction({
              rawBody,
              activeQuestion: activeQnNo,
            });
          }
        }
        // localAnswers is null
        else {
          answerKeyValuePairs = currentSubmission.SubmissionKeyValuePairs;
          rawBody.answerkeyValuePairs = answerKeyValuePairs;
          // Dispatch check answer redux action
          checkAnswerAction({
            rawBody,
            activeQuestion: activeQnNo,
          });
        }
      } // answer submission is neither correct/wrong
      else if (localAnswers !== null) {
        answerKeyValuePairs = createAnswerKeyValuePairsByQuestionType(
          questionType,
          {
            studentAnswers: localAnswers,
            questionAnswers: questionAnswer,
            userID,
            qnID: currentQn.Id,
            keysToMarkRight,
          }
        );
        rawBody.answerkeyValuePairs = answerKeyValuePairs;
        saveAnswerAction({
          rawBody,
          activeQuestion: activeQnNo,
        });
      } else {
        answerKeyValuePairs = currentSubmission.SubmissionKeyValuePairs;
        rawBody.answerkeyValuePairs = answerKeyValuePairs;
        saveAnswerAction({
          rawBody,
          activeQuestion: activeQnNo,
        });
      }
    } // No answer submissions
    else {
      answerKeyValuePairs = createAnswerKeyValuePairsByQuestionType(
        questionType,
        {
          studentAnswers: localAnswers,
          questionAnswers: questionAnswer,
          userID,
          qnID: currentQn.Id,
        }
      );
      rawBody.answerkeyValuePairs = answerKeyValuePairs;
      saveAnswerAction({
        rawBody,
        activeQuestion: activeQnNo,
      });
    }
  } else if (assignmentType === 'sundayMC') {
    if (currentLocalSavedAnswer !== null) {
      answerKeyValuePairs = createAnswerKeyValuePairsByQuestionType(
        questionType,
        {
          studentAnswers: currentLocalSavedAnswer,
          questionAnswers: questionAnswer,
          userID,
          qnID: currentQn.Id,
          keysToMarkRight,
        }
      );
    } else if (currentSubmission !== null) {
      answerKeyValuePairs = currentSubmission.SubmissionKeyValuePairs;
    } else {
      answerKeyValuePairs = createAnswerKeyValuePairsByQuestionType(
        questionType,
        {
          studentAnswers: null,
          questionAnswers: questionAnswer,
          userID,
          qnID: currentQn.Id,
          keysToMarkRight,
        }
      );
    }
    rawBody.answerkeyValuePairs = answerKeyValuePairs;
    saveAnswerAction({
      rawBody,
      activeQuestion: activeQnNo,
    });
  }
  return rawBody;
};

export default saveAnswerHandler;

export const saveKooClassAnsHandler = (
  isSaveBtn,
  submissionId,
  questionType,
  questionAnswer,
  saveAnswerAction,
  activeQnNo,
  currentQn,
  currentLocalSavedAnswer,
  currentSubmission,
  userID,
  workings,
  barModel,
  activityType
) => {
  const rawBody = {
    userID,
    questionID: currentQn.id,
    questionType: currentQn.questionType,
    versionId: currentQn.version,
    activityType: Number(activityType),
    isFinalSubmit: false,
  };
  if (!isNil(workings) || !isNil(barModel)) {
    // Save workings/bar model data under property "solution"
    const solutionObject = {
      workings: isNil(workings) ? null : workings,
      barModel: isNil(barModel) ? null : barModel,
    };
    // Stringify solution object as API only accepts value type string for solution
    rawBody.solution = JSON.stringify(solutionObject);
  }
  const localAnswers =
    currentLocalSavedAnswer !== null ? currentLocalSavedAnswer : null;
  // const submissionResult =
  //   currentSubmission !== null
  //     ? SubmissionResultTypes[currentSubmission.submissionResult]
  //     : null;

  let answerKeyValuePairs = createAnswerKeyValuePairsByQuestionType(
    questionType,
    {
      studentAnswers: localAnswers,
      questionAnswers: questionAnswer,
      userID,
      qnID: currentQn.id,
    }
  );
  // Check if save btn or Finish button clicked
  if (isSaveBtn) {
    if (currentSubmission !== null) {
      if (localAnswers !== null) {
        // save with new values if answer is saved in local
        rawBody.answerkeyValuePairs = answerKeyValuePairs;
        saveAnswerAction({
          rawBody,
          activeQuestion: activeQnNo,
          userActivityId: submissionId,
        });
      } else {
        // save with previous values if no answer saved in local
        rawBody.answerkeyValuePairs = currentSubmission.submissionKeyValuePairs;
        saveAnswerAction({
          rawBody,
          activeQuestion: activeQnNo,
          userActivityId: submissionId,
        });
      }
    } else {
      // No answer submissions before
      rawBody.answerkeyValuePairs = answerKeyValuePairs;
      saveAnswerAction({
        rawBody,
        activeQuestion: activeQnNo,
        userActivityId: submissionId,
      });
    }
  } else {
    // If navigating b/w pages
    if (currentSubmission !== null) {
      const isWorkingSame = compareWorkings(
        workings,
        parseWorkingsBarModel(currentSubmission.solution).workings
      );
      const isBarModelSame = compareBarModel(
        barModel,
        parseWorkingsBarModel(currentSubmission.solution).barModel
      );
      if (localAnswers !== null) {
        const isAnswersSame = compareAnswerKeyValuePairs(
          questionType,
          localAnswers,
          currentSubmission.submissionKeyValuePairs,
          currentQn.id,
          'KooClass'
        );
        // Check if local answers !== submitted answers
        if (!isAnswersSame || !isWorkingSame || !isBarModelSame) {
          rawBody.answerkeyValuePairs = answerKeyValuePairs;
          saveAnswerAction({
            rawBody,
            activeQuestion: activeQnNo,
            userActivityId: submissionId,
          });
        }
      } else {
        if (!isWorkingSame || !isBarModelSame) {
          rawBody.answerkeyValuePairs = answerKeyValuePairs;
          saveAnswerAction({
            rawBody,
            activeQuestion: activeQnNo,
            userActivityId: submissionId,
          });
        }
      }
    } else {
      // No answer submissions before
      rawBody.answerkeyValuePairs = answerKeyValuePairs;
      saveAnswerAction({
        rawBody,
        activeQuestion: activeQnNo,
        userActivityId: submissionId,
      });
    }
  }
};
