import styled from 'styled-components';
import { MAX_WIDTH } from './constants';
export const StyledContainer = styled.div`
  max-width: ${MAX_WIDTH + 32}px;
  width: 100%;
  margin: 27px auto 0;
  padding: 0 16px;
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    span {
      color: #626262;
    }
    p {
      font-size: 26px;
      line-height: 1;
      margin: 0 10px 0 0;
    }
  }
  .right {
    display: flex;
    align-items: center;
    svg {
      width: 26px;
      color: ${({ subject }) => (subject === 'math' ? '#2BC5FF' : '#96FF1C')};
      margin: 0 8px 2px 8px;
    }
    p {
      margin: 0;
    }
    span {
      font-size: 34px;
    }
  }
`;
export const StyledTable = styled.table`
  margin-top: 26px;
  margin-bottom: 60px;
  width: 100%;
  th {
    color: #b4b4b4;
    font-weight: normal;
    padding-bottom: 8px;
  }
  tr {
    border-bottom: 1px solid #efefef;
  }
  td {
    padding: 19px 0;
  }
  td,
  th {
    &:first-child,
    &:nth-child(2) {
      width: 140px;
    }
  }
  .earned {
    text-align: center;
  }
`;
export const StyledNoDataWrap = styled.div`
  margin-top: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  > div {
    margin-left: 40px;
  }
  p {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1;
  }
  span {
    font-size: 14px;
    color: #656565;
  }
  .hint {
    color: #b7b5b5;
    margin-top: 14px;
    display: block;
  }
`;
