import styled from 'styled-components';
import {
  Global100Boys,
  Global100Girls,
} from 'assets/img/leaderboard/boys-vs-girls';

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
  width: 100%;
  font-size: 14px;
  position: relative;

  table,
  tr {
    width: 100%;
  }

  tr th,
  tr td {
    /* border-right: 1px solid #bbb; */
    border-right: ${(props) =>
      `1px solid ${props.color === 'pink' ? '#FFD6E1' : '#A3E3FF'}`};
    border-bottom: 1px solid #bbb;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  tr th,
  tr td {
    border-right: ${(props) =>
      `1px solid ${props.color === 'pink' ? '#FFD6E1' : '#A3E3FF'}`};
    border-bottom: ${(props) =>
      `1px solid ${props.color === 'pink' ? '#FFD6E1' : '#A3E3FF'}`};
  }
  tr th:first-child,
  tr td:first-child {
    border-left: ${(props) =>
      `1px solid ${props.color === 'pink' ? '#FFD6E1' : '#A3E3FF'}`};
  }
  tr th {
    background: ${(props) =>
      `${props.color === 'pink' ? '#FFD6E1' : '#A3E3FF'}`};
    border-top: ${(props) =>
      `1px solid ${props.color === 'pink' ? '#FFD6E1' : '#A3E3FF'}`};
    text-align: left;
  }

  tr:nth-child(odd) {
    background-color: ${(props) =>
      `${props.color === 'pink' ? '#FFF0F5' : '#d8f3ff'}`};
  }

  /* top-left border-radius */
  tr:first-child th:first-child {
    border-top-left-radius: 6px;
  }

  /* top-right border-radius */
  tr:first-child th:last-child {
    border-top-right-radius: 6px;
  }

  /* bottom-left border-radius */
  tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
  }

  /* bottom-right border-radius */
  tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
  }

  .global-header {
    line-height: 40px;
    vertical-align: bottom;
  }

  .text-center {
    text-align: center;
  }
  .ranking {
    color: ${(props) => (props.color === 'pink' ? '#F27C9C' : '#31AEE2')};
  }

  .student-name {
    color: ${(props) => (props.color === 'pink' ? '#E6517A' : '#1097D0')};
    font-weight: 600;
    max-width: 315px;
    overflow: auto;
  }

  @media (max-width: 1440px) {
    .student-name {
      max-width: 230px;
      padding-bottom: 3px;
      white-space: nowrap;
    }
  }

  @media (max-width: 991px) {
    .student-name {
      max-width: 450px;
    }
  }
`;

export const BannerTop = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: ${(props) =>
    props.color === 'pink'
      ? `url(${Global100Girls})`
      : `url(${Global100Boys})`};
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 300px;
  background-size: contain;
  p {
    line-height: 2;
    color: #fafdfd;
    font-size: 15px;
    font-family: 'Lao UIb';
  }
`;
