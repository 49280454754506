import styled from 'styled-components';

export const Container = styled.div``;

export const AlertWrapper = styled.div`
  width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ffffff;
  box-sizing: inherit;
  border-radius: 20px;
`;

export const AlertHeader = styled.div`
  background-color: #ffd548;
  box-sizing: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0 0;
  .unlock-icon {
    margin-top: -45px;
  }
`;

export const AlertHeaderTitle = styled.h2`
  box-sizing: inherit;
  display: block;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
`;

export const AlertContent = styled.div`
  box-sizing: inherit;
  display: block;
`;

export const AlertMessage = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 1.5rem 0;
  box-sizing: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  .locked-message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 0px;

    .koko-icon {
      width: 28px;
      height: 28px;
      margin-right: 0.3rem;
    }
    .koko {
      font-weight: bold;
      margin-right: ${({ lang }) => lang && '0.3rem'};
    }
  }
`;

export const AlertAction = styled.div`
  display: flex;
  justify-content: space-evenly;
  box-sizing: inherit;
  margin-bottom: 1rem;
  .koko-icon-btn {
    width: 28px;
    height: 28px;
  }
  .btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
    .bold-text {
      margin: 0 5px;
      display: inline;
      font-weight: bold;
    }
  }
`;

export const AlertFooter = styled.div`
  background-color: #ebf6ff;
  border-radius: 0 0 20px 20px;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  .footer-text {
    margin: 0 5px 0 0;
  }
  .mission-icon {
    width: 24px;
    height: 24px;
  }
  .mission-text {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-right: 5px;
      margin-left: 0;
      display: inline;
    }
  }
`;

export const CloseButton = styled.div`
  color: #f8c61e;
  background-color: #ffeaa2;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
`;
