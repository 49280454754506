import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';
import { featureToggle } from 'constants/index';

const baseUrl = process.env.REACT_APP_LEADERBOARD_API_DOMAIN;
const v2Url = `${baseUrl}Leaderboard/v2/TopBrain`;
const v1Url = `${baseUrl}Leaderboard/TopBrain`;

// get terms
export const fetchTermsTopBrain = () => {
  return fetch(`${baseUrl}Leaderboard/TopBrain/GetTerms`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get terms failed'); // eslint-disable-line
    });
};

// get top brain data by term
export const fetchTopBrainByTerm = ({
  enumTopBrainFilter,
  term,
  level,
  subject,
}) => {
  const url = featureToggle.science
    ? `${v2Url}/subject/${subject}/GetTopBrainDataByTerm/${enumTopBrainFilter}/${term}/${level}`
    : `${v1Url}/GetTopBrainDataByTerm/${enumTopBrainFilter}/${term}/${level}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get Top brain by term data failed'); // eslint-disable-line
    });
};

// get top 50 top brain by term
export const fetchTopBrainTopFiftyByTerm = ({
  enumTopBrainFilter,
  term,
  level,
  subject,
}) => {
  const url = featureToggle.science
    ? `${v2Url}/subject/${subject}/GetTopBrainTopFiftyByTerm/${enumTopBrainFilter}/${term}/${level}`
    : `${v1Url}/GetTopBrainTopFiftyByTerm/${enumTopBrainFilter}/${term}/${level}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get Top 50 top brain by term data failed'
      ); // eslint-disable-line
    });
};

// get seasons
export const fetchSeasonsTopBrain = () => {
  return fetch(`${baseUrl}Leaderboard/TopBrain/GetSeasons`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get seasons failed'); // eslint-disable-line
    });
};

// get top brain data by season
export const fetchTopBrainBySeason = ({
  enumTopBrainFilter,
  season,
  year,
  level,
}) => {
  const subjectID = 1;
  return fetch(
    featureToggle.science
      ? `${baseUrl}Leaderboard/v2/TopBrain/subject/${subjectID}/GetTopBrainDataBySeason/${enumTopBrainFilter}/${season}/${year}/${level}`
      : `${baseUrl}Leaderboard/TopBrain/GetTopBrainDataBySeason/${enumTopBrainFilter}/${season}/${year}/${level}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get Top brain by season data failed'); // eslint-disable-line
    });
};

// get top 50 top brain by season
export const fetchTopBrainTopFiftyBySeason = ({
  enumTopBrainFilter,
  season,
  year,
  level,
}) => {
  const subjectID = 1;
  return fetch(
    featureToggle.science
      ? `${baseUrl}Leaderboard/v2/TopBrain/subject/${subjectID}/GetTopBrainTopFiftyBySeason/${enumTopBrainFilter}/${season}/${year}/${level}`
      : `${baseUrl}Leaderboard/TopBrain/GetTopBrainTopFiftyBySeason/${enumTopBrainFilter}/${season}/${year}/${level}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get Top 50 top brain by season data failed'
      ); // eslint-disable-line
    });
};
