import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterSubmissionStatus,
  ChallengeBy,
  FilterSubjectStatus,
} from 'constants/index';
import { kooQuizSubjectStatus } from 'constants/kooQuiz';
import { Container, Tab } from './HwStatusTabs.styles';

const submissionStatus = {
  0: 'All',
  1: 'Complete',
  2: 'Incomplete',
};
const challengeStatus = {
  0: 'Challenges from friends',
  1: 'Challenges from me',
};

const HwStatusTabs = ({
  className,
  status,
  setStatus,
  tabs,
  type,
  activeColor,
  notActiveColor,
  customOnClick,
  clickHandle,
}) => {
  const { t, i18n } = useTranslation([
    'assignmentList',
    'peerChallengeHistory',
    'kooQuizBookShelf',
  ]);
  const filterType =
    (type === 'assignment' && FilterSubmissionStatus) ||
    (type === 'multiplayer' && ChallengeBy) ||
    (type === 'kooQuiz' && FilterSubjectStatus);
  const selectedTab =
    (type === 'assignment' && submissionStatus[status]) ||
    (type === 'multiplayer' && challengeStatus[status]) ||
    (type === 'kooQuiz' && kooQuizSubjectStatus[status]);
  const isChinese = i18n.language.toLowerCase().includes('zh');
  return (
    <Container type={type} className={className}>
      {tabs.map(({ name, icon, iconWidth, fontSize }, index) => (
        <Tab
          data-cy={`tab-${name}`}
          key={name}
          index={index}
          active={name === selectedTab}
          onClick={(e) => {
            e.preventDefault();
            setStatus(filterType[name]);
            customOnClick && clickHandle(name);
          }}
          activeColor={activeColor}
          notActiveColor={notActiveColor}
          iconWidth={iconWidth}
          type={type}
          fontSize={fontSize}
          isChinese={isChinese}
        >
          {icon && <img src={icon} title={name} alt={name} />}
          <span>
            {type === 'assignment' &&
              t(`assignmentList:topBar.${name}`, `${name}`)}
            {type === 'multiplayer' &&
              t(`peerChallengeHistory:header.${name}`, `${name}`)}
            {type === 'kooQuiz' &&
              t(`kooQuizBookShelf:header.${name}`, `${name}`)}
          </span>
        </Tab>
      ))}
    </Container>
  );
};
export default HwStatusTabs;
