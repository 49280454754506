import React, { useCallback } from 'react';
import { MapContainer, MapPoint } from './DifferentMap.style';

// Helpers
const showMapPoint = (found, index, completed, showAnswer) => {
  if (completed) {
    return showAnswer ? 'completed' : '';
  }
  const isMapPointFound = found.indexOf(index) !== -1;
  if (isMapPointFound) {
    return 'scored';
  }
  return '';
};

const getImgSize = (imgData, refEl) => {
  const baseImg = refEl.current;
  const baseImgWidth = baseImg.clientWidth;
  const baseImgHeight = baseImg.clientHeight;
  const baseImgNaturalWidth = baseImg.naturalWidth;
  const baseImgNaturalHeight = baseImg.naturalHeight;

  const imgWidth = (baseImgWidth / baseImgNaturalWidth) * imgData.Width;
  const imgHeight = (baseImgHeight / baseImgNaturalHeight) * imgData.Height;
  return {
    width: imgWidth,
    height: imgHeight,
  };
};

const getImgPosition = (imgData, refEl) => {
  const baseImg = refEl.current;
  const baseImgWidth = baseImg.clientWidth;
  const baseImgNaturalWidth = baseImg.naturalWidth;

  const imgTop = (baseImgWidth / baseImgNaturalWidth) * imgData.YCoordinate;
  const imgLeft = (baseImgWidth / baseImgNaturalWidth) * imgData.XCoordinate;

  return {
    top: imgTop,
    left: imgLeft,
  };
};

const DifferentMap = ({
  differences,
  refEl,
  onClickSpot,
  onClick,
  found,
  showAnswer,
  completed,
}) => {
  const handleOnClickSpot = useCallback(
    (index) => {
      if (!completed) {
        if (found.indexOf(index) === -1) {
          onClickSpot(index);
        }
      }
    },
    [completed, found, onClickSpot]
  );
  return (
    <MapContainer onClick={onClick} className="area">
      {differences.map((item, index) => {
        const imgDimensions = getImgSize(item, refEl);
        const imgPosition = getImgPosition(item, refEl);
        return (
          <MapPoint
            className={showMapPoint(found, index, completed, showAnswer)}
            onClick={() => handleOnClickSpot(index)}
            key={`data-${index}`}
            top={imgPosition.top}
            left={imgPosition.left}
            width={imgDimensions.width}
            height={imgDimensions.height}
          />
        );
      })}
    </MapContainer>
  );
};

export default React.memo(DifferentMap);
