import styled from 'styled-components';

export const StyledButton = styled.div`
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  position: relative;
  img {
    height: 60px;
    margin-bottom: 4px;
  }
`;

export const StyledBadge = styled.span`
  width: ${(props) => props.size ?? '28px'};
  height: ${(props) => props.size ?? '28px'};
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background: #ff3e03;
  box-shadow: 0 3px 6px 0 rgba(26, 26, 26, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(25%, -25%);
`;
