import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 210px;
  justify-content: space-evenly;
  padding: 0rem 2rem;
  .captcha {
    display: flex;
    flex-direction: column;
    .captcha-qn {
      display: flex;
      justify-content: center;
      font-size: 34px;
      color: #169bb9;
      font-family: Arial;
      font-weight: bold;
    }
    .captcha-answer {
      display: flex;
      align-items: center;
      p {
        margin-right: 0.8rem;
        color: rgb(0, 0, 0, 0.71);
        font-size: 14px;
      }
    }
    .validation-text {
      color: #ff6701;
      width: 100%;
      text-align: center;
      margin-top: 0.3rem;
    }
  }
`;
