import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './EmptySection.styles';

const EmptySection = () => {
  const { t } = useTranslation(['assignmentList']);
  return (
    <Container>
      {t(
        'assignmentList:noAssignment.currentMonth',
        'There is no assignment this month so far. Please wait patiently.'
      )}
    </Container>
  );
};

export default EmptySection;
