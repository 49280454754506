import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MobileOnlyView } from 'react-device-detect';
import ModalContentXButton from 'components/Shared/ModalContentXButton';
import { useTranslation } from 'react-i18next';
import Button from 'components/Shared/Button';
import ActiveRadio from 'assets/img/plan-toggle/activeRadio.png';
import InactiveRadio from 'assets/img/plan-toggle/inactiveRadio.png';
import MobileIcon from 'assets/img/mobile-toggle/icon-mobile.svg';
import DesktopIcon from 'assets/img/mobile-toggle/icon-desktop.svg';
import {
  Container,
  BodyContainer,
  VersionItem,
  StyledButton,
  StyledDescription,
} from './MobileToggleModal.styles';

const MobileToggleModal = ({ seenMobilePopup, setSeenMobilePopup }) => {
  const { t } = useTranslation(['switchMobile', 'common']);
  const [selectedVersion, setSelectedVersion] = useState(1);

  const { hasScreenLoaded } = useSelector((state) => state.studentDetails);

  const toggleVersions = [
    {
      id: 1,
      icon: MobileIcon,
      description: t(
        'switchMobile:mobileVersion.description',
        'Mobile-lite Version'
      ),
      point1: t(
        'switchMobile:mobileVersion.point1',
        'Focused on practise features'
      ),
      point2: t(
        'switchMobile:mobileVersion.point2',
        'Designed for mobile phones'
      ),
    },
    {
      id: 2,
      icon: DesktopIcon,
      description: t('switchMobile:fullVersion.description', 'Full Version'),
      point1: t(
        'switchMobile:fullVersion.point1',
        'Full access to all content and activities'
      ),
      point2: t(
        'switchMobile:fullVersion.point2',
        'Best experienced on computers or tablets'
      ),
    },
  ];

  return (
    <MobileOnlyView>
      <ModalContentXButton
        showModal={seenMobilePopup === false && hasScreenLoaded}
        showClosedButton={false}
        containerMinWidth="90vw"
        zIndex={5}
      >
        <Container>
          <div className="modal-header">
            <p className="modal-title">
              {t('switchMobile:title', 'Welcome To Koobits')}
            </p>
          </div>
          <p className="description">
            {t('switchMobile:description', 'Choose Your Experience')}
          </p>
          <BodyContainer>
            {toggleVersions.map(({ id, icon, description, point1, point2 }) => (
              <VersionItem
                key={id}
                className={selectedVersion === id ? 'active' : ''}
                onClick={() => {
                  setSelectedVersion(id);
                }}
              >
                <img
                  src={selectedVersion === id ? ActiveRadio : InactiveRadio}
                  alt=""
                  width="26px"
                  height="26px"
                  className="icon"
                />
                <img
                  src={icon}
                  alt=""
                  width="50px"
                  height="50px"
                  className="icon"
                />
                <StyledDescription>
                  <h4>{description}</h4>
                  <ul>
                    <li>{point1}</li>
                    <li>{point2}</li>
                  </ul>
                </StyledDescription>
              </VersionItem>
            ))}
          </BodyContainer>
          <StyledButton>
            <Button
              variant="primary"
              width="100%"
              onClick={() => {
                setSeenMobilePopup(selectedVersion);
              }}
            >
              {t('common:start', 'Start')}
            </Button>
          </StyledButton>
          <p className="switch-version">
            *{t('switchMobile:switchAnytime', 'Switch versions anytime')}.
          </p>
        </Container>
      </ModalContentXButton>
    </MobileOnlyView>
  );
};

export default MobileToggleModal;
