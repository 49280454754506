import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 150px;
  margin-left: 2rem;
  display: flex;
  align-items: center;
  color: #404447;
  font-size: 0.875rem;
  span {
    margin-right: 5px;
  }
  @media (max-width: 1290px) {
    right: 100px;
  }
  @media (max-width: 1150px) {
    right: 50px;
  }
`;
