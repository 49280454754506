import React from 'react';
import moment from 'moment-timezone';

import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import { Lists } from '../StudentSubmission.styles';

const ZodiacRaceStudents = ({ nonDuplicateData }) => {
  if (nonDuplicateData.length !== 0) return '';
  return nonDuplicateData.map((item, i) => (
    <React.Fragment key={item.UserId}>
      <div>
        <Lists pos={i}>
          <div className="positions">{i + 1}</div>
          <div className="list-name">{item.UserName}</div>
          <div className="middle">
            <div className="list-points">
              <img width="30px" src={item.SchoolLogo} alt="logo" />
              <img src={CPIcon} alt="cps" />
              {item.CPPoints}
              {item.IsSuperHero && <SuperheroIconModal />}
            </div>
          </div>
          <div className="list-achivement">
            {moment(item.LastSubmissionDate).format('DD-MMM-YYYY HH:mm')}
            {item.CountryCode}
          </div>
        </Lists>
      </div>
    </React.Fragment>
  ));
};

export default ZodiacRaceStudents;
