import React, { useCallback } from 'react';
import practiceImage from 'assets/img/superherochallenge/superspeed/practice-background.png';
import challengeImage from 'assets/img/superherochallenge/superspeed/challenge-background.png';
import pointIcon from 'assets/img/superherochallenge/superspeed/point.png';
import pointChallengeIcon from 'assets/img/superherochallenge/superspeed/point-challenge.png';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from './Button.style';
import {
  Container,
  Title,
  Description,
  HeaderContainer,
  ButtonContainer,
} from './BeforeStartChallenge.style';
import { Header } from './Scene.style';

function BeforeStartChallenge({ onStart, practice }) {
  const { t } = useTranslation(['superSpeed']);
  const history = useHistory();
  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);
  const title = practice
    ? t('superSpeed:game.practiceTitle', 'Practice')
    : t('superSpeed:game.challengeTitle', 'Challenge');
  const background = practice ? practiceImage : challengeImage;
  const startButton = practice
    ? t('superSpeed:game.startNowBtnText', 'Start Now')
    : t('superSpeed:game.challengeBtnText', 'Challenge Now');
  const content = practice
    ? t(
        'superSpeed:game.practiceContent',
        `This is the practice session. It has the same format as the actual challenge, but the scores will not be registered. It's for practice only!`
      )
    : t(
        'superSpeed:game.challengeContent',
        'This is the Super Speed Challenge. You only have one chance per day. If you are not sure how to play, please go back to the Practice session to familiarize yourself with the challenge.'
      );
  return (
    <Container background={background}>
      <HeaderContainer>
        <Header>
          <div className="content">
            <img
              className="point"
              alt="points"
              src={practice ? pointIcon : pointChallengeIcon}
            />
            <span className="score">0</span>
            <span className="timerDescription">
              {t('superSpeed:game.timerDescription', 'Time Left')}
            </span>
          </div>
        </Header>
      </HeaderContainer>
      <Title>{title}</Title>
      <Description>{content}</Description>
      <ButtonContainer>
        <Button
          textColor="#fff"
          backgroundColor="#84d8ff"
          backgroundColorHover="#65ceff"
          onClick={onBack}
        >
          {t('superSpeed:game.backBtnText', 'Back')}
        </Button>
        <Button
          textColor="#fff"
          backgroundColor="#ff7e00"
          backgroundColorHover="#ff7200"
          onClick={onStart}
        >
          {startButton}
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default React.memo(BeforeStartChallenge);
