import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
`;

export const ModalHeader = styled.div`
  width: 100%;
  padding: 0;
  background: #00a1c6;
  height: 95px;
  border-bottom: 1px solid #e5e5e5;
  &:before {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
`;

export const ImgCloseWrapper = styled.img`
  margin-top: -12px;
  margin-right: -10px;
  width: 30px;
  cursor: pointer;
  float: right;
`;

export const ImageCenterWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  float: left;

  img {
    position: relative;
    width: 160px;
    top: -48px;
    &:first-child {
      position: absolute;
      z-index: 100;
    }
  }
`;

export const ModalBody = styled.div`
  font-family: arial;
  font-weight: normal;
  color: #000;
  position: relative;
  padding: 15px;
  text-align: center;
`;

export const TextInfo = styled.p`
  padding: 15px 133px;
  font-weight: bold;
  font-size: 18px;
`;

export const ButtonGrpup = styled.div`
  display: flex;
`;

export const ButtonLink = styled.a`
  justify-content: center;
  cursor: pointer;
`;
