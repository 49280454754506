import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Card = styled.div`
  padding-bottom: 3rem;
  color: black;
  width: 380px;
  .error {
    color: red;
    font-size: 12px;
    text-align: left;
  }
  form {
    margin: 0 5rem;
  }
  .notes {
    margin: 4rem 5rem 1rem;
    color: #2f2f2f;
    .title {
      font-size: 14px;
      font-weight: bold;
    }
    .desc {
      font-size: 14px;
      font-weight: 600;
      padding: 0 2.5rem;
      margin-top: 0.5rem;
    }
    .heading {
      font-size: 18px;
      font-weight: 900;
      margin-top: -20px;
      .grey {
        text-decoration: line-through;
        color: #b1b1b1;
      }
      .dollar-sign {
        font-size: 25px;
        font-weight: bold;
        margin-left: 5px;
      }
      .black {
        font-family: Linotte;
        font-size: 45px;
        font-weight: bold;
      }
    }
    .trial-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20%;
      text-align: left;
      font-size: 12px;
      margin: 10px 0;
      color: #2f2f2f;
    }
    .after-trial-details {
      font-size: 12px;
      color: #404447;
    }
  }
  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: 900;
  }
  hr {
    border-top: 2px dashed #707070;
  }
  .details-text {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 12px;
    color: #9d9d9d;
  }
  .error-text {
    margin-top: 5px;
    color: red;
    font-size: 12px;
    text-align: left;
  }
  .trial-options {
    font-size: 12px;
    color: #020202;
    margin-top: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .preview-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ffc69d;
      border-radius: 20px;
      width: 240px;
      padding: 0.3rem 0;
      margin-top: 3px;
      cursor: pointer;
      background: #ffffff;
      p {
        color: #f46700;
        font-size: 9px;
        max-width: 120px;
        margin-left: 3px;
      }
    }
  }
  .other-inp {
    border: solid 1px #d6d6d6;
    width: 100%;
    border-radius: 1px;
    color: #404447;
    height: 40px;
    margin-bottom: 20px;
    background-color: #f8f9fa;
  }
  .free-trial-started {
    background: #edffe5;
    display: flex;
    min-height: 60px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
`;
