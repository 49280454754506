import styled from 'styled-components';

export const TabContainer = styled.div`
  background-color: #f7f7f7;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TabStyle = styled.div`
  cursor: pointer;
  margin: 10px 10px 0;
  width: 180px;
  text-align: center;
  padding-bottom: 6px;
  color: ${(props) => (props.active ? '#000000' : '#707070')};
  border-bottom: 3px solid ${(props) => (props.active ? '#ffba00' : '#f7f7f7')};
  .count {
    color: black;
    font-weight: 700;
    margin-left: 0.5rem;
  }
`;
