const StyledLeaderboard = {
  1: {
    headerColor: '#2fc7ff',
    btnColor: '#2fc7ff',
    textColor: '#ffffff',
    borderColor: '#009dd9',
    hoverColor: '#46d4ff',
    headingColor: '#5ec0eb',
    oddBgColor: '#ffffff',
    oddBdColor: '#d4ecf5',
    evenBgColor: '#def6ff',
    evenBdColor: '#b9e6f7',
    nearbyBgColor: '#f0fcff',
    nearbyBtnColor: '#dbf7ff',
    nearbyBdColor: '#9adcf5',
    nearbyTxtColor: '#0081b1',
  },
  2: {
    headerColor: '#21DB9D',
    btnColor: '#0DD186',
    textColor: '#ffffff',
    borderColor: '#00c976',
    hoverColor: '#0DE693',
    headingColor: '#00aa86',
    oddBgColor: '#ffffff',
    oddBdColor: '#9ee5d0',
    evenBgColor: '#dcfff6',
    evenBdColor: '#9ee5d0',
    nearbyBgColor: '#F0FFFA',
    nearbyBtnColor: '#D3FDF0',
    nearbyBdColor: '#ADE3D1',
    nearbyTxtColor: '#00aa86',
  },
};

export default StyledLeaderboard;
