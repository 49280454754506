import styled from 'styled-components';

export const Main = styled.div`
  text-align: center;
  padding: 1rem 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .add-bff {
    margin-top: 20px;
    margin-bottom: 1rem;
    h4 {
      font-weight: 600;
    }
  }
`;

export const AddFriendBtn = styled.button`
  background: white;
  border: none;
  border-radius: 12px;
  color: #0db9e2;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem 4rem 0.5rem 2rem;
  margin-bottom: 1rem;
  box-shadow: 0 3px 3px rgba(175, 175, 175, 0.16);
  img {
    margin-right: 1rem;
  }
  :hover {
    color: #0a94b5;
    img {
      filter: brightness(0.8);
    }
  }
`;

export const RemoveFriendBtn = styled.button`
  border: none;
  border-radius: 1rem;
  background: #effcff;
  color: #47bdd8;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  :hover {
    color: #3997ad;
  }
`;

export const CPResult = styled.div`
  display: flex;
  align-self: center;
  div {
    font-size: 10px;
    margin-left: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 0;
  }
  img {
    width: 38px;
  }
`;
