import styled from 'styled-components';
import PremBg from 'assets/img/expired/expired-bg-premium.svg';
import StandBg from 'assets/img/expired/expired-bg-standard.svg';

export const Page = styled.div`
  overflow: hidden;
  background: ${(props) =>
    props.isPremium ? `url(${PremBg})` : `url(${StandBg})`};
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
  height: 80%;
  width: 100%;
  align-items: center;
`;

export const Cards = styled.div`
  background-color: #ffffff;
  min-height: 350px;
  width: 50%;
  border-radius: 25px;
  padding: 30px 50px;
`;
