import moment from 'moment-timezone';

export const getCurrentDateTimeInTimezone = (timezone) => {
  const currentDateTime = moment();
  return currentDateTime.clone().tz(timezone);
};

export const isSundayBetween6amTo10pm = (timezone) => {
  const currentDateTime = getCurrentDateTimeInTimezone(timezone);
  const smcStartTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 6,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const smcEndTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 22,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return (
    currentDateTime.isoWeekday() === 7 &&
    currentDateTime.isBetween(smcStartTime, smcEndTime)
  );
};

/**
 *  Only open on 6am to 10pm, Monday to Saturday
 * @param {string} timezone
 * @returns {boolean}
 */
export const isSuperHeroChallengeOpen = (timezone) => {
  const currentDateTime = getCurrentDateTimeInTimezone(timezone);
  const smcStartTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 6,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const smcEndTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 22,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  if (currentDateTime.isoWeekday() === 7) return false;
  return currentDateTime.isBetween(smcStartTime, smcEndTime);
};
