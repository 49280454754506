import React from 'react';
import { useTranslation } from 'react-i18next';

import ProgressBarWithMarkers from 'components/Mission/ProgressBarWithMarkers';

import { ProgressContainer } from 'components/Mission/SkillsProgress.styles';
import ContainerScience from './SkillsProgressScience.styles';

const SkillsProgressScience = ({ goldMedal, totalSkills, bgColour }) => {
  const { t, i18n } = useTranslation(['selfPractice']);

  return (
    <ContainerScience data-cy="top-progress-bar">
      <p>
        {t('selfPractice:header.youHaveGot', 'You have got ')}
        <span className="highlighted">
          {t(
            'selfPractice:header.goldMedal',
            { medal: goldMedal },
            `${goldMedal} Gold Medal`
          )}
          {goldMedal > 1 && i18n.language === 'en' && 's'}
        </span>
        .{' '}
        <span className="carry-on">
          {t('selfPractice:header.carryOn', 'Carry on!')}
        </span>
      </p>
      <ProgressContainer bgColour={bgColour}>
        <ProgressBarWithMarkers
          numOfAStars={goldMedal}
          totalSkills={totalSkills * 3}
          type="gold-medal"
        />
        <span className="skills-no">
          {t(
            'selfPractice:header.totalSkill',
            { number: totalSkills },
            `(Total ${totalSkills} skills)`
          )}
        </span>
      </ProgressContainer>
    </ContainerScience>
  );
};

export default SkillsProgressScience;
