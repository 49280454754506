import React from 'react';
import PictureType from 'components/Assignment/StartPage/PictureType';
import SMCDetails from 'components/Events/ResultPage/SMCDetails';
import { Container } from './SMCResult.styles';

const Expired = () => {
  return (
    <Container>
      <PictureType type="sundayMC" />
      <SMCDetails status="expired" />
    </Container>
  );
};

export default Expired;
