import React, { useRef, useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { changeObjectExtra, setEditing } from 'store/assignment/barModelSlice';
import Positioning from './Positioning';
import { Input, Container, Header } from './Text.style';

function TextComponent({ size, x, y, zIndex, id, text, height }) {
  const { activeId } = useSelector((state) => state.barModel);
  const dispatch = useDispatch();
  const onChangeText = useCallback(
    (e) => {
      dispatch(changeObjectExtra({ id, extra: { text: e.target.value } }));
    },
    [dispatch, id]
  );
  const onFocus = useCallback(() => {
    dispatch(setEditing(id));
    // eslint-disable-next-line
  }, [dispatch]);
  const componentRef = useRef(null);
  const selected = activeId === id;
  return (
    <Positioning
      componentRef={componentRef}
      x={x}
      y={y}
      id={id}
      size={size}
      zIndex={zIndex}
      height={height}
      vertical={false}
      bottomRightOnly
      dragHandleClass="textHeader"
    >
      <Container
        className={clsx({ barModelComponent: true, deselect: !selected })}
      >
        <Header ref={componentRef} className="textHeader" />
        <Input
          className="barModelComponent"
          onFocus={onFocus}
          value={text}
          onChange={onChangeText}
        />
      </Container>
    </Positioning>
  );
}

export default React.memo(TextComponent);
