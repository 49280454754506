import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import {
  getSuperHeroChallengeStatus,
  getSuperHeroChallengeDataWithoutImage,
} from 'store/dailyChallenge/dailyChallengeSlice';
import Header from 'components/QnView/QnViewHeader';
import BackButton from 'components/Shared/BackButton';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import PopupModal from 'components/Shared/PopupModal';
import SMCModal from 'components/DailyChallenge/SMCModal';
import {
  isSundayBetween6amTo10pm,
  isSuperHeroChallengeOpen,
} from 'helpers/timezone';
import {
  superHeroChallengeType,
  superHeroChallengeStatusIDs,
  SuperHeroChallengeList,
} from 'constants/superHero';
import HeaderIcon from 'assets/img/superhero/superhero-challenge-header-icon.png';
import HeaderMask from 'assets/img/superhero/superhero-challenge-header-mask.png';
import SuperHeroCard from './SuperHeroCard';
import { Container, Main, Heading } from './SuperHero.style';

/**
 *
 * @returns - will return challenge status
 *      0 - close
 *      1 - locked
 *      2 - completed
 *      3 - incomplete
 */
const parseSuperHeroChallengeStatus = (
  challengeType,
  superHeroChallengeStatusData,
  timezone
) => {
  if (!isSuperHeroChallengeOpen(timezone)) return 'close';

  if (challengeType === 'super-vision-challenge') {
    return superHeroChallengeStatusIDs[
      superHeroChallengeStatusData.Supervision.Status
    ];
  }
  if (challengeType === 'super-speed-challenge') {
    return superHeroChallengeStatusIDs[
      superHeroChallengeStatusData.Superspeed.Status
    ];
  }
  return null;
};

const CustomSMCNodal = ({ timezone }) => {
  const { studentDetails, subscriptionData } = useSelector(
    (state) => state.studentDetails
  );
  const isLocalizedUser =
    (studentDetails && studentDetails.Roles.includes(107)) ?? false;
  const isIDUser = subscriptionData && subscriptionData.CountryId === 100;
  const isHKUser = subscriptionData && subscriptionData.CountryId === 96;
  const hasSMC = !isIDUser && !isHKUser;
  const isSundayNow = isSundayBetween6amTo10pm(timezone);
  const [showSMCModal, setShowSMCModal] = useState(
    isSundayNow && (!isLocalizedUser || (isLocalizedUser && hasSMC))
  );

  return (
    <PopupModal show={showSMCModal} backdrop="static">
      <SMCModal setShowSMCModal={setShowSMCModal} />
    </PopupModal>
  );
};

/**
 *
 * @param {useDispatch} dispatch
 * @param {*} i18n
 *
 * @returns SuperHero Card List
 */
const SuperHeroList = ({ dispatch, t, timezone }) => {
  const { superHeroChallengeStatus, superHeroChallengeIsError } = useSelector(
    (state) => state.dailyChallenge
  );

  const reloadSuperHeroChallengeData = () => {
    dispatch(getSuperHeroChallengeStatus());
  };

  const onReloadScore = () => dispatch(getSuperHeroChallengeDataWithoutImage());

  if (!superHeroChallengeIsError && !isNil(superHeroChallengeStatus)) {
    return SuperHeroChallengeList.challenge
      .filter((challenge) => superHeroChallengeType.includes(challenge.type))
      .map((item) => (
        <SuperHeroCard
          key={item.id}
          challengeStatus={parseSuperHeroChallengeStatus(
            item.type,
            superHeroChallengeStatus,
            timezone
          )}
          challengeName={t(
            `challengeList:${item.nameTranslation}`,
            `${item.name}`
          )}
          challengeDesc={item.desc}
          challengeType={item.type}
          challengeOpenImg={item.imgOpen}
          challengeCloseImg={item.imgClose}
          challengeLink={item.link}
          onReloadScore={onReloadScore}
        />
      ));
  }
  if (
    !isNil(superHeroChallengeIsError) &&
    superHeroChallengeIsError !== 'Unable to get super vision image'
  ) {
    return (
      <ErrorModal
        reloadAction={reloadSuperHeroChallengeData}
        errorMessage={superHeroChallengeIsError}
      />
    );
  }

  return null;
};

/**
 *  SuperHero Challenge List page component to support science product using v2 endpoints
 *
 * @returns
 */
const NewSuperHero = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['challengeList', 'common', 'topicSelection']);
  const { timezone } = useSelector((state) => state.login);

  const {
    superHeroChallengeIsLoading,
    superHeroChallengeWithoutImageIsLoading,
  } = useSelector((state) => state.dailyChallenge);

  const isPageLoading =
    superHeroChallengeIsLoading || superHeroChallengeWithoutImageIsLoading;

  useEffect(() => {
    dispatch(getSuperHeroChallengeDataWithoutImage());
    dispatch(getSuperHeroChallengeStatus());
  }, [dispatch]);

  return (
    <Container>
      <Header
        page
        bgColor="linear-gradient(to left, #18BCFB, #2EE4FD)"
        headerBg={HeaderMask}
      >
        <BackButton
          backText={t('topicSelection:backBtn', 'Back')}
          btnBgColor="#10CAFF"
          padding="5px 20px"
          onClick={() => history.push('/dashboard')}
          styles={{ position: 'absolute', left: '3%' }}
        />

        <Heading>
          {t('challengeList:SuperHeroChallenge', 'Super Hero Challenge')}
        </Heading>
        <img
          className="super-hero-challenge-icon"
          src={HeaderIcon}
          alt="super-hero-challenge-icon"
        />
      </Header>
      <Main>
        {isPageLoading && <Spinner />}
        {!isPageLoading && <CustomSMCNodal timezone={timezone} />}
        {!isPageLoading && (
          <SuperHeroList dispatch={dispatch} t={t} timezone={timezone} />
        )}
      </Main>
    </Container>
  );
};

export default NewSuperHero;
