import React, { useState, useEffect } from 'react';
import { getKoKoHistory } from 'store/kokoHistory/kokoHistorySlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import { StyledTitle } from './index.styles';
import { KoKoHistoryHeader, KoKoHistoryResult } from 'components/KoKoHistory';
const periodOptions = [1, 2, 3];
const KoKoHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['kokoHistory']);
  const [selectPeriod, setSelectPeriod] = useState(1);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    searchAction();
    //eslint-disable-next-line
  }, [selectPeriod]);
  const searchAction = () => {
    setLoading(true);
    dispatch(
      getKoKoHistory({
        period: selectPeriod,
      })
    ).then(() => {
      setLoading(false);
    });
  };
  return (
    <>
      <Header />
      <StyledTitle>{t('title', 'My KoKo Credits')}</StyledTitle>
      <KoKoHistoryHeader
        selectPeriod={selectPeriod}
        setSelectPeriod={setSelectPeriod}
        options={periodOptions}
        searchAction={searchAction}
      />
      <KoKoHistoryResult
        searchAction={searchAction}
        selectPeriod={selectPeriod}
        loading={loading}
      />
    </>
  );
};

export default KoKoHistory;
