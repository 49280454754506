import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  unlockLesson,
  resetErr,
  resetUnlock,
} from 'store/kooClass/kooClassSlice';
import PopupModal from 'components/Shared/PopupModal';
import ConfirmModal from 'components/KooClass/ConfirmModal';
import Scroll from 'assets/img/kooclass/lessons/scroll.svg';
import Complete from 'assets/img/kooclass/lessons/complete.svg';
import One from 'assets/img/kooclass/one.png';
import { isNil } from 'ramda';
import { Card } from './LessonCard.styles';
import Locked from './Locked';

const kokoToUnlock = 20;
const LessonCard = ({
  data,
  status,
  i,
  openLesson,
  isActive,
  selectedLessonId,
}) => {
  const { t } = useTranslation(['kooClassLessons']);
  const { unlockLoading, unlock } = useSelector((state) => state.kooClass);
  const dispatch = useDispatch();
  const { topicId } = useParams();
  const progress = Math.floor((data.currentUserPoint * 100) / data.maxpoints);

  const [showModal, setShowModal] = useState(false);
  const showSelectedLessonModal = useRef(false);

  useEffect(() => {
    if (unlock) {
      dispatch(resetUnlock());
    }
  }, [dispatch, unlock]);

  const unLockCard = () => {
    dispatch(unlockLesson(data.id));
    setShowModal(false);
  };
  const openModal = () => {
    dispatch(resetErr());
    setShowModal(true);
  };
  const hideModal = () => {
    setShowModal(false);
    showSelectedLessonModal.current = true;
  };

  useEffect(() => {
    if (
      !showSelectedLessonModal.current &&
      isActive &&
      data.id === selectedLessonId
    ) {
      setShowModal(true);
    }
  }, [data.id, isActive, selectedLessonId]);
  return (
    <>
      <PopupModal show={showModal} backdrop="static" centered>
        <ConfirmModal
          text={`${t(
            'kooClassLessons:card.unlock',
            'Do you want to start this lesson?'
          )}`}
          closeHandler={hideModal}
          yesHandler={unLockCard}
          noHandler={hideModal}
          loading={unlockLoading}
        />
      </PopupModal>
      <Card
        data-cy={`lesson-card-${i}`}
        className="main-container"
        progress={progress}
        onClick={() => {
          if (status !== 'Locked') openLesson(topicId, data.id, i);
        }}
      >
        <div className="lesson-container">
          {status === 'Locked' && (
            <Locked
              count={kokoToUnlock}
              openModal={openModal}
              isActive={isActive}
            />
          )}
          <div className="lesson-img">
            <img
              className="img-cover"
              src={isNil(data.thumbnail) ? One : data.thumbnail}
              alt="lesson-img"
            />
          </div>
          <div className="center">
            <div className="left">
              <div className="lesson-title">
                {t('kooClassLessons:card.lesson', 'Lesson')} {data.displayOrder}
              </div>
              <div className="lesson-name">{data.name}</div>
            </div>
            <div className="right">
              {t('kooClassLessons:card.age', 'Age')} {data.minAge}-{data.maxAge}
            </div>
          </div>
          <div className="footer">
            <div className="prog-status">
              <span>{progress}%</span>
              <div className="outer-progress">
                <div className="inner-progress" />
              </div>
              <span className="complete-progress">&#10003;</span>
            </div>
            <div className="details">
              <span className="xp">{data.currentUserPoint} </span>
              <span>
                {t('kooClassLessons:header.outOf', 'out of')} {data.maxpoints}
              </span>
              <img src={Scroll} alt="scroll" />
              <span>{t('kooClassLessons:card.achieved', 'achieved')}</span>
            </div>
          </div>
        </div>
        {status === 'Completed' && (
          <img src={Complete} alt="complete" className="complete" />
        )}
      </Card>
    </>
  );
};

export default LessonCard;
