import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Button } from './ActionsRestrictModal.styles';

const ActionsRestrictModal = ({ onHide }) => {
  const { t } = useTranslation(['dailyChallengeQnView']);
  return (
    <Container>
      <h4>
        {t(
          'dailyChallengeQnView:actionRestricModal.qnSetting',
          'Question Settings'
        )}
      </h4>
      <div style={{ textAlign: 'left' }}>
        {t(
          'dailyChallengeQnView:actionRestricModal.text',
          'You have already changed the current question 3 times and cannot change it anymore. You are allowed to change each question 3 times only.'
        )}
      </div>
      <Button onClick={onHide}>
        {t('dailyChallengeQnView:changeQnModal.ok', 'OK')}
      </Button>
    </Container>
  );
};

export default ActionsRestrictModal;
