import React from 'react';
import { useSelector } from 'react-redux';

import Header from 'components/Assignment/AssignmentView/Header';
import Spinner from 'components/Shared/Spinner';
import SubmittedResult from 'components/KooClass/SubmittedResult';

import Mascot from 'assets/img/assignment/assignment-view/mascot-submitted.svg';

import { Container, Main, ErrorMsg } from './KooClassSubmitted.styles';

const KooClassSubmitted = () => {
  const { isLoading, kooClassQnTitle, kooClassSubmissionError } = useSelector(
    (state) => state.kooClass
  );
  return (
    <Container>
      <Header
        page="submitted"
        type="KooClass"
        isLoading={isLoading}
        assignmentTitle={kooClassQnTitle}
      />
      <Main>
        {isLoading && <Spinner color="#26B1EF" />}
        {!isLoading && kooClassSubmissionError !== null && (
          <ErrorMsg>
            <h4>Error</h4>
            <p>{kooClassSubmissionError}</p>
          </ErrorMsg>
        )}
        {!isLoading && kooClassSubmissionError === null && (
          <>
            <div className="mascot">
              <img src={Mascot} alt="" />
            </div>
            <SubmittedResult />
          </>
        )}
      </Main>
    </Container>
  );
};

export default KooClassSubmitted;
