const currencyLocaleFormatter = (currency, amount) => {
  switch (currency) {
    case 'sgd':
      return {
        formattedString: new Intl.NumberFormat('en-SG', {
          currency: 'SGD',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount),
      };
    case 'hkd':
      return {
        formattedString: new Intl.NumberFormat('en-SG', {
          currency: 'SGD',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount),
      };
    case 'php':
      return {
        formattedString: new Intl.NumberFormat('en-PH', {
          currency: 'PHP',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount),
      };
    case 'idr': {
      const isAmtMoreThanMillion = amount >= 1000000;
      const adjustedAmt = isAmtMoreThanMillion ? amount / 1000000 : amount;
      const formatted = isAmtMoreThanMillion
        ? new Intl.NumberFormat('id-ID', {
            currency: 'IDR',
          }).format(adjustedAmt)
        : new Intl.NumberFormat('id-ID', {
            currency: 'IDR',
            maximumFractionDigits: 0,
          }).format(adjustedAmt);
      return {
        formattedString: isAmtMoreThanMillion ? `${formatted}jt` : formatted,
      };
    }
    case 'myr': {
      return {
        formattedString: new Intl.NumberFormat('ms-MY', {
          currency: 'MYR',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount),
      };
    }

    case 'aud':
      return {
        formattedString: new Intl.NumberFormat('en-SG', {
          currency: 'SGD',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount),
      };

    default:
      return {
        formattedString: new Intl.NumberFormat('en-SG', {
          currency: 'SGD',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount),
      };
  }
};

export default currencyLocaleFormatter;
