import styled from 'styled-components';
import CorrectIcon from 'assets/img/icon-correct.svg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background: ${(props) =>
    props.status === 'correct'
      ? '#F2FFF4'
      : props.status === 'skipped'
      ? '#D8D8D8'
      : '#F7F7F7'};
  border-bottom: 1px solid #cecece;
  min-height: 52px;
  cursor: pointer;
  padding: 5px 0;
  .number {
    margin-right: ${(props) => !props.collapse && '0.5rem'};

    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      margin-right: 1rem;
    }
  }
`;

export const BorderVertical = styled.div`
  border-right: 6px solid #c7c7c7;
  height: ${(props) => (props.top ? '10px' : '100%')};
  position: relative;
  right: 9px;
  z-index: 1;
`;

export const Number = styled.div`
  border-radius: 50%;
  background: ${(props) =>
    props.status === 'correct'
      ? `url(${CorrectIcon})`
      : props.status === 'skipped'
      ? `#C1C1C1`
      : '#1DC9FD'};
  background-repeat: no-repeat;
  background-size: 100%;
  color: #ffffff;
  text-align: center;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  box-shadow: ${(props) => (props.active ? ' 0 0 0pt 3pt #fff3b4' : 'none')};
  z-index: 2;
  position: relative;
`;

export const QnStatus = styled.div`
  line-height: 1.2;
  align-self: center;
  width: 55%;
  padding: 5px 0 0;
  display: ${(props) => (props.collapse ? 'none' : 'block')};
  .related-text {
    font-size: 13px;
    color: ${(props) =>
      props.status === 'correct'
        ? `#4A6E3F`
        : props.status === 'skipped'
        ? `#909090`
        : '#95A5A5'};
  }

  @media (min-width: ${({ theme }) =>
      `${theme.rwd.tablet}px`}) and (max-width: 1200px) {
    display: none;
  }
`;

export const QnNumber = styled.p`
  margin-bottom: 0rem;
`;

export const StatusInfo = styled.p`
  margin-bottom: 0rem;
  font-size: 0.8rem;
  color: ${(props) =>
    props.status === 'correct'
      ? '#060606'
      : props.status === 'incomplete'
      ? '#9C9C9C'
      : '#575757'};
  img {
    width: ${(props) => (props.status === 'correct' ? '24px' : '18px')};
    margin-right: ${(props) => (props.status === 'correct' ? '0' : '3px')};
  }
`;
