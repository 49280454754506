import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  border-radius: 1rem;
`;

export const CalendarHeader = styled.div`
  background: #31ccdf;
  padding: 0.5rem 0 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  border-radius: 1rem 1rem 0 0;
  .day-header {
    margin-right: 2rem;
    color: #197c88;
  }
`;

export const CalendarDays = styled.div`
  background: #ade8ef;
`;

export const CalendarWeek = styled.div`
  display: flex;
`;
