import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import StoryMathLogo from 'assets/img/storymath/koobits-storymath.png';
import StoryScienceLogo from 'assets/img/storymath/koobits-storyscience.png';
import ArrowLeft from 'assets/img/storymath/left-mouse.png';
import ArrowLeftOver from 'assets/img/storymath/left-mouseover.png';
import ArrowRight from 'assets/img/storymath/right-mouse.png';
import ArrowRightOver from 'assets/img/storymath/right-mouseover.png';
import { sendEventTracking } from 'helpers/UserEventTracking';
import { getStoryChapterTiles } from 'store/storyMath/storyMathSlice';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { isNil } from 'ramda';
import {
  Page,
  Header,
  MenuWrapper,
  HeaderMenu,
  Menu,
  Content,
  Main,
  Sidebar,
  CarouselWrapper,
  Carousel,
  Image,
  ImageWrapper,
  ArrowWrapper,
  Arrow,
  SidebarContent,
  SidebarItem,
  SidebarImage,
  SidebarText,
  SpinnerContainer,
} from './StoryViewer.styles';

const RenderArrow = ({ disabled, type, onClickArrrow }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <Arrow
      disabled={disabled}
      role="button"
      tabIndex={0}
      onClick={onClickArrrow}
      onMouseOver={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeftOver : ArrowRightOver;
      }}
      onFocus={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeftOver : ArrowRightOver;
      }}
      onMouseOut={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeft : ArrowRight;
      }}
      onBlur={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeft : ArrowRight;
      }}
    >
      <img alt="arrow" src={type === 'left' ? ArrowLeft : ArrowRight} />
    </Arrow>
  );
};

const StoryViewer = ({ match }) => {
  const { t } = useTranslation(['storyMath']);
  const { id, subjectId } = match.params;
  const history = useHistory();
  const dispatch = useDispatch();
  const { stories, isLoading } = useSelector((state) => state.storyMath);
  const [index, setIndex] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [sidebarHeight, setSidebarHeight] = useState(0);
  const [sidebarThumbHeight, setSidebarThumbHeight] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const { userID, sessionID } = useSelector((state) => state.login);
  const sidebarRef = useRef();

  const getHeight = useCallback(() => {
    const headerHeightNew = document.getElementById('header').clientHeight;
    const sidebarHeightNew = document.getElementById('sidebar').clientHeight;
    const sidebarThumbHeightNew = document.getElementById('sidebar-content')
      .clientHeight;
    setHeaderHeight(headerHeightNew);
    setSidebarHeight(sidebarHeightNew);
    setSidebarThumbHeight(sidebarThumbHeightNew);
  }, []);

  useEffect(() => {
    if (!isNil(stories)) {
      getHeight();
    }
  }, [getHeight, stories]);

  useEffect(() => {
    dispatch(getStoryChapterTiles(id));
  }, [dispatch, id]);

  useEffect(() => {
    const currentMousePosition = mousePosition.y - headerHeight;
    const percentageCurentTop = currentMousePosition / sidebarHeight;
    const sidebarThumbTop = percentageCurentTop * sidebarThumbHeight;
    if (!isNil(stories) && !isLoading) {
      sidebarRef.current.scrollTo({
        top: sidebarThumbTop - currentMousePosition,
        behavior: 'auto',
      });
    }
  }, [
    headerHeight,
    isLoading,
    mousePosition,
    sidebarHeight,
    sidebarThumbHeight,
    stories,
  ]);
  useEffect(() => {
    if (sessionID && userID && id) {
      sendEventTracking('story', 'story_open', {
        sub: subjectId,
        ui: userID,
        si: sessionID,
        sti: id,
      });
    }
  }, [subjectId, sessionID, userID, id]);
  const handleMouseMove = useCallback((position) => {
    setMousePosition({ x: position.clientX, y: position.clientY });
  }, []);

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Page>
      {isLoading && (
        <SpinnerContainer>
          <Spinner animation="border" />
        </SpinnerContainer>
      )}
      <Header id="header">
        <MenuWrapper>
          <HeaderMenu>
            <img
              src={Number(subjectId) === 2 ? StoryScienceLogo : StoryMathLogo}
              alt="storylogo"
              onClick={onBack}
              aria-hidden
            />
            <Menu onClick={onBack} subjectId={Number(subjectId)}>
              {Number(subjectId) === 2
                ? t('storyMath:backToStoryScience', 'Back to StoryScience')
                : t('storyMath:backToStoryMath', 'Back to StoryMath')}
            </Menu>
          </HeaderMenu>
        </MenuWrapper>
      </Header>
      {!isLoading && (
        <Content>
          <Main>
            <CarouselWrapper>
              <ArrowWrapper type="left">
                {index !== 0 && (
                  <RenderArrow
                    disabled={disabled}
                    type="left"
                    onClickArrrow={() => setIndex((prevState) => prevState - 1)}
                  />
                )}
              </ArrowWrapper>
              <Carousel
                onTransitionStart={() => setDisabled(true)}
                onTransitionEnd={() => setDisabled(false)}
                selected={index}
                bullets={false}
                infinite={false}
                buttons={false}
                organicArrows={false}
              >
                {stories.map((story) => {
                  return (
                    <ImageWrapper key={`data${story.ID}`}>
                      <Image src={`data:image/png;base64,${story.Image}`} />
                    </ImageWrapper>
                  );
                })}
              </Carousel>
              <ArrowWrapper type="right">
                {index !== stories.length - 1 && (
                  <RenderArrow
                    disabled={disabled}
                    type="right"
                    onClickArrrow={() => setIndex((prevState) => prevState + 1)}
                  />
                )}
              </ArrowWrapper>
            </CarouselWrapper>
          </Main>
          <Sidebar id="sidebar" onMouseMove={handleMouseMove} ref={sidebarRef}>
            <SidebarContent id="sidebar-content">
              {stories.map((story, i) => {
                return (
                  <SidebarItem
                    key={`data${story.ID || story.Id}`}
                    onClick={() => setIndex(i)}
                  >
                    <SidebarImage
                      src={`data:image/png;base64,${story.Image}`}
                    />
                    <SidebarText>
                      {t('storyMath:page', 'Page')} {story.DisplayOrder}
                    </SidebarText>
                  </SidebarItem>
                );
              })}
            </SidebarContent>
          </Sidebar>
        </Content>
      )}
    </Page>
  );
};

export default React.memo(StoryViewer);
