import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .instructions {
    font-weight: 600;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 1rem;
  border: ${(props) =>
    props.isSelected === true ? 'solid 2px #FF6701' : 'solid 1px #E2E2E2'};
  cursor: pointer;
  width: 33%;
  margin-bottom: 2rem;
  p {
    margin: 0;
  }
`;
