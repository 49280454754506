import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import Header from 'components/Header';
import BookClubHeader from 'components/BookClub/BookClubHeader';
import GroupByControl from 'components/BookClub/GroupByControl';
import ControlPanelInfo from 'components/BookClub/ControlPanelInfo';
import LegendImg from 'assets/img/bookclub/bookclub_legends.png';
import { find, groupBy, keys, propEq } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBookClubInfo,
  getTotalKokoCredit,
} from 'store/storyMath/bookClubSlice';
import {
  Page,
  Content,
  Section,
  ControlPanelWrap,
  MainPanelWrap,
  BookClubIntruction,
  BookclubD3,
  BookClubLegend,
} from './BookClub.style';

const maxRadius = 35; // max radius of nodes

const getColor = (userLevel) => {
  const colors = [
    '#EBEBEB',
    '#FFF701',
    '#FEBA00',
    '#FF9200',
    '#A56E47',
    '#5CD2EC',
    '#2F9CCF',
  ];

  if (typeof colors[userLevel] !== 'undefined') {
    return colors[userLevel];
  }
  return '#EBEBEB';
};

const clickCircle = (circle, nodes, getInfo) => {
  const info = find(propEq('code', parseInt(circle.target.id, 10)))(nodes);
  getInfo(info.detail, info.color);
  d3.selectAll('circle').style('stroke', '');
  d3.select(circle.target)
    .style('stroke', '#FFE030')
    .style('stroke-width', '3');
};

const unsortedCircles = (data, ref, width, height, getInfo) => {
  // Resolve collisions between nodes.
  const circleSpeed = 0.5;
  const svg = d3
    .select(ref.current)
    .append('svg')
    .attr('id', 'bookclubsvg')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('preserveAspectRatio', 'xMidYMid meet');

  const nodes = data.map((d) => {
    const userLevel = d.LevelId;
    const r = d.KidsTotalCPPoints / 2 + 17;
    return {
      code: d.UserId,
      radius: r,
      color: getColor(userLevel),
      x: Math.round(Math.random() * (width - r * 2) + r),
      y: Math.round(Math.random() * (height - r * 2) + r),
      speedX: (Math.random() - 0.5) * circleSpeed,
      speedY: (Math.random() - 0.5) * circleSpeed,
      detail: d,
    };
  });

  const node = svg.selectAll('.node').data(nodes).enter().append('g');

  const simulation = d3
    .forceSimulation(nodes)
    .force(
      'collide',
      d3.forceCollide().radius((d) => {
        return d.radius;
      })
    )
    .alphaTarget(3);

  const dragstarted = (event, d) => {
    d.fx = d.x;
    d.fy = d.y;
  };

  const dragged = (event, d) => {
    d.fx = event.x;
    d.fy = event.y;
  };

  const dragended = (event, d) => {
    d.fx = null;
    d.fy = null;
  };

  const circle = node
    .append('circle')
    .attr('cx', (d) => {
      return d.x;
    })
    .attr('cy', (d) => {
      return d.y;
    })
    .attr('r', (d) => {
      return d.radius;
    }) // set radius of circle
    .attr('id', (d) => {
      return d.code;
    }) // set id of circle
    .style('fill', (d) => {
      return d.color;
    }) // set color of circle
    .on('mousedown', (d) => {
      clickCircle(d, nodes, getInfo);
    })
    .call(
      d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    );

  const text = node
    .append('text')
    .style('fill', '#FFFFFF')
    .style('font-family', 'Arial')
    .style('font-size', '125%')
    .style('text-anchor', 'middle')
    .style('dominant-baseline', 'middle')
    .style('pointer-events', 'none')
    .attr('dx', (d) => {
      return d.x;
    })
    .attr('dy', (d) => {
      return d.y;
    })
    .text((d) => {
      return d.detail.FullName[0];
    });

  circle
    .transition()
    .duration(750)
    .attrTween('r', (d) => {
      const i = d3.interpolate(0, d.radius);
      return (t) => {
        const newR = i(t);
        d.radius = newR;
        return newR;
      };
    });

  const gravity = (alpha) => {
    // circles in motion
    for (let i = 0, n = nodes.length; i < n; ++i) {
      const d = nodes[i];
      if (d.x - maxRadius <= 0) d.speedX = Math.abs(d.speedX);
      if (d.x + maxRadius >= width) d.speedX = -1 * Math.abs(d.speedX);
      if (d.y - maxRadius <= 0) d.speedY = -1 * Math.abs(d.speedY);
      if (d.y + maxRadius >= height) d.speedY = Math.abs(d.speedY);

      d.x += d.speedX * alpha;
      d.y += -1 * d.speedY * alpha;
    }
  };

  const tick = () => {
    circle
      .attr('cx', (d) => {
        const x = Math.max(maxRadius, Math.min(width - maxRadius, d.x));
        d.x = x;
        return x;
      })
      .attr('cy', (d) => {
        const y = Math.max(maxRadius, Math.min(height - maxRadius, d.y));
        d.y = y;
        return y;
      });

    text
      .attr('dx', (d) => {
        const x = Math.max(maxRadius, Math.min(width - maxRadius, d.x));
        d.x = x;
        return x;
      })
      .attr('dy', (d) => {
        const y = Math.max(maxRadius, Math.min(height - maxRadius, d.y));
        d.y = y;
        return y;
      });
  };

  simulation.force('g', gravity).on('tick', tick);
};

const sortBySchool = (data, ref, width, height, getInfo) => {
  const svg = d3
    .select(ref.current)
    .append('svg')
    .attr('id', 'bookclubsvg')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('preserveAspectRatio', 'xMidYMid meet');

  const BySchool = groupBy((student) => {
    const { SchoolId } = student;
    return SchoolId;
  });
  const dataSchool = keys(BySchool(data));

  const nodes = data.map((d) => {
    const userLevel = d.LevelId;
    const schoolposition = dataSchool.indexOf(d.SchoolId.toString());
    const r = d.KidsTotalCPPoints / 2 + 17;

    let x = 0;
    let y = 0;
    switch (schoolposition) {
      case 0:
        x = width * 0.5;
        y = height * 0.5;
        break; // Middle
      case 1:
        x = width * 0.22;
        y = height * 0.29;
        break; // Top Left
      case 5:
        x = width * 0.43;
        y = height * 0.14;
        break; // Top Middle
      case 3:
        x = width * 0.71;
        y = height * 0.2;
        break; // Top Right
      case 4:
        x = width * 0.22;
        y = height * 0.71;
        break; // Bottom Left
      case 6:
        x = width * 0.43;
        y = height * 0.86;
        break; // Bottom Middle
      case 2:
        x = width * 0.71;
        y = height * 0.8;
        break; // Bottom Right
      case 7:
        x = width * 0.8;
        y = height * 0.5;
        break; // Middle Right
      default:
        x = width * 0.17;
        y = height * 0.5;
        break; // Middle Left
    }
    return {
      code: d.UserId,
      radius: r,
      color: getColor(userLevel),
      cx: x,
      cy: y,
      detail: d,
    };
  });

  const node = svg.selectAll('.node').data(nodes).enter().append('g');

  const simulation = d3
    .forceSimulation(nodes)
    .force('charge', d3.forceManyBody())
    .force(
      'collide',
      d3.forceCollide().radius((d) => {
        return d.radius;
      })
    );

  const dragstarted = (event, d) => {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  };

  const dragged = (event, d) => {
    d.fx = event.x;
    d.fy = event.y;
  };

  const dragended = (event, d) => {
    d.fx = null;
    d.fy = null;
  };

  const circle = node
    .append('circle')
    .attr('cx', (d) => {
      return d.x;
    })
    .attr('cy', (d) => {
      return d.y;
    })
    .attr('r', (d) => {
      return d.radius;
    }) // set radius of circle
    .attr('id', (d) => {
      return d.code;
    }) // set id of circle
    .style('fill', (d) => {
      return d.color;
    })
    .on('mousedown', (d) => {
      clickCircle(d, nodes, getInfo);
    })
    .call(
      d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    );

  const text = node
    .append('text')
    .style('fill', '#FFFFFF')
    .style('font-family', 'Arial')
    .style('font-size', '125%')
    .style('text-anchor', 'middle')
    .style('dominant-baseline', 'middle') // center text
    .style('pointer-events', 'none') // unselectable text
    .attr('dx', (d) => {
      return d.x;
    })
    .attr('dy', (d) => {
      return d.y;
    })
    .text((d) => {
      return d.detail.FullName[0];
    }); // add initials to circle

  circle
    .transition()
    .duration(750)
    .attrTween('r', (d) => {
      const i = d3.interpolate(0, d.radius);
      return (t) => {
        const newR = i(t);
        d.radius = newR;
        return newR;
      };
    });

  const gravity = (alpha) => {
    const alphaG = 0.2 * alpha;
    // circles in motion
    for (let i = 0, n = nodes.length; i < n; ++i) {
      const d = nodes[i];
      d.y += (d.cy - d.y) * alphaG;
      d.x += (d.cx - d.x) * alphaG;
    }
  };

  const tick = () => {
    circle
      .attr('cx', (d) => {
        const x = Math.max(maxRadius, Math.min(width - maxRadius, d.x));
        d.x = x;
        return x;
      })
      .attr('cy', (d) => {
        const y = Math.max(maxRadius, Math.min(height - maxRadius, d.y));
        d.y = y;
        return y;
      });

    text
      .attr('dx', (d) => {
        const x = Math.max(maxRadius, Math.min(width - maxRadius, d.x));
        d.x = x;
        return x;
      })
      .attr('dy', (d) => {
        const y = Math.max(maxRadius, Math.min(height - maxRadius, d.y));
        d.y = y;
        return y;
      });
  };

  simulation.force('g', gravity).on('tick', tick);
};

const sortByLevel = (data, ref, width, height, getInfo) => {
  const clusters = [];
  const svg = d3
    .select(ref.current)
    .append('svg')
    .attr('id', 'bookclubsvg')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('preserveAspectRatio', 'xMidYMid meet');

  const nodes = data.map((d) => {
    const userLevel = d.LevelId;
    const r = d.KidsTotalCPPoints / 2 + 17;

    let x = 0;
    let y = 0;
    switch (userLevel) {
      case 0:
        x = width * 0.5;
        y = height * 0.5;
        break; // Middle
      case 1:
        x = width * 0.22;
        y = height * 0.29;
        break; // Top Left
      case 5:
        x = width * 0.43;
        y = height * 0.14;
        break; // Top Middle
      case 3:
        x = width * 0.71;
        y = height * 0.2;
        break; // Top Right
      case 4:
        x = width * 0.22;
        y = height * 0.71;
        break; // Bottom Left
      case 6:
        x = width * 0.43;
        y = height * 0.86;
        break; // Bottom Middle
      case 2:
        x = width * 0.71;
        y = height * 0.8;
        break; // Bottom Right
      case 7:
        x = width * 0.8;
        y = height * 0.5;
        break; // Middle Right
      default:
        x = width * 0.17;
        y = height * 0.5;
        break; // Middle Left
    }
    const n = {
      code: d.UserId,
      radius: r,
      color: getColor(userLevel),
      x,
      y,
      detail: d,
      userLevel,
    };
    if (!clusters[userLevel] || r > clusters[userLevel].radius)
      clusters[userLevel] = n;
    return n;
  });

  const node = svg.selectAll('.node').data(nodes).enter().append('g');

  const simulation = d3
    .forceSimulation(nodes)
    .force('x', d3.forceX(width / 2).strength(0.1))
    .force('y', d3.forceY(height / 2).strength(0.1))
    .force(
      'collide',
      d3.forceCollide().radius((d) => {
        return d.radius;
      })
    );

  const dragstarted = (event, d) => {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  };

  const dragged = (event, d) => {
    d.fx = event.x;
    d.fy = event.y;
  };

  const dragended = (event, d) => {
    d.fx = null;
    d.fy = null;
  };

  const circle = node
    .append('circle')
    .attr('cx', (d) => {
      return d.x;
    })
    .attr('cy', (d) => {
      return d.y;
    })
    .attr('r', (d) => {
      return d.radius;
    }) // set radius of circle
    .attr('id', (d) => {
      return d.code;
    }) // set id of circle
    .style('fill', (d) => {
      return d.color;
    })
    .on('mousedown', (d) => {
      clickCircle(d, nodes, getInfo);
    })
    .call(
      d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    );

  const text = node
    .append('text')
    .style('fill', '#FFFFFF')
    .style('font-family', 'Arial')
    .style('font-size', '125%')
    .style('text-anchor', 'middle')
    .style('dominant-baseline', 'middle') // center text
    .style('pointer-events', 'none') // unselectable text
    .attr('dx', (d) => {
      return d.x;
    })
    .attr('dy', (d) => {
      return d.y;
    })
    .text((d) => {
      return d.detail.FullName[0];
    }); // add initials to circle

  circle
    .transition()
    .duration(750)
    .attrTween('r', (d) => {
      const i = d3.interpolate(0, d.radius);
      return (t) => {
        const newR = i(t);
        d.radius = newR;
        return newR;
      };
    });

  const cluster = (alpha) => {
    // circles in motion
    for (let i = 0, n = nodes.length; i < n; ++i) {
      const d = nodes[i];
      const cluster = clusters[d.userLevel];
      if (cluster !== d) {
        const x = d.x - cluster.x;
        const y = d.y - cluster.y;
        let l = Math.sqrt(x * x + y * y);
        if (l > 0) {
          const r = d.radius + cluster.radius;
          if (l !== r) {
            l = ((l - r) / l) * alpha;
            d.x -= x * l;
            d.y -= y * l;
            const clusterIndex = nodes.findIndex(
              (n) => n.code === cluster.code
            );
            nodes[clusterIndex].x += x * l;
            nodes[clusterIndex].y += y * l;
          }
        }
      }
    }
  };

  const tick = () => {
    circle
      .attr('cx', (d) => {
        const x = Math.max(maxRadius, Math.min(width - maxRadius, d.x));
        d.x = x;
        return x;
      })
      .attr('cy', (d) => {
        const y = Math.max(maxRadius, Math.min(height - maxRadius, d.y));
        d.y = y;
        return y;
      });

    text
      .attr('dx', (d) => {
        const x = Math.max(maxRadius, Math.min(width - maxRadius, d.x));
        d.x = x;
        return x;
      })
      .attr('dy', (d) => {
        const y = Math.max(maxRadius, Math.min(height - maxRadius, d.y));
        d.y = y;
        return y;
      });
  };

  simulation.force('c', cluster).on('tick', tick);
};

const BookClub = () => {
  const d3Ref = useRef();
  const dispatch = useDispatch();

  const { totalKoko, bookClubInfo } = useSelector((state) => state.bookclub);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  const [controlWidth, setControlWidth] = useState(0);
  const [mainHeight, setMainHeight] = useState(0);
  const [mainWidth, setMainWidth] = useState(0);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [school, setSchool] = useState('');
  const [chapter, setChapter] = useState('');
  const [bbfcode, setBbfcode] = useState('');

  const { t } = useTranslation(['blockClub']);

  const getDisplayInfo = useCallback((detail, colorCircle) => {
    setTitle(detail.FullName[0]);
    setName(detail.FullName);
    setSchool(detail.SchoolName);
    setChapter(detail.CurrentChapter);
    setBbfcode(detail.KidBFFcode);
    setColor(colorCircle);
  }, []);

  const [currentSort, setCurrentSort] = useState('Default');
  const sortCircles = useCallback(() => {
    setTitle('');
    setName('');
    setSchool('');
    setChapter('');
    setBbfcode('');
    setColor('');
    if (bookClubInfo) {
      switch (currentSort) {
        case 'Default':
          unsortedCircles(bookClubInfo, d3Ref, width, height, getDisplayInfo);
          break;
        case 'School':
          sortBySchool(bookClubInfo, d3Ref, width, height, getDisplayInfo);
          break;
        case 'Level':
          sortByLevel(bookClubInfo, d3Ref, width, height, getDisplayInfo);
          break;
        default:
          break;
      }
    }
  }, [bookClubInfo, currentSort, getDisplayInfo, height, width]);
  const handleClick = useCallback((value) => {
    setCurrentSort(value);
  }, []);

  const handleResize = useCallback(() => {
    setWindowWidth(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.getElementsByTagName('body')[0].clientWidth
    );
    setWindowHeight(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight
    );

    setControlWidth(Math.max(windowWidth * 0.25, 314));
    setMainHeight(Math.max(windowHeight - 154, 390));
    setMainWidth(Math.max(windowWidth * 0.75, 300));

    setWidth(Math.max(mainWidth, 300));
    setHeight(Math.max(mainHeight - 90, 300));
  }, [mainHeight, mainWidth, windowHeight, windowWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    d3.select('#bookclubsvg').remove();
    sortCircles();
  }, [sortCircles]);

  useEffect(() => {
    dispatch(getTotalKokoCredit());
    dispatch(getBookClubInfo());
  }, [dispatch]);

  return (
    <Page>
      <Header />
      <Content>
        <BookClubHeader totalKoko={totalKoko} />
        <Section>
          <ControlPanelWrap height={mainHeight} width={controlWidth}>
            <GroupByControl onClick={handleClick} selected={currentSort} />
            <ControlPanelInfo
              name={name}
              title={title}
              school={school}
              chapter={chapter}
              color={color}
              bbfcode={bbfcode}
            />
          </ControlPanelWrap>
          <MainPanelWrap height={mainHeight} width={mainWidth}>
            <BookClubIntruction>
              {t(
                'bookClub:words.instruction',
                'Click a bubble to see more information :)'
              )}
            </BookClubIntruction>
            <BookclubD3 height={height} ref={d3Ref} />
            <BookClubLegend>
              <img src={LegendImg} alt="Book Club Legend" />
            </BookClubLegend>
          </MainPanelWrap>
        </Section>
      </Content>
    </Page>
  );
};

export default React.memo(BookClub);
