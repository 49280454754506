import styled from 'styled-components';

export const Card = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 290px;
  .lesson-container {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 7%);
    position: relative;
    font-size: 12px;
    cursor: 'pointer';
  }
  .lesson-img {
    height: 170px;
    width: 100%;
    border-radius: 20px 20px 0 0;
    .img-cover {
      width: 100%;
      height: 100%;
      border-radius: 20px 20px 0 0;
    }
  }
  .center {
    background-color: #ffffff;
    padding: 10px 0 25px 15px;
    display: flex;
    justify-content: space-between;
  }
  .left {
    .lesson-title {
      color: #6187d6;
      font-size: 1em;
    }
    .lesson-name {
      color: #000000;
      font-weight: 600;
      font-size: 1.1em;
    }
  }
  .right {
    background-color: #ffe235;
    padding: 5px 0px 5px 10px;
    border-radius: 20px 0 0 20px;
    align-self: flex-start;
    color: #000000;
    font-weight: 600;
    width: 24%;
  }
  .footer {
    border-radius: 0 0 20px 20px;
    background-color: #f2f6ff;
    padding: 0.8em 1em 0.8em 1.5em;
    .prog-status {
      color: #000000;
      display: flex;
      align-items: center;
      .outer-progress,
      .inner-progress {
        height: 1em;
        border-radius: 20px;
      }
      .outer-progress {
        margin-left: 10px;
        width: 100%;
        background: #d6e3fe;
      }
      .inner-progress {
        width: ${(props) => props.progress}%;
        background: #fdc12e;
      }
      .complete-progress {
        height: 1.7em;
        width: 2.2em;
        border: 1px solid transparent;
        border-radius: 50%;
        background: ${(props) =>
          props.progress === '100' ? '#fdc12e' : '#d6e3fe'};
        position: relative;
        right: 9px;
        color: #ffffff;
        font-weight: 700;
        font-size: 1.2em;
        display: grid;
        place-content: center;
      }
    }
    .details {
      color: #698ed3;
      font-size: 0.8em;
      img {
        display: inline-block;
        width: 11%;
      }
      .xp {
        color: #000000;
        font-size: 1.5em;
      }
    }
  }
  .complete {
    position: absolute;
    top: -3%;
    right: -3%;
    width: 18%;
  }
  @media (max-width: 1320px) {
    width: 270px;
  }
  @media (max-width: 1250px) {
    width: 260px;
  }
  @media (max-width: 1200px) {
    width: 250px;
    .lesson-img {
      height: 160px;
    }
    .center {
      padding: 10px 0 15px 10px;
    }
    .lesson-name {
      font-size: 1em !important;
    }
  }
  @media (max-width: 1100px) {
    width: 230px;
    .lesson-img {
      height: 150px;
    }
  }
`;
