/* eslint-disable radix */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Spinner } from 'react-bootstrap';
import moment from 'moment-timezone';
import DisableCPIcon from 'assets/img/multiplayer/disable-cp.svg';
import ErrorModal from 'components/Shared/ErrorModal';
import { peerChallengeResult } from 'constants/index';
import { isNil } from 'ramda';
import { multiplayerListStyles } from 'constants/multiplayer';
import Avatar from 'assets/img/avatar.png';
import { useSelector } from 'react-redux';
import { rwd } from 'Theme';
import { useMediaQuery } from 'react-responsive';
import {
  Container,
  Row,
  Cell,
  TableHeader,
  HeaderRow,
  HeaderCell,
  StyledCenter,
  StyledTableRow,
  StyledTableFirstData,
  StyledMobileTable,
  StyledMobileTableRow,
} from './ChallengesTable.styles';

// Helpers
const compareDateTimes = (userID, order = 'asc') => {
  return function innerSort(a, b) {
    const aAttemptDate = a.Challengers.find((item) => item.UserId === userID)
      ?.AttemptDate;
    const bAttemptDate = b.Challengers.find((item) => item.UserId === userID)
      ?.AttemptDate;
    if (!aAttemptDate || !bAttemptDate) {
      // property doesn't exist on either object
      return 0;
    }
    const convertedDateTimeInSeconds = {
      a: moment(aAttemptDate).unix(),
      b: moment(bAttemptDate).unix(),
    };
    const varA =
      typeof convertedDateTimeInSeconds.a === 'string'
        ? convertedDateTimeInSeconds.a.toUpperCase()
        : convertedDateTimeInSeconds.a;
    const varB =
      typeof convertedDateTimeInSeconds.b === 'string'
        ? convertedDateTimeInSeconds.b.toUpperCase()
        : convertedDateTimeInSeconds.b;

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    } else if (varA === varB) {
      const aId = a.Id;
      const bId = b.Id;
      if (aId > bId) {
        comparison = 1;
      } else if (aId < bId) {
        comparison = -1;
      }
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

const findRemainingTime = (expiryDate) => {
  const expiryDateTime = moment(expiryDate)
    .add(3, 'days')
    .set({ hour: 6, minute: 0, second: 0 });
  const currentDateTime = moment();
  const duration = moment.duration(expiryDateTime.diff(currentDateTime));
  if (duration < 0) return `0d 0h 0m`;

  const days = parseInt(duration.asDays());
  const hours = parseInt(duration.asHours() % 24);
  const minutes = parseInt(duration.asMinutes() % 60);

  return `${days}d ${hours}h ${minutes}m`;
};

const getChallengerInfo = (challengersData, currentUserId, userInfo) => {
  const challengerInfo = {};
  if (!challengersData || !currentUserId || !userInfo) return challengerInfo;
  challengersData.forEach((challenger) => {
    const keyName =
      challenger.UserId === currentUserId ? 'current' : 'opponent';
    const currentChallengerInfo = userInfo[challenger.UserId];
    if (isNil(currentChallengerInfo)) return;
    challengerInfo[keyName] = {
      ...challenger,
      ...currentChallengerInfo,
    };
  });
  return challengerInfo;
};

const TableRow = ({ rowData, subjectName }) => {
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  const { t } = useTranslation(['peerChallengeHistory']);
  const { userID } = useSelector((state) => state.login);
  const { userInfoCache } = useSelector((state) => state.lookup);
  const challengeInfo = getChallengerInfo(
    rowData.Challengers,
    userID,
    userInfoCache
  );
  const challenger = challengeInfo.opponent;
  const myChallenge = challengeInfo.current;
  const attemptDate = myChallenge?.AttemptDate
    ? moment(myChallenge.AttemptDate).format('D/MM/YYYY')
    : '-';
  if (isMobile)
    return (
      <StyledMobileTable>
        <StyledMobileTable>
          <Cell width="15%">
            <span className="respond-date">{attemptDate}</span>
          </Cell>
          <Cell width="50%">
            <StyledTableFirstData>
              <img
                src={challenger?.UserAvatarImage || Avatar}
                alt="challenger-pic"
                className="challenger-picture"
                onError={(e) => {
                  e.target.src = Avatar;
                }}
              />
              <div className="challenger-name">
                {challenger?.FullName ?? ''}
              </div>
            </StyledTableFirstData>
          </Cell>
          <Cell
            data-cy="result"
            result={peerChallengeResult[myChallenge.Result]}
            width="25%"
          >
            <span className="result">
              {t(
                `peerChallengeHistory:table.${
                  peerChallengeResult[myChallenge.Result]
                }`,
                peerChallengeResult[myChallenge.Result]
              )}
            </span>
            {peerChallengeResult[myChallenge.Result] === 'pending' && (
              <div>
                Ends{' '}
                <span style={{ color: 'red' }}>
                  {findRemainingTime(rowData.ChallengeDate)}
                </span>
              </div>
            )}
          </Cell>
        </StyledMobileTable>
        <StyledMobileTable>
          <StyledMobileTableRow>
            <div className="border-none" width="70">
              <img
                src={
                  myChallenge.ChallengePoints === 0
                    ? DisableCPIcon
                    : multiplayerListStyles[subjectName].cpIcon
                }
                width="17.4px"
                height="20.59px"
                alt="CPs"
              />
              <span
                className={
                  myChallenge.ChallengePoints === 0
                    ? 'cps disable-result'
                    : 'cps'
                }
              >{`${myChallenge.ChallengePoints} CP`}</span>
            </div>
            <div className="border-none" width="50%">
              {myChallenge.ChallengePoints > 0 && (
                <span className="cps-earned-date">
                  {t('peerChallengeHistory:table.earnedDate', 'Earned on')}{' '}
                  {moment(myChallenge.ClaimedDate).format('D/MM/YYYY')}
                </span>
              )}
            </div>
            <div data-cy="check-result" className="border-none">
              {myChallenge.Result === 8 ? (
                <span className="disable-result">
                  {t('peerChallengeHistory:table.checkResult', 'Check Results')}
                </span>
              ) : (
                <Link
                  to={`/multiplayer/peer-challenge/challenge-result/${rowData.Id}/history?subject=${subjectName}`}
                  data-cy={`check-result-${rowData.Id}`}
                  className="check-result"
                >
                  {t('peerChallengeHistory:table.checkResult', 'Check Results')}
                </Link>
              )}
            </div>
          </StyledMobileTableRow>
        </StyledMobileTable>
      </StyledMobileTable>
    );
  return (
    <Row>
      <Cell>
        <span className="respond-date">{attemptDate}</span>
      </Cell>
      <Cell data-cy="result" result={peerChallengeResult[myChallenge.Result]}>
        <span className="result">
          {t(
            `peerChallengeHistory:table.${
              peerChallengeResult[myChallenge.Result]
            }`,
            peerChallengeResult[myChallenge.Result]
          )}
        </span>
        {peerChallengeResult[myChallenge.Result] === 'pending' && (
          <div>
            Ends{' '}
            <span style={{ color: 'red' }}>
              {findRemainingTime(rowData.ChallengeDate)}
            </span>
          </div>
        )}
      </Cell>
      <Cell data-cy="cp" style={{ width: isTablet ? '180px' : '350px' }}>
        <StyledCenter>
          <StyledTableRow>
            <td className="border-none" width={isTablet ? '100' : '80'}>
              <img
                src={
                  myChallenge.ChallengePoints === 0
                    ? DisableCPIcon
                    : multiplayerListStyles[subjectName].cpIcon
                }
                width="17.4px"
                height="20.59px"
                alt="CPs"
              />
              <span
                className={
                  myChallenge.ChallengePoints === 0
                    ? 'cps disable-result'
                    : 'cps'
                }
              >{`${myChallenge.ChallengePoints} CP`}</span>
            </td>
            <td className="border-none" width={isTablet ? '80' : '160'}>
              {myChallenge.ChallengePoints > 0 && (
                <span className="cps-earned-date">
                  {!isTablet &&
                    t(
                      'peerChallengeHistory:table.earnedDate',
                      'Earned on'
                    )}{' '}
                  {moment(myChallenge.ClaimedDate).format('D/MM/YYYY')}
                </span>
              )}
            </td>
          </StyledTableRow>
        </StyledCenter>
      </Cell>
      <Cell>
        <div className="first-row">
          <StyledTableFirstData>
            <img
              src={challenger?.UserAvatarImage || Avatar}
              alt="challenger-pic"
              className="challenger-picture"
              onError={(e) => {
                e.target.src = Avatar;
              }}
            />
            <div className="challenger-name">{challenger?.FullName ?? ''}</div>
          </StyledTableFirstData>
        </div>
      </Cell>
      <Cell data-cy="check-result">
        {myChallenge.Result === 8 ? (
          <span className="disable-result">
            {t('peerChallengeHistory:table.checkResult', 'Check Results')}
          </span>
        ) : (
          <Link
            to={`/multiplayer/peer-challenge/challenge-result/${rowData.Id}/history?subject=${subjectName}`}
            data-cy={`check-result-${rowData.Id}`}
          >
            {t('peerChallengeHistory:table.checkResult', 'Check Results')}
          </Link>
        )}
      </Cell>
    </Row>
  );
};

const ChallengesTable = ({
  headers,
  data,
  challengeType,
  loading,
  noDataFound,
  error,
  reload,
  subjectName,
}) => {
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  const { userID } = useSelector((state) => state.login);
  const sortedData = isNil(data)
    ? []
    : [...data].sort(compareDateTimes(userID, 'desc'));
  // Filter out results where status is expired or not started
  const filteredData =
    sortedData.length > 0
      ? sortedData.filter((row) => row.Result !== 6 && row.Result !== 7)
      : [];
  return (
    <Container>
      {!noDataFound &&
        (isMobile ? (
          filteredData &&
          filteredData.map((row) => (
            <Table bordered size="sm" key={row.Id}>
              <TableRow
                rowData={row}
                challengeType={challengeType}
                subjectName={subjectName}
              />
            </Table>
          ))
        ) : (
          <Table bordered size="sm">
            {!isMobile && (
              <TableHeader>
                <HeaderRow className="header-row" challengeType={challengeType}>
                  {headers.map((header, i) => (
                    <HeaderCell
                      key={i}
                      className={header === 'Opponent' ? 'first-row' : null}
                      challengeType={challengeType}
                    >
                      {header}
                    </HeaderCell>
                  ))}
                </HeaderRow>
              </TableHeader>
            )}
            <tbody data-cy="table-body">
              {loading && (
                <div style={{ position: 'absolute', left: '50%', top: '30%' }}>
                  <Spinner animation="border" variant="warning" />
                </div>
              )}
              {filteredData &&
                filteredData.map((row) => (
                  <TableRow
                    key={row.Id}
                    rowData={row}
                    challengeType={challengeType}
                    subjectName={subjectName}
                  />
                ))}
            </tbody>
          </Table>
        ))}
      {!loading && !isNil(error) && (
        <ErrorModal reloadAction={reload} errorMessage={error} />
      )}
    </Container>
  );
};

export default ChallengesTable;
