import styled from 'styled-components';

export const Container = styled.div`
  height: 30%;
  display: flex;
  text-align: left;
  line-height: 20px;
  padding: 2%;
  margin: 2%;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const Content = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
`;

export const Circle = styled.div`
  display: inline-flex;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${(props) => props.color ?? '#000'};
`;
export const TextWrap = styled.div``;
export const Text = styled.div`
  display: block;
  font-size: 1rem;
  color: #383838;
`;
