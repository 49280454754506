/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchParentDetails,
  fetchParentSubsDetails,
  postParentProfile,
  postParentPassword,
  postChildPassword,
  postChildDisplayName,
  postChildLoginId,
  fetchAvailableProducts,
} from 'services/parentSettings';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';

// Redux Thunks
export const getParentDetails = createAsyncThunk(
  'parentSettings/getParentDetails',
  async (parentID) => {
    const res = await fetchWrapper(fetchParentDetails, parentID);
    return handleResponseV2(res);
  }
);

export const getParentSubsDetails = createAsyncThunk(
  'parentSettings/getParentSubsDetails',
  async () => {
    const res = await fetchWrapper(fetchParentSubsDetails);
    return handleResponseV2(res);
  }
);

export const updateParentProfile = createAsyncThunk(
  'parentSettings/updateParentProfile',
  async (params) => {
    try {
      const res = await fetchWrapper(postParentProfile, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update parent profile failed');
    }
  }
);

export const changeParentPassword = createAsyncThunk(
  'parentSettings/changeParentPassword',
  async (params) => {
    try {
      const res = await fetchWrapper(postParentPassword, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update parent password failed');
    }
  }
);

export const changeChildPassword = createAsyncThunk(
  'parentSettings/changeChildPassword',
  async (params) => {
    try {
      const res = await fetchWrapper(postChildPassword, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update child password failed');
    }
  }
);

export const updateChildDisplayName = createAsyncThunk(
  'parentSettings/updateChildDisplayName',
  async (params) => {
    try {
      const res = await fetchWrapper(postChildDisplayName, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update child display name failed');
    }
  }
);

export const updateChildLoginId = createAsyncThunk(
  'parentSettings/updateChildLoginId',
  async (params) => {
    try {
      const res = await fetchWrapper(postChildLoginId, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update child login id failed');
    }
  }
);

export const getAvailableProducts = createAsyncThunk(
  'parentSettings/getAvailableProducts',
  async (id) => {
    const res = await fetchWrapper(fetchAvailableProducts, id);
    return handleResponseV2(res);
  }
);

const initialState = {
  isLoading: false,
  error: null,
  parentDetails: null,
  uuid: '',
  kidId: '',
  childrenProfile: null,
  parentProfileUpdate: null,
  updateParentPassword: null,
  updateChildPassword: null,
  childDisplayNameUpdate: null,
  childLoginIdUpdate: null,
  isUpdateLoading: false,
  isUpdateError: null,
  parentSubscriptions: null,
  availableProducts: {
    data: null,
    isLoading: false,
    isError: null,
  },
};

const parentSettingsSlice = createSlice({
  name: 'parentSettings',
  initialState,
  reducers: {
    reset: (state) => {
      state.isUpdateError = null;
      state.parentProfileUpdate = null;
      state.childDisplayNameUpdate = null;
      state.childLoginIdUpdate = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getParentDetails.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          error: null,
          parentDetails: null,
          childrenProfile: null,
        };
      })
      .addCase(getParentDetails.fulfilled, (state, action) => {
        let rest = null;
        let students = null;
        if (action.payload) {
          Object.entries(action.payload).forEach(([key, value]) => {
            if (key === 'students') {
              students = value;
            } else {
              rest = {
                ...rest,
                [key]: value,
              };
            }
          });
        }
        return {
          ...state,
          isLoading: false,
          error: null,
          parentDetails: rest,
          childrenProfile: students,
        };
      })
      .addCase(getParentDetails.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.error.message,
          parentDetails: null,
          childrenProfile: null,
        };
      })
      .addCase(getParentSubsDetails.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          error: null,
          parentSubscriptions: null,
        };
      })
      .addCase(getParentSubsDetails.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: null,
          parentSubscriptions: action.payload,
        };
      })
      .addCase(getParentSubsDetails.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.error.message,
          parentSubscriptions: null,
        };
      })
      .addCase(changeParentPassword.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          error: null,
          updateParentPassword: null,
        };
      })
      .addCase(changeParentPassword.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: null,
          updateParentPassword: action.payload,
        };
      })
      .addCase(changeParentPassword.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.error.message,
          updateParentPassword: null,
        };
      })
      .addCase(changeChildPassword.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          error: null,
          updateChildPassword: null,
        };
      })
      .addCase(changeChildPassword.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: null,
          updateChildPassword: action.payload,
        };
      })
      .addCase(changeChildPassword.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.error.message,
          updateChildPassword: null,
        };
      })
      .addCase(getAvailableProducts.pending, (state) => {
        state.availableProducts.isLoading = true;
        state.availableProducts.data = { ...state.availableProducts.data };
        state.availableProducts.isError = null;
      })
      .addCase(getAvailableProducts.fulfilled, (state, action) => {
        state.availableProducts.isLoading = false;
        state.availableProducts.data = {
          ...state.availableProducts.data,
          [action.meta.arg]: action.payload,
        };
        state.availableProducts.isError = null;
      })
      .addCase(getAvailableProducts.rejected, (state, action) => {
        state.availableProducts.isLoading = false;
        state.availableProducts.data = null;
        state.availableProducts.isError = action.error.message;
      });
  },
});

const { actions, reducer } = parentSettingsSlice;
export const { reset } = actions;
export default reducer;
