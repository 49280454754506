export const initialCurrentLevel = {
  ID: 1,
  Name: 'Pea',
  ImageUrl:
    'https://static.content.koobits.com/coreproduct/EXPLevel/small/1.png',
  MinExp: 0,
};

export const initialUpcomingLevels = [
  {
    ID: 2,
    Name: 'Peanut',
    ImageUrl:
      'https://static.content.koobits.com/coreproduct/EXPLevel/small/2.png',
    MinExp: 4,
  },
  {
    ID: 3,
    Name: 'Seedling',
    ImageUrl:
      'https://static.content.koobits.com/coreproduct/EXPLevel/small/3.png',
    MinExp: 10,
  },
  {
    ID: 4,
    Name: 'Tulip',
    ImageUrl:
      'https://static.content.koobits.com/coreproduct/EXPLevel/small/4.png',
    MinExp: 16,
  },
  {
    ID: 5,
    Name: 'Daisy',
    ImageUrl:
      'https://static.content.koobits.com/coreproduct/EXPLevel/small/5.png',
    MinExp: 21,
  },
  {
    ID: 6,
    Name: 'Sunflower',
    ImageUrl:
      'https://static.content.koobits.com/coreproduct/EXPLevel/small/6.png',
    MinExp: 31,
  },
];
