import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SubmitButton from 'components/Assignment/AssignmentFinish/SubmitButton';
import { Container } from './AlreadyClaimKoko.styles';

const AlreadyClaimKoko = () => {
  const { t } = useTranslation(['assignmentSubmitted']);
  const history = useHistory();

  return (
    <Container>
      <div className="content-container">
        <div className="box">
          <div className="text-box">
            <p className="success-text">
              {t(
                'assignmentSubmitted:words.AlreadyClaimText',
                'You have claimed the KoKo for this assignment. Try another way to get more KoKo!'
              )}
            </p>
            <SubmitButton
              width="70%"
              onClick={() => {
                history.push('/assignment/assignment-list');
              }}
            >
              {t(
                'assignmentSubmitted:words.AssignmentLisBtn',
                'Go to Assignment List'
              )}
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AlreadyClaimKoko;
