/* eslint-disable import/prefer-default-export */
import { isNil } from 'ramda';
import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border-radius: 8px;
  min-height: 150px;
  min-width: ${(props) => (isNil(props.minWidth) ? '515px' : props.minWidth)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  p {
    margin-bottom: 0.5rem;
  }
  .icon {
    position: absolute;
    top: -15px;
  }
  .success-text {
    color: ${(props) =>
      props.error ? 'rgb(255, 97, 0, 0.71)' : 'rgb(48, 150, 21, 0.71)'};
    font-size: 22px;
    font-weight: 600;
  }
  .desc {
    font-size: 18px;
    margin-bottom: 1.5rem;
    color: rgb(0, 0, 0, 0.71);
  }
  .error-msg {
    font-size: 16px;
    color: #000000;
    margin-bottom: 1.5rem;
  }
  .bff-name {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 1.5rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 2rem;
  }
`;
