import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BackButton from 'components/Shared/BackButton';
import moment from 'moment-timezone';
import Badge from 'assets/img/leaderboard/top-brain/badge.svg';
import {
  Topbar,
  CenterBar,
  TermClassic,
  BrainHeading,
  Terms,
  ButtonContainer,
} from './TopBrainHeader.styles';
import ChallengeType from '../ChallengeType';
import formatI18nDateTime from 'helpers/formatI18nDateTime';

const terms = [
  { bgColor: '#C7851D' },
  { bgColor: '#979F26' },
  { bgColor: '#934B27' },
  { bgColor: '#F7C92E' },
];

const TopBrain = ({
  activeTerm,
  setActiveTerm,
  currentTerm,
  changeCurrentTerm,
  isTermlyOpen,
  isClassicOpen,
}) => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const history = useHistory();
  const { topBrainTerms, topBrainSeasonsLoading } = useSelector(
    (state) => state.topBrain
  );
  return (
    <Topbar>
      <ButtonContainer>
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(209, 122, 20, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <img height="98%" src={Badge} alt="badge" className="badge-img" />
      <CenterBar>
        <div className="middle-content">
          <BrainHeading
            content={t(
              'leaderboard:topBrainBanner.topBrainAward',
              `Top Brain Award`
            )}
          >
            {t('leaderboard:topBrainBanner.topBrainAward', `Top Brain Award`)}
          </BrainHeading>
          <TermClassic
            active={activeTerm}
            bgActive="#FF8C05"
            bgInactive="#DE6600"
            colorActive="#FFFFFF"
            colorInactive="#893F00"
          >
            <div
              className="term"
              onClick={() => {
                if (!isTermlyOpen) {
                  window.open(
                    'https://support.koobits.com/hc/en-gb/articles/4430653191449'
                  );
                  return;
                }
                setActiveTerm('term');
              }}
            >
              {t('leaderboard:topBrainBanner.termly', `Termly`)}
            </div>
            <div
              className="classic"
              onClick={() => {
                if (!isClassicOpen) {
                  window.open(
                    'https://support.koobits.com/hc/en-gb/articles/4430653191449'
                  );
                  return;
                }
                setActiveTerm('classic');
              }}
            >
              {t('leaderboard:topBrainBanner.classic', `Classic`)}
            </div>
          </TermClassic>
        </div>
        <Terms>
          {topBrainSeasonsLoading ? (
            <div>Loading..</div>
          ) : (
            topBrainTerms.map((item, i) => (
              <div
                data-cy={`term-${i + 1}`}
                className={
                  item.TermId === currentTerm.TermId ? 'active-term' : null
                }
                style={{ background: terms[i].bgColor }}
                onClick={() => changeCurrentTerm(item.TermId)}
                key={item.TermId}
              >
                {t(
                  'leaderboard:topBrainBanner.term',
                  { number: item.TermId },
                  `Term ${item.TermId}`
                )}
              </div>
            ))
          )}
        </Terms>
        <div className="scoring-period">
          <div>
            <span>
              {t('leaderboard:topBrainBanner.scoringPeriod', `Scoring Period`)}
            </span>{' '}
            {t(
              'leaderboard:topBrainBanner.term',
              { number: currentTerm.TermId },
              `Term ${currentTerm.TermId}`
            )}
            ,{' '}
            {t('leaderboard:topBrainBanner.dateRange', {
              week: `${currentTerm.StartWeek} - ${currentTerm.EndWeek}`,
              start: moment(currentTerm.TermStartDate).format(
                formatI18nDateTime({
                  language: i18n.language,
                  format: 'DD-MMM',
                  dateOnly: true,
                })
              ),
              end: moment(currentTerm.TermEndDate).format(
                formatI18nDateTime({
                  language: i18n.language,
                  format: 'DD-MMM',
                  dateOnly: true,
                })
              ),
            })}
          </div>
        </div>
        <a
          href={`${process.env.REACT_APP_OFFICIAL_WEB_URL}awards/topbrain/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="rules">
            <p className="rules-text">
              {t('leaderboard:topBrainBanner.rules', 'Rules')}
            </p>
          </div>
        </a>
      </CenterBar>
      <ChallengeType styles={{ top: '12%' }} />
    </Topbar>
  );
};

export default TopBrain;
