/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MikoImg from 'assets/img/error/miko-coding.jpg';
import Button from './Button';
import {
  StyledModal,
  StyledContainer,
  StyledHeader,
  StyledTitle,
  StyledButtonWrap,
  StyledConcat,
} from './ErrorModal.style';

const LINK = 'https://www.koobits.com/contact';
// reloadAction:function
// backButton:string
// if have reloadAction and backPage both, will render reload first
const ErrorModal = ({
  reloadAction,
  backPage,
  errorMessage,
  otherBtn,
  closeHandler,
  backText,
}) => {
  const { t } = useTranslation(['common', 'errorModal']);
  const history = useHistory();
  const [show, setShow] = useState(true);
  const haveOptionButton = reloadAction || backPage;
  const handleCloseModal = () => {
    if (closeHandler) {
      closeHandler();
    }
    setShow(false);
  };
  const handleOptionButtonClick = () => {
    if (reloadAction) {
      reloadAction();
    } else {
      history.push(backPage);
    }
  };
  const renderOptionButton = () => {
    if (haveOptionButton) {
      return (
        <Button
          dataCy="option-btn"
          className="primary"
          width="70%"
          margin="0 auto"
          onClick={handleOptionButtonClick}
        >
          {reloadAction
            ? otherBtn || t('common:reload', 'Reload')
            : t('common:back', 'Back')}
        </Button>
      );
    }
  };
  return (
    <StyledModal
      show={show}
      keyboard={false}
      onHide={handleCloseModal}
      centered
      backdrop="static"
      size="lg"
      data-cy="error-modal"
    >
      <StyledContainer>
        <StyledHeader>
          <StyledTitle data-cy="title">
            <p>{t('common:sorry', 'Sorry')}</p>
            <span>
              {errorMessage.toLowerCase() === 'unknown error'
                ? t('errorModal:someWrong', 'Something went wrong')
                : errorMessage}
            </span>
          </StyledTitle>
          <img src={MikoImg} alt="" width="302px" />
        </StyledHeader>
        <StyledButtonWrap isButtonGroup={haveOptionButton}>
          {renderOptionButton()}
          <Button
            dataCy="ok-btn"
            className="primary"
            variant={haveOptionButton && 'secondary'}
            width={haveOptionButton ? '70%' : '60%'}
            margin="0 auto"
            onClick={handleCloseModal}
          >
            {backText ? t('common:back', 'Back') : t('common:ok', 'OK')}
          </Button>
        </StyledButtonWrap>
        <StyledConcat>
          <Trans i18nKey="errorModal:contactUs" t={t}>
            <a href={LINK} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </Trans>
        </StyledConcat>
      </StyledContainer>
    </StyledModal>
  );
};

export default ErrorModal;
