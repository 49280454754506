import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import BackButton from 'components/Shared/BackButton';
import useDashboard from 'hooks/useDashboard';
import { Container } from './AssignmentBackButton.styles';

const AssignmentBackButton = ({ className }) => {
  const { t } = useTranslation(['assignmentList']);
  const history = useHistory();
  const location = useLocation();
  const isFromShortCut = location.state?.quickAccess;
  const { isDashboardv2 } = useDashboard();

  const handleBack = () => {
    if (isFromShortCut) {
      history.goBack();
      return;
    }
    const destination = isDashboardv2 ? '/assignment' : '/dashboard';
    history.push(destination);
  };

  return (
    <Container data-cy="back-button" className={className}>
      <BackButton
        backText={t('leaderboard:buttons.back', 'Back')}
        btnBgColor="#8BD03E"
        padding="0.3rem 1.2rem"
        onClick={handleBack}
      />
    </Container>
  );
};

export default AssignmentBackButton;
