import React from 'react';
import Button from 'components/Shared/Button';
import Mascot from 'assets/img/dailychallenge/smc-mascot.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Container, CloseBtn } from './SMCModal.styles';

const SMCModal = ({ setShowSMCModal }) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { t } = useTranslation(['challengeList']);

  return (
    <>
      {!isMobile && (
        <div
          style={{
            position: 'absolute',
            marginLeft: '-7.5rem',
          }}
        >
          <img src={Mascot} alt="Miko" />
        </div>
      )}
      <CloseBtn
        onClick={() => {
          setShowSMCModal(false);
        }}
      >
        X
      </CloseBtn>
      <Container data-cy="smc-modal">
        <>
          <p className="title-text">
            {t(`challengeList:SMCModal.titleText`, 'Enjoy your Sunday')}
          </p>
          <p className="main-text">
            {t(
              `challengeList:SMCModal.mainText`,
              'Daily Challenge is closed on Sunday so you can take a break. You may still attempt the Sunday Mini Challenge for Math if you are gamed for some brain booster!'
            )}
          </p>
          <Button
            fontSize="22px"
            textShadow="0px 3px 6px rgba(0, 0, 0, 0.16)"
            width="316.28px"
            variant="primary"
            onClick={() => {
              history.push('/events');
            }}
          >
            {t(
              `challengeList:SMCModal.buttonText`,
              'Try Sunday Mini Challenge'
            )}
          </Button>
        </>
      </Container>
    </>
  );
};
export default SMCModal;
