import { useEffect, useRef, useState } from 'react';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';
import { useDispatch, useSelector } from 'react-redux';

const useDraw = () => {
  const [mouseDown, setMouseDown] = useState(false);
  const dispatch = useDispatch();
  const {
    isActive,
    pencilColor,
    highlighterColor,
    type,
    clearDraw,
  } = useSelector((state) => state.drawingKits);
  const canvasRef = useRef(null);
  const prevPointRef = useRef(null);
  const cursor = document.getElementById('custom-cursor');
  const canvas = canvasRef.current;
  const context = canvas?.getContext('2d');
  const color = type === 'pencil' ? pencilColor : highlighterColor;
  const clear = () => {
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };
  const calculatePointInCanvas = (e) => {
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    let clientX;
    let clientY;
    if (e.touches && e.touches.length) {
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      clientX = e.clientX;
      clientY = e.clientY;
    }

    const x = clientX - rect.left;
    const y = clientY - rect.top;
    return { x, y };
  };

  const startHandler = (e) => {
    if (e.button === 0) {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
      }
      if (e.touches && e.touches.length) {
        prevPointRef.current = null;
      }
      setMouseDown(true);
    }
  };

  const moveHandler = (e) => {
    if (e.button === 0) {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
      }
      const currentPoint = calculatePointInCanvas(e);
      if (!mouseDown || !isActive || !context || !currentPoint) return;

      if (cursor) {
        if (type === 'eraser') {
          cursor.setAttribute(
            'style',
            `display: block; top: ${currentPoint.y - 3}px; left: ${
              currentPoint.x - 7.5
            }px; background-color: white; border: 1px solid #707070`
          );
        } else {
          cursor.setAttribute('style', `display: none`);
        }
      }

      if (type === 'eraser' && mouseDown) {
        context.beginPath();
        context.clearRect(currentPoint.x, currentPoint.y, 15, 15);
        return;
      }
      const { x: currX, y: currY } = currentPoint;
      const lineColor = color;
      const lineWidth = 3;

      const startPoint = prevPointRef.current ?? currentPoint;
      if (type === 'pencil') {
        context.beginPath();
        context.lineWidth = lineWidth;
        context.strokeStyle = lineColor;
        context.moveTo(startPoint.x, startPoint.y);
        context.lineTo(currX, currY);
        context.stroke();

        context.fillStyle = lineColor;
        context.arc(startPoint.x, startPoint.y, 2, 0, 2 * Math.PI);
        context.fill();
      } else {
        context.beginPath();
        context.lineWidth = 10;
        context.fillStyle = color;
        context.moveTo(startPoint.x, startPoint.y);
        context.lineTo(currX, currY);
        context.fillRect(currX - 10, currY - 10, 20, 20);
      }

      prevPointRef.current = currentPoint;
    }
  };

  const mouseUpHandler = (e) => {
    if (e.button === 0) {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
      }
      setMouseDown(false);
      if (cursor) {
        cursor.setAttribute('style', `display: none`);
      }
      prevPointRef.current = null;
    }
  };
  useEffect(() => {
    if (clearDraw) {
      clear();
      dispatch(setClearDraw(false));
    }
    // eslint-disable-next-line
  }, [clearDraw]);
  useEffect(() => {
    // add event listeners
    if (isActive) {
      window.addEventListener('mousedown', startHandler);
      window.addEventListener('touchstart', startHandler);
      window.addEventListener('mousemove', moveHandler);
      window.addEventListener('touchmove', moveHandler);
      window.addEventListener('mouseup', mouseUpHandler);
    } else {
      window.removeEventListener('mousedown', startHandler);
      window.removeEventListener('touchstart', startHandler);
      window.removeEventListener('mousemove', moveHandler);
      window.removeEventListener('touchmove', moveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
    }

    // remove event listener
    return () => {
      window.removeEventListener('mousedown', startHandler);
      window.removeEventListener('touchstart', startHandler);
      window.removeEventListener('mousemove', moveHandler);
      window.removeEventListener('touchmove', moveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouseDown, isActive]);
  return { canvasRef, clear };
};

export default useDraw;
