import React from 'react';
import Container from './RoundedModal.styles';

const RoundedModal = ({
  msg,
  desc,
  close,
  noHandle,
  yesHandle,
  singleButton,
  singleButtonText,
  isLoading,
  children,
}) => {
  return (
    <Container singleButton={singleButton}>
      <div className="close-button" onClick={close}>
        &times;
      </div>
      <div className="body-section">{children}</div>
    </Container>
  );
};

export default RoundedModal;
