import React, { useEffect, useCallback } from 'react';
import { isNil } from 'ramda';
import {
  getSuperHeroChallengeStatus,
  getSuperHeroChallengeDataWithoutImage,
  getDailyChallengesList,
} from 'store/dailyChallenge/dailyChallengeSlice';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Header from 'components/QnView/QnViewHeader';
import BackButton from 'components/Shared/BackButton';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import DCText from 'assets/img/dailychallenge/daily-challenge-text.svg';
import Spinner from 'components/Shared/Spinner';
import { compareValues } from 'helpers/compareValue';
import ChallengeCard from 'components/DailyChallenge/ChallengeCard';
import { dailyChallengeList, subjectStyles } from 'constants/dailyChallenge';
import {
  checkErrMsgIsClosed,
  parseSuperHeroChallengeStatus,
} from 'pages/DailyChallenge/ChallengesList';
import ErrorModal from 'components/Shared/ErrorModal';
import { submissionResultIDs } from 'constants/index';

import { subjectIDs } from 'constants/products';
import useDashboard from 'hooks/useDashboard';
import { Container, Main } from './ChallengesList.styles';

// HELPERS
const sortDailyChallenges = (challenges) => {
  if (isNil(challenges)) return [];
  return challenges
    .map((dc) => {
      return {
        ...dc,
        displayOrder: dc.subjectID ?? 99,
      };
    })
    .sort(compareValues('displayOrder', 'asc'));
};

const challengeStatus = (dailyChallengeStatus, dailyChallengeDetails) => {
  const stillOpen = dailyChallengeStatus && !dailyChallengeStatus.IsClosed;
  const checkSubmissionModel =
    dailyChallengeDetails.UserQuestionSubmissions &&
    dailyChallengeDetails.UserQuestionSubmissions.filter(
      (item) => item.QuestionStatus !== 4
    )
      .map((item) => item.SubmissionModel)
      .findIndex(
        (data) =>
          data === null ||
          submissionResultIDs[data?.SubmissionResult] === 'wrong' ||
          submissionResultIDs[data?.SubmissionResult] === 'unknown'
      );

  const challengeComplete = () => {
    if (checkSubmissionModel === null || isNil(checkSubmissionModel)) {
      return false;
    }
    if (checkSubmissionModel !== null) {
      if (checkSubmissionModel === -1) {
        return true;
      }
    }
    return false;
  };

  if (!stillOpen) {
    return 'close';
  }
  if (stillOpen && challengeComplete()) {
    return 'completed';
  }
  if (stillOpen) {
    return 'open';
  }
};

// Challenge List page component to support science product using v2 endpoints
const NewChallengeList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(['challengeList', 'common', 'topicSelection']);
  const isFromShortCut = location.state?.quickAccess;
  // REDUX STATES
  const {
    isLoading: isProductsLoading,
    products,
    currentProduct,
    getProductsError,
  } = useSelector((state) => state.plan);
  const hasProductsLoaded =
    !isProductsLoading && (!isNil(products) || !isNil(getProductsError));
  const {
    dailyChallenges,
    isDailyChallengesLoading,
    superHeroChallengeStatus,
    superHeroChallengeIsLoading,
    superHeroChallengeIsError,
  } = useSelector((state) => state.dailyChallenge);
  const { timezone } = useSelector((state) => state.login);
  const isPageLoading =
    isProductsLoading ||
    isDailyChallengesLoading ||
    superHeroChallengeIsLoading;

  const { isDashboardv2 } = useDashboard();
  const isSunday = moment().clone().tz(timezone)?.isoWeekday() === 7;
  // Check the account is registered through localized route

  // Component States

  const loadPageData = useCallback(() => {
    dispatch(getSuperHeroChallengeDataWithoutImage());
    dispatch(getSuperHeroChallengeStatus());
    dispatch(getDailyChallengesList());
  }, [dispatch]);
  const reloadSuperHeroChallengeData = () => {
    dispatch(getSuperHeroChallengeStatus());
  };
  // USEEFFECTS
  useEffect(() => {
    if (hasProductsLoaded) {
      loadPageData();
    }
  }, [hasProductsLoaded, loadPageData]);

  const renderSuperChallenge = () => {
    if (!isSunday) {
      if (
        !isPageLoading &&
        !superHeroChallengeIsError &&
        !isNil(superHeroChallengeStatus)
      ) {
        return dailyChallengeList.challenge
          .filter((chal) => chal.type !== 'daily-challenge')
          .map((item) => (
            <ChallengeCard
              key={item.id}
              challengeStatus={parseSuperHeroChallengeStatus(
                item.id,
                superHeroChallengeStatus
              )}
              challengeName={t(
                `challengeList:${item.nameTranslation}`,
                `${item.name}`
              )}
              challengeDesc={item.desc}
              challengeType={item.type}
              challengeOpenImg={item.imgOpen}
              challengeCloseImg={item.imgClose}
              challengeLink={item.link}
            />
          ));
      }
      if (
        !isPageLoading &&
        !isNil(superHeroChallengeIsError) &&
        superHeroChallengeIsError !== 'Unable to get super vision image'
      ) {
        return (
          <ErrorModal
            reloadAction={reloadSuperHeroChallengeData}
            errorMessage={superHeroChallengeIsError}
          />
        );
      }
    }
    return null;
  };

  return (
    <Container>
      <Header page bgColor="#FFC70B">
        <BackButton
          backText={t('topicSelection:backBtn', 'Back')}
          isResponsive
          btnBgColor="rgb(229,153,0,0.61)"
          padding="5px 20px"
          onClick={() => {
            if (isFromShortCut) {
              history.goBack();
              return;
            }
            const destination = isDashboardv2
              ? '/dashboard?view=koochallenge'
              : '/dashboard';
            history.push(destination);
          }}
          styles={{ position: 'absolute', left: '3%' }}
        />
        <img className="dc-text" src={DCText} alt="daily-challenge" />
      </Header>
      <Main>
        {/* Render Daily Challenges */}
        {isPageLoading && <Spinner />}
        {!isPageLoading &&
          currentProduct &&
          sortDailyChallenges(dailyChallenges).map((dc) => {
            const hasDCError =
              !isNil(dc.status.error) || !isNil(dc.details.error);
            const isDCClosed = hasDCError
              ? checkErrMsgIsClosed(dc.status.error, dc.details.error)
              : false;
            const dcConstants = dailyChallengeList.challenge.find(
              (challenge) =>
                challenge.type === 'daily-challenge' &&
                challenge.subjectID === dc.subjectID
            );
            const lowercaseSubject = subjectIDs[dc.subjectID]
              .toLowerCase()
              .trim();
            if (isNil(dcConstants)) {
              return null;
            }
            if (hasDCError && isDCClosed) {
              return (
                <ChallengeCard
                  key={dcConstants.id}
                  challengeStatus="close"
                  challengeName={t(
                    `challengeList:${dcConstants.nameTranslation}`,
                    `${dcConstants.name}`
                  )}
                  challengeDesc={
                    dcConstants.nameTranslation === 'ScienceDC'
                      ? dcConstants.scienceDesc
                      : dcConstants.desc
                  }
                  challengeType={dcConstants.type}
                  challengeOpenImg={dcConstants.imgOpen}
                  challengeCloseImg={dcConstants.imgClose}
                  challengeLink={dcConstants.link}
                  totalQns={dc.totalQns}
                  cpAmt={dcConstants.cpAmt}
                  cpIcon={subjectStyles[lowercaseSubject].cpIcon}
                />
              );
            }
            if (hasDCError && !isDCClosed) {
              return (
                <ErrorModal
                  reloadAction={loadPageData}
                  errorMessage={dc.status.error ?? dc.details.error}
                />
              );
            }
            return (
              <ChallengeCard
                key={dcConstants.id}
                challengeStatus={challengeStatus(dc.status, dc.details)}
                challengeName={t(
                  `challengeList:${dcConstants.nameTranslation}`,
                  `${dcConstants.name}`
                )}
                challengeDesc={
                  dcConstants.nameTranslation === 'ScienceDC'
                    ? t(
                        'challengeList:scienceDesc',
                        `${dcConstants.scienceDesc}`
                      )
                    : t('challengeList:desc', `${dcConstants.desc}`)
                }
                challengeType={dcConstants.type}
                challengeOpenImg={dcConstants.imgOpen}
                challengeCloseImg={dcConstants.imgClose}
                challengeLink={dcConstants.link}
                totalQns={dcConstants.totalQns}
                cpAmt={dcConstants.cpAmt}
                cpIcon={subjectStyles[lowercaseSubject].cpIcon}
              />
            );
          })}
        {renderSuperChallenge()}
      </Main>
    </Container>
  );
};

export default NewChallengeList;
