import React from 'react';
import { Menu, Item } from './HelpMenu.styles';

const helpList = [
  {
    name: 'Challenges',
    link:
      'https://support.koobits.com/hc/en-gb/categories/201303186-Challenges-Leaderboards',
    premiumOnly: false,
  },
  {
    name: 'Leaderboards',
    link:
      'https://support.koobits.com/hc/en-gb/sections/360003272492-Leaderboards',
    premiumOnly: false,
  },
  {
    name: 'Mission',
    link: 'https://support.koobits.com/hc/en-gb/sections/202163166-Mission',
    premiumOnly: false,
  },
  {
    name: 'Assignment',
    link:
      'https://support.koobits.com/hc/en-gb/categories/201321023-Assignments',
    premiumOnly: false,
  },
  {
    name: 'KoKo Credits',
    link:
      'https://support.koobits.com/hc/en-gb/sections/360008152751-KoKo-Credits',
    premiumOnly: false,
  },
  {
    name: 'KooClass (for Premium users)',
    link: '	https://support.koobits.com/hc/en-gb/sections/900002051863-KooClass',
    premiumOnly: true,
  },
  {
    name: 'Others',
    link: 'https://support.koobits.com/ ',
    premiumOnly: false,
  },
];

const HelpMenu = () => {
  return (
    <Menu id="option">
      {helpList.map((item) => (
        <Item key={item.name} href={item.link} target="_blank">
          {item.name}
        </Item>
      ))}
    </Menu>
  );
};

export default HelpMenu;
