import styled from 'styled-components';

export const ModalContent = styled.div`
  width: 470px;
  background: #ffffff;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 2rem 2rem;
  gap: 1.5rem;
  p {
    margin-bottom: 0;
    color: #000000;
  }
  .icon {
    position: relative;
    top: -2.3rem;
  }
  .title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: -4rem;
  }
  .desc {
    span {
      font-weight: 600;
    }
  }
`;
