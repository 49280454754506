import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import AuthenticatedRoute from 'components/Shared/AuthenticatedRoute';

const CheckHistoryPopRouter = ({ component, redirectPathname, ...props }) => {
  const history = useHistory();
  if (history.action === 'POP') {
    return (
      <Redirect
        to={{
          pathname: redirectPathname,
        }}
      />
    );
  }
  return (
    <AuthenticatedRoute {...props} path={props.path} component={component} />
  );
};

export default CheckHistoryPopRouter;
