import handleResponse from 'helpers/handleAPIResponse';
import { isNil } from 'ramda';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

const dailyChallengeBaseUrl = process.env.REACT_APP_DAILYCHALLENGE_API_DOMAIN;

// Get daily challenge status
export const fetchDailyChallengeStatus = () => {
  return fetch(`${dailyChallengeBaseUrl}DailyChallenge/GetStatus`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get current daily challenge failed'); // eslint-disable-line
    });
};

// Get super hero challenge status
export const fetchSuperHeroChallengeStatus = () => {
  return fetch(`${baseUrl}SuperHeroChallenge/GetSuperheroChallengeData`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get super hero challenge status failed'); // eslint-disable-line
    });
};

// Get current daily challenge
export const fetchCurrentDailyChallenge = () => {
  return fetch(
    `${dailyChallengeBaseUrl}DailyChallenge/GetDailyChallengeDetails`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get current daily challenge failed'); // eslint-disable-line
    });
};

// Update difficulty
export const updateDifficultyChallenge = (diffLevel) => {
  return fetch(
    `${dailyChallengeBaseUrl}DailyChallenge/UpdateDailyChallengeLevel`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
      body: JSON.stringify(diffLevel),
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update difficulty challenge failed'); // eslint-disable-line
    });
};

export const updateDifficultyChallengeForB2C = (diffLevel) => {
  return fetch(
    `${dailyChallengeBaseUrl}DailyChallenge/UpdateDailyChallengeLevelB2C`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
      body: JSON.stringify(diffLevel),
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update difficulty challenge failed'); // eslint-disable-line
    });
};
// Get topics based on student level
export const fetchTopics = () => {
  return fetch(`${baseUrl}Lookup/CurrentUserTopics`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get topics based on student failed'); // eslint-disable-line
    });
};

// Change question
export const updateQuestion = (rawBody) => {
  return fetch(`${dailyChallengeBaseUrl}DailyChallenge/ChangeQuestion`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Change question failed'); // eslint-disable-line
    });
};

// Submit the answer
export const submitChallengeAnswer = ({ rawBody }) => {
  return fetch(`${dailyChallengeBaseUrl}DailyChallenge/Submit`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit answer failed');
    });
};

// v2 Daily Challenge Endpoints
export const fetchDailyChallengeStatusBySubjectID = (subjectID) => {
  if (isNil(subjectID)) throw new Error('Subject ID undefined');
  return fetch(
    `${dailyChallengeBaseUrl}DailyChallenge/v2/GetStatus/${subjectID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get daily challenge status by subject id failed'
      ); // eslint-disable-line
    });
};

export const fetchDailyChallengeDetailsBySubjectID = (subjectID) => {
  if (isNil(subjectID)) throw new Error('Subject ID undefined');
  return fetch(
    `${dailyChallengeBaseUrl}DailyChallenge/v2/GetDailyChallengeDetails/${subjectID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get daily challenge details by subject id failed'
      ); // eslint-disable-line
    });
};

export const fetchClaimKoko = ({ subjectID, dcId }) => {
  if (isNil(subjectID)) throw new Error('Subject ID undefined');
  if (isNil(dcId)) throw new Error('Daily Challenge ID undefined');
  return fetch(
    `${dailyChallengeBaseUrl}DailyChallenge/v2/${subjectID}/ClaimKoko/${dcId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Claim Daily Challenge Koko failed');
    });
};

export const fetchDCCompletionStatusBySubjectId = (subjectID) => {
  if (isNil(subjectID)) throw new Error('Subject ID undefined');
  return fetch(
    `${dailyChallengeBaseUrl}DailyChallenge/GetCompletionStatus/${subjectID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ??
          'Get Daily Challenge Completion status by subject id failed'
      );
    });
};

export const fetchGetTodayCPDetails = () => {
  return fetch(`${baseUrl}ChallengePoint/GetTodayCPDetails`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get today cp detail failed');
    });
};

export const fetchGetTodayCPDetailsWithSMCData = (smcSubmissionId) => {
  if (isNil(smcSubmissionId)) throw new Error('smcSubmission ID undefined');
  return fetch(
    `${baseUrl}ChallengePoint/GetTodayCPDetails/${smcSubmissionId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get today cp detail failed');
    });
};
