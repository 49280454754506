import styled from 'styled-components';

export const TopSection = styled.div`
  padding-top: 1rem;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  .back-btn {
    border: none;
    background: #fcedcc;
    color: orange;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    :hover {
      background: #e3d5b8;
    }
  }
  .banner {
    flex-basis: 400px;
    margin-right: 3rem;
    margin-left: 2rem;
  }
`;

export const MonthYearSwitcher = styled.div`
  background: #fcedcc;
  color: orange;
  padding: 0.5rem 1rem;
  button {
    border: none;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: orange;
    color: white;
    :hover {
      background: #ffb733;
    }
  }
  span {
    margin: 0 1rem;
  }
`;
