import React from 'react';
import ImgIslandCLose from 'assets/img/braingame/Koobits-Games-games-island-closed.jpg';
import CloseArenaModal from 'components/BrainGame/CloseArenaModal';
import { useSelector } from 'react-redux';
import { Content, ImgClosed } from './CloseArena.style';

const CloseArena = () => {
  const { openTime, closeTime } = useSelector((state) => state.brainGame);
  return (
    <Content>
      <ImgClosed src={ImgIslandCLose} />
      <CloseArenaModal openTime={openTime} closeTime={closeTime} />
    </Content>
  );
};

export default React.memo(CloseArena);
