import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const StyledToggle = styled.div`
  background-color: #303233;
  border-radius: 16px;
  padding: 0.3125rem 0.75rem 0.3125rem 0.3125rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  .subject-name {
    font-size: 0.875rem;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0;
    margin-left: 0.3125rem;
  }
`;

export const Arrow = styled.div`
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid
    ${({ subject }) => (subject === 'science' ? '#B8E682' : '#81c9ff')};
  border-radius: 6rem;
  margin-left: 0.4375rem;
`;

export const RadioBtn = styled.div`
  background-color: ${({ active }) => (active ? '#F88F06' : '#676767')};
  height: 15px;
  width: 15px;
  border: ${({ active }) => active && '3px solid #ffffff'};
  border-radius: 100%;
`;

export const Container = styled(Dropdown)`
  margin-left: 0.75rem;
  padding-top: 5px;
  .dropdown-menu {
    background-color: #292929;
    padding: 0;
    border-radius: 5px;
    min-width: 8.15rem;
  }
  .first-item {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .last-item {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .dropdown-item {
    background-color: #404447;
    border: 1px solid #292929;
    font-size: 0.875rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 0.8125rem;
    justify-content: space-between;
    &:hover {
      background-color: #292929;
      ${RadioBtn} {
        background-color: #b2b2b2;
        height: 15px;
        width: 15px;
        border: 3px solid #676767;
        border-radius: 100%;
      }
    }
  }
  .subject-item {
    padding-left: 2.2rem;
  }
`;
