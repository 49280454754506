import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  #dropdown-custom {
    font-size: 14px;
    padding: 5px;
    height: 35px;
    .toggle-icon {
      background: #ffce00;
      position: absolute;
      right: 0.4px;
      height: 100%;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
    }
  }
  #dropdown-custom::after {
    content: none;
  }
  .dropdown-item-custom {
    padding: 0.25rem 1rem;
  }
`;

export default Container;
