export const HALL_OF_FAME_COLORS = {
  1: {
    border: '#E2B91E',
    header: '#FFDF67',
    font: '#B28C00',
    content: '#FFFAE0',
    footer: '#FFF0B9',
  },
  2: {
    border: '#F2982F',
    header: '#FFB865',
    font: '#C46900',
    content: '#FFF6EB',
    footer: '#FFE5C6',
  },
  3: {
    border: '#B7D033',
    header: '#D9EB73',
    font: '#6F9D07',
    content: '#F9FFD9',
    footer: '#F0FF9D',
  },
  4: {
    border: '#E094D5',
    header: '#F5D7F1',
    font: '#AD60A3',
    content: '#FFF2FD',
    footer: '#F5DAF2',
  },
  5: {
    border: '#A695E2',
    header: '#E0D8FF',
    font: '#8C7CC4',
    content: '#F5F2FF',
    footer: '#E8E2FF',
  },
  6: {
    border: '#58B7D3',
    header: '#B7EBFA',
    font: '#3D97B1',
    content: '#EFFBFF',
    footer: '#DBF7FF',
  },
};
