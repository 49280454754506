import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 5rem;
  .layer-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 450px;
    margin-top: 3rem;
    background: rgb(16, 15, 15, 0.13);
    border: 4px solid rgb(112, 112, 112, 0.16);
    border-radius: 13px;
  }
  .ribbon {
    z-index: 1;
    margin-top: -2.5rem;
  }
  .content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1.5rem;
    .main-star {
      width: 60px;
      height: 60px;
    }
    .sub-star {
      margin-bottom: 1rem;
    }
  }

  .claimed-text {
    color: #ffffff;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-left: 0;
    padding-bottom: 10px;
    .layer-bg {
      width: 300px;
    }
    .claimed-text {
      text-align: center;
    }
  }
`;

export default {};
