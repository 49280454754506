import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAvatarParts } from 'store/avatar/avatarCreatorSlice';

export default function useAvatarBodyParts() {
  const { isPartLoaded } = useSelector((state) => state.avatarCreator);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isPartLoaded) {
      dispatch(getAvatarParts());
    }
  }, [dispatch, isPartLoaded]);
}
