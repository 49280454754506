import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStudentDetails } from 'services/dashboard';
import { fetchWrapper } from 'services/login';
import { getCurrentDateTimeInTimezone } from 'pages/Events/EventList';
import { filterNonExpiredPlans } from 'helpers/subscriptionPlans';
import moment from 'moment-timezone';
import { isNil, isEmpty } from 'ramda';
import store from 'store/index';
import { setPlan } from 'store/plan/planSlice';
import LogRocket from 'logrocket';

const initialState = {
  studentDetails: null,
  gender: null,
  defaultLevel: null,
  country: 'Singapore',
  countryCode: 'SG',
  groupId: '',
  isLoading: true,
  error: null,
  hasScreenLoaded: false,
  hasSeenLoader: false,
  hasSeenToggleTooltip: true,
  isExpired: false,
  // If user has both non-expired premium & school subscription plan: true
  hasPremiumSchoolPlan: false,
  userPremiumSchoolPlanPreference: null,
  userCurrentLocale: null,
  nonExpiredSubscriptionPlans: null,
  subscription: '',
  subscriptionData: null,
  showPremiumSchoolToggleModal: false,
};

// HELPERS
// Gets the default level id from the subscriptions
const getDefaultLevel = (subscriptions) => {
  if (subscriptions.length <= 0) return 1;
  const premiumPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 3 || plan.PlanId === 4
  );
  const plusPlanIndex = subscriptions.findIndex((plan) => plan.PlanId === 2);
  const schoolPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
  );
  if (premiumPlanIndex !== -1) {
    return subscriptions[premiumPlanIndex].LevelId;
  }
  if (plusPlanIndex !== -1) {
    return subscriptions[plusPlanIndex].LevelId;
  }
  if (schoolPlanIndex !== -1) {
    return subscriptions[schoolPlanIndex].LevelId;
  }
  return subscriptions[0].LevelId;
};

const titleCase = (text) => {
  const output = text.replace(
    /(\w)(\w*)/g,
    (_, firstChar, rest) => firstChar + rest.toLowerCase()
  );
  return output;
};

const getSubsData = (subscriptions) => {
  if (subscriptions.length <= 0) return -1;
  const premiumPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 3 || plan.PlanId === 4
  );
  const plusPlanIndex = subscriptions.findIndex((plan) => plan.PlanId === 2);
  const schoolPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
  );
  const standardPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 13
  );
  if (premiumPlanIndex !== -1) {
    return subscriptions[premiumPlanIndex];
  }
  if (plusPlanIndex !== -1) {
    return subscriptions[plusPlanIndex];
  }
  if (standardPlanIndex !== -1) {
    return subscriptions[standardPlanIndex];
  }
  if (schoolPlanIndex !== -1) {
    return subscriptions[schoolPlanIndex];
  }
  return subscriptions[0];
};

// Returns single plan name based on plan hierarchy regardless of expiry date
export const checkSchoolPlan = (subscriptions) => {
  const schoolPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
  );
  const standardPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 13
  );
  // PlanId === 4 is for Math olympiad
  const premiumPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 3 || plan.PlanId === 4
  );
  const plusPlanIndex = subscriptions.findIndex((plan) => plan.PlanId === 2);
  if (premiumPlanIndex !== -1) {
    return 'premium';
  }
  if (plusPlanIndex !== -1) {
    return 'plus';
  }
  if (standardPlanIndex !== -1) {
    return 'standard';
  }
  if (schoolPlanIndex !== -1) {
    return 'school';
  }
  return 'plus';
};

// Checks if user has any non-expired subscription plans
const checkIfAccountExpired = (subscriptions, timezone) => {
  const currentDateTimeMoment = getCurrentDateTimeInTimezone(timezone);
  const findValidSubscription = subscriptions.find((plan) =>
    currentDateTimeMoment.isBefore(moment.tz(plan.EndDate, timezone))
  );
  return isNil(findValidSubscription);
};

// Check if user has both non-expired premium & school subscription plans
const hasPremiumAndSchoolPlans = (subscriptions, timezone) => {
  const currentDateTimeMoment = getCurrentDateTimeInTimezone(timezone);
  const nonExpiredSubscriptionPlans = subscriptions.filter((plan) =>
    currentDateTimeMoment.isBefore(moment.tz(plan.EndDate, timezone))
  );
  const premiumPlanIndex = nonExpiredSubscriptionPlans.findIndex(
    (plan) => plan.PlanId === 3 || plan.PlanId === 4
  );
  const schoolPlanIndex = nonExpiredSubscriptionPlans.findIndex(
    (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
  );
  return premiumPlanIndex !== -1 && schoolPlanIndex !== -1;
};

// ASYNC THUNKS
export const getStudentDetails = createAsyncThunk(
  'dashboard/getStudentDetails',
  async (_, { dispatch }) => {
    try {
      const res = await fetchWrapper(fetchStudentDetails);
      LogRocket.identify(`${res.Result.UserId}`, {
        name: res.Result.FullName,
        username: res.Result.UserName,
      });
      const { timezone } = store.getState().login;
      const nonExpiredSubscriptionPlans = await filterNonExpiredPlans(
        res.Result.Subscriptions,
        timezone
      );
      const hasPremiumSchoolPlan = hasPremiumAndSchoolPlans(
        res.Result.Subscriptions,
        timezone
      );
      const subsPreference = !isEmpty(res.Result.UserPreferences)
        ? res.Result.UserPreferences.find(
            (item) => item.Name.toLowerCase().trim() === 'subscription_default'
          )
        : null;
      let premiumSchoolPreference;
      if (process.env.REACT_APP_SCIENCE === 'true') {
        premiumSchoolPreference =
          !isNil(subsPreference) &&
          !isEmpty(subsPreference.Value.toLowerCase().trim())
            ? subsPreference.Value.toLowerCase().trim()
            : null;
      } else {
        premiumSchoolPreference =
          !isNil(subsPreference) &&
          ['premium', 'school'].includes(
            subsPreference.Value.toLowerCase().trim()
          )
            ? subsPreference.Value.toLowerCase().trim()
            : null;
      }

      const planToShow =
        hasPremiumSchoolPlan && premiumSchoolPreference !== null
          ? premiumSchoolPreference
          : checkSchoolPlan(nonExpiredSubscriptionPlans);
      if (process.env.REACT_APP_SCIENCE !== 'true')
        dispatch(setPlan(planToShow));

      const checkLocale = !isEmpty(res.Result.UserPreferences)
        ? res.Result.UserPreferences.find(
            (item) => item.Name.toLowerCase().trim() === 'currentlocale'
          )
        : null;
      const currentLocale = !isNil(checkLocale) ? checkLocale.Value : '';
      return {
        result: res,
        timezone: store.getState().login.timezone,
        nonExpiredSubscriptionPlans,
        hasPremiumSchoolPlan,
        premiumSchoolPreference,
        currentLocale,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Get student details failed');
    }
  }
);

const studentDetailsSlice = createSlice({
  name: 'studentDetails',
  initialState,
  reducers: {
    setStudentDetails: (state, action) => {
      state.studentDetails = action.payload;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setHasScreenLoaded: (state, action) => {
      state.hasScreenLoaded = action.payload;
    },
    setHasSeenLoader: (state, action) => {
      state.hasSeenLoader = action.payload;
    },
    setHasSeenToggleTooltip: (state, action) => {
      state.hasSeenToggleTooltip = action.payload;
    },
    setShowPremiumSchoolToggleModal: (state, action) => {
      state.showPremiumSchoolToggleModal = action.payload;
    },
    setIsExpired: (state, action) => {
      state.isExpired = action.payload;
    },
  },
  extraReducers: {
    [getStudentDetails.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getStudentDetails.fulfilled]: (state, action) => {
      state.studentDetails = action.payload.result.Result;
      state.isLoading = false;
      if (process.env.REACT_APP_SCIENCE !== 'true') {
        state.isExpired = checkIfAccountExpired(
          action.payload.result.Result.Subscriptions,
          action.payload.timezone
        );
      }
      state.subscription = checkSchoolPlan(
        action.payload.nonExpiredSubscriptionPlans
      );
      state.defaultLevel = getDefaultLevel(
        action.payload.result.Result.Subscriptions
      );
      const subsData = getSubsData(action.payload.nonExpiredSubscriptionPlans);
      if (subsData !== -1) {
        state.country = titleCase(subsData.CountryName);
        state.countryCode = subsData.ISOCode3;
        state.groupId = subsData.GroupId;
        state.subscriptionData = subsData;
      }
      state.hasPremiumSchoolPlan = action.payload.hasPremiumSchoolPlan;
      if (
        action.payload.premiumSchoolPreference === null &&
        action.payload.hasPremiumSchoolPlan === true
      ) {
        state.hasSeenToggleTooltip = !action.payload.hasPremiumSchoolPlan;
      }
      state.nonExpiredSubscriptionPlans =
        action.payload.nonExpiredSubscriptionPlans;
      state.userPremiumSchoolPlanPreference =
        action.payload.premiumSchoolPreference;
      state.userCurrentLocale = action.payload.currentLocale;
      state.gender = action.payload.result.Result.Gender;
    },
    [getStudentDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = studentDetailsSlice;
export const {
  setStudentDetails,
  setGender,
  setHasSeenLoader,
  setHasScreenLoaded,
  setHasSeenToggleTooltip,
  setShowPremiumSchoolToggleModal,
  setIsExpired,
} = actions;
export default reducer;
