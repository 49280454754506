import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchSuperHeroLeaderboardData,
  fetchSuperHeroTopFiftyLeaderboardData,
} from 'services/superheroLeaderboard';
import { fetchWrapper } from 'services/login';

// get superhero leaderboard data
export const getSuperheroLeaderboardData = createAsyncThunk(
  'superhero/getSuperheroLeaderboardData',
  async () => {
    try {
      const res = await fetchWrapper(fetchSuperHeroLeaderboardData);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get superhero leaderboard data failed'
      );
    }
  }
);

// get superhero top 50 leaderboard data
export const getSuperheroTopFiftyLeaderboardData = createAsyncThunk(
  'superhero/getSuperheroTopFiftyLeaderboardData',
  async () => {
    try {
      const res = await fetchWrapper(fetchSuperHeroTopFiftyLeaderboardData);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Get superhero leaderboard data failed'
      );
    }
  }
);

const initialState = {
  superheroLeaderboard: [],
  superheroLeaderboardLoading: false,
  superheroLeaderboardError: null,
};

const superheroLeaderboardSlice = createSlice({
  name: 'superheroLeaderboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getSuperheroLeaderboardData.pending]: (state) => {
      state.superheroLeaderboardLoading = true;
      state.superheroLeaderboard = [];
      state.superheroLeaderboardError = null;
    },
    [getSuperheroLeaderboardData.fulfilled]: (state, action) => {
      state.superheroLeaderboardLoading = false;
      state.superheroLeaderboard = action.payload;
      state.superheroLeaderboardError = null;
    },
    [getSuperheroLeaderboardData.rejected]: (state, action) => {
      state.superheroLeaderboardLoading = false;
      state.superheroLeaderboard = [];
      state.superheroLeaderboardError = action.error.message;
    },
    [getSuperheroTopFiftyLeaderboardData.pending]: (state) => {
      state.superheroLeaderboardLoading = true;
      state.superheroLeaderboard = [];
      state.superheroLeaderboardError = null;
    },
    [getSuperheroTopFiftyLeaderboardData.fulfilled]: (state, action) => {
      state.superheroLeaderboardLoading = false;
      state.superheroLeaderboard = action.payload;
      state.superheroLeaderboardError = null;
    },
    [getSuperheroTopFiftyLeaderboardData.rejected]: (state, action) => {
      state.superheroLeaderboardLoading = false;
      state.superheroLeaderboard = [];
      state.superheroLeaderboardError = action.error.message;
    },
  },
});

const { reducer } = superheroLeaderboardSlice;
export default reducer;
