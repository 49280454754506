import { createSlice } from '@reduxjs/toolkit';
import { isNil } from 'ramda';

const initialState = {
  toolsToShow: [],
  // Assist Tool that should be displayed on top of other tool windows
  toolOnTop: null,
  barModelToolPosition: null,
  addWorkingsToolPosition: null,
  fourOpsToolPosition: null,
};

const assistToolsSlice = createSlice({
  name: 'assistTools',
  initialState,
  reducers: {
    setToolPosition: (state, action) => {
      switch (action.payload.tool) {
        case 'bar-model':
          state.barModelToolPosition = action.payload.position;
          break;
        case 'add-working':
          state.addWorkingsToolPosition = action.payload.position;
          break;
        case 'four-ops':
          state.fourOpsToolPosition = action.payload.position;
          break;
        default:
          break;
      }
    },
    hideAllTools: (state) => {
      state.toolsToShow = [];
    },
    setSelectedToolOnTop: (state, action) => {
      state.toolOnTop = action.payload;
    },
    showSelectedTool: (state, action) => {
      if (
        isNil(state.toolsToShow.find((tool) => tool === action.payload)) ===
        true
      ) {
        const newToolsToShow = [...state.toolsToShow];
        newToolsToShow.push(action.payload);
        state.toolsToShow = newToolsToShow;
        state.toolOnTop = action.payload;
      }
    },
    hideSelectedTool: (state, action) => {
      const newToolsToShow = [...state.toolsToShow];
      const toolIndexToRemove = newToolsToShow.findIndex(
        (tool) => tool === action.payload
      );
      if (toolIndexToRemove !== -1) {
        newToolsToShow.splice(toolIndexToRemove, 1);
        state.toolsToShow = newToolsToShow;
      }
    },
  },
});

const { actions, reducer } = assistToolsSlice;
export const {
  hideAllTools,
  setToolPosition,
  setSelectedToolOnTop,
  showSelectedTool,
  hideSelectedTool,
} = actions;
export default reducer;
