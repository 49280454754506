import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchMainSuperheroPageData = async () => {
  return fetch(`${baseUrl}SuperHeroChallenge/GetSuperheroChallengeData`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get superhero data failed'); // eslint-disable-line
    });
};

export const fetchSuperHeroChallengeDataWithoutImage = () => {
  return fetch(
    `${baseUrl}SuperHeroChallenge/GetSuperheroChallengeDataWithoutImage`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get Super Hero challenge data without image failed'
      ); // eslint-disable-line
    });
};

export const fetchSupervisionData = async () => {
  return fetch(`${baseUrl}SuperHeroChallenge/GetSuperVisionData`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get supervision data failed'); // eslint-disable-line
    });
};

export const postSupervisionScore = async ({ score, userDiff }) => {
  return fetch(`${baseUrl}SuperHeroChallenge/SubmitSuperVision`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify({
      Score: score,
      UserDifferences: userDiff,
    }),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get supervision data failed'); // eslint-disable-line
    });
};

export const fetchSuperspeedData = async () => {
  return fetch(`${baseUrl}SuperHeroChallenge/GetSuperSpeedData`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'submit superspeed data failed'); // eslint-disable-line
    });
};

export const postSuperspeedScore = async (score) => {
  return fetch(`${baseUrl}SuperHeroChallenge/SubmitSuperSpeed`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify({
      score,
    }),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'submit superspeed score failed'); // eslint-disable-line
    });
};

export const fetchChallengeHistory = async ({ week }) => {
  return fetch(`${baseUrl}SuperHeroChallenge/GetChallengeHistory/${week}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get challenge history data failed'); // eslint-disable-line
    });
};
