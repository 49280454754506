import React from 'react';
import { Container } from './Card.styles';

const Card = ({ children, firstItem, lastItem, active }) => {
  return (
    <Container firstItem={firstItem} lastItem={lastItem} active={active}>
      {children}
    </Container>
  );
};

export default Card;
