import React from 'react';
import Found from 'assets/img/superhero/supervision-found.png';
import NotFound from 'assets/img/superhero/supervision-not-found.png';
import { useTranslation } from 'react-i18next';
import { Container, FoundText, FoundList, FoundItem } from './FooterBar.style';

const FooterBar = ({ found }) => {
  const { t } = useTranslation(['superVision']);
  const RenderFound = ({ points }) => {
    const Founds = [];
    for (let index = 0; index < 5; index += 1) {
      Founds.push(
        <FoundItem
          key={`found-${index}`}
          className={index + 1 <= points ? 'found' : ''}
        >
          <img src={Found} className="full" alt="Found" />
          <img src={NotFound} className="empty" alt="Empty" />
        </FoundItem>
      );
    }
    return Founds;
  };
  return (
    <Container>
      <FoundText>
        {t(
          'superVision:game.foundText',
          { found },
          `Found ${found} differences:`
        )}
      </FoundText>
      <FoundList>
        <RenderFound points={found} />
      </FoundList>
    </Container>
  );
};

export default React.memo(FooterBar);
