import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { compareValues } from 'helpers/compareValue';
import TopBrainHeader from 'components/Leaderboard/TopBrain/TopBrainHeader';
import TopBrainHeaderClassic from 'components/Leaderboard/TopBrain/TopBrainHeaderClassic';
import Category from 'components/Leaderboard/Category';
import StudentList from 'components/Leaderboard/StudentList';
import { isEmpty, isNil } from 'ramda';
import { useHistory } from 'react-router-dom';
import {
  getTopBrainTerms,
  getTopBrainByTerm,
  getTopFiftyTopBrainByTerm,
  getTopBrainBySeason,
  getTopFiftyTopBrainBySeason,
} from 'store/topBrain/topBrainSlice';
import useGlobalFeature from 'hooks/useGlobalFeature';
import { checkIsCurrentDateTimeBetweenHours } from 'pages/Leaderboard/index';
import { Container, NoParticipant } from './TopBrain.styles';

// Helpers
export const isLeaderboardFeatureOpen = (feature) => {
  if (feature.isEnabled) {
    return true;
  }
  const startTime = feature?.feature?.parameters?.startTime;
  const endTime = feature?.feature?.parameters?.endTime;
  const hasStartEndTime =
    !isNil(startTime) &&
    !isNil(endTime) &&
    startTime.length === 5 &&
    endTime.length === 5;
  if (hasStartEndTime) {
    return checkIsCurrentDateTimeBetweenHours(startTime, endTime);
  }
  return false;
};

// 1 for Math
const subjectID = 1;

const TopBrain = () => {
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isLoading,
    topBrainTerms,
    topBrainData,
    topBrainSeasons,
  } = useSelector((state) => state.topBrain);
  const { userID } = useSelector((state) => state.login);
  const { defaultLevel, country } = useSelector(
    (state) => state.studentDetails
  );
  // get current season & year
  const sortedData = Object.values(topBrainSeasons)
    .sort(compareValues('StartDate', 'desc'))
    .filter((data) => moment().isSameOrAfter(data.StartDate));
  const currentSeason = sortedData[0] && sortedData[0].ID;
  const currentYear = sortedData[0] && sortedData[0].Year;
  const currentSeasonName = sortedData[0] && sortedData[0].Name;

  const singaporeSchool = country === 'Singapore';

  const [selectedLevel, setSelectedlevel] = useState(defaultLevel);
  const [activeTerm, setActiveTerm] = useState('term');
  const [activeCategory, setActiveCategory] = useState(3);
  const [activeSeason, setActiveSeason] = useState(currentSeason);
  const [currentTerm, setCurrentTerm] = useState({});
  const [year, setYear] = useState(currentYear);
  const [studentData, setStudentData] = useState([]);
  const [isTopFifty, setIsTopFifty] = useState(false);

  const { featuresHashmap } = useGlobalFeature(null, [
    'topbrain-leaderboard-termly',
    'topbrain-leaderboard-classic',
  ]);
  const isTermlyOpen = isLeaderboardFeatureOpen(
    featuresHashmap['topbrain-leaderboard-termly']
  );
  const isClassicOpen = isLeaderboardFeatureOpen(
    featuresHashmap['topbrain-leaderboard-classic']
  );

  useEffect(() => {
    if (!singaporeSchool) {
      if (!isClassicOpen) {
        history.replace('/maintenance-redirect');
        return;
      }
      setActiveTerm('classic');
    } else if (!isTermlyOpen && isClassicOpen) {
      setActiveTerm('classic');
    } else if (!isClassicOpen && !isTermlyOpen) {
      history.replace('/maintenance-redirect');
    }
  }, [history, isClassicOpen, isTermlyOpen, singaporeSchool]);

  useEffect(() => {
    dispatch(getTopBrainTerms());
  }, [dispatch]);

  useEffect(() => {
    setActiveSeason(currentSeason);
  }, [currentSeason]);

  useEffect(() => {
    setYear(currentYear);
  }, [currentYear]);

  useEffect(() => {
    if (isNil(selectedLevel)) {
      setSelectedlevel(defaultLevel);
    }
  }, [defaultLevel, selectedLevel]);

  const getTermsData = (
    term,
    level = defaultLevel,
    enumTopBrainFilter = activeCategory
  ) => {
    const params = {
      enumTopBrainFilter,
      term,
      level,
      subject: subjectID,
    };
    if (isNil(level)) return;
    dispatch(getTopBrainByTerm(params));
  };

  useEffect(() => {
    if (activeTerm === 'term') {
      if (!isEmpty(topBrainTerms)) {
        for (let i = 0; i < topBrainTerms.length; i++) {
          if (
            moment().isSameOrAfter(topBrainTerms[i].TermStartDate) &&
            moment().isSameOrBefore(topBrainTerms[i].TermEndDate)
          ) {
            setCurrentTerm(topBrainTerms[i]);
            getTermsData(topBrainTerms[i].TermId);
            break;
          } else if (
            i < topBrainTerms.length - 1 &&
            moment().isBetween(
              topBrainTerms[i].TermEndDate,
              topBrainTerms[i + 1].TermStartDate
            )
          ) {
            setCurrentTerm(topBrainTerms[i]);
            getTermsData(topBrainTerms[i].TermId);
            break;
          } else if (i === topBrainTerms.length - 1) {
            setCurrentTerm(topBrainTerms[i]);
            getTermsData(topBrainTerms[i].TermId);
            break;
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [topBrainTerms, activeTerm, defaultLevel]);

  useEffect(() => {
    const isParamsAvailable = !isNil(year) && !isNil(activeSeason);
    if (!isParamsAvailable) return;
    if (activeTerm === 'classic') {
      dispatch(
        getTopBrainBySeason({
          enumTopBrainFilter: activeCategory,
          season: activeSeason,
          year,
          level: selectedLevel,
          subject: subjectID,
        })
      );
    }
    // eslint-disable-next-line
  }, [activeTerm, year, activeSeason]);

  useEffect(() => {
    if (!isEmpty(topBrainData)) {
      const isUser = topBrainData.find((stu) => stu.UserId === userID);
      if (isUser) {
        setIsTopFifty(isUser.LeaderBoardRank <= 50);
      } else {
        setIsTopFifty(true);
      }
      setStudentData(topBrainData);
    } else {
      setStudentData([]);
    }
    // eslint-disable-next-line
  }, [topBrainData]);

  const getStudents = (active, level) => {
    setIsTopFifty(false);
    if (activeTerm === 'term') {
      getTermsData(currentTerm.TermId, level, active);
    }
    const isParamsAvailable = !isNil(year) && !isNil(activeSeason);
    if (!isParamsAvailable) return;
    if (activeTerm === 'classic') {
      dispatch(
        getTopBrainBySeason({
          enumTopBrainFilter: active,
          season: activeSeason,
          year,
          level,
          subject: subjectID,
        })
      );
    }
  };

  const getTopFiftyStudents = () => {
    setIsTopFifty(false);
    if (activeTerm === 'term') {
      dispatch(
        getTopFiftyTopBrainByTerm({
          enumTopBrainFilter: activeCategory,
          term: currentTerm.TermId,
          level: selectedLevel,
          subject: subjectID,
        })
      );
    }
    if (activeTerm === 'classic') {
      dispatch(
        getTopFiftyTopBrainBySeason({
          enumTopBrainFilter: activeCategory,
          season: activeSeason,
          year,
          level: selectedLevel,
          subject: subjectID,
        })
      );
    }
  };

  const changeCurrentTerm = (id) => {
    setSelectedlevel(defaultLevel);
    setCurrentTerm(topBrainTerms.find((e) => e.TermId === id));
    getTermsData(id);
  };

  return (
    <Container>
      {activeTerm === 'term' ? (
        <TopBrainHeader
          activeTerm={activeTerm}
          setActiveTerm={setActiveTerm}
          changeCurrentTerm={changeCurrentTerm}
          currentTerm={currentTerm}
          isTermlyOpen={isTermlyOpen}
          isClassicOpen={isClassicOpen}
        />
      ) : (
        <TopBrainHeaderClassic
          activeTerm={singaporeSchool ? activeTerm : 'classic'}
          setActiveTerm={setActiveTerm}
          singaporeSchool={singaporeSchool}
          season={activeSeason}
          setSeason={setActiveSeason}
          year={year}
          setYear={setYear}
          currentSeason={currentSeason}
          currentYear={currentYear}
          currentSeasonName={currentSeasonName}
          data={sortedData}
          isTermlyOpen={isTermlyOpen}
          isClassicOpen={isClassicOpen}
        />
      )}
      <Category
        showTopFifty
        getLists={getStudents}
        getTopFiftyLists={getTopFiftyStudents}
        active={activeCategory}
        setActive={setActiveCategory}
        selectedLevel={selectedLevel}
        setSelectedlevel={setSelectedlevel}
      />
      {studentData.length > 0 && (
        <StudentList
          challengeMode="topBrain"
          reducer="topBrain"
          data={studentData}
          isTopFifty={isTopFifty}
          active={activeCategory}
          setActive={setActiveCategory}
          isLoading={isLoading}
        />
      )}
      {!isLoading && studentData.length === 0 && (
        <NoParticipant>
          {t(
            'leaderboard:hodBanner.noParticipant',
            `No participants at the moment.`
          )}
        </NoParticipant>
      )}
    </Container>
  );
};

export default TopBrain;
