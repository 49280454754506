import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BffCategory = styled.div`
  width: 70%;
  display: flex;
  justify-content: flex-end;
  background-color: #c6efff;
  align-self: center;
  align-items: center;
  padding: 10px 40px;
  .curriculum-date {
    margin-left: 10px;
  }
`;
