import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import SuperheroIcon from 'assets/img/leaderboard/super-hero/superhero-point.png';
import { LABELS } from './constants';
import { BreakdownScore } from '../../../pages/Superhero/components';
import { Container, Headings, Lists } from '../List.styles';

const SuperheroList = ({ data, isTopFifty }) => {
  const { userID } = useSelector((state) => state.login);
  const { t } = useTranslation(['leaderboard']);
  const { timezone } = useSelector((state) => state.login);
  const [showBreakdownData, setShowBreakdownData] = useState({});
  const [isNearBy, setIsNearBy] = useState(false);
  const [nearByData, setNearByData] = useState(data);
  const showPosition = (pos) => {
    if (isTopFifty && pos < 4) {
      if (pos === 1) return 'first';
      if (pos === 2) return 'second';
      if (pos === 3) return 'third';
    }
    return 'default';
  };
  const handleToggleBreakdown = (i) => {
    setShowBreakdownData((prev) =>
      prev[i] ? { ...prev, [i]: null } : { ...prev, [i]: data[i] }
    );
  };
  useEffect(() => {
    if (isTopFifty) {
      setIsNearBy(false);
      setNearByData(data);
    } else {
      setIsNearBy(true);
      setNearByData(data.filter((_, i) => i >= 2));
    }
  }, [isTopFifty, data]);
  const nearByHandler = () => {
    setIsNearBy(false);
    setNearByData(data);
  };
  return (
    <Container>
      <div className="list-headings">
        {LABELS.map((label) => (
          <Headings key={label.name} flex={label.flex}>
            {t(
              `leaderboard:leaderboardRanking.${label.translateKey}`,
              label.name
            )}
          </Headings>
        ))}
      </div>
      {isNearBy && !isTopFifty && (
        <div className="nearby-container">
          <div onClick={nearByHandler} role="button" className="nearby">
            {t('leaderboard:buttons.nearby', 'See Who is nearby')}
          </div>
        </div>
      )}
      {nearByData.map((item, i) => {
        return (
          <Fragment key={item.userId}>
            <Lists
              data-cy={`data-rank-${i}`}
              className={item.userId === userID && 'current-user'}
              currPos={showPosition(item.leaderboardRank)}
              onClick={() => handleToggleBreakdown(i)}
              pointer
            >
              {isTopFifty && (
                <div className="positions">{item.leaderboardRank}</div>
              )}
              <div className="list-name">{item.userName}</div>
              <div className="list-school">
                <div className="school-img-container">
                  <img
                    src={`https://static.koobits.com/eduresources/schlogo/${item.schoolLogo}.png`}
                    alt="flag"
                  />
                </div>
                {item.schoolName}
              </div>
              <div className="list-points">
                <img alt="cps" src={SuperheroIcon} />
                {item.cpPoints}
              </div>
              <div className="list-achievement">
                {moment
                  .utc(item.lastSubmissionDate)
                  .tz(timezone)
                  .format('DD-MMM-YYYY HH:mm')}
              </div>
            </Lists>
            {showBreakdownData[i] && <BreakdownScore data={item} />}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default SuperheroList;
